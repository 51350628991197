export const WORDS_VALID = new Set([
  "AADAR",
  "AADEN",
  "AADNA",
  "AADNE",
  "AADNI",
  "AADNU",
  "AAKUL",
  "AALAM",
  "AALEN",
  "AALLA",
  "AALLE",
  "AALLI",
  "AALLU",
  "AALNA",
  "AALNU",
  "AALST",
  "AALTA",
  "AALTE",
  "AALTI",
  "AALTO",
  "AALTU",
  "AAMES",
  "AAMIN",
  "AAMIR",
  "AAPIH",
  "AAPJA",
  "AAPJU",
  "AAPOM",
  "AAPOV",
  "AARAU",
  "AARIF",
  "AARIK",
  "AARNI",
  "AARON",
  "AARTI",
  "AASEN",
  "AASHA",
  "AASHE",
  "AASHI",
  "AASHO",
  "AASIF",
  "AASIH",
  "AASIN",
  "AASJA",
  "AASJU",
  "AASNA",
  "AASNE",
  "AASNI",
  "AASNU",
  "AASOM",
  "AASOV",
  "AAVIK",
  "ABACA",
  "ABACE",
  "ABACI",
  "ABACO",
  "ABACU",
  "ABADA",
  "ABADE",
  "ABADI",
  "ABADU",
  "ABAJA",
  "ABAJE",
  "ABAJI",
  "ABAJO",
  "ABAKA",
  "ABAKE",
  "ABAKI",
  "ABAKU",
  "ABALA",
  "ABALE",
  "ABALI",
  "ABALO",
  "ABALU",
  "ABANA",
  "ABANE",
  "ABANI",
  "ABANO",
  "ABASA",
  "ABASE",
  "ABASI",
  "ABASS",
  "ABASU",
  "ABATE",
  "ABATI",
  "ABAZA",
  "ABAZE",
  "ABAZI",
  "ABAZO",
  "ABAZU",
  "ABBAH",
  "ABBAM",
  "ABBAS",
  "ABBES",
  "ABBIE",
  "ABBIH",
  "ABBIN",
  "ABBJA",
  "ABBJU",
  "ABBOM",
  "ABBOT",
  "ABBOU",
  "ABBOV",
  "ABBSA",
  "ABBSE",
  "ABBSI",
  "ABBSU",
  "ABCIM",
  "ABDEL",
  "ABDIČ",
  "ABDIU",
  "ABDON",
  "ABDUL",
  "ABEBA",
  "ABEBE",
  "ABEBI",
  "ABEBO",
  "ABEDA",
  "ABEDE",
  "ABEDI",
  "ABEDU",
  "ABEED",
  "ABEER",
  "ABEGG",
  "ABEJA",
  "ABEJE",
  "ABEJI",
  "ABEJU",
  "ABELA",
  "ABELE",
  "ABELI",
  "ABELL",
  "ABELO",
  "ABELS",
  "ABELU",
  "ABENA",
  "ABEND",
  "ABENE",
  "ABENI",
  "ABENO",
  "ABENZ",
  "ABERG",
  "ABERT",
  "ABHAZ",
  "ABIDA",
  "ABIDE",
  "ABIDI",
  "ABIDO",
  "ABIDU",
  "ABIEL",
  "ABIJA",
  "ABIJE",
  "ABIJI",
  "ABIJU",
  "ABIKA",
  "ABIKE",
  "ABIKI",
  "ABIKO",
  "ABIKU",
  "ABINA",
  "ABINE",
  "ABINI",
  "ABINO",
  "ABINU",
  "ABIOL",
  "ABIOZ",
  "ABISA",
  "ABISU",
  "ABITA",
  "ABITE",
  "ABITI",
  "ABITU",
  "ABKIN",
  "ABLIH",
  "ABLIN",
  "ABLJA",
  "ABLJU",
  "ABLOH",
  "ABLOM",
  "ABLOV",
  "ABNER",
  "ABODI",
  "ABOJU",
  "ABOLD",
  "ABOLS",
  "ABOMA",
  "ABOTA",
  "ABOTE",
  "ABOTI",
  "ABOTO",
  "ABOTU",
  "ABOVA",
  "ABOVE",
  "ABOVI",
  "ABOVO",
  "ABRAH",
  "ABRAM",
  "ABREU",
  "ABRIL",
  "ABRIM",
  "ABRIN",
  "ABRIS",
  "ABRON",
  "ABTIH",
  "ABTJA",
  "ABTJU",
  "ABTOM",
  "ABTOV",
  "ABUJA",
  "ABUJE",
  "ABUJI",
  "ABUJO",
  "ABUSA",
  "ABUSE",
  "ABUSI",
  "ABUSU",
  "ABZAL",
  "ABZUG",
  "ACABA",
  "ACABE",
  "ACABI",
  "ACABO",
  "ACABU",
  "ACAMP",
  "ACCOL",
  "ACCOR",
  "ACEIH",
  "ACEJA",
  "ACEJU",
  "ACEOM",
  "ACEOV",
  "ACHAL",
  "ACHIM",
  "ACIDA",
  "ACIDI",
  "ACIDU",
  "ACIJA",
  "ACIJE",
  "ACIJI",
  "ACIJU",
  "ACINA",
  "ACINE",
  "ACINI",
  "ACINU",
  "ACKER",
  "ACKIE",
  "ACKIH",
  "ACKJA",
  "ACKJU",
  "ACKOM",
  "ACKOV",
  "ACLAN",
  "ACMAN",
  "ACOMA",
  "ACONA",
  "ACONE",
  "ACONI",
  "ACONU",
  "ACOST",
  "ACOTA",
  "ACOTT",
  "ACOTU",
  "ACOVA",
  "ACOVE",
  "ACOVI",
  "ACOVO",
  "ACRES",
  "ACRON",
  "ACTON",
  "ACUFF",
  "ACUNA",
  "ACUNE",
  "ACUNI",
  "ACUNO",
  "ACUNU",
  "AČKAR",
  "AČKIH",
  "AČKJA",
  "AČKJU",
  "AČKOM",
  "AČKOV",
  "AČKUN",
  "ADABI",
  "ADAIR",
  "ADAMA",
  "ADAME",
  "ADAMI",
  "ADAMK",
  "ADAMO",
  "ADAMS",
  "ADAMU",
  "ADANA",
  "ADANE",
  "ADANI",
  "ADANJ",
  "ADANU",
  "ADAOR",
  "ADARA",
  "ADARE",
  "ADARI",
  "ADARO",
  "ADARU",
  "ADDIE",
  "ADDIH",
  "ADDIS",
  "ADDJA",
  "ADDJU",
  "ADDOM",
  "ADDOV",
  "ADEAN",
  "ADEEL",
  "ADELA",
  "ADELE",
  "ADELF",
  "ADELI",
  "ADELL",
  "ADELO",
  "ADELT",
  "ADELU",
  "ADEMA",
  "ADEME",
  "ADEMI",
  "ADEMU",
  "ADENA",
  "ADENE",
  "ADENI",
  "ADENU",
  "ADEPT",
  "ADHAM",
  "ADIAH",
  "ADIAM",
  "ADIBA",
  "ADIBE",
  "ADIBI",
  "ADIBU",
  "ADIJA",
  "ADIJE",
  "ADIJI",
  "ADIJO",
  "ADIJU",
  "ADIKA",
  "ADIKE",
  "ADIKI",
  "ADIKU",
  "ADILA",
  "ADILE",
  "ADILI",
  "ADILO",
  "ADILU",
  "ADINA",
  "ADIND",
  "ADINE",
  "ADINI",
  "ADINO",
  "ADINU",
  "ADIRA",
  "ADIRI",
  "ADIRU",
  "ADISA",
  "ADISE",
  "ADISI",
  "ADISO",
  "ADISU",
  "ADITA",
  "ADITE",
  "ADITI",
  "ADITU",
  "ADIVA",
  "ADIVE",
  "ADIVI",
  "ADIVO",
  "ADIZA",
  "ADIZE",
  "ADIZI",
  "ADIZO",
  "ADIŽA",
  "ADIŽE",
  "ADIŽI",
  "ADIŽO",
  "ADKIN",
  "ADLAM",
  "ADLER",
  "ADLES",
  "ADLON",
  "ADLUM",
  "ADMET",
  "ADMIN",
  "ADMIR",
  "ADNAN",
  "ADNOR",
  "ADOBA",
  "ADOBE",
  "ADOBU",
  "ADOLF",
  "ADOMA",
  "ADONI",
  "ADORF",
  "ADOTA",
  "ADOTU",
  "ADOUM",
  "ADOVA",
  "ADOVE",
  "ADOVI",
  "ADOVO",
  "ADRAH",
  "ADRAM",
  "ADRAR",
  "ADREM",
  "ADRES",
  "ADRIA",
  "ADRIE",
  "ADRII",
  "ADRIN",
  "ADRIO",
  "ADRIS",
  "ADSIT",
  "ADSON",
  "ADTEH",
  "ADUAH",
  "ADUAM",
  "ADUBA",
  "ADUBE",
  "ADUBI",
  "ADUBO",
  "ADUBU",
  "ADUIN",
  "ADULT",
  "ADURA",
  "ADURE",
  "ADURI",
  "ADURO",
  "ADUTA",
  "ADUTE",
  "ADUTI",
  "ADUTK",
  "ADUTU",
  "ADVAN",
  "AEDIN",
  "AEGON",
  "AEMET",
  "AENNE",
  "AEREO",
  "AERJA",
  "AERJE",
  "AERJI",
  "AERJU",
  "AERLI",
  "AERNI",
  "AEROM",
  "AEROV",
  "AERTA",
  "AERTE",
  "AERTI",
  "AERTS",
  "AERTU",
  "AESHA",
  "AESHE",
  "AESHI",
  "AESHO",
  "AETIJ",
  "AETNA",
  "AETNE",
  "AETNI",
  "AETNO",
  "AFEKT",
  "AFENA",
  "AFENE",
  "AFENI",
  "AFENO",
  "AFENU",
  "AFERA",
  "AFERE",
  "AFERI",
  "AFERO",
  "AFFIF",
  "AFFUL",
  "AFFUM",
  "AFIFA",
  "AFIFE",
  "AFIFI",
  "AFIFU",
  "AFIKS",
  "AFINA",
  "AFINE",
  "AFINI",
  "AFINO",
  "AFKIR",
  "AFNAH",
  "AFNAJ",
  "AFNAL",
  "AFNAM",
  "AFNAŠ",
  "AFNAT",
  "AFNAU",
  "AFNAV",
  "AFNIN",
  "AFPAC",
  "AFRIČ",
  "AFRIK",
  "AFRIM",
  "AFRIN",
  "AFTAB",
  "AFTAH",
  "AFTAM",
  "AFTON",
  "AFTOZ",
  "AFZAL",
  "AGAGA",
  "AGAGE",
  "AGAGI",
  "AGAGU",
  "AGAJA",
  "AGAJE",
  "AGAJI",
  "AGAJU",
  "AGAMA",
  "AGAME",
  "AGAMI",
  "AGAMO",
  "AGANI",
  "AGARD",
  "AGATA",
  "AGATE",
  "AGATH",
  "AGATI",
  "AGATO",
  "AGAVA",
  "AGAVE",
  "AGAVI",
  "AGAVO",
  "AGBAH",
  "AGBAL",
  "AGBAM",
  "AGBIH",
  "AGBJA",
  "AGBJU",
  "AGBOM",
  "AGBOV",
  "AGEEM",
  "AGEJA",
  "AGEJU",
  "AGELI",
  "AGEND",
  "AGENS",
  "AGENT",
  "AGEOV",
  "AGEVA",
  "AGEVE",
  "AGEVI",
  "AGEVO",
  "AGFIN",
  "AGGAH",
  "AGGAM",
  "AGGER",
  "AGGIN",
  "AGIČA",
  "AGIČE",
  "AGIČI",
  "AGIČU",
  "AGIMA",
  "AGIME",
  "AGIMI",
  "AGIMU",
  "AGINA",
  "AGINE",
  "AGINI",
  "AGINO",
  "AGIRA",
  "AGIRE",
  "AGIRI",
  "AGIRO",
  "AGIRU",
  "AGISA",
  "AGISU",
  "AGITA",
  "AGITK",
  "AGITO",
  "AGITU",
  "AGIUS",
  "AGLAH",
  "AGLAI",
  "AGLAJ",
  "AGLAM",
  "AGLEF",
  "AGLIA",
  "AGLIE",
  "AGLII",
  "AGLIN",
  "AGLIO",
  "AGLIU",
  "AGMON",
  "AGNAT",
  "AGNEL",
  "AGNES",
  "AGNET",
  "AGNEZ",
  "AGNIČ",
  "AGNON",
  "AGOLA",
  "AGOLE",
  "AGOLI",
  "AGOLU",
  "AGOMA",
  "AGONA",
  "AGONE",
  "AGONI",
  "AGONU",
  "AGOPA",
  "AGOPE",
  "AGOPI",
  "AGOPU",
  "AGORA",
  "AGORE",
  "AGORI",
  "AGORO",
  "AGOTA",
  "AGOTE",
  "AGOTI",
  "AGOTO",
  "AGOTU",
  "AGOVA",
  "AGOVE",
  "AGOVI",
  "AGOVO",
  "AGRAF",
  "AGRAM",
  "AGRAR",
  "AGRAS",
  "AGREŽ",
  "AGRFT",
  "AGRIP",
  "AGRON",
  "AGTHE",
  "AGUED",
  "AGUIL",
  "AGUSA",
  "AGUSE",
  "AGUSH",
  "AGUSI",
  "AGUST",
  "AGUSU",
  "AGUTA",
  "AGUTE",
  "AGUTI",
  "AGUTU",
  "AHABA",
  "AHABE",
  "AHABI",
  "AHABU",
  "AHACA",
  "AHACE",
  "AHACI",
  "AHACU",
  "AHAJA",
  "AHAJE",
  "AHAJI",
  "AHAJK",
  "AHAJO",
  "AHATA",
  "AHATE",
  "AHATI",
  "AHATU",
  "AHAVA",
  "AHAVE",
  "AHAVI",
  "AHAVO",
  "AHAVU",
  "AHČAN",
  "AHČIN",
  "AHEJA",
  "AHEJE",
  "AHEJI",
  "AHEJU",
  "AHERN",
  "AHILA",
  "AHILE",
  "AHILI",
  "AHILU",
  "AHIŠA",
  "AHIŠE",
  "AHIŠI",
  "AHIŠU",
  "AHLAH",
  "AHLAM",
  "AHLIN",
  "AHLMA",
  "AHLME",
  "AHLMI",
  "AHLMU",
  "AHMAD",
  "AHMAN",
  "AHMAT",
  "AHMED",
  "AHMEJ",
  "AHMET",
  "AHNEM",
  "AHNEŠ",
  "AHNIH",
  "AHNIL",
  "AHNIT",
  "AHNIU",
  "AHNIV",
  "AHNJA",
  "AHNJU",
  "AHNOM",
  "AHNOV",
  "AHOLA",
  "AHOLD",
  "AHOLE",
  "AHOLI",
  "AHOLO",
  "AHOLU",
  "AHOMA",
  "AHORN",
  "AHOTA",
  "AHOTU",
  "AHOVA",
  "AHOVE",
  "AHOVI",
  "AHOVO",
  "AHSIA",
  "AHSIE",
  "AHSII",
  "AHSIJ",
  "AHSIO",
  "AHTAJ",
  "AHTAL",
  "AHTAM",
  "AHTAŠ",
  "AHTAT",
  "AHTAU",
  "AHTAV",
  "AHTEN",
  "AHTIK",
  "AHTNA",
  "AHTNU",
  "AHURA",
  "AICHA",
  "AICHE",
  "AICHI",
  "AICHO",
  "AICHU",
  "AIDAH",
  "AIDAM",
  "AIDAN",
  "AIDAR",
  "AIDEE",
  "AIDEN",
  "AIDID",
  "AIDIN",
  "AIDNA",
  "AIDNE",
  "AIDNI",
  "AIDNU",
  "AIDSA",
  "AIDSI",
  "AIDSU",
  "AIGRA",
  "AIGRE",
  "AIGRI",
  "AIGRO",
  "AIGRU",
  "AIJAH",
  "AIJAM",
  "AIJIN",
  "AIKEN",
  "AIKIN",
  "AILES",
  "AILSA",
  "AILSE",
  "AILSI",
  "AILSO",
  "AIMAN",
  "AIMEE",
  "AINAH",
  "AINAM",
  "AINIH",
  "AININ",
  "AINJA",
  "AINJU",
  "AINOM",
  "AINOV",
  "AIONA",
  "AIONE",
  "AIONI",
  "AIONO",
  "AIONU",
  "AIOSS",
  "AIREK",
  "AIRES",
  "AIRJA",
  "AIRJU",
  "AIROM",
  "AIRON",
  "AISHA",
  "AISHE",
  "AISHI",
  "AISHO",
  "AISSA",
  "AISSE",
  "AISSI",
  "AISSO",
  "AISSU",
  "AISTA",
  "AISTE",
  "AISTI",
  "AISTU",
  "AITAN",
  "AITON",
  "AITOR",
  "AIUTI",
  "AIVAR",
  "AJAJE",
  "AJAJO",
  "AJAKA",
  "AJAKE",
  "AJAKI",
  "AJAKU",
  "AJALA",
  "AJALE",
  "AJALI",
  "AJALO",
  "AJAMA",
  "AJAME",
  "AJAMI",
  "AJAMO",
  "AJANA",
  "AJANE",
  "AJANI",
  "AJANJ",
  "AJANT",
  "AJANU",
  "AJATA",
  "AJATE",
  "AJATI",
  "AJAVA",
  "AJAVE",
  "AJAVI",
  "AJAZI",
  "AJBIŠ",
  "AJDAH",
  "AJDAM",
  "AJDEM",
  "AJDEN",
  "AJDEV",
  "AJDIČ",
  "AJDIH",
  "AJDIN",
  "AJDJA",
  "AJDJE",
  "AJDJU",
  "AJDNA",
  "AJDNE",
  "AJDNI",
  "AJDNO",
  "AJDOM",
  "AJDOV",
  "AJETA",
  "AJETE",
  "AJETI",
  "AJETU",
  "AJFRA",
  "AJFRI",
  "AJFRU",
  "AJGEJ",
  "AJHAN",
  "AJILA",
  "AJILE",
  "AJILI",
  "AJILO",
  "AJILU",
  "AJIMO",
  "AJINA",
  "AJINE",
  "AJINI",
  "AJINO",
  "AJITA",
  "AJITE",
  "AJITH",
  "AJITI",
  "AJITO",
  "AJITU",
  "AJIVA",
  "AJKAH",
  "AJKAM",
  "AJKUL",
  "AJKUN",
  "AJLAH",
  "AJLAM",
  "AJLEC",
  "AJLIN",
  "AJLUL",
  "AJMAN",
  "AJMER",
  "AJNAH",
  "AJNAM",
  "AJNCA",
  "AJNCI",
  "AJNCU",
  "AJNIK",
  "AJNIN",
  "AJOLA",
  "AJOLE",
  "AJOLI",
  "AJOLO",
  "AJOLU",
  "AJPES",
  "AJSHE",
  "AJŠAH",
  "AJŠAM",
  "AJŠIN",
  "AJTEN",
  "AJUMU",
  "AJVAR",
  "AJVAZ",
  "AJZON",
  "AKABA",
  "AKABE",
  "AKABI",
  "AKABO",
  "AKADA",
  "AKADU",
  "AKAJE",
  "AKAJO",
  "AKALA",
  "AKALE",
  "AKALI",
  "AKALO",
  "AKAMA",
  "AKAME",
  "AKAMO",
  "AKANA",
  "AKANE",
  "AKANI",
  "AKANJ",
  "AKANO",
  "AKANT",
  "AKANU",
  "AKARI",
  "AKASH",
  "AKAST",
  "AKAŠA",
  "AKAŠE",
  "AKAŠI",
  "AKAŠO",
  "AKATA",
  "AKATE",
  "AKATI",
  "AKAVA",
  "AKAVE",
  "AKAVI",
  "AKBAG",
  "AKBAR",
  "AKCIJ",
  "AKDAG",
  "AKELA",
  "AKELE",
  "AKELI",
  "AKELO",
  "AKEMI",
  "AKENO",
  "AKERS",
  "AKIFA",
  "AKIFE",
  "AKIFI",
  "AKIFU",
  "AKIIH",
  "AKIJA",
  "AKIJE",
  "AKIJI",
  "AKIJO",
  "AKIJU",
  "AKIKI",
  "AKIKO",
  "AKILA",
  "AKILE",
  "AKILI",
  "AKILU",
  "AKIMA",
  "AKIME",
  "AKIMI",
  "AKIMO",
  "AKIMU",
  "AKINA",
  "AKINE",
  "AKINI",
  "AKINO",
  "AKINS",
  "AKINU",
  "AKIOM",
  "AKIOV",
  "AKIRA",
  "AKIRE",
  "AKIRI",
  "AKIRO",
  "AKIRU",
  "AKISA",
  "AKISE",
  "AKISI",
  "AKISU",
  "AKITA",
  "AKITE",
  "AKITI",
  "AKITO",
  "AKITU",
  "AKIVA",
  "AKIVE",
  "AKIVI",
  "AKIVO",
  "AKIVU",
  "AKLER",
  "AKMAN",
  "AKNAH",
  "AKNAM",
  "AKNIH",
  "AKNIN",
  "AKNJA",
  "AKNJU",
  "AKNOM",
  "AKNOV",
  "AKOMA",
  "AKORD",
  "AKOSA",
  "AKOSE",
  "AKOSI",
  "AKOSU",
  "AKOTA",
  "AKOTH",
  "AKOTU",
  "AKOVA",
  "AKOVE",
  "AKOVI",
  "AKOVO",
  "AKPAN",
  "AKPOM",
  "AKRAM",
  "AKRAP",
  "AKREM",
  "AKREV",
  "AKRIH",
  "AKRIL",
  "AKRJA",
  "AKRJU",
  "AKROM",
  "AKRON",
  "AKROV",
  "AKSAH",
  "AKSAM",
  "AKSEL",
  "AKSIL",
  "AKSIS",
  "AKSJU",
  "AKSLA",
  "AKSLE",
  "AKSLI",
  "AKSLU",
  "AKSON",
  "AKTEM",
  "AKTER",
  "AKTEV",
  "AKTIH",
  "AKTIN",
  "AKTIV",
  "AKTJA",
  "AKTJU",
  "AKTOM",
  "AKTON",
  "AKTOV",
  "AKTRP",
  "AKUTA",
  "AKUTE",
  "AKUTI",
  "AKUTU",
  "ALABA",
  "ALABE",
  "ALABI",
  "ALABO",
  "ALABU",
  "ALAČA",
  "ALAČE",
  "ALAČI",
  "ALAČU",
  "ALAHA",
  "ALAHU",
  "ALAIN",
  "ALAJA",
  "ALAJU",
  "ALAKA",
  "ALAKU",
  "ALAMA",
  "ALAME",
  "ALAMI",
  "ALAMU",
  "ALANA",
  "ALANE",
  "ALANI",
  "ALANK",
  "ALANN",
  "ALANO",
  "ALANU",
  "ALAPI",
  "ALARA",
  "ALARE",
  "ALARI",
  "ALARM",
  "ALARO",
  "ALASA",
  "ALASE",
  "ALASI",
  "ALASU",
  "ALATI",
  "ALAUF",
  "ALAUR",
  "ALBAG",
  "ALBAH",
  "ALBAM",
  "ALBAN",
  "ALBAS",
  "ALBEE",
  "ALBEN",
  "ALBER",
  "ALBIE",
  "ALBIG",
  "ALBIH",
  "ALBIN",
  "ALBJA",
  "ALBJU",
  "ALBOG",
  "ALBOM",
  "ALBON",
  "ALBOV",
  "ALBUM",
  "ALBUS",
  "ALCAL",
  "ALCAN",
  "ALCOA",
  "ALCOB",
  "ALCOE",
  "ALCOI",
  "ALCON",
  "ALCOO",
  "ALDAG",
  "ALDAH",
  "ALDAM",
  "ALDAN",
  "ALDEN",
  "ALDER",
  "ALDIH",
  "ALDIN",
  "ALDIS",
  "ALDJA",
  "ALDJU",
  "ALDNA",
  "ALDNE",
  "ALDNI",
  "ALDNU",
  "ALDOM",
  "ALDON",
  "ALDOV",
  "ALDOZ",
  "ALDUK",
  "ALDUS",
  "ALECA",
  "ALECE",
  "ALECI",
  "ALECU",
  "ALEDA",
  "ALEDE",
  "ALEDI",
  "ALEDU",
  "ALEED",
  "ALEEN",
  "ALEIT",
  "ALEJA",
  "ALEJE",
  "ALEJI",
  "ALEJO",
  "ALEJU",
  "ALEKA",
  "ALEKE",
  "ALEKI",
  "ALEKO",
  "ALEKS",
  "ALEKU",
  "ALELA",
  "ALELE",
  "ALELI",
  "ALELU",
  "ALEMA",
  "ALEME",
  "ALEMI",
  "ALEMK",
  "ALEMU",
  "ALENA",
  "ALENC",
  "ALEND",
  "ALENE",
  "ALENI",
  "ALENK",
  "ALENO",
  "ALENU",
  "ALEPA",
  "ALEPU",
  "ALERS",
  "ALESH",
  "ALESI",
  "ALESS",
  "ALEŠA",
  "ALEŠE",
  "ALEŠI",
  "ALEŠK",
  "ALEŠO",
  "ALEŠU",
  "ALETA",
  "ALETE",
  "ALETI",
  "ALETO",
  "ALETT",
  "ALEUA",
  "ALEUE",
  "ALEUI",
  "ALEUU",
  "ALFAH",
  "ALFAM",
  "ALFIA",
  "ALFIE",
  "ALFIH",
  "ALFII",
  "ALFIO",
  "ALFIU",
  "ALFJA",
  "ALFJE",
  "ALFJI",
  "ALFJO",
  "ALFJU",
  "ALFOM",
  "ALFON",
  "ALFOV",
  "ALGAH",
  "ALGAM",
  "ALGAN",
  "ALGAR",
  "ALGAS",
  "ALGER",
  "ALGIT",
  "ALGOZ",
  "ALIAH",
  "ALIAJ",
  "ALIAM",
  "ALIAN",
  "ALIBI",
  "ALICA",
  "ALICE",
  "ALICI",
  "ALICK",
  "ALICO",
  "ALIČA",
  "ALIČE",
  "ALIČI",
  "ALIČU",
  "ALIDA",
  "ALIDE",
  "ALIDI",
  "ALIDO",
  "ALIFF",
  "ALIIH",
  "ALIJA",
  "ALIJE",
  "ALIJI",
  "ALIJO",
  "ALIJU",
  "ALIKA",
  "ALIKE",
  "ALIKI",
  "ALIKO",
  "ALIKU",
  "ALILA",
  "ALILE",
  "ALILI",
  "ALILU",
  "ALIMA",
  "ALIME",
  "ALIMI",
  "ALIMU",
  "ALINA",
  "ALINE",
  "ALINI",
  "ALINO",
  "ALINU",
  "ALIOM",
  "ALION",
  "ALIOV",
  "ALISA",
  "ALISE",
  "ALISH",
  "ALISI",
  "ALISO",
  "ALISS",
  "ALISU",
  "ALIŠA",
  "ALIŠE",
  "ALIŠI",
  "ALIŠO",
  "ALITA",
  "ALITE",
  "ALITI",
  "ALITO",
  "ALITU",
  "ALIZA",
  "ALIZE",
  "ALIZI",
  "ALIZO",
  "ALJAF",
  "ALJAH",
  "ALJAM",
  "ALJAN",
  "ALJAŽ",
  "ALJIN",
  "ALJON",
  "ALJOŠ",
  "ALJUŠ",
  "ALKAH",
  "ALKAM",
  "ALKAN",
  "ALKAR",
  "ALKEM",
  "ALKEN",
  "ALKET",
  "ALKEV",
  "ALKIH",
  "ALKIL",
  "ALKIM",
  "ALKIN",
  "ALKIS",
  "ALKJA",
  "ALKJU",
  "ALKOM",
  "ALKOV",
  "ALLAH",
  "ALLAM",
  "ALLAN",
  "ALLEN",
  "ALLER",
  "ALLIE",
  "ALLIH",
  "ALLIN",
  "ALLIR",
  "ALLJA",
  "ALLJU",
  "ALLOM",
  "ALLON",
  "ALLOV",
  "ALLUM",
  "ALMAH",
  "ALMAM",
  "ALMAN",
  "ALMAR",
  "ALMAŠ",
  "ALMAT",
  "ALMIL",
  "ALMIN",
  "ALMIR",
  "ALMON",
  "ALMUT",
  "ALOAH",
  "ALOAM",
  "ALOIN",
  "ALOIS",
  "ALOJA",
  "ALOJE",
  "ALOJI",
  "ALOJO",
  "ALOJZ",
  "ALOKA",
  "ALOKE",
  "ALOKI",
  "ALOKU",
  "ALOMA",
  "ALOME",
  "ALOMI",
  "ALOMO",
  "ALONA",
  "ALONE",
  "ALONI",
  "ALONN",
  "ALONO",
  "ALONU",
  "ALORA",
  "ALORE",
  "ALORI",
  "ALORO",
  "ALOTA",
  "ALOTU",
  "ALOVA",
  "ALOVE",
  "ALOVI",
  "ALOVO",
  "ALPAH",
  "ALPAK",
  "ALPAM",
  "ALPEN",
  "ALPER",
  "ALPHA",
  "ALPHE",
  "ALPHI",
  "ALPHO",
  "ALPHU",
  "ALPID",
  "ALPIH",
  "ALPJA",
  "ALPJU",
  "ALPOD",
  "ALPOM",
  "ALPOS",
  "ALPOV",
  "ALPRO",
  "ALRAN",
  "ALSIN",
  "ALSIP",
  "ALSOP",
  "ALSUP",
  "ALTAH",
  "ALTAJ",
  "ALTAM",
  "ALTAN",
  "ALTEL",
  "ALTEM",
  "ALTER",
  "ALTEV",
  "ALTIH",
  "ALTIN",
  "ALTJA",
  "ALTJU",
  "ALTOM",
  "ALTON",
  "ALTOV",
  "ALTUG",
  "ALTUN",
  "ALUKA",
  "ALUKE",
  "ALUKI",
  "ALUKO",
  "ALUKU",
  "ALULA",
  "ALULE",
  "ALULI",
  "ALULO",
  "ALUNA",
  "ALUNE",
  "ALUNI",
  "ALUNU",
  "ALUSH",
  "ALVAH",
  "ALVAM",
  "ALVAR",
  "ALVED",
  "ALVER",
  "ALVES",
  "ALVIH",
  "ALVIN",
  "ALVIR",
  "ALVJA",
  "ALVJU",
  "ALVOM",
  "ALVOV",
  "ALZAN",
  "ALŽAN",
  "ALŽIR",
  "AMADA",
  "AMADE",
  "AMADI",
  "AMADO",
  "AMADU",
  "AMAKA",
  "AMAKE",
  "AMAKI",
  "AMAKO",
  "AMALI",
  "AMALM",
  "AMAMI",
  "AMANA",
  "AMAND",
  "AMANE",
  "AMANI",
  "AMANN",
  "AMANO",
  "AMANU",
  "AMARA",
  "AMARE",
  "AMARI",
  "AMARO",
  "AMARU",
  "AMASI",
  "AMATA",
  "AMATE",
  "AMATI",
  "AMATO",
  "AMATU",
  "AMAVI",
  "AMBER",
  "AMBIK",
  "AMBIN",
  "AMBRA",
  "AMBRE",
  "AMBRI",
  "AMBRO",
  "AMBUR",
  "AMDAL",
  "AMEBA",
  "AMEBE",
  "AMEBI",
  "AMEBO",
  "AMEDI",
  "AMEEN",
  "AMEJV",
  "AMELA",
  "AMELD",
  "AMELE",
  "AMELI",
  "AMELK",
  "AMELN",
  "AMELO",
  "AMELU",
  "AMENA",
  "AMEND",
  "AMENE",
  "AMENI",
  "AMENT",
  "AMENU",
  "AMERI",
  "AMESA",
  "AMESE",
  "AMESI",
  "AMESS",
  "AMESU",
  "AMETI",
  "AMFOR",
  "AMFTA",
  "AMFTE",
  "AMFTI",
  "AMFTU",
  "AMGEN",
  "AMIAN",
  "AMICI",
  "AMICK",
  "AMIDA",
  "AMIDE",
  "AMIDI",
  "AMIDU",
  "AMIEL",
  "AMIEZ",
  "AMIIN",
  "AMIJA",
  "AMIJE",
  "AMIJI",
  "AMIJO",
  "AMIKA",
  "AMIKE",
  "AMIKI",
  "AMIKO",
  "AMIKU",
  "AMILA",
  "AMILE",
  "AMILI",
  "AMILO",
  "AMILU",
  "AMINA",
  "AMINE",
  "AMINI",
  "AMINO",
  "AMINU",
  "AMIRA",
  "AMIRE",
  "AMIRI",
  "AMIRO",
  "AMISA",
  "AMISE",
  "AMISI",
  "AMISU",
  "AMIŠA",
  "AMIŠE",
  "AMIŠI",
  "AMIŠU",
  "AMITA",
  "AMITE",
  "AMITI",
  "AMITO",
  "AMITU",
  "AMJAD",
  "AMMAH",
  "AMMAM",
  "AMMAN",
  "AMMAR",
  "AMMIN",
  "AMMJA",
  "AMMJU",
  "AMMOM",
  "AMMON",
  "AMMOV",
  "AMNER",
  "AMNIJ",
  "AMNON",
  "AMOKA",
  "AMOKE",
  "AMOKI",
  "AMOKU",
  "AMOLA",
  "AMOLE",
  "AMOLI",
  "AMOLU",
  "AMONA",
  "AMOND",
  "AMONE",
  "AMONG",
  "AMONI",
  "AMONU",
  "AMORF",
  "AMOSA",
  "AMOSE",
  "AMOSI",
  "AMOSS",
  "AMOSU",
  "AMPAK",
  "AMPER",
  "AMPIL",
  "AMPUL",
  "AMRAH",
  "AMRAM",
  "AMREI",
  "AMRIH",
  "AMRIN",
  "AMRIT",
  "AMRJA",
  "AMRJU",
  "AMROM",
  "AMRON",
  "AMROP",
  "AMROV",
  "AMRUT",
  "AMSIH",
  "AMSJA",
  "AMSJU",
  "AMSOM",
  "AMSOV",
  "AMUND",
  "AMURA",
  "AMURE",
  "AMURI",
  "AMURO",
  "AMURU",
  "ANADA",
  "ANADE",
  "ANADI",
  "ANADO",
  "ANADU",
  "ANAHI",
  "ANAID",
  "ANAIS",
  "ANALA",
  "ANALE",
  "ANALI",
  "ANALO",
  "ANAMA",
  "ANAMI",
  "ANANA",
  "ANAND",
  "ANANE",
  "ANANG",
  "ANANI",
  "ANANU",
  "ANAPA",
  "ANAPE",
  "ANAPI",
  "ANAPO",
  "ANASA",
  "ANASE",
  "ANASI",
  "ANASU",
  "ANBAR",
  "ANBET",
  "ANCAH",
  "ANCAM",
  "ANCIN",
  "ANČAN",
  "ANČIČ",
  "ANČIK",
  "ANČKA",
  "ANČKE",
  "ANČKI",
  "ANČKO",
  "ANDAH",
  "ANDAM",
  "ANDAN",
  "ANDEE",
  "ANDER",
  "ANDIA",
  "ANDIE",
  "ANDIH",
  "ANDII",
  "ANDIJ",
  "ANDIN",
  "ANDIO",
  "ANDIS",
  "ANDJA",
  "ANDJU",
  "ANDOL",
  "ANDOM",
  "ANDON",
  "ANDOR",
  "ANDOV",
  "ANDRA",
  "ANDRE",
  "ANDRI",
  "ANDRO",
  "ANDRU",
  "ANEIL",
  "ANEJA",
  "ANEJE",
  "ANEJI",
  "ANEJO",
  "ANEJU",
  "ANEKS",
  "ANELA",
  "ANELD",
  "ANELE",
  "ANELI",
  "ANELK",
  "ANELO",
  "ANELU",
  "ANERI",
  "ANESA",
  "ANESE",
  "ANESI",
  "ANESS",
  "ANESU",
  "ANETA",
  "ANETE",
  "ANETI",
  "ANETO",
  "ANETT",
  "ANEZA",
  "ANEZE",
  "ANEZI",
  "ANEZU",
  "ANEŽK",
  "ANGAS",
  "ANGEA",
  "ANGEE",
  "ANGEI",
  "ANGEL",
  "ANGER",
  "ANGEU",
  "ANGIE",
  "ANGIH",
  "ANGIN",
  "ANGJA",
  "ANGJU",
  "ANGLA",
  "ANGLE",
  "ANGLI",
  "ANGLU",
  "ANGOM",
  "ANGOV",
  "ANGST",
  "ANGUS",
  "ANHIZ",
  "ANIAH",
  "ANIAM",
  "ANIAN",
  "ANICA",
  "ANICE",
  "ANICI",
  "ANICO",
  "ANIEK",
  "ANIEL",
  "ANIJA",
  "ANIJE",
  "ANIJI",
  "ANIJU",
  "ANIKA",
  "ANIKE",
  "ANIKI",
  "ANIKK",
  "ANIKO",
  "ANILA",
  "ANILE",
  "ANILI",
  "ANILO",
  "ANILU",
  "ANIMA",
  "ANIME",
  "ANIMI",
  "ANIMO",
  "ANINA",
  "ANINE",
  "ANINI",
  "ANINK",
  "ANINO",
  "ANION",
  "ANISA",
  "ANISE",
  "ANISH",
  "ANISI",
  "ANISO",
  "ANISS",
  "ANISU",
  "ANITA",
  "ANITE",
  "ANITI",
  "ANITO",
  "ANIŽA",
  "ANIŽE",
  "ANIŽI",
  "ANIŽO",
  "ANJAH",
  "ANJAM",
  "ANJAN",
  "ANJEM",
  "ANJEV",
  "ANJEZ",
  "ANJIH",
  "ANJIN",
  "ANJJA",
  "ANJJU",
  "ANJOM",
  "ANJOV",
  "ANKAH",
  "ANKAM",
  "ANKER",
  "ANKET",
  "ANKIC",
  "ANKIE",
  "ANKIH",
  "ANKIN",
  "ANKIT",
  "ANKJA",
  "ANKJU",
  "ANKOM",
  "ANKON",
  "ANKOV",
  "ANLOS",
  "ANNAH",
  "ANNAM",
  "ANNAN",
  "ANNDI",
  "ANNEK",
  "ANNEN",
  "ANNIC",
  "ANNIE",
  "ANNIH",
  "ANNIK",
  "ANNIN",
  "ANNIS",
  "ANNIT",
  "ANNJA",
  "ANNJU",
  "ANNOM",
  "ANNOV",
  "ANODA",
  "ANODE",
  "ANODI",
  "ANODO",
  "ANONA",
  "ANONE",
  "ANONI",
  "ANONO",
  "ANONS",
  "ANOUK",
  "ANOVE",
  "ANRAK",
  "ANSAR",
  "ANSHU",
  "ANSIN",
  "ANSIP",
  "ANSJU",
  "ANSON",
  "ANSSI",
  "ANŠBA",
  "ANŠBE",
  "ANŠBI",
  "ANŠBO",
  "ANŠBU",
  "ANTAJ",
  "ANTAL",
  "ANTAR",
  "ANTEA",
  "ANTEE",
  "ANTEI",
  "ANTEJ",
  "ANTEN",
  "ANTEO",
  "ANTIC",
  "ANTIČ",
  "ANTIH",
  "ANTIN",
  "ANTIP",
  "ANTIŠ",
  "ANTJA",
  "ANTJE",
  "ANTJU",
  "ANTOM",
  "ANTON",
  "ANTOV",
  "ANTTI",
  "ANTUN",
  "ANUAR",
  "ANUJA",
  "ANUJE",
  "ANUJI",
  "ANUJU",
  "ANUSA",
  "ANUSE",
  "ANUSI",
  "ANUSU",
  "ANUŠA",
  "ANUŠE",
  "ANUŠI",
  "ANUŠK",
  "ANUŠO",
  "ANVAR",
  "ANVIT",
  "ANZUR",
  "ANŽAH",
  "ANŽAM",
  "ANŽEJ",
  "ANŽEL",
  "ANŽIČ",
  "ANŽIN",
  "ANŽJU",
  "ANŽUR",
  "AOIFE",
  "AONIH",
  "AONJA",
  "AONJU",
  "AONOM",
  "AONOV",
  "AORTA",
  "AORTE",
  "AORTI",
  "AORTO",
  "AOSTA",
  "AOSTE",
  "AOSTI",
  "AOSTO",
  "AOUNA",
  "AOUNE",
  "AOUNI",
  "AOUNU",
  "APAČA",
  "APAČE",
  "APAČI",
  "APAČU",
  "APAKA",
  "APAKE",
  "APAKI",
  "APAKU",
  "APARN",
  "APATA",
  "APATE",
  "APATH",
  "APATI",
  "APATU",
  "APEKS",
  "APELA",
  "APELE",
  "APELI",
  "APELT",
  "APELU",
  "APERT",
  "APFEL",
  "APFLA",
  "APFLE",
  "APFLI",
  "APFLU",
  "APGAR",
  "APHER",
  "APHRA",
  "APHRE",
  "APHRI",
  "APHRO",
  "APIAN",
  "APICK",
  "APIHA",
  "APIHE",
  "APIHI",
  "APIHU",
  "APLIN",
  "APLIT",
  "APNAH",
  "APNAM",
  "APNAR",
  "APNAT",
  "APNEČ",
  "APNEJ",
  "APNEL",
  "APNEN",
  "APNET",
  "APNEU",
  "APNIC",
  "APNIH",
  "APNIK",
  "APNIL",
  "APNIM",
  "APNIŠ",
  "APNIT",
  "APNIU",
  "APNIV",
  "APNOA",
  "APNOE",
  "APNOI",
  "APNOJ",
  "APNOM",
  "APNOO",
  "APOEL",
  "APOEN",
  "APOTA",
  "APPEL",
  "APPEN",
  "APPLA",
  "APPLE",
  "APPLI",
  "APPLU",
  "APPNA",
  "APPNE",
  "APPNI",
  "APPNU",
  "APPSA",
  "APPSE",
  "APPSI",
  "APPSU",
  "APRIL",
  "APSID",
  "APTED",
  "APTER",
  "APTIN",
  "APTIV",
  "APTOS",
  "ARABA",
  "ARABE",
  "ARABI",
  "ARABK",
  "ARABO",
  "ARADE",
  "ARAGO",
  "ARAHA",
  "ARAHE",
  "ARAHI",
  "ARAHU",
  "ARAJS",
  "ARAJU",
  "ARAKA",
  "ARAKI",
  "ARAKU",
  "ARALA",
  "ARALE",
  "ARALI",
  "ARALU",
  "ARAMA",
  "ARAME",
  "ARAMI",
  "ARAMU",
  "ARANA",
  "ARAND",
  "ARANE",
  "ARANI",
  "ARANU",
  "ARAPI",
  "ARASA",
  "ARASE",
  "ARASH",
  "ARASI",
  "ARASU",
  "ARATA",
  "ARATE",
  "ARATI",
  "ARATO",
  "ARATU",
  "ARAUA",
  "ARAUD",
  "ARAUE",
  "ARAUI",
  "ARAUU",
  "ARAUZ",
  "ARAVS",
  "ARAZI",
  "ARBAN",
  "ARBEN",
  "ARBIB",
  "ARBID",
  "ARBON",
  "ARBOR",
  "ARBRA",
  "ARBRE",
  "ARBRI",
  "ARBRU",
  "ARBUN",
  "ARBUS",
  "ARCAN",
  "ARCEL",
  "ARCET",
  "ARČAN",
  "ARČON",
  "ARDAH",
  "ARDAM",
  "ARDAN",
  "ARDEL",
  "ARDEM",
  "ARDEN",
  "ARDIH",
  "ARDIL",
  "ARDIN",
  "ARDIS",
  "ARDIT",
  "ARDJA",
  "ARDJU",
  "ARDNA",
  "ARDNE",
  "ARDNI",
  "ARDNU",
  "ARDOM",
  "ARDOV",
  "ARDRO",
  "AREAL",
  "AREAN",
  "AREHA",
  "AREHE",
  "AREHI",
  "AREHN",
  "AREHU",
  "AREJA",
  "AREJU",
  "AREMA",
  "AREME",
  "AREMI",
  "AREMO",
  "ARENA",
  "ARENC",
  "AREND",
  "ARENE",
  "ARENI",
  "ARENO",
  "ARENS",
  "ARENT",
  "ARENU",
  "AREOL",
  "AREOV",
  "ARESA",
  "ARESE",
  "ARESI",
  "ARESO",
  "AREST",
  "ARESU",
  "ARETA",
  "ARETE",
  "ARETH",
  "ARETI",
  "ARETO",
  "AREVA",
  "AREVE",
  "AREVI",
  "AREVO",
  "AREZE",
  "ARFAN",
  "ARFIB",
  "ARFIN",
  "ARFTA",
  "ARFTE",
  "ARFTI",
  "ARFTU",
  "ARGAR",
  "ARGIR",
  "ARGIT",
  "ARGON",
  "ARGOS",
  "ARGOV",
  "ARGUN",
  "ARGUS",
  "ARHAR",
  "ARHEJ",
  "ARHIH",
  "ARHIM",
  "ARHIN",
  "ARHIV",
  "ARHJA",
  "ARHJU",
  "ARHOM",
  "ARHOV",
  "ARIAH",
  "ARIAL",
  "ARIAM",
  "ARIAN",
  "ARIAS",
  "ARIBA",
  "ARIBE",
  "ARIBI",
  "ARIBO",
  "ARIBU",
  "ARICA",
  "ARICE",
  "ARICI",
  "ARICU",
  "ARIEL",
  "ARIES",
  "ARIFA",
  "ARIFE",
  "ARIFI",
  "ARIFO",
  "ARIFU",
  "ARIHA",
  "ARIHE",
  "ARIHI",
  "ARIHU",
  "ARIJA",
  "ARIJE",
  "ARIJI",
  "ARIJK",
  "ARIJO",
  "ARIJU",
  "ARIKA",
  "ARIKE",
  "ARIKI",
  "ARIKU",
  "ARILD",
  "ARINA",
  "ARINE",
  "ARINI",
  "ARINO",
  "ARINU",
  "ARION",
  "ARISA",
  "ARISE",
  "ARISI",
  "ARISO",
  "ARISS",
  "ARISU",
  "ARITA",
  "ARITE",
  "ARITI",
  "ARITO",
  "ARITU",
  "ARIZA",
  "ARIZE",
  "ARIZI",
  "ARIZO",
  "ARIZU",
  "ARJAN",
  "ARJEN",
  "ARJET",
  "ARJOL",
  "ARJUN",
  "ARKAD",
  "ARKAN",
  "ARKAR",
  "ARKAS",
  "ARKIE",
  "ARKIH",
  "ARKIN",
  "ARKJA",
  "ARKJU",
  "ARKOM",
  "ARKOV",
  "ARKSI",
  "ARLAK",
  "ARLEA",
  "ARLEE",
  "ARLEI",
  "ARLEN",
  "ARLEO",
  "ARLES",
  "ARLET",
  "ARLEU",
  "ARLIČ",
  "ARLIE",
  "ARLIH",
  "ARLIN",
  "ARLJA",
  "ARLJU",
  "ARLOM",
  "ARLON",
  "ARLOV",
  "ARLTA",
  "ARLTE",
  "ARLTI",
  "ARLTU",
  "ARMAD",
  "ARMAL",
  "ARMAN",
  "ARMAS",
  "ARMAT",
  "ARMEN",
  "ARMES",
  "ARMIČ",
  "ARMIE",
  "ARMIJ",
  "ARMIN",
  "ARMIT",
  "ARMSA",
  "ARMSE",
  "ARMSI",
  "ARMSU",
  "ARMUŠ",
  "ARNAČ",
  "ARNAH",
  "ARNAL",
  "ARNAM",
  "ARNAS",
  "ARNDA",
  "ARNDE",
  "ARNDI",
  "ARNDT",
  "ARNDU",
  "ARNEJ",
  "ARNEL",
  "ARNES",
  "ARNEŽ",
  "ARNIČ",
  "ARNIE",
  "ARNIH",
  "ARNIK",
  "ARNIM",
  "ARNIN",
  "ARNIS",
  "ARNIT",
  "ARNJA",
  "ARNJU",
  "ARNOL",
  "ARNOM",
  "ARNON",
  "ARNOV",
  "ARNSA",
  "ARNSE",
  "ARNSI",
  "ARNST",
  "ARNSU",
  "ARNTA",
  "ARNTE",
  "ARNTI",
  "ARNTU",
  "ARNUG",
  "ARNUŠ",
  "ARNUŽ",
  "AROHA",
  "AROHE",
  "AROHI",
  "AROHO",
  "AROLA",
  "AROLE",
  "AROLI",
  "AROLO",
  "AROLU",
  "AROMA",
  "AROME",
  "AROMI",
  "AROMO",
  "ARONA",
  "ARONE",
  "ARONI",
  "ARONO",
  "ARONS",
  "ARONU",
  "ARORA",
  "ARORE",
  "ARORI",
  "ARORO",
  "ARORU",
  "AROSA",
  "AROSE",
  "AROSI",
  "AROSO",
  "AROTA",
  "AROTU",
  "AROVA",
  "AROVE",
  "AROVI",
  "AROVO",
  "ARPAD",
  "ARPIH",
  "ARPJA",
  "ARPJU",
  "ARPOM",
  "ARPOV",
  "ARRAN",
  "ARRAS",
  "ARRJU",
  "ARRON",
  "ARRUD",
  "ARSAN",
  "ARSEN",
  "ARSHI",
  "ARSHT",
  "ARSIČ",
  "ARSIH",
  "ARSIM",
  "ARSJA",
  "ARSJU",
  "ARSNA",
  "ARSNE",
  "ARSNI",
  "ARSNU",
  "ARSOM",
  "ARSON",
  "ARSOV",
  "ARŠIČ",
  "ARŠIN",
  "ARTAČ",
  "ARTAH",
  "ARTAM",
  "ARTAN",
  "ARTEM",
  "ARTES",
  "ARTET",
  "ARTIČ",
  "ARTIE",
  "ARTIH",
  "ARTIM",
  "ARTIN",
  "ARTIS",
  "ARTJA",
  "ARTJU",
  "ARTOM",
  "ARTON",
  "ARTOV",
  "ARTSA",
  "ARTSU",
  "ARTTI",
  "ARTTU",
  "ARTUR",
  "ARTUS",
  "ARUBA",
  "ARUBE",
  "ARUBI",
  "ARUBO",
  "ARUJA",
  "ARUJE",
  "ARUJI",
  "ARUJU",
  "ARULA",
  "ARULE",
  "ARULF",
  "ARULI",
  "ARULO",
  "ARULU",
  "ARUMA",
  "ARUME",
  "ARUMI",
  "ARUMO",
  "ARUMU",
  "ARUNA",
  "ARUNE",
  "ARUNI",
  "ARUNO",
  "ARUNU",
  "ARURI",
  "ARUTT",
  "ARVAD",
  "ARVAJ",
  "ARVAN",
  "ARVEN",
  "ARVIA",
  "ARVID",
  "ARVIH",
  "ARVIN",
  "ARVIO",
  "ARVIU",
  "ARVJA",
  "ARVJU",
  "ARVOM",
  "ARVOV",
  "ARZAH",
  "ARZAK",
  "ARZAM",
  "ARZEN",
  "ARZIH",
  "ARZJA",
  "ARZJU",
  "ARZOM",
  "ARZOV",
  "ASABI",
  "ASADA",
  "ASADE",
  "ASADI",
  "ASADU",
  "ASAEL",
  "ASAFA",
  "ASAFE",
  "ASAFI",
  "ASAFU",
  "ASAHI",
  "ASAJA",
  "ASAJE",
  "ASAJI",
  "ASAJU",
  "ASAKI",
  "ASAMA",
  "ASAME",
  "ASAMI",
  "ASAMU",
  "ASANA",
  "ASANE",
  "ASANI",
  "ASANO",
  "ASANU",
  "ASARA",
  "ASARE",
  "ASARI",
  "ASARO",
  "ASARU",
  "ASBIS",
  "ASCHA",
  "ASCHE",
  "ASCHI",
  "ASCHU",
  "ASCII",
  "ASCOM",
  "ASDIN",
  "ASEAN",
  "ASEEM",
  "ASEFI",
  "ASENA",
  "ASENE",
  "ASENI",
  "ASENO",
  "ASEOV",
  "ASEPS",
  "ASESA",
  "ASESU",
  "ASEVA",
  "ASEVE",
  "ASEVI",
  "ASEVO",
  "ASGAR",
  "ASGER",
  "ASHEM",
  "ASHER",
  "ASHEV",
  "ASHIH",
  "ASHJA",
  "ASHJU",
  "ASHLI",
  "ASHOK",
  "ASHOM",
  "ASHOV",
  "ASIAH",
  "ASIAM",
  "ASIAN",
  "ASICA",
  "ASICE",
  "ASICI",
  "ASICO",
  "ASICS",
  "ASIER",
  "ASIFA",
  "ASIFE",
  "ASIFI",
  "ASIFO",
  "ASIFU",
  "ASIMA",
  "ASIME",
  "ASIMI",
  "ASIMU",
  "ASINA",
  "ASINE",
  "ASINI",
  "ASINO",
  "ASIRK",
  "ASIST",
  "ASJAH",
  "ASJAM",
  "ASJIN",
  "ASKAM",
  "ASKAR",
  "ASKET",
  "ASKEZ",
  "ASKIN",
  "ASKIT",
  "ASKJA",
  "ASKJU",
  "ASKOM",
  "ASKOV",
  "ASLAG",
  "ASLAM",
  "ASLAN",
  "ASLEF",
  "ASLIM",
  "ASLIN",
  "ASMAH",
  "ASMAM",
  "ASMAN",
  "ASMAR",
  "ASMIK",
  "ASMIN",
  "ASMIR",
  "ASMUS",
  "ASNER",
  "ASOLA",
  "ASOLE",
  "ASOLI",
  "ASOLO",
  "ASOMA",
  "ASOTA",
  "ASOTU",
  "ASOVA",
  "ASOVE",
  "ASOVI",
  "ASOVO",
  "ASPAS",
  "ASPEN",
  "ASPIH",
  "ASPIK",
  "ASPJA",
  "ASPJU",
  "ASPNA",
  "ASPNU",
  "ASPOM",
  "ASPOV",
  "ASRAK",
  "ASSAF",
  "ASSET",
  "ASSIA",
  "ASSIE",
  "ASSII",
  "ASSIJ",
  "ASSIO",
  "ASSIS",
  "ASSIT",
  "ASSIU",
  "ASSUR",
  "ASTAH",
  "ASTAL",
  "ASTAM",
  "ASTAT",
  "ASTEC",
  "ASTER",
  "ASTIN",
  "ASTLA",
  "ASTLE",
  "ASTLI",
  "ASTLU",
  "ASTMA",
  "ASTME",
  "ASTMI",
  "ASTMO",
  "ASTOK",
  "ASTON",
  "ASTOR",
  "ASTRA",
  "ASTRE",
  "ASTRI",
  "ASTRO",
  "ASTRU",
  "ASUAN",
  "ASUMI",
  "ASUSA",
  "ASUSU",
  "ASVEL",
  "AŠČIČ",
  "AŠDOD",
  "AŠEMA",
  "AŠEVA",
  "AŠEVE",
  "AŠEVI",
  "AŠEVO",
  "AŠIČA",
  "AŠIČE",
  "AŠIČI",
  "AŠIČU",
  "AŠNER",
  "AŠOMA",
  "AŠOTA",
  "AŠOTU",
  "AŠOVA",
  "AŠOVE",
  "AŠOVI",
  "AŠOVO",
  "AŠRAF",
  "AŠRAM",
  "ATACK",
  "ATAGA",
  "ATAGE",
  "ATAGI",
  "ATAGO",
  "ATAGU",
  "ATAKA",
  "ATAKE",
  "ATAKI",
  "ATAKO",
  "ATALA",
  "ATALE",
  "ATALI",
  "ATALO",
  "ATALU",
  "ATAMA",
  "ATAME",
  "ATAMI",
  "ATAMO",
  "ATANA",
  "ATANE",
  "ATANI",
  "ATANU",
  "ATARI",
  "ATAŠE",
  "ATDHE",
  "ATECH",
  "ATEJA",
  "ATEJE",
  "ATEJI",
  "ATEJU",
  "ATENA",
  "ATENE",
  "ATENI",
  "ATENK",
  "ATENO",
  "ATEOV",
  "ATESA",
  "ATESE",
  "ATESI",
  "ATEST",
  "ATESU",
  "ATEVA",
  "ATEVE",
  "ATEVI",
  "ATEVO",
  "ATHEN",
  "ATHIE",
  "ATHIN",
  "ATHOL",
  "ATIAN",
  "ATIAS",
  "ATIBA",
  "ATIBE",
  "ATIBI",
  "ATIBO",
  "ATIBU",
  "ATIDA",
  "ATIDE",
  "ATIDI",
  "ATIDU",
  "ATIFA",
  "ATIFE",
  "ATIFI",
  "ATIFO",
  "ATIFU",
  "ATIJA",
  "ATIJE",
  "ATIJI",
  "ATIJU",
  "ATIKA",
  "ATIKE",
  "ATIKI",
  "ATIKO",
  "ATILA",
  "ATILE",
  "ATILI",
  "ATILL",
  "ATILO",
  "ATILU",
  "ATIMA",
  "ATIME",
  "ATIMI",
  "ATIMU",
  "ATINA",
  "ATINE",
  "ATINI",
  "ATINO",
  "ATKEM",
  "ATKEV",
  "ATKIH",
  "ATKIN",
  "ATKJA",
  "ATKJU",
  "ATKOM",
  "ATKOV",
  "ATLAN",
  "ATLAS",
  "ATLEE",
  "ATLET",
  "ATLIJ",
  "ATLIT",
  "ATMOS",
  "ATOLA",
  "ATOLE",
  "ATOLI",
  "ATOLU",
  "ATOMA",
  "ATOME",
  "ATOMI",
  "ATOMS",
  "ATOMU",
  "ATOSA",
  "ATOSU",
  "ATOTA",
  "ATOTU",
  "ATOVA",
  "ATOVE",
  "ATOVI",
  "ATOVO",
  "ATRAN",
  "ATRAP",
  "ATREJ",
  "ATRIJ",
  "ATSJU",
  "ATTAH",
  "ATTAL",
  "ATTAM",
  "ATTIH",
  "ATTIL",
  "ATTJA",
  "ATTJU",
  "ATTOM",
  "ATTOV",
  "ATULA",
  "ATULE",
  "ATULI",
  "ATULU",
  "ATUMA",
  "ATUMU",
  "AUBEL",
  "AUBIN",
  "AUDEL",
  "AUDEN",
  "AUDER",
  "AUDIČ",
  "AUDIE",
  "AUDIH",
  "AUDIT",
  "AUDJA",
  "AUDJU",
  "AUDNA",
  "AUDNE",
  "AUDNI",
  "AUDNU",
  "AUDOM",
  "AUDON",
  "AUDOV",
  "AUDRA",
  "AUDRE",
  "AUDRI",
  "AUDRO",
  "AUDUN",
  "AUENA",
  "AUENE",
  "AUENI",
  "AUENU",
  "AUGER",
  "AUGIE",
  "AUKES",
  "AUKIN",
  "AUKJE",
  "AUKUS",
  "AULAR",
  "AULDA",
  "AULDE",
  "AULDI",
  "AULDU",
  "AULIE",
  "AULIN",
  "AULIS",
  "AULLA",
  "AULLE",
  "AULLI",
  "AULLU",
  "AULON",
  "AULTA",
  "AULTE",
  "AULTI",
  "AULTU",
  "AUNER",
  "AUPIČ",
  "AUREL",
  "AURIC",
  "AURIK",
  "AURIN",
  "AUROR",
  "AUSCA",
  "AUSCE",
  "AUSCI",
  "AUSCU",
  "AUSEC",
  "AUSTA",
  "AUSTE",
  "AUSTI",
  "AUSTU",
  "AUTEN",
  "AUTET",
  "AUTHA",
  "AUTHE",
  "AUTHI",
  "AUTHU",
  "AUTIA",
  "AUTIE",
  "AUTIH",
  "AUTII",
  "AUTIN",
  "AUTIO",
  "AUTIU",
  "AUTJA",
  "AUTJU",
  "AUTNA",
  "AUTNE",
  "AUTNI",
  "AUTNU",
  "AUTOM",
  "AUTON",
  "AUTOR",
  "AUTOV",
  "AVAKA",
  "AVAKE",
  "AVAKI",
  "AVAKU",
  "AVALA",
  "AVALE",
  "AVALI",
  "AVALU",
  "AVAMA",
  "AVAMI",
  "AVANS",
  "AVANT",
  "AVARA",
  "AVARE",
  "AVARI",
  "AVARK",
  "AVARU",
  "AVATI",
  "AVAZA",
  "AVAZU",
  "AVBAH",
  "AVBAM",
  "AVBAR",
  "AVBER",
  "AVBIC",
  "AVČAH",
  "AVČAM",
  "AVČAN",
  "AVČIN",
  "AVDIA",
  "AVDIČ",
  "AVDIE",
  "AVDIH",
  "AVDII",
  "AVDIJ",
  "AVDIO",
  "AVDIU",
  "AVDJA",
  "AVDJU",
  "AVDOM",
  "AVDOV",
  "AVELL",
  "AVENI",
  "AVENT",
  "AVERZ",
  "AVEST",
  "AVGAH",
  "AVGAM",
  "AVGIJ",
  "AVGIN",
  "AVGIT",
  "AVGUR",
  "AVIAT",
  "AVIJA",
  "AVIJE",
  "AVIJI",
  "AVIJU",
  "AVIKS",
  "AVILA",
  "AVILE",
  "AVILI",
  "AVILO",
  "AVILU",
  "AVINA",
  "AVINE",
  "AVINI",
  "AVINO",
  "AVION",
  "AVISA",
  "AVISU",
  "AVIVA",
  "AVIVE",
  "AVIVI",
  "AVIVO",
  "AVIVU",
  "AVIZA",
  "AVIZE",
  "AVIZI",
  "AVIZO",
  "AVIZU",
  "AVLAH",
  "AVLAM",
  "AVLON",
  "AVNET",
  "AVNOJ",
  "AVONA",
  "AVONE",
  "AVONI",
  "AVONU",
  "AVOSS",
  "AVRAH",
  "AVRAM",
  "AVRIL",
  "AVSAR",
  "AVSCA",
  "AVSCE",
  "AVSCI",
  "AVSCU",
  "AVSEC",
  "AVSTR",
  "AVŠAH",
  "AVŠAM",
  "AVŠAN",
  "AVŠIČ",
  "AVŠIN",
  "AVŠKA",
  "AVŠKE",
  "AVŠKI",
  "AVŠKO",
  "AVTEK",
  "AVTEM",
  "AVTEV",
  "AVTIH",
  "AVTJA",
  "AVTJU",
  "AVTKA",
  "AVTKE",
  "AVTKI",
  "AVTKU",
  "AVTOM",
  "AVTOR",
  "AVTOV",
  "AZADA",
  "AZADE",
  "AZADI",
  "AZADU",
  "AZALE",
  "AZAMA",
  "AZAME",
  "AZAMI",
  "AZAMU",
  "AZARI",
  "AZASA",
  "AZASE",
  "AZASI",
  "AZASU",
  "AZEKA",
  "AZEKE",
  "AZEKI",
  "AZEKO",
  "AZELA",
  "AZELE",
  "AZELI",
  "AZELO",
  "AZEMA",
  "AZEME",
  "AZEMI",
  "AZEMU",
  "AZGAN",
  "AZIAT",
  "AZIDA",
  "AZIDE",
  "AZIDI",
  "AZIDU",
  "AZIJA",
  "AZIJE",
  "AZIJI",
  "AZIJK",
  "AZIJO",
  "AZILA",
  "AZILE",
  "AZILI",
  "AZILU",
  "AZIMA",
  "AZIME",
  "AZIMI",
  "AZIMU",
  "AZIRI",
  "AZITA",
  "AZITE",
  "AZITI",
  "AZITO",
  "AZIZA",
  "AZIZE",
  "AZIZI",
  "AZIZO",
  "AZIZU",
  "AZMIN",
  "AZNAR",
  "AZOFF",
  "AZOIK",
  "AZOLA",
  "AZOLE",
  "AZOLI",
  "AZOLO",
  "AZORE",
  "AZORI",
  "AZOTA",
  "AZOTU",
  "AZOVA",
  "AZOVU",
  "AZRAH",
  "AZRAM",
  "AZRIN",
  "AZTEK",
  "AZUEL",
  "AZUMA",
  "AZUME",
  "AZUMI",
  "AZUMO",
  "AZUMU",
  "AZURA",
  "AZURE",
  "AZURI",
  "AZURO",
  "AZZAR",
  "AŽMAN",
  "AŽNIK",
  "AŽNOH",
  "AŽURA",
  "AŽURU",
  "BAABA",
  "BAABE",
  "BAABI",
  "BAABU",
  "BAACK",
  "BAADE",
  "BAAKE",
  "BAALA",
  "BAALE",
  "BAALI",
  "BAALU",
  "BAANA",
  "BAANE",
  "BAANI",
  "BAANU",
  "BAASA",
  "BAASE",
  "BAASI",
  "BAASU",
  "BABAH",
  "BABAJ",
  "BABAK",
  "BABAM",
  "BABAN",
  "BABBA",
  "BABBE",
  "BABBI",
  "BABBU",
  "BABEŽ",
  "BABIC",
  "BABIČ",
  "BABIJ",
  "BABIN",
  "BABIŠ",
  "BABJA",
  "BABJE",
  "BABJI",
  "BABJO",
  "BABJU",
  "BABKA",
  "BABKE",
  "BABKI",
  "BABKO",
  "BABNA",
  "BABNE",
  "BABNI",
  "BABNO",
  "BABOŠ",
  "BABSA",
  "BABSE",
  "BABSI",
  "BABSU",
  "BABŠE",
  "BABUR",
  "BACAJ",
  "BACAL",
  "BACCI",
  "BACEK",
  "BACHA",
  "BACHE",
  "BACHI",
  "BACHU",
  "BACIL",
  "BACKA",
  "BACKE",
  "BACKI",
  "BACKO",
  "BACKU",
  "BACON",
  "BAČAN",
  "BAČAR",
  "BAČEM",
  "BAČEN",
  "BAČIČ",
  "BAČIN",
  "BAČJA",
  "BAČJU",
  "BAČKA",
  "BAČKE",
  "BAČKI",
  "BAČKO",
  "BAČNE",
  "BAČOM",
  "BAČUN",
  "BADAL",
  "BADEN",
  "BADER",
  "BADIA",
  "BADIE",
  "BADII",
  "BADIN",
  "BADIO",
  "BADNA",
  "BADNE",
  "BADNI",
  "BADNU",
  "BADOS",
  "BADRA",
  "BADRE",
  "BADRI",
  "BADRU",
  "BADTA",
  "BADTE",
  "BADTI",
  "BADTU",
  "BADUM",
  "BADUR",
  "BAECK",
  "BAEHR",
  "BAEKA",
  "BAEKE",
  "BAEKI",
  "BAEKU",
  "BAENA",
  "BAENE",
  "BAENI",
  "BAENO",
  "BAENU",
  "BAERA",
  "BAERE",
  "BAERI",
  "BAERU",
  "BAETE",
  "BAETZ",
  "BAEZA",
  "BAEZE",
  "BAEZI",
  "BAEZU",
  "BAFFA",
  "BAFFE",
  "BAFFI",
  "BAFFU",
  "BAFIN",
  "BAFTA",
  "BAFTE",
  "BAFTI",
  "BAFTO",
  "BAGAR",
  "BAGEL",
  "BAGER",
  "BAGES",
  "BAGET",
  "BAGGA",
  "BAGGE",
  "BAGGI",
  "BAGGS",
  "BAGGU",
  "BAGHA",
  "BAGHE",
  "BAGHI",
  "BAGHU",
  "BAGIH",
  "BAGIS",
  "BAGJA",
  "BAGJU",
  "BAGNI",
  "BAGOL",
  "BAGOM",
  "BAGON",
  "BAGOV",
  "BAGRA",
  "BAGRE",
  "BAGRI",
  "BAGRU",
  "BAHAA",
  "BAHAČ",
  "BAHAE",
  "BAHAI",
  "BAHAJ",
  "BAHAL",
  "BAHAM",
  "BAHAO",
  "BAHAR",
  "BAHAŠ",
  "BAHAT",
  "BAHAU",
  "BAHAV",
  "BAHČA",
  "BAHČE",
  "BAHČI",
  "BAHČU",
  "BAHIA",
  "BAHIE",
  "BAHIH",
  "BAHII",
  "BAHIO",
  "BAHJA",
  "BAHJU",
  "BAHLA",
  "BAHLE",
  "BAHLI",
  "BAHLU",
  "BAHMA",
  "BAHME",
  "BAHMI",
  "BAHMU",
  "BAHNA",
  "BAHNS",
  "BAHNU",
  "BAHOM",
  "BAHOR",
  "BAHOV",
  "BAHRA",
  "BAHRE",
  "BAHRI",
  "BAHRU",
  "BAHSA",
  "BAHSE",
  "BAHSI",
  "BAHSU",
  "BAHTA",
  "BAHTE",
  "BAHTI",
  "BAHTU",
  "BAHUN",
  "BAIBA",
  "BAIBE",
  "BAIBI",
  "BAIBO",
  "BAICH",
  "BAIDU",
  "BAIEM",
  "BAIER",
  "BAIEV",
  "BAIGA",
  "BAIGE",
  "BAIGI",
  "BAIGU",
  "BAIIH",
  "BAIJA",
  "BAIJU",
  "BAILA",
  "BAILE",
  "BAILI",
  "BAILU",
  "BAINA",
  "BAINE",
  "BAINI",
  "BAINS",
  "BAINU",
  "BAIOM",
  "BAIOV",
  "BAIRA",
  "BAIRD",
  "BAIRE",
  "BAIRI",
  "BAIRU",
  "BAISA",
  "BAISE",
  "BAISI",
  "BAISU",
  "BAJAC",
  "BAJAG",
  "BAJAJ",
  "BAJAK",
  "BAJAL",
  "BAJAM",
  "BAJAN",
  "BAJAR",
  "BAJAŠ",
  "BAJAT",
  "BAJAU",
  "BAJAV",
  "BAJCA",
  "BAJCE",
  "BAJCI",
  "BAJCU",
  "BAJDA",
  "BAJDE",
  "BAJDI",
  "BAJDO",
  "BAJDU",
  "BAJEC",
  "BAJEN",
  "BAJER",
  "BAJIČ",
  "BAJKA",
  "BAJKE",
  "BAJKI",
  "BAJKO",
  "BAJKU",
  "BAJLA",
  "BAJLE",
  "BAJLI",
  "BAJLO",
  "BAJLU",
  "BAJNA",
  "BAJNE",
  "BAJNI",
  "BAJNO",
  "BAJON",
  "BAJOR",
  "BAJRA",
  "BAJRE",
  "BAJRI",
  "BAJRO",
  "BAJRU",
  "BAJSA",
  "BAJSE",
  "BAJSI",
  "BAJSO",
  "BAJSU",
  "BAJTA",
  "BAJTE",
  "BAJTI",
  "BAJTO",
  "BAJTU",
  "BAJUK",
  "BAJUL",
  "BAJZA",
  "BAJZE",
  "BAJZI",
  "BAJZO",
  "BAKAJ",
  "BAKAL",
  "BAKAN",
  "BAKAR",
  "BAKEL",
  "BAKER",
  "BAKIC",
  "BAKIČ",
  "BAKIH",
  "BAKIJ",
  "BAKIR",
  "BAKIU",
  "BAKJA",
  "BAKJU",
  "BAKKE",
  "BAKLA",
  "BAKLE",
  "BAKLI",
  "BAKLO",
  "BAKOM",
  "BAKOS",
  "BAKOŠ",
  "BAKOV",
  "BAKRA",
  "BAKRC",
  "BAKRE",
  "BAKRI",
  "BAKRO",
  "BAKRU",
  "BAKSA",
  "BAKSE",
  "BAKSI",
  "BAKSO",
  "BAKSU",
  "BAKUL",
  "BAKUN",
  "BALAD",
  "BALAH",
  "BALAJ",
  "BALAM",
  "BALAN",
  "BALAS",
  "BALAT",
  "BALAŽ",
  "BALBA",
  "BALBE",
  "BALBI",
  "BALBO",
  "BALBU",
  "BALCH",
  "BALCI",
  "BALCK",
  "BALDA",
  "BALDE",
  "BALDI",
  "BALDO",
  "BALDU",
  "BALEK",
  "BALEM",
  "BALEN",
  "BALER",
  "BALES",
  "BALET",
  "BALEV",
  "BALFA",
  "BALFE",
  "BALFI",
  "BALFU",
  "BALIČ",
  "BALIH",
  "BALIK",
  "BALIL",
  "BALIN",
  "BALIS",
  "BALIŠ",
  "BALIŽ",
  "BALJA",
  "BALJU",
  "BALKA",
  "BALKE",
  "BALKI",
  "BALKO",
  "BALKU",
  "BALLA",
  "BALLE",
  "BALLI",
  "BALLO",
  "BALLU",
  "BALMA",
  "BALME",
  "BALMI",
  "BALMU",
  "BALNA",
  "BALNE",
  "BALNI",
  "BALNU",
  "BALOG",
  "BALOH",
  "BALOM",
  "BALON",
  "BALOV",
  "BALPA",
  "BALPE",
  "BALPI",
  "BALPO",
  "BALPU",
  "BALSA",
  "BALSE",
  "BALSI",
  "BALSO",
  "BALSU",
  "BALŠA",
  "BALŠE",
  "BALŠI",
  "BALŠO",
  "BALŠU",
  "BALTA",
  "BALTE",
  "BALTI",
  "BALTO",
  "BALTU",
  "BALTZ",
  "BALUH",
  "BALUT",
  "BALZA",
  "BALZE",
  "BALZI",
  "BALZO",
  "BALZU",
  "BAMBA",
  "BAMBI",
  "BAMIH",
  "BAMIJ",
  "BAMIN",
  "BAMIR",
  "BAMJA",
  "BAMJU",
  "BAMOM",
  "BAMOV",
  "BAMPS",
  "BANAH",
  "BANAM",
  "BANAN",
  "BANAT",
  "BANCA",
  "BANCE",
  "BANCI",
  "BANCO",
  "BANDA",
  "BANDE",
  "BANDI",
  "BANDL",
  "BANDO",
  "BANDT",
  "BANDU",
  "BANEM",
  "BANES",
  "BANEV",
  "BANFF",
  "BANFI",
  "BANGA",
  "BANGE",
  "BANGI",
  "BANGO",
  "BANGS",
  "BANGU",
  "BANIC",
  "BANIČ",
  "BANIH",
  "BANIN",
  "BANJA",
  "BANJE",
  "BANJI",
  "BANJK",
  "BANJO",
  "BANJU",
  "BANKA",
  "BANKE",
  "BANKI",
  "BANKO",
  "BANKS",
  "BANKU",
  "BANOM",
  "BANON",
  "BANOV",
  "BANSA",
  "BANSE",
  "BANSI",
  "BANSU",
  "BANTI",
  "BANUS",
  "BARAB",
  "BARAČ",
  "BARAD",
  "BARAG",
  "BARAH",
  "BARAJ",
  "BARAK",
  "BARAL",
  "BARAM",
  "BARAN",
  "BARAT",
  "BARAŽ",
  "BARBA",
  "BARBE",
  "BARBI",
  "BARBK",
  "BARBO",
  "BARBU",
  "BARCA",
  "BARCE",
  "BARCI",
  "BARCO",
  "BARČA",
  "BARČE",
  "BARČI",
  "BARČU",
  "BARDA",
  "BARDE",
  "BARDH",
  "BARDI",
  "BARDO",
  "BARDU",
  "BAREČ",
  "BAREL",
  "BAREM",
  "BARER",
  "BARES",
  "BAREV",
  "BARFI",
  "BARGH",
  "BARIA",
  "BARIC",
  "BARIČ",
  "BARIE",
  "BARIH",
  "BARII",
  "BARIJ",
  "BARIK",
  "BARIL",
  "BARIM",
  "BARIN",
  "BARIO",
  "BARIŠ",
  "BARIT",
  "BARIU",
  "BARIV",
  "BARJA",
  "BARJE",
  "BARJI",
  "BARJU",
  "BARKA",
  "BARKE",
  "BARKI",
  "BARKO",
  "BARKS",
  "BARKU",
  "BARLA",
  "BARLE",
  "BARLI",
  "BARLU",
  "BARNA",
  "BARNE",
  "BARNI",
  "BARNO",
  "BARNS",
  "BARNU",
  "BARNZ",
  "BAROK",
  "BAROM",
  "BARON",
  "BAROŠ",
  "BAROV",
  "BARPI",
  "BARRA",
  "BARRE",
  "BARRI",
  "BARRO",
  "BARRS",
  "BARRU",
  "BARSA",
  "BARSE",
  "BARSI",
  "BARSS",
  "BARSU",
  "BARŠI",
  "BARTA",
  "BARTE",
  "BARTH",
  "BARTI",
  "BARTL",
  "BARTO",
  "BARTU",
  "BARTZ",
  "BARUC",
  "BARUD",
  "BARUM",
  "BARUN",
  "BARUT",
  "BARVA",
  "BARVE",
  "BARVI",
  "BARVO",
  "BARVU",
  "BARZA",
  "BARZE",
  "BARZI",
  "BARZU",
  "BARŽA",
  "BARŽE",
  "BARŽI",
  "BARŽO",
  "BASAJ",
  "BASAK",
  "BASAL",
  "BASAR",
  "BASAT",
  "BASAU",
  "BASAV",
  "BASCA",
  "BASCE",
  "BASCH",
  "BASCI",
  "BASCO",
  "BASCU",
  "BASEL",
  "BASEM",
  "BASEN",
  "BASER",
  "BASEV",
  "BASHA",
  "BASHE",
  "BASHI",
  "BASHO",
  "BASHU",
  "BASIA",
  "BASIE",
  "BASIH",
  "BASII",
  "BASIJ",
  "BASIL",
  "BASIN",
  "BASIO",
  "BASIS",
  "BASJA",
  "BASJU",
  "BASKA",
  "BASKE",
  "BASKI",
  "BASKU",
  "BASLA",
  "BASLE",
  "BASLI",
  "BASLU",
  "BASNI",
  "BASOM",
  "BASOV",
  "BASRA",
  "BASRE",
  "BASRI",
  "BASRO",
  "BASSA",
  "BASSE",
  "BASSI",
  "BASSO",
  "BASSU",
  "BASTA",
  "BASTE",
  "BASTI",
  "BASTL",
  "BASTO",
  "BASTU",
  "BAŠAH",
  "BAŠAM",
  "BAŠAN",
  "BAŠAR",
  "BAŠEK",
  "BAŠEM",
  "BAŠEŠ",
  "BAŠEV",
  "BAŠIČ",
  "BAŠIH",
  "BAŠIN",
  "BAŠIR",
  "BAŠJA",
  "BAŠJU",
  "BAŠKA",
  "BAŠKE",
  "BAŠKI",
  "BAŠKO",
  "BAŠKU",
  "BAŠLA",
  "BAŠLE",
  "BAŠLI",
  "BAŠLU",
  "BAŠOČ",
  "BAŠOM",
  "BAŠOV",
  "BAŠTA",
  "BAŠTE",
  "BAŠTI",
  "BAŠTO",
  "BAŠTU",
  "BATAH",
  "BATAM",
  "BATAN",
  "BATAR",
  "BATAT",
  "BATEK",
  "BATEM",
  "BATEN",
  "BATES",
  "BATET",
  "BATEV",
  "BATHA",
  "BATHE",
  "BATHI",
  "BATHU",
  "BATIČ",
  "BATIH",
  "BATIK",
  "BATIN",
  "BATIS",
  "BATJA",
  "BATJU",
  "BATKA",
  "BATKE",
  "BATKI",
  "BATKU",
  "BATNA",
  "BATNE",
  "BATNI",
  "BATNO",
  "BATOM",
  "BATON",
  "BATOR",
  "BATOV",
  "BATTA",
  "BATTE",
  "BATTI",
  "BATTS",
  "BATTU",
  "BATUJ",
  "BATUL",
  "BATUM",
  "BATUR",
  "BATUT",
  "BAUDA",
  "BAUDE",
  "BAUDI",
  "BAUDO",
  "BAUDU",
  "BAUER",
  "BAUHA",
  "BAUHE",
  "BAUHI",
  "BAUHU",
  "BAUIH",
  "BAUJA",
  "BAUJU",
  "BAUKA",
  "BAUKE",
  "BAUKI",
  "BAUKS",
  "BAUKU",
  "BAULI",
  "BAUMA",
  "BAUME",
  "BAUMI",
  "BAUMU",
  "BAUNA",
  "BAUNE",
  "BAUNI",
  "BAUNU",
  "BAUOM",
  "BAUOV",
  "BAUSA",
  "BAUSE",
  "BAUSI",
  "BAUSU",
  "BAUTA",
  "BAUTE",
  "BAUTI",
  "BAUTO",
  "BAUTU",
  "BAUTZ",
  "BAVAN",
  "BAVCA",
  "BAVCE",
  "BAVCI",
  "BAVCU",
  "BAVČE",
  "BAVČI",
  "BAVEC",
  "BAVEČ",
  "BAVHA",
  "BAVHU",
  "BAVIL",
  "BAVIM",
  "BAVIŠ",
  "BAVIT",
  "BAVIU",
  "BAVIV",
  "BAVKA",
  "BAVKI",
  "BAVKU",
  "BAVLI",
  "BAVTA",
  "BAVTE",
  "BAVTI",
  "BAVTO",
  "BAZAH",
  "BAZAM",
  "BAZAN",
  "BAZAR",
  "BAZEN",
  "BAZIH",
  "BAZIL",
  "BAZIN",
  "BAZJA",
  "BAZJU",
  "BAZNA",
  "BAZNE",
  "BAZNI",
  "BAZNO",
  "BAZOM",
  "BAZOV",
  "BAZUK",
  "BAŽAH",
  "BAŽAM",
  "BAŽCA",
  "BAŽCE",
  "BAŽCI",
  "BAŽCU",
  "BAŽEC",
  "BAŽIK",
  "BBVJA",
  "BDEČA",
  "BDEČE",
  "BDEČI",
  "BDEČO",
  "BDELA",
  "BDELE",
  "BDELI",
  "BDELO",
  "BDENJ",
  "BDETI",
  "BDIJO",
  "BDIMA",
  "BDIME",
  "BDIMO",
  "BDITA",
  "BDITE",
  "BDIVA",
  "BDIVE",
  "BDIVI",
  "BEACH",
  "BEAHM",
  "BEAJU",
  "BEALA",
  "BEALE",
  "BEALI",
  "BEALL",
  "BEALS",
  "BEALU",
  "BEAMA",
  "BEAMI",
  "BEANA",
  "BEANE",
  "BEANI",
  "BEANS",
  "BEANU",
  "BEARA",
  "BEARD",
  "BEARE",
  "BEARI",
  "BEARO",
  "BEARS",
  "BEARU",
  "BEATA",
  "BEATE",
  "BEATH",
  "BEATI",
  "BEATO",
  "BEATS",
  "BEATU",
  "BEAUA",
  "BEAUE",
  "BEAUI",
  "BEAUU",
  "BEBAH",
  "BEBAM",
  "BEBAN",
  "BEBAR",
  "BEBAV",
  "BEBBA",
  "BEBBE",
  "BEBBI",
  "BEBBU",
  "BEBCA",
  "BEBCE",
  "BEBCI",
  "BEBCU",
  "BEBEC",
  "BEBEK",
  "BEBER",
  "BEBIH",
  "BEBIN",
  "BEBIT",
  "BEBJA",
  "BEBJU",
  "BEBKA",
  "BEBKE",
  "BEBKI",
  "BEBKO",
  "BEBOM",
  "BEBOP",
  "BEBOV",
  "BEBRA",
  "BEBRE",
  "BEBRI",
  "BEBRU",
  "BECCA",
  "BECCE",
  "BECCI",
  "BECCO",
  "BECHA",
  "BECHE",
  "BECHI",
  "BECHT",
  "BECHU",
  "BECIH",
  "BECJA",
  "BECJU",
  "BECKA",
  "BECKE",
  "BECKI",
  "BECKO",
  "BECKU",
  "BECOM",
  "BECOV",
  "BEČAJ",
  "BEČAN",
  "BEČEJ",
  "BEČEL",
  "BEČEM",
  "BEČIČ",
  "BEČJA",
  "BEČJU",
  "BEČOM",
  "BEDAH",
  "BEDAK",
  "BEDAM",
  "BEDEČ",
  "BEDEK",
  "BEDEL",
  "BEDEN",
  "BEDER",
  "BEDET",
  "BEDEU",
  "BEDIČ",
  "BEDIH",
  "BEDIM",
  "BEDIN",
  "BEDIŠ",
  "BEDJA",
  "BEDJU",
  "BEDNA",
  "BEDNE",
  "BEDNI",
  "BEDNO",
  "BEDOM",
  "BEDOV",
  "BEDRA",
  "BEDRC",
  "BEDRI",
  "BEDRN",
  "BEDRO",
  "BEDRU",
  "BEDŽA",
  "BEDŽE",
  "BEDŽI",
  "BEDŽU",
  "BEEBE",
  "BEECH",
  "BEECK",
  "BEEHA",
  "BEEHE",
  "BEEHI",
  "BEEHU",
  "BEEJA",
  "BEEJE",
  "BEEJI",
  "BEEJU",
  "BEEKA",
  "BEEKE",
  "BEEKI",
  "BEEKS",
  "BEEKU",
  "BEENA",
  "BEENE",
  "BEENI",
  "BEENO",
  "BEENU",
  "BEEOV",
  "BEERA",
  "BEERE",
  "BEERI",
  "BEERS",
  "BEERU",
  "BEEVA",
  "BEEVE",
  "BEEVI",
  "BEEVO",
  "BEFEL",
  "BEFIT",
  "BEFUS",
  "BEGAH",
  "BEGAJ",
  "BEGAL",
  "BEGAM",
  "BEGAN",
  "BEGAS",
  "BEGAŠ",
  "BEGAT",
  "BEGAU",
  "BEGAV",
  "BEGEM",
  "BEGER",
  "BEGEŠ",
  "BEGEV",
  "BEGGA",
  "BEGGE",
  "BEGGI",
  "BEGGS",
  "BEGGU",
  "BEGIC",
  "BEGIČ",
  "BEGIH",
  "BEGIN",
  "BEGJA",
  "BEGJU",
  "BEGOM",
  "BEGOV",
  "BEGUM",
  "BEGUN",
  "BEGUŠ",
  "BEHAN",
  "BEHAR",
  "BEHEK",
  "BEHER",
  "BEHIN",
  "BEHKA",
  "BEHKE",
  "BEHKI",
  "BEHKU",
  "BEHLA",
  "BEHLE",
  "BEHLI",
  "BEHLU",
  "BEHMA",
  "BEHME",
  "BEHMI",
  "BEHMU",
  "BEHNA",
  "BEHNE",
  "BEHNI",
  "BEHNU",
  "BEHRA",
  "BEHRE",
  "BEHRI",
  "BEHRS",
  "BEHRU",
  "BEIDA",
  "BEIDE",
  "BEIDI",
  "BEIDO",
  "BEIDU",
  "BEIER",
  "BEIKA",
  "BEIKE",
  "BEIKI",
  "BEIKO",
  "BEIKU",
  "BEILA",
  "BEILE",
  "BEILI",
  "BEILU",
  "BEINA",
  "BEINE",
  "BEINI",
  "BEINO",
  "BEINT",
  "BEINU",
  "BEITA",
  "BEITE",
  "BEITH",
  "BEITI",
  "BEITU",
  "BEJAH",
  "BEJAM",
  "BEJBA",
  "BEJBE",
  "BEJBI",
  "BEJBO",
  "BEJEK",
  "BEJEM",
  "BEJES",
  "BEJEV",
  "BEJIH",
  "BEJIN",
  "BEJJA",
  "BEJJU",
  "BEJKA",
  "BEJKE",
  "BEJKI",
  "BEJKO",
  "BEJKU",
  "BEJOM",
  "BEJOV",
  "BEJTA",
  "BEJTE",
  "BEJTI",
  "BEJTO",
  "BEJTU",
  "BEKAH",
  "BEKAJ",
  "BEKAM",
  "BEKAR",
  "BEKER",
  "BEKEŠ",
  "BEKIC",
  "BEKIH",
  "BEKIM",
  "BEKIN",
  "BEKIR",
  "BEKJA",
  "BEKJU",
  "BEKKA",
  "BEKKE",
  "BEKKI",
  "BEKKO",
  "BEKOM",
  "BEKON",
  "BEKOV",
  "BEKRA",
  "BEKŠA",
  "BEKŠE",
  "BEKŠI",
  "BEKŠU",
  "BEKUT",
  "BELAC",
  "BELAČ",
  "BELAH",
  "BELAJ",
  "BELAK",
  "BELAM",
  "BELAN",
  "BELAR",
  "BELAS",
  "BELCA",
  "BELCE",
  "BELCI",
  "BELCL",
  "BELCO",
  "BELCU",
  "BELDA",
  "BELDE",
  "BELDI",
  "BELDO",
  "BELDU",
  "BELEC",
  "BELEČ",
  "BELEH",
  "BELEJ",
  "BELEM",
  "BELEZ",
  "BELEŽ",
  "BELFI",
  "BELGA",
  "BELIC",
  "BELIČ",
  "BELIH",
  "BELIK",
  "BELIL",
  "BELIM",
  "BELIN",
  "BELIŠ",
  "BELIT",
  "BELIU",
  "BELIV",
  "BELJA",
  "BELJE",
  "BELJO",
  "BELJU",
  "BELKA",
  "BELKE",
  "BELKI",
  "BELKO",
  "BELKU",
  "BELLA",
  "BELLE",
  "BELLI",
  "BELLO",
  "BELLU",
  "BELMI",
  "BELNA",
  "BELNE",
  "BELNI",
  "BELNO",
  "BELNU",
  "BELOB",
  "BELOM",
  "BELOT",
  "BELOV",
  "BELTA",
  "BELTE",
  "BELTI",
  "BELTO",
  "BELTU",
  "BELUG",
  "BELUH",
  "BELUŠ",
  "BEMBA",
  "BEMBE",
  "BEMBI",
  "BEMBO",
  "BEMBU",
  "BEMIH",
  "BEMIS",
  "BEMIT",
  "BEMJA",
  "BEMJU",
  "BEMOM",
  "BEMOV",
  "BEMTI",
  "BENAS",
  "BENCA",
  "BENCE",
  "BENCI",
  "BENCU",
  "BENDA",
  "BENDE",
  "BENDI",
  "BENDL",
  "BENDO",
  "BENDT",
  "BENDU",
  "BENEC",
  "BENES",
  "BENEŠ",
  "BENET",
  "BENGI",
  "BENGT",
  "BENHA",
  "BENHE",
  "BENHI",
  "BENHU",
  "BENIČ",
  "BENIH",
  "BENIN",
  "BENIT",
  "BENJA",
  "BENJE",
  "BENJI",
  "BENJO",
  "BENJU",
  "BENKA",
  "BENKE",
  "BENKI",
  "BENKO",
  "BENKU",
  "BENLI",
  "BENNA",
  "BENNE",
  "BENNI",
  "BENNO",
  "BENNU",
  "BENOM",
  "BENOV",
  "BENSA",
  "BENSE",
  "BENSI",
  "BENSO",
  "BENSU",
  "BENTA",
  "BENTE",
  "BENTI",
  "BENTO",
  "BENTT",
  "BENTU",
  "BENZA",
  "BENZE",
  "BENZI",
  "BENZU",
  "BEOMA",
  "BEOTA",
  "BEOTU",
  "BEOVA",
  "BEOVE",
  "BEOVI",
  "BEOVO",
  "BEPOP",
  "BEPPA",
  "BEPPE",
  "BEPPI",
  "BEPPO",
  "BEPPU",
  "BERAČ",
  "BERAJ",
  "BERAK",
  "BERAN",
  "BERAT",
  "BERCA",
  "BERCE",
  "BERCI",
  "BERCU",
  "BERDA",
  "BERDE",
  "BERDI",
  "BERDO",
  "BEREM",
  "BEREŠ",
  "BERGA",
  "BERGE",
  "BERGH",
  "BERGI",
  "BERGL",
  "BERGS",
  "BERGT",
  "BERGU",
  "BERIČ",
  "BERIH",
  "BERIJ",
  "BERIL",
  "BERIM",
  "BERIN",
  "BERIŠ",
  "BERIT",
  "BERIV",
  "BERJA",
  "BERJI",
  "BERJU",
  "BERKA",
  "BERKE",
  "BERKI",
  "BERKO",
  "BERKU",
  "BERLA",
  "BERLE",
  "BERLI",
  "BERLU",
  "BERMA",
  "BERME",
  "BERMI",
  "BERMO",
  "BERNA",
  "BERND",
  "BERNE",
  "BERNI",
  "BERNO",
  "BERNS",
  "BERNT",
  "BERNU",
  "BEROČ",
  "BEROM",
  "BEROŠ",
  "BEROT",
  "BEROV",
  "BERRA",
  "BERRE",
  "BERRI",
  "BERRO",
  "BERRU",
  "BERTA",
  "BERTE",
  "BERTH",
  "BERTI",
  "BERTL",
  "BERTO",
  "BERTU",
  "BERUS",
  "BERZL",
  "BESAH",
  "BESAL",
  "BESAM",
  "BESAR",
  "BESCH",
  "BESED",
  "BESEK",
  "BESEM",
  "BESEN",
  "BESET",
  "BESEV",
  "BESIH",
  "BESIK",
  "BESIM",
  "BESIN",
  "BESJA",
  "BESJU",
  "BESKA",
  "BESKE",
  "BESKI",
  "BESKU",
  "BESNA",
  "BESNE",
  "BESNI",
  "BESNO",
  "BESNU",
  "BESOM",
  "BESOV",
  "BESSA",
  "BESSE",
  "BESSI",
  "BESSU",
  "BESTA",
  "BESTE",
  "BESTI",
  "BESTU",
  "BEŠIČ",
  "BEŠIR",
  "BEŠKA",
  "BEŠKE",
  "BEŠKI",
  "BEŠKO",
  "BEŠOK",
  "BETAH",
  "BETAL",
  "BETAM",
  "BETEL",
  "BETEM",
  "BETEV",
  "BETHE",
  "BETIC",
  "BETIČ",
  "BETIH",
  "BETIM",
  "BETIN",
  "BETIS",
  "BETJA",
  "BETJU",
  "BETKA",
  "BETKE",
  "BETKI",
  "BETKO",
  "BETOM",
  "BETON",
  "BETOV",
  "BETTA",
  "BETTE",
  "BETTI",
  "BETTO",
  "BETTS",
  "BETTU",
  "BETVA",
  "BETVE",
  "BETVI",
  "BETVO",
  "BEUCA",
  "BEUCE",
  "BEUCI",
  "BEUCU",
  "BEUKA",
  "BEUKE",
  "BEUKI",
  "BEUKU",
  "BEUSA",
  "BEUSE",
  "BEUSI",
  "BEUST",
  "BEUSU",
  "BEUTH",
  "BEVAN",
  "BEVCA",
  "BEVCE",
  "BEVCI",
  "BEVCU",
  "BEVČE",
  "BEVČI",
  "BEVEC",
  "BEVIN",
  "BEVIS",
  "BEVJA",
  "BEVJU",
  "BEVKA",
  "BEVKE",
  "BEVKI",
  "BEVKU",
  "BEVOG",
  "BEVOM",
  "BEVOV",
  "BEVSK",
  "BEZAJ",
  "BEZAK",
  "BEZAL",
  "BEZAM",
  "BEZAŠ",
  "BEZAT",
  "BEZAU",
  "BEZAV",
  "BEZEG",
  "BEZEK",
  "BEZGA",
  "BEZGI",
  "BEZGU",
  "BEZIČ",
  "BEZKA",
  "BEZKE",
  "BEZKI",
  "BEZKU",
  "BEZLA",
  "BEZLI",
  "BEZLU",
  "BEZOS",
  "BEZUK",
  "BEŽAL",
  "BEŽAN",
  "BEŽAT",
  "BEŽAU",
  "BEŽAV",
  "BEŽEČ",
  "BEŽEK",
  "BEŽEN",
  "BEŽIM",
  "BEŽIŠ",
  "BEŽKA",
  "BEŽKE",
  "BEŽKI",
  "BEŽKU",
  "BEŽNA",
  "BEŽNE",
  "BEŽNI",
  "BEŽNO",
  "BHANA",
  "BHANE",
  "BHANI",
  "BHANU",
  "BHATT",
  "BIAGI",
  "BIALA",
  "BIALK",
  "BIALU",
  "BIANC",
  "BIANK",
  "BIANT",
  "BIASI",
  "BIAVA",
  "BIAVE",
  "BIAVI",
  "BIAVO",
  "BIAVU",
  "BIBAH",
  "BIBAJ",
  "BIBAM",
  "BIBAN",
  "BIBAS",
  "BIBBA",
  "BIBBE",
  "BIBBI",
  "BIBBS",
  "BIBBU",
  "BIBER",
  "BIBIČ",
  "BIBIH",
  "BIBIN",
  "BIBJA",
  "BIBJU",
  "BIBOM",
  "BIBOV",
  "BIBRA",
  "BIBRE",
  "BIBRI",
  "BIBRU",
  "BICAJ",
  "BICAN",
  "BICCI",
  "BICEV",
  "BICHL",
  "BICKA",
  "BICKE",
  "BICKI",
  "BICKL",
  "BICKS",
  "BICKU",
  "BICOM",
  "BIČAJ",
  "BIČAL",
  "BIČAM",
  "BIČAN",
  "BIČAR",
  "BIČAŠ",
  "BIČAT",
  "BIČAU",
  "BIČAV",
  "BIČEK",
  "BIČEM",
  "BIČEV",
  "BIČIČ",
  "BIČIH",
  "BIČIJ",
  "BIČIN",
  "BIČJA",
  "BIČJE",
  "BIČJI",
  "BIČJO",
  "BIČJU",
  "BIČKA",
  "BIČKE",
  "BIČKI",
  "BIČKU",
  "BIČOM",
  "BIČOV",
  "BIDAN",
  "BIDAR",
  "BIDCO",
  "BIDEN",
  "BIDER",
  "BIDIH",
  "BIDJA",
  "BIDJU",
  "BIDNA",
  "BIDNE",
  "BIDNI",
  "BIDNU",
  "BIDOM",
  "BIDON",
  "BIDOV",
  "BIDRA",
  "BIDRE",
  "BIDRI",
  "BIDRU",
  "BIEBL",
  "BIEDL",
  "BIEHL",
  "BIEHN",
  "BIELA",
  "BIELE",
  "BIELI",
  "BIELO",
  "BIELS",
  "BIELU",
  "BIENA",
  "BIENE",
  "BIENI",
  "BIENU",
  "BIERI",
  "BIERK",
  "BIERL",
  "BIERN",
  "BIESK",
  "BIESS",
  "BIFFA",
  "BIFFE",
  "BIFFI",
  "BIFFU",
  "BIFID",
  "BIFOR",
  "BIGAH",
  "BIGAM",
  "BIGGI",
  "BIGGS",
  "BIGIA",
  "BIGIE",
  "BIGII",
  "BIGIO",
  "BIGIU",
  "BIGON",
  "BIGOT",
  "BIHAČ",
  "BIHAN",
  "BIHAR",
  "BIHLA",
  "BIHLE",
  "BIHLI",
  "BIHLU",
  "BIJAN",
  "BIJEM",
  "BIJEŠ",
  "BIJMO",
  "BIJOČ",
  "BIJOL",
  "BIJOU",
  "BIJTA",
  "BIJTE",
  "BIJVA",
  "BIKAR",
  "BIKCA",
  "BIKCE",
  "BIKCI",
  "BIKCU",
  "BIKEC",
  "BIKEM",
  "BIKER",
  "BIKEV",
  "BIKIC",
  "BIKIH",
  "BIKIL",
  "BIKIN",
  "BIKJA",
  "BIKJU",
  "BIKOM",
  "BIKOV",
  "BIKSA",
  "BIKSI",
  "BIKSU",
  "BILAČ",
  "BILAK",
  "BILAL",
  "BILAN",
  "BILBA",
  "BILBE",
  "BILBI",
  "BILBO",
  "BILBU",
  "BILCA",
  "BILCE",
  "BILCI",
  "BILCU",
  "BILDA",
  "BILDT",
  "BILDU",
  "BILEN",
  "BILES",
  "BILIČ",
  "BILIJ",
  "BILIK",
  "BILJA",
  "BILJE",
  "BILJI",
  "BILJK",
  "BILJU",
  "BILKA",
  "BILKE",
  "BILKI",
  "BILKO",
  "BILLA",
  "BILLE",
  "BILLI",
  "BILLO",
  "BILLS",
  "BILLU",
  "BILNA",
  "BILNE",
  "BILNI",
  "BILNO",
  "BILOG",
  "BILOŠ",
  "BILPA",
  "BILPE",
  "BILPI",
  "BILPO",
  "BILUŠ",
  "BIMAH",
  "BIMAM",
  "BIMBA",
  "BIMBE",
  "BIMBI",
  "BIMBO",
  "BIMBU",
  "BIMED",
  "BIMIH",
  "BIMJA",
  "BIMJU",
  "BIMOM",
  "BIMOV",
  "BINAH",
  "BINAJ",
  "BINAK",
  "BINAM",
  "BINDI",
  "BINDL",
  "BINDT",
  "BINER",
  "BINET",
  "BINGA",
  "BINGE",
  "BINGI",
  "BINGO",
  "BINGU",
  "BINIH",
  "BININ",
  "BINJA",
  "BINJU",
  "BINKS",
  "BINNS",
  "BINOM",
  "BINOV",
  "BINZA",
  "BINZE",
  "BINZI",
  "BINZU",
  "BIOMA",
  "BIOME",
  "BIOMI",
  "BIOMU",
  "BIONA",
  "BIONE",
  "BIONI",
  "BIONU",
  "BIOTA",
  "BIOTO",
  "BIOTU",
  "BIOVA",
  "BIOVE",
  "BIOVI",
  "BIOVO",
  "BIPIN",
  "BIPIS",
  "BIRAN",
  "BIRCH",
  "BIRCK",
  "BIRDA",
  "BIRDE",
  "BIRDI",
  "BIRDU",
  "BIREM",
  "BIREN",
  "BIRET",
  "BIRGA",
  "BIRGE",
  "BIRGI",
  "BIRGU",
  "BIRIČ",
  "BIRIH",
  "BIRJA",
  "BIRJU",
  "BIRKA",
  "BIRKE",
  "BIRKI",
  "BIRKO",
  "BIRKS",
  "BIRKU",
  "BIRLI",
  "BIRMA",
  "BIRME",
  "BIRMI",
  "BIRMO",
  "BIRMU",
  "BIRNA",
  "BIRNE",
  "BIRNI",
  "BIRNO",
  "BIRNS",
  "BIRNU",
  "BIROL",
  "BIROM",
  "BIRON",
  "BIROV",
  "BIRSA",
  "BIRSE",
  "BIRSI",
  "BIRSO",
  "BIRSU",
  "BIRTA",
  "BIRTE",
  "BIRTI",
  "BIRTU",
  "BIRUŠ",
  "BIRUT",
  "BISAG",
  "BISAH",
  "BISAM",
  "BISCH",
  "BISER",
  "BISHA",
  "BISHE",
  "BISHI",
  "BISHO",
  "BISHT",
  "BISIA",
  "BISIE",
  "BISIH",
  "BISII",
  "BISIN",
  "BISIO",
  "BISIU",
  "BISJA",
  "BISJU",
  "BISOL",
  "BISOM",
  "BISON",
  "BISOV",
  "BISSA",
  "BISSE",
  "BISSI",
  "BISSU",
  "BISTA",
  "BISTE",
  "BISTI",
  "BISTO",
  "BISUS",
  "BIŠČE",
  "BIŠEM",
  "BIŠJA",
  "BIŠJU",
  "BIŠKA",
  "BIŠKE",
  "BIŠKI",
  "BIŠKO",
  "BIŠOM",
  "BITAH",
  "BITAM",
  "BITAR",
  "BITEM",
  "BITEN",
  "BITEV",
  "BITGA",
  "BITIČ",
  "BITIH",
  "BITIJ",
  "BITIM",
  "BITIN",
  "BITJA",
  "BITJE",
  "BITJI",
  "BITJO",
  "BITJU",
  "BITKA",
  "BITKE",
  "BITKI",
  "BITKO",
  "BITMA",
  "BITMI",
  "BITNA",
  "BITNE",
  "BITNI",
  "BITNO",
  "BITOM",
  "BITON",
  "BITOV",
  "BITRI",
  "BITTA",
  "BITTE",
  "BITTI",
  "BITTL",
  "BITTO",
  "BITTU",
  "BITVA",
  "BITVE",
  "BITVI",
  "BITVO",
  "BIUKA",
  "BIUKE",
  "BIUKI",
  "BIUKU",
  "BIUŠA",
  "BIUŠE",
  "BIUŠI",
  "BIUŠO",
  "BIVAJ",
  "BIVAK",
  "BIVAL",
  "BIVAM",
  "BIVAŠ",
  "BIVAT",
  "BIVAU",
  "BIVAV",
  "BIVIC",
  "BIVIN",
  "BIVJA",
  "BIVJE",
  "BIVJU",
  "BIVOL",
  "BIVŠA",
  "BIVŠE",
  "BIVŠI",
  "BIVŠO",
  "BIZAJ",
  "BIZET",
  "BIZGC",
  "BIZIK",
  "BIZON",
  "BIZZI",
  "BIŽAL",
  "BJANK",
  "BJARČ",
  "BJERG",
  "BJOND",
  "BJORG",
  "BJORN",
  "BLAAK",
  "BLAAS",
  "BLACH",
  "BLACK",
  "BLADA",
  "BLADE",
  "BLADH",
  "BLADI",
  "BLADU",
  "BLAGA",
  "BLAGE",
  "BLAGG",
  "BLAGI",
  "BLAGO",
  "BLAGU",
  "BLAHA",
  "BLAHE",
  "BLAHI",
  "BLAHO",
  "BLAHU",
  "BLAIN",
  "BLAIR",
  "BLAIS",
  "BLAJA",
  "BLAJE",
  "BLAJI",
  "BLAJU",
  "BLAKA",
  "BLAKE",
  "BLAKI",
  "BLAKU",
  "BLANC",
  "BLAND",
  "BLANK",
  "BLANT",
  "BLASA",
  "BLASE",
  "BLASI",
  "BLASS",
  "BLAST",
  "BLASU",
  "BLAŠK",
  "BLATA",
  "BLATC",
  "BLATE",
  "BLATI",
  "BLATO",
  "BLATT",
  "BLATU",
  "BLATZ",
  "BLAUA",
  "BLAUE",
  "BLAUI",
  "BLAUU",
  "BLAZE",
  "BLAŽA",
  "BLAŽE",
  "BLAŽI",
  "BLAŽK",
  "BLAŽO",
  "BLAŽU",
  "BLECH",
  "BLECK",
  "BLEDA",
  "BLEDE",
  "BLEDI",
  "BLEDO",
  "BLEDU",
  "BLEED",
  "BLEES",
  "BLEFA",
  "BLEFE",
  "BLEFI",
  "BLEFU",
  "BLEJA",
  "BLEJC",
  "BLEJE",
  "BLEJI",
  "BLEJK",
  "BLEJU",
  "BLEKA",
  "BLEKI",
  "BLEKU",
  "BLENA",
  "BLEND",
  "BLENE",
  "BLENI",
  "BLENN",
  "BLENU",
  "BLEON",
  "BLERT",
  "BLESA",
  "BLESE",
  "BLESI",
  "BLESK",
  "BLESO",
  "BLESS",
  "BLEST",
  "BLESU",
  "BLETA",
  "BLETE",
  "BLETI",
  "BLETO",
  "BLETU",
  "BLIAS",
  "BLICA",
  "BLICK",
  "BLICU",
  "BLIDA",
  "BLIDE",
  "BLIDI",
  "BLIDO",
  "BLIER",
  "BLIGA",
  "BLIGE",
  "BLIGH",
  "BLIJA",
  "BLIJE",
  "BLIJI",
  "BLIJU",
  "BLIMA",
  "BLIME",
  "BLIMI",
  "BLIMU",
  "BLINA",
  "BLINC",
  "BLIND",
  "BLINE",
  "BLINI",
  "BLINN",
  "BLINO",
  "BLINU",
  "BLISK",
  "BLISS",
  "BLIŠČ",
  "BLITT",
  "BLIZU",
  "BLIŽA",
  "BLIŽE",
  "BLIŽI",
  "BLIŽU",
  "BLJAK",
  "BLJUJ",
  "BLLAC",
  "BLOCH",
  "BLOCK",
  "BLODI",
  "BLODU",
  "BLOGA",
  "BLOGE",
  "BLOGI",
  "BLOGU",
  "BLOHM",
  "BLOKA",
  "BLOKC",
  "BLOKE",
  "BLOKI",
  "BLOKU",
  "BLOMA",
  "BLOME",
  "BLOMI",
  "BLOMU",
  "BLOND",
  "BLOOD",
  "BLOOM",
  "BLORA",
  "BLORE",
  "BLORI",
  "BLORU",
  "BLOSS",
  "BLOTT",
  "BLUCK",
  "BLUES",
  "BLUFF",
  "BLUHM",
  "BLUJA",
  "BLUJE",
  "BLUJI",
  "BLUJU",
  "BLUMA",
  "BLUME",
  "BLUMI",
  "BLUMO",
  "BLUMU",
  "BLUNT",
  "BLUŠČ",
  "BLUTH",
  "BLUZA",
  "BLUZE",
  "BLUZI",
  "BLUZO",
  "BLUZU",
  "BOAKA",
  "BOAKE",
  "BOAKI",
  "BOAKU",
  "BOALA",
  "BOALE",
  "BOALI",
  "BOALS",
  "BOALU",
  "BOAMA",
  "BOAMI",
  "BOANA",
  "BOANE",
  "BOANI",
  "BOANU",
  "BOARD",
  "BOARI",
  "BOASA",
  "BOASE",
  "BOASI",
  "BOASU",
  "BOATI",
  "BOAZA",
  "BOAZE",
  "BOAZI",
  "BOAZU",
  "BOBAH",
  "BOBAJ",
  "BOBAK",
  "BOBAM",
  "BOBAN",
  "BOBBA",
  "BOBBE",
  "BOBBI",
  "BOBBU",
  "BOBEK",
  "BOBEM",
  "BOBEN",
  "BOBER",
  "BOBEV",
  "BOBIČ",
  "BOBIH",
  "BOBIK",
  "BOBIN",
  "BOBJA",
  "BOBJU",
  "BOBKA",
  "BOBKE",
  "BOBKI",
  "BOBKO",
  "BOBKU",
  "BOBNA",
  "BOBNE",
  "BOBNI",
  "BOBNU",
  "BOBOM",
  "BOBOV",
  "BOBRA",
  "BOBRE",
  "BOBRI",
  "BOBRO",
  "BOBRU",
  "BOCCI",
  "BOCEM",
  "BOCEV",
  "BOCHL",
  "BOCIH",
  "BOCJA",
  "BOCJU",
  "BOCKA",
  "BOCKE",
  "BOCKI",
  "BOCKU",
  "BOCOM",
  "BOCOV",
  "BOCSI",
  "BOČAJ",
  "BOČAN",
  "BOČEČ",
  "BOČEK",
  "BOČEM",
  "BOČEN",
  "BOČEV",
  "BOČIČ",
  "BOČIL",
  "BOČIM",
  "BOČIŠ",
  "BOČIT",
  "BOČIU",
  "BOČIV",
  "BOČJA",
  "BOČJU",
  "BOČKA",
  "BOČKE",
  "BOČKI",
  "BOČKO",
  "BOČKU",
  "BOČNA",
  "BOČNE",
  "BOČNI",
  "BOČNO",
  "BOČOM",
  "BOČOV",
  "BODAK",
  "BODAL",
  "BODČC",
  "BODDI",
  "BODEČ",
  "BODEL",
  "BODEM",
  "BODEN",
  "BODER",
  "BODEŠ",
  "BODEU",
  "BODEŽ",
  "BODHI",
  "BODIC",
  "BODIČ",
  "BODIE",
  "BODIH",
  "BODIK",
  "BODIL",
  "BODIN",
  "BODIŠ",
  "BODJA",
  "BODJU",
  "BODLA",
  "BODLE",
  "BODLI",
  "BODLO",
  "BODNA",
  "BODNE",
  "BODNI",
  "BODNO",
  "BODOČ",
  "BODOM",
  "BODOR",
  "BODOV",
  "BODRA",
  "BODRE",
  "BODRI",
  "BODRO",
  "BODRU",
  "BOECK",
  "BOEGL",
  "BOEHI",
  "BOEHL",
  "BOEHM",
  "BOEJA",
  "BOEJE",
  "BOEJI",
  "BOEJU",
  "BOEKE",
  "BOELA",
  "BOELE",
  "BOELI",
  "BOELK",
  "BOELL",
  "BOELU",
  "BOEMA",
  "BOEME",
  "BOEMI",
  "BOEMK",
  "BOEMU",
  "BOERA",
  "BOERE",
  "BOERI",
  "BOERO",
  "BOERS",
  "BOERU",
  "BOESA",
  "BOESE",
  "BOESI",
  "BOESS",
  "BOESU",
  "BOETA",
  "BOETE",
  "BOETH",
  "BOETI",
  "BOETO",
  "BOETU",
  "BOFFA",
  "BOFFE",
  "BOFFI",
  "BOFFU",
  "BOFOR",
  "BOGAN",
  "BOGAR",
  "BOGAT",
  "BOGCA",
  "BOGCE",
  "BOGCI",
  "BOGCU",
  "BOGEC",
  "BOGEK",
  "BOGEM",
  "BOGER",
  "BOGEV",
  "BOGGA",
  "BOGGS",
  "BOGIH",
  "BOGIM",
  "BOGIN",
  "BOGJA",
  "BOGJU",
  "BOGKA",
  "BOGKE",
  "BOGKI",
  "BOGKU",
  "BOGLA",
  "BOGLE",
  "BOGLI",
  "BOGLU",
  "BOGOM",
  "BOGOV",
  "BOGUE",
  "BOGUŠ",
  "BOGUT",
  "BOHAK",
  "BOHAN",
  "BOHAR",
  "BOHEN",
  "BOHIH",
  "BOHIN",
  "BOHJA",
  "BOHJU",
  "BOHLA",
  "BOHLE",
  "BOHLI",
  "BOHLU",
  "BOHMA",
  "BOHME",
  "BOHMI",
  "BOHMU",
  "BOHNA",
  "BOHNE",
  "BOHNI",
  "BOHNU",
  "BOHOM",
  "BOHOR",
  "BOHOT",
  "BOHOV",
  "BOHRA",
  "BOHRE",
  "BOHRI",
  "BOHRU",
  "BOHSE",
  "BOHTE",
  "BOIJO",
  "BOIMA",
  "BOIME",
  "BOIMI",
  "BOIMU",
  "BOINA",
  "BOINE",
  "BOINI",
  "BOINO",
  "BOISA",
  "BOISE",
  "BOISI",
  "BOISO",
  "BOISU",
  "BOITA",
  "BOITE",
  "BOITI",
  "BOITO",
  "BOITU",
  "BOJAH",
  "BOJAM",
  "BOJAN",
  "BOJAR",
  "BOJCA",
  "BOJCE",
  "BOJCI",
  "BOJCU",
  "BOJDA",
  "BOJEČ",
  "BOJEM",
  "BOJEN",
  "BOJEV",
  "BOJIČ",
  "BOJIH",
  "BOJIM",
  "BOJIN",
  "BOJIŠ",
  "BOJJA",
  "BOJJU",
  "BOJKA",
  "BOJKE",
  "BOJKI",
  "BOJKO",
  "BOJKU",
  "BOJMO",
  "BOJNA",
  "BOJNE",
  "BOJNI",
  "BOJNO",
  "BOJOM",
  "BOJOV",
  "BOJTA",
  "BOJTE",
  "BOJUJ",
  "BOJVA",
  "BOKAH",
  "BOKAJ",
  "BOKAL",
  "BOKAM",
  "BOKAN",
  "BOKAŠ",
  "BOKAT",
  "BOKAU",
  "BOKAV",
  "BOKEM",
  "BOKEV",
  "BOKIH",
  "BOKJA",
  "BOKJU",
  "BOKOM",
  "BOKOR",
  "BOKOV",
  "BOKSA",
  "BOKSE",
  "BOKSI",
  "BOKSU",
  "BOKUN",
  "BOLAH",
  "BOLAM",
  "BOLAN",
  "BOLAR",
  "BOLAT",
  "BOLDA",
  "BOLDE",
  "BOLDI",
  "BOLDT",
  "BOLDU",
  "BOLEČ",
  "BOLEH",
  "BOLEL",
  "BOLEM",
  "BOLEN",
  "BOLER",
  "BOLES",
  "BOLET",
  "BOLEU",
  "BOLFA",
  "BOLFE",
  "BOLFI",
  "BOLFU",
  "BOLHA",
  "BOLHE",
  "BOLHI",
  "BOLHO",
  "BOLHU",
  "BOLIČ",
  "BOLID",
  "BOLIH",
  "BOLIK",
  "BOLIL",
  "BOLIM",
  "BOLIN",
  "BOLIŠ",
  "BOLJA",
  "BOLJE",
  "BOLJK",
  "BOLJO",
  "BOLJU",
  "BOLKA",
  "BOLKE",
  "BOLKI",
  "BOLKO",
  "BOLKS",
  "BOLKU",
  "BOLLA",
  "BOLLE",
  "BOLLI",
  "BOLLO",
  "BOLLU",
  "BOLMI",
  "BOLNA",
  "BOLNE",
  "BOLNI",
  "BOLNO",
  "BOLOM",
  "BOLOV",
  "BOLTA",
  "BOLTE",
  "BOLTI",
  "BOLTO",
  "BOLTU",
  "BOLUJ",
  "BOLUS",
  "BOLZA",
  "BOLZE",
  "BOLZI",
  "BOLZU",
  "BOMAN",
  "BOMAR",
  "BOMBA",
  "BOMBE",
  "BOMBI",
  "BOMBO",
  "BOMER",
  "BOMIN",
  "BONAČ",
  "BONAH",
  "BONAJ",
  "BONAM",
  "BONAR",
  "BONAS",
  "BONCA",
  "BONCE",
  "BONCI",
  "BONCU",
  "BONČA",
  "BONČE",
  "BONČI",
  "BONČO",
  "BONČU",
  "BONDA",
  "BONDE",
  "BONDI",
  "BONDS",
  "BONDT",
  "BONDU",
  "BONEC",
  "BONEM",
  "BONER",
  "BONES",
  "BONEŠ",
  "BONET",
  "BONEV",
  "BONGA",
  "BONGE",
  "BONGI",
  "BONGO",
  "BONGU",
  "BONIA",
  "BONIC",
  "BONIE",
  "BONIH",
  "BONII",
  "BONIJ",
  "BONIN",
  "BONIO",
  "BONIS",
  "BONIT",
  "BONJA",
  "BONJU",
  "BONNA",
  "BONNE",
  "BONNI",
  "BONNU",
  "BONOL",
  "BONOM",
  "BONOR",
  "BONOV",
  "BONTA",
  "BONTE",
  "BONTI",
  "BONTO",
  "BONTU",
  "BONUS",
  "BONZI",
  "BOOCK",
  "BOOKA",
  "BOOKE",
  "BOOKI",
  "BOOKU",
  "BOOLA",
  "BOOLE",
  "BOOLI",
  "BOOLU",
  "BOOMA",
  "BOOME",
  "BOOMI",
  "BOOMU",
  "BOONA",
  "BOONE",
  "BOONI",
  "BOONS",
  "BOONU",
  "BOORA",
  "BOORE",
  "BOORI",
  "BOORU",
  "BOOSA",
  "BOOSE",
  "BOOSI",
  "BOOSU",
  "BOOTA",
  "BOOTE",
  "BOOTH",
  "BOOTI",
  "BOOTS",
  "BOOTU",
  "BOPPA",
  "BOPPE",
  "BOPPI",
  "BOPPU",
  "BORAC",
  "BORAG",
  "BORAH",
  "BORAK",
  "BORAM",
  "BORAN",
  "BORAS",
  "BORAT",
  "BORBA",
  "BORBE",
  "BORBI",
  "BORBO",
  "BORBU",
  "BORCA",
  "BORCE",
  "BORCH",
  "BORCI",
  "BORCK",
  "BORCU",
  "BORČE",
  "BORDA",
  "BORDE",
  "BORDI",
  "BORDO",
  "BORDU",
  "BOREC",
  "BOREČ",
  "BORED",
  "BOREM",
  "BOREN",
  "BORET",
  "BOREV",
  "BORGA",
  "BORGE",
  "BORGI",
  "BORGU",
  "BORIA",
  "BORIC",
  "BORIČ",
  "BORIE",
  "BORIH",
  "BORII",
  "BORIJ",
  "BORIK",
  "BORIL",
  "BORIM",
  "BORIN",
  "BORIO",
  "BORIS",
  "BORIŠ",
  "BORIT",
  "BORIU",
  "BORIV",
  "BORJA",
  "BORJE",
  "BORJI",
  "BORJO",
  "BORJU",
  "BORKA",
  "BORKE",
  "BORKH",
  "BORKI",
  "BORKO",
  "BORKU",
  "BORLA",
  "BORLE",
  "BORLI",
  "BORLU",
  "BORNA",
  "BORNE",
  "BORNI",
  "BORNO",
  "BORNU",
  "BOROM",
  "BORON",
  "BOROŠ",
  "BOROT",
  "BOROV",
  "BORRE",
  "BORRI",
  "BORSA",
  "BORSE",
  "BORSI",
  "BORST",
  "BORSU",
  "BORŠA",
  "BORŠČ",
  "BORŠE",
  "BORŠI",
  "BORŠO",
  "BORŠT",
  "BORŠU",
  "BORTA",
  "BORTE",
  "BORTH",
  "BORTI",
  "BORTU",
  "BORUT",
  "BORZA",
  "BORZE",
  "BORZI",
  "BORZO",
  "BOSAJ",
  "BOSAK",
  "BOSAL",
  "BOSAM",
  "BOSAŠ",
  "BOSAT",
  "BOSAU",
  "BOSAV",
  "BOSCA",
  "BOSCE",
  "BOSCH",
  "BOSCI",
  "BOSCO",
  "BOSCU",
  "BOSEK",
  "BOSEM",
  "BOSGA",
  "BOSHA",
  "BOSHE",
  "BOSHI",
  "BOSHU",
  "BOSIA",
  "BOSIH",
  "BOSIM",
  "BOSIO",
  "BOSIU",
  "BOSJA",
  "BOSJU",
  "BOSKA",
  "BOSKE",
  "BOSKI",
  "BOSKO",
  "BOSKU",
  "BOSLE",
  "BOSNA",
  "BOSNE",
  "BOSNI",
  "BOSNO",
  "BOSOM",
  "BOSOV",
  "BOSSA",
  "BOSSE",
  "BOSSI",
  "BOSSO",
  "BOSSU",
  "BOSTA",
  "BOSTE",
  "BOSTI",
  "BOŠKA",
  "BOŠKE",
  "BOŠKI",
  "BOŠKO",
  "BOŠKU",
  "BOTAČ",
  "BOTAS",
  "BOTEM",
  "BOTER",
  "BOTES",
  "BOTEV",
  "BOTHA",
  "BOTHE",
  "BOTHI",
  "BOTHO",
  "BOTHU",
  "BOTIH",
  "BOTIN",
  "BOTJA",
  "BOTJU",
  "BOTOM",
  "BOTOV",
  "BOTRA",
  "BOTRC",
  "BOTRE",
  "BOTRI",
  "BOTRO",
  "BOTRU",
  "BOTSA",
  "BOTSE",
  "BOTSI",
  "BOTSU",
  "BOTTA",
  "BOTTE",
  "BOTTI",
  "BOTTO",
  "BOTTS",
  "BOTTU",
  "BOUCK",
  "BOUET",
  "BOUIH",
  "BOUIN",
  "BOUJA",
  "BOUJU",
  "BOULA",
  "BOULD",
  "BOULE",
  "BOULI",
  "BOULO",
  "BOULU",
  "BOUOM",
  "BOUOV",
  "BOURG",
  "BOURK",
  "BOURL",
  "BOVCA",
  "BOVCU",
  "BOVEC",
  "BOVEE",
  "BOVEL",
  "BOVHA",
  "BOVHE",
  "BOVHI",
  "BOVHO",
  "BOVHU",
  "BOVIA",
  "BOVIE",
  "BOVII",
  "BOVIN",
  "BOVIO",
  "BOVIU",
  "BOVLA",
  "BOVLE",
  "BOVLI",
  "BOVLO",
  "BOVON",
  "BOVŠE",
  "BOZAK",
  "BOZEN",
  "BOZEV",
  "BOZGA",
  "BOZGE",
  "BOZGI",
  "BOZGO",
  "BOZGU",
  "BOZIJ",
  "BOZJA",
  "BOZJE",
  "BOZJI",
  "BOZJO",
  "BOZJU",
  "BOZNA",
  "BOZNU",
  "BOZON",
  "BOZOV",
  "BOZZA",
  "BOZZE",
  "BOZZI",
  "BOZZO",
  "BOZZU",
  "BOŽAC",
  "BOŽAH",
  "BOŽAJ",
  "BOŽAK",
  "BOŽAL",
  "BOŽAM",
  "BOŽAN",
  "BOŽAŠ",
  "BOŽAT",
  "BOŽAU",
  "BOŽAV",
  "BOŽCA",
  "BOŽCE",
  "BOŽCI",
  "BOŽCO",
  "BOŽCU",
  "BOŽEC",
  "BOŽEM",
  "BOŽEN",
  "BOŽEV",
  "BOŽIC",
  "BOŽIČ",
  "BOŽIH",
  "BOŽIN",
  "BOŽJA",
  "BOŽJE",
  "BOŽJI",
  "BOŽJO",
  "BOŽJU",
  "BOŽKA",
  "BOŽKI",
  "BOŽKU",
  "BOŽNA",
  "BOŽNE",
  "BOŽNI",
  "BOŽNO",
  "BOŽOM",
  "BOŽOV",
  "BRAAK",
  "BRAAM",
  "BRACA",
  "BRACE",
  "BRACI",
  "BRACK",
  "BRACO",
  "BRACU",
  "BRAČA",
  "BRAČU",
  "BRADA",
  "BRADD",
  "BRADE",
  "BRADI",
  "BRADL",
  "BRADO",
  "BRADT",
  "BRADU",
  "BRAFF",
  "BRAGA",
  "BRAGE",
  "BRAGG",
  "BRAGI",
  "BRAGO",
  "BRAGU",
  "BRAHE",
  "BRAHM",
  "BRAHN",
  "BRAID",
  "BRAIN",
  "BRAIT",
  "BRAJC",
  "BRAJD",
  "BRAJŠ",
  "BRAKA",
  "BRAKE",
  "BRAKI",
  "BRAKO",
  "BRAKU",
  "BRALA",
  "BRALC",
  "BRALE",
  "BRALI",
  "BRALK",
  "BRALO",
  "BRAMA",
  "BRAMB",
  "BRAME",
  "BRAMI",
  "BRAML",
  "BRAMS",
  "BRAMU",
  "BRANA",
  "BRANC",
  "BRAND",
  "BRANE",
  "BRANI",
  "BRANJ",
  "BRANK",
  "BRANN",
  "BRANO",
  "BRANT",
  "BRANU",
  "BRANŽ",
  "BRASA",
  "BRASE",
  "BRASH",
  "BRASI",
  "BRASK",
  "BRASS",
  "BRAST",
  "BRASU",
  "BRATA",
  "BRATC",
  "BRATE",
  "BRATI",
  "BRATT",
  "BRATU",
  "BRAUC",
  "BRAUD",
  "BRAUN",
  "BRAUS",
  "BRAUT",
  "BRAVA",
  "BRAVC",
  "BRAVE",
  "BRAVI",
  "BRAVO",
  "BRAVU",
  "BRAZA",
  "BRAZD",
  "BRAZE",
  "BRAZI",
  "BRAZO",
  "BRAZU",
  "BRBER",
  "BRBOT",
  "BRBRA",
  "BRBRE",
  "BRBRI",
  "BRBRO",
  "BRBRU",
  "BRCAH",
  "BRCAJ",
  "BRCAK",
  "BRCAL",
  "BRCAM",
  "BRCAR",
  "BRCAŠ",
  "BRCAT",
  "BRCAU",
  "BRCAV",
  "BRCEM",
  "BRCEV",
  "BRCIH",
  "BRCJA",
  "BRCJU",
  "BRCKA",
  "BRCKE",
  "BRCKI",
  "BRCKO",
  "BRCKU",
  "BRCNE",
  "BRCNI",
  "BRCNU",
  "BRCOM",
  "BRCOV",
  "BRČAN",
  "BRČEV",
  "BRČIC",
  "BRČKO",
  "BRČOV",
  "BRDAH",
  "BRDAM",
  "BRDAN",
  "BRDAR",
  "BRDCE",
  "BRDEK",
  "BRDEM",
  "BRDIC",
  "BRDIH",
  "BRDIK",
  "BRDKA",
  "BRDKE",
  "BRDKI",
  "BRDKO",
  "BRDOM",
  "BRDOV",
  "BREAH",
  "BREAK",
  "BREAM",
  "BREAZ",
  "BRECA",
  "BRECE",
  "BRECH",
  "BRECI",
  "BRECK",
  "BRECL",
  "BRECO",
  "BRECU",
  "BREČA",
  "BREČE",
  "BREČI",
  "BREČO",
  "BREDA",
  "BREDE",
  "BREDI",
  "BREDO",
  "BREDT",
  "BREDU",
  "BREED",
  "BREEL",
  "BREEN",
  "BREES",
  "BREGA",
  "BREGC",
  "BREGE",
  "BREGI",
  "BREGU",
  "BREHM",
  "BREIG",
  "BREIH",
  "BREIL",
  "BREIT",
  "BREJA",
  "BREJC",
  "BREJE",
  "BREJI",
  "BREJK",
  "BREJO",
  "BREJU",
  "BREJZ",
  "BREKA",
  "BREKE",
  "BREKI",
  "BREKO",
  "BREKU",
  "BRELA",
  "BRELE",
  "BRELI",
  "BRELO",
  "BRELU",
  "BREMA",
  "BREME",
  "BREMI",
  "BREMM",
  "BREMU",
  "BREMZ",
  "BRENA",
  "BREND",
  "BRENE",
  "BRENI",
  "BRENK",
  "BRENN",
  "BRENO",
  "BRENT",
  "BRENU",
  "BREOM",
  "BREON",
  "BREOV",
  "BRERA",
  "BRERE",
  "BRERI",
  "BRERO",
  "BRERU",
  "BRESH",
  "BRESS",
  "BREST",
  "BRETA",
  "BRETE",
  "BRETI",
  "BRETT",
  "BRETU",
  "BREUA",
  "BREUE",
  "BREUI",
  "BREUS",
  "BREUU",
  "BREVI",
  "BREZA",
  "BREZE",
  "BREZI",
  "BREZO",
  "BREŽE",
  "BRGAN",
  "BRGEŠ",
  "BRHEK",
  "BRHKA",
  "BRHKE",
  "BRHKI",
  "BRHKO",
  "BRIAH",
  "BRIAM",
  "BRIAN",
  "BRICA",
  "BRICE",
  "BRICI",
  "BRICK",
  "BRICL",
  "BRICO",
  "BRICS",
  "BRICU",
  "BRIČA",
  "BRIČU",
  "BRIDI",
  "BRIDŽ",
  "BRIEM",
  "BRIGA",
  "BRIGE",
  "BRIGI",
  "BRIGO",
  "BRIGU",
  "BRIJE",
  "BRIKA",
  "BRIKE",
  "BRIKI",
  "BRIKO",
  "BRIKU",
  "BRILA",
  "BRILE",
  "BRILI",
  "BRILJ",
  "BRILL",
  "BRILO",
  "BRILU",
  "BRIMM",
  "BRINA",
  "BRINC",
  "BRINE",
  "BRINI",
  "BRINK",
  "BRINO",
  "BRINU",
  "BRION",
  "BRIOŠ",
  "BRISA",
  "BRISE",
  "BRISI",
  "BRISU",
  "BRIŠČ",
  "BRIŠE",
  "BRIŠI",
  "BRITA",
  "BRITE",
  "BRITI",
  "BRITO",
  "BRITS",
  "BRITT",
  "BRITU",
  "BRITZ",
  "BRIVC",
  "BRIVK",
  "BRIZG",
  "BRJAH",
  "BRJAM",
  "BRJAN",
  "BRKAČ",
  "BRKAH",
  "BRKAM",
  "BRKAN",
  "BRKAT",
  "BRKEM",
  "BRKEV",
  "BRKIČ",
  "BRKIH",
  "BRKIN",
  "BRKJA",
  "BRKJU",
  "BRKOM",
  "BRKOV",
  "BRLAN",
  "BRLEC",
  "BRLEČ",
  "BRLEK",
  "BRLEL",
  "BRLET",
  "BRLEU",
  "BRLIČ",
  "BRLIM",
  "BRLIŠ",
  "BRLOG",
  "BRMEŽ",
  "BRNAD",
  "BRNCA",
  "BRNCE",
  "BRNCI",
  "BRNCO",
  "BRNEČ",
  "BRNEL",
  "BRNET",
  "BRNEU",
  "BRNIČ",
  "BRNIK",
  "BRNIM",
  "BRNIŠ",
  "BRNOM",
  "BRNOS",
  "BRNOT",
  "BROAD",
  "BROCA",
  "BROCK",
  "BROČA",
  "BROČE",
  "BROČI",
  "BROČU",
  "BRODA",
  "BRODE",
  "BRODI",
  "BRODO",
  "BRODT",
  "BRODU",
  "BROEK",
  "BROGI",
  "BROJA",
  "BROJE",
  "BROJI",
  "BROJK",
  "BROJO",
  "BROJU",
  "BROKA",
  "BROKE",
  "BROKI",
  "BROKU",
  "BROLL",
  "BROMA",
  "BROMI",
  "BROMM",
  "BROMU",
  "BRONA",
  "BRONE",
  "BRONI",
  "BRONJ",
  "BRONK",
  "BRONN",
  "BRONO",
  "BRONU",
  "BROOK",
  "BROOM",
  "BROOS",
  "BROSA",
  "BROSE",
  "BROSI",
  "BROSS",
  "BROST",
  "BROSU",
  "BROŠA",
  "BROŠČ",
  "BROŠE",
  "BROŠI",
  "BROŠK",
  "BROŠO",
  "BROUN",
  "BROUT",
  "BROVČ",
  "BROZA",
  "BROZE",
  "BROZG",
  "BROZI",
  "BROZU",
  "BROŽA",
  "BROŽE",
  "BROŽI",
  "BROŽU",
  "BRSKA",
  "BRSKE",
  "BRSKI",
  "BRSKO",
  "BRSKU",
  "BRSTA",
  "BRSTE",
  "BRSTI",
  "BRSTU",
  "BRŠKA",
  "BRŠKE",
  "BRŠKI",
  "BRŠKO",
  "BRUCA",
  "BRUCE",
  "BRUCH",
  "BRUCI",
  "BRUCK",
  "BRUCU",
  "BRUČI",
  "BRUEL",
  "BRUEN",
  "BRUFF",
  "BRUHA",
  "BRUHI",
  "BRUHL",
  "BRUHN",
  "BRUHU",
  "BRUIL",
  "BRUIN",
  "BRUKA",
  "BRUKE",
  "BRUKI",
  "BRUKU",
  "BRULC",
  "BRULS",
  "BRUMA",
  "BRUME",
  "BRUMI",
  "BRUMM",
  "BRUMU",
  "BRUNA",
  "BRUND",
  "BRUNE",
  "BRUNI",
  "BRUNK",
  "BRUNN",
  "BRUNO",
  "BRUNS",
  "BRUNT",
  "BRUNU",
  "BRUSA",
  "BRUSC",
  "BRUSE",
  "BRUSI",
  "BRUSK",
  "BRUST",
  "BRUSU",
  "BRUTO",
  "BRUTT",
  "BRUUN",
  "BRUUS",
  "BRUVA",
  "BRUVE",
  "BRUVI",
  "BRUVO",
  "BRUVU",
  "BRVAC",
  "BRVAR",
  "BRVEH",
  "BRVEM",
  "BRVJO",
  "BRVMI",
  "BRZCA",
  "BRZCE",
  "BRZCI",
  "BRZCU",
  "BRZDA",
  "BRZDE",
  "BRZDI",
  "BRZDO",
  "BRZDU",
  "BRZEC",
  "BRZEČ",
  "BRZEL",
  "BRZEM",
  "BRZET",
  "BRZEU",
  "BRZGA",
  "BRZIC",
  "BRZIH",
  "BRZIM",
  "BRZIN",
  "BRZIŠ",
  "BRŽAN",
  "BRŽDA",
  "BRŽOL",
  "BUBAH",
  "BUBAM",
  "BUBAN",
  "BUBAŠ",
  "BUBBA",
  "BUBBE",
  "BUBBI",
  "BUBBO",
  "BUBBS",
  "BUBBU",
  "BUBEK",
  "BUBER",
  "BUBIH",
  "BUBIK",
  "BUBIN",
  "BUBJA",
  "BUBJU",
  "BUBKA",
  "BUBKE",
  "BUBKI",
  "BUBKO",
  "BUBKU",
  "BUBOL",
  "BUBOM",
  "BUBON",
  "BUBOV",
  "BUCAJ",
  "BUCCA",
  "BUCCE",
  "BUCCI",
  "BUCCO",
  "BUCCU",
  "BUCET",
  "BUCHA",
  "BUCHE",
  "BUCHI",
  "BUCHT",
  "BUCHU",
  "BUCIA",
  "BUCIE",
  "BUCII",
  "BUCIK",
  "BUCIO",
  "BUCIU",
  "BUCKA",
  "BUCKE",
  "BUCKI",
  "BUCKO",
  "BUCKS",
  "BUCKU",
  "BUCNE",
  "BUCNI",
  "BUCNU",
  "BUCSI",
  "BUČAH",
  "BUČAL",
  "BUČAM",
  "BUČAN",
  "BUČAR",
  "BUČAS",
  "BUČAT",
  "BUČAU",
  "BUČAV",
  "BUČEČ",
  "BUČEK",
  "BUČEM",
  "BUČEN",
  "BUČER",
  "BUČIC",
  "BUČIM",
  "BUČIŠ",
  "BUČJA",
  "BUČJU",
  "BUČKA",
  "BUČKE",
  "BUČKI",
  "BUČKO",
  "BUČKU",
  "BUČNA",
  "BUČNE",
  "BUČNI",
  "BUČNO",
  "BUČOM",
  "BUČUK",
  "BUDAH",
  "BUDAJ",
  "BUDAK",
  "BUDAL",
  "BUDAM",
  "BUDAU",
  "BUDDA",
  "BUDDE",
  "BUDDI",
  "BUDDS",
  "BUDDU",
  "BUDEČ",
  "BUDEN",
  "BUDER",
  "BUDGA",
  "BUDGE",
  "BUDIČ",
  "BUDIG",
  "BUDIH",
  "BUDIJ",
  "BUDIL",
  "BUDIM",
  "BUDIN",
  "BUDIŠ",
  "BUDIT",
  "BUDIU",
  "BUDIV",
  "BUDJA",
  "BUDJE",
  "BUDJI",
  "BUDJO",
  "BUDJU",
  "BUDKA",
  "BUDKE",
  "BUDKI",
  "BUDKO",
  "BUDKU",
  "BUDNA",
  "BUDNE",
  "BUDNI",
  "BUDNO",
  "BUDNU",
  "BUDOM",
  "BUDOV",
  "BUDRA",
  "BUDRE",
  "BUDRI",
  "BUDRO",
  "BUDVA",
  "BUDVE",
  "BUDVI",
  "BUDVO",
  "BUEHL",
  "BUELD",
  "BUELL",
  "BUEMI",
  "BUENA",
  "BUENE",
  "BUENI",
  "BUENO",
  "BUENU",
  "BUERK",
  "BUFFA",
  "BUFFE",
  "BUFFI",
  "BUFFU",
  "BUFON",
  "BUGEM",
  "BUGEV",
  "BUGGA",
  "BUGGE",
  "BUGGI",
  "BUGGS",
  "BUGGU",
  "BUGIH",
  "BUGIN",
  "BUGJA",
  "BUGJU",
  "BUGNA",
  "BUGNE",
  "BUGNI",
  "BUGNO",
  "BUGNU",
  "BUGOM",
  "BUGOV",
  "BUGRI",
  "BUHIH",
  "BUHJA",
  "BUHJU",
  "BUHLA",
  "BUHLE",
  "BUHLI",
  "BUHLU",
  "BUHNE",
  "BUHNI",
  "BUHNU",
  "BUHOM",
  "BUHOV",
  "BUHRE",
  "BUHRS",
  "BUHTE",
  "BUHTI",
  "BUHTU",
  "BUICK",
  "BUIJA",
  "BUIJE",
  "BUIJI",
  "BUIJU",
  "BUIKA",
  "BUIKE",
  "BUIKI",
  "BUIKU",
  "BUJAH",
  "BUJAK",
  "BUJAM",
  "BUJAN",
  "BUJAR",
  "BUJAS",
  "BUJEN",
  "BUJNA",
  "BUJNE",
  "BUJNI",
  "BUJNO",
  "BUJON",
  "BUJTA",
  "BUJTE",
  "BUJTI",
  "BUJTO",
  "BUKAČ",
  "BUKAL",
  "BUKEL",
  "BUKET",
  "BUKEV",
  "BUKIH",
  "BUKJA",
  "BUKJU",
  "BUKLA",
  "BUKLE",
  "BUKLI",
  "BUKLO",
  "BUKOM",
  "BUKOV",
  "BUKŠA",
  "BUKŠE",
  "BUKŠI",
  "BUKŠO",
  "BUKŠU",
  "BUKVA",
  "BUKVE",
  "BUKVI",
  "BUKVO",
  "BULAH",
  "BULAJ",
  "BULAM",
  "BULAN",
  "BULAT",
  "BULAV",
  "BULCA",
  "BULCE",
  "BULCI",
  "BULCU",
  "BULER",
  "BULIC",
  "BULIČ",
  "BULIH",
  "BULIK",
  "BULIL",
  "BULIM",
  "BULIŠ",
  "BULIT",
  "BULIU",
  "BULIV",
  "BULJA",
  "BULJE",
  "BULJI",
  "BULJU",
  "BULKA",
  "BULKE",
  "BULKI",
  "BULKU",
  "BULLA",
  "BULLE",
  "BULLI",
  "BULLO",
  "BULLS",
  "BULLU",
  "BULOG",
  "BULOM",
  "BULOV",
  "BULUT",
  "BUMBU",
  "BUMEM",
  "BUMEV",
  "BUMIH",
  "BUMJA",
  "BUMJU",
  "BUMOM",
  "BUMOV",
  "BUNCA",
  "BUNCE",
  "BUNCI",
  "BUNCU",
  "BUNDA",
  "BUNDE",
  "BUNDI",
  "BUNDO",
  "BUNEČ",
  "BUNET",
  "BUNIČ",
  "BUNIK",
  "BUNIL",
  "BUNIM",
  "BUNIN",
  "BUNIŠ",
  "BUNIT",
  "BUNIU",
  "BUNIV",
  "BUNKA",
  "BUNKE",
  "BUNKI",
  "BUNKO",
  "BUNKU",
  "BUNNA",
  "BUNNE",
  "BUNNI",
  "BUNNU",
  "BUNTA",
  "BUNTE",
  "BUNTI",
  "BUNTO",
  "BUNTU",
  "BUONA",
  "BUONE",
  "BUONI",
  "BUONO",
  "BUONU",
  "BUPPA",
  "BUPPE",
  "BUPPI",
  "BUPPU",
  "BURAK",
  "BURAS",
  "BURCH",
  "BURCK",
  "BURDA",
  "BURDE",
  "BURDI",
  "BURDO",
  "BURDU",
  "BUREK",
  "BUREL",
  "BUREN",
  "BUREŠ",
  "BURET",
  "BURGA",
  "BURGE",
  "BURGI",
  "BURGU",
  "BURIC",
  "BURIH",
  "BURIJ",
  "BURIL",
  "BURIM",
  "BURIN",
  "BURIŠ",
  "BURIT",
  "BURIU",
  "BURIV",
  "BURJA",
  "BURJE",
  "BURJI",
  "BURJO",
  "BURJU",
  "BURKA",
  "BURKE",
  "BURKI",
  "BURKO",
  "BURKS",
  "BURKU",
  "BURLA",
  "BURLE",
  "BURLI",
  "BURLU",
  "BURMA",
  "BURME",
  "BURMI",
  "BURMO",
  "BURNA",
  "BURNE",
  "BURNI",
  "BURNO",
  "BURNS",
  "BURNT",
  "BURNU",
  "BUROM",
  "BUROV",
  "BURRA",
  "BURRE",
  "BURRI",
  "BURRO",
  "BURRU",
  "BURSA",
  "BURSE",
  "BURSI",
  "BURSO",
  "BURŠA",
  "BURŠE",
  "BURŠI",
  "BURŠU",
  "BURTA",
  "BURTE",
  "BURTI",
  "BURTT",
  "BURTU",
  "BURUK",
  "BURUL",
  "BURUM",
  "BURZA",
  "BURZE",
  "BURZI",
  "BURZO",
  "BUSCH",
  "BUSEK",
  "BUSEM",
  "BUSER",
  "BUSEV",
  "BUSHA",
  "BUSHE",
  "BUSHI",
  "BUSHU",
  "BUSIH",
  "BUSJA",
  "BUSJU",
  "BUSKA",
  "BUSKE",
  "BUSKI",
  "BUSKU",
  "BUSOL",
  "BUSOM",
  "BUSON",
  "BUSOV",
  "BUSSA",
  "BUSSE",
  "BUSSI",
  "BUSSU",
  "BUSTA",
  "BUSTE",
  "BUSTI",
  "BUSTO",
  "BUSTU",
  "BUŠAT",
  "BUŠEN",
  "BUŠKA",
  "BUŠKE",
  "BUŠKI",
  "BUŠKO",
  "BUŠKU",
  "BUŠNE",
  "BUŠNI",
  "BUŠNU",
  "BUTAJ",
  "BUTAL",
  "BUTAM",
  "BUTAN",
  "BUTAR",
  "BUTAŠ",
  "BUTAT",
  "BUTAU",
  "BUTAV",
  "BUTCA",
  "BUTCE",
  "BUTCH",
  "BUTCI",
  "BUTCU",
  "BUTEC",
  "BUTEK",
  "BUTEM",
  "BUTIC",
  "BUTIH",
  "BUTIK",
  "BUTIL",
  "BUTIN",
  "BUTJA",
  "BUTJU",
  "BUTKA",
  "BUTKE",
  "BUTKI",
  "BUTKO",
  "BUTKU",
  "BUTNE",
  "BUTNI",
  "BUTNU",
  "BUTOM",
  "BUTOR",
  "BUTOV",
  "BUTTA",
  "BUTTE",
  "BUTTI",
  "BUTTO",
  "BUTTS",
  "BUTTU",
  "BUTUL",
  "BUTZA",
  "BUTZE",
  "BUTZI",
  "BUTZU",
  "BUVAČ",
  "BUZEK",
  "BUZET",
  "BUZIH",
  "BUZIN",
  "BUZJA",
  "BUZJU",
  "BUZKA",
  "BUZKE",
  "BUZKI",
  "BUZKU",
  "BUZOM",
  "BUZOV",
  "BUZZA",
  "BUZZE",
  "BUZZI",
  "BUZZU",
  "BUŽAN",
  "BUŽGA",
  "BUŽGE",
  "BUŽGI",
  "BUŽGO",
  "BUŽGU",
  "BZIKA",
  "BZIKE",
  "BZIKI",
  "BZIKU",
  "CAANA",
  "CAANE",
  "CAANI",
  "CAANU",
  "CABAD",
  "CABAL",
  "CABAN",
  "CABET",
  "CABLA",
  "CABLE",
  "CABLI",
  "CABLU",
  "CABSA",
  "CABSU",
  "CABUK",
  "CACAJ",
  "CACCI",
  "CADAN",
  "CADEL",
  "CADIH",
  "CADIZ",
  "CADJA",
  "CADJU",
  "CADLA",
  "CADLE",
  "CADLI",
  "CADLU",
  "CADOM",
  "CADOV",
  "CAENA",
  "CAENU",
  "CAETI",
  "CAFFA",
  "CAFFE",
  "CAFFI",
  "CAFFO",
  "CAFFU",
  "CAFIH",
  "CAFJA",
  "CAFJU",
  "CAFOM",
  "CAFOV",
  "CAFUN",
  "CAFUT",
  "CAGAJ",
  "CAGAL",
  "CAGAM",
  "CAGAŠ",
  "CAGAT",
  "CAGAU",
  "CAGAV",
  "CAGEA",
  "CAGEE",
  "CAGEI",
  "CAGEU",
  "CAHAN",
  "CAHEN",
  "CAHIH",
  "CAHJA",
  "CAHJU",
  "CAHNA",
  "CAHNE",
  "CAHNI",
  "CAHNU",
  "CAHOM",
  "CAHOV",
  "CAHUN",
  "CAIAT",
  "CAIEM",
  "CAIEV",
  "CAIIH",
  "CAIJA",
  "CAIJE",
  "CAIJI",
  "CAIJU",
  "CAIMI",
  "CAINA",
  "CAINE",
  "CAINI",
  "CAINO",
  "CAINS",
  "CAINU",
  "CAIOM",
  "CAIOV",
  "CAISA",
  "CAISE",
  "CAISI",
  "CAISO",
  "CAJAL",
  "CAJGA",
  "CAJGI",
  "CAJGU",
  "CAJNA",
  "CAJNE",
  "CAJNI",
  "CAJNO",
  "CAJTA",
  "CAJTE",
  "CAJTI",
  "CAJTU",
  "CAKAH",
  "CAKAJ",
  "CAKAM",
  "CAKEM",
  "CAKEV",
  "CAKIH",
  "CAKJA",
  "CAKJU",
  "CAKOM",
  "CAKOV",
  "CALAM",
  "CALDI",
  "CALEB",
  "CALEF",
  "CALEM",
  "CALFA",
  "CALFE",
  "CALFI",
  "CALFU",
  "CALIA",
  "CALIE",
  "CALIH",
  "CALII",
  "CALIL",
  "CALIN",
  "CALIO",
  "CALIU",
  "CALJA",
  "CALJU",
  "CALLA",
  "CALLE",
  "CALLI",
  "CALLU",
  "CALOF",
  "CALOM",
  "CALON",
  "CALOV",
  "CALUM",
  "CALVA",
  "CALVE",
  "CALVI",
  "CALVO",
  "CALVU",
  "CAMAJ",
  "CAMAR",
  "CAMBI",
  "CAMBS",
  "CAMIH",
  "CAMIL",
  "CAMJA",
  "CAMJU",
  "CAMMA",
  "CAMME",
  "CAMMI",
  "CAMMU",
  "CAMOM",
  "CAMON",
  "CAMOV",
  "CAMPA",
  "CAMPE",
  "CAMPF",
  "CAMPI",
  "CAMPO",
  "CAMPS",
  "CAMPU",
  "CAMUS",
  "CAMUZ",
  "CANAH",
  "CANAJ",
  "CANAL",
  "CANAM",
  "CANAN",
  "CANDI",
  "CANEL",
  "CANER",
  "CANET",
  "CANGA",
  "CANGE",
  "CANGI",
  "CANGO",
  "CANGU",
  "CANIA",
  "CANIČ",
  "CANIE",
  "CANIH",
  "CANII",
  "CANIK",
  "CANIN",
  "CANIO",
  "CANIU",
  "CANJA",
  "CANJU",
  "CANKA",
  "CANKE",
  "CANKI",
  "CANKU",
  "CANNA",
  "CANNE",
  "CANNI",
  "CANNU",
  "CANOM",
  "CANON",
  "CANOR",
  "CANOS",
  "CANOV",
  "CANSU",
  "CANTA",
  "CANTE",
  "CANTH",
  "CANTI",
  "CANTO",
  "CANTU",
  "CANZI",
  "CAONA",
  "CAONE",
  "CAONI",
  "CAONU",
  "CAPAH",
  "CAPAJ",
  "CAPAK",
  "CAPAL",
  "CAPAM",
  "CAPAR",
  "CAPAS",
  "CAPAŠ",
  "CAPAT",
  "CAPAU",
  "CAPAV",
  "CAPEL",
  "CAPIN",
  "CAPIZ",
  "CAPKA",
  "CAPKI",
  "CAPKU",
  "CAPLA",
  "CAPLE",
  "CAPLI",
  "CAPLU",
  "CAPON",
  "CAPPA",
  "CAPPE",
  "CAPPI",
  "CAPPO",
  "CAPPS",
  "CAPPU",
  "CAPRA",
  "CAPRE",
  "CAPRI",
  "CAPRO",
  "CAPRU",
  "CAPUS",
  "CAPUT",
  "CARAH",
  "CARAM",
  "CARAR",
  "CARDA",
  "CARDE",
  "CARDI",
  "CARDU",
  "CAREL",
  "CAREN",
  "CARER",
  "CARGA",
  "CARGO",
  "CARGU",
  "CARIA",
  "CARIB",
  "CARIC",
  "CARIČ",
  "CARIE",
  "CARIH",
  "CARII",
  "CARIN",
  "CARIO",
  "CARIS",
  "CARIU",
  "CARJA",
  "CARJE",
  "CARJI",
  "CARJU",
  "CARLA",
  "CARLE",
  "CARLI",
  "CARLO",
  "CARLU",
  "CARMA",
  "CARME",
  "CARMI",
  "CARMO",
  "CARMU",
  "CARNA",
  "CARNE",
  "CARNI",
  "CARNS",
  "CARNU",
  "CAROL",
  "CAROM",
  "CARON",
  "CAROV",
  "CARPE",
  "CARPI",
  "CARRA",
  "CARRE",
  "CARRI",
  "CARRO",
  "CARRU",
  "CARSS",
  "CARST",
  "CARTA",
  "CARTE",
  "CARTI",
  "CARTL",
  "CARTU",
  "CARUS",
  "CASAL",
  "CASAR",
  "CASAS",
  "CASCA",
  "CASCE",
  "CASCI",
  "CASCO",
  "CASCU",
  "CASHA",
  "CASHE",
  "CASHI",
  "CASHU",
  "CASIA",
  "CASIH",
  "CASIO",
  "CASIU",
  "CASJA",
  "CASJU",
  "CASOM",
  "CASON",
  "CASOV",
  "CASPE",
  "CASPI",
  "CASSA",
  "CASSE",
  "CASSI",
  "CASSU",
  "CASTA",
  "CASTE",
  "CASTI",
  "CASTO",
  "CASTU",
  "CASUL",
  "CATEL",
  "CATEN",
  "CATES",
  "CATHI",
  "CATIE",
  "CATIH",
  "CATIN",
  "CATJA",
  "CATJU",
  "CATOM",
  "CATON",
  "CATOV",
  "CATTA",
  "CATTE",
  "CATTI",
  "CATTO",
  "CATTU",
  "CAUSI",
  "CAVAD",
  "CAVAN",
  "CAVEN",
  "CAVIH",
  "CAVIN",
  "CAVJA",
  "CAVJU",
  "CAVOM",
  "CAVOV",
  "CAZAL",
  "CAZAN",
  "CAZES",
  "CAZIN",
  "CCCII",
  "CCCIV",
  "CCCLI",
  "CCCLV",
  "CCCVI",
  "CCIII",
  "CCLII",
  "CCLIV",
  "CCLVI",
  "CCVII",
  "CDIII",
  "CDLII",
  "CDLIV",
  "CDLVI",
  "CDOTU",
  "CDVII",
  "CEARA",
  "CEARE",
  "CEARI",
  "CEARO",
  "CEASA",
  "CEASE",
  "CEASI",
  "CEASU",
  "CEBAN",
  "CEBEJ",
  "CEBEK",
  "CEBIN",
  "CECAH",
  "CECAM",
  "CECCA",
  "CECCE",
  "CECCI",
  "CECCO",
  "CECCU",
  "CECIL",
  "CECIN",
  "CECON",
  "CEDAR",
  "CEDEČ",
  "CEDER",
  "CEDIK",
  "CEDIL",
  "CEDIM",
  "CEDIŠ",
  "CEDIT",
  "CEDIU",
  "CEDIV",
  "CEDRA",
  "CEDRE",
  "CEDRI",
  "CEDRO",
  "CEFIR",
  "CEFRA",
  "CEFRI",
  "CEFRU",
  "CEFTA",
  "CEFTE",
  "CEFTI",
  "CEFTO",
  "CEGEL",
  "CEGLA",
  "CEGLE",
  "CEGLI",
  "CEGLO",
  "CEGLU",
  "CEHEM",
  "CEHEV",
  "CEHIH",
  "CEHJA",
  "CEHJU",
  "CEHOM",
  "CEHOV",
  "CEHTE",
  "CEHTL",
  "CEIRI",
  "CEJAS",
  "CEJEM",
  "CEJEN",
  "CEJEV",
  "CEJIH",
  "CEJJA",
  "CEJJU",
  "CEJOM",
  "CEJOV",
  "CEKAR",
  "CEKAS",
  "CEKET",
  "CEKIČ",
  "CEKIH",
  "CEKIN",
  "CEKJA",
  "CEKJU",
  "CEKOM",
  "CEKOV",
  "CEKUM",
  "CEKUT",
  "CELAJ",
  "CELAL",
  "CELAN",
  "CELAR",
  "CELCA",
  "CELCI",
  "CELCU",
  "CELEC",
  "CELEČ",
  "CELEK",
  "CELEM",
  "CELGA",
  "CELIA",
  "CELIC",
  "CELIE",
  "CELIH",
  "CELII",
  "CELIJ",
  "CELIK",
  "CELIL",
  "CELIM",
  "CELIN",
  "CELIO",
  "CELIS",
  "CELIŠ",
  "CELIT",
  "CELIU",
  "CELIV",
  "CELJA",
  "CELJE",
  "CELJU",
  "CELKA",
  "CELKE",
  "CELKI",
  "CELKU",
  "CELLA",
  "CELLE",
  "CELLI",
  "CELLO",
  "CELLU",
  "CELOM",
  "CELOT",
  "CELOV",
  "CELSA",
  "CELSE",
  "CELSI",
  "CELSO",
  "CELSU",
  "CELTA",
  "CELTE",
  "CELTI",
  "CELTO",
  "CEMIČ",
  "CEMIH",
  "CEMIL",
  "CEMIN",
  "CEMJA",
  "CEMJU",
  "CEMOM",
  "CEMOV",
  "CENAH",
  "CENAJ",
  "CENAM",
  "CENCA",
  "CENCE",
  "CENCI",
  "CENCU",
  "CENEČ",
  "CENEN",
  "CENER",
  "CENIH",
  "CENIK",
  "CENIL",
  "CENIM",
  "CENIŠ",
  "CENIT",
  "CENIU",
  "CENIV",
  "CENJA",
  "CENJU",
  "CENKA",
  "CENKE",
  "CENKI",
  "CENKU",
  "CENNI",
  "CENOM",
  "CENOV",
  "CENSI",
  "CENTA",
  "CENTE",
  "CENTI",
  "CENTO",
  "CENTU",
  "CEPAJ",
  "CEPAK",
  "CEPAL",
  "CEPAM",
  "CEPAŠ",
  "CEPAT",
  "CEPAU",
  "CEPAV",
  "CEPCA",
  "CEPCE",
  "CEPCI",
  "CEPCU",
  "CEPEC",
  "CEPEČ",
  "CEPED",
  "CEPEK",
  "CEPET",
  "CEPIČ",
  "CEPIH",
  "CEPIL",
  "CEPIM",
  "CEPIN",
  "CEPIŠ",
  "CEPIT",
  "CEPIU",
  "CEPIV",
  "CEPJA",
  "CEPJU",
  "CEPKA",
  "CEPKE",
  "CEPKI",
  "CEPKO",
  "CEPKU",
  "CEPNE",
  "CEPNI",
  "CEPNU",
  "CEPOM",
  "CEPOV",
  "CEPTC",
  "CEPUŠ",
  "CERAD",
  "CERAJ",
  "CERAR",
  "CERDA",
  "CERDE",
  "CERDI",
  "CERDO",
  "CERDU",
  "CEREJ",
  "CEREM",
  "CERER",
  "CEREV",
  "CERFA",
  "CERFE",
  "CERFI",
  "CERFU",
  "CERGA",
  "CERGE",
  "CERGI",
  "CERGO",
  "CERGU",
  "CERIA",
  "CERIČ",
  "CERIE",
  "CERIH",
  "CERII",
  "CERIJ",
  "CERIM",
  "CERIO",
  "CERIU",
  "CERJA",
  "CERJE",
  "CERJU",
  "CERKA",
  "CERKE",
  "CERKI",
  "CERKU",
  "CERLE",
  "CERNA",
  "CERNE",
  "CERNI",
  "CERNO",
  "CERNU",
  "CEROM",
  "CERON",
  "CEROV",
  "CEROZ",
  "CERRA",
  "CERRE",
  "CERRI",
  "CERRO",
  "CERRU",
  "CERTA",
  "CERTE",
  "CERTI",
  "CERTO",
  "CERUT",
  "CERVI",
  "CESAR",
  "CESEN",
  "CESIJ",
  "CESIR",
  "CESNA",
  "CESNE",
  "CESNI",
  "CESNU",
  "CESTA",
  "CESTE",
  "CESTI",
  "CESTO",
  "CEŠKA",
  "CEŠKE",
  "CEŠKI",
  "CEŠKO",
  "CETAN",
  "CETIN",
  "CETIS",
  "CETLA",
  "CETLE",
  "CETLI",
  "CETLU",
  "CETOR",
  "CETTA",
  "CETTE",
  "CETTI",
  "CETTU",
  "CEUTA",
  "CEUTE",
  "CEUTI",
  "CEUTO",
  "CEVAČ",
  "CEVAR",
  "CEVCA",
  "CEVCE",
  "CEVCI",
  "CEVCU",
  "CEVEC",
  "CEVEH",
  "CEVEM",
  "CEVEN",
  "CEVJA",
  "CEVJE",
  "CEVJO",
  "CEVJU",
  "CEVKA",
  "CEVKE",
  "CEVKI",
  "CEVKO",
  "CEVMI",
  "CEVNA",
  "CEVNE",
  "CEVNI",
  "CEVNO",
  "CEZAR",
  "CEZEČ",
  "CEZEL",
  "CEZET",
  "CEZEU",
  "CEZIJ",
  "CEZIM",
  "CEZIŠ",
  "CEZUR",
  "CHACA",
  "CHACE",
  "CHADA",
  "CHADE",
  "CHADI",
  "CHADU",
  "CHAEL",
  "CHAFA",
  "CHAFE",
  "CHAFI",
  "CHAFU",
  "CHAGA",
  "CHAGE",
  "CHAGI",
  "CHAGU",
  "CHAIH",
  "CHAIN",
  "CHAJA",
  "CHAJU",
  "CHALK",
  "CHAMI",
  "CHAMP",
  "CHANA",
  "CHAND",
  "CHANE",
  "CHANG",
  "CHANI",
  "CHANT",
  "CHANU",
  "CHAOM",
  "CHAON",
  "CHAOV",
  "CHARA",
  "CHARE",
  "CHARI",
  "CHARL",
  "CHARO",
  "CHARU",
  "CHASA",
  "CHASE",
  "CHASI",
  "CHASU",
  "CHATA",
  "CHATE",
  "CHATI",
  "CHATT",
  "CHATU",
  "CHAZA",
  "CHAZE",
  "CHAZI",
  "CHAZU",
  "CHEAH",
  "CHECA",
  "CHECE",
  "CHECI",
  "CHECO",
  "CHECU",
  "CHEEK",
  "CHEEM",
  "CHEFA",
  "CHEFE",
  "CHEFI",
  "CHEFU",
  "CHEGA",
  "CHEGE",
  "CHEGI",
  "CHEGO",
  "CHEJA",
  "CHEJE",
  "CHEJI",
  "CHEJU",
  "CHELA",
  "CHELE",
  "CHELI",
  "CHELL",
  "CHELO",
  "CHELU",
  "CHEMA",
  "CHEME",
  "CHEMI",
  "CHEMO",
  "CHEMU",
  "CHENA",
  "CHENE",
  "CHENG",
  "CHENI",
  "CHENU",
  "CHEOK",
  "CHERI",
  "CHESS",
  "CHETA",
  "CHETE",
  "CHETI",
  "CHETU",
  "CHIAM",
  "CHIAR",
  "CHIBA",
  "CHIBE",
  "CHIBI",
  "CHIBO",
  "CHIBU",
  "CHICK",
  "CHIEM",
  "CHIES",
  "CHIGI",
  "CHIJA",
  "CHIJE",
  "CHIJI",
  "CHIJU",
  "CHILD",
  "CHILE",
  "CHILL",
  "CHINA",
  "CHINE",
  "CHING",
  "CHINI",
  "CHINN",
  "CHINO",
  "CHINU",
  "CHIPA",
  "CHIPE",
  "CHIPI",
  "CHIPU",
  "CHISA",
  "CHISE",
  "CHISI",
  "CHISM",
  "CHISO",
  "CHITI",
  "CHLOE",
  "CHOCK",
  "CHOIA",
  "CHOIE",
  "CHOII",
  "CHOIU",
  "CHOJA",
  "CHOJE",
  "CHOJI",
  "CHOJU",
  "CHOKA",
  "CHOKE",
  "CHOKI",
  "CHOKU",
  "CHONA",
  "CHOND",
  "CHONE",
  "CHONG",
  "CHONI",
  "CHONU",
  "CHOON",
  "CHOPA",
  "CHOPE",
  "CHOPI",
  "CHOPU",
  "CHORA",
  "CHORE",
  "CHORI",
  "CHORU",
  "CHOUA",
  "CHOUE",
  "CHOUI",
  "CHOUU",
  "CHRIS",
  "CHUBB",
  "CHUCK",
  "CHUGG",
  "CHUJA",
  "CHUJE",
  "CHUJI",
  "CHUJU",
  "CHUKA",
  "CHUKE",
  "CHUKI",
  "CHUKO",
  "CHUKU",
  "CHUMA",
  "CHUME",
  "CHUMI",
  "CHUMU",
  "CHUNA",
  "CHUNE",
  "CHUNG",
  "CHUNI",
  "CHUNN",
  "CHUNU",
  "CHUPP",
  "CHURA",
  "CHURG",
  "CHURM",
  "CHURU",
  "CHUST",
  "CHUTA",
  "CHUTE",
  "CHUTI",
  "CHUTU",
  "CIAMP",
  "CIANA",
  "CIANE",
  "CIANI",
  "CIANO",
  "CIANU",
  "CIARA",
  "CIARE",
  "CIARI",
  "CIARO",
  "CIBET",
  "CIBIC",
  "CIBOR",
  "CICIA",
  "CICIE",
  "CICIH",
  "CICII",
  "CICIN",
  "CICIO",
  "CICIU",
  "CICJA",
  "CICJU",
  "CICOM",
  "CICOV",
  "CIDIH",
  "CIDJA",
  "CIDJU",
  "CIDOM",
  "CIDOV",
  "CIELA",
  "CIELE",
  "CIELI",
  "CIELU",
  "CIENN",
  "CIERA",
  "CIERE",
  "CIERI",
  "CIERO",
  "CIFER",
  "CIFFT",
  "CIFOL",
  "CIFRA",
  "CIFRE",
  "CIFRI",
  "CIFRO",
  "CIGAL",
  "CIGAN",
  "CIGAR",
  "CIGOJ",
  "CIGUT",
  "CIINA",
  "CIINE",
  "CIINI",
  "CIINO",
  "CIJAK",
  "CIJAN",
  "CIJIN",
  "CIKAČ",
  "CIKAD",
  "CIKAJ",
  "CIKAL",
  "CIKAM",
  "CIKAR",
  "CIKAŠ",
  "CIKAT",
  "CIKAU",
  "CIKAV",
  "CIKEL",
  "CIKEM",
  "CIKIH",
  "CIKJA",
  "CIKJU",
  "CIKLA",
  "CIKLE",
  "CIKLI",
  "CIKLU",
  "CIKNE",
  "CIKNI",
  "CIKNU",
  "CIKOM",
  "CIKOV",
  "CILIA",
  "CILIE",
  "CILII",
  "CILIJ",
  "CILIO",
  "CILIU",
  "CILJA",
  "CILJE",
  "CILJI",
  "CILJU",
  "CILKA",
  "CILKE",
  "CILKI",
  "CILKO",
  "CILLA",
  "CILLE",
  "CILLI",
  "CILLO",
  "CILLU",
  "CILMI",
  "CIMAH",
  "CIMAM",
  "CIMAN",
  "CIMAR",
  "CIMAT",
  "CIMAZ",
  "CIMER",
  "CIMET",
  "CIMIL",
  "CIMIM",
  "CIMIR",
  "CIMIŠ",
  "CIMIT",
  "CIMIU",
  "CIMIV",
  "CIMOS",
  "CIMRA",
  "CIMRE",
  "CIMRI",
  "CIMRO",
  "CIMRU",
  "CINAH",
  "CINAM",
  "CINAR",
  "CINCA",
  "CINCI",
  "CINCU",
  "CINDA",
  "CINDE",
  "CINDI",
  "CINDO",
  "CINEČ",
  "CINEM",
  "CINIJ",
  "CINIK",
  "CINIL",
  "CINIM",
  "CINIP",
  "CINIS",
  "CINIŠ",
  "CINIT",
  "CINIU",
  "CINIV",
  "CINJA",
  "CINJU",
  "CINKA",
  "CINKE",
  "CINKI",
  "CINKO",
  "CINKU",
  "CINOM",
  "CIOAT",
  "CIOBA",
  "CIOBE",
  "CIOBI",
  "CIOBO",
  "CIOBU",
  "CIOCC",
  "CIOLI",
  "CIONI",
  "CIORB",
  "CIPAH",
  "CIPAM",
  "CIPAR",
  "CIPCI",
  "CIPER",
  "CIPES",
  "CIPIC",
  "CIPIH",
  "CIPIN",
  "CIPJA",
  "CIPJU",
  "CIPOM",
  "CIPOT",
  "CIPOV",
  "CIPRA",
  "CIPRE",
  "CIPRI",
  "CIPRO",
  "CIPRU",
  "CIRAJ",
  "CIRAR",
  "CIREJ",
  "CIRER",
  "CIRIA",
  "CIRIE",
  "CIRIH",
  "CIRII",
  "CIRIL",
  "CIRIN",
  "CIRIO",
  "CIRIU",
  "CIRJA",
  "CIRJE",
  "CIRJU",
  "CIRKA",
  "CIROM",
  "CIRON",
  "CIROV",
  "CIROZ",
  "CIRUS",
  "CISAR",
  "CISCA",
  "CISCO",
  "CISCU",
  "CISSE",
  "CISTA",
  "CISTE",
  "CISTI",
  "CISTO",
  "CITAH",
  "CITAM",
  "CITAT",
  "CITER",
  "CITIN",
  "CITRA",
  "CITRE",
  "CITRI",
  "CITRU",
  "CITTI",
  "CIUHA",
  "CIUHE",
  "CIUHI",
  "CIUHO",
  "CIUHU",
  "CIULL",
  "CIUTI",
  "CIVET",
  "CIVIC",
  "CIVIL",
  "CIVIT",
  "CIZAH",
  "CIZAM",
  "CIZAR",
  "CIZEJ",
  "CIZEK",
  "CIZEL",
  "CIZKA",
  "CIZKE",
  "CIZKI",
  "CIZKU",
  "CIZLA",
  "CIZLE",
  "CIZLI",
  "CIZLU",
  "CJUHA",
  "CJUHE",
  "CJUHI",
  "CJUHO",
  "CJUHU",
  "CLAAS",
  "CLACK",
  "CLAES",
  "CLAIN",
  "CLAIR",
  "CLAMI",
  "CLAMP",
  "CLAPP",
  "CLARA",
  "CLARE",
  "CLARI",
  "CLARK",
  "CLARO",
  "CLARU",
  "CLASA",
  "CLASE",
  "CLASI",
  "CLASS",
  "CLASU",
  "CLAUB",
  "CLAUD",
  "CLAUS",
  "CLEAH",
  "CLEAM",
  "CLEAR",
  "CLEEF",
  "CLEGG",
  "CLEIN",
  "CLEMA",
  "CLEME",
  "CLEMI",
  "CLEMM",
  "CLEMU",
  "CLERC",
  "CLERI",
  "CLERK",
  "CLEVA",
  "CLEVE",
  "CLEVI",
  "CLEVU",
  "CLIFF",
  "CLIFT",
  "CLIII",
  "CLIMA",
  "CLIME",
  "CLIMI",
  "CLIMO",
  "CLIMU",
  "CLINA",
  "CLINE",
  "CLINI",
  "CLINK",
  "CLINT",
  "CLINU",
  "CLION",
  "CLIVA",
  "CLIVE",
  "CLIVI",
  "CLIVU",
  "CLOGG",
  "CLOOS",
  "CLORA",
  "CLORE",
  "CLORI",
  "CLORU",
  "CLOSA",
  "CLOSE",
  "CLOSI",
  "CLOSS",
  "CLOSU",
  "CLOTA",
  "CLOUD",
  "CLUBA",
  "CLUBB",
  "CLUBE",
  "CLUBI",
  "CLUBU",
  "CLUER",
  "CLUFF",
  "CLUGA",
  "CLUGE",
  "CLUGI",
  "CLUGU",
  "CLUJA",
  "CLUJU",
  "CLUNA",
  "CLUNE",
  "CLUNI",
  "CLUNU",
  "CLUTA",
  "CLUTE",
  "CLUTI",
  "CLUTU",
  "CLVII",
  "CMAKA",
  "CMAKI",
  "CMAKU",
  "CMARE",
  "CMARI",
  "CMARU",
  "CMERA",
  "CMERE",
  "CMERI",
  "CMERO",
  "CMERU",
  "CMIHA",
  "CMIHI",
  "CMIHU",
  "CMIII",
  "CMIRA",
  "CMIRU",
  "CMLII",
  "CMLIV",
  "CMLVI",
  "CMOKA",
  "CMOKC",
  "CMOKE",
  "CMOKI",
  "CMOKU",
  "CMRKA",
  "CMRKE",
  "CMRKI",
  "CMRKU",
  "CMUKA",
  "CMUKE",
  "CMUKI",
  "CMUKU",
  "CMVII",
  "CNVOS",
  "COACH",
  "COADA",
  "COADE",
  "COADI",
  "COADU",
  "COANA",
  "COANE",
  "COANI",
  "COANU",
  "COARI",
  "COASA",
  "COASE",
  "COASI",
  "COAST",
  "COASU",
  "COATS",
  "COBBA",
  "COBBE",
  "COBBI",
  "COBBS",
  "COBBU",
  "COBEN",
  "COBIE",
  "COBIH",
  "COBJA",
  "COBJU",
  "COBLA",
  "COBLE",
  "COBLI",
  "COBLU",
  "COBOM",
  "COBOV",
  "COCCA",
  "COCCE",
  "COCCI",
  "COCCO",
  "COCCU",
  "COCKA",
  "COCKE",
  "COCKI",
  "COCKU",
  "CODDA",
  "CODDE",
  "CODDI",
  "CODDU",
  "CODEE",
  "CODIE",
  "CODOL",
  "COECK",
  "COEJA",
  "COEJE",
  "COEJI",
  "COEJU",
  "COELA",
  "COELE",
  "COELI",
  "COELN",
  "COELU",
  "COENA",
  "COENE",
  "COENI",
  "COENU",
  "COFEK",
  "COFEM",
  "COFEV",
  "COFIH",
  "COFJA",
  "COFJU",
  "COFKA",
  "COFKE",
  "COFKI",
  "COFKU",
  "COFOM",
  "COFOV",
  "COGAN",
  "COHAN",
  "COHEN",
  "COHLA",
  "COHLE",
  "COHLI",
  "COHLU",
  "COHNA",
  "COHNE",
  "COHNI",
  "COHNU",
  "COHON",
  "COHRS",
  "COIDA",
  "COIDE",
  "COIDI",
  "COIDU",
  "COIFA",
  "COIFE",
  "COIFI",
  "COIFU",
  "COINA",
  "COINE",
  "COINI",
  "COINU",
  "COITA",
  "COITE",
  "COITI",
  "COITO",
  "COITU",
  "COJZA",
  "COJZE",
  "COJZI",
  "COJZU",
  "COKAL",
  "COKAN",
  "COKEL",
  "COKES",
  "COKIH",
  "COKJA",
  "COKJU",
  "COKLA",
  "COKLE",
  "COKLI",
  "COKLO",
  "COKLU",
  "COKOJ",
  "COKOM",
  "COKOV",
  "COLAH",
  "COLAK",
  "COLAM",
  "COLAS",
  "COLAU",
  "COLBI",
  "COLCA",
  "COLCE",
  "COLCI",
  "COLCU",
  "COLDA",
  "COLDE",
  "COLDI",
  "COLDU",
  "COLEC",
  "COLES",
  "COLET",
  "COLIH",
  "COLIN",
  "COLJA",
  "COLJE",
  "COLJI",
  "COLJO",
  "COLJU",
  "COLKA",
  "COLKE",
  "COLKI",
  "COLKO",
  "COLLA",
  "COLLE",
  "COLLI",
  "COLLO",
  "COLLS",
  "COLLU",
  "COLMA",
  "COLME",
  "COLMI",
  "COLMU",
  "COLOM",
  "COLON",
  "COLOR",
  "COLOV",
  "COLPA",
  "COLPE",
  "COLPI",
  "COLPU",
  "COLTA",
  "COLTE",
  "COLTI",
  "COLTS",
  "COLTU",
  "COMAN",
  "COMBA",
  "COMBE",
  "COMBI",
  "COMBS",
  "COMBU",
  "COMET",
  "COMIK",
  "COMIN",
  "COMJA",
  "COMJU",
  "COMKA",
  "COMKE",
  "COMKI",
  "COMKO",
  "COMKU",
  "COMOM",
  "COMOV",
  "COMTA",
  "COMTE",
  "COMTI",
  "COMTU",
  "CONAH",
  "CONAM",
  "CONAN",
  "CONCH",
  "CONCI",
  "CONDA",
  "CONDE",
  "CONDI",
  "CONDO",
  "CONDU",
  "CONES",
  "CONGI",
  "CONIL",
  "CONLI",
  "CONNA",
  "CONNE",
  "CONNI",
  "CONNU",
  "CONOR",
  "CONTA",
  "CONTE",
  "CONTI",
  "CONTO",
  "CONTU",
  "CONZA",
  "CONZE",
  "CONZI",
  "CONZO",
  "CONZU",
  "COOKA",
  "COOKE",
  "COOKI",
  "COOKS",
  "COOKU",
  "COOLA",
  "COOLE",
  "COOLI",
  "COOLS",
  "COOLU",
  "COONA",
  "COONE",
  "COONI",
  "COONS",
  "COONU",
  "COOPA",
  "COOPU",
  "COORS",
  "COOTA",
  "COOTE",
  "COOTI",
  "COOTU",
  "COPAK",
  "COPAT",
  "COPIA",
  "COPIE",
  "COPIH",
  "COPII",
  "COPIO",
  "COPJA",
  "COPJU",
  "COPOM",
  "COPON",
  "COPOT",
  "COPOV",
  "COPPA",
  "COPPE",
  "COPPI",
  "COPPO",
  "COPPU",
  "COPRA",
  "COPRI",
  "COPRU",
  "CORAH",
  "CORAM",
  "CORBI",
  "CORDA",
  "CORDE",
  "CORDI",
  "CORDO",
  "CORDT",
  "CORDU",
  "COREL",
  "CORER",
  "CORIA",
  "CORIE",
  "CORIH",
  "CORII",
  "CORIJ",
  "CORIN",
  "CORIO",
  "CORIS",
  "CORIU",
  "CORJA",
  "CORJU",
  "CORKA",
  "CORKE",
  "CORKI",
  "CORKU",
  "CORNA",
  "CORNE",
  "CORNI",
  "CORNS",
  "CORNU",
  "COROM",
  "COROV",
  "CORRA",
  "CORRE",
  "CORRI",
  "CORRO",
  "CORRU",
  "CORSA",
  "CORSE",
  "CORSI",
  "CORSO",
  "CORSU",
  "CORTA",
  "CORTE",
  "CORTI",
  "CORTO",
  "CORTU",
  "CORVA",
  "CORVE",
  "CORVI",
  "CORVO",
  "CORVU",
  "COSCA",
  "COSCI",
  "COSCO",
  "COSCU",
  "COSIM",
  "COSMA",
  "COSME",
  "COSMI",
  "COSMO",
  "COSMU",
  "COSSI",
  "COSSU",
  "COSTA",
  "COSTE",
  "COSTI",
  "COSTO",
  "COSTU",
  "COSUN",
  "COTAH",
  "COTAM",
  "COTAN",
  "COTAR",
  "COTIČ",
  "COTIH",
  "COTJA",
  "COTJU",
  "COTOM",
  "COTOV",
  "COTTA",
  "COTTE",
  "COTTI",
  "COTTO",
  "COTTU",
  "COUCA",
  "COUCE",
  "COUCI",
  "COUCO",
  "COUCU",
  "COUPA",
  "COUPE",
  "COUPI",
  "COUPU",
  "COURT",
  "COUTA",
  "COUTE",
  "COUTI",
  "COUTO",
  "COUTU",
  "COVAN",
  "COVAS",
  "COVID",
  "COVIH",
  "COVJA",
  "COVJU",
  "COVOM",
  "COVOV",
  "COZZI",
  "CRAAN",
  "CRABB",
  "CRACA",
  "CRACE",
  "CRAFT",
  "CRAGG",
  "CRAIB",
  "CRAIG",
  "CRAIK",
  "CRAIN",
  "CRAIT",
  "CRAMA",
  "CRAME",
  "CRAMI",
  "CRAMM",
  "CRAMP",
  "CRAMU",
  "CRANA",
  "CRANE",
  "CRANI",
  "CRANK",
  "CRANN",
  "CRANO",
  "CRANS",
  "CRANU",
  "CRAPA",
  "CRAPE",
  "CRAPI",
  "CRAPO",
  "CRAPP",
  "CRAPU",
  "CRAST",
  "CRAZA",
  "CRAZE",
  "CRAZI",
  "CRAZU",
  "CREAN",
  "CREDE",
  "CREED",
  "CREEK",
  "CREEL",
  "CREGA",
  "CREGE",
  "CREGG",
  "CREGI",
  "CREGO",
  "CREGU",
  "CRENN",
  "CRESA",
  "CRESS",
  "CRESU",
  "CRETI",
  "CRETU",
  "CRIBB",
  "CRICK",
  "CRIFA",
  "CRIFE",
  "CRIFI",
  "CRIFO",
  "CRIFU",
  "CRIMA",
  "CRIME",
  "CRIMI",
  "CRIMO",
  "CRIMP",
  "CRIMU",
  "CRING",
  "CRIPP",
  "CRISA",
  "CRISE",
  "CRISI",
  "CRISP",
  "CRISS",
  "CRIST",
  "CRISU",
  "CRKNE",
  "CRKNI",
  "CRKNU",
  "CRKUJ",
  "CRNEK",
  "CRNIČ",
  "CRNOJ",
  "CROAK",
  "CROCE",
  "CROCI",
  "CROES",
  "CROFT",
  "CROHN",
  "CROLL",
  "CROMA",
  "CROME",
  "CROMI",
  "CROMU",
  "CRONA",
  "CRONE",
  "CRONI",
  "CRONK",
  "CRONU",
  "CROOK",
  "CROOM",
  "CROOT",
  "CROSS",
  "CROUS",
  "CROZZ",
  "CRUDA",
  "CRUDE",
  "CRUDI",
  "CRUDO",
  "CRUDU",
  "CRUMA",
  "CRUMB",
  "CRUME",
  "CRUMI",
  "CRUMM",
  "CRUMP",
  "CRUMU",
  "CRUPI",
  "CRUSA",
  "CRUSE",
  "CRUSI",
  "CRUSU",
  "CRUZA",
  "CRUZE",
  "CRUZI",
  "CRUZU",
  "CSABA",
  "CSABE",
  "CSABI",
  "CSABO",
  "CSABU",
  "CSAKA",
  "CSAKE",
  "CSAKI",
  "CSAKU",
  "CSEHA",
  "CSEHE",
  "CSEHI",
  "CSEHU",
  "CSIBI",
  "CSKJA",
  "CSOKA",
  "CSOKE",
  "CSOKI",
  "CSOKO",
  "CSOKU",
  "CSOTU",
  "CSUTH",
  "CTOTA",
  "CUBAH",
  "CUBAM",
  "CUBAN",
  "CUBBA",
  "CUBBE",
  "CUBBI",
  "CUBBU",
  "CUBER",
  "CUBIH",
  "CUBIS",
  "CUBJA",
  "CUBJU",
  "CUBOM",
  "CUBOV",
  "CUBRA",
  "CUBRU",
  "CUCAL",
  "CUCCI",
  "CUCEK",
  "CUCHA",
  "CUCHE",
  "CUCHI",
  "CUCHU",
  "CUCKA",
  "CUCKE",
  "CUCKI",
  "CUCKU",
  "CUDDA",
  "CUDDE",
  "CUDDI",
  "CUDDU",
  "CUDER",
  "CUDRA",
  "CUDRE",
  "CUDRI",
  "CUDRU",
  "CUEFF",
  "CUEJA",
  "CUEJE",
  "CUEJI",
  "CUEJU",
  "CUENC",
  "CUENI",
  "CUEST",
  "CUETA",
  "CUETE",
  "CUETI",
  "CUETO",
  "CUETU",
  "CUFAJ",
  "CUFAL",
  "CUFAM",
  "CUFAŠ",
  "CUFAT",
  "CUFAU",
  "CUFAV",
  "CUFFA",
  "CUFFE",
  "CUFFI",
  "CUFFU",
  "CUHAN",
  "CUKAJ",
  "CUKAL",
  "CUKAM",
  "CUKAŠ",
  "CUKAT",
  "CUKAU",
  "CUKAV",
  "CUKER",
  "CUKIH",
  "CUKJA",
  "CUKJU",
  "CUKNE",
  "CUKNI",
  "CUKNU",
  "CUKOM",
  "CUKOR",
  "CUKOV",
  "CUKRA",
  "CUKRI",
  "CUKRU",
  "CULAH",
  "CULAJ",
  "CULAM",
  "CULIC",
  "CULIK",
  "CULIN",
  "CULLA",
  "CULLE",
  "CULLI",
  "CULLU",
  "CULPA",
  "CULPE",
  "CULPI",
  "CULPO",
  "CULPU",
  "CUMIN",
  "CUNHA",
  "CUNHE",
  "CUNHI",
  "CUNHO",
  "CUNHU",
  "CUNJA",
  "CUNJE",
  "CUNJI",
  "CUNJO",
  "CUNKA",
  "CUNKE",
  "CUNKI",
  "CUNKU",
  "CUNOD",
  "CUNTA",
  "CUNTE",
  "CUNTI",
  "CUNTO",
  "CUNTU",
  "CUOMA",
  "CUOME",
  "CUOMI",
  "CUOMO",
  "CUOMU",
  "CUPAR",
  "CUPIN",
  "CUPRA",
  "CUPRE",
  "CUPRI",
  "CUPRO",
  "CURAH",
  "CURAM",
  "CURAN",
  "CURCI",
  "CURDA",
  "CURDE",
  "CURDI",
  "CURDO",
  "CURDU",
  "CUREČ",
  "CUREK",
  "CUREL",
  "CURET",
  "CUREU",
  "CURIA",
  "CURIE",
  "CURIH",
  "CURII",
  "CURIJ",
  "CURIM",
  "CURIO",
  "CURIŠ",
  "CURIU",
  "CURJA",
  "CURJU",
  "CURKA",
  "CURKE",
  "CURKI",
  "CURKU",
  "CURLA",
  "CURLE",
  "CURLI",
  "CURLU",
  "CUROM",
  "CUROV",
  "CURRA",
  "CURRE",
  "CURRI",
  "CURRO",
  "CURRU",
  "CURTA",
  "CURTE",
  "CURTI",
  "CURTO",
  "CURTU",
  "CURZI",
  "CUSAK",
  "CUSIH",
  "CUSJA",
  "CUSJU",
  "CUSOM",
  "CUSOV",
  "CUTTS",
  "CUZAJ",
  "CUZAK",
  "CUZAL",
  "CUZAM",
  "CUZAŠ",
  "CUZAT",
  "CUZAU",
  "CUZAV",
  "CUZIK",
  "CUZZI",
  "CVACK",
  "CVARA",
  "CVARE",
  "CVARI",
  "CVARU",
  "CVEKA",
  "CVEKE",
  "CVEKI",
  "CVEKU",
  "CVELA",
  "CVELE",
  "CVELI",
  "CVELO",
  "CVENA",
  "CVENK",
  "CVENU",
  "CVEST",
  "CVETA",
  "CVETE",
  "CVETI",
  "CVETK",
  "CVETO",
  "CVETU",
  "CVIII",
  "CVIKA",
  "CVIKE",
  "CVIKI",
  "CVIKL",
  "CVIKO",
  "CVIKU",
  "CVILI",
  "CVILU",
  "CVIRN",
  "CVRČE",
  "CVRČI",
  "CVRČU",
  "CVREM",
  "CVREŠ",
  "CVRET",
  "CVRKA",
  "CVRKE",
  "CVRKI",
  "CVRKU",
  "CVRLA",
  "CVRLE",
  "CVRLI",
  "CVRLO",
  "CVRLU",
  "CVROČ",
  "CVRTA",
  "CVRTE",
  "CVRTI",
  "CVRTO",
  "CZACK",
  "CZECH",
  "CZEPL",
  "CZUBA",
  "CZUBE",
  "CZUBI",
  "CZUBO",
  "CZUBU",
  "ČABAR",
  "ČABAT",
  "ČABRA",
  "ČABRU",
  "ČACEV",
  "ČAČAH",
  "ČAČAK",
  "ČAČAM",
  "ČAČEM",
  "ČAČEV",
  "ČAČIČ",
  "ČAČIH",
  "ČAČIN",
  "ČAČJA",
  "ČAČJU",
  "ČAČKA",
  "ČAČKE",
  "ČAČKI",
  "ČAČKO",
  "ČAČKU",
  "ČAČOM",
  "ČAČOV",
  "ČADAV",
  "ČADEJ",
  "ČADEM",
  "ČADEŽ",
  "ČADIH",
  "ČADJA",
  "ČADJU",
  "ČADOM",
  "ČADOR",
  "ČADOV",
  "ČADRG",
  "ČAGAH",
  "ČAGAM",
  "ČAHUK",
  "ČAJEM",
  "ČAJEN",
  "ČAJEV",
  "ČAJIH",
  "ČAJJA",
  "ČAJJU",
  "ČAJKA",
  "ČAJKE",
  "ČAJKI",
  "ČAJKO",
  "ČAJNA",
  "ČAJNE",
  "ČAJNI",
  "ČAJNO",
  "ČAJOM",
  "ČAJOT",
  "ČAJOV",
  "ČAKAH",
  "ČAKAJ",
  "ČAKAL",
  "ČAKAM",
  "ČAKAŠ",
  "ČAKAT",
  "ČAKAU",
  "ČAKAV",
  "ČAKEM",
  "ČAKER",
  "ČAKMA",
  "ČAKME",
  "ČAKMI",
  "ČAKMO",
  "ČAKRA",
  "ČAKRE",
  "ČAKRI",
  "ČAKRO",
  "ČAKSA",
  "ČAKSE",
  "ČAKSI",
  "ČAKSU",
  "ČAKŠA",
  "ČAKŠE",
  "ČAKŠI",
  "ČAKŠU",
  "ČALAH",
  "ČALAM",
  "ČALET",
  "ČALIČ",
  "ČALIH",
  "ČALJA",
  "ČALJU",
  "ČALOM",
  "ČALOV",
  "ČAMPA",
  "ČAMPE",
  "ČAMPI",
  "ČAMPO",
  "ČAMPU",
  "ČANAK",
  "ČANČA",
  "ČANČE",
  "ČANČI",
  "ČANČU",
  "ČANJA",
  "ČANJE",
  "ČANJI",
  "ČANJU",
  "ČAPCA",
  "ČAPCE",
  "ČAPCI",
  "ČAPCU",
  "ČAPEC",
  "ČAPEK",
  "ČAPIH",
  "ČAPIN",
  "ČAPJA",
  "ČAPJU",
  "ČAPKA",
  "ČAPKE",
  "ČAPKI",
  "ČAPKO",
  "ČAPKU",
  "ČAPOM",
  "ČAPOV",
  "ČARAJ",
  "ČARAL",
  "ČARAM",
  "ČARAŠ",
  "ČARAT",
  "ČARAU",
  "ČARAV",
  "ČARDA",
  "ČARDE",
  "ČARDI",
  "ČARDO",
  "ČAREM",
  "ČAREN",
  "ČAREV",
  "ČARFA",
  "ČARFE",
  "ČARFI",
  "ČARFU",
  "ČARGA",
  "ČARGE",
  "ČARGI",
  "ČARGO",
  "ČARGU",
  "ČARIH",
  "ČARIL",
  "ČARJA",
  "ČARJU",
  "ČARLI",
  "ČARNA",
  "ČARNE",
  "ČARNI",
  "ČARNO",
  "ČAROM",
  "ČAROV",
  "ČASAR",
  "ČASEK",
  "ČASEM",
  "ČASEN",
  "ČASEV",
  "ČASIH",
  "ČASIV",
  "ČASJA",
  "ČASJU",
  "ČASKA",
  "ČASKE",
  "ČASKI",
  "ČASKU",
  "ČASLA",
  "ČASLE",
  "ČASLI",
  "ČASLU",
  "ČASNA",
  "ČASNE",
  "ČASNI",
  "ČASNO",
  "ČASOH",
  "ČASOM",
  "ČASOV",
  "ČASTE",
  "ČASTI",
  "ČASTU",
  "ČAŠAH",
  "ČAŠAM",
  "ČAŠEN",
  "ČAŠIC",
  "ČAŠNA",
  "ČAŠNE",
  "ČAŠNI",
  "ČAŠNO",
  "ČATAR",
  "ČATER",
  "ČATEŽ",
  "ČATRA",
  "ČATRE",
  "ČATRI",
  "ČATRU",
  "ČAUSA",
  "ČAUSE",
  "ČAUSI",
  "ČAUSU",
  "ČAUŠA",
  "ČAUŠE",
  "ČAUŠI",
  "ČAUŠU",
  "ČAVAL",
  "ČAVAR",
  "ČAVEN",
  "ČAVIČ",
  "ČAVKA",
  "ČAVKE",
  "ČAVKI",
  "ČAVKO",
  "ČAVKU",
  "ČAVNA",
  "ČAVNU",
  "ČAVRK",
  "ČAVŠA",
  "ČAVŠE",
  "ČAVŠI",
  "ČAVŠU",
  "ČAVŽA",
  "ČAVŽE",
  "ČAVŽI",
  "ČAVŽU",
  "ČAZMA",
  "ČAZME",
  "ČAZMI",
  "ČAZMO",
  "ČEAKA",
  "ČEAKE",
  "ČEAKI",
  "ČEAKU",
  "ČEBEL",
  "ČEBER",
  "ČEBIN",
  "ČEBRA",
  "ČEBRE",
  "ČEBRI",
  "ČEBRU",
  "ČEBUL",
  "ČECEV",
  "ČEČAH",
  "ČEČAM",
  "ČEČAN",
  "ČEČEK",
  "ČEČEM",
  "ČEČEN",
  "ČEČEV",
  "ČEČIH",
  "ČEČJA",
  "ČEČJU",
  "ČEČKA",
  "ČEČKE",
  "ČEČKI",
  "ČEČKO",
  "ČEČKU",
  "ČEČOM",
  "ČEČOV",
  "ČEČUK",
  "ČEDAD",
  "ČEDAR",
  "ČEDAZ",
  "ČEDEČ",
  "ČEDEJ",
  "ČEDEM",
  "ČEDEN",
  "ČEDER",
  "ČEDIH",
  "ČEDIL",
  "ČEDIM",
  "ČEDIŠ",
  "ČEDIT",
  "ČEDIU",
  "ČEDIV",
  "ČEDJA",
  "ČEDJU",
  "ČEDMA",
  "ČEDMU",
  "ČEDNA",
  "ČEDNE",
  "ČEDNI",
  "ČEDNO",
  "ČEDOM",
  "ČEDOV",
  "ČEDRA",
  "ČEDRE",
  "ČEDRI",
  "ČEDRN",
  "ČEDRO",
  "ČEFUR",
  "ČEHIČ",
  "ČEHIH",
  "ČEHJA",
  "ČEHJU",
  "ČEHOK",
  "ČEHOM",
  "ČEHOV",
  "ČEHUN",
  "ČEKAD",
  "ČEKAN",
  "ČEKEM",
  "ČEKEV",
  "ČEKIH",
  "ČEKJA",
  "ČEKJU",
  "ČEKOM",
  "ČEKON",
  "ČEKOV",
  "ČEKUT",
  "ČELAD",
  "ČELAH",
  "ČELAM",
  "ČELAN",
  "ČELAR",
  "ČELAT",
  "ČELCA",
  "ČELCE",
  "ČELCI",
  "ČELCU",
  "ČELEC",
  "ČELEM",
  "ČELEN",
  "ČELEŠ",
  "ČELEV",
  "ČELIČ",
  "ČELIG",
  "ČELIH",
  "ČELIK",
  "ČELJA",
  "ČELJE",
  "ČELJU",
  "ČELKA",
  "ČELKE",
  "ČELKI",
  "ČELKO",
  "ČELKU",
  "ČELNA",
  "ČELNE",
  "ČELNI",
  "ČELNO",
  "ČELOM",
  "ČELOV",
  "ČEMAN",
  "ČEMAS",
  "ČEMAŽ",
  "ČEMEČ",
  "ČEMEL",
  "ČEMER",
  "ČEMET",
  "ČEMEU",
  "ČEMIM",
  "ČEMIŠ",
  "ČEMŠE",
  "ČEMUR",
  "ČENAR",
  "ČENČA",
  "ČENČE",
  "ČENČI",
  "ČENČO",
  "ČENČU",
  "ČENTA",
  "ČENTE",
  "ČENTI",
  "ČENTO",
  "ČEPAH",
  "ČEPAM",
  "ČEPAR",
  "ČEPEČ",
  "ČEPEK",
  "ČEPEL",
  "ČEPEM",
  "ČEPEN",
  "ČEPET",
  "ČEPEU",
  "ČEPEV",
  "ČEPIC",
  "ČEPIČ",
  "ČEPIG",
  "ČEPIH",
  "ČEPIM",
  "ČEPIN",
  "ČEPIŠ",
  "ČEPJA",
  "ČEPJU",
  "ČEPKA",
  "ČEPKE",
  "ČEPKI",
  "ČEPKU",
  "ČEPNA",
  "ČEPNE",
  "ČEPNI",
  "ČEPNO",
  "ČEPNU",
  "ČEPOM",
  "ČEPON",
  "ČEPOV",
  "ČEREH",
  "ČEREM",
  "ČEREN",
  "ČERIČ",
  "ČERIH",
  "ČERIN",
  "ČERJA",
  "ČERJO",
  "ČERJU",
  "ČERMI",
  "ČERNE",
  "ČERNI",
  "ČEROM",
  "ČEROV",
  "ČERVA",
  "ČERVE",
  "ČERVI",
  "ČERVU",
  "ČESAL",
  "ČESAN",
  "ČESAR",
  "ČESAT",
  "ČESAU",
  "ČESAV",
  "ČESEN",
  "ČESNA",
  "ČESNE",
  "ČESNI",
  "ČESNU",
  "ČESTA",
  "ČESTE",
  "ČESTI",
  "ČESTO",
  "ČEŠČA",
  "ČEŠČE",
  "ČEŠČI",
  "ČEŠČO",
  "ČEŠEM",
  "ČEŠEŠ",
  "ČEŠKA",
  "ČEŠKE",
  "ČEŠKI",
  "ČEŠKO",
  "ČEŠKU",
  "ČEŠOČ",
  "ČETAH",
  "ČETAM",
  "ČETEN",
  "ČETEŽ",
  "ČETIC",
  "ČETIN",
  "ČETNA",
  "ČETNE",
  "ČETNI",
  "ČETNO",
  "ČETRT",
  "ČEVKA",
  "ČEVKE",
  "ČEVKI",
  "ČEVKO",
  "ČEVKU",
  "ČIBEJ",
  "ČIBUK",
  "ČICEV",
  "ČIČAH",
  "ČIČAJ",
  "ČIČAK",
  "ČIČAL",
  "ČIČAM",
  "ČIČAŠ",
  "ČIČAT",
  "ČIČAU",
  "ČIČAV",
  "ČIČEK",
  "ČIČEM",
  "ČIČEV",
  "ČIČIH",
  "ČIČIN",
  "ČIČJA",
  "ČIČJU",
  "ČIČKA",
  "ČIČKE",
  "ČIČKI",
  "ČIČKO",
  "ČIČKU",
  "ČIČOM",
  "ČIČOV",
  "ČIGAR",
  "ČIGAV",
  "ČIGER",
  "ČIGIR",
  "ČIGOJ",
  "ČIGON",
  "ČIGRA",
  "ČIGRE",
  "ČIGRI",
  "ČIGRO",
  "ČIIJO",
  "ČIJAH",
  "ČIJAM",
  "ČIJEM",
  "ČIJJA",
  "ČIJJU",
  "ČIJOM",
  "ČIKAR",
  "ČIKEM",
  "ČIKEŠ",
  "ČIKEV",
  "ČIKIH",
  "ČIKJA",
  "ČIKJU",
  "ČIKOM",
  "ČIKOV",
  "ČILAH",
  "ČILAM",
  "ČILEM",
  "ČILGA",
  "ČILIH",
  "ČILIM",
  "ČILJA",
  "ČILJU",
  "ČILOM",
  "ČIMER",
  "ČIMŽA",
  "ČIMŽE",
  "ČIMŽI",
  "ČIMŽO",
  "ČIMŽU",
  "ČINČA",
  "ČINČE",
  "ČINČI",
  "ČINČU",
  "ČINEL",
  "ČINEM",
  "ČINEV",
  "ČINIH",
  "ČINJA",
  "ČINJU",
  "ČINOM",
  "ČINOV",
  "ČIOVA",
  "ČIOVO",
  "ČIOVU",
  "ČIPEM",
  "ČIPEN",
  "ČIPEV",
  "ČIPIH",
  "ČIPIN",
  "ČIPJA",
  "ČIPJU",
  "ČIPKA",
  "ČIPKE",
  "ČIPKI",
  "ČIPKO",
  "ČIPNA",
  "ČIPNE",
  "ČIPNI",
  "ČIPNO",
  "ČIPOM",
  "ČIPOV",
  "ČIPSA",
  "ČIPSE",
  "ČIPSI",
  "ČIPSU",
  "ČIRAV",
  "ČIRČE",
  "ČIREM",
  "ČIREV",
  "ČIRIČ",
  "ČIRIH",
  "ČIRJA",
  "ČIRJU",
  "ČIRKA",
  "ČIRKE",
  "ČIRKI",
  "ČIRKO",
  "ČIRKU",
  "ČIROM",
  "ČIROV",
  "ČISEL",
  "ČISLA",
  "ČISLI",
  "ČISLO",
  "ČISLU",
  "ČISTA",
  "ČISTC",
  "ČISTE",
  "ČISTI",
  "ČISTK",
  "ČISTO",
  "ČISTU",
  "ČIŠČA",
  "ČIŠČE",
  "ČIŠČI",
  "ČIŠČO",
  "ČIŠKA",
  "ČIŠKE",
  "ČIŠKI",
  "ČIŠKO",
  "ČITAJ",
  "ČITAL",
  "ČITAM",
  "ČITAN",
  "ČITAŠ",
  "ČITAT",
  "ČITAU",
  "ČITAV",
  "ČIVAV",
  "ČIVKA",
  "ČIVKE",
  "ČIVKI",
  "ČIVKU",
  "ČIZOV",
  "ČIŽEK",
  "ČIŽEM",
  "ČIŽEV",
  "ČIŽIČ",
  "ČIŽIH",
  "ČIŽJA",
  "ČIŽJU",
  "ČIŽKA",
  "ČIŽKE",
  "ČIŽKI",
  "ČIŽKU",
  "ČIŽMA",
  "ČIŽME",
  "ČIŽMI",
  "ČIŽMU",
  "ČIŽOM",
  "ČIŽOV",
  "ČLANA",
  "ČLANE",
  "ČLANI",
  "ČLANU",
  "ČLENA",
  "ČLENE",
  "ČLENI",
  "ČLENU",
  "ČMAJA",
  "ČMAJE",
  "ČMAJI",
  "ČMAJU",
  "ČMERA",
  "ČMERE",
  "ČMERI",
  "ČMERU",
  "ČMILA",
  "ČMILE",
  "ČMILI",
  "ČMILU",
  "ČMRLJ",
  "ČOBAL",
  "ČOBCA",
  "ČOBCE",
  "ČOBCI",
  "ČOBCU",
  "ČOBEC",
  "ČOBER",
  "ČOBIH",
  "ČOBJA",
  "ČOBJU",
  "ČOBOM",
  "ČOBOV",
  "ČOBRA",
  "ČOBRE",
  "ČOBRI",
  "ČOBRU",
  "ČOFAJ",
  "ČOFAL",
  "ČOFAM",
  "ČOFAŠ",
  "ČOFAT",
  "ČOFAU",
  "ČOFAV",
  "ČOFNE",
  "ČOFNI",
  "ČOFNU",
  "ČOHAJ",
  "ČOHAL",
  "ČOHAM",
  "ČOHAŠ",
  "ČOHAT",
  "ČOHAU",
  "ČOHAV",
  "ČOHIH",
  "ČOHJA",
  "ČOHJU",
  "ČOHOM",
  "ČOHOV",
  "ČOKAN",
  "ČOKAR",
  "ČOKAT",
  "ČOKEM",
  "ČOKET",
  "ČOKEV",
  "ČOKIH",
  "ČOKJA",
  "ČOKJU",
  "ČOKLA",
  "ČOKLE",
  "ČOKLI",
  "ČOKLU",
  "ČOKOM",
  "ČOKOV",
  "ČOLAK",
  "ČOLIG",
  "ČOLIN",
  "ČOLNA",
  "ČOLNE",
  "ČOLNI",
  "ČOLNU",
  "ČONČA",
  "ČONČE",
  "ČONČI",
  "ČONČU",
  "ČONDA",
  "ČONDE",
  "ČONDI",
  "ČONDO",
  "ČONDU",
  "ČOPAR",
  "ČOPEK",
  "ČOPEM",
  "ČOPEV",
  "ČOPIČ",
  "ČOPIH",
  "ČOPJA",
  "ČOPJU",
  "ČOPKA",
  "ČOPKE",
  "ČOPKI",
  "ČOPKO",
  "ČOPKU",
  "ČOPOM",
  "ČOPOV",
  "ČORAK",
  "ČORAV",
  "ČORBA",
  "ČORBE",
  "ČORBI",
  "ČORBO",
  "ČORIČ",
  "ČOTAR",
  "ČOTIH",
  "ČOTJA",
  "ČOTJU",
  "ČOTOM",
  "ČOTOV",
  "ČOVEN",
  "ČOVIČ",
  "ČOVNA",
  "ČOVNE",
  "ČOVNI",
  "ČOVNU",
  "ČOŽEM",
  "ČOŽEV",
  "ČOŽIH",
  "ČOŽJA",
  "ČOŽJU",
  "ČOŽOM",
  "ČOŽOV",
  "ČRČEK",
  "ČRČKA",
  "ČRČKE",
  "ČRČKI",
  "ČRČKU",
  "ČREDA",
  "ČREDE",
  "ČREDI",
  "ČREDO",
  "ČREMS",
  "ČREPA",
  "ČREPE",
  "ČREPI",
  "ČREPU",
  "ČRETA",
  "ČRETE",
  "ČRETI",
  "ČRETO",
  "ČRETU",
  "ČREVA",
  "ČREVC",
  "ČREVI",
  "ČREVO",
  "ČREVU",
  "ČRHNE",
  "ČRHNI",
  "ČRHNU",
  "ČRIČE",
  "ČRIČI",
  "ČRIČU",
  "ČRIKA",
  "ČRIKI",
  "ČRIKU",
  "ČRKAH",
  "ČRKAM",
  "ČRKIC",
  "ČRKIJ",
  "ČRKJA",
  "ČRKJE",
  "ČRKJI",
  "ČRKJU",
  "ČRKUJ",
  "ČRNAČ",
  "ČRNAK",
  "ČRNAV",
  "ČRNCA",
  "ČRNCE",
  "ČRNCI",
  "ČRNCU",
  "ČRNČK",
  "ČRNEC",
  "ČRNEČ",
  "ČRNEJ",
  "ČRNEL",
  "ČRNEM",
  "ČRNET",
  "ČRNEU",
  "ČRNGA",
  "ČRNIC",
  "ČRNIČ",
  "ČRNIH",
  "ČRNIK",
  "ČRNIL",
  "ČRNIM",
  "ČRNIN",
  "ČRNIŠ",
  "ČRNIT",
  "ČRNIU",
  "ČRNIV",
  "ČRNJO",
  "ČRNKA",
  "ČRNKE",
  "ČRNKI",
  "ČRNKO",
  "ČRNKU",
  "ČRNOB",
  "ČRNOG",
  "ČRNUČ",
  "ČRNUH",
  "ČRPAJ",
  "ČRPAL",
  "ČRPAM",
  "ČRPAN",
  "ČRPAŠ",
  "ČRPAT",
  "ČRPAU",
  "ČRPAV",
  "ČRPIČ",
  "ČRTAH",
  "ČRTAJ",
  "ČRTAL",
  "ČRTAM",
  "ČRTAN",
  "ČRTAŠ",
  "ČRTAT",
  "ČRTAU",
  "ČRTAV",
  "ČRTEČ",
  "ČRTEN",
  "ČRTEŽ",
  "ČRTIC",
  "ČRTIH",
  "ČRTIL",
  "ČRTIM",
  "ČRTIŠ",
  "ČRTIT",
  "ČRTIU",
  "ČRTIV",
  "ČRTJA",
  "ČRTJU",
  "ČRTKA",
  "ČRTKE",
  "ČRTKI",
  "ČRTKO",
  "ČRTKU",
  "ČRTNA",
  "ČRTNE",
  "ČRTNI",
  "ČRTNO",
  "ČRTOM",
  "ČRTOV",
  "ČRTUH",
  "ČRVAR",
  "ČRVEM",
  "ČRVEV",
  "ČRVIČ",
  "ČRVIH",
  "ČRVIN",
  "ČRVIV",
  "ČRVJA",
  "ČRVJE",
  "ČRVJI",
  "ČRVJO",
  "ČRVJU",
  "ČRVOM",
  "ČRVOV",
  "ČTIVA",
  "ČTIVI",
  "ČTIVO",
  "ČTIVU",
  "ČUBEJ",
  "ČUBER",
  "ČUBRA",
  "ČUBRE",
  "ČUBRI",
  "ČUBRU",
  "ČUČEK",
  "ČUČJA",
  "ČUČJE",
  "ČUČJI",
  "ČUČJO",
  "ČUČKA",
  "ČUČKE",
  "ČUČKI",
  "ČUČKO",
  "ČUČKU",
  "ČUČUK",
  "ČUDAH",
  "ČUDAK",
  "ČUDAM",
  "ČUDEČ",
  "ČUDEN",
  "ČUDES",
  "ČUDEŽ",
  "ČUDIČ",
  "ČUDIH",
  "ČUDIL",
  "ČUDIM",
  "ČUDIN",
  "ČUDIŠ",
  "ČUDIT",
  "ČUDIU",
  "ČUDIV",
  "ČUDJO",
  "ČUDMA",
  "ČUDMI",
  "ČUDNA",
  "ČUDNE",
  "ČUDNI",
  "ČUDNO",
  "ČUDNU",
  "ČUDOM",
  "ČUDOV",
  "ČUFAR",
  "ČUFER",
  "ČUFTA",
  "ČUFTE",
  "ČUFTI",
  "ČUFTU",
  "ČUGAH",
  "ČUGAM",
  "ČUJCA",
  "ČUJCE",
  "ČUJCI",
  "ČUJCU",
  "ČUJEC",
  "ČUJEČ",
  "ČUJEM",
  "ČUJEŠ",
  "ČUJEŽ",
  "ČUJKA",
  "ČUJKE",
  "ČUJKI",
  "ČUJKO",
  "ČUJKU",
  "ČUJMO",
  "ČUJTA",
  "ČUJTE",
  "ČUJVA",
  "ČUKAR",
  "ČUKCA",
  "ČUKCE",
  "ČUKCI",
  "ČUKCU",
  "ČUKEC",
  "ČUKEL",
  "ČUKEM",
  "ČUKEV",
  "ČUKIH",
  "ČUKIN",
  "ČUKJA",
  "ČUKJU",
  "ČUKLA",
  "ČUKLE",
  "ČUKLI",
  "ČUKLO",
  "ČUKOM",
  "ČUKOV",
  "ČUKUR",
  "ČULAR",
  "ČULIG",
  "ČULIH",
  "ČULIK",
  "ČULIN",
  "ČULJA",
  "ČULJU",
  "ČULKA",
  "ČULKE",
  "ČULKI",
  "ČULKU",
  "ČULOM",
  "ČULOV",
  "ČULUM",
  "ČUNJA",
  "ČUNJE",
  "ČUNJI",
  "ČUNJO",
  "ČUPAH",
  "ČUPAM",
  "ČURAJ",
  "ČURDA",
  "ČURDE",
  "ČURDI",
  "ČURDO",
  "ČURDU",
  "ČURIČ",
  "ČURIL",
  "ČURIN",
  "ČURKA",
  "ČURKE",
  "ČURKI",
  "ČURKO",
  "ČURKU",
  "ČURTA",
  "ČURTE",
  "ČURTI",
  "ČURTU",
  "ČUŠEM",
  "ČUŠEV",
  "ČUŠIH",
  "ČUŠIN",
  "ČUŠJA",
  "ČUŠJU",
  "ČUŠOM",
  "ČUŠOV",
  "ČUTAR",
  "ČUTEČ",
  "ČUTEM",
  "ČUTEN",
  "ČUTEV",
  "ČUTGA",
  "ČUTIH",
  "ČUTIJ",
  "ČUTIL",
  "ČUTIM",
  "ČUTIŠ",
  "ČUTIT",
  "ČUTIU",
  "ČUTIV",
  "ČUTJA",
  "ČUTJE",
  "ČUTJI",
  "ČUTJU",
  "ČUTNA",
  "ČUTNE",
  "ČUTNI",
  "ČUTNO",
  "ČUTOM",
  "ČUTOV",
  "ČUTUK",
  "ČUTUR",
  "ČUVAJ",
  "ČUVAL",
  "ČUVAM",
  "ČUVAN",
  "ČUVAR",
  "ČUVAŠ",
  "ČUVAT",
  "ČUVAU",
  "ČUVAV",
  "ČUZAH",
  "ČUZAM",
  "ČUŽKA",
  "ČUŽKE",
  "ČUŽKI",
  "ČUŽKO",
  "ČVANA",
  "ČVANE",
  "ČVANI",
  "ČVANU",
  "ČVEKA",
  "ČVEKE",
  "ČVEKI",
  "ČVEKU",
  "ČVRČE",
  "ČVRČI",
  "ČVRČU",
  "ČVRLE",
  "ČVRLI",
  "ČVRLU",
  "ČVRST",
  "ČVRŠA",
  "ČVRŠE",
  "ČVRŠI",
  "ČVRŠU",
  "DABAS",
  "DABBA",
  "DABBE",
  "DABBI",
  "DABBS",
  "DABBU",
  "DABER",
  "DABIČ",
  "DABIJ",
  "DABIN",
  "DABRA",
  "DABRC",
  "DABRU",
  "DACAH",
  "DACAM",
  "DACAR",
  "DACEM",
  "DACEV",
  "DACHS",
  "DACIA",
  "DACIE",
  "DACIH",
  "DACII",
  "DACIJ",
  "DACIO",
  "DACIT",
  "DACJA",
  "DACJU",
  "DACKA",
  "DACKE",
  "DACKI",
  "DACKU",
  "DACOM",
  "DACOV",
  "DACRA",
  "DACRE",
  "DACRI",
  "DACRU",
  "DAČAH",
  "DAČAM",
  "DAČAN",
  "DADAH",
  "DADAM",
  "DADAS",
  "DADDA",
  "DADDE",
  "DADDI",
  "DADDO",
  "DADDU",
  "DADIH",
  "DADIN",
  "DADJA",
  "DADJU",
  "DADOM",
  "DADOV",
  "DAEGU",
  "DAEHN",
  "DAEMS",
  "DAETZ",
  "DAFFT",
  "DAFIH",
  "DAFIN",
  "DAFJA",
  "DAFJU",
  "DAFNE",
  "DAFOE",
  "DAFOM",
  "DAFOV",
  "DAGAN",
  "DAGGA",
  "DAGGE",
  "DAGGI",
  "DAGGU",
  "DAGIH",
  "DAGJA",
  "DAGJU",
  "DAGOM",
  "DAGOV",
  "DAGRI",
  "DAHAN",
  "DAHEM",
  "DAHEV",
  "DAHIH",
  "DAHIJ",
  "DAHJA",
  "DAHJU",
  "DAHLA",
  "DAHLE",
  "DAHLI",
  "DAHLU",
  "DAHMA",
  "DAHME",
  "DAHMI",
  "DAHMU",
  "DAHNA",
  "DAHNE",
  "DAHNI",
  "DAHNU",
  "DAHOM",
  "DAHOV",
  "DAIAN",
  "DAIEM",
  "DAIEV",
  "DAIIH",
  "DAIJA",
  "DAIJE",
  "DAIJI",
  "DAIJO",
  "DAIJU",
  "DAIKI",
  "DAIMA",
  "DAIME",
  "DAIMI",
  "DAIMU",
  "DAINA",
  "DAINE",
  "DAINI",
  "DAINO",
  "DAINU",
  "DAIOM",
  "DAIOV",
  "DAIRE",
  "DAJAH",
  "DAJAJ",
  "DAJAL",
  "DAJAM",
  "DAJAN",
  "DAJAŠ",
  "DAJAT",
  "DAJAU",
  "DAJAV",
  "DAJČA",
  "DAJČE",
  "DAJČI",
  "DAJČU",
  "DAJEM",
  "DAJEŠ",
  "DAJEV",
  "DAJIH",
  "DAJIN",
  "DAJJA",
  "DAJJU",
  "DAJMO",
  "DAJOM",
  "DAJOV",
  "DAJTA",
  "DAJTE",
  "DAJVA",
  "DAKAH",
  "DAKAJ",
  "DAKAM",
  "DAKAR",
  "DAKIČ",
  "DAKIH",
  "DAKIL",
  "DAKIN",
  "DAKJA",
  "DAKJU",
  "DAKKA",
  "DAKKE",
  "DAKKI",
  "DAKKO",
  "DAKKU",
  "DAKLI",
  "DAKOM",
  "DAKOT",
  "DAKOV",
  "DALAL",
  "DALAN",
  "DALCA",
  "DALCE",
  "DALCU",
  "DALEČ",
  "DALEN",
  "DALIA",
  "DALIE",
  "DALIH",
  "DALII",
  "DALIJ",
  "DALIK",
  "DALIL",
  "DALIN",
  "DALIO",
  "DALIP",
  "DALIU",
  "DALJA",
  "DALJE",
  "DALJI",
  "DALJO",
  "DALJU",
  "DALLA",
  "DALLE",
  "DALLI",
  "DALLU",
  "DALMA",
  "DALME",
  "DALMI",
  "DALMO",
  "DALNA",
  "DALNE",
  "DALNI",
  "DALNU",
  "DALOM",
  "DALOT",
  "DALOV",
  "DALŠA",
  "DALŠE",
  "DALŠI",
  "DALŠO",
  "DALTA",
  "DALTE",
  "DALTI",
  "DALTU",
  "DAMAH",
  "DAMAJ",
  "DAMAM",
  "DAMAN",
  "DAMAR",
  "DAMAZ",
  "DAMCA",
  "DAMCO",
  "DAMCU",
  "DAMER",
  "DAMIA",
  "DAMIC",
  "DAMIE",
  "DAMIH",
  "DAMII",
  "DAMIJ",
  "DAMIN",
  "DAMIO",
  "DAMIR",
  "DAMIŠ",
  "DAMJA",
  "DAMJU",
  "DAMKA",
  "DAMKE",
  "DAMKI",
  "DAMKO",
  "DAMKU",
  "DAMMA",
  "DAMME",
  "DAMMI",
  "DAMMU",
  "DAMOM",
  "DAMON",
  "DAMOV",
  "DAMPF",
  "DAMRI",
  "DAMSA",
  "DAMSE",
  "DAMSI",
  "DAMSU",
  "DANAH",
  "DANAJ",
  "DANAM",
  "DANAN",
  "DANAS",
  "DANCA",
  "DANCE",
  "DANCI",
  "DANCU",
  "DANČA",
  "DANČE",
  "DANČI",
  "DANČU",
  "DANDA",
  "DANDE",
  "DANDI",
  "DANDO",
  "DANDU",
  "DANEC",
  "DANEČ",
  "DANEK",
  "DANEL",
  "DANEM",
  "DANES",
  "DANEŠ",
  "DANEU",
  "DANEV",
  "DANEZ",
  "DANGA",
  "DANGE",
  "DANGI",
  "DANGL",
  "DANGU",
  "DANIC",
  "DANIH",
  "DANIK",
  "DANIL",
  "DANIM",
  "DANIN",
  "DANIS",
  "DANIŠ",
  "DANIT",
  "DANIU",
  "DANIV",
  "DANJA",
  "DANJE",
  "DANJI",
  "DANJO",
  "DANJU",
  "DANKA",
  "DANKE",
  "DANKI",
  "DANKO",
  "DANKS",
  "DANKU",
  "DANNA",
  "DANNE",
  "DANNI",
  "DANNO",
  "DANNS",
  "DANNU",
  "DANOM",
  "DANON",
  "DANOV",
  "DANTE",
  "DANTI",
  "DANUŠ",
  "DANUT",
  "DANZA",
  "DANZE",
  "DANZI",
  "DANZO",
  "DANZU",
  "DAOMA",
  "DAOTA",
  "DAOTU",
  "DAOUD",
  "DAOVA",
  "DAOVE",
  "DAOVI",
  "DAOVO",
  "DARAH",
  "DARAK",
  "DARAM",
  "DARBI",
  "DARCI",
  "DAREJ",
  "DAREL",
  "DAREM",
  "DAREN",
  "DAREV",
  "DARGO",
  "DARIA",
  "DARIE",
  "DARIH",
  "DARII",
  "DARIJ",
  "DARIL",
  "DARIN",
  "DARIO",
  "DARIS",
  "DARIŠ",
  "DARIU",
  "DARIŽ",
  "DARJA",
  "DARJE",
  "DARJI",
  "DARJO",
  "DARJU",
  "DARKA",
  "DARKE",
  "DARKI",
  "DARKO",
  "DARKU",
  "DARLA",
  "DARLE",
  "DARLI",
  "DARLO",
  "DAROM",
  "DARON",
  "DAROV",
  "DARPA",
  "DARPE",
  "DARPI",
  "DARPO",
  "DARRI",
  "DARSA",
  "DARST",
  "DARSU",
  "DARTA",
  "DARTE",
  "DARTI",
  "DARTU",
  "DARUJ",
  "DARVI",
  "DASAH",
  "DASAM",
  "DASAN",
  "DASCH",
  "DASHA",
  "DASHE",
  "DASHI",
  "DASHO",
  "DASHU",
  "DASIH",
  "DASJA",
  "DASJU",
  "DASOM",
  "DASOV",
  "DASSU",
  "DAŠAH",
  "DAŠAM",
  "DAŠIN",
  "DAŠKA",
  "DAŠKE",
  "DAŠKI",
  "DAŠKO",
  "DATIN",
  "DATIV",
  "DATTA",
  "DATTE",
  "DATTI",
  "DATTO",
  "DATTU",
  "DATUM",
  "DATUN",
  "DAUBA",
  "DAUBE",
  "DAUBI",
  "DAUBU",
  "DAULA",
  "DAULE",
  "DAULI",
  "DAULU",
  "DAUMA",
  "DAUME",
  "DAUMI",
  "DAUMU",
  "DAUTA",
  "DAUTE",
  "DAUTI",
  "DAUTU",
  "DAVAH",
  "DAVAJ",
  "DAVAL",
  "DAVAŠ",
  "DAVAT",
  "DAVAU",
  "DAVAV",
  "DAVČA",
  "DAVČE",
  "DAVČI",
  "DAVČO",
  "DAVDA",
  "DAVDE",
  "DAVDI",
  "DAVDO",
  "DAVDU",
  "DAVEČ",
  "DAVEK",
  "DAVEM",
  "DAVEN",
  "DAVEV",
  "DAVIA",
  "DAVID",
  "DAVIE",
  "DAVIH",
  "DAVII",
  "DAVIJ",
  "DAVIL",
  "DAVIM",
  "DAVIN",
  "DAVIO",
  "DAVIS",
  "DAVIŠ",
  "DAVIT",
  "DAVIU",
  "DAVIV",
  "DAVJA",
  "DAVJU",
  "DAVKA",
  "DAVKE",
  "DAVKI",
  "DAVKU",
  "DAVNA",
  "DAVNE",
  "DAVNI",
  "DAVNO",
  "DAVOM",
  "DAVON",
  "DAVOR",
  "DAVOS",
  "DAVOV",
  "DAVRI",
  "DAVUD",
  "DAZZI",
  "DBOTA",
  "DCCCI",
  "DCCCL",
  "DCCCV",
  "DCCII",
  "DCCIV",
  "DCCLI",
  "DCCLV",
  "DCCVI",
  "DCIII",
  "DCLII",
  "DCLIV",
  "DCLVI",
  "DCVII",
  "DEAKA",
  "DEAKE",
  "DEAKI",
  "DEAKU",
  "DEALA",
  "DEALE",
  "DEALI",
  "DEALU",
  "DEAMA",
  "DEAMI",
  "DEANA",
  "DEANE",
  "DEANI",
  "DEANN",
  "DEANO",
  "DEANU",
  "DEARA",
  "DEARE",
  "DEARI",
  "DEARU",
  "DEASA",
  "DEASE",
  "DEASI",
  "DEASU",
  "DEATA",
  "DEATE",
  "DEATI",
  "DEATU",
  "DEBAK",
  "DEBAN",
  "DEBAR",
  "DEBAT",
  "DEBBI",
  "DEBEL",
  "DEBER",
  "DEBET",
  "DEBIČ",
  "DEBIL",
  "DEBIN",
  "DEBLA",
  "DEBLI",
  "DEBLO",
  "DEBLU",
  "DEBOR",
  "DEBRA",
  "DEBRE",
  "DEBRI",
  "DEBRO",
  "DEBRU",
  "DEBSA",
  "DEBSE",
  "DEBSI",
  "DEBSU",
  "DEBUS",
  "DEBUT",
  "DECEM",
  "DECEV",
  "DECIH",
  "DECIL",
  "DECJA",
  "DECJU",
  "DECKA",
  "DECKE",
  "DECKI",
  "DECKU",
  "DECOM",
  "DECOV",
  "DECSI",
  "DEČAN",
  "DEČEK",
  "DEČEV",
  "DEČIN",
  "DEČJA",
  "DEČJE",
  "DEČJI",
  "DEČJO",
  "DEČKA",
  "DEČKE",
  "DEČKI",
  "DEČKO",
  "DEČKU",
  "DEČNO",
  "DEČOV",
  "DEČVA",
  "DEČVE",
  "DEČVI",
  "DEČVO",
  "DEDAH",
  "DEDAJ",
  "DEDAL",
  "DEDAM",
  "DEDCA",
  "DEDCE",
  "DEDCI",
  "DEDCU",
  "DEDEC",
  "DEDEJ",
  "DEDEK",
  "DEDEM",
  "DEDEN",
  "DEDER",
  "DEDET",
  "DEDEV",
  "DEDIA",
  "DEDIČ",
  "DEDIE",
  "DEDIH",
  "DEDII",
  "DEDIN",
  "DEDIO",
  "DEDIU",
  "DEDJA",
  "DEDJE",
  "DEDJU",
  "DEDKA",
  "DEDKE",
  "DEDKI",
  "DEDKU",
  "DEDNA",
  "DEDNE",
  "DEDNI",
  "DEDNO",
  "DEDOM",
  "DEDOV",
  "DEDRA",
  "DEDRE",
  "DEDRI",
  "DEDRO",
  "DEDUJ",
  "DEDUŠ",
  "DEEGA",
  "DEEGE",
  "DEEGI",
  "DEEGU",
  "DEEJA",
  "DEEJE",
  "DEEJI",
  "DEEJU",
  "DEEKA",
  "DEEKE",
  "DEEKI",
  "DEEKS",
  "DEEKU",
  "DEELA",
  "DEELE",
  "DEELI",
  "DEELU",
  "DEENA",
  "DEENE",
  "DEENI",
  "DEENO",
  "DEENU",
  "DEEPA",
  "DEEPE",
  "DEEPI",
  "DEEPO",
  "DEEPS",
  "DEEPU",
  "DEERA",
  "DEERE",
  "DEERI",
  "DEERU",
  "DEESA",
  "DEESE",
  "DEESH",
  "DEESI",
  "DEESU",
  "DEFAR",
  "DEFIS",
  "DEFNE",
  "DEFOE",
  "DEGAN",
  "DEGAS",
  "DEGEN",
  "DEGJU",
  "DEGLI",
  "DEGNA",
  "DEGNE",
  "DEGNI",
  "DEGNU",
  "DEGRA",
  "DEGRE",
  "DEGRI",
  "DEGRO",
  "DEGRU",
  "DEHBI",
  "DEHIA",
  "DEHIE",
  "DEHII",
  "DEHIO",
  "DEHIU",
  "DEHMA",
  "DEHME",
  "DEHMI",
  "DEHMU",
  "DEHNA",
  "DEHNE",
  "DEHNI",
  "DEHNU",
  "DEHTE",
  "DEHTI",
  "DEHTU",
  "DEIAH",
  "DEIAM",
  "DEIAN",
  "DEIFA",
  "DEIFE",
  "DEIFI",
  "DEIFU",
  "DEINA",
  "DEINE",
  "DEINI",
  "DEINO",
  "DEINU",
  "DEION",
  "DEIRA",
  "DEIRE",
  "DEIRI",
  "DEIRO",
  "DEISS",
  "DEIST",
  "DEITZ",
  "DEJAH",
  "DEJAK",
  "DEJAL",
  "DEJAM",
  "DEJAN",
  "DEJAT",
  "DEJAU",
  "DEJAV",
  "DEJEM",
  "DEJEŠ",
  "DEJIN",
  "DEJMO",
  "DEJTA",
  "DEJTE",
  "DEJVA",
  "DEKAD",
  "DEKAH",
  "DEKAM",
  "DEKAN",
  "DEKEL",
  "DEKIC",
  "DEKIH",
  "DEKJA",
  "DEKJU",
  "DEKLA",
  "DEKLE",
  "DEKLI",
  "DEKLO",
  "DEKOK",
  "DEKOM",
  "DEKOR",
  "DEKOV",
  "DEKRA",
  "DEKRE",
  "DEKRI",
  "DEKRO",
  "DELAČ",
  "DELAH",
  "DELAJ",
  "DELAK",
  "DELAL",
  "DELAM",
  "DELAN",
  "DELAŠ",
  "DELAT",
  "DELAU",
  "DELAV",
  "DELCA",
  "DELCE",
  "DELCI",
  "DELCU",
  "DELEC",
  "DELEČ",
  "DELEJ",
  "DELEM",
  "DELEN",
  "DELEV",
  "DELEŽ",
  "DELFA",
  "DELFE",
  "DELFI",
  "DELFO",
  "DELFS",
  "DELFT",
  "DELHI",
  "DELIA",
  "DELIČ",
  "DELIE",
  "DELIH",
  "DELII",
  "DELIJ",
  "DELIL",
  "DELIM",
  "DELIN",
  "DELIO",
  "DELIŠ",
  "DELIT",
  "DELIU",
  "DELIV",
  "DELJA",
  "DELJU",
  "DELKA",
  "DELKE",
  "DELKI",
  "DELKU",
  "DELLA",
  "DELLE",
  "DELLI",
  "DELLO",
  "DELLS",
  "DELLU",
  "DELNA",
  "DELNE",
  "DELNI",
  "DELNO",
  "DELOM",
  "DELON",
  "DELOS",
  "DELOV",
  "DELTA",
  "DELTE",
  "DELTI",
  "DELTO",
  "DELUC",
  "DELUJ",
  "DEMAH",
  "DEMAJ",
  "DEMAM",
  "DEMAN",
  "DEMAR",
  "DEMBA",
  "DEMEM",
  "DEMET",
  "DEMEV",
  "DEMIE",
  "DEMIH",
  "DEMIN",
  "DEMIR",
  "DEMJA",
  "DEMJU",
  "DEMKA",
  "DEMKE",
  "DEMKI",
  "DEMKO",
  "DEMKU",
  "DEMMA",
  "DEMME",
  "DEMMI",
  "DEMMU",
  "DEMOM",
  "DEMON",
  "DEMOS",
  "DEMOV",
  "DEMPE",
  "DEMRI",
  "DEMUS",
  "DENAC",
  "DENAD",
  "DENAH",
  "DENAJ",
  "DENAM",
  "DENAR",
  "DENCA",
  "DENCE",
  "DENCH",
  "DENCI",
  "DENCU",
  "DENEM",
  "DENEŠ",
  "DENGA",
  "DENGE",
  "DENGI",
  "DENGO",
  "DENGU",
  "DENIČ",
  "DENID",
  "DENIH",
  "DENIK",
  "DENIN",
  "DENIS",
  "DENIŠ",
  "DENIZ",
  "DENJA",
  "DENJU",
  "DENKA",
  "DENKE",
  "DENKI",
  "DENKO",
  "DENKU",
  "DENNA",
  "DENNE",
  "DENNI",
  "DENNO",
  "DENOM",
  "DENOV",
  "DENSA",
  "DENSO",
  "DENSU",
  "DENTA",
  "DENTE",
  "DENTI",
  "DENTU",
  "DENTZ",
  "DEONA",
  "DEONE",
  "DEONI",
  "DEONN",
  "DEONU",
  "DEOSA",
  "DEOSU",
  "DEPER",
  "DEPEŠ",
  "DEPOK",
  "DEPPA",
  "DEPPE",
  "DEPPI",
  "DEPPU",
  "DEPRA",
  "DEPRE",
  "DEPRI",
  "DEPRO",
  "DERAČ",
  "DERAK",
  "DERBI",
  "DERCK",
  "DERČA",
  "DERČE",
  "DERČI",
  "DERČO",
  "DERČU",
  "DEREK",
  "DEREM",
  "DEREŠ",
  "DEREZ",
  "DERIK",
  "DERJI",
  "DERKA",
  "DERKE",
  "DERKI",
  "DERKS",
  "DERKU",
  "DERNA",
  "DERNE",
  "DERNI",
  "DERNO",
  "DERNU",
  "DEROČ",
  "DERON",
  "DERRI",
  "DERUH",
  "DERUM",
  "DERVA",
  "DERVE",
  "DERVI",
  "DERVL",
  "DERVO",
  "DERVU",
  "DESAH",
  "DESAK",
  "DESAM",
  "DESCH",
  "DESEN",
  "DESER",
  "DESET",
  "DESIH",
  "DESIN",
  "DESJA",
  "DESJU",
  "DESKA",
  "DESKE",
  "DESKI",
  "DESKO",
  "DESKU",
  "DESNA",
  "DESNE",
  "DESNI",
  "DESNO",
  "DESNU",
  "DESOM",
  "DESOV",
  "DESSI",
  "DESTA",
  "DESTE",
  "DESTI",
  "DESTU",
  "DESUS",
  "DEŠAH",
  "DEŠAM",
  "DEŠEN",
  "DEŠIN",
  "DEŠJA",
  "DEŠJI",
  "DEŠJU",
  "DEŠKA",
  "DEŠKE",
  "DEŠKI",
  "DEŠKO",
  "DEŠNA",
  "DEŠNU",
  "DETEC",
  "DETEL",
  "DETET",
  "DETIČ",
  "DETIG",
  "DETLA",
  "DETLE",
  "DETLI",
  "DETLU",
  "DETRE",
  "DETTI",
  "DETTL",
  "DEUCA",
  "DEUCE",
  "DEUIH",
  "DEUJA",
  "DEUJU",
  "DEUOM",
  "DEUOV",
  "DEUTZ",
  "DEVAH",
  "DEVAJ",
  "DEVAL",
  "DEVAM",
  "DEVAN",
  "DEVAŠ",
  "DEVAT",
  "DEVAU",
  "DEVAV",
  "DEVEN",
  "DEVER",
  "DEVET",
  "DEVGN",
  "DEVIC",
  "DEVID",
  "DEVIH",
  "DEVIK",
  "DEVIN",
  "DEVIS",
  "DEVIZ",
  "DEVJA",
  "DEVJU",
  "DEVOM",
  "DEVON",
  "DEVOS",
  "DEVOV",
  "DEVRA",
  "DEVRE",
  "DEVRI",
  "DEVRU",
  "DEZEN",
  "DEZIH",
  "DEZJA",
  "DEZJU",
  "DEZOM",
  "DEZOV",
  "DEŽAN",
  "DEŽEK",
  "DEŽEL",
  "DEŽEN",
  "DEŽJA",
  "DEŽJE",
  "DEŽJI",
  "DEŽJU",
  "DEŽKA",
  "DEŽKE",
  "DEŽKI",
  "DEŽKU",
  "DEŽNA",
  "DEŽNE",
  "DEŽNI",
  "DEŽNO",
  "DEŽNU",
  "DEŽUJ",
  "DFVJU",
  "DHABI",
  "DHALL",
  "DHANI",
  "DHARM",
  "DHESI",
  "DHIRI",
  "DHOMI",
  "DHONT",
  "DHORI",
  "DIAAN",
  "DIABA",
  "DIABE",
  "DIABI",
  "DIABU",
  "DIACI",
  "DIACK",
  "DIADA",
  "DIADE",
  "DIADI",
  "DIADO",
  "DIAGA",
  "DIAMA",
  "DIAMI",
  "DIANA",
  "DIANE",
  "DIANI",
  "DIANN",
  "DIANO",
  "DIANU",
  "DIARR",
  "DIASA",
  "DIASE",
  "DIASI",
  "DIASU",
  "DIAZA",
  "DIAZE",
  "DIAZI",
  "DIAZU",
  "DIBER",
  "DIBIH",
  "DIBJA",
  "DIBJU",
  "DIBOM",
  "DIBOV",
  "DIBRA",
  "DIBRE",
  "DIBRI",
  "DIBRO",
  "DIBRU",
  "DICKA",
  "DICKE",
  "DICKI",
  "DICKS",
  "DICKU",
  "DICUS",
  "DIČEČ",
  "DIČEN",
  "DIČIL",
  "DIČIM",
  "DIČIŠ",
  "DIČIT",
  "DIČIU",
  "DIČIV",
  "DIDAH",
  "DIDAK",
  "DIDAM",
  "DIDDI",
  "DIDIH",
  "DIDIM",
  "DIDIN",
  "DIDJA",
  "DIDJU",
  "DIDOM",
  "DIDON",
  "DIDOV",
  "DIECK",
  "DIEGA",
  "DIEGE",
  "DIEGI",
  "DIEGO",
  "DIEGU",
  "DIEHL",
  "DIEHN",
  "DIEJU",
  "DIEKS",
  "DIELA",
  "DIELE",
  "DIELI",
  "DIELS",
  "DIELU",
  "DIEMA",
  "DIEMU",
  "DIENA",
  "DIENE",
  "DIENG",
  "DIENI",
  "DIENU",
  "DIEPP",
  "DIERK",
  "DIESS",
  "DIETA",
  "DIETE",
  "DIETI",
  "DIETL",
  "DIETO",
  "DIETZ",
  "DIEZA",
  "DIEZE",
  "DIEZI",
  "DIEZU",
  "DIFIN",
  "DIFON",
  "DIGGS",
  "DIGIT",
  "DIGNE",
  "DIHAJ",
  "DIHAL",
  "DIHAM",
  "DIHAŠ",
  "DIHAT",
  "DIHAU",
  "DIHAV",
  "DIHEM",
  "DIHEV",
  "DIHIH",
  "DIHJA",
  "DIHJU",
  "DIHNE",
  "DIHNI",
  "DIHNU",
  "DIHOM",
  "DIHOV",
  "DIHTA",
  "DIHTI",
  "DIHTU",
  "DIHUR",
  "DIJAK",
  "DIJAN",
  "DIJAS",
  "DIJCK",
  "DIJIN",
  "DIJKA",
  "DIJKE",
  "DIJKI",
  "DIJKU",
  "DIJON",
  "DIKAH",
  "DIKAM",
  "DIKIH",
  "DIKIN",
  "DIKJA",
  "DIKJU",
  "DIKKA",
  "DIKKE",
  "DIKKI",
  "DIKKO",
  "DIKKU",
  "DIKOM",
  "DIKOV",
  "DIKSA",
  "DIKSE",
  "DIKSI",
  "DIKSU",
  "DILAH",
  "DILAJ",
  "DILAL",
  "DILAM",
  "DILAN",
  "DILAR",
  "DILAŠ",
  "DILAT",
  "DILAU",
  "DILAV",
  "DILCA",
  "DILCE",
  "DILCI",
  "DILCU",
  "DILDA",
  "DILDE",
  "DILDI",
  "DILDO",
  "DILDU",
  "DILEC",
  "DILEK",
  "DILEM",
  "DILER",
  "DILIČ",
  "DILIP",
  "DILKA",
  "DILKE",
  "DILKI",
  "DILKO",
  "DILKS",
  "DILLA",
  "DILLE",
  "DILLI",
  "DILLU",
  "DILMA",
  "DILME",
  "DILMI",
  "DILMO",
  "DILON",
  "DILTS",
  "DIMAČ",
  "DIMAH",
  "DIMAM",
  "DIMAS",
  "DIMCA",
  "DIMCE",
  "DIMCI",
  "DIMCU",
  "DIMČE",
  "DIMEC",
  "DIMEČ",
  "DIMEK",
  "DIMEM",
  "DIMEN",
  "DIMER",
  "DIMEV",
  "DIMIC",
  "DIMIČ",
  "DIMIH",
  "DIMIJ",
  "DIMIL",
  "DIMIM",
  "DIMIN",
  "DIMIŠ",
  "DIMIT",
  "DIMIU",
  "DIMIV",
  "DIMJA",
  "DIMJU",
  "DIMKA",
  "DIMKE",
  "DIMKI",
  "DIMKU",
  "DIMNA",
  "DIMNE",
  "DIMNI",
  "DIMNO",
  "DIMOM",
  "DIMON",
  "DIMOV",
  "DINAH",
  "DINAJ",
  "DINAL",
  "DINAM",
  "DINAN",
  "DINAR",
  "DINDA",
  "DINDE",
  "DINDI",
  "DINDO",
  "DINDU",
  "DINES",
  "DINEV",
  "DINGA",
  "DINGE",
  "DINGI",
  "DINGO",
  "DINGU",
  "DINIH",
  "DININ",
  "DINIS",
  "DINIT",
  "DINIZ",
  "DINJA",
  "DINJE",
  "DINJI",
  "DINJO",
  "DINJU",
  "DINKA",
  "DINKE",
  "DINKI",
  "DINKO",
  "DINKU",
  "DINNA",
  "DINNE",
  "DINNI",
  "DINNU",
  "DINOM",
  "DINOS",
  "DINOV",
  "DIODA",
  "DIODE",
  "DIODI",
  "DIODO",
  "DIOFA",
  "DIOFE",
  "DIOFI",
  "DIOFO",
  "DIOGA",
  "DIOGE",
  "DIOGI",
  "DIOGO",
  "DIOGU",
  "DIONA",
  "DIONE",
  "DIONG",
  "DIONI",
  "DIONO",
  "DIONU",
  "DIOPA",
  "DIOPE",
  "DIOPI",
  "DIOPU",
  "DIOTA",
  "DIOTE",
  "DIOTI",
  "DIOTU",
  "DIOUF",
  "DIPEL",
  "DIPLA",
  "DIPLE",
  "DIPLI",
  "DIPLO",
  "DIPLU",
  "DIPOL",
  "DIPPL",
  "DIRAC",
  "DIRAH",
  "DIRAK",
  "DIRAM",
  "DIRAN",
  "DIREM",
  "DIREZ",
  "DIRIE",
  "DIRJA",
  "DIRJI",
  "DIRJU",
  "DIRKA",
  "DIRKE",
  "DIRKI",
  "DIRKO",
  "DIRKS",
  "DIRKU",
  "DIRNT",
  "DIROM",
  "DISHA",
  "DISHE",
  "DISHI",
  "DISHO",
  "DISHU",
  "DISKA",
  "DISKE",
  "DISKI",
  "DISKO",
  "DISKU",
  "DIŠAL",
  "DIŠAT",
  "DIŠAU",
  "DIŠAV",
  "DIŠEČ",
  "DIŠIM",
  "DIŠIŠ",
  "DITAH",
  "DITAM",
  "DITIN",
  "DITKA",
  "DITKE",
  "DITKI",
  "DITKO",
  "DITTA",
  "DITTE",
  "DITTI",
  "DITTO",
  "DITTU",
  "DITZA",
  "DITZE",
  "DITZI",
  "DITZU",
  "DIVAC",
  "DIVAH",
  "DIVAM",
  "DIVAN",
  "DIVCA",
  "DIVCE",
  "DIVCI",
  "DIVCU",
  "DIVČE",
  "DIVČI",
  "DIVIN",
  "DIVJA",
  "DIVJE",
  "DIVJI",
  "DIVJO",
  "DIVJU",
  "DIVNA",
  "DIVNE",
  "DIVNI",
  "DIVNO",
  "DIZEL",
  "DIZLA",
  "DIZLE",
  "DIZLI",
  "DIZLU",
  "DIZON",
  "DJABA",
  "DJABE",
  "DJABI",
  "DJABO",
  "DJABU",
  "DJAIP",
  "DJALM",
  "DJALO",
  "DJANA",
  "DJANE",
  "DJANI",
  "DJANO",
  "DJAPA",
  "DJAPE",
  "DJAPI",
  "DJAPO",
  "DJAPU",
  "DJEBI",
  "DJEKŠ",
  "DJELA",
  "DJELE",
  "DJELI",
  "DJELO",
  "DJELU",
  "DJERE",
  "DJERF",
  "DJIAN",
  "DJIRA",
  "DJIRE",
  "DJIRI",
  "DJIRO",
  "DJIRU",
  "DJURA",
  "DJURE",
  "DJURI",
  "DJURO",
  "DJURU",
  "DLAKA",
  "DLAKE",
  "DLAKI",
  "DLAKO",
  "DLANI",
  "DLESK",
  "DLETA",
  "DLETC",
  "DLETI",
  "DLETO",
  "DLETU",
  "DLIBA",
  "DLIBU",
  "DLIII",
  "DLVII",
  "DMFJA",
  "DNAMI",
  "DNEMA",
  "DNEMI",
  "DNEVA",
  "DNEVE",
  "DNEVI",
  "DNEVU",
  "DNINA",
  "DNINE",
  "DNINI",
  "DNINO",
  "DNIŠČ",
  "DNOMA",
  "DOAKA",
  "DOAKE",
  "DOAKI",
  "DOAKU",
  "DOANA",
  "DOANE",
  "DOANI",
  "DOANU",
  "DOARS",
  "DOBAH",
  "DOBAJ",
  "DOBAM",
  "DOBAV",
  "DOBBA",
  "DOBBE",
  "DOBBI",
  "DOBBS",
  "DOBBU",
  "DOBCA",
  "DOBCU",
  "DOBEC",
  "DOBEM",
  "DOBER",
  "DOBEV",
  "DOBID",
  "DOBIE",
  "DOBIH",
  "DOBIL",
  "DOBIM",
  "DOBIN",
  "DOBIŠ",
  "DOBIT",
  "DOBIU",
  "DOBIV",
  "DOBJA",
  "DOBJE",
  "DOBJI",
  "DOBJO",
  "DOBJU",
  "DOBOJ",
  "DOBOM",
  "DOBOV",
  "DOBRA",
  "DOBRE",
  "DOBRI",
  "DOBRO",
  "DOBRU",
  "DOCIH",
  "DOCJA",
  "DOCJU",
  "DOCOM",
  "DOCOV",
  "DOCVU",
  "DOČIM",
  "DODAH",
  "DODAJ",
  "DODAL",
  "DODAM",
  "DODAN",
  "DODAŠ",
  "DODAT",
  "DODAU",
  "DODAV",
  "DODDA",
  "DODDE",
  "DODDI",
  "DODDS",
  "DODDU",
  "DODEM",
  "DODEV",
  "DODGA",
  "DODGE",
  "DODIČ",
  "DODIE",
  "DODIG",
  "DODIH",
  "DODIK",
  "DODIN",
  "DODJA",
  "DODJU",
  "DODOM",
  "DODON",
  "DODOO",
  "DODOV",
  "DODUK",
  "DOEHN",
  "DOEJA",
  "DOEJE",
  "DOEJI",
  "DOEJU",
  "DOERR",
  "DOETT",
  "DOFFA",
  "DOFFE",
  "DOFFI",
  "DOFFO",
  "DOFFU",
  "DOGAH",
  "DOGAM",
  "DOGAN",
  "DOGAR",
  "DOGAS",
  "DOGEM",
  "DOGGA",
  "DOGGE",
  "DOGGI",
  "DOGGU",
  "DOGIČ",
  "DOGIH",
  "DOGIN",
  "DOGJA",
  "DOGJU",
  "DOGMA",
  "DOGME",
  "DOGMI",
  "DOGMO",
  "DOGNU",
  "DOGOM",
  "DOGON",
  "DOGOŠ",
  "DOGOV",
  "DOGRU",
  "DOGŠA",
  "DOGŠE",
  "DOGŠI",
  "DOGŠO",
  "DOGŠU",
  "DOHAL",
  "DOHMA",
  "DOHME",
  "DOHMI",
  "DOHMU",
  "DOHNA",
  "DOHNE",
  "DOHNI",
  "DOHNU",
  "DOHOD",
  "DOHOT",
  "DOHRN",
  "DOIGA",
  "DOIGE",
  "DOIGI",
  "DOIGU",
  "DOJAK",
  "DOJEČ",
  "DOJEJ",
  "DOJEL",
  "DOJEM",
  "DOJEN",
  "DOJEŠ",
  "DOJET",
  "DOJEU",
  "DOJIL",
  "DOJIM",
  "DOJIŠ",
  "DOJIT",
  "DOJIU",
  "DOJIV",
  "DOJKA",
  "DOJKE",
  "DOJKI",
  "DOJKO",
  "DOKAH",
  "DOKAJ",
  "DOKAM",
  "DOKAZ",
  "DOKEM",
  "DOKEV",
  "DOKIH",
  "DOKJA",
  "DOKJU",
  "DOKLA",
  "DOKLE",
  "DOKLI",
  "DOKLU",
  "DOKOM",
  "DOKOV",
  "DOKŠA",
  "DOKŠE",
  "DOKŠI",
  "DOKŠO",
  "DOKŠU",
  "DOKUJ",
  "DOKUP",
  "DOLAH",
  "DOLAK",
  "DOLAM",
  "DOLAN",
  "DOLAR",
  "DOLAT",
  "DOLBE",
  "DOLBI",
  "DOLBU",
  "DOLCA",
  "DOLCE",
  "DOLCI",
  "DOLCU",
  "DOLEC",
  "DOLEK",
  "DOLEM",
  "DOLER",
  "DOLES",
  "DOLET",
  "DOLEV",
  "DOLFA",
  "DOLFE",
  "DOLFI",
  "DOLFK",
  "DOLFU",
  "DOLGA",
  "DOLGE",
  "DOLGI",
  "DOLGO",
  "DOLGU",
  "DOLIČ",
  "DOLIH",
  "DOLIJ",
  "DOLIL",
  "DOLIN",
  "DOLIT",
  "DOLIU",
  "DOLIV",
  "DOLJA",
  "DOLJE",
  "DOLJU",
  "DOLKA",
  "DOLKE",
  "DOLKI",
  "DOLKO",
  "DOLKU",
  "DOLLA",
  "DOLLE",
  "DOLLI",
  "DOLLO",
  "DOLLS",
  "DOLLU",
  "DOLOM",
  "DOLOV",
  "DOLPH",
  "DOLSA",
  "DOLSE",
  "DOLSI",
  "DOLSU",
  "DOLŠC",
  "DOLUS",
  "DOLŽA",
  "DOLŽE",
  "DOLŽI",
  "DOLŽU",
  "DOMAČ",
  "DOMAN",
  "DOMAR",
  "DOMAŠ",
  "DOMBI",
  "DOMCA",
  "DOMCE",
  "DOMCI",
  "DOMCU",
  "DOMEC",
  "DOMEH",
  "DOMEJ",
  "DOMEK",
  "DOMEL",
  "DOMEM",
  "DOMEN",
  "DOMET",
  "DOMEV",
  "DOMIH",
  "DOMIK",
  "DOMIN",
  "DOMJA",
  "DOMJO",
  "DOMJU",
  "DOMKA",
  "DOMKE",
  "DOMKI",
  "DOMKU",
  "DOMNA",
  "DOMNE",
  "DOMNI",
  "DOMNU",
  "DOMOL",
  "DOMOM",
  "DOMOU",
  "DOMOV",
  "DOMUJ",
  "DOMUS",
  "DONAH",
  "DONAJ",
  "DONAL",
  "DONAM",
  "DONAR",
  "DONAT",
  "DONCK",
  "DONDI",
  "DONEČ",
  "DONEL",
  "DONEM",
  "DONEN",
  "DONES",
  "DONET",
  "DONEU",
  "DONEV",
  "DONGA",
  "DONGE",
  "DONGI",
  "DONGO",
  "DONGU",
  "DONIH",
  "DONIK",
  "DONIM",
  "DONIN",
  "DONIS",
  "DONIŠ",
  "DONIT",
  "DONJA",
  "DONJE",
  "DONJI",
  "DONJO",
  "DONJU",
  "DONKA",
  "DONKE",
  "DONKI",
  "DONKO",
  "DONKU",
  "DONNA",
  "DONNE",
  "DONNI",
  "DONNO",
  "DONNU",
  "DONOM",
  "DONOR",
  "DONOS",
  "DONOŠ",
  "DONOV",
  "DONŠA",
  "DONŠE",
  "DONŠI",
  "DONŠO",
  "DONŠU",
  "DONTH",
  "DOOBA",
  "DOOBE",
  "DOOBI",
  "DOOBU",
  "DOOMS",
  "DOORN",
  "DOORU",
  "DOPEČ",
  "DOPIK",
  "DOPIS",
  "DOPPS",
  "DORAH",
  "DORAM",
  "DORAN",
  "DORAU",
  "DOREČ",
  "DOREL",
  "DOREN",
  "DORER",
  "DORET",
  "DORFA",
  "DORFE",
  "DORFF",
  "DORFI",
  "DORFU",
  "DORIA",
  "DORIC",
  "DORIČ",
  "DORIE",
  "DORIH",
  "DORII",
  "DORIJ",
  "DORIN",
  "DORIO",
  "DORIS",
  "DORIT",
  "DORIU",
  "DORJA",
  "DORJU",
  "DORKA",
  "DORKE",
  "DORKI",
  "DORKO",
  "DORLE",
  "DORLI",
  "DORNA",
  "DORNE",
  "DORNI",
  "DORNO",
  "DORNU",
  "DOROM",
  "DORON",
  "DOROT",
  "DOROV",
  "DORPA",
  "DORPE",
  "DORPI",
  "DORPU",
  "DORSA",
  "DORSE",
  "DORSI",
  "DORST",
  "DORSU",
  "DORTA",
  "DORTE",
  "DORTI",
  "DORTU",
  "DORUK",
  "DOSAL",
  "DOSCH",
  "DOSEČ",
  "DOSEG",
  "DOSEJ",
  "DOSHI",
  "DOSJE",
  "DOSPE",
  "DOSPI",
  "DOSPU",
  "DOSSA",
  "DOSSE",
  "DOSSI",
  "DOSSU",
  "DOSTA",
  "DOSTE",
  "DOSTI",
  "DOSTU",
  "DOSUJ",
  "DOSUL",
  "DOSUT",
  "DOSZA",
  "DOSZE",
  "DOSZI",
  "DOSZO",
  "DOSZU",
  "DOŠAH",
  "DOŠAM",
  "DOŠEN",
  "DOŠIJ",
  "DOŠIL",
  "DOŠIT",
  "DOŠIU",
  "DOŠIV",
  "DOŠKA",
  "DOŠKE",
  "DOŠKI",
  "DOŠKO",
  "DOTAH",
  "DOTAM",
  "DOTAN",
  "DOTIC",
  "DOTIK",
  "DOTIS",
  "DOTOK",
  "DOTOM",
  "DOTOV",
  "DOTTA",
  "DOTTE",
  "DOTTI",
  "DOTTO",
  "DOTTU",
  "DOUBA",
  "DOUBE",
  "DOUBI",
  "DOUBU",
  "DOUGA",
  "DOUGE",
  "DOUGH",
  "DOUGI",
  "DOUGU",
  "DOULA",
  "DOULE",
  "DOULI",
  "DOULL",
  "DOULO",
  "DOUME",
  "DOUMI",
  "DOUMU",
  "DOVAL",
  "DOVČA",
  "DOVČE",
  "DOVČI",
  "DOVČU",
  "DOVER",
  "DOVIH",
  "DOVIN",
  "DOVJA",
  "DOVJE",
  "DOVJU",
  "DOVOD",
  "DOVOL",
  "DOVOM",
  "DOVOT",
  "DOVOV",
  "DOVOZ",
  "DOVRA",
  "DOVRU",
  "DOVŽE",
  "DOZAH",
  "DOZAM",
  "DOZER",
  "DOŽEM",
  "DOŽEN",
  "DOŽEV",
  "DOŽIC",
  "DOŽIH",
  "DOŽIN",
  "DOŽJA",
  "DOŽJU",
  "DOŽNA",
  "DOŽNE",
  "DOŽNI",
  "DOŽNO",
  "DOŽOM",
  "DOŽOV",
  "DPDVN",
  "DPOTA",
  "DRABA",
  "DRABE",
  "DRABI",
  "DRABU",
  "DRACH",
  "DRACK",
  "DRAČA",
  "DRAČU",
  "DRAFA",
  "DRAFE",
  "DRAFI",
  "DRAFT",
  "DRAFU",
  "DRAGA",
  "DRAGC",
  "DRAGE",
  "DRAGI",
  "DRAGO",
  "DRAGT",
  "DRAGU",
  "DRAHI",
  "DRAIN",
  "DRAIS",
  "DRAKA",
  "DRAKE",
  "DRAKI",
  "DRAKU",
  "DRAMA",
  "DRAME",
  "DRAMI",
  "DRAMO",
  "DRAMU",
  "DRANE",
  "DRAPE",
  "DRAST",
  "DRAŠA",
  "DRAŠE",
  "DRAŠI",
  "DRAŠU",
  "DRATA",
  "DRATE",
  "DRATH",
  "DRATI",
  "DRATU",
  "DRAUL",
  "DRAVA",
  "DRAVČ",
  "DRAVE",
  "DRAVI",
  "DRAVO",
  "DRAŽA",
  "DRAŽB",
  "DRAŽE",
  "DRAŽI",
  "DRAŽO",
  "DRAŽU",
  "DRCEV",
  "DRČAH",
  "DRČAL",
  "DRČAM",
  "DRČAN",
  "DRČAR",
  "DRČAT",
  "DRČAU",
  "DRČAV",
  "DRČEČ",
  "DRČEM",
  "DRČEN",
  "DRČEV",
  "DRČIČ",
  "DRČIH",
  "DRČIM",
  "DRČIŠ",
  "DRČJA",
  "DRČJU",
  "DRČNA",
  "DRČNE",
  "DRČNI",
  "DRČNO",
  "DRČOM",
  "DRČOV",
  "DRDRA",
  "DRDRI",
  "DRDRU",
  "DREAH",
  "DREAM",
  "DREČK",
  "DREDA",
  "DREDE",
  "DREDI",
  "DREDU",
  "DREES",
  "DREGA",
  "DREGI",
  "DREGU",
  "DREIS",
  "DREJA",
  "DREJC",
  "DREJI",
  "DREJO",
  "DREJU",
  "DREKA",
  "DREKC",
  "DREKE",
  "DREKI",
  "DREKO",
  "DREKU",
  "DREMA",
  "DREME",
  "DREMI",
  "DREMO",
  "DREMU",
  "DRENA",
  "DRENE",
  "DRENI",
  "DRENJ",
  "DRENK",
  "DRENO",
  "DRENT",
  "DRENU",
  "DRESA",
  "DRESE",
  "DRESI",
  "DRESU",
  "DRETA",
  "DRETE",
  "DRETI",
  "DRETO",
  "DREUA",
  "DREUE",
  "DREUI",
  "DREUU",
  "DREVA",
  "DREVC",
  "DREVE",
  "DREVI",
  "DREVO",
  "DREVU",
  "DREZA",
  "DREZG",
  "DREZI",
  "DREZU",
  "DRFMG",
  "DRGAČ",
  "DRGAL",
  "DRGAN",
  "DRGET",
  "DRGNE",
  "DRGNI",
  "DRGNU",
  "DRHAL",
  "DRHTE",
  "DRHTI",
  "DRHTU",
  "DRIAD",
  "DRIAN",
  "DRIČA",
  "DRIČE",
  "DRIČI",
  "DRIČO",
  "DRIČU",
  "DRIDI",
  "DRIES",
  "DRILA",
  "DRILE",
  "DRILI",
  "DRILO",
  "DRILU",
  "DRIMA",
  "DRIMO",
  "DRIMU",
  "DRINA",
  "DRINE",
  "DRING",
  "DRINI",
  "DRINK",
  "DRINO",
  "DRINU",
  "DRION",
  "DRISK",
  "DRITA",
  "DRITE",
  "DRITI",
  "DRITO",
  "DRIVA",
  "DRKAČ",
  "DRKAJ",
  "DRKAL",
  "DRKAM",
  "DRKAŠ",
  "DRKAT",
  "DRKAU",
  "DRKAV",
  "DRKIH",
  "DRKJA",
  "DRKJU",
  "DRKOM",
  "DRKOV",
  "DRLJA",
  "DRLJE",
  "DRLJI",
  "DRLJO",
  "DRLJU",
  "DRMAŽ",
  "DRMOT",
  "DRNAČ",
  "DRNCA",
  "DRNCE",
  "DRNCI",
  "DRNCU",
  "DRNEC",
  "DRNEM",
  "DRNIC",
  "DRNIČ",
  "DRNIŠ",
  "DRNJA",
  "DRNJU",
  "DRNOM",
  "DROBA",
  "DROBC",
  "DROBE",
  "DROBI",
  "DROBO",
  "DROBU",
  "DROGA",
  "DROGB",
  "DROGC",
  "DROGE",
  "DROGI",
  "DROGO",
  "DROGU",
  "DROLA",
  "DROLC",
  "DROLE",
  "DROLI",
  "DROLL",
  "DROLU",
  "DROMM",
  "DRONA",
  "DRONE",
  "DRONG",
  "DRONI",
  "DRONT",
  "DRONU",
  "DRORA",
  "DRORE",
  "DRORI",
  "DRORU",
  "DROST",
  "DROTT",
  "DROVA",
  "DROVE",
  "DROVI",
  "DROVU",
  "DROZD",
  "DROZG",
  "DROŽE",
  "DROŽI",
  "DROŽK",
  "DROŽU",
  "DRPAH",
  "DRPAM",
  "DRPIH",
  "DRPJA",
  "DRPJU",
  "DRPOM",
  "DRPOV",
  "DRSAJ",
  "DRSAL",
  "DRSAM",
  "DRSAŠ",
  "DRSAT",
  "DRSAU",
  "DRSAV",
  "DRSEČ",
  "DRSEL",
  "DRSEN",
  "DRSET",
  "DRSEU",
  "DRSIM",
  "DRSIN",
  "DRSIŠ",
  "DRSKA",
  "DRSKE",
  "DRSKI",
  "DRSKO",
  "DRSKU",
  "DRSNA",
  "DRSNE",
  "DRSNI",
  "DRSNO",
  "DRSTE",
  "DRSTI",
  "DRSTU",
  "DRŠKA",
  "DRŠKE",
  "DRŠKI",
  "DRŠKO",
  "DRTIJ",
  "DRTJA",
  "DRTJE",
  "DRTJI",
  "DRTJU",
  "DRUCA",
  "DRUCE",
  "DRUDI",
  "DRUGA",
  "DRUGE",
  "DRUGI",
  "DRUGO",
  "DRUGU",
  "DRUID",
  "DRUMA",
  "DRUME",
  "DRUMI",
  "DRUML",
  "DRUMM",
  "DRUMU",
  "DRUON",
  "DRUŠČ",
  "DRUZA",
  "DRUZE",
  "DRUZI",
  "DRUZO",
  "DRUŽB",
  "DRUŽI",
  "DRUŽU",
  "DRVAČ",
  "DRVAH",
  "DRVAM",
  "DRVAR",
  "DRVCA",
  "DRVCI",
  "DRVEČ",
  "DRVEH",
  "DRVEL",
  "DRVET",
  "DRVEU",
  "DRVIH",
  "DRVIM",
  "DRVIŠ",
  "DRVMI",
  "DRVOM",
  "DRVOV",
  "DRZAJ",
  "DRZAL",
  "DRZAM",
  "DRZAN",
  "DRZAŠ",
  "DRZAT",
  "DRZAU",
  "DRZAV",
  "DRZEK",
  "DRZEN",
  "DRZKA",
  "DRZKE",
  "DRZKI",
  "DRZKO",
  "DRZNA",
  "DRZNE",
  "DRZNI",
  "DRZNO",
  "DRZNU",
  "DRŽAČ",
  "DRŽAH",
  "DRŽAJ",
  "DRŽAL",
  "DRŽAM",
  "DRŽAN",
  "DRŽAT",
  "DRŽAU",
  "DRŽAV",
  "DRŽEČ",
  "DRŽEK",
  "DRŽIČ",
  "DRŽIM",
  "DRŽIŠ",
  "DRŽKA",
  "DRŽKE",
  "DRŽKI",
  "DRŽKO",
  "DRŽKU",
  "DSFTP",
  "DSOTU",
  "DUALA",
  "DUALE",
  "DUALI",
  "DUALU",
  "DUAMA",
  "DUAMI",
  "DUANA",
  "DUANE",
  "DUANI",
  "DUANU",
  "DUATA",
  "DUATE",
  "DUATI",
  "DUATO",
  "DUATU",
  "DUBAH",
  "DUBAJ",
  "DUBAM",
  "DUBAS",
  "DUBEM",
  "DUBIN",
  "DUBJA",
  "DUBJU",
  "DUBOK",
  "DUBOM",
  "DUBON",
  "DUBSA",
  "DUBSE",
  "DUBSI",
  "DUBSU",
  "DUBUS",
  "DUCAT",
  "DUCKA",
  "DUCKE",
  "DUCKI",
  "DUCKS",
  "DUCKU",
  "DUDAČ",
  "DUDAH",
  "DUDAJ",
  "DUDAL",
  "DUDAM",
  "DUDAN",
  "DUDAR",
  "DUDAŠ",
  "DUDAT",
  "DUDAU",
  "DUDAV",
  "DUDEK",
  "DUDEN",
  "DUDIC",
  "DUDIH",
  "DUDIN",
  "DUDJA",
  "DUDJU",
  "DUDKA",
  "DUDKE",
  "DUDKI",
  "DUDKO",
  "DUDKU",
  "DUDLA",
  "DUDLI",
  "DUDLU",
  "DUDNA",
  "DUDNU",
  "DUDOK",
  "DUDOM",
  "DUDOV",
  "DUECK",
  "DUELA",
  "DUELE",
  "DUELI",
  "DUELL",
  "DUELU",
  "DUERA",
  "DUERO",
  "DUERR",
  "DUERU",
  "DUETA",
  "DUETE",
  "DUETI",
  "DUETU",
  "DUFFA",
  "DUFFE",
  "DUFFI",
  "DUFFU",
  "DUFLO",
  "DUGAL",
  "DUGAN",
  "DUGAR",
  "DUGAS",
  "DUGGA",
  "DUGGE",
  "DUGGI",
  "DUGGU",
  "DUGIH",
  "DUGIN",
  "DUGJA",
  "DUGJU",
  "DUGOM",
  "DUGOV",
  "DUHAJ",
  "DUHAL",
  "DUHAM",
  "DUHAŠ",
  "DUHAT",
  "DUHAU",
  "DUHAV",
  "DUHCA",
  "DUHCE",
  "DUHCI",
  "DUHCU",
  "DUHEC",
  "DUHEK",
  "DUHEM",
  "DUHEV",
  "DUHIG",
  "DUHIH",
  "DUHJA",
  "DUHJU",
  "DUHKA",
  "DUHKE",
  "DUHKI",
  "DUHKU",
  "DUHOM",
  "DUHON",
  "DUHOV",
  "DUHTE",
  "DUHTI",
  "DUHTU",
  "DUINA",
  "DUINE",
  "DUINI",
  "DUINO",
  "DUINU",
  "DUJCA",
  "DUJCE",
  "DUJCI",
  "DUJCU",
  "DUJEM",
  "DUJEV",
  "DUJIČ",
  "DUJIH",
  "DUJJA",
  "DUJJU",
  "DUJOM",
  "DUJOV",
  "DUKAH",
  "DUKAM",
  "DUKAN",
  "DUKAT",
  "DUKER",
  "DUKES",
  "DUKIČ",
  "DUKIH",
  "DUKIN",
  "DUKJA",
  "DUKJU",
  "DUKOM",
  "DUKOV",
  "DULAC",
  "DULAH",
  "DULAM",
  "DULAR",
  "DULCA",
  "DULCE",
  "DULCI",
  "DULCU",
  "DULEC",
  "DULER",
  "DULIG",
  "DULIN",
  "DULJA",
  "DULJE",
  "DULJI",
  "DULJU",
  "DULKA",
  "DULKE",
  "DULKI",
  "DULKO",
  "DULKU",
  "DULLA",
  "DULLE",
  "DULLI",
  "DULLU",
  "DUMAH",
  "DUMAM",
  "DUMAN",
  "DUMAS",
  "DUMBA",
  "DUMBE",
  "DUMBI",
  "DUMBO",
  "DUMBU",
  "DUMKA",
  "DUMKE",
  "DUMKI",
  "DUMKO",
  "DUMKU",
  "DUNAJ",
  "DUNDA",
  "DUNDE",
  "DUNDI",
  "DUNDO",
  "DUNDU",
  "DUNIH",
  "DUNIN",
  "DUNIS",
  "DUNIT",
  "DUNJA",
  "DUNJE",
  "DUNJI",
  "DUNJO",
  "DUNJU",
  "DUNKA",
  "DUNKE",
  "DUNKI",
  "DUNKU",
  "DUNNA",
  "DUNNE",
  "DUNNI",
  "DUNNU",
  "DUNOM",
  "DUNOV",
  "DUNST",
  "DUOLA",
  "DUOLE",
  "DUOLI",
  "DUOLO",
  "DUOLU",
  "DUOMA",
  "DUOPA",
  "DUOPE",
  "DUOPI",
  "DUOPU",
  "DUOTA",
  "DUOTU",
  "DUPAT",
  "DUPEL",
  "DUPIN",
  "DUPLA",
  "DUPLI",
  "DUPLO",
  "DUPLU",
  "DUPRE",
  "DUPRI",
  "DURAA",
  "DURAE",
  "DURAI",
  "DURAJ",
  "DURAK",
  "DURAL",
  "DURAM",
  "DURAN",
  "DURAO",
  "DURAS",
  "DURAU",
  "DURCA",
  "DURCE",
  "DURCI",
  "DUREM",
  "DUREN",
  "DUREV",
  "DURGA",
  "DURGE",
  "DURGI",
  "DURGO",
  "DURIČ",
  "DURIE",
  "DURIG",
  "DURIH",
  "DURIM",
  "DURIN",
  "DURIŠ",
  "DURJA",
  "DURJE",
  "DURJI",
  "DURJO",
  "DURJU",
  "DURKA",
  "DURKE",
  "DURKI",
  "DURKU",
  "DURMA",
  "DURME",
  "DURMI",
  "DURMO",
  "DURMU",
  "DURNA",
  "DURNE",
  "DURNI",
  "DURNU",
  "DUROM",
  "DUROV",
  "DURRA",
  "DURRE",
  "DURRI",
  "DURRU",
  "DURSA",
  "DURSE",
  "DURSI",
  "DURST",
  "DURSU",
  "DURUL",
  "DURUM",
  "DURZI",
  "DUSHI",
  "DUSKA",
  "DUSKE",
  "DUSKI",
  "DUSKU",
  "DUSSA",
  "DUSSE",
  "DUSSI",
  "DUSSU",
  "DUŠAH",
  "DUŠAK",
  "DUŠAM",
  "DUŠAN",
  "DUŠEČ",
  "DUŠEK",
  "DUŠEN",
  "DUŠIC",
  "DUŠIČ",
  "DUŠIH",
  "DUŠIK",
  "DUŠIL",
  "DUŠIM",
  "DUŠIN",
  "DUŠIŠ",
  "DUŠIT",
  "DUŠIU",
  "DUŠIV",
  "DUŠJA",
  "DUŠJU",
  "DUŠKA",
  "DUŠKE",
  "DUŠKI",
  "DUŠKO",
  "DUŠKU",
  "DUŠNA",
  "DUŠNE",
  "DUŠNI",
  "DUŠNO",
  "DUŠOM",
  "DUŠOV",
  "DUTCH",
  "DUTER",
  "DUTKA",
  "DUTKE",
  "DUTKI",
  "DUTKO",
  "DUTKU",
  "DUTRA",
  "DUTRE",
  "DUTRI",
  "DUTRO",
  "DUTRU",
  "DUTTA",
  "DUTTE",
  "DUTTI",
  "DUTTO",
  "DUTTU",
  "DUTZI",
  "DUUSA",
  "DUUSE",
  "DUUSI",
  "DUUSU",
  "DUVAL",
  "DUVAN",
  "DUVIH",
  "DUVJA",
  "DUVJU",
  "DUVOM",
  "DUVOV",
  "DVALI",
  "DVEMA",
  "DVEMI",
  "DVERC",
  "DVERI",
  "DVIGA",
  "DVIGE",
  "DVIGI",
  "DVIGU",
  "DVIII",
  "DVINA",
  "DVINE",
  "DVINI",
  "DVINO",
  "DVOJA",
  "DVOJC",
  "DVOJI",
  "DVOJK",
  "DVOJO",
  "DVOMA",
  "DVOME",
  "DVOMI",
  "DVOMU",
  "DVOOK",
  "DVORA",
  "DVORC",
  "DVORE",
  "DVORI",
  "DVORK",
  "DVORO",
  "DVORU",
  "DZETA",
  "DZETE",
  "DZETI",
  "DZETO",
  "DZIKA",
  "DZIKE",
  "DZIKI",
  "DZIKU",
  "DZIRI",
  "DŽABE",
  "DŽANG",
  "DŽAPA",
  "DŽAPE",
  "DŽAPI",
  "DŽAPO",
  "DŽAPU",
  "DŽAVR",
  "DŽEDA",
  "DŽEDE",
  "DŽEDI",
  "DŽEDO",
  "DŽEJA",
  "DŽEJE",
  "DŽEJI",
  "DŽEJU",
  "DŽEKA",
  "DŽEKE",
  "DŽEKI",
  "DŽEKO",
  "DŽEKU",
  "DŽEMA",
  "DŽEME",
  "DŽEMI",
  "DŽEMU",
  "DŽEZA",
  "DŽEZI",
  "DŽEZU",
  "DŽIDA",
  "DŽIDE",
  "DŽIDI",
  "DŽIDO",
  "DŽINA",
  "DŽINE",
  "DŽINI",
  "DŽINS",
  "DŽINU",
  "DŽIPA",
  "DŽIPE",
  "DŽIPI",
  "DŽIPU",
  "DŽOMB",
  "DŽONG",
  "DŽONI",
  "DŽUDA",
  "DŽUDI",
  "DŽUDO",
  "DŽUDU",
  "DŽULA",
  "DŽULE",
  "DŽULI",
  "DŽULU",
  "DŽUNK",
  "DŽURŽ",
  "DŽUSA",
  "DŽUSE",
  "DŽUSI",
  "DŽUSU",
  "EADES",
  "EADIE",
  "EADSA",
  "EADSE",
  "EADSI",
  "EADSU",
  "EAGAN",
  "EAGER",
  "EAGLA",
  "EAGLE",
  "EAGLI",
  "EAGLU",
  "EAGRA",
  "EAGRE",
  "EAGRI",
  "EAGRU",
  "EAKES",
  "EAKIN",
  "EALES",
  "EAMES",
  "EAMON",
  "EARLA",
  "EARLE",
  "EARLI",
  "EARLL",
  "EARLS",
  "EARLU",
  "EARPA",
  "EARPE",
  "EARPI",
  "EARPS",
  "EARPU",
  "EASON",
  "EASTA",
  "EASTE",
  "EASTI",
  "EASTU",
  "EATON",
  "EAVES",
  "EAVIS",
  "EBADI",
  "EBANA",
  "EBANE",
  "EBANI",
  "EBANU",
  "EBBAH",
  "EBBAM",
  "EBBIN",
  "EBELI",
  "EBELL",
  "EBELS",
  "EBELT",
  "EBERL",
  "EBERS",
  "EBERT",
  "EBESU",
  "EBING",
  "EBNER",
  "EBOLA",
  "EBOLE",
  "EBOLI",
  "EBOLO",
  "EBONG",
  "EBONI",
  "EBOTU",
  "EBRIH",
  "EBRJA",
  "EBRJU",
  "EBROM",
  "EBROV",
  "EBSEN",
  "EBSNA",
  "EBSNE",
  "EBSNI",
  "EBSNU",
  "EBUKA",
  "ECEJA",
  "ECEJE",
  "ECEJI",
  "ECEJU",
  "ECHOS",
  "ECIJA",
  "ECIJE",
  "ECIJI",
  "ECIJO",
  "ECKEL",
  "ECKER",
  "ECKIH",
  "ECKJA",
  "ECKJU",
  "ECKLA",
  "ECKLE",
  "ECKLI",
  "ECKLU",
  "ECKOM",
  "ECKOV",
  "ECKSA",
  "ECKSE",
  "ECKSI",
  "ECKSU",
  "ECOMA",
  "ECOTA",
  "ECOTU",
  "ECOVA",
  "ECOVE",
  "ECOVI",
  "ECOVO",
  "EDAMA",
  "EDAMC",
  "EDAMI",
  "EDANA",
  "EDANE",
  "EDANI",
  "EDANO",
  "EDANU",
  "EDDAH",
  "EDDAM",
  "EDDIE",
  "EDDIH",
  "EDDIN",
  "EDDJA",
  "EDDJU",
  "EDDOM",
  "EDDOV",
  "EDEKA",
  "EDEKE",
  "EDEKI",
  "EDEKO",
  "EDEMA",
  "EDEME",
  "EDEMI",
  "EDEMU",
  "EDENA",
  "EDENE",
  "EDENI",
  "EDENS",
  "EDENU",
  "EDESA",
  "EDESE",
  "EDESI",
  "EDESO",
  "EDGAR",
  "EDGEA",
  "EDGEE",
  "EDGEI",
  "EDGEU",
  "EDHEM",
  "EDIGS",
  "EDIJA",
  "EDIJE",
  "EDIJI",
  "EDIJU",
  "EDIKT",
  "EDILA",
  "EDILE",
  "EDILI",
  "EDILU",
  "EDINA",
  "EDINC",
  "EDINE",
  "EDINI",
  "EDINK",
  "EDINO",
  "EDINU",
  "EDIPA",
  "EDIPE",
  "EDIPI",
  "EDIPU",
  "EDISA",
  "EDISE",
  "EDISI",
  "EDISO",
  "EDISU",
  "EDITA",
  "EDITE",
  "EDITH",
  "EDITI",
  "EDITK",
  "EDITO",
  "EDLER",
  "EDLIH",
  "EDLIR",
  "EDLIS",
  "EDLJA",
  "EDLJU",
  "EDLOM",
  "EDLOV",
  "EDMAN",
  "EDMIR",
  "EDNAH",
  "EDNAM",
  "EDNER",
  "EDNIH",
  "EDNIN",
  "EDNJA",
  "EDNJU",
  "EDNOM",
  "EDNOV",
  "EDOMA",
  "EDONA",
  "EDONE",
  "EDONI",
  "EDONO",
  "EDONU",
  "EDOTA",
  "EDOTU",
  "EDOVA",
  "EDOVE",
  "EDOVI",
  "EDOVO",
  "EDRIC",
  "EDSON",
  "EDVIN",
  "EEGOL",
  "EELES",
  "EELLS",
  "EEOTA",
  "EERIH",
  "EERJA",
  "EERJE",
  "EERJI",
  "EERJU",
  "EEROL",
  "EEROM",
  "EEROV",
  "EETJU",
  "EEVAH",
  "EEVAM",
  "EEVIN",
  "EFEKT",
  "EFERL",
  "EFESA",
  "EFESU",
  "EFEZA",
  "EFEZU",
  "EFFIE",
  "EFIMA",
  "EFIME",
  "EFIMI",
  "EFIMU",
  "EFIRA",
  "EFIRE",
  "EFIRI",
  "EFIRO",
  "EFNIL",
  "EFOSA",
  "EFOSU",
  "EFRAH",
  "EFRAM",
  "EFRAN",
  "EFREM",
  "EFREN",
  "EFRIH",
  "EFRIN",
  "EFRJA",
  "EFRJU",
  "EFROM",
  "EFRON",
  "EFROV",
  "EGANA",
  "EGANE",
  "EGANI",
  "EGANU",
  "EGART",
  "EGEDE",
  "EGELJ",
  "EGGAN",
  "EGGEL",
  "EGGER",
  "EGGLA",
  "EGGLE",
  "EGGLI",
  "EGGLU",
  "EGHAN",
  "EGIDI",
  "EGIJA",
  "EGIJE",
  "EGIJI",
  "EGIJU",
  "EGILA",
  "EGILE",
  "EGILI",
  "EGILS",
  "EGILU",
  "EGIPT",
  "EGISA",
  "EGISU",
  "EGKIH",
  "EGKJA",
  "EGKJU",
  "EGKOM",
  "EGKOV",
  "EGLIN",
  "EGNAR",
  "EGNER",
  "EGOLF",
  "EGOMA",
  "EGONA",
  "EGONE",
  "EGONI",
  "EGONU",
  "EGOTA",
  "EGOTU",
  "EGOVA",
  "EGOVE",
  "EGOVI",
  "EGOVO",
  "EGRES",
  "EGZON",
  "EHADA",
  "EHADE",
  "EHADI",
  "EHADU",
  "EHALT",
  "EHART",
  "EHASZ",
  "EHATA",
  "EHATE",
  "EHATI",
  "EHATU",
  "EHLER",
  "EHLIH",
  "EHLJA",
  "EHLJU",
  "EHLOM",
  "EHLOV",
  "EHMAN",
  "EHNIH",
  "EHNJA",
  "EHNJU",
  "EHNOM",
  "EHNOV",
  "EHRAT",
  "EHRET",
  "EHSAN",
  "EHSER",
  "EHUDA",
  "EHUDE",
  "EHUDI",
  "EHUDU",
  "EIBAR",
  "EIBEL",
  "EIBLA",
  "EIBLE",
  "EIBLI",
  "EIBLU",
  "EICCA",
  "EICCE",
  "EICCI",
  "EICCO",
  "EICCU",
  "EICHA",
  "EICHE",
  "EICHI",
  "EICHL",
  "EICHU",
  "EICKA",
  "EICKE",
  "EICKI",
  "EICKU",
  "EIDAM",
  "EIDER",
  "EIDIH",
  "EIDIN",
  "EIDJA",
  "EIDJU",
  "EIDOM",
  "EIDOV",
  "EIDTA",
  "EIDTE",
  "EIDTI",
  "EIDTU",
  "EIDUR",
  "EIFFA",
  "EIFFE",
  "EIFFI",
  "EIFFU",
  "EIGEN",
  "EIGER",
  "EIGNA",
  "EIGNE",
  "EIGNI",
  "EIGNU",
  "EIKEN",
  "EIKIH",
  "EIKIN",
  "EIKJA",
  "EIKJU",
  "EIKNA",
  "EIKNE",
  "EIKNI",
  "EIKNU",
  "EIKOM",
  "EIKOV",
  "EILAM",
  "EILAT",
  "EILER",
  "EILIF",
  "EILTS",
  "EINAR",
  "EINEM",
  "EINIH",
  "EINJA",
  "EINJU",
  "EINOM",
  "EINOV",
  "EIOTU",
  "EIPPA",
  "EIPPE",
  "EIPPI",
  "EIPPU",
  "EIRIK",
  "EIRIN",
  "EISAH",
  "EISAI",
  "EISAM",
  "EISEL",
  "EISEN",
  "EISER",
  "EISIH",
  "EISJA",
  "EISJU",
  "EISLA",
  "EISLE",
  "EISLI",
  "EISLU",
  "EISNA",
  "EISNE",
  "EISNI",
  "EISNU",
  "EISOM",
  "EISOV",
  "EITAN",
  "EIVIN",
  "EIZAH",
  "EIZAM",
  "EIZIN",
  "EJIMA",
  "EJIME",
  "EJIMI",
  "EJIMU",
  "EJLLI",
  "EJLON",
  "EJONA",
  "EJONE",
  "EJONI",
  "EJONO",
  "EJUBA",
  "EJUBE",
  "EJUBI",
  "EJUBU",
  "EJUPA",
  "EJUPE",
  "EJUPI",
  "EJUPU",
  "EKAMA",
  "EKAMI",
  "EKART",
  "EKASA",
  "EKASE",
  "EKASI",
  "EKASU",
  "EKAVC",
  "EKAVK",
  "EKCEM",
  "EKDIS",
  "EKELI",
  "EKICI",
  "EKINA",
  "EKINE",
  "EKINI",
  "EKINO",
  "EKINS",
  "EKINU",
  "EKIPA",
  "EKIPE",
  "EKIPI",
  "EKIPO",
  "EKLOG",
  "EKMAN",
  "EKMEN",
  "EKOCI",
  "EKOEN",
  "EKOLA",
  "EKOLU",
  "EKOMA",
  "EKONG",
  "EKOSA",
  "EKOSU",
  "EKOTA",
  "EKOTU",
  "EKOVA",
  "EKOVE",
  "EKOVI",
  "EKOVO",
  "EKRAN",
  "EKREM",
  "EKSAJ",
  "EKSAL",
  "EKSAM",
  "EKSAN",
  "EKSAŠ",
  "EKSAT",
  "EKSAU",
  "EKSAV",
  "EKSEM",
  "EKSEV",
  "EKSIH",
  "EKSJA",
  "EKSJU",
  "EKSNA",
  "EKSNE",
  "EKSNI",
  "EKSOM",
  "EKSON",
  "EKSOT",
  "EKSOV",
  "EKTIM",
  "EKUJA",
  "EKUJE",
  "EKUJI",
  "EKUJU",
  "ELADA",
  "ELADE",
  "ELADI",
  "ELADU",
  "ELAIN",
  "ELALU",
  "ELAMA",
  "ELAME",
  "ELAMI",
  "ELAMU",
  "ELANA",
  "ELAND",
  "ELANE",
  "ELANG",
  "ELANI",
  "ELANK",
  "ELANO",
  "ELANU",
  "ELBAH",
  "ELBAM",
  "ELBAZ",
  "ELBER",
  "ELBIH",
  "ELBIN",
  "ELBJA",
  "ELBJU",
  "ELBLA",
  "ELBLE",
  "ELBLI",
  "ELBLU",
  "ELBOM",
  "ELBOV",
  "ELBSA",
  "ELBSE",
  "ELBSI",
  "ELBSU",
  "ELCHE",
  "ELCOM",
  "ELDAH",
  "ELDAM",
  "ELDAN",
  "ELDAR",
  "ELDEM",
  "ELDER",
  "ELDHA",
  "ELDHE",
  "ELDHI",
  "ELDHU",
  "ELDIN",
  "ELDIS",
  "ELDOM",
  "ELDON",
  "ELEAH",
  "ELEAM",
  "ELEAN",
  "ELEAT",
  "ELEKA",
  "ELEKE",
  "ELEKI",
  "ELEKU",
  "ELENA",
  "ELENE",
  "ELENI",
  "ELENO",
  "ELERS",
  "ELESA",
  "ELESU",
  "ELEZA",
  "ELEZE",
  "ELEZI",
  "ELEZU",
  "ELFEM",
  "ELFET",
  "ELFEV",
  "ELFIE",
  "ELFIH",
  "ELFIN",
  "ELFJA",
  "ELFJU",
  "ELFOM",
  "ELFOV",
  "ELGAH",
  "ELGAM",
  "ELGAR",
  "ELGER",
  "ELGIE",
  "ELGIN",
  "ELGON",
  "ELIAH",
  "ELIAM",
  "ELIAN",
  "ELIAS",
  "ELIAŠ",
  "ELIAV",
  "ELICA",
  "ELICE",
  "ELICI",
  "ELICO",
  "ELIDA",
  "ELIDE",
  "ELIDI",
  "ELIDO",
  "ELIEM",
  "ELIEV",
  "ELIGA",
  "ELIGO",
  "ELIGU",
  "ELIIH",
  "ELIJA",
  "ELIJE",
  "ELIJI",
  "ELIJO",
  "ELIJU",
  "ELIKA",
  "ELIKE",
  "ELIKI",
  "ELIKO",
  "ELIKU",
  "ELINA",
  "ELINE",
  "ELING",
  "ELINI",
  "ELINO",
  "ELINU",
  "ELIOM",
  "ELION",
  "ELIOT",
  "ELIOV",
  "ELIPS",
  "ELIRA",
  "ELIRE",
  "ELIRI",
  "ELIRO",
  "ELISA",
  "ELISE",
  "ELISH",
  "ELISI",
  "ELISO",
  "ELISS",
  "ELISU",
  "ELIŠK",
  "ELITA",
  "ELITE",
  "ELITI",
  "ELITO",
  "ELITZ",
  "ELIUD",
  "ELIZA",
  "ELIZE",
  "ELIZI",
  "ELIZO",
  "ELJAN",
  "ELJAS",
  "ELJES",
  "ELJIF",
  "ELJON",
  "ELKAH",
  "ELKAJ",
  "ELKAL",
  "ELKAM",
  "ELKAN",
  "ELKAŠ",
  "ELKAT",
  "ELKAU",
  "ELKAV",
  "ELKEM",
  "ELKIH",
  "ELKIN",
  "ELKIS",
  "ELKJA",
  "ELKJU",
  "ELKOM",
  "ELKOV",
  "ELLAH",
  "ELLAM",
  "ELLEN",
  "ELLER",
  "ELLES",
  "ELLIE",
  "ELLIN",
  "ELLIS",
  "ELLSA",
  "ELLSE",
  "ELLSI",
  "ELLSU",
  "ELLUL",
  "ELLUM",
  "ELMAH",
  "ELMAM",
  "ELMAN",
  "ELMAR",
  "ELMAS",
  "ELMAZ",
  "ELMER",
  "ELMES",
  "ELMIN",
  "ELMIR",
  "ELMSA",
  "ELMSE",
  "ELMSI",
  "ELMSU",
  "ELNAZ",
  "ELNIF",
  "ELOIZ",
  "ELONA",
  "ELONE",
  "ELONI",
  "ELONO",
  "ELONU",
  "ELOPA",
  "ELOPE",
  "ELOPI",
  "ELOPU",
  "ELORA",
  "ELORE",
  "ELORI",
  "ELORO",
  "ELPAN",
  "ELRAD",
  "ELROD",
  "ELSAH",
  "ELSAM",
  "ELSER",
  "ELSIE",
  "ELSIN",
  "ELSOM",
  "ELSON",
  "ELSTA",
  "ELSTE",
  "ELSTI",
  "ELSTU",
  "ELTIM",
  "ELTON",
  "ELURU",
  "ELUTA",
  "ELUTE",
  "ELUTI",
  "ELUTO",
  "ELUTU",
  "ELVAH",
  "ELVAM",
  "ELVAN",
  "ELVEZ",
  "ELVID",
  "ELVIK",
  "ELVIN",
  "ELVIR",
  "ELVIS",
  "ELZAH",
  "ELZAM",
  "ELZIN",
  "EMAIL",
  "EMAJL",
  "EMAMA",
  "EMAME",
  "EMAMI",
  "EMAMU",
  "EMANA",
  "EMANE",
  "EMANI",
  "EMANU",
  "EMAVS",
  "EMBEL",
  "EMBLA",
  "EMBLE",
  "EMBLI",
  "EMBLO",
  "EMCKE",
  "EMDEN",
  "EMDIN",
  "EMDNA",
  "EMDNU",
  "EMEFA",
  "EMEFE",
  "EMEFI",
  "EMEFO",
  "EMERT",
  "EMESA",
  "EMESE",
  "EMESI",
  "EMESU",
  "EMETT",
  "EMEZI",
  "EMIDI",
  "EMIKA",
  "EMIKE",
  "EMIKI",
  "EMIKO",
  "EMILA",
  "EMILE",
  "EMILI",
  "EMILS",
  "EMILU",
  "EMINA",
  "EMINE",
  "EMINI",
  "EMINO",
  "EMINU",
  "EMIOL",
  "EMIRA",
  "EMIRE",
  "EMIRI",
  "EMIRO",
  "EMKEJ",
  "EMKOR",
  "EMMAH",
  "EMMAM",
  "EMMET",
  "EMMIN",
  "EMMSA",
  "EMMSE",
  "EMMSI",
  "EMMSU",
  "EMOMA",
  "EMONA",
  "EMOND",
  "EMONE",
  "EMONI",
  "EMONK",
  "EMONO",
  "EMONS",
  "EMORI",
  "EMOTA",
  "EMOTE",
  "EMOTI",
  "EMOTO",
  "EMOTU",
  "EMOVA",
  "EMOVE",
  "EMOVI",
  "EMOVO",
  "EMPAT",
  "EMPIR",
  "EMPIS",
  "EMPOR",
  "EMPPU",
  "EMRAH",
  "EMRAM",
  "EMREE",
  "EMRIH",
  "EMRIK",
  "EMRJA",
  "EMRJU",
  "EMROM",
  "EMROV",
  "EMŠEM",
  "EMŠEV",
  "EMŠIH",
  "EMŠJA",
  "EMŠJU",
  "EMŠOM",
  "EMŠOV",
  "EMUJA",
  "EMUJE",
  "EMUJI",
  "EMUJU",
  "EMZIN",
  "ENAČB",
  "ENAČI",
  "ENAČL",
  "ENAČU",
  "ENAKA",
  "ENAKE",
  "ENAKI",
  "ENAKO",
  "ENAKU",
  "ENAMA",
  "ENAMI",
  "ENBOM",
  "ENCIM",
  "ENCKE",
  "ENČEV",
  "ENDAH",
  "ENDAM",
  "ENDAR",
  "ENDER",
  "ENDIH",
  "ENDJA",
  "ENDJU",
  "ENDOM",
  "ENDOV",
  "ENDRE",
  "ENDRI",
  "ENDTA",
  "ENDTE",
  "ENDTI",
  "ENDTU",
  "ENEAH",
  "ENEAM",
  "ENEDA",
  "ENEDE",
  "ENEDI",
  "ENEDO",
  "ENEIH",
  "ENEJA",
  "ENEJE",
  "ENEJI",
  "ENEJO",
  "ENEJU",
  "ENEKA",
  "ENEKE",
  "ENEKI",
  "ENEKO",
  "ENEKU",
  "ENELA",
  "ENELU",
  "ENEMU",
  "ENEOM",
  "ENEOV",
  "ENESA",
  "ENESE",
  "ENESI",
  "ENESO",
  "ENESU",
  "ENGEL",
  "ENGER",
  "ENGIE",
  "ENGIH",
  "ENGIN",
  "ENGJA",
  "ENGJU",
  "ENGLA",
  "ENGLE",
  "ENGLI",
  "ENGLU",
  "ENGOB",
  "ENGOM",
  "ENGOV",
  "ENGST",
  "ENICA",
  "ENICE",
  "ENICI",
  "ENICO",
  "ENIDA",
  "ENIDE",
  "ENIDI",
  "ENIDO",
  "ENIGK",
  "ENIKA",
  "ENIKE",
  "ENIKI",
  "ENIKO",
  "ENIKU",
  "ENIMA",
  "ENIMI",
  "ENINA",
  "ENINE",
  "ENINI",
  "ENINO",
  "ENIOL",
  "ENISA",
  "ENISE",
  "ENISI",
  "ENISO",
  "ENISU",
  "ENJAH",
  "ENJAM",
  "ENJIN",
  "ENKAH",
  "ENKAM",
  "ENKIH",
  "ENKJA",
  "ENKJU",
  "ENKOM",
  "ENKOV",
  "ENLEN",
  "ENLOE",
  "ENNAH",
  "ENNAM",
  "ENNER",
  "ENNIA",
  "ENNIE",
  "ENNIH",
  "ENNII",
  "ENNIN",
  "ENNIO",
  "ENNIS",
  "ENNIU",
  "ENNJA",
  "ENNJU",
  "ENNOM",
  "ENNOV",
  "ENNSA",
  "ENNSE",
  "ENNSI",
  "ENNSU",
  "ENOCH",
  "ENOJA",
  "ENOJC",
  "ENOJE",
  "ENOJI",
  "ENOJK",
  "ENOJO",
  "ENOKA",
  "ENOKE",
  "ENOKI",
  "ENOKU",
  "ENOLA",
  "ENOLE",
  "ENOLI",
  "ENOLO",
  "ENOLU",
  "ENOMA",
  "ENOOK",
  "ENOSA",
  "ENOSE",
  "ENOSI",
  "ENOST",
  "ENOSU",
  "ENOŠA",
  "ENOŠE",
  "ENOŠI",
  "ENOŠU",
  "ENOTA",
  "ENOTE",
  "ENOTI",
  "ENOTO",
  "ENOTU",
  "ENOVA",
  "ENOVE",
  "ENOVI",
  "ENOVO",
  "ENOVU",
  "ENRIA",
  "ENRIC",
  "ENRID",
  "ENRIE",
  "ENRII",
  "ENRIJ",
  "ENRIK",
  "ENRIO",
  "ENRIU",
  "ENRON",
  "ENSAR",
  "ENSIH",
  "ENSJA",
  "ENSJU",
  "ENSKA",
  "ENSKE",
  "ENSKI",
  "ENSKO",
  "ENSKU",
  "ENSOL",
  "ENSOM",
  "ENSOV",
  "ENSSA",
  "ENSSE",
  "ENSSI",
  "ENSSU",
  "ENTAZ",
  "ENTEL",
  "ENTER",
  "ENTIN",
  "ENTLA",
  "ENTLI",
  "ENTLU",
  "ENTRA",
  "ENTRE",
  "ENTRI",
  "ENTRU",
  "ENVAH",
  "ENVAM",
  "ENVER",
  "ENVIN",
  "ENVIT",
  "ENZEM",
  "ENZEV",
  "ENZIH",
  "ENZJA",
  "ENZJU",
  "ENZOM",
  "ENZOV",
  "EOCEN",
  "EOINA",
  "EOINE",
  "EOINI",
  "EOINU",
  "EOLCA",
  "EOLCE",
  "EOLCI",
  "EOLCU",
  "EOLEC",
  "EOLIH",
  "EOLJA",
  "EOLJU",
  "EOLKA",
  "EOLKE",
  "EOLKI",
  "EOLKO",
  "EOLOM",
  "EOLOV",
  "EONEM",
  "EONEV",
  "EONIH",
  "EONJA",
  "EONJU",
  "EONOM",
  "EONOV",
  "EOTOM",
  "EOZIN",
  "EPAFA",
  "EPAFE",
  "EPAFI",
  "EPAFU",
  "EPAKT",
  "EPARH",
  "EPEJA",
  "EPEJE",
  "EPEJI",
  "EPEJU",
  "EPICA",
  "EPICU",
  "EPIHA",
  "EPIHE",
  "EPIHI",
  "EPIHU",
  "EPIKA",
  "EPIKE",
  "EPIKI",
  "EPIKO",
  "EPIKU",
  "EPIRA",
  "EPIRK",
  "EPIRU",
  "EPLER",
  "EPLIN",
  "EPNER",
  "EPOHA",
  "EPOHE",
  "EPOHI",
  "EPOHO",
  "EPOMA",
  "EPONA",
  "EPONI",
  "EPONU",
  "EPOTA",
  "EPOTU",
  "EPPER",
  "EPPSA",
  "EPPSE",
  "EPPSI",
  "EPPSU",
  "EPSKA",
  "EPSKE",
  "EPSKI",
  "EPSKO",
  "EPSOM",
  "EPSON",
  "EPŠEK",
  "EPŠKA",
  "EPŠKE",
  "EPŠKI",
  "EPŠKU",
  "EPTIN",
  "EPTON",
  "ERAKA",
  "ERAKE",
  "ERAKI",
  "ERAKU",
  "ERALB",
  "ERALP",
  "ERAMA",
  "ERAMI",
  "ERANA",
  "ERAND",
  "ERANE",
  "ERANI",
  "ERANU",
  "ERASA",
  "ERASE",
  "ERASI",
  "ERASO",
  "ERASU",
  "ERATA",
  "ERATE",
  "ERATI",
  "ERATO",
  "ERATU",
  "ERBEN",
  "ERBER",
  "ERBID",
  "ERBIH",
  "ERBIJ",
  "ERBIL",
  "ERBJA",
  "ERBJU",
  "ERBNA",
  "ERBNE",
  "ERBNI",
  "ERBNU",
  "ERBOM",
  "ERBOV",
  "ERBUS",
  "ERCAN",
  "ERCEG",
  "ERCEK",
  "ERCIN",
  "ERCKA",
  "ERCKE",
  "ERCKI",
  "ERCKU",
  "ERCOT",
  "ERDAL",
  "ERDAN",
  "ERDEM",
  "ERDEŠ",
  "ERDIS",
  "ERDTA",
  "ERDTE",
  "ERDTI",
  "ERDTU",
  "ERDUT",
  "EREIR",
  "EREIZ",
  "ERELI",
  "EREND",
  "ERENT",
  "EREŠA",
  "EREŠE",
  "EREŠI",
  "EREŠU",
  "ERGEM",
  "ERGEV",
  "ERGIH",
  "ERGIN",
  "ERGJA",
  "ERGJU",
  "ERGOM",
  "ERGON",
  "ERGOV",
  "ERGUN",
  "ERHAN",
  "ERHEL",
  "ERHLA",
  "ERHLE",
  "ERHLI",
  "ERHLU",
  "ERHOL",
  "ERIAN",
  "ERICA",
  "ERICE",
  "ERICH",
  "ERICI",
  "ERICK",
  "ERICO",
  "ERICU",
  "ERIČA",
  "ERIČE",
  "ERIČI",
  "ERIČU",
  "ERIDA",
  "ERIDE",
  "ERIDI",
  "ERIDO",
  "ERIEM",
  "ERIEV",
  "ERIGA",
  "ERIGE",
  "ERIGI",
  "ERIGU",
  "ERIHA",
  "ERIHE",
  "ERIHI",
  "ERIHU",
  "ERIIH",
  "ERIJA",
  "ERIJU",
  "ERIKA",
  "ERIKE",
  "ERIKI",
  "ERIKO",
  "ERIKU",
  "ERINA",
  "ERINE",
  "ERINI",
  "ERINN",
  "ERINO",
  "ERINU",
  "ERIOM",
  "ERION",
  "ERIOV",
  "ERISA",
  "ERISE",
  "ERISI",
  "ERISU",
  "ERIVA",
  "ERIVE",
  "ERIVI",
  "ERIVO",
  "ERIVU",
  "ERJOL",
  "ERJON",
  "ERKAL",
  "ERKAN",
  "ERKAR",
  "ERKER",
  "ERKIČ",
  "ERKIN",
  "ERKKI",
  "ERLAČ",
  "ERLAH",
  "ERLAM",
  "ERLER",
  "ERLET",
  "ERLIH",
  "ERLIN",
  "ERLIS",
  "ERMAL",
  "ERMAN",
  "ERMEL",
  "ERMEN",
  "ERMIN",
  "ERMIR",
  "ERNAH",
  "ERNAM",
  "ERNDL",
  "ERNES",
  "ERNIC",
  "ERNIE",
  "ERNIH",
  "ERNIN",
  "ERNIŠ",
  "ERNJA",
  "ERNJU",
  "ERNOM",
  "ERNOV",
  "ERNST",
  "EROLA",
  "EROLE",
  "EROLI",
  "EROLL",
  "EROLU",
  "EROSA",
  "EROSE",
  "EROSI",
  "EROSU",
  "EROTA",
  "EROTE",
  "EROTI",
  "EROTU",
  "ERPIČ",
  "ERRAT",
  "ERRIC",
  "ERROL",
  "ERSAN",
  "ERSIN",
  "ERSON",
  "ERSTE",
  "ERSUN",
  "ERŠTE",
  "ERTAN",
  "ERTEL",
  "ERTEM",
  "ERTLA",
  "ERTLE",
  "ERTLI",
  "ERTLU",
  "ERTUG",
  "ERVIN",
  "ERZAH",
  "ERZAM",
  "ERZAR",
  "ERZEN",
  "ERZIN",
  "ERZSI",
  "ERŽEN",
  "ESADA",
  "ESADE",
  "ESADI",
  "ESADO",
  "ESADU",
  "ESAKI",
  "ESALA",
  "ESALU",
  "ESAMA",
  "ESAME",
  "ESAMI",
  "ESAMU",
  "ESAST",
  "ESATA",
  "ESATE",
  "ESATI",
  "ESATU",
  "ESAUL",
  "ESCHA",
  "ESCHE",
  "ESCHI",
  "ESCHU",
  "ESCIN",
  "ESDAR",
  "ESEJA",
  "ESEJE",
  "ESEJI",
  "ESEJU",
  "ESENA",
  "ESENC",
  "ESENE",
  "ESENI",
  "ESENK",
  "ESENU",
  "ESERA",
  "ESERE",
  "ESERI",
  "ESERK",
  "ESERO",
  "ESERU",
  "ESHAR",
  "ESHOO",
  "ESIHA",
  "ESIHE",
  "ESIHI",
  "ESIHU",
  "ESION",
  "ESKEN",
  "ESKIH",
  "ESKIM",
  "ESKJA",
  "ESKJU",
  "ESKOL",
  "ESKOM",
  "ESKOV",
  "ESLER",
  "ESMAH",
  "ESMAM",
  "ESMAN",
  "ESMIN",
  "ESODA",
  "ESODE",
  "ESODI",
  "ESODO",
  "ESODU",
  "ESOLA",
  "ESOLU",
  "ESOMA",
  "ESOTA",
  "ESOTU",
  "ESOVA",
  "ESOVE",
  "ESOVI",
  "ESOVO",
  "ESPAD",
  "ESPER",
  "ESPIL",
  "ESPIN",
  "ESRAH",
  "ESRAM",
  "ESRIN",
  "ESSAM",
  "ESSEN",
  "ESSER",
  "ESSIE",
  "ESSIG",
  "ESSLA",
  "ESSLE",
  "ESSLI",
  "ESSLU",
  "ESSNA",
  "ESSNU",
  "ESSON",
  "ESTAH",
  "ESTAM",
  "ESTEL",
  "ESTEN",
  "ESTEP",
  "ESTER",
  "ESTES",
  "ESTET",
  "ESTIL",
  "ESTIN",
  "ESTNA",
  "ESTNE",
  "ESTNI",
  "ESTNU",
  "ESTON",
  "ESTRA",
  "ESTRE",
  "ESTRI",
  "ESTRU",
  "EŠARP",
  "ETAIN",
  "ETAMA",
  "ETAMI",
  "ETANA",
  "ETANE",
  "ETANI",
  "ETANU",
  "ETAPA",
  "ETAPE",
  "ETAPI",
  "ETAPO",
  "ETATA",
  "ETATE",
  "ETATI",
  "ETATU",
  "ETAŽA",
  "ETAŽE",
  "ETAŽI",
  "ETAŽO",
  "ETBIN",
  "ETELK",
  "ETEMA",
  "ETEME",
  "ETEMI",
  "ETEMU",
  "ETENA",
  "ETENI",
  "ETENU",
  "ETGEN",
  "ETHAN",
  "ETHEL",
  "ETHEM",
  "ETHER",
  "ETIEN",
  "ETIKA",
  "ETIKE",
  "ETIKI",
  "ETIKO",
  "ETIKU",
  "ETILA",
  "ETILD",
  "ETILE",
  "ETILI",
  "ETILU",
  "ETIMA",
  "ETIME",
  "ETIMI",
  "ETIMU",
  "ETINA",
  "ETINE",
  "ETINI",
  "ETINO",
  "ETINU",
  "ETISA",
  "ETISU",
  "ETKIH",
  "ETKIN",
  "ETKJA",
  "ETKJU",
  "ETKOM",
  "ETKOV",
  "ETLEV",
  "ETNEM",
  "ETNIJ",
  "ETNJA",
  "ETNJU",
  "ETNOM",
  "ETOLA",
  "ETOLU",
  "ETONA",
  "ETONU",
  "ETOSA",
  "ETOSE",
  "ETOSI",
  "ETOSU",
  "ETREL",
  "ETREM",
  "ETREV",
  "ETRIH",
  "ETRIN",
  "ETRIT",
  "ETRJA",
  "ETRJU",
  "ETROM",
  "ETROV",
  "ETRUR",
  "ETRUS",
  "ETTAH",
  "ETTAM",
  "ETTER",
  "ETTIN",
  "ETUDA",
  "ETUDE",
  "ETUDI",
  "ETUDO",
  "EUANA",
  "EUANE",
  "EUANI",
  "EUANU",
  "EUFOR",
  "EUGEN",
  "EULER",
  "EUREL",
  "EURIH",
  "EURIU",
  "EURJA",
  "EURJU",
  "EUROM",
  "EUROV",
  "EVAIN",
  "EVALD",
  "EVAMA",
  "EVAMI",
  "EVANA",
  "EVANE",
  "EVANI",
  "EVANN",
  "EVANS",
  "EVANU",
  "EVATT",
  "EVEIN",
  "EVELA",
  "EVELE",
  "EVELI",
  "EVELU",
  "EVENA",
  "EVENE",
  "EVENI",
  "EVENO",
  "EVENS",
  "EVENT",
  "EVENU",
  "EVERS",
  "EVERT",
  "EVESA",
  "EVESE",
  "EVESI",
  "EVESU",
  "EVETT",
  "EVGAR",
  "EVGEN",
  "EVIAN",
  "EVINA",
  "EVINE",
  "EVINI",
  "EVINO",
  "EVITA",
  "EVITE",
  "EVITI",
  "EVITO",
  "EVJEN",
  "EVMAJ",
  "EVNUH",
  "EVOLA",
  "EVOLE",
  "EVOLI",
  "EVOLO",
  "EVOLU",
  "EVOMA",
  "EVORS",
  "EVOTA",
  "EVOTU",
  "EVOVA",
  "EVOVE",
  "EVOVI",
  "EVOVO",
  "EVRAZ",
  "EVREM",
  "EVREN",
  "EVREV",
  "EVRIH",
  "EVRIT",
  "EVRJA",
  "EVRJU",
  "EVROM",
  "EVROV",
  "EZANA",
  "EZANE",
  "EZANI",
  "EZANU",
  "EZATA",
  "EZATE",
  "EZATI",
  "EZATU",
  "EZAVA",
  "EZAVE",
  "EZAVI",
  "EZAVU",
  "EZBAN",
  "EZDIN",
  "EZEDI",
  "EZGET",
  "EZIEM",
  "EZIEV",
  "EZIIH",
  "EZIJA",
  "EZIJU",
  "EZIOM",
  "EZIOV",
  "EZOPA",
  "EZOPE",
  "EZOPI",
  "EZOPU",
  "EZRAH",
  "EZRAM",
  "EZRIH",
  "EZRJA",
  "EZRJU",
  "EZROM",
  "EZROV",
  "EZULA",
  "EZULE",
  "EZULI",
  "EZULK",
  "EZULU",
  "FAASA",
  "FAASE",
  "FAASI",
  "FAASU",
  "FABCA",
  "FABCE",
  "FABCI",
  "FABCU",
  "FABEC",
  "FABEL",
  "FABER",
  "FABIA",
  "FABIČ",
  "FABIE",
  "FABII",
  "FABIJ",
  "FABIO",
  "FABIU",
  "FABRA",
  "FABRE",
  "FABRI",
  "FABRO",
  "FABRU",
  "FABUL",
  "FACAH",
  "FACAJ",
  "FACAM",
  "FACCI",
  "FACIA",
  "FACIE",
  "FACIG",
  "FACII",
  "FACIO",
  "FACIU",
  "FACON",
  "FACTO",
  "FAČAH",
  "FAČAM",
  "FADEM",
  "FADID",
  "FADIG",
  "FADIL",
  "FADJA",
  "FADJU",
  "FADOM",
  "FAEHA",
  "FAEHE",
  "FAEHI",
  "FAEHU",
  "FAESI",
  "FAFAČ",
  "FAFAJ",
  "FAFAL",
  "FAFAM",
  "FAFAŠ",
  "FAFAT",
  "FAFAU",
  "FAFAV",
  "FAGAN",
  "FAGEM",
  "FAGEV",
  "FAGGA",
  "FAGGE",
  "FAGGI",
  "FAGGU",
  "FAGIH",
  "FAGIN",
  "FAGJA",
  "FAGJU",
  "FAGOM",
  "FAGOR",
  "FAGOT",
  "FAGOV",
  "FAGUN",
  "FAHDA",
  "FAHDE",
  "FAHDI",
  "FAHDU",
  "FAHIE",
  "FAHIM",
  "FAHMI",
  "FAHNA",
  "FAHNE",
  "FAHNI",
  "FAHNU",
  "FAHRA",
  "FAHRE",
  "FAHRI",
  "FAHRO",
  "FAHRU",
  "FAIKA",
  "FAIKE",
  "FAIKI",
  "FAIKU",
  "FAILA",
  "FAILE",
  "FAILI",
  "FAILL",
  "FAILO",
  "FAILS",
  "FAINA",
  "FAINE",
  "FAINI",
  "FAINO",
  "FAINU",
  "FAIOL",
  "FAIRA",
  "FAIRE",
  "FAIRI",
  "FAIRN",
  "FAIRS",
  "FAIRU",
  "FAISS",
  "FAIST",
  "FAITA",
  "FAITE",
  "FAITH",
  "FAITI",
  "FAITO",
  "FAITU",
  "FAIZA",
  "FAIZE",
  "FAIZI",
  "FAIZO",
  "FAJAH",
  "FAJAM",
  "FAJEM",
  "FAJEV",
  "FAJFA",
  "FAJFE",
  "FAJFI",
  "FAJFO",
  "FAJGL",
  "FAJIH",
  "FAJJA",
  "FAJJU",
  "FAJKA",
  "FAJKE",
  "FAJKI",
  "FAJKO",
  "FAJKU",
  "FAJLA",
  "FAJLE",
  "FAJLI",
  "FAJLU",
  "FAJOM",
  "FAJON",
  "FAJOV",
  "FAJSA",
  "FAJSE",
  "FAJSI",
  "FAJSO",
  "FAJSU",
  "FAJTA",
  "FAJTE",
  "FAJTH",
  "FAJTI",
  "FAJTU",
  "FAKER",
  "FAKET",
  "FAKIH",
  "FAKIN",
  "FAKIR",
  "FAKJA",
  "FAKJU",
  "FAKOM",
  "FAKOV",
  "FAKSA",
  "FAKSE",
  "FAKSI",
  "FAKSO",
  "FAKSU",
  "FAKTA",
  "FAKTE",
  "FAKTI",
  "FAKTU",
  "FAKUČ",
  "FALAN",
  "FALCA",
  "FALCE",
  "FALCI",
  "FALCK",
  "FALCO",
  "FALCU",
  "FALEČ",
  "FALEŽ",
  "FALIL",
  "FALIM",
  "FALIN",
  "FALIŠ",
  "FALIT",
  "FALIU",
  "FALIV",
  "FALKA",
  "FALKE",
  "FALKI",
  "FALKO",
  "FALKU",
  "FALLA",
  "FALLE",
  "FALLI",
  "FALLS",
  "FALLU",
  "FALON",
  "FALOS",
  "FALOT",
  "FALSI",
  "FALUN",
  "FALUS",
  "FAMAH",
  "FAMAM",
  "FAMIN",
  "FAMIR",
  "FANAJ",
  "FANAL",
  "FANČI",
  "FANDA",
  "FANDE",
  "FANDI",
  "FANDU",
  "FANEM",
  "FANEV",
  "FANGA",
  "FANGE",
  "FANGI",
  "FANGU",
  "FANIC",
  "FANIH",
  "FANIK",
  "FANIN",
  "FANJA",
  "FANJU",
  "FANKA",
  "FANKE",
  "FANKI",
  "FANKU",
  "FANNA",
  "FANNE",
  "FANNI",
  "FANNU",
  "FANOM",
  "FANON",
  "FANOV",
  "FANTA",
  "FANTE",
  "FANTI",
  "FANTL",
  "FANTO",
  "FANTU",
  "FANUC",
  "FAOJU",
  "FARAD",
  "FARAG",
  "FARAH",
  "FARAJ",
  "FARAM",
  "FARAN",
  "FARAT",
  "FARBA",
  "FARBE",
  "FARBI",
  "FARBO",
  "FARBU",
  "FARDA",
  "FARDE",
  "FARDI",
  "FARDO",
  "FARDU",
  "FAREN",
  "FARES",
  "FARGA",
  "FARGE",
  "FARGI",
  "FARGO",
  "FARGU",
  "FARHA",
  "FARHE",
  "FARHI",
  "FARHO",
  "FARHU",
  "FARIB",
  "FARIČ",
  "FARID",
  "FARIH",
  "FARIN",
  "FARIS",
  "FARJA",
  "FARJE",
  "FARJI",
  "FARJU",
  "FARKA",
  "FARKE",
  "FARKI",
  "FARKO",
  "FARKU",
  "FARMA",
  "FARME",
  "FARMI",
  "FARMO",
  "FARNA",
  "FARNE",
  "FARNI",
  "FARNO",
  "FAROM",
  "FAROS",
  "FAROV",
  "FARRA",
  "FARRE",
  "FARRI",
  "FARRO",
  "FARRU",
  "FARSA",
  "FARSE",
  "FARSI",
  "FARSO",
  "FARUK",
  "FASAD",
  "FASAL",
  "FASAN",
  "FASAT",
  "FASAU",
  "FASAV",
  "FASCH",
  "FASET",
  "FASJA",
  "FASJU",
  "FASOM",
  "FASSI",
  "FASTA",
  "FASTE",
  "FASTI",
  "FASTU",
  "FAŠEM",
  "FAŠEŠ",
  "FAŠIN",
  "FAŠOČ",
  "FAŠUN",
  "FATAH",
  "FATAM",
  "FATEV",
  "FATHA",
  "FATHE",
  "FATHI",
  "FATHU",
  "FATIČ",
  "FATIH",
  "FATIM",
  "FATIN",
  "FATJU",
  "FATON",
  "FATOS",
  "FATTI",
  "FATUM",
  "FATUR",
  "FATUS",
  "FATVA",
  "FATVE",
  "FATVI",
  "FATVO",
  "FAUCI",
  "FAUDE",
  "FAULA",
  "FAULE",
  "FAULI",
  "FAULK",
  "FAULL",
  "FAULU",
  "FAUNT",
  "FAURA",
  "FAURE",
  "FAURI",
  "FAURU",
  "FAUSA",
  "FAUSE",
  "FAUSI",
  "FAUST",
  "FAUSU",
  "FAUTH",
  "FAVAH",
  "FAVAM",
  "FAVEL",
  "FAVIH",
  "FAVJA",
  "FAVJU",
  "FAVLA",
  "FAVLE",
  "FAVLI",
  "FAVLU",
  "FAVNA",
  "FAVNE",
  "FAVNI",
  "FAVNO",
  "FAVNU",
  "FAVOM",
  "FAVOV",
  "FAVRA",
  "FAVRE",
  "FAVRI",
  "FAVRO",
  "FAVRU",
  "FAZAH",
  "FAZAL",
  "FAZAM",
  "FAZAN",
  "FAZEN",
  "FAZER",
  "FAZIA",
  "FAZIE",
  "FAZII",
  "FAZIO",
  "FAZIU",
  "FAZLE",
  "FAZLI",
  "FAZNA",
  "FAZNE",
  "FAZNI",
  "FAZNO",
  "FAZZI",
  "FEALA",
  "FEALE",
  "FEALI",
  "FEALU",
  "FEARN",
  "FEARS",
  "FEAST",
  "FEBBI",
  "FECAN",
  "FEČUR",
  "FEDAK",
  "FEDDA",
  "FEDDE",
  "FEDDI",
  "FEDDU",
  "FEDER",
  "FEDEZ",
  "FEDIJ",
  "FEDIN",
  "FEDIR",
  "FEDJA",
  "FEDJE",
  "FEDJI",
  "FEDJO",
  "FEDJU",
  "FEDOM",
  "FEDOR",
  "FEDOV",
  "FEDRA",
  "FEDRE",
  "FEDRI",
  "FEDRO",
  "FEDRU",
  "FEDUN",
  "FEDUT",
  "FEEJA",
  "FEEJE",
  "FEEJI",
  "FEEJU",
  "FEEKA",
  "FEEKE",
  "FEEKI",
  "FEEKU",
  "FEERA",
  "FEERE",
  "FEERI",
  "FEERU",
  "FEFER",
  "FEGAN",
  "FEGEJ",
  "FEGGI",
  "FEGIC",
  "FEGUŠ",
  "FEHER",
  "FEHIR",
  "FEHLA",
  "FEHLE",
  "FEHLI",
  "FEHLU",
  "FEHMI",
  "FEHNA",
  "FEHNE",
  "FEHNI",
  "FEHNU",
  "FEHRA",
  "FEHRE",
  "FEHRI",
  "FEHRS",
  "FEHRU",
  "FEHSE",
  "FEHTA",
  "FEHTI",
  "FEHTU",
  "FEIGA",
  "FEIGE",
  "FEIGI",
  "FEIGL",
  "FEIGU",
  "FEIKA",
  "FEIKE",
  "FEIKI",
  "FEIKS",
  "FEIKU",
  "FEILA",
  "FEILE",
  "FEILI",
  "FEILU",
  "FEIMA",
  "FEIME",
  "FEIMI",
  "FEIMU",
  "FEINA",
  "FEINE",
  "FEINI",
  "FEINU",
  "FEISA",
  "FEISE",
  "FEISI",
  "FEISS",
  "FEIST",
  "FEISU",
  "FEITA",
  "FEITE",
  "FEITH",
  "FEITI",
  "FEITL",
  "FEITO",
  "FEITU",
  "FEJAT",
  "FEJEM",
  "FEJJA",
  "FEJJU",
  "FEJKA",
  "FEJKE",
  "FEJKI",
  "FEJKU",
  "FEJOJ",
  "FEJSA",
  "FEJST",
  "FEJSU",
  "FEJZA",
  "FEJZE",
  "FEJZI",
  "FEJZO",
  "FEJZU",
  "FEKAJ",
  "FEKIR",
  "FEKLA",
  "FEKLE",
  "FEKLI",
  "FEKLU",
  "FELAH",
  "FELAJ",
  "FELAM",
  "FELBA",
  "FELBE",
  "FELBI",
  "FELBO",
  "FELBU",
  "FELCA",
  "FELCE",
  "FELCI",
  "FELCU",
  "FELDA",
  "FELDE",
  "FELDI",
  "FELDO",
  "FELDU",
  "FELER",
  "FELGA",
  "FELGE",
  "FELGI",
  "FELGO",
  "FELIČ",
  "FELIH",
  "FELIS",
  "FELJA",
  "FELJU",
  "FELKA",
  "FELKE",
  "FELKI",
  "FELKU",
  "FELLA",
  "FELLE",
  "FELLI",
  "FELLU",
  "FELOM",
  "FELOV",
  "FELSA",
  "FELSE",
  "FELSI",
  "FELSU",
  "FELTA",
  "FELTE",
  "FELTI",
  "FELTU",
  "FELTZ",
  "FELUK",
  "FEMCA",
  "FEMCE",
  "FEMCI",
  "FEMCU",
  "FEMEC",
  "FEMKE",
  "FEMUR",
  "FENAJ",
  "FENAL",
  "FENAM",
  "FENAŠ",
  "FENAT",
  "FENAU",
  "FENAV",
  "FENCA",
  "FENCE",
  "FENCI",
  "FENCU",
  "FENDA",
  "FENDE",
  "FENDI",
  "FENDT",
  "FENDU",
  "FENEK",
  "FENEM",
  "FENEV",
  "FENGA",
  "FENGE",
  "FENGI",
  "FENGU",
  "FENIH",
  "FENIL",
  "FENJA",
  "FENJU",
  "FENKA",
  "FENKE",
  "FENKI",
  "FENKO",
  "FENKU",
  "FENNA",
  "FENNE",
  "FENNI",
  "FENNU",
  "FENOL",
  "FENOM",
  "FENOV",
  "FENSI",
  "FENTA",
  "FENTE",
  "FENTI",
  "FENTU",
  "FENZI",
  "FENZL",
  "FEORA",
  "FEORE",
  "FEORI",
  "FEORU",
  "FERAJ",
  "FERAS",
  "FERAT",
  "FERDA",
  "FERDE",
  "FERDI",
  "FERDO",
  "FERDU",
  "FERID",
  "FERIH",
  "FERIN",
  "FERIS",
  "FERIT",
  "FERIZ",
  "FERJA",
  "FERJI",
  "FERKA",
  "FERKE",
  "FERKI",
  "FERKO",
  "FERKU",
  "FERLE",
  "FERME",
  "FERMI",
  "FERNA",
  "FERNE",
  "FERNI",
  "FERNK",
  "FERNS",
  "FERNU",
  "FERRA",
  "FERRE",
  "FERRI",
  "FERRO",
  "FERRU",
  "FERŠA",
  "FERŠE",
  "FERŠI",
  "FERŠU",
  "FERTA",
  "FERTE",
  "FERTI",
  "FERTU",
  "FESEL",
  "FESEM",
  "FESEV",
  "FESIH",
  "FESJA",
  "FESJU",
  "FESLA",
  "FESLE",
  "FESLI",
  "FESLU",
  "FESOM",
  "FESOV",
  "FESSL",
  "FESST",
  "FESTA",
  "FESTE",
  "FESTI",
  "FESTU",
  "FEŠTA",
  "FEŠTE",
  "FEŠTI",
  "FEŠTO",
  "FETAH",
  "FETAM",
  "FETEV",
  "FETHI",
  "FETIH",
  "FETIN",
  "FETIŠ",
  "FETTA",
  "FETTE",
  "FETTI",
  "FETTU",
  "FETUS",
  "FETVA",
  "FETVE",
  "FETVI",
  "FETVO",
  "FEUČE",
  "FEUŠA",
  "FEUŠE",
  "FEUŠI",
  "FEUŠU",
  "FEUZA",
  "FEUZE",
  "FEUZI",
  "FEUZU",
  "FEVDA",
  "FEVDE",
  "FEVDI",
  "FEVDU",
  "FEVRA",
  "FEVRE",
  "FEVRI",
  "FEVRU",
  "FEZZI",
  "FFION",
  "FIALA",
  "FIALE",
  "FIALI",
  "FIALK",
  "FIALO",
  "FIALU",
  "FIAMM",
  "FIANA",
  "FIANE",
  "FIANI",
  "FIANN",
  "FIANO",
  "FIANU",
  "FIATA",
  "FIATE",
  "FIATI",
  "FIATU",
  "FIBIN",
  "FIBUL",
  "FICEK",
  "FICEM",
  "FICEV",
  "FICIH",
  "FICJA",
  "FICJU",
  "FICKA",
  "FICKE",
  "FICKI",
  "FICKO",
  "FICKU",
  "FICOM",
  "FICOV",
  "FIČEV",
  "FIČKA",
  "FIČKE",
  "FIČKI",
  "FIČKO",
  "FIČKU",
  "FIČOR",
  "FIČOV",
  "FIČUR",
  "FIDAN",
  "FIDEJ",
  "FIDEL",
  "FIDES",
  "FIDIH",
  "FIDIJ",
  "FIDJA",
  "FIDJU",
  "FIDOM",
  "FIDOV",
  "FIDŽI",
  "FIEGE",
  "FIEKE",
  "FIELD",
  "FIENT",
  "FIERI",
  "FIESA",
  "FIESE",
  "FIESI",
  "FIESO",
  "FIEST",
  "FIFIN",
  "FIGAH",
  "FIGAM",
  "FIGAR",
  "FIGEL",
  "FIGES",
  "FIGGA",
  "FIGGE",
  "FIGGI",
  "FIGGU",
  "FIGIC",
  "FIGIH",
  "FIGJA",
  "FIGJU",
  "FIGLA",
  "FIGLE",
  "FIGLI",
  "FIGLU",
  "FIGMA",
  "FIGME",
  "FIGMI",
  "FIGMO",
  "FIGOM",
  "FIGOV",
  "FIGUR",
  "FIHNA",
  "FIHNE",
  "FIHNI",
  "FIHNU",
  "FIJAL",
  "FIJAN",
  "FIJEM",
  "FIJEN",
  "FIJEV",
  "FIJIH",
  "FIJJA",
  "FIJJU",
  "FIJOK",
  "FIJOM",
  "FIJOV",
  "FIKET",
  "FIKIH",
  "FIKJA",
  "FIKJU",
  "FIKOM",
  "FIKON",
  "FIKOV",
  "FIKRI",
  "FIKSA",
  "FIKSE",
  "FIKSI",
  "FIKSU",
  "FIKUS",
  "FILAČ",
  "FILAH",
  "FILAJ",
  "FILAK",
  "FILAL",
  "FILAM",
  "FILAN",
  "FILAŠ",
  "FILAT",
  "FILAU",
  "FILAV",
  "FILBA",
  "FILBO",
  "FILBU",
  "FILCA",
  "FILCE",
  "FILCI",
  "FILCU",
  "FILEJ",
  "FILEM",
  "FILER",
  "FILES",
  "FILEŠ",
  "FILET",
  "FILHA",
  "FILHE",
  "FILHI",
  "FILHO",
  "FILHU",
  "FILID",
  "FILIH",
  "FILIN",
  "FILIP",
  "FILIR",
  "FILIS",
  "FILIT",
  "FILJA",
  "FILJU",
  "FILKA",
  "FILKE",
  "FILKI",
  "FILKO",
  "FILKU",
  "FILLA",
  "FILLE",
  "FILLI",
  "FILLU",
  "FILMA",
  "FILME",
  "FILMI",
  "FILMS",
  "FILMU",
  "FILOM",
  "FILOS",
  "FILOV",
  "FILOZ",
  "FILPI",
  "FILSA",
  "FILSE",
  "FILSI",
  "FILSU",
  "FILZA",
  "FILZE",
  "FILZI",
  "FILZU",
  "FIMOZ",
  "FINAL",
  "FINAN",
  "FINCA",
  "FINCE",
  "FINCH",
  "FINCI",
  "FINCK",
  "FINCU",
  "FINEC",
  "FINEJ",
  "FINEM",
  "FINER",
  "FINES",
  "FINGA",
  "FINGS",
  "FINIH",
  "FINIM",
  "FININ",
  "FINIŠ",
  "FINJA",
  "FINJE",
  "FINJI",
  "FINJO",
  "FINJU",
  "FINKA",
  "FINKE",
  "FINKI",
  "FINKO",
  "FINKU",
  "FINNA",
  "FINNE",
  "FINNI",
  "FINNU",
  "FINOL",
  "FINOM",
  "FINOV",
  "FINSA",
  "FINSE",
  "FINSI",
  "FINSU",
  "FINTA",
  "FINTE",
  "FINTI",
  "FINTL",
  "FINTO",
  "FINZI",
  "FIOCK",
  "FIOLA",
  "FIOLE",
  "FIOLI",
  "FIOLO",
  "FIOLU",
  "FIONA",
  "FIONE",
  "FIONI",
  "FIONN",
  "FIONO",
  "FIORE",
  "FIORI",
  "FIPIS",
  "FIRAT",
  "FIRBC",
  "FIRER",
  "FIRIH",
  "FIRIT",
  "FIRJA",
  "FIRJU",
  "FIRMA",
  "FIRME",
  "FIRMI",
  "FIRMO",
  "FIRMU",
  "FIRNA",
  "FIRNE",
  "FIRNI",
  "FIRNU",
  "FIROM",
  "FIROV",
  "FIRST",
  "FIRŠT",
  "FIRTH",
  "FIRZI",
  "FISAC",
  "FISCH",
  "FISHA",
  "FISHE",
  "FISHI",
  "FISHT",
  "FISHU",
  "FISIJ",
  "FISIN",
  "FISKA",
  "FISKE",
  "FISKI",
  "FISKU",
  "FISON",
  "FISTA",
  "FISTU",
  "FISUR",
  "FIŠER",
  "FITAH",
  "FITAM",
  "FITCH",
  "FITIM",
  "FITIN",
  "FITOL",
  "FITTA",
  "FITTE",
  "FITTI",
  "FITTO",
  "FITTS",
  "FITTU",
  "FITZA",
  "FITZE",
  "FITZI",
  "FITZU",
  "FIUME",
  "FIUSA",
  "FIUSE",
  "FIUSI",
  "FIUSU",
  "FIVIN",
  "FIZIK",
  "FIŽOL",
  "FIŽOV",
  "FJELA",
  "FJELD",
  "FJELE",
  "FJELI",
  "FJELU",
  "FJOLL",
  "FJORD",
  "FLACA",
  "FLACE",
  "FLACH",
  "FLACI",
  "FLACK",
  "FLACU",
  "FLAGG",
  "FLAJS",
  "FLAKA",
  "FLAKE",
  "FLAKI",
  "FLAKO",
  "FLAKS",
  "FLAKU",
  "FLAMA",
  "FLAME",
  "FLAMI",
  "FLAMK",
  "FLAMM",
  "FLAMU",
  "FLANC",
  "FLANN",
  "FLAPA",
  "FLAPE",
  "FLAPI",
  "FLAPU",
  "FLASH",
  "FLAŠA",
  "FLAŠE",
  "FLAŠI",
  "FLAŠK",
  "FLAŠO",
  "FLATI",
  "FLATT",
  "FLATZ",
  "FLAUM",
  "FLAUN",
  "FLAUS",
  "FLAVT",
  "FLECK",
  "FLEET",
  "FLEGA",
  "FLEGE",
  "FLEGG",
  "FLEGI",
  "FLEGO",
  "FLEGU",
  "FLEHA",
  "FLEHE",
  "FLEHI",
  "FLEHO",
  "FLEIG",
  "FLEKA",
  "FLEKE",
  "FLEKI",
  "FLEKU",
  "FLENČ",
  "FLEPP",
  "FLERE",
  "FLERI",
  "FLEŠA",
  "FLEŠE",
  "FLEŠI",
  "FLEŠU",
  "FLETA",
  "FLETE",
  "FLETI",
  "FLETO",
  "FLETT",
  "FLETU",
  "FLEUR",
  "FLICK",
  "FLIER",
  "FLIKA",
  "FLIKE",
  "FLIKI",
  "FLIKO",
  "FLIKU",
  "FLIMM",
  "FLIND",
  "FLINK",
  "FLINN",
  "FLINS",
  "FLINT",
  "FLIRI",
  "FLIRT",
  "FLISA",
  "FLISE",
  "FLISI",
  "FLISU",
  "FLIŠA",
  "FLIŠI",
  "FLIŠU",
  "FLOCH",
  "FLOCK",
  "FLOHR",
  "FLOJA",
  "FLOJE",
  "FLOJI",
  "FLOJU",
  "FLOKA",
  "FLOKE",
  "FLOKI",
  "FLOKU",
  "FLOMA",
  "FLOME",
  "FLOMI",
  "FLOMU",
  "FLONA",
  "FLONE",
  "FLONI",
  "FLONJ",
  "FLONU",
  "FLOOD",
  "FLOPA",
  "FLOPE",
  "FLOPI",
  "FLOPU",
  "FLORA",
  "FLORE",
  "FLORI",
  "FLORO",
  "FLORU",
  "FLOSA",
  "FLOSE",
  "FLOSI",
  "FLOSU",
  "FLOTA",
  "FLOTE",
  "FLOTI",
  "FLOTO",
  "FLUAT",
  "FLUCH",
  "FLUHR",
  "FLUID",
  "FLUKI",
  "FLUKS",
  "FLUOR",
  "FOADA",
  "FOADE",
  "FOADI",
  "FOADU",
  "FOAJA",
  "FOAJE",
  "FOAJI",
  "FOAJU",
  "FOALE",
  "FOBBS",
  "FOBIJ",
  "FOBIK",
  "FOBOS",
  "FOCHA",
  "FOCHE",
  "FOCHI",
  "FOCHU",
  "FOCIS",
  "FOCKA",
  "FOCKE",
  "FOCKI",
  "FOCKS",
  "FOCKU",
  "FOCUS",
  "FOČAN",
  "FODEN",
  "FODER",
  "FODNA",
  "FODNE",
  "FODNI",
  "FODNU",
  "FODOR",
  "FOEST",
  "FOGAR",
  "FOGDE",
  "FOGED",
  "FOGEL",
  "FOGGA",
  "FOGGE",
  "FOGGI",
  "FOGGO",
  "FOGGU",
  "FOGHA",
  "FOGHE",
  "FOGHI",
  "FOGHU",
  "FOGLA",
  "FOGLE",
  "FOGLI",
  "FOGLU",
  "FOHEM",
  "FOHEV",
  "FOHIH",
  "FOHJA",
  "FOHJU",
  "FOHLA",
  "FOHLE",
  "FOHLI",
  "FOHLU",
  "FOHOM",
  "FOHOV",
  "FOINA",
  "FOINE",
  "FOINI",
  "FOINU",
  "FOJAN",
  "FOJBA",
  "FOJBE",
  "FOJBI",
  "FOJBO",
  "FOJDA",
  "FOJDE",
  "FOJDI",
  "FOJDO",
  "FOJEM",
  "FOJEV",
  "FOJIH",
  "FOJJA",
  "FOJJU",
  "FOJOM",
  "FOJOV",
  "FOJTL",
  "FOKIH",
  "FOKIN",
  "FOKJA",
  "FOKJU",
  "FOKOM",
  "FOKOV",
  "FOKUS",
  "FOLAN",
  "FOLAT",
  "FOLCA",
  "FOLCE",
  "FOLCH",
  "FOLCI",
  "FOLCO",
  "FOLCU",
  "FOLDI",
  "FOLDS",
  "FOLEN",
  "FOLES",
  "FOLGA",
  "FOLGI",
  "FOLGU",
  "FOLIA",
  "FOLIČ",
  "FOLIE",
  "FOLII",
  "FOLIJ",
  "FOLIO",
  "FOLIU",
  "FOLKA",
  "FOLKE",
  "FOLKI",
  "FOLKO",
  "FOLKS",
  "FOLKU",
  "FOLLI",
  "FOLNA",
  "FOLNE",
  "FOLNI",
  "FOLNO",
  "FOLON",
  "FOLOS",
  "FOLZA",
  "FOLZE",
  "FOLZI",
  "FOLZU",
  "FOLŽA",
  "FOLŽE",
  "FOLŽI",
  "FOLŽO",
  "FOMIN",
  "FONCK",
  "FONDA",
  "FONDE",
  "FONDI",
  "FONDO",
  "FONDU",
  "FONEM",
  "FONER",
  "FONEV",
  "FONGA",
  "FONGE",
  "FONGI",
  "FONGU",
  "FONIH",
  "FONJA",
  "FONJU",
  "FONOM",
  "FONON",
  "FONOV",
  "FONSA",
  "FONSE",
  "FONSI",
  "FONSU",
  "FONTA",
  "FONTE",
  "FONTI",
  "FONTU",
  "FONZI",
  "FOODA",
  "FOODI",
  "FOODS",
  "FOODU",
  "FOONA",
  "FOONE",
  "FOONI",
  "FOONU",
  "FOORD",
  "FOOSA",
  "FOOSE",
  "FOOSI",
  "FOOSU",
  "FOOTA",
  "FOOTE",
  "FOOTI",
  "FOOTT",
  "FOOTU",
  "FOPAJ",
  "FOPAL",
  "FOPAM",
  "FOPAN",
  "FOPAŠ",
  "FOPAT",
  "FOPAU",
  "FOPAV",
  "FOPPA",
  "FOPPE",
  "FOPPI",
  "FOPPO",
  "FOPPU",
  "FORAH",
  "FORAM",
  "FORAN",
  "FORBS",
  "FORCA",
  "FORCE",
  "FORDA",
  "FORDE",
  "FORDI",
  "FORDU",
  "FOREL",
  "FORGE",
  "FORIH",
  "FORJA",
  "FORJU",
  "FORKA",
  "FORKE",
  "FORKI",
  "FORKU",
  "FORLI",
  "FORMA",
  "FORME",
  "FORMI",
  "FORMO",
  "FORMU",
  "FORNA",
  "FORNE",
  "FORNI",
  "FORNU",
  "FOROM",
  "FOROV",
  "FORSA",
  "FORSE",
  "FORSI",
  "FORSS",
  "FORST",
  "FORSU",
  "FORŠA",
  "FORŠT",
  "FORŠU",
  "FORTA",
  "FORTE",
  "FORTI",
  "FORTO",
  "FORTU",
  "FORUH",
  "FORUM",
  "FOSAH",
  "FOSAM",
  "FOSHA",
  "FOSHE",
  "FOSHI",
  "FOSHO",
  "FOSHU",
  "FOSIL",
  "FOSIN",
  "FOSSA",
  "FOSSE",
  "FOSSI",
  "FOSSU",
  "FOSUN",
  "FOŠKA",
  "FOŠKE",
  "FOŠKI",
  "FOŠKO",
  "FOŠTA",
  "FOŠTE",
  "FOŠTI",
  "FOŠTU",
  "FOTER",
  "FOTHI",
  "FOTIJ",
  "FOTIS",
  "FOTKA",
  "FOTKE",
  "FOTKI",
  "FOTKO",
  "FOTKU",
  "FOTON",
  "FOTRA",
  "FOTRE",
  "FOTRI",
  "FOTRU",
  "FOUED",
  "FOULK",
  "FOUST",
  "FPOTA",
  "FRAČA",
  "FRAČE",
  "FRAČI",
  "FRAČO",
  "FRAGA",
  "FRAHM",
  "FRAIL",
  "FRAIN",
  "FRAJH",
  "FRAJL",
  "FRAJT",
  "FRAKA",
  "FRAKE",
  "FRAKI",
  "FRAKU",
  "FRAMA",
  "FRAME",
  "FRAMI",
  "FRAMU",
  "FRANA",
  "FRANC",
  "FRANE",
  "FRANG",
  "FRANI",
  "FRANJ",
  "FRANK",
  "FRANN",
  "FRANO",
  "FRANS",
  "FRANU",
  "FRANZ",
  "FRANŽ",
  "FRAPE",
  "FRASA",
  "FRASE",
  "FRASI",
  "FRASS",
  "FRAST",
  "FRASU",
  "FRATA",
  "FRATE",
  "FRATI",
  "FRATO",
  "FRATT",
  "FRAZA",
  "FRAZE",
  "FRAZI",
  "FRAZO",
  "FRCAJ",
  "FRCAL",
  "FRCAM",
  "FRCAŠ",
  "FRCAT",
  "FRCAU",
  "FRCAV",
  "FRCNE",
  "FRCNI",
  "FRCNU",
  "FRČAD",
  "FRČAL",
  "FRČAT",
  "FRČAU",
  "FRČAV",
  "FRČEC",
  "FRČEČ",
  "FRČIM",
  "FRČIŠ",
  "FRČKA",
  "FRČKE",
  "FRČKI",
  "FRČKO",
  "FRČKU",
  "FRECE",
  "FREDA",
  "FREDD",
  "FREDE",
  "FREDI",
  "FREDO",
  "FREDU",
  "FREED",
  "FREEH",
  "FREEK",
  "FREEL",
  "FREEM",
  "FREES",
  "FREID",
  "FREJA",
  "FREJE",
  "FREJI",
  "FREJO",
  "FREND",
  "FRENI",
  "FRENK",
  "FREON",
  "FRERA",
  "FRERE",
  "FRERI",
  "FRERU",
  "FRESE",
  "FRESI",
  "FRESK",
  "FRESL",
  "FRESU",
  "FREUD",
  "FREZA",
  "FREZE",
  "FREZI",
  "FREZO",
  "FREZU",
  "FREZZ",
  "FRFER",
  "FRFOT",
  "FRFRA",
  "FRFRE",
  "FRFRI",
  "FRFRO",
  "FRFRU",
  "FRIAR",
  "FRICA",
  "FRICE",
  "FRICI",
  "FRICK",
  "FRICU",
  "FRIČA",
  "FRIČE",
  "FRIČI",
  "FRIČU",
  "FRIDA",
  "FRIDE",
  "FRIDH",
  "FRIDI",
  "FRIDL",
  "FRIDO",
  "FRIDU",
  "FRIEB",
  "FRIED",
  "FRIEL",
  "FRIER",
  "FRIES",
  "FRIFT",
  "FRIGG",
  "FRIIS",
  "FRIKA",
  "FRIKE",
  "FRIKI",
  "FRIKO",
  "FRIKU",
  "FRIMA",
  "FRIME",
  "FRIMI",
  "FRIML",
  "FRIMU",
  "FRINK",
  "FRISA",
  "FRISE",
  "FRISI",
  "FRISK",
  "FRIST",
  "FRISU",
  "FRIŠA",
  "FRIŠE",
  "FRIŠI",
  "FRIŠU",
  "FRITH",
  "FRITS",
  "FRITZ",
  "FRIZA",
  "FRIZE",
  "FRIZI",
  "FRIZU",
  "FRKAH",
  "FRKAL",
  "FRKAM",
  "FRLAN",
  "FRLEC",
  "FRLEČ",
  "FRLEL",
  "FRLET",
  "FRLEU",
  "FRLEŽ",
  "FRLIC",
  "FRLIM",
  "FRLIN",
  "FRLIŠ",
  "FROCA",
  "FROCE",
  "FROCI",
  "FROCU",
  "FRODA",
  "FRODE",
  "FRODI",
  "FRODO",
  "FRODU",
  "FROIN",
  "FROLA",
  "FROLE",
  "FROLI",
  "FROLU",
  "FROMA",
  "FROME",
  "FROMI",
  "FROMM",
  "FROMO",
  "FROMU",
  "FROND",
  "FRONK",
  "FRONT",
  "FROOD",
  "FROOM",
  "FROSI",
  "FROST",
  "FROUD",
  "FRUEH",
  "FRUGE",
  "FRUIT",
  "FRUKA",
  "FRUKE",
  "FRUKI",
  "FRUKU",
  "FRULA",
  "FRULE",
  "FRULI",
  "FRULO",
  "FRUMA",
  "FRUME",
  "FRUMI",
  "FRUMM",
  "FRUMU",
  "FRUSI",
  "FRUST",
  "FTIZA",
  "FTIZE",
  "FTIZI",
  "FTIZO",
  "FTOTA",
  "FUADA",
  "FUADE",
  "FUADI",
  "FUADU",
  "FUART",
  "FUATA",
  "FUATE",
  "FUATI",
  "FUATU",
  "FUCCI",
  "FUCHS",
  "FUČIK",
  "FUČKA",
  "FUČKE",
  "FUČKI",
  "FUČKO",
  "FUČKU",
  "FUDGA",
  "FUDGE",
  "FUDŽI",
  "FUEKI",
  "FUELE",
  "FUEST",
  "FUFEL",
  "FUFLA",
  "FUFLE",
  "FUFLI",
  "FUFLO",
  "FUGAH",
  "FUGAL",
  "FUGAM",
  "FUGAS",
  "FUGHA",
  "FUGHE",
  "FUGHI",
  "FUGHU",
  "FUGIH",
  "FUGIN",
  "FUGIT",
  "FUGJA",
  "FUGJU",
  "FUGOM",
  "FUGOV",
  "FUISA",
  "FUISE",
  "FUISI",
  "FUISU",
  "FUITH",
  "FUJAN",
  "FUJIT",
  "FUJSA",
  "FUJSE",
  "FUJSI",
  "FUJSU",
  "FUKAČ",
  "FUKAJ",
  "FUKAL",
  "FUKAM",
  "FUKAR",
  "FUKAŠ",
  "FUKAT",
  "FUKAU",
  "FUKAV",
  "FUKEM",
  "FUKEV",
  "FUKIH",
  "FUKJA",
  "FUKJU",
  "FUKNE",
  "FUKNI",
  "FUKNU",
  "FUKOM",
  "FUKOV",
  "FUKOZ",
  "FUKSA",
  "FUKSE",
  "FUKSI",
  "FUKSU",
  "FUKUD",
  "FULAN",
  "FULCI",
  "FULDA",
  "FULDE",
  "FULDI",
  "FULDO",
  "FULDU",
  "FULKS",
  "FULLA",
  "FULLE",
  "FULLI",
  "FULLO",
  "FULLU",
  "FULOP",
  "FULPA",
  "FULPE",
  "FULPI",
  "FULPU",
  "FULSS",
  "FULTZ",
  "FUMIA",
  "FUMIE",
  "FUMII",
  "FUMIO",
  "FUMIU",
  "FUNAH",
  "FUNAM",
  "FUNCK",
  "FUNDA",
  "FUNDE",
  "FUNDI",
  "FUNDO",
  "FUNDS",
  "FUNDU",
  "FUNES",
  "FUNGA",
  "FUNGE",
  "FUNGI",
  "FUNGU",
  "FUNIH",
  "FUNJA",
  "FUNJU",
  "FUNKA",
  "FUNKE",
  "FUNKI",
  "FUNKL",
  "FUNKU",
  "FUNOM",
  "FUNOV",
  "FUNTA",
  "FUNTE",
  "FUNTI",
  "FUNTU",
  "FURAH",
  "FURAJ",
  "FURAL",
  "FURAM",
  "FURAN",
  "FURAR",
  "FURAŠ",
  "FURAT",
  "FURAU",
  "FURAV",
  "FURDI",
  "FUREK",
  "FURIA",
  "FURIE",
  "FURII",
  "FURIJ",
  "FURIN",
  "FURIO",
  "FURIU",
  "FURRH",
  "FURSA",
  "FURSE",
  "FURSI",
  "FURST",
  "FURSU",
  "FURTH",
  "FURUT",
  "FUSAR",
  "FUSCA",
  "FUSCE",
  "FUSCI",
  "FUSCO",
  "FUSCU",
  "FUSON",
  "FUSSA",
  "FUSSE",
  "FUSSI",
  "FUSSL",
  "FUSSU",
  "FUSTA",
  "FUSTE",
  "FUSTI",
  "FUSTU",
  "FUŠAJ",
  "FUŠAL",
  "FUŠAM",
  "FUŠAR",
  "FUŠAŠ",
  "FUŠAT",
  "FUŠAU",
  "FUŠAV",
  "FUŠEM",
  "FUŠEV",
  "FUŠIH",
  "FUŠJA",
  "FUŠJU",
  "FUŠOM",
  "FUŠOV",
  "FUTAČ",
  "FUTER",
  "FUTON",
  "FUTRA",
  "FUTRI",
  "FUTRU",
  "FUTUR",
  "FUZIJ",
  "FUZLA",
  "FUZLI",
  "FUZLU",
  "FUZUL",
  "FUŽEM",
  "FUŽEV",
  "FUŽIH",
  "FUŽIN",
  "FUŽIR",
  "FUŽOM",
  "FUŽOV",
  "GAAGA",
  "GAAGE",
  "GAAGI",
  "GAAGU",
  "GAALA",
  "GAALE",
  "GAALI",
  "GAALU",
  "GABAJ",
  "GABBI",
  "GABER",
  "GABEZ",
  "GABIH",
  "GABIL",
  "GABIM",
  "GABIN",
  "GABIŠ",
  "GABIT",
  "GABIU",
  "GABIV",
  "GABJA",
  "GABJU",
  "GABLA",
  "GABLE",
  "GABLI",
  "GABLU",
  "GABOM",
  "GABON",
  "GABOR",
  "GABOV",
  "GABRA",
  "GABRC",
  "GABRČ",
  "GABRE",
  "GABRI",
  "GABRK",
  "GABRO",
  "GABRU",
  "GACON",
  "GAČAH",
  "GAČAM",
  "GAČAN",
  "GAČEČ",
  "GAČEŠ",
  "GAČIČ",
  "GAČIL",
  "GAČIM",
  "GAČIN",
  "GAČIŠ",
  "GAČIT",
  "GAČIU",
  "GAČIV",
  "GADDA",
  "GADDE",
  "GADDI",
  "GADDO",
  "GADDU",
  "GADEA",
  "GADEE",
  "GADEI",
  "GADEM",
  "GADEO",
  "GADES",
  "GADEU",
  "GADEV",
  "GADIH",
  "GADIN",
  "GADJA",
  "GADJE",
  "GADJI",
  "GADJO",
  "GADJU",
  "GADOM",
  "GADON",
  "GADOT",
  "GADOV",
  "GADŽE",
  "GAEDT",
  "GAETZ",
  "GAFAH",
  "GAFAM",
  "GAFFA",
  "GAFFE",
  "GAFFI",
  "GAFFU",
  "GAFIH",
  "GAFJA",
  "GAFJU",
  "GAFOM",
  "GAFOV",
  "GAFTA",
  "GAFTE",
  "GAFTI",
  "GAFTU",
  "GAFUR",
  "GAGAH",
  "GAGAJ",
  "GAGAL",
  "GAGAM",
  "GAGAŠ",
  "GAGAT",
  "GAGAU",
  "GAGAV",
  "GAGEA",
  "GAGEE",
  "GAGEI",
  "GAGEL",
  "GAGEU",
  "GAGIC",
  "GAGIK",
  "GAGIL",
  "GAGIN",
  "GAGLA",
  "GAGLE",
  "GAGLI",
  "GAGLU",
  "GAGNE",
  "GAGNI",
  "GAGNU",
  "GAGUL",
  "GAHAN",
  "GAHOV",
  "GAHRA",
  "GAHRE",
  "GAHRI",
  "GAHRU",
  "GAIAH",
  "GAIAM",
  "GAIGA",
  "GAIGE",
  "GAIGG",
  "GAIIN",
  "GAILA",
  "GAILE",
  "GAILI",
  "GAILU",
  "GAINS",
  "GAITA",
  "GAITE",
  "GAITH",
  "GAITI",
  "GAITU",
  "GAIZK",
  "GAJAH",
  "GAJAM",
  "GAJAN",
  "GAJBA",
  "GAJBE",
  "GAJBI",
  "GAJBO",
  "GAJDA",
  "GAJDE",
  "GAJDI",
  "GAJDO",
  "GAJDU",
  "GAJEM",
  "GAJET",
  "GAJEV",
  "GAJIČ",
  "GAJIH",
  "GAJIN",
  "GAJJA",
  "GAJJU",
  "GAJOM",
  "GAJOV",
  "GAJŠT",
  "GAKOV",
  "GAKPA",
  "GAKPE",
  "GAKPI",
  "GAKPO",
  "GAKPU",
  "GALAH",
  "GALAI",
  "GALAL",
  "GALAM",
  "GALAN",
  "GALCA",
  "GALCE",
  "GALCI",
  "GALCO",
  "GALCU",
  "GALDA",
  "GALDE",
  "GALDI",
  "GALDU",
  "GALEA",
  "GALEB",
  "GALEC",
  "GALEE",
  "GALEI",
  "GALEJ",
  "GALEN",
  "GALEO",
  "GALER",
  "GALES",
  "GALEŠ",
  "GALET",
  "GALEU",
  "GALFI",
  "GALIC",
  "GALIČ",
  "GALIH",
  "GALIJ",
  "GALIK",
  "GALIN",
  "GALIS",
  "GALIT",
  "GALJA",
  "GALJE",
  "GALJI",
  "GALJO",
  "GALJU",
  "GALKA",
  "GALKE",
  "GALKI",
  "GALKO",
  "GALLA",
  "GALLE",
  "GALLI",
  "GALLO",
  "GALLU",
  "GALNA",
  "GALNE",
  "GALNI",
  "GALNO",
  "GALOF",
  "GALOM",
  "GALON",
  "GALOP",
  "GALOŠ",
  "GALOV",
  "GALPA",
  "GALPU",
  "GALTA",
  "GALTE",
  "GALTI",
  "GALTU",
  "GALUF",
  "GALUH",
  "GALUN",
  "GAMAH",
  "GAMAL",
  "GAMAM",
  "GAMAŠ",
  "GAMBA",
  "GAMBE",
  "GAMBI",
  "GAMBO",
  "GAMBU",
  "GAMET",
  "GAMIH",
  "GAMJA",
  "GAMJU",
  "GAMMA",
  "GAMME",
  "GAMMI",
  "GAMMS",
  "GAMMU",
  "GAMOM",
  "GAMON",
  "GAMOŠ",
  "GAMOV",
  "GAMPE",
  "GAMPP",
  "GAMPU",
  "GAMSA",
  "GAMSE",
  "GAMSI",
  "GAMST",
  "GAMSU",
  "GAMZE",
  "GANAH",
  "GANAL",
  "GANAM",
  "GANCA",
  "GANCE",
  "GANCI",
  "GANCU",
  "GANDI",
  "GANEC",
  "GANEM",
  "GANEŠ",
  "GANEV",
  "GANGA",
  "GANGE",
  "GANGI",
  "GANGL",
  "GANGO",
  "GANIA",
  "GANIE",
  "GANIH",
  "GANII",
  "GANIL",
  "GANIN",
  "GANIO",
  "GANIS",
  "GANIT",
  "GANIU",
  "GANIV",
  "GANJA",
  "GANJU",
  "GANKA",
  "GANKE",
  "GANKI",
  "GANKO",
  "GANNA",
  "GANNE",
  "GANNI",
  "GANNO",
  "GANNU",
  "GANOM",
  "GANOV",
  "GANSA",
  "GANSE",
  "GANSI",
  "GANSO",
  "GANSU",
  "GANTA",
  "GANTE",
  "GANTI",
  "GANTT",
  "GANTU",
  "GANTZ",
  "GANZA",
  "GANZE",
  "GANZI",
  "GANZU",
  "GAOMA",
  "GAOSA",
  "GAOSE",
  "GAOSI",
  "GAOSU",
  "GAOTA",
  "GAOTU",
  "GAOVA",
  "GAOVE",
  "GAOVI",
  "GAOVO",
  "GAPES",
  "GAPIH",
  "GAPJA",
  "GAPJU",
  "GAPOM",
  "GAPON",
  "GAPOV",
  "GAPPA",
  "GAPPE",
  "GAPPI",
  "GAPPU",
  "GAPSA",
  "GAPSE",
  "GAPSI",
  "GAPSO",
  "GAPSU",
  "GARAČ",
  "GARAH",
  "GARAJ",
  "GARAL",
  "GARAM",
  "GARAN",
  "GARAS",
  "GARAŠ",
  "GARAT",
  "GARAU",
  "GARAV",
  "GARAŽ",
  "GARBA",
  "GARBE",
  "GARBI",
  "GARBO",
  "GARBU",
  "GARCI",
  "GARDA",
  "GARDE",
  "GARDI",
  "GARDO",
  "GARFF",
  "GARIB",
  "GARIC",
  "GARIJ",
  "GARIN",
  "GARIP",
  "GARIT",
  "GARJE",
  "GARKA",
  "GARKE",
  "GARKI",
  "GARKO",
  "GARKU",
  "GARON",
  "GAROT",
  "GARRA",
  "GARRE",
  "GARRI",
  "GARRN",
  "GARRU",
  "GARTH",
  "GARUB",
  "GARZA",
  "GARZE",
  "GARZI",
  "GARZO",
  "GARZU",
  "GASAN",
  "GASAR",
  "GASCH",
  "GASEČ",
  "GASEM",
  "GASER",
  "GASEV",
  "GASHI",
  "GASIH",
  "GASIL",
  "GASIM",
  "GASIŠ",
  "GASIT",
  "GASIU",
  "GASIV",
  "GASJA",
  "GASJU",
  "GASOL",
  "GASOM",
  "GASOS",
  "GASOV",
  "GASSA",
  "GASSE",
  "GASSI",
  "GASSU",
  "GASTA",
  "GASTE",
  "GASTI",
  "GASTU",
  "GASUM",
  "GAŠEN",
  "GAŠIČ",
  "GATAH",
  "GATAM",
  "GATAR",
  "GATEJ",
  "GATES",
  "GATIH",
  "GATIS",
  "GATJA",
  "GATJU",
  "GATKE",
  "GATOM",
  "GATOV",
  "GATTA",
  "GATTE",
  "GATTI",
  "GATTO",
  "GATTS",
  "GATTU",
  "GATUN",
  "GAUBA",
  "GAUBE",
  "GAUBI",
  "GAUBU",
  "GAUCI",
  "GAUCK",
  "GAUDE",
  "GAUDI",
  "GAUDU",
  "GAUER",
  "GAUFA",
  "GAUFE",
  "GAUFF",
  "GAUFI",
  "GAUFU",
  "GAUKA",
  "GAUKE",
  "GAUKI",
  "GAUKU",
  "GAULA",
  "GAULE",
  "GAULI",
  "GAULT",
  "GAULU",
  "GAUNT",
  "GAUPA",
  "GAUPE",
  "GAUPI",
  "GAUPU",
  "GAURI",
  "GAUSA",
  "GAUSE",
  "GAUSI",
  "GAUSS",
  "GAUSU",
  "GAUŠA",
  "GAUŠE",
  "GAUŠI",
  "GAUŠU",
  "GAVAH",
  "GAVAM",
  "GAVAN",
  "GAVCE",
  "GAVČA",
  "GAVČE",
  "GAVČI",
  "GAVČO",
  "GAVČU",
  "GAVDA",
  "GAVDE",
  "GAVDI",
  "GAVDO",
  "GAVEZ",
  "GAVGE",
  "GAVIH",
  "GAVIN",
  "GAVJA",
  "GAVJU",
  "GAVOM",
  "GAVOV",
  "GAVUN",
  "GAZDA",
  "GAZDE",
  "GAZDI",
  "GAZDO",
  "GAZDU",
  "GAZEČ",
  "GAZEH",
  "GAZEL",
  "GAZEM",
  "GAZEN",
  "GAZIC",
  "GAZIL",
  "GAZIM",
  "GAZIN",
  "GAZIŠ",
  "GAZIT",
  "GAZIU",
  "GAZIV",
  "GAZJO",
  "GAZMI",
  "GAZNA",
  "GAZNE",
  "GAZNI",
  "GAZNO",
  "GAZZI",
  "GAŽEN",
  "GAŽON",
  "GDANK",
  "GEAMA",
  "GEAMI",
  "GEBBE",
  "GEBRU",
  "GECAJ",
  "GECAN",
  "GECEM",
  "GECEV",
  "GECIH",
  "GECJA",
  "GECJU",
  "GECKS",
  "GECOM",
  "GECOV",
  "GEČEM",
  "GEČEV",
  "GEČIH",
  "GEČJA",
  "GEČJU",
  "GEČOM",
  "GEČOV",
  "GEDAH",
  "GEDAM",
  "GEDDA",
  "GEDDE",
  "GEDDI",
  "GEDDO",
  "GEDDU",
  "GEDEI",
  "GEDER",
  "GEDIH",
  "GEDJA",
  "GEDJU",
  "GEDLA",
  "GEDLE",
  "GEDLI",
  "GEDLU",
  "GEDOM",
  "GEDOV",
  "GEEJA",
  "GEEJE",
  "GEEJI",
  "GEEJU",
  "GEEKA",
  "GEEKE",
  "GEEKI",
  "GEEKO",
  "GEEKU",
  "GEELS",
  "GEENA",
  "GEENE",
  "GEENI",
  "GEENO",
  "GEENS",
  "GEERT",
  "GEETA",
  "GEETE",
  "GEETI",
  "GEETO",
  "GEFCA",
  "GEFCO",
  "GEFCU",
  "GEGAN",
  "GEGIČ",
  "GEHBA",
  "GEHBE",
  "GEHBI",
  "GEHBU",
  "GEHLA",
  "GEHLE",
  "GEHLI",
  "GEHLU",
  "GEHMA",
  "GEHME",
  "GEHMI",
  "GEHMU",
  "GEHRI",
  "GEHRS",
  "GEICA",
  "GEICK",
  "GEICO",
  "GEICU",
  "GEIDT",
  "GEIER",
  "GEIMA",
  "GEIME",
  "GEIMI",
  "GEIMU",
  "GEINA",
  "GEINE",
  "GEINI",
  "GEINO",
  "GEISA",
  "GEISE",
  "GEISI",
  "GEISS",
  "GEIST",
  "GEISU",
  "GEITL",
  "GEJEM",
  "GEJEV",
  "GEJIH",
  "GEJIN",
  "GEJJA",
  "GEJJU",
  "GEJOM",
  "GEJOV",
  "GEJŠA",
  "GEJŠE",
  "GEJŠI",
  "GEJŠO",
  "GEKKA",
  "GEKKE",
  "GEKKI",
  "GEKKO",
  "GEKKU",
  "GEKON",
  "GELAN",
  "GELBA",
  "GELBE",
  "GELBI",
  "GELBU",
  "GELCA",
  "GELCE",
  "GELCI",
  "GELCU",
  "GELEC",
  "GELEM",
  "GELEV",
  "GELIH",
  "GELIS",
  "GELJA",
  "GELJU",
  "GELKA",
  "GELKE",
  "GELKI",
  "GELKO",
  "GELLA",
  "GELLE",
  "GELLI",
  "GELLU",
  "GELOM",
  "GELOR",
  "GELOS",
  "GELOV",
  "GELOZ",
  "GELUK",
  "GELUL",
  "GEMAG",
  "GEMAH",
  "GEMAM",
  "GEMBE",
  "GEMIA",
  "GEMIE",
  "GEMIH",
  "GEMII",
  "GEMIN",
  "GEMIO",
  "GEMIU",
  "GEMJA",
  "GEMJU",
  "GEMMA",
  "GEMME",
  "GEMMI",
  "GEMMO",
  "GEMOM",
  "GEMOR",
  "GEMOV",
  "GEMSA",
  "GEMSE",
  "GEMSI",
  "GEMSU",
  "GEMUL",
  "GENAH",
  "GENAM",
  "GENCA",
  "GENCE",
  "GENCI",
  "GENCU",
  "GENDT",
  "GENEM",
  "GENET",
  "GENEV",
  "GENEZ",
  "GENGA",
  "GENGE",
  "GENGI",
  "GENGU",
  "GENIH",
  "GENIJ",
  "GENIN",
  "GENIS",
  "GENJA",
  "GENJU",
  "GENKA",
  "GENKU",
  "GENNA",
  "GENNE",
  "GENNI",
  "GENNO",
  "GENNU",
  "GENOM",
  "GENOV",
  "GENSA",
  "GENSE",
  "GENSI",
  "GENSU",
  "GENTA",
  "GENTE",
  "GENTH",
  "GENTI",
  "GENTO",
  "GENTU",
  "GENTZ",
  "GENZA",
  "GENZE",
  "GENZI",
  "GENZU",
  "GEOAN",
  "GEODA",
  "GEODE",
  "GEODI",
  "GEODO",
  "GEOFF",
  "GEOGR",
  "GEOID",
  "GEOMA",
  "GEORG",
  "GEOTA",
  "GEOTU",
  "GEOVA",
  "GEOVE",
  "GEOVI",
  "GEOVO",
  "GEPPI",
  "GERAK",
  "GERAN",
  "GERAS",
  "GERBA",
  "GERBE",
  "GERBI",
  "GERBO",
  "GERBU",
  "GERDA",
  "GERDE",
  "GERDI",
  "GERDO",
  "GERDT",
  "GERDU",
  "GERFA",
  "GERFE",
  "GERFI",
  "GERFO",
  "GERIČ",
  "GERIH",
  "GERIN",
  "GERJA",
  "GERJU",
  "GERKA",
  "GERKE",
  "GERKI",
  "GERKU",
  "GERLA",
  "GERLE",
  "GERLI",
  "GERLU",
  "GERMA",
  "GERME",
  "GERMI",
  "GERMU",
  "GERNA",
  "GERNE",
  "GERNI",
  "GERNU",
  "GEROM",
  "GERON",
  "GEROV",
  "GERRI",
  "GERSI",
  "GERST",
  "GERTA",
  "GERTE",
  "GERTI",
  "GERTO",
  "GERTS",
  "GERTU",
  "GESEL",
  "GESLA",
  "GESLI",
  "GESLO",
  "GESLU",
  "GESSA",
  "GESSE",
  "GESSI",
  "GESSO",
  "GESSU",
  "GESTA",
  "GESTE",
  "GESTI",
  "GESTO",
  "GESTU",
  "GEŠEV",
  "GETEM",
  "GETEV",
  "GETIH",
  "GETJA",
  "GETJU",
  "GETOM",
  "GETOV",
  "GETRA",
  "GETRO",
  "GETRU",
  "GETTA",
  "GETTE",
  "GETTI",
  "GETTO",
  "GETTU",
  "GETZA",
  "GETZE",
  "GETZI",
  "GETZU",
  "GEURS",
  "GEUSA",
  "GEUSE",
  "GEUSI",
  "GEUSU",
  "GEZAH",
  "GEZAM",
  "GEZIH",
  "GEZJA",
  "GEZJU",
  "GEZOM",
  "GEZOV",
  "GHALI",
  "GHANI",
  "GHAVI",
  "GHEGA",
  "GHEGE",
  "GHEGI",
  "GHEGO",
  "GHEGU",
  "GHENT",
  "GHEZA",
  "GHEZE",
  "GHEZI",
  "GHEZU",
  "GHIEM",
  "GHIEV",
  "GHIIH",
  "GHIJA",
  "GHIJU",
  "GHINI",
  "GHIOM",
  "GHIOV",
  "GHISI",
  "GHODS",
  "GHONA",
  "GHONE",
  "GHONI",
  "GHONU",
  "GHOSH",
  "GHOSN",
  "GIADA",
  "GIADE",
  "GIADI",
  "GIADO",
  "GIAMA",
  "GIAMI",
  "GIANA",
  "GIANE",
  "GIANG",
  "GIANI",
  "GIANN",
  "GIANU",
  "GIARD",
  "GIBAJ",
  "GIBAL",
  "GIBAM",
  "GIBAŠ",
  "GIBAT",
  "GIBAU",
  "GIBAV",
  "GIBBA",
  "GIBBE",
  "GIBBI",
  "GIBBS",
  "GIBBU",
  "GIBEK",
  "GIBEL",
  "GIBEM",
  "GIBEN",
  "GIBES",
  "GIBEV",
  "GIBIH",
  "GIBIT",
  "GIBJA",
  "GIBJU",
  "GIBKA",
  "GIBKE",
  "GIBKI",
  "GIBKO",
  "GIBLA",
  "GIBLE",
  "GIBLI",
  "GIBLU",
  "GIBNA",
  "GIBNE",
  "GIBNI",
  "GIBNO",
  "GIBOM",
  "GIBON",
  "GIBOV",
  "GIBUL",
  "GIČAN",
  "GIČEV",
  "GIDER",
  "GIDIH",
  "GIDJA",
  "GIDJU",
  "GIDOM",
  "GIDOV",
  "GIELA",
  "GIELE",
  "GIELI",
  "GIELO",
  "GIELU",
  "GIESA",
  "GIESE",
  "GIESI",
  "GIESS",
  "GIESU",
  "GIETZ",
  "GIFTA",
  "GIFTE",
  "GIFTI",
  "GIFTU",
  "GIGAH",
  "GIGAM",
  "GIGEM",
  "GIGER",
  "GIGEV",
  "GIGGS",
  "GIGIH",
  "GIGIN",
  "GIGJA",
  "GIGJU",
  "GIGLI",
  "GIGOL",
  "GIGOM",
  "GIGON",
  "GIGOV",
  "GIHON",
  "GIJIN",
  "GIJON",
  "GIJSA",
  "GIJSE",
  "GIJSI",
  "GIJSU",
  "GILAD",
  "GILAH",
  "GILAM",
  "GILAN",
  "GILDA",
  "GILDE",
  "GILDI",
  "GILDO",
  "GILDU",
  "GILES",
  "GILGA",
  "GILGE",
  "GILGI",
  "GILGU",
  "GILIA",
  "GILIE",
  "GILIH",
  "GILII",
  "GILIN",
  "GILIO",
  "GILIU",
  "GILJA",
  "GILJU",
  "GILKS",
  "GILLA",
  "GILLE",
  "GILLI",
  "GILLO",
  "GILLU",
  "GILOM",
  "GILOT",
  "GILOV",
  "GILSA",
  "GILSE",
  "GILSI",
  "GILSU",
  "GILZA",
  "GILZE",
  "GILZI",
  "GILZU",
  "GIMEN",
  "GIMLI",
  "GINAH",
  "GINAM",
  "GINAT",
  "GINDI",
  "GINDL",
  "GINEČ",
  "GINEM",
  "GINER",
  "GINES",
  "GINEŠ",
  "GINGA",
  "GINGE",
  "GINGI",
  "GINGU",
  "GINIH",
  "GINIL",
  "GININ",
  "GINIT",
  "GINIU",
  "GINIV",
  "GINJA",
  "GINJU",
  "GINKA",
  "GINKE",
  "GINKI",
  "GINKO",
  "GINKU",
  "GINNA",
  "GINNE",
  "GINNI",
  "GINNO",
  "GINNU",
  "GINOM",
  "GINOV",
  "GINSA",
  "GINSE",
  "GINSI",
  "GINSU",
  "GIOIA",
  "GIOIE",
  "GIOII",
  "GIOIO",
  "GIOLI",
  "GIONA",
  "GIONE",
  "GIONI",
  "GIONO",
  "GIONU",
  "GIORA",
  "GIORD",
  "GIORE",
  "GIORI",
  "GIORO",
  "GIORU",
  "GIOTA",
  "GIOTE",
  "GIOTI",
  "GIOTU",
  "GIPPA",
  "GIPPE",
  "GIPPI",
  "GIPPU",
  "GIPSA",
  "GIPSE",
  "GIPSI",
  "GIPSU",
  "GIRAL",
  "GIRIC",
  "GIRIČ",
  "GIRJA",
  "GIRJU",
  "GIRKA",
  "GIRKE",
  "GIRKI",
  "GIRKU",
  "GIRLS",
  "GIROL",
  "GIROM",
  "GIRON",
  "GIRUS",
  "GISCH",
  "GISEL",
  "GISEM",
  "GISEV",
  "GISHA",
  "GISHE",
  "GISHI",
  "GISHU",
  "GISIH",
  "GISIN",
  "GISJA",
  "GISJU",
  "GISOM",
  "GISOV",
  "GISTA",
  "GISTE",
  "GISTI",
  "GISTU",
  "GIŠKA",
  "GIŠKE",
  "GIŠKI",
  "GIŠKO",
  "GITAH",
  "GITAM",
  "GITIC",
  "GITIN",
  "GITTA",
  "GITTE",
  "GITTI",
  "GITTO",
  "GITTU",
  "GIUNT",
  "GIUSA",
  "GIUSE",
  "GIUSI",
  "GIUSU",
  "GIVEN",
  "GIVNA",
  "GIVNU",
  "GIVOS",
  "GIZEL",
  "GIZEM",
  "GIZZI",
  "GIŽAN",
  "GJACI",
  "GJEKA",
  "GJEKE",
  "GJEKI",
  "GJEKO",
  "GJEKU",
  "GJEMS",
  "GJERT",
  "GJETA",
  "GJETE",
  "GJETI",
  "GJETO",
  "GJETU",
  "GJICI",
  "GJIKA",
  "GJIKE",
  "GJIKI",
  "GJIKO",
  "GJIKU",
  "GJINA",
  "GJINE",
  "GJINI",
  "GJINU",
  "GJOCI",
  "GJOKA",
  "GJOKE",
  "GJOKI",
  "GJOKO",
  "GJOKU",
  "GJONA",
  "GJONE",
  "GJONI",
  "GJONU",
  "GJUDA",
  "GJUDE",
  "GJUDI",
  "GJUDU",
  "GJUKA",
  "GJUKE",
  "GJUKI",
  "GJUKO",
  "GJUKU",
  "GJURA",
  "GJURE",
  "GJURI",
  "GJURO",
  "GJURR",
  "GJURU",
  "GJUZI",
  "GLABB",
  "GLADA",
  "GLADE",
  "GLADI",
  "GLADU",
  "GLAHN",
  "GLAJU",
  "GLAMA",
  "GLAME",
  "GLAMI",
  "GLAMU",
  "GLANA",
  "GLANE",
  "GLANG",
  "GLANI",
  "GLANS",
  "GLANU",
  "GLANZ",
  "GLASA",
  "GLASB",
  "GLASE",
  "GLASI",
  "GLASL",
  "GLASS",
  "GLASU",
  "GLATZ",
  "GLAUA",
  "GLAUE",
  "GLAUI",
  "GLAUK",
  "GLAUU",
  "GLAVA",
  "GLAVC",
  "GLAVE",
  "GLAVI",
  "GLAVO",
  "GLAZA",
  "GLAZB",
  "GLAZE",
  "GLAZI",
  "GLAZU",
  "GLAŽA",
  "GLAŽE",
  "GLAŽI",
  "GLAŽU",
  "GLEBA",
  "GLEBE",
  "GLEBI",
  "GLEBU",
  "GLEDA",
  "GLEDE",
  "GLEDI",
  "GLEDO",
  "GLEDU",
  "GLEES",
  "GLEIB",
  "GLEIM",
  "GLEIN",
  "GLEIT",
  "GLEJA",
  "GLEJU",
  "GLEMA",
  "GLEMK",
  "GLEMU",
  "GLENA",
  "GLEND",
  "GLENE",
  "GLENI",
  "GLENN",
  "GLENU",
  "GLESS",
  "GLICK",
  "GLIER",
  "GLIFA",
  "GLIFE",
  "GLIFI",
  "GLIFU",
  "GLIGA",
  "GLIGE",
  "GLIGI",
  "GLIGO",
  "GLIGU",
  "GLIHA",
  "GLIHE",
  "GLIHI",
  "GLIHO",
  "GLIHU",
  "GLIJA",
  "GLIJE",
  "GLIJI",
  "GLIJO",
  "GLIKA",
  "GLIKE",
  "GLIKI",
  "GLIKU",
  "GLIMT",
  "GLINA",
  "GLINE",
  "GLINI",
  "GLINJ",
  "GLINK",
  "GLINO",
  "GLINT",
  "GLIOM",
  "GLIOZ",
  "GLIST",
  "GLIVA",
  "GLIVC",
  "GLIVE",
  "GLIVI",
  "GLIVO",
  "GLJIV",
  "GLOAG",
  "GLOBA",
  "GLOBE",
  "GLOBI",
  "GLOBO",
  "GLOBU",
  "GLOCK",
  "GLODA",
  "GLODE",
  "GLODI",
  "GLODO",
  "GLODU",
  "GLOFF",
  "GLOGA",
  "GLOGI",
  "GLOGU",
  "GLOJI",
  "GLOOR",
  "GLORI",
  "GLOSA",
  "GLOSE",
  "GLOSI",
  "GLOSO",
  "GLOSS",
  "GLOSU",
  "GLOTA",
  "GLOTE",
  "GLOTH",
  "GLOTI",
  "GLOTO",
  "GLOVA",
  "GLOVO",
  "GLOVU",
  "GLUBB",
  "GLUCK",
  "GLUHA",
  "GLUHC",
  "GLUHE",
  "GLUHI",
  "GLUHO",
  "GLUMA",
  "GLUME",
  "GLUMI",
  "GLUMU",
  "GLUON",
  "GLUPA",
  "GLUPE",
  "GLUPI",
  "GLUPO",
  "GLUŠC",
  "GLUŠE",
  "GLUŠI",
  "GLUŠU",
  "GLUTA",
  "GLUTE",
  "GLUTH",
  "GLUTI",
  "GLUTU",
  "GMAIL",
  "GMAJN",
  "GMOTA",
  "GMOTE",
  "GMOTI",
  "GMOTO",
  "GNADA",
  "GNADE",
  "GNADI",
  "GNADO",
  "GNAJS",
  "GNALA",
  "GNALE",
  "GNALI",
  "GNALO",
  "GNANA",
  "GNANE",
  "GNANI",
  "GNANJ",
  "GNANN",
  "GNANO",
  "GNANT",
  "GNARR",
  "GNATI",
  "GNEČA",
  "GNEČE",
  "GNEČI",
  "GNEČO",
  "GNEST",
  "GNETE",
  "GNETI",
  "GNETU",
  "GNEVA",
  "GNEVI",
  "GNEVU",
  "GNEZD",
  "GNIDA",
  "GNIDE",
  "GNIDI",
  "GNIDO",
  "GNIJE",
  "GNIJO",
  "GNILA",
  "GNILE",
  "GNILI",
  "GNILO",
  "GNITI",
  "GNJAT",
  "GNJEČ",
  "GNOJA",
  "GNOJE",
  "GNOJI",
  "GNOJU",
  "GNOLI",
  "GNOZA",
  "GNOZE",
  "GNOZI",
  "GNOZO",
  "GNUJA",
  "GNUJE",
  "GNUJI",
  "GNUJU",
  "GNUSA",
  "GNUSE",
  "GNUSI",
  "GNUSU",
  "GOADA",
  "GOADE",
  "GOADI",
  "GOADU",
  "GOAMA",
  "GOAMI",
  "GOATI",
  "GOBAH",
  "GOBAM",
  "GOBAN",
  "GOBAR",
  "GOBAV",
  "GOBBA",
  "GOBBE",
  "GOBBI",
  "GOBBO",
  "GOBBU",
  "GOBCA",
  "GOBCE",
  "GOBCI",
  "GOBCU",
  "GOBEC",
  "GOBIC",
  "GOBIN",
  "GOBJA",
  "GOBJE",
  "GOBJI",
  "GOBJO",
  "GOBLA",
  "GOBLE",
  "GOBLI",
  "GOBLU",
  "GOBOV",
  "GOCHA",
  "GOCHE",
  "GOCHI",
  "GOCHU",
  "GOCKA",
  "GOCKE",
  "GOCKI",
  "GOCKU",
  "GOČAH",
  "GOČAM",
  "GOČAN",
  "GODAL",
  "GODAN",
  "GODBA",
  "GODBE",
  "GODBI",
  "GODBO",
  "GODCA",
  "GODCE",
  "GODCI",
  "GODCU",
  "GODEC",
  "GODEČ",
  "GODEL",
  "GODEM",
  "GODEN",
  "GODEŠ",
  "GODEU",
  "GODEV",
  "GODEŽ",
  "GODIČ",
  "GODIH",
  "GODIL",
  "GODIM",
  "GODIN",
  "GODIŠ",
  "GODIT",
  "GODIU",
  "GODIV",
  "GODJA",
  "GODJU",
  "GODLA",
  "GODLE",
  "GODLI",
  "GODLO",
  "GODNA",
  "GODNE",
  "GODNI",
  "GODNO",
  "GODOM",
  "GODON",
  "GODOT",
  "GODOV",
  "GODTA",
  "GODTE",
  "GODTI",
  "GODTU",
  "GODUJ",
  "GOEDE",
  "GOEKA",
  "GOEKE",
  "GOEKI",
  "GOEKU",
  "GOENA",
  "GOENC",
  "GOENE",
  "GOENI",
  "GOENU",
  "GOERG",
  "GOERL",
  "GOETH",
  "GOETZ",
  "GOFEM",
  "GOFER",
  "GOFEV",
  "GOFFA",
  "GOFFE",
  "GOFFI",
  "GOFFU",
  "GOFIH",
  "GOFIN",
  "GOFJA",
  "GOFJU",
  "GOFOM",
  "GOFOV",
  "GOGAH",
  "GOGAJ",
  "GOGAL",
  "GOGAM",
  "GOGGI",
  "GOGHA",
  "GOGHE",
  "GOGHI",
  "GOGHU",
  "GOGIH",
  "GOGIN",
  "GOGJA",
  "GOGJU",
  "GOGLA",
  "GOGLE",
  "GOGLI",
  "GOGLU",
  "GOGOL",
  "GOGOM",
  "GOGOV",
  "GOHIH",
  "GOHIL",
  "GOHJA",
  "GOHJU",
  "GOHLA",
  "GOHLE",
  "GOHLI",
  "GOHLU",
  "GOHOM",
  "GOHOV",
  "GOINA",
  "GOINE",
  "GOING",
  "GOINI",
  "GOINO",
  "GOINS",
  "GOINU",
  "GOIRI",
  "GOJAČ",
  "GOJAH",
  "GOJAK",
  "GOJAM",
  "GOJAN",
  "GOJCA",
  "GOJCE",
  "GOJCI",
  "GOJCU",
  "GOJČA",
  "GOJČE",
  "GOJČI",
  "GOJČO",
  "GOJČU",
  "GOJEČ",
  "GOJEM",
  "GOJEN",
  "GOJER",
  "GOJEV",
  "GOJIH",
  "GOJIL",
  "GOJIM",
  "GOJIN",
  "GOJIŠ",
  "GOJIT",
  "GOJIU",
  "GOJIV",
  "GOJJA",
  "GOJJU",
  "GOJKA",
  "GOJKE",
  "GOJKI",
  "GOJKO",
  "GOJKU",
  "GOJOM",
  "GOJOV",
  "GOJUN",
  "GOKAL",
  "GOKIH",
  "GOKJA",
  "GOKJU",
  "GOKOM",
  "GOKOV",
  "GOKPA",
  "GOKPE",
  "GOKPI",
  "GOKPO",
  "GOKPU",
  "GOLAC",
  "GOLAČ",
  "GOLAH",
  "GOLAK",
  "GOLAM",
  "GOLAN",
  "GOLAR",
  "GOLAŽ",
  "GOLCA",
  "GOLCE",
  "GOLCI",
  "GOLCU",
  "GOLČE",
  "GOLČI",
  "GOLČU",
  "GOLDA",
  "GOLDE",
  "GOLDI",
  "GOLDO",
  "GOLDU",
  "GOLEC",
  "GOLEČ",
  "GOLEH",
  "GOLEK",
  "GOLEM",
  "GOLEN",
  "GOLEŠ",
  "GOLEV",
  "GOLEŽ",
  "GOLFA",
  "GOLFI",
  "GOLFU",
  "GOLGA",
  "GOLGI",
  "GOLIA",
  "GOLIC",
  "GOLIČ",
  "GOLID",
  "GOLIE",
  "GOLIH",
  "GOLII",
  "GOLIJ",
  "GOLIK",
  "GOLIL",
  "GOLIM",
  "GOLIN",
  "GOLIO",
  "GOLIS",
  "GOLIŠ",
  "GOLIT",
  "GOLIU",
  "GOLIV",
  "GOLJA",
  "GOLJE",
  "GOLJI",
  "GOLJO",
  "GOLJU",
  "GOLLA",
  "GOLLE",
  "GOLLI",
  "GOLLU",
  "GOLMI",
  "GOLOB",
  "GOLOM",
  "GOLON",
  "GOLOT",
  "GOLOV",
  "GOLPE",
  "GOLŠA",
  "GOLŠC",
  "GOLŠE",
  "GOLŠI",
  "GOLŠO",
  "GOLTA",
  "GOLTC",
  "GOLTE",
  "GOLTI",
  "GOLTL",
  "GOLTU",
  "GOLTZ",
  "GOLUB",
  "GOLUH",
  "GOLUM",
  "GOLUŽ",
  "GOMAH",
  "GOMAM",
  "GOMEL",
  "GOMES",
  "GOMEZ",
  "GOMIH",
  "GOMIL",
  "GOMIS",
  "GOMJA",
  "GOMJU",
  "GOMOL",
  "GOMOM",
  "GOMOV",
  "GOMZI",
  "GONAD",
  "GONDA",
  "GONDE",
  "GONDI",
  "GONDO",
  "GONDU",
  "GONEČ",
  "GONEM",
  "GONEV",
  "GONGA",
  "GONGE",
  "GONGI",
  "GONGU",
  "GONIČ",
  "GONIH",
  "GONIK",
  "GONIL",
  "GONIM",
  "GONIN",
  "GONIŠ",
  "GONIT",
  "GONIU",
  "GONIV",
  "GONJA",
  "GONJE",
  "GONJI",
  "GONJO",
  "GONJU",
  "GONOM",
  "GONON",
  "GONOV",
  "GONZA",
  "GONZE",
  "GONZI",
  "GONZO",
  "GONZU",
  "GOOCH",
  "GOODA",
  "GOODE",
  "GOODI",
  "GOODU",
  "GOOLD",
  "GOOSE",
  "GOPAL",
  "GOPAN",
  "GORAH",
  "GORAK",
  "GORAL",
  "GORAM",
  "GORAN",
  "GORAT",
  "GORCA",
  "GORCE",
  "GORCI",
  "GORCO",
  "GORCU",
  "GORČE",
  "GORDA",
  "GORDE",
  "GORDH",
  "GORDI",
  "GORDU",
  "GOREC",
  "GOREČ",
  "GOREE",
  "GOREK",
  "GOREL",
  "GOREN",
  "GORES",
  "GORET",
  "GOREU",
  "GORIC",
  "GORIČ",
  "GORIH",
  "GORIJ",
  "GORIL",
  "GORIM",
  "GORIN",
  "GORIS",
  "GORIŠ",
  "GORIV",
  "GORJA",
  "GORJE",
  "GORJI",
  "GORJU",
  "GORKA",
  "GORKE",
  "GORKI",
  "GORKO",
  "GORKU",
  "GORNA",
  "GORNE",
  "GORNI",
  "GORNU",
  "GOROM",
  "GORON",
  "GOROV",
  "GORST",
  "GORŠA",
  "GORŠE",
  "GORŠI",
  "GORŠO",
  "GORŠU",
  "GORTA",
  "GORTE",
  "GORTI",
  "GORTU",
  "GORUN",
  "GORUP",
  "GORZA",
  "GORZE",
  "GORZI",
  "GORZO",
  "GORZU",
  "GOSAK",
  "GOSAR",
  "GOSCH",
  "GOSEH",
  "GOSEM",
  "GOSIN",
  "GOSJA",
  "GOSJE",
  "GOSJI",
  "GOSJO",
  "GOSKA",
  "GOSKE",
  "GOSKI",
  "GOSKO",
  "GOSLI",
  "GOSMI",
  "GOSPA",
  "GOSPE",
  "GOSPO",
  "GOSSA",
  "GOSSE",
  "GOSSI",
  "GOSSU",
  "GOSTA",
  "GOSTE",
  "GOSTI",
  "GOSTO",
  "GOSTU",
  "GOŠČA",
  "GOŠČE",
  "GOŠČI",
  "GOŠČO",
  "GOŠEV",
  "GOŠKA",
  "GOŠKE",
  "GOŠKI",
  "GOŠKO",
  "GOŠTE",
  "GOTAL",
  "GOTAR",
  "GOTER",
  "GOTEV",
  "GOTHA",
  "GOTHE",
  "GOTHI",
  "GOTHO",
  "GOTHU",
  "GOTIH",
  "GOTJA",
  "GOTJE",
  "GOTJU",
  "GOTOM",
  "GOTOR",
  "GOTOV",
  "GOTSA",
  "GOTSE",
  "GOTSI",
  "GOTSU",
  "GOTTA",
  "GOTTE",
  "GOTTI",
  "GOTTO",
  "GOTTU",
  "GOUCK",
  "GOUDA",
  "GOUDE",
  "GOUDI",
  "GOUDO",
  "GOUGH",
  "GOUIH",
  "GOUIN",
  "GOUJA",
  "GOUJU",
  "GOULD",
  "GOUOM",
  "GOUOV",
  "GOVCA",
  "GOVCE",
  "GOVCI",
  "GOVCU",
  "GOVED",
  "GOVEJ",
  "GOVIH",
  "GOVIL",
  "GOVJA",
  "GOVJU",
  "GOVNA",
  "GOVNO",
  "GOVNU",
  "GOVOM",
  "GOVOR",
  "GOVOV",
  "GOVŠE",
  "GOVŽE",
  "GOZAL",
  "GOZDA",
  "GOZDE",
  "GOZDI",
  "GOZDU",
  "GOZZA",
  "GOZZE",
  "GOZZI",
  "GOZZO",
  "GOZZU",
  "GOŽAN",
  "GOŽEM",
  "GOŽEV",
  "GOŽIH",
  "GOŽIN",
  "GOŽJA",
  "GOŽJE",
  "GOŽJI",
  "GOŽJO",
  "GOŽJU",
  "GOŽOM",
  "GOŽOV",
  "GPOTU",
  "GRAAE",
  "GRAAF",
  "GRABA",
  "GRABE",
  "GRABI",
  "GRABO",
  "GRABS",
  "GRABU",
  "GRACA",
  "GRACE",
  "GRACI",
  "GRADA",
  "GRADB",
  "GRADE",
  "GRADI",
  "GRADL",
  "GRADO",
  "GRADU",
  "GRAEF",
  "GRAEL",
  "GRAFA",
  "GRAFE",
  "GRAFF",
  "GRAFI",
  "GRAFU",
  "GRAGG",
  "GRAGT",
  "GRAHA",
  "GRAHC",
  "GRAHE",
  "GRAHI",
  "GRAHM",
  "GRAHN",
  "GRAHU",
  "GRAIG",
  "GRAIS",
  "GRAJA",
  "GRAJE",
  "GRAJF",
  "GRAJI",
  "GRAJO",
  "GRAJŠ",
  "GRAJU",
  "GRALA",
  "GRALE",
  "GRALI",
  "GRALU",
  "GRAMA",
  "GRAMC",
  "GRAME",
  "GRAMI",
  "GRAMM",
  "GRAMS",
  "GRAMU",
  "GRANA",
  "GRAND",
  "GRANE",
  "GRANI",
  "GRANN",
  "GRANT",
  "GRANU",
  "GRAPA",
  "GRAPE",
  "GRAPI",
  "GRAPO",
  "GRASA",
  "GRASE",
  "GRASI",
  "GRASS",
  "GRASU",
  "GRAŠA",
  "GRAŠC",
  "GRAŠE",
  "GRAŠI",
  "GRAŠO",
  "GRAŠU",
  "GRATA",
  "GRATE",
  "GRATI",
  "GRATL",
  "GRATU",
  "GRAUA",
  "GRAUE",
  "GRAUF",
  "GRAUI",
  "GRAUU",
  "GRAZI",
  "GRBAC",
  "GRBAČ",
  "GRBAH",
  "GRBAM",
  "GRBAN",
  "GRBAS",
  "GRBAV",
  "GRBCA",
  "GRBCE",
  "GRBCI",
  "GRBCU",
  "GRBEC",
  "GRBEČ",
  "GRBEM",
  "GRBEN",
  "GRBEV",
  "GRBEŽ",
  "GRBIC",
  "GRBIČ",
  "GRBIH",
  "GRBIL",
  "GRBIM",
  "GRBIN",
  "GRBIŠ",
  "GRBIT",
  "GRBIU",
  "GRBIV",
  "GRBJA",
  "GRBJU",
  "GRBNA",
  "GRBNE",
  "GRBNI",
  "GRBNO",
  "GRBOM",
  "GRBOV",
  "GRCEV",
  "GRČAH",
  "GRČAL",
  "GRČAM",
  "GRČAR",
  "GRČAT",
  "GRČAU",
  "GRČAV",
  "GRČEČ",
  "GRČEM",
  "GRČEV",
  "GRČIC",
  "GRČIČ",
  "GRČIH",
  "GRČIM",
  "GRČIŠ",
  "GRČJA",
  "GRČJU",
  "GRČOM",
  "GRČOV",
  "GRDEČ",
  "GRDEM",
  "GRDEN",
  "GRDGA",
  "GRDIČ",
  "GRDIH",
  "GRDIL",
  "GRDIM",
  "GRDIN",
  "GRDIŠ",
  "GRDIT",
  "GRDIU",
  "GRDIV",
  "GRDOB",
  "GRDOT",
  "GRDUN",
  "GREAN",
  "GREAT",
  "GREBA",
  "GREBB",
  "GREBE",
  "GREBI",
  "GREBO",
  "GREBU",
  "GRECA",
  "GRECE",
  "GRECH",
  "GRECI",
  "GRECO",
  "GRECS",
  "GRECU",
  "GREDA",
  "GREDE",
  "GREDI",
  "GREDO",
  "GREEN",
  "GREER",
  "GREET",
  "GREFA",
  "GREFE",
  "GREFF",
  "GREFI",
  "GREFU",
  "GREGA",
  "GREGE",
  "GREGG",
  "GREGI",
  "GREGL",
  "GREGO",
  "GREGU",
  "GREHA",
  "GREHE",
  "GREHI",
  "GREHU",
  "GREIF",
  "GREIG",
  "GREIL",
  "GREIM",
  "GREIN",
  "GREIS",
  "GREJA",
  "GREJE",
  "GREJI",
  "GREJO",
  "GREJU",
  "GRELA",
  "GRELC",
  "GRELE",
  "GRELI",
  "GRELK",
  "GRELL",
  "GRELO",
  "GRELU",
  "GREMA",
  "GREME",
  "GREMM",
  "GREMO",
  "GRENA",
  "GRENC",
  "GRENE",
  "GRENI",
  "GRENO",
  "GRENU",
  "GREPA",
  "GREPI",
  "GREPP",
  "GREPU",
  "GRESA",
  "GRESE",
  "GRESI",
  "GRESU",
  "GREŠE",
  "GREŠI",
  "GREŠU",
  "GRETA",
  "GRETE",
  "GRETI",
  "GRETL",
  "GRETO",
  "GRETT",
  "GREVA",
  "GREVE",
  "GREVI",
  "GREVO",
  "GREVU",
  "GREZD",
  "GREŽA",
  "GREŽI",
  "GREŽU",
  "GRGAN",
  "GRGAR",
  "GRGAT",
  "GRGET",
  "GRGIČ",
  "GRGIN",
  "GRGRA",
  "GRGRI",
  "GRGRU",
  "GRIBL",
  "GRICA",
  "GRICE",
  "GRIČA",
  "GRIČE",
  "GRIČI",
  "GRIČU",
  "GRIDA",
  "GRIDE",
  "GRIDI",
  "GRIDO",
  "GRIEB",
  "GRIEG",
  "GRIEM",
  "GRIES",
  "GRIFA",
  "GRIFE",
  "GRIFF",
  "GRIFI",
  "GRIFO",
  "GRIFU",
  "GRIGA",
  "GRIGE",
  "GRIGG",
  "GRIGI",
  "GRIGO",
  "GRIGU",
  "GRILA",
  "GRILC",
  "GRILE",
  "GRILI",
  "GRILJ",
  "GRILL",
  "GRILO",
  "GRILU",
  "GRIMA",
  "GRIME",
  "GRIMI",
  "GRIMM",
  "GRIMO",
  "GRIMS",
  "GRIMU",
  "GRINA",
  "GRINE",
  "GRING",
  "GRINI",
  "GRINS",
  "GRINT",
  "GRINU",
  "GRIOT",
  "GRIPA",
  "GRIPE",
  "GRIPI",
  "GRIPO",
  "GRIPP",
  "GRIPU",
  "GRISA",
  "GRISE",
  "GRISI",
  "GRISS",
  "GRIST",
  "GRISU",
  "GRIŠA",
  "GRIŠE",
  "GRIŠI",
  "GRIŠO",
  "GRIŠU",
  "GRITZ",
  "GRIVA",
  "GRIVC",
  "GRIVČ",
  "GRIVE",
  "GRIVI",
  "GRIVN",
  "GRIVO",
  "GRIZA",
  "GRIZE",
  "GRIZI",
  "GRIZT",
  "GRIZU",
  "GRIZZ",
  "GRIŽA",
  "GRIŽE",
  "GRIŽI",
  "GRIŽK",
  "GRIŽO",
  "GRJOL",
  "GRKIH",
  "GRKJA",
  "GRKJU",
  "GRKNE",
  "GRKNI",
  "GRKNU",
  "GRKOM",
  "GRKOV",
  "GRLAH",
  "GRLAM",
  "GRLAT",
  "GRLCA",
  "GRLCE",
  "GRLCI",
  "GRLCU",
  "GRLEC",
  "GRLEČ",
  "GRLEL",
  "GRLEN",
  "GRLET",
  "GRLEU",
  "GRLIC",
  "GRLIČ",
  "GRLIH",
  "GRLIM",
  "GRLIŠ",
  "GRLJA",
  "GRLJE",
  "GRLJI",
  "GRLJU",
  "GRLNA",
  "GRLNE",
  "GRLNI",
  "GRLNO",
  "GRLOM",
  "GRLOV",
  "GRMAD",
  "GRMAŠ",
  "GRMAT",
  "GRMEC",
  "GRMEČ",
  "GRMEK",
  "GRMEL",
  "GRMEM",
  "GRMET",
  "GRMEU",
  "GRMEV",
  "GRMIČ",
  "GRMIH",
  "GRMIM",
  "GRMIŠ",
  "GRMJA",
  "GRMJU",
  "GRMOJ",
  "GRMOM",
  "GRMOV",
  "GRMUŠ",
  "GROBA",
  "GROBE",
  "GROBI",
  "GROBO",
  "GROBU",
  "GRODA",
  "GRODE",
  "GRODI",
  "GRODU",
  "GROFA",
  "GROFE",
  "GROFF",
  "GROFI",
  "GROFU",
  "GROGA",
  "GROGE",
  "GROGI",
  "GROGL",
  "GROGO",
  "GROGU",
  "GROHL",
  "GROLL",
  "GROMA",
  "GROME",
  "GROMI",
  "GROMU",
  "GROOF",
  "GROOM",
  "GROOP",
  "GROOS",
  "GROOT",
  "GROPP",
  "GROSA",
  "GROSE",
  "GROSI",
  "GROSS",
  "GROSU",
  "GROSZ",
  "GROŠA",
  "GROŠE",
  "GROŠI",
  "GROŠL",
  "GROŠU",
  "GROTA",
  "GROTE",
  "GROTH",
  "GROTI",
  "GROTU",
  "GROUP",
  "GROUT",
  "GROVA",
  "GROVE",
  "GROVI",
  "GROVU",
  "GROZA",
  "GROZD",
  "GROZE",
  "GROZI",
  "GROZO",
  "GROZU",
  "GRŠAJ",
  "GRŠAL",
  "GRŠAM",
  "GRŠAŠ",
  "GRŠAT",
  "GRŠAU",
  "GRŠAV",
  "GRŠEM",
  "GRŠIH",
  "GRŠIM",
  "GRŠKA",
  "GRŠKE",
  "GRŠKI",
  "GRŠKO",
  "GRUBB",
  "GRUBE",
  "GRUBI",
  "GRUČA",
  "GRUČE",
  "GRUČI",
  "GRUČL",
  "GRUČO",
  "GRUČU",
  "GRUDA",
  "GRUDE",
  "GRUDI",
  "GRUDO",
  "GRUDU",
  "GRUEL",
  "GRUEN",
  "GRUHL",
  "GRUHN",
  "GRUJA",
  "GRUJE",
  "GRUJI",
  "GRUJU",
  "GRULI",
  "GRULJ",
  "GRULU",
  "GRUMA",
  "GRUME",
  "GRUMI",
  "GRUMU",
  "GRUND",
  "GRUNF",
  "GRUNT",
  "GRUPA",
  "GRUPE",
  "GRUPI",
  "GRUPO",
  "GRUPP",
  "GRUSH",
  "GRUSS",
  "GRUŠA",
  "GRUŠC",
  "GRUŠČ",
  "GRUŠE",
  "GRUŠI",
  "GRUŠO",
  "GRUŠT",
  "GRUŠU",
  "GRUTT",
  "GRZIN",
  "GRŽAN",
  "GRŽIN",
  "GSPAN",
  "GTTEH",
  "GUAIT",
  "GUAMA",
  "GUAMU",
  "GUANA",
  "GUANE",
  "GUANI",
  "GUANU",
  "GUAPA",
  "GUAPE",
  "GUAPI",
  "GUAPO",
  "GUAPU",
  "GUARA",
  "GUARE",
  "GUARI",
  "GUARU",
  "GUBAH",
  "GUBAJ",
  "GUBAL",
  "GUBAM",
  "GUBAN",
  "GUBAŠ",
  "GUBAT",
  "GUBAU",
  "GUBAV",
  "GUBCA",
  "GUBCE",
  "GUBCI",
  "GUBCU",
  "GUBEC",
  "GUBEČ",
  "GUBEN",
  "GUBER",
  "GUBIC",
  "GUBIČ",
  "GUBIH",
  "GUBIK",
  "GUBIL",
  "GUBIM",
  "GUBIN",
  "GUBIŠ",
  "GUBIT",
  "GUBIU",
  "GUBIV",
  "GUBJA",
  "GUBJU",
  "GUBNA",
  "GUBNE",
  "GUBNI",
  "GUBNO",
  "GUBNU",
  "GUBOM",
  "GUBOV",
  "GUCCI",
  "GUCHT",
  "GUČEK",
  "GUČKA",
  "GUČKE",
  "GUČKI",
  "GUČKU",
  "GUDAF",
  "GUDAR",
  "GUDAS",
  "GUDIM",
  "GUDIN",
  "GUDME",
  "GUDNI",
  "GUEHI",
  "GUEKA",
  "GUEKE",
  "GUEKI",
  "GUEKO",
  "GUEKU",
  "GUELA",
  "GUELE",
  "GUELI",
  "GUELL",
  "GUELU",
  "GUERR",
  "GUERS",
  "GUESS",
  "GUEST",
  "GUETT",
  "GUGAJ",
  "GUGAL",
  "GUGAM",
  "GUGAŠ",
  "GUGAT",
  "GUGAU",
  "GUGAV",
  "GUGER",
  "GUGLA",
  "GUGLI",
  "GUGLU",
  "GUHLA",
  "GUHLE",
  "GUHLI",
  "GUHLU",
  "GUIAH",
  "GUIAM",
  "GUICA",
  "GUICE",
  "GUIDA",
  "GUIDE",
  "GUIDI",
  "GUIDO",
  "GUIDU",
  "GUIIN",
  "GUIJT",
  "GUILA",
  "GUILD",
  "GUILE",
  "GUILI",
  "GUILL",
  "GUILU",
  "GUINA",
  "GUINE",
  "GUINI",
  "GUINN",
  "GUINT",
  "GUINU",
  "GUIOL",
  "GUION",
  "GUIST",
  "GUJEM",
  "GUJEV",
  "GUJIH",
  "GUJJA",
  "GUJJU",
  "GUJOM",
  "GUJON",
  "GUJOV",
  "GUJTA",
  "GUJTE",
  "GUJTI",
  "GUJTU",
  "GUKOV",
  "GULAG",
  "GULAH",
  "GULAM",
  "GULAN",
  "GULDA",
  "GULDE",
  "GULDI",
  "GULDO",
  "GULDU",
  "GULEČ",
  "GULEŽ",
  "GULIČ",
  "GULIH",
  "GULIK",
  "GULIL",
  "GULIM",
  "GULIN",
  "GULIŠ",
  "GULIT",
  "GULIU",
  "GULIV",
  "GULJA",
  "GULJU",
  "GULKA",
  "GULKE",
  "GULKI",
  "GULKO",
  "GULKU",
  "GULLA",
  "GULLE",
  "GULLI",
  "GULLO",
  "GULLU",
  "GULOM",
  "GULOV",
  "GUMAH",
  "GUMAM",
  "GUMAR",
  "GUMBA",
  "GUMBE",
  "GUMBI",
  "GUMBS",
  "GUMBU",
  "GUMEN",
  "GUMIC",
  "GUMIN",
  "GUMMA",
  "GUMME",
  "GUMMI",
  "GUMMU",
  "GUMNA",
  "GUMNI",
  "GUMNO",
  "GUMNU",
  "GUMPA",
  "GUMPC",
  "GUMPE",
  "GUMPI",
  "GUMPK",
  "GUMPU",
  "GUMZE",
  "GUMZI",
  "GUNAH",
  "GUNAM",
  "GUNCA",
  "GUNCI",
  "GUNCU",
  "GUNDA",
  "GUNDE",
  "GUNDI",
  "GUNDO",
  "GUNDU",
  "GUNGA",
  "GUNGE",
  "GUNGI",
  "GUNGL",
  "GUNGO",
  "GUNGU",
  "GUNIH",
  "GUNJA",
  "GUNJU",
  "GUNKE",
  "GUNNA",
  "GUNNE",
  "GUNNI",
  "GUNNU",
  "GUNOM",
  "GUNOV",
  "GUNTA",
  "GUNTE",
  "GUNTI",
  "GUNTO",
  "GUOMA",
  "GUOTA",
  "GUOTU",
  "GUOVA",
  "GUOVE",
  "GUOVI",
  "GUOVO",
  "GUPTA",
  "GUPTE",
  "GUPTI",
  "GUPTO",
  "GUPTU",
  "GURAK",
  "GURAL",
  "GURFI",
  "GURIE",
  "GURIH",
  "GURIK",
  "GURJA",
  "GURJU",
  "GUROM",
  "GUROV",
  "GURRI",
  "GURSA",
  "GURSU",
  "GUSAR",
  "GUSEL",
  "GUSEV",
  "GUSHA",
  "GUSHE",
  "GUSHI",
  "GUSHO",
  "GUSHU",
  "GUSIČ",
  "GUSIH",
  "GUSJA",
  "GUSJU",
  "GUSKA",
  "GUSKE",
  "GUSKI",
  "GUSKO",
  "GUSKU",
  "GUSLE",
  "GUSOM",
  "GUSOV",
  "GUSSA",
  "GUSSE",
  "GUSSI",
  "GUSSO",
  "GUSSU",
  "GUSTA",
  "GUSTI",
  "GUSTL",
  "GUSTO",
  "GUSTU",
  "GUŠTA",
  "GUŠTE",
  "GUŠTI",
  "GUŠTU",
  "GUTAJ",
  "GUTEŠ",
  "GUTHA",
  "GUTHE",
  "GUTHI",
  "GUTHU",
  "GUTIH",
  "GUTIN",
  "GUTJA",
  "GUTJU",
  "GUTOM",
  "GUTOV",
  "GUTZA",
  "GUTZE",
  "GUTZI",
  "GUTZU",
  "GUUSA",
  "GUUSE",
  "GUUSI",
  "GUUSU",
  "GUZAJ",
  "GUZAN",
  "GUZEJ",
  "GUZIK",
  "GUZIN",
  "GUZNE",
  "GUZNI",
  "GUZNU",
  "GUZZA",
  "GUZZE",
  "GUZZI",
  "GUZZO",
  "GUZZU",
  "GUŽIČ",
  "GUŽVA",
  "GUŽVE",
  "GUŽVI",
  "GUŽVO",
  "GUŽVU",
  "GVAJC",
  "GVANA",
  "GVANI",
  "GVANO",
  "GVANT",
  "GVANU",
  "GVAŠA",
  "GVAŠE",
  "GVAŠI",
  "GVAŠU",
  "GVELF",
  "GVERA",
  "GVERE",
  "GVERI",
  "GVERO",
  "GVERU",
  "GVIDA",
  "GVIDE",
  "GVIDI",
  "GVIDO",
  "GVIDU",
  "GVIRA",
  "GVIRE",
  "GVIRI",
  "GVIRU",
  "GZIMA",
  "GZIME",
  "GZIMI",
  "GZIMU",
  "HAABA",
  "HAABE",
  "HAABI",
  "HAABU",
  "HAACK",
  "HAAGA",
  "HAAGE",
  "HAAGI",
  "HAAGU",
  "HAAKA",
  "HAAKE",
  "HAAKI",
  "HAAKU",
  "HAANA",
  "HAANE",
  "HAANI",
  "HAANU",
  "HAARS",
  "HAART",
  "HAASA",
  "HAASE",
  "HAASI",
  "HAASS",
  "HAASU",
  "HABAS",
  "HABAT",
  "HABBA",
  "HABBE",
  "HABBI",
  "HABBO",
  "HABBU",
  "HABEČ",
  "HABER",
  "HABIB",
  "HABIČ",
  "HABIL",
  "HABIM",
  "HABIŠ",
  "HABIT",
  "HABIU",
  "HABIV",
  "HABOT",
  "HABUL",
  "HABUŠ",
  "HACCP",
  "HACHA",
  "HACHE",
  "HACHI",
  "HACHO",
  "HACHU",
  "HACIN",
  "HACKA",
  "HACKE",
  "HACKI",
  "HACKL",
  "HACKS",
  "HACKU",
  "HACON",
  "HAČEK",
  "HAČKA",
  "HAČKE",
  "HAČKI",
  "HAČKU",
  "HADAD",
  "HADAR",
  "HADAŠ",
  "HADDA",
  "HADDE",
  "HADDI",
  "HADDO",
  "HADID",
  "HADIH",
  "HADIK",
  "HADJA",
  "HADJU",
  "HADLA",
  "HADLE",
  "HADLI",
  "HADLU",
  "HADOM",
  "HADOV",
  "HADRI",
  "HADŽA",
  "HADŽE",
  "HADŽI",
  "HADŽU",
  "HAEBA",
  "HAEBE",
  "HAEBI",
  "HAEBU",
  "HAEFS",
  "HAEGG",
  "HAERI",
  "HAFED",
  "HAFER",
  "HAFIS",
  "HAFIZ",
  "HAFTA",
  "HAFTE",
  "HAFTI",
  "HAFTU",
  "HAGAG",
  "HAGAN",
  "HAGEL",
  "HAGEN",
  "HAGER",
  "HAGGA",
  "HAGGE",
  "HAGGI",
  "HAGGU",
  "HAGIH",
  "HAGJA",
  "HAGJU",
  "HAGLA",
  "HAGLE",
  "HAGLI",
  "HAGLU",
  "HAGNA",
  "HAGNE",
  "HAGNI",
  "HAGNU",
  "HAGOM",
  "HAGON",
  "HAGOV",
  "HAGUA",
  "HAGUE",
  "HAGUI",
  "HAGUU",
  "HAHLA",
  "HAHLE",
  "HAHLI",
  "HAHLU",
  "HAHNA",
  "HAHNE",
  "HAHNI",
  "HAHNU",
  "HAIDA",
  "HAIDE",
  "HAIDI",
  "HAIDT",
  "HAIDU",
  "HAIER",
  "HAIFA",
  "HAIFE",
  "HAIFI",
  "HAIFO",
  "HAIGA",
  "HAIGE",
  "HAIGH",
  "HAIGI",
  "HAIGU",
  "HAIKA",
  "HAIKE",
  "HAIKI",
  "HAIKU",
  "HAILS",
  "HAIMA",
  "HAIME",
  "HAIMI",
  "HAIMS",
  "HAIMU",
  "HAINA",
  "HAINE",
  "HAINI",
  "HAINS",
  "HAINU",
  "HAINZ",
  "HAIPL",
  "HAIRA",
  "HAIRE",
  "HAIRI",
  "HAIRU",
  "HAISA",
  "HAISE",
  "HAISI",
  "HAISU",
  "HAITI",
  "HAJAJ",
  "HAJAL",
  "HAJAM",
  "HAJAN",
  "HAJAR",
  "HAJAŠ",
  "HAJAT",
  "HAJAU",
  "HAJAV",
  "HAJCA",
  "HAJCI",
  "HAJCU",
  "HAJDI",
  "HAJDU",
  "HAJEK",
  "HAJFA",
  "HAJFE",
  "HAJFI",
  "HAJFO",
  "HAJIČ",
  "HAJKA",
  "HAJKE",
  "HAJKI",
  "HAJKO",
  "HAJKU",
  "HAJNA",
  "HAJNC",
  "HAJNE",
  "HAJNI",
  "HAJNO",
  "HAJNU",
  "HAJOŠ",
  "HAJRA",
  "HAJRE",
  "HAJRI",
  "HAJRO",
  "HAJRU",
  "HAKAN",
  "HAKIF",
  "HAKIM",
  "HAKKI",
  "HAKLA",
  "HAKLE",
  "HAKLI",
  "HAKLU",
  "HAKOL",
  "HAKON",
  "HALAH",
  "HALAK",
  "HALAL",
  "HALAM",
  "HALAS",
  "HALBA",
  "HALBE",
  "HALBI",
  "HALBU",
  "HALDA",
  "HALDE",
  "HALDI",
  "HALDU",
  "HALEC",
  "HALED",
  "HALEE",
  "HALEN",
  "HALEP",
  "HALER",
  "HALES",
  "HALET",
  "HALFA",
  "HALFE",
  "HALFF",
  "HALFI",
  "HALFU",
  "HALID",
  "HALIF",
  "HALIH",
  "HALIJ",
  "HALIL",
  "HALIM",
  "HALIN",
  "HALIT",
  "HALIV",
  "HALJA",
  "HALJE",
  "HALJI",
  "HALJO",
  "HALJU",
  "HALKA",
  "HALKE",
  "HALKI",
  "HALKO",
  "HALKU",
  "HALLA",
  "HALLE",
  "HALLI",
  "HALLO",
  "HALLS",
  "HALLU",
  "HALMA",
  "HALME",
  "HALMI",
  "HALMO",
  "HALMU",
  "HALOG",
  "HALOM",
  "HALON",
  "HALOP",
  "HALOV",
  "HALOZ",
  "HALSA",
  "HALSE",
  "HALSI",
  "HALST",
  "HALSU",
  "HALTA",
  "HALTE",
  "HALTI",
  "HALTU",
  "HALUG",
  "HALVA",
  "HALVE",
  "HALVI",
  "HALVO",
  "HAMAD",
  "HAMAM",
  "HAMAN",
  "HAMAR",
  "HAMAS",
  "HAMDI",
  "HAMED",
  "HAMEL",
  "HAMER",
  "HAMES",
  "HAMID",
  "HAMIH",
  "HAMIT",
  "HAMJA",
  "HAMJU",
  "HAMLA",
  "HAMLE",
  "HAMLI",
  "HAMLU",
  "HAMMA",
  "HAMME",
  "HAMMI",
  "HAMMU",
  "HAMOM",
  "HAMON",
  "HAMOV",
  "HAMPA",
  "HAMPE",
  "HAMPI",
  "HAMPL",
  "HAMPU",
  "HAMZA",
  "HAMZE",
  "HAMZI",
  "HAMZO",
  "HAMZU",
  "HANAD",
  "HANAH",
  "HANAK",
  "HANAM",
  "HANAN",
  "HANCA",
  "HANCE",
  "HANCI",
  "HANCO",
  "HANCU",
  "HANČA",
  "HANČE",
  "HANČI",
  "HANČU",
  "HANDA",
  "HANDE",
  "HANDI",
  "HANDL",
  "HANDS",
  "HANDU",
  "HANED",
  "HANEN",
  "HANES",
  "HANFA",
  "HANFE",
  "HANFI",
  "HANFO",
  "HANFT",
  "HANGA",
  "HANGE",
  "HANGI",
  "HANGU",
  "HANIC",
  "HANIF",
  "HANIG",
  "HANIH",
  "HANIJ",
  "HANIK",
  "HANIN",
  "HANIS",
  "HANJA",
  "HANJU",
  "HANKA",
  "HANKE",
  "HANKI",
  "HANKO",
  "HANKS",
  "HANKU",
  "HANNA",
  "HANNE",
  "HANNI",
  "HANNO",
  "HANNS",
  "HANNU",
  "HANOI",
  "HANOJ",
  "HANOM",
  "HANOV",
  "HANSA",
  "HANSE",
  "HANSI",
  "HANSL",
  "HANSO",
  "HANST",
  "HANSU",
  "HANTA",
  "HANTE",
  "HANTI",
  "HANTU",
  "HANTZ",
  "HANUN",
  "HANUŠ",
  "HANZI",
  "HANZL",
  "HAPAČ",
  "HAPAG",
  "HAPKA",
  "HAPKE",
  "HAPKI",
  "HAPKO",
  "HAPKU",
  "HAPPA",
  "HAPPE",
  "HAPPI",
  "HAPPU",
  "HARAČ",
  "HARAD",
  "HARAJ",
  "HARAL",
  "HARAM",
  "HARAN",
  "HARAŠ",
  "HARAT",
  "HARAU",
  "HARAV",
  "HARBA",
  "HARBE",
  "HARBI",
  "HARBU",
  "HARCA",
  "HARCE",
  "HARCI",
  "HARCU",
  "HARDA",
  "HARDE",
  "HARDI",
  "HARDS",
  "HARDT",
  "HARDU",
  "HAREJ",
  "HAREM",
  "HARFA",
  "HARFE",
  "HARFI",
  "HARFO",
  "HARIH",
  "HARIJ",
  "HARIS",
  "HARIT",
  "HARJA",
  "HARJE",
  "HARJU",
  "HARLA",
  "HARLE",
  "HARLI",
  "HARLU",
  "HARMS",
  "HAROM",
  "HARON",
  "HAROV",
  "HARPA",
  "HARPE",
  "HARPI",
  "HARPO",
  "HARPU",
  "HARRA",
  "HARRE",
  "HARRI",
  "HARRO",
  "HARRU",
  "HARTA",
  "HARTE",
  "HARTH",
  "HARTI",
  "HARTL",
  "HARTS",
  "HARTT",
  "HARTU",
  "HARTZ",
  "HARUA",
  "HARUE",
  "HARUI",
  "HARUK",
  "HARUM",
  "HARUN",
  "HARUO",
  "HARUU",
  "HARZA",
  "HARZE",
  "HARZI",
  "HARZO",
  "HASAF",
  "HASAJ",
  "HASAK",
  "HASAN",
  "HASEK",
  "HASIB",
  "HASIČ",
  "HASIH",
  "HASIJ",
  "HASIM",
  "HASJA",
  "HASJU",
  "HASKA",
  "HASKE",
  "HASKI",
  "HASKU",
  "HASLA",
  "HASLE",
  "HASLI",
  "HASLU",
  "HASNE",
  "HASNI",
  "HASNU",
  "HASOM",
  "HASOV",
  "HASPL",
  "HASSA",
  "HASSE",
  "HASSI",
  "HASSO",
  "HASSU",
  "HASUN",
  "HAŠEK",
  "HAŠIČ",
  "HAŠIM",
  "HAŠIŠ",
  "HAŠKA",
  "HAŠKE",
  "HAŠKI",
  "HAŠKU",
  "HATCH",
  "HATIJ",
  "HATIK",
  "HATIM",
  "HATOR",
  "HATTA",
  "HATTE",
  "HATTI",
  "HATTU",
  "HATZA",
  "HATZE",
  "HATZI",
  "HATZL",
  "HATZU",
  "HAUBA",
  "HAUBE",
  "HAUBI",
  "HAUBU",
  "HAUCA",
  "HAUCE",
  "HAUCI",
  "HAUCK",
  "HAUCU",
  "HAUER",
  "HAUFA",
  "HAUFE",
  "HAUFF",
  "HAUFI",
  "HAUFU",
  "HAUGA",
  "HAUGE",
  "HAUGG",
  "HAUGI",
  "HAUGK",
  "HAUGU",
  "HAUIH",
  "HAUJA",
  "HAUJU",
  "HAUKA",
  "HAUKE",
  "HAUKI",
  "HAUKO",
  "HAUKU",
  "HAULA",
  "HAULE",
  "HAULI",
  "HAULK",
  "HAULO",
  "HAULU",
  "HAUNA",
  "HAUNE",
  "HAUNI",
  "HAUNU",
  "HAUOM",
  "HAUOV",
  "HAUPT",
  "HAUSA",
  "HAUSE",
  "HAUSI",
  "HAUSS",
  "HAUSU",
  "HAUTA",
  "HAUTE",
  "HAUTH",
  "HAUTI",
  "HAUTU",
  "HAVAH",
  "HAVAM",
  "HAVBA",
  "HAVBE",
  "HAVBI",
  "HAVBO",
  "HAVEL",
  "HAVIN",
  "HAVLA",
  "HAVLE",
  "HAVLI",
  "HAVLU",
  "HAVOK",
  "HAVRA",
  "HAVRE",
  "HAVRU",
  "HAVSA",
  "HAVSE",
  "HAVSI",
  "HAVSU",
  "HAZAL",
  "HAZAN",
  "HAZEL",
  "HAZEN",
  "HAZIH",
  "HAZIR",
  "HAZIZ",
  "HAZJA",
  "HAZJU",
  "HAZLA",
  "HAZLE",
  "HAZLI",
  "HAZLU",
  "HAZOM",
  "HAZOV",
  "HBINT",
  "HČERA",
  "HČERE",
  "HČERI",
  "HČERK",
  "HČERO",
  "HEADA",
  "HEADE",
  "HEADI",
  "HEADU",
  "HEAFA",
  "HEAFE",
  "HEAFI",
  "HEAFU",
  "HEALD",
  "HEAPA",
  "HEAPE",
  "HEAPI",
  "HEAPS",
  "HEAPU",
  "HEARD",
  "HEARN",
  "HEART",
  "HEATA",
  "HEATH",
  "HEATU",
  "HEBAH",
  "HEBAM",
  "HEBAR",
  "HEBBA",
  "HEBBE",
  "HEBBI",
  "HEBBU",
  "HEBDO",
  "HEBER",
  "HEBIN",
  "HEBRA",
  "HEBRE",
  "HEBRI",
  "HEBRU",
  "HECAJ",
  "HECAL",
  "HECAM",
  "HECAŠ",
  "HECAT",
  "HECAU",
  "HECAV",
  "HECEM",
  "HECEN",
  "HECEV",
  "HECHA",
  "HECHE",
  "HECHI",
  "HECHT",
  "HECHU",
  "HECIH",
  "HECJA",
  "HECJU",
  "HECKA",
  "HECKE",
  "HECKI",
  "HECKU",
  "HECLA",
  "HECLE",
  "HECLI",
  "HECLU",
  "HECNA",
  "HECNE",
  "HECNI",
  "HECNO",
  "HECOM",
  "HECOV",
  "HEDDA",
  "HEDDE",
  "HEDDI",
  "HEDDO",
  "HEDGA",
  "HEDGE",
  "HEDIH",
  "HEDIN",
  "HEDJA",
  "HEDJU",
  "HEDLA",
  "HEDLE",
  "HEDLI",
  "HEDLU",
  "HEDOM",
  "HEDOV",
  "HEEGA",
  "HEEGE",
  "HEEGI",
  "HEEGU",
  "HEEJA",
  "HEEJE",
  "HEEJI",
  "HEEJU",
  "HEELS",
  "HEENA",
  "HEENE",
  "HEENI",
  "HEENU",
  "HEEPA",
  "HEEPE",
  "HEEPI",
  "HEEPU",
  "HEERE",
  "HEESA",
  "HEESE",
  "HEESI",
  "HEESU",
  "HEFIN",
  "HEFTA",
  "HEFTI",
  "HEFTU",
  "HEGAN",
  "HEGAR",
  "HEGEL",
  "HEGER",
  "HEGGA",
  "HEGGE",
  "HEGGI",
  "HEGGS",
  "HEGGU",
  "HEGHA",
  "HEGHE",
  "HEGHI",
  "HEGHU",
  "HEGLA",
  "HEGLE",
  "HEGLI",
  "HEGLU",
  "HEHET",
  "HEHIR",
  "HEHNA",
  "HEHNE",
  "HEHNI",
  "HEHNU",
  "HEIBA",
  "HEIBE",
  "HEIBI",
  "HEIBU",
  "HEICK",
  "HEIDA",
  "HEIDE",
  "HEIDI",
  "HEIDL",
  "HEIDO",
  "HEIDT",
  "HEIGL",
  "HEIJN",
  "HEIKA",
  "HEIKE",
  "HEIKI",
  "HEIKO",
  "HEIKS",
  "HEIKU",
  "HEILA",
  "HEILE",
  "HEILI",
  "HEILU",
  "HEIMA",
  "HEIME",
  "HEIMI",
  "HEIMO",
  "HEIMU",
  "HEINA",
  "HEINE",
  "HEINI",
  "HEINL",
  "HEINO",
  "HEINS",
  "HEINU",
  "HEINZ",
  "HEISE",
  "HEISS",
  "HEIST",
  "HEITA",
  "HEITE",
  "HEITI",
  "HEITU",
  "HEITZ",
  "HEJEM",
  "HEJEV",
  "HEJIH",
  "HEJJA",
  "HEJJU",
  "HEJLA",
  "HEJLE",
  "HEJLI",
  "HEJLU",
  "HEJOM",
  "HEJOV",
  "HEKAB",
  "HEKAJ",
  "HEKAL",
  "HEKAM",
  "HEKAŠ",
  "HEKAT",
  "HEKAU",
  "HEKAV",
  "HEKEM",
  "HEKER",
  "HEKEV",
  "HEKIČ",
  "HEKIH",
  "HEKJA",
  "HEKJU",
  "HEKLA",
  "HEKLE",
  "HEKLI",
  "HEKLO",
  "HEKOM",
  "HEKOV",
  "HELAN",
  "HELBL",
  "HELCA",
  "HELCE",
  "HELCI",
  "HELCU",
  "HELDA",
  "HELDE",
  "HELDI",
  "HELDT",
  "HELDU",
  "HELEN",
  "HELGA",
  "HELGE",
  "HELGI",
  "HELGO",
  "HELIJ",
  "HELIN",
  "HELKA",
  "HELKE",
  "HELKI",
  "HELKO",
  "HELLA",
  "HELLE",
  "HELLI",
  "HELLO",
  "HELLU",
  "HELMA",
  "HELME",
  "HELMI",
  "HELMO",
  "HELMS",
  "HELMU",
  "HELON",
  "HELOT",
  "HELTA",
  "HELTE",
  "HELTI",
  "HELTU",
  "HEMAH",
  "HEMAM",
  "HEMEM",
  "HEMIN",
  "HEMJA",
  "HEMJU",
  "HEMMA",
  "HEMME",
  "HEMMI",
  "HEMMO",
  "HEMOM",
  "HEMON",
  "HEMOZ",
  "HEMPA",
  "HEMPE",
  "HEMPI",
  "HEMPT",
  "HEMPU",
  "HENAA",
  "HENAE",
  "HENAI",
  "HENAN",
  "HENAO",
  "HENAU",
  "HENCH",
  "HENCK",
  "HENCL",
  "HENDL",
  "HENGA",
  "HENGE",
  "HENGI",
  "HENGL",
  "HENGU",
  "HENIE",
  "HENIG",
  "HENIN",
  "HENKA",
  "HENKE",
  "HENKI",
  "HENKU",
  "HENLE",
  "HENNA",
  "HENNE",
  "HENNI",
  "HENNU",
  "HENOK",
  "HENRI",
  "HENSA",
  "HENSE",
  "HENSI",
  "HENSU",
  "HENZA",
  "HENZE",
  "HENZI",
  "HENZU",
  "HEPER",
  "HEPPA",
  "HEPPE",
  "HEPPI",
  "HEPPU",
  "HERAD",
  "HERAH",
  "HERAK",
  "HERAM",
  "HERAS",
  "HERAT",
  "HERBA",
  "HERBE",
  "HERBI",
  "HERBO",
  "HERBU",
  "HERCA",
  "HERCE",
  "HERCI",
  "HERCU",
  "HERDA",
  "HERDE",
  "HERDI",
  "HERDT",
  "HERDU",
  "HEREG",
  "HEREK",
  "HERER",
  "HERFF",
  "HERGA",
  "HERGE",
  "HERGI",
  "HERGO",
  "HERGT",
  "HERGU",
  "HERIC",
  "HERIČ",
  "HERIN",
  "HERKA",
  "HERKE",
  "HERKI",
  "HERKU",
  "HERMA",
  "HERME",
  "HERMI",
  "HERMO",
  "HERMS",
  "HERNA",
  "HERNE",
  "HERNI",
  "HERNU",
  "HEROD",
  "HEROJ",
  "HERON",
  "HERRA",
  "HERRE",
  "HERRI",
  "HERRO",
  "HERRU",
  "HERSH",
  "HERST",
  "HERTA",
  "HERTE",
  "HERTH",
  "HERTI",
  "HERTL",
  "HERTO",
  "HERTZ",
  "HERUN",
  "HERVE",
  "HERZA",
  "HERZE",
  "HERZI",
  "HERZL",
  "HERZU",
  "HESEM",
  "HESET",
  "HESEV",
  "HESIH",
  "HESJA",
  "HESJU",
  "HESMA",
  "HESME",
  "HESMI",
  "HESMU",
  "HESOM",
  "HESOV",
  "HESSA",
  "HESSE",
  "HESSI",
  "HESSU",
  "HESTA",
  "HESTE",
  "HESTI",
  "HESTU",
  "HEŠKA",
  "HEŠKE",
  "HEŠKI",
  "HEŠKO",
  "HEŠKU",
  "HETEM",
  "HETER",
  "HETIN",
  "HETIT",
  "HETTA",
  "HETTE",
  "HETTI",
  "HETTU",
  "HEUER",
  "HEUNA",
  "HEUNE",
  "HEUNG",
  "HEUNI",
  "HEUNU",
  "HEUPA",
  "HEUPE",
  "HEUPI",
  "HEUPU",
  "HEUSS",
  "HEVIR",
  "HEVIZ",
  "HEVKA",
  "HEVKE",
  "HEVKI",
  "HEVKO",
  "HEVKU",
  "HEVZI",
  "HIATA",
  "HIATE",
  "HIATI",
  "HIATT",
  "HIATU",
  "HIBAH",
  "HIBAM",
  "HIBAV",
  "HIBBS",
  "HIBEN",
  "HIBIC",
  "HIBLA",
  "HIBLE",
  "HIBLI",
  "HIBLU",
  "HIBNA",
  "HIBNE",
  "HIBNI",
  "HIBNO",
  "HIBON",
  "HICEA",
  "HICEE",
  "HICEI",
  "HICEU",
  "HICKA",
  "HICKE",
  "HICKI",
  "HICKL",
  "HICKS",
  "HICKU",
  "HIDAK",
  "HIDAS",
  "HIDEA",
  "HIDEE",
  "HIDEI",
  "HIDEJ",
  "HIDEO",
  "HIDER",
  "HIDIČ",
  "HIDIH",
  "HIDJA",
  "HIDJU",
  "HIDOM",
  "HIDOV",
  "HIDRA",
  "HIDRE",
  "HIDRI",
  "HIDRO",
  "HIDRU",
  "HIEJA",
  "HIEJE",
  "HIEJI",
  "HIEJU",
  "HIELD",
  "HIENA",
  "HIENE",
  "HIENG",
  "HIENI",
  "HIENU",
  "HIERL",
  "HIFAH",
  "HIFAM",
  "HIFEM",
  "HIGGS",
  "HIGHA",
  "HIGHE",
  "HIGHI",
  "HIGHT",
  "HIGHU",
  "HIGLA",
  "HIGLE",
  "HIGLI",
  "HIGLU",
  "HIHOT",
  "HIJEM",
  "HIJEN",
  "HIJEV",
  "HIJIH",
  "HIJJA",
  "HIJJU",
  "HIJOM",
  "HIJOS",
  "HIJOV",
  "HILAH",
  "HILAL",
  "HILAM",
  "HILBA",
  "HILBE",
  "HILBI",
  "HILBU",
  "HILDA",
  "HILDE",
  "HILDI",
  "HILDO",
  "HILDT",
  "HILIH",
  "HILJA",
  "HILJE",
  "HILJI",
  "HILJU",
  "HILLA",
  "HILLE",
  "HILLI",
  "HILLS",
  "HILLU",
  "HILMI",
  "HILOM",
  "HILOV",
  "HILSE",
  "HILTI",
  "HILUS",
  "HIMAH",
  "HIMAM",
  "HIMEN",
  "HIMER",
  "HIMES",
  "HIMIH",
  "HIMJA",
  "HIMJU",
  "HIMKI",
  "HIMNA",
  "HIMNE",
  "HIMNI",
  "HIMNO",
  "HIMOM",
  "HIMOV",
  "HIMST",
  "HINAM",
  "HINDI",
  "HINDS",
  "HINES",
  "HINGA",
  "HINGE",
  "HINGI",
  "HINGU",
  "HINIH",
  "HINIJ",
  "HININ",
  "HINJA",
  "HINJE",
  "HINJK",
  "HINJU",
  "HINKA",
  "HINKE",
  "HINKI",
  "HINKO",
  "HINKS",
  "HINKU",
  "HINNA",
  "HINNE",
  "HINNI",
  "HINNU",
  "HINOM",
  "HINOV",
  "HINTZ",
  "HINZA",
  "HINZE",
  "HINZI",
  "HINZU",
  "HIOBA",
  "HIOBE",
  "HIOBI",
  "HIOBU",
  "HIONA",
  "HIONE",
  "HIONI",
  "HIONO",
  "HIOSA",
  "HIOSE",
  "HIOSI",
  "HIOSU",
  "HIPCA",
  "HIPCE",
  "HIPCI",
  "HIPCU",
  "HIPEC",
  "HIPEM",
  "HIPEN",
  "HIPEV",
  "HIPIH",
  "HIPJA",
  "HIPJU",
  "HIPNA",
  "HIPNE",
  "HIPNI",
  "HIPNO",
  "HIPOM",
  "HIPON",
  "HIPOV",
  "HIPPA",
  "HIPPE",
  "HIPPI",
  "HIPPS",
  "HIPPU",
  "HIPUS",
  "HIRAH",
  "HIRAJ",
  "HIRAL",
  "HIRAM",
  "HIRAŠ",
  "HIRAT",
  "HIRAU",
  "HIRAV",
  "HIRCI",
  "HIRDA",
  "HIRDE",
  "HIRDI",
  "HIRDU",
  "HIRIH",
  "HIRIN",
  "HIRJA",
  "HIRJU",
  "HIROM",
  "HIRON",
  "HIROV",
  "HIRSH",
  "HIRST",
  "HIRŠL",
  "HIRTA",
  "HIRTE",
  "HIRTH",
  "HIRTI",
  "HIRTU",
  "HISEN",
  "HISIH",
  "HISJA",
  "HISJU",
  "HISKU",
  "HISNA",
  "HISNE",
  "HISNI",
  "HISNU",
  "HISOM",
  "HISOV",
  "HISSA",
  "HISSE",
  "HISSI",
  "HISSU",
  "HIŠAH",
  "HIŠAM",
  "HIŠEN",
  "HIŠIC",
  "HIŠKA",
  "HIŠKE",
  "HIŠKI",
  "HIŠKO",
  "HIŠNA",
  "HIŠNE",
  "HIŠNI",
  "HIŠNO",
  "HIŠUR",
  "HITAH",
  "HITAM",
  "HITEČ",
  "HITEL",
  "HITEM",
  "HITER",
  "HITET",
  "HITEU",
  "HITEV",
  "HITIH",
  "HITIJ",
  "HITIM",
  "HITIN",
  "HITIŠ",
  "HITJA",
  "HITJU",
  "HITOM",
  "HITON",
  "HITOV",
  "HITRA",
  "HITRE",
  "HITRI",
  "HITRO",
  "HITTA",
  "HITTE",
  "HITTI",
  "HITTU",
  "HITZA",
  "HITZE",
  "HITZI",
  "HITZU",
  "HIVDA",
  "HIVDE",
  "HIVDI",
  "HIVDO",
  "HIVEM",
  "HIVJA",
  "HIVJU",
  "HIVOM",
  "HIVON",
  "HIVZI",
  "HIZLI",
  "HIZON",
  "HIŽAR",
  "HJELM",
  "HJELT",
  "HJORT",
  "HLAČA",
  "HLAČE",
  "HLAČI",
  "HLAČK",
  "HLAČO",
  "HLAČU",
  "HLADA",
  "HLADE",
  "HLADI",
  "HLADU",
  "HLAJA",
  "HLAJE",
  "HLAJI",
  "HLAJU",
  "HLAPA",
  "HLAPC",
  "HLAPE",
  "HLAPI",
  "HLAPU",
  "HLAVK",
  "HLEBA",
  "HLEBC",
  "HLEBE",
  "HLEBI",
  "HLEBŠ",
  "HLEBU",
  "HLEDE",
  "HLEPE",
  "HLEPI",
  "HLEPU",
  "HLEVA",
  "HLEVE",
  "HLEVI",
  "HLEVU",
  "HLINI",
  "HLINK",
  "HLINU",
  "HLIPA",
  "HLIPI",
  "HLIPU",
  "HLIŠA",
  "HLIŠČ",
  "HLIŠE",
  "HLIŠI",
  "HLIŠU",
  "HLODA",
  "HLODC",
  "HLODE",
  "HLODI",
  "HLODU",
  "HLOND",
  "HMALU",
  "HMELJ",
  "HMONG",
  "HOAGA",
  "HOAGE",
  "HOAGI",
  "HOAGU",
  "HOAKA",
  "HOAKE",
  "HOAKI",
  "HOAKU",
  "HOANA",
  "HOANE",
  "HOANI",
  "HOANO",
  "HOARA",
  "HOARD",
  "HOARE",
  "HOARI",
  "HOARO",
  "HOATH",
  "HOBAN",
  "HOBBI",
  "HOBBS",
  "HOBEL",
  "HOBIČ",
  "HOBIJ",
  "HOBIN",
  "HOBIT",
  "HOBLA",
  "HOBLE",
  "HOBLI",
  "HOBLU",
  "HOBOK",
  "HOBOM",
  "HOBOR",
  "HOCHA",
  "HOCHE",
  "HOCHI",
  "HOCHU",
  "HOCKA",
  "HOCKE",
  "HOCKI",
  "HOCKU",
  "HOČAH",
  "HOČAM",
  "HOČAN",
  "HOČEM",
  "HOČEŠ",
  "HOČKO",
  "HODAH",
  "HODAJ",
  "HODAK",
  "HODAM",
  "HODCA",
  "HODCE",
  "HODCI",
  "HODCU",
  "HODEC",
  "HODEČ",
  "HODEJ",
  "HODEL",
  "HODEM",
  "HODEN",
  "HODES",
  "HODEV",
  "HODGA",
  "HODGE",
  "HODIH",
  "HODIL",
  "HODIM",
  "HODIN",
  "HODIŠ",
  "HODIT",
  "HODIU",
  "HODIV",
  "HODJA",
  "HODJU",
  "HODLA",
  "HODLE",
  "HODLI",
  "HODLU",
  "HODNA",
  "HODNE",
  "HODNI",
  "HODNO",
  "HODOM",
  "HODOR",
  "HODOŠ",
  "HODOV",
  "HODŽA",
  "HODŽE",
  "HODŽI",
  "HODŽO",
  "HODŽU",
  "HOEDL",
  "HOEDT",
  "HOEFA",
  "HOEFE",
  "HOEFI",
  "HOEFL",
  "HOEFT",
  "HOEFU",
  "HOEHN",
  "HOEKA",
  "HOEKE",
  "HOEKI",
  "HOEKU",
  "HOELA",
  "HOELE",
  "HOELI",
  "HOELL",
  "HOELU",
  "HOENA",
  "HOENE",
  "HOENI",
  "HOENN",
  "HOENU",
  "HOERL",
  "HOERR",
  "HOESA",
  "HOESE",
  "HOESI",
  "HOESL",
  "HOESS",
  "HOESU",
  "HOFER",
  "HOFFA",
  "HOFFE",
  "HOFFI",
  "HOFFO",
  "HOFFS",
  "HOFFU",
  "HOFIH",
  "HOFJA",
  "HOFJU",
  "HOFOM",
  "HOFOV",
  "HOGAN",
  "HOGER",
  "HOGGA",
  "HOGGE",
  "HOGGI",
  "HOGGU",
  "HOGUE",
  "HOHLA",
  "HOHLE",
  "HOHLI",
  "HOHLU",
  "HOHNA",
  "HOHNE",
  "HOHNI",
  "HOHNU",
  "HOIJO",
  "HOINA",
  "HOINE",
  "HOINI",
  "HOINU",
  "HOJAH",
  "HOJAK",
  "HOJAM",
  "HOJAN",
  "HOJCA",
  "HOJCE",
  "HOJCI",
  "HOJCO",
  "HOJCU",
  "HOJČA",
  "HOJČE",
  "HOJČI",
  "HOJČU",
  "HOJEV",
  "HOJIC",
  "HOJIN",
  "HOJKA",
  "HOJKE",
  "HOJKI",
  "HOJKO",
  "HOJLA",
  "HOJOV",
  "HOJSA",
  "HOJSE",
  "HOJSI",
  "HOJSU",
  "HOKEJ",
  "HOKIN",
  "HOLAN",
  "HOLCA",
  "HOLCE",
  "HOLCH",
  "HOLCI",
  "HOLCK",
  "HOLCU",
  "HOLDA",
  "HOLDE",
  "HOLDI",
  "HOLDT",
  "HOLDU",
  "HOLEC",
  "HOLEN",
  "HOLER",
  "HOLES",
  "HOLEŠ",
  "HOLIH",
  "HOLIK",
  "HOLIN",
  "HOLJA",
  "HOLJU",
  "HOLLA",
  "HOLLE",
  "HOLLI",
  "HOLLO",
  "HOLLU",
  "HOLMA",
  "HOLMC",
  "HOLME",
  "HOLMI",
  "HOLMS",
  "HOLMU",
  "HOLNA",
  "HOLNE",
  "HOLNI",
  "HOLNO",
  "HOLOM",
  "HOLOV",
  "HOLST",
  "HOLTA",
  "HOLTE",
  "HOLTI",
  "HOLTU",
  "HOLTZ",
  "HOLUB",
  "HOLZA",
  "HOLZE",
  "HOLZI",
  "HOLZU",
  "HOMAČ",
  "HOMAN",
  "HOMAR",
  "HOMCA",
  "HOMCU",
  "HOMEC",
  "HOMER",
  "HOMIČ",
  "HOMIH",
  "HOMJA",
  "HOMJU",
  "HOMMA",
  "HOMME",
  "HOMMI",
  "HOMMU",
  "HOMOL",
  "HOMOM",
  "HOMOV",
  "HOMSA",
  "HOMSU",
  "HONAJ",
  "HONAL",
  "HONAM",
  "HONAN",
  "HONAŠ",
  "HONAT",
  "HONAU",
  "HONAV",
  "HONDA",
  "HONDE",
  "HONDI",
  "HONDO",
  "HONDU",
  "HONGA",
  "HONGE",
  "HONGI",
  "HONGU",
  "HONIG",
  "HONKA",
  "HONKE",
  "HONKI",
  "HONKO",
  "HONKU",
  "HONOR",
  "HONTS",
  "HOODA",
  "HOODE",
  "HOODI",
  "HOODU",
  "HOOFT",
  "HOOKA",
  "HOOKE",
  "HOOKI",
  "HOOKS",
  "HOOKU",
  "HOOLA",
  "HOOLE",
  "HOOLI",
  "HOOLU",
  "HOONA",
  "HOONE",
  "HOONI",
  "HOONU",
  "HOOPA",
  "HOOPE",
  "HOOPI",
  "HOOPS",
  "HOOPU",
  "HOORA",
  "HOORE",
  "HOORI",
  "HOORN",
  "HOORU",
  "HOOSA",
  "HOOSE",
  "HOOSI",
  "HOOSU",
  "HOPAK",
  "HOPFA",
  "HOPFE",
  "HOPFI",
  "HOPFU",
  "HOPIH",
  "HOPIN",
  "HOPJA",
  "HOPJU",
  "HOPKA",
  "HOPKE",
  "HOPKI",
  "HOPKO",
  "HOPKU",
  "HOPLA",
  "HOPOM",
  "HOPOV",
  "HOPPA",
  "HOPPE",
  "HOPPI",
  "HOPPS",
  "HOPPU",
  "HOPSA",
  "HOPSE",
  "HOPSI",
  "HOPSU",
  "HORAC",
  "HORAH",
  "HORAK",
  "HORAL",
  "HORAM",
  "HORAN",
  "HORAT",
  "HORDA",
  "HORDE",
  "HORDI",
  "HORDO",
  "HORIE",
  "HORIH",
  "HORIJ",
  "HORJA",
  "HORJU",
  "HORKA",
  "HORKE",
  "HORKI",
  "HORKO",
  "HORKU",
  "HORNA",
  "HORNE",
  "HORNG",
  "HORNI",
  "HORNU",
  "HOROM",
  "HOROV",
  "HORSA",
  "HORSE",
  "HORSI",
  "HORST",
  "HORSU",
  "HORTA",
  "HORTE",
  "HORTI",
  "HORTO",
  "HORTU",
  "HORUK",
  "HOSCH",
  "HOSEK",
  "HOSER",
  "HOSIE",
  "HOSKA",
  "HOSKE",
  "HOSKI",
  "HOSKU",
  "HOSNI",
  "HOSPA",
  "HOSPE",
  "HOSPI",
  "HOSPU",
  "HOSSA",
  "HOSSE",
  "HOSSI",
  "HOSSU",
  "HOSTA",
  "HOSTE",
  "HOSTI",
  "HOSTO",
  "HOSTU",
  "HOŠEM",
  "HOŠEV",
  "HOŠIH",
  "HOŠJA",
  "HOŠJU",
  "HOŠKA",
  "HOŠKE",
  "HOŠKI",
  "HOŠKO",
  "HOŠOM",
  "HOŠOV",
  "HOTEČ",
  "HOTEL",
  "HOTEN",
  "HOTHA",
  "HOTHE",
  "HOTHI",
  "HOTHU",
  "HOTIČ",
  "HOTIH",
  "HOTJA",
  "HOTJU",
  "HOTKA",
  "HOTKE",
  "HOTKI",
  "HOTKO",
  "HOTKU",
  "HOTNA",
  "HOTNE",
  "HOTNI",
  "HOTNO",
  "HOTOM",
  "HOTOV",
  "HOTTA",
  "HOTTE",
  "HOTTI",
  "HOTTU",
  "HOTZA",
  "HOTZE",
  "HOTZI",
  "HOTZU",
  "HOUCK",
  "HOUDE",
  "HOUGH",
  "HOUJA",
  "HOUJE",
  "HOUJI",
  "HOUJU",
  "HOUKA",
  "HOUKE",
  "HOUKI",
  "HOUKU",
  "HOULT",
  "HOUPT",
  "HOURI",
  "HOUSA",
  "HOUSE",
  "HOUSI",
  "HOUSU",
  "HOUŠK",
  "HOUTS",
  "HOUZE",
  "HOVEN",
  "HOVIG",
  "HOVIS",
  "HOVNA",
  "HOVNE",
  "HOVNI",
  "HOVNU",
  "HOZAK",
  "HOZAN",
  "HOZIH",
  "HOZJA",
  "HOZJU",
  "HOZOM",
  "HOZOV",
  "HOŽIČ",
  "HPOTA",
  "HRAMA",
  "HRAMB",
  "HRAMC",
  "HRAME",
  "HRAMI",
  "HRAMŠ",
  "HRAMU",
  "HRANA",
  "HRANE",
  "HRANI",
  "HRANJ",
  "HRANO",
  "HRANT",
  "HRANU",
  "HRAST",
  "HRAŠČ",
  "HRAŠE",
  "HRBET",
  "HRBOK",
  "HRBTA",
  "HRBTE",
  "HRBTI",
  "HRBTU",
  "HRČAK",
  "HRČAL",
  "HRČAT",
  "HRČAU",
  "HRČAV",
  "HRČEČ",
  "HRČEK",
  "HRČIC",
  "HRČIM",
  "HRČIŠ",
  "HRČJA",
  "HRČJE",
  "HRČJI",
  "HRČJO",
  "HRČKA",
  "HRČKE",
  "HRČKI",
  "HRČKU",
  "HRECA",
  "HRECI",
  "HRECU",
  "HRELJ",
  "HRENA",
  "HRENE",
  "HRENI",
  "HRENK",
  "HRENU",
  "HRESK",
  "HREŠČ",
  "HRGAH",
  "HRGAM",
  "HRGIH",
  "HRGJA",
  "HRGJU",
  "HRGOM",
  "HRGOT",
  "HRGOV",
  "HRIBA",
  "HRIBE",
  "HRIBI",
  "HRIBU",
  "HRIPA",
  "HRIPI",
  "HRIPU",
  "HRKAČ",
  "HRKAJ",
  "HRKAL",
  "HRKAM",
  "HRKAŠ",
  "HRKAT",
  "HRKAU",
  "HRKAV",
  "HRKNE",
  "HRKNI",
  "HRKNU",
  "HRMAN",
  "HRNEC",
  "HROCH",
  "HROMA",
  "HROMC",
  "HROME",
  "HROMI",
  "HROMO",
  "HROMU",
  "HRONA",
  "HRONE",
  "HRONI",
  "HRONU",
  "HROPC",
  "HROPE",
  "HROPI",
  "HROPU",
  "HROŠČ",
  "HRSIH",
  "HRSJA",
  "HRSJU",
  "HRSKA",
  "HRSKE",
  "HRSKI",
  "HRSKU",
  "HRSOM",
  "HRSOV",
  "HRSTA",
  "HRSTI",
  "HRSTU",
  "HRŠAK",
  "HRŠEM",
  "HRŠEV",
  "HRŠIH",
  "HRŠJA",
  "HRŠJU",
  "HRŠOM",
  "HRŠOV",
  "HRTEM",
  "HRTEV",
  "HRTIC",
  "HRTIH",
  "HRTIN",
  "HRTJA",
  "HRTJU",
  "HRTOM",
  "HRTOV",
  "HRULE",
  "HRULI",
  "HRULU",
  "HRUMA",
  "HRUME",
  "HRUMI",
  "HRUMU",
  "HRUPA",
  "HRUPE",
  "HRUPI",
  "HRUPU",
  "HRUST",
  "HRUŠČ",
  "HRUŠK",
  "HRUZA",
  "HRUZE",
  "HRUZI",
  "HRUZO",
  "HRUZU",
  "HRVAT",
  "HRVOJ",
  "HRZAJ",
  "HRZAL",
  "HRZAM",
  "HRZAŠ",
  "HRZAT",
  "HRZAU",
  "HRZAV",
  "HRZNE",
  "HRZNI",
  "HRZNU",
  "HRŽEM",
  "HRŽEŠ",
  "HRŽIC",
  "HRŽIČ",
  "HSIAA",
  "HSIAE",
  "HSIAI",
  "HSIAO",
  "HSIAU",
  "HSIEH",
  "HSING",
  "HSUJA",
  "HSUJE",
  "HSUJI",
  "HSUJU",
  "HTEIN",
  "HTTPS",
  "HUANG",
  "HUARD",
  "HUBAČ",
  "HUBAD",
  "HUBAH",
  "HUBAM",
  "HUBAT",
  "HUBBS",
  "HUBEI",
  "HUBEJ",
  "HUBEL",
  "HUBER",
  "HUBIK",
  "HUBLA",
  "HUBLE",
  "HUBLI",
  "HUBLU",
  "HUBRA",
  "HUBRE",
  "HUBRI",
  "HUBRU",
  "HUCEV",
  "HUCIK",
  "HUCKA",
  "HUCKE",
  "HUCKI",
  "HUCKU",
  "HUČEM",
  "HUČEV",
  "HUČIH",
  "HUČJA",
  "HUČJU",
  "HUČOM",
  "HUČOV",
  "HUDAČ",
  "HUDAH",
  "HUDAK",
  "HUDAL",
  "HUDDA",
  "HUDDE",
  "HUDDI",
  "HUDDU",
  "HUDEJ",
  "HUDEM",
  "HUDGA",
  "HUDIČ",
  "HUDIH",
  "HUDIK",
  "HUDIM",
  "HUDIN",
  "HUDIR",
  "HUDIS",
  "HUDLA",
  "HUDLE",
  "HUDLI",
  "HUDLU",
  "HUDOB",
  "HUDON",
  "HUDUJ",
  "HUECK",
  "HUEIH",
  "HUEJA",
  "HUEJU",
  "HUELL",
  "HUEOM",
  "HUEOV",
  "HUERT",
  "HUETA",
  "HUETE",
  "HUETI",
  "HUETT",
  "HUETU",
  "HUETZ",
  "HUFFA",
  "HUFFE",
  "HUFFI",
  "HUFFU",
  "HUGER",
  "HUGGA",
  "HUGGE",
  "HUGGI",
  "HUGGU",
  "HUGHA",
  "HUGHE",
  "HUGHI",
  "HUGHU",
  "HUGIH",
  "HUGJA",
  "HUGJU",
  "HUGOM",
  "HUGON",
  "HUGOV",
  "HUHNA",
  "HUHNE",
  "HUHNI",
  "HUHNU",
  "HUISH",
  "HUJAH",
  "HUJAM",
  "HUJAN",
  "HUJEM",
  "HUJEV",
  "HUJIH",
  "HUJJA",
  "HUJJU",
  "HUJOM",
  "HUJOV",
  "HUJSA",
  "HUJSE",
  "HUJSI",
  "HUJSU",
  "HUJŠA",
  "HUJŠE",
  "HUJŠI",
  "HUJŠO",
  "HUJŠU",
  "HUKAJ",
  "HUKAL",
  "HUKAM",
  "HUKAŠ",
  "HUKAT",
  "HUKAU",
  "HUKAV",
  "HUKIČ",
  "HUKNE",
  "HUKNI",
  "HUKNU",
  "HULAJ",
  "HULAK",
  "HULCA",
  "HULCE",
  "HULDA",
  "HULDE",
  "HULDI",
  "HULDO",
  "HULEČ",
  "HULEŽ",
  "HULIL",
  "HULIM",
  "HULIN",
  "HULIŠ",
  "HULIT",
  "HULIU",
  "HULIV",
  "HULJA",
  "HULJE",
  "HULJI",
  "HULJO",
  "HULJU",
  "HULKA",
  "HULKE",
  "HULKI",
  "HULKU",
  "HULLA",
  "HULLE",
  "HULLI",
  "HULLU",
  "HULMA",
  "HULME",
  "HULMI",
  "HULMU",
  "HULOK",
  "HULOT",
  "HULOV",
  "HULSA",
  "HULSE",
  "HULSI",
  "HULST",
  "HULSU",
  "HULTA",
  "HULTE",
  "HULTI",
  "HULTS",
  "HULTU",
  "HUMAH",
  "HUMAL",
  "HUMAM",
  "HUMAN",
  "HUMAR",
  "HUMEK",
  "HUMES",
  "HUMIH",
  "HUMIN",
  "HUMJA",
  "HUMJU",
  "HUMKA",
  "HUMKE",
  "HUMKI",
  "HUMKO",
  "HUMKU",
  "HUMLA",
  "HUMLE",
  "HUMLI",
  "HUMLU",
  "HUMMA",
  "HUMME",
  "HUMMI",
  "HUMMU",
  "HUMOM",
  "HUMOR",
  "HUMOV",
  "HUMPF",
  "HUMUS",
  "HUNAN",
  "HUNDA",
  "HUNDE",
  "HUNDI",
  "HUNDT",
  "HUNDU",
  "HUNGA",
  "HUNGE",
  "HUNGI",
  "HUNGU",
  "HUNIH",
  "HUNJA",
  "HUNJU",
  "HUNKA",
  "HUNKE",
  "HUNKI",
  "HUNKO",
  "HUNKU",
  "HUNOM",
  "HUNOV",
  "HUNTA",
  "HUNTE",
  "HUNTI",
  "HUNTO",
  "HUNTU",
  "HUPAH",
  "HUPAJ",
  "HUPAL",
  "HUPAM",
  "HUPAŠ",
  "HUPAT",
  "HUPAU",
  "HUPAV",
  "HUPKA",
  "HUPKE",
  "HUPKI",
  "HUPKO",
  "HUPKU",
  "HUPPA",
  "HUPPE",
  "HUPPI",
  "HUPPU",
  "HURCH",
  "HURDA",
  "HURDE",
  "HURDI",
  "HURDU",
  "HURIJ",
  "HURIN",
  "HURLA",
  "HURLE",
  "HURLI",
  "HURLU",
  "HURMA",
  "HURME",
  "HURMI",
  "HURMU",
  "HURNA",
  "HURNE",
  "HURNI",
  "HURNU",
  "HURON",
  "HURRA",
  "HURST",
  "HURTA",
  "HURTE",
  "HURTI",
  "HURTS",
  "HURTT",
  "HURTU",
  "HUSAJ",
  "HUSAM",
  "HUSAR",
  "HUSHI",
  "HUSIČ",
  "HUSIH",
  "HUSIK",
  "HUSIN",
  "HUSIT",
  "HUSJA",
  "HUSJU",
  "HUSKA",
  "HUSKE",
  "HUSKI",
  "HUSKO",
  "HUSKU",
  "HUSOM",
  "HUSON",
  "HUSOV",
  "HUSSA",
  "HUSSE",
  "HUSSI",
  "HUSSO",
  "HUSSU",
  "HUSZT",
  "HUŠEM",
  "HUŠEV",
  "HUŠIH",
  "HUŠJA",
  "HUŠJU",
  "HUŠKA",
  "HUŠKE",
  "HUŠKI",
  "HUŠKO",
  "HUŠKU",
  "HUŠNE",
  "HUŠNI",
  "HUŠNU",
  "HUŠOM",
  "HUŠOV",
  "HUTAR",
  "HUTHA",
  "HUTHE",
  "HUTHI",
  "HUTHU",
  "HUTIN",
  "HUTJA",
  "HUTJU",
  "HUTKA",
  "HUTKE",
  "HUTKI",
  "HUTKO",
  "HUTKU",
  "HUTOM",
  "HUTTA",
  "HUTTE",
  "HUTTI",
  "HUTTO",
  "HUTTU",
  "HUTZA",
  "HUTZE",
  "HUTZI",
  "HUTZU",
  "HUUBA",
  "HUUBE",
  "HUUBI",
  "HUUBU",
  "HUZAR",
  "HVALA",
  "HVALC",
  "HVALE",
  "HVALI",
  "HVALO",
  "HVALU",
  "HVAMA",
  "HVAME",
  "HVAMI",
  "HVAMU",
  "HVARA",
  "HVARU",
  "HVAUC",
  "HVIČA",
  "HVIČE",
  "HVIČI",
  "HVIČO",
  "HVIČU",
  "HVIDT",
  "HVILA",
  "HVILE",
  "HVILI",
  "HVILO",
  "HVILU",
  "IAEJA",
  "IAINA",
  "IAINE",
  "IAINI",
  "IAINU",
  "IANIH",
  "IANJA",
  "IANJU",
  "IANNI",
  "IANOM",
  "IANOV",
  "IANTA",
  "IANTE",
  "IANTH",
  "IANTI",
  "IANTO",
  "IANTU",
  "IAONA",
  "IAONE",
  "IAONI",
  "IAONU",
  "IBAKA",
  "IBAKE",
  "IBAKI",
  "IBAKO",
  "IBAKU",
  "IBARR",
  "IBERK",
  "IBERT",
  "IBIKA",
  "IBIKO",
  "IBIKU",
  "IBISA",
  "IBISE",
  "IBISI",
  "IBISU",
  "IBIZA",
  "IBIZE",
  "IBIZI",
  "IBIZO",
  "IBLER",
  "IBOMA",
  "IBOTA",
  "IBOTU",
  "IBOVA",
  "IBOVE",
  "IBOVI",
  "IBOVO",
  "IBRAJ",
  "IBRIH",
  "IBRJA",
  "IBRJU",
  "IBROM",
  "IBROV",
  "IBSEN",
  "IBSNA",
  "IBSNE",
  "IBSNI",
  "IBSNU",
  "IBUKI",
  "ICAHN",
  "ICANN",
  "ICEHL",
  "ICEIH",
  "ICEJA",
  "ICEJU",
  "ICEOM",
  "ICEOV",
  "ICHAK",
  "ICKES",
  "ICKIH",
  "ICKJA",
  "ICKJU",
  "ICKOM",
  "ICKOV",
  "ICTSI",
  "IČAMA",
  "IČAMI",
  "IČINA",
  "IČINE",
  "IČINI",
  "IČINO",
  "IDAHA",
  "IDAHO",
  "IDAHU",
  "IDAJA",
  "IDAJE",
  "IDAJI",
  "IDAJO",
  "IDAMA",
  "IDAMI",
  "IDANA",
  "IDANE",
  "IDANI",
  "IDANU",
  "IDDEJ",
  "IDDON",
  "IDEAL",
  "IDEAZ",
  "IDEJA",
  "IDEJE",
  "IDEJI",
  "IDEJO",
  "IDEMA",
  "IDEME",
  "IDEMI",
  "IDEMO",
  "IDEMU",
  "IDEUS",
  "IDHAH",
  "IDHAM",
  "IDHIN",
  "IDIJA",
  "IDIJE",
  "IDIJI",
  "IDIJU",
  "IDILA",
  "IDILE",
  "IDILI",
  "IDILO",
  "IDINA",
  "IDINE",
  "IDINI",
  "IDINO",
  "IDIOM",
  "IDIOT",
  "IDLIB",
  "IDLIH",
  "IDLJA",
  "IDLJU",
  "IDLOM",
  "IDLOV",
  "IDOIA",
  "IDOIE",
  "IDOII",
  "IDOIJ",
  "IDOIO",
  "IDOLA",
  "IDOLE",
  "IDOLI",
  "IDOLU",
  "IDOMA",
  "IDORA",
  "IDORE",
  "IDORI",
  "IDORO",
  "IDOTA",
  "IDOTU",
  "IDOVA",
  "IDOVE",
  "IDOVI",
  "IDOVO",
  "IDOZA",
  "IDOZE",
  "IDOZI",
  "IDOZO",
  "IDRCA",
  "IDRCE",
  "IDRCI",
  "IDRCU",
  "IDRIS",
  "IDRIZ",
  "IDRKA",
  "IDRKE",
  "IDRKI",
  "IDRKO",
  "IDUNA",
  "IDUNE",
  "IDUNI",
  "IDUNO",
  "IDZIG",
  "IEPER",
  "IEPRA",
  "IEPRU",
  "IERJA",
  "IERJE",
  "IERJI",
  "IERJU",
  "IERSE",
  "IEZZI",
  "IFANA",
  "IFANE",
  "IFANI",
  "IFANS",
  "IFANU",
  "IFILL",
  "IFITA",
  "IFITE",
  "IFITI",
  "IFITU",
  "IFKIH",
  "IFKJA",
  "IFKJU",
  "IFKOM",
  "IFKOV",
  "IFTAR",
  "IGALK",
  "IGAMA",
  "IGAMI",
  "IGBON",
  "IGELN",
  "IGERC",
  "IGGIE",
  "IGINA",
  "IGINE",
  "IGINI",
  "IGINO",
  "IGLAH",
  "IGLAJ",
  "IGLAL",
  "IGLAM",
  "IGLAN",
  "IGLAR",
  "IGLAŠ",
  "IGLAT",
  "IGLAU",
  "IGLAV",
  "IGLER",
  "IGLIC",
  "IGLIČ",
  "IGLJU",
  "IGLOO",
  "IGMAN",
  "IGMAT",
  "IGNAC",
  "IGNAS",
  "IGNAT",
  "IGNAZ",
  "IGOMA",
  "IGOME",
  "IGOMI",
  "IGOMO",
  "IGOTA",
  "IGOTU",
  "IGOVA",
  "IGOVE",
  "IGOVI",
  "IGOVK",
  "IGOVO",
  "IGRAČ",
  "IGRAH",
  "IGRAJ",
  "IGRAL",
  "IGRAM",
  "IGRAN",
  "IGRAŠ",
  "IGRAT",
  "IGRAU",
  "IGRAV",
  "IGREC",
  "IGRIC",
  "IGRIV",
  "IHANA",
  "IHANE",
  "IHANI",
  "IHANK",
  "IHANU",
  "IHNAT",
  "IHOVA",
  "IHOVE",
  "IHOVI",
  "IHOVO",
  "IHTAV",
  "IHTEČ",
  "IHTEL",
  "IHTET",
  "IHTEU",
  "IHTIM",
  "IHTIŠ",
  "IIRIH",
  "IIRJA",
  "IIRJU",
  "IIROM",
  "IIROV",
  "IIVIH",
  "IIVJA",
  "IIVJU",
  "IIVOM",
  "IIVOV",
  "IJOTU",
  "IKARA",
  "IKARE",
  "IKARI",
  "IKARU",
  "IKAST",
  "IKAVC",
  "IKAVK",
  "IKEDA",
  "IKEDE",
  "IKEDI",
  "IKEDO",
  "IKEDU",
  "IKEIN",
  "IKEJE",
  "IKEJI",
  "IKEJO",
  "IKIČA",
  "IKIČE",
  "IKIČI",
  "IKIČU",
  "IKINA",
  "IKINE",
  "IKING",
  "IKINI",
  "IKINU",
  "IKOMA",
  "IKONA",
  "IKONE",
  "IKONI",
  "IKONO",
  "IKOTA",
  "IKOTU",
  "IKOVA",
  "IKOVE",
  "IKOVI",
  "IKOVO",
  "IKRAH",
  "IKRAM",
  "IKRAV",
  "IKRIC",
  "IKRNA",
  "IKRNE",
  "IKRNI",
  "IKRNO",
  "IKSEM",
  "IKSEV",
  "IKSIH",
  "IKSJA",
  "IKSJU",
  "IKSOM",
  "IKSOV",
  "IKTEM",
  "IKTEV",
  "IKTIH",
  "IKTJA",
  "IKTJU",
  "IKTOM",
  "IKTOV",
  "IKTUS",
  "ILADS",
  "ILANA",
  "ILANE",
  "ILANI",
  "ILANO",
  "ILANU",
  "ILAŠA",
  "ILAŠE",
  "ILAŠI",
  "ILAŠU",
  "ILAZA",
  "ILAZE",
  "ILAZI",
  "ILAZU",
  "ILCEM",
  "ILCEV",
  "ILCIH",
  "ILCJA",
  "ILCJU",
  "ILCOM",
  "ILCOV",
  "ILČEV",
  "ILČOV",
  "ILDAH",
  "ILDAM",
  "ILDAR",
  "ILDIH",
  "ILDIN",
  "ILDJA",
  "ILDJU",
  "ILDOM",
  "ILDOV",
  "ILEAN",
  "ILEEN",
  "ILENE",
  "ILETT",
  "ILEUS",
  "ILFIH",
  "ILFJA",
  "ILFJU",
  "ILFOM",
  "ILFOV",
  "ILGAR",
  "ILGIH",
  "ILGJA",
  "ILGJU",
  "ILGOM",
  "ILGOV",
  "ILHAM",
  "ILHAN",
  "ILIAN",
  "ILIAS",
  "ILICH",
  "ILIČA",
  "ILIČE",
  "ILIČI",
  "ILIČU",
  "ILIEV",
  "ILIJA",
  "ILIJE",
  "ILIJI",
  "ILIJO",
  "ILIJU",
  "ILINA",
  "ILINC",
  "ILINE",
  "ILING",
  "ILINI",
  "ILINK",
  "ILINU",
  "ILIRA",
  "ILIRC",
  "ILIRE",
  "ILIRI",
  "ILIRK",
  "ILIRO",
  "ILIRU",
  "ILISH",
  "ILJAH",
  "ILJAM",
  "ILJAŠ",
  "ILJAZ",
  "ILJAŽ",
  "ILJEM",
  "ILJEŠ",
  "ILJEV",
  "ILJIČ",
  "ILJIH",
  "ILJIN",
  "ILJJA",
  "ILJJU",
  "ILJOM",
  "ILJOV",
  "ILKAH",
  "ILKAM",
  "ILKAN",
  "ILKIH",
  "ILKIN",
  "ILKJA",
  "ILKJU",
  "ILKKA",
  "ILKKE",
  "ILKKI",
  "ILKKO",
  "ILKKU",
  "ILKOM",
  "ILKOV",
  "ILLAH",
  "ILLAM",
  "ILLAN",
  "ILLEK",
  "ILLIG",
  "ILLIH",
  "ILLJA",
  "ILLJU",
  "ILLOM",
  "ILLON",
  "ILLOV",
  "ILMAR",
  "ILNAT",
  "ILNUR",
  "ILOKA",
  "ILOKU",
  "ILOMA",
  "ILONA",
  "ILONE",
  "ILONI",
  "ILONK",
  "ILONO",
  "ILORI",
  "ILOTA",
  "ILOTT",
  "ILOTU",
  "ILOVA",
  "ILOVE",
  "ILOVI",
  "ILOVK",
  "ILOVO",
  "ILSAH",
  "ILSAM",
  "ILSIN",
  "ILSKA",
  "ILSKE",
  "ILSKI",
  "ILTIS",
  "ILUZA",
  "ILUZE",
  "ILUZI",
  "ILUZU",
  "ILVES",
  "IMAGE",
  "IMAJO",
  "IMAMA",
  "IMAME",
  "IMAMI",
  "IMAMO",
  "IMAMU",
  "IMANA",
  "IMANE",
  "IMANI",
  "IMANU",
  "IMARI",
  "IMATA",
  "IMATE",
  "IMAVA",
  "IMAVE",
  "IMAVI",
  "IMBUS",
  "IMEJA",
  "IMEJE",
  "IMEJI",
  "IMEJU",
  "IMELA",
  "IMELD",
  "IMELE",
  "IMELI",
  "IMELO",
  "IMENA",
  "IMENI",
  "IMENJ",
  "IMENO",
  "IMENU",
  "IMERI",
  "IMETA",
  "IMETE",
  "IMETI",
  "IMETU",
  "IMHOF",
  "IMIDA",
  "IMIDE",
  "IMIDI",
  "IMIDU",
  "IMIDŽ",
  "IMINA",
  "IMINE",
  "IMINI",
  "IMINU",
  "IMKIN",
  "IMLEK",
  "IMMAH",
  "IMMAM",
  "IMMIE",
  "IMMIN",
  "IMOLA",
  "IMOLE",
  "IMOLI",
  "IMOLO",
  "IMOSA",
  "IMOSU",
  "IMOTA",
  "IMOTE",
  "IMOTI",
  "IMOTO",
  "IMPAL",
  "IMPOL",
  "IMPRO",
  "IMRAH",
  "IMRAM",
  "IMRAN",
  "IMRIE",
  "IMRIH",
  "IMRJA",
  "IMRJU",
  "IMROM",
  "IMROV",
  "IMSTA",
  "IMSTU",
  "IMUNA",
  "IMUNE",
  "IMUNI",
  "IMUNO",
  "IMUSA",
  "IMUSE",
  "IMUSI",
  "IMUSU",
  "INABA",
  "INABE",
  "INABI",
  "INABO",
  "INABU",
  "INAJA",
  "INAJE",
  "INAJI",
  "INAJO",
  "INAKI",
  "INALA",
  "INALE",
  "INALI",
  "INALU",
  "INAMA",
  "INAMI",
  "INANA",
  "INANE",
  "INANI",
  "INANN",
  "INANO",
  "INANU",
  "INARA",
  "INARE",
  "INARI",
  "INARO",
  "INBAL",
  "INCEA",
  "INCEE",
  "INCEI",
  "INCEL",
  "INCEU",
  "INCHA",
  "INCHE",
  "INCHI",
  "INCHU",
  "INCOM",
  "INČAH",
  "INČAM",
  "INČEM",
  "INČEV",
  "INČIH",
  "INČJA",
  "INČJU",
  "INČOM",
  "INČOV",
  "INČUN",
  "INDEN",
  "INDER",
  "INDIA",
  "INDIC",
  "INDIE",
  "INDII",
  "INDIJ",
  "INDIO",
  "INDIR",
  "INDIU",
  "INDJA",
  "INDJU",
  "INDOF",
  "INDOM",
  "INDOP",
  "INDRA",
  "INDRE",
  "INDRI",
  "INDRO",
  "INDRU",
  "INELA",
  "INELU",
  "INEOS",
  "INFOS",
  "INFRA",
  "INFRE",
  "INFRI",
  "INFRO",
  "INGAH",
  "INGAM",
  "INGAS",
  "INGEL",
  "INGER",
  "INGIH",
  "INGIN",
  "INGJA",
  "INGJU",
  "INGKA",
  "INGKE",
  "INGKI",
  "INGKO",
  "INGLA",
  "INGLE",
  "INGLI",
  "INGLU",
  "INGOM",
  "INGOT",
  "INGOV",
  "INGRA",
  "INGRE",
  "INGRI",
  "INGRO",
  "INGSA",
  "INGSE",
  "INGSI",
  "INGSU",
  "INGUŠ",
  "INHOF",
  "INIGA",
  "INIGE",
  "INIGI",
  "INIGO",
  "INIGU",
  "ININA",
  "ININE",
  "ININI",
  "ININO",
  "INJAC",
  "INJAH",
  "INJAM",
  "INJIN",
  "INKAH",
  "INKAM",
  "INKER",
  "INKIH",
  "INKIN",
  "INKJA",
  "INKJU",
  "INKOM",
  "INKOP",
  "INKOS",
  "INKOV",
  "INKSA",
  "INKSE",
  "INKSI",
  "INKSU",
  "INLES",
  "INLET",
  "INMAH",
  "INMAM",
  "INMAN",
  "INMIN",
  "INNAH",
  "INNAM",
  "INNAN",
  "INNES",
  "INNIN",
  "INNIS",
  "INNJA",
  "INNJU",
  "INNOM",
  "INOKS",
  "INOMA",
  "INOTA",
  "INOTU",
  "INOVA",
  "INOVE",
  "INOVI",
  "INOVO",
  "INPOS",
  "INPUT",
  "INSAM",
  "INSIN",
  "INSKA",
  "INSKE",
  "INSKI",
  "INSKO",
  "INSUL",
  "INTAG",
  "INTEL",
  "INTER",
  "INTIM",
  "INTRA",
  "INTUS",
  "INUIT",
  "INVAR",
  "INZKA",
  "INZKE",
  "INZKI",
  "INZKO",
  "INZKU",
  "IOANA",
  "IOANE",
  "IOANI",
  "IOANO",
  "IOANU",
  "IOLAH",
  "IOLAM",
  "IOLAR",
  "IOLIN",
  "IOMMI",
  "IONAH",
  "IONAM",
  "IONEM",
  "IONEV",
  "IONIH",
  "IONIN",
  "IONIS",
  "IONJA",
  "IONJU",
  "IONOM",
  "IONOV",
  "IONUT",
  "IORIA",
  "IORIE",
  "IORII",
  "IORIO",
  "IORIU",
  "IOSIF",
  "IOSUB",
  "IOTOM",
  "IOZZI",
  "IPADA",
  "IPADU",
  "IPKJA",
  "IPKJU",
  "IPKOM",
  "IPKOV",
  "IPMJA",
  "IPODA",
  "IPODE",
  "IPODI",
  "IPODU",
  "IPOGA",
  "IPOGE",
  "IPOGI",
  "IPOGO",
  "IPONG",
  "IPROM",
  "IPROS",
  "IPSIT",
  "IPSON",
  "IPSOS",
  "IPŠAH",
  "IPŠAM",
  "IPŠEK",
  "IPŠEM",
  "IPŠEV",
  "IPŠIH",
  "IPŠJA",
  "IPŠJU",
  "IPŠOM",
  "IPŠOV",
  "IRAKA",
  "IRAKU",
  "IRAMA",
  "IRAMI",
  "IRANA",
  "IRANI",
  "IRANK",
  "IRANU",
  "IRBIS",
  "IRCAJ",
  "IRCAL",
  "IRCAM",
  "IRCAŠ",
  "IRCAT",
  "IRCAU",
  "IRCAV",
  "IRCEM",
  "IRCEV",
  "IRCIH",
  "IRCJA",
  "IRCJU",
  "IRCOM",
  "IRCOV",
  "IRČAN",
  "IRČEV",
  "IRČOV",
  "IREET",
  "IRENA",
  "IRENC",
  "IRENE",
  "IRENI",
  "IRENK",
  "IRENO",
  "IRFAN",
  "IRFET",
  "IRGLA",
  "IRGLE",
  "IRGLI",
  "IRGLU",
  "IRHAD",
  "IRHAN",
  "IRHAR",
  "IRHEM",
  "IRHJA",
  "IRHJU",
  "IRHOM",
  "IRIBA",
  "IRIBE",
  "IRIBI",
  "IRIBU",
  "IRIDA",
  "IRIDE",
  "IRIDI",
  "IRIDO",
  "IRINA",
  "IRINE",
  "IRINI",
  "IRINO",
  "IRION",
  "IRISA",
  "IRISE",
  "IRISH",
  "IRISI",
  "IRISU",
  "IRJEM",
  "IRJIM",
  "IRKAH",
  "IRKAM",
  "IRKIN",
  "IRLEN",
  "IRMAH",
  "IRMAK",
  "IRMAM",
  "IRMAN",
  "IRMAS",
  "IRMIN",
  "IRNIN",
  "IROMA",
  "IRONA",
  "IRONE",
  "IRONI",
  "IRONS",
  "IRONU",
  "IROTA",
  "IROTU",
  "IROVA",
  "IROVE",
  "IROVI",
  "IROVO",
  "IRPIN",
  "IRRER",
  "IRSCH",
  "IRSKA",
  "IRSKE",
  "IRSKI",
  "IRSKM",
  "IRSKO",
  "IRSNZ",
  "IRSOP",
  "IRŠIČ",
  "IRŠKA",
  "IRŠKE",
  "IRŠKI",
  "IRŠKO",
  "IRTIH",
  "IRTIŠ",
  "IRTJA",
  "IRTJU",
  "IRTOM",
  "IRTOV",
  "IRUNA",
  "IRUNU",
  "IRVIN",
  "ISAAC",
  "ISAAK",
  "ISAKA",
  "ISAKE",
  "ISAKI",
  "ISAKU",
  "ISAMA",
  "ISAMI",
  "ISBIN",
  "ISCAR",
  "ISCHL",
  "ISENI",
  "ISERN",
  "ISERT",
  "ISETT",
  "ISHAG",
  "ISHAK",
  "ISHAM",
  "ISINA",
  "ISINE",
  "ISING",
  "ISINI",
  "ISINO",
  "ISITT",
  "ISKAH",
  "ISKAL",
  "ISKAM",
  "ISKAN",
  "ISKAT",
  "ISKAU",
  "ISKAV",
  "ISKER",
  "ISKIH",
  "ISKJA",
  "ISKJU",
  "ISKOM",
  "ISKON",
  "ISKOV",
  "ISKRA",
  "ISKRE",
  "ISKRI",
  "ISKRN",
  "ISKRO",
  "ISKRU",
  "ISLAH",
  "ISLAM",
  "ISLAS",
  "ISLER",
  "ISLIH",
  "ISLIN",
  "ISLJA",
  "ISLJU",
  "ISLOM",
  "ISLOV",
  "ISMEN",
  "ISMET",
  "ISNER",
  "ISNIH",
  "ISNJA",
  "ISNJU",
  "ISNOM",
  "ISNOV",
  "ISOLA",
  "ISOLD",
  "ISOLE",
  "ISOLI",
  "ISOLO",
  "ISOLU",
  "ISOMA",
  "ISONA",
  "ISONE",
  "ISONI",
  "ISONU",
  "ISOPA",
  "ISOPE",
  "ISOPI",
  "ISOPU",
  "ISOTA",
  "ISOTU",
  "ISOVA",
  "ISOVE",
  "ISOVI",
  "ISOVO",
  "ISPIT",
  "ISSAH",
  "ISSAM",
  "ISSIH",
  "ISSJA",
  "ISSJU",
  "ISSOM",
  "ISSOV",
  "ISTAT",
  "ISTEČ",
  "ISTEJ",
  "ISTEL",
  "ISTEM",
  "ISTEN",
  "ISTIH",
  "ISTIJ",
  "ISTIL",
  "ISTIM",
  "ISTIŠ",
  "ISTIT",
  "ISTIU",
  "ISTIV",
  "ISTJE",
  "ISTOK",
  "ISTOP",
  "ISTRA",
  "ISTRE",
  "ISTRI",
  "ISTRO",
  "ISUFA",
  "ISUFE",
  "ISUFI",
  "ISUFU",
  "ISUMA",
  "ISUME",
  "ISUMI",
  "ISUMU",
  "ISUNZ",
  "ISUZU",
  "IŠČEM",
  "IŠČEŠ",
  "IŠČOČ",
  "IŠIAS",
  "IŠIMA",
  "IŠIMU",
  "IŠKAR",
  "IŠKEM",
  "IŠKGA",
  "IŠKIH",
  "IŠKIM",
  "ITAKA",
  "ITAKE",
  "ITAKI",
  "ITAKO",
  "ITALA",
  "ITALE",
  "ITALI",
  "ITALK",
  "ITALO",
  "ITALU",
  "ITAMA",
  "ITAMI",
  "ITANA",
  "ITANE",
  "ITANI",
  "ITANO",
  "ITHIH",
  "ITHJA",
  "ITHJU",
  "ITHOM",
  "ITHOV",
  "ITINA",
  "ITINE",
  "ITINI",
  "ITINO",
  "ITKIN",
  "ITOMA",
  "ITOTA",
  "ITOTU",
  "ITOVA",
  "ITOVE",
  "ITOVI",
  "ITOVO",
  "ITRIJ",
  "ITUMA",
  "ITUME",
  "ITUMI",
  "ITUMO",
  "ITUMU",
  "IUELA",
  "IUELE",
  "IUELI",
  "IUELU",
  "IUPAC",
  "IUSEM",
  "IUSJA",
  "IUSJU",
  "IUSOM",
  "IUTZI",
  "IVAMA",
  "IVAMI",
  "IVANA",
  "IVANC",
  "IVAND",
  "IVANE",
  "IVANI",
  "IVANK",
  "IVANO",
  "IVANU",
  "IVARI",
  "IVARS",
  "IVASA",
  "IVASE",
  "IVASI",
  "IVASU",
  "IVAŠK",
  "IVCEM",
  "IVCEV",
  "IVCIH",
  "IVCJA",
  "IVCJU",
  "IVCOM",
  "IVCOV",
  "IVČEV",
  "IVČOV",
  "IVECA",
  "IVECO",
  "IVECU",
  "IVELJ",
  "IVENS",
  "IVERI",
  "IVERK",
  "IVERS",
  "IVESA",
  "IVESE",
  "IVESI",
  "IVESU",
  "IVETA",
  "IVETE",
  "IVETI",
  "IVETO",
  "IVETU",
  "IVHAN",
  "IVICA",
  "IVICE",
  "IVICI",
  "IVICO",
  "IVICU",
  "IVIČA",
  "IVIČE",
  "IVIČI",
  "IVIČU",
  "IVINA",
  "IVINE",
  "IVINI",
  "IVINO",
  "IVINS",
  "IVJAM",
  "IVJAN",
  "IVJEM",
  "IVJOM",
  "IVKAH",
  "IVKAM",
  "IVKIN",
  "IVKOV",
  "IVNAH",
  "IVNAM",
  "IVNAT",
  "IVNIK",
  "IVNIN",
  "IVOMA",
  "IVONA",
  "IVONE",
  "IVONI",
  "IVONO",
  "IVOŠA",
  "IVOŠE",
  "IVOŠI",
  "IVOŠU",
  "IVOTA",
  "IVOTU",
  "IVOVA",
  "IVOVE",
  "IVOVI",
  "IVOVO",
  "IVRIT",
  "IVŠAH",
  "IVŠAM",
  "IVŠEK",
  "IVŠEM",
  "IVŠEV",
  "IVŠIČ",
  "IVŠIH",
  "IVŠJA",
  "IVŠJU",
  "IVŠKA",
  "IVŠKE",
  "IVŠKI",
  "IVŠKU",
  "IVŠOM",
  "IVŠOV",
  "IZAIJ",
  "IZAKA",
  "IZAKE",
  "IZAKI",
  "IZAKU",
  "IZAMA",
  "IZAMI",
  "IZANA",
  "IZANE",
  "IZANI",
  "IZANU",
  "IZATA",
  "IZATE",
  "IZATI",
  "IZATO",
  "IZATT",
  "IZATU",
  "IZBAH",
  "IZBAM",
  "IZBEN",
  "IZBIC",
  "IZBIJ",
  "IZBIL",
  "IZBIR",
  "IZBIT",
  "IZBIU",
  "IZBIV",
  "IZBNA",
  "IZBNE",
  "IZBNI",
  "IZBNO",
  "IZBOK",
  "IZBOR",
  "IZBRU",
  "IZBUH",
  "IZDAJ",
  "IZDAL",
  "IZDAM",
  "IZDAN",
  "IZDAŠ",
  "IZDAT",
  "IZDAU",
  "IZDAV",
  "IZDIH",
  "IZDRE",
  "IZDRI",
  "IZDRL",
  "IZDRT",
  "IZDUH",
  "IZETA",
  "IZETE",
  "IZETI",
  "IZETO",
  "IZETT",
  "IZETU",
  "IZGIN",
  "IZGNU",
  "IZGON",
  "IZGUB",
  "IZHOD",
  "IZHOT",
  "IZIDA",
  "IZIDE",
  "IZIDI",
  "IZIDO",
  "IZIDU",
  "IZINA",
  "IZINE",
  "IZINI",
  "IZINO",
  "IZITI",
  "IZIVA",
  "IZIVE",
  "IZIVI",
  "IZIVU",
  "IZJAV",
  "IZJED",
  "IZJEJ",
  "IZJEM",
  "IZJEŠ",
  "IZJET",
  "IZJUM",
  "IZKAZ",
  "IZKOP",
  "IZKUP",
  "IZLAK",
  "IZLEČ",
  "IZLET",
  "IZLIJ",
  "IZLIL",
  "IZLIT",
  "IZLIU",
  "IZLIV",
  "IZLOV",
  "IZMED",
  "IZMEM",
  "IZMEN",
  "IZMER",
  "IZMET",
  "IZMEV",
  "IZMIH",
  "IZMIJ",
  "IZMIK",
  "IZMIL",
  "IZMIR",
  "IZMIT",
  "IZMIU",
  "IZMIV",
  "IZMJA",
  "IZMJU",
  "IZMOM",
  "IZMOV",
  "IZNAD",
  "IZNOS",
  "IZOLA",
  "IZOLD",
  "IZOLE",
  "IZOLI",
  "IZOLO",
  "IZORU",
  "IZOVE",
  "IZOVI",
  "IZPAD",
  "IZPAH",
  "IZPAT",
  "IZPEL",
  "IZPET",
  "IZPEU",
  "IZPIH",
  "IZPIJ",
  "IZPIL",
  "IZPIS",
  "IZPIT",
  "IZPIU",
  "IZPIV",
  "IZPLU",
  "IZPNE",
  "IZPNI",
  "IZPOD",
  "IZPOJ",
  "IZPRE",
  "IZPRI",
  "IZPRL",
  "IZPRT",
  "IZPRU",
  "IZPUH",
  "IZRAB",
  "IZRAZ",
  "IZREČ",
  "IZREK",
  "IZREZ",
  "IZRIJ",
  "IZRIL",
  "IZRIS",
  "IZRIT",
  "IZRIU",
  "IZRIV",
  "IZRUJ",
  "IZRUL",
  "IZRUT",
  "IZSEK",
  "IZSEV",
  "IZSRU",
  "IZŠEL",
  "IZŠEU",
  "IZŠLA",
  "IZŠLE",
  "IZŠLI",
  "IZŠLO",
  "IZŠTU",
  "IZTEČ",
  "IZTEG",
  "IZTEK",
  "IZTIS",
  "IZTOK",
  "IZUČI",
  "IZUČL",
  "IZUČU",
  "IZUMA",
  "IZUME",
  "IZUMI",
  "IZUMU",
  "IZURI",
  "IZURU",
  "IZVAL",
  "IZVAN",
  "IZVAT",
  "IZVAU",
  "IZVAV",
  "IZVEJ",
  "IZVEM",
  "IZVEN",
  "IZVES",
  "IZVEŠ",
  "IZVID",
  "IZVIJ",
  "IZVIL",
  "IZVIN",
  "IZVIR",
  "IZVIT",
  "IZVIU",
  "IZVIV",
  "IZVOD",
  "IZVOR",
  "IZVOT",
  "IZVOZ",
  "IZVRE",
  "IZVRG",
  "IZVRI",
  "IZVRU",
  "IZVZU",
  "IZZID",
  "IZZIE",
  "IZZIS",
  "IZZIV",
  "IZZVU",
  "IZŽEL",
  "IZŽET",
  "IZŽEU",
  "IZŽGE",
  "IZŽGI",
  "IZŽGU",
  "IZŽME",
  "IZŽMI",
  "IZŽRE",
  "IZŽRI",
  "IZŽRL",
  "IZŽRT",
  "IŽANC",
  "IŽANK",
  "IŽESA",
  "IŽESI",
  "IŽESU",
  "IŽOTA",
  "IŽOTU",
  "JAAKA",
  "JAAKE",
  "JAAKI",
  "JAAKU",
  "JAALA",
  "JAALE",
  "JAALI",
  "JAALO",
  "JAAPA",
  "JAAPE",
  "JAAPI",
  "JAAPU",
  "JABEL",
  "JABLE",
  "JABON",
  "JABSA",
  "JABSE",
  "JABSI",
  "JABSU",
  "JACEK",
  "JACHS",
  "JACIH",
  "JACJA",
  "JACJU",
  "JACKA",
  "JACKE",
  "JACKI",
  "JACKS",
  "JACKU",
  "JACOB",
  "JACOM",
  "JACOV",
  "JAČAJ",
  "JAČAL",
  "JAČAM",
  "JAČAŠ",
  "JAČAT",
  "JAČAU",
  "JAČAV",
  "JAČEČ",
  "JAČEN",
  "JAČIL",
  "JAČIM",
  "JAČIN",
  "JAČIŠ",
  "JAČIT",
  "JAČIU",
  "JAČIV",
  "JAČJE",
  "JADAH",
  "JADAM",
  "JADEK",
  "JADEN",
  "JADER",
  "JADIN",
  "JADON",
  "JADOT",
  "JADRA",
  "JADRC",
  "JADRI",
  "JADRN",
  "JADRO",
  "JADRU",
  "JAEJA",
  "JAEJE",
  "JAEJI",
  "JAEJU",
  "JAELA",
  "JAELE",
  "JAELI",
  "JAELO",
  "JAENA",
  "JAENU",
  "JAERG",
  "JAFAR",
  "JAFET",
  "JAFFE",
  "JAFRI",
  "JAGAH",
  "JAGAM",
  "JAGAR",
  "JAGDA",
  "JAGDE",
  "JAGDI",
  "JAGDU",
  "JAGER",
  "JAGGA",
  "JAGGE",
  "JAGGI",
  "JAGGS",
  "JAGGU",
  "JAGIČ",
  "JAGIH",
  "JAGJA",
  "JAGJU",
  "JAGOČ",
  "JAGOD",
  "JAGOM",
  "JAGOŠ",
  "JAGOV",
  "JAGRA",
  "JAGRE",
  "JAGRI",
  "JAGRU",
  "JAHAČ",
  "JAHAJ",
  "JAHAL",
  "JAHAM",
  "JAHAŠ",
  "JAHAT",
  "JAHAU",
  "JAHAV",
  "JAHIČ",
  "JAHIH",
  "JAHIJ",
  "JAHIR",
  "JAHIU",
  "JAHJA",
  "JAHJU",
  "JAHNA",
  "JAHNE",
  "JAHNI",
  "JAHNS",
  "JAHNU",
  "JAHNZ",
  "JAHOD",
  "JAHOM",
  "JAHOV",
  "JAHTA",
  "JAHTE",
  "JAHTI",
  "JAHTO",
  "JAHTU",
  "JAHVE",
  "JAIEM",
  "JAIEV",
  "JAIIH",
  "JAIJA",
  "JAIJU",
  "JAIME",
  "JAINA",
  "JAINE",
  "JAINI",
  "JAINO",
  "JAINU",
  "JAIOM",
  "JAIOV",
  "JAIRA",
  "JAIRE",
  "JAIRI",
  "JAIRO",
  "JAIRU",
  "JAJAŠ",
  "JAJCA",
  "JAJCE",
  "JAJCI",
  "JAJCU",
  "JAJČK",
  "JAJEM",
  "JAJEV",
  "JAJIH",
  "JAJJA",
  "JAJJU",
  "JAJOM",
  "JAJOV",
  "JAKAB",
  "JAKAC",
  "JAKAH",
  "JAKAJ",
  "JAKAM",
  "JAKAR",
  "JAKCA",
  "JAKCE",
  "JAKCI",
  "JAKCU",
  "JAKEC",
  "JAKEM",
  "JAKEN",
  "JAKES",
  "JAKEV",
  "JAKGA",
  "JAKIČ",
  "JAKIH",
  "JAKIL",
  "JAKIM",
  "JAKIN",
  "JAKIR",
  "JAKIŠ",
  "JAKJA",
  "JAKJU",
  "JAKLA",
  "JAKLE",
  "JAKLI",
  "JAKLU",
  "JAKNA",
  "JAKNE",
  "JAKNI",
  "JAKNO",
  "JAKOB",
  "JAKOM",
  "JAKOP",
  "JAKOŠ",
  "JAKOV",
  "JAKSA",
  "JAKSE",
  "JAKSI",
  "JAKSU",
  "JAKŠA",
  "JAKŠE",
  "JAKŠI",
  "JAKŠO",
  "JAKŠU",
  "JAKUB",
  "JAKUP",
  "JAKUS",
  "JAKUŠ",
  "JAKUT",
  "JAKUZ",
  "JALAL",
  "JALAS",
  "JALEN",
  "JALNA",
  "JALNE",
  "JALNI",
  "JALNU",
  "JALOV",
  "JALTA",
  "JALTE",
  "JALTI",
  "JALTO",
  "JAMAH",
  "JAMAL",
  "JAMAM",
  "JAMAN",
  "JAMAR",
  "JAMBA",
  "JAMBE",
  "JAMBI",
  "JAMBU",
  "JAMCA",
  "JAMCE",
  "JAMCI",
  "JAMCU",
  "JAMČI",
  "JAMČL",
  "JAMČU",
  "JAMEC",
  "JAMEM",
  "JAMES",
  "JAMEŠ",
  "JAMIC",
  "JAMIE",
  "JAMIH",
  "JAMIK",
  "JAMIL",
  "JAMIN",
  "JAMJA",
  "JAMJU",
  "JAMKA",
  "JAMKE",
  "JAMKI",
  "JAMKO",
  "JAMNA",
  "JAMNE",
  "JAMNI",
  "JAMNO",
  "JAMOM",
  "JAMOV",
  "JAMRA",
  "JAMRI",
  "JAMRU",
  "JANAF",
  "JANAH",
  "JANAK",
  "JANAM",
  "JANAS",
  "JANBU",
  "JANCA",
  "JANCE",
  "JANCI",
  "JANCU",
  "JANČE",
  "JANČI",
  "JANDA",
  "JANDE",
  "JANDI",
  "JANDL",
  "JANDO",
  "JANDU",
  "JANEČ",
  "JANEE",
  "JANEK",
  "JANEL",
  "JANER",
  "JANES",
  "JANEŠ",
  "JANET",
  "JANEV",
  "JANEZ",
  "JANEŽ",
  "JANGA",
  "JANGE",
  "JANGI",
  "JANGU",
  "JANIC",
  "JANIČ",
  "JANIH",
  "JANIK",
  "JANIN",
  "JANIS",
  "JANIŠ",
  "JANIT",
  "JANIV",
  "JANJA",
  "JANJE",
  "JANJI",
  "JANJO",
  "JANJU",
  "JANKA",
  "JANKE",
  "JANKI",
  "JANKO",
  "JANKS",
  "JANKU",
  "JANNA",
  "JANNE",
  "JANNI",
  "JANNO",
  "JANNU",
  "JANOM",
  "JANOS",
  "JANOŠ",
  "JANOT",
  "JANOV",
  "JANSA",
  "JANSE",
  "JANSI",
  "JANSU",
  "JANŠA",
  "JANŠE",
  "JANŠI",
  "JANŠO",
  "JANŠU",
  "JANUR",
  "JANUS",
  "JANUŠ",
  "JANŽA",
  "JANŽE",
  "JANŽI",
  "JANŽO",
  "JANŽU",
  "JAPAN",
  "JAPCA",
  "JAPCE",
  "JAPCI",
  "JAPCU",
  "JAPEC",
  "JAPIČ",
  "JAPOK",
  "JAPPA",
  "JAPPE",
  "JAPPI",
  "JAPPU",
  "JAPTI",
  "JARAH",
  "JARAM",
  "JARCA",
  "JARCE",
  "JARCI",
  "JARCU",
  "JARDA",
  "JARDE",
  "JARDI",
  "JARDU",
  "JAREC",
  "JARED",
  "JAREK",
  "JAREM",
  "JAREN",
  "JARGA",
  "JARHA",
  "JARHE",
  "JARHI",
  "JARHU",
  "JARIC",
  "JARIČ",
  "JARIH",
  "JARIM",
  "JARIN",
  "JARJA",
  "JARJU",
  "JARKA",
  "JARKE",
  "JARKI",
  "JARKO",
  "JARKU",
  "JARLA",
  "JARLE",
  "JARLI",
  "JARLU",
  "JARMA",
  "JARME",
  "JARMI",
  "JARMO",
  "JARMU",
  "JARNA",
  "JARNE",
  "JARNI",
  "JARNO",
  "JARNU",
  "JAROM",
  "JARON",
  "JAROŠ",
  "JAROV",
  "JARRA",
  "JARRE",
  "JARRI",
  "JARRU",
  "JARŠE",
  "JARUN",
  "JASAH",
  "JASAM",
  "JASAN",
  "JASCH",
  "JASEN",
  "JASER",
  "JASHA",
  "JASHE",
  "JASHI",
  "JASHO",
  "JASHU",
  "JASIC",
  "JASIK",
  "JASIN",
  "JASLI",
  "JASMI",
  "JASNA",
  "JASNE",
  "JASNI",
  "JASNO",
  "JASNU",
  "JASON",
  "JASTA",
  "JASTE",
  "JASTI",
  "JASTO",
  "JASTU",
  "JAŠAH",
  "JAŠAM",
  "JAŠAR",
  "JAŠEK",
  "JAŠEM",
  "JAŠEŠ",
  "JAŠEV",
  "JAŠIČ",
  "JAŠIH",
  "JAŠIK",
  "JAŠIN",
  "JAŠJA",
  "JAŠJU",
  "JAŠKA",
  "JAŠKE",
  "JAŠKI",
  "JAŠKU",
  "JAŠOM",
  "JAŠOV",
  "JATAH",
  "JATAM",
  "JATEM",
  "JATEV",
  "JATIH",
  "JATIN",
  "JATJA",
  "JATJU",
  "JATOM",
  "JATOV",
  "JATTA",
  "JATTE",
  "JATTI",
  "JATTO",
  "JATTU",
  "JAUCH",
  "JAUHA",
  "JAUHE",
  "JAUHI",
  "JAUHU",
  "JAUKA",
  "JAUKE",
  "JAUKI",
  "JAUKU",
  "JAUME",
  "JAUNA",
  "JAUNE",
  "JAUNI",
  "JAUNU",
  "JAUSA",
  "JAUSE",
  "JAUSI",
  "JAUSS",
  "JAUSU",
  "JAVAD",
  "JAVAL",
  "JAVEN",
  "JAVHA",
  "JAVHE",
  "JAVHI",
  "JAVHU",
  "JAVID",
  "JAVIL",
  "JAVIM",
  "JAVIŠ",
  "JAVIT",
  "JAVIU",
  "JAVIV",
  "JAVKA",
  "JAVKE",
  "JAVKI",
  "JAVKO",
  "JAVKU",
  "JAVNA",
  "JAVNE",
  "JAVNI",
  "JAVNO",
  "JAVOR",
  "JAZBC",
  "JAZEM",
  "JAZEN",
  "JAZER",
  "JAZEV",
  "JAZID",
  "JAZIH",
  "JAZJA",
  "JAZJU",
  "JAZMP",
  "JAZNE",
  "JAZOM",
  "JAZON",
  "JAZOV",
  "JAZZA",
  "JAZZI",
  "JAZZM",
  "JAZZU",
  "JEANA",
  "JEANE",
  "JEANI",
  "JEANO",
  "JEANS",
  "JEANU",
  "JEBAČ",
  "JEBAH",
  "JEBAJ",
  "JEBAL",
  "JEBAM",
  "JEBAN",
  "JEBAŠ",
  "JEBAT",
  "JEBAU",
  "JEBAV",
  "JEBBA",
  "JEBBE",
  "JEBBI",
  "JEBBU",
  "JEBEM",
  "JEBEŠ",
  "JEBIH",
  "JEBJA",
  "JEBJU",
  "JEBOM",
  "JEBOV",
  "JECAV",
  "JECLA",
  "JECLE",
  "JECLI",
  "JECLU",
  "JECNE",
  "JECNI",
  "JECNU",
  "JEČAH",
  "JEČAL",
  "JEČAM",
  "JEČAR",
  "JEČAT",
  "JEČAU",
  "JEČAV",
  "JEČEČ",
  "JEČIM",
  "JEČIŠ",
  "JEDCA",
  "JEDCE",
  "JEDCI",
  "JEDCU",
  "JEDEC",
  "JEDEH",
  "JEDEK",
  "JEDEL",
  "JEDEM",
  "JEDER",
  "JEDEU",
  "JEDEŽ",
  "JEDIH",
  "JEDIL",
  "JEDJA",
  "JEDJO",
  "JEDJU",
  "JEDKA",
  "JEDKE",
  "JEDKI",
  "JEDKO",
  "JEDKU",
  "JEDLA",
  "JEDLE",
  "JEDLI",
  "JEDLO",
  "JEDMI",
  "JEDOČ",
  "JEDOM",
  "JEDOV",
  "JEDRA",
  "JEDRC",
  "JEDRE",
  "JEDRI",
  "JEDRN",
  "JEDRO",
  "JEDRT",
  "JEDRU",
  "JEETU",
  "JEFFA",
  "JEFFE",
  "JEFFI",
  "JEFFS",
  "JEFFU",
  "JEFIM",
  "JEGOR",
  "JEHAN",
  "JEHNA",
  "JEHNE",
  "JEHNI",
  "JEHNU",
  "JEHON",
  "JEHOV",
  "JEHUD",
  "JEINA",
  "JEINE",
  "JEINI",
  "JEINU",
  "JEJMO",
  "JEJSK",
  "JEJTA",
  "JEJTE",
  "JEJVA",
  "JEKEL",
  "JEKEM",
  "JEKEV",
  "JEKIH",
  "JEKJA",
  "JEKJU",
  "JEKLA",
  "JEKLE",
  "JEKLI",
  "JEKLO",
  "JEKLU",
  "JEKNE",
  "JEKNI",
  "JEKNU",
  "JEKOM",
  "JEKOŠ",
  "JEKOV",
  "JELAČ",
  "JELAH",
  "JELAM",
  "JELAR",
  "JELAŠ",
  "JELCE",
  "JELEČ",
  "JELEN",
  "JELER",
  "JELEV",
  "JELIC",
  "JELIČ",
  "JELIN",
  "JELKA",
  "JELKE",
  "JELKI",
  "JELKO",
  "JELKS",
  "JELKU",
  "JELLA",
  "JELLE",
  "JELLI",
  "JELLO",
  "JELOV",
  "JELSA",
  "JELSE",
  "JELSI",
  "JELSO",
  "JELŠA",
  "JELŠE",
  "JELŠI",
  "JELŠO",
  "JEMAL",
  "JEMAN",
  "JEMAS",
  "JEMAT",
  "JEMAU",
  "JEMAV",
  "JEMCA",
  "JEMCE",
  "JEMCI",
  "JEMCU",
  "JEMEC",
  "JEMEN",
  "JEMIL",
  "JEMIM",
  "JEMIN",
  "JEMMA",
  "JEMME",
  "JEMMI",
  "JEMMO",
  "JEMNA",
  "JEMNU",
  "JENAH",
  "JENAL",
  "JENAM",
  "JENCA",
  "JENCE",
  "JENCI",
  "JENCU",
  "JENČE",
  "JENEM",
  "JENEŠ",
  "JENEV",
  "JENIČ",
  "JENIH",
  "JENIN",
  "JENJA",
  "JENJI",
  "JENJU",
  "JENKA",
  "JENKE",
  "JENKI",
  "JENKO",
  "JENKS",
  "JENKU",
  "JENLE",
  "JENNA",
  "JENNE",
  "JENNI",
  "JENNO",
  "JENNS",
  "JENOM",
  "JENOV",
  "JENSA",
  "JENSE",
  "JENSI",
  "JENSU",
  "JENTE",
  "JENUŠ",
  "JEONA",
  "JEONE",
  "JEONI",
  "JEONU",
  "JEPIH",
  "JEPJA",
  "JEPJU",
  "JEPOM",
  "JEPOV",
  "JEPPE",
  "JERAH",
  "JERAI",
  "JERAJ",
  "JERAK",
  "JERAL",
  "JERAM",
  "JERAN",
  "JERAS",
  "JERAŠ",
  "JERCA",
  "JERCE",
  "JERCI",
  "JERCO",
  "JERČA",
  "JERČE",
  "JERČI",
  "JERČU",
  "JEREB",
  "JERED",
  "JEREL",
  "JEREZ",
  "JERIC",
  "JERIČ",
  "JERIH",
  "JERIN",
  "JERIŠ",
  "JERKA",
  "JERKE",
  "JERKI",
  "JERKO",
  "JERKU",
  "JERNE",
  "JEROB",
  "JEROT",
  "JERRI",
  "JERŠE",
  "JERTA",
  "JERTE",
  "JERTI",
  "JERTU",
  "JESCH",
  "JESEN",
  "JESIH",
  "JESKE",
  "JESNA",
  "JESNE",
  "JESNI",
  "JESNU",
  "JESSA",
  "JESSE",
  "JESSI",
  "JESSU",
  "JESTA",
  "JESTE",
  "JESTI",
  "JESUR",
  "JESUS",
  "JEŠČA",
  "JEŠČE",
  "JEŠČI",
  "JEŠČO",
  "JEŠIV",
  "JEŠKA",
  "JEŠKE",
  "JEŠKI",
  "JEŠKO",
  "JETAH",
  "JETAM",
  "JETER",
  "JETIN",
  "JETON",
  "JETRA",
  "JETRC",
  "JETRI",
  "JETRN",
  "JETRV",
  "JETTA",
  "JETTE",
  "JETTI",
  "JETTO",
  "JETTU",
  "JEUNA",
  "JEUNE",
  "JEUNI",
  "JEUNU",
  "JEVCA",
  "JEVCE",
  "JEVCI",
  "JEVCU",
  "JEVON",
  "JEZAH",
  "JEZAM",
  "JEZAV",
  "JEZDC",
  "JEZDI",
  "JEZDU",
  "JEZEČ",
  "JEZEM",
  "JEZEN",
  "JEZER",
  "JEZEV",
  "JEZIC",
  "JEZID",
  "JEZIH",
  "JEZIK",
  "JEZIL",
  "JEZIM",
  "JEZIŠ",
  "JEZIT",
  "JEZIU",
  "JEZIV",
  "JEZJA",
  "JEZJU",
  "JEZNA",
  "JEZNE",
  "JEZNI",
  "JEZNO",
  "JEZOM",
  "JEZOV",
  "JEZUS",
  "JEŽAH",
  "JEŽAM",
  "JEŽCE",
  "JEŽEK",
  "JEŽEM",
  "JEŽEN",
  "JEŽEŠ",
  "JEŽEV",
  "JEŽIC",
  "JEŽIH",
  "JEŽIL",
  "JEŽIM",
  "JEŽIN",
  "JEŽIŠ",
  "JEŽIT",
  "JEŽIU",
  "JEŽIV",
  "JEŽJA",
  "JEŽJU",
  "JEŽKA",
  "JEŽKE",
  "JEŽKI",
  "JEŽKU",
  "JEŽNA",
  "JEŽNE",
  "JEŽNI",
  "JEŽNO",
  "JEŽOM",
  "JEŽOV",
  "JIANA",
  "JIANE",
  "JIANG",
  "JIANI",
  "JIANU",
  "JICAK",
  "JICAM",
  "JICKA",
  "JICKE",
  "JICKI",
  "JICKU",
  "JIDIŠ",
  "JIGAL",
  "JILEN",
  "JILIN",
  "JILLA",
  "JILLE",
  "JILLI",
  "JILLU",
  "JILTA",
  "JILTE",
  "JILTI",
  "JILTO",
  "JILTU",
  "JIMEN",
  "JIMIH",
  "JIMJA",
  "JIMJU",
  "JIMMI",
  "JIMOM",
  "JIMOV",
  "JINEK",
  "JINEM",
  "JINEV",
  "JINGA",
  "JINGE",
  "JINGI",
  "JINGU",
  "JINIH",
  "JINJA",
  "JINJU",
  "JINOM",
  "JINOV",
  "JINSA",
  "JINSE",
  "JINSI",
  "JINSU",
  "JIRRI",
  "JITKA",
  "JITKE",
  "JITKI",
  "JITKO",
  "JOAIH",
  "JOAJA",
  "JOAJU",
  "JOANA",
  "JOANE",
  "JOANI",
  "JOANN",
  "JOANO",
  "JOANU",
  "JOAOM",
  "JOAOV",
  "JOASA",
  "JOASE",
  "JOASI",
  "JOASU",
  "JOAVA",
  "JOAVE",
  "JOAVI",
  "JOAVU",
  "JOBIH",
  "JOBIM",
  "JOBIN",
  "JOBJA",
  "JOBJU",
  "JOBOM",
  "JOBOV",
  "JOBSA",
  "JOBSE",
  "JOBSI",
  "JOBST",
  "JOBSU",
  "JOCEM",
  "JOCEV",
  "JOCIČ",
  "JOCIF",
  "JOCIH",
  "JOCJA",
  "JOCJU",
  "JOCKA",
  "JOCKE",
  "JOCKI",
  "JOCKO",
  "JOCKU",
  "JOCOM",
  "JOCOV",
  "JOČEM",
  "JOČEŠ",
  "JOČEV",
  "JOČOV",
  "JODAT",
  "JODEM",
  "JODEN",
  "JODEV",
  "JODID",
  "JODIE",
  "JODIN",
  "JODIT",
  "JODJA",
  "JODJU",
  "JODLA",
  "JODLE",
  "JODLI",
  "JODLU",
  "JODNA",
  "JODNE",
  "JODNI",
  "JODNO",
  "JODOM",
  "JODOV",
  "JOEEM",
  "JOEEV",
  "JOEIH",
  "JOEJA",
  "JOEJE",
  "JOEJI",
  "JOEJU",
  "JOELA",
  "JOELE",
  "JOELI",
  "JOELU",
  "JOEMA",
  "JOENS",
  "JOEOM",
  "JOEOV",
  "JOEPA",
  "JOEPE",
  "JOEPI",
  "JOEPU",
  "JOERG",
  "JOERN",
  "JOFFA",
  "JOFFE",
  "JOFFI",
  "JOFFS",
  "JOFFU",
  "JOGAN",
  "JOGER",
  "JOGRA",
  "JOGRE",
  "JOGRI",
  "JOGRU",
  "JOHAH",
  "JOHAM",
  "JOHAN",
  "JOHAR",
  "JOHIH",
  "JOHJA",
  "JOHJU",
  "JOHNA",
  "JOHNE",
  "JOHNI",
  "JOHNS",
  "JOHNU",
  "JOHOM",
  "JOHOV",
  "JOHST",
  "JOINT",
  "JOISA",
  "JOISE",
  "JOISI",
  "JOISU",
  "JOJEM",
  "JOJEV",
  "JOJIH",
  "JOJIN",
  "JOJJA",
  "JOJJU",
  "JOJME",
  "JOJOB",
  "JOJOM",
  "JOJOV",
  "JOKAJ",
  "JOKAL",
  "JOKAM",
  "JOKAŠ",
  "JOKAT",
  "JOKAU",
  "JOKAV",
  "JOKCA",
  "JOKCI",
  "JOKCU",
  "JOKEM",
  "JOKER",
  "JOKIČ",
  "JOKIH",
  "JOKJA",
  "JOKJU",
  "JOKLA",
  "JOKLE",
  "JOKLI",
  "JOKLU",
  "JOKOM",
  "JOKOV",
  "JOLAH",
  "JOLAM",
  "JOLER",
  "JOLES",
  "JOLIČ",
  "JOLIE",
  "JOLIG",
  "JOLIH",
  "JOLIN",
  "JOLJA",
  "JOLJU",
  "JOLKA",
  "JOLKE",
  "JOLKI",
  "JOLKO",
  "JOLOM",
  "JOLOV",
  "JONAH",
  "JONAK",
  "JONAM",
  "JONAS",
  "JONAŠ",
  "JONAT",
  "JONCA",
  "JONCE",
  "JONCI",
  "JONCK",
  "JONCU",
  "JONEC",
  "JONER",
  "JONES",
  "JONET",
  "JONGA",
  "JONGE",
  "JONGI",
  "JONGU",
  "JONID",
  "JONIH",
  "JONIN",
  "JONJA",
  "JONJU",
  "JONKA",
  "JONKE",
  "JONKI",
  "JONKO",
  "JONKU",
  "JONNA",
  "JONNE",
  "JONNI",
  "JONNO",
  "JONOM",
  "JONOV",
  "JONUZ",
  "JONZA",
  "JONZE",
  "JONZI",
  "JONZU",
  "JOODA",
  "JOODE",
  "JOODI",
  "JOODU",
  "JOOFA",
  "JOOFE",
  "JOOFI",
  "JOOFU",
  "JOOLS",
  "JOOMA",
  "JOONA",
  "JOONE",
  "JOONI",
  "JOONO",
  "JOONU",
  "JOOPA",
  "JOOPE",
  "JOOPI",
  "JOOPU",
  "JOOSS",
  "JOOST",
  "JOOTA",
  "JOOTU",
  "JOPAH",
  "JOPAM",
  "JOPIC",
  "JOPIČ",
  "JOPPA",
  "JOPPE",
  "JOPPI",
  "JOPPU",
  "JOPTA",
  "JOPTE",
  "JOPTI",
  "JOPTU",
  "JORAN",
  "JORAS",
  "JORDA",
  "JORDI",
  "JORET",
  "JORGA",
  "JORGE",
  "JORGI",
  "JORGO",
  "JORGU",
  "JORIA",
  "JORID",
  "JORIE",
  "JORII",
  "JORIO",
  "JORIS",
  "JORIU",
  "JORJA",
  "JORJE",
  "JORJI",
  "JORJO",
  "JORMA",
  "JORME",
  "JORMI",
  "JORMO",
  "JORMU",
  "JORNA",
  "JORNE",
  "JORNI",
  "JORNO",
  "JORNU",
  "JOSCH",
  "JOSEF",
  "JOSEK",
  "JOSEL",
  "JOSEM",
  "JOSEP",
  "JOSHA",
  "JOSHE",
  "JOSHI",
  "JOSHO",
  "JOSHU",
  "JOSIČ",
  "JOSIE",
  "JOSIF",
  "JOSIH",
  "JOSIP",
  "JOSJA",
  "JOSJU",
  "JOSKA",
  "JOSKE",
  "JOSKI",
  "JOSKU",
  "JOSOM",
  "JOSOV",
  "JOSSA",
  "JOSSE",
  "JOSSI",
  "JOSSO",
  "JOSSU",
  "JOSTA",
  "JOSTE",
  "JOSTI",
  "JOSTO",
  "JOSTU",
  "JOŠAR",
  "JOŠIČ",
  "JOŠID",
  "JOŠKA",
  "JOŠKE",
  "JOŠKI",
  "JOŠKO",
  "JOŠKU",
  "JOŠTA",
  "JOŠTE",
  "JOŠTI",
  "JOŠTU",
  "JOTAH",
  "JOTAM",
  "JOTIČ",
  "JOTIH",
  "JOTJA",
  "JOTJU",
  "JOTOM",
  "JOTOV",
  "JOTTA",
  "JOTTE",
  "JOTTI",
  "JOTTO",
  "JOTTU",
  "JOUAN",
  "JOULA",
  "JOULE",
  "JOULI",
  "JOULU",
  "JOUVA",
  "JOUVE",
  "JOUVI",
  "JOUVU",
  "JOVAN",
  "JOVIC",
  "JOVIČ",
  "JOVIH",
  "JOVIŠ",
  "JOVIT",
  "JOVJA",
  "JOVJU",
  "JOVOM",
  "JOVOV",
  "JOZEF",
  "JOZIČ",
  "JOZIH",
  "JOZJA",
  "JOZJU",
  "JOZOM",
  "JOZOV",
  "JOZSI",
  "JOZUE",
  "JOZUV",
  "JOŽAH",
  "JOŽAM",
  "JOŽEF",
  "JOŽEK",
  "JOŽEM",
  "JOŽEV",
  "JOŽIC",
  "JOŽIH",
  "JOŽIN",
  "JOŽJA",
  "JOŽJU",
  "JOŽKA",
  "JOŽKE",
  "JOŽKI",
  "JOŽKO",
  "JOŽKU",
  "JOŽOM",
  "JOŽOV",
  "JUAAK",
  "JUANA",
  "JUANE",
  "JUANI",
  "JUANM",
  "JUANO",
  "JUANU",
  "JUBAK",
  "JUBJA",
  "JUBJU",
  "JUBOM",
  "JUBOV",
  "JUCKS",
  "JUČAS",
  "JUDAH",
  "JUDAM",
  "JUDAR",
  "JUDDA",
  "JUDDE",
  "JUDDI",
  "JUDDU",
  "JUDEM",
  "JUDEV",
  "JUDEŽ",
  "JUDGA",
  "JUDGE",
  "JUDIH",
  "JUDIN",
  "JUDIT",
  "JUDJA",
  "JUDJE",
  "JUDJU",
  "JUDOM",
  "JUDON",
  "JUDOV",
  "JUDTA",
  "JUDTE",
  "JUDTI",
  "JUDTU",
  "JUEJA",
  "JUEJE",
  "JUEJI",
  "JUEJU",
  "JUERI",
  "JUETT",
  "JUGEM",
  "JUGEV",
  "JUGIH",
  "JUGJA",
  "JUGJU",
  "JUGOM",
  "JUGOV",
  "JUHAH",
  "JUHAM",
  "JUHAN",
  "JUHAR",
  "JUHAS",
  "JUHEJ",
  "JUHIC",
  "JUHIH",
  "JUHJA",
  "JUHJU",
  "JUHLA",
  "JUHLE",
  "JUHLI",
  "JUHLU",
  "JUHNA",
  "JUHNE",
  "JUHNI",
  "JUHNU",
  "JUHOM",
  "JUHOV",
  "JUHTA",
  "JUHTE",
  "JUHTI",
  "JUHTO",
  "JUINA",
  "JUINE",
  "JUINI",
  "JUINU",
  "JUIST",
  "JUKAH",
  "JUKAM",
  "JUKAN",
  "JUKES",
  "JUKIČ",
  "JUKIJ",
  "JUKIN",
  "JUKKA",
  "JUKKE",
  "JUKKI",
  "JUKKO",
  "JUKKU",
  "JUKON",
  "JUKOS",
  "JULAH",
  "JULAM",
  "JULEN",
  "JULES",
  "JULIA",
  "JULIE",
  "JULII",
  "JULIJ",
  "JULIK",
  "JULIN",
  "JULIO",
  "JULIU",
  "JULKA",
  "JULKE",
  "JULKI",
  "JULKO",
  "JULON",
  "JUMBA",
  "JUMBO",
  "JUMBU",
  "JUMIN",
  "JUMPA",
  "JUMPE",
  "JUMPI",
  "JUMPU",
  "JUNAH",
  "JUNAK",
  "JUNAM",
  "JUNCA",
  "JUNCE",
  "JUNCI",
  "JUNCU",
  "JUNEC",
  "JUNGA",
  "JUNGE",
  "JUNGI",
  "JUNGU",
  "JUNIC",
  "JUNIH",
  "JUNIJ",
  "JUNIK",
  "JUNIN",
  "JUNIS",
  "JUNJA",
  "JUNJU",
  "JUNKO",
  "JUNNA",
  "JUNNE",
  "JUNNI",
  "JUNNU",
  "JUNOM",
  "JUNON",
  "JUNOR",
  "JUNOT",
  "JUNOV",
  "JUNRI",
  "JUNUS",
  "JUNUZ",
  "JUPIH",
  "JUPJA",
  "JUPJU",
  "JUPOM",
  "JUPOV",
  "JUPPA",
  "JUPPE",
  "JUPPI",
  "JUPPU",
  "JURAČ",
  "JURAG",
  "JURAJ",
  "JURAK",
  "JURAN",
  "JURAS",
  "JURAŠ",
  "JURCA",
  "JURCE",
  "JURCI",
  "JURCO",
  "JURCU",
  "JURDI",
  "JUREČ",
  "JUREN",
  "JUREŠ",
  "JURIC",
  "JURIČ",
  "JURIH",
  "JURIJ",
  "JURIK",
  "JURIŠ",
  "JURJA",
  "JURJE",
  "JURJI",
  "JURJU",
  "JURKA",
  "JURKE",
  "JURKI",
  "JURKO",
  "JURKU",
  "JURNA",
  "JURNE",
  "JURNI",
  "JURNO",
  "JURNU",
  "JUROM",
  "JUROŠ",
  "JUROV",
  "JURŠA",
  "JURŠČ",
  "JURŠE",
  "JURŠI",
  "JURŠO",
  "JURŠU",
  "JURTA",
  "JURTE",
  "JURTI",
  "JURTO",
  "JUSEF",
  "JUSIČ",
  "JUSIF",
  "JUSIH",
  "JUSJA",
  "JUSJU",
  "JUSOM",
  "JUSOV",
  "JUSSI",
  "JUSTA",
  "JUSTE",
  "JUSTI",
  "JUSTO",
  "JUSTU",
  "JUSUF",
  "JUSUP",
  "JUŠEM",
  "JUŠEN",
  "JUŠEV",
  "JUŠIČ",
  "JUŠIH",
  "JUŠJA",
  "JUŠJU",
  "JUŠNA",
  "JUŠNE",
  "JUŠNI",
  "JUŠNO",
  "JUŠOM",
  "JUŠOV",
  "JUTAH",
  "JUTAM",
  "JUTER",
  "JUTIN",
  "JUTKA",
  "JUTKE",
  "JUTKI",
  "JUTKO",
  "JUTRA",
  "JUTRE",
  "JUTRI",
  "JUTRO",
  "JUTRU",
  "JUTTA",
  "JUTTE",
  "JUTTI",
  "JUTTO",
  "JUTZI",
  "JUULA",
  "JUULE",
  "JUULI",
  "JUULU",
  "JUUSA",
  "JUUSE",
  "JUUSI",
  "JUUSO",
  "JUUSU",
  "JUVAL",
  "JUVAN",
  "JUVAR",
  "JUVER",
  "JUVIN",
  "JUŽEČ",
  "JUŽEN",
  "JUŽIL",
  "JUŽIM",
  "JUŽIN",
  "JUŽIŠ",
  "JUŽIT",
  "JUŽIU",
  "JUŽIV",
  "JUŽNA",
  "JUŽNE",
  "JUŽNI",
  "JUŽNO",
  "JUŽNU",
  "JZZPR",
  "KAACK",
  "KAAGA",
  "KAAGE",
  "KAAGI",
  "KAAGU",
  "KAALI",
  "KAANA",
  "KAANE",
  "KAANI",
  "KAANU",
  "KAAPA",
  "KAAPE",
  "KAAPI",
  "KAAPO",
  "KAAPU",
  "KAARI",
  "KAASA",
  "KAASE",
  "KAASI",
  "KAASU",
  "KABAH",
  "KABAJ",
  "KABAK",
  "KABAM",
  "KABAN",
  "KABAT",
  "KABEL",
  "KABIH",
  "KABIL",
  "KABIN",
  "KABIR",
  "KABIT",
  "KABJA",
  "KABJU",
  "KABLA",
  "KABLE",
  "KABLI",
  "KABLU",
  "KABOK",
  "KABOM",
  "KABOV",
  "KABST",
  "KABUL",
  "KABUR",
  "KACAV",
  "KACEM",
  "KACET",
  "KACEV",
  "KACHI",
  "KACHL",
  "KACIE",
  "KACIH",
  "KACIN",
  "KACJA",
  "KACJU",
  "KACOM",
  "KACOV",
  "KAČAH",
  "KAČAM",
  "KAČAN",
  "KAČAR",
  "KAČEK",
  "KAČEM",
  "KAČEV",
  "KAČIC",
  "KAČIČ",
  "KAČIH",
  "KAČIN",
  "KAČJA",
  "KAČJE",
  "KAČJI",
  "KAČJO",
  "KAČJU",
  "KAČKA",
  "KAČKE",
  "KAČKI",
  "KAČKU",
  "KAČOM",
  "KAČON",
  "KAČOV",
  "KAČUR",
  "KADAJ",
  "KADAK",
  "KADAR",
  "KADEČ",
  "KADEH",
  "KADEM",
  "KADEN",
  "KADER",
  "KADET",
  "KADIC",
  "KADIČ",
  "KADIJ",
  "KADIL",
  "KADIM",
  "KADIN",
  "KADIR",
  "KADIS",
  "KADIŠ",
  "KADIT",
  "KADIU",
  "KADIV",
  "KADJA",
  "KADJO",
  "KADJU",
  "KADMI",
  "KADNA",
  "KADNE",
  "KADNI",
  "KADNU",
  "KADOM",
  "KADOV",
  "KADRA",
  "KADRE",
  "KADRI",
  "KADRU",
  "KAEIT",
  "KAELA",
  "KAELE",
  "KAELI",
  "KAELO",
  "KAELU",
  "KAEMN",
  "KAFEL",
  "KAFER",
  "KAFIČ",
  "KAFKA",
  "KAFKE",
  "KAFKI",
  "KAFKO",
  "KAFKU",
  "KAFLA",
  "KAFLE",
  "KAFLI",
  "KAFLU",
  "KAFOL",
  "KAFRA",
  "KAFRE",
  "KAFRI",
  "KAFRN",
  "KAFRO",
  "KAGAN",
  "KAGER",
  "KAGGE",
  "KAGJU",
  "KAGTI",
  "KAGUT",
  "KAHAN",
  "KAHEL",
  "KAHIN",
  "KAHLA",
  "KAHLE",
  "KAHLI",
  "KAHLO",
  "KAHLU",
  "KAHNA",
  "KAHNE",
  "KAHNI",
  "KAHNU",
  "KAHON",
  "KAHRA",
  "KAHRE",
  "KAHRI",
  "KAHRS",
  "KAHRU",
  "KAHUN",
  "KAIAH",
  "KAIAM",
  "KAIBI",
  "KAIDA",
  "KAIDE",
  "KAIDI",
  "KAIDO",
  "KAIEM",
  "KAIEV",
  "KAIFA",
  "KAIFE",
  "KAIFI",
  "KAIFU",
  "KAIIH",
  "KAIJA",
  "KAIJE",
  "KAIJI",
  "KAIJO",
  "KAIJU",
  "KAILA",
  "KAILE",
  "KAILI",
  "KAILO",
  "KAILU",
  "KAIMA",
  "KAIME",
  "KAIMI",
  "KAIMU",
  "KAINA",
  "KAINE",
  "KAINI",
  "KAINU",
  "KAINZ",
  "KAIOM",
  "KAIOV",
  "KAIRA",
  "KAIRO",
  "KAIRU",
  "KAISA",
  "KAISE",
  "KAISI",
  "KAISO",
  "KAISU",
  "KAITA",
  "KAITE",
  "KAITI",
  "KAITU",
  "KAIUS",
  "KAJAH",
  "KAJAK",
  "KAJAL",
  "KAJAM",
  "KAJBA",
  "KAJBE",
  "KAJBI",
  "KAJBO",
  "KAJBU",
  "KAJDA",
  "KAJDE",
  "KAJDI",
  "KAJDO",
  "KAJFA",
  "KAJFE",
  "KAJFI",
  "KAJFO",
  "KAJFU",
  "KAJIČ",
  "KAJIN",
  "KAJIŠ",
  "KAJLA",
  "KAJLE",
  "KAJLI",
  "KAJLO",
  "KAJLU",
  "KAJNA",
  "KAJNČ",
  "KAJNE",
  "KAJNI",
  "KAJNU",
  "KAJPA",
  "KAJSA",
  "KAJSE",
  "KAJSI",
  "KAJSO",
  "KAJTA",
  "KAJTE",
  "KAJTI",
  "KAJTU",
  "KAJUH",
  "KAJUT",
  "KAJZB",
  "KAJŽA",
  "KAJŽE",
  "KAJŽI",
  "KAJŽO",
  "KAKAJ",
  "KAKAL",
  "KAKAM",
  "KAKAR",
  "KAKAŠ",
  "KAKAT",
  "KAKAU",
  "KAKAV",
  "KAKCA",
  "KAKCE",
  "KAKCI",
  "KAKCU",
  "KAKEC",
  "KAKEM",
  "KAKER",
  "KAKIH",
  "KAKIM",
  "KAKJU",
  "KAKKA",
  "KAKKE",
  "KAKKI",
  "KAKKO",
  "KAKKU",
  "KAKOJ",
  "KAKON",
  "KAKOR",
  "KAKUK",
  "KAKUS",
  "KALAB",
  "KALAČ",
  "KALAF",
  "KALAH",
  "KALAL",
  "KALAM",
  "KALAN",
  "KALAR",
  "KALAŠ",
  "KALBA",
  "KALBE",
  "KALBI",
  "KALBU",
  "KALCA",
  "KALCE",
  "KALCI",
  "KALCU",
  "KALEB",
  "KALEČ",
  "KALEH",
  "KALEM",
  "KALEN",
  "KALER",
  "KALET",
  "KALEV",
  "KALEŽ",
  "KALFF",
  "KALIA",
  "KALIČ",
  "KALIE",
  "KALIF",
  "KALIH",
  "KALII",
  "KALIJ",
  "KALIL",
  "KALIM",
  "KALIN",
  "KALIO",
  "KALIŠ",
  "KALIT",
  "KALIU",
  "KALIV",
  "KALJA",
  "KALJO",
  "KALJU",
  "KALKA",
  "KALKE",
  "KALKI",
  "KALKU",
  "KALLA",
  "KALLE",
  "KALLI",
  "KALLO",
  "KALLU",
  "KALMA",
  "KALME",
  "KALMI",
  "KALMU",
  "KALNA",
  "KALNE",
  "KALNI",
  "KALNO",
  "KALNS",
  "KALOČ",
  "KALOH",
  "KALOM",
  "KALON",
  "KALOP",
  "KALOT",
  "KALOV",
  "KALSS",
  "KALŠE",
  "KALTA",
  "KALTE",
  "KALTI",
  "KALTU",
  "KALUP",
  "KALUS",
  "KALUŠ",
  "KALUŽ",
  "KALVA",
  "KAMAD",
  "KAMAH",
  "KAMAL",
  "KAMAM",
  "KAMAN",
  "KAMAR",
  "KAMAS",
  "KAMBA",
  "KAMBE",
  "KAMBI",
  "KAMBO",
  "KAMEJ",
  "KAMEL",
  "KAMEN",
  "KAMER",
  "KAMHI",
  "KAMIE",
  "KAMIL",
  "KAMIN",
  "KAMIR",
  "KAMMA",
  "KAMME",
  "KAMMI",
  "KAMMU",
  "KAMNA",
  "KAMNE",
  "KAMNI",
  "KAMNO",
  "KAMNU",
  "KAMOR",
  "KAMOV",
  "KAMPA",
  "KAMPE",
  "KAMPF",
  "KAMPI",
  "KAMPL",
  "KAMPO",
  "KAMPS",
  "KAMPU",
  "KAMRA",
  "KAMRE",
  "KAMRI",
  "KAMRO",
  "KAMŠT",
  "KANAH",
  "KANAJ",
  "KANAL",
  "KANAM",
  "KANAN",
  "KANAT",
  "KANCA",
  "KANCE",
  "KANCI",
  "KANCK",
  "KANCU",
  "KANDL",
  "KANDT",
  "KANEC",
  "KANEL",
  "KANEM",
  "KANEŠ",
  "KANEV",
  "KANGA",
  "KANGE",
  "KANGI",
  "KANGU",
  "KANIA",
  "KANIČ",
  "KANIE",
  "KANIH",
  "KANII",
  "KANIJ",
  "KANIL",
  "KANIM",
  "KANIN",
  "KANIO",
  "KANIS",
  "KANIŠ",
  "KANIT",
  "KANIU",
  "KANIV",
  "KANJA",
  "KANJE",
  "KANJI",
  "KANJO",
  "KANJU",
  "KANKA",
  "KANKE",
  "KANKI",
  "KANKO",
  "KANKU",
  "KANNA",
  "KANNE",
  "KANNI",
  "KANNO",
  "KANNU",
  "KANOM",
  "KANON",
  "KANOP",
  "KANOV",
  "KANTA",
  "KANTE",
  "KANTI",
  "KANTO",
  "KANTU",
  "KAORI",
  "KAORU",
  "KAOSA",
  "KAOSE",
  "KAOSI",
  "KAOSU",
  "KAPAH",
  "KAPAJ",
  "KAPAL",
  "KAPAM",
  "KAPAN",
  "KAPAR",
  "KAPAŠ",
  "KAPAT",
  "KAPAU",
  "KAPAV",
  "KAPCA",
  "KAPCE",
  "KAPCI",
  "KAPCO",
  "KAPEH",
  "KAPEL",
  "KAPEM",
  "KAPEN",
  "KAPER",
  "KAPEV",
  "KAPIC",
  "KAPIČ",
  "KAPIH",
  "KAPIL",
  "KAPIN",
  "KAPIS",
  "KAPJA",
  "KAPJO",
  "KAPJU",
  "KAPKA",
  "KAPKE",
  "KAPKI",
  "KAPKO",
  "KAPKU",
  "KAPLA",
  "KAPLE",
  "KAPLI",
  "KAPLO",
  "KAPLU",
  "KAPMI",
  "KAPNA",
  "KAPNE",
  "KAPNI",
  "KAPNO",
  "KAPNU",
  "KAPOK",
  "KAPOM",
  "KAPON",
  "KAPOR",
  "KAPOV",
  "KAPPA",
  "KAPPE",
  "KAPPI",
  "KAPPU",
  "KAPRA",
  "KAPRE",
  "KAPRI",
  "KAPRN",
  "KAPRO",
  "KAPSA",
  "KAPSE",
  "KAPSI",
  "KAPSU",
  "KAPŠA",
  "KAPŠE",
  "KAPŠI",
  "KAPŠU",
  "KAPUC",
  "KAPUN",
  "KAPUR",
  "KAPUS",
  "KAPUT",
  "KARAF",
  "KARAG",
  "KARAH",
  "KARAJ",
  "KARAL",
  "KARAM",
  "KARAN",
  "KARAR",
  "KARAS",
  "KARAŠ",
  "KARAT",
  "KARAU",
  "KARAV",
  "KARBA",
  "KARBE",
  "KARBI",
  "KARBO",
  "KARBU",
  "KARCI",
  "KARDA",
  "KARDE",
  "KARDI",
  "KARDO",
  "KARDU",
  "KAREL",
  "KAREM",
  "KAREN",
  "KARER",
  "KARET",
  "KAREV",
  "KARGA",
  "KARGE",
  "KARGI",
  "KARGO",
  "KARGU",
  "KARIČ",
  "KARIH",
  "KARIM",
  "KARIN",
  "KARIS",
  "KARIŠ",
  "KARIŽ",
  "KARJA",
  "KARJU",
  "KARLA",
  "KARLČ",
  "KARLE",
  "KARLI",
  "KARLO",
  "KARLU",
  "KARMA",
  "KARME",
  "KARMI",
  "KARMO",
  "KARNA",
  "KARNE",
  "KARNI",
  "KARNS",
  "KARNU",
  "KAROL",
  "KAROM",
  "KARON",
  "KAROV",
  "KARPA",
  "KARPE",
  "KARPF",
  "KARPI",
  "KARPO",
  "KARPU",
  "KARRA",
  "KARRE",
  "KARRH",
  "KARRI",
  "KARRO",
  "KARRU",
  "KARST",
  "KARTA",
  "KARTE",
  "KARTI",
  "KARTO",
  "KARTU",
  "KARUN",
  "KASAČ",
  "KASAI",
  "KASAK",
  "KASAL",
  "KASAN",
  "KASAP",
  "KASAR",
  "KASAŠ",
  "KASAV",
  "KASAZ",
  "KASBA",
  "KASBE",
  "KASBI",
  "KASBO",
  "KASBU",
  "KASCH",
  "KASEL",
  "KASEM",
  "KASEN",
  "KASES",
  "KASET",
  "KASHA",
  "KASHE",
  "KASHI",
  "KASHU",
  "KASIA",
  "KASIE",
  "KASII",
  "KASIJ",
  "KASIK",
  "KASIM",
  "KASIO",
  "KASJA",
  "KASJU",
  "KASKA",
  "KASKE",
  "KASKI",
  "KASKO",
  "KASKU",
  "KASLA",
  "KASLE",
  "KASLI",
  "KASLU",
  "KASNA",
  "KASNE",
  "KASNI",
  "KASNO",
  "KASNU",
  "KASOM",
  "KASPA",
  "KASPE",
  "KASPI",
  "KASPU",
  "KASSA",
  "KASSE",
  "KASSI",
  "KASSU",
  "KASTA",
  "KASTE",
  "KASTI",
  "KASTL",
  "KASTO",
  "KASTU",
  "KASUM",
  "KASUN",
  "KAŠAH",
  "KAŠAM",
  "KAŠAN",
  "KAŠCA",
  "KAŠCE",
  "KAŠCI",
  "KAŠCO",
  "KAŠCU",
  "KAŠČA",
  "KAŠČE",
  "KAŠČI",
  "KAŠČO",
  "KAŠEM",
  "KAŠEN",
  "KAŠER",
  "KAŠEV",
  "KAŠIC",
  "KAŠIČ",
  "KAŠIH",
  "KAŠJA",
  "KAŠJU",
  "KAŠKA",
  "KAŠKE",
  "KAŠKI",
  "KAŠKO",
  "KAŠNA",
  "KAŠNE",
  "KAŠNI",
  "KAŠNO",
  "KAŠOM",
  "KAŠOV",
  "KAŠUB",
  "KATAH",
  "KATAM",
  "KATAN",
  "KATAR",
  "KATEB",
  "KATEM",
  "KATER",
  "KATES",
  "KATET",
  "KATHA",
  "KATHE",
  "KATHI",
  "KATHO",
  "KATHU",
  "KATIA",
  "KATIC",
  "KATIČ",
  "KATIE",
  "KATIH",
  "KATII",
  "KATIJ",
  "KATIN",
  "KATIO",
  "KATIR",
  "KATJA",
  "KATJE",
  "KATJI",
  "KATJO",
  "KATJU",
  "KATKA",
  "KATKE",
  "KATKI",
  "KATKO",
  "KATOD",
  "KATOM",
  "KATON",
  "KATOV",
  "KATRA",
  "KATRC",
  "KATRE",
  "KATRI",
  "KATRO",
  "KATTA",
  "KATTE",
  "KATTI",
  "KATTU",
  "KATUN",
  "KATUŠ",
  "KATZA",
  "KATZE",
  "KATZI",
  "KATZU",
  "KAUBE",
  "KAUER",
  "KAUFA",
  "KAUFE",
  "KAUFF",
  "KAUFI",
  "KAUFU",
  "KAULA",
  "KAULE",
  "KAULI",
  "KAULU",
  "KAUND",
  "KAUPA",
  "KAUPE",
  "KAUPI",
  "KAUPO",
  "KAUPP",
  "KAUPU",
  "KAURI",
  "KAUSA",
  "KAUSE",
  "KAUSI",
  "KAUSO",
  "KAUSU",
  "KAVAH",
  "KAVAJ",
  "KAVAK",
  "KAVAL",
  "KAVAM",
  "KAVAR",
  "KAVAŠ",
  "KAVCA",
  "KAVCE",
  "KAVCI",
  "KAVCU",
  "KAVČA",
  "KAVČE",
  "KAVČI",
  "KAVČU",
  "KAVEC",
  "KAVEN",
  "KAVER",
  "KAVEV",
  "KAVIC",
  "KAVIN",
  "KAVIT",
  "KAVKA",
  "KAVKE",
  "KAVKI",
  "KAVKO",
  "KAVKU",
  "KAVNA",
  "KAVNE",
  "KAVNI",
  "KAVNO",
  "KAVOV",
  "KAVRA",
  "KAVRE",
  "KAVRI",
  "KAVRU",
  "KAVSA",
  "KAVSE",
  "KAVSI",
  "KAVSU",
  "KAVUR",
  "KAVUT",
  "KAVZA",
  "KAVZE",
  "KAVZI",
  "KAVZO",
  "KAZAH",
  "KAZAL",
  "KAZAM",
  "KAZAN",
  "KAZAT",
  "KAZAU",
  "KAZAV",
  "KAZEČ",
  "KAZEE",
  "KAZEN",
  "KAZIČ",
  "KAZIL",
  "KAZIM",
  "KAZIN",
  "KAZIŠ",
  "KAZIT",
  "KAZIU",
  "KAZIV",
  "KAZMI",
  "KAZNA",
  "KAZNE",
  "KAZNI",
  "KAZNU",
  "KAZUA",
  "KAZUE",
  "KAZUI",
  "KAZUL",
  "KAZUO",
  "KAZUU",
  "KAŽEM",
  "KAŽEŠ",
  "KAŽOČ",
  "KCTMO",
  "KDOVE",
  "KEACH",
  "KEALA",
  "KEALE",
  "KEALI",
  "KEALO",
  "KEAMA",
  "KEAMI",
  "KEAMS",
  "KEANA",
  "KEANE",
  "KEANI",
  "KEANN",
  "KEANO",
  "KEANU",
  "KEARA",
  "KEARE",
  "KEARI",
  "KEARO",
  "KEAST",
  "KEATS",
  "KEBAB",
  "KEBAP",
  "KEBAT",
  "KEBEL",
  "KEBER",
  "KEBET",
  "KEBIČ",
  "KEBLA",
  "KEBLE",
  "KEBLI",
  "KEBLU",
  "KEBRA",
  "KEBRE",
  "KEBRI",
  "KEBRU",
  "KECKA",
  "KECKE",
  "KECKI",
  "KECKO",
  "KECKU",
  "KEČAN",
  "KEČAP",
  "KEČEK",
  "KEČIG",
  "KEČKA",
  "KEČKE",
  "KEČKI",
  "KEČKU",
  "KEDAJ",
  "KEDAR",
  "KEDER",
  "KEDEŠ",
  "KEDMI",
  "KEDRA",
  "KEDRE",
  "KEDRI",
  "KEDRU",
  "KEEFA",
  "KEEFE",
  "KEEFI",
  "KEEFU",
  "KEEHN",
  "KEEKA",
  "KEEKU",
  "KEELA",
  "KEELE",
  "KEELI",
  "KEELU",
  "KEENA",
  "KEENE",
  "KEENI",
  "KEENO",
  "KEENU",
  "KEERL",
  "KEESA",
  "KEESE",
  "KEESI",
  "KEESU",
  "KEFAL",
  "KEFIH",
  "KEFIJ",
  "KEFIR",
  "KEFJA",
  "KEFJU",
  "KEFOM",
  "KEFOV",
  "KEGAN",
  "KEGEL",
  "KEGLA",
  "KEGLE",
  "KEGLI",
  "KEGLU",
  "KEHAH",
  "KEHAM",
  "KEHLA",
  "KEHLE",
  "KEHLI",
  "KEHLU",
  "KEHMA",
  "KEHME",
  "KEHMI",
  "KEHMU",
  "KEHOE",
  "KEHRA",
  "KEHRE",
  "KEHRI",
  "KEHRL",
  "KEHRU",
  "KEIJI",
  "KEIKI",
  "KEIKO",
  "KEIKS",
  "KEILA",
  "KEILE",
  "KEILI",
  "KEILS",
  "KEILU",
  "KEIMA",
  "KEIME",
  "KEIMI",
  "KEIMU",
  "KEINA",
  "KEINE",
  "KEINI",
  "KEINO",
  "KEINU",
  "KEIRA",
  "KEIRE",
  "KEIRI",
  "KEIRO",
  "KEIRU",
  "KEISH",
  "KEIŠS",
  "KEITA",
  "KEITE",
  "KEITH",
  "KEITI",
  "KEITO",
  "KEITT",
  "KEITU",
  "KEIVA",
  "KEIVE",
  "KEIVI",
  "KEIVO",
  "KEJDI",
  "KEJLA",
  "KEJLE",
  "KEJLI",
  "KEJLO",
  "KEJLU",
  "KEJSI",
  "KEKCA",
  "KEKCE",
  "KEKCI",
  "KEKCU",
  "KEKEC",
  "KEKIČ",
  "KEKIH",
  "KEKIN",
  "KEKJA",
  "KEKJU",
  "KEKOM",
  "KEKOŠ",
  "KEKOV",
  "KEKSA",
  "KEKSE",
  "KEKSI",
  "KEKSU",
  "KEKUŠ",
  "KELAG",
  "KELAH",
  "KELAM",
  "KELAT",
  "KELAV",
  "KELBL",
  "KELCA",
  "KELCE",
  "KELCI",
  "KELCU",
  "KELIH",
  "KELIN",
  "KELJA",
  "KELJU",
  "KELKA",
  "KELKE",
  "KELKI",
  "KELKK",
  "KELKU",
  "KELLA",
  "KELLE",
  "KELLI",
  "KELLS",
  "KELLU",
  "KELMA",
  "KELME",
  "KELMI",
  "KELMU",
  "KELOM",
  "KELOV",
  "KELSA",
  "KELSE",
  "KELSI",
  "KELSO",
  "KELSU",
  "KELTA",
  "KELTE",
  "KELTI",
  "KELTU",
  "KEMAL",
  "KEMBA",
  "KEMBE",
  "KEMBI",
  "KEMBO",
  "KEMBU",
  "KEMIK",
  "KEMIN",
  "KEMIS",
  "KEMMA",
  "KEMME",
  "KEMMI",
  "KEMMU",
  "KEMPA",
  "KEMPE",
  "KEMPF",
  "KEMPI",
  "KEMPK",
  "KEMPU",
  "KEMRA",
  "KEMRE",
  "KEMRI",
  "KEMRU",
  "KENAN",
  "KENCK",
  "KENDA",
  "KENDE",
  "KENDI",
  "KENDO",
  "KENDU",
  "KENEN",
  "KENER",
  "KENET",
  "KENGA",
  "KENGE",
  "KENGI",
  "KENGU",
  "KENIG",
  "KENIH",
  "KENIN",
  "KENJA",
  "KENJI",
  "KENJU",
  "KENKA",
  "KENKE",
  "KENKI",
  "KENKU",
  "KENNA",
  "KENNE",
  "KENNI",
  "KENNU",
  "KENOM",
  "KENOV",
  "KENTA",
  "KENTE",
  "KENTI",
  "KENTO",
  "KENTU",
  "KEOGH",
  "KEOMA",
  "KEOMI",
  "KEONA",
  "KEONE",
  "KEONI",
  "KEONU",
  "KEOPS",
  "KEOTA",
  "KEOTU",
  "KEOVA",
  "KEOVE",
  "KEOVI",
  "KEOVO",
  "KEPAH",
  "KEPAJ",
  "KEPAL",
  "KEPAM",
  "KEPAŠ",
  "KEPAT",
  "KEPAU",
  "KEPAV",
  "KEPER",
  "KEPEŠ",
  "KEPIC",
  "KEPIH",
  "KEPJA",
  "KEPJU",
  "KEPKA",
  "KEPKE",
  "KEPKI",
  "KEPKO",
  "KEPKU",
  "KEPOM",
  "KEPOV",
  "KEPRA",
  "KEPRE",
  "KEPRI",
  "KEPRU",
  "KERČA",
  "KERČE",
  "KERČI",
  "KERČU",
  "KEREC",
  "KEREM",
  "KEREP",
  "KERES",
  "KERHE",
  "KERIČ",
  "KERIG",
  "KERIH",
  "KERIJ",
  "KERIK",
  "KERIM",
  "KERIN",
  "KERJA",
  "KERJI",
  "KERJU",
  "KERLA",
  "KERLC",
  "KERLE",
  "KERLI",
  "KERLU",
  "KERMC",
  "KERNA",
  "KERNC",
  "KERNE",
  "KERNI",
  "KERNS",
  "KERNU",
  "KEROM",
  "KEROS",
  "KEROV",
  "KERRA",
  "KERRE",
  "KERRI",
  "KERRU",
  "KERST",
  "KERŠA",
  "KERŠE",
  "KERŠI",
  "KERŠU",
  "KERTA",
  "KERTE",
  "KERTI",
  "KERTU",
  "KERUB",
  "KERUM",
  "KERŽE",
  "KESAJ",
  "KESAL",
  "KESAM",
  "KESAR",
  "KESAŠ",
  "KESAT",
  "KESAU",
  "KESAV",
  "KESEM",
  "KESIČ",
  "KESJA",
  "KESJU",
  "KESOM",
  "KESON",
  "KESSA",
  "KESSE",
  "KESSI",
  "KESSU",
  "KEŠAR",
  "KEŠEM",
  "KEŠEV",
  "KEŠIH",
  "KEŠIN",
  "KEŠJA",
  "KEŠJU",
  "KEŠOM",
  "KEŠOV",
  "KETAH",
  "KETAM",
  "KETEN",
  "KETIH",
  "KETIL",
  "KETIŠ",
  "KETJA",
  "KETJU",
  "KETNA",
  "KETNE",
  "KETNI",
  "KETNO",
  "KETOL",
  "KETOM",
  "KETON",
  "KETOV",
  "KETOZ",
  "KETSA",
  "KETSE",
  "KETSI",
  "KETSU",
  "KETTA",
  "KETTE",
  "KETTI",
  "KETTU",
  "KEUCA",
  "KEUCE",
  "KEUCI",
  "KEUCU",
  "KEULA",
  "KEULE",
  "KEULI",
  "KEULS",
  "KEULU",
  "KEUMA",
  "KEUME",
  "KEUMI",
  "KEUMU",
  "KEUNE",
  "KEUPA",
  "KEUPE",
  "KEUPI",
  "KEUPP",
  "KEUPU",
  "KEVAN",
  "KEVIH",
  "KEVIN",
  "KEVJA",
  "KEVJU",
  "KEVOM",
  "KEVON",
  "KEVOV",
  "KEZIH",
  "KEZJA",
  "KEZJU",
  "KEZOM",
  "KEZOV",
  "KEŽAR",
  "KFORA",
  "KFORU",
  "KFURI",
  "KGARI",
  "KGLJU",
  "KHALI",
  "KHAMA",
  "KHAME",
  "KHAMI",
  "KHAMU",
  "KHANA",
  "KHANE",
  "KHANI",
  "KHANN",
  "KHANU",
  "KHERA",
  "KHERE",
  "KHERI",
  "KHERU",
  "KHIAR",
  "KHLOE",
  "KHOLA",
  "KHOLE",
  "KHOLI",
  "KHOLU",
  "KHRIS",
  "KHUON",
  "KIAMA",
  "KIAMI",
  "KIANA",
  "KIANE",
  "KIANG",
  "KIANI",
  "KIANN",
  "KIANO",
  "KIANU",
  "KIARA",
  "KIARE",
  "KIARI",
  "KIARO",
  "KIBAR",
  "KIBBE",
  "KIBEL",
  "KIBIC",
  "KIBLA",
  "KIBLE",
  "KIBLI",
  "KIBLO",
  "KIBUC",
  "KICAH",
  "KICAJ",
  "KICAM",
  "KICAR",
  "KICEM",
  "KICEV",
  "KICIH",
  "KICJA",
  "KICJU",
  "KICKL",
  "KICOM",
  "KICOV",
  "KIČEM",
  "KIČEV",
  "KIČIN",
  "KIČJA",
  "KIČJU",
  "KIČOM",
  "KIČOV",
  "KIDAJ",
  "KIDAL",
  "KIDAM",
  "KIDAN",
  "KIDAŠ",
  "KIDAT",
  "KIDAU",
  "KIDAV",
  "KIDDA",
  "KIDDE",
  "KIDDI",
  "KIDDU",
  "KIDIH",
  "KIDIP",
  "KIDJA",
  "KIDJU",
  "KIDOM",
  "KIDOV",
  "KIEFL",
  "KIELA",
  "KIELC",
  "KIELU",
  "KIERA",
  "KIERE",
  "KIERI",
  "KIERO",
  "KIETH",
  "KIFFA",
  "KIFFE",
  "KIFFI",
  "KIFFU",
  "KIFOZ",
  "KIHAJ",
  "KIHAL",
  "KIHAM",
  "KIHAR",
  "KIHAŠ",
  "KIHAT",
  "KIHAU",
  "KIHAV",
  "KIHEM",
  "KIHEV",
  "KIHIH",
  "KIHJA",
  "KIHJU",
  "KIHNE",
  "KIHNI",
  "KIHNU",
  "KIHOM",
  "KIHOT",
  "KIHOV",
  "KIILA",
  "KIILE",
  "KIILI",
  "KIILU",
  "KIJAK",
  "KIJCA",
  "KIJCE",
  "KIJCI",
  "KIJCU",
  "KIJEC",
  "KIJEM",
  "KIJEV",
  "KIJIH",
  "KIJIN",
  "KIJJA",
  "KIJJU",
  "KIJOM",
  "KIJOV",
  "KIKAH",
  "KIKAJ",
  "KIKAM",
  "KIKAŠ",
  "KIKCA",
  "KIKCE",
  "KIKCI",
  "KIKCU",
  "KIKEC",
  "KIKEL",
  "KIKIH",
  "KIKIN",
  "KIKJA",
  "KIKJU",
  "KIKLA",
  "KIKLE",
  "KIKLI",
  "KIKLO",
  "KIKLU",
  "KIKOM",
  "KIKOV",
  "KIKSA",
  "KIKSE",
  "KIKSI",
  "KIKSU",
  "KIKUT",
  "KILAH",
  "KILAJ",
  "KILAM",
  "KILAR",
  "KILAV",
  "KILAŽ",
  "KILBA",
  "KILBE",
  "KILBI",
  "KILBU",
  "KILDE",
  "KILEN",
  "KILER",
  "KILHA",
  "KILHE",
  "KILHI",
  "KILHU",
  "KILIK",
  "KILIN",
  "KILIS",
  "KILLE",
  "KILMI",
  "KILNA",
  "KILNE",
  "KILNI",
  "KILNO",
  "KILTA",
  "KILTE",
  "KILTI",
  "KILTU",
  "KIMAJ",
  "KIMAL",
  "KIMAM",
  "KIMAŠ",
  "KIMAT",
  "KIMAU",
  "KIMAV",
  "KIMBI",
  "KIMČI",
  "KIMIH",
  "KIMIN",
  "KIMJA",
  "KIMJU",
  "KIMLA",
  "KIMLE",
  "KIMLI",
  "KIMLO",
  "KIMLU",
  "KIMOM",
  "KIMOR",
  "KIMOV",
  "KIMPE",
  "KIMUR",
  "KINAZ",
  "KINCK",
  "KINCL",
  "KINČL",
  "KINDA",
  "KINDE",
  "KINDI",
  "KINDL",
  "KINDO",
  "KINDT",
  "KINDU",
  "KINEM",
  "KINET",
  "KINEV",
  "KINGA",
  "KINGE",
  "KINGI",
  "KINGO",
  "KINGS",
  "KINGU",
  "KINIH",
  "KINIK",
  "KININ",
  "KINJA",
  "KINJU",
  "KINKA",
  "KINKE",
  "KINKI",
  "KINKO",
  "KINKU",
  "KINOM",
  "KINON",
  "KINOV",
  "KINTA",
  "KINTE",
  "KINTI",
  "KINTU",
  "KIOSK",
  "KIPAJ",
  "KIPAL",
  "KIPAM",
  "KIPAR",
  "KIPAŠ",
  "KIPAT",
  "KIPAU",
  "KIPAV",
  "KIPCA",
  "KIPCE",
  "KIPCI",
  "KIPCU",
  "KIPEC",
  "KIPEČ",
  "KIPEL",
  "KIPEM",
  "KIPER",
  "KIPET",
  "KIPEU",
  "KIPEV",
  "KIPIH",
  "KIPIM",
  "KIPIŠ",
  "KIPJA",
  "KIPJU",
  "KIPKE",
  "KIPOM",
  "KIPOV",
  "KIPPA",
  "KIPPE",
  "KIPPI",
  "KIPPS",
  "KIPPU",
  "KIRAH",
  "KIRAM",
  "KIRAN",
  "KIRAR",
  "KIRAZ",
  "KIRCH",
  "KIREM",
  "KIRET",
  "KIRIČ",
  "KIRIH",
  "KIRIJ",
  "KIRIK",
  "KIRIL",
  "KIRIM",
  "KIRIN",
  "KIRJA",
  "KIRJU",
  "KIRKA",
  "KIRKE",
  "KIRKI",
  "KIRKU",
  "KIRMA",
  "KIRME",
  "KIRMI",
  "KIRMU",
  "KIRNA",
  "KIRNE",
  "KIRNI",
  "KIRNJ",
  "KIRNU",
  "KIROM",
  "KIRON",
  "KIROV",
  "KIRSA",
  "KIRSE",
  "KIRSI",
  "KIRSO",
  "KIRTI",
  "KISAJ",
  "KISAL",
  "KISAM",
  "KISAN",
  "KISAŠ",
  "KISAT",
  "KISAU",
  "KISAV",
  "KISCH",
  "KISEL",
  "KISEM",
  "KISEV",
  "KISHA",
  "KISHE",
  "KISHI",
  "KISHO",
  "KISHU",
  "KISIH",
  "KISIK",
  "KISIN",
  "KISJA",
  "KISJU",
  "KISKA",
  "KISKE",
  "KISKI",
  "KISKO",
  "KISKU",
  "KISLA",
  "KISLC",
  "KISLE",
  "KISLI",
  "KISLO",
  "KISLU",
  "KISOM",
  "KISOV",
  "KISSA",
  "KISSE",
  "KISSI",
  "KISSU",
  "KISZK",
  "KIŠEM",
  "KIŠEV",
  "KIŠID",
  "KIŠIH",
  "KIŠJA",
  "KIŠJU",
  "KIŠKA",
  "KIŠKE",
  "KIŠKI",
  "KIŠKO",
  "KIŠKU",
  "KIŠOM",
  "KIŠOV",
  "KIŠTA",
  "KIŠTE",
  "KIŠTI",
  "KIŠTO",
  "KITAH",
  "KITAJ",
  "KITAK",
  "KITAL",
  "KITAM",
  "KITAN",
  "KITAR",
  "KITAŠ",
  "KITAT",
  "KITAU",
  "KITAV",
  "KITEK",
  "KITEL",
  "KITEM",
  "KITEN",
  "KITEV",
  "KITIC",
  "KITIČ",
  "KITIH",
  "KITIL",
  "KITIM",
  "KITIN",
  "KITIŠ",
  "KITIT",
  "KITIU",
  "KITIV",
  "KITJA",
  "KITJU",
  "KITKA",
  "KITKE",
  "KITKI",
  "KITKO",
  "KITKU",
  "KITLA",
  "KITLE",
  "KITLI",
  "KITLU",
  "KITNA",
  "KITNE",
  "KITNI",
  "KITNO",
  "KITOM",
  "KITOV",
  "KITTA",
  "KITTE",
  "KITTI",
  "KITTO",
  "KITTU",
  "KITZL",
  "KIURU",
  "KIVET",
  "KIZIL",
  "KIZIM",
  "KJAER",
  "KJELL",
  "KJERA",
  "KJERE",
  "KJERI",
  "KJERU",
  "KJOTA",
  "KJOTO",
  "KJOTU",
  "KJUSA",
  "KJUSE",
  "KJUSI",
  "KJUSU",
  "KJUŠU",
  "KLAAS",
  "KLADA",
  "KLADE",
  "KLADI",
  "KLADO",
  "KLADU",
  "KLAFF",
  "KLAIN",
  "KLAJA",
  "KLAJE",
  "KLAJI",
  "KLAJN",
  "KLAJO",
  "KLAJU",
  "KLAKA",
  "KLAKE",
  "KLAKI",
  "KLAKO",
  "KLAKU",
  "KLALA",
  "KLALE",
  "KLALI",
  "KLALO",
  "KLAMA",
  "KLAME",
  "KLAMF",
  "KLAMI",
  "KLAMT",
  "KLAMU",
  "KLANA",
  "KLANC",
  "KLAND",
  "KLANE",
  "KLANI",
  "KLANJ",
  "KLANN",
  "KLANO",
  "KLANU",
  "KLAPA",
  "KLAPE",
  "KLAPI",
  "KLAPO",
  "KLAPP",
  "KLAPŠ",
  "KLAPU",
  "KLARA",
  "KLARE",
  "KLARI",
  "KLARO",
  "KLARU",
  "KLASA",
  "KLASE",
  "KLASI",
  "KLASS",
  "KLASU",
  "KLATI",
  "KLATT",
  "KLATU",
  "KLAUE",
  "KLAUK",
  "KLAUS",
  "KLAUT",
  "KLAVC",
  "KLAVK",
  "KLAVS",
  "KLAVŽ",
  "KLEBE",
  "KLEBS",
  "KLECA",
  "KLECI",
  "KLECK",
  "KLECU",
  "KLEČA",
  "KLEČE",
  "KLEČI",
  "KLEČU",
  "KLEDE",
  "KLEEB",
  "KLEEF",
  "KLEES",
  "KLEFF",
  "KLEIN",
  "KLEJA",
  "KLEJE",
  "KLEJI",
  "KLEJO",
  "KLEJU",
  "KLEKA",
  "KLEKE",
  "KLEKI",
  "KLEKL",
  "KLEKU",
  "KLELA",
  "KLELE",
  "KLELI",
  "KLELO",
  "KLEMA",
  "KLEME",
  "KLEMI",
  "KLEMM",
  "KLEMO",
  "KLEMP",
  "KLEMT",
  "KLEMU",
  "KLENA",
  "KLENE",
  "KLENI",
  "KLENK",
  "KLENO",
  "KLENU",
  "KLEPA",
  "KLEPE",
  "KLEPI",
  "KLEPL",
  "KLEPU",
  "KLERA",
  "KLERI",
  "KLERK",
  "KLERU",
  "KLEST",
  "KLESU",
  "KLEŠČ",
  "KLEŠE",
  "KLEŠI",
  "KLETA",
  "KLETE",
  "KLETI",
  "KLETK",
  "KLETT",
  "KLETU",
  "KLEVA",
  "KLEVE",
  "KLEVI",
  "KLEVO",
  "KLEVU",
  "KLIBA",
  "KLIBE",
  "KLIBI",
  "KLIBO",
  "KLIBU",
  "KLICA",
  "KLICE",
  "KLICI",
  "KLICK",
  "KLICO",
  "KLICU",
  "KLIČA",
  "KLIČE",
  "KLIČI",
  "KLIČU",
  "KLIER",
  "KLIFA",
  "KLIFE",
  "KLIFF",
  "KLIFI",
  "KLIFU",
  "KLIJE",
  "KLIKA",
  "KLIKE",
  "KLIKI",
  "KLIKO",
  "KLIKU",
  "KLILA",
  "KLILE",
  "KLILI",
  "KLILO",
  "KLIMA",
  "KLIME",
  "KLIMI",
  "KLIMO",
  "KLIMT",
  "KLIMU",
  "KLINA",
  "KLINC",
  "KLINČ",
  "KLINE",
  "KLING",
  "KLINI",
  "KLINK",
  "KLINO",
  "KLINT",
  "KLINU",
  "KLIŠE",
  "KLITI",
  "KLJUB",
  "KLJUČ",
  "KLJUJ",
  "KLJUK",
  "KLJUN",
  "KLOAK",
  "KLOAR",
  "KLOBK",
  "KLOCK",
  "KLOFA",
  "KLOFI",
  "KLOFT",
  "KLOFU",
  "KLOMP",
  "KLONA",
  "KLONE",
  "KLONI",
  "KLONU",
  "KLOOS",
  "KLOPA",
  "KLOPC",
  "KLOPE",
  "KLOPF",
  "KLOPI",
  "KLOPP",
  "KLOPU",
  "KLORA",
  "KLORI",
  "KLORU",
  "KLOSA",
  "KLOSE",
  "KLOSI",
  "KLOSS",
  "KLOSU",
  "KLOTZ",
  "KLOVN",
  "KLOŽA",
  "KLOŽE",
  "KLOŽI",
  "KLOŽO",
  "KLUBA",
  "KLUBE",
  "KLUBI",
  "KLUBU",
  "KLUCK",
  "KLUFT",
  "KLUGA",
  "KLUGE",
  "KLUGI",
  "KLUGU",
  "KLUIN",
  "KLUMA",
  "KLUME",
  "KLUMI",
  "KLUMP",
  "KLUMU",
  "KLUNA",
  "KLUNE",
  "KLUNI",
  "KLUNU",
  "KLUSH",
  "KLUTH",
  "KLUŽE",
  "KMALO",
  "KMALU",
  "KMECL",
  "KMENT",
  "KMERA",
  "KMERE",
  "KMERI",
  "KMERK",
  "KMERU",
  "KMETA",
  "KMETE",
  "KMETI",
  "KMETU",
  "KMETZ",
  "KMITA",
  "KMITE",
  "KMITI",
  "KMITU",
  "KNAAK",
  "KNAAP",
  "KNABA",
  "KNABE",
  "KNABI",
  "KNABU",
  "KNAKA",
  "KNAKE",
  "KNAKI",
  "KNAKU",
  "KNAMA",
  "KNAME",
  "KNAMI",
  "KNAMU",
  "KNAPA",
  "KNAPE",
  "KNAPI",
  "KNAPP",
  "KNAPU",
  "KNATA",
  "KNATE",
  "KNATI",
  "KNATU",
  "KNAUB",
  "KNAUF",
  "KNAUP",
  "KNAUS",
  "KNAUT",
  "KNAVS",
  "KNDNS",
  "KNEBL",
  "KNEFA",
  "KNEFE",
  "KNEFI",
  "KNEFU",
  "KNEGA",
  "KNEGE",
  "KNEGI",
  "KNEGO",
  "KNEGU",
  "KNEJA",
  "KNEJK",
  "KNEJU",
  "KNELL",
  "KNEPA",
  "KNEPE",
  "KNEPI",
  "KNEPP",
  "KNEPU",
  "KNETA",
  "KNETE",
  "KNETI",
  "KNETT",
  "KNETU",
  "KNEZA",
  "KNEZE",
  "KNEZI",
  "KNEZU",
  "KNEŽA",
  "KNEŽE",
  "KNEŽI",
  "KNEŽO",
  "KNIBB",
  "KNIES",
  "KNIGA",
  "KNIGE",
  "KNIGI",
  "KNIGO",
  "KNIKA",
  "KNIKU",
  "KNILL",
  "KNINA",
  "KNINU",
  "KNJAZ",
  "KNJIG",
  "KNOBL",
  "KNOCK",
  "KNODA",
  "KNODE",
  "KNODI",
  "KNODU",
  "KNOFA",
  "KNOFE",
  "KNOFI",
  "KNOFU",
  "KNOGL",
  "KNOKA",
  "KNOKE",
  "KNOKI",
  "KNOKU",
  "KNOLA",
  "KNOLE",
  "KNOLI",
  "KNOLL",
  "KNOLU",
  "KNOOP",
  "KNOOT",
  "KNOPA",
  "KNOPE",
  "KNOPF",
  "KNOPI",
  "KNOPP",
  "KNOPS",
  "KNOPU",
  "KNORR",
  "KNOTA",
  "KNOTE",
  "KNOTI",
  "KNOTT",
  "KNOTU",
  "KNOUF",
  "KNOVS",
  "KNUDA",
  "KNUDE",
  "KNUDI",
  "KNUDU",
  "KNUFA",
  "KNUFE",
  "KNUFI",
  "KNUFU",
  "KNUPP",
  "KNUTA",
  "KNUTE",
  "KNUTH",
  "KNUTI",
  "KNUTO",
  "KNUTT",
  "KNUTU",
  "KOALA",
  "KOALE",
  "KOALI",
  "KOALL",
  "KOALO",
  "KOANA",
  "KOANE",
  "KOANI",
  "KOANU",
  "KOATA",
  "KOATE",
  "KOATI",
  "KOATO",
  "KOBAL",
  "KOBAN",
  "KOBAV",
  "KOBCA",
  "KOBCE",
  "KOBCI",
  "KOBCU",
  "KOBEC",
  "KOBEJ",
  "KOBEL",
  "KOBER",
  "KOBIE",
  "KOBIL",
  "KOBIN",
  "KOBLA",
  "KOBLE",
  "KOBLI",
  "KOBLO",
  "KOBOL",
  "KOBRA",
  "KOBRE",
  "KOBRI",
  "KOBRO",
  "KOBSA",
  "KOBSE",
  "KOBSI",
  "KOBSU",
  "KOBUL",
  "KOBUS",
  "KOCAJ",
  "KOCAK",
  "KOCAN",
  "KOCEM",
  "KOCEN",
  "KOCET",
  "KOCEV",
  "KOCHA",
  "KOCHE",
  "KOCHI",
  "KOCHU",
  "KOCIČ",
  "KOCIH",
  "KOCIN",
  "KOCJA",
  "KOCJU",
  "KOCKA",
  "KOCKE",
  "KOCKI",
  "KOCKO",
  "KOCKU",
  "KOCOM",
  "KOCON",
  "KOCOV",
  "KOCUR",
  "KOČAH",
  "KOČAM",
  "KOČAN",
  "KOČAR",
  "KOČEN",
  "KOČET",
  "KOČIC",
  "KOČIČ",
  "KOČIJ",
  "KOČIL",
  "KOČIŠ",
  "KOČKA",
  "KOČKE",
  "KOČKI",
  "KOČKO",
  "KOČNA",
  "KOČNE",
  "KOČNI",
  "KOČNO",
  "KOČUR",
  "KODAH",
  "KODAK",
  "KODAL",
  "KODAM",
  "KODAR",
  "KODBA",
  "KODBE",
  "KODBI",
  "KODBO",
  "KODBU",
  "KODEH",
  "KODEK",
  "KODEL",
  "KODEM",
  "KODEN",
  "KODER",
  "KODEV",
  "KODIH",
  "KODIL",
  "KODJA",
  "KODJU",
  "KODNA",
  "KODNE",
  "KODNI",
  "KODNO",
  "KODOM",
  "KODON",
  "KODOV",
  "KODRA",
  "KODRC",
  "KODRE",
  "KODRI",
  "KODRU",
  "KODSI",
  "KODZA",
  "KODZE",
  "KODZI",
  "KODZU",
  "KOEHL",
  "KOEHN",
  "KOEKA",
  "KOEKE",
  "KOEKI",
  "KOEKU",
  "KOELN",
  "KOELZ",
  "KOENA",
  "KOENE",
  "KOENI",
  "KOENU",
  "KOEPK",
  "KOEPP",
  "KOERL",
  "KOERS",
  "KOFFA",
  "KOFFE",
  "KOFFI",
  "KOFFK",
  "KOFFU",
  "KOFOD",
  "KOFOL",
  "KOFOZ",
  "KOGAL",
  "KOGAN",
  "KOGAR",
  "KOGEJ",
  "KOGEL",
  "KOGGE",
  "KOGJA",
  "KOGJU",
  "KOGLA",
  "KOGLU",
  "KOGOJ",
  "KOGOM",
  "KOGON",
  "KOGOV",
  "KOHAN",
  "KOHAR",
  "KOHEK",
  "KOHIH",
  "KOHJA",
  "KOHJU",
  "KOHKA",
  "KOHKE",
  "KOHKI",
  "KOHKU",
  "KOHLA",
  "KOHLE",
  "KOHLI",
  "KOHLU",
  "KOHNA",
  "KOHNE",
  "KOHNI",
  "KOHNU",
  "KOHOM",
  "KOHON",
  "KOHOV",
  "KOHRS",
  "KOHRT",
  "KOHSA",
  "KOHSE",
  "KOHSI",
  "KOHSU",
  "KOHUT",
  "KOIJA",
  "KOIJU",
  "KOITA",
  "KOJCA",
  "KOJCE",
  "KOJCI",
  "KOJCU",
  "KOJEM",
  "KOJIČ",
  "KOJIH",
  "KOJIM",
  "KOJOT",
  "KOKAH",
  "KOKAL",
  "KOKAM",
  "KOKAN",
  "KOKAŠ",
  "KOKEM",
  "KOKER",
  "KOKEŠ",
  "KOKET",
  "KOKEV",
  "KOKIC",
  "KOKIH",
  "KOKIL",
  "KOKIN",
  "KOKJA",
  "KOKJU",
  "KOKLA",
  "KOKLE",
  "KOKLI",
  "KOKLU",
  "KOKOL",
  "KOKOM",
  "KOKON",
  "KOKOS",
  "KOKOŠ",
  "KOKOT",
  "KOKOV",
  "KOKRA",
  "KOKRE",
  "KOKRI",
  "KOKRO",
  "KOKSA",
  "KOKSI",
  "KOKSU",
  "KOLAČ",
  "KOLAH",
  "KOLAK",
  "KOLAM",
  "KOLAN",
  "KOLAR",
  "KOLAT",
  "KOLAŽ",
  "KOLBA",
  "KOLBE",
  "KOLBI",
  "KOLBL",
  "KOLBU",
  "KOLCA",
  "KOLCE",
  "KOLCI",
  "KOLCU",
  "KOLČA",
  "KOLČE",
  "KOLČI",
  "KOLČU",
  "KOLEB",
  "KOLEC",
  "KOLEH",
  "KOLEK",
  "KOLEM",
  "KOLEN",
  "KOLER",
  "KOLES",
  "KOLEŠ",
  "KOLET",
  "KOLEV",
  "KOLFF",
  "KOLIB",
  "KOLIČ",
  "KOLIG",
  "KOLIH",
  "KOLIK",
  "KOLIN",
  "KOLJA",
  "KOLJE",
  "KOLJI",
  "KOLJO",
  "KOLJU",
  "KOLKA",
  "KOLKE",
  "KOLKI",
  "KOLKO",
  "KOLKU",
  "KOLLA",
  "KOLLE",
  "KOLLI",
  "KOLLO",
  "KOLLU",
  "KOLMA",
  "KOLME",
  "KOLMI",
  "KOLMU",
  "KOLNE",
  "KOLNI",
  "KOLOM",
  "KOLON",
  "KOLOS",
  "KOLOŠ",
  "KOLOV",
  "KOLPA",
  "KOLPE",
  "KOLPI",
  "KOLPO",
  "KOLTA",
  "KOLTE",
  "KOLTI",
  "KOLTU",
  "KOLUT",
  "KOMAC",
  "KOMAD",
  "KOMAH",
  "KOMAJ",
  "KOMAM",
  "KOMAN",
  "KOMAR",
  "KOMAS",
  "KOMAT",
  "KOMBI",
  "KOMED",
  "KOMEJ",
  "KOMEL",
  "KOMEN",
  "KOMER",
  "KOMET",
  "KOMIK",
  "KOMIS",
  "KOMIT",
  "KOMMA",
  "KOMME",
  "KOMMI",
  "KOMMU",
  "KOMNA",
  "KOMNE",
  "KOMNI",
  "KOMNO",
  "KOMNU",
  "KOMOD",
  "KOMOR",
  "KOMOT",
  "KOMPA",
  "KOMPE",
  "KOMPI",
  "KOMPU",
  "KOMSI",
  "KOMUN",
  "KOMUR",
  "KONAN",
  "KONAR",
  "KONCA",
  "KONCE",
  "KONCI",
  "KONCU",
  "KONCZ",
  "KONČA",
  "KONČI",
  "KONČU",
  "KONDA",
  "KONDE",
  "KONDI",
  "KONDO",
  "KONDU",
  "KONEC",
  "KONEV",
  "KONGA",
  "KONGE",
  "KONGI",
  "KONGO",
  "KONGU",
  "KONHA",
  "KONHE",
  "KONHI",
  "KONHO",
  "KONIC",
  "KONIČ",
  "KONIG",
  "KONIH",
  "KONIK",
  "KONJA",
  "KONJC",
  "KONJE",
  "KONJI",
  "KONJU",
  "KONNA",
  "KONNE",
  "KONNI",
  "KONNO",
  "KONNU",
  "KONOM",
  "KONOV",
  "KONSA",
  "KONSE",
  "KONSI",
  "KONSU",
  "KONTA",
  "KONTE",
  "KONTI",
  "KONTO",
  "KONTU",
  "KONUK",
  "KONUL",
  "KONUS",
  "KONZI",
  "KOOBA",
  "KOOBE",
  "KOOBI",
  "KOOBU",
  "KOOIJ",
  "KOOJA",
  "KOOJE",
  "KOOJI",
  "KOOJU",
  "KOOKA",
  "KOOKE",
  "KOOKI",
  "KOOKU",
  "KOOLA",
  "KOOLE",
  "KOOLI",
  "KOOLU",
  "KOONA",
  "KOONE",
  "KOONI",
  "KOONS",
  "KOONU",
  "KOOPA",
  "KOOPE",
  "KOOPI",
  "KOOPS",
  "KOOPU",
  "KOPAČ",
  "KOPAH",
  "KOPAJ",
  "KOPAL",
  "KOPAM",
  "KOPAN",
  "KOPAR",
  "KOPAS",
  "KOPAŠ",
  "KOPAT",
  "KOPAU",
  "KOPAV",
  "KOPEL",
  "KOPEM",
  "KOPEN",
  "KOPER",
  "KOPEV",
  "KOPFA",
  "KOPFE",
  "KOPFI",
  "KOPFU",
  "KOPIC",
  "KOPIČ",
  "KOPIH",
  "KOPIJ",
  "KOPIN",
  "KOPIT",
  "KOPJA",
  "KOPJE",
  "KOPJI",
  "KOPJU",
  "KOPNA",
  "KOPNE",
  "KOPNI",
  "KOPNO",
  "KOPNU",
  "KOPOM",
  "KOPOV",
  "KOPPA",
  "KOPPE",
  "KOPPI",
  "KOPPU",
  "KOPRA",
  "KOPRC",
  "KOPRI",
  "KOPRU",
  "KOPSA",
  "KOPSE",
  "KOPSI",
  "KOPSU",
  "KOPŠE",
  "KOPTA",
  "KOPTE",
  "KOPTI",
  "KOPTU",
  "KOPUČ",
  "KOPUL",
  "KOPUN",
  "KORAB",
  "KORAČ",
  "KORAD",
  "KORAH",
  "KORAK",
  "KORAL",
  "KORAM",
  "KORAN",
  "KORAR",
  "KORAS",
  "KORAT",
  "KORBA",
  "KORBE",
  "KORBI",
  "KORBO",
  "KORBU",
  "KORCA",
  "KORCE",
  "KORCI",
  "KORCU",
  "KORČE",
  "KORDA",
  "KORDE",
  "KORDI",
  "KORDO",
  "KORDU",
  "KOREC",
  "KOREJ",
  "KOREM",
  "KOREN",
  "KORES",
  "KORET",
  "KOREV",
  "KOREZ",
  "KORFA",
  "KORFE",
  "KORFF",
  "KORFI",
  "KORFU",
  "KORIČ",
  "KORID",
  "KORIE",
  "KORIH",
  "KORIK",
  "KORIN",
  "KORIR",
  "KORIT",
  "KORJA",
  "KORJU",
  "KORMA",
  "KORME",
  "KORMI",
  "KORMU",
  "KORNA",
  "KORNE",
  "KORNI",
  "KORNO",
  "KORNU",
  "KOROL",
  "KOROM",
  "KORON",
  "KOROŠ",
  "KOROT",
  "KOROV",
  "KORPA",
  "KORPE",
  "KORPI",
  "KORPU",
  "KORSA",
  "KORSE",
  "KORSH",
  "KORSI",
  "KORSU",
  "KORTA",
  "KORTE",
  "KORTH",
  "KORTI",
  "KORTU",
  "KORUN",
  "KORUZ",
  "KORVA",
  "KORVE",
  "KORVI",
  "KORVO",
  "KORVU",
  "KORZA",
  "KORZE",
  "KORZI",
  "KORZO",
  "KORZU",
  "KORŽE",
  "KOSAH",
  "KOSAJ",
  "KOSAL",
  "KOSAM",
  "KOSAR",
  "KOSAŠ",
  "KOSAT",
  "KOSAU",
  "KOSAV",
  "KOSCA",
  "KOSCE",
  "KOSCH",
  "KOSCI",
  "KOSCU",
  "KOSEC",
  "KOSEČ",
  "KOSEM",
  "KOSER",
  "KOSEV",
  "KOSEZ",
  "KOSIC",
  "KOSIČ",
  "KOSIH",
  "KOSIL",
  "KOSIM",
  "KOSIN",
  "KOSIŠ",
  "KOSIT",
  "KOSIU",
  "KOSIV",
  "KOSJA",
  "KOSJE",
  "KOSJI",
  "KOSJO",
  "KOSJU",
  "KOSKI",
  "KOSLA",
  "KOSLE",
  "KOSLI",
  "KOSLO",
  "KOSLU",
  "KOSMA",
  "KOSME",
  "KOSMI",
  "KOSMO",
  "KOSMU",
  "KOSOM",
  "KOSOR",
  "KOSOV",
  "KOSSA",
  "KOSSE",
  "KOSSI",
  "KOSSO",
  "KOSSU",
  "KOSTA",
  "KOSTE",
  "KOSTI",
  "KOSTO",
  "KOSTU",
  "KOSUN",
  "KOŠAK",
  "KOŠAN",
  "KOŠAR",
  "KOŠAT",
  "KOŠCA",
  "KOŠCE",
  "KOŠCI",
  "KOŠCU",
  "KOŠČC",
  "KOŠEC",
  "KOŠEK",
  "KOŠEM",
  "KOŠEN",
  "KOŠER",
  "KOŠEV",
  "KOŠIC",
  "KOŠIČ",
  "KOŠIH",
  "KOŠIN",
  "KOŠIR",
  "KOŠIŠ",
  "KOŠJA",
  "KOŠJU",
  "KOŠKA",
  "KOŠKE",
  "KOŠKI",
  "KOŠKO",
  "KOŠKU",
  "KOŠNA",
  "KOŠNE",
  "KOŠNI",
  "KOŠNO",
  "KOŠOM",
  "KOŠOV",
  "KOŠTA",
  "KOŠTE",
  "KOŠTI",
  "KOŠTO",
  "KOŠTU",
  "KOŠUT",
  "KOTAČ",
  "KOTAH",
  "KOTAM",
  "KOTAR",
  "KOTEČ",
  "KOTEK",
  "KOTEL",
  "KOTEM",
  "KOTEN",
  "KOTER",
  "KOTEV",
  "KOTIG",
  "KOTIH",
  "KOTIK",
  "KOTIL",
  "KOTIM",
  "KOTIN",
  "KOTIS",
  "KOTIŠ",
  "KOTIT",
  "KOTIU",
  "KOTIV",
  "KOTJA",
  "KOTJU",
  "KOTKA",
  "KOTKE",
  "KOTKI",
  "KOTKU",
  "KOTLA",
  "KOTLE",
  "KOTLI",
  "KOTLU",
  "KOTNA",
  "KOTNE",
  "KOTNI",
  "KOTNO",
  "KOTOK",
  "KOTOM",
  "KOTOR",
  "KOTOV",
  "KOTTA",
  "KOTTE",
  "KOTTI",
  "KOTTO",
  "KOTTU",
  "KOTUL",
  "KOTUR",
  "KOTVA",
  "KOTVE",
  "KOTVI",
  "KOTVO",
  "KOUAO",
  "KOUFA",
  "KOUFE",
  "KOUFI",
  "KOUFU",
  "KOUKI",
  "KOUMA",
  "KOUME",
  "KOUMI",
  "KOUMU",
  "KOURI",
  "KOUSA",
  "KOUSE",
  "KOUSI",
  "KOUSU",
  "KOVAČ",
  "KOVAK",
  "KOVAL",
  "KOVAN",
  "KOVAS",
  "KOVAT",
  "KOVAU",
  "KOVAV",
  "KOVČA",
  "KOVČE",
  "KOVČI",
  "KOVČU",
  "KOVEM",
  "KOVEN",
  "KOVIC",
  "KOVIČ",
  "KOVID",
  "KOVIN",
  "KOVIS",
  "KOVJA",
  "KOVJO",
  "KOVJU",
  "KOVKA",
  "KOVKU",
  "KOVNA",
  "KOVNE",
  "KOVNI",
  "KOVNO",
  "KOVOD",
  "KOVOM",
  "KOVOR",
  "KOVŠC",
  "KOVŠE",
  "KOZAČ",
  "KOZAH",
  "KOZAK",
  "KOZAM",
  "KOZAN",
  "KOZAR",
  "KOZAV",
  "KOZEL",
  "KOZEM",
  "KOZIC",
  "KOZIN",
  "KOZJA",
  "KOZJE",
  "KOZJI",
  "KOZJO",
  "KOZLA",
  "KOZLE",
  "KOZLI",
  "KOZLU",
  "KOZMA",
  "KOZME",
  "KOZMI",
  "KOZMO",
  "KOZMU",
  "KOZOM",
  "KOŽAH",
  "KOŽAM",
  "KOŽAR",
  "KOŽEN",
  "KOŽIC",
  "KOŽNA",
  "KOŽNE",
  "KOŽNI",
  "KOŽNO",
  "KOŽUH",
  "KOŽUL",
  "KPOTA",
  "KPOZA",
  "KPOZE",
  "KPOZI",
  "KPOZO",
  "KPOZU",
  "KRAAL",
  "KRACA",
  "KRACI",
  "KRACK",
  "KRACU",
  "KRAČA",
  "KRAČE",
  "KRAČI",
  "KRAČO",
  "KRADE",
  "KRADI",
  "KRADU",
  "KRAFA",
  "KRAFE",
  "KRAFI",
  "KRAFT",
  "KRAFU",
  "KRAGA",
  "KRAGE",
  "KRAGH",
  "KRAGI",
  "KRAGL",
  "KRAGU",
  "KRAHA",
  "KRAHE",
  "KRAHI",
  "KRAHL",
  "KRAHN",
  "KRAHU",
  "KRAIG",
  "KRAJA",
  "KRAJC",
  "KRAJE",
  "KRAJI",
  "KRAJK",
  "KRAJO",
  "KRAJT",
  "KRAJU",
  "KRAKA",
  "KRAKE",
  "KRAKI",
  "KRAKU",
  "KRALA",
  "KRALE",
  "KRALI",
  "KRALJ",
  "KRALL",
  "KRALU",
  "KRAMA",
  "KRAME",
  "KRAMI",
  "KRAML",
  "KRAMM",
  "KRAMO",
  "KRAMP",
  "KRAMS",
  "KRANJ",
  "KRANK",
  "KRANZ",
  "KRAPA",
  "KRAPE",
  "KRAPF",
  "KRAPI",
  "KRAPŠ",
  "KRAPU",
  "KRASA",
  "KRASE",
  "KRASI",
  "KRASS",
  "KRAST",
  "KRASU",
  "KRAŠA",
  "KRAŠC",
  "KRAŠE",
  "KRAŠI",
  "KRAŠU",
  "KRATA",
  "KRATE",
  "KRATI",
  "KRATT",
  "KRATU",
  "KRATZ",
  "KRAUS",
  "KRAUT",
  "KRAVA",
  "KRAVE",
  "KRAVI",
  "KRAVL",
  "KRAVO",
  "KRAVS",
  "KRCAJ",
  "KRCAL",
  "KRCAM",
  "KRCAN",
  "KRCAŠ",
  "KRCAT",
  "KRCAU",
  "KRCAV",
  "KRCEM",
  "KRCEV",
  "KRCIČ",
  "KRCIH",
  "KRCJA",
  "KRCJU",
  "KRCNE",
  "KRCNI",
  "KRCNU",
  "KRCOM",
  "KRCOV",
  "KRČAN",
  "KRČAR",
  "KRČEČ",
  "KRČEM",
  "KRČEN",
  "KRČEV",
  "KRČIH",
  "KRČIL",
  "KRČIM",
  "KRČIŠ",
  "KRČIT",
  "KRČIU",
  "KRČIV",
  "KRČJA",
  "KRČJU",
  "KRČMA",
  "KRČME",
  "KRČMI",
  "KRČMO",
  "KRČNA",
  "KRČNE",
  "KRČNI",
  "KRČNO",
  "KRČOM",
  "KRČOV",
  "KRDEL",
  "KREBL",
  "KREBS",
  "KREČA",
  "KREČE",
  "KREČI",
  "KREČO",
  "KREČU",
  "KREDA",
  "KREDE",
  "KREDI",
  "KREDO",
  "KREDU",
  "KREFL",
  "KREFT",
  "KREGA",
  "KREGE",
  "KREGI",
  "KREGU",
  "KREHA",
  "KREHI",
  "KREHL",
  "KREHU",
  "KREIG",
  "KREIN",
  "KREIS",
  "KREJK",
  "KREKA",
  "KREKE",
  "KREKI",
  "KREKU",
  "KRELJ",
  "KREMA",
  "KREME",
  "KREMI",
  "KREMO",
  "KREMS",
  "KRENA",
  "KRENE",
  "KRENI",
  "KRENK",
  "KRENN",
  "KRENU",
  "KRENZ",
  "KREOL",
  "KREON",
  "KREPA",
  "KREPE",
  "KREPI",
  "KREPS",
  "KREPŠ",
  "KREPU",
  "KRESA",
  "KRESE",
  "KRESI",
  "KRESL",
  "KRESS",
  "KRESU",
  "KREŠA",
  "KREŠE",
  "KREŠI",
  "KREŠO",
  "KREŠU",
  "KRETA",
  "KRETE",
  "KRETI",
  "KRETO",
  "KREUH",
  "KREUK",
  "KREVC",
  "KREVH",
  "KREVL",
  "KREVS",
  "KREŽE",
  "KRFJA",
  "KRFJU",
  "KRFOM",
  "KRHAJ",
  "KRHAL",
  "KRHAM",
  "KRHAŠ",
  "KRHAT",
  "KRHAU",
  "KRHAV",
  "KRHEK",
  "KRHIN",
  "KRHKA",
  "KRHKE",
  "KRHKI",
  "KRHKO",
  "KRICK",
  "KRIČE",
  "KRIČI",
  "KRIČK",
  "KRIČU",
  "KRIDL",
  "KRIEF",
  "KRIEG",
  "KRIEK",
  "KRIEN",
  "KRIES",
  "KRIGE",
  "KRIIS",
  "KRIJE",
  "KRIKA",
  "KRIKE",
  "KRIKI",
  "KRIKU",
  "KRILA",
  "KRILC",
  "KRILE",
  "KRILI",
  "KRILK",
  "KRILL",
  "KRILO",
  "KRILU",
  "KRIMA",
  "KRIME",
  "KRIMI",
  "KRIMS",
  "KRIMU",
  "KRING",
  "KRINK",
  "KRIPA",
  "KRIPE",
  "KRIPI",
  "KRIPO",
  "KRIPT",
  "KRISA",
  "KRISE",
  "KRISH",
  "KRISI",
  "KRISS",
  "KRIST",
  "KRISU",
  "KRITA",
  "KRITE",
  "KRITI",
  "KRITO",
  "KRIVA",
  "KRIVC",
  "KRIVD",
  "KRIVE",
  "KRIVI",
  "KRIVK",
  "KRIVO",
  "KRIVU",
  "KRIZA",
  "KRIZE",
  "KRIZI",
  "KRIZO",
  "KRIŽA",
  "KRIŽC",
  "KRIŽE",
  "KRIŽI",
  "KRIŽU",
  "KRKAČ",
  "KRKIH",
  "KRKIN",
  "KRKJA",
  "KRKJU",
  "KRKOČ",
  "KRKOM",
  "KRKON",
  "KRKOV",
  "KRLEŽ",
  "KRLJA",
  "KRLJE",
  "KRLJI",
  "KRLJU",
  "KRMAC",
  "KRMAH",
  "KRMAM",
  "KRMAR",
  "KRMCA",
  "KRMCE",
  "KRMCI",
  "KRMCU",
  "KRMEC",
  "KRMEK",
  "KRMEN",
  "KRMIL",
  "KRMIM",
  "KRMIN",
  "KRMIŠ",
  "KRMIT",
  "KRMIU",
  "KRMIV",
  "KRMNA",
  "KRMNE",
  "KRMNI",
  "KRMNO",
  "KRNCA",
  "KRNCE",
  "KRNCI",
  "KRNCU",
  "KRNČE",
  "KRNEČ",
  "KRNEL",
  "KRNEM",
  "KRNET",
  "KRNEU",
  "KRNEV",
  "KRNEŽ",
  "KRNIC",
  "KRNIH",
  "KRNIL",
  "KRNIM",
  "KRNIŠ",
  "KRNIT",
  "KRNIU",
  "KRNIV",
  "KRNIŽ",
  "KRNJA",
  "KRNJU",
  "KRNOM",
  "KRNOV",
  "KROEH",
  "KROES",
  "KROFA",
  "KROFE",
  "KROFI",
  "KROFL",
  "KROFT",
  "KROFU",
  "KROGA",
  "KROGC",
  "KROGE",
  "KROGH",
  "KROGI",
  "KROGU",
  "KROHM",
  "KROHN",
  "KROJA",
  "KROJE",
  "KROJI",
  "KROJS",
  "KROJU",
  "KROKA",
  "KROKI",
  "KROKU",
  "KROLA",
  "KROLE",
  "KROLI",
  "KROLL",
  "KROLO",
  "KROLU",
  "KROMA",
  "KROMI",
  "KROMP",
  "KROMU",
  "KRONA",
  "KRONE",
  "KRONI",
  "KRONO",
  "KRONU",
  "KROOK",
  "KROON",
  "KROOP",
  "KROOS",
  "KROPA",
  "KROPE",
  "KROPF",
  "KROPI",
  "KROPO",
  "KROPP",
  "KROPU",
  "KROSA",
  "KROSE",
  "KROSI",
  "KROSS",
  "KROST",
  "KROSU",
  "KROŠE",
  "KROŠL",
  "KROTA",
  "KROTE",
  "KROTI",
  "KROTO",
  "KROTU",
  "KROVA",
  "KROVC",
  "KROVE",
  "KROVI",
  "KROVK",
  "KROVU",
  "KROŽC",
  "KROŽE",
  "KROŽI",
  "KROŽU",
  "KRPAČ",
  "KRPAH",
  "KRPAJ",
  "KRPAL",
  "KRPAM",
  "KRPAN",
  "KRPAR",
  "KRPAŠ",
  "KRPAT",
  "KRPAU",
  "KRPAV",
  "KRPEŽ",
  "KRPIC",
  "KRPIČ",
  "KRPIN",
  "KRSTA",
  "KRSTE",
  "KRSTI",
  "KRSTO",
  "KRSTU",
  "KRŠCA",
  "KRŠCE",
  "KRŠCI",
  "KRŠCU",
  "KRŠEC",
  "KRŠEČ",
  "KRŠEN",
  "KRŠIČ",
  "KRŠIL",
  "KRŠIM",
  "KRŠIN",
  "KRŠIŠ",
  "KRŠIT",
  "KRŠIU",
  "KRŠIV",
  "KRŠJA",
  "KRŠJE",
  "KRŠJU",
  "KRŠKA",
  "KRŠKE",
  "KRŠKI",
  "KRŠKO",
  "KRŠUL",
  "KRTAČ",
  "KRTEK",
  "KRTEM",
  "KRTEV",
  "KRTIC",
  "KRTIH",
  "KRTIN",
  "KRTJA",
  "KRTJU",
  "KRTKA",
  "KRTKE",
  "KRTKI",
  "KRTKU",
  "KRTOM",
  "KRTOV",
  "KRUGA",
  "KRUGE",
  "KRUGI",
  "KRUGU",
  "KRUHA",
  "KRUHE",
  "KRUHI",
  "KRUHM",
  "KRUHU",
  "KRUIF",
  "KRUJA",
  "KRUJE",
  "KRUJI",
  "KRUJO",
  "KRUKA",
  "KRUKE",
  "KRUKI",
  "KRUKU",
  "KRULC",
  "KRULI",
  "KRULJ",
  "KRULL",
  "KRULU",
  "KRUMA",
  "KRUME",
  "KRUMI",
  "KRUMM",
  "KRUMP",
  "KRUMU",
  "KRUNA",
  "KRUNE",
  "KRUNI",
  "KRUNO",
  "KRUNU",
  "KRUPA",
  "KRUPE",
  "KRUPI",
  "KRUPO",
  "KRUPP",
  "KRUPS",
  "KRUPU",
  "KRUSE",
  "KRUŠČ",
  "KRUŠI",
  "KRUŠU",
  "KRUTA",
  "KRUTE",
  "KRUTI",
  "KRUTO",
  "KRUUS",
  "KRVAV",
  "KRVEN",
  "KRVIN",
  "KRVJO",
  "KRVNA",
  "KRVNE",
  "KRVNI",
  "KRVNO",
  "KRZEN",
  "KRZNA",
  "KRZNI",
  "KRZNO",
  "KRZNU",
  "KRŽAN",
  "KRŽIČ",
  "KRŽIN",
  "KSELA",
  "KSELE",
  "KSELI",
  "KSELO",
  "KSELU",
  "KSENA",
  "KSENE",
  "KSENI",
  "KSENJ",
  "KSENO",
  "KSEVT",
  "KSIHT",
  "KSIJA",
  "KSIJE",
  "KSIJI",
  "KSIJU",
  "KSIRA",
  "KSIRE",
  "KSIRI",
  "KSIRU",
  "KŠEFT",
  "KŠELA",
  "KŠELE",
  "KŠELI",
  "KŠELO",
  "KŠELU",
  "KUALA",
  "KUANG",
  "KUBAL",
  "KUBAN",
  "KUBAT",
  "KUBED",
  "KUBEK",
  "KUBEM",
  "KUBER",
  "KUBES",
  "KUBEŠ",
  "KUBEV",
  "KUBIC",
  "KUBIH",
  "KUBIK",
  "KUBIN",
  "KUBIŠ",
  "KUBIT",
  "KUBJA",
  "KUBJU",
  "KUBOM",
  "KUBOT",
  "KUBOV",
  "KUBUS",
  "KUCEV",
  "KUCHL",
  "KUCHT",
  "KUCKA",
  "KUCKE",
  "KUCKI",
  "KUCKO",
  "KUCKU",
  "KUČAH",
  "KUČAM",
  "KUČAN",
  "KUČAR",
  "KUČEJ",
  "KUČEK",
  "KUČEM",
  "KUČER",
  "KUČEV",
  "KUČIČ",
  "KUČIH",
  "KUČIN",
  "KUČIŠ",
  "KUČJA",
  "KUČJU",
  "KUČKA",
  "KUČKE",
  "KUČKI",
  "KUČKO",
  "KUČKU",
  "KUČMA",
  "KUČME",
  "KUČMI",
  "KUČMO",
  "KUČMU",
  "KUČOM",
  "KUČOV",
  "KUČUK",
  "KUDAS",
  "KUDEL",
  "KUDER",
  "KUDIN",
  "KUDJU",
  "KUDRA",
  "KUDRE",
  "KUDRI",
  "KUDRU",
  "KUDSK",
  "KUDUS",
  "KUEHL",
  "KUEHN",
  "KUENA",
  "KUENE",
  "KUENG",
  "KUENI",
  "KUENU",
  "KUESS",
  "KUFER",
  "KUFRA",
  "KUFRE",
  "KUFRI",
  "KUFRU",
  "KUGAH",
  "KUGAM",
  "KUGAN",
  "KUGEL",
  "KUGLA",
  "KUGLE",
  "KUGLI",
  "KUGLO",
  "KUHAJ",
  "KUHAL",
  "KUHAM",
  "KUHAN",
  "KUHAR",
  "KUHAŠ",
  "KUHAT",
  "KUHAU",
  "KUHAV",
  "KUHLA",
  "KUHLE",
  "KUHLI",
  "KUHLU",
  "KUHMA",
  "KUHME",
  "KUHMI",
  "KUHMU",
  "KUHNA",
  "KUHNE",
  "KUHNI",
  "KUHNT",
  "KUHNU",
  "KUHRA",
  "KUHRE",
  "KUHRI",
  "KUHRT",
  "KUHRU",
  "KUHTA",
  "KUHTE",
  "KUHTI",
  "KUHTO",
  "KUHTU",
  "KUICK",
  "KUIJK",
  "KUIKA",
  "KUIKE",
  "KUIKI",
  "KUIKU",
  "KUITA",
  "KUITE",
  "KUITI",
  "KUITU",
  "KUJAJ",
  "KUJAL",
  "KUJAM",
  "KUJAŠ",
  "KUJAT",
  "KUJAU",
  "KUJAV",
  "KUJEM",
  "KUJEŠ",
  "KUJIK",
  "KUJMO",
  "KUJOČ",
  "KUJON",
  "KUJTA",
  "KUJTE",
  "KUJVA",
  "KUKAH",
  "KUKAJ",
  "KUKAL",
  "KUKAM",
  "KUKAN",
  "KUKAR",
  "KUKAŠ",
  "KUKAT",
  "KUKAU",
  "KUKAV",
  "KUKCA",
  "KUKCE",
  "KUKCI",
  "KUKCU",
  "KUKEB",
  "KUKEC",
  "KUKEČ",
  "KUKEL",
  "KUKIČ",
  "KUKIH",
  "KUKJA",
  "KUKJU",
  "KUKLA",
  "KUKLE",
  "KUKLI",
  "KUKLO",
  "KUKOČ",
  "KUKOM",
  "KUKOV",
  "KUKUL",
  "KULAH",
  "KULAK",
  "KULAM",
  "KULAN",
  "KULAŠ",
  "KULEB",
  "KULEN",
  "KULEŠ",
  "KULET",
  "KULIC",
  "KULIČ",
  "KULIG",
  "KULIK",
  "KULIN",
  "KULIS",
  "KULIŠ",
  "KULKA",
  "KULKE",
  "KULKI",
  "KULKO",
  "KULKU",
  "KULLA",
  "KULLE",
  "KULLI",
  "KULLO",
  "KULLS",
  "KULLU",
  "KULMA",
  "KULMU",
  "KULON",
  "KULOT",
  "KULOV",
  "KULPA",
  "KULPE",
  "KULPI",
  "KULPU",
  "KULSA",
  "KULSE",
  "KULSI",
  "KULSU",
  "KULTA",
  "KULTE",
  "KULTI",
  "KULTU",
  "KUMAR",
  "KUMEN",
  "KUMER",
  "KUMIK",
  "KUMIN",
  "KUMIS",
  "KUMJA",
  "KUMJU",
  "KUMNA",
  "KUMNU",
  "KUMOM",
  "KUMPA",
  "KUMPE",
  "KUMPF",
  "KUMPI",
  "KUMPU",
  "KUMRA",
  "KUMRE",
  "KUMRI",
  "KUMRN",
  "KUMRU",
  "KUMST",
  "KUMŠE",
  "KUNAH",
  "KUNAL",
  "KUNAM",
  "KUNCA",
  "KUNCE",
  "KUNCI",
  "KUNCU",
  "KUNDA",
  "KUNDE",
  "KUNDI",
  "KUNDL",
  "KUNDU",
  "KUNEC",
  "KUNEJ",
  "KUNES",
  "KUNIČ",
  "KUNIG",
  "KUNIH",
  "KUNIN",
  "KUNIS",
  "KUNJA",
  "KUNJE",
  "KUNJI",
  "KUNJO",
  "KUNJU",
  "KUNOM",
  "KUNOV",
  "KUNST",
  "KUNŠT",
  "KUNTZ",
  "KUNZA",
  "KUNZE",
  "KUNZI",
  "KUNZU",
  "KUOSA",
  "KUOSE",
  "KUOSI",
  "KUOSO",
  "KUOSU",
  "KUPAH",
  "KUPAM",
  "KUPCA",
  "KUPCE",
  "KUPCI",
  "KUPCU",
  "KUPEC",
  "KUPEM",
  "KUPEN",
  "KUPEV",
  "KUPIC",
  "KUPID",
  "KUPIH",
  "KUPIL",
  "KUPIM",
  "KUPIŠ",
  "KUPIT",
  "KUPIU",
  "KUPIV",
  "KUPJA",
  "KUPJU",
  "KUPKA",
  "KUPKE",
  "KUPKI",
  "KUPKO",
  "KUPKU",
  "KUPNA",
  "KUPNE",
  "KUPNI",
  "KUPNO",
  "KUPOL",
  "KUPOM",
  "KUPON",
  "KUPOV",
  "KUPPA",
  "KUPPE",
  "KUPPI",
  "KUPPU",
  "KUPTI",
  "KUPUJ",
  "KURAC",
  "KURAH",
  "KURAJ",
  "KURAL",
  "KURAM",
  "KURAN",
  "KURAT",
  "KURBA",
  "KURBE",
  "KURBI",
  "KURBO",
  "KURBU",
  "KURCA",
  "KURCE",
  "KURCI",
  "KURCU",
  "KURDA",
  "KURDE",
  "KURDI",
  "KURDU",
  "KUREC",
  "KUREČ",
  "KUREJ",
  "KURER",
  "KUREŠ",
  "KURET",
  "KUREŽ",
  "KURIJ",
  "KURIL",
  "KURIM",
  "KURIN",
  "KURIR",
  "KURIŠ",
  "KURIT",
  "KURIU",
  "KURIV",
  "KURJA",
  "KURJE",
  "KURJI",
  "KURJO",
  "KURKS",
  "KUROD",
  "KUROL",
  "KURSK",
  "KURTA",
  "KURTE",
  "KURTH",
  "KURTI",
  "KURTS",
  "KURTU",
  "KURTZ",
  "KURUM",
  "KURUP",
  "KURUZ",
  "KURZA",
  "KURZE",
  "KURZI",
  "KURZU",
  "KUSAM",
  "KUSCH",
  "KUSHI",
  "KUSHT",
  "KUSIČ",
  "KUSIH",
  "KUSJA",
  "KUSJU",
  "KUSKE",
  "KUSOM",
  "KUSOV",
  "KUSSA",
  "KUSSE",
  "KUSSI",
  "KUSSU",
  "KUSTA",
  "KUSTE",
  "KUSTI",
  "KUSTO",
  "KUSTU",
  "KUŠAN",
  "KUŠAR",
  "KUŠEJ",
  "KUŠNE",
  "KUŠNI",
  "KUŠNU",
  "KUŠUJ",
  "KUTAH",
  "KUTAM",
  "KUTER",
  "KUTIN",
  "KUTLE",
  "KUTLU",
  "KUTOŠ",
  "KUUSI",
  "KUUTA",
  "KUUTE",
  "KUUTI",
  "KUUTU",
  "KUVIN",
  "KUZAK",
  "KUZEL",
  "KUZEM",
  "KUZIN",
  "KUZJU",
  "KUZLA",
  "KUZLE",
  "KUZLI",
  "KUZLO",
  "KUZMA",
  "KUZME",
  "KUZMI",
  "KUZMO",
  "KUZMU",
  "KUZOM",
  "KUŽAH",
  "KUŽAM",
  "KUŽEK",
  "KUŽEL",
  "KUŽEM",
  "KUŽEN",
  "KUŽET",
  "KUŽEV",
  "KUŽIH",
  "KUŽIL",
  "KUŽJA",
  "KUŽJU",
  "KUŽKA",
  "KUŽKE",
  "KUŽKI",
  "KUŽKU",
  "KUŽNA",
  "KUŽNE",
  "KUŽNI",
  "KUŽNO",
  "KUŽOM",
  "KUŽOV",
  "KVACA",
  "KVACE",
  "KVACI",
  "KVACU",
  "KVAČK",
  "KVAGA",
  "KVAGE",
  "KVAGI",
  "KVAGO",
  "KVAKA",
  "KVAKI",
  "KVAKU",
  "KVAMM",
  "KVANT",
  "KVARA",
  "KVARC",
  "KVARE",
  "KVARI",
  "KVARK",
  "KVART",
  "KVARU",
  "KVASA",
  "KVASE",
  "KVASI",
  "KVASU",
  "KVAŠA",
  "KVAŠE",
  "KVAŠI",
  "KVAŠO",
  "KVAZI",
  "KVEIM",
  "KVETA",
  "KVETE",
  "KVETI",
  "KVETO",
  "KVIAZ",
  "KVIBA",
  "KVIBO",
  "KVIBU",
  "KVIEN",
  "KVIHT",
  "KVINT",
  "KVIST",
  "KVIZA",
  "KVIZE",
  "KVIZI",
  "KVIZU",
  "KVJAT",
  "KVOTA",
  "KVOTE",
  "KVOTI",
  "KVOTO",
  "LAABS",
  "LAAGA",
  "LAAGE",
  "LAAKA",
  "LAAKE",
  "LAAKI",
  "LAAKU",
  "LAANA",
  "LAANE",
  "LAANI",
  "LAANU",
  "LAARA",
  "LAARE",
  "LAARI",
  "LAARU",
  "LAASA",
  "LAASE",
  "LAASI",
  "LAASU",
  "LABAN",
  "LABAŠ",
  "LABED",
  "LABEM",
  "LABEV",
  "LABIH",
  "LABIN",
  "LABJA",
  "LABJU",
  "LABOD",
  "LABOM",
  "LABOR",
  "LABOT",
  "LABOV",
  "LABSA",
  "LABSU",
  "LABUS",
  "LACAJ",
  "LACAN",
  "LACEM",
  "LACEV",
  "LACIG",
  "LACIH",
  "LACIJ",
  "LACIK",
  "LACIN",
  "LACJA",
  "LACJU",
  "LACKA",
  "LACKE",
  "LACKI",
  "LACKO",
  "LACKS",
  "LACKU",
  "LACOB",
  "LACOM",
  "LACOV",
  "LAČAN",
  "LAČEN",
  "LAČEV",
  "LAČJA",
  "LAČJE",
  "LAČJI",
  "LAČJO",
  "LAČNA",
  "LAČNE",
  "LAČNI",
  "LAČNO",
  "LAČNU",
  "LAČOV",
  "LADAG",
  "LADAH",
  "LADAM",
  "LADAN",
  "LADDA",
  "LADDE",
  "LADDI",
  "LADDU",
  "LADEJ",
  "LADEN",
  "LADER",
  "LADIČ",
  "LADIH",
  "LADIJ",
  "LADIK",
  "LADIN",
  "LADJA",
  "LADJE",
  "LADJI",
  "LADJO",
  "LADJU",
  "LADKA",
  "LADKE",
  "LADKI",
  "LADKO",
  "LADKU",
  "LADNA",
  "LADNE",
  "LADNI",
  "LADNU",
  "LADOM",
  "LADOV",
  "LADRA",
  "LADRC",
  "LADRE",
  "LADRI",
  "LADRK",
  "LADRO",
  "LADRU",
  "LAERT",
  "LAFET",
  "LAFIA",
  "LAFIE",
  "LAFII",
  "LAFIJ",
  "LAFIO",
  "LAFIU",
  "LAFON",
  "LAGAL",
  "LAGAN",
  "LAGAT",
  "LAGAU",
  "LAGAV",
  "LAGER",
  "LAGHI",
  "LAGIH",
  "LAGJA",
  "LAGJU",
  "LAGOA",
  "LAGOE",
  "LAGOI",
  "LAGOJ",
  "LAGOM",
  "LAGOO",
  "LAGOS",
  "LAGOU",
  "LAGOV",
  "LAGUM",
  "LAGUN",
  "LAHAJ",
  "LAHAK",
  "LAHAV",
  "LAHDE",
  "LAHEK",
  "LAHEN",
  "LAHET",
  "LAHGE",
  "LAHIH",
  "LAHIŠ",
  "LAHJA",
  "LAHJU",
  "LAHKA",
  "LAHKE",
  "LAHKI",
  "LAHKO",
  "LAHKU",
  "LAHLA",
  "LAHLE",
  "LAHLI",
  "LAHLU",
  "LAHMA",
  "LAHME",
  "LAHMI",
  "LAHMU",
  "LAHNA",
  "LAHNE",
  "LAHNI",
  "LAHNO",
  "LAHOD",
  "LAHOL",
  "LAHOM",
  "LAHOV",
  "LAHOZ",
  "LAHRS",
  "LAHTI",
  "LAHUT",
  "LAIBA",
  "LAIBE",
  "LAIBI",
  "LAIBL",
  "LAIBU",
  "LAIKA",
  "LAIKE",
  "LAIKI",
  "LAIKU",
  "LAILA",
  "LAILE",
  "LAILI",
  "LAILO",
  "LAILU",
  "LAIMA",
  "LAIME",
  "LAIMI",
  "LAIMO",
  "LAIMU",
  "LAINA",
  "LAINE",
  "LAING",
  "LAINI",
  "LAINO",
  "LAINU",
  "LAIRD",
  "LAIZA",
  "LAIZE",
  "LAIZI",
  "LAIZO",
  "LAJAJ",
  "LAJAL",
  "LAJAM",
  "LAJAŠ",
  "LAJAT",
  "LAJAU",
  "LAJAV",
  "LAJEM",
  "LAJEŠ",
  "LAJEV",
  "LAJEŽ",
  "LAJFA",
  "LAJFE",
  "LAJFI",
  "LAJFU",
  "LAJHA",
  "LAJHE",
  "LAJHI",
  "LAJHU",
  "LAJIH",
  "LAJIK",
  "LAJJA",
  "LAJJU",
  "LAJKA",
  "LAJKE",
  "LAJKI",
  "LAJKO",
  "LAJKU",
  "LAJLA",
  "LAJLE",
  "LAJLI",
  "LAJLO",
  "LAJNA",
  "LAJNE",
  "LAJNI",
  "LAJNO",
  "LAJNU",
  "LAJOM",
  "LAJOS",
  "LAJOV",
  "LAJŠA",
  "LAJŠE",
  "LAJŠI",
  "LAJŠT",
  "LAJŠU",
  "LAJUK",
  "LAKAJ",
  "LAKAZ",
  "LAKEM",
  "LAKEN",
  "LAKET",
  "LAKEV",
  "LAKHA",
  "LAKHE",
  "LAKHI",
  "LAKHO",
  "LAKHU",
  "LAKIČ",
  "LAKIH",
  "LAKIN",
  "LAKIS",
  "LAKJA",
  "LAKJU",
  "LAKOB",
  "LAKOM",
  "LAKOS",
  "LAKOŠ",
  "LAKOT",
  "LAKOV",
  "LAKSA",
  "LAKSE",
  "LAKSI",
  "LAKSU",
  "LAKTA",
  "LAKTE",
  "LAKTI",
  "LAKTU",
  "LAKUN",
  "LALAH",
  "LALAM",
  "LALIČ",
  "LALIH",
  "LALIN",
  "LALIS",
  "LALJA",
  "LALJU",
  "LALLA",
  "LALLE",
  "LALLI",
  "LALLO",
  "LALLU",
  "LALOM",
  "LALOS",
  "LALOŠ",
  "LALOV",
  "LAMAH",
  "LAMAJ",
  "LAMAM",
  "LAMAR",
  "LAMAS",
  "LAMBA",
  "LAMBD",
  "LAMBE",
  "LAMBI",
  "LAMBL",
  "LAMBU",
  "LAMEK",
  "LAMEL",
  "LAMET",
  "LAMEV",
  "LAMIE",
  "LAMIH",
  "LAMIJ",
  "LAMIN",
  "LAMIR",
  "LAMJA",
  "LAMJE",
  "LAMJI",
  "LAMJO",
  "LAMJU",
  "LAMKA",
  "LAMKE",
  "LAMKI",
  "LAMKU",
  "LAMMA",
  "LAMME",
  "LAMMI",
  "LAMMU",
  "LAMOM",
  "LAMOT",
  "LAMOV",
  "LAMPA",
  "LAMPE",
  "LAMPI",
  "LAMPL",
  "LAMPO",
  "LAMPP",
  "LAMPS",
  "LAMSA",
  "LAMSE",
  "LAMSI",
  "LAMSU",
  "LAMUT",
  "LANAH",
  "LANAM",
  "LANAS",
  "LANCA",
  "LANCE",
  "LANCI",
  "LANCU",
  "LANDA",
  "LANDE",
  "LANDI",
  "LANDL",
  "LANDO",
  "LANDT",
  "LANDU",
  "LANEM",
  "LANEN",
  "LANEV",
  "LANGA",
  "LANGE",
  "LANGI",
  "LANGO",
  "LANGS",
  "LANGU",
  "LANIE",
  "LANIH",
  "LANIK",
  "LANIN",
  "LANIŠ",
  "LANJA",
  "LANJU",
  "LANKA",
  "LANKE",
  "LANKI",
  "LANKO",
  "LANKU",
  "LANNA",
  "LANNE",
  "LANNI",
  "LANNO",
  "LANOM",
  "LANOV",
  "LANTZ",
  "LANZA",
  "LANZE",
  "LANZI",
  "LANZO",
  "LANZU",
  "LAOIS",
  "LAOSA",
  "LAOSU",
  "LAPAH",
  "LAPAJ",
  "LAPAL",
  "LAPAM",
  "LAPAN",
  "LAPAŠ",
  "LAPAT",
  "LAPAU",
  "LAPAV",
  "LAPIČ",
  "LAPID",
  "LAPIH",
  "LAPIN",
  "LAPIS",
  "LAPJA",
  "LAPJU",
  "LAPOL",
  "LAPOM",
  "LAPOR",
  "LAPOŠ",
  "LAPOV",
  "LAPPA",
  "LAPPE",
  "LAPPI",
  "LAPPO",
  "LAPPU",
  "LAPUA",
  "LAPUE",
  "LAPUH",
  "LAPUI",
  "LAPUO",
  "LAPUS",
  "LARAH",
  "LARAM",
  "LARBI",
  "LARCA",
  "LARCE",
  "LARCI",
  "LARCU",
  "LARDI",
  "LAREM",
  "LAREN",
  "LAREV",
  "LARFA",
  "LARFE",
  "LARFI",
  "LARFO",
  "LARGA",
  "LARGE",
  "LARGI",
  "LARGO",
  "LARGU",
  "LARIA",
  "LARIE",
  "LARIH",
  "LARII",
  "LARIN",
  "LARIO",
  "LARIS",
  "LARIU",
  "LARJA",
  "LARJU",
  "LARNA",
  "LARNE",
  "LARNI",
  "LARNU",
  "LAROM",
  "LAROS",
  "LAROV",
  "LARSA",
  "LARSE",
  "LARSI",
  "LARSU",
  "LARVA",
  "LARVE",
  "LARVI",
  "LARVO",
  "LASAJ",
  "LASAL",
  "LASAM",
  "LASAN",
  "LASAŠ",
  "LASAT",
  "LASAU",
  "LASAV",
  "LASCA",
  "LASCE",
  "LASCH",
  "LASCI",
  "LASCU",
  "LASEC",
  "LASEH",
  "LASEK",
  "LASEM",
  "LASEN",
  "LASER",
  "LASEV",
  "LASIČ",
  "LASIH",
  "LASJA",
  "LASJE",
  "LASJU",
  "LASKA",
  "LASKE",
  "LASKI",
  "LASKU",
  "LASMI",
  "LASNA",
  "LASNE",
  "LASNI",
  "LASNO",
  "LASOM",
  "LASOV",
  "LASSA",
  "LASSE",
  "LASSI",
  "LASSO",
  "LASSU",
  "LASTA",
  "LASTE",
  "LASTI",
  "LASTU",
  "LAŠAH",
  "LAŠAM",
  "LAŠAN",
  "LAŠČE",
  "LAŠIČ",
  "LAŠKA",
  "LAŠKE",
  "LAŠKI",
  "LAŠKO",
  "LAŠNA",
  "LAŠNE",
  "LAŠNI",
  "LAŠNO",
  "LATAH",
  "LATAL",
  "LATAM",
  "LATAS",
  "LATEM",
  "LATEN",
  "LATEV",
  "LATHA",
  "LATHE",
  "LATHI",
  "LATHU",
  "LATIC",
  "LATIF",
  "LATIH",
  "LATIL",
  "LATIN",
  "LATJA",
  "LATJE",
  "LATJU",
  "LATOM",
  "LATOV",
  "LATRE",
  "LATSA",
  "LATSE",
  "LATSI",
  "LATSU",
  "LATTA",
  "LATTE",
  "LATTI",
  "LATTO",
  "LATTU",
  "LAUBA",
  "LAUBE",
  "LAUBI",
  "LAUBU",
  "LAUCA",
  "LAUCE",
  "LAUCI",
  "LAUCU",
  "LAUDA",
  "LAUDE",
  "LAUDI",
  "LAUDO",
  "LAUDU",
  "LAUER",
  "LAUFA",
  "LAUFI",
  "LAUFU",
  "LAUGA",
  "LAUGE",
  "LAUGI",
  "LAUGU",
  "LAUIH",
  "LAUJA",
  "LAUJU",
  "LAUKA",
  "LAUKE",
  "LAUKI",
  "LAUKO",
  "LAUKU",
  "LAUOM",
  "LAUOV",
  "LAURA",
  "LAURE",
  "LAURI",
  "LAURO",
  "LAURU",
  "LAUSA",
  "LAUSE",
  "LAUSI",
  "LAUSU",
  "LAUŠA",
  "LAUŠE",
  "LAUŠI",
  "LAUŠU",
  "LAUTT",
  "LAUZI",
  "LAVAH",
  "LAVAL",
  "LAVAM",
  "LAVAN",
  "LAVAŽ",
  "LAVEN",
  "LAVER",
  "LAVIC",
  "LAVIE",
  "LAVIH",
  "LAVIN",
  "LAVIS",
  "LAVJA",
  "LAVJU",
  "LAVKA",
  "LAVKE",
  "LAVKI",
  "LAVKO",
  "LAVKU",
  "LAVNA",
  "LAVNE",
  "LAVNI",
  "LAVNO",
  "LAVOM",
  "LAVOR",
  "LAVOV",
  "LAVRA",
  "LAVRE",
  "LAVRI",
  "LAVRO",
  "LAVŠA",
  "LAVŠE",
  "LAVŠI",
  "LAVŠU",
  "LAZAH",
  "LAZAM",
  "LAZAN",
  "LAZAR",
  "LAZCA",
  "LAZCU",
  "LAZEC",
  "LAZEČ",
  "LAZEM",
  "LAZEV",
  "LAZIA",
  "LAZIC",
  "LAZIČ",
  "LAZIH",
  "LAZIL",
  "LAZIM",
  "LAZIN",
  "LAZIO",
  "LAZIŠ",
  "LAZIT",
  "LAZIU",
  "LAZIV",
  "LAZJA",
  "LAZJU",
  "LAZNA",
  "LAZNE",
  "LAZNI",
  "LAZNO",
  "LAZOM",
  "LAZOV",
  "LAZRI",
  "LAZUR",
  "LAŽAH",
  "LAŽAM",
  "LAŽAN",
  "LAŽEH",
  "LAŽEM",
  "LAŽEN",
  "LAŽEŠ",
  "LAŽET",
  "LAŽIŠ",
  "LAŽJA",
  "LAŽJE",
  "LAŽJI",
  "LAŽJO",
  "LAŽMI",
  "LAŽNA",
  "LAŽNE",
  "LAŽNI",
  "LAŽNO",
  "LEACH",
  "LEAFA",
  "LEAFE",
  "LEAFI",
  "LEAFS",
  "LEAFU",
  "LEAIH",
  "LEAJA",
  "LEAJU",
  "LEAKA",
  "LEAKE",
  "LEAKI",
  "LEAKU",
  "LEALA",
  "LEALE",
  "LEALI",
  "LEALU",
  "LEAMA",
  "LEAMI",
  "LEANA",
  "LEANE",
  "LEANI",
  "LEANN",
  "LEANO",
  "LEANU",
  "LEANZ",
  "LEAOM",
  "LEAOV",
  "LEAPA",
  "LEAPE",
  "LEAPI",
  "LEAPU",
  "LEARA",
  "LEARE",
  "LEARI",
  "LEART",
  "LEARU",
  "LEASK",
  "LEATH",
  "LEBAN",
  "LEBAR",
  "LEBAS",
  "LEBBE",
  "LEBDE",
  "LEBDI",
  "LEBDU",
  "LEBED",
  "LEBEN",
  "LEBER",
  "LEBEZ",
  "LEBIČ",
  "LEBNA",
  "LEBNE",
  "LEBNI",
  "LEBNU",
  "LEBON",
  "LECAH",
  "LECAM",
  "LECCE",
  "LECHA",
  "LECHE",
  "LECHI",
  "LECHU",
  "LECIA",
  "LECIE",
  "LECII",
  "LECIJ",
  "LECIN",
  "LECIO",
  "LECKA",
  "LECKE",
  "LECKI",
  "LECKU",
  "LECTA",
  "LECTE",
  "LECTI",
  "LECTU",
  "LECUN",
  "LEČAH",
  "LEČAM",
  "LEČEČ",
  "LEČEN",
  "LEČIC",
  "LEČIČ",
  "LEČIJ",
  "LEČIL",
  "LEČIM",
  "LEČIN",
  "LEČIŠ",
  "LEČIT",
  "LEČIU",
  "LEČIV",
  "LEČJA",
  "LEČJE",
  "LEČJI",
  "LEČJU",
  "LEČNA",
  "LEČNE",
  "LEČNI",
  "LEČNO",
  "LEČTI",
  "LEDAH",
  "LEDAM",
  "LEDAR",
  "LEDEM",
  "LEDEN",
  "LEDER",
  "LEDEV",
  "LEDIC",
  "LEDIH",
  "LEDIJ",
  "LEDIK",
  "LEDIN",
  "LEDJA",
  "LEDJE",
  "LEDJI",
  "LEDJU",
  "LEDLA",
  "LEDLE",
  "LEDLI",
  "LEDLU",
  "LEDNA",
  "LEDNE",
  "LEDNI",
  "LEDNO",
  "LEDOM",
  "LEDOV",
  "LEDRA",
  "LEDRE",
  "LEDRI",
  "LEDRU",
  "LEDUC",
  "LEEBA",
  "LEEBE",
  "LEEBI",
  "LEEBU",
  "LEECH",
  "LEEDA",
  "LEEDE",
  "LEEDI",
  "LEEDS",
  "LEEDU",
  "LEEJA",
  "LEEJE",
  "LEEJI",
  "LEEJU",
  "LEEKS",
  "LEELA",
  "LEELE",
  "LEELI",
  "LEELO",
  "LEENA",
  "LEENE",
  "LEENI",
  "LEENO",
  "LEENU",
  "LEERA",
  "LEERE",
  "LEERI",
  "LEERS",
  "LEERU",
  "LEESA",
  "LEESE",
  "LEESI",
  "LEESO",
  "LEESU",
  "LEEZA",
  "LEEZE",
  "LEEZI",
  "LEEZO",
  "LEFFA",
  "LEFFE",
  "LEFFI",
  "LEFFU",
  "LEFTA",
  "LEFTE",
  "LEFTI",
  "LEFTT",
  "LEFTU",
  "LEGAH",
  "LEGAJ",
  "LEGAL",
  "LEGAM",
  "LEGAN",
  "LEGAR",
  "LEGAŠ",
  "LEGAT",
  "LEGAU",
  "LEGAV",
  "LEGEL",
  "LEGEN",
  "LEGER",
  "LEGEU",
  "LEGGA",
  "LEGGE",
  "LEGGI",
  "LEGGU",
  "LEGIC",
  "LEGIJ",
  "LEGIN",
  "LEGIŠ",
  "LEGJA",
  "LEGJU",
  "LEGLA",
  "LEGLE",
  "LEGLI",
  "LEGLO",
  "LEGLU",
  "LEGNA",
  "LEGNU",
  "LEGOM",
  "LEGOV",
  "LEGUR",
  "LEHAH",
  "LEHAM",
  "LEHAN",
  "LEHAR",
  "LEHEN",
  "LEHNA",
  "LEHNE",
  "LEHNI",
  "LEHNU",
  "LEHRA",
  "LEHRE",
  "LEHRI",
  "LEHRU",
  "LEHTA",
  "LEHTE",
  "LEHTI",
  "LEHTO",
  "LEHTU",
  "LEIAH",
  "LEIAM",
  "LEIBA",
  "LEIBE",
  "LEIBI",
  "LEIBU",
  "LEICA",
  "LEICE",
  "LEICI",
  "LEICK",
  "LEICO",
  "LEIFA",
  "LEIFE",
  "LEIFI",
  "LEIFU",
  "LEIGH",
  "LEILA",
  "LEILE",
  "LEILI",
  "LEILO",
  "LEINA",
  "LEINE",
  "LEINI",
  "LEINO",
  "LEINS",
  "LEINU",
  "LEISH",
  "LEISS",
  "LEIST",
  "LEITA",
  "LEITE",
  "LEITH",
  "LEITI",
  "LEITL",
  "LEITO",
  "LEJAH",
  "LEJAM",
  "LEJDI",
  "LEJEM",
  "LEJEV",
  "LEJIČ",
  "LEJIH",
  "LEJIN",
  "LEJJA",
  "LEJJU",
  "LEJKA",
  "LEJKE",
  "LEJKI",
  "LEJKO",
  "LEJKU",
  "LEJLA",
  "LEJLE",
  "LEJLI",
  "LEJLO",
  "LEJOM",
  "LEJOV",
  "LEKAH",
  "LEKAJ",
  "LEKAM",
  "LEKAN",
  "LEKEM",
  "LEKEŠ",
  "LEKEV",
  "LEKIČ",
  "LEKIH",
  "LEKJA",
  "LEKJU",
  "LEKKI",
  "LEKOM",
  "LEKOV",
  "LEKSI",
  "LEKŠE",
  "LELAH",
  "LELAJ",
  "LELAM",
  "LELAS",
  "LELEK",
  "LELIA",
  "LELIČ",
  "LELIE",
  "LELIH",
  "LELII",
  "LELIJ",
  "LELIN",
  "LELIO",
  "LELIU",
  "LELJA",
  "LELJE",
  "LELJI",
  "LELJO",
  "LELJU",
  "LELLA",
  "LELLE",
  "LELLI",
  "LELLO",
  "LELLU",
  "LELOM",
  "LELOV",
  "LEMAH",
  "LEMAL",
  "LEMAM",
  "LEMAN",
  "LEMAR",
  "LEMBI",
  "LEMEŠ",
  "LEMEŽ",
  "LEMGA",
  "LEMGO",
  "LEMGU",
  "LEMIČ",
  "LEMIH",
  "LEMIN",
  "LEMJA",
  "LEMJU",
  "LEMKE",
  "LEMMA",
  "LEMME",
  "LEMMI",
  "LEMMU",
  "LEMOM",
  "LEMON",
  "LEMOV",
  "LEMPA",
  "LEMPE",
  "LEMPI",
  "LEMPL",
  "LEMPU",
  "LEMUR",
  "LEMUT",
  "LENAC",
  "LENAH",
  "LENAM",
  "LENAR",
  "LENAT",
  "LENAU",
  "LENCL",
  "LENČA",
  "LENČE",
  "LENČI",
  "LENČO",
  "LENDL",
  "LENEČ",
  "LENEM",
  "LENGA",
  "LENGE",
  "LENGI",
  "LENGU",
  "LENIČ",
  "LENIH",
  "LENIL",
  "LENIM",
  "LENIN",
  "LENIS",
  "LENIŠ",
  "LENIT",
  "LENIU",
  "LENIV",
  "LENJA",
  "LENJU",
  "LENKA",
  "LENKE",
  "LENKI",
  "LENKO",
  "LENKU",
  "LENNA",
  "LENNE",
  "LENNI",
  "LENNO",
  "LENOB",
  "LENOM",
  "LENOR",
  "LENOV",
  "LENSA",
  "LENSE",
  "LENSI",
  "LENSU",
  "LENTA",
  "LENTE",
  "LENTI",
  "LENTO",
  "LENTU",
  "LENTZ",
  "LENUH",
  "LENZA",
  "LENZE",
  "LENZI",
  "LENZU",
  "LEOBA",
  "LEOBE",
  "LEOBI",
  "LEOBU",
  "LEOMA",
  "LEONA",
  "LEONE",
  "LEONG",
  "LEONI",
  "LEONO",
  "LEONT",
  "LEONU",
  "LEOTA",
  "LEOTT",
  "LEOTU",
  "LEOVA",
  "LEOVE",
  "LEOVI",
  "LEOVO",
  "LEOZA",
  "LEOZE",
  "LEOZI",
  "LEOZU",
  "LEPAK",
  "LEPAN",
  "LEPCA",
  "LEPCE",
  "LEPCI",
  "LEPCU",
  "LEPEC",
  "LEPEČ",
  "LEPEJ",
  "LEPEK",
  "LEPEL",
  "LEPEM",
  "LEPEN",
  "LEPER",
  "LEPET",
  "LEPEU",
  "LEPGA",
  "LEPIC",
  "LEPIH",
  "LEPIK",
  "LEPIL",
  "LEPIM",
  "LEPIN",
  "LEPIR",
  "LEPIŠ",
  "LEPIT",
  "LEPIU",
  "LEPIV",
  "LEPJA",
  "LEPJU",
  "LEPKA",
  "LEPKE",
  "LEPKI",
  "LEPKO",
  "LEPKU",
  "LEPOM",
  "LEPOŠ",
  "LEPOT",
  "LEPOV",
  "LEPPE",
  "LEPRA",
  "LEPRE",
  "LEPRI",
  "LEPRO",
  "LEPŠA",
  "LEPŠE",
  "LEPŠI",
  "LEPŠO",
  "LEPŠU",
  "LEPUR",
  "LERCH",
  "LEREM",
  "LERER",
  "LERIH",
  "LERJA",
  "LERJU",
  "LERMA",
  "LERME",
  "LERMI",
  "LERMU",
  "LEROM",
  "LEROS",
  "LEROV",
  "LESAH",
  "LESAM",
  "LESAR",
  "LESCE",
  "LESCH",
  "LESEM",
  "LESEN",
  "LESEV",
  "LESIC",
  "LESIH",
  "LESIJ",
  "LESJA",
  "LESJE",
  "LESJI",
  "LESJO",
  "LESJU",
  "LESKA",
  "LESKE",
  "LESKI",
  "LESKO",
  "LESKU",
  "LESLI",
  "LESNA",
  "LESNE",
  "LESNI",
  "LESNO",
  "LESOM",
  "LESOV",
  "LESTI",
  "LEŠAH",
  "LEŠAM",
  "LEŠAN",
  "LEŠČA",
  "LEŠČE",
  "LEŠČU",
  "LEŠEK",
  "LEŠEM",
  "LEŠER",
  "LEŠEV",
  "LEŠIČ",
  "LEŠIH",
  "LEŠJA",
  "LEŠJE",
  "LEŠJU",
  "LEŠKA",
  "LEŠKE",
  "LEŠKI",
  "LEŠKO",
  "LEŠKU",
  "LEŠOM",
  "LEŠOV",
  "LETAH",
  "LETAJ",
  "LETAK",
  "LETAL",
  "LETAM",
  "LETAŠ",
  "LETAT",
  "LETAU",
  "LETAV",
  "LETEČ",
  "LETEL",
  "LETEM",
  "LETEN",
  "LETET",
  "LETEU",
  "LETEV",
  "LETHA",
  "LETHE",
  "LETHI",
  "LETHU",
  "LETIC",
  "LETIČ",
  "LETIG",
  "LETIH",
  "LETIM",
  "LETIN",
  "LETIŠ",
  "LETJA",
  "LETJU",
  "LETNA",
  "LETNE",
  "LETNI",
  "LETNO",
  "LETOM",
  "LETOS",
  "LETOV",
  "LETTA",
  "LETTE",
  "LETTI",
  "LETTO",
  "LETTS",
  "LETTU",
  "LETUJ",
  "LETUŠ",
  "LETVA",
  "LETVE",
  "LETVI",
  "LETVO",
  "LETVU",
  "LEUBA",
  "LEUBE",
  "LEUBI",
  "LEUBO",
  "LEUBU",
  "LEUIH",
  "LEUJA",
  "LEUJU",
  "LEUNG",
  "LEUOM",
  "LEUOV",
  "LEUPP",
  "LEVAC",
  "LEVAD",
  "LEVAK",
  "LEVAN",
  "LEVAR",
  "LEVCA",
  "LEVCE",
  "LEVCI",
  "LEVCU",
  "LEVEC",
  "LEVEČ",
  "LEVEM",
  "LEVER",
  "LEVET",
  "LEVEV",
  "LEVGA",
  "LEVIC",
  "LEVIČ",
  "LEVIE",
  "LEVIH",
  "LEVIL",
  "LEVIM",
  "LEVIN",
  "LEVIŠ",
  "LEVIT",
  "LEVIU",
  "LEVIV",
  "LEVJA",
  "LEVJE",
  "LEVJI",
  "LEVJO",
  "LEVJU",
  "LEVOM",
  "LEVON",
  "LEVOV",
  "LEVPA",
  "LEVPE",
  "LEVPI",
  "LEVPO",
  "LEVTA",
  "LEVTE",
  "LEVTI",
  "LEVTU",
  "LEZAM",
  "LEZBA",
  "LEZBE",
  "LEZBI",
  "LEZBO",
  "LEZEL",
  "LEZEM",
  "LEZEŠ",
  "LEZEU",
  "LEZHA",
  "LEZHE",
  "LEZHI",
  "LEZHO",
  "LEZIJ",
  "LEZLA",
  "LEZLE",
  "LEZLI",
  "LEZLO",
  "LEZOČ",
  "LEZTI",
  "LEZZI",
  "LEŽAJ",
  "LEŽAK",
  "LEŽAL",
  "LEŽAT",
  "LEŽAU",
  "LEŽAV",
  "LEŽEČ",
  "LEŽEM",
  "LEŽEN",
  "LEŽEŠ",
  "LEŽIČ",
  "LEŽIM",
  "LEŽIŠ",
  "LEŽNA",
  "LEŽNE",
  "LEŽNI",
  "LEŽNO",
  "LHASA",
  "LHASE",
  "LHASI",
  "LHASO",
  "LHOTA",
  "LHOTE",
  "LHOTI",
  "LHOTU",
  "LIAIH",
  "LIAJA",
  "LIAJU",
  "LIAMA",
  "LIAME",
  "LIAMI",
  "LIAMU",
  "LIANA",
  "LIANE",
  "LIANG",
  "LIANI",
  "LIANN",
  "LIANO",
  "LIANU",
  "LIAOM",
  "LIAOV",
  "LIASA",
  "LIASE",
  "LIASI",
  "LIASU",
  "LIAZA",
  "LIAZE",
  "LIAZI",
  "LIAZO",
  "LIBAH",
  "LIBAM",
  "LIBEL",
  "LIBER",
  "LIBIČ",
  "LIBIN",
  "LIBNA",
  "LIBNE",
  "LIBNI",
  "LIBNO",
  "LIBOJ",
  "LIBON",
  "LIBOR",
  "LIBRA",
  "LIBRE",
  "LIBRI",
  "LIBRO",
  "LICAH",
  "LICAM",
  "LICAT",
  "LICEJ",
  "LICEM",
  "LICEV",
  "LICHT",
  "LICIH",
  "LICKA",
  "LICKE",
  "LICKI",
  "LICKU",
  "LICOM",
  "LICON",
  "LICOV",
  "LICUL",
  "LIČAJ",
  "LIČAL",
  "LIČAM",
  "LIČAN",
  "LIČAR",
  "LIČAŠ",
  "LIČAT",
  "LIČAU",
  "LIČAV",
  "LIČEC",
  "LIČEČ",
  "LIČEF",
  "LIČEK",
  "LIČEN",
  "LIČER",
  "LIČIL",
  "LIČIM",
  "LIČIN",
  "LIČIŠ",
  "LIČIT",
  "LIČIU",
  "LIČIV",
  "LIČJA",
  "LIČJE",
  "LIČJU",
  "LIČKA",
  "LIČKE",
  "LIČKI",
  "LIČKU",
  "LIČNA",
  "LIČNE",
  "LIČNI",
  "LIČNO",
  "LIČNU",
  "LIČOF",
  "LIDAK",
  "LIDAR",
  "LIDDI",
  "LIDER",
  "LIDIA",
  "LIDIC",
  "LIDIE",
  "LIDII",
  "LIDIJ",
  "LIDIO",
  "LIDLA",
  "LIDLU",
  "LIDZA",
  "LIDZE",
  "LIDZI",
  "LIDZU",
  "LIEBA",
  "LIEBE",
  "LIEBI",
  "LIEBL",
  "LIEBU",
  "LIECK",
  "LIEFA",
  "LIEFE",
  "LIEFF",
  "LIEFI",
  "LIEFU",
  "LIEGA",
  "LIEGE",
  "LIEGL",
  "LIEJA",
  "LIEJE",
  "LIEJI",
  "LIEJU",
  "LIEKE",
  "LIENA",
  "LIENE",
  "LIENI",
  "LIENU",
  "LIENZ",
  "LIERA",
  "LIERE",
  "LIERI",
  "LIERU",
  "LIESA",
  "LIESE",
  "LIESI",
  "LIESL",
  "LIESO",
  "LIESU",
  "LIETZ",
  "LIEVA",
  "LIEVE",
  "LIEVI",
  "LIEVU",
  "LIFAR",
  "LIFFA",
  "LIFFE",
  "LIFFI",
  "LIFFU",
  "LIFTA",
  "LIFTE",
  "LIFTI",
  "LIFTU",
  "LIGAH",
  "LIGAM",
  "LIGAŠ",
  "LIGAZ",
  "LIGER",
  "LIGHT",
  "LIGJA",
  "LIGJU",
  "LIGOM",
  "LIGON",
  "LIGRA",
  "LIGRE",
  "LIGRI",
  "LIGRU",
  "LIGTA",
  "LIGTE",
  "LIGTI",
  "LIGTU",
  "LIHEM",
  "LIHGA",
  "LIHIH",
  "LIHIM",
  "LIIKA",
  "LIIKE",
  "LIIKI",
  "LIIKU",
  "LIISA",
  "LIISE",
  "LIISI",
  "LIISO",
  "LIJAH",
  "LIJAK",
  "LIJAM",
  "LIJAN",
  "LIJEM",
  "LIJEŠ",
  "LIJEV",
  "LIJIH",
  "LIJIN",
  "LIJJA",
  "LIJJU",
  "LIJMO",
  "LIJOČ",
  "LIJOM",
  "LIJOV",
  "LIJTA",
  "LIJTE",
  "LIJVA",
  "LIKAH",
  "LIKAJ",
  "LIKAL",
  "LIKAM",
  "LIKAN",
  "LIKAR",
  "LIKAŠ",
  "LIKAT",
  "LIKAU",
  "LIKAV",
  "LIKEB",
  "LIKEM",
  "LIKER",
  "LIKEV",
  "LIKIČ",
  "LIKIH",
  "LIKIN",
  "LIKJA",
  "LIKJU",
  "LIKLA",
  "LIKLE",
  "LIKLI",
  "LIKLU",
  "LIKOF",
  "LIKOM",
  "LIKON",
  "LIKOV",
  "LIKUD",
  "LILAH",
  "LILAM",
  "LILEG",
  "LILEK",
  "LILIA",
  "LILIČ",
  "LILIE",
  "LILIH",
  "LILII",
  "LILIJ",
  "LILIK",
  "LILIN",
  "LILIO",
  "LILIT",
  "LILJA",
  "LILJE",
  "LILJI",
  "LILJO",
  "LILJU",
  "LILLA",
  "LILLE",
  "LILLI",
  "LILLO",
  "LILLU",
  "LILOM",
  "LILOV",
  "LILTA",
  "LILTE",
  "LILTI",
  "LILTU",
  "LIMAH",
  "LIMAJ",
  "LIMAL",
  "LIMAM",
  "LIMAN",
  "LIMAŠ",
  "LIMAT",
  "LIMAU",
  "LIMAV",
  "LIMBA",
  "LIMBE",
  "LIMBI",
  "LIMBU",
  "LIMEL",
  "LIMEM",
  "LIMES",
  "LIMET",
  "LIMEV",
  "LIMFA",
  "LIMFE",
  "LIMFI",
  "LIMFO",
  "LIMIH",
  "LIMIN",
  "LIMIT",
  "LIMJA",
  "LIMJU",
  "LIMLA",
  "LIMLE",
  "LIMLI",
  "LIMLU",
  "LIMOM",
  "LIMON",
  "LIMOR",
  "LIMOS",
  "LIMOV",
  "LIMPA",
  "LIMPE",
  "LIMPI",
  "LIMPU",
  "LINAH",
  "LINAM",
  "LINAS",
  "LINCK",
  "LINČA",
  "LINČE",
  "LINČI",
  "LINČU",
  "LINDA",
  "LINDE",
  "LINDH",
  "LINDI",
  "LINDL",
  "LINDO",
  "LINDT",
  "LINDU",
  "LINEA",
  "LINEE",
  "LINEI",
  "LINEJ",
  "LINEO",
  "LINER",
  "LINES",
  "LINGA",
  "LINGE",
  "LINGG",
  "LINGI",
  "LINGU",
  "LINHA",
  "LINHE",
  "LINHI",
  "LINHU",
  "LINIC",
  "LINIH",
  "LINIJ",
  "LINIK",
  "LININ",
  "LINJA",
  "LINJE",
  "LINJI",
  "LINJU",
  "LINKA",
  "LINKE",
  "LINKI",
  "LINKO",
  "LINKU",
  "LINNA",
  "LINNE",
  "LINNI",
  "LINNU",
  "LINOM",
  "LINOS",
  "LINOV",
  "LINSA",
  "LINSE",
  "LINSI",
  "LINSU",
  "LINTA",
  "LINTE",
  "LINTI",
  "LINTO",
  "LINTU",
  "LINUS",
  "LINZA",
  "LINZE",
  "LINZI",
  "LINZU",
  "LIOBL",
  "LIONA",
  "LIONE",
  "LIONI",
  "LIONS",
  "LIONU",
  "LIORA",
  "LIORE",
  "LIORI",
  "LIORO",
  "LIOTT",
  "LIPAH",
  "LIPAJ",
  "LIPAM",
  "LIPAN",
  "LIPAR",
  "LIPAZ",
  "LIPCA",
  "LIPCE",
  "LIPCI",
  "LIPCU",
  "LIPEC",
  "LIPEJ",
  "LIPIC",
  "LIPIČ",
  "LIPID",
  "LIPIH",
  "LIPIK",
  "LIPIN",
  "LIPJA",
  "LIPJE",
  "LIPJU",
  "LIPKA",
  "LIPKE",
  "LIPKI",
  "LIPKO",
  "LIPKU",
  "LIPNI",
  "LIPOM",
  "LIPOV",
  "LIPPA",
  "LIPPE",
  "LIPPI",
  "LIPPO",
  "LIPPS",
  "LIPPU",
  "LIPSA",
  "LIPSE",
  "LIPSI",
  "LIPSU",
  "LIPUŠ",
  "LIRAH",
  "LIRAK",
  "LIRAM",
  "LIREŠ",
  "LIRIK",
  "LIRIM",
  "LIROL",
  "LISAC",
  "LISAH",
  "LISAJ",
  "LISAK",
  "LISAL",
  "LISAM",
  "LISAN",
  "LISAR",
  "LISAŠ",
  "LISAT",
  "LISAU",
  "LISAV",
  "LISCA",
  "LISCE",
  "LISCH",
  "LISCI",
  "LISCO",
  "LISCU",
  "LISEC",
  "LISIC",
  "LISIL",
  "LISIN",
  "LISKA",
  "LISKE",
  "LISKI",
  "LISKO",
  "LISLA",
  "LISLE",
  "LISLI",
  "LISLU",
  "LISSA",
  "LISSE",
  "LISSI",
  "LISSO",
  "LISSU",
  "LISTA",
  "LISTE",
  "LISTI",
  "LISTO",
  "LISTU",
  "LISUL",
  "LISZT",
  "LIŠAJ",
  "LIŠKA",
  "LIŠKE",
  "LIŠKI",
  "LIŠKO",
  "LIŠPA",
  "LIŠPE",
  "LIŠPI",
  "LIŠPU",
  "LITAH",
  "LITAJ",
  "LITAM",
  "LITAN",
  "LITAS",
  "LITEM",
  "LITER",
  "LITES",
  "LITGA",
  "LITIH",
  "LITIJ",
  "LITIM",
  "LITIN",
  "LITJA",
  "LITJE",
  "LITJI",
  "LITJU",
  "LITOT",
  "LITOU",
  "LITRA",
  "LITRE",
  "LITRI",
  "LITRU",
  "LITTA",
  "LITTE",
  "LITTI",
  "LITTO",
  "LITTU",
  "LITVA",
  "LITVE",
  "LITVI",
  "LITVO",
  "LITZK",
  "LIUJA",
  "LIUJE",
  "LIUJI",
  "LIUJU",
  "LIUNI",
  "LIVAD",
  "LIVAJ",
  "LIVAR",
  "LIVEK",
  "LIVEM",
  "LIVEN",
  "LIVER",
  "LIVEV",
  "LIVIA",
  "LIVIE",
  "LIVIH",
  "LIVII",
  "LIVIJ",
  "LIVIN",
  "LIVIO",
  "LIVIU",
  "LIVJA",
  "LIVJU",
  "LIVKA",
  "LIVKE",
  "LIVKI",
  "LIVKO",
  "LIVKU",
  "LIVNA",
  "LIVNE",
  "LIVNI",
  "LIVNO",
  "LIVNU",
  "LIVOM",
  "LIVOV",
  "LIVRA",
  "LIVRE",
  "LIVRI",
  "LIVRO",
  "LIZAČ",
  "LIZAH",
  "LIZAL",
  "LIZAM",
  "LIZAT",
  "LIZAU",
  "LIZAV",
  "LIZEM",
  "LIZEV",
  "LIZIH",
  "LIZIK",
  "LIZIL",
  "LIZIN",
  "LIZJA",
  "LIZJU",
  "LIZOL",
  "LIZOM",
  "LIZOV",
  "LIZUN",
  "LIŽAN",
  "LIŽEM",
  "LIŽEŠ",
  "LJERK",
  "LJOBA",
  "LJOBE",
  "LJOBI",
  "LJOBO",
  "LJUBA",
  "LJUBE",
  "LJUBI",
  "LJUBO",
  "LJUBU",
  "LJUDA",
  "LJUDI",
  "LJUDU",
  "LJUNG",
  "LJUPK",
  "LLALL",
  "LLAZI",
  "LLOPA",
  "LLOPE",
  "LLOPI",
  "LLOPU",
  "LLORC",
  "LLOSA",
  "LLOSE",
  "LLOSI",
  "LLOSO",
  "LLOSU",
  "LLOTI",
  "LLUIS",
  "LLUKA",
  "LLUKE",
  "LLUKI",
  "LLUKO",
  "LLUKU",
  "LLULL",
  "LLUPA",
  "LLUPE",
  "LLUPI",
  "LLUPO",
  "LLUPU",
  "LOACH",
  "LOAOZ",
  "LOARA",
  "LOARE",
  "LOARI",
  "LOARO",
  "LOBAD",
  "LOBAN",
  "LOBBA",
  "LOBBE",
  "LOBBI",
  "LOBBU",
  "LOBEM",
  "LOBEV",
  "LOBIH",
  "LOBIJ",
  "LOBIS",
  "LOBJA",
  "LOBJU",
  "LOBLA",
  "LOBLE",
  "LOBLI",
  "LOBLU",
  "LOBOD",
  "LOBOM",
  "LOBOR",
  "LOBOS",
  "LOBOV",
  "LOBUL",
  "LOBUS",
  "LOCEM",
  "LOCEN",
  "LOCEV",
  "LOCHA",
  "LOCHE",
  "LOCHI",
  "LOCHT",
  "LOCHU",
  "LOCIH",
  "LOCJA",
  "LOCJU",
  "LOCKA",
  "LOCKE",
  "LOCKI",
  "LOCKS",
  "LOCKU",
  "LOCNA",
  "LOCNE",
  "LOCNI",
  "LOCNU",
  "LOCOM",
  "LOCOV",
  "LOČAH",
  "LOČAJ",
  "LOČAM",
  "LOČAN",
  "LOČBA",
  "LOČBE",
  "LOČBI",
  "LOČBO",
  "LOČEČ",
  "LOČEK",
  "LOČEM",
  "LOČEN",
  "LOČEŠ",
  "LOČEV",
  "LOČIČ",
  "LOČIH",
  "LOČIL",
  "LOČIM",
  "LOČIN",
  "LOČIŠ",
  "LOČIT",
  "LOČIU",
  "LOČIV",
  "LOČJA",
  "LOČJE",
  "LOČJO",
  "LOČJU",
  "LOČKA",
  "LOČKE",
  "LOČKI",
  "LOČKO",
  "LOČKU",
  "LOČMA",
  "LOČMI",
  "LOČNA",
  "LOČNE",
  "LOČNI",
  "LOČNO",
  "LOČOV",
  "LOČUJ",
  "LODDI",
  "LODEN",
  "LODER",
  "LODGA",
  "LODGE",
  "LODIH",
  "LODJA",
  "LODJU",
  "LODNA",
  "LODNI",
  "LODNU",
  "LODOM",
  "LODOS",
  "LODOV",
  "LODRA",
  "LODRE",
  "LODRI",
  "LODRO",
  "LODŽA",
  "LODŽU",
  "LOEBA",
  "LOEBE",
  "LOEBI",
  "LOEBU",
  "LOECK",
  "LOEHR",
  "LOEKA",
  "LOEKE",
  "LOEKI",
  "LOEKU",
  "LOELA",
  "LOELE",
  "LOELI",
  "LOELO",
  "LOFFA",
  "LOFFE",
  "LOFFI",
  "LOFFT",
  "LOFFU",
  "LOFIH",
  "LOFJA",
  "LOFJU",
  "LOFOM",
  "LOFOV",
  "LOFTA",
  "LOFTE",
  "LOFTI",
  "LOFTU",
  "LOGAJ",
  "LOGAN",
  "LOGAR",
  "LOGEH",
  "LOGEM",
  "LOGEV",
  "LOGIC",
  "LOGIE",
  "LOGIH",
  "LOGIK",
  "LOGIN",
  "LOGJA",
  "LOGJE",
  "LOGJU",
  "LOGMI",
  "LOGOM",
  "LOGOR",
  "LOGOS",
  "LOGOV",
  "LOHAN",
  "LOHIJ",
  "LOHRA",
  "LOHRE",
  "LOHRI",
  "LOHRU",
  "LOHSE",
  "LOIBL",
  "LOICA",
  "LOICE",
  "LOICI",
  "LOICU",
  "LOIDL",
  "LOISL",
  "LOJAH",
  "LOJAK",
  "LOJAM",
  "LOJAV",
  "LOJEM",
  "LOJEN",
  "LOJEV",
  "LOJIH",
  "LOJJA",
  "LOJJU",
  "LOJKA",
  "LOJKE",
  "LOJKI",
  "LOJKU",
  "LOJNA",
  "LOJNE",
  "LOJNI",
  "LOJNO",
  "LOJOM",
  "LOJOV",
  "LOJZA",
  "LOJZE",
  "LOJZI",
  "LOJZK",
  "LOJZU",
  "LOKAH",
  "LOKAJ",
  "LOKAL",
  "LOKAM",
  "LOKAN",
  "LOKAR",
  "LOKAS",
  "LOKAŠ",
  "LOKAT",
  "LOKAU",
  "LOKAV",
  "LOKEM",
  "LOKEV",
  "LOKIH",
  "LOKIN",
  "LOKJA",
  "LOKJU",
  "LOKKA",
  "LOKKE",
  "LOKKI",
  "LOKKO",
  "LOKKU",
  "LOKOM",
  "LOKOV",
  "LOKUS",
  "LOKVE",
  "LOKVI",
  "LOLAH",
  "LOLAM",
  "LOLEK",
  "LOLIH",
  "LOLIN",
  "LOLIT",
  "LOLJA",
  "LOLJE",
  "LOLJI",
  "LOLJO",
  "LOLJU",
  "LOLLA",
  "LOLLE",
  "LOLLI",
  "LOLLO",
  "LOLLU",
  "LOLOM",
  "LOLOV",
  "LOMAČ",
  "LOMAN",
  "LOMAS",
  "LOMEČ",
  "LOMEM",
  "LOMEN",
  "LOMEV",
  "LOMIH",
  "LOMIL",
  "LOMIM",
  "LOMIŠ",
  "LOMIT",
  "LOMIU",
  "LOMIV",
  "LOMJA",
  "LOMJU",
  "LOMMA",
  "LOMME",
  "LOMMI",
  "LOMMU",
  "LOMNA",
  "LOMNE",
  "LOMNI",
  "LOMNO",
  "LOMOM",
  "LOMOV",
  "LONAH",
  "LONAM",
  "LONCA",
  "LONCE",
  "LONCI",
  "LONCU",
  "LONDA",
  "LONDE",
  "LONDI",
  "LONDO",
  "LONDU",
  "LONEC",
  "LONEM",
  "LONGA",
  "LONGE",
  "LONGI",
  "LONGO",
  "LONGU",
  "LONIE",
  "LONIN",
  "LONJA",
  "LONJU",
  "LONKA",
  "LONKE",
  "LONKI",
  "LONKU",
  "LONNA",
  "LONNE",
  "LONNI",
  "LONNU",
  "LONOM",
  "LONZA",
  "LONZE",
  "LONZI",
  "LONZO",
  "LONZU",
  "LOOFA",
  "LOOFE",
  "LOOFI",
  "LOOFU",
  "LOOGA",
  "LOOGE",
  "LOOGI",
  "LOOGU",
  "LOOJA",
  "LOOJE",
  "LOOJI",
  "LOOJU",
  "LOOKS",
  "LOONA",
  "LOONE",
  "LOONG",
  "LOONI",
  "LOONU",
  "LOOPA",
  "LOOPE",
  "LOOPI",
  "LOOPU",
  "LOOSA",
  "LOOSE",
  "LOOSI",
  "LOOSS",
  "LOOSU",
  "LOPAH",
  "LOPAM",
  "LOPAR",
  "LOPAT",
  "LOPEN",
  "LOPES",
  "LOPEZ",
  "LOPIC",
  "LOPIČ",
  "LOPNA",
  "LOPNE",
  "LOPNI",
  "LOPNO",
  "LOPNU",
  "LOPOT",
  "LOPOV",
  "LOPPA",
  "LOPPE",
  "LOPPI",
  "LOPPU",
  "LOPSA",
  "LOPSE",
  "LOPSI",
  "LOPSU",
  "LOPUD",
  "LOPUH",
  "LOPUT",
  "LORAH",
  "LORAL",
  "LORAM",
  "LORAN",
  "LORAS",
  "LORCA",
  "LORCE",
  "LORCI",
  "LORCK",
  "LORCO",
  "LORCU",
  "LORDA",
  "LORDE",
  "LORDI",
  "LORDS",
  "LORDU",
  "LOREL",
  "LOREN",
  "LORET",
  "LORIE",
  "LORIG",
  "LORIH",
  "LORIK",
  "LORIN",
  "LORIS",
  "LORJA",
  "LORJU",
  "LORMA",
  "LORME",
  "LORMI",
  "LORMU",
  "LORNA",
  "LORNE",
  "LORNI",
  "LORNO",
  "LORNU",
  "LOROM",
  "LOROV",
  "LORRE",
  "LOSAH",
  "LOSAJ",
  "LOSAL",
  "LOSAM",
  "LOSAŠ",
  "LOSAT",
  "LOSAU",
  "LOSAV",
  "LOSCH",
  "LOSEM",
  "LOSEV",
  "LOSIH",
  "LOSIN",
  "LOSJA",
  "LOSJE",
  "LOSJI",
  "LOSJO",
  "LOSJU",
  "LOSOM",
  "LOSOS",
  "LOSOV",
  "LOŠČA",
  "LOŠČI",
  "LOŠČL",
  "LOŠČU",
  "LOŠKA",
  "LOŠKE",
  "LOŠKI",
  "LOŠKO",
  "LOTAJ",
  "LOTAL",
  "LOTAM",
  "LOTAN",
  "LOTAR",
  "LOTAŠ",
  "LOTAT",
  "LOTAU",
  "LOTAV",
  "LOTEČ",
  "LOTEM",
  "LOTEV",
  "LOTFI",
  "LOTHA",
  "LOTHE",
  "LOTHI",
  "LOTHU",
  "LOTIČ",
  "LOTIE",
  "LOTIH",
  "LOTIL",
  "LOTIM",
  "LOTIS",
  "LOTIŠ",
  "LOTIT",
  "LOTIU",
  "LOTIV",
  "LOTJA",
  "LOTJU",
  "LOTOM",
  "LOTOS",
  "LOTOV",
  "LOTSE",
  "LOTSI",
  "LOTTA",
  "LOTTE",
  "LOTTI",
  "LOTTO",
  "LOTTU",
  "LOTUS",
  "LOUIE",
  "LOUIS",
  "LOUJA",
  "LOUJE",
  "LOUJI",
  "LOUJU",
  "LOUKA",
  "LOUKE",
  "LOUKI",
  "LOUKU",
  "LOUPA",
  "LOUPE",
  "LOUPI",
  "LOUPU",
  "LOURA",
  "LOURD",
  "LOURE",
  "LOURI",
  "LOURO",
  "LOURU",
  "LOUTH",
  "LOVAČ",
  "LOVCA",
  "LOVCE",
  "LOVCI",
  "LOVCU",
  "LOVEC",
  "LOVEČ",
  "LOVEM",
  "LOVEN",
  "LOVEV",
  "LOVIČ",
  "LOVIH",
  "LOVIL",
  "LOVIM",
  "LOVIN",
  "LOVIŠ",
  "LOVIT",
  "LOVIU",
  "LOVIV",
  "LOVJA",
  "LOVJU",
  "LOVKA",
  "LOVKE",
  "LOVKI",
  "LOVKO",
  "LOVNA",
  "LOVNE",
  "LOVNI",
  "LOVNO",
  "LOVOM",
  "LOVOR",
  "LOVOV",
  "LOVRA",
  "LOVRE",
  "LOVRI",
  "LOVRO",
  "LOVRU",
  "LOVŠA",
  "LOVŠE",
  "LOVŠI",
  "LOVŠO",
  "LOVŠU",
  "LOZAD",
  "LOZAH",
  "LOZAM",
  "LOZAR",
  "LOZEJ",
  "LOZIC",
  "LOZIČ",
  "LOZIN",
  "LOZZI",
  "LOŽAH",
  "LOŽAK",
  "LOŽAM",
  "LOŽAN",
  "LOŽAR",
  "LOŽCA",
  "LOŽCU",
  "LOŽEC",
  "LOŽEM",
  "LOŽEN",
  "LOŽEV",
  "LOŽIH",
  "LOŽIN",
  "LOŽJA",
  "LOŽJU",
  "LOŽNA",
  "LOŽNE",
  "LOŽNI",
  "LOŽNO",
  "LOŽOM",
  "LOŽOV",
  "LUANA",
  "LUANE",
  "LUANI",
  "LUANN",
  "LUANO",
  "LUANU",
  "LUBAC",
  "LUBBE",
  "LUBEJ",
  "LUBEN",
  "LUBIC",
  "LUBIN",
  "LUBJA",
  "LUBJE",
  "LUBJU",
  "LUBNA",
  "LUBNE",
  "LUBNI",
  "LUBNO",
  "LUBOR",
  "LUBOŠ",
  "LUBOT",
  "LUCAH",
  "LUCAM",
  "LUCAN",
  "LUCAS",
  "LUCCI",
  "LUCEA",
  "LUCEE",
  "LUCEI",
  "LUCEU",
  "LUCEV",
  "LUCHS",
  "LUCHT",
  "LUCIA",
  "LUCID",
  "LUCIE",
  "LUCIH",
  "LUCII",
  "LUCIJ",
  "LUCIN",
  "LUCIO",
  "LUCIU",
  "LUCJA",
  "LUCJU",
  "LUCKA",
  "LUCKE",
  "LUCKI",
  "LUCKS",
  "LUCKU",
  "LUCOM",
  "LUCOV",
  "LUČAH",
  "LUČAJ",
  "LUČAL",
  "LUČAM",
  "LUČAN",
  "LUČAR",
  "LUČAŠ",
  "LUČAT",
  "LUČAU",
  "LUČAV",
  "LUČCA",
  "LUČCE",
  "LUČCI",
  "LUČCO",
  "LUČEH",
  "LUČEM",
  "LUČEV",
  "LUČIC",
  "LUČIČ",
  "LUČIH",
  "LUČIM",
  "LUČIN",
  "LUČJA",
  "LUČJO",
  "LUČJU",
  "LUČKA",
  "LUČKE",
  "LUČKI",
  "LUČKO",
  "LUČMA",
  "LUČMI",
  "LUČOM",
  "LUČOV",
  "LUDAH",
  "LUDAM",
  "LUDDA",
  "LUDDE",
  "LUDDI",
  "LUDDU",
  "LUDIH",
  "LUDIK",
  "LUDIN",
  "LUDIT",
  "LUDJA",
  "LUDJU",
  "LUDOM",
  "LUDOV",
  "LUEDI",
  "LUEHL",
  "LUELL",
  "LUETH",
  "LUETZ",
  "LUFAJ",
  "LUFEN",
  "LUFFA",
  "LUFFE",
  "LUFFI",
  "LUFFU",
  "LUFNA",
  "LUFNE",
  "LUFNI",
  "LUFNU",
  "LUFTA",
  "LUFTE",
  "LUFTI",
  "LUFTU",
  "LUGAR",
  "LUGEM",
  "LUGER",
  "LUGEV",
  "LUGGA",
  "LUGGE",
  "LUGGI",
  "LUGGU",
  "LUGIH",
  "LUGIN",
  "LUGJA",
  "LUGJU",
  "LUGOL",
  "LUGOM",
  "LUGOS",
  "LUGOV",
  "LUHNA",
  "LUHNE",
  "LUHNI",
  "LUHNU",
  "LUICK",
  "LUIFA",
  "LUIFE",
  "LUIFI",
  "LUIFU",
  "LUIGI",
  "LUIGJ",
  "LUIKA",
  "LUIKE",
  "LUIKI",
  "LUIKU",
  "LUINA",
  "LUINE",
  "LUINI",
  "LUINU",
  "LUISA",
  "LUISE",
  "LUISI",
  "LUISO",
  "LUISU",
  "LUIZA",
  "LUIZE",
  "LUIZI",
  "LUIZO",
  "LUIZU",
  "LUJAN",
  "LUJEM",
  "LUJEV",
  "LUJIH",
  "LUJJA",
  "LUJJU",
  "LUJOM",
  "LUJOV",
  "LUKAČ",
  "LUKAH",
  "LUKAM",
  "LUKAN",
  "LUKAS",
  "LUKAŠ",
  "LUKCA",
  "LUKCE",
  "LUKCI",
  "LUKCU",
  "LUKEC",
  "LUKEM",
  "LUKEŠ",
  "LUKET",
  "LUKEV",
  "LUKEŽ",
  "LUKIČ",
  "LUKIH",
  "LUKIN",
  "LUKIS",
  "LUKIT",
  "LUKJA",
  "LUKJU",
  "LUKNJ",
  "LUKOM",
  "LUKOV",
  "LUKSA",
  "LUKSE",
  "LUKSI",
  "LUKSU",
  "LUKŠA",
  "LUKŠE",
  "LUKŠI",
  "LUKŠO",
  "LUKŠU",
  "LULAH",
  "LULAJ",
  "LULAL",
  "LULAM",
  "LULAŠ",
  "LULAT",
  "LULAU",
  "LULAV",
  "LULEK",
  "LULIC",
  "LULIH",
  "LULIK",
  "LULJA",
  "LULJU",
  "LULKA",
  "LULKE",
  "LULKI",
  "LULKU",
  "LULLA",
  "LULLE",
  "LULLI",
  "LULLO",
  "LULLU",
  "LULOM",
  "LULOV",
  "LUMAH",
  "LUMAM",
  "LUMAR",
  "LUMAS",
  "LUMBA",
  "LUMBE",
  "LUMBI",
  "LUMBU",
  "LUMEN",
  "LUMET",
  "LUMIH",
  "LUMIN",
  "LUMIR",
  "LUMJA",
  "LUMJU",
  "LUMNA",
  "LUMNE",
  "LUMNI",
  "LUMNO",
  "LUMNU",
  "LUMOM",
  "LUMOV",
  "LUMPA",
  "LUMPE",
  "LUMPI",
  "LUMPU",
  "LUNAH",
  "LUNAM",
  "LUNAR",
  "LUNDA",
  "LUNDE",
  "LUNDH",
  "LUNDI",
  "LUNDU",
  "LUNEK",
  "LUNER",
  "LUNET",
  "LUNGA",
  "LUNGE",
  "LUNGI",
  "LUNGU",
  "LUNIC",
  "LUNIH",
  "LUNIK",
  "LUNIN",
  "LUNJA",
  "LUNJE",
  "LUNJI",
  "LUNJU",
  "LUNKA",
  "LUNKE",
  "LUNKI",
  "LUNKO",
  "LUNKU",
  "LUNNA",
  "LUNNE",
  "LUNNI",
  "LUNNU",
  "LUNOM",
  "LUNOS",
  "LUNOV",
  "LUNTA",
  "LUNTE",
  "LUNTI",
  "LUNTU",
  "LUNUL",
  "LUOMA",
  "LUONG",
  "LUOTA",
  "LUOTU",
  "LUOVA",
  "LUOVE",
  "LUOVI",
  "LUOVO",
  "LUPAČ",
  "LUPAH",
  "LUPAM",
  "LUPEI",
  "LUPIH",
  "LUPIL",
  "LUPIM",
  "LUPIN",
  "LUPIŠ",
  "LUPIT",
  "LUPIU",
  "LUPIV",
  "LUPJA",
  "LUPJU",
  "LUPOM",
  "LUPOV",
  "LUPPA",
  "LUPPE",
  "LUPPI",
  "LUPPU",
  "LUPRI",
  "LUPŠA",
  "LUPŠE",
  "LUPŠI",
  "LUPŠO",
  "LUPŠU",
  "LUPUS",
  "LURDA",
  "LURDU",
  "LURGI",
  "LURIA",
  "LURIE",
  "LURII",
  "LURIJ",
  "LURIO",
  "LURIU",
  "LURON",
  "LUSAH",
  "LUSAM",
  "LUSCH",
  "LUSHA",
  "LUSHE",
  "LUSHI",
  "LUSHU",
  "LUSIA",
  "LUSIE",
  "LUSIH",
  "LUSII",
  "LUSIJ",
  "LUSIN",
  "LUSIO",
  "LUSJA",
  "LUSJU",
  "LUSKA",
  "LUSKE",
  "LUSKI",
  "LUSKO",
  "LUSKU",
  "LUSNC",
  "LUSOM",
  "LUSOV",
  "LUSSE",
  "LUSSI",
  "LUSTA",
  "LUSTE",
  "LUSTI",
  "LUSTU",
  "LUŠAH",
  "LUŠAM",
  "LUŠAN",
  "LUŠČE",
  "LUŠČI",
  "LUŠČL",
  "LUŠČU",
  "LUŠEM",
  "LUŠEV",
  "LUŠIČ",
  "LUŠIH",
  "LUŠIN",
  "LUŠJA",
  "LUŠJU",
  "LUŠKA",
  "LUŠKE",
  "LUŠKI",
  "LUŠKO",
  "LUŠOM",
  "LUŠOV",
  "LUŠTA",
  "LUŠTE",
  "LUŠTI",
  "LUŠTU",
  "LUTAH",
  "LUTAK",
  "LUTAM",
  "LUTAR",
  "LUTER",
  "LUTES",
  "LUTFI",
  "LUTHE",
  "LUTIH",
  "LUTJA",
  "LUTJU",
  "LUTKA",
  "LUTKE",
  "LUTKI",
  "LUTKO",
  "LUTOM",
  "LUTON",
  "LUTOV",
  "LUTRA",
  "LUTRE",
  "LUTRI",
  "LUTRU",
  "LUTSA",
  "LUTSE",
  "LUTSI",
  "LUTSU",
  "LUTZA",
  "LUTZE",
  "LUTZI",
  "LUTZU",
  "LUUJA",
  "LUUJE",
  "LUUJI",
  "LUUJU",
  "LUUKA",
  "LUUKE",
  "LUUKI",
  "LUUKO",
  "LUUKU",
  "LUVAN",
  "LUZAR",
  "LUZER",
  "LUZIA",
  "LUZIE",
  "LUZII",
  "LUZIJ",
  "LUZIN",
  "LUZIO",
  "LUZJA",
  "LUZJU",
  "LUZOM",
  "LUZON",
  "LUZZI",
  "LUŽAH",
  "LUŽAM",
  "LUŽAN",
  "LUŽAR",
  "LUŽEČ",
  "LUŽEN",
  "LUŽIC",
  "LUŽIL",
  "LUŽIM",
  "LUŽIN",
  "LUŽIŠ",
  "LUŽIT",
  "LUŽIU",
  "LUŽIV",
  "LUŽNA",
  "LUŽNE",
  "LUŽNI",
  "LUŽNO",
  "LVIII",
  "LVOVA",
  "LVOVU",
  "MAACK",
  "MAADA",
  "MAADE",
  "MAADI",
  "MAADO",
  "MAADU",
  "MAAJK",
  "MAAKA",
  "MAAKE",
  "MAAKI",
  "MAAKU",
  "MAANI",
  "MAARJ",
  "MAASA",
  "MAASE",
  "MAASI",
  "MAASU",
  "MABEL",
  "MABIE",
  "MABIT",
  "MABJU",
  "MABRA",
  "MABRE",
  "MABRI",
  "MABRO",
  "MABUS",
  "MACAA",
  "MACAH",
  "MACAM",
  "MACAN",
  "MACAO",
  "MACAU",
  "MACCI",
  "MACEL",
  "MACER",
  "MACHA",
  "MACHE",
  "MACHI",
  "MACHT",
  "MACHU",
  "MACIH",
  "MACIN",
  "MACIP",
  "MACJA",
  "MACJU",
  "MACKA",
  "MACKE",
  "MACKI",
  "MACKO",
  "MACKU",
  "MACOL",
  "MACOM",
  "MACON",
  "MACOS",
  "MACOV",
  "MACRI",
  "MACUH",
  "MACUL",
  "MACUN",
  "MACUR",
  "MAČAH",
  "MAČAM",
  "MAČAN",
  "MAČAR",
  "MAČEH",
  "MAČEK",
  "MAČET",
  "MAČEV",
  "MAČIC",
  "MAČIH",
  "MAČIN",
  "MAČJA",
  "MAČJE",
  "MAČJI",
  "MAČJO",
  "MAČJU",
  "MAČKA",
  "MAČKE",
  "MAČKI",
  "MAČKO",
  "MAČKU",
  "MAČOM",
  "MAČOV",
  "MAČUS",
  "MAČVA",
  "MAČVE",
  "MAČVI",
  "MAČVO",
  "MADAJ",
  "MADAM",
  "MADAN",
  "MADAR",
  "MADDI",
  "MADEA",
  "MADEE",
  "MADEI",
  "MADEJ",
  "MADEO",
  "MADER",
  "MADEU",
  "MADEŽ",
  "MADGA",
  "MADGE",
  "MADHI",
  "MADIA",
  "MADIČ",
  "MADIE",
  "MADII",
  "MADIO",
  "MADIS",
  "MADIT",
  "MADIU",
  "MADLA",
  "MADLE",
  "MADLI",
  "MADLU",
  "MADON",
  "MADSA",
  "MADSE",
  "MADSI",
  "MADSU",
  "MAECK",
  "MAEDA",
  "MAEDE",
  "MAEDI",
  "MAEDO",
  "MAEDU",
  "MAEJA",
  "MAEJE",
  "MAEJI",
  "MAEJU",
  "MAEKI",
  "MAELE",
  "MAESA",
  "MAESE",
  "MAESI",
  "MAESU",
  "MAEVA",
  "MAEVE",
  "MAEVI",
  "MAEVO",
  "MAFFA",
  "MAFFE",
  "MAFFI",
  "MAFFU",
  "MAFIJ",
  "MAFIN",
  "MAGAL",
  "MAGAN",
  "MAGAR",
  "MAGAŠ",
  "MAGDA",
  "MAGDE",
  "MAGDI",
  "MAGDO",
  "MAGDU",
  "MAGEE",
  "MAGEM",
  "MAGER",
  "MAGEV",
  "MAGGI",
  "MAGGS",
  "MAGIC",
  "MAGIČ",
  "MAGIH",
  "MAGIJ",
  "MAGIK",
  "MAGIN",
  "MAGJA",
  "MAGJU",
  "MAGLI",
  "MAGMA",
  "MAGME",
  "MAGMI",
  "MAGMO",
  "MAGNA",
  "MAGNE",
  "MAGNI",
  "MAGNO",
  "MAGOM",
  "MAGOO",
  "MAGOT",
  "MAGOV",
  "MAGRI",
  "MAGUŠ",
  "MAHAČ",
  "MAHAJ",
  "MAHAL",
  "MAHAM",
  "MAHAN",
  "MAHAŠ",
  "MAHAT",
  "MAHAU",
  "MAHAV",
  "MAHEK",
  "MAHEM",
  "MAHEN",
  "MAHER",
  "MAHEV",
  "MAHIH",
  "MAHIN",
  "MAHIR",
  "MAHJA",
  "MAHJU",
  "MAHKA",
  "MAHKE",
  "MAHKI",
  "MAHKU",
  "MAHLE",
  "MAHNA",
  "MAHNE",
  "MAHNI",
  "MAHNU",
  "MAHOM",
  "MAHON",
  "MAHOV",
  "MAHRL",
  "MAHRT",
  "MAHSA",
  "MAHSE",
  "MAHSI",
  "MAHSO",
  "MAHUT",
  "MAIAH",
  "MAIAM",
  "MAIDA",
  "MAIDE",
  "MAIDI",
  "MAIDO",
  "MAIDU",
  "MAIEM",
  "MAIER",
  "MAIEV",
  "MAIIH",
  "MAIIN",
  "MAIJA",
  "MAIJE",
  "MAIJI",
  "MAIJO",
  "MAIJU",
  "MAIKA",
  "MAIKE",
  "MAIKI",
  "MAIKO",
  "MAIKU",
  "MAILA",
  "MAILE",
  "MAILI",
  "MAILU",
  "MAINA",
  "MAINE",
  "MAINI",
  "MAINS",
  "MAINU",
  "MAINZ",
  "MAIOM",
  "MAIOV",
  "MAIRA",
  "MAIRE",
  "MAIRI",
  "MAIRU",
  "MAISA",
  "MAISE",
  "MAISI",
  "MAISO",
  "MAITA",
  "MAITE",
  "MAITI",
  "MAITU",
  "MAIZA",
  "MAIZE",
  "MAIZI",
  "MAIZU",
  "MAJAH",
  "MAJAJ",
  "MAJAL",
  "MAJAM",
  "MAJAR",
  "MAJAŠ",
  "MAJAT",
  "MAJAU",
  "MAJAV",
  "MAJCA",
  "MAJCE",
  "MAJCI",
  "MAJCU",
  "MAJČA",
  "MAJČE",
  "MAJČI",
  "MAJČK",
  "MAJČU",
  "MAJDA",
  "MAJDE",
  "MAJDI",
  "MAJDK",
  "MAJDO",
  "MAJED",
  "MAJEL",
  "MAJEM",
  "MAJER",
  "MAJES",
  "MAJEŠ",
  "MAJEV",
  "MAJIC",
  "MAJIČ",
  "MAJID",
  "MAJIH",
  "MAJIN",
  "MAJJA",
  "MAJJU",
  "MAJKA",
  "MAJKE",
  "MAJKI",
  "MAJKO",
  "MAJKU",
  "MAJLA",
  "MAJLE",
  "MAJLI",
  "MAJLO",
  "MAJNA",
  "MAJNE",
  "MAJNI",
  "MAJNO",
  "MAJOM",
  "MAJOR",
  "MAJOŠ",
  "MAJOV",
  "MAKAA",
  "MAKAE",
  "MAKAH",
  "MAKAI",
  "MAKAK",
  "MAKAM",
  "MAKAN",
  "MAKAO",
  "MAKAR",
  "MAKAU",
  "MAKEB",
  "MAKEJ",
  "MAKEM",
  "MAKET",
  "MAKEV",
  "MAKIH",
  "MAKIJ",
  "MAKIN",
  "MAKJA",
  "MAKJU",
  "MAKKE",
  "MAKOL",
  "MAKOM",
  "MAKOR",
  "MAKOS",
  "MAKOŠ",
  "MAKOV",
  "MAKRA",
  "MAKRE",
  "MAKRI",
  "MAKRO",
  "MAKRU",
  "MAKSA",
  "MAKSE",
  "MAKSI",
  "MAKSL",
  "MAKSO",
  "MAKSU",
  "MAKŠE",
  "MAKUC",
  "MAKUL",
  "MALAJ",
  "MALAK",
  "MALAL",
  "MALAM",
  "MALAN",
  "MALAR",
  "MALAS",
  "MALAŠ",
  "MALAT",
  "MALAU",
  "MALAV",
  "MALCA",
  "MALCE",
  "MALCI",
  "MALCU",
  "MALČI",
  "MALEC",
  "MALEJ",
  "MALEK",
  "MALEM",
  "MALEN",
  "MALEŠ",
  "MALEV",
  "MALGA",
  "MALHA",
  "MALHE",
  "MALHI",
  "MALHO",
  "MALIA",
  "MALIC",
  "MALIČ",
  "MALIE",
  "MALIH",
  "MALII",
  "MALIJ",
  "MALIK",
  "MALIL",
  "MALIM",
  "MALIN",
  "MALIO",
  "MALIS",
  "MALIŠ",
  "MALJA",
  "MALJU",
  "MALKA",
  "MALKE",
  "MALKI",
  "MALKO",
  "MALKU",
  "MALLA",
  "MALLE",
  "MALLI",
  "MALLO",
  "MALLU",
  "MALMA",
  "MALME",
  "MALMI",
  "MALMU",
  "MALNA",
  "MALNE",
  "MALNI",
  "MALNO",
  "MALOČ",
  "MALOM",
  "MALOT",
  "MALOV",
  "MALSA",
  "MALTA",
  "MALTE",
  "MALTI",
  "MALTO",
  "MALTZ",
  "MALUH",
  "MALUS",
  "MALZL",
  "MAMAH",
  "MAMAM",
  "MAMAT",
  "MAMBA",
  "MAMBE",
  "MAMBI",
  "MAMBO",
  "MAMBU",
  "MAMEČ",
  "MAMER",
  "MAMET",
  "MAMIC",
  "MAMIČ",
  "MAMIE",
  "MAMIL",
  "MAMIM",
  "MAMIN",
  "MAMIS",
  "MAMIŠ",
  "MAMIT",
  "MAMIU",
  "MAMIV",
  "MAMKA",
  "MAMKE",
  "MAMKI",
  "MAMKO",
  "MAMMI",
  "MAMOL",
  "MAMON",
  "MAMUL",
  "MAMUT",
  "MAMUZ",
  "MANAH",
  "MANAJ",
  "MANAM",
  "MANAR",
  "MANAS",
  "MANCA",
  "MANCE",
  "MANCI",
  "MANCO",
  "MANČA",
  "MANČE",
  "MANČI",
  "MANČO",
  "MANČU",
  "MANDA",
  "MANDE",
  "MANDI",
  "MANDL",
  "MANDS",
  "MANDT",
  "MANDU",
  "MANEČ",
  "MANEM",
  "MANER",
  "MANES",
  "MANEŠ",
  "MANET",
  "MANEV",
  "MANEŽ",
  "MANGA",
  "MANGE",
  "MANGI",
  "MANGO",
  "MANGU",
  "MANIC",
  "MANIG",
  "MANIH",
  "MANIJ",
  "MANIN",
  "MANIR",
  "MANIS",
  "MANIŽ",
  "MANJA",
  "MANJE",
  "MANJI",
  "MANJO",
  "MANJU",
  "MANKA",
  "MANKE",
  "MANKI",
  "MANKO",
  "MANKU",
  "MANNA",
  "MANNE",
  "MANNI",
  "MANNO",
  "MANNS",
  "MANNU",
  "MANOJ",
  "MANOM",
  "MANON",
  "MANOS",
  "MANOV",
  "MANOZ",
  "MANSA",
  "MANSE",
  "MANSI",
  "MANSO",
  "MANSU",
  "MANTA",
  "MANTE",
  "MANTI",
  "MANTO",
  "MANUL",
  "MANUS",
  "MANZA",
  "MANZE",
  "MANZI",
  "MANZO",
  "MANZU",
  "MAODA",
  "MAODE",
  "MAODI",
  "MAODO",
  "MAODU",
  "MAOMA",
  "MAORA",
  "MAORE",
  "MAORI",
  "MAORK",
  "MAORU",
  "MAOSA",
  "MAOSU",
  "MAOTA",
  "MAOTU",
  "MAOVA",
  "MAOVE",
  "MAOVI",
  "MAOVO",
  "MAPAH",
  "MAPAM",
  "MAPEN",
  "MAPER",
  "MAPES",
  "MAPLA",
  "MAPLE",
  "MAPLI",
  "MAPLU",
  "MAPNA",
  "MAPNE",
  "MAPNI",
  "MAPNO",
  "MAPPA",
  "MAPPE",
  "MAPPI",
  "MAPPU",
  "MARAH",
  "MARAJ",
  "MARAL",
  "MARAM",
  "MARAN",
  "MARAS",
  "MARAŠ",
  "MARAT",
  "MARAŽ",
  "MARBA",
  "MARBO",
  "MARBU",
  "MARCA",
  "MARCE",
  "MARCH",
  "MARCI",
  "MARCK",
  "MARCO",
  "MARCU",
  "MARDI",
  "MAREC",
  "MAREG",
  "MAREK",
  "MAREL",
  "MAREN",
  "MARES",
  "MAREŠ",
  "MARGA",
  "MARGE",
  "MARGI",
  "MARGO",
  "MARGU",
  "MARHL",
  "MARIA",
  "MARIC",
  "MARIČ",
  "MARIE",
  "MARIH",
  "MARII",
  "MARIJ",
  "MARIK",
  "MARIN",
  "MARIO",
  "MARIS",
  "MARIŠ",
  "MARIT",
  "MARIU",
  "MARIZ",
  "MARJA",
  "MARJE",
  "MARJI",
  "MARJO",
  "MARJU",
  "MARKA",
  "MARKE",
  "MARKI",
  "MARKL",
  "MARKO",
  "MARKS",
  "MARKŠ",
  "MARKT",
  "MARKU",
  "MARLA",
  "MARLE",
  "MARLI",
  "MARLO",
  "MARLU",
  "MARMI",
  "MARMO",
  "MARNA",
  "MARNE",
  "MARNI",
  "MARNO",
  "MARNU",
  "MAROD",
  "MAROF",
  "MAROG",
  "MAROH",
  "MAROK",
  "MAROM",
  "MARON",
  "MAROŠ",
  "MAROT",
  "MAROV",
  "MARRA",
  "MARRE",
  "MARRI",
  "MARRO",
  "MARRS",
  "MARRU",
  "MARSA",
  "MARSE",
  "MARSH",
  "MARSI",
  "MARSU",
  "MARŠA",
  "MARŠE",
  "MARŠI",
  "MARŠU",
  "MARTA",
  "MARTE",
  "MARTH",
  "MARTI",
  "MARTO",
  "MARTU",
  "MARUF",
  "MARUH",
  "MARUJ",
  "MARUM",
  "MARUŠ",
  "MARZI",
  "MARŽA",
  "MARŽE",
  "MARŽI",
  "MARŽO",
  "MASAH",
  "MASAJ",
  "MASAK",
  "MASAL",
  "MASAM",
  "MASAŽ",
  "MASCH",
  "MASCI",
  "MASEN",
  "MASER",
  "MASHA",
  "MASHE",
  "MASHI",
  "MASHO",
  "MASIČ",
  "MASIH",
  "MASIN",
  "MASIP",
  "MASIV",
  "MASJA",
  "MASJU",
  "MASKA",
  "MASKE",
  "MASKI",
  "MASKO",
  "MASKU",
  "MASLA",
  "MASLE",
  "MASLI",
  "MASLO",
  "MASLU",
  "MASNA",
  "MASNE",
  "MASNI",
  "MASNO",
  "MASOM",
  "MASON",
  "MASOR",
  "MASOV",
  "MASRI",
  "MASSA",
  "MASSE",
  "MASSI",
  "MASSO",
  "MASSU",
  "MASTA",
  "MASTE",
  "MASTI",
  "MASTU",
  "MASUD",
  "MASUR",
  "MAŠAH",
  "MAŠAM",
  "MAŠEČ",
  "MAŠEK",
  "MAŠEN",
  "MAŠER",
  "MAŠIČ",
  "MAŠIL",
  "MAŠIM",
  "MAŠIN",
  "MAŠIŠ",
  "MAŠIT",
  "MAŠIU",
  "MAŠIV",
  "MAŠKA",
  "MAŠKE",
  "MAŠKI",
  "MAŠKU",
  "MAŠNA",
  "MAŠNE",
  "MAŠNI",
  "MAŠNO",
  "MAŠUJ",
  "MAŠUK",
  "MAŠUN",
  "MATAH",
  "MATAJ",
  "MATAM",
  "MATAN",
  "MATAR",
  "MATAS",
  "MATEA",
  "MATEE",
  "MATEI",
  "MATEJ",
  "MATEK",
  "MATEL",
  "MATEM",
  "MATEN",
  "MATEO",
  "MATER",
  "MATES",
  "MATEŠ",
  "MATET",
  "MATEU",
  "MATEV",
  "MATHE",
  "MATIC",
  "MATIČ",
  "MATIH",
  "MATIJ",
  "MATIN",
  "MATIP",
  "MATIŠ",
  "MATJA",
  "MATJE",
  "MATJI",
  "MATJO",
  "MATJU",
  "MATKA",
  "MATKE",
  "MATKI",
  "MATKO",
  "MATKU",
  "MATLA",
  "MATLE",
  "MATLI",
  "MATLU",
  "MATNA",
  "MATNE",
  "MATNI",
  "MATNO",
  "MATOH",
  "MATOM",
  "MATON",
  "MATOS",
  "MATOŠ",
  "MATOV",
  "MATOZ",
  "MATRA",
  "MATRI",
  "MATRU",
  "MATSA",
  "MATSE",
  "MATSI",
  "MATSU",
  "MATTA",
  "MATTE",
  "MATTI",
  "MATTO",
  "MATTU",
  "MATUH",
  "MATUL",
  "MATUR",
  "MATUS",
  "MATUŠ",
  "MATZK",
  "MAUDE",
  "MAUER",
  "MAUHA",
  "MAUHE",
  "MAUHI",
  "MAUHU",
  "MAUKA",
  "MAUKE",
  "MAUKI",
  "MAUKO",
  "MAUKU",
  "MAULA",
  "MAULE",
  "MAULI",
  "MAULT",
  "MAULU",
  "MAUMA",
  "MAUME",
  "MAUMI",
  "MAUMU",
  "MAUNA",
  "MAUND",
  "MAUNE",
  "MAUNG",
  "MAUNI",
  "MAUNO",
  "MAUPI",
  "MAURA",
  "MAURE",
  "MAURI",
  "MAURO",
  "MAURU",
  "MAUSA",
  "MAUSE",
  "MAUSI",
  "MAUSS",
  "MAUSU",
  "MAUZA",
  "MAUZE",
  "MAUZI",
  "MAUZU",
  "MAVCA",
  "MAVCE",
  "MAVCI",
  "MAVCU",
  "MAVČE",
  "MAVČI",
  "MAVČL",
  "MAVČU",
  "MAVEC",
  "MAVER",
  "MAVIH",
  "MAVIS",
  "MAVJA",
  "MAVJU",
  "MAVKA",
  "MAVKE",
  "MAVKI",
  "MAVKO",
  "MAVKU",
  "MAVOM",
  "MAVOR",
  "MAVOV",
  "MAVRA",
  "MAVRC",
  "MAVRE",
  "MAVRI",
  "MAVRK",
  "MAVRO",
  "MAVRU",
  "MAVSA",
  "MAVSE",
  "MAVSI",
  "MAVSU",
  "MAVŽA",
  "MAVŽI",
  "MAVŽU",
  "MAZAČ",
  "MAZAH",
  "MAZAL",
  "MAZAM",
  "MAZAN",
  "MAZAR",
  "MAZAS",
  "MAZAT",
  "MAZAU",
  "MAZAV",
  "MAZDA",
  "MAZDE",
  "MAZDI",
  "MAZDO",
  "MAZDU",
  "MAZEJ",
  "MAZEL",
  "MAZEP",
  "MAZER",
  "MAZIA",
  "MAZIE",
  "MAZIH",
  "MAZII",
  "MAZIJ",
  "MAZIL",
  "MAZIN",
  "MAZIO",
  "MAZIU",
  "MAZIV",
  "MAZJA",
  "MAZJU",
  "MAZOM",
  "MAZOR",
  "MAZOV",
  "MAZUR",
  "MAZUT",
  "MAZZA",
  "MAZZE",
  "MAZZI",
  "MAZZO",
  "MAZZU",
  "MAŽAH",
  "MAŽAM",
  "MAŽEM",
  "MAŽEŠ",
  "MBEKI",
  "MBENG",
  "MBONG",
  "MBOTA",
  "MBULI",
  "MCALL",
  "MCBEE",
  "MCCCI",
  "MCCCL",
  "MCCCV",
  "MCCII",
  "MCCIV",
  "MCCLI",
  "MCCLV",
  "MCCUE",
  "MCCVI",
  "MCDII",
  "MCDIV",
  "MCDLI",
  "MCDLV",
  "MCDVI",
  "MCFEE",
  "MCFIT",
  "MCGEE",
  "MCIII",
  "MCINI",
  "MCKEE",
  "MCKIE",
  "MCKIM",
  "MCLII",
  "MCLIN",
  "MCLIV",
  "MCLVI",
  "MCMII",
  "MCMIV",
  "MCMLI",
  "MCMLV",
  "MCMVI",
  "MCNAB",
  "MCNEE",
  "MCRAE",
  "MCREE",
  "MCVIE",
  "MCVII",
  "MDCCC",
  "MDCCI",
  "MDCCL",
  "MDCCV",
  "MDCII",
  "MDCIV",
  "MDCLI",
  "MDCLV",
  "MDCVI",
  "MDDSZ",
  "MDIII",
  "MDLII",
  "MDLIV",
  "MDLVI",
  "MDMJA",
  "MDVII",
  "MEADA",
  "MEADE",
  "MEADI",
  "MEADS",
  "MEADU",
  "MEALS",
  "MEANA",
  "MEANE",
  "MEANI",
  "MEANS",
  "MEANU",
  "MEARA",
  "MEARE",
  "MEARI",
  "MEARO",
  "MEARS",
  "MEARU",
  "MEASH",
  "MEATA",
  "MEATH",
  "MEATS",
  "MEATU",
  "MEAZZ",
  "MEBIN",
  "MEBLA",
  "MEBLO",
  "MEBLU",
  "MEBOR",
  "MECAH",
  "MECAM",
  "MECEN",
  "MECIH",
  "MECJA",
  "MECJU",
  "MECKA",
  "MECKE",
  "MECKI",
  "MECKL",
  "MECKU",
  "MECOM",
  "MECOV",
  "MECUM",
  "MEČAH",
  "MEČAM",
  "MEČAN",
  "MEČAR",
  "MEČAV",
  "MEČEK",
  "MEČEM",
  "MEČEN",
  "MEČEŠ",
  "MEČEV",
  "MEČIC",
  "MEČIH",
  "MEČJA",
  "MEČJU",
  "MEČKA",
  "MEČKE",
  "MEČKI",
  "MEČKU",
  "MEČNA",
  "MEČNE",
  "MEČNI",
  "MEČNO",
  "MEČOM",
  "MEČOV",
  "MEČUJ",
  "MEDAK",
  "MEDAN",
  "MEDAR",
  "MEDDI",
  "MEDEA",
  "MEDEČ",
  "MEDEE",
  "MEDEI",
  "MEDEJ",
  "MEDEL",
  "MEDEM",
  "MEDEN",
  "MEDEO",
  "MEDEŠ",
  "MEDEU",
  "MEDIA",
  "MEDIC",
  "MEDIČ",
  "MEDIE",
  "MEDIH",
  "MEDII",
  "MEDIJ",
  "MEDIK",
  "MEDIL",
  "MEDIM",
  "MEDIN",
  "MEDIO",
  "MEDIS",
  "MEDIŠ",
  "MEDIT",
  "MEDIU",
  "MEDIV",
  "MEDJA",
  "MEDJE",
  "MEDJI",
  "MEDJO",
  "MEDJU",
  "MEDLA",
  "MEDLE",
  "MEDLI",
  "MEDLO",
  "MEDLU",
  "MEDNA",
  "MEDNE",
  "MEDNI",
  "MEDNO",
  "MEDNU",
  "MEDOČ",
  "MEDOM",
  "MEDOV",
  "MEDUL",
  "MEDUZ",
  "MEDVE",
  "MEECA",
  "MEECE",
  "MEEHL",
  "MEEJA",
  "MEEJE",
  "MEEJI",
  "MEEJU",
  "MEEKA",
  "MEEKE",
  "MEEKI",
  "MEEKS",
  "MEEKU",
  "MEERA",
  "MEERE",
  "MEERI",
  "MEERO",
  "MEERS",
  "MEERT",
  "MEERU",
  "MEEUS",
  "MEGAN",
  "MEGAR",
  "MEGEL",
  "MEGGS",
  "MEGIN",
  "MEGLA",
  "MEGLE",
  "MEGLI",
  "MEGLO",
  "MEGLU",
  "MEHAJ",
  "MEHAK",
  "MEHČA",
  "MEHČI",
  "MEHČU",
  "MEHDI",
  "MEHEK",
  "MEHEM",
  "MEHES",
  "MEHEV",
  "MEHIČ",
  "MEHIH",
  "MEHJA",
  "MEHJU",
  "MEHKA",
  "MEHKE",
  "MEHKI",
  "MEHKO",
  "MEHLA",
  "MEHLE",
  "MEHLI",
  "MEHLU",
  "MEHOM",
  "MEHOV",
  "MEHTA",
  "MEHTE",
  "MEHTI",
  "MEHTO",
  "MEHTU",
  "MEHUL",
  "MEHUN",
  "MEHUR",
  "MEHUS",
  "MEIDA",
  "MEIDE",
  "MEIDI",
  "MEIDL",
  "MEIDU",
  "MEIER",
  "MEIGS",
  "MEIJO",
  "MEIJS",
  "MEIKA",
  "MEIKE",
  "MEIKI",
  "MEIKU",
  "MEINL",
  "MEIOZ",
  "MEIRA",
  "MEIRE",
  "MEIRI",
  "MEIRO",
  "MEIRU",
  "MEISE",
  "MEISS",
  "MEITE",
  "MEJAČ",
  "MEJAH",
  "MEJAK",
  "MEJAL",
  "MEJAM",
  "MEJAŠ",
  "MEJEČ",
  "MEJEN",
  "MEJIA",
  "MEJIC",
  "MEJIE",
  "MEJII",
  "MEJIL",
  "MEJIM",
  "MEJIN",
  "MEJIO",
  "MEJIŠ",
  "MEJIT",
  "MEJIU",
  "MEJIV",
  "MEJLA",
  "MEJLE",
  "MEJLI",
  "MEJLU",
  "MEJMO",
  "MEJNA",
  "MEJNE",
  "MEJNI",
  "MEJNO",
  "MEJOZ",
  "MEJRA",
  "MEJRE",
  "MEJRI",
  "MEJRO",
  "MEJTA",
  "MEJTE",
  "MEJVA",
  "MEKAH",
  "MEKAJ",
  "MEKAM",
  "MEKAS",
  "MEKET",
  "MEKHI",
  "MEKIČ",
  "MEKIN",
  "MEKIŠ",
  "MEKKI",
  "MEKŠE",
  "MEKUČ",
  "MELAS",
  "MELBA",
  "MELBE",
  "MELBI",
  "MELBO",
  "MELEH",
  "MELEK",
  "MELEM",
  "MELEN",
  "MELER",
  "MELEV",
  "MELFI",
  "MELIH",
  "MELIK",
  "MELIN",
  "MELIS",
  "MELIT",
  "MELIZ",
  "MELJA",
  "MELJE",
  "MELJI",
  "MELJU",
  "MELKA",
  "MELKE",
  "MELKI",
  "MELKO",
  "MELKU",
  "MELLA",
  "MELLE",
  "MELLI",
  "MELLO",
  "MELLU",
  "MELMI",
  "MELOM",
  "MELON",
  "MELOS",
  "MELOV",
  "MELUA",
  "MELUE",
  "MELUI",
  "MELUJ",
  "MELUN",
  "MELUO",
  "MELUU",
  "MELZI",
  "MELZL",
  "MEMAJ",
  "MEMEM",
  "MEMEV",
  "MEMIČ",
  "MEMIH",
  "MEMJA",
  "MEMJU",
  "MEMLI",
  "MEMME",
  "MEMOM",
  "MEMON",
  "MEMOV",
  "MENAH",
  "MENAM",
  "MENAR",
  "MENCA",
  "MENCI",
  "MENCU",
  "MENDA",
  "MENDI",
  "MENDL",
  "MENDT",
  "MENEČ",
  "MENEK",
  "MENEM",
  "MENGA",
  "MENGE",
  "MENGI",
  "MENGU",
  "MENIC",
  "MENIČ",
  "MENIH",
  "MENIL",
  "MENIM",
  "MENIN",
  "MENIS",
  "MENIŠ",
  "MENIT",
  "MENIU",
  "MENIV",
  "MENJA",
  "MENJI",
  "MENJU",
  "MENKA",
  "MENKE",
  "MENKI",
  "MENKO",
  "MENKS",
  "MENKU",
  "MENNA",
  "MENNE",
  "MENNI",
  "MENNO",
  "MENNU",
  "MENOJ",
  "MENON",
  "MENOR",
  "MENSE",
  "MENZA",
  "MENZE",
  "MENZI",
  "MENZO",
  "MEOLA",
  "MEOLE",
  "MEOLI",
  "MEOLO",
  "MEOLU",
  "MEOMA",
  "MEONI",
  "MEOTA",
  "MEOTU",
  "MEOVA",
  "MEOVE",
  "MEOVI",
  "MEOVO",
  "MERAD",
  "MERAG",
  "MERAH",
  "MERAK",
  "MERAM",
  "MERBA",
  "MERBE",
  "MERBI",
  "MERBO",
  "MERCA",
  "MERCE",
  "MERCI",
  "MERCK",
  "MERCU",
  "MERČE",
  "MEREČ",
  "MEREK",
  "MEREL",
  "MERET",
  "MERGL",
  "MERHI",
  "MERIC",
  "MERID",
  "MERIH",
  "MERIJ",
  "MERIL",
  "MERIM",
  "MERIS",
  "MERIŠ",
  "MERIT",
  "MERIU",
  "MERIV",
  "MERJA",
  "MERJE",
  "MERJI",
  "MERJO",
  "MERKA",
  "MERKE",
  "MERKI",
  "MERKT",
  "MERKU",
  "MERLA",
  "MERLE",
  "MERLI",
  "MERLO",
  "MERLU",
  "MERNA",
  "MERNE",
  "MERNI",
  "MERNO",
  "MEROL",
  "MERON",
  "MERŠE",
  "MERTA",
  "MERTE",
  "MERTI",
  "MERTL",
  "MERTU",
  "MERTZ",
  "MERVA",
  "MERVE",
  "MERVI",
  "MERVU",
  "MERZA",
  "MERZE",
  "MERZI",
  "MERZU",
  "MESAH",
  "MESAM",
  "MESAR",
  "MESCA",
  "MESCE",
  "MESCI",
  "MESCU",
  "MESEC",
  "MESEČ",
  "MESEK",
  "MESEN",
  "MESIČ",
  "MESIH",
  "MESIJ",
  "MESIK",
  "MESIL",
  "MESIM",
  "MESIŠ",
  "MESIT",
  "MESIU",
  "MESIV",
  "MESJA",
  "MESJU",
  "MESKA",
  "MESKI",
  "MESKU",
  "MESNA",
  "MESNE",
  "MESNI",
  "MESNO",
  "MESOM",
  "MESOV",
  "MESSE",
  "MESSI",
  "MESTA",
  "MESTI",
  "MESTO",
  "MESTU",
  "MESUD",
  "MESUT",
  "MEŠAH",
  "MEŠAJ",
  "MEŠAL",
  "MEŠAM",
  "MEŠAN",
  "MEŠAŠ",
  "MEŠAT",
  "MEŠAU",
  "MEŠAV",
  "MEŠEM",
  "MEŠEN",
  "MEŠEV",
  "MEŠIČ",
  "MEŠIH",
  "MEŠIN",
  "MEŠJA",
  "MEŠJU",
  "MEŠKA",
  "MEŠKE",
  "MEŠKI",
  "MEŠKO",
  "MEŠKU",
  "MEŠLA",
  "MEŠLE",
  "MEŠLI",
  "MEŠLU",
  "MEŠOM",
  "MEŠOV",
  "METAH",
  "METAJ",
  "METAL",
  "METAM",
  "METAN",
  "METAT",
  "METAU",
  "METAV",
  "METEK",
  "METEL",
  "METEM",
  "METEN",
  "METER",
  "METEŠ",
  "METEU",
  "METEV",
  "METEŽ",
  "METGA",
  "METGE",
  "METID",
  "METIH",
  "METIJ",
  "METIL",
  "METIM",
  "METIN",
  "METIS",
  "METJA",
  "METJE",
  "METJI",
  "METJU",
  "METKA",
  "METKE",
  "METKI",
  "METKO",
  "METKU",
  "METLA",
  "METLE",
  "METLI",
  "METLO",
  "METNA",
  "METNE",
  "METNI",
  "METNO",
  "METOČ",
  "METOD",
  "METOM",
  "METON",
  "METOP",
  "METOV",
  "METRA",
  "METRE",
  "METRI",
  "METRO",
  "METRU",
  "METSA",
  "METSE",
  "METSI",
  "METSO",
  "METSU",
  "METTE",
  "METUJ",
  "METZA",
  "METZE",
  "METZI",
  "METZL",
  "METZU",
  "MEUSA",
  "MEUSE",
  "MEUSU",
  "MEVCE",
  "MEVŽA",
  "MEVŽE",
  "MEVŽI",
  "MEVŽO",
  "MEZAH",
  "MEZAK",
  "MEZAM",
  "MEZDA",
  "MEZDE",
  "MEZDI",
  "MEZDO",
  "MEZEČ",
  "MEZEG",
  "MEZEK",
  "MEZEL",
  "MEZET",
  "MEZEU",
  "MEZGA",
  "MEZGE",
  "MEZGI",
  "MEZGO",
  "MEZGU",
  "MEZIH",
  "MEZIM",
  "MEZIŠ",
  "MEZJA",
  "MEZJU",
  "MEZKA",
  "MEZKE",
  "MEZKI",
  "MEZKU",
  "MEZOM",
  "MEZON",
  "MEZOV",
  "MEŽAH",
  "MEŽAM",
  "MEŽAN",
  "MEŽEK",
  "MEŽEM",
  "MEŽEV",
  "MEŽIČ",
  "MEŽIH",
  "MEŽIK",
  "MEŽJA",
  "MEŽJU",
  "MEŽKA",
  "MEŽKE",
  "MEŽKI",
  "MEŽKU",
  "MEŽOM",
  "MEŽOV",
  "MFDPŠ",
  "MIAMA",
  "MIAMI",
  "MIANA",
  "MIANE",
  "MIANI",
  "MIANO",
  "MIANU",
  "MIARI",
  "MIAZA",
  "MIAZE",
  "MIAZI",
  "MIAZO",
  "MIBRA",
  "MIBRE",
  "MIBRI",
  "MIBRO",
  "MICAH",
  "MICAJ",
  "MICAM",
  "MICEM",
  "MICEN",
  "MICEV",
  "MICHA",
  "MICHE",
  "MICHI",
  "MICHL",
  "MICHO",
  "MICHU",
  "MICIČ",
  "MICIH",
  "MICIN",
  "MICJA",
  "MICJU",
  "MICKA",
  "MICKE",
  "MICKI",
  "MICKO",
  "MICKU",
  "MICOL",
  "MICOM",
  "MICOV",
  "MICRA",
  "MICRO",
  "MICRU",
  "MIČEM",
  "MIČEN",
  "MIČEV",
  "MIČIČ",
  "MIČIH",
  "MIČJA",
  "MIČJU",
  "MIČNA",
  "MIČNE",
  "MIČNI",
  "MIČNO",
  "MIČOM",
  "MIČOV",
  "MIDAS",
  "MIDJA",
  "MIDJU",
  "MIDOL",
  "MIDOM",
  "MIDRE",
  "MIDVA",
  "MIDVE",
  "MIEDL",
  "MIEHE",
  "MIEHL",
  "MIEKE",
  "MIELA",
  "MIELE",
  "MIELI",
  "MIELU",
  "MIERS",
  "MIERT",
  "MIESA",
  "MIESE",
  "MIESH",
  "MIESI",
  "MIESU",
  "MIETH",
  "MIETT",
  "MIGAJ",
  "MIGAL",
  "MIGAM",
  "MIGAŠ",
  "MIGAT",
  "MIGAU",
  "MIGAV",
  "MIGEM",
  "MIGEV",
  "MIGIH",
  "MIGJA",
  "MIGJU",
  "MIGNE",
  "MIGNI",
  "MIGNU",
  "MIGOM",
  "MIGOS",
  "MIGOV",
  "MIHAH",
  "MIHAI",
  "MIHAL",
  "MIHAM",
  "MIHCA",
  "MIHCE",
  "MIHCI",
  "MIHCU",
  "MIHEC",
  "MIHEL",
  "MIHEV",
  "MIHIČ",
  "MIHIH",
  "MIHIN",
  "MIHJA",
  "MIHJU",
  "MIHMA",
  "MIHME",
  "MIHMI",
  "MIHMU",
  "MIHOK",
  "MIHOM",
  "MIHOV",
  "MIIKA",
  "MIIKE",
  "MIIKI",
  "MIIKK",
  "MIIKO",
  "MIIKU",
  "MIITT",
  "MIJAČ",
  "MIJAH",
  "MIJAM",
  "MIJAT",
  "MIJAV",
  "MIJEM",
  "MIJEŠ",
  "MIJEV",
  "MIJIH",
  "MIJIN",
  "MIJJA",
  "MIJJU",
  "MIJMO",
  "MIJOČ",
  "MIJOK",
  "MIJOM",
  "MIJOV",
  "MIJTA",
  "MIJTE",
  "MIJVA",
  "MIKAC",
  "MIKAČ",
  "MIKAH",
  "MIKAJ",
  "MIKAL",
  "MIKAM",
  "MIKAN",
  "MIKAŠ",
  "MIKAT",
  "MIKAU",
  "MIKAV",
  "MIKCA",
  "MIKCE",
  "MIKCI",
  "MIKCU",
  "MIKEC",
  "MIKEK",
  "MIKEL",
  "MIKEM",
  "MIKEN",
  "MIKES",
  "MIKEV",
  "MIKIC",
  "MIKIČ",
  "MIKIH",
  "MIKIN",
  "MIKIS",
  "MIKIŠ",
  "MIKIT",
  "MIKJA",
  "MIKJU",
  "MIKKA",
  "MIKKE",
  "MIKKI",
  "MIKKO",
  "MIKKU",
  "MIKLA",
  "MIKLE",
  "MIKLI",
  "MIKLU",
  "MIKOL",
  "MIKOM",
  "MIKOS",
  "MIKOŠ",
  "MIKOT",
  "MIKOV",
  "MIKOZ",
  "MIKRA",
  "MIKRE",
  "MIKRI",
  "MIKRO",
  "MIKRU",
  "MIKSA",
  "MIKSE",
  "MIKSI",
  "MIKSU",
  "MIKŠA",
  "MIKŠE",
  "MIKŠI",
  "MIKŠO",
  "MIKŠU",
  "MIKUL",
  "MIKUS",
  "MIKUŠ",
  "MIKUŽ",
  "MILAČ",
  "MILAD",
  "MILAH",
  "MILAK",
  "MILAM",
  "MILAN",
  "MILAR",
  "MILAS",
  "MILAT",
  "MILCH",
  "MILČA",
  "MILČE",
  "MILČI",
  "MILČO",
  "MILČU",
  "MILDA",
  "MILDE",
  "MILDI",
  "MILDO",
  "MILEC",
  "MILEČ",
  "MILEI",
  "MILEK",
  "MILEM",
  "MILEN",
  "MILER",
  "MILES",
  "MILET",
  "MILEV",
  "MILGA",
  "MILIA",
  "MILIC",
  "MILIČ",
  "MILIE",
  "MILIH",
  "MILII",
  "MILIK",
  "MILIL",
  "MILIM",
  "MILIN",
  "MILIO",
  "MILIŠ",
  "MILIT",
  "MILIU",
  "MILIV",
  "MILJA",
  "MILJE",
  "MILJI",
  "MILJO",
  "MILJU",
  "MILKA",
  "MILKE",
  "MILKI",
  "MILKO",
  "MILKU",
  "MILLA",
  "MILLE",
  "MILLI",
  "MILLO",
  "MILLS",
  "MILLU",
  "MILNA",
  "MILNE",
  "MILNI",
  "MILNO",
  "MILNU",
  "MILOB",
  "MILOK",
  "MILOM",
  "MILON",
  "MILOŠ",
  "MILOT",
  "MILOV",
  "MILTA",
  "MILTE",
  "MILTI",
  "MILTU",
  "MIMAH",
  "MIMAM",
  "MIMIC",
  "MIMIK",
  "MIMIN",
  "MIMMA",
  "MIMME",
  "MIMMI",
  "MIMMO",
  "MIMMU",
  "MIMOS",
  "MIMOZ",
  "MIMSA",
  "MIMSE",
  "MIMSI",
  "MIMSU",
  "MINAH",
  "MINAJ",
  "MINAM",
  "MINCA",
  "MINCE",
  "MINCI",
  "MINCK",
  "MINCO",
  "MINDS",
  "MINDT",
  "MINEA",
  "MINEE",
  "MINEI",
  "MINEJ",
  "MINEL",
  "MINEM",
  "MINEO",
  "MINER",
  "MINES",
  "MINEŠ",
  "MINGA",
  "MINGE",
  "MINGI",
  "MINGU",
  "MINHA",
  "MINHE",
  "MINHI",
  "MINHU",
  "MINIC",
  "MINIH",
  "MINIJ",
  "MINIK",
  "MINIL",
  "MININ",
  "MINIR",
  "MINIS",
  "MINIT",
  "MINIU",
  "MINIV",
  "MINJA",
  "MINJE",
  "MINJI",
  "MINJO",
  "MINJU",
  "MINKA",
  "MINKE",
  "MINKI",
  "MINKO",
  "MINKS",
  "MINKU",
  "MINNA",
  "MINNE",
  "MINNI",
  "MINNO",
  "MINNS",
  "MINNU",
  "MINOM",
  "MINOS",
  "MINOT",
  "MINOV",
  "MINSK",
  "MINTA",
  "MINTE",
  "MINTI",
  "MINTO",
  "MINTU",
  "MINTZ",
  "MINUL",
  "MINUS",
  "MINUT",
  "MIOČA",
  "MIOČE",
  "MIOČI",
  "MIOČU",
  "MIOID",
  "MIOMA",
  "MIOME",
  "MIOMI",
  "MIOMU",
  "MIONA",
  "MIONE",
  "MIONI",
  "MIONU",
  "MIOPA",
  "MIOPE",
  "MIOPI",
  "MIOPU",
  "MIOZA",
  "MIOZE",
  "MIOZI",
  "MIOZO",
  "MIRAH",
  "MIRAK",
  "MIRAL",
  "MIRAM",
  "MIRAN",
  "MIRCA",
  "MIRCE",
  "MIRCI",
  "MIRCO",
  "MIRCU",
  "MIREČ",
  "MIREJ",
  "MIREK",
  "MIREL",
  "MIREM",
  "MIREN",
  "MIREV",
  "MIRIC",
  "MIRIH",
  "MIRIJ",
  "MIRIL",
  "MIRIM",
  "MIRIN",
  "MIRIŠ",
  "MIRIT",
  "MIRIU",
  "MIRIV",
  "MIRJA",
  "MIRJE",
  "MIRJI",
  "MIRJO",
  "MIRJU",
  "MIRKA",
  "MIRKE",
  "MIRKI",
  "MIRKO",
  "MIRKU",
  "MIRNA",
  "MIRNE",
  "MIRNI",
  "MIRNO",
  "MIRNU",
  "MIROM",
  "MIRON",
  "MIROV",
  "MIRSA",
  "MIRSE",
  "MIRSI",
  "MIRSO",
  "MIRTA",
  "MIRTE",
  "MIRTI",
  "MIRTO",
  "MIRTU",
  "MIRUJ",
  "MIRZA",
  "MIRZE",
  "MIRZI",
  "MIRZO",
  "MIRZU",
  "MISAK",
  "MISAL",
  "MISCH",
  "MISEL",
  "MISES",
  "MISEU",
  "MISHA",
  "MISHE",
  "MISHI",
  "MISHO",
  "MISHU",
  "MISIC",
  "MISIH",
  "MISIJ",
  "MISIN",
  "MISJA",
  "MISJE",
  "MISJI",
  "MISJO",
  "MISJU",
  "MISLC",
  "MISLE",
  "MISLI",
  "MISLM",
  "MISLU",
  "MISOM",
  "MISON",
  "MISOV",
  "MISSI",
  "MIŠAČ",
  "MIŠAH",
  "MIŠAK",
  "MIŠAM",
  "MIŠAR",
  "MIŠEK",
  "MIŠEL",
  "MIŠEM",
  "MIŠEV",
  "MIŠIC",
  "MIŠIČ",
  "MIŠIH",
  "MIŠIM",
  "MIŠIN",
  "MIŠJA",
  "MIŠJE",
  "MIŠJI",
  "MIŠJO",
  "MIŠJU",
  "MIŠKA",
  "MIŠKE",
  "MIŠKI",
  "MIŠKO",
  "MIŠKU",
  "MIŠMA",
  "MIŠMI",
  "MIŠOM",
  "MIŠON",
  "MIŠOV",
  "MIŠUL",
  "MIŠUR",
  "MITAH",
  "MITAJ",
  "MITAK",
  "MITAM",
  "MITAR",
  "MITCH",
  "MITEM",
  "MITER",
  "MITEV",
  "MITIČ",
  "MITIE",
  "MITIH",
  "MITIJ",
  "MITIN",
  "MITJA",
  "MITJE",
  "MITJI",
  "MITJO",
  "MITJU",
  "MITKA",
  "MITKE",
  "MITKI",
  "MITKO",
  "MITKU",
  "MITOF",
  "MITOL",
  "MITOM",
  "MITOV",
  "MITOZ",
  "MITRA",
  "MITRE",
  "MITRI",
  "MITRO",
  "MITRU",
  "MITTA",
  "MITTE",
  "MITTI",
  "MITTU",
  "MIVKA",
  "MIVKE",
  "MIVKI",
  "MIVKO",
  "MIZAH",
  "MIZAM",
  "MIZAR",
  "MIZEN",
  "MIZIC",
  "MIZIH",
  "MIZIJ",
  "MIZIN",
  "MIZJA",
  "MIZJU",
  "MIZNA",
  "MIZNE",
  "MIZNI",
  "MIZNO",
  "MIZOM",
  "MIZOV",
  "MIZZI",
  "MIŽAL",
  "MIŽAT",
  "MIŽAU",
  "MIŽAV",
  "MIŽEČ",
  "MIŽIM",
  "MIŽIŠ",
  "MJAKI",
  "MJAKU",
  "MJEDA",
  "MJEDE",
  "MJEDI",
  "MJEDO",
  "MJEDU",
  "MJEKU",
  "MJOTU",
  "MKAPA",
  "MKAPE",
  "MKAPI",
  "MKAPO",
  "MKAPU",
  "MKEND",
  "MLAČA",
  "MLAČE",
  "MLAČI",
  "MLAČU",
  "MLADA",
  "MLADC",
  "MLADE",
  "MLADI",
  "MLADO",
  "MLAJA",
  "MLAJE",
  "MLAJI",
  "MLAJO",
  "MLAJU",
  "MLAKA",
  "MLAKE",
  "MLAKI",
  "MLAKO",
  "MLASK",
  "MLATA",
  "MLATE",
  "MLATI",
  "MLATU",
  "MLEKA",
  "MLEKC",
  "MLEKI",
  "MLEKO",
  "MLEKU",
  "MLELA",
  "MLELE",
  "MLELI",
  "MLELO",
  "MLETA",
  "MLETE",
  "MLETI",
  "MLETO",
  "MLEVC",
  "MLIII",
  "MLINA",
  "MLINC",
  "MLINE",
  "MLINI",
  "MLINŠ",
  "MLINU",
  "MLJAČ",
  "MLJET",
  "MLVII",
  "MMCCC",
  "MMCCI",
  "MMCCL",
  "MMCCV",
  "MMCDI",
  "MMCDL",
  "MMCDV",
  "MMCII",
  "MMCIV",
  "MMCLI",
  "MMCLV",
  "MMCMI",
  "MMCML",
  "MMCMV",
  "MMCVI",
  "MMDCC",
  "MMDCI",
  "MMDCL",
  "MMDCV",
  "MMDII",
  "MMDIV",
  "MMDLI",
  "MMDLV",
  "MMDVI",
  "MMIII",
  "MMLII",
  "MMLIV",
  "MMLVI",
  "MMOHA",
  "MMOHE",
  "MMOHI",
  "MMOHU",
  "MMVII",
  "MNENJ",
  "MNOGA",
  "MNOGE",
  "MNOGI",
  "MNOGO",
  "MNOŽE",
  "MNOŽI",
  "MNOŽU",
  "MOAMA",
  "MOAMI",
  "MOANA",
  "MOANE",
  "MOANI",
  "MOANO",
  "MOATI",
  "MOBIL",
  "MOBIN",
  "MOCCI",
  "MOCHA",
  "MOCHE",
  "MOCHI",
  "MOCHU",
  "MOCKA",
  "MOCKE",
  "MOCKI",
  "MOCKU",
  "MOČAN",
  "MOČEH",
  "MOČEL",
  "MOČEM",
  "MOČEN",
  "MOČIL",
  "MOČIM",
  "MOČIŠ",
  "MOČIT",
  "MOČIU",
  "MOČIV",
  "MOČJO",
  "MOČLE",
  "MOČMI",
  "MOČNA",
  "MOČNE",
  "MOČNI",
  "MOČNO",
  "MODAH",
  "MODAM",
  "MODEL",
  "MODEM",
  "MODEN",
  "MODER",
  "MODIA",
  "MODIC",
  "MODIE",
  "MODIH",
  "MODII",
  "MODIN",
  "MODIO",
  "MODIU",
  "MODJA",
  "MODJU",
  "MODLA",
  "MODLE",
  "MODLI",
  "MODLU",
  "MODNA",
  "MODNE",
  "MODNI",
  "MODNO",
  "MODOL",
  "MODOM",
  "MODOV",
  "MODRA",
  "MODRC",
  "MODRE",
  "MODRI",
  "MODRO",
  "MODRU",
  "MODUL",
  "MODUS",
  "MOECK",
  "MOEJA",
  "MOEJE",
  "MOEJI",
  "MOEJU",
  "MOENS",
  "MOERK",
  "MOERS",
  "MOETI",
  "MOFAZ",
  "MOFET",
  "MOFFA",
  "MOFFE",
  "MOFFI",
  "MOFFO",
  "MOFFU",
  "MOGEL",
  "MOGGA",
  "MOGGE",
  "MOGGI",
  "MOGGU",
  "MOGIH",
  "MOGIS",
  "MOGJA",
  "MOGJU",
  "MOGKA",
  "MOGKE",
  "MOGKI",
  "MOGKU",
  "MOGLA",
  "MOGLE",
  "MOGLI",
  "MOGLO",
  "MOGOČ",
  "MOGOM",
  "MOGOT",
  "MOGOV",
  "MOGUL",
  "MOGUŠ",
  "MOHAN",
  "MOHAR",
  "MOHER",
  "MOHIH",
  "MOHJA",
  "MOHJU",
  "MOHLA",
  "MOHLE",
  "MOHLI",
  "MOHLU",
  "MOHNA",
  "MOHNE",
  "MOHNI",
  "MOHNS",
  "MOHNU",
  "MOHOM",
  "MOHOR",
  "MOHOV",
  "MOHRA",
  "MOHRE",
  "MOHRI",
  "MOHRS",
  "MOHRU",
  "MOHSA",
  "MOHSE",
  "MOHSI",
  "MOHSU",
  "MOIEM",
  "MOIEV",
  "MOIIH",
  "MOIJA",
  "MOIJU",
  "MOIKA",
  "MOIKE",
  "MOIKI",
  "MOIKU",
  "MOINA",
  "MOINE",
  "MOINI",
  "MOINO",
  "MOIOM",
  "MOIOV",
  "MOIRA",
  "MOIRE",
  "MOIRI",
  "MOIRO",
  "MOISA",
  "MOISE",
  "MOISI",
  "MOISU",
  "MOJCA",
  "MOJCE",
  "MOJCI",
  "MOJCO",
  "MOJEM",
  "MOJEV",
  "MOJIH",
  "MOJIM",
  "MOJJA",
  "MOJJU",
  "MOJOM",
  "MOJOV",
  "MOKAH",
  "MOKAJ",
  "MOKAL",
  "MOKAM",
  "MOKAR",
  "MOKAŠ",
  "MOKAT",
  "MOKAU",
  "MOKAV",
  "MOKER",
  "MOKIH",
  "MOKJA",
  "MOKJU",
  "MOKOM",
  "MOKOŠ",
  "MOKOV",
  "MOKOŽ",
  "MOKRA",
  "MOKRC",
  "MOKRE",
  "MOKRI",
  "MOKRO",
  "MOKRU",
  "MOLAH",
  "MOLAM",
  "MOLAN",
  "MOLAR",
  "MOLČE",
  "MOLČI",
  "MOLČU",
  "MOLDE",
  "MOLEČ",
  "MOLEH",
  "MOLEK",
  "MOLEL",
  "MOLEM",
  "MOLET",
  "MOLEU",
  "MOLEV",
  "MOLID",
  "MOLIH",
  "MOLIK",
  "MOLIL",
  "MOLIM",
  "MOLIN",
  "MOLIŠ",
  "MOLIT",
  "MOLIU",
  "MOLIV",
  "MOLJA",
  "MOLJE",
  "MOLJI",
  "MOLJK",
  "MOLJU",
  "MOLKA",
  "MOLKE",
  "MOLKI",
  "MOLKO",
  "MOLKU",
  "MOLLA",
  "MOLLE",
  "MOLLI",
  "MOLLO",
  "MOLLU",
  "MOLOD",
  "MOLOH",
  "MOLOM",
  "MOLOS",
  "MOLOV",
  "MOLST",
  "MOLTA",
  "MOLTE",
  "MOLTI",
  "MOLTO",
  "MOLTU",
  "MOLZC",
  "MOLZE",
  "MOLZI",
  "MOLZT",
  "MOLZU",
  "MOLŽA",
  "MOLŽE",
  "MOLŽI",
  "MOLŽO",
  "MOMAH",
  "MOMAM",
  "MOMIH",
  "MOMIK",
  "MOMIR",
  "MOMJA",
  "MOMJU",
  "MOMOA",
  "MOMOE",
  "MOMOI",
  "MOMOJ",
  "MOMOM",
  "MOMOO",
  "MOMOU",
  "MOMOV",
  "MONAD",
  "MONAH",
  "MONAL",
  "MONAM",
  "MONCK",
  "MONDA",
  "MONDE",
  "MONDI",
  "MONDO",
  "MONDU",
  "MONEN",
  "MONER",
  "MONET",
  "MONGA",
  "MONGE",
  "MONGI",
  "MONGO",
  "MONIA",
  "MONIC",
  "MONIE",
  "MONII",
  "MONIJ",
  "MONIK",
  "MONIN",
  "MONIO",
  "MONIT",
  "MONIZ",
  "MONJA",
  "MONJE",
  "MONJI",
  "MONJO",
  "MONJU",
  "MONKA",
  "MONKE",
  "MONKI",
  "MONKS",
  "MONKU",
  "MONNE",
  "MONNI",
  "MONOD",
  "MONSA",
  "MONSE",
  "MONSI",
  "MONSU",
  "MONTA",
  "MONTE",
  "MONTI",
  "MONTT",
  "MONTU",
  "MONZA",
  "MONZE",
  "MONZI",
  "MONZO",
  "MOOCK",
  "MOOGA",
  "MOOGE",
  "MOOGI",
  "MOOGU",
  "MOOKA",
  "MOOKE",
  "MOOKI",
  "MOOKU",
  "MOONA",
  "MOONE",
  "MOONI",
  "MOONS",
  "MOONU",
  "MOORA",
  "MOORE",
  "MOORI",
  "MOORS",
  "MOORU",
  "MOOSA",
  "MOOSE",
  "MOOSI",
  "MOOSU",
  "MOPED",
  "MOPET",
  "MOPJA",
  "MOPJU",
  "MOPOM",
  "MOPSA",
  "MOPSE",
  "MOPSI",
  "MOPSU",
  "MORAČ",
  "MORAG",
  "MORAH",
  "MORAK",
  "MORAL",
  "MORAM",
  "MORAN",
  "MORAR",
  "MORAS",
  "MORAŠ",
  "MORAT",
  "MORCK",
  "MORDA",
  "MORDE",
  "MORDI",
  "MORDT",
  "MORDU",
  "MOREČ",
  "MOREL",
  "MOREM",
  "MOREN",
  "MORER",
  "MOREŠ",
  "MOREŽ",
  "MORIČ",
  "MORIH",
  "MORIJ",
  "MORIK",
  "MORIL",
  "MORIM",
  "MORIN",
  "MORIS",
  "MORIŠ",
  "MORIT",
  "MORIU",
  "MORIV",
  "MORJA",
  "MORJE",
  "MORJI",
  "MORJO",
  "MORJU",
  "MORKA",
  "MORKE",
  "MORKI",
  "MORKU",
  "MORLA",
  "MORLE",
  "MORLI",
  "MORLO",
  "MORLU",
  "MORMO",
  "MOROM",
  "MORON",
  "MOROV",
  "MOROZ",
  "MORSA",
  "MORSE",
  "MORSI",
  "MORSU",
  "MORTA",
  "MORTE",
  "MORTH",
  "MORTI",
  "MORTU",
  "MORUL",
  "MORVA",
  "MOSAD",
  "MOSCA",
  "MOSCE",
  "MOSCI",
  "MOSCO",
  "MOSCU",
  "MOSER",
  "MOSES",
  "MOSSA",
  "MOSSE",
  "MOSSI",
  "MOSSO",
  "MOSSU",
  "MOSTA",
  "MOSTE",
  "MOSTI",
  "MOSTU",
  "MOSUL",
  "MOŠAH",
  "MOŠAM",
  "MOŠAN",
  "MOŠEJ",
  "MOŠEM",
  "MOŠJA",
  "MOŠJU",
  "MOŠKA",
  "MOŠKE",
  "MOŠKI",
  "MOŠKO",
  "MOŠOM",
  "MOŠTA",
  "MOŠTI",
  "MOŠTU",
  "MOŠUS",
  "MOTAH",
  "MOTAJ",
  "MOTAL",
  "MOTAM",
  "MOTAN",
  "MOTAŠ",
  "MOTAT",
  "MOTAU",
  "MOTAV",
  "MOTEČ",
  "MOTEK",
  "MOTEL",
  "MOTEM",
  "MOTEN",
  "MOTET",
  "MOTEV",
  "MOTIH",
  "MOTIK",
  "MOTIL",
  "MOTIM",
  "MOTIN",
  "MOTIŠ",
  "MOTIT",
  "MOTIU",
  "MOTIV",
  "MOTJA",
  "MOTJU",
  "MOTKA",
  "MOTKE",
  "MOTKI",
  "MOTKU",
  "MOTLA",
  "MOTLE",
  "MOTLI",
  "MOTLU",
  "MOTNA",
  "MOTNE",
  "MOTNI",
  "MOTNO",
  "MOTNU",
  "MOTOH",
  "MOTOM",
  "MOTOR",
  "MOTOV",
  "MOTRI",
  "MOTRU",
  "MOTTA",
  "MOTTE",
  "MOTTI",
  "MOTTL",
  "MOTTO",
  "MOTTU",
  "MOTUM",
  "MOTZA",
  "MOTZE",
  "MOTZI",
  "MOTZU",
  "MOUAZ",
  "MOUIN",
  "MOULD",
  "MOULT",
  "MOUMI",
  "MOUNA",
  "MOUNE",
  "MOUNI",
  "MOUNO",
  "MOUNT",
  "MOURA",
  "MOURE",
  "MOURI",
  "MOURO",
  "MOURU",
  "MOUSA",
  "MOUSE",
  "MOUSI",
  "MOUSS",
  "MOUSU",
  "MOVIA",
  "MOVIE",
  "MOVII",
  "MOVIL",
  "MOVIN",
  "MOVIO",
  "MOVŽE",
  "MOZAH",
  "MOZAM",
  "MOZEG",
  "MOZER",
  "MOZGA",
  "MOZGE",
  "MOZGI",
  "MOZGU",
  "MOZHU",
  "MOZIN",
  "MOZZI",
  "MOŽAČ",
  "MOŽAK",
  "MOŽAT",
  "MOŽEČ",
  "MOŽEH",
  "MOŽEK",
  "MOŽEM",
  "MOŽEN",
  "MOŽEV",
  "MOŽIC",
  "MOŽIČ",
  "MOŽIL",
  "MOŽIM",
  "MOŽIN",
  "MOŽIŠ",
  "MOŽIT",
  "MOŽIU",
  "MOŽIV",
  "MOŽJA",
  "MOŽJE",
  "MOŽJU",
  "MOŽKA",
  "MOŽKE",
  "MOŽKI",
  "MOŽKU",
  "MOŽMI",
  "MOŽNA",
  "MOŽNE",
  "MOŽNI",
  "MOŽNO",
  "MOŽOM",
  "MOŽOV",
  "MOŽUJ",
  "MPIDI",
  "MPOTA",
  "MRAČI",
  "MRAČL",
  "MRAČU",
  "MRAKA",
  "MRAKE",
  "MRAKI",
  "MRAKU",
  "MRAZA",
  "MRAZE",
  "MRAZI",
  "MRAZU",
  "MRCIN",
  "MRČAV",
  "MRČEL",
  "MRČEM",
  "MRČES",
  "MRČEV",
  "MRČIH",
  "MRČJA",
  "MRČJU",
  "MRČNA",
  "MRČOM",
  "MRČOV",
  "MRČUN",
  "MRDAJ",
  "MRDAL",
  "MRDAM",
  "MRDAŠ",
  "MRDAT",
  "MRDAU",
  "MRDAV",
  "MRDJA",
  "MRDJE",
  "MRDJI",
  "MRDJO",
  "MRDJU",
  "MRDNE",
  "MRDNI",
  "MRDNU",
  "MREJO",
  "MREMA",
  "MREME",
  "MREMO",
  "MRENA",
  "MRENE",
  "MRENI",
  "MRENO",
  "MRENU",
  "MREST",
  "MRETA",
  "MRETE",
  "MRETI",
  "MREVA",
  "MREVE",
  "MREVI",
  "MREŽA",
  "MREŽE",
  "MREŽI",
  "MREŽO",
  "MREŽU",
  "MRHAH",
  "MRHAM",
  "MRHAR",
  "MRHIC",
  "MRHIN",
  "MRIKA",
  "MRIKE",
  "MRIKI",
  "MRIKO",
  "MRIMO",
  "MRITA",
  "MRITE",
  "MRIVA",
  "MRJEM",
  "MRJEŠ",
  "MRKAČ",
  "MRKEL",
  "MRKEM",
  "MRKEV",
  "MRKGA",
  "MRKIH",
  "MRKIM",
  "MRKJA",
  "MRKJU",
  "MRKLA",
  "MRKLE",
  "MRKLI",
  "MRKLO",
  "MRKNE",
  "MRKNI",
  "MRKNU",
  "MRKOM",
  "MRKOV",
  "MRKŠA",
  "MRKŠE",
  "MRKŠI",
  "MRKŠO",
  "MRKŠU",
  "MRKUN",
  "MRLAK",
  "MRLEČ",
  "MRLEL",
  "MRLET",
  "MRLEU",
  "MRLIČ",
  "MRLIM",
  "MRLIŠ",
  "MRMOT",
  "MRMRA",
  "MRMRI",
  "MRMRU",
  "MROČA",
  "MROČE",
  "MROČI",
  "MROČO",
  "MROSS",
  "MROŽA",
  "MROŽE",
  "MROŽI",
  "MROŽU",
  "MRSAH",
  "MRSAM",
  "MRŠAH",
  "MRŠAK",
  "MRŠAM",
  "MRŠAN",
  "MRŠAV",
  "MRŠČI",
  "MRŠČL",
  "MRŠČU",
  "MRŠEK",
  "MRŠEM",
  "MRŠEV",
  "MRŠIČ",
  "MRŠIH",
  "MRŠIL",
  "MRŠIM",
  "MRŠIŠ",
  "MRŠIT",
  "MRŠIU",
  "MRŠIV",
  "MRŠJA",
  "MRŠJU",
  "MRŠKA",
  "MRŠKE",
  "MRŠKI",
  "MRŠKO",
  "MRŠKU",
  "MRŠOM",
  "MRŠOV",
  "MRTEV",
  "MRTVA",
  "MRTVC",
  "MRTVE",
  "MRTVI",
  "MRTVO",
  "MRTVU",
  "MRVAH",
  "MRVAM",
  "MRVAR",
  "MRVIC",
  "MRVIČ",
  "MRVIH",
  "MRVJA",
  "MRVJU",
  "MRVOM",
  "MRVOŠ",
  "MRVOV",
  "MRZEČ",
  "MRZEK",
  "MRZEL",
  "MRZEN",
  "MRZIL",
  "MRZIM",
  "MRZIŠ",
  "MRZIT",
  "MRZIU",
  "MRZIV",
  "MRZKA",
  "MRZKE",
  "MRZKI",
  "MRZKO",
  "MRZLA",
  "MRZLE",
  "MRZLI",
  "MRZLO",
  "MSIKA",
  "MSIKE",
  "MSIKI",
  "MSIKO",
  "MSIKU",
  "MSNBC",
  "MSPOV",
  "MSSSZ",
  "MTDNA",
  "MTDNK",
  "MUANI",
  "MUCAH",
  "MUCAM",
  "MUCCI",
  "MUCEK",
  "MUCEM",
  "MUCEV",
  "MUCHA",
  "MUCHE",
  "MUCHI",
  "MUCHO",
  "MUCHU",
  "MUCIC",
  "MUCIH",
  "MUCIK",
  "MUCIN",
  "MUCJA",
  "MUCJU",
  "MUCKA",
  "MUCKE",
  "MUCKI",
  "MUCKO",
  "MUCKU",
  "MUCOM",
  "MUCOV",
  "MUCSI",
  "MUČAN",
  "MUČEČ",
  "MUČEN",
  "MUČIČ",
  "MUČIL",
  "MUČIM",
  "MUČIŠ",
  "MUČIT",
  "MUČIU",
  "MUČIV",
  "MUČNA",
  "MUČNE",
  "MUČNI",
  "MUČNO",
  "MUDDA",
  "MUDDE",
  "MUDDI",
  "MUDDU",
  "MUDEČ",
  "MUDER",
  "MUDGA",
  "MUDGE",
  "MUDIE",
  "MUDIL",
  "MUDIM",
  "MUDIN",
  "MUDIŠ",
  "MUDIT",
  "MUDIU",
  "MUDIV",
  "MUDRA",
  "MUDRE",
  "MUDRI",
  "MUDRO",
  "MUECK",
  "MUEHL",
  "MUFAH",
  "MUFAM",
  "MUFEM",
  "MUFEN",
  "MUFEV",
  "MUFIH",
  "MUFJA",
  "MUFJU",
  "MUFNA",
  "MUFNE",
  "MUFNI",
  "MUFNO",
  "MUFOM",
  "MUFOV",
  "MUFTI",
  "MUGOŠ",
  "MUHAH",
  "MUHAJ",
  "MUHAL",
  "MUHAM",
  "MUHAR",
  "MUHAŠ",
  "MUHAT",
  "MUHAU",
  "MUHAV",
  "MUHIC",
  "MUHIČ",
  "MUHIH",
  "MUHIN",
  "MUHJA",
  "MUHJU",
  "MUHLA",
  "MUHLE",
  "MUHLI",
  "MUHLU",
  "MUHNI",
  "MUHOM",
  "MUHOV",
  "MUHRA",
  "MUHRE",
  "MUHRI",
  "MUHRU",
  "MUICK",
  "MUIES",
  "MUIGG",
  "MUIJO",
  "MUIRA",
  "MUIRE",
  "MUIRI",
  "MUIRU",
  "MUJAH",
  "MUJAJ",
  "MUJAL",
  "MUJAM",
  "MUJAN",
  "MUJAŠ",
  "MUJAT",
  "MUJAU",
  "MUJAV",
  "MUJEM",
  "MUJEN",
  "MUJEV",
  "MUJIC",
  "MUJIČ",
  "MUJIH",
  "MUJJA",
  "MUJJU",
  "MUJKA",
  "MUJKE",
  "MUJKI",
  "MUJKO",
  "MUJKU",
  "MUJOM",
  "MUJOV",
  "MUKAH",
  "MUKAJ",
  "MUKAL",
  "MUKAM",
  "MUKAŠ",
  "MUKAT",
  "MUKAU",
  "MUKAV",
  "MUKEŠ",
  "MUKIČ",
  "MUKIH",
  "MUKJA",
  "MUKJU",
  "MUKNE",
  "MUKNI",
  "MUKNU",
  "MUKOM",
  "MUKOV",
  "MUKUS",
  "MULAH",
  "MULAJ",
  "MULAM",
  "MULAT",
  "MULAŽ",
  "MULCA",
  "MULCE",
  "MULCI",
  "MULCU",
  "MULČI",
  "MULČL",
  "MULČU",
  "MULDA",
  "MULDE",
  "MULDI",
  "MULDO",
  "MULEC",
  "MULEČ",
  "MULEJ",
  "MULEM",
  "MULEV",
  "MULHA",
  "MULHE",
  "MULHI",
  "MULHU",
  "MULIČ",
  "MULIH",
  "MULIL",
  "MULIM",
  "MULIN",
  "MULIŠ",
  "MULIT",
  "MULIU",
  "MULIV",
  "MULJA",
  "MULJE",
  "MULJI",
  "MULJO",
  "MULJU",
  "MULLA",
  "MULLE",
  "MULLI",
  "MULLO",
  "MULLU",
  "MULOM",
  "MULOV",
  "MUMAR",
  "MUMEL",
  "MUMIJ",
  "MUMLA",
  "MUMLE",
  "MUMLI",
  "MUMLU",
  "MUMPS",
  "MUNAR",
  "MUNCA",
  "MUNCE",
  "MUNCH",
  "MUNCI",
  "MUNCK",
  "MUNCU",
  "MUNDA",
  "MUNDE",
  "MUNDI",
  "MUNDO",
  "MUNDT",
  "MUNDU",
  "MUNEC",
  "MUNER",
  "MUNGA",
  "MUNGE",
  "MUNGI",
  "MUNGO",
  "MUNGU",
  "MUNIH",
  "MUNIK",
  "MUNIR",
  "MUNIZ",
  "MUNJA",
  "MUNJU",
  "MUNKA",
  "MUNKE",
  "MUNKI",
  "MUNKU",
  "MUNNA",
  "MUNNE",
  "MUNNI",
  "MUNNS",
  "MUNNU",
  "MUNOM",
  "MUNOS",
  "MUNOV",
  "MUNOZ",
  "MUNRO",
  "MUNTA",
  "MUNTE",
  "MUNTI",
  "MUNTU",
  "MUNTZ",
  "MUNZA",
  "MUNZE",
  "MUNZI",
  "MUNZU",
  "MUONA",
  "MUONE",
  "MUONI",
  "MUONU",
  "MURAD",
  "MURAJ",
  "MURAL",
  "MURAT",
  "MURAV",
  "MURCH",
  "MUREN",
  "MURER",
  "MURFF",
  "MURGA",
  "MURGE",
  "MURGI",
  "MURGU",
  "MURIČ",
  "MURIH",
  "MURIN",
  "MURJA",
  "MURJU",
  "MURKA",
  "MURKE",
  "MURKI",
  "MURKO",
  "MURKS",
  "MURKU",
  "MURMU",
  "MURNA",
  "MURNE",
  "MURNI",
  "MURNU",
  "MUROM",
  "MUROV",
  "MURRA",
  "MURRE",
  "MURRI",
  "MURRO",
  "MURRU",
  "MURSA",
  "MURSE",
  "MURSI",
  "MURSU",
  "MURTA",
  "MURTE",
  "MURTI",
  "MURTO",
  "MURTU",
  "MURVA",
  "MURVE",
  "MURVI",
  "MURVO",
  "MURZA",
  "MURZE",
  "MURZI",
  "MURZO",
  "MURZU",
  "MUSAH",
  "MUSAK",
  "MUSAM",
  "MUSAR",
  "MUSCA",
  "MUSCE",
  "MUSCH",
  "MUSCI",
  "MUSCO",
  "MUSCU",
  "MUSEK",
  "MUSEM",
  "MUSEV",
  "MUSIC",
  "MUSIČ",
  "MUSIH",
  "MUSIL",
  "MUSIN",
  "MUSJA",
  "MUSJU",
  "MUSKA",
  "MUSKE",
  "MUSKI",
  "MUSKO",
  "MUSKU",
  "MUSLI",
  "MUSOM",
  "MUSOV",
  "MUSSA",
  "MUSSE",
  "MUSSI",
  "MUSSO",
  "MUSSU",
  "MUSTA",
  "MUSTE",
  "MUSTI",
  "MUSTO",
  "MUSTU",
  "MUSUL",
  "MUŠAT",
  "MUŠIC",
  "MUŠIČ",
  "MUŠJA",
  "MUŠJE",
  "MUŠJI",
  "MUŠJO",
  "MUŠKA",
  "MUŠKE",
  "MUŠKI",
  "MUŠKO",
  "MUTAZ",
  "MUTCA",
  "MUTCE",
  "MUTCI",
  "MUTCU",
  "MUTEC",
  "MUTHA",
  "MUTHE",
  "MUTHI",
  "MUTHU",
  "MUTIČ",
  "MUTIH",
  "MUTIN",
  "MUTJA",
  "MUTJU",
  "MUTKA",
  "MUTKE",
  "MUTKI",
  "MUTKO",
  "MUTKU",
  "MUTLU",
  "MUTOL",
  "MUTOM",
  "MUTON",
  "MUTOV",
  "MUTSU",
  "MUTTI",
  "MUZAH",
  "MUZAJ",
  "MUZAK",
  "MUZAL",
  "MUZAM",
  "MUZAŠ",
  "MUZAT",
  "MUZAU",
  "MUZAV",
  "MUZEJ",
  "MUZEK",
  "MUZGA",
  "MUZGE",
  "MUZGI",
  "MUZGO",
  "MUZIA",
  "MUZIE",
  "MUZII",
  "MUZIK",
  "MUZIL",
  "MUZIO",
  "MUZIU",
  "MUZKA",
  "MUZKE",
  "MUZKI",
  "MUZKU",
  "MUZZI",
  "MUŽAC",
  "MUŽAN",
  "MUŽAR",
  "MUŽCA",
  "MUŽCE",
  "MUŽCI",
  "MUŽCU",
  "MUŽEČ",
  "MUŽEK",
  "MUŽIČ",
  "MUŽIK",
  "MUŽIL",
  "MUŽIM",
  "MUŽIN",
  "MUŽIŠ",
  "MUŽIT",
  "MUŽIU",
  "MUŽIV",
  "MUŽKA",
  "MUŽKE",
  "MUŽKI",
  "MUŽKU",
  "MVIII",
  "MZALI",
  "MZIJU",
  "NAAMA",
  "NAAME",
  "NAAMI",
  "NAAMO",
  "NAASA",
  "NAASE",
  "NAASI",
  "NAASU",
  "NABAL",
  "NABAV",
  "NABER",
  "NABIH",
  "NABIJ",
  "NABIL",
  "NABIT",
  "NABIU",
  "NABIV",
  "NABJA",
  "NABJU",
  "NABOB",
  "NABOD",
  "NABOJ",
  "NABOM",
  "NABOR",
  "NABOT",
  "NABOV",
  "NABRU",
  "NACAH",
  "NACAM",
  "NACCI",
  "NACER",
  "NACEV",
  "NACHA",
  "NACHE",
  "NACHI",
  "NACHO",
  "NACHR",
  "NACHU",
  "NACIF",
  "NACIJ",
  "NACIN",
  "NACON",
  "NAČEL",
  "NAČEM",
  "NAČET",
  "NAČEU",
  "NAČEV",
  "NAČIH",
  "NAČIN",
  "NAČJA",
  "NAČJU",
  "NAČNE",
  "NAČNI",
  "NAČNO",
  "NAČOM",
  "NAČOV",
  "NAČRT",
  "NADAH",
  "NADAL",
  "NADAM",
  "NADAN",
  "NADAS",
  "NADAV",
  "NADEL",
  "NADER",
  "NADET",
  "NADEU",
  "NADEV",
  "NADIA",
  "NADIC",
  "NADIE",
  "NADIG",
  "NADIH",
  "NADII",
  "NADIJ",
  "NADIM",
  "NADIN",
  "NADIO",
  "NADIR",
  "NADIŽ",
  "NADJA",
  "NADJE",
  "NADJI",
  "NADJO",
  "NADJU",
  "NADLA",
  "NADLE",
  "NADLI",
  "NADLU",
  "NADOH",
  "NADOL",
  "NADOM",
  "NADON",
  "NADOV",
  "NADRE",
  "NADRI",
  "NADRL",
  "NADRT",
  "NADUH",
  "NADUR",
  "NADUT",
  "NAEEM",
  "NAEFA",
  "NAEFE",
  "NAEFI",
  "NAEFU",
  "NAEMI",
  "NAESS",
  "NAFIZ",
  "NAFTA",
  "NAFTE",
  "NAFTI",
  "NAFTO",
  "NAGCA",
  "NAGCE",
  "NAGCI",
  "NAGCU",
  "NAGEC",
  "NAGEL",
  "NAGEM",
  "NAGER",
  "NAGGA",
  "NAGIB",
  "NAGIC",
  "NAGIH",
  "NAGIM",
  "NAGIN",
  "NAGIP",
  "NAGIS",
  "NAGLA",
  "NAGLE",
  "NAGLI",
  "NAGLO",
  "NAGLU",
  "NAGNE",
  "NAGNI",
  "NAGNU",
  "NAGON",
  "NAGRA",
  "NAGRE",
  "NAGRI",
  "NAGRU",
  "NAHEL",
  "NAHID",
  "NAHIJ",
  "NAHIN",
  "NAHLA",
  "NAHLE",
  "NAHLI",
  "NAHLU",
  "NAHOD",
  "NAHON",
  "NAHOT",
  "NAHUM",
  "NAIBI",
  "NAIDA",
  "NAIDE",
  "NAIDI",
  "NAIDO",
  "NAIDU",
  "NAIFI",
  "NAIGA",
  "NAIGE",
  "NAIGI",
  "NAIGO",
  "NAIGU",
  "NAIKE",
  "NAILA",
  "NAILE",
  "NAILI",
  "NAILO",
  "NAILS",
  "NAILU",
  "NAIMA",
  "NAIME",
  "NAIMI",
  "NAIMO",
  "NAIMU",
  "NAIRA",
  "NAIRE",
  "NAIRI",
  "NAIRN",
  "NAIRO",
  "NAIRU",
  "NAITA",
  "NAITE",
  "NAITI",
  "NAITO",
  "NAIVA",
  "NAIVC",
  "NAIVE",
  "NAIVI",
  "NAIVK",
  "NAIVO",
  "NAJAD",
  "NAJAH",
  "NAJAM",
  "NAJAV",
  "NAJBL",
  "NAJDB",
  "NAJDE",
  "NAJDI",
  "NAJDU",
  "NAJEF",
  "NAJEJ",
  "NAJEL",
  "NAJEM",
  "NAJER",
  "NAJEŠ",
  "NAJET",
  "NAJEU",
  "NAJIB",
  "NAJID",
  "NAJIN",
  "NAJMA",
  "NAJME",
  "NAJMI",
  "NAJMU",
  "NAJSI",
  "NAJTI",
  "NAKAD",
  "NAKAN",
  "NAKAR",
  "NAKAT",
  "NAKAZ",
  "NAKIB",
  "NAKIČ",
  "NAKIH",
  "NAKIT",
  "NAKJA",
  "NAKJU",
  "NAKLA",
  "NAKLO",
  "NAKLU",
  "NAKOM",
  "NAKOV",
  "NAKUJ",
  "NAKUP",
  "NALAH",
  "NALAM",
  "NALDI",
  "NALEČ",
  "NALEP",
  "NALET",
  "NALIH",
  "NALIJ",
  "NALIK",
  "NALIL",
  "NALIN",
  "NALIS",
  "NALIT",
  "NALIU",
  "NALIV",
  "NALJA",
  "NALJU",
  "NALLI",
  "NALLS",
  "NALOG",
  "NALOM",
  "NALOV",
  "NAMAZ",
  "NAMEN",
  "NAMER",
  "NAMIB",
  "NAMIG",
  "NAMIK",
  "NAMIN",
  "NAMIT",
  "NAMLI",
  "NAMLU",
  "NAMMI",
  "NAMOK",
  "NAMOR",
  "NAMOT",
  "NANAH",
  "NANAM",
  "NANAU",
  "NANCA",
  "NANCE",
  "NANCI",
  "NANDA",
  "NANDE",
  "NANDI",
  "NANDO",
  "NANDU",
  "NANEH",
  "NANEM",
  "NANEV",
  "NANGA",
  "NANIH",
  "NANIK",
  "NANIN",
  "NANIZ",
  "NANJI",
  "NANKE",
  "NANNA",
  "NANNE",
  "NANNI",
  "NANNO",
  "NANOM",
  "NANOS",
  "NANOV",
  "NANTE",
  "NANUT",
  "NAOKI",
  "NAOMI",
  "NAPAD",
  "NAPAH",
  "NAPAK",
  "NAPAM",
  "NAPAT",
  "NAPEČ",
  "NAPEL",
  "NAPER",
  "NAPET",
  "NAPEU",
  "NAPEV",
  "NAPHA",
  "NAPHI",
  "NAPHU",
  "NAPIJ",
  "NAPIL",
  "NAPIS",
  "NAPIT",
  "NAPIU",
  "NAPIV",
  "NAPLU",
  "NAPNE",
  "NAPNI",
  "NAPNO",
  "NAPOJ",
  "NAPOK",
  "NAPOL",
  "NAPON",
  "NAPOR",
  "NAPPA",
  "NAPPE",
  "NAPPI",
  "NAPPO",
  "NAPPU",
  "NAPRU",
  "NAPŠE",
  "NAPŠI",
  "NAPUH",
  "NARAH",
  "NARAM",
  "NARAS",
  "NARAT",
  "NARAV",
  "NARDA",
  "NARDE",
  "NARDI",
  "NARDU",
  "NARED",
  "NAREK",
  "NAREŽ",
  "NARIJ",
  "NARIL",
  "NARIN",
  "NARIS",
  "NARIT",
  "NARIU",
  "NARIV",
  "NAROD",
  "NAROK",
  "NAROT",
  "NARRA",
  "NARRE",
  "NARRI",
  "NARRO",
  "NARRU",
  "NARTA",
  "NARTE",
  "NARTH",
  "NARTI",
  "NARTU",
  "NARUL",
  "NARVA",
  "NARVE",
  "NARVI",
  "NARVO",
  "NASAD",
  "NASAT",
  "NASEJ",
  "NASEK",
  "NASER",
  "NASHA",
  "NASHE",
  "NASHI",
  "NASHU",
  "NASIF",
  "NASIH",
  "NASIL",
  "NASIM",
  "NASIN",
  "NASIP",
  "NASON",
  "NASPI",
  "NASPU",
  "NASRI",
  "NASRU",
  "NASSA",
  "NASSE",
  "NASSI",
  "NASSU",
  "NASTA",
  "NASTE",
  "NASTI",
  "NASTO",
  "NASTU",
  "NASUF",
  "NASUJ",
  "NASUL",
  "NASUN",
  "NASUT",
  "NAŠEL",
  "NAŠEM",
  "NAŠEU",
  "NAŠIC",
  "NAŠIH",
  "NAŠIJ",
  "NAŠIL",
  "NAŠIM",
  "NAŠIT",
  "NAŠIU",
  "NAŠIV",
  "NAŠKA",
  "NAŠKE",
  "NAŠKI",
  "NAŠKO",
  "NAŠKU",
  "NAŠLA",
  "NAŠLE",
  "NAŠLI",
  "NAŠLO",
  "NAŠTU",
  "NATAF",
  "NATAL",
  "NATAN",
  "NATAŠ",
  "NATEČ",
  "NATEG",
  "NATEK",
  "NATHA",
  "NATHE",
  "NATHI",
  "NATHU",
  "NATIČ",
  "NATIH",
  "NATIN",
  "NATIS",
  "NATJA",
  "NATJU",
  "NATKA",
  "NATKE",
  "NATKI",
  "NATKO",
  "NATKU",
  "NATOM",
  "NATON",
  "NATOV",
  "NATRE",
  "NATRI",
  "NATRL",
  "NATRT",
  "NATTA",
  "NATTE",
  "NATTI",
  "NATTO",
  "NATTU",
  "NATUR",
  "NAUCK",
  "NAUČI",
  "NAUČL",
  "NAUČU",
  "NAUDA",
  "NAUDE",
  "NAUDI",
  "NAUDU",
  "NAUEN",
  "NAUKA",
  "NAUKE",
  "NAUKI",
  "NAUKU",
  "NAULT",
  "NAUMA",
  "NAUME",
  "NAUMI",
  "NAUMU",
  "NAUNA",
  "NAUNE",
  "NAUNI",
  "NAUNU",
  "NAURU",
  "NAUTA",
  "NAUTE",
  "NAUTI",
  "NAUTO",
  "NAUTU",
  "NAUŽU",
  "NAVAD",
  "NAVAF",
  "NAVAH",
  "NAVAJ",
  "NAVAL",
  "NAVAM",
  "NAVAR",
  "NAVAS",
  "NAVAZ",
  "NAVDA",
  "NAVDI",
  "NAVDO",
  "NAVDU",
  "NAVEZ",
  "NAVID",
  "NAVIH",
  "NAVIJ",
  "NAVIL",
  "NAVIN",
  "NAVIS",
  "NAVIT",
  "NAVIU",
  "NAVIV",
  "NAVJA",
  "NAVJE",
  "NAVJU",
  "NAVKA",
  "NAVKE",
  "NAVKI",
  "NAVKO",
  "NAVKU",
  "NAVOJ",
  "NAVOM",
  "NAVON",
  "NAVOR",
  "NAVOV",
  "NAVOZ",
  "NAVRE",
  "NAVRI",
  "NAVRU",
  "NAVZU",
  "NAZAJ",
  "NAZAL",
  "NAZAM",
  "NAZAN",
  "NAZAR",
  "NAZEM",
  "NAZGA",
  "NAZIF",
  "NAZIH",
  "NAZIM",
  "NAZIN",
  "NAZIR",
  "NAZIV",
  "NAZJA",
  "NAZJU",
  "NAZMI",
  "NAZOM",
  "NAZON",
  "NAZOR",
  "NAZOV",
  "NAZUK",
  "NAŽEL",
  "NAŽET",
  "NAŽEU",
  "NAŽGE",
  "NAŽGI",
  "NAŽGU",
  "NAŽRE",
  "NAŽRI",
  "NAŽRL",
  "NAŽRT",
  "NBOTA",
  "NDICK",
  "NDIDI",
  "NDISI",
  "NDOBA",
  "NDOBE",
  "NDOBI",
  "NDOBO",
  "NDOBU",
  "NDOCA",
  "NDOCE",
  "NDOCI",
  "NDOCU",
  "NDOJA",
  "NDOJE",
  "NDOJI",
  "NDOJU",
  "NDOKA",
  "NDOKE",
  "NDOKI",
  "NDOKO",
  "NDOKU",
  "NDOLI",
  "NDREC",
  "NEADA",
  "NEADE",
  "NEADI",
  "NEADU",
  "NEAJA",
  "NEAKA",
  "NEALA",
  "NEALE",
  "NEALI",
  "NEALL",
  "NEALO",
  "NEALU",
  "NEAMA",
  "NEAME",
  "NEAMI",
  "NEAMU",
  "NEASA",
  "NEASE",
  "NEASI",
  "NEASO",
  "NEASU",
  "NEATA",
  "NEATE",
  "NEATI",
  "NEATU",
  "NEAVA",
  "NEAVE",
  "NEAVI",
  "NEAVU",
  "NEBAH",
  "NEBAM",
  "NEBCA",
  "NEBCE",
  "NEBCI",
  "NEBCU",
  "NEBDI",
  "NEBEC",
  "NEBEL",
  "NEBEN",
  "NEBES",
  "NEBIH",
  "NEBIJ",
  "NEBIN",
  "NEBIU",
  "NEBLA",
  "NEBLE",
  "NEBLI",
  "NEBLO",
  "NEBLU",
  "NEBNA",
  "NEBNE",
  "NEBNI",
  "NEBNO",
  "NEBOJ",
  "NEBOM",
  "NEBOŠ",
  "NEBOV",
  "NEBRA",
  "NEBRE",
  "NEBRI",
  "NEBRO",
  "NEBUL",
  "NECAH",
  "NECAM",
  "NECAR",
  "NECCI",
  "NECEL",
  "NECIN",
  "NECKA",
  "NECKE",
  "NECKI",
  "NECKU",
  "NECUL",
  "NEČAK",
  "NEČAS",
  "NEČEM",
  "NEČEŠ",
  "NEČIM",
  "NEČJO",
  "NEČKE",
  "NEČMO",
  "NEČTA",
  "NEČTE",
  "NEČUJ",
  "NEČVA",
  "NEDAH",
  "NEDAJ",
  "NEDAM",
  "NEDAŠ",
  "NEDEJ",
  "NEDEM",
  "NEDER",
  "NEDEŠ",
  "NEDIČ",
  "NEDIH",
  "NEDIM",
  "NEDIN",
  "NEDJA",
  "NEDJE",
  "NEDJI",
  "NEDJO",
  "NEDJU",
  "NEDOG",
  "NEDOH",
  "NEDOK",
  "NEDOM",
  "NEDOV",
  "NEDRA",
  "NEDRC",
  "NEDRE",
  "NEDRI",
  "NEDRO",
  "NEEBA",
  "NEEBE",
  "NEEBI",
  "NEEBU",
  "NEEDS",
  "NEEFE",
  "NEEFS",
  "NEELA",
  "NEELE",
  "NEELI",
  "NEELO",
  "NEELU",
  "NEEMA",
  "NEEME",
  "NEEMI",
  "NEEMO",
  "NEEMU",
  "NEENA",
  "NEENE",
  "NEENI",
  "NEENO",
  "NEERA",
  "NEERE",
  "NEERI",
  "NEERO",
  "NEFER",
  "NEFFA",
  "NEFFE",
  "NEFFI",
  "NEFFU",
  "NEFIH",
  "NEFIS",
  "NEFJA",
  "NEFJU",
  "NEFOM",
  "NEFOV",
  "NEFTA",
  "NEFTE",
  "NEFTI",
  "NEFTU",
  "NEGAH",
  "NEGAM",
  "NEGDO",
  "NEGER",
  "NEGEV",
  "NEGGA",
  "NEGGE",
  "NEGGI",
  "NEGGO",
  "NEGGU",
  "NEGIN",
  "NEGOD",
  "NEGRE",
  "NEGRI",
  "NEGRU",
  "NEGUJ",
  "NEGUS",
  "NEHAH",
  "NEHAJ",
  "NEHAL",
  "NEHAM",
  "NEHAŠ",
  "NEHAT",
  "NEHAU",
  "NEHAV",
  "NEHER",
  "NEHIN",
  "NEHLS",
  "NEHMA",
  "NEHME",
  "NEHMI",
  "NEHMU",
  "NEHRU",
  "NEHUJ",
  "NEIKE",
  "NEILA",
  "NEILD",
  "NEILE",
  "NEILI",
  "NEILL",
  "NEILU",
  "NEIMA",
  "NEISA",
  "NEISE",
  "NEISH",
  "NEISI",
  "NEISU",
  "NEITE",
  "NEJAH",
  "NEJAM",
  "NEJAT",
  "NEJAZ",
  "NEJCA",
  "NEJCE",
  "NEJCI",
  "NEJCU",
  "NEJEJ",
  "NEJEM",
  "NEJEŠ",
  "NEJIN",
  "NEJKA",
  "NEJKE",
  "NEJKI",
  "NEJKO",
  "NEJKU",
  "NEJLA",
  "NEJLE",
  "NEJLI",
  "NEJLO",
  "NEJRA",
  "NEJRE",
  "NEJRI",
  "NEJRO",
  "NEJUD",
  "NEKAJ",
  "NEKAK",
  "NEKAM",
  "NEKDO",
  "NEKEM",
  "NEKIH",
  "NEKIM",
  "NEKJA",
  "NEKJE",
  "NEKJU",
  "NEKOČ",
  "NEKOD",
  "NEKOM",
  "NEKUJ",
  "NELAH",
  "NELAM",
  "NELAN",
  "NELCA",
  "NELCE",
  "NELCI",
  "NELCU",
  "NELDA",
  "NELDE",
  "NELDI",
  "NELDO",
  "NELEC",
  "NELEJ",
  "NELEP",
  "NELID",
  "NELIH",
  "NELIJ",
  "NELIN",
  "NELIS",
  "NELJA",
  "NELJU",
  "NELKA",
  "NELKE",
  "NELKI",
  "NELKO",
  "NELKU",
  "NELLA",
  "NELLE",
  "NELLI",
  "NELLO",
  "NELLU",
  "NELMS",
  "NELOM",
  "NELOV",
  "NEMAC",
  "NEMAH",
  "NEMAM",
  "NEMAN",
  "NEMAŠ",
  "NEMCA",
  "NEMCE",
  "NEMCI",
  "NEMCU",
  "NEMČE",
  "NEMČI",
  "NEMČL",
  "NEMČU",
  "NEMEC",
  "NEMEČ",
  "NEMEJ",
  "NEMEL",
  "NEMEM",
  "NEMES",
  "NEMEŠ",
  "NEMET",
  "NEMEU",
  "NEMEZ",
  "NEMGA",
  "NEMIH",
  "NEMIJ",
  "NEMIL",
  "NEMIM",
  "NEMIR",
  "NEMIŠ",
  "NEMKA",
  "NEMKE",
  "NEMKI",
  "NEMKO",
  "NEMOČ",
  "NEMRE",
  "NEMRI",
  "NEMRO",
  "NENAD",
  "NENAH",
  "NENAM",
  "NENDL",
  "NENEH",
  "NENIN",
  "NENNI",
  "NEODA",
  "NEOHA",
  "NEOMA",
  "NEOMI",
  "NEONA",
  "NEONI",
  "NEONK",
  "NEONU",
  "NEORI",
  "NEOSA",
  "NEOSU",
  "NEOTA",
  "NEOTU",
  "NEOVA",
  "NEOVE",
  "NEOVI",
  "NEOVO",
  "NEOŽA",
  "NEOŽI",
  "NEPAL",
  "NEPER",
  "NEPHA",
  "NEPIJ",
  "NEPNE",
  "NEPNI",
  "NEPNO",
  "NEPOJ",
  "NEPPA",
  "NEPPE",
  "NEPPI",
  "NEPPU",
  "NERAD",
  "NERAH",
  "NERAM",
  "NERAT",
  "NERBA",
  "NERBE",
  "NERBI",
  "NERBU",
  "NERCA",
  "NERCE",
  "NERCI",
  "NERCU",
  "NERDI",
  "NEREČ",
  "NERED",
  "NEREJ",
  "NERES",
  "NERET",
  "NERGA",
  "NERGI",
  "NERGU",
  "NERIA",
  "NERID",
  "NERIE",
  "NERIH",
  "NERII",
  "NERIJ",
  "NERIN",
  "NERIO",
  "NERIU",
  "NERJA",
  "NERJU",
  "NERLI",
  "NERMA",
  "NERME",
  "NERMI",
  "NERMO",
  "NEROD",
  "NEROM",
  "NERON",
  "NEROV",
  "NERUD",
  "NERUJ",
  "NERVA",
  "NERVE",
  "NERVI",
  "NERVO",
  "NERVU",
  "NESCH",
  "NESCI",
  "NESEJ",
  "NESEL",
  "NESEM",
  "NESEN",
  "NESEŠ",
  "NESEU",
  "NESIC",
  "NESIJ",
  "NESIT",
  "NESLA",
  "NESLE",
  "NESLI",
  "NESLO",
  "NESMA",
  "NESME",
  "NESMO",
  "NESNA",
  "NESNE",
  "NESNI",
  "NESNO",
  "NESOČ",
  "NESOS",
  "NESPE",
  "NESPI",
  "NESSA",
  "NESSE",
  "NESSI",
  "NESSL",
  "NESSU",
  "NESTA",
  "NESTE",
  "NESTI",
  "NESTO",
  "NESTU",
  "NESUJ",
  "NESVA",
  "NESVE",
  "NEŠČI",
  "NEŠEM",
  "NEŠEV",
  "NEŠIH",
  "NEŠJA",
  "NEŠJU",
  "NEŠKE",
  "NEŠOM",
  "NEŠOV",
  "NETEČ",
  "NETEK",
  "NETEM",
  "NETEV",
  "NETHA",
  "NETHE",
  "NETHI",
  "NETHU",
  "NETIH",
  "NETIK",
  "NETIL",
  "NETIM",
  "NETIŠ",
  "NETIT",
  "NETIU",
  "NETIV",
  "NETJA",
  "NETJU",
  "NETKA",
  "NETKE",
  "NETKI",
  "NETKO",
  "NETKU",
  "NETLI",
  "NETOM",
  "NETOV",
  "NETRE",
  "NETRI",
  "NETRO",
  "NETSA",
  "NETSE",
  "NETSI",
  "NETSU",
  "NETTA",
  "NETTE",
  "NETTI",
  "NETTO",
  "NETTU",
  "NETZA",
  "NETZE",
  "NETZI",
  "NETZU",
  "NEUČE",
  "NEUČI",
  "NEUDA",
  "NEUER",
  "NEUJE",
  "NEUKA",
  "NEUKE",
  "NEUKI",
  "NEUKO",
  "NEULI",
  "NEUMA",
  "NEUME",
  "NEUMI",
  "NEUMU",
  "NEUPA",
  "NEURI",
  "NEUSS",
  "NEVAH",
  "NEVAM",
  "NEVDA",
  "NEVEJ",
  "NEVEL",
  "NEVEM",
  "NEVEN",
  "NEVER",
  "NEVES",
  "NEVEŠ",
  "NEVIA",
  "NEVIE",
  "NEVII",
  "NEVIJ",
  "NEVIL",
  "NEVIN",
  "NEVIO",
  "NEVIS",
  "NEVIU",
  "NEVJE",
  "NEVLA",
  "NEVLE",
  "NEVLI",
  "NEVLU",
  "NEVMA",
  "NEVME",
  "NEVMI",
  "NEVMO",
  "NEVOL",
  "NEVRE",
  "NEVRI",
  "NEVUS",
  "NEZAJ",
  "NEZDE",
  "NEZDI",
  "NEZHA",
  "NEZHE",
  "NEZHI",
  "NEZHO",
  "NEZHU",
  "NEZIH",
  "NEZIM",
  "NEZIR",
  "NEZJA",
  "NEZJU",
  "NEZNA",
  "NEZOM",
  "NEZOV",
  "NEZRE",
  "NEZRI",
  "NEZRO",
  "NEZVE",
  "NEŽAH",
  "NEŽAM",
  "NEŽDI",
  "NEŽEN",
  "NEŽGE",
  "NEŽGI",
  "NEŽIC",
  "NEŽIČ",
  "NEŽID",
  "NEŽIK",
  "NEŽIN",
  "NEŽIV",
  "NEŽKA",
  "NEŽKE",
  "NEŽKI",
  "NEŽKO",
  "NEŽME",
  "NEŽMI",
  "NEŽNA",
  "NEŽNE",
  "NEŽNI",
  "NEŽNO",
  "NEŽRE",
  "NEŽRI",
  "NEŽRO",
  "NGAJA",
  "NGAJE",
  "NGAJI",
  "NGAJU",
  "NGANA",
  "NGANE",
  "NGANI",
  "NGANU",
  "NGATA",
  "NGATE",
  "NGATI",
  "NGATO",
  "NGATU",
  "NGJEL",
  "NGOJA",
  "NGOJE",
  "NGOJI",
  "NGOJU",
  "NGOMA",
  "NGOME",
  "NGOMI",
  "NGOMU",
  "NGOTA",
  "NGOTU",
  "NGOVA",
  "NGOVE",
  "NGOVI",
  "NGOVO",
  "NGOZI",
  "NHANA",
  "NHANE",
  "NHANI",
  "NHANU",
  "NIALA",
  "NIALE",
  "NIALI",
  "NIALL",
  "NIALO",
  "NIAMA",
  "NIAMH",
  "NIAMI",
  "NIANA",
  "NIANE",
  "NIANG",
  "NIANI",
  "NIANS",
  "NIANU",
  "NIAZI",
  "NICCI",
  "NICIH",
  "NICJA",
  "NICJU",
  "NICKA",
  "NICKE",
  "NICKI",
  "NICKL",
  "NICKS",
  "NICKU",
  "NICOL",
  "NICOM",
  "NICOS",
  "NICOV",
  "NIČAN",
  "NIČEČ",
  "NIČEL",
  "NIČEM",
  "NIČEN",
  "NIČET",
  "NIČEV",
  "NIČIH",
  "NIČIL",
  "NIČIM",
  "NIČIŠ",
  "NIČIT",
  "NIČIU",
  "NIČIV",
  "NIČJA",
  "NIČJU",
  "NIČLA",
  "NIČLE",
  "NIČLI",
  "NIČLO",
  "NIČNA",
  "NIČNE",
  "NIČNI",
  "NIČNO",
  "NIČOM",
  "NIČOV",
  "NIČTA",
  "NIČTE",
  "NIČTI",
  "NIČTO",
  "NIDAH",
  "NIDAL",
  "NIDAM",
  "NIDHI",
  "NIDIH",
  "NIDIN",
  "NIDJA",
  "NIDJU",
  "NIDOM",
  "NIDOV",
  "NIEKA",
  "NIEKE",
  "NIEKI",
  "NIEKU",
  "NIELA",
  "NIELE",
  "NIELI",
  "NIELS",
  "NIELU",
  "NIEMA",
  "NIEME",
  "NIEMI",
  "NIEMO",
  "NIEMU",
  "NIERG",
  "NIESA",
  "NIESE",
  "NIESH",
  "NIESI",
  "NIESU",
  "NIETA",
  "NIETE",
  "NIETI",
  "NIETO",
  "NIETT",
  "NIETU",
  "NIGAM",
  "NIGAR",
  "NIGEL",
  "NIGER",
  "NIGGA",
  "NIGGE",
  "NIGGI",
  "NIGGU",
  "NIGHA",
  "NIGHE",
  "NIGHI",
  "NIGHT",
  "NIGHU",
  "NIGIN",
  "NIGLA",
  "NIGLE",
  "NIGLI",
  "NIGLU",
  "NIGRA",
  "NIGRC",
  "NIGRE",
  "NIGRI",
  "NIGRK",
  "NIGRO",
  "NIGRU",
  "NIHAD",
  "NIHAJ",
  "NIHAL",
  "NIHAM",
  "NIHAŠ",
  "NIHAT",
  "NIHAU",
  "NIHAV",
  "NIHČE",
  "NIINA",
  "NIINE",
  "NIINI",
  "NIINO",
  "NIIRA",
  "NIIRE",
  "NIIRI",
  "NIIRO",
  "NIIRU",
  "NIJAS",
  "NIJAZ",
  "NIJEL",
  "NIJEM",
  "NIJEV",
  "NIJIH",
  "NIJIN",
  "NIJJA",
  "NIJJU",
  "NIJOM",
  "NIJOV",
  "NIKAB",
  "NIKAH",
  "NIKAJ",
  "NIKAK",
  "NIKAM",
  "NIKAR",
  "NIKDO",
  "NIKIC",
  "NIKIH",
  "NIKIN",
  "NIKIT",
  "NIKJA",
  "NIKJU",
  "NIKKI",
  "NIKLA",
  "NIKLE",
  "NIKLI",
  "NIKLU",
  "NIKOL",
  "NIKOM",
  "NIKON",
  "NIKOS",
  "NIKOV",
  "NIKŠA",
  "NIKŠE",
  "NIKŠI",
  "NIKŠO",
  "NIKŠU",
  "NIKUL",
  "NILAH",
  "NILAM",
  "NILES",
  "NILIH",
  "NILIN",
  "NILJA",
  "NILJU",
  "NILLA",
  "NILLE",
  "NILLI",
  "NILLO",
  "NILLU",
  "NILOM",
  "NILOV",
  "NILSA",
  "NILSE",
  "NILSI",
  "NILSO",
  "NILSU",
  "NIMAH",
  "NIMAM",
  "NIMAN",
  "NIMAŠ",
  "NIMBA",
  "NIMBE",
  "NIMBI",
  "NIMBO",
  "NIMBU",
  "NIMES",
  "NIMFA",
  "NIMFE",
  "NIMFI",
  "NIMFO",
  "NIMIH",
  "NIMIK",
  "NIMIR",
  "NIMJA",
  "NIMJU",
  "NIMNO",
  "NIMOM",
  "NIMON",
  "NIMOV",
  "NIMSA",
  "NIMSE",
  "NIMSI",
  "NIMSU",
  "NINAH",
  "NINAM",
  "NINDŽ",
  "NINEM",
  "NINET",
  "NINGA",
  "NINGE",
  "NINGI",
  "NINGU",
  "NINIC",
  "NINIČ",
  "NINIH",
  "NININ",
  "NINIV",
  "NINJA",
  "NINJE",
  "NINJI",
  "NINJO",
  "NINJU",
  "NINKA",
  "NINKE",
  "NINKI",
  "NINKO",
  "NINNA",
  "NINNE",
  "NINNI",
  "NINNO",
  "NINOM",
  "NINOV",
  "NIOBA",
  "NIOBE",
  "NIOBI",
  "NIOBO",
  "NIOLA",
  "NIOLE",
  "NIOLI",
  "NIOLO",
  "NIOLU",
  "NIOSI",
  "NIOTA",
  "NIOTU",
  "NIOVA",
  "NIOVE",
  "NIOVI",
  "NIOVO",
  "NIPIČ",
  "NIREN",
  "NIRGI",
  "NIRIH",
  "NIRJA",
  "NIRJU",
  "NIROM",
  "NIROV",
  "NISCA",
  "NISCE",
  "NISCI",
  "NISCO",
  "NISCU",
  "NISEK",
  "NISEM",
  "NISHA",
  "NISHE",
  "NISHI",
  "NISHO",
  "NISHU",
  "NISKA",
  "NISKE",
  "NISKI",
  "NISKO",
  "NISMA",
  "NISME",
  "NISMO",
  "NISNO",
  "NISON",
  "NISSA",
  "NISSE",
  "NISSI",
  "NISSU",
  "NISTA",
  "NISTE",
  "NISVA",
  "NISVE",
  "NIŠAH",
  "NIŠAM",
  "NIŠAN",
  "NIŠEM",
  "NIŠEN",
  "NIŠJA",
  "NIŠJU",
  "NIŠKA",
  "NIŠKE",
  "NIŠKI",
  "NIŠKO",
  "NIŠNA",
  "NIŠNE",
  "NIŠNI",
  "NIŠNO",
  "NIŠOM",
  "NITAH",
  "NITAJ",
  "NITAM",
  "NITAR",
  "NITAV",
  "NITEN",
  "NITIH",
  "NITIJ",
  "NITIM",
  "NITIN",
  "NITJA",
  "NITJE",
  "NITJI",
  "NITJO",
  "NITJU",
  "NITKA",
  "NITKE",
  "NITKI",
  "NITKO",
  "NITKU",
  "NITMA",
  "NITMI",
  "NITNA",
  "NITNE",
  "NITNI",
  "NITNO",
  "NITTI",
  "NITZE",
  "NIURK",
  "NIVAL",
  "NIVAT",
  "NIVEA",
  "NIVEE",
  "NIVEI",
  "NIVEN",
  "NIVEO",
  "NIVES",
  "NIVIC",
  "NIVIH",
  "NIVJA",
  "NIVJU",
  "NIVOL",
  "NIVOM",
  "NIVOV",
  "NIZAJ",
  "NIZAL",
  "NIZAM",
  "NIZAŠ",
  "NIZAT",
  "NIZAU",
  "NIZAV",
  "NIZEK",
  "NIZEM",
  "NIZEV",
  "NIZIH",
  "NIZJA",
  "NIZJU",
  "NIZKA",
  "NIZKE",
  "NIZKI",
  "NIZKO",
  "NIZOM",
  "NIZON",
  "NIZOS",
  "NIZOV",
  "NIŽAJ",
  "NIŽAL",
  "NIŽAM",
  "NIŽAŠ",
  "NIŽAT",
  "NIŽAU",
  "NIŽAV",
  "NIŽEK",
  "NIŽEM",
  "NIŽEN",
  "NIŽEŠ",
  "NIŽIN",
  "NIŽJA",
  "NIŽJE",
  "NIŽJI",
  "NIŽJO",
  "NIŽKA",
  "NIŽKE",
  "NIŽKI",
  "NIŽKU",
  "NIŽNA",
  "NIŽNE",
  "NIŽNI",
  "NIŽNO",
  "NJAMI",
  "NJAZI",
  "NJEGA",
  "NJEIM",
  "NJEMU",
  "NJENA",
  "NJENE",
  "NJENI",
  "NJENO",
  "NJIJU",
  "NJIMA",
  "NJIMI",
  "NJIVA",
  "NJIVE",
  "NJIVI",
  "NJIVK",
  "NJIVO",
  "NJOKA",
  "NJOKE",
  "NJOKI",
  "NJOKU",
  "NJORK",
  "NJUHA",
  "NJUHI",
  "NJUHU",
  "NJUNA",
  "NJUNE",
  "NJUNI",
  "NJUNO",
  "NKADA",
  "NKAMA",
  "NKAME",
  "NKAMI",
  "NKAMO",
  "NKAMU",
  "NKOMA",
  "NKOME",
  "NKOMI",
  "NKOMO",
  "NKOMU",
  "NKOSI",
  "NLZOH",
  "NNAJI",
  "NNEKA",
  "NNEKE",
  "NNEKI",
  "NNEKO",
  "NOACK",
  "NOAHA",
  "NOAHE",
  "NOAHI",
  "NOAHU",
  "NOAMA",
  "NOAME",
  "NOAMI",
  "NOAMU",
  "NOBBS",
  "NOBEL",
  "NOBEN",
  "NOBLA",
  "NOBLE",
  "NOBLI",
  "NOBLU",
  "NOBOA",
  "NOBOE",
  "NOBOI",
  "NOBOJ",
  "NOBOO",
  "NOBOU",
  "NOBRE",
  "NOCER",
  "NOCEV",
  "NOCHT",
  "NOCKA",
  "NOCKE",
  "NOCKI",
  "NOCKU",
  "NOCOJ",
  "NOČEČ",
  "NOČEH",
  "NOČEM",
  "NOČEN",
  "NOČEŠ",
  "NOČEV",
  "NOČIH",
  "NOČIL",
  "NOČIM",
  "NOČIŠ",
  "NOČIT",
  "NOČIU",
  "NOČIV",
  "NOČJA",
  "NOČJO",
  "NOČJU",
  "NOČKA",
  "NOČKE",
  "NOČKI",
  "NOČKO",
  "NOČMI",
  "NOČNA",
  "NOČNE",
  "NOČNI",
  "NOČNO",
  "NOČOM",
  "NOČOV",
  "NOČUJ",
  "NODOK",
  "NODUL",
  "NODUS",
  "NOELA",
  "NOELE",
  "NOELI",
  "NOELL",
  "NOELU",
  "NOEMA",
  "NOEME",
  "NOEMI",
  "NOEMU",
  "NOEST",
  "NOETA",
  "NOETE",
  "NOETI",
  "NOETU",
  "NOGAH",
  "NOGAM",
  "NOGIC",
  "NOGIN",
  "NOHLA",
  "NOHLE",
  "NOHLI",
  "NOHLU",
  "NOHTA",
  "NOHTE",
  "NOHTI",
  "NOHTU",
  "NOJEM",
  "NOJEV",
  "NOJIH",
  "NOJIM",
  "NOJIN",
  "NOJJA",
  "NOJJU",
  "NOJOM",
  "NOJOV",
  "NOKAH",
  "NOKAJ",
  "NOKAM",
  "NOKES",
  "NOKIA",
  "NOKIE",
  "NOKIH",
  "NOKII",
  "NOKIO",
  "NOKJA",
  "NOKJU",
  "NOKOM",
  "NOKOT",
  "NOKOV",
  "NOKSA",
  "NOKSE",
  "NOKSI",
  "NOKSO",
  "NOLAH",
  "NOLAM",
  "NOLAN",
  "NOLDA",
  "NOLDE",
  "NOLDI",
  "NOLDU",
  "NOLFI",
  "NOLIH",
  "NOLIK",
  "NOLIN",
  "NOLJA",
  "NOLJU",
  "NOLLA",
  "NOLLE",
  "NOLLI",
  "NOLLU",
  "NOLOM",
  "NOLOV",
  "NOLTE",
  "NOMAD",
  "NOMAT",
  "NOMIJ",
  "NOMUR",
  "NONAH",
  "NONAK",
  "NONAM",
  "NONAR",
  "NONEM",
  "NONER",
  "NONES",
  "NONET",
  "NONEV",
  "NONIC",
  "NONIE",
  "NONIH",
  "NONIJ",
  "NONIN",
  "NONJA",
  "NONJU",
  "NONNA",
  "NONNE",
  "NONNI",
  "NONNU",
  "NONOM",
  "NONOV",
  "NOOMI",
  "NOONA",
  "NOONE",
  "NOONI",
  "NOONU",
  "NOORA",
  "NOORD",
  "NOORE",
  "NOORI",
  "NOORO",
  "NORAD",
  "NORAH",
  "NORAM",
  "NORCA",
  "NORCE",
  "NORCI",
  "NORCU",
  "NORDA",
  "NORDE",
  "NORDI",
  "NORDU",
  "NOREC",
  "NOREČ",
  "NOREL",
  "NOREM",
  "NORET",
  "NOREU",
  "NORGA",
  "NORIA",
  "NORIC",
  "NORIE",
  "NORIH",
  "NORII",
  "NORIJ",
  "NORIK",
  "NORIM",
  "NORIN",
  "NORIO",
  "NORIS",
  "NORIŠ",
  "NORIU",
  "NORKA",
  "NORKE",
  "NORKI",
  "NORKO",
  "NORMA",
  "NORME",
  "NORMI",
  "NORMO",
  "NORMU",
  "NORRA",
  "NORRE",
  "NORRI",
  "NORRO",
  "NORTH",
  "NORUM",
  "NORZA",
  "NORZE",
  "NORZI",
  "NORZO",
  "NORZU",
  "NOSAČ",
  "NOSAL",
  "NOSAN",
  "NOSAT",
  "NOSEČ",
  "NOSEH",
  "NOSEK",
  "NOSEM",
  "NOSEN",
  "NOSEV",
  "NOSIK",
  "NOSIL",
  "NOSIM",
  "NOSIŠ",
  "NOSIT",
  "NOSIU",
  "NOSIV",
  "NOSJA",
  "NOSJU",
  "NOSKA",
  "NOSKE",
  "NOSKI",
  "NOSKO",
  "NOSKU",
  "NOSNA",
  "NOSNE",
  "NOSNI",
  "NOSNO",
  "NOSOM",
  "NOSOV",
  "NOSTA",
  "NOSTE",
  "NOSTI",
  "NOSTO",
  "NOSTU",
  "NOŠAH",
  "NOŠAM",
  "NOŠAN",
  "NOŠEN",
  "NOŠKA",
  "NOŠKE",
  "NOŠKI",
  "NOŠKO",
  "NOTAH",
  "NOTAM",
  "NOTAR",
  "NOTEN",
  "NOTER",
  "NOTES",
  "NOTHA",
  "NOTHE",
  "NOTHI",
  "NOTHU",
  "NOTIC",
  "NOTIN",
  "NOTIS",
  "NOTNA",
  "NOTNE",
  "NOTNI",
  "NOTNO",
  "NOTNU",
  "NOTOL",
  "NOTRE",
  "NOTRI",
  "NOTTA",
  "NOTTE",
  "NOTTI",
  "NOTTO",
  "NOTTU",
  "NOTZA",
  "NOTZE",
  "NOTZI",
  "NOTZU",
  "NOUEL",
  "NOURI",
  "NOUTA",
  "NOUTE",
  "NOUTI",
  "NOUTU",
  "NOVAH",
  "NOVAK",
  "NOVAM",
  "NOVAS",
  "NOVCA",
  "NOVCE",
  "NOVCI",
  "NOVCU",
  "NOVEC",
  "NOVEL",
  "NOVEM",
  "NOVGA",
  "NOVIC",
  "NOVIČ",
  "NOVIH",
  "NOVIK",
  "NOVIM",
  "NOVIN",
  "NOVIT",
  "NOVJA",
  "NOVJU",
  "NOVKA",
  "NOVKE",
  "NOVKI",
  "NOVKO",
  "NOVOM",
  "NOVOT",
  "NOVOV",
  "NOZIK",
  "NOZNO",
  "NOŽAR",
  "NOŽEK",
  "NOŽEM",
  "NOŽEN",
  "NOŽEV",
  "NOŽIC",
  "NOŽIČ",
  "NOŽIH",
  "NOŽIN",
  "NOŽJA",
  "NOŽJU",
  "NOŽKA",
  "NOŽKE",
  "NOŽKI",
  "NOŽKU",
  "NOŽNA",
  "NOŽNE",
  "NOŽNI",
  "NOŽNO",
  "NOŽOM",
  "NOŽOV",
  "NPOTU",
  "NRAVA",
  "NRAVE",
  "NRAVI",
  "NRAVU",
  "NSANA",
  "NSDAP",
  "NSIOS",
  "NSOTA",
  "NUALA",
  "NUALE",
  "NUALI",
  "NUALO",
  "NUCAJ",
  "NUCAL",
  "NUCAM",
  "NUCAN",
  "NUCAŠ",
  "NUCAT",
  "NUCAU",
  "NUCAV",
  "NUCCI",
  "NUCOR",
  "NUČIČ",
  "NUDEČ",
  "NUDEN",
  "NUDIH",
  "NUDIL",
  "NUDIM",
  "NUDIŠ",
  "NUDIT",
  "NUDIU",
  "NUDIV",
  "NUDJA",
  "NUDJU",
  "NUDOM",
  "NUDOV",
  "NUELA",
  "NUELE",
  "NUELI",
  "NUELL",
  "NUELU",
  "NUERA",
  "NUERE",
  "NUERI",
  "NUERU",
  "NUFER",
  "NUGAT",
  "NUHAH",
  "NUHAJ",
  "NUHAM",
  "NUHIH",
  "NUHIU",
  "NUHJA",
  "NUHJU",
  "NUHOM",
  "NUHOV",
  "NUIJO",
  "NUJAH",
  "NUJAM",
  "NUJEN",
  "NUJNA",
  "NUJNE",
  "NUJNI",
  "NUJNO",
  "NULAH",
  "NULAM",
  "NULTA",
  "NULTE",
  "NULTI",
  "NULTO",
  "NUMAN",
  "NUMIP",
  "NUNAH",
  "NUNAM",
  "NUNAN",
  "NUNAR",
  "NUNES",
  "NUNEZ",
  "NUNIČ",
  "NUNIH",
  "NUNIN",
  "NUNJA",
  "NUNJU",
  "NUNKA",
  "NUNKE",
  "NUNKI",
  "NUNKO",
  "NUNNA",
  "NUNNE",
  "NUNNI",
  "NUNNU",
  "NUNOM",
  "NUNOV",
  "NUNZI",
  "NUORA",
  "NUORO",
  "NUORU",
  "NUOVA",
  "NUOVE",
  "NUOVI",
  "NUOVO",
  "NUOVU",
  "NURAH",
  "NURAM",
  "NURIA",
  "NURIE",
  "NURIH",
  "NURII",
  "NURIJ",
  "NURIO",
  "NURIS",
  "NURIT",
  "NURIU",
  "NURJA",
  "NURJU",
  "NURMI",
  "NUROM",
  "NUROV",
  "NURSA",
  "NURSE",
  "NURSI",
  "NURSU",
  "NUSHE",
  "NUSHI",
  "NUSIN",
  "NUŠAH",
  "NUŠAM",
  "NUŠIN",
  "NUŠKA",
  "NUŠKE",
  "NUŠKI",
  "NUŠKO",
  "NUTIH",
  "NUTJA",
  "NUTJU",
  "NUTOM",
  "NUTOV",
  "NUTTA",
  "NUTTE",
  "NUTTI",
  "NUTTU",
  "NUUTA",
  "NUUTE",
  "NUUTI",
  "NUUTU",
  "NUZIA",
  "NUZIE",
  "NUZII",
  "NUZIO",
  "NUZIU",
  "NUZZA",
  "NUZZE",
  "NUZZI",
  "NUZZO",
  "NUZZU",
  "NUŽEJ",
  "NUŽIN",
  "NZING",
  "NZOLA",
  "NZOLE",
  "NZOLI",
  "NZOLO",
  "NZOLU",
  "OAKES",
  "OAKIE",
  "OAKSA",
  "OAKSE",
  "OAKSI",
  "OAKSU",
  "OANAH",
  "OANAM",
  "OANIN",
  "OASIS",
  "OATES",
  "OATIS",
  "OAZAH",
  "OAZAM",
  "OAZEN",
  "OAZIC",
  "OAZNA",
  "OAZNE",
  "OAZNI",
  "OAZNO",
  "OBABI",
  "OBABO",
  "OBABU",
  "OBADA",
  "OBADE",
  "OBADI",
  "OBADU",
  "OBALA",
  "OBALE",
  "OBALI",
  "OBALK",
  "OBALO",
  "OBALU",
  "OBAMA",
  "OBAME",
  "OBAMI",
  "OBAMO",
  "OBAMU",
  "OBARA",
  "OBARE",
  "OBARI",
  "OBARO",
  "OBARU",
  "OBČAN",
  "OBČEM",
  "OBČEN",
  "OBČGA",
  "OBČIC",
  "OBČIH",
  "OBČIL",
  "OBČIM",
  "OBČIN",
  "OBČNA",
  "OBČNE",
  "OBČNI",
  "OBČNO",
  "OBČUJ",
  "OBDAJ",
  "OBDAL",
  "OBDAM",
  "OBDAN",
  "OBDAŠ",
  "OBDAT",
  "OBDAU",
  "OBDAV",
  "OBEDA",
  "OBEDE",
  "OBEDI",
  "OBEDU",
  "OBEID",
  "OBELI",
  "OBELU",
  "OBEMA",
  "OBEMI",
  "OBENG",
  "OBERČ",
  "OBERE",
  "OBERG",
  "OBERI",
  "OBERT",
  "OBESA",
  "OBESE",
  "OBESI",
  "OBESO",
  "OBESU",
  "OBEŠA",
  "OBEŠI",
  "OBEŠU",
  "OBETA",
  "OBETE",
  "OBETI",
  "OBETU",
  "OBHOD",
  "OBHOT",
  "OBIDA",
  "OBIDE",
  "OBIDI",
  "OBIDU",
  "OBIJA",
  "OBIJE",
  "OBIJI",
  "OBIJU",
  "OBILA",
  "OBILE",
  "OBILI",
  "OBILO",
  "OBIRA",
  "OBIRI",
  "OBIRK",
  "OBIRU",
  "OBISK",
  "OBIST",
  "OBITA",
  "OBITE",
  "OBITI",
  "OBITO",
  "OBJAV",
  "OBJED",
  "OBJEJ",
  "OBJEL",
  "OBJEM",
  "OBJEŠ",
  "OBJET",
  "OBJEU",
  "OBLAH",
  "OBLAJ",
  "OBLAK",
  "OBLAL",
  "OBLAM",
  "OBLAN",
  "OBLAŠ",
  "OBLAT",
  "OBLAU",
  "OBLAV",
  "OBLEČ",
  "OBLEK",
  "OBLEM",
  "OBLET",
  "OBLGA",
  "OBLIC",
  "OBLIČ",
  "OBLIH",
  "OBLIJ",
  "OBLIK",
  "OBLIL",
  "OBLIM",
  "OBLIN",
  "OBLIŠ",
  "OBLIT",
  "OBLIU",
  "OBLIV",
  "OBLIŽ",
  "OBLOG",
  "OBLOK",
  "OBNOV",
  "OBOAH",
  "OBOAM",
  "OBODA",
  "OBODC",
  "OBODE",
  "OBODI",
  "OBODU",
  "OBOJA",
  "OBOJE",
  "OBOJI",
  "OBOJK",
  "OBOJO",
  "OBOJU",
  "OBOKA",
  "OBOKE",
  "OBOKI",
  "OBOKU",
  "OBOLA",
  "OBOLE",
  "OBOLI",
  "OBOLU",
  "OBORA",
  "OBORE",
  "OBORI",
  "OBORN",
  "OBORO",
  "OBORU",
  "OBOST",
  "OBOTA",
  "OBOTE",
  "OBOTU",
  "OBRAB",
  "OBRAD",
  "OBRAL",
  "OBRAN",
  "OBRAS",
  "OBRAT",
  "OBRAU",
  "OBRAV",
  "OBRAZ",
  "OBRCA",
  "OBRCI",
  "OBRCU",
  "OBRČA",
  "OBRČE",
  "OBRČI",
  "OBRČU",
  "OBREČ",
  "OBRED",
  "OBRET",
  "OBREZ",
  "OBREŽ",
  "OBRHA",
  "OBRHE",
  "OBRHI",
  "OBRHU",
  "OBRIH",
  "OBRIJ",
  "OBRIL",
  "OBRIS",
  "OBRIT",
  "OBRIU",
  "OBRIV",
  "OBRJA",
  "OBRJU",
  "OBRNE",
  "OBRNI",
  "OBRNU",
  "OBROB",
  "OBROČ",
  "OBROD",
  "OBROK",
  "OBROM",
  "OBROT",
  "OBROV",
  "OBRŠE",
  "OBRTC",
  "OBRTI",
  "OBRUL",
  "OBRUS",
  "OBRVI",
  "OBSEČ",
  "OBSEG",
  "OBSEJ",
  "OBSEV",
  "OBSIJ",
  "OBSKA",
  "OBSKE",
  "OBSKI",
  "OBSKO",
  "OBSTA",
  "OBSTE",
  "OBSTI",
  "OBSTU",
  "OBSUJ",
  "OBSUL",
  "OBSUT",
  "OBŠEL",
  "OBŠEU",
  "OBŠIJ",
  "OBŠIL",
  "OBŠIT",
  "OBŠIU",
  "OBŠIV",
  "OBŠLA",
  "OBŠLE",
  "OBŠLI",
  "OBŠLO",
  "OBTEČ",
  "OBTOK",
  "OBUDI",
  "OBUDU",
  "OBUJA",
  "OBUJE",
  "OBUJI",
  "OBUJK",
  "OBUJU",
  "OBULA",
  "OBULE",
  "OBULI",
  "OBULO",
  "OBUPA",
  "OBUPE",
  "OBUPI",
  "OBUPU",
  "OBUTA",
  "OBUTE",
  "OBUTI",
  "OBUTO",
  "OBUVA",
  "OBUVI",
  "OBUVU",
  "OBVEZ",
  "OBVIJ",
  "OBVIL",
  "OBVIT",
  "OBVIU",
  "OBVIV",
  "OBVOD",
  "OBVOT",
  "OBVOZ",
  "OBZIR",
  "OBZOR",
  "OBŽDI",
  "OBŽDU",
  "OBŽEL",
  "OBŽET",
  "OBŽEU",
  "OBŽGE",
  "OBŽGI",
  "OBŽGU",
  "OBŽIG",
  "OBŽRE",
  "OBŽRI",
  "OBŽRL",
  "OBŽRT",
  "OCCAR",
  "OCEAN",
  "OCEDI",
  "OCEDU",
  "OCENA",
  "OCENE",
  "OCENG",
  "OCENI",
  "OCENO",
  "OCENU",
  "OCHAB",
  "OCHIH",
  "OCHJA",
  "OCHJU",
  "OCHOA",
  "OCHOE",
  "OCHOI",
  "OCHOJ",
  "OCHOM",
  "OCHOO",
  "OCHOU",
  "OCHOV",
  "OCHSA",
  "OCHSE",
  "OCHSI",
  "OCHSU",
  "OCONA",
  "OCONE",
  "OCONI",
  "OCONU",
  "OCTEM",
  "OCTJA",
  "OCTJU",
  "OCTOM",
  "OCVEL",
  "OCVEU",
  "OCVRE",
  "OCVRI",
  "OCVRL",
  "OCVRT",
  "OČAKA",
  "OČAKE",
  "OČAKI",
  "OČAKU",
  "OČALA",
  "OČALE",
  "OČALI",
  "OČAMA",
  "OČAMI",
  "OČANC",
  "OČARA",
  "OČARI",
  "OČARU",
  "OČCEM",
  "OČCEV",
  "OČCIH",
  "OČCJA",
  "OČCJU",
  "OČCOM",
  "OČCOV",
  "OČEDI",
  "OČEDU",
  "OČEMA",
  "OČESA",
  "OČESC",
  "OČESI",
  "OČESU",
  "OČEŠE",
  "OČEŠI",
  "OČETA",
  "OČETE",
  "OČETI",
  "OČETU",
  "OČEVA",
  "OČEVE",
  "OČEVI",
  "OČEVO",
  "OČIJA",
  "OČIJE",
  "OČIJI",
  "OČIJU",
  "OČIMA",
  "OČIME",
  "OČIMI",
  "OČIMU",
  "OČINA",
  "OČINE",
  "OČINI",
  "OČINO",
  "OČIŠČ",
  "OČITA",
  "OČITE",
  "OČITI",
  "OČITU",
  "OČJEV",
  "OČKAH",
  "OČKAM",
  "OČKEM",
  "OČKEV",
  "OČKIH",
  "OČKIN",
  "OČKJA",
  "OČKJU",
  "OČKOM",
  "OČKOV",
  "OČNIC",
  "OČOHA",
  "OČOHI",
  "OČOHU",
  "OČOMA",
  "OČOTA",
  "OČOTU",
  "OČOVA",
  "OČOVE",
  "OČOVI",
  "OČOVO",
  "OČRNI",
  "OČRNU",
  "OČRTA",
  "OČRTE",
  "OČRTI",
  "OČRTU",
  "OČUVA",
  "OČUVI",
  "OČUVU",
  "ODAJA",
  "ODAJE",
  "ODAJI",
  "ODAJO",
  "ODAKA",
  "ODAKE",
  "ODAKI",
  "ODAKU",
  "ODALA",
  "ODALE",
  "ODALI",
  "ODALO",
  "ODAMA",
  "ODAME",
  "ODAMI",
  "ODAMO",
  "ODANA",
  "ODANE",
  "ODANI",
  "ODANO",
  "ODATA",
  "ODATE",
  "ODATI",
  "ODAVA",
  "ODAVE",
  "ODAVI",
  "ODBIJ",
  "ODBIL",
  "ODBIR",
  "ODBIT",
  "ODBIU",
  "ODBIV",
  "ODBOJ",
  "ODBOR",
  "ODBRU",
  "ODCEP",
  "ODCVU",
  "ODDAJ",
  "ODDAL",
  "ODDAM",
  "ODDAN",
  "ODDAŠ",
  "ODDAT",
  "ODDAU",
  "ODDAV",
  "ODDEJ",
  "ODDIE",
  "ODDIH",
  "ODDIT",
  "ODDJA",
  "ODDJU",
  "ODDOM",
  "ODDOV",
  "ODDŠU",
  "ODEDA",
  "ODEDE",
  "ODEDI",
  "ODEDU",
  "ODEJA",
  "ODEJE",
  "ODEJI",
  "ODEJO",
  "ODELA",
  "ODELE",
  "ODELI",
  "ODELL",
  "ODELO",
  "ODELU",
  "ODENE",
  "ODENI",
  "ODEON",
  "ODERA",
  "ODERE",
  "ODERI",
  "ODERO",
  "ODERU",
  "ODESA",
  "ODESE",
  "ODESI",
  "ODESO",
  "ODETA",
  "ODETE",
  "ODETI",
  "ODETO",
  "ODETS",
  "ODEVA",
  "ODEVI",
  "ODEVU",
  "ODGNU",
  "ODGON",
  "ODHOD",
  "ODHOT",
  "ODIDE",
  "ODIDI",
  "ODIJA",
  "ODIJE",
  "ODIJI",
  "ODIJK",
  "ODIJU",
  "ODILA",
  "ODILE",
  "ODILI",
  "ODILO",
  "ODILU",
  "ODIMB",
  "ODIMI",
  "ODIMO",
  "ODIMU",
  "ODINA",
  "ODINE",
  "ODING",
  "ODINI",
  "ODINK",
  "ODINO",
  "ODION",
  "ODIRA",
  "ODIRI",
  "ODIRU",
  "ODITA",
  "ODITE",
  "ODITI",
  "ODIVA",
  "ODJAV",
  "ODJEJ",
  "ODJEK",
  "ODJEM",
  "ODJEŠ",
  "ODJUG",
  "ODKAR",
  "ODKAZ",
  "ODKLU",
  "ODKOD",
  "ODKOP",
  "ODKOS",
  "ODKRU",
  "ODKUJ",
  "ODKUP",
  "ODLAG",
  "ODLEČ",
  "ODLET",
  "ODLIH",
  "ODLIJ",
  "ODLIK",
  "ODLIL",
  "ODLIT",
  "ODLIU",
  "ODLIV",
  "ODLJA",
  "ODLJU",
  "ODLOG",
  "ODLOK",
  "ODLOM",
  "ODLOV",
  "ODLUM",
  "ODMER",
  "ODMET",
  "ODMEV",
  "ODMIK",
  "ODMOR",
  "ODMRE",
  "ODMRI",
  "ODMRL",
  "ODMRT",
  "ODNER",
  "ODNOS",
  "ODOMA",
  "ODOME",
  "ODOMI",
  "ODOMU",
  "ODOOM",
  "ODORU",
  "ODPAD",
  "ODPAT",
  "ODPEL",
  "ODPET",
  "ODPEU",
  "ODPEV",
  "ODPIJ",
  "ODPIL",
  "ODPIS",
  "ODPIT",
  "ODPIU",
  "ODPIV",
  "ODPNE",
  "ODPNI",
  "ODPOJ",
  "ODPOR",
  "ODPRE",
  "ODPRI",
  "ODPRL",
  "ODPRO",
  "ODPRT",
  "ODPRU",
  "ODRAJ",
  "ODRAL",
  "ODRAM",
  "ODRAN",
  "ODRAŠ",
  "ODRAT",
  "ODRAU",
  "ODRAV",
  "ODRAZ",
  "ODRCA",
  "ODRCE",
  "ODRCI",
  "ODRCU",
  "ODREČ",
  "ODRED",
  "ODREK",
  "ODREM",
  "ODREŠ",
  "ODRET",
  "ODREV",
  "ODREZ",
  "ODRGA",
  "ODRGE",
  "ODRGI",
  "ODRGO",
  "ODRIH",
  "ODRIN",
  "ODRIV",
  "ODRJA",
  "ODRJU",
  "ODRLA",
  "ODRLE",
  "ODRLI",
  "ODRLO",
  "ODROM",
  "ODROV",
  "ODRSA",
  "ODRSI",
  "ODRSU",
  "ODRTA",
  "ODRTE",
  "ODRTI",
  "ODRTO",
  "ODRZA",
  "ODRZI",
  "ODRZU",
  "ODSEK",
  "ODSEV",
  "ODSIJ",
  "ODSPI",
  "ODSPU",
  "ODSUJ",
  "ODSUL",
  "ODSUN",
  "ODSUT",
  "ODŠEL",
  "ODŠEU",
  "ODŠLA",
  "ODŠLE",
  "ODŠLI",
  "ODŠLO",
  "ODŠTU",
  "ODTEČ",
  "ODTIS",
  "ODTOD",
  "ODTOK",
  "ODUČI",
  "ODUČL",
  "ODUČU",
  "ODUOR",
  "ODURA",
  "ODURE",
  "ODURI",
  "ODURO",
  "ODURU",
  "ODVEČ",
  "ODVEZ",
  "ODVIJ",
  "ODVIL",
  "ODVIT",
  "ODVIU",
  "ODVIV",
  "ODVOD",
  "ODVOT",
  "ODVOZ",
  "ODVRE",
  "ODVRI",
  "ODVRL",
  "ODVRT",
  "ODVRU",
  "ODVZU",
  "ODZAD",
  "ODZIV",
  "ODZVU",
  "ODŽEL",
  "ODŽET",
  "ODŽEU",
  "ODŽGE",
  "ODŽGI",
  "ODŽGU",
  "ODŽIG",
  "ODŽRE",
  "ODŽRI",
  "ODŽRL",
  "OEBAG",
  "OEGER",
  "OEHME",
  "OEMER",
  "OENAL",
  "OENER",
  "OEPIK",
  "OERJA",
  "OERJE",
  "OERJI",
  "OERJU",
  "OERKE",
  "OESCH",
  "OESTE",
  "OETTL",
  "OETZI",
  "OEURN",
  "OEZAL",
  "OEZAT",
  "OEZER",
  "OEZGE",
  "OEZIL",
  "OFELT",
  "OFFIT",
  "OFGEM",
  "OFIAZ",
  "OFISA",
  "OFISU",
  "OFLAZ",
  "OFNAJ",
  "OFNAL",
  "OFNAM",
  "OFNAN",
  "OFNAŠ",
  "OFNAT",
  "OFNAU",
  "OFNAV",
  "OFNER",
  "OFRAJ",
  "OFRAL",
  "OFRAM",
  "OFRAN",
  "OFRAŠ",
  "OFRAT",
  "OFRAU",
  "OFRAV",
  "OFREM",
  "OFREV",
  "OFRIH",
  "OFRJA",
  "OFRJU",
  "OFROM",
  "OFROV",
  "OGABA",
  "OGABE",
  "OGABI",
  "OGABO",
  "OGANA",
  "OGANE",
  "OGANI",
  "OGANU",
  "OGATA",
  "OGATE",
  "OGATI",
  "OGATO",
  "OGATU",
  "OGDEN",
  "OGELN",
  "OGENJ",
  "OGERT",
  "OGGIH",
  "OGGJA",
  "OGGJU",
  "OGGOM",
  "OGGOV",
  "OGIBA",
  "OGIBI",
  "OGIBU",
  "OGIER",
  "OGIJA",
  "OGIJE",
  "OGIJI",
  "OGIJU",
  "OGINA",
  "OGINE",
  "OGINI",
  "OGINO",
  "OGINU",
  "OGLAR",
  "OGLAS",
  "OGLAT",
  "OGLAV",
  "OGLED",
  "OGLEJ",
  "OGLEM",
  "OGLEN",
  "OGLET",
  "OGLEV",
  "OGLIH",
  "OGLJA",
  "OGLJE",
  "OGLJU",
  "OGLOM",
  "OGLOV",
  "OGNEM",
  "OGNEŠ",
  "OGNEV",
  "OGNIJ",
  "OGNIL",
  "OGNIT",
  "OGNIU",
  "OGNIV",
  "OGNJA",
  "OGNJE",
  "OGNJI",
  "OGNJU",
  "OGOLI",
  "OGOLU",
  "OGONA",
  "OGONE",
  "OGONI",
  "OGONU",
  "OGORI",
  "OGORU",
  "OGRAD",
  "OGRAJ",
  "OGRCA",
  "OGRCE",
  "OGRCI",
  "OGRCU",
  "OGRDI",
  "OGRDU",
  "OGREJ",
  "OGREL",
  "OGRET",
  "OGREU",
  "OGRIC",
  "OGRIČ",
  "OGRIN",
  "OGRIS",
  "OGRIZ",
  "OGRNE",
  "OGRNI",
  "OGRNU",
  "OGULI",
  "OGULU",
  "OGUNS",
  "OHAIN",
  "OHAJE",
  "OHAJK",
  "OHAJO",
  "OHALA",
  "OHALE",
  "OHALI",
  "OHALO",
  "OHAMA",
  "OHAME",
  "OHAMO",
  "OHANJ",
  "OHARA",
  "OHARE",
  "OHARI",
  "OHARO",
  "OHARU",
  "OHATA",
  "OHATE",
  "OHATI",
  "OHAVA",
  "OHAVE",
  "OHAVI",
  "OHCET",
  "OHIJA",
  "OHIJU",
  "OHIMO",
  "OHIOM",
  "OHITA",
  "OHITE",
  "OHIVA",
  "OHLER",
  "OHLIN",
  "OHLIP",
  "OHMAN",
  "OHMIH",
  "OHMJA",
  "OHMJU",
  "OHMOM",
  "OHMOV",
  "OHOLA",
  "OHOLE",
  "OHOLI",
  "OHOLO",
  "OHRID",
  "OHRNA",
  "OHRNE",
  "OHRNI",
  "OHRNO",
  "OHSAM",
  "OHSER",
  "OHTAM",
  "OIDIJ",
  "OIHAN",
  "OIKON",
  "OIKOS",
  "OILJA",
  "OILJU",
  "OILOM",
  "OISEA",
  "OISEU",
  "OJAČA",
  "OJAČI",
  "OJAČL",
  "OJAČU",
  "OJAMA",
  "OJAMI",
  "OJDIP",
  "OJEDA",
  "OJEDE",
  "OJEDI",
  "OJEDO",
  "OJEDU",
  "OJEMA",
  "OJESA",
  "OJESI",
  "OJESU",
  "OJEVA",
  "OJEVE",
  "OJEVI",
  "OJEVO",
  "OJICE",
  "OJNEJ",
  "OJNIC",
  "OJNIK",
  "OJNON",
  "OJOMA",
  "OJOTA",
  "OJOTU",
  "OJOVA",
  "OJOVE",
  "OJOVI",
  "OJOVO",
  "OJRIH",
  "OJRJA",
  "OJRJU",
  "OJROM",
  "OJROV",
  "OJUBA",
  "OJUBE",
  "OJUBI",
  "OJUBU",
  "OJUŽI",
  "OJUŽU",
  "OKADI",
  "OKADU",
  "OKAJA",
  "OKAJI",
  "OKAJU",
  "OKALI",
  "OKALU",
  "OKAMA",
  "OKAMI",
  "OKANA",
  "OKANE",
  "OKANI",
  "OKANU",
  "OKAPI",
  "OKARA",
  "OKARI",
  "OKARU",
  "OKATA",
  "OKATE",
  "OKATI",
  "OKATO",
  "OKČIČ",
  "OKEKE",
  "OKELA",
  "OKELE",
  "OKELI",
  "OKELO",
  "OKELU",
  "OKENC",
  "OKEPA",
  "OKEPI",
  "OKEPU",
  "OKIČA",
  "OKIČE",
  "OKIČI",
  "OKIČU",
  "OKINA",
  "OKIND",
  "OKINE",
  "OKINI",
  "OKINO",
  "OKINU",
  "OKISA",
  "OKISI",
  "OKISU",
  "OKITA",
  "OKITE",
  "OKITI",
  "OKITO",
  "OKITU",
  "OKKAN",
  "OKLAH",
  "OKLAL",
  "OKLAM",
  "OKLAN",
  "OKLAR",
  "OKLAT",
  "OKLAU",
  "OKLAV",
  "OKLEM",
  "OKLEP",
  "OKLEV",
  "OKLIC",
  "OKLIH",
  "OKLJA",
  "OKLJU",
  "OKLOM",
  "OKLOV",
  "OKMIN",
  "OKNAH",
  "OKNAM",
  "OKNIC",
  "OKNIH",
  "OKNOM",
  "OKNOV",
  "OKOBA",
  "OKOBE",
  "OKOBI",
  "OKOBO",
  "OKOBU",
  "OKOCH",
  "OKOLI",
  "OKOMA",
  "OKONA",
  "OKONE",
  "OKONI",
  "OKONU",
  "OKOPA",
  "OKOPE",
  "OKOPI",
  "OKOPU",
  "OKORA",
  "OKORE",
  "OKORI",
  "OKORN",
  "OKORO",
  "OKORU",
  "OKOTA",
  "OKOTU",
  "OKOVA",
  "OKOVE",
  "OKOVI",
  "OKOVO",
  "OKOVU",
  "OKPAL",
  "OKRAH",
  "OKRAJ",
  "OKRAM",
  "OKRAS",
  "OKRCA",
  "OKRCI",
  "OKRCU",
  "OKREM",
  "OKREŠ",
  "OKRHA",
  "OKRHI",
  "OKRHU",
  "OKRJA",
  "OKRJU",
  "OKRNE",
  "OKRNI",
  "OKRNU",
  "OKROG",
  "OKROM",
  "OKROV",
  "OKSAN",
  "OKSID",
  "OKTAN",
  "OKTAR",
  "OKTAV",
  "OKTET",
  "OKUDA",
  "OKUDE",
  "OKUDI",
  "OKUDO",
  "OKUDU",
  "OKUJE",
  "OKUKA",
  "OKUKE",
  "OKUKI",
  "OKUKO",
  "OKUKU",
  "OKUNA",
  "OKUNE",
  "OKUNI",
  "OKUNO",
  "OKUNU",
  "OKUSA",
  "OKUSE",
  "OKUSI",
  "OKUSU",
  "OKUŠA",
  "OKUŠI",
  "OKUŠU",
  "OKUŽB",
  "OKUŽI",
  "OKUŽU",
  "OKVAR",
  "OKVIR",
  "OLAFA",
  "OLAFE",
  "OLAFI",
  "OLAFU",
  "OLAHA",
  "OLAHE",
  "OLAHI",
  "OLAHU",
  "OLAJA",
  "OLAJE",
  "OLAJI",
  "OLAJU",
  "OLAMA",
  "OLAMI",
  "OLANA",
  "OLAND",
  "OLANE",
  "OLANI",
  "OLANO",
  "OLANU",
  "OLAVA",
  "OLAVE",
  "OLAVI",
  "OLAVU",
  "OLBEN",
  "OLBIA",
  "OLBIE",
  "OLBII",
  "OLBIO",
  "OLBNA",
  "OLBNE",
  "OLBNI",
  "OLBNU",
  "OLDSA",
  "OLDSE",
  "OLDSI",
  "OLDSU",
  "OLEAK",
  "OLEAT",
  "OLEGA",
  "OLEGE",
  "OLEGI",
  "OLEGU",
  "OLEIN",
  "OLEJA",
  "OLEJE",
  "OLEJI",
  "OLEJU",
  "OLENA",
  "OLENE",
  "OLENI",
  "OLENK",
  "OLENO",
  "OLENU",
  "OLEŠA",
  "OLEŠČ",
  "OLEŠE",
  "OLEŠI",
  "OLEŠO",
  "OLEŠU",
  "OLEVI",
  "OLEVU",
  "OLFEN",
  "OLGAH",
  "OLGAM",
  "OLGIC",
  "OLGIN",
  "OLGUN",
  "OLIAS",
  "OLIČA",
  "OLIČE",
  "OLIČI",
  "OLIČU",
  "OLIEM",
  "OLIEN",
  "OLIEV",
  "OLIIH",
  "OLIJA",
  "OLIJU",
  "OLIKA",
  "OLIKE",
  "OLIKI",
  "OLIKO",
  "OLIKU",
  "OLIMA",
  "OLIME",
  "OLIMI",
  "OLIMP",
  "OLIMU",
  "OLINA",
  "OLINE",
  "OLINI",
  "OLINK",
  "OLINO",
  "OLINS",
  "OLINU",
  "OLIOM",
  "OLIOV",
  "OLIPA",
  "OLIPE",
  "OLIPI",
  "OLIPU",
  "OLISA",
  "OLISE",
  "OLISI",
  "OLISU",
  "OLIVA",
  "OLIVE",
  "OLIVI",
  "OLIVO",
  "OLIVU",
  "OLJAČ",
  "OLJAH",
  "OLJAM",
  "OLJAR",
  "OLJEČ",
  "OLJEM",
  "OLJEN",
  "OLJIH",
  "OLJIL",
  "OLJIM",
  "OLJIN",
  "OLJIŠ",
  "OLJIT",
  "OLJIU",
  "OLJIV",
  "OLJKA",
  "OLJKE",
  "OLJKI",
  "OLJKO",
  "OLJNA",
  "OLJNE",
  "OLJNI",
  "OLJNO",
  "OLJOM",
  "OLJOV",
  "OLKIN",
  "OLLIE",
  "OLLIG",
  "OLLIL",
  "OLLOM",
  "OLMAN",
  "OLMEK",
  "OLMIH",
  "OLMIN",
  "OLMJA",
  "OLMJU",
  "OLMOM",
  "OLMOS",
  "OLMOV",
  "OLMSA",
  "OLMSE",
  "OLMSI",
  "OLMSU",
  "OLOFA",
  "OLOFE",
  "OLOFI",
  "OLOFU",
  "OLOMA",
  "OLOOP",
  "OLORI",
  "OLOTA",
  "OLOTU",
  "OLOVA",
  "OLOVE",
  "OLOVI",
  "OLOVO",
  "OLPIN",
  "OLSBU",
  "OLSEN",
  "OLSES",
  "OLSNA",
  "OLSNE",
  "OLSNI",
  "OLSNU",
  "OLSON",
  "OLTAH",
  "OLTAM",
  "OLTAR",
  "OLTIH",
  "OLTIN",
  "OLTJA",
  "OLTJU",
  "OLTOM",
  "OLTOV",
  "OLUPA",
  "OLUPE",
  "OLUPI",
  "OLUPU",
  "OLUŽI",
  "OLUŽU",
  "OLVER",
  "OLZON",
  "OMAGA",
  "OMAGI",
  "OMAGU",
  "OMAHA",
  "OMAHE",
  "OMAHI",
  "OMAHO",
  "OMAIR",
  "OMAJA",
  "OMAJE",
  "OMAJI",
  "OMAJU",
  "OMAKA",
  "OMAKE",
  "OMAKI",
  "OMAKO",
  "OMAMA",
  "OMAME",
  "OMAMI",
  "OMAMO",
  "OMAMU",
  "OMANA",
  "OMANE",
  "OMANI",
  "OMANK",
  "OMANU",
  "OMARA",
  "OMARE",
  "OMARI",
  "OMARO",
  "OMBRA",
  "OMBRE",
  "OMBRI",
  "OMBRO",
  "OMEČE",
  "OMEČI",
  "OMEČL",
  "OMEČU",
  "OMEDE",
  "OMEDI",
  "OMEDU",
  "OMEGA",
  "OMEGE",
  "OMEGI",
  "OMEGO",
  "OMEJA",
  "OMEJC",
  "OMEJE",
  "OMEJI",
  "OMEJU",
  "OMELA",
  "OMELC",
  "OMELE",
  "OMELI",
  "OMELO",
  "OMELU",
  "OMEMB",
  "OMENA",
  "OMENE",
  "OMENI",
  "OMENS",
  "OMENU",
  "OMERA",
  "OMERE",
  "OMERI",
  "OMERO",
  "OMERS",
  "OMERU",
  "OMERZ",
  "OMEST",
  "OMETA",
  "OMETE",
  "OMETI",
  "OMETO",
  "OMETU",
  "OMFAL",
  "OMIDA",
  "OMIDE",
  "OMIDI",
  "OMIDU",
  "OMIKA",
  "OMIKE",
  "OMIKI",
  "OMIKO",
  "OMIKU",
  "OMILI",
  "OMILU",
  "OMIŠA",
  "OMIŠU",
  "OMLET",
  "OMNIA",
  "OMNIE",
  "OMNII",
  "OMNIO",
  "OMOČI",
  "OMOČL",
  "OMOČU",
  "OMOMA",
  "OMONT",
  "OMORI",
  "OMOTA",
  "OMOTE",
  "OMOTI",
  "OMOTO",
  "OMOTU",
  "OMOŽI",
  "OMOŽU",
  "OMSKA",
  "OMSKE",
  "OMSKI",
  "OMSKO",
  "OMSKU",
  "OMULI",
  "OMULU",
  "OMURA",
  "OMURE",
  "OMURI",
  "OMURO",
  "OMURU",
  "ONALE",
  "ONANA",
  "ONANE",
  "ONANI",
  "ONANU",
  "ONDAN",
  "ONDER",
  "ONDIN",
  "ONDOD",
  "ONDRA",
  "ONDRE",
  "ONDRI",
  "ONDRO",
  "ONDRU",
  "ONEGA",
  "ONEGE",
  "ONEGI",
  "ONEGO",
  "ONEKA",
  "ONEKU",
  "ONELE",
  "ONEMI",
  "ONEMU",
  "ONGGA",
  "ONGGE",
  "ONGGI",
  "ONGGU",
  "ONGIH",
  "ONGJA",
  "ONGJU",
  "ONGOM",
  "ONGOV",
  "ONIČA",
  "ONIČE",
  "ONIČI",
  "ONIČU",
  "ONIKA",
  "ONIKE",
  "ONIKI",
  "ONIKO",
  "ONIKS",
  "ONIKU",
  "ONILE",
  "ONIMA",
  "ONIMI",
  "ONJAH",
  "ONJAM",
  "ONJIN",
  "ONNES",
  "ONNIH",
  "ONNJA",
  "ONNJU",
  "ONNOM",
  "ONNOV",
  "ONOLE",
  "ONOMA",
  "ONONA",
  "ONONE",
  "ONONI",
  "ONONO",
  "ONONU",
  "ONOTA",
  "ONOTU",
  "ONOVA",
  "ONOVE",
  "ONOVI",
  "ONOVO",
  "ONSIN",
  "ONUČA",
  "ONUČE",
  "ONUČI",
  "ONUČO",
  "ONUKA",
  "ONUKE",
  "ONUKI",
  "ONUKU",
  "OOCIT",
  "OOLIT",
  "OOMEN",
  "OOMNA",
  "OOMNE",
  "OOMNI",
  "OOMNU",
  "OOMSA",
  "OOMSE",
  "OOMSI",
  "OOMSU",
  "OONAH",
  "OONAM",
  "OONIN",
  "OORTA",
  "OORTE",
  "OORTI",
  "OORTU",
  "OPAJA",
  "OPAJI",
  "OPAJU",
  "OPAKA",
  "OPAKE",
  "OPAKI",
  "OPAKO",
  "OPAKU",
  "OPALA",
  "OPALE",
  "OPALI",
  "OPALK",
  "OPALU",
  "OPANK",
  "OPARA",
  "OPARE",
  "OPARI",
  "OPARO",
  "OPART",
  "OPARU",
  "OPAST",
  "OPASU",
  "OPAŠE",
  "OPAŠI",
  "OPATA",
  "OPATE",
  "OPATI",
  "OPATU",
  "OPAZI",
  "OPAZK",
  "OPAZU",
  "OPAŽA",
  "OPAŽE",
  "OPAŽI",
  "OPAŽU",
  "OPCIJ",
  "OPČIN",
  "OPECA",
  "OPECI",
  "OPECU",
  "OPEČE",
  "OPEČI",
  "OPEČT",
  "OPEKA",
  "OPEKE",
  "OPEKI",
  "OPEKO",
  "OPEKU",
  "OPELA",
  "OPELE",
  "OPELI",
  "OPELK",
  "OPELO",
  "OPELT",
  "OPEND",
  "OPENI",
  "OPENK",
  "OPENU",
  "OPERA",
  "OPERE",
  "OPERI",
  "OPERO",
  "OPERU",
  "OPEŠA",
  "OPEŠI",
  "OPEŠU",
  "OPETA",
  "OPETE",
  "OPETI",
  "OPETO",
  "OPEVA",
  "OPEVI",
  "OPEVU",
  "OPHAJ",
  "OPHAL",
  "OPHAM",
  "OPHAN",
  "OPHAŠ",
  "OPHAT",
  "OPHAU",
  "OPHAV",
  "OPHIR",
  "OPIAT",
  "OPICA",
  "OPICE",
  "OPICI",
  "OPICO",
  "OPIČK",
  "OPIHA",
  "OPIHE",
  "OPIHI",
  "OPIHU",
  "OPIJA",
  "OPIJE",
  "OPIJI",
  "OPIJU",
  "OPIKA",
  "OPIKI",
  "OPIKU",
  "OPILA",
  "OPILE",
  "OPILI",
  "OPILO",
  "OPILU",
  "OPIRA",
  "OPIRI",
  "OPIRU",
  "OPISA",
  "OPISE",
  "OPISI",
  "OPISU",
  "OPIŠE",
  "OPIŠI",
  "OPITA",
  "OPITE",
  "OPITI",
  "OPITO",
  "OPITZ",
  "OPLAT",
  "OPLEL",
  "OPLEM",
  "OPLEN",
  "OPLET",
  "OPLEU",
  "OPLEV",
  "OPLIH",
  "OPLJA",
  "OPLJU",
  "OPLOM",
  "OPLOV",
  "OPNAH",
  "OPNAM",
  "OPNEM",
  "OPNEŠ",
  "OPOJA",
  "OPOJE",
  "OPOJI",
  "OPOJU",
  "OPOKA",
  "OPOKE",
  "OPOKI",
  "OPOKO",
  "OPOKU",
  "OPOLA",
  "OPOLE",
  "OPOLI",
  "OPOLO",
  "OPOLU",
  "OPOMB",
  "OPONC",
  "OPONK",
  "OPORA",
  "OPORE",
  "OPORI",
  "OPORO",
  "OPPAL",
  "OPPNP",
  "OPRAH",
  "OPRAL",
  "OPRAN",
  "OPRAT",
  "OPRAU",
  "OPRAV",
  "OPREK",
  "OPREM",
  "OPREŠ",
  "OPRET",
  "OPRHA",
  "OPRHE",
  "OPRHI",
  "OPRHU",
  "OPRLA",
  "OPRLE",
  "OPRLI",
  "OPRLO",
  "OPROD",
  "OPROG",
  "OPRON",
  "OPRTA",
  "OPRTE",
  "OPRTI",
  "OPRTO",
  "OPRTU",
  "OPSIN",
  "OPSUJ",
  "OPTIK",
  "OPTIS",
  "OPUSA",
  "OPUSE",
  "OPUSI",
  "OPUSU",
  "ORAČA",
  "ORAČE",
  "ORAČI",
  "ORAČU",
  "ORADA",
  "ORADE",
  "ORADI",
  "ORADO",
  "ORAIN",
  "ORALA",
  "ORALE",
  "ORALI",
  "ORALO",
  "ORALU",
  "ORAMA",
  "ORAME",
  "ORAMI",
  "ORAMU",
  "ORANA",
  "ORANE",
  "ORANI",
  "ORANJ",
  "ORANO",
  "ORANT",
  "ORANU",
  "ORANŽ",
  "ORATI",
  "ORAŽE",
  "ORBAL",
  "ORBAN",
  "ORBIS",
  "ORBIT",
  "ORBOM",
  "ORBON",
  "ORCAN",
  "ORCEL",
  "ORDEN",
  "ORDNA",
  "ORDNE",
  "ORDNI",
  "ORDNU",
  "ORDON",
  "OREBA",
  "OREBE",
  "OREBI",
  "OREBU",
  "ORECK",
  "OREČA",
  "OREČE",
  "OREČI",
  "OREČO",
  "OREHA",
  "OREHE",
  "OREHI",
  "OREHU",
  "ORELJ",
  "ORENA",
  "ORENE",
  "ORENI",
  "ORENS",
  "ORENT",
  "ORENU",
  "ORESA",
  "ORESE",
  "ORESI",
  "OREST",
  "ORESU",
  "ORETT",
  "ORFEJ",
  "ORFFA",
  "ORFFE",
  "ORFFI",
  "ORFFU",
  "ORGAN",
  "ORGEL",
  "ORGES",
  "ORGIJ",
  "ORGLA",
  "ORGLE",
  "ORGLI",
  "ORGLU",
  "ORHAN",
  "ORIAN",
  "ORIBA",
  "ORIBI",
  "ORIBU",
  "ORIEM",
  "ORIEV",
  "ORIGI",
  "ORIIH",
  "ORIJA",
  "ORIJE",
  "ORIJI",
  "ORIJO",
  "ORIJU",
  "ORIKS",
  "ORILA",
  "ORILE",
  "ORILI",
  "ORILO",
  "ORIMA",
  "ORIME",
  "ORIMO",
  "ORIOL",
  "ORIOM",
  "ORION",
  "ORIOV",
  "ORISA",
  "ORISE",
  "ORISI",
  "ORISU",
  "ORIŠE",
  "ORIŠI",
  "ORITA",
  "ORITE",
  "ORITI",
  "ORIVA",
  "ORIVE",
  "ORIVI",
  "ORJAK",
  "ORJAN",
  "ORJEM",
  "ORJEŠ",
  "ORJET",
  "ORJOL",
  "ORKAH",
  "ORKAM",
  "ORKAN",
  "ORKEM",
  "ORKEV",
  "ORKIČ",
  "ORKIH",
  "ORKIN",
  "ORKIS",
  "ORKJA",
  "ORKJU",
  "ORKOM",
  "ORKOV",
  "ORLAČ",
  "ORLAH",
  "ORLAM",
  "ORLEK",
  "ORLEM",
  "ORLEN",
  "ORLEV",
  "ORLIC",
  "ORLIČ",
  "ORLIH",
  "ORLIK",
  "ORLIN",
  "ORLJA",
  "ORLJE",
  "ORLJI",
  "ORLJO",
  "ORLJU",
  "ORLOM",
  "ORLOV",
  "ORMAN",
  "ORMIH",
  "ORMJA",
  "ORMJU",
  "ORMOM",
  "ORMOV",
  "ORMOŽ",
  "ORNAT",
  "ORNEL",
  "ORNEM",
  "ORNER",
  "ORNGA",
  "ORNIG",
  "ORNIH",
  "ORNIK",
  "ORNIM",
  "OROČA",
  "OROČI",
  "OROČL",
  "OROČU",
  "OROJA",
  "OROJU",
  "OROMA",
  "OROOM",
  "OROPA",
  "OROPI",
  "OROPU",
  "OROSI",
  "OROSU",
  "OROSZ",
  "OROŠA",
  "OROŠE",
  "OROŠI",
  "OROŠU",
  "OROTA",
  "OROTU",
  "OROVA",
  "OROVE",
  "OROVI",
  "OROVO",
  "OROZA",
  "OROZE",
  "OROZI",
  "OROZU",
  "OROŽA",
  "OROŽE",
  "OROŽI",
  "OROŽU",
  "ORPEA",
  "ORPEE",
  "ORPEI",
  "ORPEO",
  "ORPIH",
  "ORPIN",
  "ORPJA",
  "ORPJU",
  "ORPOM",
  "ORPOV",
  "ORREN",
  "ORRIH",
  "ORRIN",
  "ORRJA",
  "ORRJU",
  "ORRNA",
  "ORRNE",
  "ORRNI",
  "ORRNU",
  "ORROM",
  "ORROV",
  "ORSAG",
  "ORSAT",
  "ORSIN",
  "ORSKA",
  "ORSKU",
  "ORSON",
  "ORTAH",
  "ORTAM",
  "ORTAN",
  "ORTAR",
  "ORTEG",
  "ORTER",
  "ORTEZ",
  "ORTHA",
  "ORTHE",
  "ORTHI",
  "ORTHS",
  "ORTHU",
  "ORTIH",
  "ORTIZ",
  "ORTJA",
  "ORTJU",
  "ORTOM",
  "ORTON",
  "ORTOV",
  "ORTOZ",
  "ORZAN",
  "OSAJI",
  "OSAJU",
  "OSAKA",
  "OSAKE",
  "OSAKI",
  "OSAKO",
  "OSAKU",
  "OSAMA",
  "OSAME",
  "OSAMI",
  "OSAMO",
  "OSAMU",
  "OSANG",
  "OSAPK",
  "OSAST",
  "OSATA",
  "OSATE",
  "OSATI",
  "OSATO",
  "OSATU",
  "OSCAL",
  "OSCAN",
  "OSCAR",
  "OSCAT",
  "OSCAU",
  "OSCAV",
  "OSDIN",
  "OSEBA",
  "OSEBE",
  "OSEBI",
  "OSEBO",
  "OSEFA",
  "OSEFE",
  "OSEFI",
  "OSEFO",
  "OSEFU",
  "OSEIN",
  "OSEKA",
  "OSEKE",
  "OSEKI",
  "OSEKO",
  "OSEKU",
  "OSELI",
  "OSELJ",
  "OSELL",
  "OSEMA",
  "OSENK",
  "OSETA",
  "OSETE",
  "OSETI",
  "OSETU",
  "OSHEM",
  "OSHEV",
  "OSHIH",
  "OSHJA",
  "OSHJU",
  "OSHOM",
  "OSHOV",
  "OSICA",
  "OSICE",
  "OSICI",
  "OSICO",
  "OSIJO",
  "OSILI",
  "OSIMA",
  "OSIME",
  "OSIMI",
  "OSIMO",
  "OSIMU",
  "OSINA",
  "OSINE",
  "OSINI",
  "OSINO",
  "OSINU",
  "OSIPA",
  "OSIPE",
  "OSIPI",
  "OSIPU",
  "OSIRA",
  "OSIRI",
  "OSIRU",
  "OSIŠČ",
  "OSIVI",
  "OSIVU",
  "OSJEM",
  "OSJGA",
  "OSJIH",
  "OSJIM",
  "OSKAM",
  "OSKAR",
  "OSKIL",
  "OSKRT",
  "OSLAD",
  "OSLAH",
  "OSLAM",
  "OSLAR",
  "OSLAT",
  "OSLEM",
  "OSLER",
  "OSLEV",
  "OSLEZ",
  "OSLIC",
  "OSLIČ",
  "OSLIH",
  "OSLIN",
  "OSLJA",
  "OSLJU",
  "OSLOM",
  "OSLOV",
  "OSMAK",
  "OSMAN",
  "OSMEM",
  "OSMER",
  "OSMIC",
  "OSMIČ",
  "OSMIH",
  "OSMIJ",
  "OSMIM",
  "OSMIN",
  "OSNEM",
  "OSNGA",
  "OSNIH",
  "OSNIK",
  "OSNIM",
  "OSNOV",
  "OSNUJ",
  "OSOČI",
  "OSOČL",
  "OSOČU",
  "OSOJA",
  "OSOJE",
  "OSOJI",
  "OSOJO",
  "OSOLE",
  "OSOLI",
  "OSOLU",
  "OSPEN",
  "OSPJA",
  "OSPJU",
  "OSPOM",
  "OSRAL",
  "OSRAM",
  "OSRAN",
  "OSRAT",
  "OSRAU",
  "OSRAV",
  "OSRČI",
  "OSRČL",
  "OSRČU",
  "OSSIG",
  "OSSIH",
  "OSSIP",
  "OSSJA",
  "OSSJU",
  "OSSOL",
  "OSSOM",
  "OSSOV",
  "OSTAL",
  "OSTAN",
  "OSTAP",
  "OSTAT",
  "OSTAU",
  "OSTAV",
  "OSTEH",
  "OSTEM",
  "OSTEN",
  "OSTER",
  "OSTEV",
  "OSTIČ",
  "OSTIH",
  "OSTIJ",
  "OSTIN",
  "OSTJA",
  "OSTJO",
  "OSTJU",
  "OSTMI",
  "OSTNA",
  "OSTNE",
  "OSTNI",
  "OSTNU",
  "OSTOJ",
  "OSTOM",
  "OSTOV",
  "OSTRA",
  "OSTRC",
  "OSTRE",
  "OSTRI",
  "OSTRO",
  "OSTRŠ",
  "OSTRU",
  "OSTRV",
  "OSTUD",
  "OSTVA",
  "OSTVE",
  "OSTVI",
  "OSTVO",
  "OSUJE",
  "OSUJI",
  "OSULA",
  "OSULE",
  "OSULI",
  "OSULO",
  "OSUMI",
  "OSUMU",
  "OSUPI",
  "OSUPU",
  "OSUŠI",
  "OSUŠU",
  "OSUTA",
  "OSUTE",
  "OSUTI",
  "OSUTO",
  "OSUVA",
  "OSUVI",
  "OSUVU",
  "OSVET",
  "OŠAPA",
  "OŠAPE",
  "OŠAPI",
  "OŠAPU",
  "OŠČEP",
  "OŠČIJ",
  "OŠČIM",
  "OŠČIŠ",
  "OŠELJ",
  "OŠEPA",
  "OŠEPE",
  "OŠEPI",
  "OŠEPU",
  "OŠIBA",
  "OŠIBI",
  "OŠIBO",
  "OŠIBU",
  "OŠILI",
  "OŠILU",
  "OŠINA",
  "OŠINE",
  "OŠINI",
  "OŠINO",
  "OŠINU",
  "OŠKEM",
  "OŠKGA",
  "OŠKIH",
  "OŠKIM",
  "OŠLAJ",
  "OŠLAK",
  "OŠLJA",
  "OŠLJU",
  "OŠPIC",
  "OŠTEJ",
  "OŠTEL",
  "OŠTET",
  "OŠTEU",
  "OŠTIR",
  "OŠTRA",
  "OŠTRC",
  "OŠTRO",
  "OŠTRU",
  "OTAJA",
  "OTAJI",
  "OTAJU",
  "OTALI",
  "OTALU",
  "OTAMA",
  "OTAMI",
  "OTARE",
  "OTAVA",
  "OTAVE",
  "OTAVI",
  "OTAVK",
  "OTAVO",
  "OTCEM",
  "OTCEV",
  "OTCIH",
  "OTCJA",
  "OTCJU",
  "OTCOM",
  "OTCOV",
  "OTČEV",
  "OTČIN",
  "OTČOV",
  "OTECI",
  "OTEČE",
  "OTEČI",
  "OTEČT",
  "OTEGI",
  "OTEIL",
  "OTEKA",
  "OTEKI",
  "OTEKU",
  "OTELA",
  "OTELE",
  "OTELI",
  "OTELO",
  "OTELU",
  "OTEPA",
  "OTEPE",
  "OTEPI",
  "OTEPU",
  "OTERA",
  "OTERE",
  "OTERI",
  "OTERM",
  "OTERO",
  "OTERU",
  "OTESU",
  "OTEŠE",
  "OTEŠI",
  "OTETA",
  "OTETE",
  "OTETI",
  "OTETO",
  "OTEŽI",
  "OTEŽU",
  "OTHON",
  "OTIČA",
  "OTIČE",
  "OTIČI",
  "OTIČU",
  "OTINA",
  "OTINE",
  "OTINI",
  "OTINO",
  "OTIPA",
  "OTIPE",
  "OTIPI",
  "OTIPU",
  "OTIRA",
  "OTIRI",
  "OTIRU",
  "OTISA",
  "OTISE",
  "OTISI",
  "OTISK",
  "OTISU",
  "OTKAH",
  "OTKAM",
  "OTMAN",
  "OTMAR",
  "OTMEM",
  "OTMEŠ",
  "OTOČE",
  "OTOKA",
  "OTOKE",
  "OTOKI",
  "OTOKU",
  "OTOLČ",
  "OTOMA",
  "OTONA",
  "OTONE",
  "OTONI",
  "OTONU",
  "OTOPI",
  "OTOPU",
  "OTOŠČ",
  "OTOTA",
  "OTOTU",
  "OTOVA",
  "OTOVE",
  "OTOVI",
  "OTOVO",
  "OTPRE",
  "OTPRI",
  "OTPRL",
  "OTPRT",
  "OTRDI",
  "OTRDU",
  "OTREM",
  "OTREŠ",
  "OTRET",
  "OTRIN",
  "OTRKA",
  "OTRKI",
  "OTRKU",
  "OTRLA",
  "OTRLE",
  "OTRLI",
  "OTRLO",
  "OTRNE",
  "OTRNI",
  "OTRNU",
  "OTROK",
  "OTROV",
  "OTRTA",
  "OTRTE",
  "OTRTI",
  "OTRTO",
  "OTTAH",
  "OTTAM",
  "OTTER",
  "OTTIH",
  "OTTIN",
  "OTTJA",
  "OTTJU",
  "OTTKE",
  "OTTOM",
  "OTTON",
  "OTTOV",
  "OTUČA",
  "OTUČE",
  "OTUČI",
  "OTUČO",
  "OTUJI",
  "OTUJU",
  "OTVAH",
  "OTVAM",
  "OTVIN",
  "OUAKI",
  "OUBIN",
  "OUBRE",
  "OUDEA",
  "OUDIH",
  "OUDIN",
  "OUDJA",
  "OUDJU",
  "OUDOM",
  "OUDOV",
  "OUGHA",
  "OUGHE",
  "OUGHI",
  "OUGHU",
  "OUIJA",
  "OUIJE",
  "OUIJI",
  "OUIJO",
  "OUIJU",
  "OUKIL",
  "OUMIH",
  "OUMJA",
  "OUMJU",
  "OUMOM",
  "OUMOV",
  "OURIH",
  "OURJA",
  "OURJU",
  "OUROM",
  "OUROV",
  "OUTIH",
  "OUTJA",
  "OUTJU",
  "OUTOM",
  "OUTOV",
  "OUZEM",
  "OUZJA",
  "OUZJU",
  "OUZOM",
  "OVADB",
  "OVADI",
  "OVADU",
  "OVAJA",
  "OVAJI",
  "OVAJU",
  "OVALA",
  "OVALE",
  "OVALI",
  "OVALU",
  "OVCAH",
  "OVCAM",
  "OVCIN",
  "OVČAK",
  "OVČAN",
  "OVČAR",
  "OVČIC",
  "OVČIN",
  "OVČJA",
  "OVČJE",
  "OVČJI",
  "OVČJO",
  "OVČKA",
  "OVČKE",
  "OVČKI",
  "OVČKO",
  "OVEDA",
  "OVEDI",
  "OVEDO",
  "OVEDU",
  "OVEJO",
  "OVEMA",
  "OVEME",
  "OVEMO",
  "OVENE",
  "OVENI",
  "OVENS",
  "OVENU",
  "OVERI",
  "OVERU",
  "OVESI",
  "OVEST",
  "OVESU",
  "OVEŠA",
  "OVEŠI",
  "OVEŠU",
  "OVEVA",
  "OVEVE",
  "OVEVI",
  "OVIDI",
  "OVIJA",
  "OVIJE",
  "OVIJI",
  "OVIJU",
  "OVILA",
  "OVILE",
  "OVILI",
  "OVILO",
  "OVINA",
  "OVINE",
  "OVINI",
  "OVINU",
  "OVIRA",
  "OVIRE",
  "OVIRI",
  "OVIRO",
  "OVIRU",
  "OVIST",
  "OVITA",
  "OVITE",
  "OVITI",
  "OVITO",
  "OVNEM",
  "OVNEV",
  "OVNIC",
  "OVNIČ",
  "OVNIH",
  "OVNIK",
  "OVNIN",
  "OVNJA",
  "OVNJU",
  "OVNOM",
  "OVNOV",
  "OVOHA",
  "OVOHI",
  "OVOHU",
  "OVOJA",
  "OVOJE",
  "OVOJI",
  "OVOJK",
  "OVOJU",
  "OVREČ",
  "OVRGU",
  "OVRZI",
  "OVRŽB",
  "OVRŽE",
  "OVSCA",
  "OVSCE",
  "OVSCI",
  "OVSCU",
  "OVSEC",
  "OVSEM",
  "OVSEN",
  "OVSIK",
  "OVSIŠ",
  "OVSJA",
  "OVSJU",
  "OVSOM",
  "OVTAR",
  "OVUMA",
  "OVUMU",
  "OZALJ",
  "OZALL",
  "OZANA",
  "OZANE",
  "OZANI",
  "OZANO",
  "OZANU",
  "OZARA",
  "OZARE",
  "OZARI",
  "OZARK",
  "OZARO",
  "OZARU",
  "OZBIČ",
  "OZDEM",
  "OZDEV",
  "OZDIH",
  "OZDJA",
  "OZDJU",
  "OZDOB",
  "OZDOM",
  "OZDOV",
  "OZEBA",
  "OZEBE",
  "OZEBI",
  "OZEBO",
  "OZEBU",
  "OZERI",
  "OZIMA",
  "OZIME",
  "OZIMI",
  "OZIMU",
  "OZIRA",
  "OZIRE",
  "OZIRI",
  "OZIRU",
  "OZKEM",
  "OZKGA",
  "OZKIH",
  "OZKIM",
  "OZMEC",
  "OZNAK",
  "OZOBA",
  "OZOBC",
  "OZOBI",
  "OZOBO",
  "OZOBU",
  "OZOLS",
  "OZOMA",
  "OZONA",
  "OZONE",
  "OZONI",
  "OZONU",
  "OZOTA",
  "OZOTU",
  "OZOVA",
  "OZOVE",
  "OZOVI",
  "OZOVO",
  "OZREM",
  "OZREN",
  "OZREŠ",
  "OZRET",
  "OZRLA",
  "OZRLE",
  "OZRLI",
  "OZRLO",
  "OZRNI",
  "OZRNU",
  "OZZIE",
  "OZZIN",
  "OŽAJE",
  "OŽAJO",
  "OŽALA",
  "OŽALE",
  "OŽALI",
  "OŽALO",
  "OŽAMA",
  "OŽAME",
  "OŽAMO",
  "OŽANA",
  "OŽANE",
  "OŽANI",
  "OŽANJ",
  "OŽANO",
  "OŽARI",
  "OŽARU",
  "OŽATA",
  "OŽATE",
  "OŽATI",
  "OŽAVA",
  "OŽAVE",
  "OŽAVI",
  "OŽBEJ",
  "OŽBOT",
  "OŽEČA",
  "OŽEČE",
  "OŽEČI",
  "OŽEČO",
  "OŽEKA",
  "OŽEKE",
  "OŽEKI",
  "OŽEKU",
  "OŽELA",
  "OŽELE",
  "OŽELI",
  "OŽELO",
  "OŽEMA",
  "OŽEMI",
  "OŽEMU",
  "OŽENA",
  "OŽENE",
  "OŽENI",
  "OŽENJ",
  "OŽENO",
  "OŽENU",
  "OŽETA",
  "OŽETE",
  "OŽETI",
  "OŽETO",
  "OŽGAL",
  "OŽGAN",
  "OŽGAT",
  "OŽGAU",
  "OŽGAV",
  "OŽGEM",
  "OŽGEŠ",
  "OŽIČI",
  "OŽIČL",
  "OŽIČU",
  "OŽIGA",
  "OŽIGE",
  "OŽIGI",
  "OŽIGU",
  "OŽIJO",
  "OŽILA",
  "OŽILE",
  "OŽILI",
  "OŽILO",
  "OŽILU",
  "OŽIMA",
  "OŽIME",
  "OŽIMO",
  "OŽINA",
  "OŽINE",
  "OŽINI",
  "OŽINO",
  "OŽIRA",
  "OŽIRI",
  "OŽIRU",
  "OŽITA",
  "OŽITE",
  "OŽITI",
  "OŽIVA",
  "OŽIVE",
  "OŽIVI",
  "OŽIVU",
  "OŽJEM",
  "OŽJGA",
  "OŽJIH",
  "OŽJIM",
  "OŽMEM",
  "OŽMEŠ",
  "OŽREM",
  "OŽREŠ",
  "OŽRET",
  "OŽRLA",
  "OŽRLE",
  "OŽRLI",
  "OŽRLO",
  "OŽULI",
  "OŽULU",
  "OŽURA",
  "OŽURE",
  "OŽURI",
  "OŽURO",
  "OŽURU",
  "PAAGA",
  "PAAGE",
  "PAALA",
  "PAALE",
  "PAALI",
  "PAALU",
  "PAARA",
  "PAARE",
  "PAARI",
  "PAARU",
  "PAAVA",
  "PAAVE",
  "PAAVI",
  "PAAVO",
  "PAAVU",
  "PABAI",
  "PABLA",
  "PABLE",
  "PABLI",
  "PABLO",
  "PABLU",
  "PABST",
  "PACAH",
  "PACAJ",
  "PACAL",
  "PACAM",
  "PACAŠ",
  "PACAT",
  "PACAU",
  "PACAV",
  "PACEA",
  "PACEE",
  "PACEI",
  "PACEK",
  "PACEU",
  "PACHL",
  "PACHT",
  "PACIH",
  "PACJA",
  "PACJU",
  "PACKA",
  "PACKE",
  "PACKI",
  "PACKO",
  "PACKU",
  "PACOM",
  "PACOV",
  "PACUG",
  "PAČAN",
  "PAČEČ",
  "PAČEN",
  "PAČIL",
  "PAČIM",
  "PAČIŠ",
  "PAČIT",
  "PAČIU",
  "PAČIV",
  "PADAJ",
  "PADAL",
  "PADAM",
  "PADAR",
  "PADAŠ",
  "PADAT",
  "PADAU",
  "PADAV",
  "PADCA",
  "PADCE",
  "PADCI",
  "PADCU",
  "PADEC",
  "PADEL",
  "PADEM",
  "PADEN",
  "PADER",
  "PADEŠ",
  "PADEU",
  "PADEV",
  "PADEŽ",
  "PADIH",
  "PADJA",
  "PADJU",
  "PADLA",
  "PADLE",
  "PADLI",
  "PADLO",
  "PADNA",
  "PADNE",
  "PADNI",
  "PADNO",
  "PADOM",
  "PADOV",
  "PADRA",
  "PADRE",
  "PADRI",
  "PADRU",
  "PADTA",
  "PADTE",
  "PADTI",
  "PADTU",
  "PADUL",
  "PADUN",
  "PAELJ",
  "PAELL",
  "PAERA",
  "PAERE",
  "PAERI",
  "PAERT",
  "PAERU",
  "PAETA",
  "PAETE",
  "PAETH",
  "PAETI",
  "PAETS",
  "PAETU",
  "PAFIJ",
  "PAFIN",
  "PAFJA",
  "PAFJU",
  "PAFKA",
  "PAFKE",
  "PAFKI",
  "PAFKO",
  "PAFKU",
  "PAFOM",
  "PAFOS",
  "PAFOV",
  "PAGAN",
  "PAGAT",
  "PAGEA",
  "PAGEE",
  "PAGEI",
  "PAGER",
  "PAGES",
  "PAGET",
  "PAGEU",
  "PAGGI",
  "PAGIN",
  "PAGJA",
  "PAGJU",
  "PAGLI",
  "PAGNI",
  "PAGOD",
  "PAGOM",
  "PAGON",
  "PAHIČ",
  "PAHLA",
  "PAHLE",
  "PAHLI",
  "PAHLU",
  "PAHNE",
  "PAHNI",
  "PAHNU",
  "PAHOR",
  "PAICA",
  "PAICE",
  "PAIČA",
  "PAIČE",
  "PAIČI",
  "PAIČU",
  "PAIDI",
  "PAIEM",
  "PAIER",
  "PAIEV",
  "PAIGE",
  "PAIIH",
  "PAIJA",
  "PAIJU",
  "PAIKA",
  "PAIKE",
  "PAIKI",
  "PAIKU",
  "PAILA",
  "PAILE",
  "PAILI",
  "PAILO",
  "PAINA",
  "PAINE",
  "PAING",
  "PAINI",
  "PAINT",
  "PAINU",
  "PAIOL",
  "PAIOM",
  "PAIOV",
  "PAISI",
  "PAIZI",
  "PAJAC",
  "PAJAČ",
  "PAJCA",
  "PAJCI",
  "PAJCU",
  "PAJEK",
  "PAJEM",
  "PAJER",
  "PAJEV",
  "PAJIČ",
  "PAJIH",
  "PAJJA",
  "PAJJU",
  "PAJKA",
  "PAJKC",
  "PAJKE",
  "PAJKI",
  "PAJKU",
  "PAJOM",
  "PAJOV",
  "PAJTA",
  "PAJTE",
  "PAJTI",
  "PAJTU",
  "PAKAH",
  "PAKAM",
  "PAKEM",
  "PAKEN",
  "PAKET",
  "PAKEV",
  "PAKIH",
  "PAKIM",
  "PAKIN",
  "PAKIŽ",
  "PAKJA",
  "PAKJU",
  "PAKNA",
  "PAKNE",
  "PAKNI",
  "PAKNO",
  "PAKOM",
  "PAKOV",
  "PAKTA",
  "PAKTE",
  "PAKTI",
  "PAKTU",
  "PAKUL",
  "PALAČ",
  "PALAH",
  "PALAJ",
  "PALAM",
  "PALAN",
  "PALAT",
  "PALAU",
  "PALAV",
  "PALCA",
  "PALCE",
  "PALCI",
  "PALCU",
  "PALČK",
  "PALEC",
  "PALEČ",
  "PALET",
  "PALEŽ",
  "PALFI",
  "PALIC",
  "PALIČ",
  "PALIH",
  "PALIJ",
  "PALIK",
  "PALIL",
  "PALIM",
  "PALIN",
  "PALIR",
  "PALIS",
  "PALIŠ",
  "PALIT",
  "PALIU",
  "PALIV",
  "PALJA",
  "PALJK",
  "PALJU",
  "PALKA",
  "PALKE",
  "PALKI",
  "PALKO",
  "PALKU",
  "PALLA",
  "PALLE",
  "PALLI",
  "PALLO",
  "PALLU",
  "PALMA",
  "PALME",
  "PALMI",
  "PALMO",
  "PALMU",
  "PALOK",
  "PALOM",
  "PALOS",
  "PALOŠ",
  "PALOV",
  "PALTA",
  "PALTE",
  "PALTI",
  "PALTU",
  "PALUB",
  "PALUC",
  "PAMEČ",
  "PAMEL",
  "PAMET",
  "PAMIA",
  "PAMIE",
  "PAMIH",
  "PAMII",
  "PAMIN",
  "PAMIO",
  "PAMIR",
  "PAMIS",
  "PAMIU",
  "PAMJA",
  "PAMJU",
  "PAMOM",
  "PAMOV",
  "PAMPA",
  "PAMPE",
  "PAMPI",
  "PAMPO",
  "PAMUK",
  "PAMUL",
  "PAMŽA",
  "PAMŽE",
  "PAMŽI",
  "PAMŽU",
  "PANAD",
  "PANAJ",
  "PANAL",
  "PANAM",
  "PANAN",
  "PANAŠ",
  "PANAT",
  "PANAU",
  "PANAV",
  "PANCE",
  "PANCK",
  "PANDA",
  "PANDE",
  "PANDI",
  "PANDO",
  "PANDU",
  "PANEČ",
  "PANEL",
  "PANES",
  "PANGA",
  "PANGE",
  "PANGI",
  "PANGO",
  "PANGU",
  "PANIČ",
  "PANIH",
  "PANIK",
  "PANIN",
  "PANIS",
  "PANJA",
  "PANJE",
  "PANJI",
  "PANJU",
  "PANKA",
  "PANKE",
  "PANKI",
  "PANKO",
  "PANKU",
  "PANNA",
  "PANNE",
  "PANNI",
  "PANNO",
  "PANNU",
  "PANOG",
  "PANOM",
  "PANOS",
  "PANOV",
  "PANSA",
  "PANSE",
  "PANSI",
  "PANSO",
  "PANSU",
  "PANTA",
  "PANTE",
  "PANTI",
  "PANTO",
  "PANTS",
  "PANTU",
  "PANTZ",
  "PANUL",
  "PAOLA",
  "PAOLE",
  "PAOLI",
  "PAOLO",
  "PAOLU",
  "PAPAH",
  "PAPAJ",
  "PAPAK",
  "PAPAL",
  "PAPAM",
  "PAPAN",
  "PAPAS",
  "PAPAŠ",
  "PAPAT",
  "PAPAU",
  "PAPAV",
  "PAPCA",
  "PAPCI",
  "PAPCU",
  "PAPEN",
  "PAPEŠ",
  "PAPEŽ",
  "PAPIČ",
  "PAPIG",
  "PAPIH",
  "PAPIL",
  "PAPIN",
  "PAPIR",
  "PAPJA",
  "PAPJU",
  "PAPKE",
  "PAPNA",
  "PAPNE",
  "PAPNI",
  "PAPNU",
  "PAPOM",
  "PAPON",
  "PAPOV",
  "PAPPA",
  "PAPPE",
  "PAPPI",
  "PAPPS",
  "PAPPU",
  "PAPUA",
  "PAPUE",
  "PAPUG",
  "PAPUI",
  "PAPUK",
  "PAPUL",
  "PAPUO",
  "PARAA",
  "PARAČ",
  "PARAD",
  "PARAF",
  "PARAG",
  "PARAH",
  "PARAJ",
  "PARAL",
  "PARAM",
  "PARAN",
  "PARAŠ",
  "PARAT",
  "PARAU",
  "PARAV",
  "PARCA",
  "PARCE",
  "PARCI",
  "PARCU",
  "PARDA",
  "PARDE",
  "PARDI",
  "PARDO",
  "PARDU",
  "PAREC",
  "PAREČ",
  "PARED",
  "PAREM",
  "PAREN",
  "PAREO",
  "PARER",
  "PARET",
  "PAREV",
  "PAREZ",
  "PARFE",
  "PARIC",
  "PARIČ",
  "PARID",
  "PARIH",
  "PARIJ",
  "PARIL",
  "PARIM",
  "PARIS",
  "PARIŠ",
  "PARIT",
  "PARIU",
  "PARIV",
  "PARIZ",
  "PARJA",
  "PARJE",
  "PARJU",
  "PARKA",
  "PARKE",
  "PARKI",
  "PARKO",
  "PARKS",
  "PARKU",
  "PARMA",
  "PARME",
  "PARMI",
  "PARMO",
  "PARMU",
  "PARNA",
  "PARNE",
  "PARNI",
  "PARNO",
  "PAROA",
  "PAROE",
  "PAROH",
  "PAROI",
  "PAROL",
  "PAROM",
  "PAROU",
  "PAROV",
  "PAROŽ",
  "PARRA",
  "PARRE",
  "PARRI",
  "PARRO",
  "PARRU",
  "PARSA",
  "PARSE",
  "PARSI",
  "PARSO",
  "PARSU",
  "PARTA",
  "PARTE",
  "PARTH",
  "PARTI",
  "PARTL",
  "PARTO",
  "PARTU",
  "PARUN",
  "PARUS",
  "PASAL",
  "PASAN",
  "PASAR",
  "PASAT",
  "PASAU",
  "PASAV",
  "PASAŽ",
  "PASCH",
  "PASEK",
  "PASEL",
  "PASEM",
  "PASEN",
  "PASEŠ",
  "PASEU",
  "PASEV",
  "PASHA",
  "PASHE",
  "PASHI",
  "PASHK",
  "PASHO",
  "PASHU",
  "PASIC",
  "PASIH",
  "PASIK",
  "PASIV",
  "PASJA",
  "PASJE",
  "PASJI",
  "PASJO",
  "PASJU",
  "PASKA",
  "PASKE",
  "PASKI",
  "PASKO",
  "PASKU",
  "PASLA",
  "PASLE",
  "PASLI",
  "PASLO",
  "PASMA",
  "PASME",
  "PASMI",
  "PASMO",
  "PASNA",
  "PASNE",
  "PASNI",
  "PASNO",
  "PASOČ",
  "PASOM",
  "PASOŠ",
  "PASOV",
  "PASSA",
  "PASSE",
  "PASSI",
  "PASSU",
  "PASTA",
  "PASTE",
  "PASTI",
  "PASTO",
  "PASUS",
  "PASUŠ",
  "PASZT",
  "PAŠAH",
  "PAŠAM",
  "PAŠEK",
  "PAŠEM",
  "PAŠEN",
  "PAŠEŠ",
  "PAŠEV",
  "PAŠIČ",
  "PAŠIH",
  "PAŠIN",
  "PAŠJA",
  "PAŠJU",
  "PAŠKA",
  "PAŠKE",
  "PAŠKI",
  "PAŠKO",
  "PAŠKU",
  "PAŠNA",
  "PAŠNE",
  "PAŠNI",
  "PAŠNO",
  "PAŠOM",
  "PAŠOV",
  "PAŠTA",
  "PAŠTE",
  "PAŠTI",
  "PAŠTO",
  "PAŠUJ",
  "PATAR",
  "PATAS",
  "PATEA",
  "PATEE",
  "PATEI",
  "PATEJ",
  "PATEL",
  "PATEM",
  "PATEN",
  "PATEO",
  "PATER",
  "PATES",
  "PATEU",
  "PATEV",
  "PATIG",
  "PATIH",
  "PATIL",
  "PATIN",
  "PATJA",
  "PATJU",
  "PATKA",
  "PATKE",
  "PATKI",
  "PATKO",
  "PATKU",
  "PATNI",
  "PATOM",
  "PATON",
  "PATOS",
  "PATOU",
  "PATOV",
  "PATRA",
  "PATRE",
  "PATRI",
  "PATRU",
  "PATTA",
  "PATTE",
  "PATTI",
  "PATTU",
  "PATZA",
  "PATZE",
  "PATZI",
  "PATZU",
  "PAUCK",
  "PAUER",
  "PAUIH",
  "PAUJA",
  "PAUJU",
  "PAUKA",
  "PAUKE",
  "PAUKI",
  "PAUKU",
  "PAULA",
  "PAULE",
  "PAULI",
  "PAULK",
  "PAULL",
  "PAULO",
  "PAULS",
  "PAULU",
  "PAUNA",
  "PAUNE",
  "PAUNI",
  "PAUNU",
  "PAUOM",
  "PAUOV",
  "PAUSA",
  "PAUSE",
  "PAUSI",
  "PAUSU",
  "PAVAA",
  "PAVAE",
  "PAVAI",
  "PAVAN",
  "PAVAO",
  "PAVAU",
  "PAVCA",
  "PAVCE",
  "PAVCI",
  "PAVCU",
  "PAVEC",
  "PAVEL",
  "PAVEM",
  "PAVER",
  "PAVEV",
  "PAVIA",
  "PAVIC",
  "PAVIČ",
  "PAVIE",
  "PAVIH",
  "PAVII",
  "PAVIN",
  "PAVIO",
  "PAVJA",
  "PAVJE",
  "PAVJI",
  "PAVJO",
  "PAVJU",
  "PAVKA",
  "PAVKE",
  "PAVKI",
  "PAVKO",
  "PAVKU",
  "PAVLA",
  "PAVLE",
  "PAVLI",
  "PAVLO",
  "PAVLU",
  "PAVOL",
  "PAVOM",
  "PAVOV",
  "PAVŠE",
  "PAVUS",
  "PAVZA",
  "PAVZE",
  "PAVZI",
  "PAVZO",
  "PAZAR",
  "PAZEČ",
  "PAZEN",
  "PAZIA",
  "PAZIE",
  "PAZIH",
  "PAZII",
  "PAZIK",
  "PAZIL",
  "PAZIM",
  "PAZIN",
  "PAZIO",
  "PAZIŠ",
  "PAZIT",
  "PAZIU",
  "PAZIV",
  "PAZJA",
  "PAZJU",
  "PAZNA",
  "PAZNE",
  "PAZNI",
  "PAZNO",
  "PAZOM",
  "PAZOV",
  "PAŽAN",
  "PAŽEČ",
  "PAŽEM",
  "PAŽEN",
  "PAŽEV",
  "PAŽIH",
  "PAŽIL",
  "PAŽIM",
  "PAŽIN",
  "PAŽIŠ",
  "PAŽIT",
  "PAŽIU",
  "PAŽIV",
  "PAŽJA",
  "PAŽJU",
  "PAŽOM",
  "PAŽON",
  "PAŽOV",
  "PDVSA",
  "PEACA",
  "PEACE",
  "PEAKA",
  "PEAKE",
  "PEAKI",
  "PEAKU",
  "PEANA",
  "PEANE",
  "PEANI",
  "PEANO",
  "PEANU",
  "PEARL",
  "PEARN",
  "PEARS",
  "PEART",
  "PEASA",
  "PEASE",
  "PEASI",
  "PEASU",
  "PEATA",
  "PEATE",
  "PEATI",
  "PEATU",
  "PECAH",
  "PECAJ",
  "PECAL",
  "PECAM",
  "PECAS",
  "PECAŠ",
  "PECAT",
  "PECAU",
  "PECAV",
  "PECCI",
  "PECEV",
  "PECIN",
  "PECIV",
  "PECKA",
  "PECKE",
  "PECKI",
  "PECKU",
  "PECLA",
  "PECLE",
  "PECLI",
  "PECLU",
  "PECSA",
  "PECSU",
  "PEČAH",
  "PEČAJ",
  "PEČAK",
  "PEČAL",
  "PEČAM",
  "PEČAN",
  "PEČAR",
  "PEČAŠ",
  "PEČAT",
  "PEČAU",
  "PEČAV",
  "PEČEH",
  "PEČEK",
  "PEČEM",
  "PEČEN",
  "PEČEŠ",
  "PEČIC",
  "PEČIN",
  "PEČJA",
  "PEČJE",
  "PEČJO",
  "PEČJU",
  "PEČKA",
  "PEČKE",
  "PEČKI",
  "PEČKO",
  "PEČKU",
  "PEČMI",
  "PEČNA",
  "PEČNE",
  "PEČNI",
  "PEČNO",
  "PEČTI",
  "PEČUH",
  "PEDAL",
  "PEDDE",
  "PEDEH",
  "PEDEM",
  "PEDER",
  "PEDIC",
  "PEDIJ",
  "PEDJA",
  "PEDJE",
  "PEDJI",
  "PEDJO",
  "PEDJU",
  "PEDMI",
  "PEDRA",
  "PEDRE",
  "PEDRI",
  "PEDRO",
  "PEDRU",
  "PEECK",
  "PEEKA",
  "PEEKE",
  "PEEKI",
  "PEEKU",
  "PEELA",
  "PEELE",
  "PEELI",
  "PEELS",
  "PEELU",
  "PEERA",
  "PEERE",
  "PEERI",
  "PEERS",
  "PEERU",
  "PEETA",
  "PEETE",
  "PEETI",
  "PEETU",
  "PEGAH",
  "PEGAM",
  "PEGAN",
  "PEGAT",
  "PEGAV",
  "PEGAZ",
  "PEGGA",
  "PEGGE",
  "PEGGI",
  "PEGGU",
  "PEGIC",
  "PEGIN",
  "PEGLA",
  "PEGLI",
  "PEGLU",
  "PEGOV",
  "PEGUL",
  "PEHAJ",
  "PEHAL",
  "PEHAM",
  "PEHAN",
  "PEHAR",
  "PEHAŠ",
  "PEHAT",
  "PEHAU",
  "PEHAV",
  "PEHOT",
  "PEHTA",
  "PEHTE",
  "PEHTI",
  "PEHTO",
  "PEIER",
  "PEIJS",
  "PEILA",
  "PEILE",
  "PEILI",
  "PEILU",
  "PEJEM",
  "PEJEV",
  "PEJIČ",
  "PEJIH",
  "PEJJA",
  "PEJJU",
  "PEJMO",
  "PEJOM",
  "PEJOV",
  "PEJTA",
  "PEJTE",
  "PEJVA",
  "PEJVI",
  "PEKAČ",
  "PEKAH",
  "PEKAM",
  "PEKEL",
  "PEKEM",
  "PEKER",
  "PEKET",
  "PEKEU",
  "PEKEV",
  "PEKIČ",
  "PEKIH",
  "PEKIN",
  "PEKJA",
  "PEKJU",
  "PEKKA",
  "PEKKE",
  "PEKKI",
  "PEKKO",
  "PEKKU",
  "PEKLA",
  "PEKLE",
  "PEKLI",
  "PEKLO",
  "PEKLU",
  "PEKOČ",
  "PEKOM",
  "PEKOV",
  "PEKRE",
  "PELAJ",
  "PELAN",
  "PELAT",
  "PELCA",
  "PELCE",
  "PELCI",
  "PELCL",
  "PELCU",
  "PELEG",
  "PELEJ",
  "PELET",
  "PELIN",
  "PELIR",
  "PELJA",
  "PELJE",
  "PELJI",
  "PELJU",
  "PELKA",
  "PELKE",
  "PELKI",
  "PELKO",
  "PELKU",
  "PELLA",
  "PELLE",
  "PELLI",
  "PELLO",
  "PELLS",
  "PELLU",
  "PELOD",
  "PELOT",
  "PELOZ",
  "PELTA",
  "PELTE",
  "PELTI",
  "PELTO",
  "PELTU",
  "PELTZ",
  "PELZL",
  "PEMIH",
  "PEMJA",
  "PEMJU",
  "PEMOM",
  "PEMOV",
  "PENAH",
  "PENAL",
  "PENAM",
  "PENAT",
  "PENAV",
  "PENCA",
  "PENCE",
  "PENCI",
  "PENCK",
  "PENCO",
  "PENCU",
  "PENCZ",
  "PENDL",
  "PENEC",
  "PENEČ",
  "PENEM",
  "PENEŠ",
  "PENEV",
  "PENEZ",
  "PENGA",
  "PENGE",
  "PENGI",
  "PENGO",
  "PENGU",
  "PENHA",
  "PENHU",
  "PENIC",
  "PENIČ",
  "PENIH",
  "PENIL",
  "PENIM",
  "PENIN",
  "PENIS",
  "PENIŠ",
  "PENIT",
  "PENIU",
  "PENIV",
  "PENJA",
  "PENJE",
  "PENJI",
  "PENJO",
  "PENJU",
  "PENKA",
  "PENKE",
  "PENKI",
  "PENKO",
  "PENKU",
  "PENNA",
  "PENNE",
  "PENNI",
  "PENNU",
  "PENOM",
  "PENOV",
  "PENSA",
  "PENSE",
  "PENSI",
  "PENSO",
  "PENSU",
  "PENUŠ",
  "PENZA",
  "PENZE",
  "PENZI",
  "PENZO",
  "PENZU",
  "PEPAH",
  "PEPAM",
  "PEPCA",
  "PEPCE",
  "PEPCI",
  "PEPCO",
  "PEPEL",
  "PEPIH",
  "PEPIN",
  "PEPIT",
  "PEPJA",
  "PEPJU",
  "PEPOM",
  "PEPOV",
  "PEPPE",
  "PEPSI",
  "PERAK",
  "PERAN",
  "PERAS",
  "PERAT",
  "PERCA",
  "PERCE",
  "PERCI",
  "PERCL",
  "PERCU",
  "PERČI",
  "PEREC",
  "PEREČ",
  "PERED",
  "PEREM",
  "PERER",
  "PERES",
  "PEREŠ",
  "PERET",
  "PEREZ",
  "PERIC",
  "PERIČ",
  "PERIE",
  "PERIH",
  "PERIK",
  "PERIN",
  "PERIS",
  "PERIŠ",
  "PERJA",
  "PERJE",
  "PERJU",
  "PERKA",
  "PERKE",
  "PERKI",
  "PERKO",
  "PERKS",
  "PERKU",
  "PERLA",
  "PERLE",
  "PERLI",
  "PERLO",
  "PERLS",
  "PERLU",
  "PERMA",
  "PERME",
  "PERMI",
  "PERMU",
  "PERNA",
  "PERNE",
  "PERNI",
  "PERNO",
  "PERNU",
  "PEROČ",
  "PEROM",
  "PERON",
  "PEROŠ",
  "PEROT",
  "PEROV",
  "PERRI",
  "PERSI",
  "PERŠA",
  "PERŠE",
  "PERŠI",
  "PERŠL",
  "PERŠU",
  "PERTH",
  "PERTI",
  "PERTL",
  "PERUN",
  "PERUŠ",
  "PERUT",
  "PERZL",
  "PESAN",
  "PESAR",
  "PESCE",
  "PESCH",
  "PESCI",
  "PESEK",
  "PESEM",
  "PESEN",
  "PESEV",
  "PESIH",
  "PESIN",
  "PESJA",
  "PESJE",
  "PESJU",
  "PESKA",
  "PESKE",
  "PESKI",
  "PESKU",
  "PESLA",
  "PESLE",
  "PESLI",
  "PESLU",
  "PESMC",
  "PESMI",
  "PESNA",
  "PESNE",
  "PESNI",
  "PESNO",
  "PESNU",
  "PESOM",
  "PESOV",
  "PESRL",
  "PESSI",
  "PESSL",
  "PESTA",
  "PESTE",
  "PESTI",
  "PESTO",
  "PESTU",
  "PEŠAJ",
  "PEŠAK",
  "PEŠAL",
  "PEŠAM",
  "PEŠAŠ",
  "PEŠAT",
  "PEŠAU",
  "PEŠAV",
  "PEŠCA",
  "PEŠCE",
  "PEŠCI",
  "PEŠCU",
  "PEŠEC",
  "PEŠIČ",
  "PEŠKA",
  "PEŠKE",
  "PEŠKI",
  "PEŠKO",
  "PEŠLA",
  "PEŠLE",
  "PEŠLI",
  "PEŠLU",
  "PEŠTA",
  "PEŠTI",
  "PEŠTU",
  "PEŠUT",
  "PETAČ",
  "PETAH",
  "PETAK",
  "PETAL",
  "PETAM",
  "PETAN",
  "PETAR",
  "PETEH",
  "PETEK",
  "PETEL",
  "PETEM",
  "PETEN",
  "PETER",
  "PETGA",
  "PETIC",
  "PETIČ",
  "PETIH",
  "PETIJ",
  "PETIM",
  "PETIN",
  "PETIR",
  "PETIT",
  "PETJA",
  "PETJE",
  "PETJI",
  "PETJO",
  "PETJU",
  "PETKA",
  "PETKE",
  "PETKI",
  "PETKO",
  "PETKU",
  "PETNA",
  "PETNE",
  "PETNI",
  "PETNO",
  "PETOL",
  "PETOM",
  "PETOŠ",
  "PETOV",
  "PETRA",
  "PETRE",
  "PETRI",
  "PETRO",
  "PETRU",
  "PETTA",
  "PETTE",
  "PETTI",
  "PETTO",
  "PETTS",
  "PETTU",
  "PETZL",
  "PEUCH",
  "PEUSS",
  "PEVAJ",
  "PEVAL",
  "PEVAM",
  "PEVAŠ",
  "PEVAT",
  "PEVAU",
  "PEVAV",
  "PEVCA",
  "PEVCE",
  "PEVCI",
  "PEVCU",
  "PEVEC",
  "PEVEN",
  "PEVKA",
  "PEVKE",
  "PEVKI",
  "PEVKO",
  "PEVMA",
  "PEVME",
  "PEVMI",
  "PEVMO",
  "PEVNA",
  "PEVNE",
  "PEVNI",
  "PEVNO",
  "PEZAH",
  "PEZAM",
  "PEZDE",
  "PEZET",
  "PEZZI",
  "PEZZU",
  "PFAFF",
  "PFARR",
  "PFAUS",
  "PFEIL",
  "PFLUG",
  "PFOHL",
  "PFORR",
  "PHAJO",
  "PHALA",
  "PHALE",
  "PHALI",
  "PHALO",
  "PHAMA",
  "PHAME",
  "PHAMI",
  "PHAMO",
  "PHAMU",
  "PHANA",
  "PHANE",
  "PHANI",
  "PHANJ",
  "PHANO",
  "PHARA",
  "PHARE",
  "PHARM",
  "PHARU",
  "PHATA",
  "PHATE",
  "PHATI",
  "PHAVA",
  "PHAVE",
  "PHAVI",
  "PHILA",
  "PHILE",
  "PHILI",
  "PHILL",
  "PHILO",
  "PHILP",
  "PHILU",
  "PHIMO",
  "PHIRI",
  "PHITA",
  "PHITE",
  "PHITZ",
  "PHIVA",
  "PHNOM",
  "PHRAN",
  "PHUNG",
  "PIAFA",
  "PIAFE",
  "PIAFF",
  "PIAFI",
  "PIAFU",
  "PIAMA",
  "PIAMI",
  "PIANA",
  "PIANE",
  "PIANI",
  "PIANO",
  "PIANU",
  "PIARA",
  "PIARE",
  "PIARI",
  "PIARU",
  "PIATT",
  "PIAVA",
  "PIAVE",
  "PIAVI",
  "PIAVO",
  "PIAZZ",
  "PIBER",
  "PIBRA",
  "PIBRE",
  "PIBRI",
  "PIBRU",
  "PICAH",
  "PICAM",
  "PICEJ",
  "PICEK",
  "PICET",
  "PICHE",
  "PICIG",
  "PICKA",
  "PICKE",
  "PICKI",
  "PICKU",
  "PICON",
  "PICUL",
  "PIČEL",
  "PIČEN",
  "PIČIC",
  "PIČIL",
  "PIČIM",
  "PIČIŠ",
  "PIČIT",
  "PIČIU",
  "PIČIV",
  "PIČKA",
  "PIČKE",
  "PIČKI",
  "PIČKO",
  "PIČLA",
  "PIČLE",
  "PIČLI",
  "PIČLO",
  "PIDEM",
  "PIDEV",
  "PIDIH",
  "PIDJA",
  "PIDJU",
  "PIDOM",
  "PIDOV",
  "PIECH",
  "PIECK",
  "PIEJA",
  "PIEJE",
  "PIEJI",
  "PIEJU",
  "PIENE",
  "PIERA",
  "PIERE",
  "PIERI",
  "PIERO",
  "PIERS",
  "PIERU",
  "PIESK",
  "PIETA",
  "PIETE",
  "PIETH",
  "PIETI",
  "PIETU",
  "PIFFA",
  "PIFFE",
  "PIFFI",
  "PIFFU",
  "PIFKA",
  "PIFKE",
  "PIFKI",
  "PIFKO",
  "PIFKU",
  "PIFLA",
  "PIFLI",
  "PIFLU",
  "PIGAC",
  "PIGGA",
  "PIGGE",
  "PIGGI",
  "PIGGU",
  "PIGLA",
  "PIGLE",
  "PIGLI",
  "PIGLU",
  "PIGOU",
  "PIHAJ",
  "PIHAL",
  "PIHAM",
  "PIHAN",
  "PIHAŠ",
  "PIHAT",
  "PIHAU",
  "PIHAV",
  "PIHEM",
  "PIHEV",
  "PIHIH",
  "PIHJA",
  "PIHJU",
  "PIHNE",
  "PIHNI",
  "PIHNU",
  "PIHOM",
  "PIHOV",
  "PIJAČ",
  "PIJAH",
  "PIJAM",
  "PIJAN",
  "PIJEM",
  "PIJEŠ",
  "PIJEV",
  "PIJIH",
  "PIJIN",
  "PIJJA",
  "PIJJU",
  "PIJMO",
  "PIJOČ",
  "PIJOM",
  "PIJOV",
  "PIJTA",
  "PIJTE",
  "PIJUS",
  "PIJVA",
  "PIKAH",
  "PIKAJ",
  "PIKAL",
  "PIKAM",
  "PIKAŠ",
  "PIKAT",
  "PIKAU",
  "PIKAV",
  "PIKCA",
  "PIKCE",
  "PIKCI",
  "PIKCU",
  "PIKČA",
  "PIKČI",
  "PIKČU",
  "PIKEC",
  "PIKEL",
  "PIKEM",
  "PIKER",
  "PIKES",
  "PIKEV",
  "PIKIC",
  "PIKIH",
  "PIKIN",
  "PIKJA",
  "PIKJU",
  "PIKLA",
  "PIKLE",
  "PIKLI",
  "PIKLU",
  "PIKNE",
  "PIKNI",
  "PIKNU",
  "PIKOK",
  "PIKOM",
  "PIKON",
  "PIKOV",
  "PIKRA",
  "PIKRE",
  "PIKRI",
  "PIKRO",
  "PIKŠA",
  "PIKŠE",
  "PIKŠI",
  "PIKŠU",
  "PIKUL",
  "PIKUS",
  "PILAD",
  "PILAH",
  "PILAM",
  "PILAR",
  "PILAT",
  "PILAV",
  "PILCH",
  "PILEČ",
  "PILEJ",
  "PILIC",
  "PILIČ",
  "PILIH",
  "PILIK",
  "PILIL",
  "PILIM",
  "PILIŠ",
  "PILIT",
  "PILIU",
  "PILIV",
  "PILJA",
  "PILJE",
  "PILJI",
  "PILJU",
  "PILKA",
  "PILKE",
  "PILKI",
  "PILKO",
  "PILKU",
  "PILLA",
  "PILLE",
  "PILLI",
  "PILLO",
  "PILLS",
  "PILLU",
  "PILOM",
  "PILON",
  "PILOT",
  "PILSA",
  "PILSE",
  "PILSI",
  "PILSL",
  "PILSU",
  "PILUL",
  "PILUM",
  "PILVI",
  "PILZA",
  "PILZE",
  "PILZI",
  "PILZU",
  "PIMCA",
  "PIMCO",
  "PIMCU",
  "PIMIH",
  "PIMJA",
  "PIMJU",
  "PIMMA",
  "PIMME",
  "PIMMI",
  "PIMMU",
  "PIMOM",
  "PIMOV",
  "PINAH",
  "PINAL",
  "PINAM",
  "PINAR",
  "PINAS",
  "PINCA",
  "PINCE",
  "PINCI",
  "PINCO",
  "PINČA",
  "PINČE",
  "PINČI",
  "PINČU",
  "PINDA",
  "PINDI",
  "PINDU",
  "PINED",
  "PINEL",
  "PINEM",
  "PINER",
  "PINES",
  "PINEV",
  "PINGA",
  "PINGE",
  "PINGI",
  "PINGU",
  "PINHA",
  "PINHE",
  "PINHI",
  "PINHO",
  "PINHU",
  "PINIH",
  "PINIJ",
  "PINIL",
  "PINIM",
  "PININ",
  "PINIŠ",
  "PINIT",
  "PINIU",
  "PINIV",
  "PINJA",
  "PINJE",
  "PINJI",
  "PINJO",
  "PINJU",
  "PINKA",
  "PINKE",
  "PINKI",
  "PINKL",
  "PINKO",
  "PINKU",
  "PINNA",
  "PINNE",
  "PINNI",
  "PINNO",
  "PINNU",
  "PINOM",
  "PINON",
  "PINOS",
  "PINOT",
  "PINOV",
  "PINOZ",
  "PINOŽ",
  "PINSA",
  "PINSE",
  "PINSI",
  "PINSS",
  "PINSU",
  "PINTA",
  "PINTE",
  "PINTI",
  "PINTO",
  "PINTU",
  "PINUS",
  "PIOLA",
  "PIOLE",
  "PIOLI",
  "PIOLO",
  "PIOLU",
  "PIONA",
  "PIONE",
  "PIONI",
  "PIONU",
  "PIORA",
  "PIORE",
  "PIORI",
  "PIORO",
  "PIORU",
  "PIOTA",
  "PIOTE",
  "PIOTI",
  "PIOTR",
  "PIOTU",
  "PIPAH",
  "PIPAJ",
  "PIPAL",
  "PIPAM",
  "PIPAN",
  "PIPAR",
  "PIPAŠ",
  "PIPAT",
  "PIPAU",
  "PIPAV",
  "PIPCA",
  "PIPCE",
  "PIPCI",
  "PIPCU",
  "PIPEC",
  "PIPER",
  "PIPES",
  "PIPET",
  "PIPIC",
  "PIPIH",
  "PIPIN",
  "PIPJA",
  "PIPJU",
  "PIPKE",
  "PIPNE",
  "PIPNI",
  "PIPNU",
  "PIPOM",
  "PIPOV",
  "PIPPA",
  "PIPPE",
  "PIPPI",
  "PIPPO",
  "PIPPU",
  "PIPSA",
  "PIPSI",
  "PIPSU",
  "PIPUŠ",
  "PIRAJ",
  "PIRAN",
  "PIRAS",
  "PIRAT",
  "PIRCA",
  "PIRCE",
  "PIRCI",
  "PIRCU",
  "PIRČE",
  "PIREJ",
  "PIREM",
  "PIRES",
  "PIREV",
  "PIRGU",
  "PIRHA",
  "PIRHE",
  "PIRHI",
  "PIRHU",
  "PIRIE",
  "PIRIH",
  "PIRIK",
  "PIRIN",
  "PIRIS",
  "PIRIT",
  "PIRJA",
  "PIRJU",
  "PIRKA",
  "PIRKE",
  "PIRKI",
  "PIRKO",
  "PIRLA",
  "PIRLE",
  "PIRLI",
  "PIRLO",
  "PIRLS",
  "PIRLU",
  "PIROG",
  "PIROM",
  "PIRON",
  "PIROT",
  "PIROV",
  "PIRRA",
  "PIRRE",
  "PIRRI",
  "PIRRO",
  "PIRRU",
  "PIRŠA",
  "PIRŠE",
  "PIRŠI",
  "PIRŠU",
  "PIRUJ",
  "PISAČ",
  "PISAL",
  "PISAN",
  "PISAR",
  "PISAT",
  "PISAU",
  "PISAV",
  "PISCA",
  "PISCE",
  "PISCI",
  "PISCU",
  "PISEC",
  "PISEK",
  "PISEM",
  "PISEN",
  "PISIS",
  "PISKA",
  "PISKE",
  "PISKI",
  "PISKO",
  "PISKU",
  "PISMA",
  "PISMI",
  "PISMO",
  "PISMU",
  "PISNA",
  "PISNE",
  "PISNI",
  "PISNO",
  "PISNU",
  "PISOČ",
  "PISON",
  "PISTA",
  "PISTE",
  "PISTI",
  "PISTO",
  "PISUL",
  "PISUN",
  "PIŠČE",
  "PIŠEC",
  "PIŠEK",
  "PIŠEM",
  "PIŠEŠ",
  "PIŠEV",
  "PIŠIH",
  "PIŠJA",
  "PIŠJU",
  "PIŠKA",
  "PIŠKE",
  "PIŠKI",
  "PIŠKO",
  "PIŠKU",
  "PIŠOČ",
  "PIŠOM",
  "PIŠOT",
  "PIŠOV",
  "PITAH",
  "PITAJ",
  "PITAL",
  "PITAM",
  "PITAN",
  "PITAŠ",
  "PITAT",
  "PITAU",
  "PITAV",
  "PITEN",
  "PITIH",
  "PITIJ",
  "PITJA",
  "PITJE",
  "PITJI",
  "PITJU",
  "PITNA",
  "PITNE",
  "PITNI",
  "PITNO",
  "PITOL",
  "PITOM",
  "PITON",
  "PITOV",
  "PITSA",
  "PITSE",
  "PITSI",
  "PITSO",
  "PITSU",
  "PITTA",
  "PITTE",
  "PITTI",
  "PITTL",
  "PITTS",
  "PITTU",
  "PITZA",
  "PITZE",
  "PITZI",
  "PITZU",
  "PIUSA",
  "PIUSE",
  "PIUSI",
  "PIUSU",
  "PIVAC",
  "PIVAČ",
  "PIVAH",
  "PIVAM",
  "PIVAR",
  "PIVCA",
  "PIVCE",
  "PIVCI",
  "PIVCU",
  "PIVEC",
  "PIVEN",
  "PIVEV",
  "PIVIH",
  "PIVIN",
  "PIVKA",
  "PIVKE",
  "PIVKI",
  "PIVKO",
  "PIVKU",
  "PIVNA",
  "PIVNE",
  "PIVNI",
  "PIVNO",
  "PIVNU",
  "PIVOD",
  "PIVOM",
  "PIVOT",
  "PIVOV",
  "PIZAN",
  "PIZDA",
  "PIZDE",
  "PIZDI",
  "PIZDO",
  "PIZDU",
  "PIZZA",
  "PIZZE",
  "PIZZI",
  "PIZZO",
  "PIZZU",
  "PIŽAM",
  "PIŽEM",
  "PIŽIR",
  "PIŽMA",
  "PIŽME",
  "PIŽMI",
  "PIŽMU",
  "PJACA",
  "PJACE",
  "PJACI",
  "PJACO",
  "PJACU",
  "PJERA",
  "PJERE",
  "PJERI",
  "PJERU",
  "PLACA",
  "PLACE",
  "PLACI",
  "PLACU",
  "PLAČA",
  "PLAČE",
  "PLAČI",
  "PLAČO",
  "PLAČU",
  "PLADL",
  "PLAHA",
  "PLAHE",
  "PLAHI",
  "PLAHO",
  "PLAHT",
  "PLAIN",
  "PLAIT",
  "PLAJA",
  "PLAJB",
  "PLAJE",
  "PLAJI",
  "PLAJU",
  "PLAKA",
  "PLAKE",
  "PLAKI",
  "PLAKU",
  "PLALA",
  "PLALE",
  "PLALI",
  "PLALO",
  "PLAMA",
  "PLAME",
  "PLAMI",
  "PLAMO",
  "PLAMU",
  "PLANA",
  "PLANE",
  "PLANI",
  "PLANJ",
  "PLANK",
  "PLANO",
  "PLANT",
  "PLANU",
  "PLAPP",
  "PLASA",
  "PLASE",
  "PLASI",
  "PLASS",
  "PLAST",
  "PLASU",
  "PLAŠČ",
  "PLAŠI",
  "PLAŠU",
  "PLATA",
  "PLATE",
  "PLATH",
  "PLATI",
  "PLATO",
  "PLATT",
  "PLATU",
  "PLAVA",
  "PLAVC",
  "PLAVE",
  "PLAVI",
  "PLAVO",
  "PLAVT",
  "PLAVU",
  "PLAVŽ",
  "PLAZA",
  "PLAZE",
  "PLAZI",
  "PLAZL",
  "PLAZO",
  "PLAZU",
  "PLAŽA",
  "PLAŽE",
  "PLAŽI",
  "PLAŽO",
  "PLEBS",
  "PLEČA",
  "PLEČE",
  "PLEČI",
  "PLEČK",
  "PLEDA",
  "PLEDE",
  "PLEDI",
  "PLEDU",
  "PLEHA",
  "PLEHI",
  "PLEHU",
  "PLEIN",
  "PLEJA",
  "PLEJE",
  "PLEJI",
  "PLEJU",
  "PLELA",
  "PLELE",
  "PLELI",
  "PLELO",
  "PLEME",
  "PLENA",
  "PLENE",
  "PLENI",
  "PLENK",
  "PLENU",
  "PLEPP",
  "PLESA",
  "PLESE",
  "PLESI",
  "PLESL",
  "PLESS",
  "PLEST",
  "PLESU",
  "PLEŠA",
  "PLEŠC",
  "PLEŠE",
  "PLEŠI",
  "PLEŠK",
  "PLEŠO",
  "PLEŠU",
  "PLETA",
  "PLETE",
  "PLETI",
  "PLETO",
  "PLETT",
  "PLETU",
  "PLETZ",
  "PLEVA",
  "PLEVC",
  "PLEVE",
  "PLEVI",
  "PLEVK",
  "PLEVO",
  "PLEZA",
  "PLEZI",
  "PLEZU",
  "PLEŽE",
  "PLEŽI",
  "PLIKA",
  "PLIKE",
  "PLIKI",
  "PLIKO",
  "PLIMA",
  "PLIME",
  "PLIMI",
  "PLIMO",
  "PLIMU",
  "PLINA",
  "PLINE",
  "PLINI",
  "PLINK",
  "PLINU",
  "PLISE",
  "PLIŠA",
  "PLIŠI",
  "PLIŠU",
  "PLITT",
  "PLIVA",
  "PLIVE",
  "PLIVI",
  "PLIVO",
  "PLJUČ",
  "PLJUJ",
  "PLLAN",
  "PLOCK",
  "PLOČE",
  "PLODA",
  "PLODE",
  "PLODI",
  "PLODU",
  "PLOGV",
  "PLOHA",
  "PLOHE",
  "PLOHI",
  "PLOHL",
  "PLOHO",
  "PLOHU",
  "PLOJA",
  "PLOJE",
  "PLOJI",
  "PLOJU",
  "PLOMA",
  "PLOMB",
  "PLOME",
  "PLOMI",
  "PLOMU",
  "PLONK",
  "PLOOG",
  "PLOSA",
  "PLOSE",
  "PLOSI",
  "PLOSK",
  "PLOSS",
  "PLOSU",
  "PLOŠČ",
  "PLOTA",
  "PLOTC",
  "PLOTE",
  "PLOTI",
  "PLOTU",
  "PLOVA",
  "PLOVB",
  "PLOVC",
  "PLOVE",
  "PLOVI",
  "PLOVK",
  "PLOVU",
  "PLOZA",
  "PLOZI",
  "PLOZU",
  "PLUCH",
  "PLUČA",
  "PLUČI",
  "PLUGA",
  "PLUGE",
  "PLUGI",
  "PLUGU",
  "PLUJE",
  "PLULA",
  "PLULE",
  "PLULI",
  "PLULO",
  "PLUMA",
  "PLUMB",
  "PLUME",
  "PLUMI",
  "PLUMU",
  "PLUSA",
  "PLUSE",
  "PLUSI",
  "PLUSU",
  "PLUTA",
  "PLUTE",
  "PLUTH",
  "PLUTI",
  "PLUTO",
  "PLUTU",
  "PLUVA",
  "PLUVI",
  "PLUVU",
  "PLUŽI",
  "PLUŽU",
  "PLZEN",
  "PLZNA",
  "PLZNU",
  "PNEJO",
  "PNEMA",
  "PNEME",
  "PNEMO",
  "PNETA",
  "PNETE",
  "PNEVA",
  "PNEVE",
  "PNEVI",
  "PNGIH",
  "PNGJA",
  "PNGJU",
  "PNGOM",
  "PNGOV",
  "PNIMO",
  "PNINA",
  "PNINE",
  "PNINI",
  "PNINO",
  "PNITA",
  "PNITE",
  "PNIVA",
  "PNJJU",
  "POANT",
  "POAST",
  "POBCA",
  "POBCE",
  "POBCI",
  "POBCU",
  "POBEC",
  "POBEG",
  "POBEM",
  "POBEV",
  "POBIČ",
  "POBIH",
  "POBIJ",
  "POBIL",
  "POBIN",
  "POBIT",
  "POBIU",
  "POBIV",
  "POBJA",
  "POBJE",
  "POBJU",
  "POBOJ",
  "POBOM",
  "POBOT",
  "POBOV",
  "POBRU",
  "POBUD",
  "POCAR",
  "POCEM",
  "POCEV",
  "POCHA",
  "POCHE",
  "POCHI",
  "POCHU",
  "POCIH",
  "POCJA",
  "POCJU",
  "POCKA",
  "POCKE",
  "POCKI",
  "POCKU",
  "POCOM",
  "POCOV",
  "POČAH",
  "POČAK",
  "POČAM",
  "POČAN",
  "POČAS",
  "POČEH",
  "POČEK",
  "POČEL",
  "POČEM",
  "POČEN",
  "POČEP",
  "POČET",
  "POČEU",
  "POČEZ",
  "POČIČ",
  "POČIJ",
  "POČIL",
  "POČIM",
  "POČIŠ",
  "POČIT",
  "POČIU",
  "POČIV",
  "POČJO",
  "POČKA",
  "POČKE",
  "POČKI",
  "POČKU",
  "POČMI",
  "POČNE",
  "POČNI",
  "POČNO",
  "POČUČ",
  "PODAJ",
  "PODAL",
  "PODAM",
  "PODAN",
  "PODAŠ",
  "PODAT",
  "PODAU",
  "PODAV",
  "PODBO",
  "PODBU",
  "PODEČ",
  "PODEL",
  "PODEM",
  "PODEN",
  "PODEV",
  "PODIA",
  "PODIE",
  "PODIH",
  "PODII",
  "PODIJ",
  "PODIL",
  "PODIM",
  "PODIO",
  "PODIŠ",
  "PODIT",
  "PODIU",
  "PODIV",
  "PODJA",
  "PODJE",
  "PODJU",
  "PODLA",
  "PODLE",
  "PODLI",
  "PODLO",
  "PODLU",
  "PODNA",
  "PODNE",
  "PODNI",
  "PODNO",
  "PODNU",
  "PODOB",
  "PODOJ",
  "PODOM",
  "PODOR",
  "PODOV",
  "PODRE",
  "PODRI",
  "PODRL",
  "PODRS",
  "PODRT",
  "PODŠU",
  "PODUB",
  "PODUK",
  "PODVU",
  "POEJA",
  "POEJE",
  "POEJI",
  "POEJU",
  "POELA",
  "POELE",
  "POELI",
  "POELS",
  "POELU",
  "POEMA",
  "POEME",
  "POEMI",
  "POEMO",
  "POETA",
  "POETE",
  "POETI",
  "POETU",
  "POFEL",
  "POFLA",
  "POFLI",
  "POFLU",
  "POFUK",
  "POGAČ",
  "POGAN",
  "POGBA",
  "POGGI",
  "POGIN",
  "POGNU",
  "POGOJ",
  "POGON",
  "POGRU",
  "POGUB",
  "POGUE",
  "POGUM",
  "POHAB",
  "POHAJ",
  "POHAL",
  "POHAM",
  "POHAN",
  "POHAR",
  "POHAŠ",
  "POHAT",
  "POHAU",
  "POHAV",
  "POHER",
  "POHLA",
  "POHLE",
  "POHLI",
  "POHLU",
  "POHOD",
  "POHOT",
  "POHRA",
  "POHRE",
  "POHRI",
  "POHRO",
  "POIDE",
  "POIDI",
  "POIER",
  "POINT",
  "POITI",
  "POJAJ",
  "POJAL",
  "POJAM",
  "POJAN",
  "POJAR",
  "POJAŠ",
  "POJAT",
  "POJAU",
  "POJAV",
  "POJDE",
  "POJDI",
  "POJEČ",
  "POJEJ",
  "POJEM",
  "POJEŠ",
  "POJIL",
  "POJIM",
  "POJIŠ",
  "POJIT",
  "POJIU",
  "POJIV",
  "POJMA",
  "POJME",
  "POJMI",
  "POJMO",
  "POJMU",
  "POJOČ",
  "POJTA",
  "POJTE",
  "POJVA",
  "POKAČ",
  "POKAH",
  "POKAJ",
  "POKAL",
  "POKAM",
  "POKAN",
  "POKAŠ",
  "POKAT",
  "POKAU",
  "POKAV",
  "POKAZ",
  "POKCA",
  "POKCE",
  "POKCI",
  "POKCU",
  "POKEC",
  "POKEM",
  "POKER",
  "POKEV",
  "POKIH",
  "POKJA",
  "POKJU",
  "POKKA",
  "POKKE",
  "POKKI",
  "POKKO",
  "POKKU",
  "POKLU",
  "POKNE",
  "POKNI",
  "POKNU",
  "POKOJ",
  "POKOL",
  "POKOM",
  "POKOP",
  "POKOR",
  "POKOS",
  "POKOŠ",
  "POKOV",
  "POKRA",
  "POKRČ",
  "POKRI",
  "POKRU",
  "POLAH",
  "POLAJ",
  "POLAK",
  "POLAM",
  "POLAN",
  "POLAR",
  "POLAT",
  "POLCA",
  "POLCE",
  "POLCI",
  "POLCU",
  "POLDA",
  "POLDE",
  "POLDI",
  "POLDK",
  "POLDO",
  "POLDU",
  "POLEC",
  "POLEČ",
  "POLEG",
  "POLEK",
  "POLEM",
  "POLEN",
  "POLER",
  "POLES",
  "POLET",
  "POLEV",
  "POLHA",
  "POLHE",
  "POLHI",
  "POLHU",
  "POLIA",
  "POLIB",
  "POLIC",
  "POLIČ",
  "POLIE",
  "POLIH",
  "POLII",
  "POLIJ",
  "POLIK",
  "POLIL",
  "POLIN",
  "POLIO",
  "POLIP",
  "POLIR",
  "POLIS",
  "POLIT",
  "POLIU",
  "POLIV",
  "POLJA",
  "POLJČ",
  "POLJE",
  "POLJI",
  "POLJK",
  "POLJU",
  "POLKA",
  "POLKE",
  "POLKI",
  "POLKO",
  "POLKU",
  "POLLA",
  "POLLE",
  "POLLI",
  "POLLO",
  "POLLU",
  "POLNA",
  "POLNE",
  "POLNI",
  "POLNO",
  "POLNU",
  "POLOG",
  "POLOM",
  "POLON",
  "POLOS",
  "POLOV",
  "POLSA",
  "POLSE",
  "POLSI",
  "POLST",
  "POLSU",
  "POLŠE",
  "POLTA",
  "POLTE",
  "POLTI",
  "POLTO",
  "POLTU",
  "POLUL",
  "POLUT",
  "POLZA",
  "POLZE",
  "POLZI",
  "POLZO",
  "POLZU",
  "POLŽA",
  "POLŽE",
  "POLŽI",
  "POLŽU",
  "POMAD",
  "POMAK",
  "POMEL",
  "POMEN",
  "POMET",
  "POMEU",
  "POMIG",
  "POMIJ",
  "POMIK",
  "POMIL",
  "POMIT",
  "POMIU",
  "POMIV",
  "POMLU",
  "POMNI",
  "POMNU",
  "POMOČ",
  "POMOL",
  "POMON",
  "POMOR",
  "POMOT",
  "POMPA",
  "POMPE",
  "POMPI",
  "POMPU",
  "POMRE",
  "POMRI",
  "POMRL",
  "PONAH",
  "PONAM",
  "PONCA",
  "PONCE",
  "PONCI",
  "PONCK",
  "PONCO",
  "PONČA",
  "PONČE",
  "PONČI",
  "PONČO",
  "PONČU",
  "PONDA",
  "PONDE",
  "PONDI",
  "PONDU",
  "PONEV",
  "PONGA",
  "PONGE",
  "PONGI",
  "PONGU",
  "PONIK",
  "PONIS",
  "PONIŠ",
  "PONIŽ",
  "PONKA",
  "PONKE",
  "PONKI",
  "PONKU",
  "PONOR",
  "PONOS",
  "PONOŠ",
  "PONOV",
  "PONSA",
  "PONSE",
  "PONSI",
  "PONSU",
  "PONTA",
  "PONTE",
  "PONTI",
  "PONTO",
  "PONTU",
  "PONUD",
  "PONVA",
  "PONVE",
  "PONVI",
  "PONVO",
  "PONZI",
  "POOJA",
  "POOJE",
  "POOJI",
  "POOJO",
  "POOKA",
  "POOKE",
  "POOKI",
  "POOKU",
  "POOLA",
  "POOLE",
  "POOLI",
  "POOLO",
  "POOLU",
  "POONA",
  "POONE",
  "POONI",
  "POONS",
  "POONU",
  "POORA",
  "POORE",
  "POORI",
  "POORT",
  "POORU",
  "POOTH",
  "POOTS",
  "POPAJ",
  "POPAL",
  "POPAM",
  "POPAR",
  "POPAŠ",
  "POPAT",
  "POPAU",
  "POPAV",
  "POPEČ",
  "POPEK",
  "POPEL",
  "POPEM",
  "POPER",
  "POPET",
  "POPEU",
  "POPEV",
  "POPIČ",
  "POPIH",
  "POPIJ",
  "POPIK",
  "POPIL",
  "POPIN",
  "POPIS",
  "POPIT",
  "POPIU",
  "POPIV",
  "POPJA",
  "POPJE",
  "POPJU",
  "POPKA",
  "POPKE",
  "POPKI",
  "POPKO",
  "POPKU",
  "POPLA",
  "POPLE",
  "POPLI",
  "POPLU",
  "POPNE",
  "POPNI",
  "POPOM",
  "POPON",
  "POPOV",
  "POPPA",
  "POPPE",
  "POPPI",
  "POPPU",
  "POPRA",
  "POPRE",
  "POPRH",
  "POPRI",
  "POPRU",
  "PORAB",
  "PORAH",
  "PORAM",
  "PORAT",
  "PORAZ",
  "PORDI",
  "PORDU",
  "POREČ",
  "POREK",
  "POREM",
  "POREN",
  "POREV",
  "PORIH",
  "PORIJ",
  "PORIL",
  "PORIN",
  "PORIT",
  "PORIU",
  "PORIV",
  "PORJA",
  "PORJU",
  "PORLE",
  "PORNA",
  "PORNE",
  "PORNI",
  "PORNO",
  "PORNU",
  "POROČ",
  "POROD",
  "POROG",
  "POROK",
  "POROM",
  "PORON",
  "POROT",
  "POROV",
  "POROZ",
  "PORRA",
  "PORRE",
  "PORRI",
  "PORRO",
  "PORRU",
  "PORSH",
  "PORST",
  "PORTA",
  "PORTE",
  "PORTH",
  "PORTI",
  "PORTO",
  "PORTU",
  "PORUJ",
  "POSAD",
  "POSAR",
  "POSCH",
  "POSCU",
  "POSEČ",
  "POSEG",
  "POSEJ",
  "POSEK",
  "POSEL",
  "POSEM",
  "POSIH",
  "POSIJ",
  "POSIP",
  "POSJA",
  "POSJU",
  "POSLA",
  "POSLE",
  "POSLI",
  "POSLU",
  "POSMA",
  "POSME",
  "POSMI",
  "POSMO",
  "POSMU",
  "POSNU",
  "POSOD",
  "POSOJ",
  "POSOM",
  "POSOV",
  "POSPI",
  "POSPU",
  "POSRU",
  "POSSA",
  "POSSE",
  "POSSI",
  "POSSU",
  "POSTA",
  "POSTE",
  "POSTH",
  "POSTI",
  "POSTL",
  "POSTO",
  "POSTU",
  "POSUJ",
  "POSUL",
  "POSUT",
  "POŠČI",
  "POŠEL",
  "POŠEM",
  "POŠEU",
  "POŠEV",
  "POŠIH",
  "POŠIJ",
  "POŠIL",
  "POŠIT",
  "POŠIU",
  "POŠIV",
  "POŠJA",
  "POŠJU",
  "POŠKA",
  "POŠKE",
  "POŠKI",
  "POŠKO",
  "POŠLA",
  "POŠLE",
  "POŠLI",
  "POŠLO",
  "POŠOM",
  "POŠOV",
  "POŠTA",
  "POŠTE",
  "POŠTI",
  "POŠTO",
  "POŠTU",
  "POTAR",
  "POTEČ",
  "POTEG",
  "POTEH",
  "POTEK",
  "POTEM",
  "POTEN",
  "POTEP",
  "POTEV",
  "POTEZ",
  "POTHA",
  "POTHE",
  "POTHI",
  "POTHU",
  "POTIC",
  "POTIČ",
  "POTIH",
  "POTIL",
  "POTIM",
  "POTIN",
  "POTIŠ",
  "POTIT",
  "POTIU",
  "POTIV",
  "POTJA",
  "POTJO",
  "POTJU",
  "POTKA",
  "POTKE",
  "POTKI",
  "POTKO",
  "POTLI",
  "POTLU",
  "POTMI",
  "POTNA",
  "POTNE",
  "POTNI",
  "POTNO",
  "POTOČ",
  "POTOK",
  "POTOM",
  "POTOP",
  "POTOV",
  "POTRČ",
  "POTRE",
  "POTRI",
  "POTRL",
  "POTRT",
  "POTTA",
  "POTTE",
  "POTTI",
  "POTTS",
  "POTTU",
  "POTUJ",
  "POTZA",
  "POTZE",
  "POTZI",
  "POTZO",
  "POTZU",
  "POUČI",
  "POUČL",
  "POUČU",
  "POUKA",
  "POUKE",
  "POUKI",
  "POUKU",
  "POULA",
  "POULE",
  "POULI",
  "POULU",
  "POUND",
  "POUŽU",
  "POVED",
  "POVEJ",
  "POVEM",
  "POVES",
  "POVEŠ",
  "POVET",
  "POVEZ",
  "POVHA",
  "POVHE",
  "POVHI",
  "POVHU",
  "POVIJ",
  "POVIL",
  "POVIR",
  "POVIT",
  "POVIU",
  "POVIV",
  "POVNA",
  "POVNE",
  "POVNI",
  "POVNO",
  "POVOD",
  "POVOJ",
  "POVOT",
  "POVOZ",
  "POVRE",
  "POVRG",
  "POVRH",
  "POVRI",
  "POVRU",
  "POVŠE",
  "POVZU",
  "POVŽA",
  "POVŽE",
  "POVŽI",
  "POVŽU",
  "POZAB",
  "POZAH",
  "POZAM",
  "POZEB",
  "POZEN",
  "POZER",
  "POZIV",
  "POZLU",
  "POZNA",
  "POZNE",
  "POZNI",
  "POZNO",
  "POZNU",
  "POZOJ",
  "POZOR",
  "POZVE",
  "POZVU",
  "POZZA",
  "POZZE",
  "POZZI",
  "POZZO",
  "POZZU",
  "POŽAR",
  "POŽEG",
  "POŽEK",
  "POŽEL",
  "POŽES",
  "POŽET",
  "POŽEU",
  "POŽGE",
  "POŽGI",
  "POŽGU",
  "POŽIG",
  "POŽIN",
  "POŽRE",
  "POŽRI",
  "POŽRL",
  "POŽRO",
  "POŽRT",
  "POŽRU",
  "POŽUN",
  "PPOLK",
  "PRAAG",
  "PRACK",
  "PRAČA",
  "PRAČE",
  "PRAČI",
  "PRAČO",
  "PRADA",
  "PRADE",
  "PRADI",
  "PRADO",
  "PRADU",
  "PRAET",
  "PRAGA",
  "PRAGE",
  "PRAGI",
  "PRAGO",
  "PRAGU",
  "PRAHA",
  "PRAHC",
  "PRAHE",
  "PRAHI",
  "PRAHL",
  "PRAHO",
  "PRAHU",
  "PRAIA",
  "PRAIE",
  "PRAII",
  "PRAIO",
  "PRAJS",
  "PRAKS",
  "PRALA",
  "PRALC",
  "PRALE",
  "PRALI",
  "PRALK",
  "PRALL",
  "PRALO",
  "PRAMA",
  "PRAME",
  "PRAMI",
  "PRAMO",
  "PRAMU",
  "PRANA",
  "PRANE",
  "PRANG",
  "PRANI",
  "PRANJ",
  "PRANO",
  "PRAPA",
  "PRAPE",
  "PRAPI",
  "PRAPU",
  "PRASC",
  "PRASE",
  "PRASI",
  "PRASK",
  "PRASL",
  "PRAST",
  "PRASU",
  "PRAŠA",
  "PRAŠE",
  "PRAŠI",
  "PRAŠU",
  "PRATA",
  "PRATE",
  "PRATI",
  "PRATO",
  "PRATS",
  "PRATT",
  "PRATU",
  "PRAUN",
  "PRAVA",
  "PRAVD",
  "PRAVE",
  "PRAVI",
  "PRAVO",
  "PRAVU",
  "PRAŽE",
  "PRAŽI",
  "PRAŽU",
  "PRBIJ",
  "PRBIL",
  "PRBIT",
  "PRBIU",
  "PRBIV",
  "PRDAH",
  "PRDAM",
  "PRDCA",
  "PRDCE",
  "PRDCI",
  "PRDCU",
  "PRDEC",
  "PRDEČ",
  "PRDEL",
  "PRDET",
  "PRDEU",
  "PRDIM",
  "PRDIŠ",
  "PRDNE",
  "PRDNI",
  "PRDNU",
  "PREAC",
  "PREBO",
  "PREBU",
  "PREČA",
  "PREČE",
  "PREČI",
  "PREČK",
  "PREČL",
  "PREČO",
  "PREČU",
  "PREDA",
  "PREDE",
  "PREDG",
  "PREDI",
  "PREDN",
  "PREDO",
  "PREDU",
  "PREET",
  "PREGL",
  "PREHM",
  "PREHN",
  "PREIL",
  "PREIS",
  "PREIT",
  "PREJA",
  "PREJC",
  "PREJE",
  "PREJI",
  "PREJK",
  "PREJO",
  "PREJU",
  "PREKA",
  "PREKE",
  "PREKI",
  "PREKO",
  "PREKU",
  "PRELA",
  "PRELC",
  "PRELE",
  "PRELI",
  "PRELK",
  "PRELL",
  "PRELU",
  "PREMA",
  "PREMC",
  "PREME",
  "PREMI",
  "PREMK",
  "PREMO",
  "PREMU",
  "PREND",
  "PRENG",
  "PRENI",
  "PRENK",
  "PRENN",
  "PRENT",
  "PREPU",
  "PRERU",
  "PRESA",
  "PRESE",
  "PRESI",
  "PRESL",
  "PRESS",
  "PREST",
  "PRESU",
  "PREŠA",
  "PREŠC",
  "PREŠE",
  "PREŠI",
  "PREŠO",
  "PREŠU",
  "PRETE",
  "PRETI",
  "PRETU",
  "PREVC",
  "PREVU",
  "PREZA",
  "PREZE",
  "PREZI",
  "PREZL",
  "PREZO",
  "PREZU",
  "PREŽA",
  "PREŽE",
  "PREŽI",
  "PREŽO",
  "PREŽU",
  "PRGAH",
  "PRGAM",
  "PRGIH",
  "PRGJA",
  "PRGJU",
  "PRGOM",
  "PRGOV",
  "PRHAH",
  "PRHAJ",
  "PRHAK",
  "PRHAL",
  "PRHAM",
  "PRHAŠ",
  "PRHAT",
  "PRHAU",
  "PRHAV",
  "PRHEK",
  "PRHEL",
  "PRHKA",
  "PRHKE",
  "PRHKI",
  "PRHKO",
  "PRHLA",
  "PRHLE",
  "PRHLI",
  "PRHLO",
  "PRHNE",
  "PRHNI",
  "PRHNU",
  "PRHUT",
  "PRIAL",
  "PRIAM",
  "PRIBA",
  "PRIBE",
  "PRIBI",
  "PRIBL",
  "PRIBO",
  "PRIBU",
  "PRICA",
  "PRICE",
  "PRIČA",
  "PRIČE",
  "PRIČI",
  "PRIČO",
  "PRIČU",
  "PRIDA",
  "PRIDE",
  "PRIDI",
  "PRIDO",
  "PRIDU",
  "PRIEL",
  "PRIEM",
  "PRIEN",
  "PRIES",
  "PRIGA",
  "PRIGL",
  "PRIGO",
  "PRIGU",
  "PRIIM",
  "PRIJA",
  "PRIJI",
  "PRIJU",
  "PRIKL",
  "PRILL",
  "PRILU",
  "PRIMA",
  "PRIMC",
  "PRIME",
  "PRIMI",
  "PRIMM",
  "PRIMO",
  "PRIMU",
  "PRINA",
  "PRINC",
  "PRINE",
  "PRING",
  "PRINI",
  "PRINS",
  "PRINT",
  "PRINU",
  "PRINZ",
  "PRIOL",
  "PRION",
  "PRIOR",
  "PRIOT",
  "PRIPU",
  "PRIRU",
  "PRISK",
  "PRISL",
  "PRISS",
  "PRIŠČ",
  "PRIŠU",
  "PRITI",
  "PRIUS",
  "PRIVU",
  "PRIŽU",
  "PRKAH",
  "PRKAM",
  "PRKIH",
  "PRKJA",
  "PRKJU",
  "PRKOM",
  "PRKOV",
  "PRLEK",
  "PRLJA",
  "PRLJE",
  "PRLJI",
  "PRLJO",
  "PRLJU",
  "PRNEK",
  "PROBA",
  "PROBE",
  "PROBI",
  "PROBO",
  "PROBU",
  "PROCK",
  "PRODA",
  "PRODC",
  "PRODE",
  "PRODI",
  "PRODO",
  "PRODU",
  "PROFI",
  "PROFT",
  "PROGA",
  "PROGE",
  "PROGI",
  "PROGO",
  "PROGU",
  "PROJA",
  "PROJE",
  "PROJI",
  "PROJO",
  "PROJT",
  "PROJU",
  "PROLI",
  "PROLL",
  "PRONK",
  "PRONT",
  "PROPA",
  "PROPE",
  "PROPI",
  "PROPP",
  "PROPS",
  "PROPU",
  "PROSA",
  "PROSE",
  "PROSI",
  "PROSO",
  "PROSS",
  "PROST",
  "PROSU",
  "PROŠT",
  "PROTA",
  "PROTE",
  "PROTI",
  "PROTO",
  "PROTU",
  "PROUD",
  "PROUT",
  "PROZA",
  "PROZE",
  "PROZI",
  "PROZO",
  "PROŽI",
  "PROŽU",
  "PRPIČ",
  "PRSAH",
  "PRSAM",
  "PRSAT",
  "PRSCA",
  "PRSCI",
  "PRSEČ",
  "PRSEN",
  "PRSIH",
  "PRSIL",
  "PRSIM",
  "PRSIŠ",
  "PRSIT",
  "PRSIU",
  "PRSIV",
  "PRSKA",
  "PRSKE",
  "PRSKI",
  "PRSKU",
  "PRSMI",
  "PRSNA",
  "PRSNE",
  "PRSNI",
  "PRSNO",
  "PRSNU",
  "PRSOM",
  "PRSOV",
  "PRSTA",
  "PRSTE",
  "PRSTI",
  "PRSTU",
  "PRŠAH",
  "PRŠAL",
  "PRŠAM",
  "PRŠAT",
  "PRŠAU",
  "PRŠAV",
  "PRŠCA",
  "PRŠCE",
  "PRŠCI",
  "PRŠCU",
  "PRŠEC",
  "PRŠEČ",
  "PRŠEL",
  "PRŠEM",
  "PRŠEN",
  "PRŠEŠ",
  "PRŠET",
  "PRŠEU",
  "PRŠEV",
  "PRŠIC",
  "PRŠIČ",
  "PRŠIH",
  "PRŠIL",
  "PRŠIM",
  "PRŠIN",
  "PRŠIŠ",
  "PRŠIT",
  "PRŠIU",
  "PRŠIV",
  "PRŠJA",
  "PRŠJU",
  "PRŠNA",
  "PRŠNE",
  "PRŠNI",
  "PRŠNO",
  "PRŠOM",
  "PRŠOV",
  "PRŠUH",
  "PRŠUT",
  "PRTEČ",
  "PRTEM",
  "PRTEN",
  "PRTEV",
  "PRTIČ",
  "PRTIH",
  "PRTIL",
  "PRTIM",
  "PRTIN",
  "PRTIŠ",
  "PRTIT",
  "PRTIU",
  "PRTIV",
  "PRTJA",
  "PRTJU",
  "PRTOM",
  "PRTOV",
  "PRUCA",
  "PRUCE",
  "PRUČK",
  "PRUDA",
  "PRUDI",
  "PRUDO",
  "PRUDU",
  "PRUEM",
  "PRUKL",
  "PRULE",
  "PRUNČ",
  "PRUNK",
  "PRUSA",
  "PRUSE",
  "PRUSI",
  "PRUSS",
  "PRUSU",
  "PRUŠA",
  "PRUŠE",
  "PRUŠI",
  "PRUŠU",
  "PRVAK",
  "PRVCA",
  "PRVCE",
  "PRVCI",
  "PRVCU",
  "PRVEC",
  "PRVEM",
  "PRVIČ",
  "PRVIH",
  "PRVIM",
  "PRVIN",
  "PSAIL",
  "PSAKI",
  "PSALM",
  "PSARK",
  "PSARN",
  "PSICA",
  "PSICE",
  "PSICI",
  "PSICO",
  "PSIČK",
  "PSIHA",
  "PSIHE",
  "PSIHI",
  "PSIHO",
  "PSIJA",
  "PSIJE",
  "PSIJI",
  "PSIJU",
  "PSIKA",
  "PSIKI",
  "PSIKU",
  "PSIUK",
  "PSKOV",
  "PSOMA",
  "PSOMI",
  "PSOTA",
  "PSOTT",
  "PSOTU",
  "PSOVK",
  "PSOVU",
  "PSUJE",
  "PŠAGA",
  "PŠAGE",
  "PŠAGI",
  "PŠAGU",
  "PŠAJD",
  "PŠATA",
  "PŠATE",
  "PŠATI",
  "PŠATO",
  "PŠENA",
  "PŠENO",
  "PŠENU",
  "PŠEVO",
  "PTAKA",
  "PTAKE",
  "PTAKI",
  "PTAKU",
  "PTICA",
  "PTICE",
  "PTICI",
  "PTICO",
  "PTIČA",
  "PTIČE",
  "PTIČI",
  "PTIČK",
  "PTIČU",
  "PTOKA",
  "PTOKE",
  "PTOKI",
  "PTOKU",
  "PTOZA",
  "PTOZE",
  "PTOZI",
  "PTOZO",
  "PTUJA",
  "PTUJU",
  "PUBEM",
  "PUBEV",
  "PUBIH",
  "PUBJA",
  "PUBJU",
  "PUBOM",
  "PUBOV",
  "PUCAJ",
  "PUCAL",
  "PUCAM",
  "PUCAR",
  "PUCAŠ",
  "PUCAT",
  "PUCAU",
  "PUCAV",
  "PUCCI",
  "PUCEM",
  "PUCER",
  "PUCEV",
  "PUCHA",
  "PUCHT",
  "PUCHU",
  "PUCIH",
  "PUCJA",
  "PUCJU",
  "PUCKA",
  "PUCKE",
  "PUCKI",
  "PUCKO",
  "PUCKU",
  "PUCOM",
  "PUCOV",
  "PUČAH",
  "PUČAM",
  "PUČAN",
  "PUČAR",
  "PUČEK",
  "PUČEM",
  "PUČEV",
  "PUČIH",
  "PUČJA",
  "PUČJU",
  "PUČKA",
  "PUČKE",
  "PUČKI",
  "PUČKO",
  "PUČKU",
  "PUČOM",
  "PUČOV",
  "PUDAR",
  "PUDEM",
  "PUDER",
  "PUDEV",
  "PUDIH",
  "PUDJA",
  "PUDJU",
  "PUDLA",
  "PUDLI",
  "PUDLU",
  "PUDOB",
  "PUDOM",
  "PUDOV",
  "PUDRA",
  "PUDRE",
  "PUDRI",
  "PUDRN",
  "PUDRU",
  "PUELA",
  "PUELE",
  "PUELI",
  "PUELU",
  "PUERT",
  "PUETT",
  "PUFEM",
  "PUFER",
  "PUFEV",
  "PUFIH",
  "PUFJA",
  "PUFJU",
  "PUFOM",
  "PUFOV",
  "PUFRA",
  "PUFRE",
  "PUFRI",
  "PUFRU",
  "PUGET",
  "PUGHA",
  "PUGHE",
  "PUGHI",
  "PUGHU",
  "PUGIN",
  "PUHAJ",
  "PUHAL",
  "PUHAM",
  "PUHAN",
  "PUHAR",
  "PUHAŠ",
  "PUHAT",
  "PUHAU",
  "PUHAV",
  "PUHCA",
  "PUHCE",
  "PUHCI",
  "PUHCU",
  "PUHEC",
  "PUHEK",
  "PUHEL",
  "PUHEM",
  "PUHIH",
  "PUHJA",
  "PUHJU",
  "PUHKA",
  "PUHKE",
  "PUHKI",
  "PUHKU",
  "PUHLA",
  "PUHLE",
  "PUHLI",
  "PUHLO",
  "PUHNE",
  "PUHNI",
  "PUHNU",
  "PUHOM",
  "PUHOV",
  "PUHRA",
  "PUHRE",
  "PUHRI",
  "PUHRU",
  "PUHTE",
  "PUHTI",
  "PUHTU",
  "PUIGA",
  "PUIGE",
  "PUIGI",
  "PUIGU",
  "PUIJU",
  "PUJEM",
  "PUJEV",
  "PUJIH",
  "PUJJA",
  "PUJJU",
  "PUJOL",
  "PUJOM",
  "PUJOV",
  "PUJSA",
  "PUJSE",
  "PUJSI",
  "PUJSO",
  "PUJSU",
  "PUKAH",
  "PUKAM",
  "PUKCA",
  "PUKCE",
  "PUKCI",
  "PUKCU",
  "PUKEC",
  "PUKEL",
  "PUKET",
  "PUKIH",
  "PUKJA",
  "PUKJU",
  "PUKKA",
  "PUKKE",
  "PUKKI",
  "PUKKU",
  "PUKLA",
  "PUKLE",
  "PUKLI",
  "PUKLU",
  "PUKOM",
  "PUKOV",
  "PULAH",
  "PULAM",
  "PULCA",
  "PULCE",
  "PULCI",
  "PULCU",
  "PULEC",
  "PULIH",
  "PULIL",
  "PULIM",
  "PULIN",
  "PULIS",
  "PULIŠ",
  "PULIT",
  "PULIU",
  "PULIV",
  "PULJA",
  "PULJU",
  "PULKA",
  "PULKE",
  "PULKI",
  "PULKO",
  "PULKU",
  "PULLI",
  "PULOM",
  "PULOV",
  "PULPA",
  "PULPE",
  "PULPI",
  "PULPO",
  "PULSA",
  "PULSE",
  "PULSI",
  "PULSU",
  "PULTA",
  "PULTE",
  "PULTI",
  "PULTU",
  "PULZA",
  "PULZE",
  "PULZI",
  "PULZU",
  "PUMAH",
  "PUMAM",
  "PUMIH",
  "PUMIN",
  "PUMJA",
  "PUMJU",
  "PUMOM",
  "PUMOV",
  "PUMPA",
  "PUMPE",
  "PUMPI",
  "PUMPO",
  "PUMPS",
  "PUMPU",
  "PUNAT",
  "PUNCA",
  "PUNCE",
  "PUNCI",
  "PUNCO",
  "PUNCU",
  "PUNČA",
  "PUNČE",
  "PUNČI",
  "PUNČK",
  "PUNČU",
  "PUNEC",
  "PUNEV",
  "PUNIŠ",
  "PUNKA",
  "PUNKE",
  "PUNKI",
  "PUNKO",
  "PUNKT",
  "PUNKU",
  "PUNTA",
  "PUNTE",
  "PUNTI",
  "PUNTO",
  "PUNTU",
  "PUPAH",
  "PUPAJ",
  "PUPAL",
  "PUPAM",
  "PUPAŠ",
  "PUPAT",
  "PUPAU",
  "PUPAV",
  "PUPCA",
  "PUPCI",
  "PUPCU",
  "PUPEK",
  "PUPIC",
  "PUPIH",
  "PUPIL",
  "PUPIN",
  "PUPIS",
  "PUPJA",
  "PUPJU",
  "PUPKA",
  "PUPKE",
  "PUPKI",
  "PUPKU",
  "PUPOM",
  "PUPOV",
  "PUPPA",
  "PUPPE",
  "PUPPI",
  "PUPPO",
  "PUPPU",
  "PURAH",
  "PURAM",
  "PURAN",
  "PURAR",
  "PURGA",
  "PURGE",
  "PURGI",
  "PURGO",
  "PURGU",
  "PURIC",
  "PURIČ",
  "PURIN",
  "PURIS",
  "PURJA",
  "PURJE",
  "PURJI",
  "PURJO",
  "PURJU",
  "PURLA",
  "PURLE",
  "PURLI",
  "PURLU",
  "PURRA",
  "PURRE",
  "PURRI",
  "PURRO",
  "PURRU",
  "PURŠA",
  "PURŠE",
  "PURŠI",
  "PURŠU",
  "PUSAN",
  "PUSAR",
  "PUSIČ",
  "PUSTA",
  "PUSTE",
  "PUSTI",
  "PUSTO",
  "PUSTU",
  "PUŠAH",
  "PUŠAM",
  "PUŠAR",
  "PUŠČA",
  "PUŠČE",
  "PUŠČI",
  "PUŠČO",
  "PUŠČU",
  "PUŠEM",
  "PUŠEV",
  "PUŠIC",
  "PUŠIČ",
  "PUŠIH",
  "PUŠIL",
  "PUŠIM",
  "PUŠIŠ",
  "PUŠIT",
  "PUŠIU",
  "PUŠIV",
  "PUŠJA",
  "PUŠJE",
  "PUŠJI",
  "PUŠJO",
  "PUŠJU",
  "PUŠKA",
  "PUŠKE",
  "PUŠKI",
  "PUŠKO",
  "PUŠLA",
  "PUŠLE",
  "PUŠLI",
  "PUŠLU",
  "PUŠOM",
  "PUŠOV",
  "PUTAH",
  "PUTAM",
  "PUTCH",
  "PUTEM",
  "PUTER",
  "PUTEV",
  "PUTHA",
  "PUTHE",
  "PUTHI",
  "PUTHU",
  "PUTIH",
  "PUTIN",
  "PUTIŠ",
  "PUTJA",
  "PUTJU",
  "PUTKA",
  "PUTKE",
  "PUTKI",
  "PUTKO",
  "PUTOM",
  "PUTOV",
  "PUTRA",
  "PUTRE",
  "PUTRI",
  "PUTRU",
  "PUTTA",
  "PUTTE",
  "PUTTI",
  "PUTTO",
  "PUTTU",
  "PUURS",
  "PUZAK",
  "PUZIH",
  "PUZJA",
  "PUZJU",
  "PUZOM",
  "PUZOV",
  "PUŽAR",
  "PUŽEM",
  "PUŽEV",
  "PUŽIH",
  "PUŽJA",
  "PUŽJU",
  "PUŽOM",
  "PUŽOV",
  "RAABA",
  "RAABE",
  "RAABI",
  "RAABU",
  "RAADA",
  "RAADE",
  "RAADI",
  "RAADU",
  "RAAFA",
  "RAAFE",
  "RAAFI",
  "RAAFU",
  "RAAGA",
  "RAAGE",
  "RAAGI",
  "RAAGU",
  "RAANT",
  "RAATA",
  "RAATE",
  "RAATI",
  "RAATU",
  "RABAC",
  "RABAH",
  "RABAL",
  "RABAM",
  "RABAN",
  "RABAR",
  "RABAT",
  "RABBA",
  "RABBE",
  "RABBI",
  "RABBU",
  "RABCA",
  "RABCU",
  "RABEČ",
  "RABHI",
  "RABIČ",
  "RABIE",
  "RABIH",
  "RABIL",
  "RABIM",
  "RABIN",
  "RABIŠ",
  "RABIT",
  "RABIU",
  "RABIV",
  "RABJA",
  "RABJO",
  "RABJU",
  "RABLA",
  "RABLE",
  "RABLI",
  "RABLO",
  "RABLU",
  "RABMA",
  "RABME",
  "RABMO",
  "RABOM",
  "RABON",
  "RABOT",
  "RABOV",
  "RABTA",
  "RABTE",
  "RABUK",
  "RABUZ",
  "RABVA",
  "RABVE",
  "RABVI",
  "RACAH",
  "RACAJ",
  "RACAK",
  "RACAL",
  "RACAM",
  "RACAŠ",
  "RACAT",
  "RACAU",
  "RACAV",
  "RACCA",
  "RACCE",
  "RACCI",
  "RACCO",
  "RACCU",
  "RACEM",
  "RACIJ",
  "RACIN",
  "RACKA",
  "RACKE",
  "RACKI",
  "RACKU",
  "RAČAH",
  "RAČAK",
  "RAČAM",
  "RAČAN",
  "RAČEK",
  "RAČEL",
  "RAČIC",
  "RAČIČ",
  "RAČJA",
  "RAČJE",
  "RAČJI",
  "RAČJO",
  "RAČKA",
  "RAČKE",
  "RAČKI",
  "RAČKO",
  "RAČKU",
  "RAČNA",
  "RAČNE",
  "RAČNI",
  "RAČNO",
  "RAČUN",
  "RADAH",
  "RADAK",
  "RADAM",
  "RADAN",
  "RADAR",
  "RADDA",
  "RADDE",
  "RADDI",
  "RADDU",
  "RADEČ",
  "RADEJ",
  "RADEK",
  "RADER",
  "RADET",
  "RADEV",
  "RADEŽ",
  "RADHA",
  "RADHE",
  "RADHI",
  "RADHO",
  "RADIA",
  "RADIČ",
  "RADIE",
  "RADIH",
  "RADII",
  "RADIJ",
  "RADIN",
  "RADIO",
  "RADIŠ",
  "RADIU",
  "RADJA",
  "RADJE",
  "RADJI",
  "RADJO",
  "RADJU",
  "RADKA",
  "RADKE",
  "RADKI",
  "RADKO",
  "RADKU",
  "RADLA",
  "RADLE",
  "RADLI",
  "RADLU",
  "RADNA",
  "RADNE",
  "RADNI",
  "RADNO",
  "RADOH",
  "RADOJ",
  "RADOK",
  "RADOL",
  "RADOM",
  "RADON",
  "RADOS",
  "RADOŠ",
  "RADOV",
  "RADTA",
  "RADTE",
  "RADTI",
  "RADTU",
  "RADUH",
  "RADUJ",
  "RADUN",
  "RADUŠ",
  "RADŽA",
  "RADŽE",
  "RADŽI",
  "RADŽO",
  "RADŽU",
  "RAEEM",
  "RAEEV",
  "RAEIH",
  "RAEIN",
  "RAEJA",
  "RAEJU",
  "RAEOV",
  "RAESA",
  "RAESE",
  "RAESI",
  "RAESU",
  "RAFAJ",
  "RAFAL",
  "RAFET",
  "RAFFA",
  "RAFFE",
  "RAFFI",
  "RAFFL",
  "RAFFO",
  "RAFFU",
  "RAFIH",
  "RAFIJ",
  "RAFIK",
  "RAFIU",
  "RAFJA",
  "RAFJU",
  "RAFKA",
  "RAFKE",
  "RAFKI",
  "RAFKO",
  "RAFKU",
  "RAFOM",
  "RAFOT",
  "RAFOV",
  "RAFTA",
  "RAFTE",
  "RAFTI",
  "RAFTU",
  "RAFUN",
  "RAFUT",
  "RAGAB",
  "RAGAD",
  "RAGAN",
  "RAGAS",
  "RAGBI",
  "RAGEN",
  "RAGER",
  "RAGGI",
  "RAGGS",
  "RAGIN",
  "RAGIP",
  "RAGJU",
  "RAGMI",
  "RAGNA",
  "RAGNE",
  "RAGNI",
  "RAGNO",
  "RAGOR",
  "RAGUS",
  "RAGUŽ",
  "RAHAF",
  "RAHAH",
  "RAHAL",
  "RAHAM",
  "RAHAV",
  "RAHEL",
  "RAHIH",
  "RAHIL",
  "RAHIM",
  "RAHIN",
  "RAHJA",
  "RAHJU",
  "RAHLA",
  "RAHLE",
  "RAHLI",
  "RAHLO",
  "RAHMA",
  "RAHME",
  "RAHMI",
  "RAHMU",
  "RAHNA",
  "RAHNE",
  "RAHNI",
  "RAHNU",
  "RAHOL",
  "RAHOM",
  "RAHOV",
  "RAHUL",
  "RAICH",
  "RAIČA",
  "RAIČE",
  "RAIČI",
  "RAIČU",
  "RAIFA",
  "RAIFE",
  "RAIFI",
  "RAIFU",
  "RAIJO",
  "RAIKA",
  "RAIKE",
  "RAIKI",
  "RAIKU",
  "RAILA",
  "RAILE",
  "RAILI",
  "RAILO",
  "RAILU",
  "RAIMA",
  "RAIME",
  "RAIMI",
  "RAIMO",
  "RAIMU",
  "RAINA",
  "RAINE",
  "RAINI",
  "RAINN",
  "RAINO",
  "RAINS",
  "RAINU",
  "RAIOL",
  "RAISA",
  "RAISE",
  "RAISI",
  "RAISO",
  "RAISS",
  "RAISU",
  "RAITH",
  "RAITT",
  "RAIVA",
  "RAIVE",
  "RAIVI",
  "RAIVO",
  "RAIVU",
  "RAJAA",
  "RAJAE",
  "RAJAH",
  "RAJAI",
  "RAJAJ",
  "RAJAL",
  "RAJAM",
  "RAJAN",
  "RAJAO",
  "RAJAR",
  "RAJAŠ",
  "RAJAT",
  "RAJAU",
  "RAJAV",
  "RAJCA",
  "RAJCE",
  "RAJCI",
  "RAJCU",
  "RAJDA",
  "RAJDE",
  "RAJDI",
  "RAJDO",
  "RAJEC",
  "RAJEM",
  "RAJER",
  "RAJEV",
  "RAJFA",
  "RAJFE",
  "RAJFI",
  "RAJFU",
  "RAJGL",
  "RAJHA",
  "RAJHE",
  "RAJHI",
  "RAJHU",
  "RAJIČ",
  "RAJIH",
  "RAJIV",
  "RAJJA",
  "RAJJU",
  "RAJKA",
  "RAJKE",
  "RAJKI",
  "RAJKO",
  "RAJKU",
  "RAJNA",
  "RAJNE",
  "RAJNI",
  "RAJNO",
  "RAJNU",
  "RAJOM",
  "RAJON",
  "RAJOV",
  "RAJŠI",
  "RAJŠL",
  "RAJŠP",
  "RAJTA",
  "RAJTE",
  "RAJTI",
  "RAJTU",
  "RAJŽA",
  "RAJŽE",
  "RAJŽI",
  "RAJŽO",
  "RAJŽU",
  "RAKAB",
  "RAKAH",
  "RAKAM",
  "RAKAR",
  "RAKAV",
  "RAKCA",
  "RAKCE",
  "RAKCI",
  "RAKCU",
  "RAKEC",
  "RAKEF",
  "RAKEK",
  "RAKEL",
  "RAKEM",
  "RAKET",
  "RAKEV",
  "RAKIC",
  "RAKIČ",
  "RAKIH",
  "RAKIM",
  "RAKIN",
  "RAKIT",
  "RAKJA",
  "RAKJU",
  "RAKLA",
  "RAKLE",
  "RAKLI",
  "RAKLU",
  "RAKOM",
  "RAKOŠ",
  "RAKOV",
  "RAKSH",
  "RAKUN",
  "RAKUŠ",
  "RAKVE",
  "RAKVI",
  "RALAH",
  "RALAM",
  "RALCA",
  "RALCE",
  "RALCI",
  "RALCO",
  "RALCU",
  "RALEM",
  "RALES",
  "RALEV",
  "RALFA",
  "RALFE",
  "RALFI",
  "RALFU",
  "RALIH",
  "RALIK",
  "RALJA",
  "RALJU",
  "RALLA",
  "RALLE",
  "RALLI",
  "RALLU",
  "RALOM",
  "RALOV",
  "RALPH",
  "RAMAH",
  "RAMAJ",
  "RAMAM",
  "RAMAN",
  "RAMBA",
  "RAMBE",
  "RAMBI",
  "RAMBO",
  "RAMBU",
  "RAMEN",
  "RAMIČ",
  "RAMIH",
  "RAMIJ",
  "RAMIL",
  "RAMIN",
  "RAMIS",
  "RAMIZ",
  "RAMJA",
  "RAMJU",
  "RAMLA",
  "RAMLE",
  "RAMLI",
  "RAMLU",
  "RAMMA",
  "RAMME",
  "RAMMI",
  "RAMMU",
  "RAMOM",
  "RAMON",
  "RAMOS",
  "RAMOV",
  "RAMPA",
  "RAMPE",
  "RAMPF",
  "RAMPI",
  "RAMPL",
  "RAMPO",
  "RAMSA",
  "RAMSE",
  "RAMSI",
  "RAMSU",
  "RAMUŠ",
  "RAMUT",
  "RAMUZ",
  "RAMZI",
  "RANAH",
  "RANAM",
  "RANAR",
  "RANCA",
  "RANCE",
  "RANCH",
  "RANCI",
  "RANCO",
  "RANCU",
  "RANČA",
  "RANČE",
  "RANČI",
  "RANČU",
  "RANDA",
  "RANDE",
  "RANDI",
  "RANDL",
  "RANDU",
  "RANEM",
  "RANER",
  "RANFL",
  "RANFT",
  "RANGA",
  "RANGE",
  "RANGI",
  "RANGO",
  "RANGU",
  "RANIC",
  "RANIČ",
  "RANIH",
  "RANIK",
  "RANIL",
  "RANIM",
  "RANIN",
  "RANIS",
  "RANIŠ",
  "RANIT",
  "RANIU",
  "RANIV",
  "RANJA",
  "RANJU",
  "RANKA",
  "RANKE",
  "RANKI",
  "RANKL",
  "RANKO",
  "RANKU",
  "RANNA",
  "RANNE",
  "RANNI",
  "RANNU",
  "RANOM",
  "RANOV",
  "RANST",
  "RANTA",
  "RANTE",
  "RANTI",
  "RANTU",
  "RANUL",
  "RANUM",
  "RAOMA",
  "RAONI",
  "RAORT",
  "RAOSA",
  "RAOSE",
  "RAOSI",
  "RAOSU",
  "RAOTA",
  "RAOTU",
  "RAOUF",
  "RAOUL",
  "RAOVA",
  "RAOVE",
  "RAOVI",
  "RAOVO",
  "RAPAH",
  "RAPAM",
  "RAPEM",
  "RAPER",
  "RAPFA",
  "RAPFE",
  "RAPFI",
  "RAPFU",
  "RAPID",
  "RAPIN",
  "RAPIR",
  "RAPJA",
  "RAPJU",
  "RAPKE",
  "RAPOM",
  "RAPOŠ",
  "RAPPA",
  "RAPPE",
  "RAPPI",
  "RAPPL",
  "RAPPU",
  "RAPUŠ",
  "RARIH",
  "RARJA",
  "RARJU",
  "RAROG",
  "RAROM",
  "RAROV",
  "RASAH",
  "RASAM",
  "RASCH",
  "RASEL",
  "RASEM",
  "RASEN",
  "RASEŠ",
  "RASEU",
  "RASFF",
  "RASHA",
  "RASHE",
  "RASHI",
  "RASHU",
  "RASIH",
  "RASIK",
  "RASIL",
  "RASIM",
  "RASJA",
  "RASJU",
  "RASKA",
  "RASKE",
  "RASKI",
  "RASKU",
  "RASLA",
  "RASLE",
  "RASLI",
  "RASLO",
  "RASNA",
  "RASNE",
  "RASNI",
  "RASNO",
  "RASOL",
  "RASOM",
  "RASOV",
  "RASPA",
  "RASPE",
  "RASPI",
  "RASPU",
  "RASTA",
  "RASTE",
  "RASTI",
  "RASTL",
  "RASTO",
  "RASTU",
  "RASUK",
  "RASUL",
  "RAŠAH",
  "RAŠAM",
  "RAŠAN",
  "RAŠČA",
  "RAŠČE",
  "RAŠČI",
  "RAŠČL",
  "RAŠČO",
  "RAŠČU",
  "RAŠEM",
  "RAŠET",
  "RAŠEV",
  "RAŠIČ",
  "RAŠID",
  "RAŠIH",
  "RAŠIN",
  "RAŠIT",
  "RAŠJA",
  "RAŠJU",
  "RAŠKA",
  "RAŠKE",
  "RAŠKI",
  "RAŠKO",
  "RAŠKU",
  "RAŠLA",
  "RAŠLE",
  "RAŠLI",
  "RAŠLU",
  "RAŠOM",
  "RAŠOV",
  "RAŠPA",
  "RAŠPE",
  "RAŠPI",
  "RAŠPO",
  "RAŠPU",
  "RAŠUL",
  "RATAJ",
  "RATAL",
  "RATAM",
  "RATAN",
  "RATAR",
  "RATAS",
  "RATAŠ",
  "RATAT",
  "RATAU",
  "RATAV",
  "RATEČ",
  "RATEJ",
  "RATEK",
  "RATEŽ",
  "RATHA",
  "RATHE",
  "RATHI",
  "RATHS",
  "RATHU",
  "RATIA",
  "RATIH",
  "RATIU",
  "RATJA",
  "RATJE",
  "RATJU",
  "RATKA",
  "RATKE",
  "RATKI",
  "RATKO",
  "RATKU",
  "RATOM",
  "RATON",
  "RATOŠ",
  "RATOV",
  "RATTA",
  "RATTE",
  "RATTI",
  "RATTO",
  "RATTU",
  "RATZA",
  "RATZE",
  "RATZI",
  "RATZU",
  "RAUCH",
  "RAUFA",
  "RAUFE",
  "RAUFF",
  "RAUFI",
  "RAUFU",
  "RAUHA",
  "RAUHE",
  "RAUHI",
  "RAUHU",
  "RAUIH",
  "RAUJA",
  "RAUJU",
  "RAULA",
  "RAULE",
  "RAULI",
  "RAULT",
  "RAULU",
  "RAUMA",
  "RAUME",
  "RAUMI",
  "RAUMU",
  "RAUOM",
  "RAUOV",
  "RAUPP",
  "RAUSA",
  "RAUSE",
  "RAUSI",
  "RAUSU",
  "RAUŠA",
  "RAUŠE",
  "RAUŠI",
  "RAUŠL",
  "RAUŠU",
  "RAUTA",
  "RAUTE",
  "RAUTH",
  "RAUTI",
  "RAUTO",
  "RAUTU",
  "RAVAN",
  "RAVAS",
  "RAVBA",
  "RAVBI",
  "RAVBU",
  "RAVEL",
  "RAVEN",
  "RAVER",
  "RAVID",
  "RAVIH",
  "RAVIL",
  "RAVIN",
  "RAVJA",
  "RAVJI",
  "RAVJU",
  "RAVNA",
  "RAVNE",
  "RAVNI",
  "RAVNO",
  "RAVNU",
  "RAVOM",
  "RAVOV",
  "RAVSA",
  "RAVSE",
  "RAVSI",
  "RAVSU",
  "RAVŠA",
  "RAVŠE",
  "RAVŠI",
  "RAVŠL",
  "RAVŠU",
  "RAZAH",
  "RAZAK",
  "RAZAL",
  "RAZAM",
  "RAZAN",
  "RAZBI",
  "RAZBO",
  "RAZBU",
  "RAZDA",
  "RAZDE",
  "RAZDI",
  "RAZDO",
  "RAZDU",
  "RAZEH",
  "RAZEM",
  "RAZEN",
  "RAZEV",
  "RAZIC",
  "RAZID",
  "RAZIH",
  "RAZIJ",
  "RAZIL",
  "RAZIM",
  "RAZIN",
  "RAZIŠ",
  "RAZIT",
  "RAZIU",
  "RAZIV",
  "RAZJA",
  "RAZJE",
  "RAZJO",
  "RAZJU",
  "RAZLU",
  "RAZMI",
  "RAZNA",
  "RAZNE",
  "RAZNI",
  "RAZNO",
  "RAZOM",
  "RAZOR",
  "RAZOV",
  "RAZPU",
  "RAZRU",
  "RAZŠU",
  "RAZUM",
  "RAZVE",
  "RAZVU",
  "RAZZI",
  "RAŽAH",
  "RAŽAM",
  "RAŽEM",
  "RAŽIN",
  "RAŽMA",
  "RAŽME",
  "RAŽMI",
  "RAŽMU",
  "RAŽUN",
  "RCERA",
  "RCERO",
  "RCERU",
  "RCOTA",
  "RDEČA",
  "RDEČE",
  "RDEČI",
  "RDEČK",
  "RDEČL",
  "RDEČO",
  "RDEČU",
  "RDELA",
  "RDELE",
  "RDELI",
  "RDELO",
  "RDENJ",
  "RDETI",
  "RDIJO",
  "RDIMA",
  "RDIME",
  "RDIMO",
  "RDITA",
  "RDITE",
  "RDIVA",
  "RDIVE",
  "RDIVI",
  "REACH",
  "READA",
  "READE",
  "READI",
  "READU",
  "REALA",
  "REALC",
  "REALE",
  "REALI",
  "REALK",
  "REALU",
  "REAMA",
  "REAME",
  "REAMI",
  "REAMS",
  "REAMU",
  "REATA",
  "REATE",
  "REATH",
  "REATI",
  "REATO",
  "REATU",
  "REBAH",
  "REBAM",
  "REBAR",
  "REBBI",
  "REBCA",
  "REBCE",
  "REBCI",
  "REBCU",
  "REBEC",
  "REBEK",
  "REBEL",
  "REBER",
  "REBIČ",
  "REBIH",
  "REBJA",
  "REBJU",
  "REBOL",
  "REBOM",
  "REBOV",
  "REBRA",
  "REBRC",
  "REBRI",
  "REBRN",
  "REBRO",
  "REBRU",
  "REBUL",
  "REBUS",
  "RECEK",
  "RECEP",
  "RECER",
  "RECHA",
  "RECHE",
  "RECHI",
  "RECHN",
  "RECHU",
  "RECIA",
  "RECIE",
  "RECII",
  "RECIO",
  "RECIU",
  "RECKA",
  "RECKE",
  "RECKI",
  "RECKS",
  "RECKU",
  "REČAN",
  "REČEH",
  "REČEM",
  "REČEN",
  "REČEŠ",
  "REČIC",
  "REČIČ",
  "REČIJ",
  "REČJA",
  "REČJE",
  "REČJI",
  "REČJO",
  "REČJU",
  "REČMI",
  "REČNA",
  "REČNE",
  "REČNI",
  "REČNO",
  "REČTI",
  "REDAH",
  "REDAM",
  "REDAN",
  "REDAR",
  "REDČI",
  "REDČL",
  "REDČU",
  "REDDA",
  "REDDE",
  "REDDI",
  "REDDU",
  "REDEČ",
  "REDEK",
  "REDEL",
  "REDEM",
  "REDEN",
  "REDER",
  "REDEV",
  "REDIH",
  "REDIL",
  "REDIM",
  "REDIŠ",
  "REDIT",
  "REDIU",
  "REDIV",
  "REDJA",
  "REDJU",
  "REDKA",
  "REDKE",
  "REDKI",
  "REDKO",
  "REDKU",
  "REDLA",
  "REDLE",
  "REDLI",
  "REDLU",
  "REDMI",
  "REDNA",
  "REDNE",
  "REDNI",
  "REDNO",
  "REDOM",
  "REDON",
  "REDOV",
  "REDUJ",
  "REDUT",
  "REEBA",
  "REEBE",
  "REEBI",
  "REEBU",
  "REECA",
  "REECE",
  "REEDA",
  "REEDE",
  "REEDI",
  "REEDU",
  "REEFA",
  "REEFE",
  "REEFI",
  "REEFU",
  "REEHA",
  "REEHE",
  "REEHI",
  "REEHU",
  "REEJA",
  "REEJE",
  "REEJI",
  "REEJU",
  "REEMA",
  "REEME",
  "REEMI",
  "REEMO",
  "REEMS",
  "REENT",
  "REEPA",
  "REEPE",
  "REEPI",
  "REEPU",
  "REESA",
  "REESE",
  "REESI",
  "REESU",
  "REETA",
  "REETE",
  "REETI",
  "REETO",
  "REEVA",
  "REEVE",
  "REEVI",
  "REEVO",
  "REEVU",
  "REFET",
  "REFIK",
  "REFIN",
  "REFNA",
  "REFNE",
  "REFNI",
  "REFNU",
  "REGAH",
  "REGAJ",
  "REGAL",
  "REGAM",
  "REGAN",
  "REGAS",
  "REGAŠ",
  "REGAT",
  "REGAU",
  "REGAV",
  "REGEM",
  "REGEN",
  "REGER",
  "REGEV",
  "REGIH",
  "REGIJ",
  "REGIN",
  "REGIS",
  "REGJA",
  "REGJU",
  "REGLI",
  "REGNA",
  "REGNE",
  "REGNI",
  "REGNU",
  "REGOL",
  "REGOV",
  "REGUL",
  "REGUS",
  "REHAK",
  "REHAN",
  "REHAR",
  "REHLA",
  "REHLE",
  "REHLI",
  "REHLU",
  "REHMA",
  "REHME",
  "REHMI",
  "REHMU",
  "REHNA",
  "REHNE",
  "REHNI",
  "REHNS",
  "REHNU",
  "REHOR",
  "REHRL",
  "REICH",
  "REIDA",
  "REIDE",
  "REIDI",
  "REIDL",
  "REIDU",
  "REIFA",
  "REIFE",
  "REIFF",
  "REIFI",
  "REIFU",
  "REIGN",
  "REIHL",
  "REIJN",
  "REIJO",
  "REIKA",
  "REIKE",
  "REIKI",
  "REIKO",
  "REIKU",
  "REILA",
  "REILE",
  "REILI",
  "REILU",
  "REIMA",
  "REIME",
  "REIMI",
  "REIMS",
  "REIMU",
  "REINA",
  "REINE",
  "REINI",
  "REINL",
  "REINO",
  "REINS",
  "REINU",
  "REIRS",
  "REISA",
  "REISE",
  "REISI",
  "REISL",
  "REISP",
  "REISS",
  "REIST",
  "REISU",
  "REISZ",
  "REITA",
  "REITE",
  "REITH",
  "REITI",
  "REITU",
  "REITZ",
  "REIZE",
  "REJAH",
  "REJAK",
  "REJAM",
  "REJCA",
  "REJCE",
  "REJCI",
  "REJCU",
  "REJEC",
  "REJEM",
  "REJEN",
  "REJEV",
  "REJIH",
  "REJIN",
  "REJJA",
  "REJJU",
  "REJKA",
  "REJKE",
  "REJKI",
  "REJKO",
  "REJNA",
  "REJNE",
  "REJNI",
  "REJNO",
  "REJOM",
  "REJON",
  "REJOV",
  "REJVA",
  "REJVE",
  "REJVI",
  "REJVU",
  "REKAH",
  "REKAJ",
  "REKAL",
  "REKAM",
  "REKAR",
  "REKAŠ",
  "REKAT",
  "REKAU",
  "REKAV",
  "REKEL",
  "REKEM",
  "REKER",
  "REKET",
  "REKEU",
  "REKEV",
  "REKHI",
  "REKIČ",
  "REKIH",
  "REKIK",
  "REKIN",
  "REKJA",
  "REKJU",
  "REKLA",
  "REKLE",
  "REKLI",
  "REKLO",
  "REKLU",
  "REKOČ",
  "REKOM",
  "REKOV",
  "REKSA",
  "REKSE",
  "REKSI",
  "REKSU",
  "RELIH",
  "RELIK",
  "RELIN",
  "RELJA",
  "RELJE",
  "RELJI",
  "RELJO",
  "RELJU",
  "RELLI",
  "RELOM",
  "RELOV",
  "RELPH",
  "REMAR",
  "REMBA",
  "REMBE",
  "REMBI",
  "REMBU",
  "REMCA",
  "REMCE",
  "REMCI",
  "REMCO",
  "REMCU",
  "REMEC",
  "REMEK",
  "REMET",
  "REMHA",
  "REMHO",
  "REMHU",
  "REMIC",
  "REMIH",
  "REMIŠ",
  "REMIZ",
  "REMJA",
  "REMJU",
  "REMKA",
  "REMKE",
  "REMKI",
  "REMKO",
  "REMKU",
  "REMMA",
  "REMME",
  "REMMI",
  "REMMO",
  "REMMU",
  "REMOM",
  "REMOS",
  "REMOV",
  "REMSA",
  "REMSE",
  "REMSI",
  "REMSU",
  "REMŠE",
  "REMUS",
  "REMZI",
  "RENAH",
  "RENAM",
  "RENAN",
  "RENAR",
  "RENAT",
  "RENCK",
  "RENČE",
  "RENČI",
  "RENČU",
  "RENDA",
  "RENDE",
  "RENDI",
  "RENDL",
  "RENDO",
  "RENDU",
  "RENEE",
  "RENEJ",
  "RENER",
  "RENFE",
  "RENGA",
  "RENIH",
  "RENIJ",
  "RENIK",
  "RENIN",
  "RENJA",
  "RENJU",
  "RENKA",
  "RENKE",
  "RENKI",
  "RENKO",
  "RENKU",
  "RENNA",
  "RENNE",
  "RENNI",
  "RENNO",
  "RENNU",
  "RENOM",
  "RENON",
  "RENOV",
  "RENSI",
  "RENTA",
  "RENTE",
  "RENTI",
  "RENTO",
  "RENTZ",
  "RENUK",
  "RENUŠ",
  "RENZA",
  "RENZE",
  "RENZI",
  "RENZO",
  "RENZU",
  "REPAH",
  "REPAK",
  "REPAM",
  "REPAN",
  "REPAR",
  "REPAS",
  "REPAT",
  "REPČE",
  "REPEK",
  "REPEM",
  "REPEN",
  "REPER",
  "REPEŠ",
  "REPEV",
  "REPEŽ",
  "REPIC",
  "REPIČ",
  "REPIH",
  "REPIK",
  "REPIN",
  "REPJA",
  "REPJU",
  "REPKA",
  "REPKE",
  "REPKI",
  "REPKU",
  "REPLE",
  "REPNA",
  "REPNE",
  "REPNI",
  "REPNO",
  "REPNU",
  "REPOM",
  "REPOŠ",
  "REPOV",
  "REPPA",
  "REPPE",
  "REPPI",
  "REPPU",
  "REPŠE",
  "REPUH",
  "REPUŠ",
  "RERIK",
  "RERJA",
  "RESAH",
  "RESAM",
  "RESAR",
  "RESAV",
  "RESCH",
  "RESDA",
  "RESED",
  "RESEG",
  "RESEM",
  "RESEN",
  "RESEV",
  "RESHI",
  "RESIC",
  "RESIH",
  "RESIN",
  "RESJA",
  "RESJE",
  "RESJU",
  "RESKA",
  "RESKE",
  "RESKI",
  "RESKU",
  "RESMI",
  "RESNA",
  "RESNE",
  "RESNI",
  "RESNO",
  "RESOM",
  "RESOR",
  "RESOV",
  "RESSA",
  "RESSE",
  "RESSI",
  "RESSO",
  "RESSU",
  "RESTI",
  "RESUL",
  "RESUZ",
  "REŠAD",
  "REŠEK",
  "REŠEM",
  "REŠEN",
  "REŠET",
  "REŠEV",
  "REŠIH",
  "REŠIL",
  "REŠIM",
  "REŠIŠ",
  "REŠIT",
  "REŠIU",
  "REŠIV",
  "REŠJA",
  "REŠJU",
  "REŠKA",
  "REŠKE",
  "REŠKI",
  "REŠKO",
  "REŠKU",
  "REŠOM",
  "REŠOV",
  "REŠUJ",
  "RETAH",
  "RETAM",
  "RETAR",
  "RETBA",
  "RETBE",
  "RETBI",
  "RETBO",
  "RETEČ",
  "RETEL",
  "RETER",
  "RETHI",
  "RETIJ",
  "RETIN",
  "RETJE",
  "RETKA",
  "RETKE",
  "RETKI",
  "RETKO",
  "RETKU",
  "RETLA",
  "RETLE",
  "RETLI",
  "RETLU",
  "RETNO",
  "RETOR",
  "RETRO",
  "RETTA",
  "RETTE",
  "RETTI",
  "RETTU",
  "RETUŠ",
  "REULA",
  "REULE",
  "REULI",
  "REULU",
  "REUMA",
  "REUME",
  "REUMI",
  "REUMU",
  "REUSA",
  "REUSE",
  "REUSI",
  "REUSS",
  "REUSU",
  "REVAH",
  "REVAM",
  "REVAN",
  "REVAR",
  "REVEL",
  "REVEN",
  "REVEŽ",
  "REVIC",
  "REVIJ",
  "REVIR",
  "REVMA",
  "REVME",
  "REVMI",
  "REVMO",
  "REVNA",
  "REVNE",
  "REVNI",
  "REVNO",
  "REVON",
  "REVOZ",
  "REVSK",
  "REVŠE",
  "REZAČ",
  "REZAH",
  "REZAJ",
  "REZAL",
  "REZAM",
  "REZAN",
  "REZAR",
  "REZAT",
  "REZAU",
  "REZAV",
  "REZBA",
  "REZBI",
  "REZBU",
  "REZCA",
  "REZCE",
  "REZCI",
  "REZCU",
  "REZEC",
  "REZED",
  "REZEH",
  "REZEK",
  "REZEM",
  "REZEN",
  "REZEV",
  "REZGA",
  "REZGE",
  "REZGI",
  "REZGU",
  "REZIH",
  "REZIK",
  "REZIL",
  "REZIN",
  "REZJA",
  "REZJO",
  "REZJU",
  "REZKA",
  "REZKE",
  "REZKI",
  "REZKO",
  "REZKU",
  "REZMI",
  "REZNA",
  "REZNE",
  "REZNI",
  "REZNO",
  "REZOM",
  "REZOV",
  "REZUS",
  "REZVA",
  "REZVE",
  "REZVI",
  "REZVO",
  "REZZA",
  "REZZE",
  "REZZI",
  "REZZO",
  "REZZU",
  "REŽAH",
  "REŽAJ",
  "REŽAL",
  "REŽAM",
  "REŽAT",
  "REŽAU",
  "REŽAV",
  "REŽEČ",
  "REŽEK",
  "REŽEM",
  "REŽEN",
  "REŽEŠ",
  "REŽIC",
  "REŽIJ",
  "REŽIM",
  "REŽIŠ",
  "REŽKA",
  "REŽKE",
  "REŽKI",
  "REŽKU",
  "REŽNA",
  "REŽNE",
  "REŽNI",
  "REŽNO",
  "REŽUN",
  "RHADH",
  "RHAMA",
  "RHAME",
  "RHAMI",
  "RHAMU",
  "RHEAH",
  "RHEAM",
  "RHEIN",
  "RHEIS",
  "RHEON",
  "RHETT",
  "RHIAN",
  "RHINA",
  "RHIND",
  "RHINE",
  "RHINI",
  "RHINU",
  "RHODA",
  "RHODE",
  "RHODI",
  "RHODO",
  "RHONA",
  "RHOND",
  "RHONE",
  "RHONI",
  "RHONO",
  "RIADA",
  "RIADU",
  "RIAHI",
  "RIALA",
  "RIALE",
  "RIALI",
  "RIALU",
  "RIAMA",
  "RIAMI",
  "RIANA",
  "RIANE",
  "RIANI",
  "RIANN",
  "RIANO",
  "RIANU",
  "RIATA",
  "RIATE",
  "RIATI",
  "RIATU",
  "RIAZA",
  "RIAZE",
  "RIAZI",
  "RIAZO",
  "RIAZU",
  "RIBAH",
  "RIBAJ",
  "RIBAL",
  "RIBAM",
  "RIBAN",
  "RIBAR",
  "RIBAS",
  "RIBAŠ",
  "RIBAT",
  "RIBAU",
  "RIBAV",
  "RIBBE",
  "RIBBS",
  "RIBČE",
  "RIBER",
  "RIBEZ",
  "RIBIC",
  "RIBIČ",
  "RIBIN",
  "RIBJA",
  "RIBJE",
  "RIBJI",
  "RIBJO",
  "RIBNO",
  "RIBOM",
  "RIBON",
  "RIBOT",
  "RIBOZ",
  "RICCA",
  "RICCE",
  "RICCI",
  "RICCO",
  "RICCU",
  "RICEA",
  "RICEE",
  "RICEI",
  "RICEU",
  "RICHA",
  "RICHE",
  "RICHI",
  "RICHT",
  "RICHU",
  "RICIH",
  "RICIN",
  "RICJA",
  "RICJU",
  "RICKA",
  "RICKE",
  "RICKI",
  "RICKS",
  "RICKU",
  "RICOH",
  "RICOM",
  "RICOV",
  "RIČEK",
  "RIČEM",
  "RIČEŠ",
  "RIČET",
  "RIČKA",
  "RIČKE",
  "RIČKI",
  "RIČKO",
  "RIČKU",
  "RIČOT",
  "RIDAH",
  "RIDAM",
  "RIDER",
  "RIDGA",
  "RIDGE",
  "RIDIH",
  "RIDJA",
  "RIDJU",
  "RIDLA",
  "RIDLE",
  "RIDLI",
  "RIDLU",
  "RIDOM",
  "RIDOV",
  "RIDZI",
  "RIEBL",
  "RIECK",
  "RIEDA",
  "RIEDE",
  "RIEDI",
  "RIEDL",
  "RIEDO",
  "RIEDU",
  "RIEFA",
  "RIEFE",
  "RIEFF",
  "RIEFI",
  "RIEFU",
  "RIEHL",
  "RIEHM",
  "RIEHS",
  "RIEJU",
  "RIEKE",
  "RIELA",
  "RIELE",
  "RIELI",
  "RIELU",
  "RIEMM",
  "RIENA",
  "RIENE",
  "RIENI",
  "RIENU",
  "RIEPL",
  "RIERA",
  "RIERE",
  "RIERI",
  "RIERO",
  "RIERU",
  "RIESA",
  "RIESE",
  "RIESI",
  "RIESS",
  "RIESU",
  "RIETH",
  "RIFAT",
  "RIFEL",
  "RIFLA",
  "RIFLE",
  "RIFLI",
  "RIFLU",
  "RIGAČ",
  "RIGAJ",
  "RIGAL",
  "RIGAM",
  "RIGAŠ",
  "RIGAT",
  "RIGAU",
  "RIGAV",
  "RIGCA",
  "RIGCE",
  "RIGCI",
  "RIGCU",
  "RIGEC",
  "RIGEL",
  "RIGEM",
  "RIGEV",
  "RIGGA",
  "RIGGE",
  "RIGGI",
  "RIGGS",
  "RIGGU",
  "RIGHI",
  "RIGIH",
  "RIGJA",
  "RIGJU",
  "RIGLA",
  "RIGLE",
  "RIGLI",
  "RIGLU",
  "RIGNE",
  "RIGNI",
  "RIGNU",
  "RIGOM",
  "RIGOV",
  "RIHAH",
  "RIHAM",
  "RIHAN",
  "RIHAR",
  "RIHIH",
  "RIHJA",
  "RIHJU",
  "RIHMA",
  "RIHME",
  "RIHMI",
  "RIHMU",
  "RIHOM",
  "RIHOV",
  "RIHTA",
  "RIHTE",
  "RIHTI",
  "RIHTO",
  "RIHTU",
  "RIIKK",
  "RIINA",
  "RIINE",
  "RIINI",
  "RIINO",
  "RIINU",
  "RIISA",
  "RIISE",
  "RIISI",
  "RIISU",
  "RIITT",
  "RIJAD",
  "RIJEM",
  "RIJEŠ",
  "RIJIN",
  "RIJKE",
  "RIJMO",
  "RIJNA",
  "RIJNE",
  "RIJNI",
  "RIJNU",
  "RIJOČ",
  "RIJTA",
  "RIJTE",
  "RIJVA",
  "RIKAH",
  "RIKAJ",
  "RIKAL",
  "RIKAM",
  "RIKAŠ",
  "RIKAT",
  "RIKAU",
  "RIKAV",
  "RIKEE",
  "RIKER",
  "RIKIH",
  "RIKIN",
  "RIKJA",
  "RIKJU",
  "RIKKE",
  "RIKKI",
  "RIKLI",
  "RIKOM",
  "RIKOT",
  "RIKOV",
  "RIKŠA",
  "RIKŠE",
  "RIKŠI",
  "RIKŠO",
  "RILAK",
  "RILAN",
  "RILCA",
  "RILCE",
  "RILCI",
  "RILCU",
  "RILEC",
  "RILKE",
  "RILLA",
  "RILLE",
  "RILLI",
  "RILLO",
  "RILLU",
  "RILOV",
  "RILSK",
  "RIMAC",
  "RIMAČ",
  "RIMAH",
  "RIMAJ",
  "RIMAL",
  "RIMAM",
  "RIMAN",
  "RIMAR",
  "RIMAŠ",
  "RIMAT",
  "RIMAU",
  "RIMAV",
  "RIMCA",
  "RIMCE",
  "RIMCI",
  "RIMCU",
  "RIMER",
  "RIMES",
  "RIMET",
  "RIMIN",
  "RIMJA",
  "RIMJU",
  "RIMLA",
  "RIMLE",
  "RIMLI",
  "RIMLU",
  "RIMOM",
  "RINAB",
  "RINAH",
  "RINAM",
  "RINAT",
  "RINCA",
  "RINCE",
  "RINCI",
  "RINCK",
  "RINCU",
  "RINDA",
  "RINDE",
  "RINDI",
  "RINDT",
  "RINDU",
  "RINEČ",
  "RINEM",
  "RINEŠ",
  "RINEŽ",
  "RINGA",
  "RINGE",
  "RINGI",
  "RINGL",
  "RINGO",
  "RINGS",
  "RINGU",
  "RINIH",
  "RINIL",
  "RININ",
  "RINIT",
  "RINIU",
  "RINIV",
  "RINJA",
  "RINJU",
  "RINKA",
  "RINKE",
  "RINKI",
  "RINKO",
  "RINKU",
  "RINNA",
  "RINNE",
  "RINNI",
  "RINNU",
  "RINOM",
  "RINOR",
  "RINOV",
  "RINSA",
  "RINSE",
  "RINSI",
  "RINSU",
  "RINŽA",
  "RINŽE",
  "RINŽI",
  "RINŽO",
  "RIOCH",
  "RIOJU",
  "RIOLA",
  "RIOLE",
  "RIOLI",
  "RIOLO",
  "RIOLU",
  "RIOMA",
  "RIOMU",
  "RIONA",
  "RIONE",
  "RIONI",
  "RIONU",
  "RIOSA",
  "RIOSE",
  "RIOSI",
  "RIOSS",
  "RIOSU",
  "RIOTA",
  "RIOTT",
  "RIOTU",
  "RIOZA",
  "RIOZE",
  "RIOZI",
  "RIOZU",
  "RIPEČ",
  "RIPPA",
  "RIPPE",
  "RIPPI",
  "RIPPL",
  "RIPPO",
  "RIPPS",
  "RIPPU",
  "RISAH",
  "RISAL",
  "RISAM",
  "RISAN",
  "RISAR",
  "RISAT",
  "RISAU",
  "RISAV",
  "RISBA",
  "RISBE",
  "RISBI",
  "RISBO",
  "RISCH",
  "RISEM",
  "RISEV",
  "RISHI",
  "RISIH",
  "RISIN",
  "RISJA",
  "RISJE",
  "RISJI",
  "RISJO",
  "RISJU",
  "RISKA",
  "RISKE",
  "RISKI",
  "RISKU",
  "RISOM",
  "RISOV",
  "RISSA",
  "RISSE",
  "RISSI",
  "RISSO",
  "RISSU",
  "RISTA",
  "RISTE",
  "RISTI",
  "RISTL",
  "RISTO",
  "RISTU",
  "RIŠEM",
  "RIŠEŠ",
  "RIŠKA",
  "RIŠKE",
  "RIŠKI",
  "RIŠKO",
  "RITAH",
  "RITAJ",
  "RITAL",
  "RITAM",
  "RITAŠ",
  "RITAT",
  "RITAU",
  "RITAV",
  "RITEM",
  "RITEN",
  "RITGA",
  "RITIH",
  "RITIJ",
  "RITIM",
  "RITIN",
  "RITJA",
  "RITJE",
  "RITJI",
  "RITJO",
  "RITJU",
  "RITKA",
  "RITKE",
  "RITKI",
  "RITKO",
  "RITMA",
  "RITME",
  "RITMI",
  "RITMU",
  "RITNA",
  "RITNE",
  "RITNI",
  "RITNO",
  "RITNU",
  "RITOM",
  "RITOŠ",
  "RITOV",
  "RITTA",
  "RITTE",
  "RITTI",
  "RITTO",
  "RITTS",
  "RITTU",
  "RITUS",
  "RITZA",
  "RITZE",
  "RITZI",
  "RITZU",
  "RIVAH",
  "RIVAL",
  "RIVAM",
  "RIVAS",
  "RIVER",
  "RIVES",
  "RIVIN",
  "RIVKA",
  "RIVKE",
  "RIVKI",
  "RIVKO",
  "RIZAH",
  "RIZAJ",
  "RIZAM",
  "RIZBA",
  "RIZBE",
  "RIZBI",
  "RIZBO",
  "RIZEL",
  "RIZEM",
  "RIZIH",
  "RIZIK",
  "RIZJA",
  "RIZJU",
  "RIZKA",
  "RIZKE",
  "RIZKI",
  "RIZKU",
  "RIZLA",
  "RIZLE",
  "RIZLI",
  "RIZLO",
  "RIZMA",
  "RIZME",
  "RIZMI",
  "RIZMO",
  "RIZOM",
  "RIZOV",
  "RIZZA",
  "RIZZE",
  "RIZZI",
  "RIZZO",
  "RIZZU",
  "RIŽAH",
  "RIŽAM",
  "RIŽAN",
  "RIŽEK",
  "RIŽEM",
  "RIŽEN",
  "RIŽEV",
  "RIŽIN",
  "RIŽJA",
  "RIŽJU",
  "RIŽKA",
  "RIŽKE",
  "RIŽKI",
  "RIŽKU",
  "RIŽNA",
  "RIŽNE",
  "RIŽNI",
  "RIŽNO",
  "RIŽOM",
  "RIŽOT",
  "RIŽOV",
  "RJAMA",
  "RJAMI",
  "RJAST",
  "RJAVA",
  "RJAVC",
  "RJAVČ",
  "RJAVE",
  "RJAVI",
  "RJAVK",
  "RJAVO",
  "RJAVU",
  "RJOJU",
  "RJOVE",
  "RJOVI",
  "RJOVU",
  "RJUHA",
  "RJUHE",
  "RJUHI",
  "RJUHO",
  "RJUJE",
  "RJULA",
  "RJULE",
  "RJULI",
  "RJULO",
  "RJUTA",
  "RJUTE",
  "RJUTI",
  "RJUTO",
  "RKMAN",
  "RMANA",
  "RMANC",
  "RMANE",
  "RMANI",
  "RMANU",
  "RMUŠA",
  "RMUŠE",
  "RMUŠI",
  "RMUŠU",
  "ROACH",
  "ROALD",
  "ROANA",
  "ROANE",
  "ROANI",
  "ROANN",
  "ROANU",
  "ROARK",
  "ROATT",
  "ROBAČ",
  "ROBAK",
  "ROBAN",
  "ROBAR",
  "ROBAS",
  "ROBAT",
  "ROBBA",
  "ROBBE",
  "ROBBI",
  "ROBBO",
  "ROBBU",
  "ROBCA",
  "ROBCE",
  "ROBCI",
  "ROBCU",
  "ROBEC",
  "ROBEČ",
  "ROBEK",
  "ROBEM",
  "ROBEN",
  "ROBER",
  "ROBEV",
  "ROBIČ",
  "ROBID",
  "ROBIE",
  "ROBIH",
  "ROBIL",
  "ROBIM",
  "ROBIN",
  "ROBIŠ",
  "ROBIT",
  "ROBIU",
  "ROBIV",
  "ROBJA",
  "ROBJU",
  "ROBKA",
  "ROBKE",
  "ROBKI",
  "ROBKU",
  "ROBNA",
  "ROBNE",
  "ROBNI",
  "ROBNO",
  "ROBOM",
  "ROBOT",
  "ROBOV",
  "ROCAH",
  "ROCAM",
  "ROCCA",
  "ROCCE",
  "ROCCI",
  "ROCCO",
  "ROCCU",
  "ROCHA",
  "ROCHE",
  "ROCHI",
  "ROCHO",
  "ROCHU",
  "ROCIH",
  "ROCIO",
  "ROCJA",
  "ROCJU",
  "ROCKA",
  "ROCKE",
  "ROCKI",
  "ROCKU",
  "ROCOL",
  "ROCOM",
  "ROCOV",
  "ROČAH",
  "ROČAJ",
  "ROČAK",
  "ROČAM",
  "ROČAN",
  "ROČEK",
  "ROČEM",
  "ROČEN",
  "ROČEV",
  "ROČIC",
  "ROČIH",
  "ROČJA",
  "ROČJU",
  "ROČKA",
  "ROČKE",
  "ROČKI",
  "ROČKO",
  "ROČKU",
  "ROČNA",
  "ROČNE",
  "ROČNI",
  "ROČNO",
  "ROČOM",
  "ROČOV",
  "RODAK",
  "RODAL",
  "RODAN",
  "RODAS",
  "RODDA",
  "RODDE",
  "RODDI",
  "RODDU",
  "RODEA",
  "RODEČ",
  "RODEE",
  "RODEI",
  "RODEL",
  "RODEM",
  "RODEN",
  "RODEO",
  "RODEŠ",
  "RODEU",
  "RODEV",
  "RODEŽ",
  "RODIČ",
  "RODIH",
  "RODIJ",
  "RODIK",
  "RODIL",
  "RODIM",
  "RODIN",
  "RODIS",
  "RODIŠ",
  "RODIT",
  "RODIU",
  "RODIV",
  "RODJA",
  "RODJE",
  "RODJI",
  "RODJO",
  "RODJU",
  "RODNA",
  "RODNE",
  "RODNI",
  "RODNO",
  "RODOM",
  "RODON",
  "RODOS",
  "RODOV",
  "RODRI",
  "ROEBL",
  "ROECK",
  "ROEDL",
  "ROEGA",
  "ROEGE",
  "ROEGI",
  "ROEGU",
  "ROEHL",
  "ROEHM",
  "ROEJA",
  "ROEJE",
  "ROEJI",
  "ROEJU",
  "ROELA",
  "ROELE",
  "ROELI",
  "ROELU",
  "ROESE",
  "ROESK",
  "ROETT",
  "ROFFA",
  "ROFFE",
  "ROFFI",
  "ROFFU",
  "ROGAČ",
  "ROGAJ",
  "ROGAL",
  "ROGAM",
  "ROGAN",
  "ROGAR",
  "ROGAŠ",
  "ROGAT",
  "ROGAU",
  "ROGAV",
  "ROGEH",
  "ROGEL",
  "ROGEM",
  "ROGEN",
  "ROGER",
  "ROGEV",
  "ROGGE",
  "ROGIČ",
  "ROGIH",
  "ROGIN",
  "ROGJA",
  "ROGJU",
  "ROGLA",
  "ROGLE",
  "ROGLI",
  "ROGLO",
  "ROGLU",
  "ROGMI",
  "ROGNA",
  "ROGNE",
  "ROGNI",
  "ROGNU",
  "ROGOM",
  "ROGOR",
  "ROGOV",
  "ROGOZ",
  "ROHAL",
  "ROHAN",
  "ROHDE",
  "ROHIH",
  "ROHIT",
  "ROHJA",
  "ROHJU",
  "ROHLA",
  "ROHLE",
  "ROHLI",
  "ROHLU",
  "ROHMA",
  "ROHME",
  "ROHMI",
  "ROHMU",
  "ROHNA",
  "ROHNE",
  "ROHNI",
  "ROHNU",
  "ROHOM",
  "ROHOV",
  "ROIGA",
  "ROIGE",
  "ROIGI",
  "ROIGU",
  "ROIJO",
  "ROISS",
  "ROJAC",
  "ROJAH",
  "ROJAK",
  "ROJAM",
  "ROJAN",
  "ROJAS",
  "ROJCA",
  "ROJCE",
  "ROJCI",
  "ROJCU",
  "ROJDA",
  "ROJDE",
  "ROJDI",
  "ROJDO",
  "ROJEC",
  "ROJEČ",
  "ROJEM",
  "ROJEN",
  "ROJEV",
  "ROJIC",
  "ROJIH",
  "ROJIL",
  "ROJIM",
  "ROJIN",
  "ROJIŠ",
  "ROJIT",
  "ROJIU",
  "ROJIV",
  "ROJJA",
  "ROJJU",
  "ROJKA",
  "ROJKE",
  "ROJKI",
  "ROJKO",
  "ROJKU",
  "ROJOM",
  "ROJOV",
  "ROJSA",
  "ROJSE",
  "ROJSI",
  "ROJSU",
  "ROKAD",
  "ROKAH",
  "ROKAM",
  "ROKAS",
  "ROKAV",
  "ROKEM",
  "ROKER",
  "ROKEV",
  "ROKIC",
  "ROKIH",
  "ROKIT",
  "ROKJA",
  "ROKJU",
  "ROKOM",
  "ROKOS",
  "ROKOV",
  "ROKUJ",
  "ROKUS",
  "ROLAD",
  "ROLAH",
  "ROLAJ",
  "ROLAL",
  "ROLAM",
  "ROLAR",
  "ROLAŠ",
  "ROLAT",
  "ROLAU",
  "ROLAV",
  "ROLER",
  "ROLET",
  "ROLFA",
  "ROLFE",
  "ROLFI",
  "ROLFU",
  "ROLIC",
  "ROLIČ",
  "ROLIE",
  "ROLIH",
  "ROLIN",
  "ROLJA",
  "ROLJU",
  "ROLKA",
  "ROLKE",
  "ROLKI",
  "ROLKO",
  "ROLKU",
  "ROLLA",
  "ROLLE",
  "ROLLI",
  "ROLLO",
  "ROLLS",
  "ROLLU",
  "ROLOM",
  "ROLOV",
  "ROLTA",
  "ROLTE",
  "ROLTI",
  "ROLTU",
  "ROMAA",
  "ROMAE",
  "ROMAH",
  "ROMAI",
  "ROMAJ",
  "ROMAL",
  "ROMAM",
  "ROMAN",
  "ROMAO",
  "ROMAR",
  "ROMAŠ",
  "ROMAT",
  "ROMAU",
  "ROMAV",
  "ROMBA",
  "ROMBE",
  "ROMBI",
  "ROMBU",
  "ROMEA",
  "ROMED",
  "ROMEE",
  "ROMEI",
  "ROMEM",
  "ROMEO",
  "ROMER",
  "ROMEU",
  "ROMEV",
  "ROMIČ",
  "ROMIG",
  "ROMIH",
  "ROMIN",
  "ROMJA",
  "ROMJU",
  "ROMKA",
  "ROMKE",
  "ROMKI",
  "ROMKO",
  "ROMMA",
  "ROMME",
  "ROMMI",
  "ROMMU",
  "ROMOL",
  "ROMOM",
  "ROMOV",
  "ROMUL",
  "ROMUN",
  "RONAH",
  "RONAK",
  "RONAM",
  "RONAN",
  "RONDA",
  "RONDE",
  "RONDI",
  "RONDO",
  "RONDU",
  "RONEK",
  "RONEL",
  "RONEN",
  "RONER",
  "RONET",
  "RONIH",
  "RONIN",
  "RONIS",
  "RONJA",
  "RONJE",
  "RONJI",
  "RONJO",
  "RONJU",
  "RONKA",
  "RONKE",
  "RONKI",
  "RONKO",
  "RONKU",
  "RONNA",
  "RONNE",
  "RONNI",
  "RONNO",
  "RONNU",
  "RONOM",
  "RONOV",
  "ROODA",
  "ROODE",
  "ROODI",
  "ROODT",
  "ROODU",
  "ROOFA",
  "ROOFE",
  "ROOFI",
  "ROOFU",
  "ROOKA",
  "ROOKE",
  "ROOKI",
  "ROOKS",
  "ROOKU",
  "ROOMA",
  "ROOME",
  "ROOMI",
  "ROOMU",
  "ROONA",
  "ROONE",
  "ROONI",
  "ROONU",
  "ROOPA",
  "ROOPE",
  "ROOPI",
  "ROOPU",
  "ROOSA",
  "ROOSE",
  "ROOSI",
  "ROOSO",
  "ROOSS",
  "ROOSU",
  "ROOTA",
  "ROOTE",
  "ROOTI",
  "ROOTU",
  "ROPAC",
  "ROPAČ",
  "ROPAJ",
  "ROPAL",
  "ROPAM",
  "ROPAR",
  "ROPAS",
  "ROPAŠ",
  "ROPAT",
  "ROPAU",
  "ROPAV",
  "ROPEM",
  "ROPER",
  "ROPEV",
  "ROPIČ",
  "ROPIH",
  "ROPJA",
  "ROPJU",
  "ROPOM",
  "ROPOŠ",
  "ROPOT",
  "ROPOV",
  "ROPPA",
  "ROPPE",
  "ROPPI",
  "ROPPU",
  "ROPSA",
  "ROPSE",
  "ROPSI",
  "ROPSU",
  "ROREM",
  "ROREV",
  "RORIČ",
  "RORIE",
  "RORIH",
  "RORJA",
  "RORJU",
  "RORKA",
  "RORKE",
  "RORKI",
  "RORKU",
  "ROROM",
  "ROROV",
  "ROSAH",
  "ROSAL",
  "ROSAM",
  "ROSAN",
  "ROSAR",
  "ROSAS",
  "ROSCA",
  "ROSCE",
  "ROSCI",
  "ROSCU",
  "ROSEC",
  "ROSEČ",
  "ROSEN",
  "ROSER",
  "ROSES",
  "ROSHI",
  "ROSHN",
  "ROSIC",
  "ROSIČ",
  "ROSIE",
  "ROSIH",
  "ROSIK",
  "ROSIL",
  "ROSIM",
  "ROSIN",
  "ROSIŠ",
  "ROSIT",
  "ROSIU",
  "ROSIV",
  "ROSJA",
  "ROSJU",
  "ROSNA",
  "ROSNE",
  "ROSNI",
  "ROSNO",
  "ROSNU",
  "ROSOL",
  "ROSOM",
  "ROSOV",
  "ROSPI",
  "ROSSA",
  "ROSSE",
  "ROSSI",
  "ROSSO",
  "ROSSU",
  "ROSTA",
  "ROSTE",
  "ROSTI",
  "ROSTU",
  "ROŠAD",
  "ROŠAN",
  "ROŠAR",
  "ROŠEM",
  "ROŠER",
  "ROŠEV",
  "ROŠIČ",
  "ROŠIH",
  "ROŠJA",
  "ROŠJU",
  "ROŠKA",
  "ROŠKE",
  "ROŠKI",
  "ROŠKO",
  "ROŠOM",
  "ROŠOV",
  "ROTAH",
  "ROTAM",
  "ROTAR",
  "ROTEČ",
  "ROTEL",
  "ROTER",
  "ROTHA",
  "ROTHE",
  "ROTHI",
  "ROTHU",
  "ROTIH",
  "ROTIJ",
  "ROTIL",
  "ROTIM",
  "ROTIS",
  "ROTIŠ",
  "ROTIT",
  "ROTIU",
  "ROTIV",
  "ROTJA",
  "ROTJU",
  "ROTOM",
  "ROTOR",
  "ROTOV",
  "ROTTA",
  "ROTTE",
  "ROTTI",
  "ROTTU",
  "ROUCH",
  "ROUDI",
  "ROUEN",
  "ROUGA",
  "ROUGE",
  "ROUND",
  "ROURA",
  "ROURE",
  "ROURI",
  "ROURU",
  "ROUSA",
  "ROUSE",
  "ROUSI",
  "ROUSU",
  "ROUTH",
  "ROUVA",
  "ROUVE",
  "ROUVI",
  "ROUVU",
  "ROVAČ",
  "ROVAH",
  "ROVAM",
  "ROVAN",
  "ROVAR",
  "ROVAS",
  "ROVAŠ",
  "ROVED",
  "ROVEM",
  "ROVEN",
  "ROVER",
  "ROVEV",
  "ROVIA",
  "ROVIH",
  "ROVIO",
  "ROVIR",
  "ROVIU",
  "ROVJA",
  "ROVJU",
  "ROVKA",
  "ROVKE",
  "ROVKI",
  "ROVKO",
  "ROVNA",
  "ROVNE",
  "ROVNI",
  "ROVNO",
  "ROVOM",
  "ROVOV",
  "ROVTA",
  "ROVTE",
  "ROVTI",
  "ROVTU",
  "ROVUS",
  "ROZAF",
  "ROZAH",
  "ROZAL",
  "ROZAM",
  "ROZAN",
  "ROZET",
  "ROZGA",
  "ROZGE",
  "ROZGI",
  "ROZGO",
  "ROZGU",
  "ROZIK",
  "ROZIN",
  "ROZKA",
  "ROZKE",
  "ROZKI",
  "ROZKO",
  "ROZON",
  "ROZZI",
  "ROŽAC",
  "ROŽAH",
  "ROŽAJ",
  "ROŽAM",
  "ROŽAN",
  "ROŽAR",
  "ROŽEJ",
  "ROŽEK",
  "ROŽEM",
  "ROŽEN",
  "ROŽIC",
  "ROŽIČ",
  "ROŽIN",
  "ROŽJA",
  "ROŽJE",
  "ROŽJU",
  "ROŽKA",
  "ROŽKE",
  "ROŽKI",
  "ROŽKO",
  "ROŽLE",
  "ROŽNA",
  "ROŽNE",
  "ROŽNI",
  "ROŽNO",
  "ROŽOM",
  "RRAPA",
  "RRAPE",
  "RRAPI",
  "RRAPO",
  "RRAPU",
  "RRELI",
  "RREZE",
  "RROKA",
  "RROKE",
  "RROKI",
  "RROKU",
  "RRONA",
  "RRONE",
  "RRONI",
  "RRONU",
  "RSOTA",
  "RTAST",
  "RTIČA",
  "RTIČE",
  "RTIČI",
  "RTIČU",
  "RTOMA",
  "RTOTA",
  "RTOTU",
  "RUANO",
  "RUARD",
  "RUASA",
  "RUASE",
  "RUASI",
  "RUASU",
  "RUBBI",
  "RUBEČ",
  "RUBEN",
  "RUBEŠ",
  "RUBEŽ",
  "RUBIA",
  "RUBIE",
  "RUBII",
  "RUBIJ",
  "RUBIK",
  "RUBIL",
  "RUBIM",
  "RUBIN",
  "RUBIO",
  "RUBIŠ",
  "RUBIT",
  "RUBIU",
  "RUBIV",
  "RUCCA",
  "RUCCE",
  "RUCCI",
  "RUCCO",
  "RUCCU",
  "RUCKA",
  "RUCKE",
  "RUCKI",
  "RUCKU",
  "RUCLI",
  "RUČAN",
  "RUČEN",
  "RUČNA",
  "RUČNE",
  "RUČNI",
  "RUČNO",
  "RUČNU",
  "RUDAH",
  "RUDAJ",
  "RUDAM",
  "RUDAN",
  "RUDAR",
  "RUDAS",
  "RUDAŠ",
  "RUDDA",
  "RUDDE",
  "RUDDI",
  "RUDDU",
  "RUDEL",
  "RUDEN",
  "RUDEŠ",
  "RUDEŽ",
  "RUDGA",
  "RUDGE",
  "RUDIC",
  "RUDIE",
  "RUDIH",
  "RUDIN",
  "RUDIS",
  "RUDJA",
  "RUDJU",
  "RUDLA",
  "RUDLE",
  "RUDLI",
  "RUDLU",
  "RUDNA",
  "RUDNE",
  "RUDNI",
  "RUDNO",
  "RUDNU",
  "RUDOM",
  "RUDOV",
  "RUEDA",
  "RUEDE",
  "RUEDI",
  "RUEDL",
  "RUEDO",
  "RUEDU",
  "RUEFF",
  "RUEHE",
  "RUEHL",
  "RUEHM",
  "RUELL",
  "RUESA",
  "RUESE",
  "RUESI",
  "RUESU",
  "RUFER",
  "RUFFA",
  "RUFFE",
  "RUFFI",
  "RUFFO",
  "RUFFU",
  "RUFIH",
  "RUFIN",
  "RUFJA",
  "RUFJU",
  "RUFOM",
  "RUFOV",
  "RUFUS",
  "RUGEL",
  "RUGGA",
  "RUGGE",
  "RUGGI",
  "RUGGS",
  "RUGGU",
  "RUGLA",
  "RUGLE",
  "RUGLI",
  "RUGLU",
  "RUGOV",
  "RUHLA",
  "RUHLE",
  "RUHLI",
  "RUHLU",
  "RUHMA",
  "RUHME",
  "RUHMI",
  "RUHMU",
  "RUHNA",
  "RUHNE",
  "RUHNI",
  "RUHNO",
  "RUHRI",
  "RUHSA",
  "RUHSE",
  "RUHSI",
  "RUHSU",
  "RUICK",
  "RUIJA",
  "RUIJE",
  "RUIJI",
  "RUIJU",
  "RUINI",
  "RUIST",
  "RUIZA",
  "RUIZE",
  "RUIZI",
  "RUIZU",
  "RUJEM",
  "RUJEN",
  "RUJEŠ",
  "RUJEV",
  "RUJIH",
  "RUJIN",
  "RUJJA",
  "RUJJU",
  "RUJMO",
  "RUJNA",
  "RUJNE",
  "RUJNI",
  "RUJNO",
  "RUJOM",
  "RUJOV",
  "RUJTA",
  "RUJTE",
  "RUJVA",
  "RUKAH",
  "RUKAJ",
  "RUKAL",
  "RUKAM",
  "RUKAN",
  "RUKAŠ",
  "RUKAT",
  "RUKAU",
  "RUKAV",
  "RUKEM",
  "RUKER",
  "RUKEV",
  "RUKIH",
  "RUKIN",
  "RUKJA",
  "RUKJU",
  "RUKNE",
  "RUKNI",
  "RUKNU",
  "RUKOM",
  "RUKOV",
  "RULAD",
  "RULER",
  "RULET",
  "RULEV",
  "RULFA",
  "RULFE",
  "RULFI",
  "RULFO",
  "RULFU",
  "RULIH",
  "RULJA",
  "RULJU",
  "RULLA",
  "RULLE",
  "RULLI",
  "RULLO",
  "RULLU",
  "RULOM",
  "RULON",
  "RULOV",
  "RUMAC",
  "RUMAN",
  "RUMBA",
  "RUMBE",
  "RUMBI",
  "RUMBO",
  "RUMCA",
  "RUMCE",
  "RUMCI",
  "RUMCU",
  "RUMEM",
  "RUMEN",
  "RUMER",
  "RUMEV",
  "RUMEŽ",
  "RUMIH",
  "RUMIN",
  "RUMIZ",
  "RUMJA",
  "RUMJU",
  "RUMOM",
  "RUMOR",
  "RUMOV",
  "RUMPA",
  "RUMPE",
  "RUMPF",
  "RUMPI",
  "RUMPL",
  "RUMPU",
  "RUNAH",
  "RUNAM",
  "RUNAR",
  "RUNČA",
  "RUNČU",
  "RUNDA",
  "RUNDE",
  "RUNDI",
  "RUNDO",
  "RUNEČ",
  "RUNGE",
  "RUNGI",
  "RUNIH",
  "RUNJA",
  "RUNJE",
  "RUNJU",
  "RUNKE",
  "RUNOM",
  "RUNOV",
  "RUOFF",
  "RUOMA",
  "RUOPP",
  "RUOSS",
  "RUOTA",
  "RUOTU",
  "RUOVA",
  "RUOVE",
  "RUOVI",
  "RUOVO",
  "RUPAH",
  "RUPAM",
  "RUPAN",
  "RUPAR",
  "RUPEL",
  "RUPFA",
  "RUPFE",
  "RUPFI",
  "RUPFU",
  "RUPIH",
  "RUPIJ",
  "RUPIL",
  "RUPIN",
  "RUPJA",
  "RUPJU",
  "RUPLA",
  "RUPLE",
  "RUPLI",
  "RUPLU",
  "RUPOM",
  "RUPOV",
  "RUPPA",
  "RUPPE",
  "RUPPI",
  "RUPPU",
  "RURAK",
  "RUSAK",
  "RUSAL",
  "RUSAN",
  "RUSCH",
  "RUSDI",
  "RUSEČ",
  "RUSEM",
  "RUSEN",
  "RUSEV",
  "RUSGA",
  "RUSHA",
  "RUSHE",
  "RUSHI",
  "RUSHU",
  "RUSIČ",
  "RUSIH",
  "RUSIL",
  "RUSIM",
  "RUSIN",
  "RUSIS",
  "RUSIŠ",
  "RUSIT",
  "RUSIU",
  "RUSIV",
  "RUSJA",
  "RUSJU",
  "RUSKA",
  "RUSKE",
  "RUSKI",
  "RUSKO",
  "RUSKU",
  "RUSOM",
  "RUSOV",
  "RUSSA",
  "RUSSE",
  "RUSSI",
  "RUSSO",
  "RUSSU",
  "RUSTA",
  "RUSTE",
  "RUSTI",
  "RUSTU",
  "RUŠAH",
  "RUŠAM",
  "RUŠAN",
  "RUŠEČ",
  "RUŠEN",
  "RUŠIL",
  "RUŠIM",
  "RUŠIN",
  "RUŠIŠ",
  "RUŠIT",
  "RUŠIU",
  "RUŠIV",
  "RUŠJA",
  "RUŠJE",
  "RUŠJU",
  "RUŠKA",
  "RUŠKE",
  "RUŠKI",
  "RUŠKO",
  "RUŠNA",
  "RUŠNE",
  "RUŠNI",
  "RUŠNO",
  "RUŠTA",
  "RUŠTE",
  "RUŠTI",
  "RUŠTU",
  "RUTAH",
  "RUTAM",
  "RUTAN",
  "RUTAR",
  "RUTEN",
  "RUTER",
  "RUTHA",
  "RUTHE",
  "RUTHI",
  "RUTHU",
  "RUTIC",
  "RUTIH",
  "RUTIN",
  "RUTJA",
  "RUTJU",
  "RUTKA",
  "RUTKE",
  "RUTKI",
  "RUTKO",
  "RUTNA",
  "RUTNE",
  "RUTNI",
  "RUTNO",
  "RUTOM",
  "RUTOV",
  "RUTTA",
  "RUTTE",
  "RUTTI",
  "RUTTO",
  "RUTTU",
  "RUTZA",
  "RUTZE",
  "RUTZI",
  "RUTZU",
  "RUUDA",
  "RUUDE",
  "RUUDI",
  "RUUDU",
  "RUUTH",
  "RUUTU",
  "RUVAČ",
  "RUVAL",
  "RUVAM",
  "RUVAŠ",
  "RUVAT",
  "RUVAU",
  "RUVAV",
  "RUZAK",
  "RUZIH",
  "RUZJA",
  "RUZJU",
  "RUZOM",
  "RUZOV",
  "RUŽAH",
  "RUŽAM",
  "RUŽIC",
  "RUŽIČ",
  "RUŽIN",
  "RVAČA",
  "RVAČE",
  "RVAČI",
  "RVAČK",
  "RVAČU",
  "RVALA",
  "RVALE",
  "RVALI",
  "RVALO",
  "RVATI",
  "RZEPK",
  "RŽENA",
  "RŽENE",
  "RŽENI",
  "RŽENO",
  "RŽIJO",
  "RŽIŠČ",
  "RŽIŠE",
  "RŽKIH",
  "RŽKJA",
  "RŽKJU",
  "RŽKOM",
  "RŽKOV",
  "RŽULJ",
  "SAABA",
  "SAABE",
  "SAABI",
  "SAABU",
  "SAADA",
  "SAADE",
  "SAADI",
  "SAADO",
  "SAADU",
  "SAAKA",
  "SAAKE",
  "SAAKI",
  "SAAKO",
  "SAAKU",
  "SAALA",
  "SAALE",
  "SAALI",
  "SAALU",
  "SAARA",
  "SAARE",
  "SAARI",
  "SAARO",
  "SAASH",
  "SABAH",
  "SABAM",
  "SABAN",
  "SABAR",
  "SABAT",
  "SABEL",
  "SABER",
  "SABIA",
  "SABIE",
  "SABIH",
  "SABII",
  "SABIN",
  "SABIO",
  "SABIR",
  "SABIT",
  "SABIU",
  "SABJA",
  "SABJU",
  "SABOL",
  "SABOM",
  "SABOR",
  "SABOV",
  "SABRA",
  "SABRE",
  "SABRI",
  "SABRO",
  "SABRU",
  "SACCA",
  "SACCE",
  "SACCI",
  "SACCO",
  "SACCU",
  "SACHA",
  "SACHE",
  "SACHI",
  "SACHO",
  "SACHS",
  "SACHU",
  "SACKA",
  "SACKE",
  "SACKI",
  "SACKS",
  "SACKU",
  "SADAH",
  "SADAM",
  "SADAR",
  "SADAT",
  "SADEČ",
  "SADEK",
  "SADEM",
  "SADEN",
  "SADEV",
  "SADEŽ",
  "SADIA",
  "SADIE",
  "SADIH",
  "SADII",
  "SADIK",
  "SADIL",
  "SADIM",
  "SADIO",
  "SADIŠ",
  "SADIT",
  "SADIU",
  "SADIV",
  "SADJA",
  "SADJE",
  "SADJU",
  "SADKA",
  "SADKE",
  "SADKI",
  "SADKO",
  "SADKU",
  "SADNA",
  "SADNE",
  "SADNI",
  "SADNO",
  "SADOM",
  "SADOV",
  "SADRA",
  "SADRE",
  "SADRI",
  "SADRO",
  "SADRU",
  "SADŽA",
  "SADŽE",
  "SADŽI",
  "SADŽO",
  "SAEBA",
  "SAEBE",
  "SAEBI",
  "SAEBU",
  "SAEDA",
  "SAEDE",
  "SAEDI",
  "SAEDU",
  "SAEKI",
  "SAENZ",
  "SAEZA",
  "SAEZE",
  "SAEZI",
  "SAEZU",
  "SAFAN",
  "SAFAR",
  "SAFER",
  "SAFET",
  "SAFFA",
  "SAFFE",
  "SAFFI",
  "SAFFO",
  "SAFFU",
  "SAFIA",
  "SAFIE",
  "SAFII",
  "SAFIJ",
  "SAFIN",
  "SAFIO",
  "SAFIR",
  "SAFRA",
  "SAFRE",
  "SAFRI",
  "SAFRO",
  "SAFRU",
  "SAFTI",
  "SAGAH",
  "SAGAJ",
  "SAGAL",
  "SAGAM",
  "SAGAN",
  "SAGEA",
  "SAGEE",
  "SAGEI",
  "SAGEM",
  "SAGER",
  "SAGET",
  "SAGEU",
  "SAGEV",
  "SAGIN",
  "SAGJA",
  "SAGJU",
  "SAGOM",
  "SAGOV",
  "SAHAP",
  "SAHAR",
  "SAHEL",
  "SAHIB",
  "SAHIL",
  "SAHIN",
  "SAHIR",
  "SAHIT",
  "SAHLA",
  "SAHLE",
  "SAHLI",
  "SAHLU",
  "SAHMA",
  "SAHME",
  "SAHMI",
  "SAHMU",
  "SAHRA",
  "SAHRE",
  "SAHRI",
  "SAHRO",
  "SAIDA",
  "SAIDE",
  "SAIDI",
  "SAIDO",
  "SAIDU",
  "SAIED",
  "SAIFA",
  "SAIFE",
  "SAIFI",
  "SAIFU",
  "SAIKI",
  "SAIMI",
  "SAINA",
  "SAINE",
  "SAINI",
  "SAINT",
  "SAINU",
  "SAINZ",
  "SAIRA",
  "SAIRE",
  "SAIRI",
  "SAIRO",
  "SAISA",
  "SAISS",
  "SAISU",
  "SAITA",
  "SAITE",
  "SAITI",
  "SAITO",
  "SAITT",
  "SAITU",
  "SAJAH",
  "SAJAK",
  "SAJAM",
  "SAJAN",
  "SAJAV",
  "SAJEČ",
  "SAJED",
  "SAJEM",
  "SAJEN",
  "SAJEV",
  "SAJGA",
  "SAJGE",
  "SAJGI",
  "SAJGO",
  "SAJIČ",
  "SAJID",
  "SAJIH",
  "SAJIL",
  "SAJIM",
  "SAJIN",
  "SAJIŠ",
  "SAJIT",
  "SAJIU",
  "SAJIV",
  "SAJJA",
  "SAJJU",
  "SAJKA",
  "SAJKE",
  "SAJKI",
  "SAJKO",
  "SAJKU",
  "SAJOM",
  "SAJOV",
  "SAJRA",
  "SAJRE",
  "SAJRI",
  "SAJRO",
  "SAKAČ",
  "SAKAH",
  "SAKAL",
  "SAKAM",
  "SAKAN",
  "SAKAR",
  "SAKAT",
  "SAKEM",
  "SAKEV",
  "SAKHO",
  "SAKIH",
  "SAKIP",
  "SAKJA",
  "SAKJU",
  "SAKOM",
  "SAKOV",
  "SAKSA",
  "SAKSE",
  "SAKSI",
  "SAKSU",
  "SAKUR",
  "SALAH",
  "SALAJ",
  "SALAK",
  "SALAM",
  "SALAN",
  "SALAS",
  "SALAŠ",
  "SALBI",
  "SALCE",
  "SALDA",
  "SALDE",
  "SALDI",
  "SALDO",
  "SALDU",
  "SALED",
  "SALEH",
  "SALEM",
  "SALEN",
  "SALES",
  "SALEŽ",
  "SALHA",
  "SALHE",
  "SALHI",
  "SALHO",
  "SALHU",
  "SALIB",
  "SALIE",
  "SALIF",
  "SALIH",
  "SALIL",
  "SALIM",
  "SALIN",
  "SALIS",
  "SALIU",
  "SALJA",
  "SALJU",
  "SALKA",
  "SALKE",
  "SALKI",
  "SALKO",
  "SALKU",
  "SALLA",
  "SALLE",
  "SALLI",
  "SALLO",
  "SALLU",
  "SALMA",
  "SALME",
  "SALMI",
  "SALMO",
  "SALMU",
  "SALNA",
  "SALNE",
  "SALNI",
  "SALNO",
  "SALOM",
  "SALON",
  "SALOV",
  "SALPA",
  "SALPE",
  "SALPI",
  "SALPO",
  "SALSA",
  "SALSE",
  "SALSI",
  "SALSO",
  "SALTA",
  "SALTE",
  "SALTI",
  "SALTO",
  "SALTU",
  "SALUJ",
  "SALUN",
  "SALUS",
  "SALVA",
  "SALVE",
  "SALVI",
  "SALVO",
  "SALVU",
  "SAMAD",
  "SAMAN",
  "SAMAR",
  "SAMAT",
  "SAMBA",
  "SAMBE",
  "SAMBI",
  "SAMBO",
  "SAMBT",
  "SAMCA",
  "SAMCE",
  "SAMCI",
  "SAMCU",
  "SAMEC",
  "SAMEH",
  "SAMEM",
  "SAMER",
  "SAMET",
  "SAMGA",
  "SAMIA",
  "SAMIC",
  "SAMID",
  "SAMIE",
  "SAMIH",
  "SAMII",
  "SAMIJ",
  "SAMIM",
  "SAMIN",
  "SAMIO",
  "SAMIR",
  "SAMIT",
  "SAMJA",
  "SAMJU",
  "SAMKA",
  "SAMKE",
  "SAMKI",
  "SAMKO",
  "SAMLA",
  "SAMLE",
  "SAMLI",
  "SAMLU",
  "SAMMI",
  "SAMMS",
  "SAMOA",
  "SAMOE",
  "SAMOI",
  "SAMOL",
  "SAMOM",
  "SAMOO",
  "SAMOR",
  "SAMOS",
  "SAMOT",
  "SAMOV",
  "SAMRA",
  "SAMRE",
  "SAMRI",
  "SAMRO",
  "SAMSA",
  "SAMSE",
  "SAMSI",
  "SAMSO",
  "SAMSU",
  "SAMUD",
  "SAMUJ",
  "SAMUM",
  "SANČA",
  "SANČE",
  "SANČI",
  "SANČO",
  "SANČU",
  "SANDA",
  "SANDE",
  "SANDI",
  "SANDO",
  "SANDS",
  "SANDT",
  "SANDU",
  "SANEH",
  "SANEL",
  "SANEM",
  "SANGA",
  "SANGE",
  "SANGI",
  "SANGO",
  "SANGU",
  "SANIA",
  "SANIC",
  "SANIE",
  "SANIH",
  "SANII",
  "SANIJ",
  "SANIO",
  "SANJA",
  "SANJE",
  "SANJI",
  "SANJO",
  "SANJU",
  "SANKA",
  "SANKE",
  "SANKI",
  "SANKO",
  "SANKT",
  "SANKU",
  "SANMI",
  "SANNA",
  "SANNE",
  "SANNI",
  "SANNO",
  "SANOM",
  "SANON",
  "SANOV",
  "SANSA",
  "SANSE",
  "SANSI",
  "SANSO",
  "SANTA",
  "SANTE",
  "SANTI",
  "SANTL",
  "SANTO",
  "SANTU",
  "SANZA",
  "SANZE",
  "SANZI",
  "SANZU",
  "SAONA",
  "SAONE",
  "SAONI",
  "SAONO",
  "SAPAČ",
  "SAPAH",
  "SAPAM",
  "SAPAR",
  "SAPEG",
  "SAPER",
  "SAPET",
  "SAPFO",
  "SAPIA",
  "SAPIC",
  "SAPIE",
  "SAPII",
  "SAPIN",
  "SAPIO",
  "SAPIR",
  "SAPIU",
  "SAPJA",
  "SAPJU",
  "SAPOM",
  "SAPPA",
  "SAPPE",
  "SAPPI",
  "SAPPU",
  "SAPRA",
  "SAPRE",
  "SAPRI",
  "SAPRO",
  "SARAF",
  "SARAH",
  "SARAK",
  "SARAM",
  "SARBU",
  "SARDA",
  "SARDE",
  "SARDI",
  "SARDO",
  "SARDU",
  "SAREN",
  "SARIN",
  "SARIS",
  "SARIT",
  "SARKA",
  "SARKE",
  "SARKI",
  "SARKO",
  "SARKS",
  "SARKU",
  "SARLI",
  "SARMA",
  "SARME",
  "SARMI",
  "SARMO",
  "SARNA",
  "SARNE",
  "SARNI",
  "SARNO",
  "SARNU",
  "SAROK",
  "SARON",
  "SAROS",
  "SARRA",
  "SARRE",
  "SARRI",
  "SARRO",
  "SARRU",
  "SARSA",
  "SARSI",
  "SARSU",
  "SARTA",
  "SARTE",
  "SARTI",
  "SARTO",
  "SARTU",
  "SARUS",
  "SASCH",
  "SASHA",
  "SASHE",
  "SASHI",
  "SASHO",
  "SASHU",
  "SASIH",
  "SASIN",
  "SASJA",
  "SASJU",
  "SASOM",
  "SASON",
  "SASOV",
  "SASSA",
  "SASSE",
  "SASSI",
  "SASSO",
  "SASSU",
  "SAŠAH",
  "SAŠAM",
  "SAŠEK",
  "SAŠEM",
  "SAŠEV",
  "SAŠIH",
  "SAŠIN",
  "SAŠJA",
  "SAŠJU",
  "SAŠKA",
  "SAŠKE",
  "SAŠKI",
  "SAŠKO",
  "SAŠKU",
  "SAŠOM",
  "SAŠOV",
  "SATAH",
  "SATAM",
  "SATAN",
  "SATEM",
  "SATEN",
  "SATEV",
  "SATIE",
  "SATIH",
  "SATIN",
  "SATIR",
  "SATJA",
  "SATJE",
  "SATJU",
  "SATKA",
  "SATKE",
  "SATKI",
  "SATKO",
  "SATKU",
  "SATNA",
  "SATNE",
  "SATNI",
  "SATNO",
  "SATOM",
  "SATOV",
  "SATTA",
  "SATTE",
  "SATTI",
  "SATTO",
  "SATTU",
  "SAUCH",
  "SAUDI",
  "SAUER",
  "SAUIH",
  "SAUJA",
  "SAUJU",
  "SAUKA",
  "SAUKE",
  "SAUKI",
  "SAUKO",
  "SAUKU",
  "SAULA",
  "SAULE",
  "SAULI",
  "SAULO",
  "SAULS",
  "SAULU",
  "SAUMA",
  "SAUME",
  "SAUMI",
  "SAUMU",
  "SAUNA",
  "SAUNE",
  "SAUNI",
  "SAUNO",
  "SAUOM",
  "SAUOV",
  "SAURA",
  "SAURE",
  "SAURI",
  "SAURO",
  "SAURU",
  "SAUVA",
  "SAUVE",
  "SAUVU",
  "SAVAH",
  "SAVAL",
  "SAVAM",
  "SAVAN",
  "SAVAŠ",
  "SAVCE",
  "SAVCI",
  "SAVEL",
  "SAVER",
  "SAVET",
  "SAVIA",
  "SAVIC",
  "SAVIČ",
  "SAVIE",
  "SAVIH",
  "SAVII",
  "SAVIN",
  "SAVIO",
  "SAVIR",
  "SAVIU",
  "SAVJA",
  "SAVJU",
  "SAVKA",
  "SAVKE",
  "SAVKI",
  "SAVKO",
  "SAVLA",
  "SAVLE",
  "SAVLI",
  "SAVLU",
  "SAVNA",
  "SAVNE",
  "SAVNI",
  "SAVNO",
  "SAVNU",
  "SAVOL",
  "SAVOM",
  "SAVON",
  "SAVOV",
  "SAVRE",
  "SAZAK",
  "SAZAN",
  "SAZAS",
  "SAZJU",
  "SAZOR",
  "SBARR",
  "SBOTU",
  "SCAFF",
  "SCAGA",
  "SCAGI",
  "SCAGU",
  "SCALA",
  "SCALE",
  "SCALF",
  "SCALI",
  "SCALO",
  "SCALU",
  "SCANJ",
  "SCANU",
  "SCARF",
  "SCARP",
  "SCATI",
  "SCEDI",
  "SCEDU",
  "SCEJA",
  "SCEJI",
  "SCEJU",
  "SCELA",
  "SCELI",
  "SCELU",
  "SCENA",
  "SCENC",
  "SCENE",
  "SCENI",
  "SCENO",
  "SCHAD",
  "SCHAR",
  "SCHAT",
  "SCHEP",
  "SCHER",
  "SCHEU",
  "SCHIA",
  "SCHIO",
  "SCHIP",
  "SCHIU",
  "SCHON",
  "SCHOR",
  "SCHOT",
  "SCHOU",
  "SCIAM",
  "SCILL",
  "SCION",
  "SCIPP",
  "SCOBA",
  "SCOBE",
  "SCOBI",
  "SCOBU",
  "SCOFF",
  "SCOLA",
  "SCOLE",
  "SCOLI",
  "SCOLO",
  "SCOLU",
  "SCOMA",
  "SCOME",
  "SCOMI",
  "SCOMO",
  "SCOMU",
  "SCOON",
  "SCOOT",
  "SCOPP",
  "SCOTA",
  "SCOTE",
  "SCOTI",
  "SCOTT",
  "SCOTU",
  "SCUFA",
  "SCUFI",
  "SCUFU",
  "SCULL",
  "SCUOL",
  "SCUTT",
  "SCUZA",
  "SCUZI",
  "SCUZU",
  "SCVPB",
  "SCVRE",
  "SCVRI",
  "SCVRL",
  "SCVRT",
  "SČARA",
  "SČARI",
  "SČARU",
  "SČRNI",
  "SČRNU",
  "SDRES",
  "SDZNS",
  "SEADA",
  "SEADE",
  "SEADI",
  "SEADU",
  "SEAGA",
  "SEAGE",
  "SEAGI",
  "SEAGO",
  "SEAGU",
  "SEALA",
  "SEALE",
  "SEALI",
  "SEALS",
  "SEALU",
  "SEANA",
  "SEANE",
  "SEANI",
  "SEANN",
  "SEANO",
  "SEANS",
  "SEANU",
  "SEARL",
  "SEARS",
  "SEATA",
  "SEATU",
  "SEBAH",
  "SEBAM",
  "SEBAN",
  "SEBEK",
  "SEBIČ",
  "SEBIH",
  "SEBIJ",
  "SEBJA",
  "SEBJU",
  "SEBKA",
  "SEBKE",
  "SEBKI",
  "SEBKU",
  "SEBOJ",
  "SEBOM",
  "SEBOV",
  "SEBUM",
  "SECCI",
  "SECHI",
  "SECKA",
  "SECKE",
  "SECKI",
  "SECKU",
  "SECOM",
  "SECOP",
  "SEČAN",
  "SEČAR",
  "SEČEM",
  "SEČEN",
  "SEČIL",
  "SEČIN",
  "SEČJA",
  "SEČJE",
  "SEČJU",
  "SEČKA",
  "SEČKE",
  "SEČKI",
  "SEČKO",
  "SEČKU",
  "SEČNA",
  "SEČNE",
  "SEČNI",
  "SEČNO",
  "SEČNU",
  "SEČOM",
  "SEČTI",
  "SEDAH",
  "SEDAJ",
  "SEDAK",
  "SEDAL",
  "SEDAM",
  "SEDAN",
  "SEDAR",
  "SEDAŠ",
  "SEDAT",
  "SEDAU",
  "SEDAV",
  "SEDEČ",
  "SEDEJ",
  "SEDEL",
  "SEDEM",
  "SEDEN",
  "SEDES",
  "SEDEŠ",
  "SEDET",
  "SEDEU",
  "SEDEV",
  "SEDEŽ",
  "SEDIČ",
  "SEDIH",
  "SEDIJ",
  "SEDIM",
  "SEDIN",
  "SEDIR",
  "SEDIŠ",
  "SEDJA",
  "SEDJE",
  "SEDJI",
  "SEDJU",
  "SEDLA",
  "SEDLE",
  "SEDLI",
  "SEDLO",
  "SEDLU",
  "SEDMA",
  "SEDME",
  "SEDMI",
  "SEDMO",
  "SEDNA",
  "SEDNE",
  "SEDNI",
  "SEDNO",
  "SEDOL",
  "SEDOM",
  "SEDON",
  "SEDOV",
  "SEEAR",
  "SEEDA",
  "SEEDE",
  "SEEDI",
  "SEEDU",
  "SEEGA",
  "SEEGE",
  "SEEGI",
  "SEEGO",
  "SEEGU",
  "SEELE",
  "SEEMA",
  "SEEME",
  "SEEMI",
  "SEEMO",
  "SEFAH",
  "SEFAJ",
  "SEFAM",
  "SEFEM",
  "SEFER",
  "SEFEV",
  "SEFFA",
  "SEFFE",
  "SEFFI",
  "SEFFU",
  "SEFIH",
  "SEFJA",
  "SEFJU",
  "SEFOM",
  "SEFOV",
  "SEGAJ",
  "SEGAL",
  "SEGAM",
  "SEGAN",
  "SEGAŠ",
  "SEGAT",
  "SEGAU",
  "SEGAV",
  "SEGEL",
  "SEGER",
  "SEGEU",
  "SEGHI",
  "SEGLA",
  "SEGLE",
  "SEGLI",
  "SEGLO",
  "SEGNI",
  "SEGNU",
  "SEGOL",
  "SEGRE",
  "SEGRU",
  "SEGUR",
  "SEHER",
  "SEHIL",
  "SEHMA",
  "SEHME",
  "SEHMI",
  "SEHMU",
  "SEHUR",
  "SEIBT",
  "SEIDL",
  "SEIER",
  "SEIFA",
  "SEIFE",
  "SEIFI",
  "SEIFU",
  "SEIJO",
  "SEIKA",
  "SEIKO",
  "SEIKU",
  "SEIPP",
  "SEISS",
  "SEITH",
  "SEITI",
  "SEITL",
  "SEITZ",
  "SEIVA",
  "SEIVE",
  "SEIVI",
  "SEIVO",
  "SEIVU",
  "SEJAČ",
  "SEJAD",
  "SEJAH",
  "SEJAL",
  "SEJAM",
  "SEJAN",
  "SEJAT",
  "SEJAU",
  "SEJAV",
  "SEJDA",
  "SEJDE",
  "SEJDI",
  "SEJDO",
  "SEJDU",
  "SEJEM",
  "SEJEN",
  "SEJEŠ",
  "SEJFI",
  "SEJKA",
  "SEJKE",
  "SEJKI",
  "SEJKO",
  "SEJKU",
  "SEJMA",
  "SEJME",
  "SEJMI",
  "SEJMO",
  "SEJMU",
  "SEJNA",
  "SEJNE",
  "SEJNI",
  "SEJNO",
  "SEJTA",
  "SEJTE",
  "SEJVA",
  "SEKAČ",
  "SEKAH",
  "SEKAJ",
  "SEKAL",
  "SEKAM",
  "SEKAN",
  "SEKAŠ",
  "SEKAT",
  "SEKAU",
  "SEKAV",
  "SEKEJ",
  "SEKER",
  "SEKIN",
  "SEKIR",
  "SEKJU",
  "SEKKA",
  "SEKKE",
  "SEKKI",
  "SEKKO",
  "SEKKU",
  "SEKNE",
  "SEKOL",
  "SEKSA",
  "SEKSE",
  "SEKSI",
  "SEKST",
  "SEKSU",
  "SEKTA",
  "SEKTE",
  "SEKTI",
  "SEKTO",
  "SEKUL",
  "SELAH",
  "SELAK",
  "SELAM",
  "SELAN",
  "SELCA",
  "SELCE",
  "SELCI",
  "SELCU",
  "SELDA",
  "SELDE",
  "SELDI",
  "SELDO",
  "SELDU",
  "SELEA",
  "SELEC",
  "SELEČ",
  "SELEE",
  "SELEI",
  "SELEK",
  "SELEN",
  "SELEO",
  "SELES",
  "SELEŠ",
  "SELFA",
  "SELFE",
  "SELFI",
  "SELFO",
  "SELFU",
  "SELGE",
  "SELIČ",
  "SELIG",
  "SELIH",
  "SELIL",
  "SELIM",
  "SELIN",
  "SELIS",
  "SELIŠ",
  "SELIT",
  "SELIU",
  "SELIV",
  "SELJA",
  "SELJE",
  "SELJI",
  "SELJO",
  "SELJU",
  "SELKA",
  "SELKE",
  "SELKI",
  "SELKO",
  "SELKU",
  "SELLA",
  "SELLE",
  "SELLI",
  "SELLO",
  "SELLS",
  "SELLU",
  "SELMA",
  "SELME",
  "SELMI",
  "SELMO",
  "SELMU",
  "SELOM",
  "SELOV",
  "SELTH",
  "SEMAJ",
  "SEMAK",
  "SEMAL",
  "SEMAN",
  "SEMBA",
  "SEMBE",
  "SEMBI",
  "SEMBU",
  "SEMEJ",
  "SEMEL",
  "SEMEN",
  "SEMIČ",
  "SEMIR",
  "SEMIŠ",
  "SEMIT",
  "SEMKA",
  "SEMKE",
  "SEMKI",
  "SEMKO",
  "SEMLA",
  "SEMLE",
  "SEMLI",
  "SEMLU",
  "SEMON",
  "SEMRA",
  "SEMRE",
  "SEMRI",
  "SEMRO",
  "SENAD",
  "SENAH",
  "SENAJ",
  "SENAM",
  "SENAN",
  "SENAR",
  "SENAT",
  "SENCA",
  "SENCE",
  "SENCI",
  "SENCO",
  "SENCU",
  "SENČI",
  "SENČK",
  "SENČL",
  "SENČU",
  "SENDA",
  "SENDE",
  "SENDI",
  "SENDO",
  "SENEK",
  "SENEN",
  "SENFA",
  "SENFE",
  "SENFI",
  "SENFL",
  "SENFU",
  "SENIC",
  "SENIČ",
  "SENID",
  "SENIH",
  "SENIJ",
  "SENIK",
  "SENJA",
  "SENJE",
  "SENJI",
  "SENJO",
  "SENJU",
  "SENKA",
  "SENKE",
  "SENKI",
  "SENKO",
  "SENKU",
  "SENNA",
  "SENNE",
  "SENNI",
  "SENNO",
  "SENNU",
  "SENOM",
  "SENOR",
  "SENSI",
  "SENST",
  "SENTA",
  "SENTE",
  "SENTI",
  "SENTO",
  "SENUŠ",
  "SEOKA",
  "SEOKE",
  "SEOKI",
  "SEOKU",
  "SEOUL",
  "SEPIČ",
  "SEPIH",
  "SEPIJ",
  "SEPIN",
  "SEPJA",
  "SEPJU",
  "SEPOJ",
  "SEPOM",
  "SEPOV",
  "SEPPA",
  "SEPPE",
  "SEPPI",
  "SEPPO",
  "SEPPU",
  "SEPSA",
  "SEPSE",
  "SEPSI",
  "SEPSO",
  "SERAF",
  "SERAJ",
  "SERAK",
  "SERAP",
  "SERCA",
  "SERCE",
  "SERCI",
  "SERCU",
  "SERDT",
  "SEREC",
  "SEREM",
  "SEREN",
  "SEREV",
  "SERGA",
  "SERGE",
  "SERGI",
  "SERGO",
  "SERGU",
  "SERIA",
  "SERIE",
  "SERIF",
  "SERIH",
  "SERII",
  "SERIJ",
  "SERIN",
  "SERIO",
  "SERIU",
  "SERJA",
  "SERJE",
  "SERJI",
  "SERJU",
  "SERKA",
  "SERKE",
  "SERKI",
  "SERKO",
  "SERNC",
  "SEROČ",
  "SEROK",
  "SEROM",
  "SERON",
  "SEROU",
  "SEROV",
  "SEROZ",
  "SERRA",
  "SERRE",
  "SERRI",
  "SERRO",
  "SERRU",
  "SERSE",
  "SERŠA",
  "SERŠE",
  "SERŠI",
  "SERŠO",
  "SERŠU",
  "SERUM",
  "SERVA",
  "SERVE",
  "SERVI",
  "SERVO",
  "SERVU",
  "SERŽA",
  "SERŽI",
  "SERŽU",
  "SESAČ",
  "SESAJ",
  "SESAL",
  "SESAM",
  "SESAN",
  "SESAR",
  "SESAŠ",
  "SESAT",
  "SESAU",
  "SESAV",
  "SESCA",
  "SESCE",
  "SESCH",
  "SESCI",
  "SESCU",
  "SESEC",
  "SESEK",
  "SESEN",
  "SESIP",
  "SESKA",
  "SESKE",
  "SESKI",
  "SESKU",
  "SESLA",
  "SESLI",
  "SESLU",
  "SESNA",
  "SESNE",
  "SESNI",
  "SESNO",
  "SESSA",
  "SESSE",
  "SESSI",
  "SESSO",
  "SESSU",
  "SESTA",
  "SESTE",
  "SESTI",
  "SESTO",
  "SESTU",
  "SESUJ",
  "SESUL",
  "SESUT",
  "SEŠEK",
  "SEŠEL",
  "SEŠEU",
  "SEŠIJ",
  "SEŠIL",
  "SEŠIT",
  "SEŠIU",
  "SEŠIV",
  "SEŠKA",
  "SEŠKE",
  "SEŠKI",
  "SEŠKU",
  "SEŠLA",
  "SEŠLE",
  "SEŠLI",
  "SEŠLO",
  "SEŠLU",
  "SEŠTU",
  "SETAH",
  "SETAM",
  "SETEM",
  "SETER",
  "SETEV",
  "SETHA",
  "SETHE",
  "SETHI",
  "SETHU",
  "SETIH",
  "SETIN",
  "SETJA",
  "SETJO",
  "SETJU",
  "SETOM",
  "SETON",
  "SETOV",
  "SETRA",
  "SETRE",
  "SETRI",
  "SETRU",
  "SETTE",
  "SETTI",
  "SETUN",
  "SETVE",
  "SETVI",
  "SEULA",
  "SEULU",
  "SEUMA",
  "SEUME",
  "SEUMI",
  "SEUMU",
  "SEUSE",
  "SEVAJ",
  "SEVAL",
  "SEVAM",
  "SEVAN",
  "SEVAŠ",
  "SEVAT",
  "SEVAU",
  "SEVAV",
  "SEVCA",
  "SEVCE",
  "SEVCU",
  "SEVDI",
  "SEVEC",
  "SEVEM",
  "SEVER",
  "SEVEV",
  "SEVGI",
  "SEVIH",
  "SEVIM",
  "SEVIT",
  "SEVJA",
  "SEVJU",
  "SEVKA",
  "SEVKE",
  "SEVKI",
  "SEVKO",
  "SEVNO",
  "SEVOM",
  "SEVOV",
  "SEZAJ",
  "SEZAL",
  "SEZAM",
  "SEZAŠ",
  "SEZAT",
  "SEZAU",
  "SEZAV",
  "SEZMS",
  "SEZON",
  "SEZUJ",
  "SEZUL",
  "SEZUT",
  "SEŽAJ",
  "SEŽEL",
  "SEŽEM",
  "SEŽEŠ",
  "SEŽET",
  "SEŽEU",
  "SEŽGE",
  "SEŽGI",
  "SEŽGU",
  "SEŽIG",
  "SEŽME",
  "SEŽMI",
  "SEŽUN",
  "SFALI",
  "SFALU",
  "SFEIR",
  "SFENA",
  "SFENI",
  "SFENU",
  "SFERA",
  "SFERE",
  "SFERI",
  "SFERO",
  "SFERR",
  "SFING",
  "SFIŽI",
  "SFIŽU",
  "SFORZ",
  "SFOTA",
  "SFRČI",
  "SFRČU",
  "SFRLI",
  "SFRLU",
  "SFUKA",
  "SFUKI",
  "SFUKU",
  "SGALL",
  "SHAAR",
  "SHADA",
  "SHADE",
  "SHADI",
  "SHADU",
  "SHAFI",
  "SHAHA",
  "SHAHE",
  "SHAHI",
  "SHAHN",
  "SHAHO",
  "SHAHU",
  "SHAIA",
  "SHAIE",
  "SHAII",
  "SHAIN",
  "SHAIP",
  "SHAIU",
  "SHAJA",
  "SHAJI",
  "SHAJU",
  "SHAKA",
  "SHAKE",
  "SHAKI",
  "SHAKO",
  "SHAKU",
  "SHALA",
  "SHALE",
  "SHALI",
  "SHALK",
  "SHALO",
  "SHALU",
  "SHAMA",
  "SHAME",
  "SHAMI",
  "SHAMO",
  "SHAMS",
  "SHAMU",
  "SHANA",
  "SHAND",
  "SHANE",
  "SHANI",
  "SHANK",
  "SHANN",
  "SHANO",
  "SHANU",
  "SHAPS",
  "SHARA",
  "SHARE",
  "SHARI",
  "SHARK",
  "SHARM",
  "SHARO",
  "SHARP",
  "SHARR",
  "SHASH",
  "SHAST",
  "SHAUL",
  "SHAUN",
  "SHEAN",
  "SHEAR",
  "SHEDD",
  "SHEEB",
  "SHEED",
  "SHEEN",
  "SHEFF",
  "SHEHA",
  "SHEHE",
  "SHEHI",
  "SHEHU",
  "SHEIL",
  "SHEIN",
  "SHEJA",
  "SHEKA",
  "SHEKI",
  "SHEKU",
  "SHELA",
  "SHELE",
  "SHELI",
  "SHELL",
  "SHELU",
  "SHEMA",
  "SHEME",
  "SHEMI",
  "SHEMO",
  "SHENA",
  "SHENE",
  "SHENG",
  "SHENI",
  "SHENK",
  "SHENN",
  "SHENO",
  "SHENU",
  "SHEPA",
  "SHEPE",
  "SHEPI",
  "SHEPP",
  "SHEPU",
  "SHERI",
  "SHERM",
  "SHETH",
  "SHICK",
  "SHIFT",
  "SHIHA",
  "SHIHE",
  "SHIHI",
  "SHIHU",
  "SHILL",
  "SHILS",
  "SHIMA",
  "SHIME",
  "SHIMI",
  "SHIMO",
  "SHIMU",
  "SHINA",
  "SHINE",
  "SHING",
  "SHINI",
  "SHINN",
  "SHINU",
  "SHION",
  "SHIPA",
  "SHIPE",
  "SHIPI",
  "SHIPP",
  "SHIPU",
  "SHIRA",
  "SHIRE",
  "SHIRI",
  "SHIRK",
  "SHIRO",
  "SHIRU",
  "SHITI",
  "SHITU",
  "SHIVA",
  "SHIVE",
  "SHIVI",
  "SHIVO",
  "SHIVU",
  "SHKOP",
  "SHOAF",
  "SHOBA",
  "SHOBE",
  "SHOBI",
  "SHOBU",
  "SHOCK",
  "SHODA",
  "SHODE",
  "SHODI",
  "SHODU",
  "SHOLL",
  "SHONA",
  "SHOND",
  "SHONE",
  "SHONI",
  "SHONO",
  "SHONU",
  "SHOOF",
  "SHOOK",
  "SHOOM",
  "SHOOP",
  "SHOPA",
  "SHOPE",
  "SHOPI",
  "SHOPU",
  "SHORA",
  "SHORE",
  "SHORI",
  "SHORT",
  "SHORU",
  "SHOUP",
  "SHPAT",
  "SHPUZ",
  "SHREK",
  "SHREM",
  "SHRUM",
  "SHUCK",
  "SHUDA",
  "SHUDI",
  "SHUDU",
  "SHUFU",
  "SHUGG",
  "SHUKA",
  "SHUKE",
  "SHUKI",
  "SHUKO",
  "SHUKU",
  "SHULI",
  "SHULL",
  "SHUMA",
  "SHUME",
  "SHUMI",
  "SHUMU",
  "SHURA",
  "SHURE",
  "SHURI",
  "SHURU",
  "SHUTA",
  "SHUTE",
  "SHUTI",
  "SHUTU",
  "SIADA",
  "SIADE",
  "SIADI",
  "SIADU",
  "SIALA",
  "SIALI",
  "SIALU",
  "SIAMA",
  "SIAMC",
  "SIAMI",
  "SIAMK",
  "SIAMU",
  "SIANI",
  "SIARD",
  "SIARE",
  "SIBEL",
  "SIBIL",
  "SIBIU",
  "SIBJA",
  "SIBJU",
  "SIBOM",
  "SIBOV",
  "SIBUS",
  "SICAH",
  "SICAM",
  "SICER",
  "SICEV",
  "SICHL",
  "SICIH",
  "SICJA",
  "SICJU",
  "SICKA",
  "SICKE",
  "SICKI",
  "SICKU",
  "SICOM",
  "SICOV",
  "SICSA",
  "SICSE",
  "SICSI",
  "SICSU",
  "SIČAL",
  "SIČAT",
  "SIČAU",
  "SIČAV",
  "SIČEČ",
  "SIČEM",
  "SIČEŠ",
  "SIČEV",
  "SIČIH",
  "SIČIM",
  "SIČIŠ",
  "SIČJA",
  "SIČJU",
  "SIČKA",
  "SIČKE",
  "SIČKI",
  "SIČKO",
  "SIČKU",
  "SIČOM",
  "SIČOV",
  "SIDAR",
  "SIDDI",
  "SIDER",
  "SIDHU",
  "SIDIH",
  "SIDIS",
  "SIDJA",
  "SIDJU",
  "SIDOL",
  "SIDOM",
  "SIDON",
  "SIDOR",
  "SIDOV",
  "SIDRA",
  "SIDRC",
  "SIDRI",
  "SIDRN",
  "SIDRO",
  "SIDRU",
  "SIECK",
  "SIEFF",
  "SIEGA",
  "SIEGE",
  "SIEGI",
  "SIEGL",
  "SIEGO",
  "SIEGU",
  "SIEMA",
  "SIEME",
  "SIEMI",
  "SIEMU",
  "SIENA",
  "SIENE",
  "SIENI",
  "SIENN",
  "SIENO",
  "SIERR",
  "SIEST",
  "SIFAK",
  "SIFAN",
  "SIFFA",
  "SIFFE",
  "SIFFI",
  "SIFFU",
  "SIFLA",
  "SIFLE",
  "SIFLI",
  "SIFLO",
  "SIFON",
  "SIGAJ",
  "SIGAL",
  "SIGAM",
  "SIGAŠ",
  "SIGAT",
  "SIGAU",
  "SIGAV",
  "SIGEL",
  "SIGEM",
  "SIGEV",
  "SIGGA",
  "SIGGE",
  "SIGGI",
  "SIGGU",
  "SIGIH",
  "SIGIN",
  "SIGJA",
  "SIGJU",
  "SIGLA",
  "SIGLE",
  "SIGLI",
  "SIGLO",
  "SIGLU",
  "SIGMA",
  "SIGME",
  "SIGMI",
  "SIGMO",
  "SIGNA",
  "SIGNE",
  "SIGNI",
  "SIGNO",
  "SIGOM",
  "SIGON",
  "SIGOV",
  "SIHEM",
  "SIIMA",
  "SIIME",
  "SIIMI",
  "SIIMU",
  "SIJAJ",
  "SIJAL",
  "SIJAN",
  "SIJAT",
  "SIJAU",
  "SIJAV",
  "SIJEM",
  "SIJEŠ",
  "SIJEV",
  "SIJIH",
  "SIJIN",
  "SIJJA",
  "SIJJU",
  "SIJMO",
  "SIJOČ",
  "SIJOM",
  "SIJOV",
  "SIJTA",
  "SIJTE",
  "SIJVA",
  "SIKAH",
  "SIKAJ",
  "SIKAL",
  "SIKAM",
  "SIKAN",
  "SIKAŠ",
  "SIKAT",
  "SIKAU",
  "SIKAV",
  "SIKEL",
  "SIKEM",
  "SIKEV",
  "SIKHA",
  "SIKHE",
  "SIKHI",
  "SIKHU",
  "SIKIH",
  "SIKIM",
  "SIKIN",
  "SIKJA",
  "SIKJU",
  "SIKNE",
  "SIKNI",
  "SIKNU",
  "SIKOM",
  "SIKON",
  "SIKOR",
  "SIKOV",
  "SIKST",
  "SIKUR",
  "SILAH",
  "SILAJ",
  "SILAK",
  "SILAM",
  "SILAN",
  "SILAS",
  "SILAŽ",
  "SILEN",
  "SILER",
  "SILFA",
  "SILFE",
  "SILFI",
  "SILFO",
  "SILFU",
  "SILIČ",
  "SILIH",
  "SILIL",
  "SILIM",
  "SILIN",
  "SILIŠ",
  "SILIT",
  "SILIU",
  "SILIV",
  "SILJA",
  "SILJE",
  "SILJI",
  "SILJU",
  "SILKA",
  "SILKE",
  "SILKI",
  "SILKO",
  "SILKU",
  "SILLA",
  "SILLE",
  "SILLI",
  "SILLO",
  "SILLS",
  "SILLU",
  "SILNA",
  "SILNE",
  "SILNI",
  "SILNO",
  "SILOM",
  "SILOS",
  "SILOV",
  "SILUR",
  "SILVA",
  "SILVE",
  "SILVI",
  "SILVO",
  "SILVU",
  "SIMAA",
  "SIMAE",
  "SIMAH",
  "SIMAI",
  "SIMAM",
  "SIMAO",
  "SIMAU",
  "SIMBA",
  "SIMBE",
  "SIMBI",
  "SIMBO",
  "SIMBU",
  "SIMED",
  "SIMEN",
  "SIMES",
  "SIMIČ",
  "SIMIH",
  "SIMJA",
  "SIMJU",
  "SIMKA",
  "SIMKE",
  "SIMKI",
  "SIMKO",
  "SIMKU",
  "SIMMA",
  "SIMME",
  "SIMMI",
  "SIMMO",
  "SIMMS",
  "SIMMU",
  "SIMOK",
  "SIMOM",
  "SIMON",
  "SIMOR",
  "SIMOS",
  "SIMOV",
  "SIMPL",
  "SIMSA",
  "SIMSE",
  "SIMSI",
  "SIMSU",
  "SIMTA",
  "SIMTU",
  "SIMUN",
  "SINAH",
  "SINAJ",
  "SINAL",
  "SINAM",
  "SINAN",
  "SINEK",
  "SINEM",
  "SINER",
  "SINEŠ",
  "SINEV",
  "SINGH",
  "SINGL",
  "SINGO",
  "SINIC",
  "SINIČ",
  "SINIH",
  "SINIL",
  "SININ",
  "SINIS",
  "SINIŠ",
  "SINIT",
  "SINIU",
  "SINIV",
  "SINJA",
  "SINJC",
  "SINJE",
  "SINJI",
  "SINJO",
  "SINJU",
  "SINKA",
  "SINKE",
  "SINKI",
  "SINKO",
  "SINKU",
  "SINNA",
  "SINNE",
  "SINNI",
  "SINNO",
  "SINNU",
  "SINOD",
  "SINOM",
  "SINON",
  "SINOT",
  "SINOV",
  "SINSA",
  "SINSE",
  "SINSI",
  "SINSU",
  "SINTA",
  "SINTE",
  "SINTI",
  "SINTU",
  "SINUR",
  "SINUS",
  "SIOLA",
  "SIOLU",
  "SIONA",
  "SIONE",
  "SIONI",
  "SIONO",
  "SIONU",
  "SIPAČ",
  "SIPAH",
  "SIPAJ",
  "SIPAL",
  "SIPAM",
  "SIPAŠ",
  "SIPAT",
  "SIPAU",
  "SIPAV",
  "SIPEK",
  "SIPEM",
  "SIPES",
  "SIPIC",
  "SIPIČ",
  "SIPIN",
  "SIPJA",
  "SIPJE",
  "SIPJI",
  "SIPJO",
  "SIPJU",
  "SIPKA",
  "SIPKE",
  "SIPKI",
  "SIPKO",
  "SIPOL",
  "SIPOM",
  "SIPOS",
  "SIPOŠ",
  "SIPPA",
  "SIPPE",
  "SIPPI",
  "SIPPU",
  "SIPRA",
  "SIPRI",
  "SIPRO",
  "SIPRU",
  "SIPSA",
  "SIPSE",
  "SIPSI",
  "SIPSU",
  "SIRAD",
  "SIRAH",
  "SIRAJ",
  "SIRAR",
  "SIRCA",
  "SIRCE",
  "SIRCI",
  "SIRCU",
  "SIRČE",
  "SIRČI",
  "SIREC",
  "SIREČ",
  "SIREK",
  "SIREM",
  "SIREN",
  "SIREV",
  "SIRIH",
  "SIRIJ",
  "SIRIL",
  "SIRIM",
  "SIRIN",
  "SIRIS",
  "SIRIŠ",
  "SIRIT",
  "SIRIU",
  "SIRIV",
  "SIRJA",
  "SIRJU",
  "SIRKA",
  "SIRKE",
  "SIRKI",
  "SIRKK",
  "SIRKO",
  "SIRKU",
  "SIRNA",
  "SIRNE",
  "SIRNI",
  "SIRNO",
  "SIROL",
  "SIROM",
  "SIROT",
  "SIROV",
  "SIRRS",
  "SIRŠE",
  "SIRTA",
  "SIRTE",
  "SIRTI",
  "SIRTO",
  "SIRUP",
  "SISAK",
  "SISAL",
  "SISAM",
  "SISEK",
  "SISKA",
  "SISKE",
  "SISKI",
  "SISKU",
  "SISOL",
  "SISSE",
  "SISSI",
  "SISTA",
  "SISTE",
  "SISTI",
  "SISTO",
  "SISTU",
  "SIŠKA",
  "SIŠKE",
  "SIŠKI",
  "SIŠKO",
  "SITAH",
  "SITAM",
  "SITAR",
  "SITCA",
  "SITCE",
  "SITCI",
  "SITCU",
  "SITEC",
  "SITEL",
  "SITEM",
  "SITEN",
  "SITER",
  "SITEŽ",
  "SITGA",
  "SITIH",
  "SITIK",
  "SITIM",
  "SITJA",
  "SITJE",
  "SITJU",
  "SITKA",
  "SITKE",
  "SITKI",
  "SITKO",
  "SITNA",
  "SITNE",
  "SITNI",
  "SITNO",
  "SITOM",
  "SITOV",
  "SITTE",
  "SITUL",
  "SIUJA",
  "SIUJE",
  "SIUJI",
  "SIUJU",
  "SIUKA",
  "SIUKE",
  "SIUKI",
  "SIUKO",
  "SIUKU",
  "SIVAK",
  "SIVAN",
  "SIVCA",
  "SIVCE",
  "SIVCI",
  "SIVCU",
  "SIVEC",
  "SIVEČ",
  "SIVEL",
  "SIVEM",
  "SIVER",
  "SIVET",
  "SIVEU",
  "SIVGA",
  "SIVIC",
  "SIVIH",
  "SIVIL",
  "SIVIM",
  "SIVIN",
  "SIVIŠ",
  "SIVIT",
  "SIVIU",
  "SIVIV",
  "SIVKA",
  "SIVKE",
  "SIVKI",
  "SIVKO",
  "SIVKU",
  "SIZIF",
  "SIZIH",
  "SIZJA",
  "SIZJU",
  "SIZOM",
  "SIZOV",
  "SJEFA",
  "SJEFE",
  "SJEFI",
  "SJEFU",
  "SJUJA",
  "SJUJE",
  "SJUJI",
  "SJUJU",
  "SKAČE",
  "SKAČI",
  "SKADI",
  "SKADU",
  "SKAJA",
  "SKAJI",
  "SKAJU",
  "SKAKA",
  "SKAKI",
  "SKAKU",
  "SKALA",
  "SKALD",
  "SKALE",
  "SKALI",
  "SKALO",
  "SKALP",
  "SKALU",
  "SKANK",
  "SKARG",
  "SKARI",
  "SKASA",
  "SKASE",
  "SKASI",
  "SKASU",
  "SKATA",
  "SKATE",
  "SKATI",
  "SKATU",
  "SKAUG",
  "SKAVT",
  "SKAZA",
  "SKAZE",
  "SKAZI",
  "SKAZO",
  "SKAZU",
  "SKEČA",
  "SKEČE",
  "SKEČI",
  "SKEČU",
  "SKEDA",
  "SKEDE",
  "SKEDI",
  "SKEDU",
  "SKEEL",
  "SKEET",
  "SKEJT",
  "SKELA",
  "SKELE",
  "SKELI",
  "SKELL",
  "SKELO",
  "SKELU",
  "SKEMP",
  "SKENA",
  "SKENE",
  "SKENI",
  "SKENU",
  "SKEPI",
  "SKEPS",
  "SKEPU",
  "SKERK",
  "SKERL",
  "SKESA",
  "SKESI",
  "SKESU",
  "SKETA",
  "SKETE",
  "SKETI",
  "SKETU",
  "SKIBA",
  "SKIBE",
  "SKIBI",
  "SKIBO",
  "SKIBU",
  "SKICA",
  "SKICE",
  "SKICI",
  "SKICO",
  "SKIDA",
  "SKIDI",
  "SKIDO",
  "SKIDU",
  "SKIEN",
  "SKIFA",
  "SKIFE",
  "SKIFI",
  "SKIFU",
  "SKIMA",
  "SKIMI",
  "SKIMU",
  "SKINA",
  "SKINE",
  "SKINI",
  "SKINK",
  "SKINU",
  "SKIPA",
  "SKIPE",
  "SKIPI",
  "SKIPP",
  "SKIPU",
  "SKIRO",
  "SKISA",
  "SKISI",
  "SKISU",
  "SKITA",
  "SKITE",
  "SKITI",
  "SKITK",
  "SKITU",
  "SKLAČ",
  "SKLAD",
  "SKLAL",
  "SKLAN",
  "SKLAR",
  "SKLAT",
  "SKLAU",
  "SKLAV",
  "SKLEC",
  "SKLED",
  "SKLEP",
  "SKLIC",
  "SKLON",
  "SKLOP",
  "SKOBA",
  "SKOBC",
  "SKOBE",
  "SKOBI",
  "SKOBO",
  "SKOČI",
  "SKOČL",
  "SKOČU",
  "SKOGA",
  "SKOGE",
  "SKOGI",
  "SKOGU",
  "SKOJA",
  "SKOJU",
  "SKOKA",
  "SKOKC",
  "SKOKE",
  "SKOKI",
  "SKOKO",
  "SKOKU",
  "SKOLA",
  "SKOLE",
  "SKOLI",
  "SKOLL",
  "SKOLU",
  "SKOOG",
  "SKOPA",
  "SKOPC",
  "SKOPE",
  "SKOPI",
  "SKOPO",
  "SKOPU",
  "SKORO",
  "SKORŠ",
  "SKOTA",
  "SKOTE",
  "SKOTI",
  "SKOTU",
  "SKOUA",
  "SKOUE",
  "SKOUI",
  "SKOUU",
  "SKOVA",
  "SKOVE",
  "SKOVI",
  "SKOVU",
  "SKOZI",
  "SKRAM",
  "SKRBE",
  "SKRBI",
  "SKRBU",
  "SKRČI",
  "SKRČK",
  "SKRČL",
  "SKRČU",
  "SKREL",
  "SKRHA",
  "SKRHI",
  "SKRHU",
  "SKRIJ",
  "SKRIL",
  "SKRIN",
  "SKRIT",
  "SKRIU",
  "SKRIV",
  "SKRKA",
  "SKRKE",
  "SKRKI",
  "SKRKO",
  "SKRPA",
  "SKRPI",
  "SKRPU",
  "SKRTA",
  "SKRTE",
  "SKRTI",
  "SKRTU",
  "SKUAH",
  "SKUAM",
  "SKUBE",
  "SKUBI",
  "SKUBO",
  "SKUBU",
  "SKUHA",
  "SKUHI",
  "SKUHU",
  "SKUIN",
  "SKUJA",
  "SKUJE",
  "SKUJI",
  "SKUJU",
  "SKUKA",
  "SKUKE",
  "SKUKI",
  "SKUKO",
  "SKUKU",
  "SKULA",
  "SKULE",
  "SKULI",
  "SKULJ",
  "SKULU",
  "SKUNK",
  "SKUPI",
  "SKUPU",
  "SKURA",
  "SKURE",
  "SKURI",
  "SKURO",
  "SKURU",
  "SKUSI",
  "SKUSU",
  "SKUŠA",
  "SKUŠE",
  "SKUŠI",
  "SKUŠO",
  "SKUŠU",
  "SKUTA",
  "SKUTE",
  "SKUTI",
  "SKUTO",
  "SKVOŠ",
  "SKVOT",
  "SLABA",
  "SLABE",
  "SLABI",
  "SLABO",
  "SLABU",
  "SLACK",
  "SLAČI",
  "SLAČL",
  "SLAČU",
  "SLADA",
  "SLADE",
  "SLADI",
  "SLADU",
  "SLAKA",
  "SLAKE",
  "SLAKI",
  "SLAKU",
  "SLAMA",
  "SLAME",
  "SLAMI",
  "SLAMO",
  "SLAMU",
  "SLANA",
  "SLANC",
  "SLANE",
  "SLANI",
  "SLANO",
  "SLANU",
  "SLAPA",
  "SLAPE",
  "SLAPI",
  "SLAPP",
  "SLAPU",
  "SLAST",
  "SLATA",
  "SLATE",
  "SLATU",
  "SLAVA",
  "SLAVC",
  "SLAVČ",
  "SLAVE",
  "SLAVI",
  "SLAVK",
  "SLAVO",
  "SLAVU",
  "SLEBI",
  "SLECI",
  "SLEČA",
  "SLEČE",
  "SLEČI",
  "SLEČT",
  "SLEČU",
  "SLEDA",
  "SLEDE",
  "SLEDI",
  "SLEDU",
  "SLEEP",
  "SLEKU",
  "SLEMC",
  "SLEME",
  "SLENG",
  "SLEPA",
  "SLEPC",
  "SLEPE",
  "SLEPI",
  "SLEPK",
  "SLEPO",
  "SLEPU",
  "SLETT",
  "SLEZA",
  "SLEZE",
  "SLEZI",
  "SLEZU",
  "SLICK",
  "SLIČI",
  "SLIČL",
  "SLIČU",
  "SLIGH",
  "SLIKA",
  "SLIKC",
  "SLIKE",
  "SLIKI",
  "SLIKO",
  "SLIKU",
  "SLIMA",
  "SLIME",
  "SLIMI",
  "SLIMU",
  "SLINA",
  "SLIND",
  "SLINE",
  "SLING",
  "SLINI",
  "SLINO",
  "SLINU",
  "SLIPA",
  "SLIPE",
  "SLIPI",
  "SLIPU",
  "SLIŠE",
  "SLIŠI",
  "SLIŠU",
  "SLIVA",
  "SLIVE",
  "SLIVI",
  "SLIVK",
  "SLIVO",
  "SLJUD",
  "SLOAN",
  "SLOČI",
  "SLOČL",
  "SLOČU",
  "SLOGA",
  "SLOGE",
  "SLOGI",
  "SLOGO",
  "SLOGU",
  "SLOJA",
  "SLOJE",
  "SLOJI",
  "SLOJU",
  "SLOKA",
  "SLOKE",
  "SLOKI",
  "SLOKO",
  "SLOMA",
  "SLOME",
  "SLOMI",
  "SLOMK",
  "SLOMU",
  "SLONA",
  "SLONE",
  "SLONI",
  "SLONU",
  "SLOOT",
  "SLOPA",
  "SLOPE",
  "SLOPI",
  "SLOPU",
  "SLORI",
  "SLORK",
  "SLOSS",
  "SLOTA",
  "SLOTT",
  "SLOTU",
  "SLOVA",
  "SLOVE",
  "SLOVI",
  "SLOVK",
  "SLOVO",
  "SLOVŠ",
  "SLOVU",
  "SLUGA",
  "SLUGE",
  "SLUGI",
  "SLUGO",
  "SLUGU",
  "SLUHA",
  "SLUHI",
  "SLUHU",
  "SLUKA",
  "SLUKE",
  "SLUKI",
  "SLUKO",
  "SLUKU",
  "SLUMA",
  "SLUME",
  "SLUMI",
  "SLUMU",
  "SLUNJ",
  "SLUPA",
  "SLUPE",
  "SLUPI",
  "SLUPU",
  "SLUŠA",
  "SLUŠI",
  "SLUŠU",
  "SLUTI",
  "SLUTU",
  "SLUZA",
  "SLUZI",
  "SLUZU",
  "SLUŽB",
  "SLUŽI",
  "SLUŽU",
  "SMAIL",
  "SMAJA",
  "SMAJE",
  "SMAJI",
  "SMAJK",
  "SMAJL",
  "SMAJO",
  "SMAJU",
  "SMAKA",
  "SMAKE",
  "SMAKI",
  "SMAKO",
  "SMAKU",
  "SMALL",
  "SMALT",
  "SMART",
  "SMAST",
  "SMEAD",
  "SMEDT",
  "SMEED",
  "SMEHA",
  "SMEHC",
  "SMEHE",
  "SMEHI",
  "SMEHU",
  "SMEJA",
  "SMEJE",
  "SMEJI",
  "SMEJO",
  "SMEJU",
  "SMELA",
  "SMELE",
  "SMELI",
  "SMELO",
  "SMELT",
  "SMEMO",
  "SMERI",
  "SMEŠI",
  "SMEŠU",
  "SMETA",
  "SMETE",
  "SMETI",
  "SMETS",
  "SMETU",
  "SMEVA",
  "SMIDT",
  "SMILI",
  "SMILJ",
  "SMILU",
  "SMINK",
  "SMISL",
  "SMITA",
  "SMITE",
  "SMITH",
  "SMITI",
  "SMITS",
  "SMITU",
  "SMOCK",
  "SMODA",
  "SMODE",
  "SMODI",
  "SMODU",
  "SMOGA",
  "SMOGE",
  "SMOGI",
  "SMOGU",
  "SMOKA",
  "SMOKE",
  "SMOKI",
  "SMOKU",
  "SMOLA",
  "SMOLC",
  "SMOLE",
  "SMOLI",
  "SMOLK",
  "SMOLO",
  "SMOLU",
  "SMOOT",
  "SMOTK",
  "SMRAD",
  "SMRAT",
  "SMRČE",
  "SMRČI",
  "SMRČU",
  "SMRDE",
  "SMRDI",
  "SMRDU",
  "SMREK",
  "SMRIJ",
  "SMRJA",
  "SMRJE",
  "SMRJU",
  "SMRKA",
  "SMRKC",
  "SMRKE",
  "SMRKI",
  "SMRKU",
  "SMRTI",
  "SMUČA",
  "SMUČE",
  "SMUČI",
  "SMUČK",
  "SMUČO",
  "SMUČU",
  "SMUIN",
  "SMUKA",
  "SMUKC",
  "SMUKE",
  "SMUKI",
  "SMUKO",
  "SMUKU",
  "SMUTI",
  "SMUTS",
  "SNABE",
  "SNAGA",
  "SNAGE",
  "SNAGI",
  "SNAGO",
  "SNAHA",
  "SNAHE",
  "SNAHI",
  "SNAHO",
  "SNAIL",
  "SNAME",
  "SNAPA",
  "SNAPE",
  "SNAPI",
  "SNAPP",
  "SNAPU",
  "SNAST",
  "SNAŽI",
  "SNAŽU",
  "SNEAD",
  "SNEDE",
  "SNEDI",
  "SNEDO",
  "SNEDU",
  "SNEED",
  "SNEEP",
  "SNEGA",
  "SNEGI",
  "SNEGU",
  "SNEJO",
  "SNELA",
  "SNELE",
  "SNELI",
  "SNELL",
  "SNELO",
  "SNEMA",
  "SNEME",
  "SNEMI",
  "SNEMO",
  "SNEMU",
  "SNENC",
  "SNEST",
  "SNETA",
  "SNETC",
  "SNETE",
  "SNETI",
  "SNETO",
  "SNEVA",
  "SNEVE",
  "SNEVI",
  "SNEŽI",
  "SNEŽK",
  "SNEŽU",
  "SNIDE",
  "SNIDI",
  "SNIFA",
  "SNIFE",
  "SNIFI",
  "SNIFU",
  "SNIPA",
  "SNIPE",
  "SNIPI",
  "SNIPU",
  "SNITI",
  "SNOAD",
  "SNOBA",
  "SNOBE",
  "SNOBI",
  "SNOBU",
  "SNOČI",
  "SNOJA",
  "SNOJE",
  "SNOJI",
  "SNOJU",
  "SNOMA",
  "SNOOK",
  "SNOPA",
  "SNOPE",
  "SNOPI",
  "SNOPU",
  "SNOTA",
  "SNOTU",
  "SNOVI",
  "SNOVU",
  "SNUBC",
  "SNUBI",
  "SNUBO",
  "SNUBU",
  "SNUFF",
  "SNUJE",
  "SNULA",
  "SNULE",
  "SNULI",
  "SNULO",
  "SNUTA",
  "SNUTE",
  "SNUTI",
  "SNUTO",
  "SOANA",
  "SOANE",
  "SOANI",
  "SOANS",
  "SOANU",
  "SOAVE",
  "SOAVI",
  "SOBAH",
  "SOBAM",
  "SOBAN",
  "SOBAR",
  "SOBEL",
  "SOBEN",
  "SOBIC",
  "SOBIK",
  "SOBIN",
  "SOBKA",
  "SOBKE",
  "SOBKI",
  "SOBKO",
  "SOBKU",
  "SOBLA",
  "SOBLE",
  "SOBLI",
  "SOBLU",
  "SOBNA",
  "SOBNE",
  "SOBNI",
  "SOBNO",
  "SOBOL",
  "SOBON",
  "SOBOT",
  "SOBRA",
  "SOBRE",
  "SOBRI",
  "SOBRO",
  "SOCCI",
  "SOCKA",
  "SOCKE",
  "SOCKI",
  "SOCKO",
  "SOCKU",
  "SOČAN",
  "SOČEČ",
  "SOČEN",
  "SOČIČ",
  "SOČIL",
  "SOČIM",
  "SOČIŠ",
  "SOČIT",
  "SOČIU",
  "SOČIV",
  "SOČNA",
  "SOČNE",
  "SOČNI",
  "SOČNO",
  "SODAH",
  "SODAM",
  "SODAN",
  "SODAR",
  "SODAV",
  "SODBA",
  "SODBE",
  "SODBI",
  "SODBO",
  "SODCA",
  "SODCE",
  "SODCI",
  "SODCU",
  "SODEC",
  "SODEČ",
  "SODEL",
  "SODEM",
  "SODEN",
  "SODEV",
  "SODGA",
  "SODIČ",
  "SODIH",
  "SODIJ",
  "SODIL",
  "SODIM",
  "SODIN",
  "SODIŠ",
  "SODIT",
  "SODIU",
  "SODIV",
  "SODJA",
  "SODJE",
  "SODJI",
  "SODJO",
  "SODJU",
  "SODNA",
  "SODNE",
  "SODNI",
  "SODNO",
  "SODOM",
  "SODOV",
  "SODRA",
  "SODRE",
  "SODRG",
  "SODRI",
  "SODRO",
  "SOEDA",
  "SOEDE",
  "SOEDI",
  "SOEDO",
  "SOEDU",
  "SOELL",
  "SOFIA",
  "SOFIE",
  "SOFII",
  "SOFIJ",
  "SOFIK",
  "SOFIO",
  "SOFIT",
  "SOFOR",
  "SOGLO",
  "SOGOL",
  "SOHAH",
  "SOHAM",
  "SOHAR",
  "SOHIC",
  "SOHMA",
  "SOHME",
  "SOHMI",
  "SOHMU",
  "SOHNA",
  "SOHNE",
  "SOHNI",
  "SOHNU",
  "SOIJO",
  "SOINI",
  "SOJAK",
  "SOJAR",
  "SOJČA",
  "SOJČE",
  "SOJČI",
  "SOJČU",
  "SOJEK",
  "SOJEM",
  "SOJEN",
  "SOJER",
  "SOJEV",
  "SOJIH",
  "SOJIN",
  "SOJJA",
  "SOJJU",
  "SOJKA",
  "SOJKE",
  "SOJKI",
  "SOJKO",
  "SOJKU",
  "SOJOM",
  "SOJOV",
  "SOJUZ",
  "SOKAČ",
  "SOKAL",
  "SOKAR",
  "SOKEM",
  "SOKEV",
  "SOKIH",
  "SOKJA",
  "SOKJU",
  "SOKKA",
  "SOKKE",
  "SOKKI",
  "SOKKU",
  "SOKOL",
  "SOKOM",
  "SOKOT",
  "SOKOV",
  "SOLAH",
  "SOLAK",
  "SOLAL",
  "SOLAM",
  "SOLAN",
  "SOLAR",
  "SOLAT",
  "SOLCE",
  "SOLDA",
  "SOLDE",
  "SOLDI",
  "SOLDO",
  "SOLDT",
  "SOLDU",
  "SOLEČ",
  "SOLEH",
  "SOLEM",
  "SOLEN",
  "SOLER",
  "SOLES",
  "SOLET",
  "SOLEV",
  "SOLID",
  "SOLIH",
  "SOLIL",
  "SOLIM",
  "SOLIN",
  "SOLIS",
  "SOLIŠ",
  "SOLIT",
  "SOLIU",
  "SOLIV",
  "SOLJA",
  "SOLJE",
  "SOLJO",
  "SOLJU",
  "SOLLI",
  "SOLMA",
  "SOLME",
  "SOLMI",
  "SOLMS",
  "SOLMU",
  "SOLNA",
  "SOLNC",
  "SOLNE",
  "SOLNI",
  "SOLNO",
  "SOLOM",
  "SOLON",
  "SOLOV",
  "SOLTA",
  "SOLTE",
  "SOLTI",
  "SOLTU",
  "SOLUM",
  "SOLUN",
  "SOLVA",
  "SOLVE",
  "SOLVI",
  "SOLVO",
  "SOLVU",
  "SOLZA",
  "SOLZE",
  "SOLZI",
  "SOLZO",
  "SOLZU",
  "SOMAN",
  "SOMEM",
  "SOMEV",
  "SOMIC",
  "SOMIČ",
  "SOMIH",
  "SOMIN",
  "SOMJA",
  "SOMJU",
  "SOMMA",
  "SOMME",
  "SOMMI",
  "SOMMU",
  "SOMOM",
  "SOMOV",
  "SOMOZ",
  "SOMUN",
  "SONAK",
  "SONAR",
  "SONAT",
  "SONCA",
  "SONCE",
  "SONCI",
  "SONCK",
  "SONCU",
  "SONČI",
  "SONČL",
  "SONČU",
  "SONDA",
  "SONDE",
  "SONDI",
  "SONDO",
  "SONET",
  "SONGA",
  "SONGE",
  "SONGI",
  "SONGU",
  "SONIA",
  "SONIE",
  "SONIH",
  "SONII",
  "SONIJ",
  "SONIL",
  "SONIO",
  "SONJA",
  "SONJE",
  "SONJI",
  "SONJO",
  "SONJU",
  "SONKA",
  "SONKE",
  "SONKI",
  "SONKO",
  "SONKU",
  "SONNI",
  "SONOD",
  "SONOM",
  "SONOV",
  "SONSA",
  "SONSU",
  "SOOČA",
  "SOOČI",
  "SOOČL",
  "SOOČU",
  "SOODA",
  "SOODE",
  "SOODI",
  "SOODU",
  "SOOKA",
  "SOOKE",
  "SOOKI",
  "SOOKU",
  "SOONA",
  "SOONE",
  "SOONI",
  "SOONU",
  "SOPAJ",
  "SOPAR",
  "SOPEČ",
  "SOPEL",
  "SOPEM",
  "SOPEŠ",
  "SOPEU",
  "SOPIH",
  "SOPKA",
  "SOPKE",
  "SOPKI",
  "SOPKO",
  "SOPKU",
  "SOPLA",
  "SOPLE",
  "SOPLI",
  "SOPLO",
  "SOPNE",
  "SOPNI",
  "SOPNU",
  "SOPOT",
  "SOPPA",
  "SOPPE",
  "SOPPI",
  "SOPPU",
  "SOPST",
  "SOPUH",
  "SORAJ",
  "SORAL",
  "SORAN",
  "SORBA",
  "SORBE",
  "SORBI",
  "SORBO",
  "SORBU",
  "SORCI",
  "SORČA",
  "SORČE",
  "SORČI",
  "SORČU",
  "SORDA",
  "SORDE",
  "SORDI",
  "SORDO",
  "SORDU",
  "SOREL",
  "SOREN",
  "SORGA",
  "SORGE",
  "SORGI",
  "SORGO",
  "SORGU",
  "SORIA",
  "SORIE",
  "SORII",
  "SORIJ",
  "SORIN",
  "SORIO",
  "SORIU",
  "SORJU",
  "SORKA",
  "SORKE",
  "SORKI",
  "SORKO",
  "SORKU",
  "SOROD",
  "SOROL",
  "SOROS",
  "SOROT",
  "SORSA",
  "SORSE",
  "SORSI",
  "SORSO",
  "SORSU",
  "SORTA",
  "SORTE",
  "SORTI",
  "SORTO",
  "SORUM",
  "SOSAH",
  "SOSAM",
  "SOSED",
  "SOSET",
  "SOSIČ",
  "SOSIE",
  "SOSIH",
  "SOSIN",
  "SOSJA",
  "SOSJU",
  "SOSOL",
  "SOSOM",
  "SOSOV",
  "SOSSA",
  "SOSSE",
  "SOSSI",
  "SOSSO",
  "SOSSU",
  "SOŠEN",
  "SOŠIC",
  "SOŠKA",
  "SOŠKE",
  "SOŠKI",
  "SOŠKO",
  "SOŠNA",
  "SOŠNE",
  "SOŠNI",
  "SOŠNO",
  "SOŠOL",
  "SOTAH",
  "SOTAM",
  "SOTIH",
  "SOTIR",
  "SOTJA",
  "SOTJU",
  "SOTLA",
  "SOTLE",
  "SOTLI",
  "SOTLO",
  "SOTOM",
  "SOTOV",
  "SOTTA",
  "SOTTE",
  "SOTTI",
  "SOTTO",
  "SOTTU",
  "SOUAD",
  "SOUFI",
  "SOULA",
  "SOULE",
  "SOULI",
  "SOULT",
  "SOULU",
  "SOUSA",
  "SOUSE",
  "SOUSI",
  "SOUSO",
  "SOUSU",
  "SOUTA",
  "SOUTE",
  "SOUTH",
  "SOUTI",
  "SOUTO",
  "SOUTU",
  "SOUZA",
  "SOUZE",
  "SOUZI",
  "SOUZO",
  "SOUZU",
  "SOVAH",
  "SOVAM",
  "SOVCA",
  "SOVCE",
  "SOVCI",
  "SOVCU",
  "SOVEC",
  "SOVEN",
  "SOVET",
  "SOVIC",
  "SOVIČ",
  "SOVIN",
  "SOVIR",
  "SOVJA",
  "SOVJE",
  "SOVJI",
  "SOVJO",
  "SOVKA",
  "SOVKE",
  "SOVKI",
  "SOVKO",
  "SOVRA",
  "SOVRE",
  "SOVRI",
  "SOVRO",
  "SOZAH",
  "SOZAM",
  "SOZDA",
  "SOZDE",
  "SOZDI",
  "SOZDU",
  "SOŽAH",
  "SOŽAM",
  "SOŽEM",
  "SOŽEV",
  "SOŽIH",
  "SOŽJA",
  "SOŽJU",
  "SOŽOM",
  "SOŽOV",
  "SPAAK",
  "SPAAN",
  "SPACA",
  "SPACE",
  "SPACI",
  "SPACK",
  "SPACU",
  "SPAČI",
  "SPAČL",
  "SPAČU",
  "SPADA",
  "SPADE",
  "SPADI",
  "SPADO",
  "SPADT",
  "SPADU",
  "SPAHA",
  "SPAHE",
  "SPAHI",
  "SPAHN",
  "SPAHO",
  "SPAHU",
  "SPAIN",
  "SPAJA",
  "SPAJI",
  "SPAJK",
  "SPAJU",
  "SPAKA",
  "SPAKC",
  "SPAKE",
  "SPAKI",
  "SPAKO",
  "SPAKU",
  "SPALA",
  "SPALE",
  "SPALI",
  "SPALK",
  "SPALL",
  "SPALO",
  "SPAMA",
  "SPAMI",
  "SPAMU",
  "SPANA",
  "SPANC",
  "SPANE",
  "SPANG",
  "SPANI",
  "SPANJ",
  "SPANN",
  "SPANO",
  "SPANU",
  "SPARA",
  "SPARE",
  "SPARI",
  "SPARK",
  "SPARO",
  "SPARU",
  "SPATA",
  "SPATE",
  "SPATI",
  "SPATO",
  "SPATU",
  "SPEAK",
  "SPECI",
  "SPECK",
  "SPEČA",
  "SPEČE",
  "SPEČI",
  "SPEČO",
  "SPEČT",
  "SPEČU",
  "SPEED",
  "SPEER",
  "SPEHA",
  "SPEHI",
  "SPEHU",
  "SPEIT",
  "SPEJO",
  "SPEKA",
  "SPEKE",
  "SPEKI",
  "SPEKU",
  "SPELA",
  "SPELE",
  "SPELI",
  "SPELL",
  "SPELO",
  "SPEMA",
  "SPEME",
  "SPEMO",
  "SPENI",
  "SPENS",
  "SPENU",
  "SPERA",
  "SPERE",
  "SPERI",
  "SPERL",
  "SPERM",
  "SPERO",
  "SPERR",
  "SPERU",
  "SPETA",
  "SPETE",
  "SPETH",
  "SPETI",
  "SPETK",
  "SPETO",
  "SPETT",
  "SPEVA",
  "SPEVE",
  "SPEVI",
  "SPEVU",
  "SPHAJ",
  "SPHAL",
  "SPHAM",
  "SPHAN",
  "SPHAŠ",
  "SPHAT",
  "SPHAU",
  "SPHAV",
  "SPHOR",
  "SPICE",
  "SPIČI",
  "SPIČL",
  "SPIČU",
  "SPIDA",
  "SPIDI",
  "SPIDU",
  "SPIEL",
  "SPIER",
  "SPIES",
  "SPIHA",
  "SPIHI",
  "SPIHU",
  "SPIJE",
  "SPIJO",
  "SPIKA",
  "SPIKE",
  "SPIKI",
  "SPIKU",
  "SPILA",
  "SPILE",
  "SPILI",
  "SPILL",
  "SPILO",
  "SPILU",
  "SPIMA",
  "SPIME",
  "SPIMO",
  "SPINA",
  "SPINE",
  "SPINI",
  "SPINK",
  "SPINO",
  "SPINU",
  "SPIRA",
  "SPIRE",
  "SPIRI",
  "SPIRO",
  "SPIRU",
  "SPISA",
  "SPISE",
  "SPISI",
  "SPISU",
  "SPIŠE",
  "SPIŠI",
  "SPITA",
  "SPITE",
  "SPITI",
  "SPITT",
  "SPITU",
  "SPITZ",
  "SPIVA",
  "SPIVE",
  "SPIVI",
  "SPLAV",
  "SPLET",
  "SPLIT",
  "SPLOH",
  "SPNEM",
  "SPNEŠ",
  "SPOCK",
  "SPOČL",
  "SPOČU",
  "SPODI",
  "SPODU",
  "SPOHA",
  "SPOHI",
  "SPOHR",
  "SPOHU",
  "SPOJA",
  "SPOJE",
  "SPOJI",
  "SPOJK",
  "SPOJU",
  "SPOKA",
  "SPOKI",
  "SPOKU",
  "SPOLA",
  "SPOLE",
  "SPOLI",
  "SPOLU",
  "SPONA",
  "SPONE",
  "SPONI",
  "SPONK",
  "SPONO",
  "SPORA",
  "SPORE",
  "SPORI",
  "SPORK",
  "SPORN",
  "SPORO",
  "SPORU",
  "SPOTA",
  "SPOTE",
  "SPOTI",
  "SPOTO",
  "SPOTU",
  "SPOVE",
  "SPRAL",
  "SPRAN",
  "SPRAT",
  "SPRAU",
  "SPRAV",
  "SPRED",
  "SPREG",
  "SPREJ",
  "SPREM",
  "SPREŠ",
  "SPRET",
  "SPRIK",
  "SPRLA",
  "SPRLE",
  "SPRLI",
  "SPRLO",
  "SPRTA",
  "SPRTE",
  "SPRTI",
  "SPRTO",
  "SPRUK",
  "SPRVA",
  "SPŠEM",
  "SPŠEŠ",
  "SPUCA",
  "SPUCI",
  "SPUCU",
  "SPUFA",
  "SPUFI",
  "SPUFU",
  "SPUHL",
  "SPURS",
  "SPUST",
  "SRAGA",
  "SRAGE",
  "SRAGI",
  "SRAGO",
  "SRAJC",
  "SRAKA",
  "SRAKE",
  "SRAKI",
  "SRAKO",
  "SRAKU",
  "SRALA",
  "SRALE",
  "SRALI",
  "SRALO",
  "SRAMA",
  "SRAMI",
  "SRAMU",
  "SRANJ",
  "SRATI",
  "SRBAH",
  "SRBAM",
  "SRBCA",
  "SRBCE",
  "SRBCI",
  "SRBCU",
  "SRBEC",
  "SRBEČ",
  "SRBEL",
  "SRBET",
  "SRBEU",
  "SRBEŽ",
  "SRBIH",
  "SRBIK",
  "SRBIM",
  "SRBIŠ",
  "SRBJA",
  "SRBJU",
  "SRBOM",
  "SRBOV",
  "SRCAH",
  "SRCAM",
  "SRCEM",
  "SRCEV",
  "SRCIH",
  "SRCOM",
  "SRCOV",
  "SRČEC",
  "SRČEČ",
  "SRČEK",
  "SRČEM",
  "SRČEN",
  "SRČEŠ",
  "SRČEV",
  "SRČIK",
  "SRČIL",
  "SRČIM",
  "SRČIŠ",
  "SRČIT",
  "SRČIU",
  "SRČIV",
  "SRČKA",
  "SRČKE",
  "SRČKI",
  "SRČKU",
  "SRČNA",
  "SRČNE",
  "SRČNI",
  "SRČNO",
  "SRDAN",
  "SRDEČ",
  "SRDEM",
  "SRDEN",
  "SRDEV",
  "SRDIČ",
  "SRDIH",
  "SRDIL",
  "SRDIM",
  "SRDIŠ",
  "SRDIT",
  "SRDIU",
  "SRDIV",
  "SRDJA",
  "SRDJU",
  "SRDOČ",
  "SRDOM",
  "SRDOV",
  "SREBA",
  "SREBI",
  "SREBU",
  "SREČA",
  "SREČE",
  "SREČI",
  "SREČK",
  "SREČO",
  "SREČU",
  "SREDA",
  "SREDE",
  "SREDI",
  "SREDO",
  "SREMA",
  "SREMU",
  "SRENA",
  "SRENC",
  "SRENE",
  "SRENI",
  "SRENJ",
  "SRENU",
  "SREPA",
  "SREPE",
  "SREPI",
  "SREPO",
  "SREPU",
  "SRESA",
  "SREŠA",
  "SREŠE",
  "SREŠI",
  "SREŠU",
  "SRETA",
  "SRETE",
  "SRETI",
  "SRETO",
  "SRETU",
  "SREZA",
  "SREZE",
  "SREZI",
  "SREZU",
  "SREŽA",
  "SREŽE",
  "SREŽI",
  "SREŽU",
  "SRFAJ",
  "SRFAL",
  "SRFAM",
  "SRFAR",
  "SRFAŠ",
  "SRFAT",
  "SRFAU",
  "SRFAV",
  "SRHEK",
  "SRHEM",
  "SRHEV",
  "SRHIH",
  "SRHJA",
  "SRHJU",
  "SRHKA",
  "SRHKE",
  "SRHKI",
  "SRHKO",
  "SRHOJ",
  "SRHOM",
  "SRHOV",
  "SRKAJ",
  "SRKAL",
  "SRKAM",
  "SRKAŠ",
  "SRKAT",
  "SRKAU",
  "SRKAV",
  "SRKEM",
  "SRKEV",
  "SRKIH",
  "SRKJA",
  "SRKJU",
  "SRKNE",
  "SRKNI",
  "SRKNU",
  "SRKOM",
  "SRKOV",
  "SRMIN",
  "SRNAH",
  "SRNAM",
  "SRNIC",
  "SRNIH",
  "SRNIN",
  "SRNJA",
  "SRNJE",
  "SRNJI",
  "SRNJO",
  "SRNJU",
  "SRNOM",
  "SRNOV",
  "SROCK",
  "SRPAK",
  "SRPAN",
  "SRPAT",
  "SRPEK",
  "SRPEM",
  "SRPEV",
  "SRPIC",
  "SRPIČ",
  "SRPIH",
  "SRPIJ",
  "SRPJA",
  "SRPJE",
  "SRPJI",
  "SRPJU",
  "SRPKA",
  "SRPKE",
  "SRPKI",
  "SRPKU",
  "SRPOM",
  "SRPOV",
  "SRSTI",
  "SRŠAH",
  "SRŠAJ",
  "SRŠAL",
  "SRŠAM",
  "SRŠAN",
  "SRŠAT",
  "SRŠAU",
  "SRŠAV",
  "SRŠEČ",
  "SRŠEL",
  "SRŠEM",
  "SRŠEN",
  "SRŠET",
  "SRŠEU",
  "SRŠEV",
  "SRŠIC",
  "SRŠIH",
  "SRŠIL",
  "SRŠIM",
  "SRŠIŠ",
  "SRŠIT",
  "SRŠIU",
  "SRŠIV",
  "SRŠJA",
  "SRŠJU",
  "SRŠOM",
  "SRŠOV",
  "SRTEH",
  "SRTEM",
  "SRTJO",
  "SRTMI",
  "SRUKA",
  "SRUKE",
  "SRUKI",
  "SRUKU",
  "SRŽEH",
  "SRŽEM",
  "SRŽJO",
  "SRŽMI",
  "STAAB",
  "STAAL",
  "STABB",
  "STACA",
  "STACE",
  "STACH",
  "STACI",
  "STACK",
  "STADA",
  "STADE",
  "STADI",
  "STADL",
  "STADO",
  "STAFA",
  "STAFE",
  "STAFF",
  "STAFI",
  "STAFO",
  "STAFU",
  "STAGG",
  "STAHA",
  "STAHE",
  "STAHI",
  "STAHL",
  "STAHO",
  "STAHU",
  "STAIB",
  "STAJA",
  "STAJE",
  "STAJI",
  "STAJK",
  "STAJO",
  "STAJU",
  "STAKE",
  "STALA",
  "STALE",
  "STALF",
  "STALI",
  "STALO",
  "STALU",
  "STAMA",
  "STAME",
  "STAMI",
  "STAMM",
  "STAMO",
  "STAMP",
  "STAMU",
  "STANA",
  "STANC",
  "STANE",
  "STANG",
  "STANI",
  "STANJ",
  "STANK",
  "STANN",
  "STANO",
  "STANT",
  "STANU",
  "STAPF",
  "STAPP",
  "STARA",
  "STARC",
  "STARE",
  "STARI",
  "STARK",
  "STARL",
  "STARO",
  "STARR",
  "STARS",
  "STARŠ",
  "START",
  "STARU",
  "STASA",
  "STASI",
  "STASS",
  "STASU",
  "STAŠA",
  "STAŠE",
  "STAŠI",
  "STAŠK",
  "STAŠO",
  "STAŠU",
  "STATE",
  "STATI",
  "STATO",
  "STAUB",
  "STAUN",
  "STAUP",
  "STAUT",
  "STAVA",
  "STAVB",
  "STAVC",
  "STAVE",
  "STAVI",
  "STAVK",
  "STAVO",
  "STAVU",
  "STAZA",
  "STAZE",
  "STAZI",
  "STAZO",
  "STAŽA",
  "STAŽE",
  "STAŽI",
  "STAŽU",
  "STEAD",
  "STEAN",
  "STECA",
  "STECE",
  "STECI",
  "STECK",
  "STECU",
  "STEČE",
  "STEČI",
  "STEČT",
  "STEED",
  "STEEL",
  "STEEN",
  "STEFA",
  "STEFE",
  "STEFI",
  "STEFK",
  "STEFU",
  "STEGA",
  "STEGE",
  "STEGI",
  "STEGU",
  "STEIG",
  "STEIL",
  "STEIN",
  "STEJK",
  "STEKA",
  "STEKE",
  "STEKI",
  "STEKU",
  "STELA",
  "STELE",
  "STELI",
  "STELL",
  "STELO",
  "STENA",
  "STENE",
  "STENI",
  "STENJ",
  "STENN",
  "STENO",
  "STENT",
  "STENU",
  "STEPA",
  "STEPE",
  "STEPH",
  "STEPI",
  "STEPO",
  "STEPP",
  "STEPU",
  "STERB",
  "STERI",
  "STERN",
  "STESK",
  "STESU",
  "STEŠE",
  "STEŠI",
  "STEVA",
  "STEVE",
  "STEVI",
  "STEVO",
  "STEVU",
  "STEZA",
  "STEZE",
  "STEZI",
  "STEZO",
  "STIAN",
  "STICH",
  "STIEB",
  "STIED",
  "STIER",
  "STIFF",
  "STIGA",
  "STIGE",
  "STIGI",
  "STIGU",
  "STIHA",
  "STIHE",
  "STIHI",
  "STIHL",
  "STIHU",
  "STIJN",
  "STIKA",
  "STIKE",
  "STIKI",
  "STIKS",
  "STIKU",
  "STILA",
  "STILE",
  "STILI",
  "STILL",
  "STILO",
  "STILP",
  "STILU",
  "STINA",
  "STINE",
  "STING",
  "STINI",
  "STINO",
  "STINT",
  "STINU",
  "STIPA",
  "STIPE",
  "STIPI",
  "STIPO",
  "STIPU",
  "STIRK",
  "STIRN",
  "STISA",
  "STISE",
  "STISI",
  "STISK",
  "STISU",
  "STIŠA",
  "STIŠI",
  "STIŠU",
  "STITH",
  "STITT",
  "STIVA",
  "STIVE",
  "STIVI",
  "STIVO",
  "STKAL",
  "STKAM",
  "STKAN",
  "STKAŠ",
  "STKAT",
  "STKAU",
  "STKAV",
  "STKEM",
  "STKEŠ",
  "STOBI",
  "STOCH",
  "STOCK",
  "STOČE",
  "STOČI",
  "STOČL",
  "STOČU",
  "STOEL",
  "STOFF",
  "STOGA",
  "STOGE",
  "STOGI",
  "STOGU",
  "STOHL",
  "STOHN",
  "STOIK",
  "STOJA",
  "STOJC",
  "STOJE",
  "STOJI",
  "STOJK",
  "STOJO",
  "STOJU",
  "STOKA",
  "STOKE",
  "STOKI",
  "STOKO",
  "STOKU",
  "STOLA",
  "STOLC",
  "STOLČ",
  "STOLE",
  "STOLI",
  "STOLL",
  "STOLP",
  "STOLT",
  "STOLU",
  "STOLZ",
  "STOMA",
  "STOME",
  "STOMI",
  "STOMO",
  "STOMU",
  "STONA",
  "STONE",
  "STONG",
  "STONI",
  "STONT",
  "STONU",
  "STOOK",
  "STOPA",
  "STOPC",
  "STOPČ",
  "STOPE",
  "STOPH",
  "STOPI",
  "STOPO",
  "STOPP",
  "STOPU",
  "STORA",
  "STORE",
  "STORI",
  "STORK",
  "STORM",
  "STORP",
  "STORR",
  "STORU",
  "STORZ",
  "STORŽ",
  "STOSS",
  "STOTA",
  "STOTE",
  "STOTI",
  "STOTO",
  "STOTT",
  "STOTU",
  "STOUT",
  "STOŽA",
  "STOŽC",
  "STOŽE",
  "STOŽI",
  "STOŽK",
  "STOŽU",
  "STRAH",
  "STRAK",
  "STRAM",
  "STRAN",
  "STRAV",
  "STRAZ",
  "STRAŽ",
  "STRDE",
  "STRDI",
  "STRDU",
  "STREČ",
  "STREH",
  "STREL",
  "STREM",
  "STRES",
  "STREŠ",
  "STRET",
  "STRGA",
  "STRGI",
  "STRGU",
  "STRIC",
  "STRIČ",
  "STRIG",
  "STRIJ",
  "STRIK",
  "STRIN",
  "STRIP",
  "STRIŽ",
  "STRKA",
  "STRKI",
  "STRKU",
  "STRLA",
  "STRLE",
  "STRLI",
  "STRLO",
  "STRMA",
  "STRMC",
  "STRME",
  "STRMI",
  "STRMO",
  "STRMU",
  "STRNA",
  "STRNE",
  "STRNI",
  "STRNU",
  "STROF",
  "STROG",
  "STROH",
  "STROJ",
  "STROK",
  "STROM",
  "STROP",
  "STRPA",
  "STRPE",
  "STRPI",
  "STRPU",
  "STRTA",
  "STRTE",
  "STRTI",
  "STRTO",
  "STRUB",
  "STRUC",
  "STRUG",
  "STRUJ",
  "STRUM",
  "STRUN",
  "STRUP",
  "STRUS",
  "STRŽE",
  "STUAN",
  "STUBB",
  "STUCK",
  "STUDA",
  "STUDI",
  "STUDT",
  "STUDU",
  "STUHR",
  "STUJA",
  "STUJE",
  "STUJI",
  "STUJU",
  "STUMM",
  "STUMP",
  "STURE",
  "STURL",
  "STURM",
  "STURT",
  "STUTE",
  "STUTT",
  "STUTZ",
  "STVAR",
  "STVOR",
  "SUADA",
  "SUADE",
  "SUADI",
  "SUADO",
  "SUADU",
  "SUARD",
  "SUBAN",
  "SUCCI",
  "SUCRE",
  "SUČEM",
  "SUČEN",
  "SUČEŠ",
  "SUČNA",
  "SUČNE",
  "SUČNI",
  "SUČNO",
  "SUDAH",
  "SUDAM",
  "SUDAN",
  "SUDAR",
  "SUDER",
  "SUDIE",
  "SUDIH",
  "SUDJA",
  "SUDJU",
  "SUDOL",
  "SUDOM",
  "SUDOV",
  "SUDRA",
  "SUDRE",
  "SUDRI",
  "SUDRU",
  "SUEČA",
  "SUEČE",
  "SUEČI",
  "SUEČU",
  "SUEDA",
  "SUEDE",
  "SUEDI",
  "SUEDU",
  "SUELA",
  "SUELE",
  "SUELI",
  "SUELL",
  "SUELO",
  "SUESS",
  "SUEZA",
  "SUEZU",
  "SUFFA",
  "SUFFE",
  "SUFFI",
  "SUFFU",
  "SUFLE",
  "SUGAH",
  "SUGAM",
  "SUGAR",
  "SUGGA",
  "SUGGE",
  "SUGGI",
  "SUGGS",
  "SUGGU",
  "SUGIH",
  "SUGIT",
  "SUGJA",
  "SUGJU",
  "SUGOM",
  "SUGON",
  "SUGOV",
  "SUHAČ",
  "SUHAR",
  "SUHCA",
  "SUHCE",
  "SUHCI",
  "SUHCU",
  "SUHEC",
  "SUHEM",
  "SUHGA",
  "SUHIC",
  "SUHIH",
  "SUHIM",
  "SUHMA",
  "SUHME",
  "SUHMI",
  "SUHMU",
  "SUHOJ",
  "SUHOR",
  "SUHOT",
  "SUITA",
  "SUITE",
  "SUITI",
  "SUITO",
  "SUITS",
  "SUITT",
  "SUJAT",
  "SUJEM",
  "SUJEŠ",
  "SUJIN",
  "SUJMO",
  "SUJTA",
  "SUJTE",
  "SUJVA",
  "SUKAČ",
  "SUKAJ",
  "SUKAL",
  "SUKAM",
  "SUKAN",
  "SUKAŠ",
  "SUKAT",
  "SUKAU",
  "SUKAV",
  "SUKIČ",
  "SUKIE",
  "SUKIH",
  "SUKJA",
  "SUKJU",
  "SUKNA",
  "SUKNO",
  "SUKNU",
  "SUKOM",
  "SUKOV",
  "SUKUB",
  "SUKUS",
  "SULAH",
  "SULAJ",
  "SULAM",
  "SULCA",
  "SULCE",
  "SULCI",
  "SULCU",
  "SULEC",
  "SULIC",
  "SULIČ",
  "SULIH",
  "SULIK",
  "SULJA",
  "SULJU",
  "SULKI",
  "SULOM",
  "SULOV",
  "SUMAH",
  "SUMAM",
  "SUMAN",
  "SUMAR",
  "SUMEČ",
  "SUMEJ",
  "SUMEM",
  "SUMEV",
  "SUMIH",
  "SUMIJ",
  "SUMIL",
  "SUMIM",
  "SUMIN",
  "SUMIŠ",
  "SUMIT",
  "SUMIU",
  "SUMIV",
  "SUMJA",
  "SUMJU",
  "SUMOM",
  "SUMOV",
  "SUNAH",
  "SUNAK",
  "SUNAM",
  "SUNAR",
  "SUNDA",
  "SUNDE",
  "SUNDI",
  "SUNDL",
  "SUNDT",
  "SUNDU",
  "SUNEK",
  "SUNEM",
  "SUNEŠ",
  "SUNGA",
  "SUNGE",
  "SUNGI",
  "SUNGU",
  "SUNIL",
  "SUNIN",
  "SUNIS",
  "SUNIT",
  "SUNIU",
  "SUNIV",
  "SUNJA",
  "SUNJU",
  "SUNKA",
  "SUNKE",
  "SUNKI",
  "SUNKO",
  "SUNKU",
  "SUNNI",
  "SUNOM",
  "SUNOV",
  "SUNSE",
  "SUNSI",
  "SUONA",
  "SUONE",
  "SUONI",
  "SUONU",
  "SUPAJ",
  "SUPAL",
  "SUPAM",
  "SUPAN",
  "SUPAŠ",
  "SUPAT",
  "SUPAU",
  "SUPAV",
  "SUPEJ",
  "SUPEM",
  "SUPER",
  "SUPEV",
  "SUPIH",
  "SUPIN",
  "SUPJA",
  "SUPJU",
  "SUPOM",
  "SUPOV",
  "SUPPA",
  "SUPPE",
  "SUPPI",
  "SUPPO",
  "SUPPU",
  "SURAH",
  "SURAM",
  "SURDA",
  "SURDE",
  "SURDI",
  "SURDU",
  "SURFA",
  "SURFI",
  "SURFU",
  "SURIK",
  "SURIL",
  "SURIN",
  "SUROV",
  "SURSA",
  "SURSU",
  "SUSAK",
  "SUSAN",
  "SUSEN",
  "SUSIČ",
  "SUSIE",
  "SUSIN",
  "SUSSA",
  "SUSSE",
  "SUSSI",
  "SUSSO",
  "SUSSU",
  "SUŠAH",
  "SUŠAK",
  "SUŠAM",
  "SUŠAN",
  "SUŠCA",
  "SUŠCE",
  "SUŠCI",
  "SUŠCU",
  "SUŠEC",
  "SUŠEČ",
  "SUŠEK",
  "SUŠEM",
  "SUŠEN",
  "SUŠEV",
  "SUŠIC",
  "SUŠIH",
  "SUŠIJ",
  "SUŠIL",
  "SUŠIM",
  "SUŠIN",
  "SUŠIŠ",
  "SUŠIT",
  "SUŠIU",
  "SUŠIV",
  "SUŠJA",
  "SUŠJE",
  "SUŠJU",
  "SUŠKA",
  "SUŠKE",
  "SUŠKI",
  "SUŠKO",
  "SUŠKU",
  "SUŠLA",
  "SUŠLE",
  "SUŠLI",
  "SUŠLU",
  "SUŠNA",
  "SUŠNE",
  "SUŠNI",
  "SUŠNO",
  "SUŠOM",
  "SUŠOV",
  "SUTAJ",
  "SUTAN",
  "SUTAR",
  "SUTER",
  "SUTIL",
  "SUTRA",
  "SUTRE",
  "SUTRI",
  "SUTRO",
  "SUTRU",
  "SUTTA",
  "SUTTE",
  "SUTTI",
  "SUTTO",
  "SUTTU",
  "SUVAD",
  "SUVAJ",
  "SUVAL",
  "SUVAM",
  "SUVAN",
  "SUVAŠ",
  "SUVAT",
  "SUVAU",
  "SUVAV",
  "SUZAN",
  "SUZEE",
  "SUZIE",
  "SUZIN",
  "SUŽID",
  "SVAHN",
  "SVAJA",
  "SVAJE",
  "SVAJI",
  "SVAJO",
  "SVAKA",
  "SVAKE",
  "SVAKI",
  "SVAKU",
  "SVANA",
  "SVANE",
  "SVANI",
  "SVANU",
  "SVARI",
  "SVARU",
  "SVAST",
  "SVATA",
  "SVATB",
  "SVATE",
  "SVATI",
  "SVATO",
  "SVATU",
  "SVAVA",
  "SVAVE",
  "SVAVI",
  "SVAVO",
  "SVAZI",
  "SVEAH",
  "SVEAM",
  "SVEČA",
  "SVEČE",
  "SVEČI",
  "SVEČK",
  "SVEČO",
  "SVEEN",
  "SVEIN",
  "SVENA",
  "SVENE",
  "SVENI",
  "SVENJ",
  "SVENU",
  "SVETA",
  "SVETE",
  "SVETI",
  "SVETO",
  "SVETU",
  "SVEUM",
  "SVEVA",
  "SVEVE",
  "SVEVI",
  "SVEVO",
  "SVEŽA",
  "SVEŽC",
  "SVEŽE",
  "SVEŽI",
  "SVEŽO",
  "SVILA",
  "SVILE",
  "SVILI",
  "SVILO",
  "SVINA",
  "SVINC",
  "SVING",
  "SVINJ",
  "SVINO",
  "SVINU",
  "SVIRA",
  "SVIRI",
  "SVIRU",
  "SVISA",
  "SVISE",
  "SVISI",
  "SVISU",
  "SVIŠČ",
  "SVITA",
  "SVITE",
  "SVITI",
  "SVITU",
  "SVIZA",
  "SVIZC",
  "SVIZU",
  "SVIŽA",
  "SVIŽI",
  "SVIŽU",
  "SVODA",
  "SVODE",
  "SVODI",
  "SVODU",
  "SVOJA",
  "SVOJC",
  "SVOJE",
  "SVOJI",
  "SVOJO",
  "SVORA",
  "SVORE",
  "SVORI",
  "SVORO",
  "SVRHA",
  "SVRHE",
  "SVRHI",
  "SVRHO",
  "SZABA",
  "SZABE",
  "SZABI",
  "SZABO",
  "SZABU",
  "SZAJD",
  "SZASZ",
  "SZELL",
  "SZERB",
  "SZETA",
  "SZETE",
  "SZETI",
  "SZETO",
  "SZETU",
  "SZILI",
  "SZMUL",
  "SZOFI",
  "SZOJA",
  "SZOKA",
  "SZOKE",
  "SZOKI",
  "SZOKO",
  "SZOKU",
  "SZOLD",
  "SZPAK",
  "SZSVS",
  "SZTUK",
  "ŠABAB",
  "ŠABAC",
  "ŠABAN",
  "ŠABAT",
  "ŠABCA",
  "ŠABCE",
  "ŠABCI",
  "ŠABCU",
  "ŠABEC",
  "ŠABES",
  "ŠABIČ",
  "ŠACER",
  "ŠADLA",
  "ŠADLE",
  "ŠADLI",
  "ŠADLU",
  "ŠAFAR",
  "ŠAFEL",
  "ŠAFER",
  "ŠAFIK",
  "ŠAFLA",
  "ŠAFLE",
  "ŠAFLI",
  "ŠAFLO",
  "ŠAGUD",
  "ŠAHEM",
  "ŠAHEV",
  "ŠAHID",
  "ŠAHIH",
  "ŠAHIN",
  "ŠAHJA",
  "ŠAHJU",
  "ŠAHOM",
  "ŠAHOV",
  "ŠAHTA",
  "ŠAHTE",
  "ŠAHTI",
  "ŠAHTU",
  "ŠAINA",
  "ŠAINE",
  "ŠAINI",
  "ŠAINU",
  "ŠAJAN",
  "ŠAJBA",
  "ŠAJBE",
  "ŠAJBI",
  "ŠAJBO",
  "ŠAJET",
  "ŠAJIN",
  "ŠAJKA",
  "ŠAJKE",
  "ŠAJKI",
  "ŠAJKO",
  "ŠAJKU",
  "ŠAJNA",
  "ŠAJNE",
  "ŠAJNI",
  "ŠAJNO",
  "ŠAJNU",
  "ŠAJTA",
  "ŠAJTE",
  "ŠAJTI",
  "ŠAJTU",
  "ŠAKAL",
  "ŠAKAN",
  "ŠAKED",
  "ŠAKOT",
  "ŠAKTI",
  "ŠALAH",
  "ŠALAJ",
  "ŠALAM",
  "ŠALCA",
  "ŠALCE",
  "ŠALCI",
  "ŠALCO",
  "ŠALDA",
  "ŠALDE",
  "ŠALDI",
  "ŠALDO",
  "ŠALDU",
  "ŠALEČ",
  "ŠALEJ",
  "ŠALEK",
  "ŠALEM",
  "ŠALEV",
  "ŠALIC",
  "ŠALIH",
  "ŠALIL",
  "ŠALIM",
  "ŠALIŠ",
  "ŠALIT",
  "ŠALIU",
  "ŠALIV",
  "ŠALJA",
  "ŠALJE",
  "ŠALJI",
  "ŠALJO",
  "ŠALJU",
  "ŠALKA",
  "ŠALKE",
  "ŠALKI",
  "ŠALKO",
  "ŠALOM",
  "ŠALOV",
  "ŠALTA",
  "ŠALTI",
  "ŠALTU",
  "ŠALUP",
  "ŠAMAJ",
  "ŠAMAN",
  "ŠAMIL",
  "ŠAMIS",
  "ŠAMJU",
  "ŠAMOT",
  "ŠANAH",
  "ŠANAM",
  "ŠANCA",
  "ŠANCE",
  "ŠANCI",
  "ŠANCO",
  "ŠANCU",
  "ŠANDA",
  "ŠANDE",
  "ŠANDI",
  "ŠANDL",
  "ŠANDO",
  "ŠANDU",
  "ŠANIN",
  "ŠANKA",
  "ŠANKE",
  "ŠANKI",
  "ŠANKO",
  "ŠANKU",
  "ŠANSA",
  "ŠANSE",
  "ŠANSI",
  "ŠANSO",
  "ŠANTA",
  "ŠANTI",
  "ŠANTL",
  "ŠANTU",
  "ŠAPAH",
  "ŠAPAM",
  "ŠAPIC",
  "ŠAPIN",
  "ŠAPKA",
  "ŠAPKE",
  "ŠAPKI",
  "ŠAPKO",
  "ŠAPNE",
  "ŠAPNI",
  "ŠAPNU",
  "ŠARAC",
  "ŠARAD",
  "ŠARAR",
  "ŠARCA",
  "ŠARCE",
  "ŠARCI",
  "ŠARCU",
  "ŠARDI",
  "ŠAREC",
  "ŠAREČ",
  "ŠARED",
  "ŠAREM",
  "ŠARFA",
  "ŠARFE",
  "ŠARFI",
  "ŠARFU",
  "ŠARGA",
  "ŠARGE",
  "ŠARGI",
  "ŠARGU",
  "ŠARHA",
  "ŠARHE",
  "ŠARHI",
  "ŠARHU",
  "ŠARIČ",
  "ŠARIF",
  "ŠARIL",
  "ŠARIM",
  "ŠARIŠ",
  "ŠARIT",
  "ŠARIU",
  "ŠARIV",
  "ŠARKA",
  "ŠARKE",
  "ŠARKI",
  "ŠARKO",
  "ŠARKU",
  "ŠARMA",
  "ŠARMI",
  "ŠARMU",
  "ŠARON",
  "ŠARTA",
  "ŠARTE",
  "ŠARTI",
  "ŠARTU",
  "ŠARUG",
  "ŠARŽA",
  "ŠARŽE",
  "ŠARŽI",
  "ŠARŽO",
  "ŠASIJ",
  "ŠAŠEK",
  "ŠAŠEL",
  "ŠAŠEM",
  "ŠAŠEV",
  "ŠAŠIH",
  "ŠAŠIN",
  "ŠAŠJA",
  "ŠAŠJE",
  "ŠAŠJU",
  "ŠAŠKA",
  "ŠAŠKE",
  "ŠAŠKI",
  "ŠAŠKO",
  "ŠAŠKU",
  "ŠAŠLA",
  "ŠAŠLE",
  "ŠAŠLI",
  "ŠAŠLU",
  "ŠAŠOM",
  "ŠAŠOV",
  "ŠATAN",
  "ŠATEJ",
  "ŠATIH",
  "ŠATJA",
  "ŠATJU",
  "ŠATOM",
  "ŠATOV",
  "ŠAULA",
  "ŠAULE",
  "ŠAULI",
  "ŠAULO",
  "ŠAULU",
  "ŠAUTA",
  "ŠAUTE",
  "ŠAUTI",
  "ŠAUTO",
  "ŠAUTU",
  "ŠAVCA",
  "ŠAVCE",
  "ŠAVCI",
  "ŠAVCU",
  "ŠAVEL",
  "ŠAVER",
  "ŠAVIH",
  "ŠAVJA",
  "ŠAVJE",
  "ŠAVJU",
  "ŠAVKA",
  "ŠAVKE",
  "ŠAVKI",
  "ŠAVKO",
  "ŠAVKU",
  "ŠAVLA",
  "ŠAVLE",
  "ŠAVLI",
  "ŠAVLU",
  "ŠAVNA",
  "ŠAVNE",
  "ŠAVNI",
  "ŠAVNO",
  "ŠAVOM",
  "ŠAVOR",
  "ŠAVOV",
  "ŠAVRA",
  "ŠAVRE",
  "ŠAVRI",
  "ŠAVRO",
  "ŠAVSA",
  "ŠAVSE",
  "ŠAVSI",
  "ŠAVSU",
  "ŠČAPA",
  "ŠČAPE",
  "ŠČAPI",
  "ŠČAPU",
  "ŠČEGI",
  "ŠČEKA",
  "ŠČEKE",
  "ŠČEKI",
  "ŠČEKU",
  "ŠČEME",
  "ŠČEMI",
  "ŠČEMU",
  "ŠČENE",
  "ŠČEPA",
  "ŠČEPC",
  "ŠČEPE",
  "ŠČEPI",
  "ŠČEPU",
  "ŠČERK",
  "ŠČETI",
  "ŠČETK",
  "ŠČIJE",
  "ŠČIJO",
  "ŠČIMA",
  "ŠČIME",
  "ŠČIMO",
  "ŠČIPA",
  "ŠČIPC",
  "ŠČIPE",
  "ŠČIPI",
  "ŠČIPU",
  "ŠČIRA",
  "ŠČIRE",
  "ŠČIRI",
  "ŠČIRK",
  "ŠČIRU",
  "ŠČITA",
  "ŠČITC",
  "ŠČITE",
  "ŠČITI",
  "ŠČITU",
  "ŠČIVA",
  "ŠČIVE",
  "ŠČIVI",
  "ŠČOČA",
  "ŠČOČE",
  "ŠČOČI",
  "ŠČOČO",
  "ŠČUJE",
  "ŠČUKA",
  "ŠČUKC",
  "ŠČUKE",
  "ŠČUKI",
  "ŠČUKO",
  "ŠČUKU",
  "ŠČURA",
  "ŠČURE",
  "ŠČURI",
  "ŠČURO",
  "ŠČUVA",
  "ŠČUVI",
  "ŠČUVU",
  "ŠEBAT",
  "ŠEBEL",
  "ŠEBIH",
  "ŠEBJA",
  "ŠEBJU",
  "ŠEBOJ",
  "ŠEBOM",
  "ŠEBOV",
  "ŠECTO",
  "ŠEČIČ",
  "ŠEDEM",
  "ŠEDEV",
  "ŠEDIH",
  "ŠEDJA",
  "ŠEDJU",
  "ŠEDMA",
  "ŠEDMU",
  "ŠEDOM",
  "ŠEDOV",
  "ŠEERA",
  "ŠEERE",
  "ŠEERI",
  "ŠEERO",
  "ŠEFEM",
  "ŠEFER",
  "ŠEFEV",
  "ŠEFIC",
  "ŠEFIH",
  "ŠEFIK",
  "ŠEFIN",
  "ŠEFJA",
  "ŠEFJU",
  "ŠEFOM",
  "ŠEFOV",
  "ŠEGAH",
  "ŠEGAM",
  "ŠEGAN",
  "ŠEGAV",
  "ŠEGEL",
  "ŠEGIH",
  "ŠEGIN",
  "ŠEGJA",
  "ŠEGJU",
  "ŠEGLA",
  "ŠEGLE",
  "ŠEGLI",
  "ŠEGLU",
  "ŠEGOM",
  "ŠEGOT",
  "ŠEGOV",
  "ŠEGRT",
  "ŠEGŠA",
  "ŠEGŠE",
  "ŠEGŠI",
  "ŠEGŠU",
  "ŠEGUL",
  "ŠEHEM",
  "ŠEHEV",
  "ŠEHIČ",
  "ŠEHIH",
  "ŠEHJA",
  "ŠEHJU",
  "ŠEHOM",
  "ŠEHOV",
  "ŠEJEK",
  "ŠEJKA",
  "ŠEJKE",
  "ŠEJKI",
  "ŠEJKU",
  "ŠEKEL",
  "ŠEKIH",
  "ŠEKJA",
  "ŠEKJU",
  "ŠEKLA",
  "ŠEKLE",
  "ŠEKLI",
  "ŠEKLU",
  "ŠEKOM",
  "ŠEKOV",
  "ŠEKSA",
  "ŠEKSE",
  "ŠEKSI",
  "ŠEKSU",
  "ŠELAH",
  "ŠELAK",
  "ŠELAM",
  "ŠELBA",
  "ŠELBE",
  "ŠELBI",
  "ŠELBU",
  "ŠELDA",
  "ŠELDE",
  "ŠELDI",
  "ŠELDO",
  "ŠELER",
  "ŠELFA",
  "ŠELFE",
  "ŠELFI",
  "ŠELFU",
  "ŠELIG",
  "ŠELIH",
  "ŠELJA",
  "ŠELJE",
  "ŠELJI",
  "ŠELJU",
  "ŠELOM",
  "ŠELOV",
  "ŠEMAH",
  "ŠEMAM",
  "ŠEMCA",
  "ŠEMCE",
  "ŠEMCI",
  "ŠEMCU",
  "ŠEMEC",
  "ŠEMEČ",
  "ŠEMEN",
  "ŠEMET",
  "ŠEMIC",
  "ŠEMIL",
  "ŠEMIM",
  "ŠEMIN",
  "ŠEMIŠ",
  "ŠEMIT",
  "ŠEMIU",
  "ŠEMIV",
  "ŠEMRL",
  "ŠEMSA",
  "ŠEMSE",
  "ŠEMSI",
  "ŠEMSO",
  "ŠEMSU",
  "ŠENEM",
  "ŠENEV",
  "ŠENIC",
  "ŠENIH",
  "ŠENIJ",
  "ŠENJA",
  "ŠENJU",
  "ŠENKA",
  "ŠENKE",
  "ŠENKI",
  "ŠENKU",
  "ŠENOA",
  "ŠENOE",
  "ŠENOI",
  "ŠENOJ",
  "ŠENOM",
  "ŠENOO",
  "ŠENOU",
  "ŠENOV",
  "ŠEPAJ",
  "ŠEPAL",
  "ŠEPAM",
  "ŠEPAŠ",
  "ŠEPAT",
  "ŠEPAU",
  "ŠEPAV",
  "ŠEPCA",
  "ŠEPCE",
  "ŠEPCI",
  "ŠEPCU",
  "ŠEPEC",
  "ŠEPET",
  "ŠEPIC",
  "ŠEPIČ",
  "ŠEPNE",
  "ŠEPNI",
  "ŠEPNU",
  "ŠERAJ",
  "ŠERAL",
  "ŠERAM",
  "ŠERAN",
  "ŠERAŠ",
  "ŠERAT",
  "ŠERAU",
  "ŠERAV",
  "ŠERBA",
  "ŠERBE",
  "ŠERBI",
  "ŠERBO",
  "ŠERBU",
  "ŠERCA",
  "ŠERCE",
  "ŠERCI",
  "ŠERCU",
  "ŠERIF",
  "ŠERIH",
  "ŠERJA",
  "ŠERJU",
  "ŠERKA",
  "ŠERKE",
  "ŠERKI",
  "ŠERKO",
  "ŠERKU",
  "ŠEROD",
  "ŠEROM",
  "ŠEROV",
  "ŠERPA",
  "ŠERPE",
  "ŠERPI",
  "ŠERPO",
  "ŠERPU",
  "ŠERUG",
  "ŠESTA",
  "ŠESTE",
  "ŠESTI",
  "ŠESTO",
  "ŠESTU",
  "ŠEŠČE",
  "ŠEŠEK",
  "ŠEŠET",
  "ŠEŠIČ",
  "ŠEŠIR",
  "ŠEŠKA",
  "ŠEŠKE",
  "ŠEŠKI",
  "ŠEŠKO",
  "ŠEŠKU",
  "ŠEŠOK",
  "ŠEŠUM",
  "ŠETAJ",
  "ŠETAL",
  "ŠETAM",
  "ŠETAR",
  "ŠETAŠ",
  "ŠETAT",
  "ŠETAU",
  "ŠETAV",
  "ŠETIN",
  "ŠETKA",
  "ŠETKE",
  "ŠETKI",
  "ŠETKO",
  "ŠETKU",
  "ŠEVEL",
  "ŠEVIČ",
  "ŠEVIH",
  "ŠEVJA",
  "ŠEVJU",
  "ŠEVOM",
  "ŠEVOV",
  "ŠIBAH",
  "ŠIBAJ",
  "ŠIBAK",
  "ŠIBAL",
  "ŠIBAM",
  "ŠIBAŠ",
  "ŠIBAT",
  "ŠIBAU",
  "ŠIBAV",
  "ŠIBEČ",
  "ŠIBEK",
  "ŠIBER",
  "ŠIBIC",
  "ŠIBIČ",
  "ŠIBIJ",
  "ŠIBIK",
  "ŠIBIL",
  "ŠIBIM",
  "ŠIBIŠ",
  "ŠIBIT",
  "ŠIBIU",
  "ŠIBIV",
  "ŠIBJA",
  "ŠIBJE",
  "ŠIBJI",
  "ŠIBJU",
  "ŠIBKA",
  "ŠIBKE",
  "ŠIBKI",
  "ŠIBKO",
  "ŠIBLI",
  "ŠIBRA",
  "ŠIBRE",
  "ŠIBRI",
  "ŠIBRO",
  "ŠICAJ",
  "ŠICAL",
  "ŠICAM",
  "ŠICAŠ",
  "ŠICAT",
  "ŠICAU",
  "ŠICAV",
  "ŠICEM",
  "ŠICEV",
  "ŠICIH",
  "ŠICJA",
  "ŠICJU",
  "ŠICOM",
  "ŠICOV",
  "ŠIDJA",
  "ŠIDJU",
  "ŠIDOM",
  "ŠIFER",
  "ŠIFRA",
  "ŠIFRE",
  "ŠIFRI",
  "ŠIFRO",
  "ŠIHTA",
  "ŠIHTE",
  "ŠIHTI",
  "ŠIHTU",
  "ŠIITA",
  "ŠIITE",
  "ŠIITI",
  "ŠIITU",
  "ŠIJAN",
  "ŠIJEM",
  "ŠIJEV",
  "ŠIJJA",
  "ŠIJJU",
  "ŠIJOM",
  "ŠIJOV",
  "ŠIKAJ",
  "ŠIKAL",
  "ŠIKAM",
  "ŠIKAN",
  "ŠIKAŠ",
  "ŠIKAT",
  "ŠIKAU",
  "ŠIKAV",
  "ŠIKIH",
  "ŠIKJA",
  "ŠIKJU",
  "ŠIKOL",
  "ŠIKOM",
  "ŠIKOV",
  "ŠILAH",
  "ŠILAK",
  "ŠILAM",
  "ŠILAR",
  "ŠILCA",
  "ŠILCE",
  "ŠILCI",
  "ŠILCU",
  "ŠILEC",
  "ŠILEČ",
  "ŠILER",
  "ŠILIH",
  "ŠILIL",
  "ŠILIM",
  "ŠILIŠ",
  "ŠILIT",
  "ŠILIU",
  "ŠILIV",
  "ŠILOM",
  "ŠILOV",
  "ŠILTA",
  "ŠILTE",
  "ŠILTI",
  "ŠILTU",
  "ŠIMAC",
  "ŠIMAH",
  "ŠIMAM",
  "ŠIMAT",
  "ŠIMCA",
  "ŠIMCE",
  "ŠIMCI",
  "ŠIMCU",
  "ŠIMEC",
  "ŠIMEN",
  "ŠIMFA",
  "ŠIMFI",
  "ŠIMFU",
  "ŠIMIC",
  "ŠIMIČ",
  "ŠIMIH",
  "ŠIMJA",
  "ŠIMJU",
  "ŠIMKA",
  "ŠIMKE",
  "ŠIMKI",
  "ŠIMKO",
  "ŠIMKU",
  "ŠIMNA",
  "ŠIMNE",
  "ŠIMNI",
  "ŠIMNU",
  "ŠIMOM",
  "ŠIMON",
  "ŠIMOV",
  "ŠIMRI",
  "ŠIMUN",
  "ŠINAH",
  "ŠINAM",
  "ŠINEM",
  "ŠINEŠ",
  "ŠINFA",
  "ŠINFI",
  "ŠINFU",
  "ŠINIK",
  "ŠINIL",
  "ŠINIT",
  "ŠINIU",
  "ŠINIV",
  "ŠINJA",
  "ŠINJE",
  "ŠINJI",
  "ŠINJU",
  "ŠINKA",
  "ŠINKE",
  "ŠINKI",
  "ŠINKO",
  "ŠINKU",
  "ŠINZA",
  "ŠINZE",
  "ŠINZI",
  "ŠINZO",
  "ŠINZU",
  "ŠIPAH",
  "ŠIPAM",
  "ŠIPCA",
  "ŠIPCE",
  "ŠIPCI",
  "ŠIPCU",
  "ŠIPEC",
  "ŠIPEK",
  "ŠIPIČ",
  "ŠIPIH",
  "ŠIPJA",
  "ŠIPJU",
  "ŠIPKA",
  "ŠIPKE",
  "ŠIPKI",
  "ŠIPKO",
  "ŠIPKU",
  "ŠIPOM",
  "ŠIPON",
  "ŠIPOŠ",
  "ŠIPOV",
  "ŠIPUŠ",
  "ŠIRAJ",
  "ŠIRAZ",
  "ŠIRCA",
  "ŠIRCE",
  "ŠIRCI",
  "ŠIRCO",
  "ŠIRCU",
  "ŠIREC",
  "ŠIREN",
  "ŠIRIL",
  "ŠIRIM",
  "ŠIRIN",
  "ŠIRIŠ",
  "ŠIRIT",
  "ŠIRIU",
  "ŠIRIV",
  "ŠIRJA",
  "ŠIRJE",
  "ŠIRJO",
  "ŠIRJU",
  "ŠIRKA",
  "ŠIRKE",
  "ŠIRKI",
  "ŠIRKO",
  "ŠIRKU",
  "ŠIRNA",
  "ŠIRNE",
  "ŠIRNI",
  "ŠIRNO",
  "ŠIROK",
  "ŠIROM",
  "ŠIROV",
  "ŠIRŠA",
  "ŠIRŠE",
  "ŠIRŠI",
  "ŠIRŠO",
  "ŠIŠAH",
  "ŠIŠAM",
  "ŠIŠIČ",
  "ŠIŠKA",
  "ŠIŠKE",
  "ŠIŠKI",
  "ŠIŠKO",
  "ŠIŠKU",
  "ŠIŠOV",
  "ŠITAH",
  "ŠITAK",
  "ŠITAM",
  "ŠITEM",
  "ŠITEV",
  "ŠITIH",
  "ŠITIN",
  "ŠITJA",
  "ŠITJU",
  "ŠITOM",
  "ŠITOV",
  "ŠITUM",
  "ŠIVAH",
  "ŠIVAJ",
  "ŠIVAL",
  "ŠIVAM",
  "ŠIVAN",
  "ŠIVAŠ",
  "ŠIVAT",
  "ŠIVAU",
  "ŠIVAV",
  "ŠIVCA",
  "ŠIVCE",
  "ŠIVCI",
  "ŠIVCU",
  "ŠIVEC",
  "ŠIVEM",
  "ŠIVEN",
  "ŠIVEV",
  "ŠIVIC",
  "ŠIVIH",
  "ŠIVJA",
  "ŠIVJU",
  "ŠIVNA",
  "ŠIVNE",
  "ŠIVNI",
  "ŠIVNO",
  "ŠIVOM",
  "ŠIVOV",
  "ŠIZOV",
  "ŠKAFA",
  "ŠKAFC",
  "ŠKAFE",
  "ŠKAFI",
  "ŠKAFU",
  "ŠKAJA",
  "ŠKAJE",
  "ŠKAJI",
  "ŠKAJO",
  "ŠKALC",
  "ŠKALE",
  "ŠKAMP",
  "ŠKARA",
  "ŠKARE",
  "ŠKARI",
  "ŠKARO",
  "ŠKARP",
  "ŠKART",
  "ŠKARU",
  "ŠKEDL",
  "ŠKERA",
  "ŠKERE",
  "ŠKERI",
  "ŠKERK",
  "ŠKERL",
  "ŠKERU",
  "ŠKETA",
  "ŠKETE",
  "ŠKETI",
  "ŠKETO",
  "ŠKETU",
  "ŠKILI",
  "ŠKILU",
  "ŠKISA",
  "ŠKISE",
  "ŠKISI",
  "ŠKISU",
  "ŠKLOC",
  "ŠKODA",
  "ŠKODE",
  "ŠKODI",
  "ŠKODO",
  "ŠKODU",
  "ŠKOFA",
  "ŠKOFC",
  "ŠKOFE",
  "ŠKOFI",
  "ŠKOFU",
  "ŠKOJA",
  "ŠKOJE",
  "ŠKOJI",
  "ŠKOJO",
  "ŠKOJU",
  "ŠKOLČ",
  "ŠKOLJ",
  "ŠKOPA",
  "ŠKOPE",
  "ŠKOPI",
  "ŠKOPO",
  "ŠKORA",
  "ŠKORC",
  "ŠKORE",
  "ŠKORI",
  "ŠKORO",
  "ŠKORU",
  "ŠKOTA",
  "ŠKOTE",
  "ŠKOTI",
  "ŠKOTO",
  "ŠKOTU",
  "ŠKRAB",
  "ŠKRAT",
  "ŠKRBA",
  "ŠKRBE",
  "ŠKRBI",
  "ŠKRBO",
  "ŠKRBU",
  "ŠKREB",
  "ŠKRGA",
  "ŠKRGE",
  "ŠKRGI",
  "ŠKRGO",
  "ŠKRIB",
  "ŠKRIC",
  "ŠKRKA",
  "ŠKRKE",
  "ŠKRKI",
  "ŠKRKU",
  "ŠKRLI",
  "ŠKRLJ",
  "ŠKROB",
  "ŠKRTA",
  "ŠKRTE",
  "ŠKRTI",
  "ŠKRTO",
  "ŠKRTU",
  "ŠKRUB",
  "ŠKUCA",
  "ŠKUCU",
  "ŠKUFC",
  "ŠKULJ",
  "ŠKUNC",
  "ŠKURH",
  "ŠKVER",
  "ŠKVOR",
  "ŠLAFA",
  "ŠLAFI",
  "ŠLAFU",
  "ŠLANK",
  "ŠLAPA",
  "ŠLAPE",
  "ŠLAPI",
  "ŠLAPO",
  "ŠLATA",
  "ŠLATI",
  "ŠLATU",
  "ŠLAUF",
  "ŠLAUS",
  "ŠLEMA",
  "ŠLEME",
  "ŠLEMI",
  "ŠLEMU",
  "ŠLENC",
  "ŠLEPA",
  "ŠLEPI",
  "ŠLEPU",
  "ŠLERK",
  "ŠLEVA",
  "ŠLEVE",
  "ŠLEVI",
  "ŠLEVO",
  "ŠLICA",
  "ŠLICE",
  "ŠLICI",
  "ŠLICU",
  "ŠLOGA",
  "ŠLOGI",
  "ŠLOGU",
  "ŠLUGA",
  "ŠLUGE",
  "ŠLUGI",
  "ŠLUGO",
  "ŠLUGU",
  "ŠLUKA",
  "ŠLUKE",
  "ŠLUKI",
  "ŠLUKU",
  "ŠMAJC",
  "ŠMAJD",
  "ŠMAJS",
  "ŠMALC",
  "ŠMARK",
  "ŠMAUC",
  "ŠMEJC",
  "ŠMENT",
  "ŠMERC",
  "ŠMIDA",
  "ŠMIDE",
  "ŠMIDI",
  "ŠMIDT",
  "ŠMIDU",
  "ŠMINK",
  "ŠMIRA",
  "ŠMIRI",
  "ŠMIRU",
  "ŠMITA",
  "ŠMITE",
  "ŠMITI",
  "ŠMITU",
  "ŠMONA",
  "ŠMONE",
  "ŠMONI",
  "ŠMONU",
  "ŠMORN",
  "ŠMUCA",
  "ŠMUCE",
  "ŠMUCI",
  "ŠMUCU",
  "ŠNABL",
  "ŠNEPF",
  "ŠNIRA",
  "ŠNIRI",
  "ŠNIRU",
  "ŠNITA",
  "ŠNITE",
  "ŠNITI",
  "ŠNITO",
  "ŠNJUR",
  "ŠNOFA",
  "ŠNOFI",
  "ŠNOFL",
  "ŠNOFU",
  "ŠNOLA",
  "ŠNOLE",
  "ŠNOLI",
  "ŠNOLO",
  "ŠNOPS",
  "ŠOAHA",
  "ŠOAHI",
  "ŠOAHU",
  "ŠOBAH",
  "ŠOBAK",
  "ŠOBAM",
  "ŠOBAN",
  "ŠOBAR",
  "ŠOBAT",
  "ŠOBCA",
  "ŠOBCE",
  "ŠOBCI",
  "ŠOBCU",
  "ŠOBEC",
  "ŠOBEN",
  "ŠOBER",
  "ŠOBIC",
  "ŠOBIH",
  "ŠOBIL",
  "ŠOBIM",
  "ŠOBIŠ",
  "ŠOBIT",
  "ŠOBIU",
  "ŠOBIV",
  "ŠOBJA",
  "ŠOBJU",
  "ŠOBNA",
  "ŠOBNE",
  "ŠOBNI",
  "ŠOBNO",
  "ŠOBOM",
  "ŠOBOT",
  "ŠOBOV",
  "ŠOBRA",
  "ŠOBRE",
  "ŠOBRI",
  "ŠOBRU",
  "ŠODAH",
  "ŠODAM",
  "ŠODER",
  "ŠODIH",
  "ŠODJA",
  "ŠODJU",
  "ŠODOM",
  "ŠODOV",
  "ŠODRA",
  "ŠODRI",
  "ŠODRU",
  "ŠOFER",
  "ŠOGUN",
  "ŠOHIN",
  "ŠOIJO",
  "ŠOJAH",
  "ŠOJAM",
  "ŠOJAT",
  "ŠOJGU",
  "ŠOKEM",
  "ŠOKER",
  "ŠOKEV",
  "ŠOKIH",
  "ŠOKIN",
  "ŠOKJA",
  "ŠOKJU",
  "ŠOKOM",
  "ŠOKOV",
  "ŠOLAH",
  "ŠOLAJ",
  "ŠOLAK",
  "ŠOLAL",
  "ŠOLAM",
  "ŠOLAN",
  "ŠOLAR",
  "ŠOLAŠ",
  "ŠOLAT",
  "ŠOLAU",
  "ŠOLAV",
  "ŠOLCA",
  "ŠOLCE",
  "ŠOLCI",
  "ŠOLCU",
  "ŠOLEN",
  "ŠOLNA",
  "ŠOLNE",
  "ŠOLNI",
  "ŠOLNU",
  "ŠOLTA",
  "ŠOLTE",
  "ŠOLTI",
  "ŠOLTO",
  "ŠOMAT",
  "ŠONCA",
  "ŠONCE",
  "ŠONCI",
  "ŠONCU",
  "ŠONIH",
  "ŠONJA",
  "ŠONJU",
  "ŠONOM",
  "ŠONOV",
  "ŠOOŠA",
  "ŠOOŠE",
  "ŠOOŠI",
  "ŠOOŠU",
  "ŠOOTU",
  "ŠOPAR",
  "ŠOPEK",
  "ŠOPEM",
  "ŠOPEV",
  "ŠOPIH",
  "ŠOPJA",
  "ŠOPJU",
  "ŠOPKA",
  "ŠOPKE",
  "ŠOPKI",
  "ŠOPKU",
  "ŠOPOM",
  "ŠOPOV",
  "ŠORAK",
  "ŠORCA",
  "ŠORCE",
  "ŠORCI",
  "ŠORCU",
  "ŠORGA",
  "ŠORGE",
  "ŠORGI",
  "ŠORGO",
  "ŠORGU",
  "ŠORLA",
  "ŠORLE",
  "ŠORLI",
  "ŠORLU",
  "ŠORNA",
  "ŠORNE",
  "ŠORNI",
  "ŠORNU",
  "ŠOŠEM",
  "ŠOŠEV",
  "ŠOŠIH",
  "ŠOŠJA",
  "ŠOŠJU",
  "ŠOŠKA",
  "ŠOŠKE",
  "ŠOŠKI",
  "ŠOŠKO",
  "ŠOŠKU",
  "ŠOŠOM",
  "ŠOŠOV",
  "ŠOTAH",
  "ŠOTAM",
  "ŠOTAR",
  "ŠOTEN",
  "ŠOTER",
  "ŠOTIŠ",
  "ŠOTNA",
  "ŠOTNE",
  "ŠOTNI",
  "ŠOTNO",
  "ŠOTOL",
  "ŠOTOR",
  "ŠOTRA",
  "ŠOTRE",
  "ŠOTRI",
  "ŠOTRO",
  "ŠOTRU",
  "ŠOVEM",
  "ŠOVEV",
  "ŠOVIH",
  "ŠOVJA",
  "ŠOVJU",
  "ŠOVOM",
  "ŠOVOV",
  "ŠPAGA",
  "ŠPAGE",
  "ŠPAGI",
  "ŠPAGO",
  "ŠPAJA",
  "ŠPAJE",
  "ŠPAJI",
  "ŠPAJK",
  "ŠPAJO",
  "ŠPAJZ",
  "ŠPANA",
  "ŠPANC",
  "ŠPANE",
  "ŠPANG",
  "ŠPANI",
  "ŠPANK",
  "ŠPANU",
  "ŠPARA",
  "ŠPARE",
  "ŠPARI",
  "ŠPARL",
  "ŠPARU",
  "ŠPASA",
  "ŠPASE",
  "ŠPASI",
  "ŠPASU",
  "ŠPATA",
  "ŠPATE",
  "ŠPATI",
  "ŠPATU",
  "ŠPECA",
  "ŠPECE",
  "ŠPECI",
  "ŠPECO",
  "ŠPECU",
  "ŠPEGA",
  "ŠPEGI",
  "ŠPEGU",
  "ŠPEHA",
  "ŠPEHE",
  "ŠPEHI",
  "ŠPEHU",
  "ŠPELA",
  "ŠPELC",
  "ŠPELE",
  "ŠPELI",
  "ŠPELO",
  "ŠPENG",
  "ŠPESA",
  "ŠPESE",
  "ŠPESI",
  "ŠPESU",
  "ŠPICA",
  "ŠPICE",
  "ŠPICI",
  "ŠPICO",
  "ŠPICU",
  "ŠPIČI",
  "ŠPIČL",
  "ŠPIČU",
  "ŠPIKA",
  "ŠPIKE",
  "ŠPIKI",
  "ŠPIKU",
  "ŠPILA",
  "ŠPILE",
  "ŠPILI",
  "ŠPILJ",
  "ŠPILO",
  "ŠPILU",
  "ŠPIRA",
  "ŠPIRE",
  "ŠPIRI",
  "ŠPIRO",
  "ŠPIRU",
  "ŠPOGA",
  "ŠPOGI",
  "ŠPOGU",
  "ŠPONA",
  "ŠPONE",
  "ŠPONI",
  "ŠPONU",
  "ŠPORN",
  "ŠPORT",
  "ŠPOTA",
  "ŠPOTI",
  "ŠPOTU",
  "ŠPRAH",
  "ŠPREM",
  "ŠPRIC",
  "ŠPRUK",
  "ŠPULA",
  "ŠPULE",
  "ŠPULI",
  "ŠPULO",
  "ŠPURA",
  "ŠPURE",
  "ŠPURI",
  "ŠPURO",
  "ŠPURU",
  "ŠRAJA",
  "ŠRAJE",
  "ŠRAJI",
  "ŠRAJU",
  "ŠRAMA",
  "ŠRAME",
  "ŠRAMI",
  "ŠRAML",
  "ŠRAMU",
  "ŠRANG",
  "ŠRAUF",
  "ŠREKL",
  "ŠRENK",
  "ŠRETL",
  "ŠRIMF",
  "ŠROKA",
  "ŠROKE",
  "ŠROKI",
  "ŠROKU",
  "ŠROTA",
  "ŠROTE",
  "ŠROTI",
  "ŠROTU",
  "ŠRUTA",
  "ŠRUTE",
  "ŠRUTI",
  "ŠRUTU",
  "ŠTABA",
  "ŠTABE",
  "ŠTABI",
  "ŠTABO",
  "ŠTABU",
  "ŠTAKA",
  "ŠTAKE",
  "ŠTAKI",
  "ŠTAKO",
  "ŠTAKU",
  "ŠTALA",
  "ŠTALC",
  "ŠTALE",
  "ŠTALI",
  "ŠTALO",
  "ŠTANC",
  "ŠTANG",
  "ŠTANT",
  "ŠTARK",
  "ŠTART",
  "ŠTAUT",
  "ŠTAVS",
  "ŠTAVT",
  "ŠTEBE",
  "ŠTEBI",
  "ŠTECH",
  "ŠTEDI",
  "ŠTEDU",
  "ŠTEFA",
  "ŠTEFE",
  "ŠTEFI",
  "ŠTEFK",
  "ŠTEFL",
  "ŠTEFO",
  "ŠTEHA",
  "ŠTEHE",
  "ŠTEHI",
  "ŠTEHU",
  "ŠTEJE",
  "ŠTEJN",
  "ŠTEKA",
  "ŠTEKE",
  "ŠTEKI",
  "ŠTEKL",
  "ŠTEKO",
  "ŠTEKU",
  "ŠTELA",
  "ŠTELE",
  "ŠTELI",
  "ŠTELO",
  "ŠTELU",
  "ŠTEMA",
  "ŠTEMI",
  "ŠTEMU",
  "ŠTENG",
  "ŠTENT",
  "ŠTEPA",
  "ŠTEPI",
  "ŠTEPU",
  "ŠTERA",
  "ŠTERE",
  "ŠTERI",
  "ŠTERK",
  "ŠTERN",
  "ŠTERU",
  "ŠTESL",
  "ŠTETA",
  "ŠTETE",
  "ŠTETI",
  "ŠTETO",
  "ŠTEVC",
  "ŠTEVK",
  "ŠTIFT",
  "ŠTIGL",
  "ŠTIHA",
  "ŠTIHE",
  "ŠTIHI",
  "ŠTIHU",
  "ŠTIKA",
  "ŠTIKI",
  "ŠTIKS",
  "ŠTIKU",
  "ŠTILA",
  "ŠTILE",
  "ŠTILI",
  "ŠTILU",
  "ŠTIMA",
  "ŠTIME",
  "ŠTIMI",
  "ŠTIMO",
  "ŠTIMU",
  "ŠTIPA",
  "ŠTIPU",
  "ŠTIRB",
  "ŠTIRI",
  "ŠTIRN",
  "ŠTJAK",
  "ŠTOFA",
  "ŠTOFE",
  "ŠTOFI",
  "ŠTOFU",
  "ŠTOJS",
  "ŠTOKA",
  "ŠTOKE",
  "ŠTOKI",
  "ŠTOKO",
  "ŠTOKU",
  "ŠTOLA",
  "ŠTOLE",
  "ŠTOLF",
  "ŠTOLI",
  "ŠTOLN",
  "ŠTOLO",
  "ŠTOPA",
  "ŠTOPE",
  "ŠTOPI",
  "ŠTOPU",
  "ŠTORA",
  "ŠTORE",
  "ŠTORG",
  "ŠTORI",
  "ŠTORK",
  "ŠTORU",
  "ŠTOSA",
  "ŠTOSE",
  "ŠTOSI",
  "ŠTOSU",
  "ŠTOTL",
  "ŠTRAS",
  "ŠTRBC",
  "ŠTRCA",
  "ŠTRCI",
  "ŠTRCU",
  "ŠTREK",
  "ŠTREN",
  "ŠTRIK",
  "ŠTRIT",
  "ŠTRKA",
  "ŠTRKE",
  "ŠTRKI",
  "ŠTRKU",
  "ŠTRLE",
  "ŠTRLI",
  "ŠTRLU",
  "ŠTROK",
  "ŠTROM",
  "ŠTROS",
  "ŠTRUC",
  "ŠTRUK",
  "ŠTRUS",
  "ŠTUCL",
  "ŠTUKA",
  "ŠTUKE",
  "ŠTUKI",
  "ŠTUKL",
  "ŠTUKU",
  "ŠTULA",
  "ŠTULC",
  "ŠTULE",
  "ŠTULI",
  "ŠTULO",
  "ŠTUMF",
  "ŠTUNF",
  "ŠTUPA",
  "ŠTUPI",
  "ŠTUPU",
  "ŠTURM",
  "ŠTUSA",
  "ŠTUSE",
  "ŠTUSI",
  "ŠTUSU",
  "ŠUBER",
  "ŠUBIC",
  "ŠUBRA",
  "ŠUBRE",
  "ŠUBRI",
  "ŠUBRU",
  "ŠUCEM",
  "ŠUCEV",
  "ŠUCIH",
  "ŠUCJA",
  "ŠUCJU",
  "ŠUCOM",
  "ŠUCOV",
  "ŠUČEV",
  "ŠUČOV",
  "ŠUČUR",
  "ŠUENA",
  "ŠUENE",
  "ŠUENI",
  "ŠUENU",
  "ŠUFIT",
  "ŠUFTA",
  "ŠUFTE",
  "ŠUFTI",
  "ŠUFTU",
  "ŠUHEL",
  "ŠUHLA",
  "ŠUHLE",
  "ŠUHLI",
  "ŠUHLU",
  "ŠUICA",
  "ŠUICE",
  "ŠUICI",
  "ŠUICO",
  "ŠUICU",
  "ŠUIČI",
  "ŠUJIC",
  "ŠUKER",
  "ŠUKOV",
  "ŠUKRI",
  "ŠULAH",
  "ŠULAM",
  "ŠULCA",
  "ŠULCE",
  "ŠULCI",
  "ŠULCU",
  "ŠULEK",
  "ŠULER",
  "ŠULIH",
  "ŠULIN",
  "ŠULJA",
  "ŠULJU",
  "ŠULNA",
  "ŠULNE",
  "ŠULNI",
  "ŠULNU",
  "ŠULOM",
  "ŠULOV",
  "ŠUMAH",
  "ŠUMAJ",
  "ŠUMAK",
  "ŠUMAN",
  "ŠUMAR",
  "ŠUMCA",
  "ŠUMCU",
  "ŠUMEC",
  "ŠUMEČ",
  "ŠUMEJ",
  "ŠUMEL",
  "ŠUMEM",
  "ŠUMEN",
  "ŠUMER",
  "ŠUMET",
  "ŠUMEU",
  "ŠUMEV",
  "ŠUMIČ",
  "ŠUMIG",
  "ŠUMIH",
  "ŠUMIK",
  "ŠUMIM",
  "ŠUMIŠ",
  "ŠUMJA",
  "ŠUMJU",
  "ŠUMNA",
  "ŠUMNE",
  "ŠUMNI",
  "ŠUMNO",
  "ŠUMOM",
  "ŠUMOT",
  "ŠUMOV",
  "ŠUNDA",
  "ŠUNDI",
  "ŠUNDU",
  "ŠUNKA",
  "ŠUNKE",
  "ŠUNKI",
  "ŠUNKO",
  "ŠUNTA",
  "ŠUNTE",
  "ŠUNTI",
  "ŠUNTO",
  "ŠUNTU",
  "ŠUPAH",
  "ŠUPAM",
  "ŠUPUK",
  "ŠUPUT",
  "ŠURAH",
  "ŠURAM",
  "ŠURAN",
  "ŠURCA",
  "ŠURCE",
  "ŠURCI",
  "ŠURCU",
  "ŠUREV",
  "ŠURIH",
  "ŠURIN",
  "ŠURJA",
  "ŠURJU",
  "ŠURKA",
  "ŠURKE",
  "ŠURKI",
  "ŠURKU",
  "ŠURLA",
  "ŠURLE",
  "ŠURLI",
  "ŠURLO",
  "ŠURLU",
  "ŠUROM",
  "ŠUROV",
  "ŠUSEM",
  "ŠUSEV",
  "ŠUSIH",
  "ŠUSJA",
  "ŠUSJU",
  "ŠUSOM",
  "ŠUSOV",
  "ŠUŠAH",
  "ŠUŠAK",
  "ŠUŠAM",
  "ŠUŠEV",
  "ŠUŠIH",
  "ŠUŠJA",
  "ŠUŠJU",
  "ŠUŠKA",
  "ŠUŠKE",
  "ŠUŠKI",
  "ŠUŠKU",
  "ŠUŠOM",
  "ŠUŠOV",
  "ŠUŠTE",
  "ŠUŠTI",
  "ŠUŠTU",
  "ŠUTAH",
  "ŠUTAM",
  "ŠUTAR",
  "ŠUTEJ",
  "ŠUTIČ",
  "ŠUTIH",
  "ŠUTJA",
  "ŠUTJU",
  "ŠUTNA",
  "ŠUTNE",
  "ŠUTNI",
  "ŠUTNO",
  "ŠUTOM",
  "ŠUTOV",
  "ŠUVAK",
  "ŠUVAR",
  "ŠVABA",
  "ŠVABE",
  "ŠVABI",
  "ŠVABU",
  "ŠVALJ",
  "ŠVARA",
  "ŠVARC",
  "ŠVARE",
  "ŠVARI",
  "ŠVARM",
  "ŠVARO",
  "ŠVARU",
  "ŠVASA",
  "ŠVASI",
  "ŠVASU",
  "ŠVEDA",
  "ŠVEDE",
  "ŠVEDI",
  "ŠVEDU",
  "ŠVEGL",
  "ŠVEJD",
  "ŠVEJK",
  "ŠVELC",
  "ŠVEND",
  "ŠVENT",
  "ŠVERC",
  "ŠVICA",
  "ŠVICE",
  "ŠVICI",
  "ŠVICO",
  "ŠVICU",
  "ŠVIGA",
  "ŠVIGI",
  "ŠVIGU",
  "ŠVIKA",
  "ŠVIKE",
  "ŠVIKI",
  "ŠVIKU",
  "ŠVIRT",
  "ŠVIST",
  "ŠVRAK",
  "ŠVRKA",
  "ŠVRKE",
  "ŠVRKI",
  "ŠVRKU",
  "TAALA",
  "TAALE",
  "TAALI",
  "TAALU",
  "TAAVI",
  "TABAJ",
  "TABAK",
  "TABAR",
  "TABAT",
  "TABBA",
  "TABBE",
  "TABBI",
  "TABBU",
  "TABEA",
  "TABEE",
  "TABEI",
  "TABEJ",
  "TABEL",
  "TABEO",
  "TABGA",
  "TABIA",
  "TABIE",
  "TABII",
  "TABIO",
  "TABIT",
  "TABJU",
  "TABLA",
  "TABLE",
  "TABLI",
  "TABLO",
  "TABOR",
  "TABOT",
  "TABRA",
  "TABRU",
  "TABUN",
  "TACAH",
  "TACAJ",
  "TACAL",
  "TACAM",
  "TACAR",
  "TACAŠ",
  "TACAT",
  "TACAU",
  "TACAV",
  "TACCA",
  "TACCE",
  "TACCI",
  "TACCO",
  "TACCU",
  "TACEN",
  "TACER",
  "TACIT",
  "TACKE",
  "TACNA",
  "TACNU",
  "TACOL",
  "TAČAS",
  "TAČEK",
  "TAČIC",
  "TAČKA",
  "TAČKE",
  "TAČKI",
  "TAČKO",
  "TAČKU",
  "TADEJ",
  "TADEL",
  "TADEM",
  "TADIČ",
  "TADIH",
  "TADIJ",
  "TADIN",
  "TADJA",
  "TADJU",
  "TADLA",
  "TADLE",
  "TADLI",
  "TADLU",
  "TADOM",
  "TADOV",
  "TADVA",
  "TAEBO",
  "TAFAH",
  "TAFAJ",
  "TAFAM",
  "TAFFA",
  "TAFFE",
  "TAFFI",
  "TAFFU",
  "TAFIH",
  "TAFIL",
  "TAFJA",
  "TAFJU",
  "TAFOM",
  "TAFOV",
  "TAFTA",
  "TAFTE",
  "TAFTI",
  "TAFTU",
  "TAFUR",
  "TAGEM",
  "TAGER",
  "TAGEV",
  "TAGIH",
  "TAGIL",
  "TAGJA",
  "TAGJU",
  "TAGLE",
  "TAGOM",
  "TAGOV",
  "TAGUB",
  "TAGUE",
  "TAHAN",
  "TAHAR",
  "TAHAT",
  "TAHIL",
  "TAHIR",
  "TAHOE",
  "TAHTA",
  "TAHTE",
  "TAHTI",
  "TAHTU",
  "TAIAH",
  "TAIAM",
  "TAIAN",
  "TAIBA",
  "TAIBE",
  "TAIBI",
  "TAIBU",
  "TAIEB",
  "TAIEM",
  "TAIEV",
  "TAIGO",
  "TAIIH",
  "TAIJA",
  "TAIJI",
  "TAIJU",
  "TAIKA",
  "TAIKE",
  "TAIKI",
  "TAIKO",
  "TAIKU",
  "TAIMI",
  "TAINA",
  "TAINE",
  "TAINI",
  "TAINO",
  "TAINT",
  "TAINU",
  "TAIOM",
  "TAIOV",
  "TAIPA",
  "TAIPE",
  "TAIPI",
  "TAIPU",
  "TAIRA",
  "TAIRE",
  "TAIRI",
  "TAIRO",
  "TAIRU",
  "TAISS",
  "TAITA",
  "TAITE",
  "TAITI",
  "TAITU",
  "TAIZE",
  "TAJAH",
  "TAJAJ",
  "TAJAL",
  "TAJAM",
  "TAJAN",
  "TAJAŠ",
  "TAJAT",
  "TAJAU",
  "TAJAV",
  "TAJCA",
  "TAJCE",
  "TAJCI",
  "TAJCU",
  "TAJČI",
  "TAJDA",
  "TAJDE",
  "TAJDI",
  "TAJDO",
  "TAJEC",
  "TAJEČ",
  "TAJEM",
  "TAJEN",
  "TAJER",
  "TAJEV",
  "TAJGA",
  "TAJGE",
  "TAJGI",
  "TAJGO",
  "TAJHT",
  "TAJIH",
  "TAJIL",
  "TAJIM",
  "TAJIN",
  "TAJIP",
  "TAJIŠ",
  "TAJIT",
  "TAJIU",
  "TAJIV",
  "TAJJA",
  "TAJJU",
  "TAJKA",
  "TAJKE",
  "TAJKI",
  "TAJKO",
  "TAJNA",
  "TAJNE",
  "TAJNI",
  "TAJNO",
  "TAJOM",
  "TAJON",
  "TAJOV",
  "TAJTL",
  "TAKAČ",
  "TAKAH",
  "TAKAL",
  "TAKAM",
  "TAKAR",
  "TAKEA",
  "TAKEE",
  "TAKEI",
  "TAKEM",
  "TAKEO",
  "TAKEU",
  "TAKIH",
  "TAKIJ",
  "TAKIM",
  "TAKIN",
  "TAKLE",
  "TAKOJ",
  "TAKOV",
  "TAKSA",
  "TAKSE",
  "TAKSI",
  "TAKSO",
  "TAKSU",
  "TAKTA",
  "TAKTE",
  "TAKTI",
  "TAKTU",
  "TAKUM",
  "TALAH",
  "TALAJ",
  "TALAL",
  "TALAM",
  "TALAN",
  "TALAR",
  "TALAŠ",
  "TALAT",
  "TALAU",
  "TALAV",
  "TALCA",
  "TALCE",
  "TALCI",
  "TALCU",
  "TALDE",
  "TALEB",
  "TALEC",
  "TALEČ",
  "TALEN",
  "TALER",
  "TALES",
  "TALIA",
  "TALIB",
  "TALIČ",
  "TALIE",
  "TALIG",
  "TALIH",
  "TALII",
  "TALIJ",
  "TALIL",
  "TALIM",
  "TALIN",
  "TALIO",
  "TALIS",
  "TALIŠ",
  "TALIT",
  "TALIU",
  "TALIV",
  "TALJA",
  "TALJU",
  "TALKA",
  "TALKE",
  "TALKI",
  "TALKO",
  "TALKS",
  "TALLA",
  "TALLE",
  "TALLI",
  "TALLU",
  "TALMA",
  "TALNA",
  "TALNE",
  "TALNI",
  "TALNO",
  "TALOM",
  "TALON",
  "TALOS",
  "TALOV",
  "TALOZ",
  "TALPE",
  "TALUM",
  "TALUS",
  "TAMAL",
  "TAMAM",
  "TAMAN",
  "TAMAR",
  "TAMAS",
  "TAMBA",
  "TAMBE",
  "TAMBI",
  "TAMBO",
  "TAMBU",
  "TAMEK",
  "TAMER",
  "TAMIH",
  "TAMIK",
  "TAMIM",
  "TAMIN",
  "TAMIR",
  "TAMJA",
  "TAMJU",
  "TAMLE",
  "TAMMA",
  "TAMME",
  "TAMMI",
  "TAMMO",
  "TAMMU",
  "TAMOM",
  "TAMOV",
  "TAMPA",
  "TAMPE",
  "TAMPI",
  "TAMPO",
  "TAMSA",
  "TAMSE",
  "TAMSI",
  "TAMSU",
  "TAMŠE",
  "TAMUR",
  "TANAH",
  "TANAJ",
  "TANAK",
  "TANAM",
  "TANCA",
  "TANCE",
  "TANCI",
  "TANCU",
  "TANČA",
  "TANČE",
  "TANČI",
  "TANČO",
  "TANDE",
  "TANDI",
  "TANEA",
  "TANEČ",
  "TANED",
  "TANEE",
  "TANEI",
  "TANEJ",
  "TANEK",
  "TANEN",
  "TANEO",
  "TANER",
  "TANEV",
  "TANGA",
  "TANGE",
  "TANGI",
  "TANGL",
  "TANGO",
  "TANGU",
  "TANIA",
  "TANIE",
  "TANIH",
  "TANII",
  "TANIJ",
  "TANIL",
  "TANIM",
  "TANIN",
  "TANIO",
  "TANIS",
  "TANIŠ",
  "TANIT",
  "TANIU",
  "TANIV",
  "TANJA",
  "TANJE",
  "TANJI",
  "TANJO",
  "TANJU",
  "TANKA",
  "TANKE",
  "TANKI",
  "TANKO",
  "TANKU",
  "TANNA",
  "TANNE",
  "TANNI",
  "TANNO",
  "TANNU",
  "TANOM",
  "TANOS",
  "TANOV",
  "TANSA",
  "TANSE",
  "TANSI",
  "TANSU",
  "TANTA",
  "TANTE",
  "TANTI",
  "TANTU",
  "TANZI",
  "TAOMA",
  "TAOTA",
  "TAOTU",
  "TAOVA",
  "TAOVE",
  "TAOVI",
  "TAOVO",
  "TAPAS",
  "TAPET",
  "TAPIA",
  "TAPIE",
  "TAPII",
  "TAPIJ",
  "TAPIO",
  "TAPIR",
  "TAPIU",
  "TAPKA",
  "TAPKI",
  "TAPKU",
  "TAPNE",
  "TAPNI",
  "TAPNU",
  "TAPPA",
  "TAPPE",
  "TAPPI",
  "TAPPU",
  "TARAH",
  "TARAL",
  "TARAM",
  "TARAN",
  "TARAS",
  "TARČA",
  "TARČE",
  "TARČI",
  "TARČO",
  "TARDA",
  "TARDE",
  "TARDI",
  "TARDU",
  "TAREK",
  "TAREL",
  "TAREM",
  "TAREŠ",
  "TAREV",
  "TARIF",
  "TARIH",
  "TARIJ",
  "TARIK",
  "TARIM",
  "TARIN",
  "TARIS",
  "TARIT",
  "TARJA",
  "TARJE",
  "TARJI",
  "TARJO",
  "TARJU",
  "TARLE",
  "TARMA",
  "TARME",
  "TARMI",
  "TARMO",
  "TARMU",
  "TARNA",
  "TARNI",
  "TARNU",
  "TAROK",
  "TAROM",
  "TARON",
  "TAROT",
  "TAROV",
  "TARRA",
  "TARRE",
  "TARRI",
  "TARRO",
  "TARSI",
  "TARTA",
  "TARTE",
  "TARTI",
  "TARTT",
  "TARTU",
  "TARUT",
  "TARZA",
  "TARZU",
  "TASAK",
  "TASCH",
  "TASEV",
  "TASHA",
  "TASHE",
  "TASHI",
  "TASHO",
  "TASIA",
  "TASIČ",
  "TASIE",
  "TASIH",
  "TASII",
  "TASIJ",
  "TASIM",
  "TASIN",
  "TASIO",
  "TASJA",
  "TASJU",
  "TASOM",
  "TASOS",
  "TASOV",
  "TASSA",
  "TASSE",
  "TASSI",
  "TASSO",
  "TASSU",
  "TASTA",
  "TASTE",
  "TASTI",
  "TASTU",
  "TAŠČA",
  "TAŠČE",
  "TAŠČI",
  "TAŠČO",
  "TAŠEV",
  "TAŠIČ",
  "TAŠKA",
  "TAŠKE",
  "TAŠKI",
  "TAŠKO",
  "TATAH",
  "TATAM",
  "TATAR",
  "TATEM",
  "TATER",
  "TATEV",
  "TATIC",
  "TATIČ",
  "TATIH",
  "TATIN",
  "TATJA",
  "TATJU",
  "TATOM",
  "TATOV",
  "TATRC",
  "TATRE",
  "TATRK",
  "TATSU",
  "TATTA",
  "TATTE",
  "TATTI",
  "TATTU",
  "TATUM",
  "TAUBA",
  "TAUBE",
  "TAUBI",
  "TAUBU",
  "TAUCH",
  "TAUEM",
  "TAUEV",
  "TAUHA",
  "TAUHI",
  "TAUHU",
  "TAUIH",
  "TAUJA",
  "TAUJU",
  "TAUKA",
  "TAUKE",
  "TAUKI",
  "TAUKU",
  "TAUNJ",
  "TAUOM",
  "TAUOV",
  "TAUPA",
  "TAUPO",
  "TAUPU",
  "TAURA",
  "TAURO",
  "TAURU",
  "TAUSH",
  "TAUSZ",
  "TAVAF",
  "TAVAJ",
  "TAVAL",
  "TAVAM",
  "TAVAŠ",
  "TAVAT",
  "TAVAU",
  "TAVAV",
  "TAVER",
  "TAVHA",
  "TAVHI",
  "TAVHU",
  "TAVIA",
  "TAVIE",
  "TAVII",
  "TAVIO",
  "TAVIR",
  "TAVIU",
  "TAVOL",
  "TAVRA",
  "TAVRE",
  "TAVRI",
  "TAVRO",
  "TAVRU",
  "TAVŠA",
  "TAVŠE",
  "TAVŠI",
  "TAVŠU",
  "TAZGA",
  "TBLUS",
  "TEADA",
  "TEADE",
  "TEADI",
  "TEADU",
  "TEALA",
  "TEALE",
  "TEALI",
  "TEALU",
  "TEAMA",
  "TEAME",
  "TEAMI",
  "TEAMS",
  "TEAMU",
  "TEBAH",
  "TEBAM",
  "TEBAS",
  "TEBIH",
  "TEBJA",
  "TEBJU",
  "TEBOJ",
  "TEBOM",
  "TEBOR",
  "TEBOV",
  "TECAR",
  "TECCA",
  "TECCE",
  "TECCI",
  "TECCO",
  "TECCU",
  "TECHA",
  "TECHU",
  "TECOS",
  "TEČAJ",
  "TEČEM",
  "TEČEN",
  "TEČEŠ",
  "TEČKA",
  "TEČKE",
  "TEČKI",
  "TEČKO",
  "TEČNA",
  "TEČNE",
  "TEČNI",
  "TEČNO",
  "TEČTI",
  "TEDAH",
  "TEDAJ",
  "TEDAM",
  "TEDDA",
  "TEDDE",
  "TEDDI",
  "TEDDU",
  "TEDEN",
  "TEDER",
  "TEDIH",
  "TEDIN",
  "TEDJA",
  "TEDJU",
  "TEDNA",
  "TEDNE",
  "TEDNI",
  "TEDNU",
  "TEDOM",
  "TEDOV",
  "TEDVE",
  "TEEDA",
  "TEEDE",
  "TEEDI",
  "TEEDU",
  "TEEKA",
  "TEEKE",
  "TEEKI",
  "TEEKU",
  "TEELA",
  "TEELE",
  "TEELI",
  "TEELU",
  "TEEMS",
  "TEEMU",
  "TEENA",
  "TEENE",
  "TEENI",
  "TEENO",
  "TEEPE",
  "TEFAL",
  "TEFIK",
  "TEFTA",
  "TEFTE",
  "TEFTI",
  "TEFTO",
  "TEGAN",
  "TEGAR",
  "TEGEL",
  "TEGLA",
  "TEGLE",
  "TEGLI",
  "TEGLU",
  "TEGOB",
  "TEGOL",
  "TEGOV",
  "TEGUH",
  "TEHAN",
  "TEHIT",
  "TEHLE",
  "TEHNA",
  "TEHNI",
  "TEHNO",
  "TEHNU",
  "TEHTA",
  "TEHTI",
  "TEHTU",
  "TEIGA",
  "TEIGE",
  "TEIGI",
  "TEIGU",
  "TEINA",
  "TEINI",
  "TEINU",
  "TEIST",
  "TEJAD",
  "TEJAH",
  "TEJAM",
  "TEJAN",
  "TEJEM",
  "TEJEV",
  "TEJIČ",
  "TEJIH",
  "TEJIN",
  "TEJJA",
  "TEJJU",
  "TEJLE",
  "TEJOM",
  "TEJOV",
  "TEKAČ",
  "TEKAJ",
  "TEKAL",
  "TEKAM",
  "TEKAŠ",
  "TEKAT",
  "TEKAU",
  "TEKAV",
  "TEKEL",
  "TEKEM",
  "TEKEU",
  "TEKEV",
  "TEKIC",
  "TEKIH",
  "TEKIL",
  "TEKIN",
  "TEKJA",
  "TEKJU",
  "TEKLA",
  "TEKLE",
  "TEKLI",
  "TEKLO",
  "TEKMA",
  "TEKMC",
  "TEKME",
  "TEKMI",
  "TEKMO",
  "TEKNE",
  "TEKNI",
  "TEKNU",
  "TEKOČ",
  "TEKOM",
  "TEKOV",
  "TEKST",
  "TEKUT",
  "TELČE",
  "TELEF",
  "TELEG",
  "TELES",
  "TELET",
  "TELIA",
  "TELIC",
  "TELIČ",
  "TELIE",
  "TELII",
  "TELIL",
  "TELIM",
  "TELIN",
  "TELIO",
  "TELIŠ",
  "TELIT",
  "TELIU",
  "TELIV",
  "TELLA",
  "TELLE",
  "TELLI",
  "TELLO",
  "TELLU",
  "TELMA",
  "TELME",
  "TELMI",
  "TELMO",
  "TELMU",
  "TELOH",
  "TELOP",
  "TELPA",
  "TELPE",
  "TELPI",
  "TELPU",
  "TELUR",
  "TEMAH",
  "TEMAM",
  "TEMAN",
  "TEMEN",
  "TEMER",
  "TEMID",
  "TEMIN",
  "TEMJU",
  "TEMKA",
  "TEMKE",
  "TEMKI",
  "TEMKO",
  "TEMKU",
  "TEMLE",
  "TEMME",
  "TEMNA",
  "TEMNE",
  "TEMNI",
  "TEMNO",
  "TEMNU",
  "TEMOT",
  "TEMOV",
  "TEMPA",
  "TEMPE",
  "TEMPI",
  "TEMPO",
  "TEMPS",
  "TEMPU",
  "TEMZA",
  "TEMZE",
  "TEMZI",
  "TEMZO",
  "TENAH",
  "TENAK",
  "TENAM",
  "TENCE",
  "TENDA",
  "TENDE",
  "TENDI",
  "TENDO",
  "TENEK",
  "TENEM",
  "TENER",
  "TENET",
  "TENEV",
  "TENGA",
  "TENGE",
  "TENGI",
  "TENGU",
  "TENIH",
  "TENIK",
  "TENIN",
  "TENIS",
  "TENJA",
  "TENJU",
  "TENKA",
  "TENKE",
  "TENKI",
  "TENKO",
  "TENOM",
  "TENOR",
  "TENOV",
  "TENSI",
  "TENTA",
  "TENTE",
  "TENTI",
  "TENTO",
  "TENTU",
  "TENUT",
  "TENZE",
  "TEOLI",
  "TEOMA",
  "TEONA",
  "TEONE",
  "TEONI",
  "TEONO",
  "TEOTA",
  "TEOTU",
  "TEOVA",
  "TEOVE",
  "TEOVI",
  "TEOVO",
  "TEPAČ",
  "TEPAH",
  "TEPAM",
  "TEPCA",
  "TEPCE",
  "TEPCI",
  "TEPCO",
  "TEPCU",
  "TEPEC",
  "TEPEJ",
  "TEPEL",
  "TEPEM",
  "TEPEN",
  "TEPEŠ",
  "TEPEU",
  "TEPEŽ",
  "TEPIH",
  "TEPIN",
  "TEPKA",
  "TEPKE",
  "TEPKI",
  "TEPKO",
  "TEPLA",
  "TEPLE",
  "TEPLI",
  "TEPLO",
  "TEPPA",
  "TEPPE",
  "TEPPI",
  "TEPPO",
  "TEPPU",
  "TEPST",
  "TEPTA",
  "TEPTI",
  "TEPTU",
  "TEPUŠ",
  "TERAH",
  "TERAN",
  "TERAS",
  "TERAŠ",
  "TERAŽ",
  "TERCA",
  "TERCE",
  "TERCI",
  "TERCO",
  "TERCU",
  "TERČE",
  "TEREC",
  "TEREJ",
  "TEREK",
  "TEREN",
  "TERES",
  "TEREZ",
  "TERIA",
  "TERIC",
  "TERIE",
  "TERIH",
  "TERII",
  "TERIJ",
  "TERIN",
  "TERIO",
  "TERIS",
  "TERIU",
  "TERJA",
  "TERJE",
  "TERJI",
  "TERJU",
  "TERKA",
  "TERKE",
  "TERKI",
  "TERKO",
  "TERLI",
  "TERMA",
  "TERME",
  "TERMI",
  "TERMO",
  "TERNA",
  "TERNE",
  "TERNI",
  "TERNO",
  "TERNU",
  "TEROČ",
  "TEROL",
  "TEROM",
  "TEROR",
  "TEROV",
  "TERRA",
  "TERRE",
  "TERRI",
  "TERRO",
  "TERRU",
  "TERSI",
  "TERŠE",
  "TERZI",
  "TESAČ",
  "TESAL",
  "TESAN",
  "TESAR",
  "TESAT",
  "TESAU",
  "TESAV",
  "TESCA",
  "TESCH",
  "TESCO",
  "TESCU",
  "TESEL",
  "TESEN",
  "TESIA",
  "TESIE",
  "TESII",
  "TESIO",
  "TESIU",
  "TESKA",
  "TESKE",
  "TESKI",
  "TESKO",
  "TESKU",
  "TESLA",
  "TESLE",
  "TESLI",
  "TESLO",
  "TESLU",
  "TESNA",
  "TESNE",
  "TESNI",
  "TESNO",
  "TESNU",
  "TESSA",
  "TESSE",
  "TESSI",
  "TESSO",
  "TESTA",
  "TESTE",
  "TESTI",
  "TESTO",
  "TESTU",
  "TEŠAR",
  "TEŠČA",
  "TEŠČE",
  "TEŠČI",
  "TEŠČO",
  "TEŠEČ",
  "TEŠEK",
  "TEŠEM",
  "TEŠEN",
  "TEŠEŠ",
  "TEŠEV",
  "TEŠIČ",
  "TEŠIJ",
  "TEŠIL",
  "TEŠIM",
  "TEŠIŠ",
  "TEŠIT",
  "TEŠIU",
  "TEŠIV",
  "TEŠJA",
  "TEŠJU",
  "TEŠKA",
  "TEŠKE",
  "TEŠKI",
  "TEŠKO",
  "TEŠOČ",
  "TEŠOM",
  "TEŠOV",
  "TETAH",
  "TETAJ",
  "TETAM",
  "TETER",
  "TETIC",
  "TETIK",
  "TETIN",
  "TETIS",
  "TETIV",
  "TETJA",
  "TETJU",
  "TETKA",
  "TETKE",
  "TETKI",
  "TETKO",
  "TETOM",
  "TETRA",
  "TETRE",
  "TETRI",
  "TETRO",
  "TETTA",
  "TETTE",
  "TETTI",
  "TETTU",
  "TEUMA",
  "TEUME",
  "TEUMI",
  "TEUMO",
  "TEUMU",
  "TEUNA",
  "TEUNE",
  "TEUNI",
  "TEUNS",
  "TEUNU",
  "TEUTA",
  "TEUTE",
  "TEUTI",
  "TEUTO",
  "TEUVA",
  "TEUVE",
  "TEUVI",
  "TEUVO",
  "TEUVU",
  "TEVČE",
  "TEVEL",
  "TEVEZ",
  "TEVIJ",
  "TEVIN",
  "TEVIS",
  "TEVJA",
  "TEVJE",
  "TEVJI",
  "TEVJU",
  "TEVŽA",
  "TEVŽE",
  "TEVŽI",
  "TEVŽU",
  "TEZAH",
  "TEZAM",
  "TEZEJ",
  "TEZNA",
  "TEZNO",
  "TEZNU",
  "TEŽAH",
  "TEŽAK",
  "TEŽAM",
  "TEŽAV",
  "TEŽCA",
  "TEŽCI",
  "TEŽCU",
  "TEŽEC",
  "TEŽEK",
  "TEŽEN",
  "TEŽIL",
  "TEŽIM",
  "TEŽIN",
  "TEŽIŠ",
  "TEŽIT",
  "TEŽIU",
  "TEŽIV",
  "TEŽJA",
  "TEŽJE",
  "TEŽJI",
  "TEŽJO",
  "TEŽKA",
  "TEŽKE",
  "TEŽKI",
  "TEŽKO",
  "TEŽKU",
  "TEŽNA",
  "TEŽNE",
  "TEŽNI",
  "TEŽNO",
  "THADA",
  "THADE",
  "THADI",
  "THADU",
  "THAIN",
  "THAKA",
  "THAKE",
  "THAKI",
  "THAKU",
  "THALA",
  "THALE",
  "THALI",
  "THALU",
  "THAMA",
  "THAME",
  "THAMI",
  "THAMM",
  "THAMU",
  "THANA",
  "THANE",
  "THANI",
  "THANO",
  "THANU",
  "THARP",
  "THEAH",
  "THEAM",
  "THEEP",
  "THEIH",
  "THEIL",
  "THEIS",
  "THEJA",
  "THEJU",
  "THELL",
  "THELM",
  "THENA",
  "THENE",
  "THENI",
  "THENU",
  "THEOM",
  "THEON",
  "THEOV",
  "THERN",
  "THESS",
  "THETA",
  "THETE",
  "THETH",
  "THETI",
  "THETO",
  "THEUS",
  "THIAM",
  "THIEL",
  "THIEM",
  "THIER",
  "THIES",
  "THIJS",
  "THILA",
  "THILE",
  "THILI",
  "THILL",
  "THILO",
  "THILU",
  "THIMM",
  "THINA",
  "THINE",
  "THINI",
  "THINO",
  "THION",
  "THOLE",
  "THOMA",
  "THOME",
  "THOMI",
  "THOMO",
  "THOMS",
  "THOMU",
  "THONA",
  "THONE",
  "THONI",
  "THONU",
  "THORA",
  "THORE",
  "THORI",
  "THORN",
  "THORO",
  "THORP",
  "THORS",
  "THORU",
  "THOSS",
  "THOST",
  "THOTT",
  "THROM",
  "THRUM",
  "THRUN",
  "THUIN",
  "THUJA",
  "THUMA",
  "THUME",
  "THUMI",
  "THUMU",
  "THUNA",
  "THUNE",
  "THUNI",
  "THUNU",
  "THURE",
  "THURL",
  "THURM",
  "THURN",
  "THUSI",
  "TIAGA",
  "TIAGE",
  "TIAGI",
  "TIAGO",
  "TIAGU",
  "TIAMA",
  "TIAMI",
  "TIANA",
  "TIANE",
  "TIANI",
  "TIANN",
  "TIANO",
  "TIANU",
  "TIARA",
  "TIARE",
  "TIARI",
  "TIARO",
  "TIBBS",
  "TIBET",
  "TIBJU",
  "TIBLA",
  "TIBLE",
  "TIBLI",
  "TIBLU",
  "TIBOR",
  "TICCI",
  "TICEV",
  "TICHA",
  "TICHE",
  "TICHI",
  "TICHO",
  "TICKA",
  "TICKE",
  "TICKI",
  "TICKU",
  "TIČAL",
  "TIČAR",
  "TIČAT",
  "TIČAU",
  "TIČAV",
  "TIČEK",
  "TIČEM",
  "TIČEŠ",
  "TIČEV",
  "TIČIC",
  "TIČIČ",
  "TIČIH",
  "TIČIM",
  "TIČIŠ",
  "TIČJA",
  "TIČJU",
  "TIČKA",
  "TIČKE",
  "TIČKI",
  "TIČKO",
  "TIČKU",
  "TIČOM",
  "TIČOV",
  "TIDDA",
  "TIDDE",
  "TIDDI",
  "TIDDU",
  "TIDEJ",
  "TIDVE",
  "TIECK",
  "TIEDE",
  "TIEGS",
  "TIEMA",
  "TIENA",
  "TIENE",
  "TIENI",
  "TIENS",
  "TIENU",
  "TIERI",
  "TIESI",
  "TIESJ",
  "TIEST",
  "TIETZ",
  "TIEVA",
  "TIEVE",
  "TIEVI",
  "TIEVO",
  "TIFFE",
  "TIFUS",
  "TIGER",
  "TIGIN",
  "TIGON",
  "TIGRA",
  "TIGRE",
  "TIGRI",
  "TIGRU",
  "TIHAN",
  "TIHCA",
  "TIHCE",
  "TIHCI",
  "TIHCU",
  "TIHEC",
  "TIHEL",
  "TIHEM",
  "TIHGA",
  "TIHIH",
  "TIHIM",
  "TIHLA",
  "TIHLE",
  "TIHLI",
  "TIHLU",
  "TIHON",
  "TIHOT",
  "TIINA",
  "TIINE",
  "TIINI",
  "TIINO",
  "TIJAH",
  "TIJAM",
  "TIJAN",
  "TIJIN",
  "TIKAJ",
  "TIKAL",
  "TIKAM",
  "TIKAŠ",
  "TIKAT",
  "TIKAU",
  "TIKAV",
  "TIKEM",
  "TIKEV",
  "TIKIH",
  "TIKIN",
  "TIKJA",
  "TIKJU",
  "TIKKA",
  "TIKKE",
  "TIKKI",
  "TIKKO",
  "TIKKU",
  "TIKOM",
  "TIKOV",
  "TILDA",
  "TILDE",
  "TILDI",
  "TILDO",
  "TILEM",
  "TILEN",
  "TILGA",
  "TILGE",
  "TILGI",
  "TILGU",
  "TILIA",
  "TILIE",
  "TILIH",
  "TILII",
  "TILIO",
  "TILIS",
  "TILJA",
  "TILJU",
  "TILKA",
  "TILKE",
  "TILKI",
  "TILKO",
  "TILLA",
  "TILLE",
  "TILLI",
  "TILLO",
  "TILLU",
  "TILNA",
  "TILNE",
  "TILNI",
  "TILNU",
  "TILOM",
  "TILOS",
  "TILOV",
  "TILPA",
  "TILPE",
  "TILPI",
  "TILPU",
  "TILSE",
  "TIMAV",
  "TIMBS",
  "TIMEA",
  "TIMEE",
  "TIMEI",
  "TIMEJ",
  "TIMEM",
  "TIMEO",
  "TIMER",
  "TIMES",
  "TIMEV",
  "TIMIH",
  "TIMIN",
  "TIMJA",
  "TIMJU",
  "TIMKA",
  "TIMKE",
  "TIMKI",
  "TIMKO",
  "TIMKU",
  "TIMMA",
  "TIMME",
  "TIMMI",
  "TIMMS",
  "TIMMU",
  "TIMNA",
  "TIMNE",
  "TIMNI",
  "TIMNO",
  "TIMOK",
  "TIMOL",
  "TIMOM",
  "TIMON",
  "TIMOR",
  "TIMOŠ",
  "TIMOV",
  "TIMPA",
  "TIMPE",
  "TIMPI",
  "TIMPU",
  "TIMUR",
  "TIMUS",
  "TINAH",
  "TINAM",
  "TINCA",
  "TINCE",
  "TINCI",
  "TINCO",
  "TINEJ",
  "TINEL",
  "TINGA",
  "TINGE",
  "TINGI",
  "TINGU",
  "TINIH",
  "TININ",
  "TINJA",
  "TINJE",
  "TINJU",
  "TINKA",
  "TINKE",
  "TINKI",
  "TINKO",
  "TINOM",
  "TINOV",
  "TINTA",
  "TINTE",
  "TINTI",
  "TINTO",
  "TINTU",
  "TIOLA",
  "TIOLE",
  "TIOLI",
  "TIOLU",
  "TIOMA",
  "TIOTA",
  "TIOTU",
  "TIOVA",
  "TIOVE",
  "TIOVI",
  "TIOVO",
  "TIPAJ",
  "TIPAL",
  "TIPAM",
  "TIPAŠ",
  "TIPAT",
  "TIPAU",
  "TIPAV",
  "TIPEM",
  "TIPEN",
  "TIPEV",
  "TIPIC",
  "TIPIH",
  "TIPIK",
  "TIPIN",
  "TIPJA",
  "TIPJU",
  "TIPKA",
  "TIPKE",
  "TIPKI",
  "TIPKO",
  "TIPKU",
  "TIPNA",
  "TIPNE",
  "TIPNI",
  "TIPNO",
  "TIPOM",
  "TIPOV",
  "TIPPA",
  "TIPPE",
  "TIPPI",
  "TIPPL",
  "TIPPU",
  "TIPRA",
  "TIPRO",
  "TIPRU",
  "TIRAD",
  "TIRAJ",
  "TIRAK",
  "TIRAL",
  "TIRAM",
  "TIRAN",
  "TIRAŠ",
  "TIRAT",
  "TIRAU",
  "TIRAV",
  "TIRAŽ",
  "TIRCA",
  "TIRCE",
  "TIRCI",
  "TIRCO",
  "TIRCU",
  "TIREL",
  "TIREM",
  "TIREN",
  "TIRES",
  "TIREV",
  "TIRIH",
  "TIRIL",
  "TIRJA",
  "TIRJU",
  "TIRNA",
  "TIRNE",
  "TIRNI",
  "TIRNO",
  "TIROM",
  "TIROP",
  "TIROV",
  "TIRRA",
  "TIRRE",
  "TIRRI",
  "TIRRO",
  "TIRRU",
  "TIRSA",
  "TIRSE",
  "TIRSI",
  "TIRSU",
  "TIRŠA",
  "TIRŠE",
  "TIRŠI",
  "TIRŠU",
  "TISAH",
  "TISAJ",
  "TISAM",
  "TISCH",
  "TISCI",
  "TISEL",
  "TISEV",
  "TISHA",
  "TISHE",
  "TISHI",
  "TISHO",
  "TISHU",
  "TISIH",
  "TISIN",
  "TISJA",
  "TISJE",
  "TISJU",
  "TISKA",
  "TISKE",
  "TISKI",
  "TISKU",
  "TISLA",
  "TISLE",
  "TISLI",
  "TISLU",
  "TISOČ",
  "TISOM",
  "TISON",
  "TISOV",
  "TISSA",
  "TISSE",
  "TISSI",
  "TISSU",
  "TISTA",
  "TISTE",
  "TISTI",
  "TISTO",
  "TIŠČE",
  "TIŠČI",
  "TIŠČU",
  "TIŠEM",
  "TIŠIČ",
  "TIŠIN",
  "TIŠJA",
  "TIŠJE",
  "TIŠJI",
  "TIŠJO",
  "TIŠKA",
  "TIŠKE",
  "TIŠKI",
  "TIŠKO",
  "TIŠMA",
  "TIŠME",
  "TIŠMI",
  "TIŠMO",
  "TIŠMU",
  "TIŠOM",
  "TITAH",
  "TITAM",
  "TITAN",
  "TITEL",
  "TITER",
  "TITIH",
  "TITIN",
  "TITJA",
  "TITJU",
  "TITLA",
  "TITLE",
  "TITLI",
  "TITLU",
  "TITOM",
  "TITOV",
  "TITRA",
  "TITRE",
  "TITRI",
  "TITRU",
  "TITTL",
  "TITUL",
  "TITUS",
  "TIVAH",
  "TIVAM",
  "TIVAN",
  "TIVAT",
  "TIVIN",
  "TIVTA",
  "TIVTU",
  "TIZBA",
  "TIZBE",
  "TIZBI",
  "TIZBO",
  "TIZON",
  "TJALE",
  "TJANA",
  "TJANE",
  "TJANI",
  "TJANO",
  "TJARK",
  "TJAŠA",
  "TJAŠE",
  "TJAŠI",
  "TJAŠO",
  "TJAŠU",
  "TJAŽA",
  "TJAŽE",
  "TJAŽI",
  "TJAŽU",
  "TKACZ",
  "TKAČA",
  "TKAČE",
  "TKAČI",
  "TKAČU",
  "TKAJO",
  "TKALA",
  "TKALC",
  "TKALE",
  "TKALI",
  "TKALK",
  "TKALO",
  "TKAMA",
  "TKAME",
  "TKAMO",
  "TKANA",
  "TKANE",
  "TKANI",
  "TKANJ",
  "TKANO",
  "TKATA",
  "TKATE",
  "TKATI",
  "TKAVA",
  "TKAVC",
  "TKAVE",
  "TKAVI",
  "TKEJO",
  "TKEMA",
  "TKEME",
  "TKEMO",
  "TKETA",
  "TKETE",
  "TKEVA",
  "TKEVE",
  "TKEVI",
  "TKIMO",
  "TKITA",
  "TKITE",
  "TKIVA",
  "TKIVI",
  "TKIVO",
  "TKIVU",
  "TKOČA",
  "TKOČE",
  "TKOČI",
  "TKOČO",
  "TLAČI",
  "TLAČL",
  "TLAČU",
  "TLAIB",
  "TLAKA",
  "TLAKE",
  "TLAKI",
  "TLAKO",
  "TLAKU",
  "TLAMI",
  "TLEČA",
  "TLEČE",
  "TLEČI",
  "TLEČO",
  "TLELA",
  "TLELE",
  "TLELI",
  "TLELO",
  "TLEMI",
  "TLENJ",
  "TLESK",
  "TLETI",
  "TLIJO",
  "TLIMA",
  "TLIME",
  "TLIMO",
  "TLITA",
  "TLITE",
  "TLIVA",
  "TLIVE",
  "TLIVI",
  "TLIVK",
  "TMALL",
  "TNALA",
  "TNALC",
  "TNALE",
  "TNALI",
  "TNALO",
  "TNALU",
  "TOAST",
  "TOBAK",
  "TOBAL",
  "TOBAR",
  "TOBET",
  "TOBIE",
  "TOBIJ",
  "TOBIN",
  "TOBOL",
  "TOCCI",
  "TOCHA",
  "TOCHE",
  "TOCHI",
  "TOCHU",
  "TOČAH",
  "TOČAJ",
  "TOČAM",
  "TOČEN",
  "TOČIL",
  "TOČIM",
  "TOČIŠ",
  "TOČIT",
  "TOČIU",
  "TOČIV",
  "TOČKA",
  "TOČKE",
  "TOČKI",
  "TOČKO",
  "TOČNA",
  "TOČNE",
  "TOČNI",
  "TOČNO",
  "TODDA",
  "TODDE",
  "TODDI",
  "TODDU",
  "TODIČ",
  "TODIH",
  "TODJA",
  "TODJU",
  "TODOM",
  "TODOR",
  "TODOV",
  "TODTA",
  "TODTE",
  "TODTI",
  "TODTS",
  "TODTU",
  "TOENA",
  "TOENE",
  "TOENI",
  "TOENS",
  "TOENU",
  "TOFAJ",
  "TOFFA",
  "TOFFE",
  "TOFFI",
  "TOFFU",
  "TOFJU",
  "TOFTA",
  "TOFTE",
  "TOFTI",
  "TOFTU",
  "TOGAH",
  "TOGAM",
  "TOGAN",
  "TOGEM",
  "TOGGA",
  "TOGIH",
  "TOGIM",
  "TOGJA",
  "TOGJU",
  "TOGNI",
  "TOGOM",
  "TOGOT",
  "TOHEL",
  "TOHLA",
  "TOHLE",
  "TOHLI",
  "TOHLO",
  "TOHNA",
  "TOHNE",
  "TOHNI",
  "TOHNU",
  "TOHTI",
  "TOIFL",
  "TOITA",
  "TOITE",
  "TOITI",
  "TOITU",
  "TOIVA",
  "TOIVE",
  "TOIVI",
  "TOIVO",
  "TOIVU",
  "TOJOD",
  "TOKAJ",
  "TOKAN",
  "TOKAR",
  "TOKAT",
  "TOKAV",
  "TOKEJ",
  "TOKEM",
  "TOKEV",
  "TOKIA",
  "TOKIČ",
  "TOKIH",
  "TOKIN",
  "TOKIO",
  "TOKIT",
  "TOKIU",
  "TOKJA",
  "TOKJU",
  "TOKOJ",
  "TOKOM",
  "TOKOV",
  "TOKUD",
  "TOLAH",
  "TOLAJ",
  "TOLAM",
  "TOLAN",
  "TOLAR",
  "TOLCI",
  "TOLČE",
  "TOLČI",
  "TOLČT",
  "TOLDI",
  "TOLES",
  "TOLGA",
  "TOLGE",
  "TOLGI",
  "TOLGO",
  "TOLGU",
  "TOLIČ",
  "TOLIG",
  "TOLIH",
  "TOLIK",
  "TOLJA",
  "TOLJE",
  "TOLJI",
  "TOLJO",
  "TOLJU",
  "TOLKU",
  "TOLLA",
  "TOLLE",
  "TOLLI",
  "TOLLO",
  "TOLLU",
  "TOLOM",
  "TOLOV",
  "TOLPA",
  "TOLPE",
  "TOLPI",
  "TOLPO",
  "TOLST",
  "TOLŠČ",
  "TOMAC",
  "TOMAH",
  "TOMAJ",
  "TOMAK",
  "TOMAM",
  "TOMAN",
  "TOMAS",
  "TOMAŠ",
  "TOMAT",
  "TOMAŽ",
  "TOMBA",
  "TOMBE",
  "TOMBI",
  "TOMBO",
  "TOMBS",
  "TOMBU",
  "TOMCA",
  "TOMCE",
  "TOMCI",
  "TOMCU",
  "TOMEC",
  "TOMEI",
  "TOMEK",
  "TOMEM",
  "TOMER",
  "TOMES",
  "TOMEV",
  "TOMIA",
  "TOMIC",
  "TOMIČ",
  "TOMIE",
  "TOMIH",
  "TOMII",
  "TOMIK",
  "TOMIN",
  "TOMIO",
  "TOMIT",
  "TOMIU",
  "TOMJA",
  "TOMJU",
  "TOMKA",
  "TOMKE",
  "TOMKI",
  "TOMKO",
  "TOMKU",
  "TOMME",
  "TOMMI",
  "TOMOL",
  "TOMOM",
  "TOMOS",
  "TOMOV",
  "TOMPA",
  "TOMPE",
  "TOMPI",
  "TOMPO",
  "TOMPU",
  "TOMSA",
  "TOMSE",
  "TOMSI",
  "TOMSK",
  "TOMSU",
  "TOMŠE",
  "TOMZA",
  "TOMZE",
  "TOMZI",
  "TOMZO",
  "TOMZU",
  "TONAČ",
  "TONAH",
  "TONAM",
  "TONAŽ",
  "TONČA",
  "TONČE",
  "TONČI",
  "TONČK",
  "TONČU",
  "TONDA",
  "TONDE",
  "TONDI",
  "TONDO",
  "TONDU",
  "TONEČ",
  "TONEM",
  "TONER",
  "TONEŠ",
  "TONEV",
  "TONGA",
  "TONGE",
  "TONGI",
  "TONGO",
  "TONGU",
  "TONIA",
  "TONIE",
  "TONIH",
  "TONII",
  "TONIJ",
  "TONIK",
  "TONIL",
  "TONIN",
  "TONIO",
  "TONIT",
  "TONIU",
  "TONIV",
  "TONJA",
  "TONJE",
  "TONJI",
  "TONJO",
  "TONJU",
  "TONKA",
  "TONKE",
  "TONKI",
  "TONKO",
  "TONKS",
  "TONKU",
  "TONNA",
  "TONNE",
  "TONNI",
  "TONNO",
  "TONNU",
  "TONOM",
  "TONON",
  "TONOV",
  "TONTI",
  "TONUS",
  "TONUT",
  "TOOFA",
  "TOOFE",
  "TOOFI",
  "TOOFU",
  "TOOLA",
  "TOOLE",
  "TOOLI",
  "TOOLU",
  "TOOMA",
  "TOOME",
  "TOOMI",
  "TOOMU",
  "TOONA",
  "TOONE",
  "TOONI",
  "TOONU",
  "TOOPA",
  "TOOPE",
  "TOOPI",
  "TOOPU",
  "TOOST",
  "TOOTH",
  "TOOTS",
  "TOOZA",
  "TOOZE",
  "TOOZI",
  "TOOZU",
  "TOPAL",
  "TOPAZ",
  "TOPEČ",
  "TOPEL",
  "TOPEM",
  "TOPEN",
  "TOPER",
  "TOPEV",
  "TOPFA",
  "TOPFE",
  "TOPFI",
  "TOPFU",
  "TOPGA",
  "TOPIČ",
  "TOPIH",
  "TOPIK",
  "TOPIL",
  "TOPIM",
  "TOPIŠ",
  "TOPIT",
  "TOPIU",
  "TOPIV",
  "TOPJA",
  "TOPJU",
  "TOPLA",
  "TOPLE",
  "TOPLI",
  "TOPLO",
  "TOPNA",
  "TOPNE",
  "TOPNI",
  "TOPNO",
  "TOPOL",
  "TOPOM",
  "TOPOT",
  "TOPOV",
  "TOPPA",
  "TOPPE",
  "TOPPI",
  "TOPPU",
  "TOPRA",
  "TOPRU",
  "TOPSA",
  "TOPSE",
  "TOPSI",
  "TOPSU",
  "TOPUZ",
  "TORAH",
  "TORAJ",
  "TORAL",
  "TORAM",
  "TORBA",
  "TORBE",
  "TORBI",
  "TORBO",
  "TORČA",
  "TORČE",
  "TORČI",
  "TORČU",
  "TORDA",
  "TORDE",
  "TORDI",
  "TORDU",
  "TOREJ",
  "TOREK",
  "TOREM",
  "TOREN",
  "TOREV",
  "TORGA",
  "TORGE",
  "TORGI",
  "TORGO",
  "TORGU",
  "TORIC",
  "TORIČ",
  "TORIH",
  "TORIJ",
  "TORIL",
  "TORIN",
  "TORJA",
  "TORJU",
  "TORKA",
  "TORKE",
  "TORKI",
  "TORKO",
  "TORKU",
  "TORLA",
  "TORLE",
  "TORLI",
  "TORLO",
  "TORLU",
  "TORMA",
  "TORME",
  "TORMI",
  "TORMO",
  "TORMU",
  "TORNA",
  "TORNE",
  "TORNI",
  "TORNO",
  "TORNU",
  "TOROK",
  "TOROM",
  "TOROP",
  "TOROS",
  "TOROŠ",
  "TOROV",
  "TORPA",
  "TORPE",
  "TORPI",
  "TORPU",
  "TORRA",
  "TORRE",
  "TORRI",
  "TORRO",
  "TORRU",
  "TORSA",
  "TORSE",
  "TORSI",
  "TORSU",
  "TORTA",
  "TORTE",
  "TORTI",
  "TORTO",
  "TORTU",
  "TORUN",
  "TORUS",
  "TORZA",
  "TORZE",
  "TORZI",
  "TORZO",
  "TORZU",
  "TOSCA",
  "TOSCH",
  "TOSCU",
  "TOSHA",
  "TOSHE",
  "TOSHI",
  "TOSHU",
  "TOSIA",
  "TOSIČ",
  "TOSIE",
  "TOSIH",
  "TOSII",
  "TOSIJ",
  "TOSIO",
  "TOSIU",
  "TOSJA",
  "TOSJE",
  "TOSJI",
  "TOSJO",
  "TOSJU",
  "TOSKA",
  "TOSKE",
  "TOSKI",
  "TOSKO",
  "TOSKU",
  "TOSOM",
  "TOSOV",
  "TOSTA",
  "TOSTE",
  "TOSTI",
  "TOSTU",
  "TOSUN",
  "TOŠČA",
  "TOŠČU",
  "TOŠEL",
  "TOŠEM",
  "TOŠEV",
  "TOŠIČ",
  "TOŠIH",
  "TOŠJA",
  "TOŠJU",
  "TOŠLA",
  "TOŠLE",
  "TOŠLI",
  "TOŠLU",
  "TOŠOM",
  "TOŠOV",
  "TOTAJ",
  "TOTAL",
  "TOTEM",
  "TOTHA",
  "TOTHE",
  "TOTHI",
  "TOTHU",
  "TOTIH",
  "TOTIM",
  "TOTIN",
  "TOTJA",
  "TOTJU",
  "TOTOM",
  "TOTOV",
  "TOTRA",
  "TOTRE",
  "TOTRI",
  "TOTRO",
  "TOTTA",
  "TOTTE",
  "TOTTI",
  "TOTTU",
  "TOUIL",
  "TOUPS",
  "TOURA",
  "TOURE",
  "TOURN",
  "TOURS",
  "TOURU",
  "TOVAR",
  "TOVIL",
  "TOVIN",
  "TOVOR",
  "TOZAN",
  "TOZDA",
  "TOZDE",
  "TOZDI",
  "TOZDU",
  "TOZON",
  "TOZZA",
  "TOZZE",
  "TOZZI",
  "TOZZO",
  "TOZZU",
  "TOŽBA",
  "TOŽBE",
  "TOŽBI",
  "TOŽBO",
  "TOŽEČ",
  "TOŽEN",
  "TOŽIL",
  "TOŽIM",
  "TOŽIŠ",
  "TOŽIT",
  "TOŽIU",
  "TOŽIV",
  "TOŽNA",
  "TOŽNE",
  "TOŽNI",
  "TOŽNO",
  "TRAAT",
  "TRACA",
  "TRACE",
  "TRACI",
  "TRAČA",
  "TRAČE",
  "TRAČI",
  "TRAČU",
  "TRADA",
  "TRADE",
  "TRADI",
  "TRADU",
  "TRAFA",
  "TRAFE",
  "TRAFI",
  "TRAFO",
  "TRAFU",
  "TRAIL",
  "TRAIN",
  "TRAJA",
  "TRAJI",
  "TRAJN",
  "TRAJU",
  "TRAKA",
  "TRAKC",
  "TRAKE",
  "TRAKI",
  "TRAKL",
  "TRAKT",
  "TRAKU",
  "TRALA",
  "TRAMA",
  "TRAME",
  "TRAMI",
  "TRAMU",
  "TRANA",
  "TRANE",
  "TRANG",
  "TRANI",
  "TRANS",
  "TRANŠ",
  "TRANT",
  "TRANU",
  "TRAPA",
  "TRAPE",
  "TRAPI",
  "TRAPL",
  "TRAPO",
  "TRAPP",
  "TRAPU",
  "TRASA",
  "TRASE",
  "TRASI",
  "TRASK",
  "TRASO",
  "TRASS",
  "TRATA",
  "TRATE",
  "TRATI",
  "TRATO",
  "TRATT",
  "TRATU",
  "TRAUB",
  "TRAUD",
  "TRAUE",
  "TRAUN",
  "TRAUT",
  "TRAVA",
  "TRAVE",
  "TRAVI",
  "TRAVM",
  "TRAVO",
  "TRBEŽ",
  "TRBIČ",
  "TRBIŽ",
  "TRBOJ",
  "TRBUC",
  "TRBUH",
  "TRČAK",
  "TRČEK",
  "TRČEN",
  "TRČIL",
  "TRČIM",
  "TRČIŠ",
  "TRČIT",
  "TRČIU",
  "TRČIV",
  "TRČKA",
  "TRČKE",
  "TRČKI",
  "TRČKO",
  "TRČKU",
  "TRDAN",
  "TRDEČ",
  "TRDEL",
  "TRDEM",
  "TRDEN",
  "TRDET",
  "TRDEU",
  "TRDGA",
  "TRDIH",
  "TRDIK",
  "TRDIL",
  "TRDIM",
  "TRDIN",
  "TRDIŠ",
  "TRDIT",
  "TRDIU",
  "TRDIV",
  "TRDNA",
  "TRDNE",
  "TRDNI",
  "TRDNO",
  "TRDOT",
  "TREAT",
  "TREBA",
  "TREBČ",
  "TREBE",
  "TREBI",
  "TREBO",
  "TREBS",
  "TREBU",
  "TREEM",
  "TREEN",
  "TREFA",
  "TREFF",
  "TREFI",
  "TREFL",
  "TREFU",
  "TREGA",
  "TREGE",
  "TREGI",
  "TREGU",
  "TREIH",
  "TREIL",
  "TREJA",
  "TREJO",
  "TREJU",
  "TRELA",
  "TRELC",
  "TRELE",
  "TRELI",
  "TRELO",
  "TRELU",
  "TREMA",
  "TREME",
  "TREMI",
  "TREMO",
  "TRENA",
  "TRENC",
  "TREND",
  "TRENE",
  "TRENI",
  "TRENJ",
  "TRENK",
  "TRENN",
  "TRENT",
  "TRENU",
  "TRENZ",
  "TREOM",
  "TREOV",
  "TREPČ",
  "TREPK",
  "TRESE",
  "TRESI",
  "TRESK",
  "TRESS",
  "TREST",
  "TRESU",
  "TRETA",
  "TRETE",
  "TRETI",
  "TRETT",
  "TREUA",
  "TREUE",
  "TREUI",
  "TREUT",
  "TREUU",
  "TREVA",
  "TREVE",
  "TREVI",
  "TREVO",
  "TRGAČ",
  "TRGAJ",
  "TRGAL",
  "TRGAM",
  "TRGAN",
  "TRGAŠ",
  "TRGAT",
  "TRGAU",
  "TRGAV",
  "TRGCA",
  "TRGCE",
  "TRGCI",
  "TRGCU",
  "TRGEC",
  "TRGEM",
  "TRGEV",
  "TRGIH",
  "TRGJA",
  "TRGJU",
  "TRGOM",
  "TRGOV",
  "TRGUJ",
  "TRHEL",
  "TRHLA",
  "TRHLE",
  "TRHLI",
  "TRHLO",
  "TRHLU",
  "TRIAD",
  "TRIAH",
  "TRIAK",
  "TRIAM",
  "TRIAS",
  "TRIAŽ",
  "TRIBA",
  "TRIBE",
  "TRIBI",
  "TRIBU",
  "TRIEM",
  "TRIEP",
  "TRIER",
  "TRIEV",
  "TRIGG",
  "TRIIH",
  "TRIJA",
  "TRIJE",
  "TRIJU",
  "TRIKA",
  "TRIKE",
  "TRIKI",
  "TRIKO",
  "TRIKU",
  "TRILL",
  "TRIMA",
  "TRIME",
  "TRIMI",
  "TRIMM",
  "TRIMO",
  "TRIMU",
  "TRINA",
  "TRINE",
  "TRINH",
  "TRINI",
  "TRINK",
  "TRINO",
  "TRIOD",
  "TRIOK",
  "TRIOL",
  "TRIOM",
  "TRIOV",
  "TRIOZ",
  "TRIPA",
  "TRIPI",
  "TRIPP",
  "TRIPS",
  "TRIPU",
  "TRISA",
  "TRISE",
  "TRISH",
  "TRISI",
  "TRIST",
  "TRISU",
  "TRIŠA",
  "TRIŠE",
  "TRIŠI",
  "TRIŠO",
  "TRITA",
  "TRITE",
  "TRITT",
  "TRIVA",
  "TRIVE",
  "TRIVI",
  "TRIVO",
  "TRIVU",
  "TRJEN",
  "TRKAJ",
  "TRKAL",
  "TRKAM",
  "TRKAŠ",
  "TRKAT",
  "TRKAU",
  "TRKAV",
  "TRKEM",
  "TRKEV",
  "TRKIH",
  "TRKJA",
  "TRKJU",
  "TRKNE",
  "TRKNI",
  "TRKNU",
  "TRKOM",
  "TRKOV",
  "TRLEP",
  "TRLIC",
  "TRLIN",
  "TRLJA",
  "TRLJE",
  "TRLJI",
  "TRLJO",
  "TRMAH",
  "TRMAM",
  "TRMIC",
  "TRNAČ",
  "TRNAT",
  "TRNAV",
  "TRNEK",
  "TRNEM",
  "TRNEV",
  "TRNEŽ",
  "TRNIC",
  "TRNIČ",
  "TRNIH",
  "TRNIM",
  "TRNIN",
  "TRNJA",
  "TRNJE",
  "TRNJU",
  "TRNKA",
  "TRNKE",
  "TRNKI",
  "TRNKO",
  "TRNKU",
  "TRNOM",
  "TRNOV",
  "TROBC",
  "TROBE",
  "TROBI",
  "TROBO",
  "TROBU",
  "TROFI",
  "TROFU",
  "TROGA",
  "TROGI",
  "TROGU",
  "TROHA",
  "TROHE",
  "TROHI",
  "TROHO",
  "TROHU",
  "TROIL",
  "TROJA",
  "TROJC",
  "TROJI",
  "TROJK",
  "TROJO",
  "TROKA",
  "TROKE",
  "TROKI",
  "TROKO",
  "TROKU",
  "TROLA",
  "TROLE",
  "TROLI",
  "TROLL",
  "TROLO",
  "TROLU",
  "TROMB",
  "TROMM",
  "TROMP",
  "TROMS",
  "TRONA",
  "TROND",
  "TRONE",
  "TRONI",
  "TRONU",
  "TROOB",
  "TROOD",
  "TROPA",
  "TROPE",
  "TROPI",
  "TROPP",
  "TROPU",
  "TROSA",
  "TROSE",
  "TROSI",
  "TROST",
  "TROSU",
  "TROŠI",
  "TROŠT",
  "TROŠU",
  "TROTA",
  "TROTE",
  "TROTH",
  "TROTI",
  "TROTT",
  "TROTU",
  "TROTZ",
  "TROUP",
  "TROUT",
  "TRPAJ",
  "TRPAL",
  "TRPAM",
  "TRPAŠ",
  "TRPAT",
  "TRPAU",
  "TRPAV",
  "TRPEČ",
  "TRPEK",
  "TRPEL",
  "TRPEN",
  "TRPET",
  "TRPEU",
  "TRPEŽ",
  "TRPIM",
  "TRPIN",
  "TRPIŠ",
  "TRPKA",
  "TRPKE",
  "TRPKI",
  "TRPKO",
  "TRPNA",
  "TRPNE",
  "TRPNI",
  "TRPNO",
  "TRSAK",
  "TRSEK",
  "TRSEM",
  "TRSEN",
  "TRSEV",
  "TRSIH",
  "TRSIN",
  "TRSJA",
  "TRSJE",
  "TRSJU",
  "TRSKA",
  "TRSKE",
  "TRSKI",
  "TRSKO",
  "TRSKU",
  "TRSNA",
  "TRSNE",
  "TRSNI",
  "TRSNO",
  "TRSOM",
  "TRSOV",
  "TRSTA",
  "TRSTE",
  "TRSTI",
  "TRSTU",
  "TRŠAN",
  "TRŠAR",
  "TRŠAT",
  "TRŠEK",
  "TRŠEM",
  "TRŠIH",
  "TRŠIM",
  "TRŠKA",
  "TRŠKE",
  "TRŠKI",
  "TRŠKO",
  "TRŠKU",
  "TRTAH",
  "TRTAM",
  "TRTEN",
  "TRTIC",
  "TRTNA",
  "TRTNE",
  "TRTNI",
  "TRTNO",
  "TRUBA",
  "TRUBE",
  "TRUBI",
  "TRUBU",
  "TRUČL",
  "TRUDA",
  "TRUDE",
  "TRUDI",
  "TRUDO",
  "TRUDU",
  "TRUEB",
  "TRUGA",
  "TRUGE",
  "TRUGI",
  "TRUGO",
  "TRULA",
  "TRULE",
  "TRULI",
  "TRULL",
  "TRULO",
  "TRULS",
  "TRUMA",
  "TRUME",
  "TRUMI",
  "TRUMK",
  "TRUMO",
  "TRUMP",
  "TRUNK",
  "TRUPA",
  "TRUPE",
  "TRUPI",
  "TRUPU",
  "TRUSS",
  "TRUST",
  "TRUŠČ",
  "TRUŠK",
  "TRUTA",
  "TRUTE",
  "TRUTH",
  "TRUTI",
  "TRUTU",
  "TRZAČ",
  "TRZAJ",
  "TRZAL",
  "TRZAM",
  "TRZAŠ",
  "TRZAT",
  "TRZAU",
  "TRZAV",
  "TRZIN",
  "TRZNE",
  "TRZNI",
  "TRZNU",
  "TRŽAN",
  "TRŽCA",
  "TRŽCU",
  "TRŽEC",
  "TRŽEČ",
  "TRŽEM",
  "TRŽEN",
  "TRŽEŠ",
  "TRŽIČ",
  "TRŽIL",
  "TRŽIM",
  "TRŽIŠ",
  "TRŽIT",
  "TRŽIU",
  "TRŽIV",
  "TRŽNA",
  "TRŽNE",
  "TRŽNI",
  "TRŽNO",
  "TSEJA",
  "TSEJE",
  "TSEJI",
  "TSEJU",
  "TSION",
  "TSONI",
  "TUALA",
  "TUALE",
  "TUALI",
  "TUALU",
  "TUBAH",
  "TUBAM",
  "TUBBA",
  "TUBBE",
  "TUBBI",
  "TUBBS",
  "TUBBU",
  "TUBES",
  "TUBIN",
  "TUBIT",
  "TUBUL",
  "TUBUS",
  "TUCAH",
  "TUCAM",
  "TUCCI",
  "TUCHA",
  "TUCHE",
  "TUCHI",
  "TUCHU",
  "TUCKA",
  "TUCKE",
  "TUCKI",
  "TUCKU",
  "TUČIČ",
  "TUČNA",
  "TUČNE",
  "TUČNI",
  "TUČNO",
  "TUDEL",
  "TUDIJ",
  "TUDOR",
  "TUELA",
  "TUELE",
  "TUELI",
  "TUELU",
  "TUENI",
  "TUERK",
  "TUEVA",
  "TUEVE",
  "TUEVI",
  "TUEVO",
  "TUEVU",
  "TUFAH",
  "TUFAM",
  "TUFAN",
  "TUFEK",
  "TUFEM",
  "TUFIH",
  "TUFIT",
  "TUFJA",
  "TUFJU",
  "TUFKA",
  "TUFKE",
  "TUFKI",
  "TUFKU",
  "TUFOM",
  "TUFOV",
  "TUFTA",
  "TUFTE",
  "TUFTI",
  "TUFTS",
  "TUFTU",
  "TUGAH",
  "TUGAL",
  "TUGAM",
  "TUGAN",
  "TUGIH",
  "TUGIN",
  "TUGJA",
  "TUGJU",
  "TUGOM",
  "TUGOV",
  "TUHAN",
  "TUHEN",
  "TUHNA",
  "TUHNE",
  "TUHNI",
  "TUHNO",
  "TUHTA",
  "TUHTI",
  "TUHTU",
  "TUIJA",
  "TUIJE",
  "TUIJI",
  "TUIJO",
  "TUIJU",
  "TUILA",
  "TUILE",
  "TUILI",
  "TUILU",
  "TUJAH",
  "TUJAM",
  "TUJCA",
  "TUJCE",
  "TUJCI",
  "TUJCU",
  "TUJČI",
  "TUJČL",
  "TUJČU",
  "TUJEC",
  "TUJEK",
  "TUJEM",
  "TUJGA",
  "TUJIH",
  "TUJIM",
  "TUJIN",
  "TUJKA",
  "TUJKE",
  "TUJKI",
  "TUJKO",
  "TUJKU",
  "TUKAJ",
  "TUKAN",
  "TUKEL",
  "TUKLA",
  "TUKLE",
  "TUKLI",
  "TUKLO",
  "TULAR",
  "TULCA",
  "TULCE",
  "TULCI",
  "TULCU",
  "TULEC",
  "TULEČ",
  "TULEM",
  "TULEV",
  "TULEŽ",
  "TULIA",
  "TULIE",
  "TULIH",
  "TULII",
  "TULIJ",
  "TULIL",
  "TULIM",
  "TULIO",
  "TULIP",
  "TULIŠ",
  "TULIT",
  "TULIU",
  "TULIV",
  "TULJA",
  "TULJU",
  "TULKA",
  "TULKE",
  "TULKI",
  "TULKO",
  "TULLA",
  "TULLE",
  "TULLI",
  "TULLN",
  "TULLO",
  "TULLU",
  "TULOM",
  "TULOV",
  "TULPA",
  "TULPE",
  "TULPI",
  "TULPU",
  "TULSA",
  "TULSE",
  "TULSI",
  "TULSO",
  "TUMAH",
  "TUMAM",
  "TUMAN",
  "TUMBA",
  "TUMBI",
  "TUMBU",
  "TUMIH",
  "TUMIN",
  "TUMJA",
  "TUMJU",
  "TUMOM",
  "TUMOR",
  "TUMOV",
  "TUMUL",
  "TUNAH",
  "TUNAM",
  "TUNDE",
  "TUNEL",
  "TUNEM",
  "TUNEV",
  "TUNGA",
  "TUNGE",
  "TUNGI",
  "TUNGU",
  "TUNIE",
  "TUNIH",
  "TUNIK",
  "TUNIS",
  "TUNJA",
  "TUNJE",
  "TUNJI",
  "TUNJO",
  "TUNJU",
  "TUNKA",
  "TUNKI",
  "TUNKS",
  "TUNKU",
  "TUNOM",
  "TUNON",
  "TUNOV",
  "TUOMI",
  "TUPAJ",
  "TUPAK",
  "TUPČI",
  "TUPEM",
  "TUPGA",
  "TUPIH",
  "TUPIJ",
  "TUPIM",
  "TUPJA",
  "TUPJE",
  "TUPJI",
  "TUPJO",
  "TUPJU",
  "TUPOU",
  "TURAH",
  "TURAK",
  "TURAL",
  "TURAM",
  "TURAN",
  "TURAV",
  "TURBA",
  "TURBE",
  "TURBI",
  "TURBO",
  "TURBU",
  "TURCI",
  "TURCK",
  "TURCU",
  "TUREK",
  "TUREL",
  "TUREM",
  "TUREN",
  "TUREV",
  "TURIA",
  "TURIC",
  "TURIE",
  "TURIH",
  "TURII",
  "TURIN",
  "TURIO",
  "TURIU",
  "TURJA",
  "TURJE",
  "TURJU",
  "TURKA",
  "TURKE",
  "TURKI",
  "TURKK",
  "TURKL",
  "TURKU",
  "TURLI",
  "TURNA",
  "TURNE",
  "TURNI",
  "TURNO",
  "TURNŠ",
  "TURNU",
  "TUROM",
  "TUROS",
  "TUROV",
  "TURRA",
  "TURRE",
  "TURRI",
  "TURRO",
  "TURRU",
  "TURUK",
  "TUSCA",
  "TUSCE",
  "TUSCI",
  "TUSCO",
  "TUSCU",
  "TUSHE",
  "TUSHI",
  "TUSKA",
  "TUSKE",
  "TUSKI",
  "TUSKU",
  "TUSON",
  "TUŠAK",
  "TUŠAR",
  "TUŠEK",
  "TUŠEM",
  "TUŠEV",
  "TUŠIH",
  "TUŠJA",
  "TUŠJU",
  "TUŠKA",
  "TUŠKE",
  "TUŠKI",
  "TUŠKU",
  "TUŠOM",
  "TUŠOV",
  "TUTAH",
  "TUTAM",
  "TUTAN",
  "TUTIČ",
  "TUTIH",
  "TUTIN",
  "TUTJA",
  "TUTJU",
  "TUTOM",
  "TUTOR",
  "TUTOV",
  "TUTTA",
  "TUTTE",
  "TUTTI",
  "TUTTO",
  "TUTTU",
  "TUTUŠ",
  "TUUKK",
  "TUULI",
  "TUVAL",
  "TUZIH",
  "TUZIN",
  "TUZJA",
  "TUZJU",
  "TUZLA",
  "TUZLE",
  "TUZLI",
  "TUZLO",
  "TUZOM",
  "TUZOV",
  "TUZZI",
  "TVEEN",
  "TVEGA",
  "TVEGI",
  "TVEGU",
  "TVEIT",
  "TVERA",
  "TVERU",
  "TVEST",
  "TVEZE",
  "TVEZI",
  "TVEZU",
  "TVIDA",
  "TVIDI",
  "TVIDU",
  "TVITA",
  "TVITE",
  "TVITI",
  "TVITU",
  "TVOJA",
  "TVOJE",
  "TVOJI",
  "TVOJO",
  "TVORA",
  "TVORB",
  "TVORC",
  "TVORE",
  "TVORI",
  "TVORK",
  "TVORU",
  "TVRDA",
  "TVRDE",
  "TVRDK",
  "TZARA",
  "UAKAR",
  "UBACH",
  "UBADA",
  "UBADI",
  "UBADO",
  "UBADU",
  "UBALD",
  "UBERE",
  "UBERI",
  "UBEŽE",
  "UBEŽI",
  "UBEŽU",
  "UBIJA",
  "UBIJE",
  "UBIJI",
  "UBIJU",
  "UBILA",
  "UBILE",
  "UBILI",
  "UBILO",
  "UBIRA",
  "UBIRI",
  "UBIRU",
  "UBISK",
  "UBITA",
  "UBITE",
  "UBITI",
  "UBITO",
  "UBLIH",
  "UBLJA",
  "UBLJU",
  "UBLOM",
  "UBLOV",
  "UBODA",
  "UBODE",
  "UBODI",
  "UBODU",
  "UBOGA",
  "UBOGE",
  "UBOGI",
  "UBOGO",
  "UBOGU",
  "UBOJA",
  "UBOJE",
  "UBOJI",
  "UBOJU",
  "UBOST",
  "UBOŽC",
  "UBRAL",
  "UBRAN",
  "UBRAT",
  "UBRAU",
  "UBRAV",
  "UCCIA",
  "UCCIE",
  "UCCII",
  "UCCIO",
  "UCCIU",
  "UCHAN",
  "UCHID",
  "UCMAN",
  "UCVRE",
  "UCVRI",
  "UCVRL",
  "UČAKA",
  "UČAKI",
  "UČAKU",
  "UČEČA",
  "UČEČE",
  "UČEČI",
  "UČEČO",
  "UČEMA",
  "UČENA",
  "UČENC",
  "UČENE",
  "UČENI",
  "UČENJ",
  "UČENK",
  "UČENO",
  "UČERI",
  "UČIJO",
  "UČILA",
  "UČILE",
  "UČILI",
  "UČILN",
  "UČILO",
  "UČILU",
  "UČIMA",
  "UČIME",
  "UČIMO",
  "UČITA",
  "UČITE",
  "UČITI",
  "UČIVA",
  "UČIVE",
  "UČIVI",
  "UČJAR",
  "UČNEM",
  "UČNGA",
  "UČNIH",
  "UČNIK",
  "UČNIM",
  "UČNIN",
  "UČOMA",
  "UČOTA",
  "UČOTU",
  "UDAJA",
  "UDAJE",
  "UDAJI",
  "UDAJO",
  "UDAJU",
  "UDALA",
  "UDALE",
  "UDALI",
  "UDALL",
  "UDALO",
  "UDAMA",
  "UDAME",
  "UDAMO",
  "UDANA",
  "UDANE",
  "UDANI",
  "UDANO",
  "UDARA",
  "UDARC",
  "UDARE",
  "UDARI",
  "UDARU",
  "UDATA",
  "UDATE",
  "UDATI",
  "UDAVA",
  "UDAVE",
  "UDAVI",
  "UDAVU",
  "UDBAŠ",
  "UDDIN",
  "UDEJA",
  "UDEJE",
  "UDEJI",
  "UDEJU",
  "UDELA",
  "UDELI",
  "UDELL",
  "UDELU",
  "UDENC",
  "UDENK",
  "UDERE",
  "UDETA",
  "UDETE",
  "UDETI",
  "UDETU",
  "UDIJA",
  "UDIJE",
  "UDIJI",
  "UDIJU",
  "UDIMO",
  "UDIRA",
  "UDIRI",
  "UDIRU",
  "UDITA",
  "UDITE",
  "UDIVA",
  "UDJEM",
  "UDMAT",
  "UDNES",
  "UDOJI",
  "UDOKA",
  "UDOKE",
  "UDOKI",
  "UDOKO",
  "UDOKU",
  "UDOMA",
  "UDOMI",
  "UDOMU",
  "UDORA",
  "UDORE",
  "UDORI",
  "UDORU",
  "UDOTA",
  "UDOTU",
  "UDOVA",
  "UDOVČ",
  "UDOVE",
  "UDOVI",
  "UDOVO",
  "UDREM",
  "UDREŠ",
  "UDRET",
  "UDRIH",
  "UDRLA",
  "UDRLE",
  "UDRLI",
  "UDRLO",
  "UDRTA",
  "UDRTE",
  "UDRTI",
  "UDRTO",
  "UDUČA",
  "UDUČE",
  "UDUČI",
  "UDUČU",
  "UDUMA",
  "UDUME",
  "UDUMI",
  "UDUMO",
  "UDUMU",
  "UDUŠI",
  "UDUŠU",
  "UEDAH",
  "UEDAM",
  "UEDIH",
  "UEDJA",
  "UEDJU",
  "UEDOM",
  "UEDOV",
  "UEFIN",
  "UENAL",
  "UENIH",
  "UENJA",
  "UENJU",
  "UENOM",
  "UENOV",
  "UERJA",
  "UERJE",
  "UERJI",
  "UFČAN",
  "UFSKA",
  "UFSKE",
  "UFSKI",
  "UFSKO",
  "UGAIL",
  "UGAJA",
  "UGAJI",
  "UGAJU",
  "UGANE",
  "UGANI",
  "UGANK",
  "UGANU",
  "UGASI",
  "UGASU",
  "UGAŠA",
  "UGAŠI",
  "UGAŠU",
  "UGIBA",
  "UGIBI",
  "UGIBU",
  "UGISA",
  "UGISE",
  "UGISI",
  "UGISU",
  "UGLED",
  "UGLEJ",
  "UGLET",
  "UGNAL",
  "UGNAN",
  "UGNAT",
  "UGNAU",
  "UGNAV",
  "UGODI",
  "UGODU",
  "UGOJI",
  "UGOJU",
  "UGOMA",
  "UGOTA",
  "UGOTU",
  "UGOVA",
  "UGOVE",
  "UGOVI",
  "UGOVO",
  "UGREN",
  "UGREZ",
  "UGRIN",
  "UGRIZ",
  "UHAČA",
  "UHAČE",
  "UHAČI",
  "UHAČU",
  "UHAJA",
  "UHAJI",
  "UHAJU",
  "UHANA",
  "UHANE",
  "UHANI",
  "UHANU",
  "UHATA",
  "UHATE",
  "UHATI",
  "UHATO",
  "UHELJ",
  "UHING",
  "UHITI",
  "UHITU",
  "UHLER",
  "UHLIG",
  "UHLIH",
  "UHLIK",
  "UHLIR",
  "UHLJA",
  "UHLJE",
  "UHLJI",
  "UHLJU",
  "UHLOM",
  "UHLOV",
  "UHLSA",
  "UHLSE",
  "UHLSI",
  "UHLSU",
  "UHODI",
  "UHODU",
  "UHOTA",
  "UHOTE",
  "UHOTI",
  "UHOTO",
  "UHOTU",
  "UHRIG",
  "UHRIN",
  "UHURA",
  "UHURE",
  "UHURI",
  "UHURO",
  "UHURU",
  "UIDEM",
  "UIDEŠ",
  "UIGRA",
  "UIGRI",
  "UIGRU",
  "UJAHA",
  "UJAHI",
  "UJAHU",
  "UJAME",
  "UJAMI",
  "UJANA",
  "UJANE",
  "UJANI",
  "UJANO",
  "UJAŠE",
  "UJAŠI",
  "UJCEM",
  "UJCEV",
  "UJCIH",
  "UJCJA",
  "UJCJU",
  "UJCOM",
  "UJCOV",
  "UJČEV",
  "UJČIČ",
  "UJČIN",
  "UJČKA",
  "UJČKI",
  "UJČKU",
  "UJČOV",
  "UJEBA",
  "UJEBI",
  "UJEBU",
  "UJEDA",
  "UJEDE",
  "UJEDI",
  "UJEDO",
  "UJEDU",
  "UJEJO",
  "UJELA",
  "UJELE",
  "UJELI",
  "UJELO",
  "UJEMA",
  "UJEME",
  "UJEMI",
  "UJEMO",
  "UJEMU",
  "UJEST",
  "UJETA",
  "UJETE",
  "UJETI",
  "UJETO",
  "UJEVA",
  "UJEVE",
  "UJEVI",
  "UJEZI",
  "UJEZU",
  "UJGUR",
  "UJKAN",
  "UJMAH",
  "UJMAM",
  "UJNAH",
  "UJNAM",
  "UJNIN",
  "UKAJA",
  "UKAJE",
  "UKAJI",
  "UKAJO",
  "UKAJU",
  "UKALA",
  "UKALE",
  "UKALI",
  "UKALO",
  "UKALU",
  "UKAMA",
  "UKAME",
  "UKAMI",
  "UKAMO",
  "UKANA",
  "UKANC",
  "UKANE",
  "UKANI",
  "UKANJ",
  "UKANO",
  "UKANU",
  "UKATA",
  "UKATE",
  "UKATI",
  "UKAVA",
  "UKAVE",
  "UKAVI",
  "UKAZA",
  "UKAZE",
  "UKAZI",
  "UKAZU",
  "UKAŽE",
  "UKAŽI",
  "UKERT",
  "UKHSA",
  "UKHSE",
  "UKHSI",
  "UKHSO",
  "UKIMO",
  "UKINE",
  "UKINI",
  "UKINU",
  "UKIPA",
  "UKIPU",
  "UKITA",
  "UKITE",
  "UKIVA",
  "UKLEL",
  "UKLET",
  "UKLEU",
  "UKLON",
  "UKMAR",
  "UKOMA",
  "UKOMU",
  "UKORA",
  "UKORE",
  "UKORI",
  "UKORU",
  "UKOSI",
  "UKOSU",
  "UKOTA",
  "UKOTE",
  "UKOTI",
  "UKOTO",
  "UKOTU",
  "UKOVA",
  "UKOVE",
  "UKOVI",
  "UKOVO",
  "UKREP",
  "UKUHA",
  "UKUHI",
  "UKUHU",
  "UKVAH",
  "UKVAM",
  "ULAGA",
  "ULAGE",
  "ULAGI",
  "ULAGO",
  "ULAGU",
  "ULAKA",
  "ULAKE",
  "ULAKI",
  "ULAKO",
  "ULAMA",
  "ULAME",
  "ULAMI",
  "ULAMU",
  "ULANA",
  "ULANC",
  "ULANE",
  "ULANI",
  "ULANK",
  "ULANO",
  "ULANU",
  "ULBIG",
  "ULBLA",
  "ULBLE",
  "ULBLI",
  "ULBLU",
  "ULCEJ",
  "ULČAR",
  "ULEČI",
  "ULEČT",
  "ULEGU",
  "ULEKS",
  "ULEMA",
  "ULEME",
  "ULEMI",
  "ULEMO",
  "ULEMU",
  "ULETA",
  "ULETE",
  "ULETI",
  "ULETU",
  "ULEZI",
  "ULEŽE",
  "ULEŽI",
  "ULEŽU",
  "ULFIH",
  "ULFJA",
  "ULFJU",
  "ULFOM",
  "ULFOV",
  "ULICA",
  "ULICE",
  "ULICI",
  "ULICO",
  "ULIJA",
  "ULIJE",
  "ULIJI",
  "ULIJO",
  "ULIJU",
  "ULIKS",
  "ULILA",
  "ULILE",
  "ULILI",
  "ULILO",
  "ULIMA",
  "ULIME",
  "ULIMO",
  "ULINA",
  "ULINE",
  "ULINI",
  "ULINO",
  "ULINU",
  "ULIPI",
  "ULITA",
  "ULITE",
  "ULITI",
  "ULITO",
  "ULIVA",
  "ULIVE",
  "ULIVI",
  "ULIVU",
  "ULJAN",
  "ULJEM",
  "ULJES",
  "ULJEV",
  "ULJIH",
  "ULJIN",
  "ULJJA",
  "ULJJU",
  "ULJOM",
  "ULJOV",
  "ULKAH",
  "ULKAM",
  "ULKIN",
  "ULKUS",
  "ULLAH",
  "ULLAM",
  "ULLIN",
  "ULLOA",
  "ULLOE",
  "ULLOI",
  "ULLOJ",
  "ULLOM",
  "ULLOO",
  "ULLOU",
  "ULLOV",
  "ULLUM",
  "ULMAN",
  "ULMER",
  "ULMIH",
  "ULMIS",
  "ULMJA",
  "ULMJU",
  "ULMOM",
  "ULMOV",
  "ULOMA",
  "ULOMI",
  "ULOMU",
  "ULOTA",
  "ULOTH",
  "ULOTU",
  "ULOVA",
  "ULOVE",
  "ULOVI",
  "ULOVO",
  "ULOVU",
  "ULRIH",
  "ULRIK",
  "ULTRA",
  "ULTRE",
  "ULTRI",
  "ULTRO",
  "ULUJA",
  "ULUJE",
  "ULUJI",
  "ULUJU",
  "ULURU",
  "UMAGA",
  "UMAGU",
  "UMAIR",
  "UMALI",
  "UMAMA",
  "UMAMI",
  "UMANA",
  "UMANU",
  "UMARI",
  "UMAZU",
  "UMAŽE",
  "UMAŽI",
  "UMBER",
  "UMBRA",
  "UMBRE",
  "UMBRI",
  "UMBRO",
  "UMEDI",
  "UMEDU",
  "UMEJO",
  "UMEKA",
  "UMEKE",
  "UMEKI",
  "UMEKU",
  "UMELA",
  "UMELE",
  "UMELI",
  "UMELO",
  "UMELU",
  "UMEMA",
  "UMEME",
  "UMEMO",
  "UMERI",
  "UMERU",
  "UMESI",
  "UMESU",
  "UMEŠA",
  "UMEŠI",
  "UMEŠU",
  "UMETA",
  "UMETE",
  "UMETI",
  "UMETO",
  "UMEVA",
  "UMEVE",
  "UMEVI",
  "UMEVU",
  "UMIČE",
  "UMIJE",
  "UMIKA",
  "UMIKE",
  "UMIKI",
  "UMIKU",
  "UMILA",
  "UMILE",
  "UMILI",
  "UMILO",
  "UMILU",
  "UMIMO",
  "UMINA",
  "UMINE",
  "UMINI",
  "UMINO",
  "UMIRA",
  "UMIRI",
  "UMIRU",
  "UMITA",
  "UMITE",
  "UMITI",
  "UMITO",
  "UMIVA",
  "UMIVI",
  "UMIVU",
  "UMKIH",
  "UMKJA",
  "UMKJU",
  "UMKOM",
  "UMKOV",
  "UMNEM",
  "UMNGA",
  "UMNIH",
  "UMNIK",
  "UMNIM",
  "UMOMA",
  "UMORA",
  "UMORE",
  "UMORI",
  "UMORU",
  "UMOTA",
  "UMOTU",
  "UMOVU",
  "UMREM",
  "UMREŠ",
  "UMRET",
  "UMRJE",
  "UMRLA",
  "UMRLE",
  "UMRLI",
  "UMRLO",
  "UMSKA",
  "UMSKE",
  "UMSKI",
  "UMSKO",
  "UMUJE",
  "UMUNN",
  "UMUTA",
  "UMUTE",
  "UMUTI",
  "UMUTU",
  "UNAIA",
  "UNAIE",
  "UNAII",
  "UNAIU",
  "UNAMA",
  "UNAMI",
  "UNCAL",
  "UNCEM",
  "UNCJA",
  "UNCJU",
  "UNCOM",
  "UNČAH",
  "UNČAM",
  "UNČAN",
  "UNDAV",
  "UNEPA",
  "UNEPU",
  "UNESE",
  "UNESI",
  "UNEST",
  "UNESU",
  "UNGAR",
  "UNGER",
  "UNHCR",
  "UNIAN",
  "UNIAT",
  "UNICA",
  "UNICE",
  "UNICI",
  "UNICO",
  "UNIČI",
  "UNIČL",
  "UNIČU",
  "UNIJA",
  "UNIJE",
  "UNIJI",
  "UNIJO",
  "UNIMA",
  "UNIMI",
  "UNINA",
  "UNINE",
  "UNINI",
  "UNINO",
  "UNION",
  "UNIOR",
  "UNISA",
  "UNISU",
  "UNIŠE",
  "UNKIČ",
  "UNKIH",
  "UNKJA",
  "UNKJU",
  "UNKOM",
  "UNKOV",
  "UNMIK",
  "UNNIK",
  "UNOLD",
  "UNOMA",
  "UNOTA",
  "UNOTU",
  "UNOVA",
  "UNOVE",
  "UNOVI",
  "UNOVO",
  "UNPIS",
  "UNRUH",
  "UNSKA",
  "UNSKE",
  "UNSKI",
  "UNSKO",
  "UNŠKA",
  "UNŠKE",
  "UNŠKI",
  "UNŠKO",
  "UNTIH",
  "UNTJA",
  "UNTJU",
  "UNTOM",
  "UNTOV",
  "UNUCK",
  "UNUKA",
  "UNUKE",
  "UNUKI",
  "UNUKU",
  "UPADA",
  "UPADE",
  "UPADI",
  "UPADO",
  "UPADU",
  "UPAJE",
  "UPAJO",
  "UPALA",
  "UPALE",
  "UPALI",
  "UPALO",
  "UPAMA",
  "UPAME",
  "UPAMO",
  "UPANJ",
  "UPARI",
  "UPARU",
  "UPAST",
  "UPATA",
  "UPATE",
  "UPATI",
  "UPAVA",
  "UPAVE",
  "UPAVI",
  "UPBIT",
  "UPEHA",
  "UPEHI",
  "UPEHU",
  "UPELA",
  "UPELE",
  "UPELI",
  "UPELJ",
  "UPELO",
  "UPENI",
  "UPENU",
  "UPERE",
  "UPERI",
  "UPERU",
  "UPETA",
  "UPETE",
  "UPETI",
  "UPETO",
  "UPEVA",
  "UPEVI",
  "UPEVU",
  "UPHAM",
  "UPIMO",
  "UPIRA",
  "UPIRI",
  "UPIRU",
  "UPITA",
  "UPITE",
  "UPIVA",
  "UPLIV",
  "UPLJA",
  "UPLJE",
  "UPLJI",
  "UPLJU",
  "UPNIC",
  "UPNIK",
  "UPOJE",
  "UPOMA",
  "UPORA",
  "UPORE",
  "UPORI",
  "UPORU",
  "UPOTA",
  "UPOTU",
  "UPOVE",
  "UPPAL",
  "UPRAL",
  "UPRAT",
  "UPRAU",
  "UPRAV",
  "UPREM",
  "UPREŠ",
  "UPRET",
  "UPRLA",
  "UPRLE",
  "UPRLI",
  "UPRLO",
  "UPRTA",
  "UPRTE",
  "UPRTI",
  "UPRTO",
  "UPSON",
  "UPTON",
  "URACH",
  "URADA",
  "URADE",
  "URADI",
  "URADU",
  "URALA",
  "URALU",
  "URAMA",
  "URAMI",
  "URANA",
  "URANC",
  "URANE",
  "URANI",
  "URANK",
  "URANU",
  "URARK",
  "URARN",
  "URATA",
  "URATE",
  "URATI",
  "URATU",
  "URBAN",
  "URBAR",
  "URBAS",
  "URBIČ",
  "URBIG",
  "URBIH",
  "URBIS",
  "URCAH",
  "URCAM",
  "URDIH",
  "URDJU",
  "URDLA",
  "URDLE",
  "URDLI",
  "URDLU",
  "URECI",
  "UREČE",
  "UREČI",
  "UREČT",
  "UREDB",
  "UREDI",
  "UREDU",
  "UREJA",
  "UREJE",
  "UREJI",
  "UREJO",
  "UREJU",
  "UREKA",
  "UREKE",
  "UREKI",
  "UREKU",
  "UREVC",
  "UREZA",
  "UREZE",
  "UREZI",
  "UREZU",
  "UREŽE",
  "UREŽI",
  "URFIH",
  "URFJA",
  "URFJU",
  "URFOM",
  "URFOV",
  "URGLA",
  "URGLE",
  "URGLI",
  "URGLU",
  "URHEM",
  "URHEV",
  "URHIH",
  "URHIN",
  "URHJA",
  "URHJU",
  "URHOM",
  "URHOV",
  "URIAH",
  "URIAS",
  "URIBE",
  "URICA",
  "URICE",
  "URICI",
  "URICO",
  "URIEL",
  "URIGA",
  "URIGE",
  "URIGI",
  "URIGU",
  "URIHA",
  "URIHE",
  "URIHI",
  "URIHU",
  "URIJA",
  "URIJE",
  "URIJI",
  "URIJO",
  "URIJU",
  "URILA",
  "URILE",
  "URILI",
  "URILO",
  "URIMA",
  "URIME",
  "URIMI",
  "URIMO",
  "URIMU",
  "URINA",
  "URINE",
  "URINI",
  "URINO",
  "URINU",
  "URISA",
  "URISE",
  "URISI",
  "URISK",
  "URISU",
  "URITA",
  "URITE",
  "URITI",
  "URIVA",
  "URIVE",
  "URIVI",
  "URJEN",
  "URLEB",
  "URLEP",
  "URMAN",
  "URMAS",
  "URMAŠ",
  "URMIL",
  "URNAH",
  "URNAM",
  "URNEM",
  "URNGA",
  "URNIH",
  "URNIK",
  "URNIM",
  "UROČI",
  "UROČL",
  "UROČU",
  "UROKA",
  "UROKE",
  "UROKI",
  "UROKU",
  "UROŠA",
  "UROŠE",
  "UROŠI",
  "UROŠU",
  "URSIH",
  "URSIL",
  "URSIN",
  "URSJA",
  "URSJU",
  "URSOM",
  "URSOV",
  "URSUL",
  "URSUS",
  "URSZR",
  "URŠAH",
  "URŠAM",
  "URŠAN",
  "URŠEJ",
  "URŠIČ",
  "URŠIK",
  "URŠIN",
  "URŠKA",
  "URŠKE",
  "URŠKI",
  "URŠKO",
  "URŠNA",
  "URŠUL",
  "URTIK",
  "URTON",
  "URUKA",
  "URUKU",
  "URZON",
  "URŽAH",
  "USADA",
  "USADE",
  "USADI",
  "USADU",
  "USAIN",
  "USAJA",
  "USAJI",
  "USAJU",
  "USAKA",
  "USAKE",
  "USAKI",
  "USAKO",
  "USCAL",
  "USCAN",
  "USCAT",
  "USCAU",
  "USCAV",
  "USCHI",
  "USCOM",
  "USEDA",
  "USEDE",
  "USEDI",
  "USEDO",
  "USEDU",
  "USEKA",
  "USEKE",
  "USEKI",
  "USEKU",
  "USEMI",
  "USEST",
  "USHER",
  "USIHA",
  "USIHI",
  "USIHU",
  "USIKA",
  "USIKE",
  "USIKI",
  "USIKU",
  "USIPA",
  "USIPI",
  "USIPU",
  "USIRI",
  "USIRU",
  "USKOK",
  "USLAN",
  "USLJU",
  "USLUG",
  "USMAN",
  "USNIK",
  "USNJA",
  "USNJE",
  "USNJU",
  "USODA",
  "USODE",
  "USODI",
  "USODO",
  "USODU",
  "USOMA",
  "USOTA",
  "USOTU",
  "USOVA",
  "USOVE",
  "USOVI",
  "USOVO",
  "USPEH",
  "USPEL",
  "USPEM",
  "USPEŠ",
  "USPET",
  "USPEU",
  "USRAL",
  "USRAN",
  "USRAT",
  "USRAU",
  "USRAV",
  "USSAR",
  "USTAH",
  "USTAL",
  "USTAM",
  "USTAR",
  "USTAŠ",
  "USTAT",
  "USTAU",
  "USTAV",
  "USTEC",
  "USTEČ",
  "USTEN",
  "USTEŽ",
  "USTIH",
  "USTIJ",
  "USTIL",
  "USTIM",
  "USTIŠ",
  "USTIT",
  "USTIU",
  "USTIV",
  "USTJA",
  "USTJE",
  "USTJI",
  "USTJU",
  "USTKA",
  "USTKI",
  "USTMI",
  "USTNA",
  "USTNE",
  "USTNI",
  "USTNO",
  "USTOM",
  "USTON",
  "USTOV",
  "USUJE",
  "USULA",
  "USULE",
  "USULI",
  "USULO",
  "USURI",
  "USUTA",
  "USUTE",
  "USUTI",
  "USUTO",
  "UŠABT",
  "UŠAJA",
  "UŠAJE",
  "UŠAJI",
  "UŠAJU",
  "UŠATA",
  "UŠATE",
  "UŠATI",
  "UŠATO",
  "UŠČAN",
  "UŠČIJ",
  "UŠČIM",
  "UŠČIP",
  "UŠČIŠ",
  "UŠEMA",
  "UŠESA",
  "UŠESC",
  "UŠESI",
  "UŠESU",
  "UŠICA",
  "UŠICE",
  "UŠICI",
  "UŠICO",
  "UŠIJE",
  "UŠIJO",
  "UŠILA",
  "UŠILE",
  "UŠILI",
  "UŠILO",
  "UŠIMI",
  "UŠITI",
  "UŠIVA",
  "UŠIVC",
  "UŠIVE",
  "UŠIVI",
  "UŠIVK",
  "UŠIVO",
  "UŠKAH",
  "UŠKAM",
  "UŠKIN",
  "UŠNIK",
  "UŠTAR",
  "UŠTEJ",
  "UŠTEL",
  "UŠTET",
  "UŠTEU",
  "UTAHA",
  "UTAHU",
  "UTAJA",
  "UTAJE",
  "UTAJI",
  "UTAJO",
  "UTAJU",
  "UTAMA",
  "UTAMI",
  "UTANA",
  "UTANE",
  "UTANI",
  "UTANK",
  "UTANO",
  "UTARE",
  "UTECI",
  "UTEČE",
  "UTEČI",
  "UTEČT",
  "UTEHA",
  "UTEHE",
  "UTEHI",
  "UTEHO",
  "UTEKA",
  "UTEKE",
  "UTEKI",
  "UTEKU",
  "UTEŠI",
  "UTEŠU",
  "UTEŽI",
  "UTEŽU",
  "UTHIH",
  "UTHJA",
  "UTHJU",
  "UTHOM",
  "UTHOV",
  "UTICA",
  "UTICE",
  "UTICI",
  "UTICO",
  "UTIKA",
  "UTIKU",
  "UTINA",
  "UTINE",
  "UTINI",
  "UTINO",
  "UTIRA",
  "UTIRI",
  "UTIRU",
  "UTIŠA",
  "UTIŠI",
  "UTIŠU",
  "UTKIN",
  "UTKJU",
  "UTOKA",
  "UTOKU",
  "UTOMA",
  "UTOME",
  "UTOMI",
  "UTOMO",
  "UTOMU",
  "UTONE",
  "UTONI",
  "UTONU",
  "UTOPA",
  "UTOPE",
  "UTOPI",
  "UTOPU",
  "UTORA",
  "UTORE",
  "UTORI",
  "UTORU",
  "UTOTA",
  "UTOTU",
  "UTOVA",
  "UTOVE",
  "UTOVI",
  "UTOVK",
  "UTOVO",
  "UTRAT",
  "UTRDB",
  "UTRDI",
  "UTRDU",
  "UTREM",
  "UTREŠ",
  "UTRET",
  "UTRGA",
  "UTRGE",
  "UTRGI",
  "UTRGU",
  "UTRIP",
  "UTRLA",
  "UTRLE",
  "UTRLI",
  "UTRLO",
  "UTRNE",
  "UTRNI",
  "UTRNU",
  "UTROŠ",
  "UTRPI",
  "UTRPU",
  "UTRTA",
  "UTRTE",
  "UTRTI",
  "UTRTO",
  "UTRŽE",
  "UTSCH",
  "UTTER",
  "UTTIH",
  "UTTJA",
  "UTTJU",
  "UTTOM",
  "UTTOV",
  "UTURI",
  "UTURU",
  "UTVAH",
  "UTVAM",
  "UTVAR",
  "UTVIN",
  "UTZON",
  "UVAJA",
  "UVAJI",
  "UVAJU",
  "UVALA",
  "UVALE",
  "UVALI",
  "UVALO",
  "UVARI",
  "UVARU",
  "UVAŽA",
  "UVAŽI",
  "UVAŽU",
  "UVEDB",
  "UVEDE",
  "UVEDI",
  "UVEDU",
  "UVELA",
  "UVELE",
  "UVELI",
  "UVELO",
  "UVENI",
  "UVENU",
  "UVERA",
  "UVERE",
  "UVERI",
  "UVERO",
  "UVERU",
  "UVEST",
  "UVEZE",
  "UVEZI",
  "UVEZU",
  "UVIDA",
  "UVIDE",
  "UVIDI",
  "UVIDU",
  "UVIHA",
  "UVIHI",
  "UVIHU",
  "UVIJE",
  "UVILA",
  "UVILE",
  "UVILI",
  "UVILO",
  "UVITA",
  "UVITE",
  "UVITI",
  "UVITO",
  "UVLEČ",
  "UVLEK",
  "UVODA",
  "UVODE",
  "UVODI",
  "UVODU",
  "UVOJA",
  "UVOJE",
  "UVOJI",
  "UVOJU",
  "UVOZA",
  "UVOZE",
  "UVOZI",
  "UVOZU",
  "UVRTA",
  "UVRTI",
  "UVRTU",
  "UVULA",
  "UVULE",
  "UVULI",
  "UVULO",
  "UZAME",
  "UZANA",
  "UZANC",
  "UZANE",
  "UZANI",
  "UZANU",
  "UZBEK",
  "UZDAH",
  "UZDAJ",
  "UZDAL",
  "UZDAM",
  "UZDAN",
  "UZDAŠ",
  "UZDAT",
  "UZDAU",
  "UZDAV",
  "UZELA",
  "UZELE",
  "UZELI",
  "UZELO",
  "UZEMI",
  "UZETA",
  "UZETE",
  "UZETI",
  "UZETO",
  "UZIEL",
  "UZIJA",
  "UZIJE",
  "UZIJI",
  "UZIJU",
  "UZIRA",
  "UZIRI",
  "UZIRU",
  "UZOTA",
  "UZOTU",
  "UZREM",
  "UZREŠ",
  "UZRET",
  "UZRLA",
  "UZRLE",
  "UZRLI",
  "UZRLO",
  "UZRTA",
  "UZRTE",
  "UZRTI",
  "UZRTO",
  "UZUNI",
  "UZUSA",
  "UZUSE",
  "UZUSI",
  "UZUSU",
  "UZZAN",
  "UŽALI",
  "UŽALU",
  "UŽARI",
  "UŽARU",
  "UŽEJA",
  "UŽEJI",
  "UŽEJU",
  "UŽENE",
  "UŽENI",
  "UŽGAL",
  "UŽGAT",
  "UŽGAU",
  "UŽGAV",
  "UŽGEM",
  "UŽGEŠ",
  "UŽICA",
  "UŽICE",
  "UŽICU",
  "UŽIJE",
  "UŽILA",
  "UŽILE",
  "UŽILI",
  "UŽILO",
  "UŽITA",
  "UŽITE",
  "UŽITI",
  "UŽITO",
  "UŽIVA",
  "UŽIVI",
  "UŽIVU",
  "UŽMAH",
  "UŽNIK",
  "UŽUGA",
  "UŽUGI",
  "UŽUGU",
  "VAALA",
  "VAALU",
  "VAART",
  "VABAH",
  "VABAM",
  "VABEČ",
  "VABEN",
  "VABIČ",
  "VABIL",
  "VABIM",
  "VABIŠ",
  "VABIT",
  "VABIU",
  "VABIV",
  "VABNA",
  "VABNE",
  "VABNI",
  "VABNO",
  "VACHS",
  "VACIK",
  "VACKA",
  "VACKE",
  "VACKI",
  "VACKU",
  "VACTH",
  "VAČAH",
  "VAČAM",
  "VAČAN",
  "VAČUN",
  "VADAS",
  "VADBA",
  "VADBE",
  "VADBI",
  "VADBO",
  "VADEČ",
  "VADER",
  "VADIČ",
  "VADIL",
  "VADIM",
  "VADIŠ",
  "VADIT",
  "VADIU",
  "VADIV",
  "VADON",
  "VADUZ",
  "VAEHI",
  "VAELA",
  "VAELE",
  "VAELI",
  "VAELU",
  "VAETH",
  "VAFAH",
  "VAFAM",
  "VAFEL",
  "VAFIH",
  "VAFJA",
  "VAFJU",
  "VAFLA",
  "VAFLE",
  "VAFLI",
  "VAFLU",
  "VAFOM",
  "VAFOV",
  "VAGAH",
  "VAGAJ",
  "VAGAL",
  "VAGAM",
  "VAGAN",
  "VAGAŠ",
  "VAGAT",
  "VAGAU",
  "VAGAV",
  "VAGGA",
  "VAGGE",
  "VAGGI",
  "VAGGU",
  "VAGIN",
  "VAGIT",
  "VAGON",
  "VAGUS",
  "VAHEN",
  "VAHID",
  "VAHIH",
  "VAHIT",
  "VAHJA",
  "VAHJU",
  "VAHLA",
  "VAHLE",
  "VAHLI",
  "VAHLU",
  "VAHNA",
  "VAHNE",
  "VAHNI",
  "VAHNU",
  "VAHOM",
  "VAHOV",
  "VAHTA",
  "VAHTE",
  "VAHTI",
  "VAHTO",
  "VAHTU",
  "VAIJO",
  "VAILA",
  "VAILE",
  "VAILI",
  "VAILL",
  "VAILU",
  "VAIME",
  "VAINA",
  "VAINE",
  "VAINI",
  "VAINO",
  "VAINU",
  "VAJAH",
  "VAJAM",
  "VAJDA",
  "VAJDE",
  "VAJDI",
  "VAJDL",
  "VAJDO",
  "VAJDU",
  "VAJEN",
  "VAJET",
  "VAJGL",
  "VAJIN",
  "VAJKA",
  "VAJKE",
  "VAJKI",
  "VAJKU",
  "VAJNA",
  "VAJNE",
  "VAJNI",
  "VAJNO",
  "VAJNU",
  "VAJSA",
  "VAJSE",
  "VAJSI",
  "VAJSU",
  "VAJTA",
  "VAJTE",
  "VAJTI",
  "VAJTU",
  "VAKAR",
  "VAKKA",
  "VAKKO",
  "VAKKU",
  "VAKOČ",
  "VAKUL",
  "VAKUM",
  "VALAH",
  "VALAM",
  "VALAS",
  "VALAT",
  "VALCA",
  "VALCE",
  "VALCI",
  "VALCL",
  "VALCU",
  "VALDA",
  "VALDE",
  "VALDI",
  "VALDO",
  "VALEA",
  "VALEC",
  "VALEČ",
  "VALEK",
  "VALEM",
  "VALEN",
  "VALEO",
  "VALER",
  "VALET",
  "VALEU",
  "VALEV",
  "VALIČ",
  "VALIH",
  "VALIL",
  "VALIM",
  "VALIN",
  "VALIŠ",
  "VALIT",
  "VALIU",
  "VALIV",
  "VALJA",
  "VALJE",
  "VALJI",
  "VALJU",
  "VALKA",
  "VALKE",
  "VALKI",
  "VALKO",
  "VALKU",
  "VALLA",
  "VALLE",
  "VALLI",
  "VALLO",
  "VALLS",
  "VALLU",
  "VALNA",
  "VALNE",
  "VALNI",
  "VALNO",
  "VALOM",
  "VALON",
  "VALOV",
  "VALTA",
  "VALTE",
  "VALTI",
  "VALTL",
  "VALTO",
  "VALTU",
  "VALUJ",
  "VALUK",
  "VALUT",
  "VAMPA",
  "VAMPE",
  "VAMPI",
  "VAMPL",
  "VAMPU",
  "VANAT",
  "VANCA",
  "VANCE",
  "VANČA",
  "VANČE",
  "VANČI",
  "VANČO",
  "VANČU",
  "VANDA",
  "VANDE",
  "VANDI",
  "VANDO",
  "VANDU",
  "VANEK",
  "VANES",
  "VANEŽ",
  "VANGA",
  "VANGE",
  "VANGI",
  "VANGO",
  "VANGU",
  "VANIA",
  "VANIČ",
  "VANID",
  "VANIE",
  "VANIH",
  "VANII",
  "VANIN",
  "VANIO",
  "VANIŠ",
  "VANIU",
  "VANJI",
  "VANKE",
  "VANNA",
  "VANNE",
  "VANNI",
  "VANNO",
  "VANOM",
  "VANOV",
  "VANZI",
  "VARAH",
  "VARAJ",
  "VARAL",
  "VARAM",
  "VARAN",
  "VARAŠ",
  "VARAT",
  "VARAU",
  "VARAV",
  "VARDA",
  "VARDE",
  "VARDI",
  "VARDO",
  "VAREČ",
  "VAREJ",
  "VAREK",
  "VAREL",
  "VAREM",
  "VAREN",
  "VAREV",
  "VARFI",
  "VARGA",
  "VARGE",
  "VARGI",
  "VARGO",
  "VARGU",
  "VARHA",
  "VARHU",
  "VARIA",
  "VARIC",
  "VARIE",
  "VARIH",
  "VARII",
  "VARIJ",
  "VARIL",
  "VARIM",
  "VARIN",
  "VARIO",
  "VARIS",
  "VARIŠ",
  "VARIT",
  "VARIU",
  "VARIV",
  "VARJA",
  "VARJE",
  "VARJI",
  "VARJO",
  "VARJU",
  "VARKA",
  "VARKE",
  "VARKI",
  "VARKO",
  "VARKU",
  "VARLA",
  "VARLE",
  "VARLI",
  "VARLU",
  "VARNA",
  "VARNE",
  "VARNI",
  "VARNO",
  "VAROA",
  "VAROE",
  "VAROG",
  "VAROI",
  "VAROJ",
  "VAROM",
  "VARON",
  "VAROO",
  "VAROŠ",
  "VAROV",
  "VAROZ",
  "VARPA",
  "VARPE",
  "VARPI",
  "VARPO",
  "VARSI",
  "VARTA",
  "VARTE",
  "VARTI",
  "VARTO",
  "VARUH",
  "VARUJ",
  "VARUN",
  "VARZI",
  "VASAH",
  "VASAK",
  "VASAM",
  "VASAN",
  "VASCA",
  "VASCE",
  "VASCI",
  "VASCO",
  "VASCU",
  "VASEH",
  "VASEM",
  "VASEV",
  "VASFI",
  "VASIC",
  "VASIČ",
  "VASIH",
  "VASIJ",
  "VASIK",
  "VASIL",
  "VASIN",
  "VASJA",
  "VASJE",
  "VASJI",
  "VASJO",
  "VASJU",
  "VASKA",
  "VASKE",
  "VASKI",
  "VASKO",
  "VASKU",
  "VASLE",
  "VASMI",
  "VASOM",
  "VASOV",
  "VASSA",
  "VASSE",
  "VASSI",
  "VASSU",
  "VASTL",
  "VASUJ",
  "VAŠAH",
  "VAŠAM",
  "VAŠAN",
  "VAŠCA",
  "VAŠCE",
  "VAŠCI",
  "VAŠCO",
  "VAŠEM",
  "VAŠEV",
  "VAŠIH",
  "VAŠIM",
  "VAŠJA",
  "VAŠJU",
  "VAŠKA",
  "VAŠKE",
  "VAŠKI",
  "VAŠKO",
  "VAŠOM",
  "VAŠOV",
  "VAŠTE",
  "VATAH",
  "VATAJ",
  "VATAM",
  "VATAN",
  "VATEL",
  "VATEM",
  "VATEN",
  "VATES",
  "VATEV",
  "VATHA",
  "VATHE",
  "VATHI",
  "VATHU",
  "VATIH",
  "VATIN",
  "VATJA",
  "VATJU",
  "VATKA",
  "VATKE",
  "VATKI",
  "VATKO",
  "VATLA",
  "VATLE",
  "VATLI",
  "VATLU",
  "VATNA",
  "VATNE",
  "VATNI",
  "VATNO",
  "VATOM",
  "VATOV",
  "VAUCK",
  "VAUČE",
  "VAUDA",
  "VAUDE",
  "VAUDI",
  "VAUDK",
  "VAUDO",
  "VAUDU",
  "VAUHA",
  "VAUHE",
  "VAUHI",
  "VAUHU",
  "VAULL",
  "VAURA",
  "VAURE",
  "VAURI",
  "VAURO",
  "VAURU",
  "VAUSA",
  "VAUSE",
  "VAUSI",
  "VAUSU",
  "VAUTH",
  "VAVDI",
  "VAVHA",
  "VAVHE",
  "VAVHI",
  "VAVHU",
  "VAVRA",
  "VAVRE",
  "VAVRI",
  "VAVRO",
  "VAVRU",
  "VAVTA",
  "VAVTE",
  "VAVTI",
  "VAVTO",
  "VAZAH",
  "VAZAL",
  "VAZAM",
  "VAZEN",
  "VAZIA",
  "VAZIC",
  "VAZIE",
  "VAZIH",
  "VAZII",
  "VAZIO",
  "VAZIU",
  "VAZJA",
  "VAZJU",
  "VAZNA",
  "VAZNE",
  "VAZNI",
  "VAZNO",
  "VAZOM",
  "VAZOV",
  "VAŽEČ",
  "VAŽEN",
  "VAŽIČ",
  "VAŽIL",
  "VAŽIM",
  "VAŽIŠ",
  "VAŽIT",
  "VAŽIU",
  "VAŽIV",
  "VAŽNA",
  "VAŽNE",
  "VAŽNI",
  "VAŽNO",
  "VBADA",
  "VBADI",
  "VBADO",
  "VBADU",
  "VBIJA",
  "VBIJE",
  "VBIJI",
  "VBIJU",
  "VBILA",
  "VBILE",
  "VBILI",
  "VBILO",
  "VBITA",
  "VBITE",
  "VBITI",
  "VBITO",
  "VBOČI",
  "VBOČL",
  "VBOČU",
  "VBODA",
  "VBODE",
  "VBODI",
  "VBODU",
  "VBOST",
  "VCEPI",
  "VCEPU",
  "VČASI",
  "VČERI",
  "VČIRI",
  "VČRTA",
  "VČRTI",
  "VČRTU",
  "VDABA",
  "VDABE",
  "VDABI",
  "VDABU",
  "VDAJA",
  "VDAJE",
  "VDAJI",
  "VDAJO",
  "VDAJU",
  "VDALA",
  "VDALE",
  "VDALI",
  "VDALO",
  "VDAMA",
  "VDAME",
  "VDAMO",
  "VDANA",
  "VDANE",
  "VDANI",
  "VDANO",
  "VDATA",
  "VDATE",
  "VDATI",
  "VDAVA",
  "VDAVE",
  "VDAVI",
  "VDELA",
  "VDELE",
  "VDELI",
  "VDELO",
  "VDELU",
  "VDENE",
  "VDENI",
  "VDERE",
  "VDETA",
  "VDETE",
  "VDETI",
  "VDETO",
  "VDEVA",
  "VDEVI",
  "VDEVU",
  "VDIHA",
  "VDIHE",
  "VDIHI",
  "VDIHU",
  "VDIMO",
  "VDIRA",
  "VDIRI",
  "VDIRU",
  "VDITA",
  "VDITE",
  "VDIVA",
  "VDOLŽ",
  "VDORA",
  "VDORE",
  "VDORI",
  "VDORU",
  "VDOVA",
  "VDOVC",
  "VDOVE",
  "VDOVI",
  "VDOVO",
  "VDREM",
  "VDREŠ",
  "VDRET",
  "VDRLA",
  "VDRLE",
  "VDRLI",
  "VDRLO",
  "VDRTA",
  "VDRTE",
  "VDRTI",
  "VDRTO",
  "VEALA",
  "VEALE",
  "VEALI",
  "VEALU",
  "VEATS",
  "VEBER",
  "VEBIU",
  "VEBLE",
  "VEBRA",
  "VEBRE",
  "VEBRI",
  "VEBRU",
  "VECKA",
  "VECKE",
  "VECKI",
  "VECKO",
  "VECKU",
  "VEČAJ",
  "VEČAL",
  "VEČAM",
  "VEČAN",
  "VEČAŠ",
  "VEČAT",
  "VEČAU",
  "VEČAV",
  "VEČEN",
  "VEČER",
  "VEČIH",
  "VEČIM",
  "VEČIN",
  "VEČIŠ",
  "VEČJA",
  "VEČJE",
  "VEČJI",
  "VEČJO",
  "VEČKA",
  "VEČKE",
  "VEČKI",
  "VEČKO",
  "VEČKU",
  "VEČMI",
  "VEČNA",
  "VEČNE",
  "VEČNI",
  "VEČNO",
  "VEDAH",
  "VEDAM",
  "VEDAT",
  "VEDEL",
  "VEDEM",
  "VEDER",
  "VEDEŠ",
  "VEDET",
  "VEDEU",
  "VEDEŽ",
  "VEDIS",
  "VEDLA",
  "VEDLE",
  "VEDLI",
  "VEDLO",
  "VEDNO",
  "VEDOČ",
  "VEDOV",
  "VEDRA",
  "VEDRC",
  "VEDRE",
  "VEDRI",
  "VEDRN",
  "VEDRO",
  "VEDRU",
  "VEDUT",
  "VEDVE",
  "VEEJA",
  "VEEJE",
  "VEEJI",
  "VEEJU",
  "VEENA",
  "VEENE",
  "VEENI",
  "VEENO",
  "VEENU",
  "VEFIK",
  "VEGAH",
  "VEGAM",
  "VEGAN",
  "VEGAR",
  "VEGAS",
  "VEGAV",
  "VEGEL",
  "VEGER",
  "VEGIH",
  "VEGJA",
  "VEGJU",
  "VEGLA",
  "VEGLE",
  "VEGLI",
  "VEGLU",
  "VEGNI",
  "VEGOM",
  "VEGOV",
  "VEGRI",
  "VEHAB",
  "VEHAH",
  "VEHAM",
  "VEHAR",
  "VEHBI",
  "VEHID",
  "VEIDT",
  "VEIEL",
  "VEIJO",
  "VEILA",
  "VEILE",
  "VEILI",
  "VEILU",
  "VEINA",
  "VEINE",
  "VEINI",
  "VEINU",
  "VEIRS",
  "VEITA",
  "VEITE",
  "VEITH",
  "VEITI",
  "VEITL",
  "VEITU",
  "VEIZI",
  "VEJAH",
  "VEJAJ",
  "VEJAL",
  "VEJAM",
  "VEJAR",
  "VEJAŠ",
  "VEJAT",
  "VEJAU",
  "VEJAV",
  "VEJEČ",
  "VEJEM",
  "VEJEN",
  "VEJEŠ",
  "VEJIC",
  "VEJIL",
  "VEJIM",
  "VEJIŠ",
  "VEJIT",
  "VEJIU",
  "VEJIV",
  "VEJMO",
  "VEJNA",
  "VEJNE",
  "VEJNI",
  "VEJNO",
  "VEJOČ",
  "VEJPA",
  "VEJPE",
  "VEJPI",
  "VEJPU",
  "VEJTA",
  "VEJTE",
  "VEJVA",
  "VEKAH",
  "VEKAJ",
  "VEKAL",
  "VEKAM",
  "VEKAR",
  "VEKAŠ",
  "VEKAT",
  "VEKAU",
  "VEKAV",
  "VEKEM",
  "VEKEV",
  "VEKIH",
  "VEKJA",
  "VEKJU",
  "VEKOM",
  "VEKOV",
  "VEKŠA",
  "VEKŠU",
  "VELAH",
  "VELAJ",
  "VELAM",
  "VELAR",
  "VELBA",
  "VELBE",
  "VELBI",
  "VELBU",
  "VELCL",
  "VELDA",
  "VELDE",
  "VELDI",
  "VELDO",
  "VELDT",
  "VELDU",
  "VELEČ",
  "VELEJ",
  "VELEL",
  "VELEN",
  "VELER",
  "VELES",
  "VELET",
  "VELEU",
  "VELEZ",
  "VELIČ",
  "VELIH",
  "VELIJ",
  "VELIK",
  "VELIM",
  "VELIS",
  "VELIŠ",
  "VELIU",
  "VELJA",
  "VELJI",
  "VELJU",
  "VELKA",
  "VELKE",
  "VELKI",
  "VELKO",
  "VELKU",
  "VELLA",
  "VELLE",
  "VELLI",
  "VELLO",
  "VELLU",
  "VELMA",
  "VELME",
  "VELMI",
  "VELMO",
  "VELOM",
  "VELON",
  "VELOV",
  "VELSI",
  "VELŠE",
  "VELTE",
  "VELUD",
  "VELUR",
  "VELVA",
  "VELVE",
  "VELVI",
  "VELVO",
  "VENAH",
  "VENAM",
  "VENCA",
  "VENCE",
  "VENCI",
  "VENCL",
  "VENCU",
  "VENDE",
  "VENDL",
  "VENEC",
  "VENEČ",
  "VENEL",
  "VENEM",
  "VENER",
  "VENEŠ",
  "VENET",
  "VENEU",
  "VENGE",
  "VENIH",
  "VENIL",
  "VENIM",
  "VENIN",
  "VENIŠ",
  "VENIT",
  "VENIU",
  "VENIV",
  "VENJA",
  "VENJU",
  "VENKA",
  "VENKE",
  "VENKI",
  "VENKO",
  "VENKU",
  "VENNA",
  "VENNE",
  "VENNI",
  "VENNU",
  "VENOM",
  "VENOV",
  "VENTA",
  "VENTE",
  "VENTI",
  "VENTO",
  "VENTU",
  "VENUL",
  "VENUS",
  "VEPER",
  "VEPRA",
  "VEPRE",
  "VEPRI",
  "VEPRU",
  "VERAČ",
  "VERAH",
  "VERAM",
  "VERAN",
  "VERAS",
  "VERBA",
  "VERBI",
  "VERBU",
  "VERCE",
  "VERČA",
  "VERČE",
  "VERČI",
  "VERČU",
  "VERDA",
  "VERDI",
  "VERDU",
  "VERED",
  "VEREN",
  "VERES",
  "VEREŠ",
  "VERGA",
  "VERGE",
  "VERGI",
  "VERGO",
  "VERGU",
  "VERIC",
  "VERIG",
  "VERIH",
  "VERIN",
  "VERIS",
  "VERJA",
  "VERJE",
  "VERJU",
  "VERKA",
  "VERKE",
  "VERKI",
  "VERKO",
  "VERKU",
  "VERLI",
  "VERMA",
  "VERME",
  "VERMI",
  "VERMO",
  "VERMU",
  "VERNA",
  "VERNE",
  "VERNI",
  "VERNO",
  "VERNU",
  "VEROM",
  "VERON",
  "VEROV",
  "VERRI",
  "VERŠA",
  "VERŠE",
  "VERŠI",
  "VERŠO",
  "VERŠU",
  "VERTA",
  "VERTE",
  "VERTI",
  "VERTU",
  "VERUJ",
  "VERUS",
  "VERZA",
  "VERZE",
  "VERZI",
  "VERZU",
  "VESAH",
  "VESAM",
  "VESCA",
  "VESCE",
  "VESCI",
  "VESCO",
  "VESEL",
  "VESEN",
  "VESHI",
  "VESIH",
  "VESIK",
  "VESIN",
  "VESJA",
  "VESJO",
  "VESJU",
  "VESKA",
  "VESKE",
  "VESKI",
  "VESKO",
  "VESKU",
  "VESLA",
  "VESLI",
  "VESLO",
  "VESLU",
  "VESNA",
  "VESNE",
  "VESNI",
  "VESNO",
  "VESOM",
  "VESOV",
  "VESPA",
  "VESPE",
  "VESPI",
  "VESPO",
  "VESTA",
  "VESTE",
  "VESTH",
  "VESTI",
  "VESTO",
  "VESTU",
  "VEŠAL",
  "VEŠCA",
  "VEŠCE",
  "VEŠCI",
  "VEŠCU",
  "VEŠČA",
  "VEŠČC",
  "VEŠČE",
  "VEŠČI",
  "VEŠČO",
  "VEŠČU",
  "VEŠEC",
  "VEŠKA",
  "VEŠKE",
  "VEŠKI",
  "VEŠKO",
  "VETEM",
  "VETER",
  "VETEV",
  "VETIH",
  "VETIJ",
  "VETIM",
  "VETJA",
  "VETJE",
  "VETJI",
  "VETJU",
  "VETLE",
  "VETOM",
  "VETON",
  "VETOV",
  "VETRA",
  "VETRC",
  "VETRE",
  "VETRI",
  "VETRN",
  "VETRU",
  "VEUVA",
  "VEUVE",
  "VEUVI",
  "VEUVU",
  "VEVAR",
  "VEVČE",
  "VEZAČ",
  "VEZAH",
  "VEZAJ",
  "VEZAL",
  "VEZAM",
  "VEZAN",
  "VEZAT",
  "VEZAU",
  "VEZAV",
  "VEZEH",
  "VEZEL",
  "VEZEM",
  "VEZEN",
  "VEZEŠ",
  "VEZEU",
  "VEZEV",
  "VEZIC",
  "VEZIH",
  "VEZIJ",
  "VEZIN",
  "VEZIR",
  "VEZIV",
  "VEZJA",
  "VEZJE",
  "VEZJI",
  "VEZJO",
  "VEZJU",
  "VEZLA",
  "VEZLE",
  "VEZLI",
  "VEZLO",
  "VEZMI",
  "VEZNA",
  "VEZNE",
  "VEZNI",
  "VEZNO",
  "VEZOČ",
  "VEZOM",
  "VEZOV",
  "VEZUV",
  "VEŽAH",
  "VEŽAM",
  "VEŽBA",
  "VEŽBI",
  "VEŽBU",
  "VEŽEM",
  "VEŽEN",
  "VEŽEŠ",
  "VEŽIC",
  "VEŽNA",
  "VEŽNE",
  "VEŽNI",
  "VEŽNO",
  "VEŽOČ",
  "VGLED",
  "VGLET",
  "VGREZ",
  "VGRIZ",
  "VHODA",
  "VHODE",
  "VHODI",
  "VHODU",
  "VIALA",
  "VIALE",
  "VIALI",
  "VIALL",
  "VIALU",
  "VIANA",
  "VIANE",
  "VIANI",
  "VIANU",
  "VIARD",
  "VIARS",
  "VIBAH",
  "VIBAM",
  "VIBER",
  "VIBOR",
  "VICAH",
  "VICAM",
  "VICCI",
  "VICEM",
  "VICEV",
  "VICIH",
  "VICJA",
  "VICJU",
  "VICKA",
  "VICKE",
  "VICKI",
  "VICKU",
  "VICOM",
  "VICOV",
  "VIČAN",
  "VIČAR",
  "VIČEM",
  "VIČIČ",
  "VIČJA",
  "VIČJU",
  "VIČOM",
  "VIDAH",
  "VIDAJ",
  "VIDAL",
  "VIDAM",
  "VIDAN",
  "VIDAS",
  "VIDAU",
  "VIDAV",
  "VIDCA",
  "VIDCE",
  "VIDCI",
  "VIDCU",
  "VIDEA",
  "VIDEC",
  "VIDEČ",
  "VIDEE",
  "VIDEI",
  "VIDEK",
  "VIDEL",
  "VIDEM",
  "VIDEN",
  "VIDEO",
  "VIDER",
  "VIDET",
  "VIDEU",
  "VIDEZ",
  "VIDEŽ",
  "VIDIC",
  "VIDIČ",
  "VIDIH",
  "VIDIK",
  "VIDIM",
  "VIDIN",
  "VIDIŠ",
  "VIDJA",
  "VIDJU",
  "VIDKA",
  "VIDKE",
  "VIDKI",
  "VIDKO",
  "VIDKU",
  "VIDMA",
  "VIDMU",
  "VIDNA",
  "VIDNE",
  "VIDNI",
  "VIDNO",
  "VIDOM",
  "VIDON",
  "VIDOR",
  "VIDOŠ",
  "VIDOV",
  "VIDRA",
  "VIDRE",
  "VIDRI",
  "VIDRO",
  "VIDUK",
  "VIDVA",
  "VIDVE",
  "VIDVI",
  "VIEAU",
  "VIEIR",
  "VIELA",
  "VIELE",
  "VIELI",
  "VIELU",
  "VIENA",
  "VIENE",
  "VIENI",
  "VIENS",
  "VIENU",
  "VIERA",
  "VIERE",
  "VIERI",
  "VIERO",
  "VIERS",
  "VIERU",
  "VIETH",
  "VIGAS",
  "VIGEN",
  "VIGER",
  "VIGGA",
  "VIGGE",
  "VIGGI",
  "VIGGO",
  "VIGGU",
  "VIGHI",
  "VIGIL",
  "VIGJA",
  "VIGJU",
  "VIGLA",
  "VIGLE",
  "VIGLI",
  "VIGLU",
  "VIGNA",
  "VIGNE",
  "VIGNI",
  "VIGNO",
  "VIGOD",
  "VIGOM",
  "VIGOR",
  "VIHAJ",
  "VIHAL",
  "VIHAM",
  "VIHAR",
  "VIHAŠ",
  "VIHAT",
  "VIHAU",
  "VIHAV",
  "VIHER",
  "VIHRA",
  "VIHRE",
  "VIHRI",
  "VIHRO",
  "VIHRU",
  "VIHTI",
  "VIHTU",
  "VIIAL",
  "VIIKA",
  "VIIKE",
  "VIIKI",
  "VIIKU",
  "VIIRI",
  "VIJAH",
  "VIJAK",
  "VIJAM",
  "VIJAN",
  "VIJEM",
  "VIJEŠ",
  "VIJIN",
  "VIJMO",
  "VIJOČ",
  "VIJOL",
  "VIJTA",
  "VIJTE",
  "VIJUG",
  "VIJVA",
  "VIKAH",
  "VIKAJ",
  "VIKAL",
  "VIKAM",
  "VIKAR",
  "VIKAŠ",
  "VIKAT",
  "VIKAU",
  "VIKAV",
  "VIKEM",
  "VIKIC",
  "VIKIČ",
  "VIKIH",
  "VIKIN",
  "VIKJA",
  "VIKJU",
  "VIKKI",
  "VIKOM",
  "VIKOV",
  "VIKRČ",
  "VILAH",
  "VILAM",
  "VILAR",
  "VILAS",
  "VILČA",
  "VILČE",
  "VILČI",
  "VILČU",
  "VILDA",
  "VILDE",
  "VILDI",
  "VILDO",
  "VILDU",
  "VILEL",
  "VILEM",
  "VILER",
  "VILEŠ",
  "VILIC",
  "VILIČ",
  "VILIH",
  "VILIJ",
  "VILIM",
  "VILIN",
  "VILIS",
  "VILJA",
  "VILJU",
  "VILKA",
  "VILKE",
  "VILKI",
  "VILKO",
  "VILKS",
  "VILKU",
  "VILLA",
  "VILLE",
  "VILLI",
  "VILLO",
  "VILLU",
  "VILMA",
  "VILME",
  "VILMI",
  "VILMO",
  "VILNA",
  "VILNE",
  "VILNI",
  "VILNO",
  "VILOM",
  "VILOV",
  "VIMEN",
  "VIMOS",
  "VINAG",
  "VINAH",
  "VINAM",
  "VINAR",
  "VINCA",
  "VINCE",
  "VINCI",
  "VINCK",
  "VINCO",
  "VINCU",
  "VINČA",
  "VINČE",
  "VINČI",
  "VINČO",
  "VINEC",
  "VINEL",
  "VINER",
  "VINET",
  "VINGA",
  "VINGE",
  "VINGI",
  "VINGU",
  "VINIC",
  "VINIH",
  "VINIK",
  "VINIL",
  "VINIS",
  "VINJA",
  "VINJE",
  "VINJI",
  "VINJO",
  "VINJU",
  "VINKA",
  "VINKE",
  "VINKI",
  "VINKO",
  "VINKU",
  "VINOD",
  "VINOM",
  "VINOV",
  "VINTA",
  "VINTE",
  "VINTI",
  "VINTO",
  "VINTU",
  "VIOFF",
  "VIOLA",
  "VIOLE",
  "VIOLI",
  "VIOLO",
  "VIOLU",
  "VIONA",
  "VIONE",
  "VIONI",
  "VIONU",
  "VIPAP",
  "VIPIN",
  "VIPUL",
  "VIRAG",
  "VIRAT",
  "VIRCA",
  "VIRCE",
  "VIRCI",
  "VIRCU",
  "VIRDI",
  "VIREC",
  "VIREM",
  "VIREN",
  "VIREV",
  "VIRID",
  "VIRIH",
  "VIRIS",
  "VIRJA",
  "VIRJU",
  "VIRKA",
  "VIRKE",
  "VIRKI",
  "VIRKO",
  "VIRKU",
  "VIROM",
  "VIROV",
  "VIROZ",
  "VIRPI",
  "VIRTS",
  "VIRUS",
  "VIRZI",
  "VISAR",
  "VISCA",
  "VISCE",
  "VISCI",
  "VISCO",
  "VISCU",
  "VISEČ",
  "VISEL",
  "VISET",
  "VISEU",
  "VISHI",
  "VISHK",
  "VISIM",
  "VISIŠ",
  "VISJA",
  "VISJU",
  "VISKI",
  "VISLA",
  "VISLE",
  "VISLI",
  "VISLO",
  "VISMA",
  "VISME",
  "VISMI",
  "VISMO",
  "VISOČ",
  "VISOK",
  "VISOL",
  "VISOM",
  "VISSA",
  "VISSE",
  "VISSI",
  "VISSU",
  "VISTA",
  "VISTE",
  "VISTI",
  "VISTO",
  "VISTU",
  "VIŠAJ",
  "VIŠAL",
  "VIŠAM",
  "VIŠAN",
  "VIŠAŠ",
  "VIŠAT",
  "VIŠAU",
  "VIŠAV",
  "VIŠEK",
  "VIŠEM",
  "VIŠEŠ",
  "VIŠIČ",
  "VIŠIN",
  "VIŠJA",
  "VIŠJE",
  "VIŠJI",
  "VIŠJO",
  "VIŠJU",
  "VIŠKA",
  "VIŠKE",
  "VIŠKI",
  "VIŠKO",
  "VIŠKU",
  "VIŠNJ",
  "VIŠNU",
  "VIŠOČ",
  "VIŠOM",
  "VITAH",
  "VITAL",
  "VITAM",
  "VITAN",
  "VITAS",
  "VITEK",
  "VITEL",
  "VITEM",
  "VITER",
  "VITEZ",
  "VITGA",
  "VITIC",
  "VITIH",
  "VITIJ",
  "VITIM",
  "VITIN",
  "VITJA",
  "VITJE",
  "VITJI",
  "VITJO",
  "VITJU",
  "VITKA",
  "VITKE",
  "VITKI",
  "VITKO",
  "VITKU",
  "VITLA",
  "VITLE",
  "VITLI",
  "VITLO",
  "VITLU",
  "VITNA",
  "VITNE",
  "VITNI",
  "VITNO",
  "VITOL",
  "VITOM",
  "VITOR",
  "VITOV",
  "VITRA",
  "VITRE",
  "VITRI",
  "VITRO",
  "VITTA",
  "VITTE",
  "VITTI",
  "VITTU",
  "VIVAR",
  "VIVAS",
  "VIVEK",
  "VIVES",
  "VIVIA",
  "VIVIE",
  "VIVII",
  "VIVIN",
  "VIVIO",
  "VIVIU",
  "VIVOD",
  "VIVOT",
  "VIZAH",
  "VIZAM",
  "VIZIJ",
  "VIZIR",
  "VIZIT",
  "VIZOR",
  "VIZUM",
  "VIZUR",
  "VIŽAH",
  "VIŽAM",
  "VIŽIN",
  "VJEDO",
  "VJEDU",
  "VJEMA",
  "VJEME",
  "VJEMO",
  "VJERA",
  "VJERE",
  "VJERI",
  "VJERO",
  "VJEST",
  "VJEVA",
  "VJEVE",
  "VJEVI",
  "VJOSA",
  "VJOSE",
  "VJOSI",
  "VJOSO",
  "VKLOP",
  "VKOPA",
  "VKOPE",
  "VKOPI",
  "VKOPU",
  "VKOVU",
  "VKRAJ",
  "VKRCA",
  "VKRCI",
  "VKRCU",
  "VKUHA",
  "VKUHI",
  "VKUHU",
  "VKUJE",
  "VLAČA",
  "VLAČE",
  "VLAČI",
  "VLAČL",
  "VLAČO",
  "VLAČU",
  "VLADA",
  "VLADE",
  "VLADI",
  "VLADK",
  "VLADO",
  "VLADU",
  "VLAGA",
  "VLAGE",
  "VLAGI",
  "VLAGO",
  "VLAGU",
  "VLAHA",
  "VLAHE",
  "VLAHI",
  "VLAHO",
  "VLAHU",
  "VLAJA",
  "VLAJE",
  "VLAJI",
  "VLAJU",
  "VLAKA",
  "VLAKC",
  "VLAKE",
  "VLAKI",
  "VLAKO",
  "VLAKU",
  "VLASH",
  "VLAST",
  "VLATK",
  "VLAŽI",
  "VLAŽU",
  "VLČEK",
  "VLČKA",
  "VLČKE",
  "VLČKI",
  "VLČKU",
  "VLECI",
  "VLECK",
  "VLEČE",
  "VLEČI",
  "VLEČK",
  "VLEČT",
  "VLEET",
  "VLEKA",
  "VLEKE",
  "VLEKI",
  "VLEKO",
  "VLEKU",
  "VLEPI",
  "VLEPU",
  "VLIET",
  "VLIJE",
  "VLIKA",
  "VLIKI",
  "VLIKU",
  "VLILA",
  "VLILE",
  "VLILI",
  "VLILO",
  "VLISA",
  "VLISE",
  "VLISI",
  "VLISU",
  "VLITA",
  "VLITE",
  "VLITI",
  "VLITO",
  "VLIVA",
  "VLIVE",
  "VLIVI",
  "VLIVU",
  "VLKIH",
  "VLKJA",
  "VLKJU",
  "VLKOM",
  "VLKOV",
  "VLOGA",
  "VLOGE",
  "VLOGI",
  "VLOGO",
  "VLOGU",
  "VLOKA",
  "VLOKE",
  "VLOKI",
  "VLOKU",
  "VLOMA",
  "VLOME",
  "VLOMI",
  "VLOMU",
  "VLORA",
  "VLORE",
  "VLORI",
  "VLORO",
  "VLOŽI",
  "VLOŽU",
  "VLUGT",
  "VMESI",
  "VMESU",
  "VMEŠA",
  "VMEŠI",
  "VMEŠU",
  "VNAME",
  "VNAŠA",
  "VNAŠI",
  "VNAŠU",
  "VNELA",
  "VNELE",
  "VNELI",
  "VNELO",
  "VNEMA",
  "VNEME",
  "VNEMI",
  "VNEMO",
  "VNEMU",
  "VNESE",
  "VNESI",
  "VNEST",
  "VNESU",
  "VNETA",
  "VNETE",
  "VNETI",
  "VNETO",
  "VNOSA",
  "VNOSE",
  "VNOSI",
  "VNOSU",
  "VNUČK",
  "VNUKA",
  "VNUKE",
  "VNUKI",
  "VNUKU",
  "VOBČE",
  "VOBIČ",
  "VOCAH",
  "VOCAJ",
  "VOCAM",
  "VOCEM",
  "VOCEV",
  "VOCHL",
  "VOCIH",
  "VOCJA",
  "VOCJU",
  "VOCKA",
  "VOCKE",
  "VOCKI",
  "VOCKU",
  "VOCOM",
  "VOCOV",
  "VOČEV",
  "VOČKA",
  "VOČKE",
  "VOČKI",
  "VOČKO",
  "VOČKU",
  "VOČOV",
  "VODAH",
  "VODAL",
  "VODAM",
  "VODAN",
  "VODAR",
  "VODEB",
  "VODEČ",
  "VODEM",
  "VODEN",
  "VODEV",
  "VODIC",
  "VODIČ",
  "VODIH",
  "VODIJ",
  "VODIK",
  "VODIL",
  "VODIM",
  "VODIŠ",
  "VODIT",
  "VODIU",
  "VODIV",
  "VODJA",
  "VODJE",
  "VODJI",
  "VODJO",
  "VODJU",
  "VODKA",
  "VODKE",
  "VODKI",
  "VODKO",
  "VODNA",
  "VODNE",
  "VODNI",
  "VODNO",
  "VODOL",
  "VODOM",
  "VODOV",
  "VODUC",
  "VODUL",
  "VOGAH",
  "VOGAL",
  "VOGAM",
  "VOGDT",
  "VOGEL",
  "VOGIH",
  "VOGJA",
  "VOGJU",
  "VOGLA",
  "VOGLE",
  "VOGLI",
  "VOGLU",
  "VOGOM",
  "VOGOV",
  "VOGTA",
  "VOGTE",
  "VOGTI",
  "VOGTS",
  "VOGTU",
  "VOGUA",
  "VOGUE",
  "VOGUU",
  "VOHAJ",
  "VOHAL",
  "VOHAM",
  "VOHAR",
  "VOHAŠ",
  "VOHAT",
  "VOHAU",
  "VOHAV",
  "VOHEM",
  "VOHIH",
  "VOHJA",
  "VOHJU",
  "VOHKA",
  "VOHKI",
  "VOHKU",
  "VOHLA",
  "VOHLE",
  "VOHLI",
  "VOHLU",
  "VOHNA",
  "VOHNE",
  "VOHNI",
  "VOHNU",
  "VOHOM",
  "VOHOV",
  "VOHSA",
  "VOHSE",
  "VOHSI",
  "VOHSU",
  "VOHUN",
  "VOIGT",
  "VOINA",
  "VOINE",
  "VOINI",
  "VOINO",
  "VOINU",
  "VOITA",
  "VOITE",
  "VOITH",
  "VOITI",
  "VOITL",
  "VOITU",
  "VOJAH",
  "VOJAK",
  "VOJAM",
  "VOJAN",
  "VOJEM",
  "VOJEN",
  "VOJEV",
  "VOJIH",
  "VOJIN",
  "VOJJA",
  "VOJJU",
  "VOJKA",
  "VOJKE",
  "VOJKI",
  "VOJKO",
  "VOJKU",
  "VOJNA",
  "VOJNE",
  "VOJNI",
  "VOJNO",
  "VOJOM",
  "VOJOV",
  "VOJSK",
  "VOJTA",
  "VOJTE",
  "VOJTI",
  "VOJTO",
  "VOJTU",
  "VOJUJ",
  "VOKAČ",
  "VOKAH",
  "VOKAL",
  "VOKAM",
  "VOKEM",
  "VOKEV",
  "VOKIH",
  "VOKJA",
  "VOKJU",
  "VOKLA",
  "VOKLO",
  "VOKLU",
  "VOKOM",
  "VOKOV",
  "VOLAJ",
  "VOLAN",
  "VOLAR",
  "VOLAŠ",
  "VOLCA",
  "VOLCE",
  "VOLCI",
  "VOLCK",
  "VOLCU",
  "VOLČA",
  "VOLČE",
  "VOLČI",
  "VOLČO",
  "VOLČU",
  "VOLDA",
  "VOLDE",
  "VOLDI",
  "VOLDU",
  "VOLEČ",
  "VOLEH",
  "VOLEJ",
  "VOLEK",
  "VOLEM",
  "VOLER",
  "VOLEV",
  "VOLFA",
  "VOLFE",
  "VOLFI",
  "VOLFU",
  "VOLGA",
  "VOLGE",
  "VOLGI",
  "VOLGO",
  "VOLHA",
  "VOLHE",
  "VOLHI",
  "VOLHO",
  "VOLHU",
  "VOLIČ",
  "VOLIH",
  "VOLIL",
  "VOLIM",
  "VOLIN",
  "VOLIŠ",
  "VOLIT",
  "VOLIU",
  "VOLIV",
  "VOLJA",
  "VOLJČ",
  "VOLJE",
  "VOLJI",
  "VOLJO",
  "VOLJU",
  "VOLKA",
  "VOLKC",
  "VOLKE",
  "VOLKI",
  "VOLKU",
  "VOLLA",
  "VOLLE",
  "VOLLI",
  "VOLLU",
  "VOLMA",
  "VOLME",
  "VOLMI",
  "VOLMU",
  "VOLNA",
  "VOLNE",
  "VOLNI",
  "VOLNO",
  "VOLOF",
  "VOLOG",
  "VOLOM",
  "VOLOS",
  "VOLOV",
  "VOLPE",
  "VOLPI",
  "VOLTA",
  "VOLTE",
  "VOLTI",
  "VOLTO",
  "VOLTR",
  "VOLTU",
  "VOLTZ",
  "VOLUT",
  "VOLVA",
  "VOLVE",
  "VOLVI",
  "VOLVO",
  "VOLVU",
  "VOLZA",
  "VOLZE",
  "VOLZI",
  "VOLZU",
  "VOMER",
  "VOMJA",
  "VOMJU",
  "VOMOM",
  "VOMOV",
  "VONCK",
  "VONČA",
  "VONČE",
  "VONČI",
  "VONČO",
  "VONČU",
  "VONEM",
  "VONEV",
  "VONIH",
  "VONJA",
  "VONJE",
  "VONJI",
  "VONJU",
  "VONKA",
  "VONKE",
  "VONKI",
  "VONKU",
  "VONNA",
  "VONNE",
  "VONNI",
  "VONNU",
  "VONOM",
  "VONOV",
  "VONTA",
  "VONTE",
  "VONTI",
  "VONTO",
  "VONTU",
  "VOORN",
  "VORIH",
  "VOSAT",
  "VOSEK",
  "VOSEM",
  "VOSEV",
  "VOSIH",
  "VOSJA",
  "VOSJU",
  "VOSKA",
  "VOSKE",
  "VOSKI",
  "VOSKU",
  "VOSLA",
  "VOSLE",
  "VOSLI",
  "VOSLU",
  "VOSOM",
  "VOSOV",
  "VOSSA",
  "VOSSE",
  "VOSSI",
  "VOSSU",
  "VOŠCE",
  "VOŠČE",
  "VOŠČI",
  "VOŠČL",
  "VOŠČU",
  "VOŠKA",
  "VOŠKE",
  "VOŠKI",
  "VOŠKO",
  "VOTAN",
  "VOTEK",
  "VOTEL",
  "VOTEM",
  "VOTEV",
  "VOTHA",
  "VOTHE",
  "VOTHI",
  "VOTHU",
  "VOTIH",
  "VOTJA",
  "VOTJU",
  "VOTKA",
  "VOTKE",
  "VOTKI",
  "VOTKU",
  "VOTLA",
  "VOTLE",
  "VOTLI",
  "VOTLO",
  "VOTLU",
  "VOTOM",
  "VOTOV",
  "VOTTA",
  "VOTTE",
  "VOTTI",
  "VOTTO",
  "VOTTU",
  "VOUET",
  "VOUKA",
  "VOUKE",
  "VOUKI",
  "VOUKU",
  "VOURI",
  "VOVKA",
  "VOVKE",
  "VOVKI",
  "VOVKO",
  "VOVKU",
  "VOVSI",
  "VOVŠE",
  "VOZAČ",
  "VOZAR",
  "VOZEČ",
  "VOZEH",
  "VOZEK",
  "VOZEL",
  "VOZEM",
  "VOZEN",
  "VOZEV",
  "VOZIL",
  "VOZIM",
  "VOZIŠ",
  "VOZIT",
  "VOZIU",
  "VOZIV",
  "VOZJA",
  "VOZJE",
  "VOZJU",
  "VOZKA",
  "VOZKE",
  "VOZKI",
  "VOZKU",
  "VOZLA",
  "VOZLE",
  "VOZLI",
  "VOZLU",
  "VOZMI",
  "VOZNA",
  "VOZNE",
  "VOZNI",
  "VOZNO",
  "VOZOM",
  "VOZOV",
  "VPADA",
  "VPADE",
  "VPADI",
  "VPADO",
  "VPADU",
  "VPAST",
  "VPELA",
  "VPELE",
  "VPELI",
  "VPELO",
  "VPETA",
  "VPETE",
  "VPETI",
  "VPETO",
  "VPIHA",
  "VPIHE",
  "VPIHI",
  "VPIHU",
  "VPIJA",
  "VPIJE",
  "VPIJI",
  "VPIJU",
  "VPILA",
  "VPILE",
  "VPILI",
  "VPILO",
  "VPILU",
  "VPISA",
  "VPISE",
  "VPISI",
  "VPISU",
  "VPIŠE",
  "VPIŠI",
  "VPITI",
  "VPLET",
  "VPLIV",
  "VPLUJ",
  "VPLUL",
  "VPLUT",
  "VPNEM",
  "VPNEŠ",
  "VPOJA",
  "VPOJE",
  "VPOJI",
  "VPOJU",
  "VPONA",
  "VPONE",
  "VPONI",
  "VPONK",
  "VPONO",
  "VPREČ",
  "VPREG",
  "VPREK",
  "VPUST",
  "VRABC",
  "VRABČ",
  "VRABL",
  "VRAČA",
  "VRAČE",
  "VRAČI",
  "VRAČK",
  "VRAČU",
  "VRAGA",
  "VRAGC",
  "VRAGE",
  "VRAGI",
  "VRAGU",
  "VRAIN",
  "VRANA",
  "VRANC",
  "VRANE",
  "VRANI",
  "VRANK",
  "VRANO",
  "VRANU",
  "VRAPI",
  "VRASE",
  "VRAST",
  "VRASU",
  "VRATA",
  "VRATC",
  "VRATI",
  "VRATU",
  "VRAZA",
  "VRAZE",
  "VRAZI",
  "VRAZU",
  "VRAŽA",
  "VRAŽE",
  "VRAŽI",
  "VRAŽO",
  "VRBAH",
  "VRBAM",
  "VRBAN",
  "VRBAR",
  "VRBAS",
  "VRBEK",
  "VRBEN",
  "VRBEV",
  "VRBIC",
  "VRBIČ",
  "VRBIN",
  "VRBJA",
  "VRBJE",
  "VRBJI",
  "VRBJO",
  "VRBJU",
  "VRBNA",
  "VRBNE",
  "VRBNI",
  "VRBNO",
  "VRBOV",
  "VRČEK",
  "VRČEM",
  "VRČEV",
  "VRČIC",
  "VRČIH",
  "VRČJA",
  "VRČJU",
  "VRČKA",
  "VRČKE",
  "VRČKI",
  "VRČKO",
  "VRČKU",
  "VRČOM",
  "VRČON",
  "VRČOV",
  "VRECL",
  "VREČA",
  "VREČE",
  "VREČI",
  "VREČK",
  "VREČO",
  "VREČT",
  "VREČU",
  "VREDU",
  "VREGA",
  "VREGE",
  "VREGI",
  "VREGU",
  "VREJO",
  "VRELA",
  "VRELC",
  "VRELE",
  "VRELI",
  "VRELK",
  "VRELO",
  "VREMA",
  "VREME",
  "VREMK",
  "VREMO",
  "VRENC",
  "VRENI",
  "VRENJ",
  "VRENK",
  "VRESA",
  "VRESE",
  "VRESI",
  "VRESK",
  "VRESO",
  "VRESU",
  "VREŠA",
  "VREŠČ",
  "VREŠE",
  "VREŠI",
  "VREŠU",
  "VRETA",
  "VRETE",
  "VRETI",
  "VRETO",
  "VRETU",
  "VREVA",
  "VREVC",
  "VREVE",
  "VREVI",
  "VREZA",
  "VREZE",
  "VREZI",
  "VREZU",
  "VREŽA",
  "VREŽE",
  "VREŽI",
  "VREŽO",
  "VRGEL",
  "VRGEU",
  "VRGLA",
  "VRGLE",
  "VRGLI",
  "VRGLO",
  "VRGOČ",
  "VRHAČ",
  "VRHAH",
  "VRHAJ",
  "VRHAL",
  "VRHAM",
  "VRHAN",
  "VRHAR",
  "VRHAŠ",
  "VRHAT",
  "VRHAU",
  "VRHAV",
  "VRHEK",
  "VRHEM",
  "VRHEV",
  "VRHIH",
  "VRHJA",
  "VRHJU",
  "VRHKA",
  "VRHKU",
  "VRHOL",
  "VRHOM",
  "VRHOV",
  "VRIES",
  "VRIJA",
  "VRIJE",
  "VRIJI",
  "VRIJU",
  "VRIMO",
  "VRINE",
  "VRINI",
  "VRINU",
  "VRISA",
  "VRISE",
  "VRISI",
  "VRISK",
  "VRISU",
  "VRIŠČ",
  "VRIŠE",
  "VRIŠI",
  "VRITA",
  "VRITE",
  "VRIVA",
  "VRIVI",
  "VRIVU",
  "VRKIČ",
  "VRLEM",
  "VRLGA",
  "VRLIČ",
  "VRLIH",
  "VRLIM",
  "VRLIN",
  "VRNEM",
  "VRNEŠ",
  "VRNIL",
  "VRNIT",
  "VRNIU",
  "VRNIV",
  "VROČA",
  "VROČE",
  "VROČI",
  "VROČL",
  "VROČO",
  "VROČU",
  "VRONI",
  "VROOM",
  "VRSAR",
  "VRSNO",
  "VRSTA",
  "VRSTE",
  "VRSTI",
  "VRSTO",
  "VRSTU",
  "VRŠAC",
  "VRŠAH",
  "VRŠAJ",
  "VRŠAL",
  "VRŠAM",
  "VRŠAN",
  "VRŠAT",
  "VRŠAU",
  "VRŠAV",
  "VRŠCA",
  "VRŠCE",
  "VRŠCI",
  "VRŠCU",
  "VRŠEC",
  "VRŠEČ",
  "VRŠEK",
  "VRŠEL",
  "VRŠEN",
  "VRŠET",
  "VRŠEU",
  "VRŠIČ",
  "VRŠIL",
  "VRŠIM",
  "VRŠIŠ",
  "VRŠIT",
  "VRŠIU",
  "VRŠIV",
  "VRŠKA",
  "VRŠKE",
  "VRŠKI",
  "VRŠKO",
  "VRŠKU",
  "VRŠNA",
  "VRŠNE",
  "VRŠNI",
  "VRŠNO",
  "VRTAČ",
  "VRTAJ",
  "VRTAL",
  "VRTAM",
  "VRTAN",
  "VRTAR",
  "VRTAŠ",
  "VRTAT",
  "VRTAU",
  "VRTAV",
  "VRTCA",
  "VRTCE",
  "VRTCI",
  "VRTCU",
  "VRTEC",
  "VRTEČ",
  "VRTEH",
  "VRTEK",
  "VRTEL",
  "VRTEM",
  "VRTEN",
  "VRTET",
  "VRTEU",
  "VRTEV",
  "VRTEŽ",
  "VRTIČ",
  "VRTIL",
  "VRTIM",
  "VRTIN",
  "VRTIŠ",
  "VRTJA",
  "VRTJU",
  "VRTKA",
  "VRTKE",
  "VRTKI",
  "VRTKU",
  "VRTMI",
  "VRTNA",
  "VRTNE",
  "VRTNI",
  "VRTNO",
  "VRTOČ",
  "VRTOM",
  "VRTOV",
  "VRUJA",
  "VRUJE",
  "VRUJI",
  "VRUJO",
  "VRUNČ",
  "VRUSA",
  "VRUSE",
  "VRUSI",
  "VRUSU",
  "VRVAR",
  "VRVCA",
  "VRVCE",
  "VRVCI",
  "VRVCO",
  "VRVEČ",
  "VRVEH",
  "VRVEL",
  "VRVEM",
  "VRVEN",
  "VRVET",
  "VRVEU",
  "VRVEŽ",
  "VRVIC",
  "VRVIM",
  "VRVIŠ",
  "VRVJA",
  "VRVJE",
  "VRVJO",
  "VRVJU",
  "VRVMI",
  "VRVNA",
  "VRVNE",
  "VRVNI",
  "VRVNO",
  "VRZEL",
  "VRZIČ",
  "VRŽEM",
  "VRŽEN",
  "VRŽEŠ",
  "VSADI",
  "VSADU",
  "VSAJA",
  "VSAJI",
  "VSAJU",
  "VSAKA",
  "VSAKE",
  "VSAKI",
  "VSAKO",
  "VSEDE",
  "VSEDI",
  "VSEDU",
  "VSEEN",
  "VSEGA",
  "VSEJE",
  "VSEJU",
  "VSEKA",
  "VSEKE",
  "VSEKI",
  "VSEKU",
  "VSELI",
  "VSELU",
  "VSEMA",
  "VSEMI",
  "VSEMU",
  "VSENO",
  "VSESA",
  "VSESI",
  "VSEST",
  "VSESU",
  "VSILI",
  "VSILU",
  "VSIPA",
  "VSIPE",
  "VSIPI",
  "VSIPU",
  "VSKOK",
  "VSLED",
  "VSOTA",
  "VSOTE",
  "VSOTI",
  "VSOTO",
  "VSRČE",
  "VSRKA",
  "VSRKI",
  "VSRKU",
  "VSTAJ",
  "VSTAL",
  "VSTAT",
  "VSTAU",
  "VSTAV",
  "VSTOP",
  "VSUJE",
  "VSULA",
  "VSULE",
  "VSULI",
  "VSULO",
  "VSUTA",
  "VSUTE",
  "VSUTI",
  "VSUTO",
  "VŠIJE",
  "VŠILA",
  "VŠILE",
  "VŠILI",
  "VŠILO",
  "VŠITA",
  "VŠITE",
  "VŠITI",
  "VŠITO",
  "VŠIVA",
  "VŠIVE",
  "VŠIVI",
  "VŠIVU",
  "VŠOLA",
  "VŠOLI",
  "VŠOLU",
  "VŠTEJ",
  "VŠTEL",
  "VŠTET",
  "VŠTEU",
  "VTALI",
  "VTALU",
  "VTARE",
  "VTECI",
  "VTEČE",
  "VTEČI",
  "VTEČT",
  "VTEKA",
  "VTEKI",
  "VTEKU",
  "VTEPA",
  "VTEPE",
  "VTEPI",
  "VTEPU",
  "VTIČA",
  "VTIČE",
  "VTIČI",
  "VTIČU",
  "VTIKA",
  "VTIKE",
  "VTIKI",
  "VTIKU",
  "VTIRA",
  "VTIRI",
  "VTIRU",
  "VTISA",
  "VTISE",
  "VTISI",
  "VTISK",
  "VTISU",
  "VTKAL",
  "VTKAM",
  "VTKAN",
  "VTKAŠ",
  "VTKAT",
  "VTKAU",
  "VTKAV",
  "VTKEM",
  "VTKEŠ",
  "VTOČI",
  "VTOČL",
  "VTOČU",
  "VTOKA",
  "VTOKE",
  "VTOKI",
  "VTOKU",
  "VTOLČ",
  "VTREM",
  "VTREŠ",
  "VTRET",
  "VTRLA",
  "VTRLE",
  "VTRLI",
  "VTRLO",
  "VTRTA",
  "VTRTE",
  "VTRTI",
  "VTRTO",
  "VUCEV",
  "VUCIS",
  "VUČAJ",
  "VUČAK",
  "VUČEM",
  "VUČEV",
  "VUČIČ",
  "VUČIH",
  "VUČIN",
  "VUČJA",
  "VUČJE",
  "VUČJI",
  "VUČJO",
  "VUČJU",
  "VUČKA",
  "VUČKE",
  "VUČKI",
  "VUČKO",
  "VUČKU",
  "VUČOM",
  "VUČOV",
  "VUDJU",
  "VUGAH",
  "VUGAM",
  "VUGIH",
  "VUGJA",
  "VUGJU",
  "VUGOM",
  "VUGOV",
  "VUGTA",
  "VUGTE",
  "VUGTI",
  "VUGTU",
  "VUHAN",
  "VUICA",
  "VUICE",
  "VUICI",
  "VUICO",
  "VUICU",
  "VUJEM",
  "VUJEV",
  "VUJIČ",
  "VUJIH",
  "VUJJA",
  "VUJJU",
  "VUJOM",
  "VUJOV",
  "VUKAJ",
  "VUKAN",
  "VUKAS",
  "VUKIC",
  "VUKIČ",
  "VUKIH",
  "VUKJA",
  "VUKJU",
  "VUKOJ",
  "VUKOM",
  "VUKOT",
  "VUKOV",
  "VUKŠA",
  "VUKŠE",
  "VUKŠI",
  "VUKŠO",
  "VUKŠU",
  "VULAH",
  "VULAJ",
  "VULAM",
  "VULCA",
  "VULCE",
  "VULCI",
  "VULCU",
  "VULČA",
  "VULČE",
  "VULČI",
  "VULČU",
  "VULET",
  "VULIČ",
  "VULIN",
  "VULLA",
  "VULLE",
  "VULLI",
  "VULLO",
  "VULLU",
  "VULVA",
  "VULVE",
  "VULVI",
  "VULVO",
  "VUPIK",
  "VUPOR",
  "VURAL",
  "VURSA",
  "VURSU",
  "VURUN",
  "VUSER",
  "VUZEM",
  "VUZMA",
  "VUZME",
  "VUZMI",
  "VUZMU",
  "VZAME",
  "VZAMI",
  "VZBUH",
  "VZCVU",
  "VZDEL",
  "VZDET",
  "VZDEU",
  "VZDIG",
  "VZDIH",
  "VZDUH",
  "VZELA",
  "VZELE",
  "VZELI",
  "VZELO",
  "VZEMI",
  "VZETA",
  "VZETE",
  "VZETI",
  "VZETO",
  "VZGIB",
  "VZGON",
  "VZHOD",
  "VZHOT",
  "VZIDA",
  "VZIDE",
  "VZIDI",
  "VZIDO",
  "VZIDU",
  "VZITI",
  "VZKIP",
  "VZKLU",
  "VZLET",
  "VZMAH",
  "VZMET",
  "VZMIK",
  "VZNAK",
  "VZNIK",
  "VZORA",
  "VZORC",
  "VZORE",
  "VZORI",
  "VZORU",
  "VZPEL",
  "VZPET",
  "VZPEU",
  "VZPNE",
  "VZPNI",
  "VZPON",
  "VZPOR",
  "VZREJ",
  "VZROK",
  "VZŠEL",
  "VZŠEU",
  "VZŠLA",
  "VZŠLE",
  "VZŠLI",
  "VZŠLO",
  "VZVEN",
  "VZVOD",
  "VZVOT",
  "VŽGAL",
  "VŽGAN",
  "VŽGAT",
  "VŽGAU",
  "VŽGAV",
  "VŽGEM",
  "VŽGEŠ",
  "VŽIGA",
  "VŽIGE",
  "VŽIGI",
  "VŽIGU",
  "VŽIVA",
  "VŽIVI",
  "VŽIVU",
  "ZAADI",
  "ZAARA",
  "ZAARI",
  "ZAARU",
  "ZABAH",
  "ZABAL",
  "ZABAM",
  "ZABAV",
  "ZABDI",
  "ZABEL",
  "ZABIČ",
  "ZABIH",
  "ZABIJ",
  "ZABIL",
  "ZABIT",
  "ZABIU",
  "ZABIV",
  "ZABJA",
  "ZABJU",
  "ZABLA",
  "ZABLE",
  "ZABLI",
  "ZABLU",
  "ZABOD",
  "ZABOJ",
  "ZABOK",
  "ZABOM",
  "ZABOT",
  "ZABOV",
  "ZABRU",
  "ZABRZ",
  "ZABUŠ",
  "ZACHA",
  "ZACHE",
  "ZACHI",
  "ZACHU",
  "ZACIH",
  "ZACJA",
  "ZACJU",
  "ZACKA",
  "ZACKE",
  "ZACKI",
  "ZACKS",
  "ZACKU",
  "ZACOM",
  "ZACOV",
  "ZACVU",
  "ZAČEL",
  "ZAČET",
  "ZAČEU",
  "ZAČNE",
  "ZAČNI",
  "ZAČNO",
  "ZAČUJ",
  "ZAČUL",
  "ZAČUT",
  "ZADAH",
  "ZADAJ",
  "ZADAL",
  "ZADAM",
  "ZADAN",
  "ZADAR",
  "ZADAŠ",
  "ZADAT",
  "ZADAU",
  "ZADAV",
  "ZADEK",
  "ZADEL",
  "ZADET",
  "ZADEU",
  "ZADEV",
  "ZADIN",
  "ZADKA",
  "ZADKE",
  "ZADKI",
  "ZADKU",
  "ZADNA",
  "ZADNČ",
  "ZADNE",
  "ZADNI",
  "ZADNO",
  "ZADOR",
  "ZADRA",
  "ZADRE",
  "ZADRG",
  "ZADRI",
  "ZADRK",
  "ZADRL",
  "ZADRO",
  "ZADRT",
  "ZADRU",
  "ZADRŽ",
  "ZAETT",
  "ZAEVA",
  "ZAEVE",
  "ZAEVI",
  "ZAEVU",
  "ZAFAR",
  "ZAFIR",
  "ZAFON",
  "ZAGAJ",
  "ZAGAT",
  "ZAGER",
  "ZAGIB",
  "ZAGIH",
  "ZAGJA",
  "ZAGJU",
  "ZAGLA",
  "ZAGNI",
  "ZAGNU",
  "ZAGOD",
  "ZAGOM",
  "ZAGON",
  "ZAGOV",
  "ZAGRU",
  "ZAHAH",
  "ZAHAJ",
  "ZAHAM",
  "ZAHAR",
  "ZAHEJ",
  "ZAHER",
  "ZAHID",
  "ZAHIH",
  "ZAHIN",
  "ZAHIR",
  "ZAHJA",
  "ZAHJU",
  "ZAHMA",
  "ZAHME",
  "ZAHMI",
  "ZAHMU",
  "ZAHNA",
  "ZAHNE",
  "ZAHNI",
  "ZAHNU",
  "ZAHOD",
  "ZAHOM",
  "ZAHOR",
  "ZAHOT",
  "ZAHOV",
  "ZAHRA",
  "ZAHRE",
  "ZAHRI",
  "ZAHRO",
  "ZAHRU",
  "ZAIAH",
  "ZAIAM",
  "ZAIDA",
  "ZAIDE",
  "ZAIDI",
  "ZAIDO",
  "ZAIDU",
  "ZAIEM",
  "ZAIEV",
  "ZAIIH",
  "ZAIJA",
  "ZAIJU",
  "ZAILA",
  "ZAILE",
  "ZAILI",
  "ZAILO",
  "ZAIMI",
  "ZAIOM",
  "ZAIOV",
  "ZAIRA",
  "ZAIRI",
  "ZAIRK",
  "ZAIRU",
  "ZAITI",
  "ZAJCA",
  "ZAJCE",
  "ZAJCI",
  "ZAJCU",
  "ZAJEB",
  "ZAJEC",
  "ZAJED",
  "ZAJEJ",
  "ZAJEL",
  "ZAJEM",
  "ZAJER",
  "ZAJEŠ",
  "ZAJET",
  "ZAJEU",
  "ZAJIČ",
  "ZAJKA",
  "ZAJKE",
  "ZAJKI",
  "ZAJKO",
  "ZAJLA",
  "ZAJLE",
  "ZAJLI",
  "ZAJLO",
  "ZAJMA",
  "ZAJME",
  "ZAJMI",
  "ZAJMU",
  "ZAJUR",
  "ZAKAJ",
  "ZAKAL",
  "ZAKIH",
  "ZAKJA",
  "ZAKJU",
  "ZAKLA",
  "ZAKLU",
  "ZAKOL",
  "ZAKOM",
  "ZAKON",
  "ZAKOP",
  "ZAKOV",
  "ZAKRU",
  "ZAKSA",
  "ZAKSE",
  "ZAKSI",
  "ZAKSU",
  "ZAKUH",
  "ZAKUJ",
  "ZAKUP",
  "ZALAH",
  "ZALAM",
  "ZALAR",
  "ZALAZ",
  "ZALEČ",
  "ZALEG",
  "ZALEM",
  "ZALES",
  "ZALET",
  "ZALGA",
  "ZALIH",
  "ZALIJ",
  "ZALIK",
  "ZALIL",
  "ZALIM",
  "ZALIN",
  "ZALIT",
  "ZALIU",
  "ZALIV",
  "ZALJA",
  "ZALJU",
  "ZALKA",
  "ZALKE",
  "ZALKI",
  "ZALKO",
  "ZALLA",
  "ZALLE",
  "ZALLI",
  "ZALLU",
  "ZALMA",
  "ZALME",
  "ZALMI",
  "ZALMU",
  "ZALOG",
  "ZALOK",
  "ZALOM",
  "ZALOŠ",
  "ZALOV",
  "ZALOŽ",
  "ZALTA",
  "ZALTE",
  "ZALTI",
  "ZALTO",
  "ZALTU",
  "ZAMAH",
  "ZAMAN",
  "ZAMAR",
  "ZAMAZ",
  "ZAMBA",
  "ZAMBE",
  "ZAMBI",
  "ZAMBO",
  "ZAMBU",
  "ZAMEN",
  "ZAMER",
  "ZAMES",
  "ZAMET",
  "ZAMID",
  "ZAMIK",
  "ZAMIR",
  "ZAMMA",
  "ZAMME",
  "ZAMMI",
  "ZAMMU",
  "ZAMOR",
  "ZAMOŽ",
  "ZAMPA",
  "ZAMPE",
  "ZAMPI",
  "ZAMPO",
  "ZAMPU",
  "ZAMRE",
  "ZAMRI",
  "ZAMRL",
  "ZAMUD",
  "ZANAH",
  "ZANAM",
  "ZANDA",
  "ZANDE",
  "ZANDI",
  "ZANDT",
  "ZANDU",
  "ZANES",
  "ZANGE",
  "ZANGI",
  "ZANGS",
  "ZANGU",
  "ZANIČ",
  "ZANIH",
  "ZANIN",
  "ZANIS",
  "ZANKA",
  "ZANKE",
  "ZANKI",
  "ZANKO",
  "ZANKU",
  "ZANNA",
  "ZANNE",
  "ZANNI",
  "ZANNO",
  "ZANOM",
  "ZANON",
  "ZANOS",
  "ZANOV",
  "ZANTA",
  "ZANTE",
  "ZANTI",
  "ZANTU",
  "ZAORU",
  "ZAPAH",
  "ZAPAT",
  "ZAPAŽ",
  "ZAPEČ",
  "ZAPEL",
  "ZAPET",
  "ZAPEU",
  "ZAPIJ",
  "ZAPIK",
  "ZAPIL",
  "ZAPIN",
  "ZAPIS",
  "ZAPIT",
  "ZAPIU",
  "ZAPIV",
  "ZAPLU",
  "ZAPNE",
  "ZAPNI",
  "ZAPOG",
  "ZAPOJ",
  "ZAPON",
  "ZAPOR",
  "ZAPPA",
  "ZAPPE",
  "ZAPPG",
  "ZAPPI",
  "ZAPPO",
  "ZAPPU",
  "ZAPRE",
  "ZAPRI",
  "ZAPRL",
  "ZAPRO",
  "ZAPRT",
  "ZAPRU",
  "ZAPUŽ",
  "ZARAD",
  "ZARAH",
  "ZARAM",
  "ZARBA",
  "ZARBE",
  "ZARBI",
  "ZARBO",
  "ZARBU",
  "ZARCO",
  "ZARDI",
  "ZARDU",
  "ZAREČ",
  "ZAREK",
  "ZAREM",
  "ZARES",
  "ZAREV",
  "ZAREZ",
  "ZARFL",
  "ZARIA",
  "ZARIČ",
  "ZARIE",
  "ZARIF",
  "ZARIH",
  "ZARII",
  "ZARIJ",
  "ZARIK",
  "ZARIL",
  "ZARIN",
  "ZARIO",
  "ZARIS",
  "ZARIT",
  "ZARIU",
  "ZARIV",
  "ZARJA",
  "ZARJE",
  "ZARJI",
  "ZARJO",
  "ZARJU",
  "ZAROD",
  "ZAROK",
  "ZAROM",
  "ZARON",
  "ZAROT",
  "ZAROU",
  "ZAROV",
  "ZARTA",
  "ZARTE",
  "ZARTI",
  "ZARTU",
  "ZASAP",
  "ZASEČ",
  "ZASED",
  "ZASEG",
  "ZASEJ",
  "ZASEK",
  "ZASIJ",
  "ZASIP",
  "ZASPI",
  "ZASPU",
  "ZASRU",
  "ZASTU",
  "ZASUJ",
  "ZASUK",
  "ZASUL",
  "ZASUN",
  "ZASUT",
  "ZAŠEL",
  "ZAŠEU",
  "ZAŠIJ",
  "ZAŠIL",
  "ZAŠIT",
  "ZAŠIU",
  "ZAŠIV",
  "ZAŠLA",
  "ZAŠLE",
  "ZAŠLI",
  "ZAŠLO",
  "ZATEČ",
  "ZATEG",
  "ZATEM",
  "ZATIČ",
  "ZATIK",
  "ZATKA",
  "ZATKE",
  "ZATKI",
  "ZATKO",
  "ZATKU",
  "ZATOK",
  "ZATON",
  "ZATOR",
  "ZATRE",
  "ZATRI",
  "ZATRL",
  "ZATRT",
  "ZAUGA",
  "ZAUGE",
  "ZAUGG",
  "ZAUGI",
  "ZAUGU",
  "ZAUKA",
  "ZAUKI",
  "ZAUKU",
  "ZAUPA",
  "ZAUPI",
  "ZAUPU",
  "ZAUSA",
  "ZAUSE",
  "ZAUSI",
  "ZAUSU",
  "ZAUŽU",
  "ZAVAL",
  "ZAVCA",
  "ZAVCE",
  "ZAVCI",
  "ZAVCU",
  "ZAVDA",
  "ZAVDI",
  "ZAVDO",
  "ZAVDU",
  "ZAVEC",
  "ZAVEJ",
  "ZAVEL",
  "ZAVEM",
  "ZAVER",
  "ZAVES",
  "ZAVEŠ",
  "ZAVET",
  "ZAVEU",
  "ZAVEZ",
  "ZAVIH",
  "ZAVIJ",
  "ZAVIL",
  "ZAVIN",
  "ZAVIT",
  "ZAVIU",
  "ZAVIV",
  "ZAVOD",
  "ZAVOJ",
  "ZAVOR",
  "ZAVOT",
  "ZAVPU",
  "ZAVRA",
  "ZAVRČ",
  "ZAVRE",
  "ZAVRH",
  "ZAVRI",
  "ZAVRL",
  "ZAVRŠ",
  "ZAVRT",
  "ZAVRU",
  "ZAVZU",
  "ZAVŽU",
  "ZAZDI",
  "ZAZDU",
  "ZAZIB",
  "ZAZID",
  "ZAZIL",
  "ZAZNA",
  "ZAZNI",
  "ZAZNU",
  "ZAZRE",
  "ZAZRI",
  "ZAZRL",
  "ZAZRT",
  "ZAZUL",
  "ZAZZI",
  "ZAŽGE",
  "ZAŽGI",
  "ZAŽGU",
  "ZAŽIG",
  "ZAŽRE",
  "ZAŽRI",
  "ZAŽRL",
  "ZAŽRT",
  "ZBADA",
  "ZBADI",
  "ZBADO",
  "ZBADU",
  "ZBALA",
  "ZBALE",
  "ZBALI",
  "ZBALO",
  "ZBASI",
  "ZBASU",
  "ZBAŠE",
  "ZBAŠI",
  "ZBATI",
  "ZBEGA",
  "ZBEGI",
  "ZBEGU",
  "ZBERE",
  "ZBERI",
  "ZBEZA",
  "ZBEZI",
  "ZBEZU",
  "ZBEŽE",
  "ZBEŽI",
  "ZBEŽU",
  "ZBIČA",
  "ZBIČI",
  "ZBIČU",
  "ZBIJA",
  "ZBIJE",
  "ZBIJI",
  "ZBIJU",
  "ZBILA",
  "ZBILE",
  "ZBILI",
  "ZBILJ",
  "ZBILO",
  "ZBIOP",
  "ZBIRA",
  "ZBIRE",
  "ZBIRI",
  "ZBIRK",
  "ZBIRU",
  "ZBITA",
  "ZBITE",
  "ZBITI",
  "ZBITO",
  "ZBLOD",
  "ZBLOP",
  "ZBODE",
  "ZBODI",
  "ZBODU",
  "ZBOJI",
  "ZBOLI",
  "ZBOLU",
  "ZBORA",
  "ZBORE",
  "ZBORI",
  "ZBORU",
  "ZBOSI",
  "ZBOST",
  "ZBOSU",
  "ZBRAL",
  "ZBRAN",
  "ZBRAT",
  "ZBRAU",
  "ZBRAV",
  "ZBRCA",
  "ZBRCI",
  "ZBRCU",
  "ZBRIS",
  "ZBRKA",
  "ZBRKE",
  "ZBRKI",
  "ZBRKO",
  "ZBUDE",
  "ZBUDI",
  "ZBUDU",
  "ZBUJA",
  "ZBUJI",
  "ZBUJU",
  "ZBURE",
  "ZCMDS",
  "ZDAJE",
  "ZDAJI",
  "ZDAJU",
  "ZDANI",
  "ZDANU",
  "ZDARS",
  "ZDAVC",
  "ZDAVP",
  "ZDDPO",
  "ZDEBA",
  "ZDEBE",
  "ZDEBI",
  "ZDEBU",
  "ZDELA",
  "ZDELE",
  "ZDELI",
  "ZDELO",
  "ZDELU",
  "ZDENA",
  "ZDENE",
  "ZDENI",
  "ZDENK",
  "ZDENO",
  "ZDERE",
  "ZDERI",
  "ZDETI",
  "ZDGNS",
  "ZDIJO",
  "ZDIJZ",
  "ZDILA",
  "ZDILI",
  "ZDILU",
  "ZDIMA",
  "ZDIME",
  "ZDIMO",
  "ZDIMS",
  "ZDIOD",
  "ZDIRA",
  "ZDIRI",
  "ZDIRU",
  "ZDIŠI",
  "ZDIŠU",
  "ZDITA",
  "ZDITE",
  "ZDIVA",
  "ZDIVE",
  "ZDIVI",
  "ZDLOV",
  "ZDOLE",
  "ZDOMA",
  "ZDOMC",
  "ZDOMK",
  "ZDOPS",
  "ZDOSK",
  "ZDOTU",
  "ZDOUC",
  "ZDOVC",
  "ZDPRA",
  "ZDPRE",
  "ZDPZP",
  "ZDRAD",
  "ZDRAH",
  "ZDRAV",
  "ZDRČI",
  "ZDRČU",
  "ZDREM",
  "ZDREŠ",
  "ZDRET",
  "ZDRIZ",
  "ZDRJU",
  "ZDRKA",
  "ZDRKE",
  "ZDRKI",
  "ZDRKU",
  "ZDRLA",
  "ZDRLE",
  "ZDRLI",
  "ZDRLO",
  "ZDRMA",
  "ZDRMI",
  "ZDRMU",
  "ZDROB",
  "ZDROK",
  "ZDRSA",
  "ZDRSE",
  "ZDRSI",
  "ZDRSU",
  "ZDRUP",
  "ZDRVI",
  "ZDRVU",
  "ZDRZZ",
  "ZDRŽE",
  "ZDRŽI",
  "ZDRŽU",
  "ZDSLU",
  "ZDSSS",
  "ZDSTA",
  "ZDSVE",
  "ZDTON",
  "ZDUSA",
  "ZDUSU",
  "ZDUŠA",
  "ZDUŠE",
  "ZDUŠI",
  "ZDUŠO",
  "ZDUŠU",
  "ZDZDR",
  "ZDZFI",
  "ZDZUO",
  "ZEBAH",
  "ZEBAM",
  "ZEBCA",
  "ZEBCE",
  "ZEBCI",
  "ZEBCU",
  "ZEBEC",
  "ZEBEL",
  "ZEBEM",
  "ZEBER",
  "ZEBEŠ",
  "ZEBEU",
  "ZEBIČ",
  "ZEBIH",
  "ZEBJA",
  "ZEBJU",
  "ZEBLA",
  "ZEBLE",
  "ZEBLI",
  "ZEBLO",
  "ZEBOM",
  "ZEBOV",
  "ZEBRA",
  "ZEBRE",
  "ZEBRI",
  "ZEBRO",
  "ZEBST",
  "ZECCA",
  "ZECCE",
  "ZECCI",
  "ZECCO",
  "ZECCU",
  "ZECEM",
  "ZECEV",
  "ZECHA",
  "ZECHE",
  "ZECHI",
  "ZECHO",
  "ZECHU",
  "ZECIH",
  "ZECJA",
  "ZECJU",
  "ZECKA",
  "ZECKE",
  "ZECKI",
  "ZECKU",
  "ZECOM",
  "ZECOV",
  "ZEČAH",
  "ZEČAM",
  "ZEČAN",
  "ZEČEV",
  "ZEČOV",
  "ZEDDA",
  "ZEDDE",
  "ZEDDI",
  "ZEDDO",
  "ZEDDU",
  "ZEEPE",
  "ZEEVI",
  "ZEFFA",
  "ZEFFE",
  "ZEFFI",
  "ZEFFU",
  "ZEFIH",
  "ZEFJA",
  "ZEFJU",
  "ZEFOM",
  "ZEFOV",
  "ZEGAH",
  "ZEGAM",
  "ZEGAR",
  "ZEGIH",
  "ZEGJA",
  "ZEGJU",
  "ZEGNA",
  "ZEGNE",
  "ZEGNI",
  "ZEGNO",
  "ZEGNU",
  "ZEGOM",
  "ZEGOV",
  "ZEHAJ",
  "ZEHAL",
  "ZEHAM",
  "ZEHAŠ",
  "ZEHAT",
  "ZEHAU",
  "ZEHAV",
  "ZEHER",
  "ZEHIH",
  "ZEHJA",
  "ZEHJU",
  "ZEHOM",
  "ZEHOV",
  "ZEHRA",
  "ZEHRE",
  "ZEHRI",
  "ZEHRO",
  "ZEIAN",
  "ZEIEM",
  "ZEIEV",
  "ZEIHN",
  "ZEIIH",
  "ZEIJA",
  "ZEIJU",
  "ZEIKE",
  "ZEILA",
  "ZEILE",
  "ZEILI",
  "ZEILU",
  "ZEINA",
  "ZEINE",
  "ZEINI",
  "ZEINO",
  "ZEINU",
  "ZEIOM",
  "ZEIOV",
  "ZEIRA",
  "ZEIRE",
  "ZEIRI",
  "ZEIRO",
  "ZEIRU",
  "ZEISA",
  "ZEISE",
  "ZEISI",
  "ZEISS",
  "ZEISU",
  "ZEITZ",
  "ZEJAK",
  "ZEKAH",
  "ZEKAJ",
  "ZEKAM",
  "ZEKIH",
  "ZEKJA",
  "ZEKJU",
  "ZEKOM",
  "ZEKOV",
  "ZELAH",
  "ZELAM",
  "ZELDA",
  "ZELDE",
  "ZELDI",
  "ZELDO",
  "ZELEH",
  "ZELEM",
  "ZELEN",
  "ZELIČ",
  "ZELIG",
  "ZELIH",
  "ZELIK",
  "ZELJA",
  "ZELJE",
  "ZELJO",
  "ZELJU",
  "ZELKA",
  "ZELKE",
  "ZELKI",
  "ZELKO",
  "ZELKU",
  "ZELLA",
  "ZELLE",
  "ZELLI",
  "ZELLU",
  "ZELMI",
  "ZELOM",
  "ZELOT",
  "ZELOV",
  "ZELŠE",
  "ZELTA",
  "ZELTE",
  "ZELTI",
  "ZELTU",
  "ZEMAJ",
  "ZEMAN",
  "ZEMIN",
  "ZEMIR",
  "ZEMME",
  "ZEMON",
  "ZEMPA",
  "ZEMPE",
  "ZEMPI",
  "ZEMPU",
  "ZEMSO",
  "ZEMUN",
  "ZEMŽM",
  "ZENAH",
  "ZENAM",
  "ZENEL",
  "ZENEM",
  "ZENER",
  "ZENFA",
  "ZENFE",
  "ZENFI",
  "ZENFU",
  "ZENGA",
  "ZENGE",
  "ZENGI",
  "ZENGO",
  "ZENGU",
  "ZENIC",
  "ZENIH",
  "ZENIN",
  "ZENIT",
  "ZENJA",
  "ZENJU",
  "ZENKA",
  "ZENKE",
  "ZENKI",
  "ZENKU",
  "ZENOM",
  "ZENON",
  "ZENOV",
  "ZENPP",
  "ZENSA",
  "ZENSE",
  "ZENSI",
  "ZENSU",
  "ZENUN",
  "ZENZA",
  "ZENZE",
  "ZENZI",
  "ZENZU",
  "ZEPAN",
  "ZEPED",
  "ZEPEP",
  "ZEPIČ",
  "ZEPJV",
  "ZEPPA",
  "ZEPPE",
  "ZEPPI",
  "ZEPPO",
  "ZEPPU",
  "ZERBE",
  "ZERBI",
  "ZERCK",
  "ZEREŠ",
  "ZERIF",
  "ZERPA",
  "ZERPE",
  "ZERPI",
  "ZERPO",
  "ZERPU",
  "ZERRA",
  "ZERRE",
  "ZERRI",
  "ZERRU",
  "ZESEN",
  "ZESNA",
  "ZESNE",
  "ZESNI",
  "ZESNU",
  "ZEŠKA",
  "ZEŠKE",
  "ZEŠKI",
  "ZEŠKO",
  "ZETAH",
  "ZETAM",
  "ZETEM",
  "ZETEV",
  "ZETIH",
  "ZETIN",
  "ZETJA",
  "ZETJU",
  "ZETOM",
  "ZETOR",
  "ZETOV",
  "ZETTL",
  "ZEVAJ",
  "ZEVAL",
  "ZEVAM",
  "ZEVAŠ",
  "ZEVAT",
  "ZEVAU",
  "ZEVAV",
  "ZEVEH",
  "ZEVEM",
  "ZEVEV",
  "ZEVIH",
  "ZEVIN",
  "ZEVJA",
  "ZEVJO",
  "ZEVJU",
  "ZEVKA",
  "ZEVKE",
  "ZEVKI",
  "ZEVKO",
  "ZEVMI",
  "ZEVOM",
  "ZEVON",
  "ZEVOP",
  "ZEVOV",
  "ZEVSA",
  "ZEVSE",
  "ZEVSI",
  "ZEVSU",
  "ZEZAJ",
  "ZEZAL",
  "ZEZAM",
  "ZEZAŠ",
  "ZEZAT",
  "ZEZAU",
  "ZEZAV",
  "ZFZIP",
  "ZFZZB",
  "ZGAGA",
  "ZGAGE",
  "ZGAGI",
  "ZGAGO",
  "ZGAGU",
  "ZGANE",
  "ZGANI",
  "ZGANU",
  "ZGARA",
  "ZGARI",
  "ZGARU",
  "ZGAZI",
  "ZGAZU",
  "ZGDEJ",
  "ZGIBA",
  "ZGIBE",
  "ZGIBI",
  "ZGIBU",
  "ZGIGM",
  "ZGINE",
  "ZGINI",
  "ZGINU",
  "ZGJIM",
  "ZGLED",
  "ZGLET",
  "ZGLJA",
  "ZGLOB",
  "ZGMRS",
  "ZGNAL",
  "ZGNAN",
  "ZGNAT",
  "ZGNAU",
  "ZGNAV",
  "ZGNIJ",
  "ZGNIL",
  "ZGNIT",
  "ZGNIU",
  "ZGNIV",
  "ZGODA",
  "ZGODB",
  "ZGODE",
  "ZGODI",
  "ZGODO",
  "ZGODU",
  "ZGOLJ",
  "ZGONC",
  "ZGONČ",
  "ZGONJ",
  "ZGOPS",
  "ZGORI",
  "ZGORU",
  "ZGOŠA",
  "ZGOŠE",
  "ZGOŠI",
  "ZGOŠO",
  "ZGPRO",
  "ZGRBI",
  "ZGRBO",
  "ZGRBU",
  "ZGRDA",
  "ZGRMI",
  "ZGRMU",
  "ZGRNE",
  "ZGRNI",
  "ZGRNU",
  "ZGUBA",
  "ZGUBE",
  "ZGUBI",
  "ZGUBO",
  "ZGUBU",
  "ZGULI",
  "ZGULU",
  "ZHAIH",
  "ZHAJA",
  "ZHAJU",
  "ZHANA",
  "ZHANE",
  "ZHANG",
  "ZHANI",
  "ZHANU",
  "ZHAOM",
  "ZHAOV",
  "ZHEGU",
  "ZHENG",
  "ZHERK",
  "ZHITI",
  "ZHPPM",
  "ZHUBI",
  "ZHUJA",
  "ZHUJE",
  "ZHUJI",
  "ZHUJU",
  "ZHUPA",
  "ZHUPE",
  "ZHUPI",
  "ZHUPO",
  "ZHUPU",
  "ZHURI",
  "ZIADA",
  "ZIADE",
  "ZIADI",
  "ZIADU",
  "ZIANI",
  "ZIBAH",
  "ZIBAJ",
  "ZIBAL",
  "ZIBAM",
  "ZIBAN",
  "ZIBAR",
  "ZIBAŠ",
  "ZIBAT",
  "ZIBAU",
  "ZIBAV",
  "ZIBDR",
  "ZIBEL",
  "ZIBEM",
  "ZIBEV",
  "ZIBIH",
  "ZIBIN",
  "ZIBJA",
  "ZIBJU",
  "ZIBKA",
  "ZIBKE",
  "ZIBKI",
  "ZIBKO",
  "ZIBOM",
  "ZIBOV",
  "ZICEM",
  "ZICEV",
  "ZICIH",
  "ZICJA",
  "ZICJU",
  "ZICKA",
  "ZICKE",
  "ZICKI",
  "ZICKU",
  "ZICNE",
  "ZICNI",
  "ZICNT",
  "ZICNU",
  "ZICOM",
  "ZICOV",
  "ZIDAJ",
  "ZIDAK",
  "ZIDAL",
  "ZIDAM",
  "ZIDAN",
  "ZIDAR",
  "ZIDAŠ",
  "ZIDAT",
  "ZIDAU",
  "ZIDAV",
  "ZIDCA",
  "ZIDCE",
  "ZIDCI",
  "ZIDCU",
  "ZIDEC",
  "ZIDEH",
  "ZIDEK",
  "ZIDEM",
  "ZIDEN",
  "ZIDEV",
  "ZIDIH",
  "ZIDJA",
  "ZIDJU",
  "ZIDKA",
  "ZIDKE",
  "ZIDKI",
  "ZIDKU",
  "ZIDNA",
  "ZIDNE",
  "ZIDNI",
  "ZIDNO",
  "ZIDOM",
  "ZIDOV",
  "ZIEFF",
  "ZIEHE",
  "ZIEHL",
  "ZIEMS",
  "ZIENA",
  "ZIENE",
  "ZIENI",
  "ZIENU",
  "ZIERL",
  "ZIERR",
  "ZIESA",
  "ZIESE",
  "ZIESI",
  "ZIESU",
  "ZIFFA",
  "ZIFFE",
  "ZIFFI",
  "ZIFFU",
  "ZIGOT",
  "ZIGOZ",
  "ZIHER",
  "ZIHIH",
  "ZIHJA",
  "ZIHJU",
  "ZIHNI",
  "ZIHOM",
  "ZIHOV",
  "ZIHRA",
  "ZIHRI",
  "ZIHRU",
  "ZIJAD",
  "ZIJAH",
  "ZIJAJ",
  "ZIJAL",
  "ZIJAM",
  "ZIJAŠ",
  "ZIJAT",
  "ZIJAU",
  "ZIJAV",
  "ZIJEM",
  "ZIJEV",
  "ZIJIH",
  "ZIJJA",
  "ZIJJU",
  "ZIJOM",
  "ZIJOV",
  "ZIKAH",
  "ZIKAM",
  "ZIKIH",
  "ZIKJA",
  "ZIKJU",
  "ZIKOM",
  "ZIKOV",
  "ZILJA",
  "ZILJE",
  "ZILJI",
  "ZILJO",
  "ZILKA",
  "ZILKE",
  "ZILKI",
  "ZILKU",
  "ZILLE",
  "ZILLI",
  "ZILPA",
  "ZILPE",
  "ZILPI",
  "ZILPO",
  "ZILSA",
  "ZILSE",
  "ZILSI",
  "ZILSU",
  "ZIMAH",
  "ZIMAM",
  "ZIMAN",
  "ZIMIC",
  "ZIMIČ",
  "ZIMIH",
  "ZIMIN",
  "ZIMJA",
  "ZIMJU",
  "ZIMMA",
  "ZIMME",
  "ZIMMI",
  "ZIMMU",
  "ZIMOM",
  "ZIMOV",
  "ZIMTH",
  "ZIMUJ",
  "ZINAH",
  "ZINAM",
  "ZINCK",
  "ZINEM",
  "ZINEŠ",
  "ZINET",
  "ZINFP",
  "ZINGG",
  "ZINGL",
  "ZINIL",
  "ZININ",
  "ZINIT",
  "ZINIU",
  "ZINIV",
  "ZINKA",
  "ZINKE",
  "ZINKI",
  "ZINKO",
  "ZINKS",
  "ZINKU",
  "ZINNA",
  "ZINNE",
  "ZINNI",
  "ZINNU",
  "ZINVO",
  "ZIONA",
  "ZIONE",
  "ZIONI",
  "ZIONU",
  "ZIPES",
  "ZIPFA",
  "ZIPFE",
  "ZIPFI",
  "ZIPFU",
  "ZIPPA",
  "ZIPPE",
  "ZIPPI",
  "ZIPPU",
  "ZIPRO",
  "ZIPRS",
  "ZIPSE",
  "ZIRIA",
  "ZIRIE",
  "ZIRII",
  "ZIRIN",
  "ZIRIO",
  "ZIRIU",
  "ZIRKA",
  "ZIRKE",
  "ZIRKI",
  "ZIRKU",
  "ZIRPO",
  "ZIRVS",
  "ZISDU",
  "ZISIS",
  "ZISKA",
  "ZISKE",
  "ZISKI",
  "ZISKO",
  "ZISKU",
  "ZITAH",
  "ZITAM",
  "ZITIN",
  "ZITZA",
  "ZITZE",
  "ZITZI",
  "ZITZU",
  "ZIUOM",
  "ZIUPS",
  "ZIVIH",
  "ZIVIS",
  "ZIVJA",
  "ZIVJU",
  "ZIVOM",
  "ZIVOV",
  "ZIZAH",
  "ZIZAM",
  "ZIZDO",
  "ZIZEK",
  "ZIZKA",
  "ZIZKE",
  "ZIZKI",
  "ZIZKU",
  "ZIZZI",
  "ZJAČA",
  "ZJAČE",
  "ZJAČI",
  "ZJAČO",
  "ZJAČU",
  "ZJAHA",
  "ZJAHI",
  "ZJAHU",
  "ZJAŠE",
  "ZJAŠI",
  "ZJEBA",
  "ZJEBE",
  "ZJEBI",
  "ZJEBU",
  "ZJEDI",
  "ZJEZI",
  "ZJEZU",
  "ZJNKD",
  "ZJNKI",
  "ZJOČE",
  "ZJOČI",
  "ZJOKA",
  "ZJOKI",
  "ZJOKU",
  "ZJOPZ",
  "ZJOSS",
  "ZJOTA",
  "ZJOTU",
  "ZJSJP",
  "ZJSRS",
  "ZKIKM",
  "ZKOLP",
  "ZKOTZ",
  "ZKOZP",
  "ZKPPK",
  "ZKRMI",
  "ZLAGA",
  "ZLAGI",
  "ZLAGU",
  "ZLAMI",
  "ZLASA",
  "ZLASI",
  "ZLASU",
  "ZLATA",
  "ZLATE",
  "ZLATI",
  "ZLATK",
  "ZLATO",
  "ZLATU",
  "ZLAŽE",
  "ZLAŽI",
  "ZLEGA",
  "ZLEMU",
  "ZLEPA",
  "ZLEPI",
  "ZLEPU",
  "ZLEST",
  "ZLETA",
  "ZLETE",
  "ZLETI",
  "ZLETU",
  "ZLEZE",
  "ZLEZI",
  "ZLEZU",
  "ZLIJE",
  "ZLIKA",
  "ZLIKI",
  "ZLIKU",
  "ZLILA",
  "ZLILE",
  "ZLILI",
  "ZLILO",
  "ZLIMA",
  "ZLIMI",
  "ZLIMU",
  "ZLINA",
  "ZLINU",
  "ZLITA",
  "ZLITE",
  "ZLITI",
  "ZLITO",
  "ZLIVA",
  "ZLIVI",
  "ZLIVU",
  "ZLIZU",
  "ZLIŽE",
  "ZLIŽI",
  "ZLLMP",
  "ZLNDL",
  "ZLOBA",
  "ZLOBE",
  "ZLOBI",
  "ZLOBO",
  "ZLOGA",
  "ZLOGE",
  "ZLOGI",
  "ZLOGU",
  "ZLOMA",
  "ZLOME",
  "ZLOMI",
  "ZLOMU",
  "ZLOTA",
  "ZLOTE",
  "ZLOTI",
  "ZLOTU",
  "ZLOŽB",
  "ZLOŽI",
  "ZLOŽU",
  "ZLPLS",
  "ZMAGA",
  "ZMAGE",
  "ZMAGI",
  "ZMAGO",
  "ZMAGU",
  "ZMAJA",
  "ZMAJE",
  "ZMAJI",
  "ZMAJL",
  "ZMAJU",
  "ZMAKA",
  "ZMAKE",
  "ZMAKI",
  "ZMAKU",
  "ZMAMI",
  "ZMAMU",
  "ZMANE",
  "ZMANI",
  "ZMAŠI",
  "ZMAŠU",
  "ZMATD",
  "ZMATR",
  "ZMAZU",
  "ZMAŽE",
  "ZMAŽI",
  "ZMEČE",
  "ZMEČI",
  "ZMEDA",
  "ZMEDE",
  "ZMEDI",
  "ZMEDO",
  "ZMEDU",
  "ZMELA",
  "ZMELE",
  "ZMELI",
  "ZMELO",
  "ZMENE",
  "ZMENI",
  "ZMENU",
  "ZMERI",
  "ZMERU",
  "ZMESI",
  "ZMEST",
  "ZMEŠA",
  "ZMEŠI",
  "ZMEŠU",
  "ZMETA",
  "ZMETD",
  "ZMETE",
  "ZMETI",
  "ZMETO",
  "ZMETU",
  "ZMEZI",
  "ZMEZU",
  "ZMIGA",
  "ZMIGI",
  "ZMIGU",
  "ZMIJE",
  "ZMILA",
  "ZMILE",
  "ZMILI",
  "ZMILO",
  "ZMING",
  "ZMINP",
  "ZMIRA",
  "ZMIRE",
  "ZMIRI",
  "ZMIRO",
  "ZMITA",
  "ZMITE",
  "ZMITI",
  "ZMITO",
  "ZMLEL",
  "ZMLET",
  "ZMLEU",
  "ZMOČI",
  "ZMOČL",
  "ZMOČU",
  "ZMOLI",
  "ZMOLU",
  "ZMORE",
  "ZMOTA",
  "ZMOTC",
  "ZMOTE",
  "ZMOTI",
  "ZMOTO",
  "ZMOTU",
  "ZMPDP",
  "ZMRŠI",
  "ZMRŠU",
  "ZMZPP",
  "ZNAČK",
  "ZNAJO",
  "ZNAJT",
  "ZNAKA",
  "ZNAKE",
  "ZNAKI",
  "ZNAKU",
  "ZNALA",
  "ZNALC",
  "ZNALE",
  "ZNALI",
  "ZNALK",
  "ZNALO",
  "ZNAMA",
  "ZNAME",
  "ZNAMK",
  "ZNAMO",
  "ZNANA",
  "ZNANC",
  "ZNANE",
  "ZNANI",
  "ZNANJ",
  "ZNANK",
  "ZNANO",
  "ZNAŠA",
  "ZNAŠI",
  "ZNAŠU",
  "ZNATA",
  "ZNATE",
  "ZNATI",
  "ZNAVA",
  "ZNAVE",
  "ZNAVI",
  "ZNEBI",
  "ZNEBO",
  "ZNEBU",
  "ZNESE",
  "ZNESI",
  "ZNEST",
  "ZNESU",
  "ZNIDC",
  "ZNIMO",
  "ZNITA",
  "ZNITE",
  "ZNIVA",
  "ZNIŽA",
  "ZNIŽI",
  "ZNIŽU",
  "ZNKEB",
  "ZNNKO",
  "ZNOČI",
  "ZNOČL",
  "ZNOČU",
  "ZNOFP",
  "ZNOIP",
  "ZNOJA",
  "ZNOJE",
  "ZNOJF",
  "ZNOJI",
  "ZNOJU",
  "ZNORE",
  "ZNORG",
  "ZNORI",
  "ZNORU",
  "ZNOSC",
  "ZNOSI",
  "ZNOSU",
  "ZNOVA",
  "ZNPIO",
  "ZNPSZ",
  "ZNPZV",
  "ZNRŠZ",
  "ZNSVS",
  "ZNTPS",
  "ZNTSZ",
  "ZNZGP",
  "ZNZSF",
  "ZOBAČ",
  "ZOBAJ",
  "ZOBAL",
  "ZOBAM",
  "ZOBAN",
  "ZOBAR",
  "ZOBAŠ",
  "ZOBAT",
  "ZOBAU",
  "ZOBAV",
  "ZOBCA",
  "ZOBCE",
  "ZOBCI",
  "ZOBCU",
  "ZOBČA",
  "ZOBČI",
  "ZOBČU",
  "ZOBEC",
  "ZOBEČ",
  "ZOBEH",
  "ZOBEK",
  "ZOBEL",
  "ZOBEM",
  "ZOBEN",
  "ZOBER",
  "ZOBIL",
  "ZOBIM",
  "ZOBIŠ",
  "ZOBIT",
  "ZOBIU",
  "ZOBIV",
  "ZOBJA",
  "ZOBJE",
  "ZOBJO",
  "ZOBJU",
  "ZOBKA",
  "ZOBKE",
  "ZOBKI",
  "ZOBKU",
  "ZOBLA",
  "ZOBLE",
  "ZOBLI",
  "ZOBLU",
  "ZOBMI",
  "ZOBNA",
  "ZOBNE",
  "ZOBNI",
  "ZOBNO",
  "ZOBOM",
  "ZOBOV",
  "ZOBRA",
  "ZOBRE",
  "ZOBRI",
  "ZOBRU",
  "ZOCEM",
  "ZOCEV",
  "ZOCHE",
  "ZOCIH",
  "ZOCJA",
  "ZOCJU",
  "ZOCOM",
  "ZOCOV",
  "ZODPM",
  "ZOERN",
  "ZOFAH",
  "ZOFAM",
  "ZOFFA",
  "ZOFFE",
  "ZOFFI",
  "ZOFFU",
  "ZOFIA",
  "ZOFIČ",
  "ZOFIE",
  "ZOFII",
  "ZOFIJ",
  "ZOFIN",
  "ZOFIO",
  "ZOFKA",
  "ZOFKE",
  "ZOFKI",
  "ZOFKO",
  "ZOFVI",
  "ZOGAJ",
  "ZOGGA",
  "ZOGGE",
  "ZOGGI",
  "ZOGGU",
  "ZOHAH",
  "ZOHAM",
  "ZOHAR",
  "ZOHER",
  "ZOHIL",
  "ZOHIN",
  "ZOHMA",
  "ZOHME",
  "ZOHMI",
  "ZOHMU",
  "ZOHRA",
  "ZOHRE",
  "ZOHRI",
  "ZOHRO",
  "ZOIDL",
  "ZOILA",
  "ZOILE",
  "ZOILI",
  "ZOILO",
  "ZOISA",
  "ZOISE",
  "ZOISI",
  "ZOISU",
  "ZOIZK",
  "ZOJAH",
  "ZOJAM",
  "ZOJED",
  "ZOJIN",
  "ZOKEN",
  "ZOKNA",
  "ZOKNE",
  "ZOKNI",
  "ZOKNU",
  "ZOKOV",
  "ZOLAN",
  "ZOLEK",
  "ZOLJA",
  "ZOLJE",
  "ZOLJI",
  "ZOLJO",
  "ZOLJU",
  "ZOLLA",
  "ZOLLE",
  "ZOLLI",
  "ZOLLO",
  "ZOLLU",
  "ZOLMP",
  "ZOLOT",
  "ZOMBI",
  "ZOMEP",
  "ZOMZO",
  "ZONAH",
  "ZONAM",
  "ZONCA",
  "ZONCE",
  "ZONCI",
  "ZONCO",
  "ZONCU",
  "ZONIČ",
  "ZONIH",
  "ZONIK",
  "ZONIN",
  "ZONJA",
  "ZONJE",
  "ZONJI",
  "ZONJO",
  "ZONJU",
  "ZONNA",
  "ZONNE",
  "ZONNI",
  "ZONNU",
  "ZONOM",
  "ZONOV",
  "ZONTA",
  "ZONTE",
  "ZONTI",
  "ZONTO",
  "ZONTU",
  "ZONUL",
  "ZOOKA",
  "ZOOKE",
  "ZOOKI",
  "ZOOKU",
  "ZOOMA",
  "ZOOME",
  "ZOOMI",
  "ZOOMU",
  "ZOONA",
  "ZOONE",
  "ZOONI",
  "ZOONU",
  "ZOOTA",
  "ZOOTU",
  "ZOPDA",
  "ZOPER",
  "ZOPET",
  "ZOPFA",
  "ZOPFE",
  "ZOPFI",
  "ZOPFU",
  "ZOPJA",
  "ZOPNI",
  "ZOPNN",
  "ZOPOV",
  "ZOPPI",
  "ZOPPP",
  "ZOPPZ",
  "ZOPRN",
  "ZORAH",
  "ZORAL",
  "ZORAM",
  "ZORAN",
  "ZORAT",
  "ZORAU",
  "ZORAV",
  "ZORBA",
  "ZORBE",
  "ZORBI",
  "ZORBO",
  "ZORBU",
  "ZORCA",
  "ZORCE",
  "ZORCI",
  "ZORCU",
  "ZORČA",
  "ZORČE",
  "ZORČI",
  "ZORČU",
  "ZOREC",
  "ZOREČ",
  "ZORED",
  "ZOREL",
  "ZOREM",
  "ZOREN",
  "ZORET",
  "ZOREU",
  "ZOREV",
  "ZORIC",
  "ZORIČ",
  "ZORIH",
  "ZORIM",
  "ZORIN",
  "ZORIŠ",
  "ZORJA",
  "ZORJE",
  "ZORJI",
  "ZORJU",
  "ZORKA",
  "ZORKE",
  "ZORKI",
  "ZORKO",
  "ZORKU",
  "ZORLU",
  "ZORNA",
  "ZORNE",
  "ZORNI",
  "ZORNO",
  "ZORNU",
  "ZOROM",
  "ZOROV",
  "ZORRA",
  "ZORRE",
  "ZORRI",
  "ZORRO",
  "ZORRU",
  "ZORZI",
  "ZOSEM",
  "ZOSEV",
  "ZOSIH",
  "ZOSJA",
  "ZOSJU",
  "ZOSKE",
  "ZOSOM",
  "ZOSOV",
  "ZOSRL",
  "ZOTAJ",
  "ZOTIH",
  "ZOTJA",
  "ZOTJU",
  "ZOTKS",
  "ZOTMR",
  "ZOTOM",
  "ZOTOV",
  "ZOTTA",
  "ZOTTI",
  "ZOTTU",
  "ZOUMA",
  "ZOUTI",
  "ZOUVI",
  "ZOVEM",
  "ZOVEV",
  "ZOVIH",
  "ZOVJA",
  "ZOVJU",
  "ZOVKA",
  "ZOVKE",
  "ZOVKI",
  "ZOVKO",
  "ZOVKU",
  "ZOVOM",
  "ZOVOV",
  "ZOZKD",
  "ZOŽAJ",
  "ZOŽAL",
  "ZOŽAM",
  "ZOŽAN",
  "ZOŽAŠ",
  "ZOŽAT",
  "ZOŽAU",
  "ZOŽAV",
  "ZOŽEN",
  "ZOŽIL",
  "ZOŽIM",
  "ZOŽIŠ",
  "ZOŽIT",
  "ZOŽIU",
  "ZOŽIV",
  "ZOŽUJ",
  "ZPBZD",
  "ZPDDP",
  "ZPDJU",
  "ZPDOP",
  "ZPDPD",
  "ZPDUT",
  "ZPDZC",
  "ZPEDS",
  "ZPGBT",
  "ZPGJE",
  "ZPIZA",
  "ZPIZU",
  "ZPKEU",
  "ZPKOR",
  "ZPKRI",
  "ZPKRV",
  "ZPKTP",
  "ZPLAP",
  "ZPMCP",
  "ZPNFS",
  "ZPNFU",
  "ZPNKE",
  "ZPNUP",
  "ZPODP",
  "ZPOGD",
  "ZPOKP",
  "ZPOLS",
  "ZPOMK",
  "ZPOTA",
  "ZPOTK",
  "ZPOVO",
  "ZPPAI",
  "ZPPCP",
  "ZPPDB",
  "ZPPDC",
  "ZPPDI",
  "ZPPIZ",
  "ZPPKŽ",
  "ZPPPD",
  "ZPPPR",
  "ZPPPV",
  "ZPPRE",
  "ZPPSL",
  "ZPPSP",
  "ZPPVP",
  "ZPPVS",
  "ZPPZP",
  "ZPPŽP",
  "ZPRAP",
  "ZPRCP",
  "ZPREN",
  "ZPROE",
  "ZPSDP",
  "ZPSII",
  "ZPSPD",
  "ZPSPP",
  "ZPSTO",
  "ZPSTR",
  "ZPTTS",
  "ZPTUB",
  "ZPUPD",
  "ZPUSP",
  "ZPUSS",
  "ZPVAS",
  "ZPVSR",
  "ZPVZZ",
  "ZPZOS",
  "ZRACS",
  "ZRAČI",
  "ZRAČL",
  "ZRAČU",
  "ZRAKA",
  "ZRAKE",
  "ZRAKI",
  "ZRAKU",
  "ZRASE",
  "ZRAST",
  "ZRASU",
  "ZRCAL",
  "ZREČE",
  "ZREDI",
  "ZREDU",
  "ZREIK",
  "ZREJO",
  "ZRELA",
  "ZRELE",
  "ZRELI",
  "ZRELO",
  "ZREMA",
  "ZREME",
  "ZREMO",
  "ZRENJ",
  "ZRETA",
  "ZRETE",
  "ZRETI",
  "ZREVA",
  "ZREVE",
  "ZREVI",
  "ZREZU",
  "ZREŽE",
  "ZREŽI",
  "ZRGSO",
  "ZRIBA",
  "ZRIBI",
  "ZRIBU",
  "ZRIDR",
  "ZRIJE",
  "ZRILA",
  "ZRILE",
  "ZRILI",
  "ZRILO",
  "ZRIMA",
  "ZRIMC",
  "ZRIME",
  "ZRIMI",
  "ZRIMO",
  "ZRIMU",
  "ZRINE",
  "ZRINI",
  "ZRINK",
  "ZRINU",
  "ZRIPS",
  "ZRISA",
  "ZRISU",
  "ZRIŠE",
  "ZRIŠI",
  "ZRITA",
  "ZRITE",
  "ZRITI",
  "ZRITO",
  "ZRIVA",
  "ZRKEL",
  "ZRKLA",
  "ZRKLI",
  "ZRKLO",
  "ZRKLU",
  "ZRKZS",
  "ZRNAH",
  "ZRNAM",
  "ZRNAT",
  "ZRNCA",
  "ZRNCE",
  "ZRNCI",
  "ZRNCU",
  "ZRNEC",
  "ZRNEČ",
  "ZRNIČ",
  "ZRNIH",
  "ZRNIL",
  "ZRNIM",
  "ZRNIŠ",
  "ZRNIT",
  "ZRNIU",
  "ZRNIV",
  "ZRNJA",
  "ZRNJE",
  "ZRNJU",
  "ZRNKA",
  "ZRNKE",
  "ZRNKI",
  "ZRNKO",
  "ZRNKU",
  "ZRNOM",
  "ZRNOV",
  "ZROČA",
  "ZROČE",
  "ZROČI",
  "ZROČO",
  "ZROJI",
  "ZROJU",
  "ZROLA",
  "ZROLI",
  "ZROLU",
  "ZROMS",
  "ZRORA",
  "ZRORE",
  "ZRORI",
  "ZRORU",
  "ZRPDZ",
  "ZRPJN",
  "ZRPJZ",
  "ZRPPN",
  "ZRPRI",
  "ZRPSJ",
  "ZRSBR",
  "ZRSIN",
  "ZRSVN",
  "ZRTVS",
  "ZRUŠI",
  "ZRUŠU",
  "ZRVAH",
  "ZRVAM",
  "ZRVIN",
  "ZSAMS",
  "ZSANA",
  "ZSANE",
  "ZSANI",
  "ZSANO",
  "ZSAZU",
  "ZSDJU",
  "ZSDRP",
  "ZSEIS",
  "ZSEMS",
  "ZSFBE",
  "ZSKSS",
  "ZSKUS",
  "ZSLAR",
  "ZSLKD",
  "ZSMKR",
  "ZSMKS",
  "ZSNAS",
  "ZSOFI",
  "ZSOLI",
  "ZSOLL",
  "ZSOLT",
  "ZSOPT",
  "ZSOSI",
  "ZSOSO",
  "ZSOVA",
  "ZSPDP",
  "ZSPJS",
  "ZSPOZ",
  "ZSRAZ",
  "ZSRDO",
  "ZSREG",
  "ZSREZ",
  "ZSRIB",
  "ZSRIP",
  "ZSRPR",
  "ZSSUK",
  "ZSSVE",
  "ZSTJA",
  "ZSUZS",
  "ZSVCP",
  "ZSVUU",
  "ZSZVE",
  "ZTDSI",
  "ZTDTK",
  "ZTPDR",
  "ZTPIU",
  "ZTPMK",
  "ZTPOI",
  "ZTPVI",
  "ZTSJO",
  "ZTVCP",
  "ZTVZP",
  "ZTZUT",
  "ZUANN",
  "ZUBAC",
  "ZUBAJ",
  "ZUBCA",
  "ZUBCE",
  "ZUBCI",
  "ZUBCU",
  "ZUBER",
  "ZUBIN",
  "ZUBKA",
  "ZUBKE",
  "ZUBKI",
  "ZUBKO",
  "ZUBKU",
  "ZUBOK",
  "ZUCCA",
  "ZUCCE",
  "ZUCCI",
  "ZUCCO",
  "ZUCCU",
  "ZUDIČ",
  "ZUDOR",
  "ZUEND",
  "ZUERN",
  "ZUERS",
  "ZUEST",
  "ZUEVA",
  "ZUEVE",
  "ZUEVI",
  "ZUEVU",
  "ZUFFI",
  "ZUGAN",
  "ZUGJA",
  "ZUGJU",
  "ZUGOM",
  "ZUHAL",
  "ZUHRI",
  "ZUILL",
  "ZUJIK",
  "ZUKAH",
  "ZUKAM",
  "ZUKIH",
  "ZUKIN",
  "ZUKJA",
  "ZUKJU",
  "ZUKOM",
  "ZUKOV",
  "ZUKSB",
  "ZULEM",
  "ZULIČ",
  "ZULIM",
  "ZULJU",
  "ZULLA",
  "ZULLE",
  "ZULLI",
  "ZULLO",
  "ZULLU",
  "ZUMAH",
  "ZUMAM",
  "ZUMBA",
  "ZUMBE",
  "ZUMBI",
  "ZUMBO",
  "ZUMBU",
  "ZUMEM",
  "ZUMEV",
  "ZUMIH",
  "ZUMJA",
  "ZUMJU",
  "ZUMOM",
  "ZUMOV",
  "ZUNAH",
  "ZUNAJ",
  "ZUNAM",
  "ZUNEO",
  "ZUNES",
  "ZUNIG",
  "ZUNIH",
  "ZUNJA",
  "ZUNJU",
  "ZUNOM",
  "ZUNOV",
  "ZUNPP",
  "ZUOGR",
  "ZUOOI",
  "ZUOPP",
  "ZUPAN",
  "ZUPET",
  "ZUPIN",
  "ZUPIP",
  "ZUPJA",
  "ZUPJS",
  "ZUPPI",
  "ZUPRK",
  "ZUPRN",
  "ZUPSB",
  "ZUPZV",
  "ZURAB",
  "ZURAK",
  "ZUREP",
  "ZURER",
  "ZURIA",
  "ZURIE",
  "ZURII",
  "ZURIJ",
  "ZURIO",
  "ZURIT",
  "ZURLI",
  "ZUSAK",
  "ZUSON",
  "ZUSTS",
  "ZUSZJ",
  "ZUTPG",
  "ZUTTA",
  "ZUTTE",
  "ZUTTI",
  "ZUTTO",
  "ZUTTU",
  "ZUVZA",
  "ZUZAN",
  "ZUZIO",
  "ZUZPP",
  "ZUŽIČ",
  "ZVABI",
  "ZVABO",
  "ZVABU",
  "ZVADI",
  "ZVADU",
  "ZVAGA",
  "ZVAGI",
  "ZVAGU",
  "ZVAJA",
  "ZVAJI",
  "ZVAJU",
  "ZVALI",
  "ZVALU",
  "ZVARA",
  "ZVARD",
  "ZVARE",
  "ZVARI",
  "ZVARU",
  "ZVDVS",
  "ZVEČA",
  "ZVEČI",
  "ZVEČU",
  "ZVEDE",
  "ZVEDI",
  "ZVEDO",
  "ZVEDU",
  "ZVEGA",
  "ZVEGI",
  "ZVEGU",
  "ZVEJO",
  "ZVEKO",
  "ZVEMA",
  "ZVEME",
  "ZVEMO",
  "ZVENA",
  "ZVENE",
  "ZVENI",
  "ZVENU",
  "ZVERA",
  "ZVERC",
  "ZVERE",
  "ZVERI",
  "ZVERU",
  "ZVEST",
  "ZVEVA",
  "ZVEVE",
  "ZVEVI",
  "ZVEZA",
  "ZVEZD",
  "ZVEZE",
  "ZVEZI",
  "ZVEZO",
  "ZVEZU",
  "ZVEŽE",
  "ZVEŽI",
  "ZVGLD",
  "ZVICA",
  "ZVICI",
  "ZVICU",
  "ZVIJA",
  "ZVIJE",
  "ZVIJI",
  "ZVIJU",
  "ZVIKA",
  "ZVIKE",
  "ZVIKI",
  "ZVIKO",
  "ZVIKU",
  "ZVILA",
  "ZVILE",
  "ZVILI",
  "ZVILO",
  "ZVINA",
  "ZVINE",
  "ZVINI",
  "ZVINU",
  "ZVIRA",
  "ZVIRČ",
  "ZVIRI",
  "ZVIRU",
  "ZVISJ",
  "ZVIŠA",
  "ZVIŠI",
  "ZVIŠU",
  "ZVITA",
  "ZVITE",
  "ZVITI",
  "ZVITO",
  "ZVKBO",
  "ZVKDS",
  "ZVKSN",
  "ZVLEČ",
  "ZVNDL",
  "ZVNDN",
  "ZVNSR",
  "ZVODI",
  "ZVODU",
  "ZVOHA",
  "ZVOHI",
  "ZVOHU",
  "ZVOJA",
  "ZVOJD",
  "ZVOJE",
  "ZVOJI",
  "ZVOJL",
  "ZVOJU",
  "ZVOKA",
  "ZVOKE",
  "ZVOKI",
  "ZVOKU",
  "ZVOLK",
  "ZVONA",
  "ZVONC",
  "ZVONE",
  "ZVONI",
  "ZVONK",
  "ZVONU",
  "ZVOTU",
  "ZVOZI",
  "ZVOZU",
  "ZVPEP",
  "ZVPNB",
  "ZVPNP",
  "ZVPOT",
  "ZVPOZ",
  "ZVRHA",
  "ZVRHI",
  "ZVRHU",
  "ZVRNE",
  "ZVRNI",
  "ZVRNU",
  "ZVRST",
  "ZVRTA",
  "ZVRTE",
  "ZVRTI",
  "ZVRTS",
  "ZVRTU",
  "ZVTEH",
  "ZVTEL",
  "ZVUPR",
  "ZVVPO",
  "ZZASV",
  "ZZDEJ",
  "ZZDRI",
  "ZZDRL",
  "ZZDRS",
  "ZZDSP",
  "ZZELP",
  "ZZKAT",
  "ZZNSG",
  "ZZNTM",
  "ZZOBV",
  "ZZPJU",
  "ZZPLP",
  "ZZPPZ",
  "ZZPRI",
  "ZZRAP",
  "ZZRZI",
  "ZZSDU",
  "ZZSGO",
  "ZZSIO",
  "ZZSNN",
  "ZZSOK",
  "ZZSPI",
  "ZZUID",
  "ZZUPP",
  "ZZUUP",
  "ZZVTJ",
  "ZZVZZ",
  "ZZZAT",
  "ZZZDR",
  "ZZZIV",
  "ZZZPB",
  "ZZZRO",
  "ŽABAH",
  "ŽABAM",
  "ŽABAR",
  "ŽABCA",
  "ŽABCE",
  "ŽABCI",
  "ŽABCU",
  "ŽABČE",
  "ŽABEC",
  "ŽABIC",
  "ŽABIN",
  "ŽABJA",
  "ŽABJE",
  "ŽABJI",
  "ŽABJO",
  "ŽABON",
  "ŽABOT",
  "ŽABRL",
  "ŽADAN",
  "ŽADEM",
  "ŽADEN",
  "ŽADEV",
  "ŽADIN",
  "ŽADJA",
  "ŽADJU",
  "ŽADNA",
  "ŽADNE",
  "ŽADNI",
  "ŽADNO",
  "ŽADOM",
  "ŽADOV",
  "ŽAGAH",
  "ŽAGAJ",
  "ŽAGAL",
  "ŽAGAM",
  "ŽAGAN",
  "ŽAGAR",
  "ŽAGAŠ",
  "ŽAGAT",
  "ŽAGAU",
  "ŽAGAV",
  "ŽAGEN",
  "ŽAGIC",
  "ŽAGIN",
  "ŽAGNA",
  "ŽAGNE",
  "ŽAGNI",
  "ŽAGNO",
  "ŽAJAH",
  "ŽAJAM",
  "ŽAJEM",
  "ŽAJEV",
  "ŽAJFA",
  "ŽAJFE",
  "ŽAJFI",
  "ŽAJFO",
  "ŽAJFU",
  "ŽAJIH",
  "ŽAJJA",
  "ŽAJJU",
  "ŽAJOM",
  "ŽAJOV",
  "ŽAKEL",
  "ŽAKIH",
  "ŽAKJA",
  "ŽAKJU",
  "ŽAKLA",
  "ŽAKLE",
  "ŽAKLI",
  "ŽAKLU",
  "ŽAKOM",
  "ŽAKOV",
  "ŽAKUL",
  "ŽALAC",
  "ŽALAH",
  "ŽALAM",
  "ŽALAR",
  "ŽALCA",
  "ŽALCE",
  "ŽALCI",
  "ŽALCU",
  "ŽALEC",
  "ŽALEČ",
  "ŽALEM",
  "ŽALEN",
  "ŽALER",
  "ŽALIC",
  "ŽALIG",
  "ŽALIK",
  "ŽALIL",
  "ŽALIM",
  "ŽALIŠ",
  "ŽALIT",
  "ŽALIU",
  "ŽALIV",
  "ŽALKA",
  "ŽALKE",
  "ŽALKI",
  "ŽALKO",
  "ŽALNA",
  "ŽALNE",
  "ŽALNI",
  "ŽALNO",
  "ŽALUD",
  "ŽALUJ",
  "ŽAMEM",
  "ŽAMEŠ",
  "ŽAMET",
  "ŽAMPA",
  "ŽAMPE",
  "ŽAMPI",
  "ŽAMPO",
  "ŽAMPU",
  "ŽAMUT",
  "ŽANAH",
  "ŽANAM",
  "ŽANET",
  "ŽANIH",
  "ŽANIN",
  "ŽANJA",
  "ŽANJC",
  "ŽANJE",
  "ŽANJI",
  "ŽANJU",
  "ŽANKA",
  "ŽANKE",
  "ŽANKI",
  "ŽANKO",
  "ŽANKU",
  "ŽANOM",
  "ŽANOV",
  "ŽANRA",
  "ŽANRE",
  "ŽANRI",
  "ŽANRU",
  "ŽAPUŽ",
  "ŽARAH",
  "ŽARAM",
  "ŽARČI",
  "ŽARČL",
  "ŽARČU",
  "ŽAREČ",
  "ŽAREK",
  "ŽAREL",
  "ŽAREM",
  "ŽAREN",
  "ŽARET",
  "ŽAREU",
  "ŽAREV",
  "ŽARGI",
  "ŽARIH",
  "ŽARIJ",
  "ŽARIL",
  "ŽARIM",
  "ŽARIN",
  "ŽARIŠ",
  "ŽARIT",
  "ŽARIU",
  "ŽARIV",
  "ŽARJA",
  "ŽARJE",
  "ŽARJI",
  "ŽARJO",
  "ŽARJU",
  "ŽARKA",
  "ŽARKE",
  "ŽARKI",
  "ŽARKO",
  "ŽARKU",
  "ŽARNA",
  "ŽARNE",
  "ŽARNI",
  "ŽARNO",
  "ŽARNU",
  "ŽAROM",
  "ŽAROV",
  "ŽAVBA",
  "ŽAVBE",
  "ŽAVBI",
  "ŽAVBO",
  "ŽAŽAR",
  "ŽBANA",
  "ŽBANE",
  "ŽBANI",
  "ŽBANU",
  "ŽBEJA",
  "ŽBEJE",
  "ŽBEJI",
  "ŽBEJU",
  "ŽBICA",
  "ŽBICE",
  "ŽBICI",
  "ŽBICO",
  "ŽBONA",
  "ŽBONE",
  "ŽBONI",
  "ŽBONO",
  "ŽBONU",
  "ŽDEČA",
  "ŽDEČE",
  "ŽDEČI",
  "ŽDEČO",
  "ŽDELA",
  "ŽDELE",
  "ŽDELI",
  "ŽDELO",
  "ŽDENJ",
  "ŽDETI",
  "ŽDIJO",
  "ŽDIMA",
  "ŽDIME",
  "ŽDIMO",
  "ŽDITA",
  "ŽDITE",
  "ŽDIVA",
  "ŽDIVE",
  "ŽDIVI",
  "ŽEBOT",
  "ŽEBRA",
  "ŽEBRE",
  "ŽEBRI",
  "ŽEBRU",
  "ŽEGAR",
  "ŽEGEN",
  "ŽEGNA",
  "ŽEGNE",
  "ŽEGNI",
  "ŽEGNU",
  "ŽEGRA",
  "ŽEGRU",
  "ŽEGUR",
  "ŽEHTA",
  "ŽEHTE",
  "ŽEHTI",
  "ŽEHTO",
  "ŽEHTU",
  "ŽEIJO",
  "ŽEJAH",
  "ŽEJAJ",
  "ŽEJAL",
  "ŽEJAM",
  "ŽEJAN",
  "ŽEJAŠ",
  "ŽEJAT",
  "ŽEJAU",
  "ŽEJAV",
  "ŽEJCA",
  "ŽEJCE",
  "ŽEJCI",
  "ŽEJCU",
  "ŽEJEC",
  "ŽEJEN",
  "ŽEJKA",
  "ŽEJKE",
  "ŽEJKI",
  "ŽEJKO",
  "ŽEJNA",
  "ŽEJNE",
  "ŽEJNI",
  "ŽEJNO",
  "ŽEJNU",
  "ŽEKAR",
  "ŽEKŠA",
  "ŽEKŠE",
  "ŽEKŠI",
  "ŽEKŠU",
  "ŽELAH",
  "ŽELAM",
  "ŽELAT",
  "ŽELČE",
  "ŽELEČ",
  "ŽELEL",
  "ŽELEN",
  "ŽELEZ",
  "ŽELIH",
  "ŽELIM",
  "ŽELIŠ",
  "ŽELJA",
  "ŽELJE",
  "ŽELJI",
  "ŽELJK",
  "ŽELJN",
  "ŽELJO",
  "ŽELJU",
  "ŽELKA",
  "ŽELKE",
  "ŽELKI",
  "ŽELKO",
  "ŽELKU",
  "ŽELOD",
  "ŽELOM",
  "ŽELOT",
  "ŽELOV",
  "ŽELVA",
  "ŽELVE",
  "ŽELVI",
  "ŽELVO",
  "ŽEMVA",
  "ŽEMVE",
  "ŽEMVI",
  "ŽEMVO",
  "ŽEMVU",
  "ŽENAH",
  "ŽENAM",
  "ŽENEK",
  "ŽENEM",
  "ŽENER",
  "ŽENEŠ",
  "ŽENET",
  "ŽENIC",
  "ŽENIH",
  "ŽENIK",
  "ŽENIL",
  "ŽENIM",
  "ŽENIN",
  "ŽENIŠ",
  "ŽENIT",
  "ŽENIU",
  "ŽENIV",
  "ŽENJA",
  "ŽENJE",
  "ŽENJI",
  "ŽENJO",
  "ŽENJU",
  "ŽENKA",
  "ŽENKE",
  "ŽENKI",
  "ŽENKO",
  "ŽENKU",
  "ŽENOF",
  "ŽENOM",
  "ŽENOV",
  "ŽENSK",
  "ŽEPAR",
  "ŽEPEK",
  "ŽEPEM",
  "ŽEPEN",
  "ŽEPEV",
  "ŽEPIČ",
  "ŽEPIH",
  "ŽEPJA",
  "ŽEPJU",
  "ŽEPKA",
  "ŽEPKE",
  "ŽEPKI",
  "ŽEPKU",
  "ŽEPNA",
  "ŽEPNE",
  "ŽEPNI",
  "ŽEPNO",
  "ŽEPOM",
  "ŽEPOV",
  "ŽERAK",
  "ŽERIČ",
  "ŽERKA",
  "ŽERKE",
  "ŽERKI",
  "ŽERKO",
  "ŽERKU",
  "ŽERUH",
  "ŽETAL",
  "ŽETEM",
  "ŽETEV",
  "ŽETGA",
  "ŽETIH",
  "ŽETIJ",
  "ŽETIM",
  "ŽETJA",
  "ŽETJE",
  "ŽETJI",
  "ŽETJU",
  "ŽETKA",
  "ŽETKE",
  "ŽETKI",
  "ŽETKO",
  "ŽETKU",
  "ŽETON",
  "ŽETVE",
  "ŽETVI",
  "ŽEVŽA",
  "ŽEVŽE",
  "ŽEVŽI",
  "ŽEVŽO",
  "ŽEVŽU",
  "ŽEZEL",
  "ŽEZLA",
  "ŽEZLI",
  "ŽEZLO",
  "ŽEZLU",
  "ŽGALA",
  "ŽGALC",
  "ŽGALE",
  "ŽGALI",
  "ŽGALK",
  "ŽGALO",
  "ŽGANA",
  "ŽGANC",
  "ŽGANE",
  "ŽGANI",
  "ŽGANJ",
  "ŽGANK",
  "ŽGANO",
  "ŽGATI",
  "ŽGAVC",
  "ŽGEČA",
  "ŽGEČE",
  "ŽGEČI",
  "ŽGEČU",
  "ŽGEJO",
  "ŽGELA",
  "ŽGELE",
  "ŽGELI",
  "ŽGELO",
  "ŽGELU",
  "ŽGEMA",
  "ŽGEME",
  "ŽGEMO",
  "ŽGETA",
  "ŽGETE",
  "ŽGEVA",
  "ŽGEVE",
  "ŽGEVI",
  "ŽGIMO",
  "ŽGITA",
  "ŽGITE",
  "ŽGIVA",
  "ŽGOČA",
  "ŽGOČE",
  "ŽGOČI",
  "ŽGOČO",
  "ŽGOLI",
  "ŽGOLU",
  "ŽGOMB",
  "ŽGURA",
  "ŽGURE",
  "ŽGURI",
  "ŽGURU",
  "ŽIBER",
  "ŽIBRA",
  "ŽIBRE",
  "ŽIBRI",
  "ŽIBRŠ",
  "ŽIBRU",
  "ŽICAH",
  "ŽICAJ",
  "ŽICAL",
  "ŽICAM",
  "ŽICAN",
  "ŽICAR",
  "ŽICAŠ",
  "ŽICAT",
  "ŽICAU",
  "ŽICAV",
  "ŽIČAH",
  "ŽIČAM",
  "ŽIČAN",
  "ŽIČAR",
  "ŽIČEČ",
  "ŽIČEN",
  "ŽIČIL",
  "ŽIČIM",
  "ŽIČIŠ",
  "ŽIČIT",
  "ŽIČIU",
  "ŽIČIV",
  "ŽIČKA",
  "ŽIČKE",
  "ŽIČKI",
  "ŽIČKO",
  "ŽIČNA",
  "ŽIČNE",
  "ŽIČNI",
  "ŽIČNO",
  "ŽIDAN",
  "ŽIDEK",
  "ŽIDEM",
  "ŽIDEV",
  "ŽIDIH",
  "ŽIDIN",
  "ŽIDJA",
  "ŽIDJE",
  "ŽIDJU",
  "ŽIDKA",
  "ŽIDKE",
  "ŽIDKI",
  "ŽIDKO",
  "ŽIDOM",
  "ŽIDOV",
  "ŽIGAH",
  "ŽIGAM",
  "ŽIGAN",
  "ŽIGEM",
  "ŽIGER",
  "ŽIGEV",
  "ŽIGIH",
  "ŽIGJA",
  "ŽIGJU",
  "ŽIGOM",
  "ŽIGON",
  "ŽIGOV",
  "ŽIGRA",
  "ŽIGRE",
  "ŽIGRI",
  "ŽIGRU",
  "ŽIHER",
  "ŽIHRA",
  "ŽIHRE",
  "ŽIHRI",
  "ŽIHRU",
  "ŽIKAH",
  "ŽIKAM",
  "ŽIKIH",
  "ŽIKJA",
  "ŽIKJU",
  "ŽIKOM",
  "ŽIKOV",
  "ŽILAH",
  "ŽILAM",
  "ŽILAV",
  "ŽILEN",
  "ŽILIC",
  "ŽILIČ",
  "ŽILIH",
  "ŽILIK",
  "ŽILJA",
  "ŽILJE",
  "ŽILJI",
  "ŽILJK",
  "ŽILJU",
  "ŽILNA",
  "ŽILNE",
  "ŽILNI",
  "ŽILNO",
  "ŽINGA",
  "ŽINGI",
  "ŽINGU",
  "ŽINIČ",
  "ŽINKA",
  "ŽINKE",
  "ŽINKI",
  "ŽINKO",
  "ŽINKU",
  "ŽIRAF",
  "ŽIRCA",
  "ŽIRCE",
  "ŽIRCI",
  "ŽIRCU",
  "ŽIREC",
  "ŽIREH",
  "ŽIREM",
  "ŽIREV",
  "ŽIRIH",
  "ŽIRIJ",
  "ŽIRJA",
  "ŽIRJE",
  "ŽIRJU",
  "ŽIRKA",
  "ŽIRKE",
  "ŽIRKI",
  "ŽIRKO",
  "ŽIRMI",
  "ŽIROM",
  "ŽIROV",
  "ŽISTA",
  "ŽISTE",
  "ŽISTI",
  "ŽISTU",
  "ŽIŠKA",
  "ŽIŠKE",
  "ŽIŠKI",
  "ŽIŠKO",
  "ŽIŠTA",
  "ŽIŠTE",
  "ŽIŠTI",
  "ŽIŠTU",
  "ŽITAH",
  "ŽITAM",
  "ŽITCA",
  "ŽITCE",
  "ŽITCI",
  "ŽITCU",
  "ŽITEC",
  "ŽITEK",
  "ŽITEN",
  "ŽITIH",
  "ŽITIJ",
  "ŽITJA",
  "ŽITJE",
  "ŽITJI",
  "ŽITJU",
  "ŽITKA",
  "ŽITKE",
  "ŽITKI",
  "ŽITKO",
  "ŽITKU",
  "ŽITNA",
  "ŽITNE",
  "ŽITNI",
  "ŽITNO",
  "ŽITOM",
  "ŽITOV",
  "ŽIVAH",
  "ŽIVAL",
  "ŽIVAM",
  "ŽIVAN",
  "ŽIVCA",
  "ŽIVCE",
  "ŽIVCI",
  "ŽIVCU",
  "ŽIVEC",
  "ŽIVEČ",
  "ŽIVEL",
  "ŽIVEM",
  "ŽIVET",
  "ŽIVEU",
  "ŽIVEŽ",
  "ŽIVGA",
  "ŽIVIC",
  "ŽIVIČ",
  "ŽIVIH",
  "ŽIVIK",
  "ŽIVIL",
  "ŽIVIM",
  "ŽIVIN",
  "ŽIVIO",
  "ŽIVIŠ",
  "ŽIVIT",
  "ŽIVIU",
  "ŽIVIV",
  "ŽIVJO",
  "ŽIVKA",
  "ŽIVKE",
  "ŽIVKI",
  "ŽIVKO",
  "ŽIVKU",
  "ŽIVOT",
  "ŽIŽAH",
  "ŽIŽAM",
  "ŽIŽEK",
  "ŽIŽEM",
  "ŽIŽEV",
  "ŽIŽIČ",
  "ŽIŽIH",
  "ŽIŽJA",
  "ŽIŽJU",
  "ŽIŽKA",
  "ŽIŽKE",
  "ŽIŽKI",
  "ŽIŽKO",
  "ŽIŽKU",
  "ŽIŽOL",
  "ŽIŽOM",
  "ŽIŽOV",
  "ŽIŽUL",
  "ŽLAHT",
  "ŽLAJF",
  "ŽLAKA",
  "ŽLAKE",
  "ŽLAKI",
  "ŽLAKU",
  "ŽLANA",
  "ŽLANK",
  "ŽLANU",
  "ŽLAUS",
  "ŽLEBA",
  "ŽLEBE",
  "ŽLEBI",
  "ŽLEBO",
  "ŽLEBU",
  "ŽLEDA",
  "ŽLEDI",
  "ŽLEDU",
  "ŽLEHT",
  "ŽLEMP",
  "ŽLEZA",
  "ŽLEZE",
  "ŽLEZI",
  "ŽLEZO",
  "ŽLICA",
  "ŽLICE",
  "ŽLICI",
  "ŽLICO",
  "ŽLIČK",
  "ŽLOFA",
  "ŽLOFE",
  "ŽLOFI",
  "ŽLOFU",
  "ŽLOTA",
  "ŽLOTE",
  "ŽLOTI",
  "ŽLOTO",
  "ŽMAKA",
  "ŽMAKE",
  "ŽMAKI",
  "ŽMAKU",
  "ŽMAUC",
  "ŽMAVC",
  "ŽMEJO",
  "ŽMEMA",
  "ŽMEME",
  "ŽMEMO",
  "ŽMETA",
  "ŽMETE",
  "ŽMEVA",
  "ŽMEVE",
  "ŽMEVI",
  "ŽMIMO",
  "ŽMITA",
  "ŽMITE",
  "ŽMIVA",
  "ŽMOHT",
  "ŽMUCA",
  "ŽMUCE",
  "ŽMUCI",
  "ŽMUCU",
  "ŽMUKA",
  "ŽMUKE",
  "ŽMUKI",
  "ŽMUKU",
  "ŽMULA",
  "ŽMULE",
  "ŽMULI",
  "ŽMULO",
  "ŽMURK",
  "ŽNJAN",
  "ŽNORA",
  "ŽNORE",
  "ŽNORI",
  "ŽNORO",
  "ŽODAN",
  "ŽOGAH",
  "ŽOGAJ",
  "ŽOGAL",
  "ŽOGAM",
  "ŽOGAN",
  "ŽOGAŠ",
  "ŽOGAT",
  "ŽOGAU",
  "ŽOGAV",
  "ŽOGIC",
  "ŽOHAR",
  "ŽOKAJ",
  "ŽOKAL",
  "ŽOKAM",
  "ŽOKAŠ",
  "ŽOKAT",
  "ŽOKAU",
  "ŽOKAV",
  "ŽOKŠA",
  "ŽOKŠE",
  "ŽOKŠI",
  "ŽOKŠU",
  "ŽOLCA",
  "ŽOLCE",
  "ŽOLCI",
  "ŽOLCO",
  "ŽOLČA",
  "ŽOLČE",
  "ŽOLČI",
  "ŽOLČU",
  "ŽOLIC",
  "ŽOLNA",
  "ŽOLNE",
  "ŽOLNI",
  "ŽOLNO",
  "ŽOLTA",
  "ŽOLTC",
  "ŽOLTE",
  "ŽOLTI",
  "ŽOLTO",
  "ŽONTA",
  "ŽONTE",
  "ŽONTI",
  "ŽONTO",
  "ŽONTU",
  "ŽORGA",
  "ŽORGE",
  "ŽORGI",
  "ŽORGO",
  "ŽORGU",
  "ŽORŽA",
  "ŽORŽE",
  "ŽORŽI",
  "ŽORŽU",
  "ŽRDAN",
  "ŽRDEH",
  "ŽRDEM",
  "ŽRDJO",
  "ŽRDMI",
  "ŽREBA",
  "ŽREBC",
  "ŽREBE",
  "ŽREBI",
  "ŽREBU",
  "ŽRECA",
  "ŽRECE",
  "ŽRECI",
  "ŽRECU",
  "ŽREJO",
  "ŽRELA",
  "ŽRELC",
  "ŽRELI",
  "ŽRELO",
  "ŽRELU",
  "ŽREMA",
  "ŽREME",
  "ŽREMO",
  "ŽRETA",
  "ŽRETE",
  "ŽRETI",
  "ŽREVA",
  "ŽREVE",
  "ŽREVI",
  "ŽRIMO",
  "ŽRITA",
  "ŽRITE",
  "ŽRIVA",
  "ŽRLIČ",
  "ŽRTEV",
  "ŽRTIH",
  "ŽRTJA",
  "ŽRTJU",
  "ŽRTOM",
  "ŽRTOV",
  "ŽRTVE",
  "ŽRTVI",
  "ŽUBER",
  "ŽUČKA",
  "ŽUČKE",
  "ŽUČKI",
  "ŽUČKO",
  "ŽUČKU",
  "ŽUGAJ",
  "ŽUGAL",
  "ŽUGAM",
  "ŽUGAŠ",
  "ŽUGAT",
  "ŽUGAU",
  "ŽUGAV",
  "ŽUGCA",
  "ŽUGCE",
  "ŽUGCI",
  "ŽUGCU",
  "ŽUGEC",
  "ŽUJIČ",
  "ŽUKAH",
  "ŽUKAM",
  "ŽUKAS",
  "ŽUKIN",
  "ŽUKOV",
  "ŽULAH",
  "ŽULAM",
  "ŽULCA",
  "ŽULCE",
  "ŽULCI",
  "ŽULCU",
  "ŽULEC",
  "ŽULEČ",
  "ŽULIČ",
  "ŽULIH",
  "ŽULIL",
  "ŽULIM",
  "ŽULIŠ",
  "ŽULIT",
  "ŽULIU",
  "ŽULIV",
  "ŽULJA",
  "ŽULJE",
  "ŽULJI",
  "ŽULJU",
  "ŽULOM",
  "ŽULOV",
  "ŽUMAJ",
  "ŽUMAN",
  "ŽUMAR",
  "ŽUMER",
  "ŽUMRA",
  "ŽUMRE",
  "ŽUMRI",
  "ŽUMRU",
  "ŽUNAR",
  "ŽUNCA",
  "ŽUNCE",
  "ŽUNCI",
  "ŽUNCU",
  "ŽUNEC",
  "ŽUNEK",
  "ŽUNIČ",
  "ŽUNIH",
  "ŽUNJA",
  "ŽUNJU",
  "ŽUNKA",
  "ŽUNKE",
  "ŽUNKI",
  "ŽUNKO",
  "ŽUNKU",
  "ŽUNOM",
  "ŽUNOV",
  "ŽUPAH",
  "ŽUPAM",
  "ŽUPAN",
  "ŽUPCA",
  "ŽUPCE",
  "ŽUPCI",
  "ŽUPCO",
  "ŽUPCU",
  "ŽUPEC",
  "ŽUPEN",
  "ŽUPIC",
  "ŽUPIČ",
  "ŽUPNA",
  "ŽUPNE",
  "ŽUPNI",
  "ŽUPNO",
  "ŽURAH",
  "ŽURAJ",
  "ŽURAL",
  "ŽURAM",
  "ŽURAN",
  "ŽURAŠ",
  "ŽURAT",
  "ŽURAU",
  "ŽURAV",
  "ŽURBI",
  "ŽUREJ",
  "ŽUREM",
  "ŽURER",
  "ŽUREV",
  "ŽURGA",
  "ŽURGE",
  "ŽURGI",
  "ŽURGO",
  "ŽURGU",
  "ŽURIH",
  "ŽURJA",
  "ŽURJU",
  "ŽURKA",
  "ŽURKE",
  "ŽURKI",
  "ŽURKO",
  "ŽURKU",
  "ŽUROM",
  "ŽUROV",
  "ŽUSEM",
  "ŽUSMA",
  "ŽUSMU",
  "ŽUSTA",
  "ŽUSTE",
  "ŽUSTI",
  "ŽUSTU",
  "ŽUVAN",
  "ŽUVEL",
  "ŽUŽAH",
  "ŽUŽAM",
  "ŽUŽAN",
  "ŽUŽEJ",
  "ŽUŽEK",
  "ŽUŽIK",
  "ŽUŽIN",
  "ŽUŽKA",
  "ŽUŽKE",
  "ŽUŽKI",
  "ŽUŽKU",
  "ŽUŽUL",
  "ŽVABA",
  "ŽVABE",
  "ŽVABI",
  "ŽVABU",
  "ŽVALA",
  "ŽVALE",
  "ŽVALI",
  "ŽVALO",
  "ŽVANA",
  "ŽVANE",
  "ŽVANI",
  "ŽVANU",
  "ŽVARA",
  "ŽVARE",
  "ŽVARI",
  "ŽVARU",
  "ŽVEČI",
  "ŽVEČL",
  "ŽVEČU",
  "ŽVELC",
  "ŽVENK",
  "ŽVERC",
  "ŽVERK",
  "ŽVIRČ",
  "ŽVIŽG",
  "ŽVORC",
  "ŽVRKA",
  "ŽVRKI",
  "ŽVRKU"
]);

export const WORDS_TARGET = [
  "ABAJA",
  "ABAJE",
  "ABAJI",
  "ABAJO",
  "ABCIM",
  "ABIOZ",
  "ABRAM",
  "ABRIN",
  "ACIDA",
  "ACIDU",
  "ADIJO",
  "ADRAM",
  "ADRIN",
  "ADUTA",
  "ADUTE",
  "ADUTI",
  "ADUTK",
  "ADUTU",
  "AFEKT",
  "AFERA",
  "AFERE",
  "AFERI",
  "AFERO",
  "AFINA",
  "AFINE",
  "AFINI",
  "AFINO",
  "AFNAM",
  "AFNIN",
  "AFTAM",
  "AFTOZ",
  "AGAMA",
  "AGAME",
  "AGAMI",
  "AGAMO",
  "AGAVA",
  "AGAVE",
  "AGAVI",
  "AGAVO",
  "AGEND",
  "AGENS",
  "AGENT",
  "AGINA",
  "AGINE",
  "AGINI",
  "AGINO",
  "AGITK",
  "AGNAT",
  "AGOMA",
  "AGORA",
  "AGORE",
  "AGORI",
  "AGORO",
  "AGOVA",
  "AGOVE",
  "AGOVI",
  "AGOVO",
  "AGRAF",
  "AGUTI",
  "AHATA",
  "AHATE",
  "AHATI",
  "AHATU",
  "AHLAM",
  "AHMEJ",
  "AHNEM",
  "AHNEŠ",
  "AHNIL",
  "AIDSA",
  "AIDSU",
  "AJAJE",
  "AJAJO",
  "AJALA",
  "AJALE",
  "AJALI",
  "AJALO",
  "AJAMO",
  "AJANJ",
  "AJATA",
  "AJATE",
  "AJATI",
  "AJAVA",
  "AJBIŠ",
  "AJDEN",
  "AJDIN",
  "AJDNA",
  "AJDNE",
  "AJDNI",
  "AJDNO",
  "AJDOV",
  "AJKAM",
  "AJNCA",
  "AJNCU",
  "AJVAR",
  "AKAJE",
  "AKAJO",
  "AKALA",
  "AKALE",
  "AKALI",
  "AKALO",
  "AKAMO",
  "AKANJ",
  "AKANT",
  "AKATA",
  "AKATE",
  "AKATI",
  "AKAVA",
  "AKCIJ",
  "AKIJA",
  "AKIJE",
  "AKIJI",
  "AKIJO",
  "AKIJU",
  "AKNAM",
  "AKORD",
  "AKRIL",
  "AKROM",
  "AKROV",
  "AKSEL",
  "AKSIL",
  "AKSLA",
  "AKSLE",
  "AKSLI",
  "AKSLU",
  "AKSON",
  "AKTIN",
  "AKTOM",
  "AKTOV",
  "AKUTA",
  "AKUTE",
  "AKUTI",
  "AKUTU",
  "ALARM",
  "ALBAM",
  "ALBIN",
  "ALBUM",
  "ALDOZ",
  "ALEJA",
  "ALEJE",
  "ALEJI",
  "ALEJO",
  "ALELA",
  "ALELE",
  "ALELI",
  "ALELU",
  "ALFAM",
  "ALGAM",
  "ALGOZ",
  "ALIBI",
  "ALILA",
  "ALILE",
  "ALILI",
  "ALILU",
  "ALKAM",
  "ALKAN",
  "ALKAR",
  "ALKEN",
  "ALKIL",
  "ALKIN",
  "ALKOM",
  "ALKOV",
  "ALOIN",
  "ALOJA",
  "ALOJE",
  "ALOJI",
  "ALOJO",
  "ALPAK",
  "ALPID",
  "ALTAN",
  "ALTOM",
  "ALTOV",
  "ALULA",
  "ALULE",
  "ALULI",
  "ALULO",
  "AMBRA",
  "AMBRE",
  "AMBRI",
  "AMBRO",
  "AMEBA",
  "AMEBE",
  "AMEBI",
  "AMEBO",
  "AMEJV",
  "AMENA",
  "AMENE",
  "AMENI",
  "AMENU",
  "AMFOR",
  "AMIDA",
  "AMIDE",
  "AMIDI",
  "AMIDU",
  "AMIJA",
  "AMIJE",
  "AMIJI",
  "AMIJO",
  "AMILA",
  "AMILE",
  "AMILI",
  "AMILU",
  "AMINA",
  "AMINE",
  "AMINI",
  "AMINU",
  "AMIŠA",
  "AMIŠE",
  "AMIŠI",
  "AMIŠU",
  "AMNIJ",
  "AMOKA",
  "AMOKE",
  "AMOKI",
  "AMOKU",
  "AMORF",
  "AMPER",
  "AMPUL",
  "ANALI",
  "ANEKS",
  "ANGEL",
  "ANGIN",
  "ANIMA",
  "ANIME",
  "ANIMI",
  "ANIMO",
  "ANION",
  "ANKET",
  "ANODA",
  "ANODE",
  "ANODI",
  "ANODO",
  "ANONA",
  "ANONE",
  "ANONI",
  "ANONO",
  "ANTEN",
  "ANUSA",
  "ANUSE",
  "ANUSI",
  "ANUSU",
  "AORTA",
  "AORTE",
  "AORTI",
  "AORTO",
  "APEKS",
  "APLIT",
  "APNAR",
  "APNAT",
  "APNEČ",
  "APNEJ",
  "APNEL",
  "APNEN",
  "APNIC",
  "APNIL",
  "APNIM",
  "APNIŠ",
  "APNOM",
  "APOEN",
  "APRIL",
  "APSID",
  "ARAKA",
  "ARAKU",
  "ARAMA",
  "ARBON",
  "ARDIT",
  "ARENA",
  "ARENE",
  "ARENI",
  "ARENO",
  "AREOL",
  "ARGON",
  "ARGUS",
  "ARHEJ",
  "ARHIV",
  "ARIJA",
  "ARIJE",
  "ARIJI",
  "ARIJK",
  "ARIJO",
  "ARINA",
  "ARINE",
  "ARINI",
  "ARINO",
  "ARKAD",
  "ARMAD",
  "ARNIK",
  "AROMA",
  "AROME",
  "AROMI",
  "AROMO",
  "AROVA",
  "AROVE",
  "AROVI",
  "AROVO",
  "ARŠIN",
  "ARZEN",
  "ASAJA",
  "ASAJE",
  "ASAJI",
  "ASAJU",
  "ASANA",
  "ASANE",
  "ASANI",
  "ASANO",
  "ASEPS",
  "ASKET",
  "ASKEZ",
  "ASOMA",
  "ASOVA",
  "ASOVE",
  "ASOVI",
  "ASOVO",
  "ASTAT",
  "ASTER",
  "ASTMA",
  "ASTME",
  "ASTMI",
  "ASTMO",
  "ASTRA",
  "ASTRE",
  "ASTRI",
  "ASTRO",
  "ATAMA",
  "ATAŠE",
  "ATECH",
  "ATEST",
  "ATIJA",
  "ATIJE",
  "ATIJI",
  "ATIJU",
  "ATLAS",
  "ATLET",
  "ATOLA",
  "ATOLE",
  "ATOLI",
  "ATOLU",
  "ATOMA",
  "ATOME",
  "ATOMI",
  "ATOMU",
  "ATOVA",
  "ATOVE",
  "ATOVI",
  "ATOVO",
  "ATRIJ",
  "AVALA",
  "AVALE",
  "AVALI",
  "AVALU",
  "AVANS",
  "AVBAM",
  "AVBIC",
  "AVGIT",
  "AVGUR",
  "AVIZA",
  "AVIZE",
  "AVIZI",
  "AVIZO",
  "AVIZU",
  "AVLAM",
  "AVRAM",
  "AVŠAM",
  "AVŠIN",
  "AVŠKA",
  "AVŠKE",
  "AVŠKI",
  "AVŠKO",
  "AVTEK",
  "AVTKA",
  "AVTKE",
  "AVTKI",
  "AVTKU",
  "AVTOM",
  "AVTOR",
  "AVTOV",
  "AZIDA",
  "AZIDE",
  "AZIDI",
  "AZIDU",
  "AZILA",
  "AZILE",
  "AZILI",
  "AZILU",
  "AZOIK",
  "AZOLA",
  "AZOLE",
  "AZOLI",
  "AZOLO",
  "BABEŽ",
  "BABIC",
  "BABJA",
  "BABJE",
  "BABJI",
  "BABKA",
  "BABKE",
  "BABKI",
  "BABKO",
  "BABUR",
  "BACEK",
  "BACIL",
  "BACKA",
  "BACKE",
  "BACKI",
  "BACKO",
  "BACKU",
  "BAČKA",
  "BAČKE",
  "BAČKI",
  "BAČKO",
  "BAFTA",
  "BAFTE",
  "BAFTI",
  "BAFTO",
  "BAGEL",
  "BAGER",
  "BAGET",
  "BAGRA",
  "BAGRE",
  "BAGRI",
  "BAGRU",
  "BAHAČ",
  "BAHAT",
  "BAHAV",
  "BAHTA",
  "BAHTE",
  "BAHTI",
  "BAHTU",
  "BAJEN",
  "BAJKA",
  "BAJKE",
  "BAJKI",
  "BAJKO",
  "BAJNA",
  "BAJNE",
  "BAJNI",
  "BAJNO",
  "BAJTI",
  "BAJTU",
  "BAJZA",
  "BAJZE",
  "BAJZI",
  "BAJZO",
  "BAKEL",
  "BAKER",
  "BAKLA",
  "BAKLE",
  "BAKLI",
  "BAKLO",
  "BAKRA",
  "BAKRI",
  "BAKRU",
  "BALAD",
  "BALAM",
  "BALET",
  "BALIN",
  "BALON",
  "BALSA",
  "BALSE",
  "BALSI",
  "BALSO",
  "BALZA",
  "BALZE",
  "BALZI",
  "BALZO",
  "BAMIJ",
  "BANAN",
  "BANIC",
  "BANJA",
  "BANJE",
  "BANJI",
  "BANJO",
  "BANKA",
  "BANKE",
  "BANKI",
  "BANKO",
  "BANOM",
  "BANOV",
  "BARAB",
  "BARAK",
  "BARAL",
  "BARBA",
  "BARBE",
  "BARBI",
  "BARBO",
  "BARDA",
  "BARDE",
  "BARDI",
  "BARDU",
  "BAREČ",
  "BARIJ",
  "BARIK",
  "BARIL",
  "BARIM",
  "BARIŠ",
  "BARIT",
  "BARJA",
  "BARJE",
  "BARJI",
  "BARJU",
  "BARKA",
  "BARKE",
  "BARKI",
  "BARKO",
  "BAROK",
  "BAROM",
  "BARON",
  "BAROV",
  "BARVA",
  "BARVE",
  "BARVI",
  "BARVO",
  "BARŽA",
  "BARŽE",
  "BARŽI",
  "BARŽO",
  "BASAJ",
  "BASEN",
  "BASIL",
  "BASNI",
  "BASOM",
  "BASOV",
  "BAŠKA",
  "BAŠKE",
  "BAŠKI",
  "BAŠKO",
  "BAŠOČ",
  "BATAN",
  "BATAT",
  "BATEK",
  "BATEN",
  "BATIČ",
  "BATIK",
  "BATKA",
  "BATKE",
  "BATKI",
  "BATKU",
  "BATNA",
  "BATNE",
  "BATNI",
  "BATNO",
  "BATOM",
  "BATOV",
  "BAVČE",
  "BAVČI",
  "BAVKA",
  "BAVTA",
  "BAVTE",
  "BAVTI",
  "BAVTO",
  "BAZAM",
  "BAZAR",
  "BAZEN",
  "BAZNA",
  "BAZNE",
  "BAZNI",
  "BAZNO",
  "BAZUK",
  "BDEČA",
  "BDEČE",
  "BDEČI",
  "BDELA",
  "BDELE",
  "BDELI",
  "BDELO",
  "BDENJ",
  "BDETI",
  "BDIJO",
  "BDIMO",
  "BDITA",
  "BDITE",
  "BDIVA",
  "BEBAV",
  "BEBCA",
  "BEBCE",
  "BEBCI",
  "BEBCU",
  "BEBEC",
  "BEBKA",
  "BEBKE",
  "BEBKI",
  "BEBKO",
  "BEBOP",
  "BEDAK",
  "BEDAM",
  "BEDEČ",
  "BEDEL",
  "BEDEN",
  "BEDER",
  "BEDIM",
  "BEDIŠ",
  "BEDNA",
  "BEDNE",
  "BEDNI",
  "BEDNO",
  "BEDRA",
  "BEDRC",
  "BEDRI",
  "BEDRN",
  "BEDRO",
  "BEDRU",
  "BEGAJ",
  "BEGAL",
  "BEGAM",
  "BEGAŠ",
  "BEGAV",
  "BEGIC",
  "BEGIN",
  "BEGOM",
  "BEGOV",
  "BEKAM",
  "BEKIC",
  "BEKIN",
  "BEKON",
  "BELCA",
  "BELCE",
  "BELCI",
  "BELCU",
  "BELEC",
  "BELEČ",
  "BELEŽ",
  "BELIC",
  "BELIČ",
  "BELIL",
  "BELIN",
  "BELKA",
  "BELKE",
  "BELKI",
  "BELKO",
  "BELUG",
  "BELUH",
  "BELUŠ",
  "BEMIT",
  "BENTE",
  "BENTI",
  "BEOMA",
  "BEOVA",
  "BEOVE",
  "BEOVI",
  "BEOVO",
  "BEPOP",
  "BERAČ",
  "BERDA",
  "BERDE",
  "BERDI",
  "BERDO",
  "BEREM",
  "BEREŠ",
  "BERIL",
  "BERIV",
  "BERMA",
  "BERME",
  "BERMI",
  "BERMO",
  "BEROČ",
  "BESED",
  "BESEN",
  "BESNA",
  "BESNE",
  "BESNI",
  "BESNO",
  "BESOM",
  "BESOV",
  "BEŠKA",
  "BEŠKE",
  "BEŠKI",
  "BEŠKO",
  "BETAM",
  "BETEL",
  "BETEV",
  "BETIČ",
  "BETOM",
  "BETON",
  "BETOV",
  "BETVA",
  "BETVE",
  "BETVI",
  "BETVO",
  "BEVČE",
  "BEVČI",
  "BEVKA",
  "BEVSK",
  "BEZAJ",
  "BEZAL",
  "BEZAM",
  "BEZAŠ",
  "BEZEG",
  "BEZGA",
  "BEZGU",
  "BEŽAL",
  "BEŽEČ",
  "BEŽEN",
  "BEŽIM",
  "BEŽIŠ",
  "BEŽNA",
  "BEŽNE",
  "BEŽNI",
  "BEŽNO",
  "BIBAM",
  "BIBER",
  "BIBIN",
  "BIBRA",
  "BIBRU",
  "BIČAJ",
  "BIČAL",
  "BIČAM",
  "BIČAN",
  "BIČAR",
  "BIČAŠ",
  "BIČEK",
  "BIČEM",
  "BIČEV",
  "BIČIJ",
  "BIČJA",
  "BIČJE",
  "BIČJI",
  "BIČJU",
  "BIČKA",
  "BIČKE",
  "BIČKI",
  "BIČKU",
  "BIDCO",
  "BIDON",
  "BIFOR",
  "BIGAM",
  "BIJOČ",
  "BIKCA",
  "BIKCE",
  "BIKCI",
  "BIKCU",
  "BIKEC",
  "BIKOM",
  "BIKOV",
  "BILEN",
  "BILJA",
  "BILJE",
  "BILJU",
  "BILKA",
  "BILKE",
  "BILKI",
  "BILKO",
  "BILNA",
  "BILNE",
  "BILNI",
  "BILNO",
  "BIMBA",
  "BIMBE",
  "BIMBI",
  "BIMBO",
  "BIMBU",
  "BINGA",
  "BINGE",
  "BINGI",
  "BINGO",
  "BINGU",
  "BINOM",
  "BIOMA",
  "BIOME",
  "BIOMI",
  "BIOMU",
  "BIRET",
  "BIRIČ",
  "BIRMA",
  "BIRME",
  "BIRMI",
  "BIRMO",
  "BIRNO",
  "BISAG",
  "BISER",
  "BISTA",
  "BISTE",
  "BISTI",
  "BISTO",
  "BISUS",
  "BIŠKA",
  "BIŠKE",
  "BIŠKI",
  "BIŠKO",
  "BITEN",
  "BITIJ",
  "BITJA",
  "BITJE",
  "BITJI",
  "BITJU",
  "BITKA",
  "BITKE",
  "BITKI",
  "BITKO",
  "BITNA",
  "BITNE",
  "BITNI",
  "BITNO",
  "BITOM",
  "BITOV",
  "BIVAJ",
  "BIVAK",
  "BIVAL",
  "BIVAM",
  "BIVAŠ",
  "BIVOL",
  "BIVŠO",
  "BIZON",
  "BLAGA",
  "BLAGE",
  "BLAGI",
  "BLAGO",
  "BLAGU",
  "BLATA",
  "BLATI",
  "BLATO",
  "BLATU",
  "BLAŽE",
  "BLAŽI",
  "BLEDA",
  "BLEDE",
  "BLEDI",
  "BLEDO",
  "BLEJA",
  "BLEJE",
  "BLEND",
  "BLESK",
  "BLIDA",
  "BLIDE",
  "BLIDI",
  "BLIDO",
  "BLISK",
  "BLIŠČ",
  "BLIZU",
  "BLIŽE",
  "BLJAK",
  "BLJUJ",
  "BLODI",
  "BLOKA",
  "BLOKE",
  "BLOKI",
  "BLOKU",
  "BLUŠČ",
  "BLUZA",
  "BLUZE",
  "BLUZI",
  "BLUZO",
  "BLUZU",
  "BOAMA",
  "BOBAK",
  "BOBEK",
  "BOBEN",
  "BOBER",
  "BOBKA",
  "BOBKE",
  "BOBKI",
  "BOBKU",
  "BOBNA",
  "BOBNE",
  "BOBNI",
  "BOBNU",
  "BOBOM",
  "BOBOV",
  "BOBRA",
  "BOBRE",
  "BOBRI",
  "BOBRU",
  "BOČEČ",
  "BOČEN",
  "BOČNA",
  "BOČNE",
  "BOČNI",
  "BOČNO",
  "BODAK",
  "BODAL",
  "BODEČ",
  "BODEL",
  "BODEN",
  "BODER",
  "BODIC",
  "BODIČ",
  "BODIK",
  "BODIL",
  "BODLA",
  "BODLE",
  "BODLI",
  "BODLO",
  "BODNA",
  "BODNE",
  "BODNI",
  "BODNO",
  "BODRA",
  "BODRE",
  "BODRI",
  "BODRO",
  "BOEMA",
  "BOEME",
  "BOEMI",
  "BOEMK",
  "BOEMU",
  "BOFOR",
  "BOGAT",
  "BOGCA",
  "BOGCE",
  "BOGCI",
  "BOGCU",
  "BOGEC",
  "BOGEK",
  "BOGKA",
  "BOGKE",
  "BOGKI",
  "BOGKU",
  "BOGOV",
  "BOHOT",
  "BOJAM",
  "BOJAR",
  "BOJEČ",
  "BOJEM",
  "BOJEN",
  "BOJEV",
  "BOJIN",
  "BOJNA",
  "BOJNE",
  "BOJNI",
  "BOJNO",
  "BOKAJ",
  "BOKAL",
  "BOKAM",
  "BOKAN",
  "BOKAŠ",
  "BOKOM",
  "BOKOV",
  "BOKSA",
  "BOKSE",
  "BOKSI",
  "BOKSU",
  "BOLAN",
  "BOLEČ",
  "BOLEL",
  "BOLEN",
  "BOLHA",
  "BOLHE",
  "BOLHI",
  "BOLHO",
  "BOLID",
  "BOLJE",
  "BOLJK",
  "BOLNA",
  "BOLNE",
  "BOLNI",
  "BOLNO",
  "BOLUS",
  "BOMBA",
  "BOMBE",
  "BOMBI",
  "BOMBO",
  "BONCA",
  "BONCE",
  "BONCI",
  "BONCU",
  "BONEC",
  "BONGA",
  "BONGE",
  "BONGI",
  "BONGO",
  "BONGU",
  "BONIT",
  "BONOM",
  "BONOV",
  "BONUS",
  "BORAG",
  "BORAT",
  "BORCA",
  "BORCE",
  "BORCI",
  "BORCU",
  "BORDO",
  "BOREC",
  "BOREČ",
  "BORED",
  "BOREN",
  "BORKA",
  "BORKE",
  "BORKI",
  "BORKO",
  "BORNA",
  "BORNE",
  "BORNI",
  "BORNO",
  "BOROM",
  "BOROV",
  "BORŠČ",
  "BORZA",
  "BORZE",
  "BORZI",
  "BORZO",
  "BOSAJ",
  "BOSAL",
  "BOSAM",
  "BOSAŠ",
  "BOSTA",
  "BOSTE",
  "BOSTI",
  "BOŠKA",
  "BOŠKE",
  "BOŠKI",
  "BOŠKO",
  "BOTER",
  "BOTRA",
  "BOTRE",
  "BOTRI",
  "BOTRO",
  "BOTRU",
  "BOVEL",
  "BOVIN",
  "BOVLA",
  "BOVLE",
  "BOVLI",
  "BOVLO",
  "BOZON",
  "BOŽAJ",
  "BOŽAL",
  "BOŽAM",
  "BOŽAŠ",
  "BOŽCA",
  "BOŽCE",
  "BOŽCI",
  "BOŽCU",
  "BOŽEC",
  "BOŽIC",
  "BOŽIČ",
  "BOŽJA",
  "BOŽJE",
  "BOŽJI",
  "BOŽKA",
  "BRADA",
  "BRADE",
  "BRADI",
  "BRADO",
  "BRAJD",
  "BRAKA",
  "BRAKE",
  "BRAKI",
  "BRAKO",
  "BRAKU",
  "BRALA",
  "BRALE",
  "BRALI",
  "BRALK",
  "BRALO",
  "BRANA",
  "BRANE",
  "BRANI",
  "BRANJ",
  "BRANO",
  "BRATA",
  "BRATE",
  "BRATI",
  "BRATU",
  "BRAVO",
  "BRAZD",
  "BRBER",
  "BRBOT",
  "BRBRA",
  "BRBRE",
  "BRBRI",
  "BRBRO",
  "BRCAJ",
  "BRCAK",
  "BRCAL",
  "BRCAM",
  "BRCAŠ",
  "BRCNE",
  "BRCNI",
  "BRČIC",
  "BRDEK",
  "BRDKA",
  "BRDKE",
  "BRDKI",
  "BRDKO",
  "BREČA",
  "BREČE",
  "BREČI",
  "BREČO",
  "BREDE",
  "BREGA",
  "BREGE",
  "BREGI",
  "BREGU",
  "BREJA",
  "BREJE",
  "BREJI",
  "BREJK",
  "BREKA",
  "BREKE",
  "BREKI",
  "BREKO",
  "BREKU",
  "BREME",
  "BRENT",
  "BREST",
  "BREZA",
  "BREZE",
  "BREZI",
  "BREZO",
  "BRGEŠ",
  "BRHEK",
  "BRHKA",
  "BRHKE",
  "BRHKI",
  "BRHKO",
  "BRIDŽ",
  "BRIGA",
  "BRIGI",
  "BRIGU",
  "BRIJE",
  "BRILA",
  "BRILE",
  "BRILI",
  "BRILO",
  "BRINA",
  "BRINE",
  "BRINI",
  "BRINU",
  "BRIOŠ",
  "BRISA",
  "BRISE",
  "BRISI",
  "BRISU",
  "BRIŠI",
  "BRITI",
  "BRIVK",
  "BRIZG",
  "BRKAČ",
  "BRKAT",
  "BRKOM",
  "BRKOV",
  "BRLEČ",
  "BRLEL",
  "BRLIM",
  "BRLIŠ",
  "BRLOG",
  "BRNEČ",
  "BRNEL",
  "BRNIM",
  "BRNIŠ",
  "BROČA",
  "BROČE",
  "BROČI",
  "BROČU",
  "BRODA",
  "BRODE",
  "BRODI",
  "BRODU",
  "BROMA",
  "BROMU",
  "BRONA",
  "BRONE",
  "BRONI",
  "BRONU",
  "BROŠČ",
  "BROŠK",
  "BROZG",
  "BRSKA",
  "BRSKE",
  "BRSKI",
  "BRSKO",
  "BRSTA",
  "BRSTE",
  "BRSTI",
  "BRSTU",
  "BRŠKA",
  "BRŠKE",
  "BRŠKI",
  "BRŠKO",
  "BRUCA",
  "BRUCE",
  "BRUCI",
  "BRUCK",
  "BRUCU",
  "BRUHA",
  "BRUNA",
  "BRUNI",
  "BRUNO",
  "BRUNU",
  "BRUSA",
  "BRUSE",
  "BRUSI",
  "BRUSU",
  "BRUTO",
  "BRVEM",
  "BRZCA",
  "BRZCE",
  "BRZCI",
  "BRZCU",
  "BRZDA",
  "BRZDE",
  "BRZDI",
  "BRZDO",
  "BRZEC",
  "BRZIC",
  "BRŽOL",
  "BUBAM",
  "BUBIN",
  "BUBON",
  "BUCIK",
  "BUCNE",
  "BUCNI",
  "BUČAL",
  "BUČAM",
  "BUČEČ",
  "BUČEK",
  "BUČEN",
  "BUČIC",
  "BUČIM",
  "BUČIŠ",
  "BUČKA",
  "BUČKE",
  "BUČKI",
  "BUČKO",
  "BUČKU",
  "BUČNA",
  "BUČNE",
  "BUČNI",
  "BUČNO",
  "BUDEN",
  "BUDER",
  "BUDNA",
  "BUDNE",
  "BUDNI",
  "BUDNO",
  "BUDRA",
  "BUDRE",
  "BUDRI",
  "BUDRO",
  "BUHNE",
  "BUHNI",
  "BUHTE",
  "BUHTI",
  "BUJEN",
  "BUJNA",
  "BUJNE",
  "BUJNI",
  "BUJNO",
  "BUJON",
  "BUKAČ",
  "BUKET",
  "BUKEV",
  "BUKOV",
  "BUKVE",
  "BUKVI",
  "BULAM",
  "BULAV",
  "BULIC",
  "BULJI",
  "BUMOM",
  "BUMOV",
  "BUNDA",
  "BUNDE",
  "BUNDI",
  "BUNDO",
  "BUNIK",
  "BUNKA",
  "BUNKE",
  "BUNKI",
  "BUNKO",
  "BUREK",
  "BUREN",
  "BURET",
  "BURIJ",
  "BURIN",
  "BURJA",
  "BURJE",
  "BURJI",
  "BURJO",
  "BURKA",
  "BURKE",
  "BURKI",
  "BURKO",
  "BURNA",
  "BURNE",
  "BURNI",
  "BURNO",
  "BURSA",
  "BURSE",
  "BURSI",
  "BURSO",
  "BURŠA",
  "BURŠE",
  "BURŠI",
  "BURŠU",
  "BURZA",
  "BURZE",
  "BURZI",
  "BURZO",
  "BUSOL",
  "BUŠKA",
  "BUŠKE",
  "BUŠKI",
  "BUŠKO",
  "BUŠNE",
  "BUŠNI",
  "BUTAN",
  "BUTAR",
  "BUTCA",
  "BUTCE",
  "BUTCI",
  "BUTCU",
  "BUTEC",
  "BUTIK",
  "BUTIL",
  "BZIKA",
  "BZIKE",
  "BZIKI",
  "BZIKU",
  "CAJNA",
  "CAJNE",
  "CAJNI",
  "CAJNO",
  "CAPAJ",
  "CAPAL",
  "CAPAM",
  "CAPAŠ",
  "CAPIN",
  "CAPKA",
  "CARAR",
  "CARIC",
  "CARIN",
  "CARJA",
  "CARJE",
  "CARJI",
  "CARJU",
  "CEDEČ",
  "CEDER",
  "CEDIK",
  "CEDIL",
  "CEDRA",
  "CEDRE",
  "CEDRI",
  "CEDRO",
  "CEFIR",
  "CEHOM",
  "CEHOV",
  "CEJEN",
  "CEKAR",
  "CEKAS",
  "CEKUM",
  "CELCA",
  "CELCU",
  "CELEC",
  "CELEČ",
  "CELEK",
  "CELIC",
  "CELIN",
  "CELKA",
  "CELKE",
  "CELKI",
  "CELKU",
  "CELOM",
  "CELOT",
  "CENAM",
  "CENEČ",
  "CENEN",
  "CENIK",
  "CENTA",
  "CENTE",
  "CENTI",
  "CENTU",
  "CEPAJ",
  "CEPAL",
  "CEPAM",
  "CEPAŠ",
  "CEPCA",
  "CEPCE",
  "CEPCI",
  "CEPCU",
  "CEPEC",
  "CEPEČ",
  "CEPEK",
  "CEPET",
  "CEPIČ",
  "CEPIL",
  "CEPIM",
  "CEPIN",
  "CEPIŠ",
  "CEPIV",
  "CEPKA",
  "CEPKE",
  "CEPKI",
  "CEPKO",
  "CEPKU",
  "CERIJ",
  "CERJA",
  "CERJE",
  "CERJU",
  "CEROM",
  "CEROV",
  "CESAR",
  "CESIJ",
  "CESTA",
  "CESTE",
  "CESTI",
  "CESTO",
  "CEŠKA",
  "CEŠKE",
  "CEŠKI",
  "CEŠKO",
  "CETAN",
  "CEVAČ",
  "CEVAR",
  "CEVEM",
  "CEVEN",
  "CEVJA",
  "CEVJE",
  "CEVJU",
  "CEVKA",
  "CEVKE",
  "CEVKI",
  "CEVKO",
  "CEVNA",
  "CEVNE",
  "CEVNI",
  "CEVNO",
  "CEZEČ",
  "CEZEL",
  "CEZIJ",
  "CEZIM",
  "CEZIŠ",
  "CEZUR",
  "CIBET",
  "CIBOR",
  "CIFER",
  "CIFRA",
  "CIFRE",
  "CIFRI",
  "CIFRO",
  "CIGAN",
  "CIGAR",
  "CIKAD",
  "CIKEL",
  "CIKLA",
  "CIKLE",
  "CIKLI",
  "CIKLU",
  "CIKNE",
  "CIKNI",
  "CILJA",
  "CILJE",
  "CILJI",
  "CILJU",
  "CIMAM",
  "CIMAZ",
  "CIMET",
  "CIMIL",
  "CIMIM",
  "CIMIR",
  "CIMIŠ",
  "CINAM",
  "CINEČ",
  "CINIJ",
  "CINIK",
  "CINIL",
  "CINIM",
  "CINIŠ",
  "CINKA",
  "CINKU",
  "CIPAM",
  "CIPAR",
  "CIROZ",
  "CIRUS",
  "CISTA",
  "CISTE",
  "CISTI",
  "CISTO",
  "CITAT",
  "CITER",
  "CITRA",
  "CITRE",
  "CIVET",
  "CIZAM",
  "CIZEK",
  "CIZKA",
  "CIZKE",
  "CIZKI",
  "CIZKU",
  "CMAKA",
  "CMARE",
  "CMARI",
  "CMERA",
  "CMERE",
  "CMERI",
  "CMERO",
  "CMOKA",
  "CMOKE",
  "CMOKI",
  "CMOKU",
  "COFEK",
  "COFKA",
  "COFKE",
  "COFKI",
  "COFKU",
  "COFOM",
  "COFOV",
  "COKLA",
  "COKLE",
  "COKLO",
  "CONTE",
  "COPAT",
  "COPOT",
  "COVID",
  "CRKNI",
  "CRKUJ",
  "CUCAL",
  "CUCEK",
  "CUCKA",
  "CUCKE",
  "CUCKI",
  "CUCKU",
  "CULAM",
  "CULIC",
  "CUNJA",
  "CUNJE",
  "CUNJI",
  "CUNJO",
  "CUREČ",
  "CUREK",
  "CUREL",
  "CURIM",
  "CURIŠ",
  "CURKA",
  "CURKE",
  "CURKI",
  "CURKU",
  "CVELA",
  "CVELE",
  "CVELI",
  "CVELO",
  "CVETA",
  "CVETE",
  "CVETI",
  "CVETK",
  "CVETO",
  "CVETU",
  "CVIKU",
  "CVILI",
  "CVRČE",
  "CVRČI",
  "CVRKA",
  "CVROČ",
  "CVRTA",
  "CVRTE",
  "CVRTI",
  "CVRTO",
  "ČABAT",
  "ČAČAM",
  "ČAČEV",
  "ČAČKA",
  "ČAČKE",
  "ČAČKI",
  "ČAČKO",
  "ČADOR",
  "ČAJEM",
  "ČAJEN",
  "ČAJEV",
  "ČAJKA",
  "ČAJKE",
  "ČAJKI",
  "ČAJKO",
  "ČAJNA",
  "ČAJNE",
  "ČAJNI",
  "ČAJNO",
  "ČAJOT",
  "ČAKAJ",
  "ČAKAL",
  "ČAKAM",
  "ČAKAŠ",
  "ČAKEM",
  "ČAKER",
  "ČAKMA",
  "ČAKME",
  "ČAKMI",
  "ČAKMO",
  "ČAKRA",
  "ČAKRE",
  "ČAKRI",
  "ČAKRO",
  "ČAPCA",
  "ČAPCE",
  "ČAPCI",
  "ČAPCU",
  "ČAPEC",
  "ČAPKA",
  "ČAPKE",
  "ČAPKI",
  "ČAPKO",
  "ČARAJ",
  "ČARAL",
  "ČARAM",
  "ČARAŠ",
  "ČAREN",
  "ČARIL",
  "ČARNA",
  "ČARNE",
  "ČARNI",
  "ČARNO",
  "ČAROM",
  "ČAROV",
  "ČASEK",
  "ČASKA",
  "ČASKE",
  "ČASKI",
  "ČASKU",
  "ČASOM",
  "ČASOV",
  "ČASTE",
  "ČASTI",
  "ČAŠAM",
  "ČAŠEN",
  "ČAŠIC",
  "ČAŠNA",
  "ČAŠNE",
  "ČAŠNI",
  "ČAŠNO",
  "ČATEŽ",
  "ČEBEL",
  "ČEBER",
  "ČEBRA",
  "ČEBRE",
  "ČEBRI",
  "ČEBRU",
  "ČEBUL",
  "ČEČKA",
  "ČEDAR",
  "ČEDEN",
  "ČEDER",
  "ČEDNA",
  "ČEDNE",
  "ČEDNI",
  "ČEDNO",
  "ČEDRA",
  "ČEDRE",
  "ČEDRI",
  "ČEDRN",
  "ČEDRO",
  "ČEKAN",
  "ČEKOM",
  "ČEKOV",
  "ČELAD",
  "ČELAT",
  "ČELEN",
  "ČELNA",
  "ČELNE",
  "ČELNI",
  "ČELNO",
  "ČELOM",
  "ČELOV",
  "ČEMAŽ",
  "ČEMEČ",
  "ČEMEL",
  "ČEMIM",
  "ČEMIŠ",
  "ČEMUR",
  "ČENČA",
  "ČENČE",
  "ČENČI",
  "ČENČO",
  "ČEPAM",
  "ČEPAR",
  "ČEPEČ",
  "ČEPEK",
  "ČEPEL",
  "ČEPEN",
  "ČEPIC",
  "ČEPIM",
  "ČEPIN",
  "ČEPIŠ",
  "ČEPKA",
  "ČEPKE",
  "ČEPKI",
  "ČEPKU",
  "ČEPNA",
  "ČEPNE",
  "ČEPNI",
  "ČEPNO",
  "ČEPOM",
  "ČEPOV",
  "ČEREM",
  "ČEREN",
  "ČESAL",
  "ČESAN",
  "ČESAR",
  "ČESEN",
  "ČESNA",
  "ČESNE",
  "ČESNI",
  "ČESNU",
  "ČEŠKA",
  "ČEŠKE",
  "ČEŠKI",
  "ČEŠKO",
  "ČEŠOČ",
  "ČETAM",
  "ČETEN",
  "ČETIC",
  "ČETNA",
  "ČETNE",
  "ČETNI",
  "ČETNO",
  "ČETRT",
  "ČIBUK",
  "ČIČAJ",
  "ČIČAL",
  "ČIČAŠ",
  "ČIČEK",
  "ČIČEV",
  "ČIČKA",
  "ČIČKE",
  "ČIČKI",
  "ČIČKU",
  "ČIGAV",
  "ČIGER",
  "ČIGRA",
  "ČIGRE",
  "ČIGRI",
  "ČIGRO",
  "ČIJAM",
  "ČIKAR",
  "ČIMŽA",
  "ČIMŽE",
  "ČIMŽI",
  "ČIMŽO",
  "ČINEL",
  "ČINOM",
  "ČINOV",
  "ČIPEN",
  "ČIPKA",
  "ČIPKE",
  "ČIPKI",
  "ČIPKO",
  "ČIPNA",
  "ČIPNE",
  "ČIPNI",
  "ČIPNO",
  "ČIPOM",
  "ČIPOV",
  "ČIPSA",
  "ČIPSE",
  "ČIPSI",
  "ČIPSU",
  "ČIRAV",
  "ČIROM",
  "ČIROV",
  "ČISTA",
  "ČISTE",
  "ČISTI",
  "ČISTK",
  "ČISTO",
  "ČIŠČA",
  "ČIŠČE",
  "ČIŠČI",
  "ČIŠČO",
  "ČIŠKA",
  "ČIŠKE",
  "ČIŠKI",
  "ČIŠKO",
  "ČIVAV",
  "ČIVKA",
  "ČIVKE",
  "ČIVKI",
  "ČIVKU",
  "ČIŽEK",
  "ČIŽKA",
  "ČIŽKE",
  "ČIŽKI",
  "ČIŽKU",
  "ČLANA",
  "ČLANE",
  "ČLANI",
  "ČLANU",
  "ČLENA",
  "ČLENE",
  "ČLENI",
  "ČLENU",
  "ČMRLJ",
  "ČOBER",
  "ČOBRA",
  "ČOBRE",
  "ČOBRI",
  "ČOBRU",
  "ČOFAJ",
  "ČOFAL",
  "ČOFAM",
  "ČOFAŠ",
  "ČOFNE",
  "ČOFNI",
  "ČOHAJ",
  "ČOHAL",
  "ČOHAM",
  "ČOHAŠ",
  "ČOKAT",
  "ČOKET",
  "ČOKOM",
  "ČOKOV",
  "ČOLNA",
  "ČOLNE",
  "ČOLNI",
  "ČOLNU",
  "ČOPAR",
  "ČOPEK",
  "ČOPIČ",
  "ČOPKA",
  "ČOPKE",
  "ČOPKI",
  "ČOPKO",
  "ČOPKU",
  "ČOPOM",
  "ČOPOV",
  "ČORBA",
  "ČORBE",
  "ČORBI",
  "ČORBO",
  "ČREDA",
  "ČREDE",
  "ČREDI",
  "ČREDO",
  "ČREMS",
  "ČRETA",
  "ČRETE",
  "ČRETI",
  "ČRETU",
  "ČREVA",
  "ČREVC",
  "ČREVO",
  "ČRHNE",
  "ČRHNI",
  "ČRIČE",
  "ČRIČI",
  "ČRIKA",
  "ČRKAM",
  "ČRKIC",
  "ČRKIJ",
  "ČRKJA",
  "ČRKJE",
  "ČRKJI",
  "ČRKJU",
  "ČRKUJ",
  "ČRNAV",
  "ČRNCA",
  "ČRNCE",
  "ČRNCI",
  "ČRNCU",
  "ČRNČK",
  "ČRNEC",
  "ČRNEČ",
  "ČRNEL",
  "ČRNIC",
  "ČRNIK",
  "ČRNIL",
  "ČRNIN",
  "ČRNKA",
  "ČRNKE",
  "ČRNKI",
  "ČRNKO",
  "ČRNOB",
  "ČRNOG",
  "ČRPAJ",
  "ČRPAL",
  "ČRPAM",
  "ČRPAN",
  "ČRPAŠ",
  "ČRTAJ",
  "ČRTAL",
  "ČRTAM",
  "ČRTAN",
  "ČRTEČ",
  "ČRTEN",
  "ČRTIC",
  "ČRTKA",
  "ČRTKE",
  "ČRTKI",
  "ČRTKO",
  "ČRTNA",
  "ČRTNE",
  "ČRTNI",
  "ČRTNO",
  "ČRTUH",
  "ČRVAR",
  "ČRVIČ",
  "ČRVIN",
  "ČRVIV",
  "ČRVJA",
  "ČRVJE",
  "ČRVJI",
  "ČRVOM",
  "ČRVOV",
  "ČTIVA",
  "ČTIVI",
  "ČTIVO",
  "ČTIVU",
  "ČUDAK",
  "ČUDEČ",
  "ČUDEN",
  "ČUDES",
  "ČUDEŽ",
  "ČUDIL",
  "ČUDIM",
  "ČUDIŠ",
  "ČUDNA",
  "ČUDNE",
  "ČUDNI",
  "ČUDNO",
  "ČUDOM",
  "ČUFTA",
  "ČUFTE",
  "ČUFTI",
  "ČUFTU",
  "ČUGAM",
  "ČUJEM",
  "ČUJEŠ",
  "ČUJMO",
  "ČUJTA",
  "ČUJTE",
  "ČUJVA",
  "ČUKCA",
  "ČUKCE",
  "ČUKCI",
  "ČUKCU",
  "ČUKEC",
  "ČUKEL",
  "ČUKLA",
  "ČUKLE",
  "ČUKLI",
  "ČUKLO",
  "ČUKOM",
  "ČUKOV",
  "ČUNJA",
  "ČUNJE",
  "ČUNJI",
  "ČUNJO",
  "ČUTAR",
  "ČUTEČ",
  "ČUTEN",
  "ČUTIL",
  "ČUTNA",
  "ČUTNE",
  "ČUTNI",
  "ČUTNO",
  "ČUTOM",
  "ČUTOV",
  "ČUVAJ",
  "ČUVAL",
  "ČUVAM",
  "ČUVAN",
  "ČUVAR",
  "ČUVAŠ",
  "ČUŽKA",
  "ČUŽKE",
  "ČUŽKI",
  "ČUŽKO",
  "ČVRČE",
  "ČVRČI",
  "ČVRST",
  "ČVRŠA",
  "ČVRŠE",
  "ČVRŠI",
  "ČVRŠU",
  "DACIT",
  "DAČAM",
  "DAHIJ",
  "DAHNE",
  "DAHNI",
  "DAHOM",
  "DAHOV",
  "DAJAJ",
  "DAJAL",
  "DAJAN",
  "DAJEM",
  "DAJEŠ",
  "DAJMO",
  "DAJTA",
  "DAJTE",
  "DAJVA",
  "DAKOT",
  "DALEČ",
  "DALIJ",
  "DALJE",
  "DAMAM",
  "DAMAN",
  "DAMIC",
  "DAMIN",
  "DANEČ",
  "DANES",
  "DANJA",
  "DANJE",
  "DANJI",
  "DANKA",
  "DANKE",
  "DANKI",
  "DANKO",
  "DARIL",
  "DAROV",
  "DARUJ",
  "DAŠKA",
  "DAŠKE",
  "DAŠKI",
  "DAŠKO",
  "DATUM",
  "DAVEČ",
  "DAVEK",
  "DAVEN",
  "DAVKA",
  "DAVKE",
  "DAVKI",
  "DAVKU",
  "DAVNA",
  "DAVNE",
  "DAVNI",
  "DAVNO",
  "DAVOM",
  "DAVOV",
  "DEANN",
  "DEBAT",
  "DEBEL",
  "DEBER",
  "DEBET",
  "DEBIL",
  "DEBLA",
  "DEBLI",
  "DEBLO",
  "DEBLU",
  "DEBRI",
  "DECIL",
  "DEČEK",
  "DEČKA",
  "DEČKE",
  "DEČKI",
  "DEČKO",
  "DEČKU",
  "DEDEK",
  "DEDEN",
  "DEDIČ",
  "DEDJE",
  "DEDKA",
  "DEDKE",
  "DEDKI",
  "DEDKU",
  "DEDNA",
  "DEDNE",
  "DEDNI",
  "DEDNO",
  "DEDOM",
  "DEDOV",
  "DEDUJ",
  "DEHTE",
  "DEHTI",
  "DEIST",
  "DEJAL",
  "DEJAN",
  "DEJEM",
  "DEJEŠ",
  "DEJMO",
  "DEJTA",
  "DEJTE",
  "DEJVA",
  "DEKAD",
  "DEKAN",
  "DEKEL",
  "DEKLA",
  "DEKLE",
  "DEKLI",
  "DEKLO",
  "DEKOR",
  "DELAJ",
  "DELAL",
  "DELAM",
  "DELAN",
  "DELAŠ",
  "DELCA",
  "DELCE",
  "DELCI",
  "DELCU",
  "DELEC",
  "DELEČ",
  "DELEN",
  "DELEŽ",
  "DELIL",
  "DELIM",
  "DELIŠ",
  "DELNA",
  "DELNE",
  "DELNI",
  "DELNO",
  "DELOM",
  "DELOV",
  "DELTA",
  "DELTE",
  "DELTI",
  "DELTO",
  "DELUC",
  "DELUJ",
  "DEMAR",
  "DEMON",
  "DENAR",
  "DENGA",
  "DENGE",
  "DENGI",
  "DENGO",
  "DEPEŠ",
  "DERAČ",
  "DERAK",
  "DERBI",
  "DEREM",
  "DEREŠ",
  "DEREZ",
  "DEROČ",
  "DERUH",
  "DESAK",
  "DESEN",
  "DESKA",
  "DESKE",
  "DESKI",
  "DESKO",
  "DESNA",
  "DESNE",
  "DESNI",
  "DESNO",
  "DESTE",
  "DESUS",
  "DEŠKA",
  "DEŠKE",
  "DEŠKI",
  "DEŠKO",
  "DETEC",
  "DETEL",
  "DETET",
  "DETLA",
  "DETLE",
  "DETLI",
  "DETLU",
  "DEVAM",
  "DEVIC",
  "DEVIZ",
  "DEVON",
  "DEVOS",
  "DEŽEK",
  "DEŽEN",
  "DEŽJA",
  "DEŽJE",
  "DEŽJI",
  "DEŽJU",
  "DEŽKA",
  "DEŽKE",
  "DEŽKI",
  "DEŽKU",
  "DEŽNA",
  "DEŽNE",
  "DEŽNI",
  "DEŽNO",
  "DEŽNU",
  "DIADA",
  "DIADE",
  "DIADI",
  "DIADO",
  "DIANA",
  "DIANE",
  "DIANI",
  "DIANO",
  "DIETA",
  "DIETE",
  "DIETI",
  "DIETO",
  "DIFON",
  "DIHAJ",
  "DIHAL",
  "DIHAM",
  "DIHAŠ",
  "DIHNE",
  "DIHNI",
  "DIHOM",
  "DIHOV",
  "DIHUR",
  "DIJAK",
  "DILEM",
  "DIMEČ",
  "DIMEK",
  "DIMEN",
  "DIMER",
  "DIMIJ",
  "DIMKA",
  "DIMKE",
  "DIMKI",
  "DIMKU",
  "DIMNA",
  "DIMNE",
  "DIMNI",
  "DIMNO",
  "DIMOM",
  "DIMOV",
  "DINAR",
  "DINGA",
  "DINGE",
  "DINGI",
  "DINGO",
  "DINGU",
  "DINJA",
  "DINJE",
  "DINJI",
  "DINJO",
  "DIODA",
  "DIODE",
  "DIODI",
  "DIODO",
  "DIPEL",
  "DIPLE",
  "DIPOL",
  "DIRAM",
  "DIRJA",
  "DIRKA",
  "DIRKE",
  "DIRKI",
  "DIRKO",
  "DISKA",
  "DISKE",
  "DISKI",
  "DISKU",
  "DIŠAL",
  "DIŠAV",
  "DIŠEČ",
  "DIŠIM",
  "DIŠIŠ",
  "DIVAM",
  "DIVAN",
  "DIVIN",
  "DIVJA",
  "DIVJE",
  "DIVJI",
  "DLAKA",
  "DLAKE",
  "DLAKI",
  "DLAKO",
  "DLANI",
  "DLESK",
  "DLETA",
  "DLETC",
  "DLETI",
  "DLETO",
  "DLETU",
  "DLIBA",
  "DLIBU",
  "DNEMA",
  "DNEVA",
  "DNEVE",
  "DNEVI",
  "DNEVU",
  "DNINA",
  "DNINE",
  "DNINI",
  "DNINO",
  "DNIŠČ",
  "DNOMA",
  "DOBAM",
  "DOBAV",
  "DOBER",
  "DOBIL",
  "DOBIM",
  "DOBIŠ",
  "DOBIT",
  "DOBOM",
  "DOBOV",
  "DOBRA",
  "DOBRE",
  "DOBRI",
  "DOBRO",
  "DODAJ",
  "DODAL",
  "DODAM",
  "DODAN",
  "DODAŠ",
  "DODOV",
  "DOGAM",
  "DOGAR",
  "DOGEM",
  "DOGIN",
  "DOGMA",
  "DOGME",
  "DOGMI",
  "DOGMO",
  "DOGON",
  "DOHOD",
  "DOJEČ",
  "DOJEJ",
  "DOJEL",
  "DOJEM",
  "DOJEN",
  "DOJEŠ",
  "DOJET",
  "DOJIL",
  "DOJIM",
  "DOJIŠ",
  "DOJKA",
  "DOJKE",
  "DOJKI",
  "DOJKO",
  "DOKAZ",
  "DOKOM",
  "DOKOV",
  "DOKUJ",
  "DOKUP",
  "DOLAR",
  "DOLBI",
  "DOLEK",
  "DOLET",
  "DOLGA",
  "DOLGE",
  "DOLGI",
  "DOLGO",
  "DOLGU",
  "DOLIJ",
  "DOLIL",
  "DOLIN",
  "DOLIT",
  "DOLIV",
  "DOLKA",
  "DOLKE",
  "DOLKI",
  "DOLKU",
  "DOLOM",
  "DOLOV",
  "DOLŽE",
  "DOLŽI",
  "DOMAČ",
  "DOMCA",
  "DOMCE",
  "DOMCI",
  "DOMCU",
  "DOMEC",
  "DOMEK",
  "DOMEN",
  "DOMET",
  "DOMIK",
  "DOMIN",
  "DOMKA",
  "DOMKE",
  "DOMKI",
  "DOMKU",
  "DOMOV",
  "DOMUJ",
  "DONAM",
  "DONEČ",
  "DONEL",
  "DONIM",
  "DONIN",
  "DONIŠ",
  "DONOM",
  "DONOR",
  "DONOS",
  "DONOV",
  "DOPIK",
  "DOPIS",
  "DORKA",
  "DORKE",
  "DORKI",
  "DORKO",
  "DOSEG",
  "DOSEJ",
  "DOSJE",
  "DOSPE",
  "DOSPI",
  "DOSTI",
  "DOSUJ",
  "DOSUL",
  "DOSUT",
  "DOŠAM",
  "DOŠIJ",
  "DOŠIL",
  "DOŠIT",
  "DOŠKA",
  "DOŠKE",
  "DOŠKI",
  "DOŠKO",
  "DOTAM",
  "DOTIC",
  "DOTIK",
  "DOTIS",
  "DOTOK",
  "DOUME",
  "DOUMI",
  "DOVOD",
  "DOVOZ",
  "DOZAM",
  "DOŽEM",
  "DOŽEN",
  "DOŽEV",
  "DOŽIC",
  "DOŽNA",
  "DOŽNE",
  "DOŽNI",
  "DOŽNO",
  "DRAGA",
  "DRAGE",
  "DRAGI",
  "DRAGO",
  "DRAMA",
  "DRAME",
  "DRAMI",
  "DRAMO",
  "DRAPE",
  "DRAST",
  "DRAŽB",
  "DRAŽE",
  "DRAŽI",
  "DRČAL",
  "DRČAM",
  "DRČAV",
  "DRČEČ",
  "DRČIM",
  "DRČIŠ",
  "DRDRA",
  "DREČK",
  "DREDA",
  "DREDE",
  "DREDI",
  "DREDU",
  "DREGA",
  "DREJO",
  "DREMA",
  "DREMO",
  "DRENA",
  "DRENE",
  "DRENI",
  "DRENU",
  "DRESA",
  "DRESE",
  "DRESI",
  "DRESU",
  "DRETA",
  "DRETE",
  "DRETI",
  "DRETO",
  "DREVA",
  "DREVC",
  "DREVI",
  "DREVO",
  "DREZA",
  "DRGAL",
  "DRGET",
  "DRGNE",
  "DRGNI",
  "DRHAL",
  "DRHTE",
  "DRHTI",
  "DRIAD",
  "DRIČA",
  "DRIČE",
  "DRIČI",
  "DRIČO",
  "DRILA",
  "DRILE",
  "DRILI",
  "DRILU",
  "DRIMO",
  "DRISK",
  "DRITA",
  "DRITE",
  "DRIVA",
  "DRNCA",
  "DRNCE",
  "DRNCI",
  "DRNCU",
  "DRNEC",
  "DRNIC",
  "DRNIČ",
  "DROBA",
  "DROBE",
  "DROBI",
  "DROBU",
  "DROGA",
  "DROGE",
  "DROGI",
  "DROGO",
  "DROGU",
  "DRONG",
  "DRONT",
  "DROZG",
  "DROŽE",
  "DROŽI",
  "DROŽK",
  "DRSAJ",
  "DRSAL",
  "DRSAM",
  "DRSAŠ",
  "DRSEČ",
  "DRSEL",
  "DRSEN",
  "DRSIM",
  "DRSIN",
  "DRSIŠ",
  "DRSKA",
  "DRSKE",
  "DRSKI",
  "DRSKO",
  "DRSKU",
  "DRSNA",
  "DRSNE",
  "DRSNI",
  "DRSNO",
  "DRSTI",
  "DRŠKA",
  "DRŠKE",
  "DRŠKI",
  "DRŠKO",
  "DRTIJ",
  "DRTJA",
  "DRTJE",
  "DRTJI",
  "DRTJU",
  "DRUGA",
  "DRUGE",
  "DRUGI",
  "DRUGO",
  "DRUID",
  "DRUZA",
  "DRUZE",
  "DRUZI",
  "DRUZO",
  "DRUŽB",
  "DRUŽI",
  "DRVAČ",
  "DRVAR",
  "DRVCA",
  "DRVEČ",
  "DRVEL",
  "DRVIM",
  "DRVIŠ",
  "DRVOM",
  "DRZAJ",
  "DRZAL",
  "DRZAM",
  "DRZAN",
  "DRZAŠ",
  "DRZEN",
  "DRZNA",
  "DRZNE",
  "DRZNI",
  "DRZNO",
  "DRŽAČ",
  "DRŽAJ",
  "DRŽAL",
  "DRŽAM",
  "DRŽAV",
  "DRŽEČ",
  "DRŽEK",
  "DRŽIM",
  "DRŽIŠ",
  "DRŽKA",
  "DRŽKE",
  "DRŽKI",
  "DRŽKO",
  "DRŽKU",
  "DUALA",
  "DUALE",
  "DUALI",
  "DUCAT",
  "DUDAJ",
  "DUDAL",
  "DUDAM",
  "DUDAN",
  "DUDAR",
  "DUDAŠ",
  "DUDIC",
  "DUDKA",
  "DUDKE",
  "DUDKI",
  "DUDKO",
  "DUETA",
  "DUETE",
  "DUETI",
  "DUETU",
  "DUHAJ",
  "DUHAL",
  "DUHAM",
  "DUHAŠ",
  "DUHCA",
  "DUHCE",
  "DUHCI",
  "DUHCU",
  "DUHEC",
  "DUHEK",
  "DUHKA",
  "DUHKE",
  "DUHKI",
  "DUHKU",
  "DUHOV",
  "DUKAT",
  "DULAM",
  "DULAR",
  "DULCA",
  "DULCE",
  "DULCI",
  "DULCU",
  "DULEC",
  "DULIN",
  "DUMAM",
  "DUNIT",
  "DUOLA",
  "DUOLE",
  "DUOLI",
  "DUOLO",
  "DUOMA",
  "DUPAT",
  "DURCA",
  "DURCE",
  "DURJA",
  "DURJE",
  "DURJI",
  "DURJO",
  "DURJU",
  "DUROM",
  "DUROV",
  "DURUM",
  "DUŠAM",
  "DUŠEČ",
  "DUŠIC",
  "DUŠIK",
  "DVERC",
  "DVERI",
  "DVIGA",
  "DVIGE",
  "DVIGI",
  "DVIGU",
  "DVOJA",
  "DVOJI",
  "DVOJK",
  "DVOMA",
  "DVOME",
  "DVOMI",
  "DVOMU",
  "DVOOK",
  "DVORA",
  "DVORE",
  "DVORI",
  "DVORU",
  "DZETA",
  "DZETE",
  "DZETI",
  "DZETO",
  "DŽEMA",
  "DŽEME",
  "DŽEMI",
  "DŽEMU",
  "DŽEZA",
  "DŽEZU",
  "DŽINA",
  "DŽINE",
  "DŽINI",
  "DŽINS",
  "DŽINU",
  "DŽIPA",
  "DŽIPE",
  "DŽIPI",
  "DŽIPU",
  "DŽULA",
  "DŽULE",
  "DŽULI",
  "DŽULU",
  "DŽUNK",
  "EARLA",
  "EARLE",
  "EARLI",
  "EARLU",
  "EBOLA",
  "EBOLE",
  "EBOLI",
  "EBOLO",
  "EDEMA",
  "EDEME",
  "EDEMI",
  "EDEMU",
  "EDIKT",
  "EDILA",
  "EDILE",
  "EDILI",
  "EDILU",
  "EDINA",
  "EDINE",
  "EDINI",
  "EDINK",
  "EDINO",
  "EFIRA",
  "EFIRE",
  "EFIRI",
  "EFIRO",
  "EFRIN",
  "EKAVK",
  "EKCEM",
  "EKIPA",
  "EKIPE",
  "EKIPI",
  "EKIPO",
  "EKLOG",
  "EKRAN",
  "EKSON",
  "EKSOT",
  "EKTIM",
  "EKUJA",
  "EKUJE",
  "EKUJI",
  "EKUJU",
  "ELANA",
  "ELAND",
  "ELANE",
  "ELANI",
  "ELANU",
  "ELEAT",
  "ELFOM",
  "ELFOV",
  "ELINA",
  "ELINE",
  "ELINI",
  "ELINO",
  "ELIPS",
  "ELISA",
  "ELISE",
  "ELISI",
  "ELISO",
  "ELITA",
  "ELITE",
  "ELITI",
  "ELITO",
  "ELKAJ",
  "ELKAL",
  "ELKAM",
  "ELKAŠ",
  "EMAJL",
  "EMPAT",
  "EMPIR",
  "EMPOR",
  "EMŠEM",
  "EMŠEV",
  "EMUJA",
  "EMUJE",
  "EMUJI",
  "EMUJU",
  "ENAČB",
  "ENAČI",
  "ENAKA",
  "ENAKE",
  "ENAKI",
  "ENAKO",
  "ENCIM",
  "ENGOB",
  "ENICA",
  "ENICE",
  "ENICI",
  "ENICO",
  "ENKAM",
  "ENOJA",
  "ENOJE",
  "ENOJI",
  "ENOJK",
  "ENOKA",
  "ENOKE",
  "ENOKI",
  "ENOKU",
  "ENOLA",
  "ENOLE",
  "ENOLI",
  "ENOLU",
  "ENOOK",
  "ENOST",
  "ENOTA",
  "ENOTE",
  "ENOTI",
  "ENOTO",
  "ENTAZ",
  "EOCEN",
  "EOZIN",
  "EPAKT",
  "EPARH",
  "EPIKA",
  "EPIKE",
  "EPIKI",
  "EPIKO",
  "EPIKU",
  "EPOHA",
  "EPOHE",
  "EPOHI",
  "EPOHO",
  "EPOMA",
  "EPONA",
  "EPONU",
  "EPSKA",
  "EPSKE",
  "EPSKI",
  "EPSKO",
  "ERBIJ",
  "ERGOM",
  "ERGOV",
  "EROTA",
  "EROTE",
  "EROTI",
  "EROTU",
  "ERRAT",
  "ESAST",
  "ESAUL",
  "ESCIN",
  "ESEJA",
  "ESEJE",
  "ESEJI",
  "ESEJU",
  "ESENA",
  "ESENC",
  "ESENE",
  "ESENI",
  "ESENK",
  "ESENU",
  "ESERK",
  "ESHAR",
  "ESPAD",
  "ESTER",
  "ESTET",
  "ESTRA",
  "ESTRE",
  "ESTRI",
  "ESTRU",
  "ETAMA",
  "ETANA",
  "ETANU",
  "ETAPA",
  "ETAPE",
  "ETAPI",
  "ETAPO",
  "ETATA",
  "ETATE",
  "ETATI",
  "ETATU",
  "ETAŽA",
  "ETAŽE",
  "ETAŽI",
  "ETAŽO",
  "ETENA",
  "ETENU",
  "ETHER",
  "ETIKA",
  "ETIKE",
  "ETIKI",
  "ETIKO",
  "ETIKU",
  "ETILA",
  "ETILE",
  "ETILI",
  "ETILU",
  "ETINA",
  "ETINU",
  "ETNIJ",
  "ETOSA",
  "ETOSE",
  "ETOSI",
  "ETOSU",
  "ETROM",
  "ETROV",
  "ETRUS",
  "ETUDA",
  "ETUDE",
  "ETUDI",
  "ETUDO",
  "EVNUH",
  "EVROM",
  "EVROV",
  "EZULA",
  "EZULE",
  "EZULI",
  "EZULK",
  "EZULU",
  "FABEL",
  "FABUL",
  "FAČAM",
  "FAGOT",
  "FAKIN",
  "FAKIR",
  "FAKSA",
  "FAKSE",
  "FAKSI",
  "FAKSU",
  "FALIN",
  "FALUS",
  "FANAL",
  "FANTA",
  "FANTE",
  "FANTI",
  "FANTU",
  "FARAD",
  "FARAM",
  "FARAN",
  "FAREN",
  "FARJA",
  "FARJE",
  "FARJI",
  "FARJU",
  "FARMA",
  "FARME",
  "FARMI",
  "FARMO",
  "FARNA",
  "FARNE",
  "FARNI",
  "FARNO",
  "FARSA",
  "FARSE",
  "FARSI",
  "FARSO",
  "FASAD",
  "FASET",
  "FAŠIN",
  "FATEV",
  "FATVA",
  "FATVE",
  "FATVI",
  "FATVO",
  "FAVEL",
  "FAVNI",
  "FAVNU",
  "FAZAM",
  "FAZAN",
  "FAZEN",
  "FAZNA",
  "FAZNE",
  "FAZNI",
  "FAZNO",
  "FEJOJ",
  "FELAH",
  "FELUK",
  "FENAJ",
  "FENAL",
  "FENAM",
  "FENAŠ",
  "FENEK",
  "FENIL",
  "FENKA",
  "FENKE",
  "FENKI",
  "FENKU",
  "FENOL",
  "FERIT",
  "FESOM",
  "FESOV",
  "FETAM",
  "FETIŠ",
  "FETUS",
  "FEVDA",
  "FEVDE",
  "FEVDI",
  "FEVDU",
  "FIALA",
  "FIALE",
  "FIALI",
  "FIALO",
  "FIATA",
  "FIATE",
  "FIATI",
  "FIATU",
  "FIBUL",
  "FICEK",
  "FICKA",
  "FICKE",
  "FICKI",
  "FICKU",
  "FIEST",
  "FIGAM",
  "FIGIC",
  "FIGOV",
  "FIGUR",
  "FIJEM",
  "FIJEV",
  "FIKUS",
  "FILCA",
  "FILCE",
  "FILCI",
  "FILCU",
  "FILET",
  "FILIT",
  "FILMA",
  "FILME",
  "FILMI",
  "FILMU",
  "FIMOZ",
  "FINES",
  "FINIŠ",
  "FIRMA",
  "FIRME",
  "FIRMI",
  "FIRMO",
  "FIRNA",
  "FIRNE",
  "FIRNI",
  "FIRNU",
  "FISUR",
  "FITIN",
  "FITOL",
  "FIZIK",
  "FIŽOL",
  "FJELA",
  "FJELE",
  "FJELI",
  "FJELU",
  "FJORD",
  "FLAVT",
  "FLIŠA",
  "FLIŠU",
  "FLOKA",
  "FLOKE",
  "FLOKI",
  "FLOKU",
  "FLORE",
  "FLOTA",
  "FLOTE",
  "FLOTI",
  "FLOTO",
  "FLUAT",
  "FLUOR",
  "FOAJE",
  "FOBIJ",
  "FOBIK",
  "FOJBA",
  "FOJBE",
  "FOJBI",
  "FOJBO",
  "FOKUS",
  "FOLAT",
  "FOLEN",
  "FOLIA",
  "FOLIE",
  "FOLII",
  "FOLIJ",
  "FOLIO",
  "FOLIU",
  "FOLNA",
  "FOLNE",
  "FOLNI",
  "FOLNO",
  "FONDI",
  "FONEM",
  "FONOM",
  "FONON",
  "FONOV",
  "FORMA",
  "FORME",
  "FORMI",
  "FORMO",
  "FORTE",
  "FORUM",
  "FOSAM",
  "FOSIL",
  "FOSIN",
  "FOŠKA",
  "FOŠKE",
  "FOŠKI",
  "FOŠKO",
  "FOTON",
  "FRAČA",
  "FRAČE",
  "FRAČI",
  "FRAČO",
  "FRAKA",
  "FRAKE",
  "FRAKI",
  "FRAKU",
  "FRANA",
  "FRANK",
  "FRAPE",
  "FRATA",
  "FRATE",
  "FRATI",
  "FRATO",
  "FRAZA",
  "FRAZE",
  "FRAZI",
  "FRAZO",
  "FRCAJ",
  "FRCNI",
  "FRČAD",
  "FRČAL",
  "FRČEČ",
  "FRČIM",
  "FRČIŠ",
  "FREON",
  "FRESK",
  "FRFER",
  "FRFOT",
  "FRFRA",
  "FRFRE",
  "FRFRI",
  "FRFRO",
  "FRIKA",
  "FRIKE",
  "FRIKI",
  "FRIKO",
  "FRIKU",
  "FRIZA",
  "FRIZE",
  "FRIZI",
  "FRIZU",
  "FRLEČ",
  "FRLEL",
  "FRLIM",
  "FRLIŠ",
  "FROND",
  "FRONT",
  "FRULA",
  "FRULE",
  "FRULI",
  "FRULO",
  "FUGAM",
  "FUGAS",
  "FUKOZ",
  "FUNKA",
  "FUNKU",
  "FUNTA",
  "FUNTE",
  "FUNTI",
  "FUNTU",
  "FURAN",
  "FURIJ",
  "FURIN",
  "FUTON",
  "FUZIJ",
  "FUŽEM",
  "FUŽEV",
  "FUŽIN",
  "GABER",
  "GABEZ",
  "GABRA",
  "GABRE",
  "GABRI",
  "GABRO",
  "GABRU",
  "GAČAM",
  "GAČEČ",
  "GAČIL",
  "GAČIM",
  "GAČIŠ",
  "GADJA",
  "GADJE",
  "GADJI",
  "GADOM",
  "GADOV",
  "GAGAJ",
  "GAGAL",
  "GAGAM",
  "GAGAŠ",
  "GAGIN",
  "GAGNE",
  "GAGNI",
  "GAJBA",
  "GAJBE",
  "GAJBI",
  "GAJBO",
  "GAJEM",
  "GAJET",
  "GAJEV",
  "GAJIČ",
  "GALEB",
  "GALEJ",
  "GALEN",
  "GALER",
  "GALIC",
  "GALIJ",
  "GALNA",
  "GALNE",
  "GALNI",
  "GALNO",
  "GALON",
  "GALOP",
  "GALOŠ",
  "GALUN",
  "GAMAM",
  "GAMAŠ",
  "GAMET",
  "GAMSA",
  "GAMSE",
  "GAMSI",
  "GAMSU",
  "GARAČ",
  "GARAJ",
  "GARAL",
  "GARAM",
  "GARAŠ",
  "GARAŽ",
  "GARDA",
  "GARDE",
  "GARDI",
  "GARDO",
  "GARIC",
  "GARIJ",
  "GARJE",
  "GAROT",
  "GASEČ",
  "GASIL",
  "GASIM",
  "GASIŠ",
  "GAŠEN",
  "GAVČA",
  "GAVČE",
  "GAVČI",
  "GAVČO",
  "GAVČU",
  "GAVDA",
  "GAVDE",
  "GAVDI",
  "GAVDO",
  "GAVUN",
  "GAZEČ",
  "GAZEL",
  "GAZEM",
  "GAZEN",
  "GAZIL",
  "GAZIM",
  "GAZIŠ",
  "GAZNA",
  "GAZNE",
  "GAZNI",
  "GAZNO",
  "GAŽEN",
  "GEJEM",
  "GEJEV",
  "GEJŠA",
  "GEJŠE",
  "GEJŠI",
  "GEJŠO",
  "GEKON",
  "GELOM",
  "GELOV",
  "GELOZ",
  "GEMUL",
  "GENEZ",
  "GENIJ",
  "GENOM",
  "GENOV",
  "GEODA",
  "GEODE",
  "GEODI",
  "GEODO",
  "GEOID",
  "GESEL",
  "GESLA",
  "GESLI",
  "GESLO",
  "GESLU",
  "GESTA",
  "GESTE",
  "GESTI",
  "GESTO",
  "GETOM",
  "GETOV",
  "GIBAL",
  "GIBEK",
  "GIBEN",
  "GIBIT",
  "GIBKA",
  "GIBKE",
  "GIBKI",
  "GIBKO",
  "GIBNA",
  "GIBNE",
  "GIBNI",
  "GIBNO",
  "GIBOM",
  "GIBON",
  "GIBOV",
  "GIBUL",
  "GIGAM",
  "GIGOM",
  "GIGOV",
  "GINKA",
  "GINKE",
  "GINKI",
  "GINKO",
  "GINKU",
  "GIRIC",
  "GISOM",
  "GISOV",
  "GIŠKA",
  "GIŠKE",
  "GIŠKI",
  "GIŠKO",
  "GLADA",
  "GLADE",
  "GLADI",
  "GLADU",
  "GLANS",
  "GLASA",
  "GLASB",
  "GLASE",
  "GLASI",
  "GLASU",
  "GLAVA",
  "GLAVE",
  "GLAVI",
  "GLAVO",
  "GLEDA",
  "GLEDE",
  "GLEDI",
  "GLENA",
  "GLENE",
  "GLENI",
  "GLENU",
  "GLIFA",
  "GLIFE",
  "GLIFI",
  "GLIFU",
  "GLIJA",
  "GLIJE",
  "GLIJI",
  "GLIJO",
  "GLINA",
  "GLINE",
  "GLINI",
  "GLINO",
  "GLIOM",
  "GLIOZ",
  "GLIST",
  "GLIVA",
  "GLIVE",
  "GLIVI",
  "GLIVO",
  "GLOBA",
  "GLOBE",
  "GLOBI",
  "GLOBO",
  "GLOGA",
  "GLOGU",
  "GLOJI",
  "GLOSA",
  "GLOSE",
  "GLOSO",
  "GLOTA",
  "GLOTE",
  "GLOTI",
  "GLOTO",
  "GLUHA",
  "GLUHE",
  "GLUHI",
  "GLUHO",
  "GLUON",
  "GLUŠE",
  "GLUŠI",
  "GMOTA",
  "GMOTE",
  "GMOTI",
  "GMOTO",
  "GNAJS",
  "GNANA",
  "GNANE",
  "GNANI",
  "GNANJ",
  "GNANO",
  "GNEČA",
  "GNEČE",
  "GNEČI",
  "GNEČO",
  "GNETI",
  "GNEVA",
  "GNEVU",
  "GNEZD",
  "GNIDA",
  "GNIDE",
  "GNIDI",
  "GNIDO",
  "GNIJE",
  "GNIJO",
  "GNILA",
  "GNILE",
  "GNILI",
  "GNILO",
  "GNITI",
  "GNJAT",
  "GNOJA",
  "GNOJE",
  "GNOJI",
  "GNOJU",
  "GNOZA",
  "GNOZE",
  "GNOZI",
  "GNOZO",
  "GNUJA",
  "GNUJE",
  "GNUJI",
  "GNUJU",
  "GNUSA",
  "GNUSE",
  "GNUSI",
  "GNUSU",
  "GOAMA",
  "GOBAM",
  "GOBAN",
  "GOBAR",
  "GOBAV",
  "GOBCA",
  "GOBCE",
  "GOBCI",
  "GOBCU",
  "GOBEC",
  "GOBIC",
  "GOBJA",
  "GOBJE",
  "GOBJI",
  "GOBOV",
  "GODAL",
  "GODAN",
  "GODBA",
  "GODBE",
  "GODBI",
  "GODBO",
  "GODCA",
  "GODCE",
  "GODCI",
  "GODCU",
  "GODEC",
  "GODEČ",
  "GODEL",
  "GODEM",
  "GODEN",
  "GODEŠ",
  "GODLA",
  "GODLE",
  "GODLI",
  "GODLO",
  "GODNA",
  "GODNE",
  "GODNI",
  "GODNO",
  "GODOV",
  "GODUJ",
  "GOFER",
  "GOFOM",
  "GOFOV",
  "GOINA",
  "GOINE",
  "GOINI",
  "GOINO",
  "GOJEČ",
  "GOJEN",
  "GOJIV",
  "GOLAČ",
  "GOLAŽ",
  "GOLCA",
  "GOLCE",
  "GOLCI",
  "GOLCU",
  "GOLČE",
  "GOLČI",
  "GOLEC",
  "GOLEČ",
  "GOLEM",
  "GOLEN",
  "GOLFA",
  "GOLFU",
  "GOLIC",
  "GOLIČ",
  "GOLID",
  "GOLIL",
  "GOLIM",
  "GOLIN",
  "GOLIŠ",
  "GOLOB",
  "GOLOM",
  "GOLOT",
  "GOLOV",
  "GOLŠA",
  "GOLŠE",
  "GOLŠI",
  "GOLŠO",
  "GOLTA",
  "GOLTE",
  "GOLTI",
  "GOLTU",
  "GOMIL",
  "GONEČ",
  "GONGA",
  "GONGE",
  "GONGI",
  "GONGU",
  "GONIČ",
  "GONIL",
  "GONIM",
  "GONIŠ",
  "GONJA",
  "GONJE",
  "GONJI",
  "GONJO",
  "GONOM",
  "GONOV",
  "GORAL",
  "GORAM",
  "GORAT",
  "GOREČ",
  "GOREL",
  "GORIC",
  "GORIJ",
  "GORIL",
  "GORIM",
  "GORIŠ",
  "GORIV",
  "GORJA",
  "GORJE",
  "GORJI",
  "GORJU",
  "GOSAK",
  "GOSEM",
  "GOSIN",
  "GOSJA",
  "GOSJE",
  "GOSJI",
  "GOSKA",
  "GOSKE",
  "GOSKI",
  "GOSKO",
  "GOSLI",
  "GOSPA",
  "GOSPE",
  "GOSPO",
  "GOSTA",
  "GOSTE",
  "GOSTI",
  "GOSTO",
  "GOSTU",
  "GOŠČA",
  "GOŠČE",
  "GOŠČI",
  "GOŠČO",
  "GOŠKA",
  "GOŠKE",
  "GOŠKI",
  "GOŠKO",
  "GOVED",
  "GOVNA",
  "GOVNO",
  "GOVNU",
  "GOVOR",
  "GOZDA",
  "GOZDE",
  "GOZDI",
  "GOZDU",
  "GOŽEM",
  "GOŽEV",
  "GOŽJA",
  "GOŽJE",
  "GOŽJI",
  "GRABI",
  "GRADA",
  "GRADB",
  "GRADE",
  "GRADI",
  "GRADU",
  "GRAFA",
  "GRAFE",
  "GRAFI",
  "GRAFU",
  "GRAHA",
  "GRAHU",
  "GRAJA",
  "GRAJE",
  "GRAJI",
  "GRAJO",
  "GRALA",
  "GRALE",
  "GRALI",
  "GRALU",
  "GRAMA",
  "GRAME",
  "GRAMI",
  "GRAMU",
  "GRANA",
  "GRANE",
  "GRANI",
  "GRANU",
  "GRAPA",
  "GRAPE",
  "GRAPI",
  "GRAPO",
  "GRBAČ",
  "GRBAM",
  "GRBAN",
  "GRBAV",
  "GRBCA",
  "GRBCE",
  "GRBCI",
  "GRBCU",
  "GRBEC",
  "GRBEČ",
  "GRBEN",
  "GRBEŽ",
  "GRBIC",
  "GRBIL",
  "GRBIM",
  "GRBIN",
  "GRBIŠ",
  "GRBNA",
  "GRBNE",
  "GRBNI",
  "GRBNO",
  "GRBOM",
  "GRBOV",
  "GRČAL",
  "GRČAM",
  "GRČAV",
  "GRČEČ",
  "GRČIC",
  "GRČIM",
  "GRČIŠ",
  "GRDEČ",
  "GRDEN",
  "GRDIL",
  "GRDIM",
  "GRDIŠ",
  "GRDOB",
  "GRDOT",
  "GREBA",
  "GREBE",
  "GREBI",
  "GREDA",
  "GREDE",
  "GREDI",
  "GREDO",
  "GREHA",
  "GREHE",
  "GREHI",
  "GREHU",
  "GREJA",
  "GREJE",
  "GREJI",
  "GREJO",
  "GREJU",
  "GRELA",
  "GRELI",
  "GRELK",
  "GRELO",
  "GRELU",
  "GREMO",
  "GRENE",
  "GREŠE",
  "GREŠI",
  "GRETA",
  "GRETE",
  "GRETI",
  "GRETO",
  "GREVA",
  "GREŽA",
  "GREŽU",
  "GRGRA",
  "GRIČA",
  "GRIČE",
  "GRIČI",
  "GRIČU",
  "GRIFA",
  "GRIFE",
  "GRIFI",
  "GRIFU",
  "GRINT",
  "GRIOT",
  "GRIPA",
  "GRIPE",
  "GRIPI",
  "GRIPO",
  "GRIVA",
  "GRIVE",
  "GRIVI",
  "GRIVN",
  "GRIVO",
  "GRIZA",
  "GRIZE",
  "GRIZI",
  "GRIZU",
  "GRIŽA",
  "GRIŽE",
  "GRIŽI",
  "GRIŽO",
  "GRKNE",
  "GRKNI",
  "GRLAT",
  "GRLCA",
  "GRLCE",
  "GRLCI",
  "GRLCU",
  "GRLEC",
  "GRLEČ",
  "GRLEL",
  "GRLEN",
  "GRLIC",
  "GRLIM",
  "GRLIŠ",
  "GRLNA",
  "GRLNE",
  "GRLNI",
  "GRLNO",
  "GRLOM",
  "GRMAD",
  "GRMAT",
  "GRMEČ",
  "GRMEL",
  "GRMIČ",
  "GRMIM",
  "GRMIŠ",
  "GRMOM",
  "GRMOV",
  "GRMUŠ",
  "GROBA",
  "GROBE",
  "GROBI",
  "GROBO",
  "GROBU",
  "GROFA",
  "GROFE",
  "GROFI",
  "GROFU",
  "GROGA",
  "GROGE",
  "GROGI",
  "GROGU",
  "GROMA",
  "GROME",
  "GROMI",
  "GROMU",
  "GROŠA",
  "GROŠE",
  "GROŠI",
  "GROŠU",
  "GROTA",
  "GROTE",
  "GROTI",
  "GROTU",
  "GROZA",
  "GROZD",
  "GROZE",
  "GROZI",
  "GROZO",
  "GRŠAJ",
  "GRŠAL",
  "GRŠAM",
  "GRŠAŠ",
  "GRŠKA",
  "GRŠKE",
  "GRŠKI",
  "GRŠKO",
  "GRUČA",
  "GRUČE",
  "GRUČI",
  "GRUČO",
  "GRUDA",
  "GRUDE",
  "GRUDI",
  "GRUDO",
  "GRUJA",
  "GRUJE",
  "GRUJI",
  "GRUJU",
  "GRULI",
  "GRUND",
  "GRUŠČ",
  "GTTEH",
  "GUBAJ",
  "GUBAM",
  "GUBAN",
  "GUBAV",
  "GUBEN",
  "GUBIC",
  "GUBNA",
  "GUBNE",
  "GUBNI",
  "GUBNO",
  "GUGAJ",
  "GUGAV",
  "GULAG",
  "GULEČ",
  "GULEŽ",
  "GUMAM",
  "GUMAR",
  "GUMBA",
  "GUMBE",
  "GUMBI",
  "GUMBU",
  "GUMEN",
  "GUMIC",
  "GUMNA",
  "GUMNI",
  "GUMNO",
  "GUMNU",
  "GUSAR",
  "GUSEL",
  "GUSLE",
  "GUZNE",
  "GUZNI",
  "GVANA",
  "GVANO",
  "GVANU",
  "GVAŠA",
  "GVAŠE",
  "GVAŠI",
  "GVAŠU",
  "GVELF",
  "HABEČ",
  "HABIL",
  "HABIM",
  "HABIŠ",
  "HABIT",
  "HADŽA",
  "HADŽE",
  "HADŽI",
  "HADŽU",
  "HAFIZ",
  "HAIKA",
  "HAIKE",
  "HAIKI",
  "HAIKU",
  "HAJKA",
  "HAJKE",
  "HAJKI",
  "HAJKO",
  "HALAL",
  "HALIT",
  "HALJA",
  "HALJE",
  "HALJI",
  "HALJO",
  "HALMA",
  "HALME",
  "HALMI",
  "HALMO",
  "HALON",
  "HALUG",
  "HALVA",
  "HALVE",
  "HALVI",
  "HALVO",
  "HAMAD",
  "HAMAM",
  "HAMIT",
  "HANIJ",
  "HARAČ",
  "HAREM",
  "HARFA",
  "HARFE",
  "HARFI",
  "HARFO",
  "HARZA",
  "HARZE",
  "HARZI",
  "HARZO",
  "HASKI",
  "HAŠIŠ",
  "HBINT",
  "HČERE",
  "HČERI",
  "HČERK",
  "HEHET",
  "HEKER",
  "HELAN",
  "HELIJ",
  "HELOT",
  "HEMIN",
  "HEMOZ",
  "HENRI",
  "HERCA",
  "HERCE",
  "HERCI",
  "HERCU",
  "HERMA",
  "HERME",
  "HERMI",
  "HERMO",
  "HESOM",
  "HESOV",
  "HETER",
  "HIATA",
  "HIATE",
  "HIATI",
  "HIATU",
  "HIBAM",
  "HIBAV",
  "HIBEN",
  "HIBIC",
  "HIBNA",
  "HIBNE",
  "HIBNI",
  "HIBNO",
  "HIDER",
  "HIDRA",
  "HIDRE",
  "HIDRI",
  "HIDRO",
  "HIFAM",
  "HIFEM",
  "HIHOT",
  "HIJEM",
  "HIJEN",
  "HIJEV",
  "HILUS",
  "HIMER",
  "HIMNA",
  "HIMNE",
  "HIMNI",
  "HIMNO",
  "HINDI",
  "HINIJ",
  "HIPCA",
  "HIPCE",
  "HIPCI",
  "HIPCU",
  "HIPEC",
  "HIPEN",
  "HIPNA",
  "HIPNE",
  "HIPNI",
  "HIPNO",
  "HIPOM",
  "HIPOV",
  "HIPUS",
  "HIRAJ",
  "HIRAL",
  "HIRAM",
  "HIRAŠ",
  "HIRAV",
  "HIŠAM",
  "HIŠEN",
  "HIŠIC",
  "HIŠKA",
  "HIŠKE",
  "HIŠKI",
  "HIŠKO",
  "HIŠNI",
  "HIŠNO",
  "HIŠUR",
  "HITEČ",
  "HITEL",
  "HITER",
  "HITIM",
  "HITIN",
  "HITIŠ",
  "HITOM",
  "HITON",
  "HITOV",
  "HITRA",
  "HITRE",
  "HITRI",
  "HITRO",
  "HLAČA",
  "HLAČE",
  "HLAČK",
  "HLADA",
  "HLADE",
  "HLADI",
  "HLADU",
  "HLAPA",
  "HLAPE",
  "HLAPI",
  "HLAPU",
  "HLEBA",
  "HLEBE",
  "HLEBI",
  "HLEPE",
  "HLEPI",
  "HLEVA",
  "HLEVE",
  "HLEVI",
  "HLEVU",
  "HLINI",
  "HLIPA",
  "HLODA",
  "HLODE",
  "HLODI",
  "HLODU",
  "HMELJ",
  "HOANA",
  "HOANE",
  "HOANI",
  "HOANO",
  "HOBIT",
  "HOČEM",
  "HOČEŠ",
  "HODCA",
  "HODCE",
  "HODCI",
  "HODCU",
  "HODEC",
  "HODEČ",
  "HODEN",
  "HODIL",
  "HODIM",
  "HODIŠ",
  "HODNA",
  "HODNE",
  "HODNI",
  "HODNO",
  "HODOM",
  "HODOV",
  "HODŽA",
  "HODŽE",
  "HODŽI",
  "HODŽO",
  "HODŽU",
  "HOJAM",
  "HOJCA",
  "HOJCE",
  "HOJCI",
  "HOJCO",
  "HOJEV",
  "HOJIC",
  "HOJLA",
  "HOKEJ",
  "HOLIN",
  "HOLMA",
  "HOLME",
  "HOLMI",
  "HOLMU",
  "HONAJ",
  "HONAL",
  "HONAM",
  "HONAN",
  "HONAŠ",
  "HONDA",
  "HONDE",
  "HONDI",
  "HONDO",
  "HOPAK",
  "HOPLA",
  "HORAM",
  "HORDA",
  "HORDE",
  "HORDI",
  "HORDO",
  "HORIJ",
  "HORST",
  "HORUK",
  "HOSTA",
  "HOSTE",
  "HOSTI",
  "HOSTO",
  "HOŠKA",
  "HOŠKE",
  "HOŠKI",
  "HOŠKO",
  "HOTEČ",
  "HOTEL",
  "HOTEN",
  "HOTNA",
  "HOTNE",
  "HOTNI",
  "HOTNO",
  "HOUSA",
  "HOUSE",
  "HOUSU",
  "HOZAN",
  "HRAMA",
  "HRAMB",
  "HRAME",
  "HRAMI",
  "HRAMU",
  "HRANA",
  "HRANE",
  "HRANI",
  "HRANO",
  "HRAST",
  "HRBET",
  "HRBTA",
  "HRBTE",
  "HRBTI",
  "HRBTU",
  "HRČAK",
  "HRČAL",
  "HRČEČ",
  "HRČEK",
  "HRČIC",
  "HRČIM",
  "HRČIŠ",
  "HRČJA",
  "HRČJE",
  "HRČJI",
  "HRČKA",
  "HRČKE",
  "HRČKI",
  "HRČKU",
  "HRECA",
  "HRENA",
  "HRENE",
  "HRENI",
  "HRENU",
  "HRESK",
  "HRIBA",
  "HRIBE",
  "HRIBI",
  "HRIBU",
  "HRIPA",
  "HRKAJ",
  "HRKAL",
  "HRKAM",
  "HRKAŠ",
  "HRKNE",
  "HRKNI",
  "HROMA",
  "HROME",
  "HROMI",
  "HROMO",
  "HROPE",
  "HROPI",
  "HROŠČ",
  "HRSKA",
  "HRSKE",
  "HRSKI",
  "HRSKU",
  "HRSTA",
  "HRSTI",
  "HRTIC",
  "HRTOM",
  "HRTOV",
  "HRULE",
  "HRULI",
  "HRUMA",
  "HRUME",
  "HRUMI",
  "HRUPA",
  "HRUPE",
  "HRUPI",
  "HRUPU",
  "HRUST",
  "HRUŠČ",
  "HRUŠK",
  "HRZAJ",
  "HRZAL",
  "HRZAM",
  "HRZAŠ",
  "HRZAV",
  "HRZNE",
  "HRZNI",
  "HRŽEM",
  "HRŽEŠ",
  "HRŽIC",
  "HUBAM",
  "HUDIČ",
  "HUDIK",
  "HUDIR",
  "HUDOB",
  "HUJŠA",
  "HUJŠE",
  "HUJŠI",
  "HUKAJ",
  "HUKAL",
  "HUKAM",
  "HUKAŠ",
  "HUKNE",
  "HUKNI",
  "HULEČ",
  "HULEŽ",
  "HULIL",
  "HULIM",
  "HULIŠ",
  "HULOK",
  "HUMAN",
  "HUMOR",
  "HUMUS",
  "HUNTA",
  "HUNTE",
  "HUNTI",
  "HUNTO",
  "HUPAJ",
  "HUPAL",
  "HUPAM",
  "HUPAŠ",
  "HURIJ",
  "HUSIT",
  "HUŠKA",
  "HUŠKE",
  "HUŠKI",
  "HUŠKO",
  "HUŠNE",
  "HUŠNI",
  "HUZAR",
  "HVALA",
  "HVALI",
  "HVALO",
  "IBISA",
  "IBISE",
  "IBISI",
  "IBISU",
  "IDEAL",
  "IDEJA",
  "IDEJE",
  "IDEJI",
  "IDEJO",
  "IDILA",
  "IDILE",
  "IDILI",
  "IDILO",
  "IDIOM",
  "IDOMA",
  "IDOZA",
  "IDOZE",
  "IDOZI",
  "IDOZO",
  "IFTAR",
  "IGALK",
  "IGELN",
  "IGLAJ",
  "IGLAL",
  "IGLAM",
  "IGLAN",
  "IGLAR",
  "IGLAŠ",
  "IGLAT",
  "IGLIC",
  "IGRAČ",
  "IGRAJ",
  "IGRAL",
  "IGRAM",
  "IGRAN",
  "IGRAŠ",
  "IGRIC",
  "IGRIV",
  "IHTAV",
  "IHTEČ",
  "IHTEL",
  "IHTIM",
  "IHTIŠ",
  "IKAVK",
  "IKONA",
  "IKONE",
  "IKONI",
  "IKONO",
  "IKRAM",
  "IKRAV",
  "IKRIC",
  "IKRNA",
  "IKRNE",
  "IKRNI",
  "IKRNO",
  "IKSOM",
  "IKSOV",
  "IKTOM",
  "IKTOV",
  "IKTUS",
  "ILEUS",
  "ILIRK",
  "IMAJO",
  "IMAMA",
  "IMAME",
  "IMAMI",
  "IMAMO",
  "IMATA",
  "IMATE",
  "IMAVA",
  "IMELA",
  "IMELE",
  "IMELI",
  "IMELO",
  "IMENA",
  "IMENI",
  "IMENU",
  "IMETI",
  "IMIDA",
  "IMIDE",
  "IMIDI",
  "IMIDU",
  "IMINA",
  "IMINE",
  "IMINI",
  "IMINU",
  "IMPAL",
  "IMPRO",
  "IMUNA",
  "IMUNE",
  "IMUNI",
  "IMUNO",
  "INCEL",
  "INČUN",
  "INDIC",
  "INDIJ",
  "INDRI",
  "INFRA",
  "INGOT",
  "INLET",
  "INOKS",
  "INSKA",
  "INSKE",
  "INSKI",
  "INSKO",
  "INSUL",
  "INTIM",
  "INVAR",
  "IONOM",
  "IONOV",
  "IPADA",
  "IPADU",
  "IPODA",
  "IPODE",
  "IPODI",
  "IPODU",
  "IPROM",
  "IRBIS",
  "IRHAR",
  "IRISA",
  "IRISE",
  "IRISI",
  "IRISU",
  "IRSKA",
  "IRSKE",
  "IRSKI",
  "IRSKO",
  "IRŠKA",
  "IRŠKE",
  "IRŠKI",
  "IRŠKO",
  "ISKAL",
  "ISKAN",
  "ISKER",
  "ISKRA",
  "ISKRE",
  "ISKRI",
  "ISKRN",
  "ISKRO",
  "ISLAM",
  "ISTEČ",
  "ISTEJ",
  "ISTEN",
  "ISTIJ",
  "ISTIL",
  "ISTIM",
  "ISTIŠ",
  "ISTJE",
  "IŠČEM",
  "IŠČEŠ",
  "IŠČOČ",
  "IŠIAS",
  "ITRIJ",
  "IVAMA",
  "IVERI",
  "IVERK",
  "IVINA",
  "IVINE",
  "IVINI",
  "IVINO",
  "IVNAT",
  "IVRIT",
  "IZBAM",
  "IZBEN",
  "IZBIC",
  "IZBIJ",
  "IZBIL",
  "IZBIR",
  "IZBIT",
  "IZBNA",
  "IZBNE",
  "IZBNI",
  "IZBNO",
  "IZBOK",
  "IZBOR",
  "IZDAJ",
  "IZDAL",
  "IZDAM",
  "IZDAN",
  "IZDAŠ",
  "IZDIH",
  "IZDRI",
  "IZDRT",
  "IZGIN",
  "IZGON",
  "IZGUB",
  "IZHOD",
  "IZIDA",
  "IZIDE",
  "IZIDI",
  "IZIDU",
  "IZITI",
  "IZJAV",
  "IZJED",
  "IZJEJ",
  "IZJEM",
  "IZJEŠ",
  "IZKAZ",
  "IZKOP",
  "IZKUP",
  "IZLET",
  "IZLIJ",
  "IZLIT",
  "IZLIV",
  "IZLOV",
  "IZMEN",
  "IZMER",
  "IZMET",
  "IZMIJ",
  "IZMIK",
  "IZMIL",
  "IZMIT",
  "IZMOM",
  "IZMOV",
  "IZNOS",
  "IZPAD",
  "IZPAH",
  "IZPEL",
  "IZPET",
  "IZPIH",
  "IZPIJ",
  "IZPIL",
  "IZPIS",
  "IZPIT",
  "IZPNE",
  "IZPNI",
  "IZPOJ",
  "IZPRE",
  "IZPRI",
  "IZPRL",
  "IZPRT",
  "IZPUH",
  "IZRAB",
  "IZRAZ",
  "IZREK",
  "IZREZ",
  "IZRIJ",
  "IZRIL",
  "IZRIS",
  "IZRIT",
  "IZRUJ",
  "IZRUL",
  "IZRUT",
  "IZSEK",
  "IZSEV",
  "IZŠEL",
  "IZŠLA",
  "IZŠLE",
  "IZŠLI",
  "IZŠLO",
  "IZTEG",
  "IZTEK",
  "IZTIS",
  "IZTOK",
  "IZUČI",
  "IZUMA",
  "IZUME",
  "IZUMI",
  "IZUMU",
  "IZURI",
  "IZVEM",
  "IZVES",
  "IZVEŠ",
  "IZVID",
  "IZVIJ",
  "IZVIN",
  "IZVIR",
  "IZVIT",
  "IZVOD",
  "IZVOR",
  "IZVOZ",
  "IZVRE",
  "IZVRG",
  "IZVRI",
  "IZZIV",
  "IZŽEL",
  "IZŽET",
  "IZŽGI",
  "IZŽME",
  "IZŽMI",
  "IZŽRE",
  "IZŽRI",
  "IZŽRL",
  "IZŽRT",
  "IŽESA",
  "IŽESI",
  "IŽESU",
  "JAČAJ",
  "JAČJE",
  "JADER",
  "JADRA",
  "JADRC",
  "JADRI",
  "JADRN",
  "JADRO",
  "JADRU",
  "JAGAM",
  "JAGER",
  "JAGOD",
  "JAGRA",
  "JAGRE",
  "JAGRI",
  "JAGRU",
  "JAHAČ",
  "JAHAJ",
  "JAHAL",
  "JAHAM",
  "JAHAŠ",
  "JAHTA",
  "JAHTE",
  "JAHTI",
  "JAHTO",
  "JAJCA",
  "JAJCE",
  "JAJCI",
  "JAJCU",
  "JAJČK",
  "JAKAM",
  "JAKEN",
  "JAKNA",
  "JAKNE",
  "JAKNI",
  "JAKNO",
  "JAKOM",
  "JAKOV",
  "JAKUZ",
  "JALOV",
  "JAMAM",
  "JAMAR",
  "JAMBA",
  "JAMBE",
  "JAMBI",
  "JAMBU",
  "JAMČI",
  "JAMIC",
  "JAMRA",
  "JANEŽ",
  "JANGA",
  "JANGE",
  "JANGI",
  "JANGU",
  "JAPOK",
  "JARCA",
  "JARCE",
  "JARCI",
  "JARCU",
  "JARDA",
  "JARDE",
  "JARDI",
  "JARDU",
  "JAREC",
  "JAREK",
  "JAREM",
  "JARIC",
  "JARKA",
  "JARKE",
  "JARKI",
  "JARKU",
  "JARMA",
  "JARME",
  "JARMI",
  "JARMU",
  "JASAM",
  "JASAN",
  "JASEN",
  "JASIC",
  "JASLI",
  "JASNA",
  "JASNE",
  "JASNI",
  "JASNO",
  "JAŠEK",
  "JAŠEM",
  "JAŠEŠ",
  "JAŠKA",
  "JAŠKE",
  "JAŠKI",
  "JAŠKU",
  "JATAM",
  "JATOM",
  "JATOV",
  "JAVEN",
  "JAVKA",
  "JAVKE",
  "JAVKI",
  "JAVKO",
  "JAVNA",
  "JAVNE",
  "JAVNI",
  "JAVNO",
  "JAVOR",
  "JAZID",
  "JECAV",
  "JECNE",
  "JECNI",
  "JEČAL",
  "JEČAM",
  "JEČAR",
  "JEČAV",
  "JEČEČ",
  "JEČIM",
  "JEČIŠ",
  "JEDCA",
  "JEDCE",
  "JEDCI",
  "JEDCU",
  "JEDEC",
  "JEDEK",
  "JEDEL",
  "JEDEM",
  "JEDER",
  "JEDEŽ",
  "JEDIL",
  "JEDKA",
  "JEDKE",
  "JEDKI",
  "JEDKO",
  "JEDLA",
  "JEDLE",
  "JEDLI",
  "JEDLO",
  "JEDOČ",
  "JEDRA",
  "JEDRC",
  "JEDRE",
  "JEDRI",
  "JEDRN",
  "JEDRO",
  "JEDRU",
  "JEJMO",
  "JEJTA",
  "JEJTE",
  "JEJVA",
  "JEKEL",
  "JEKLA",
  "JEKLE",
  "JEKLI",
  "JEKLO",
  "JEKLU",
  "JEKNE",
  "JEKNI",
  "JEKOM",
  "JEKOV",
  "JELAM",
  "JELEN",
  "JELKA",
  "JELKE",
  "JELKI",
  "JELKO",
  "JELOV",
  "JELŠA",
  "JELŠE",
  "JELŠI",
  "JELŠO",
  "JEMAL",
  "JEMAN",
  "JENOM",
  "JENOV",
  "JERAM",
  "JEREB",
  "JERIN",
  "JESEN",
  "JESTA",
  "JESTE",
  "JESTI",
  "JESUR",
  "JEŠČA",
  "JEŠČE",
  "JEŠČI",
  "JEŠČO",
  "JEŠIV",
  "JEŠKA",
  "JEŠKE",
  "JEŠKI",
  "JEŠKO",
  "JETER",
  "JETRA",
  "JETRC",
  "JETRN",
  "JEZAV",
  "JEZDI",
  "JEZEČ",
  "JEZEN",
  "JEZER",
  "JEZIC",
  "JEZID",
  "JEZIK",
  "JEZNA",
  "JEZNE",
  "JEZNI",
  "JEZNO",
  "JEZOV",
  "JEŽAM",
  "JEŽEK",
  "JEŽEM",
  "JEŽEN",
  "JEŽEŠ",
  "JEŽEV",
  "JEŽIC",
  "JEŽKA",
  "JEŽKE",
  "JEŽKI",
  "JEŽKU",
  "JEŽNA",
  "JEŽNE",
  "JEŽNI",
  "JEŽNO",
  "JICAM",
  "JIDIŠ",
  "JINOM",
  "JINOV",
  "JOANN",
  "JOČEM",
  "JOČEŠ",
  "JODAT",
  "JODEN",
  "JODID",
  "JODIT",
  "JODLA",
  "JODNA",
  "JODNE",
  "JODNI",
  "JODNO",
  "JODOV",
  "JOJME",
  "JOJOB",
  "JOKAJ",
  "JOKAL",
  "JOKAM",
  "JOKAŠ",
  "JOKAV",
  "JOKCA",
  "JOKER",
  "JOPIC",
  "JOPIČ",
  "JOSTA",
  "JOSTE",
  "JOSTI",
  "JOSTO",
  "JOTAM",
  "JUANA",
  "JUANE",
  "JUANI",
  "JUANU",
  "JUDEŽ",
  "JUDJE",
  "JUDOM",
  "JUDOV",
  "JUGOM",
  "JUGOV",
  "JUHAM",
  "JUHAR",
  "JUHEJ",
  "JUHIC",
  "JUHTA",
  "JUHTE",
  "JUHTI",
  "JUHTO",
  "JUKAM",
  "JULIJ",
  "JUNAK",
  "JUNCA",
  "JUNCE",
  "JUNCI",
  "JUNCU",
  "JUNEC",
  "JUNIC",
  "JUNIJ",
  "JURIŠ",
  "JURKA",
  "JURKE",
  "JURKI",
  "JURKO",
  "JURKU",
  "JURTA",
  "JURTE",
  "JURTI",
  "JURTO",
  "JUŠEN",
  "JUŠNA",
  "JUŠNE",
  "JUŠNI",
  "JUŠNO",
  "JUTAM",
  "JUTER",
  "JUTIN",
  "JUTRA",
  "JUTRI",
  "JUTRO",
  "JUTRU",
  "JUŽEČ",
  "JUŽEN",
  "JUŽIL",
  "JUŽIM",
  "JUŽIN",
  "JUŽIŠ",
  "JUŽNA",
  "JUŽNE",
  "JUŽNI",
  "JUŽNO",
  "KABEL",
  "KABIN",
  "KABLA",
  "KABLE",
  "KABLI",
  "KABLU",
  "KACET",
  "KAČAM",
  "KAČAR",
  "KAČEK",
  "KAČIC",
  "KAČIN",
  "KAČJA",
  "KAČJE",
  "KAČJI",
  "KAČKA",
  "KAČKE",
  "KAČKI",
  "KAČKU",
  "KAČON",
  "KADEČ",
  "KADEM",
  "KADER",
  "KADET",
  "KADIC",
  "KADIJ",
  "KADIL",
  "KADIM",
  "KADIŠ",
  "KADRA",
  "KADRE",
  "KADRI",
  "KADRU",
  "KAFER",
  "KAFRA",
  "KAFRE",
  "KAFRI",
  "KAFRN",
  "KAFRO",
  "KAHON",
  "KAJAK",
  "KAJPA",
  "KAJTA",
  "KAJTE",
  "KAJTI",
  "KAJTU",
  "KAJUT",
  "KAKAJ",
  "KAKAL",
  "KAKAM",
  "KAKAŠ",
  "KAKAV",
  "KAKCA",
  "KAKCE",
  "KAKCI",
  "KAKCU",
  "KAKEC",
  "KALAM",
  "KALAN",
  "KALEČ",
  "KALEM",
  "KALEN",
  "KALEŽ",
  "KALIF",
  "KALIJ",
  "KALIL",
  "KALIM",
  "KALIN",
  "KALIŠ",
  "KALIV",
  "KALKA",
  "KALKE",
  "KALKI",
  "KALKU",
  "KALNA",
  "KALNE",
  "KALNI",
  "KALNO",
  "KALOM",
  "KALOT",
  "KALOV",
  "KALUP",
  "KALUS",
  "KALUŽ",
  "KAMAM",
  "KAMBA",
  "KAMBE",
  "KAMBI",
  "KAMBO",
  "KAMEJ",
  "KAMEL",
  "KAMEN",
  "KAMER",
  "KAMIN",
  "KAMNA",
  "KAMNE",
  "KAMNI",
  "KAMNU",
  "KAMPA",
  "KAMPI",
  "KAMPO",
  "KAMPU",
  "KAMRA",
  "KAMRE",
  "KAMRI",
  "KAMRO",
  "KANAL",
  "KANAM",
  "KANAT",
  "KANCA",
  "KANCE",
  "KANCI",
  "KANCU",
  "KANEC",
  "KANEL",
  "KANEM",
  "KANEŠ",
  "KANIL",
  "KANJA",
  "KANJE",
  "KANJI",
  "KANJO",
  "KANOM",
  "KANOP",
  "KANOV",
  "KAOSA",
  "KAOSE",
  "KAOSI",
  "KAOSU",
  "KAPAJ",
  "KAPAL",
  "KAPAM",
  "KAPAN",
  "KAPAR",
  "KAPAŠ",
  "KAPEL",
  "KAPEM",
  "KAPEN",
  "KAPER",
  "KAPIC",
  "KAPIČ",
  "KAPNO",
  "KAPOK",
  "KAPOM",
  "KAPOV",
  "KAPRA",
  "KAPRE",
  "KAPRI",
  "KAPRN",
  "KAPRO",
  "KAPUC",
  "KAPUS",
  "KARAF",
  "KARAJ",
  "KARAN",
  "KARAT",
  "KARIN",
  "KARMA",
  "KARME",
  "KARMI",
  "KARMO",
  "KAROM",
  "KAROV",
  "KARTA",
  "KARTE",
  "KARTI",
  "KARTO",
  "KASAČ",
  "KASEN",
  "KASET",
  "KASNA",
  "KASNE",
  "KASNO",
  "KASTA",
  "KASTE",
  "KASTI",
  "KASTO",
  "KAŠAM",
  "KAŠČA",
  "KAŠČE",
  "KAŠČI",
  "KAŠČO",
  "KAŠEN",
  "KAŠER",
  "KAŠIC",
  "KAŠKA",
  "KAŠKE",
  "KAŠKI",
  "KAŠKO",
  "KAŠNA",
  "KAŠNE",
  "KAŠNI",
  "KAŠNO",
  "KATAM",
  "KATAN",
  "KATAR",
  "KATET",
  "KATOD",
  "KATUN",
  "KAURI",
  "KAVAL",
  "KAVAM",
  "KAVČA",
  "KAVČE",
  "KAVČI",
  "KAVČU",
  "KAVEN",
  "KAVER",
  "KAVIC",
  "KAVKA",
  "KAVKE",
  "KAVKI",
  "KAVKO",
  "KAVNA",
  "KAVNE",
  "KAVNI",
  "KAVNO",
  "KAVOV",
  "KAVRA",
  "KAVRE",
  "KAVRI",
  "KAVRU",
  "KAZAL",
  "KAZEČ",
  "KAZEN",
  "KAZNI",
  "KAZUL",
  "KAŽEM",
  "KAŽEŠ",
  "KAŽOČ",
  "KDOVE",
  "KEAMA",
  "KEBAB",
  "KEČAP",
  "KEČIG",
  "KEFIJ",
  "KEFIR",
  "KEINA",
  "KEINE",
  "KEINI",
  "KEINO",
  "KEKSA",
  "KEKSE",
  "KEKSI",
  "KEKSU",
  "KELAM",
  "KELAT",
  "KELIH",
  "KEMIK",
  "KENTA",
  "KENTE",
  "KENTI",
  "KENTO",
  "KEPAJ",
  "KEPAM",
  "KEPER",
  "KEPIC",
  "KEPRA",
  "KEPRE",
  "KEPRI",
  "KEPRU",
  "KERIJ",
  "KERUB",
  "KESON",
  "KETOL",
  "KETON",
  "KETOZ",
  "KIANG",
  "KIBUC",
  "KIDAJ",
  "KIFOZ",
  "KIHAJ",
  "KIHAL",
  "KIHAM",
  "KIHAŠ",
  "KIHNE",
  "KIHNI",
  "KIHOM",
  "KIHOV",
  "KIJCA",
  "KIJCE",
  "KIJCI",
  "KIJCU",
  "KIJEC",
  "KIJEM",
  "KIJEV",
  "KILAM",
  "KILAV",
  "KILEN",
  "KILHA",
  "KILHE",
  "KILHI",
  "KILHU",
  "KILNA",
  "KILNE",
  "KILNI",
  "KILNO",
  "KILTA",
  "KILTE",
  "KILTI",
  "KILTU",
  "KIMAJ",
  "KIMAL",
  "KIMAM",
  "KIMAŠ",
  "KIMAV",
  "KIMČI",
  "KINAZ",
  "KINET",
  "KINIK",
  "KININ",
  "KINKA",
  "KINOM",
  "KINON",
  "KINOV",
  "KIOSK",
  "KIPAR",
  "KIPCA",
  "KIPCE",
  "KIPCI",
  "KIPCU",
  "KIPEC",
  "KIPEČ",
  "KIPEL",
  "KIPIM",
  "KIPIŠ",
  "KIPOM",
  "KIPOV",
  "KIRIJ",
  "KIRNJ",
  "KISAJ",
  "KISAN",
  "KISEL",
  "KISIK",
  "KISLA",
  "KISLE",
  "KISLI",
  "KISLO",
  "KISOM",
  "KISOV",
  "KITAJ",
  "KITAL",
  "KITAM",
  "KITAR",
  "KITAŠ",
  "KITEN",
  "KITIC",
  "KITKA",
  "KITKE",
  "KITKI",
  "KITKO",
  "KITNA",
  "KITNE",
  "KITNI",
  "KITNO",
  "KITOM",
  "KITOV",
  "KIVET",
  "KLADA",
  "KLADE",
  "KLADI",
  "KLADO",
  "KLAJA",
  "KLAJE",
  "KLAJI",
  "KLAJO",
  "KLAKA",
  "KLAKE",
  "KLAKI",
  "KLAKO",
  "KLAMA",
  "KLANA",
  "KLANE",
  "KLANI",
  "KLANJ",
  "KLANO",
  "KLANU",
  "KLASA",
  "KLASE",
  "KLASI",
  "KLASU",
  "KLATI",
  "KLAVK",
  "KLAVŽ",
  "KLECA",
  "KLEČE",
  "KLEČI",
  "KLEJA",
  "KLEJE",
  "KLEJI",
  "KLEJU",
  "KLEKA",
  "KLEKE",
  "KLEKI",
  "KLEKU",
  "KLELA",
  "KLELI",
  "KLELO",
  "KLENA",
  "KLENE",
  "KLENI",
  "KLENO",
  "KLENU",
  "KLERA",
  "KLERU",
  "KLEŠČ",
  "KLEŠE",
  "KLEŠI",
  "KLETI",
  "KLETK",
  "KLICA",
  "KLICE",
  "KLICI",
  "KLICO",
  "KLICU",
  "KLIČE",
  "KLIČI",
  "KLIFA",
  "KLIFE",
  "KLIFI",
  "KLIFU",
  "KLIJE",
  "KLIKA",
  "KLIKE",
  "KLIKI",
  "KLIKO",
  "KLIKU",
  "KLILA",
  "KLILE",
  "KLILI",
  "KLILO",
  "KLINČ",
  "KLINI",
  "KLINU",
  "KLIŠE",
  "KLITI",
  "KLJUČ",
  "KLJUJ",
  "KLJUK",
  "KLJUN",
  "KLOAK",
  "KLOBK",
  "KLONA",
  "KLONE",
  "KLONI",
  "KLONU",
  "KLOPA",
  "KLOPC",
  "KLOPE",
  "KLOPI",
  "KLOPU",
  "KLORA",
  "KLORU",
  "KLOVN",
  "KLOŽA",
  "KLOŽE",
  "KLOŽI",
  "KLOŽO",
  "KLUBA",
  "KLUBE",
  "KLUBI",
  "KLUBU",
  "KMALU",
  "KMETA",
  "KMETE",
  "KMETI",
  "KMETU",
  "KNAPA",
  "KNAPE",
  "KNAPI",
  "KNAPU",
  "KNEZA",
  "KNEZE",
  "KNEZI",
  "KNEZU",
  "KNJIG",
  "KNUTA",
  "KNUTE",
  "KNUTI",
  "KNUTO",
  "KOALA",
  "KOALE",
  "KOALI",
  "KOALO",
  "KOANA",
  "KOANE",
  "KOANI",
  "KOANU",
  "KOATA",
  "KOATE",
  "KOATI",
  "KOATO",
  "KOBER",
  "KOBIL",
  "KOBRA",
  "KOBRE",
  "KOBRI",
  "KOBRO",
  "KOBUL",
  "KOCEN",
  "KOCIN",
  "KOCKA",
  "KOCKE",
  "KOCKI",
  "KOCKO",
  "KOČAM",
  "KOČAN",
  "KOČAR",
  "KOČEN",
  "KOČIC",
  "KOČIČ",
  "KOČIJ",
  "KOČKA",
  "KOČKE",
  "KOČKI",
  "KOČKO",
  "KOČNA",
  "KOČNE",
  "KOČNI",
  "KOČNO",
  "KODAM",
  "KODEK",
  "KODEN",
  "KODER",
  "KODNA",
  "KODNE",
  "KODNI",
  "KODNO",
  "KODOM",
  "KODON",
  "KODOV",
  "KODRA",
  "KODRC",
  "KODRE",
  "KODRI",
  "KODRU",
  "KOFOZ",
  "KOGAR",
  "KOJOT",
  "KOKAM",
  "KOKET",
  "KOKIL",
  "KOKIN",
  "KOKOM",
  "KOKON",
  "KOKOS",
  "KOKOŠ",
  "KOKOV",
  "KOKSA",
  "KOKSU",
  "KOLAČ",
  "KOLAM",
  "KOLAR",
  "KOLAŽ",
  "KOLCA",
  "KOLCE",
  "KOLCI",
  "KOLCU",
  "KOLČE",
  "KOLČI",
  "KOLEB",
  "KOLEK",
  "KOLEN",
  "KOLES",
  "KOLIB",
  "KOLIK",
  "KOLIN",
  "KOLJI",
  "KOLKA",
  "KOLKE",
  "KOLKI",
  "KOLKU",
  "KOLNE",
  "KOLNI",
  "KOLON",
  "KOLOS",
  "KOLOV",
  "KOLTA",
  "KOLTE",
  "KOLTI",
  "KOLTU",
  "KOLUT",
  "KOMAJ",
  "KOMAM",
  "KOMAR",
  "KOMAT",
  "KOMBI",
  "KOMET",
  "KOMIK",
  "KOMIS",
  "KOMIT",
  "KOMOR",
  "KOMUN",
  "KOMUR",
  "KONCA",
  "KONCE",
  "KONCI",
  "KONCU",
  "KONČA",
  "KONEC",
  "KONHA",
  "KONHE",
  "KONHI",
  "KONHO",
  "KONIC",
  "KONJA",
  "KONJE",
  "KONJI",
  "KONJU",
  "KONTA",
  "KONTE",
  "KONTI",
  "KONTO",
  "KONTU",
  "KONUS",
  "KONZI",
  "KOPAČ",
  "KOPAJ",
  "KOPAL",
  "KOPAM",
  "KOPAN",
  "KOPAŠ",
  "KOPEL",
  "KOPEN",
  "KOPER",
  "KOPIC",
  "KOPIJ",
  "KOPIT",
  "KOPJA",
  "KOPJE",
  "KOPJI",
  "KOPJU",
  "KOPNA",
  "KOPNE",
  "KOPNI",
  "KOPNO",
  "KOPRA",
  "KOPRC",
  "KOPRU",
  "KOPUČ",
  "KOPUL",
  "KOPUN",
  "KORAK",
  "KORAL",
  "KORCA",
  "KORCE",
  "KORCI",
  "KORCU",
  "KOREC",
  "KOREN",
  "KORIT",
  "KORMA",
  "KORME",
  "KORMI",
  "KORMU",
  "KORNA",
  "KORNE",
  "KORNI",
  "KORNO",
  "KOROM",
  "KORON",
  "KOROV",
  "KOSAM",
  "KOSCA",
  "KOSCE",
  "KOSCI",
  "KOSCU",
  "KOSEC",
  "KOSEČ",
  "KOSEM",
  "KOSIC",
  "KOSIL",
  "KOSIM",
  "KOSIŠ",
  "KOSJA",
  "KOSJE",
  "KOSJI",
  "KOSMA",
  "KOSME",
  "KOSMI",
  "KOSMU",
  "KOSOM",
  "KOSOV",
  "KOSTI",
  "KOŠAR",
  "KOŠAT",
  "KOŠEK",
  "KOŠEM",
  "KOŠEN",
  "KOŠER",
  "KOŠEV",
  "KOŠKA",
  "KOŠKE",
  "KOŠKI",
  "KOŠKO",
  "KOŠKU",
  "KOŠNA",
  "KOŠNE",
  "KOŠNI",
  "KOŠNO",
  "KOŠUT",
  "KOTAČ",
  "KOTAR",
  "KOTEČ",
  "KOTEL",
  "KOTEN",
  "KOTIL",
  "KOTIM",
  "KOTIŠ",
  "KOTLA",
  "KOTLE",
  "KOTLI",
  "KOTLU",
  "KOTNA",
  "KOTNE",
  "KOTNI",
  "KOTNO",
  "KOTOM",
  "KOTOV",
  "KOVAČ",
  "KOVAN",
  "KOVČA",
  "KOVČE",
  "KOVČI",
  "KOVČU",
  "KOVEN",
  "KOVIC",
  "KOVID",
  "KOVIN",
  "KOVNA",
  "KOVNE",
  "KOVNI",
  "KOVNO",
  "KOZAČ",
  "KOZAK",
  "KOZAM",
  "KOZAV",
  "KOZEL",
  "KOZIC",
  "KOZIN",
  "KOZJA",
  "KOZJE",
  "KOZJI",
  "KOZLA",
  "KOZLE",
  "KOZLI",
  "KOZLU",
  "KOŽAM",
  "KOŽAR",
  "KOŽIC",
  "KOŽNA",
  "KOŽNE",
  "KOŽNI",
  "KOŽNO",
  "KOŽUH",
  "KRACA",
  "KRAČA",
  "KRAČE",
  "KRAČI",
  "KRAČO",
  "KRADE",
  "KRADI",
  "KRAFA",
  "KRAFE",
  "KRAFI",
  "KRAFU",
  "KRAJA",
  "KRAJE",
  "KRAJI",
  "KRAJO",
  "KRAJT",
  "KRAJU",
  "KRAKA",
  "KRAKE",
  "KRAKI",
  "KRAKU",
  "KRALJ",
  "KRAMA",
  "KRAME",
  "KRAMI",
  "KRAMO",
  "KRAMP",
  "KRAPA",
  "KRAPE",
  "KRAPI",
  "KRAPU",
  "KRASA",
  "KRASI",
  "KRASU",
  "KRATA",
  "KRATE",
  "KRATI",
  "KRATU",
  "KRAVA",
  "KRAVE",
  "KRAVI",
  "KRAVL",
  "KRAVO",
  "KRAVS",
  "KRCAJ",
  "KRCAN",
  "KRCEM",
  "KRCEV",
  "KRCNI",
  "KRČEČ",
  "KRČEM",
  "KRČEN",
  "KRČEV",
  "KRČMA",
  "KRČME",
  "KRČMI",
  "KRČMO",
  "KRČNA",
  "KRČNE",
  "KRČNI",
  "KRČNO",
  "KRDEL",
  "KREDA",
  "KREDE",
  "KREKA",
  "KREKU",
  "KREMA",
  "KREME",
  "KREMI",
  "KREMO",
  "KRENE",
  "KRENI",
  "KREOL",
  "KREPA",
  "KREPE",
  "KREPI",
  "KREPU",
  "KRESA",
  "KRESE",
  "KRESI",
  "KRESU",
  "KREŠA",
  "KREŠE",
  "KREŠI",
  "KREŠO",
  "KRHAJ",
  "KRHEK",
  "KRHKA",
  "KRHKE",
  "KRHKI",
  "KRHKO",
  "KRIČE",
  "KRIČI",
  "KRIKA",
  "KRIKE",
  "KRIKI",
  "KRIKU",
  "KRILA",
  "KRILC",
  "KRILE",
  "KRILI",
  "KRILK",
  "KRILO",
  "KRILU",
  "KRINK",
  "KRIPT",
  "KRITA",
  "KRITE",
  "KRITI",
  "KRITO",
  "KRIVA",
  "KRIVD",
  "KRIVE",
  "KRIVI",
  "KRIVK",
  "KRIVO",
  "KRIZA",
  "KRIZE",
  "KRIZI",
  "KRIZO",
  "KRIŽA",
  "KRIŽE",
  "KRIŽI",
  "KRIŽU",
  "KRKON",
  "KRLJA",
  "KRLJE",
  "KRLJI",
  "KRLJU",
  "KRMAM",
  "KRMAR",
  "KRMEN",
  "KRMIL",
  "KRMNA",
  "KRMNE",
  "KRMNI",
  "KRMNO",
  "KRNEČ",
  "KRNEL",
  "KRNIC",
  "KRNIŽ",
  "KRNOM",
  "KRNOV",
  "KROFA",
  "KROFE",
  "KROFI",
  "KROFU",
  "KROGA",
  "KROGE",
  "KROGI",
  "KROGU",
  "KROJA",
  "KROJE",
  "KROJI",
  "KROJU",
  "KROKI",
  "KROMA",
  "KROMU",
  "KRONA",
  "KRONE",
  "KRONI",
  "KRONO",
  "KROPA",
  "KROPE",
  "KROPI",
  "KROPU",
  "KROSA",
  "KROSE",
  "KROSI",
  "KROSU",
  "KROŠE",
  "KROTE",
  "KROVA",
  "KROVE",
  "KROVI",
  "KROVK",
  "KROVU",
  "KROŽE",
  "KROŽI",
  "KRPAČ",
  "KRPAJ",
  "KRPAM",
  "KRPAR",
  "KRPAT",
  "KRPIC",
  "KRSTA",
  "KRSTE",
  "KRSTI",
  "KRSTO",
  "KRSTU",
  "KRŠCA",
  "KRŠCE",
  "KRŠCI",
  "KRŠCU",
  "KRŠEC",
  "KRŠEČ",
  "KRŠEN",
  "KRŠIN",
  "KRŠJA",
  "KRŠJE",
  "KRŠJU",
  "KRŠKA",
  "KRŠKE",
  "KRŠKI",
  "KRŠKO",
  "KRTAČ",
  "KRTEK",
  "KRTIC",
  "KRTIN",
  "KRTKA",
  "KRTKE",
  "KRTKI",
  "KRTKU",
  "KRTOM",
  "KRTOV",
  "KRUHA",
  "KRUHU",
  "KRULI",
  "KRUPA",
  "KRUPE",
  "KRUPI",
  "KRUPU",
  "KRUŠI",
  "KRUTA",
  "KRUTE",
  "KRUTI",
  "KRUTO",
  "KRVAV",
  "KRVEN",
  "KRVNA",
  "KRVNE",
  "KRVNI",
  "KRVNO",
  "KRZEN",
  "KRZNA",
  "KRZNI",
  "KRZNO",
  "KRZNU",
  "KSIJA",
  "KSIJE",
  "KSIJI",
  "KSIJU",
  "KUBIT",
  "KUČEM",
  "KUČMA",
  "KUČME",
  "KUČMI",
  "KUČMO",
  "KUGAM",
  "KUHAJ",
  "KUHAL",
  "KUHAM",
  "KUHAN",
  "KUHAR",
  "KUHAŠ",
  "KUJAV",
  "KUJMO",
  "KUJOČ",
  "KUJTA",
  "KUJTE",
  "KUJVA",
  "KUKAJ",
  "KUKAL",
  "KUKAM",
  "KUKAŠ",
  "KUKCA",
  "KUKCE",
  "KUKCI",
  "KUKCU",
  "KUKEB",
  "KUKEC",
  "KULAK",
  "KULAN",
  "KULEN",
  "KULIS",
  "KULON",
  "KULTA",
  "KULTE",
  "KULTI",
  "KULTU",
  "KUMAR",
  "KUMIN",
  "KUMIS",
  "KUNAM",
  "KUNCA",
  "KUNCE",
  "KUNCI",
  "KUNCU",
  "KUNEC",
  "KUNJA",
  "KUNJE",
  "KUNJI",
  "KUPAM",
  "KUPCA",
  "KUPCE",
  "KUPCI",
  "KUPCU",
  "KUPEC",
  "KUPEN",
  "KUPIC",
  "KUPIL",
  "KUPIM",
  "KUPIŠ",
  "KUPKA",
  "KUPKE",
  "KUPKI",
  "KUPKO",
  "KUPNA",
  "KUPNE",
  "KUPNI",
  "KUPNO",
  "KUPOL",
  "KUPOM",
  "KUPON",
  "KUPOV",
  "KUPUJ",
  "KURAM",
  "KURAT",
  "KUREČ",
  "KURIJ",
  "KURIL",
  "KURIM",
  "KURIR",
  "KURIŠ",
  "KURIV",
  "KURJA",
  "KURJE",
  "KURJI",
  "KUROL",
  "KUTAM",
  "KUTER",
  "KUTIN",
  "KUŽAM",
  "KUŽEK",
  "KUŽEM",
  "KUŽEN",
  "KUŽET",
  "KUŽEV",
  "KUŽIL",
  "KUŽKA",
  "KUŽKE",
  "KUŽKI",
  "KUŽKU",
  "KUŽNA",
  "KUŽNE",
  "KUŽNI",
  "KUŽNO",
  "KVAČK",
  "KVAGA",
  "KVAGE",
  "KVAGI",
  "KVAGO",
  "KVAKA",
  "KVANT",
  "KVARI",
  "KVARK",
  "KVART",
  "KVASA",
  "KVASE",
  "KVASI",
  "KVASU",
  "KVAŠA",
  "KVAŠE",
  "KVAŠI",
  "KVAŠO",
  "KVINT",
  "KVIZA",
  "KVIZE",
  "KVIZI",
  "KVIZU",
  "KVOTA",
  "KVOTE",
  "KVOTI",
  "KVOTO",
  "LABOD",
  "LAČEN",
  "LAČNA",
  "LAČNE",
  "LAČNI",
  "LAČNO",
  "LADIJ",
  "LADJA",
  "LADJE",
  "LADJI",
  "LADJO",
  "LAFET",
  "LAGAL",
  "LAGER",
  "LAGUN",
  "LAHAK",
  "LAHEK",
  "LAHEN",
  "LAHET",
  "LAHKA",
  "LAHKE",
  "LAHKI",
  "LAHKO",
  "LAHNA",
  "LAHNE",
  "LAHNI",
  "LAHNO",
  "LAHTI",
  "LAIKA",
  "LAIKE",
  "LAIKI",
  "LAIKU",
  "LAJAJ",
  "LAJAL",
  "LAJAM",
  "LAJAŠ",
  "LAJAV",
  "LAJEŽ",
  "LAJNA",
  "LAJNE",
  "LAJNI",
  "LAJNO",
  "LAKAJ",
  "LAKAZ",
  "LAKET",
  "LAKOM",
  "LAKOT",
  "LAKOV",
  "LAKTA",
  "LAKTE",
  "LAKTI",
  "LAKTU",
  "LAKUN",
  "LAMAM",
  "LAMBD",
  "LAMEL",
  "LAMET",
  "LAMIN",
  "LAMJA",
  "LAMJE",
  "LAMJI",
  "LAMOV",
  "LANAS",
  "LANEN",
  "LANIK",
  "LANOV",
  "LAPAM",
  "LAPIS",
  "LAPOR",
  "LAPUH",
  "LARGA",
  "LARGE",
  "LARGI",
  "LARGO",
  "LARGU",
  "LAROM",
  "LAROS",
  "LAROV",
  "LASAJ",
  "LASAL",
  "LASAM",
  "LASAŠ",
  "LASCA",
  "LASCE",
  "LASCI",
  "LASCU",
  "LASEC",
  "LASEK",
  "LASEM",
  "LASEN",
  "LASER",
  "LASJE",
  "LASKA",
  "LASKE",
  "LASKI",
  "LASKU",
  "LASNA",
  "LASNE",
  "LASNI",
  "LASNO",
  "LASOM",
  "LASOV",
  "LASTI",
  "LAŠKA",
  "LAŠKE",
  "LAŠKI",
  "LAŠKO",
  "LATAM",
  "LATEN",
  "LATIC",
  "LATJA",
  "LATJE",
  "LATJU",
  "LATOM",
  "LATOV",
  "LATSA",
  "LATSE",
  "LATSI",
  "LATSU",
  "LAVAM",
  "LAVEN",
  "LAVIC",
  "LAVNA",
  "LAVNE",
  "LAVNI",
  "LAVNO",
  "LAZAR",
  "LAZEČ",
  "LAZIC",
  "LAZIL",
  "LAZIM",
  "LAZIŠ",
  "LAZOM",
  "LAZOV",
  "LAZUR",
  "LAŽEM",
  "LAŽEN",
  "LAŽEŠ",
  "LAŽJA",
  "LAŽJE",
  "LAŽJI",
  "LAŽNA",
  "LAŽNE",
  "LAŽNI",
  "LAŽNO",
  "LEANN",
  "LEBAS",
  "LEBDE",
  "LEBDI",
  "LECTA",
  "LECTE",
  "LECTI",
  "LECTU",
  "LECUN",
  "LEČAM",
  "LEČEN",
  "LEČIC",
  "LEČIJ",
  "LEČIN",
  "LEČJA",
  "LEČJE",
  "LEČJI",
  "LEČJU",
  "LEČNA",
  "LEČNE",
  "LEČNI",
  "LEČNO",
  "LEDAR",
  "LEDEN",
  "LEDIJ",
  "LEDIN",
  "LEDJA",
  "LEDJE",
  "LEDJI",
  "LEDJU",
  "LEDNA",
  "LEDNE",
  "LEDNI",
  "LEDNO",
  "LEDOM",
  "LEDOV",
  "LEGAJ",
  "LEGAL",
  "LEGAM",
  "LEGAŠ",
  "LEGAT",
  "LEGEL",
  "LEGIJ",
  "LEGLA",
  "LEGLE",
  "LEGLI",
  "LEGLO",
  "LEGLU",
  "LEGUR",
  "LEJEM",
  "LEJEV",
  "LEKOM",
  "LEKOV",
  "LEMEŽ",
  "LEMUR",
  "LENEČ",
  "LENGA",
  "LENGE",
  "LENGI",
  "LENGU",
  "LENIČ",
  "LENIL",
  "LENIM",
  "LENIŠ",
  "LENIV",
  "LENOB",
  "LENTA",
  "LENTE",
  "LENTI",
  "LENTO",
  "LENUH",
  "LEPAK",
  "LEPEČ",
  "LEPEK",
  "LEPEL",
  "LEPEN",
  "LEPIC",
  "LEPIL",
  "LEPKA",
  "LEPKE",
  "LEPKI",
  "LEPKO",
  "LEPKU",
  "LEPOT",
  "LEPŠA",
  "LEPŠE",
  "LEPŠI",
  "LESAM",
  "LESAR",
  "LESEN",
  "LESKA",
  "LESKE",
  "LESKI",
  "LESKO",
  "LESKU",
  "LESNA",
  "LESNE",
  "LESNI",
  "LESNO",
  "LESOM",
  "LESOV",
  "LEŠKA",
  "LEŠKE",
  "LEŠKI",
  "LEŠKO",
  "LETAJ",
  "LETAK",
  "LETAL",
  "LETAM",
  "LETAŠ",
  "LETEČ",
  "LETEL",
  "LETEN",
  "LETEV",
  "LETIM",
  "LETIN",
  "LETIŠ",
  "LETNA",
  "LETNE",
  "LETNI",
  "LETNO",
  "LETOM",
  "LETOS",
  "LETOV",
  "LETUJ",
  "LETVE",
  "LETVI",
  "LEVEČ",
  "LEVIC",
  "LEVIČ",
  "LEVIH",
  "LEVIM",
  "LEVIT",
  "LEVJA",
  "LEVJE",
  "LEVJI",
  "LEVOM",
  "LEVOV",
  "LEVTA",
  "LEVTE",
  "LEVTI",
  "LEVTU",
  "LEZEL",
  "LEZEM",
  "LEZEŠ",
  "LEZIJ",
  "LEZLA",
  "LEZLE",
  "LEZLI",
  "LEZLO",
  "LEŽAJ",
  "LEŽAK",
  "LEŽAL",
  "LEŽEČ",
  "LEŽEM",
  "LEŽEN",
  "LEŽEŠ",
  "LEŽIM",
  "LEŽIŠ",
  "LEŽNA",
  "LEŽNE",
  "LEŽNI",
  "LEŽNO",
  "LIAZA",
  "LIAZE",
  "LIAZI",
  "LIAZO",
  "LIBAM",
  "LIBER",
  "LIBIN",
  "LIBRA",
  "LIBRE",
  "LIBRI",
  "LIBRO",
  "LICEJ",
  "LICEM",
  "LIČAR",
  "LIČEC",
  "LIČEČ",
  "LIČEK",
  "LIČEN",
  "LIČIL",
  "LIČJA",
  "LIČJE",
  "LIČJU",
  "LIČKA",
  "LIČKE",
  "LIČKI",
  "LIČKU",
  "LIČNA",
  "LIČNE",
  "LIČNI",
  "LIČNO",
  "LIDAR",
  "LIGAM",
  "LIGAZ",
  "LIGER",
  "LIGRA",
  "LIGRE",
  "LIGRI",
  "LIGRU",
  "LIJAK",
  "LIJAN",
  "LIJEM",
  "LIJEŠ",
  "LIJMO",
  "LIJOČ",
  "LIJTA",
  "LIJTE",
  "LIJVA",
  "LIKAJ",
  "LIKAL",
  "LIKAM",
  "LIKAR",
  "LIKAŠ",
  "LIKER",
  "LIKOM",
  "LIKOV",
  "LILIJ",
  "LIMAJ",
  "LIMAN",
  "LIMBA",
  "LIMBE",
  "LIMBI",
  "LIMBU",
  "LIMES",
  "LIMET",
  "LIMFA",
  "LIMFE",
  "LIMFI",
  "LIMFO",
  "LIMIT",
  "LIMON",
  "LINAM",
  "LINČA",
  "LINČE",
  "LINČI",
  "LINČU",
  "LINIC",
  "LINIJ",
  "LINJA",
  "LINJE",
  "LINJI",
  "LINJU",
  "LIPAM",
  "LIPAN",
  "LIPAZ",
  "LIPIC",
  "LIPID",
  "LIPOM",
  "LIPOV",
  "LIRAM",
  "LIREŠ",
  "LIRIK",
  "LISAJ",
  "LISAL",
  "LISAM",
  "LISAN",
  "LISAR",
  "LISAŠ",
  "LISCA",
  "LISCE",
  "LISCI",
  "LISCU",
  "LISEC",
  "LISIC",
  "LISKA",
  "LISKE",
  "LISKI",
  "LISKO",
  "LISTA",
  "LISTE",
  "LISTI",
  "LISTO",
  "LISTU",
  "LIŠAJ",
  "LIŠKA",
  "LIŠKE",
  "LIŠKI",
  "LIŠKO",
  "LITAS",
  "LITER",
  "LITIJ",
  "LITIN",
  "LITJA",
  "LITJE",
  "LITJI",
  "LITJU",
  "LITOT",
  "LITRA",
  "LITRE",
  "LITRI",
  "LITRU",
  "LIVAD",
  "LIVAR",
  "LIVEN",
  "LIVER",
  "LIVKA",
  "LIVKE",
  "LIVKI",
  "LIVKO",
  "LIVNA",
  "LIVNE",
  "LIVNI",
  "LIVNO",
  "LIVRA",
  "LIVRE",
  "LIVRI",
  "LIVRO",
  "LIZAČ",
  "LIZAL",
  "LIZAM",
  "LIZIK",
  "LIZIL",
  "LIZIN",
  "LIZOL",
  "LIZOM",
  "LIZOV",
  "LIZUN",
  "LIŽEM",
  "LIŽEŠ",
  "LJUBA",
  "LJUBE",
  "LJUBI",
  "LJUBO",
  "LJUDI",
  "LOAOZ",
  "LOBOD",
  "LOBOM",
  "LOBOV",
  "LOBUL",
  "LOCEN",
  "LOCNA",
  "LOCNE",
  "LOCNI",
  "LOCNU",
  "LOČAJ",
  "LOČBA",
  "LOČBE",
  "LOČBI",
  "LOČBO",
  "LOČEČ",
  "LOČEK",
  "LOČEM",
  "LOČEN",
  "LOČEŠ",
  "LOČIL",
  "LOČIN",
  "LOČJA",
  "LOČJE",
  "LOČJU",
  "LOČKA",
  "LOČKE",
  "LOČKI",
  "LOČKO",
  "LOČKU",
  "LOČNA",
  "LOČNE",
  "LOČNI",
  "LOČNO",
  "LOČUJ",
  "LODEN",
  "LODER",
  "LODNA",
  "LODNU",
  "LODRA",
  "LODRE",
  "LODRI",
  "LODRO",
  "LOGAR",
  "LOGIK",
  "LOGOM",
  "LOGOR",
  "LOGOS",
  "LOGOV",
  "LOHIJ",
  "LOJAV",
  "LOJEN",
  "LOJNA",
  "LOJNE",
  "LOJNI",
  "LOJNO",
  "LOKAJ",
  "LOKAL",
  "LOKAM",
  "LOKAŠ",
  "LOKAV",
  "LOKEV",
  "LOKOM",
  "LOKOV",
  "LOKUS",
  "LOKVE",
  "LOKVI",
  "LOMAČ",
  "LOMEČ",
  "LOMEN",
  "LOMIL",
  "LOMNA",
  "LOMNE",
  "LOMNI",
  "LOMNO",
  "LOMOM",
  "LOMOV",
  "LONCA",
  "LONCE",
  "LONCI",
  "LONCU",
  "LONEC",
  "LOPAM",
  "LOPAR",
  "LOPAT",
  "LOPEN",
  "LOPIC",
  "LOPNA",
  "LOPNE",
  "LOPNI",
  "LOPNO",
  "LOPOT",
  "LOPOV",
  "LOPUT",
  "LORDA",
  "LORDE",
  "LORDI",
  "LORDU",
  "LOSJA",
  "LOSJE",
  "LOSJI",
  "LOSOM",
  "LOSOS",
  "LOSOV",
  "LOŠČA",
  "LOŠČI",
  "LOŠČU",
  "LOŠKA",
  "LOŠKE",
  "LOŠKI",
  "LOŠKO",
  "LOTEČ",
  "LOTOM",
  "LOTOS",
  "LOTOV",
  "LOVAČ",
  "LOVCA",
  "LOVCE",
  "LOVCI",
  "LOVCU",
  "LOVEC",
  "LOVEČ",
  "LOVEN",
  "LOVIL",
  "LOVIM",
  "LOVIŠ",
  "LOVKA",
  "LOVKE",
  "LOVKI",
  "LOVKO",
  "LOVNA",
  "LOVNE",
  "LOVNI",
  "LOVNO",
  "LOVOM",
  "LOVOR",
  "LOVOV",
  "LOZAM",
  "LOZIN",
  "LOŽAM",
  "LOŽEN",
  "LOŽNA",
  "LOŽNE",
  "LOŽNI",
  "LOŽNO",
  "LUBEN",
  "LUBJA",
  "LUBJE",
  "LUBJU",
  "LUČAJ",
  "LUČCA",
  "LUČCE",
  "LUČCI",
  "LUČCO",
  "LUČEM",
  "LUČIC",
  "LUČIM",
  "LUČKA",
  "LUČKE",
  "LUČKI",
  "LUČKO",
  "LUČMA",
  "LUDIT",
  "LUGOM",
  "LUGOV",
  "LUKOM",
  "LUKOV",
  "LUKSA",
  "LUKSE",
  "LUKSI",
  "LUKSU",
  "LULAJ",
  "LULAL",
  "LULAŠ",
  "LUMEN",
  "LUMNA",
  "LUMNE",
  "LUMNI",
  "LUMNO",
  "LUMNU",
  "LUMPA",
  "LUMPE",
  "LUMPI",
  "LUMPU",
  "LUNAM",
  "LUNEK",
  "LUNET",
  "LUNIC",
  "LUNIK",
  "LUNIN",
  "LUNJA",
  "LUNJE",
  "LUNJI",
  "LUNJU",
  "LUNKA",
  "LUNKE",
  "LUNKI",
  "LUNKU",
  "LUNUL",
  "LUPAČ",
  "LUPAM",
  "LUPIL",
  "LUPIN",
  "LUPOM",
  "LUPUS",
  "LUSKA",
  "LUSKE",
  "LUSKI",
  "LUSKO",
  "LUŠČE",
  "LUŠČI",
  "LUŠKA",
  "LUŠKE",
  "LUŠKI",
  "LUŠKO",
  "LUTKA",
  "LUTKE",
  "LUTKI",
  "LUTKO",
  "LUŽAM",
  "LUŽEČ",
  "LUŽEN",
  "LUŽIC",
  "LUŽIL",
  "LUŽNA",
  "LUŽNE",
  "LUŽNI",
  "LUŽNO",
  "MACOL",
  "MACOS",
  "MAČEH",
  "MAČEK",
  "MAČET",
  "MAČIC",
  "MAČIN",
  "MAČJA",
  "MAČJE",
  "MAČJI",
  "MAČKA",
  "MAČKE",
  "MAČKI",
  "MAČKO",
  "MAČKU",
  "MADEŽ",
  "MADON",
  "MAFIJ",
  "MAFIN",
  "MAGIJ",
  "MAGIK",
  "MAGMA",
  "MAGME",
  "MAGMI",
  "MAGMO",
  "MAGOT",
  "MAGOV",
  "MAHAČ",
  "MAHAJ",
  "MAHAL",
  "MAHAM",
  "MAHAŠ",
  "MAHEK",
  "MAHKA",
  "MAHKE",
  "MAHKI",
  "MAHKU",
  "MAHNI",
  "MAHOM",
  "MAHOV",
  "MAJAJ",
  "MAJAM",
  "MAJAV",
  "MAJČK",
  "MAJEM",
  "MAJEV",
  "MAJIC",
  "MAJNA",
  "MAJNE",
  "MAJNI",
  "MAJNO",
  "MAJOR",
  "MAJOŠ",
  "MAKAA",
  "MAKAE",
  "MAKAI",
  "MAKAK",
  "MAKAM",
  "MAKAO",
  "MAKAU",
  "MAKET",
  "MAKIJ",
  "MAKOM",
  "MAKOV",
  "MAKRA",
  "MAKRE",
  "MAKRI",
  "MAKRO",
  "MAKRU",
  "MAKUL",
  "MALAT",
  "MALCE",
  "MALHA",
  "MALHE",
  "MALHI",
  "MALHO",
  "MALIC",
  "MALIK",
  "MALTA",
  "MALTE",
  "MALTI",
  "MALTO",
  "MALUS",
  "MAMAM",
  "MAMBA",
  "MAMBE",
  "MAMBI",
  "MAMBO",
  "MAMBU",
  "MAMEČ",
  "MAMIC",
  "MAMIL",
  "MAMIN",
  "MAMKA",
  "MAMKE",
  "MAMKI",
  "MAMKO",
  "MAMON",
  "MAMUT",
  "MANAM",
  "MANCE",
  "MANDI",
  "MANEČ",
  "MANEŽ",
  "MANGA",
  "MANGE",
  "MANGI",
  "MANGO",
  "MANGU",
  "MANIJ",
  "MANIR",
  "MANKA",
  "MANKE",
  "MANKI",
  "MANKO",
  "MANKU",
  "MANOM",
  "MANOV",
  "MANOZ",
  "MANTA",
  "MANTE",
  "MANTI",
  "MANTO",
  "MANUL",
  "MAPAM",
  "MAPEN",
  "MAPNA",
  "MAPNE",
  "MAPNI",
  "MAPNO",
  "MARAL",
  "MARCA",
  "MARCE",
  "MARCI",
  "MARCU",
  "MAREC",
  "MARIN",
  "MARKA",
  "MARKE",
  "MARKI",
  "MARKO",
  "MAROG",
  "MARON",
  "MARŠA",
  "MARŠE",
  "MARŠI",
  "MARŠU",
  "MARŽA",
  "MARŽE",
  "MARŽI",
  "MARŽO",
  "MASAM",
  "MASAŽ",
  "MASEN",
  "MASER",
  "MASIV",
  "MASKA",
  "MASKE",
  "MASKI",
  "MASKO",
  "MASLA",
  "MASLO",
  "MASLU",
  "MASNA",
  "MASNE",
  "MASNI",
  "MASNO",
  "MASTI",
  "MAŠAM",
  "MAŠEČ",
  "MAŠEN",
  "MAŠIL",
  "MAŠNO",
  "MAŠUJ",
  "MATEN",
  "MATER",
  "MATIC",
  "MATIJ",
  "MATNA",
  "MATNE",
  "MATNI",
  "MATNO",
  "MATOM",
  "MATOV",
  "MATUR",
  "MAVCA",
  "MAVCU",
  "MAVČE",
  "MAVČI",
  "MAVEC",
  "MAZAČ",
  "MAZAN",
  "MAZAV",
  "MAZDA",
  "MAZDE",
  "MAZDI",
  "MAZDO",
  "MAZIL",
  "MAZIV",
  "MAZUT",
  "MCALL",
  "MCBEE",
  "MCCUE",
  "MCFEE",
  "MCFIT",
  "MCGEE",
  "MCKEE",
  "MCKIE",
  "MCKIM",
  "MCLIN",
  "MCNAB",
  "MCNEE",
  "MCRAE",
  "MCREE",
  "MCVIE",
  "MECEN",
  "MEČAR",
  "MEČAV",
  "MEČEK",
  "MEČEM",
  "MEČEN",
  "MEČEŠ",
  "MEČEV",
  "MEČIC",
  "MEČKA",
  "MEČKE",
  "MEČKI",
  "MEČKU",
  "MEČNA",
  "MEČNE",
  "MEČNI",
  "MEČNO",
  "MEČUJ",
  "MEDAR",
  "MEDEČ",
  "MEDEL",
  "MEDEM",
  "MEDEN",
  "MEDEŠ",
  "MEDIC",
  "MEDIJ",
  "MEDIL",
  "MEDIM",
  "MEDIŠ",
  "MEDLA",
  "MEDLE",
  "MEDLI",
  "MEDLO",
  "MEDNA",
  "MEDNE",
  "MEDNI",
  "MEDNO",
  "MEDOČ",
  "MEDUL",
  "MEDUZ",
  "MEDVE",
  "MEGEL",
  "MEGLA",
  "MEGLE",
  "MEGLI",
  "MEGLO",
  "MEHAK",
  "MEHEK",
  "MEHKA",
  "MEHKE",
  "MEHKI",
  "MEHKO",
  "MEHOM",
  "MEHOV",
  "MEHUR",
  "MEIOZ",
  "MEJAK",
  "MEJAM",
  "MEJAŠ",
  "MEJEČ",
  "MEJEN",
  "MEJIC",
  "MEJIL",
  "MEJIM",
  "MEJIN",
  "MEJIŠ",
  "MEJNA",
  "MEJNE",
  "MEJNI",
  "MEJNO",
  "MEJOZ",
  "MEKET",
  "MELAS",
  "MELEN",
  "MELIS",
  "MELJA",
  "MELJI",
  "MELJU",
  "MELON",
  "MELOS",
  "MEMOM",
  "MEMOV",
  "MENAM",
  "MENCA",
  "MENEČ",
  "MENEK",
  "MENIC",
  "MENIH",
  "MENIL",
  "MENIM",
  "MENIŠ",
  "MENKA",
  "MENKE",
  "MENKI",
  "MENKU",
  "MENOR",
  "MENZA",
  "MENZE",
  "MENZI",
  "MENZO",
  "MERAM",
  "MEREČ",
  "MEREK",
  "MERIC",
  "MERIL",
  "MERIM",
  "MERIŠ",
  "MERKA",
  "MERKE",
  "MERKI",
  "MERKU",
  "MESAR",
  "MESCA",
  "MESCE",
  "MESCI",
  "MESCU",
  "MESEC",
  "MESEČ",
  "MESEN",
  "MESIJ",
  "MESIL",
  "MESNA",
  "MESNE",
  "MESNI",
  "MESNO",
  "MESTA",
  "MESTI",
  "MESTO",
  "MESTU",
  "MEŠAJ",
  "MEŠAL",
  "MEŠAM",
  "MEŠAN",
  "MEŠAŠ",
  "MEŠEN",
  "MEŠIČ",
  "MEŠIN",
  "MEŠKA",
  "MEŠKE",
  "MEŠKI",
  "MEŠKO",
  "METAN",
  "METEL",
  "METEM",
  "METEN",
  "METER",
  "METEŠ",
  "METEŽ",
  "METIJ",
  "METIL",
  "METIN",
  "METJA",
  "METJE",
  "METJI",
  "METJU",
  "METLA",
  "METLE",
  "METLI",
  "METLO",
  "METNA",
  "METNE",
  "METNI",
  "METNO",
  "METOČ",
  "METOD",
  "METOM",
  "METOP",
  "METOV",
  "METRA",
  "METRE",
  "METRI",
  "METRO",
  "METRU",
  "MEZDA",
  "MEZDE",
  "MEZDI",
  "MEZDO",
  "MEZEČ",
  "MEZEG",
  "MEZEL",
  "MEZGA",
  "MEZGE",
  "MEZGI",
  "MEZGO",
  "MEZGU",
  "MEZIM",
  "MEZIŠ",
  "MEZON",
  "MEŽIK",
  "MIAZA",
  "MIAZE",
  "MIAZI",
  "MIAZO",
  "MICEN",
  "MIČEN",
  "MIČNA",
  "MIČNE",
  "MIČNI",
  "MIČNO",
  "MIDVA",
  "MIDVE",
  "MIGAJ",
  "MIGAL",
  "MIGAM",
  "MIGAŠ",
  "MIGNE",
  "MIGNI",
  "MIGOM",
  "MIGOV",
  "MIJAV",
  "MIKAJ",
  "MIKAN",
  "MIKIC",
  "MIKOM",
  "MIKOV",
  "MIKOZ",
  "MIKRA",
  "MIKRE",
  "MIKRI",
  "MIKRO",
  "MIKRU",
  "MILEČ",
  "MILEN",
  "MILIC",
  "MILJA",
  "MILJI",
  "MILJO",
  "MILJU",
  "MILNA",
  "MILNE",
  "MILNI",
  "MILNO",
  "MILOB",
  "MILOM",
  "MIMIK",
  "MIMOZ",
  "MINAM",
  "MINEM",
  "MINER",
  "MINEŠ",
  "MINIJ",
  "MINIL",
  "MINKA",
  "MINKE",
  "MINKI",
  "MINKU",
  "MINUL",
  "MINUS",
  "MINUT",
  "MIOID",
  "MIOMA",
  "MIOME",
  "MIOMI",
  "MIOMU",
  "MIONA",
  "MIONE",
  "MIONI",
  "MIONU",
  "MIOPA",
  "MIOPE",
  "MIOPI",
  "MIOPU",
  "MIOZA",
  "MIOZE",
  "MIOZI",
  "MIOZO",
  "MIREČ",
  "MIREN",
  "MIRNA",
  "MIRNE",
  "MIRNI",
  "MIRNO",
  "MIROM",
  "MIROV",
  "MIRTA",
  "MIRTE",
  "MIRTI",
  "MIRTO",
  "MIRUJ",
  "MISAL",
  "MISEL",
  "MISIJ",
  "MISLE",
  "MISLI",
  "MIŠAK",
  "MIŠAR",
  "MIŠEK",
  "MIŠIC",
  "MIŠIM",
  "MIŠJA",
  "MIŠJE",
  "MIŠJI",
  "MIŠKA",
  "MIŠKE",
  "MIŠKI",
  "MIŠKO",
  "MIŠKU",
  "MIŠMA",
  "MITER",
  "MITOM",
  "MITOV",
  "MITOZ",
  "MITRA",
  "MITRE",
  "MITRI",
  "MITRO",
  "MIVKA",
  "MIVKE",
  "MIVKI",
  "MIVKO",
  "MIZAM",
  "MIZAR",
  "MIZEN",
  "MIZIC",
  "MIZIJ",
  "MIZNA",
  "MIZNE",
  "MIZNI",
  "MIZNO",
  "MIŽAL",
  "MIŽEČ",
  "MIŽIM",
  "MIŽIŠ",
  "MLADA",
  "MLADE",
  "MLADI",
  "MLADO",
  "MLAJA",
  "MLAJE",
  "MLAJI",
  "MLAJO",
  "MLAJU",
  "MLAKA",
  "MLAKE",
  "MLAKI",
  "MLAKO",
  "MLASK",
  "MLATA",
  "MLATE",
  "MLATI",
  "MLATU",
  "MLEKA",
  "MLEKI",
  "MLEKO",
  "MLEKU",
  "MLETA",
  "MLETE",
  "MLETI",
  "MLETO",
  "MLINA",
  "MLINE",
  "MLINI",
  "MLINU",
  "MNENJ",
  "MNOGA",
  "MNOGE",
  "MNOGI",
  "MNOGO",
  "MNOŽE",
  "MNOŽI",
  "MOAMA",
  "MOČAN",
  "MOČEM",
  "MOČEN",
  "MOČNA",
  "MOČNE",
  "MOČNI",
  "MOČNO",
  "MODAM",
  "MODEL",
  "MODEM",
  "MODEN",
  "MODER",
  "MODLA",
  "MODLE",
  "MODLI",
  "MODLU",
  "MODNA",
  "MODNE",
  "MODNI",
  "MODNO",
  "MODOM",
  "MODRA",
  "MODRE",
  "MODRI",
  "MODRO",
  "MODUL",
  "MODUS",
  "MOFET",
  "MOGEL",
  "MOGLA",
  "MOGLE",
  "MOGLI",
  "MOGLO",
  "MOGOČ",
  "MOGUL",
  "MOHER",
  "MOINA",
  "MOINE",
  "MOINI",
  "MOINO",
  "MOKAJ",
  "MOKAL",
  "MOKAM",
  "MOKAR",
  "MOKAŠ",
  "MOKER",
  "MOKOŽ",
  "MOKRA",
  "MOKRC",
  "MOKRE",
  "MOKRI",
  "MOKRO",
  "MOLAR",
  "MOLČE",
  "MOLČI",
  "MOLEČ",
  "MOLEL",
  "MOLIK",
  "MOLIL",
  "MOLIM",
  "MOLIŠ",
  "MOLJA",
  "MOLJE",
  "MOLJI",
  "MOLJU",
  "MOLKA",
  "MOLKE",
  "MOLKI",
  "MOLKU",
  "MOLOH",
  "MOLOM",
  "MOLOV",
  "MOLZE",
  "MOLZI",
  "MOLŽA",
  "MOLŽE",
  "MOLŽI",
  "MOLŽO",
  "MONAD",
  "MONAL",
  "MONAM",
  "MONDA",
  "MONDE",
  "MONDI",
  "MONDU",
  "MONGA",
  "MONGE",
  "MONGI",
  "MONGO",
  "MONIN",
  "MOPSA",
  "MOPSE",
  "MOPSI",
  "MOPSU",
  "MORAL",
  "MORAM",
  "MORAŠ",
  "MOREČ",
  "MOREM",
  "MOREN",
  "MOREŠ",
  "MORIJ",
  "MORIL",
  "MORIM",
  "MORIŠ",
  "MORJA",
  "MORJE",
  "MORJI",
  "MORJU",
  "MORUL",
  "MOSSO",
  "MOSTA",
  "MOSTU",
  "MOŠEJ",
  "MOŠKA",
  "MOŠKE",
  "MOŠKI",
  "MOŠKO",
  "MOŠTA",
  "MOŠTU",
  "MOŠUS",
  "MOTAN",
  "MOTEČ",
  "MOTEK",
  "MOTEL",
  "MOTEN",
  "MOTET",
  "MOTIK",
  "MOTIL",
  "MOTIM",
  "MOTIŠ",
  "MOTIV",
  "MOTKA",
  "MOTKE",
  "MOTKI",
  "MOTKU",
  "MOTNA",
  "MOTNE",
  "MOTNI",
  "MOTNO",
  "MOTOM",
  "MOTOR",
  "MOTOV",
  "MOTRI",
  "MOZEG",
  "MOZGA",
  "MOZGE",
  "MOZGI",
  "MOZGU",
  "MOŽAČ",
  "MOŽAK",
  "MOŽAT",
  "MOŽEČ",
  "MOŽEK",
  "MOŽEM",
  "MOŽEN",
  "MOŽEV",
  "MOŽIC",
  "MOŽIL",
  "MOŽIM",
  "MOŽIN",
  "MOŽIŠ",
  "MOŽJE",
  "MOŽKA",
  "MOŽKU",
  "MOŽNA",
  "MOŽNE",
  "MOŽNI",
  "MOŽNO",
  "MOŽUJ",
  "MRAKA",
  "MRAKU",
  "MRAZA",
  "MRAZE",
  "MRAZI",
  "MRAZU",
  "MRCIN",
  "MRČAV",
  "MRČES",
  "MRDAJ",
  "MRDAL",
  "MRDAM",
  "MRDAŠ",
  "MRDNE",
  "MRDNI",
  "MREJO",
  "MREMO",
  "MRENA",
  "MRENE",
  "MRENI",
  "MRENO",
  "MREST",
  "MRETA",
  "MRETE",
  "MRETI",
  "MREVA",
  "MREŽA",
  "MREŽE",
  "MREŽI",
  "MREŽO",
  "MRHAM",
  "MRHAR",
  "MRHIC",
  "MRHIN",
  "MRIMO",
  "MRITA",
  "MRITE",
  "MRIVA",
  "MRJEM",
  "MRJEŠ",
  "MRKAČ",
  "MRKNE",
  "MRKNI",
  "MRKOM",
  "MRKOV",
  "MRLEČ",
  "MRLEL",
  "MRLIČ",
  "MRLIM",
  "MRLIŠ",
  "MRMOT",
  "MRMRA",
  "MROČA",
  "MROČE",
  "MROČI",
  "MROŽA",
  "MROŽE",
  "MROŽI",
  "MROŽU",
  "MRSAM",
  "MRŠAV",
  "MRŠČI",
  "MRŠIL",
  "MRŠIM",
  "MRŠIŠ",
  "MRŠKA",
  "MRŠKE",
  "MRŠKI",
  "MRŠKO",
  "MRTEV",
  "MRTVA",
  "MRTVE",
  "MRTVI",
  "MRTVO",
  "MRVIC",
  "MRZEČ",
  "MRZEK",
  "MRZEL",
  "MRZEN",
  "MRZKA",
  "MRZKE",
  "MRZKI",
  "MRZKO",
  "MRZLA",
  "MRZLE",
  "MRZLI",
  "MRZLO",
  "MTDNA",
  "MTDNK",
  "MUCAM",
  "MUCEK",
  "MUCEM",
  "MUCEV",
  "MUCIC",
  "MUCIK",
  "MUCIN",
  "MUCKA",
  "MUCKE",
  "MUCKI",
  "MUCKO",
  "MUCKU",
  "MUČEČ",
  "MUČEN",
  "MUČNA",
  "MUČNE",
  "MUČNI",
  "MUČNO",
  "MUDEČ",
  "MUDER",
  "MUDRA",
  "MUDRE",
  "MUDRI",
  "MUDRO",
  "MUFAM",
  "MUFOM",
  "MUFOV",
  "MUFTI",
  "MUHAJ",
  "MUHAL",
  "MUHAM",
  "MUHAR",
  "MUHAŠ",
  "MUHAV",
  "MUHIC",
  "MUKAJ",
  "MUKAL",
  "MUKAM",
  "MUKAŠ",
  "MUKNE",
  "MUKNI",
  "MULAM",
  "MULAT",
  "MULAŽ",
  "MULCA",
  "MULCE",
  "MULCI",
  "MULCU",
  "MULČI",
  "MULEC",
  "MULEČ",
  "MULIL",
  "MULIM",
  "MULIN",
  "MULIŠ",
  "MULJA",
  "MULJE",
  "MULJI",
  "MULJU",
  "MULOV",
  "MUMIJ",
  "MUMPS",
  "MUNCA",
  "MUNCE",
  "MUNCI",
  "MUNCU",
  "MUNEC",
  "MUNGA",
  "MUNGE",
  "MUNGI",
  "MUNGO",
  "MUNGU",
  "MUNIK",
  "MURAL",
  "MUREN",
  "MURKA",
  "MURKE",
  "MURKI",
  "MURKO",
  "MURNA",
  "MURNE",
  "MURNI",
  "MURNU",
  "MURVA",
  "MURVE",
  "MURVI",
  "MURVO",
  "MUSAK",
  "MUSLI",
  "MUŠAT",
  "MUŠIC",
  "MUŠJA",
  "MUŠJE",
  "MUŠJI",
  "MUŠKA",
  "MUŠKE",
  "MUŠKI",
  "MUŠKO",
  "MUTAZ",
  "MUTCA",
  "MUTCE",
  "MUTCI",
  "MUTCU",
  "MUTEC",
  "MUTON",
  "MUTSU",
  "MUZAM",
  "MUZEJ",
  "MUZGA",
  "MUZGE",
  "MUZGI",
  "MUZGO",
  "MUŽEČ",
  "MUŽIK",
  "MUŽIL",
  "MUŽIM",
  "MUŽIŠ",
  "NABAV",
  "NABIJ",
  "NABIT",
  "NABOB",
  "NABOD",
  "NABOJ",
  "NABOR",
  "NACHR",
  "NAČEL",
  "NAČET",
  "NAČIN",
  "NAČNI",
  "NAČRT",
  "NADAM",
  "NADAV",
  "NADET",
  "NADEV",
  "NADIH",
  "NADIR",
  "NADRI",
  "NADRT",
  "NADUH",
  "NADUR",
  "NADUT",
  "NAFTA",
  "NAFTE",
  "NAFTI",
  "NAFTO",
  "NAGCA",
  "NAGCE",
  "NAGCI",
  "NAGCU",
  "NAGEC",
  "NAGEL",
  "NAGIB",
  "NAGIC",
  "NAGLA",
  "NAGLE",
  "NAGLI",
  "NAGLO",
  "NAGNI",
  "NAGON",
  "NAHIJ",
  "NAHOD",
  "NAIRA",
  "NAIRE",
  "NAIRI",
  "NAIRO",
  "NAIVA",
  "NAIVE",
  "NAIVI",
  "NAIVK",
  "NAIVO",
  "NAJAD",
  "NAJAV",
  "NAJDB",
  "NAJDI",
  "NAJEL",
  "NAJEM",
  "NAJET",
  "NAJID",
  "NAJIN",
  "NAKAN",
  "NAKAR",
  "NAKAZ",
  "NAKIT",
  "NAKUJ",
  "NAKUP",
  "NALET",
  "NALIJ",
  "NALIL",
  "NALIT",
  "NALIV",
  "NALOG",
  "NALOM",
  "NAMAZ",
  "NAMEN",
  "NAMER",
  "NAMIG",
  "NAMOK",
  "NAMOT",
  "NANDU",
  "NANOM",
  "NANOS",
  "NANOV",
  "NAPAD",
  "NAPAK",
  "NAPAM",
  "NAPER",
  "NAPET",
  "NAPEV",
  "NAPHA",
  "NAPIJ",
  "NAPIL",
  "NAPIS",
  "NAPIT",
  "NAPNI",
  "NAPOJ",
  "NAPOK",
  "NAPOL",
  "NAPON",
  "NAPOR",
  "NAPŠE",
  "NAPŠI",
  "NAPUH",
  "NARAV",
  "NARED",
  "NAREK",
  "NARIJ",
  "NARIL",
  "NARIS",
  "NARIV",
  "NAROD",
  "NAROK",
  "NARTA",
  "NARTE",
  "NARTI",
  "NARTU",
  "NASAD",
  "NASEJ",
  "NASEK",
  "NASIP",
  "NASPI",
  "NASUJ",
  "NASUT",
  "NAŠIJ",
  "NAŠIT",
  "NATIČ",
  "NATIS",
  "NATKE",
  "NATKI",
  "NATRE",
  "NATRI",
  "NATRL",
  "NATRT",
  "NATUR",
  "NAUČI",
  "NAUKA",
  "NAUKE",
  "NAUKI",
  "NAUKU",
  "NAVAD",
  "NAVAL",
  "NAVAR",
  "NAVEZ",
  "NAVIJ",
  "NAVIT",
  "NAVJA",
  "NAVJE",
  "NAVJU",
  "NAVOJ",
  "NAVOR",
  "NAVOZ",
  "NAVRE",
  "NAVRI",
  "NAZAJ",
  "NAZIV",
  "NAZOR",
  "NAŽEL",
  "NAŽET",
  "NEBEL",
  "NEBEN",
  "NEBES",
  "NEBIN",
  "NEBNA",
  "NEBNE",
  "NEBNI",
  "NEBNO",
  "NEBOM",
  "NECEL",
  "NEČAK",
  "NEČKE",
  "NEDRA",
  "NEDRC",
  "NEGAM",
  "NEGUJ",
  "NEHAJ",
  "NEHAL",
  "NEHAM",
  "NEHAŠ",
  "NEHUJ",
  "NEJAZ",
  "NEJUD",
  "NEKAJ",
  "NEKAK",
  "NEKAM",
  "NEKDO",
  "NEKJE",
  "NEKOČ",
  "NELEP",
  "NEMČE",
  "NEMČI",
  "NEMEČ",
  "NEMEL",
  "NEMIL",
  "NEMIM",
  "NEMIR",
  "NEMIŠ",
  "NEMOČ",
  "NEONA",
  "NEONK",
  "NEONU",
  "NEPER",
  "NERAD",
  "NERCA",
  "NERCE",
  "NERCI",
  "NERCU",
  "NERED",
  "NERGA",
  "NEROD",
  "NESEN",
  "NESIT",
  "NESNA",
  "NESNE",
  "NESNI",
  "NESNO",
  "NESOČ",
  "NEŠKE",
  "NETEČ",
  "NETEK",
  "NETIL",
  "NETIM",
  "NETIŠ",
  "NETIV",
  "NETKA",
  "NETKE",
  "NETKI",
  "NETKO",
  "NETKU",
  "NEVEN",
  "NEVER",
  "NEVMA",
  "NEVME",
  "NEVMI",
  "NEVMO",
  "NEVUS",
  "NEŽAM",
  "NEŽEN",
  "NEŽIC",
  "NEŽID",
  "NEŽIN",
  "NEŽIV",
  "NEŽNA",
  "NEŽNE",
  "NEŽNI",
  "NEŽNO",
  "NIALA",
  "NIALE",
  "NIALI",
  "NIALO",
  "NIČEČ",
  "NIČEL",
  "NIČEM",
  "NIČEN",
  "NIČET",
  "NIČEV",
  "NIČIL",
  "NIČIM",
  "NIČIŠ",
  "NIČLA",
  "NIČLE",
  "NIČLI",
  "NIČLO",
  "NIČNA",
  "NIČNE",
  "NIČNI",
  "NIČNO",
  "NIČTA",
  "NIČTE",
  "NIČTI",
  "NIČTO",
  "NIHAJ",
  "NIHAL",
  "NIHAM",
  "NIHAŠ",
  "NIHČE",
  "NIKAB",
  "NIMAM",
  "NIMAŠ",
  "NIMFA",
  "NIMFE",
  "NIMFI",
  "NIMFO",
  "NINIC",
  "NISEM",
  "NISMO",
  "NISTA",
  "NISTE",
  "NISVA",
  "NIŠAM",
  "NIŠEN",
  "NIŠKA",
  "NIŠKE",
  "NIŠKI",
  "NIŠKO",
  "NIŠNA",
  "NIŠNE",
  "NIŠNI",
  "NIŠNO",
  "NITAR",
  "NITAV",
  "NITEN",
  "NITIJ",
  "NITIM",
  "NITJA",
  "NITJE",
  "NITJI",
  "NITJU",
  "NITKA",
  "NITKE",
  "NITKI",
  "NITKO",
  "NITMA",
  "NITNA",
  "NITNE",
  "NITNI",
  "NITNO",
  "NIZAJ",
  "NIZAL",
  "NIZAM",
  "NIZAŠ",
  "NIZEK",
  "NIZKA",
  "NIZKE",
  "NIZKI",
  "NIZKO",
  "NIZOM",
  "NIZOV",
  "NIŽAJ",
  "NIŽAV",
  "NIŽEK",
  "NIŽEM",
  "NIŽEŠ",
  "NIŽIN",
  "NIŽJA",
  "NIŽJE",
  "NIŽJI",
  "NIŽKA",
  "NIŽKE",
  "NIŽKI",
  "NIŽKU",
  "NJAMI",
  "NJEGA",
  "NJEMU",
  "NJENA",
  "NJENE",
  "NJENI",
  "NJENO",
  "NJIJU",
  "NJIMA",
  "NJIVA",
  "NJIVE",
  "NJIVI",
  "NJIVK",
  "NJIVO",
  "NJOKA",
  "NJOKE",
  "NJOKI",
  "NJOKU",
  "NJORK",
  "NJUHA",
  "NJUNA",
  "NJUNE",
  "NJUNI",
  "NJUNO",
  "NOCOJ",
  "NOČEČ",
  "NOČEM",
  "NOČEN",
  "NOČEŠ",
  "NOČNA",
  "NOČNE",
  "NOČNI",
  "NOČNO",
  "NOČUJ",
  "NODUL",
  "NOGAM",
  "NOGIC",
  "NOHTA",
  "NOHTE",
  "NOHTI",
  "NOHTU",
  "NOJEM",
  "NOJEV",
  "NOKOT",
  "NOKSA",
  "NOKSE",
  "NOKSI",
  "NOKSO",
  "NOMAD",
  "NONAM",
  "NONET",
  "NONIC",
  "NONIJ",
  "NONIN",
  "NONOM",
  "NONOV",
  "NORCA",
  "NORCE",
  "NORCI",
  "NORCU",
  "NOREC",
  "NOREČ",
  "NOREL",
  "NORIC",
  "NORIJ",
  "NORIM",
  "NORIŠ",
  "NORKA",
  "NORKE",
  "NORKI",
  "NORKO",
  "NORMA",
  "NORME",
  "NORMI",
  "NORMO",
  "NOSAČ",
  "NOSAN",
  "NOSAT",
  "NOSEČ",
  "NOSEK",
  "NOSEN",
  "NOSIL",
  "NOSKA",
  "NOSKE",
  "NOSKI",
  "NOSKU",
  "NOSNA",
  "NOSNE",
  "NOSNI",
  "NOSNO",
  "NOSOV",
  "NOŠAM",
  "NOŠEN",
  "NOŠKA",
  "NOŠKE",
  "NOŠKI",
  "NOŠKO",
  "NOTAM",
  "NOTAR",
  "NOTEN",
  "NOTER",
  "NOTES",
  "NOTIC",
  "NOTNA",
  "NOTNE",
  "NOTNI",
  "NOTNO",
  "NOTRI",
  "NOVAM",
  "NOVCA",
  "NOVCE",
  "NOVCI",
  "NOVCU",
  "NOVEC",
  "NOVEL",
  "NOVIC",
  "NOŽAR",
  "NOŽEK",
  "NOŽEM",
  "NOŽEN",
  "NOŽEV",
  "NOŽIC",
  "NOŽIČ",
  "NOŽKA",
  "NOŽKE",
  "NOŽKI",
  "NOŽKU",
  "NOŽNA",
  "NOŽNE",
  "NOŽNI",
  "NOŽNO",
  "NRAVA",
  "NRAVE",
  "NRAVI",
  "NUDEČ",
  "NUDEN",
  "NUGAT",
  "NUJAM",
  "NUJEN",
  "NUJNA",
  "NUJNE",
  "NUJNI",
  "NUJNO",
  "NUNAM",
  "NUNIN",
  "NUNKA",
  "NUNKE",
  "NUNKI",
  "NUNKO",
  "OAZAM",
  "OAZEN",
  "OAZIC",
  "OAZNA",
  "OAZNE",
  "OAZNI",
  "OAZNO",
  "OBADA",
  "OBADE",
  "OBADI",
  "OBADU",
  "OBALA",
  "OBALE",
  "OBALI",
  "OBALO",
  "OBARA",
  "OBARE",
  "OBARI",
  "OBARO",
  "OBČAN",
  "OBČIL",
  "OBČIN",
  "OBDAJ",
  "OBDAN",
  "OBEDA",
  "OBEDE",
  "OBEDI",
  "OBELI",
  "OBEMA",
  "OBERI",
  "OBESA",
  "OBESE",
  "OBESI",
  "OBESO",
  "OBETA",
  "OBETE",
  "OBETI",
  "OBETU",
  "OBHOD",
  "OBIDI",
  "OBIJA",
  "OBIJE",
  "OBILA",
  "OBILE",
  "OBILI",
  "OBILO",
  "OBISK",
  "OBITA",
  "OBITE",
  "OBITI",
  "OBITO",
  "OBJAV",
  "OBJED",
  "OBJEJ",
  "OBJEM",
  "OBJEŠ",
  "OBJET",
  "OBLAJ",
  "OBLAK",
  "OBLAL",
  "OBLAM",
  "OBLAN",
  "OBLAŠ",
  "OBLAT",
  "OBLEK",
  "OBLET",
  "OBLIC",
  "OBLIČ",
  "OBLIJ",
  "OBLIK",
  "OBLIL",
  "OBLIM",
  "OBLIN",
  "OBLIŠ",
  "OBLIT",
  "OBLIV",
  "OBLIŽ",
  "OBLOG",
  "OBLOK",
  "OBNOV",
  "OBOAM",
  "OBODA",
  "OBODE",
  "OBODI",
  "OBODU",
  "OBOJA",
  "OBOJE",
  "OBOJI",
  "OBOJK",
  "OBOKA",
  "OBOKE",
  "OBOKI",
  "OBOKU",
  "OBOLA",
  "OBOLE",
  "OBOLI",
  "OBOLU",
  "OBORA",
  "OBORE",
  "OBORI",
  "OBORO",
  "OBORU",
  "OBRAB",
  "OBRAD",
  "OBRAN",
  "OBRAT",
  "OBRAZ",
  "OBRCA",
  "OBRED",
  "OBREZ",
  "OBRHA",
  "OBRHE",
  "OBRHI",
  "OBRHU",
  "OBRIJ",
  "OBRIS",
  "OBRIT",
  "OBRNE",
  "OBRNI",
  "OBROB",
  "OBROČ",
  "OBROD",
  "OBROK",
  "OBRTI",
  "OBRUS",
  "OBRVI",
  "OBSEG",
  "OBSEJ",
  "OBSEV",
  "OBSIJ",
  "OBSKA",
  "OBSKE",
  "OBSKI",
  "OBSKO",
  "OBSUJ",
  "OBSUL",
  "OBSUT",
  "OBŠIJ",
  "OBŠIL",
  "OBŠIT",
  "OBŠIV",
  "OBTOK",
  "OBUDI",
  "OBUJK",
  "OBUPA",
  "OBUPE",
  "OBUPI",
  "OBUPU",
  "OBUTA",
  "OBUTE",
  "OBUTI",
  "OBUTO",
  "OBVEZ",
  "OBVOD",
  "OBVOZ",
  "OBZIR",
  "OBŽDI",
  "OBŽEL",
  "OBŽGE",
  "OBŽGI",
  "OBŽIG",
  "OBŽRE",
  "OBŽRI",
  "OBŽRL",
  "OBŽRT",
  "OCEAN",
  "OCEDI",
  "OCENA",
  "OCENE",
  "OCENI",
  "OCENO",
  "OCVEL",
  "OCVRI",
  "OCVRT",
  "OČAKA",
  "OČAKE",
  "OČAKI",
  "OČAKU",
  "OČALA",
  "OČCEM",
  "OČCEV",
  "OČESA",
  "OČESC",
  "OČESI",
  "OČESU",
  "OČEŠE",
  "OČEŠI",
  "OČETA",
  "OČETE",
  "OČETI",
  "OČETU",
  "OČEVA",
  "OČEVE",
  "OČEVI",
  "OČEVO",
  "OČIJA",
  "OČIJE",
  "OČIJI",
  "OČIJU",
  "OČIMA",
  "OČIME",
  "OČIMI",
  "OČIMU",
  "OČIŠČ",
  "OČITA",
  "OČKAM",
  "OČKOM",
  "OČKOV",
  "OČNIC",
  "OČOHA",
  "OČRNI",
  "OČRTA",
  "OČRTE",
  "OČRTI",
  "OČRTU",
  "OČUVA",
  "ODBIJ",
  "ODBIL",
  "ODBIR",
  "ODBIT",
  "ODBOJ",
  "ODBOR",
  "ODCEP",
  "ODDAJ",
  "ODDAN",
  "ODDIH",
  "ODEJA",
  "ODEJE",
  "ODEJI",
  "ODEJO",
  "ODENI",
  "ODEON",
  "ODERI",
  "ODETA",
  "ODETE",
  "ODETI",
  "ODETO",
  "ODGON",
  "ODHOD",
  "ODIDE",
  "ODIDI",
  "ODIMI",
  "ODITI",
  "ODJAV",
  "ODJEJ",
  "ODJEK",
  "ODJEM",
  "ODJEŠ",
  "ODJUG",
  "ODKAZ",
  "ODKOP",
  "ODKOS",
  "ODKUJ",
  "ODKUP",
  "ODLET",
  "ODLIJ",
  "ODLIK",
  "ODLIT",
  "ODLIV",
  "ODLOG",
  "ODLOK",
  "ODLOM",
  "ODLOV",
  "ODMER",
  "ODMET",
  "ODMEV",
  "ODMIK",
  "ODMOR",
  "ODMRE",
  "ODMRI",
  "ODMRL",
  "ODMRT",
  "ODNOS",
  "ODPAD",
  "ODPET",
  "ODPEV",
  "ODPIJ",
  "ODPIL",
  "ODPIS",
  "ODPIT",
  "ODPNI",
  "ODPOJ",
  "ODPOR",
  "ODPRE",
  "ODPRI",
  "ODPRL",
  "ODPRO",
  "ODPRT",
  "ODRAJ",
  "ODRAL",
  "ODRAM",
  "ODRAN",
  "ODRAŠ",
  "ODRAZ",
  "ODRCA",
  "ODRCE",
  "ODRCI",
  "ODRCU",
  "ODRED",
  "ODREK",
  "ODREZ",
  "ODRIV",
  "ODROM",
  "ODROV",
  "ODRSA",
  "ODRTA",
  "ODRTE",
  "ODRTI",
  "ODRTO",
  "ODRZA",
  "ODSEK",
  "ODSEV",
  "ODSIJ",
  "ODSPI",
  "ODSUJ",
  "ODSUL",
  "ODSUN",
  "ODŠEL",
  "ODŠLA",
  "ODŠLE",
  "ODŠLI",
  "ODŠLO",
  "ODTIS",
  "ODTOK",
  "ODUČI",
  "ODVEČ",
  "ODVEZ",
  "ODVIJ",
  "ODVIT",
  "ODVOD",
  "ODVOZ",
  "ODVRE",
  "ODVRI",
  "ODVRL",
  "ODVRT",
  "ODZIV",
  "ODŽEL",
  "ODŽET",
  "ODŽGE",
  "ODŽGI",
  "ODŽIG",
  "ODŽRE",
  "ODŽRI",
  "ODŽRL",
  "OFIAZ",
  "OGABA",
  "OGABE",
  "OGABI",
  "OGABO",
  "OGELN",
  "OGENJ",
  "OGLAR",
  "OGLAS",
  "OGLAT",
  "OGLAV",
  "OGLED",
  "OGLEJ",
  "OGLEN",
  "OGLJA",
  "OGLJE",
  "OGLJU",
  "OGLOM",
  "OGLOV",
  "OGNJA",
  "OGNJE",
  "OGNJI",
  "OGNJU",
  "OGOLI",
  "OGONA",
  "OGONE",
  "OGONI",
  "OGONU",
  "OGORI",
  "OGRAD",
  "OGRAJ",
  "OGRCA",
  "OGRCE",
  "OGRCI",
  "OGRCU",
  "OGREJ",
  "OGRET",
  "OGRIC",
  "OGRIZ",
  "OGRNE",
  "OGRNI",
  "OGULI",
  "OHAJE",
  "OHAJO",
  "OHALA",
  "OHALE",
  "OHALI",
  "OHALO",
  "OHAMO",
  "OHANJ",
  "OHATA",
  "OHATE",
  "OHATI",
  "OHAVA",
  "OHLIP",
  "OHOLA",
  "OHOLE",
  "OHOLI",
  "OHOLO",
  "OIDIJ",
  "OJAČI",
  "OJESA",
  "OJESI",
  "OJESU",
  "OJICE",
  "OJNIC",
  "OJUŽI",
  "OKADI",
  "OKAJA",
  "OKALI",
  "OKAPI",
  "OKATA",
  "OKATE",
  "OKATI",
  "OKATO",
  "OKENC",
  "OKLAL",
  "OKLAN",
  "OKLAR",
  "OKLEP",
  "OKLIC",
  "OKLOM",
  "OKLOV",
  "OKNIC",
  "OKNOM",
  "OKOLI",
  "OKOPA",
  "OKOPE",
  "OKOPI",
  "OKOPU",
  "OKORI",
  "OKOVA",
  "OKOVE",
  "OKOVI",
  "OKOVU",
  "OKRAJ",
  "OKRAM",
  "OKRAS",
  "OKRCA",
  "OKRHA",
  "OKRNE",
  "OKRNI",
  "OKROG",
  "OKROV",
  "OKSID",
  "OKTAN",
  "OKTAV",
  "OKTET",
  "OKUNA",
  "OKUNE",
  "OKUNI",
  "OKUNU",
  "OKUSA",
  "OKUSE",
  "OKUSI",
  "OKUSU",
  "OKUŽB",
  "OKUŽI",
  "OKVAR",
  "OKVIR",
  "OLEAT",
  "OLEIN",
  "OLEVI",
  "OLIKA",
  "OLIKE",
  "OLIKI",
  "OLIKO",
  "OLIVA",
  "OLIVE",
  "OLIVI",
  "OLIVO",
  "OLJAR",
  "OLJEČ",
  "OLJEM",
  "OLJEN",
  "OLJKA",
  "OLJKE",
  "OLJKI",
  "OLJKO",
  "OLJNA",
  "OLJNE",
  "OLJNI",
  "OLJNO",
  "OLTAR",
  "OLUPI",
  "OLUŽI",
  "OMAGA",
  "OMAJI",
  "OMAKA",
  "OMAKE",
  "OMAKI",
  "OMAKO",
  "OMAMA",
  "OMAME",
  "OMAMI",
  "OMAMO",
  "OMANA",
  "OMANE",
  "OMANI",
  "OMANU",
  "OMARA",
  "OMARE",
  "OMARI",
  "OMARO",
  "OMEČE",
  "OMEDE",
  "OMEDI",
  "OMEGA",
  "OMEGE",
  "OMEGI",
  "OMEGO",
  "OMEJA",
  "OMEJE",
  "OMEJI",
  "OMEJU",
  "OMELA",
  "OMELC",
  "OMELE",
  "OMELI",
  "OMELO",
  "OMELU",
  "OMEMB",
  "OMENA",
  "OMENE",
  "OMENI",
  "OMENU",
  "OMETA",
  "OMETE",
  "OMETI",
  "OMETO",
  "OMETU",
  "OMIKA",
  "OMIKO",
  "OMILI",
  "OMLET",
  "OMOČI",
  "OMOMA",
  "OMOTA",
  "OMOTE",
  "OMOTI",
  "OMOTU",
  "OMSKA",
  "OMSKE",
  "OMSKI",
  "OMSKO",
  "OMULI",
  "ONALE",
  "ONEGA",
  "ONELE",
  "ONEMI",
  "ONIKA",
  "ONIKS",
  "ONILE",
  "ONOLE",
  "ONUČA",
  "ONUČE",
  "ONUČI",
  "ONUČO",
  "OOCIT",
  "OOLIT",
  "OPAJA",
  "OPALA",
  "OPALE",
  "OPALI",
  "OPALU",
  "OPANK",
  "OPARI",
  "OPART",
  "OPAST",
  "OPAŠE",
  "OPAŠI",
  "OPATA",
  "OPATE",
  "OPATI",
  "OPATU",
  "OPAZI",
  "OPAZK",
  "OPAŽA",
  "OPAŽE",
  "OPAŽI",
  "OPAŽU",
  "OPECI",
  "OPEKA",
  "OPEKE",
  "OPEKI",
  "OPEKO",
  "OPELA",
  "OPELE",
  "OPELI",
  "OPELO",
  "OPENI",
  "OPERA",
  "OPERE",
  "OPERI",
  "OPERO",
  "OPEŠA",
  "OPETA",
  "OPETE",
  "OPETI",
  "OPETO",
  "OPEVA",
  "OPHAJ",
  "OPHAL",
  "OPHAM",
  "OPHAN",
  "OPHAŠ",
  "OPIAT",
  "OPICA",
  "OPICE",
  "OPICI",
  "OPICO",
  "OPIČK",
  "OPIHA",
  "OPIHE",
  "OPIHI",
  "OPIHU",
  "OPIJA",
  "OPIJU",
  "OPIKA",
  "OPIRA",
  "OPISA",
  "OPISE",
  "OPISI",
  "OPISU",
  "OPIŠE",
  "OPIŠI",
  "OPITA",
  "OPITE",
  "OPITI",
  "OPITO",
  "OPLAT",
  "OPLEL",
  "OPLEN",
  "OPLET",
  "OPLOM",
  "OPLOV",
  "OPNAM",
  "OPNEM",
  "OPNEŠ",
  "OPOJA",
  "OPOJE",
  "OPOJI",
  "OPOJU",
  "OPOKA",
  "OPOKE",
  "OPOKI",
  "OPOKO",
  "OPOLA",
  "OPOLE",
  "OPOLI",
  "OPOLO",
  "OPOLU",
  "OPOMB",
  "OPONK",
  "OPORA",
  "OPORE",
  "OPORI",
  "OPORO",
  "OPPNP",
  "OPRAN",
  "OPREM",
  "OPREŠ",
  "OPRHA",
  "OPRHE",
  "OPRHI",
  "OPRHU",
  "OPRLA",
  "OPRLE",
  "OPRLI",
  "OPRLO",
  "OPROD",
  "OPROG",
  "OPRTA",
  "OPRTE",
  "OPRTI",
  "OPRTO",
  "OPSIN",
  "OPSUJ",
  "OPTIK",
  "OPUSA",
  "OPUSE",
  "OPUSI",
  "OPUSU",
  "ORAČA",
  "ORAČE",
  "ORAČI",
  "ORAČU",
  "ORADA",
  "ORADE",
  "ORADI",
  "ORADO",
  "ORALI",
  "ORALU",
  "ORANA",
  "ORANE",
  "ORANI",
  "ORANJ",
  "ORANO",
  "ORANT",
  "ORANŽ",
  "ORATI",
  "ORBIT",
  "OREHA",
  "OREHE",
  "OREHI",
  "OREHU",
  "ORGAN",
  "ORGEL",
  "ORGIJ",
  "ORGLA",
  "ORGLE",
  "ORIBA",
  "ORIKS",
  "ORISA",
  "ORISE",
  "ORISI",
  "ORISU",
  "ORIŠI",
  "ORJAK",
  "ORJEM",
  "ORJEŠ",
  "ORKAM",
  "ORKAN",
  "ORKIN",
  "ORKOM",
  "ORKOV",
  "ORLIC",
  "ORLIČ",
  "ORLJA",
  "ORLJE",
  "ORLJI",
  "ORLOM",
  "ORLOV",
  "ORNAT",
  "OROČA",
  "OROČI",
  "OROSI",
  "ORTOZ",
  "OSAJI",
  "OSAMA",
  "OSAME",
  "OSAMI",
  "OSAMO",
  "OSAST",
  "OSATA",
  "OSATE",
  "OSATI",
  "OSATO",
  "OSATU",
  "OSEBA",
  "OSEBE",
  "OSEBI",
  "OSEBO",
  "OSEIN",
  "OSEKA",
  "OSEKE",
  "OSEKI",
  "OSEKO",
  "OSEMA",
  "OSICA",
  "OSICE",
  "OSICI",
  "OSICO",
  "OSINA",
  "OSINE",
  "OSINI",
  "OSINO",
  "OSIPA",
  "OSIPE",
  "OSIPI",
  "OSIPU",
  "OSIŠČ",
  "OSIVI",
  "OSKAR",
  "OSLAD",
  "OSLAM",
  "OSLAR",
  "OSLEZ",
  "OSLIC",
  "OSLIČ",
  "OSLOM",
  "OSLOV",
  "OSMIC",
  "OSMIČ",
  "OSMIJ",
  "OSMIM",
  "OSMIN",
  "OSNIK",
  "OSNOV",
  "OSNUJ",
  "OSOČI",
  "OSOJA",
  "OSOJE",
  "OSOJI",
  "OSOJO",
  "OSOLI",
  "OSTAL",
  "OSTEM",
  "OSTEN",
  "OSTER",
  "OSTEV",
  "OSTNA",
  "OSTNE",
  "OSTNI",
  "OSTNU",
  "OSTRA",
  "OSTRE",
  "OSTRI",
  "OSTRO",
  "OSTRV",
  "OSTUD",
  "OSTVA",
  "OSTVE",
  "OSTVI",
  "OSTVO",
  "OSUJE",
  "OSULA",
  "OSULE",
  "OSULI",
  "OSULO",
  "OSUMI",
  "OSUPI",
  "OSUŠI",
  "OSUTA",
  "OSUTE",
  "OSUTI",
  "OSUTO",
  "OSUVA",
  "OŠIBA",
  "OŠIBI",
  "OŠILI",
  "OŠPIC",
  "OŠTEJ",
  "OŠTET",
  "OTAJA",
  "OTALI",
  "OTAVA",
  "OTAVE",
  "OTAVI",
  "OTAVO",
  "OTECI",
  "OTEČE",
  "OTEČI",
  "OTEKA",
  "OTEPA",
  "OTEPE",
  "OTEPI",
  "OTEPU",
  "OTEŠE",
  "OTEŠI",
  "OTEŽI",
  "OTIPA",
  "OTIPE",
  "OTIPI",
  "OTIPU",
  "OTKAM",
  "OTOKA",
  "OTOKE",
  "OTOKI",
  "OTOKU",
  "OTOPI",
  "OTRDI",
  "OTRKA",
  "OTRNE",
  "OTRNI",
  "OTROK",
  "OVADB",
  "OVADI",
  "OVALA",
  "OVALE",
  "OVALI",
  "OVALU",
  "OVCAM",
  "OVCIN",
  "OVČAR",
  "OVČIC",
  "OVČIN",
  "OVČJA",
  "OVČJE",
  "OVČJI",
  "OVČKA",
  "OVČKE",
  "OVČKI",
  "OVČKO",
  "OVEDI",
  "OVEDO",
  "OVEMO",
  "OVENE",
  "OVENI",
  "OVERI",
  "OVESI",
  "OVEŠA",
  "OVEVA",
  "OVIRA",
  "OVIRE",
  "OVIRI",
  "OVIRO",
  "OVIST",
  "OVITA",
  "OVITE",
  "OVITI",
  "OVITO",
  "OVNIC",
  "OVNIČ",
  "OVNOM",
  "OVNOV",
  "OVOJA",
  "OVOJE",
  "OVOJI",
  "OVOJU",
  "OVRZI",
  "OVRŽB",
  "OVSEN",
  "OVSIK",
  "OZARA",
  "OZARE",
  "OZARI",
  "OZARO",
  "OZDOM",
  "OZDOV",
  "OZEBA",
  "OZEBE",
  "OZEBI",
  "OZEBO",
  "OZIRA",
  "OZIRE",
  "OZIRI",
  "OZIRU",
  "OZNAK",
  "OZOBA",
  "OZOBI",
  "OZONA",
  "OZONU",
  "OZRNI",
  "OŽAME",
  "OŽARI",
  "OŽEČA",
  "OŽEČE",
  "OŽEČI",
  "OŽELA",
  "OŽELE",
  "OŽELI",
  "OŽELO",
  "OŽEMA",
  "OŽEMI",
  "OŽENA",
  "OŽENE",
  "OŽENI",
  "OŽENJ",
  "OŽENO",
  "OŽETA",
  "OŽETE",
  "OŽETI",
  "OŽETO",
  "OŽGAN",
  "OŽIČI",
  "OŽIGA",
  "OŽIGE",
  "OŽIGI",
  "OŽIGU",
  "OŽIJO",
  "OŽILA",
  "OŽILE",
  "OŽILI",
  "OŽILO",
  "OŽIMO",
  "OŽINA",
  "OŽINE",
  "OŽINI",
  "OŽINO",
  "OŽITA",
  "OŽITE",
  "OŽITI",
  "OŽIVA",
  "OŽIVE",
  "OŽIVI",
  "OŽMEM",
  "OŽMEŠ",
  "OŽULI",
  "PACAJ",
  "PACAL",
  "PACAŠ",
  "PACEK",
  "PACKA",
  "PACKE",
  "PACKI",
  "PACKO",
  "PACKU",
  "PAČEČ",
  "PAČEN",
  "PADAJ",
  "PADAL",
  "PADAM",
  "PADAR",
  "PADAŠ",
  "PADCA",
  "PADCE",
  "PADCI",
  "PADCU",
  "PADEC",
  "PADEL",
  "PADEM",
  "PADEN",
  "PADEŠ",
  "PADLA",
  "PADLE",
  "PADLI",
  "PADLO",
  "PADNA",
  "PADNE",
  "PADNI",
  "PADNO",
  "PAELJ",
  "PAELL",
  "PAFIJ",
  "PAGAT",
  "PAGIN",
  "PAGOD",
  "PAHNI",
  "PAJAC",
  "PAJEK",
  "PAJKA",
  "PAJKE",
  "PAJKI",
  "PAJKU",
  "PAKAM",
  "PAKET",
  "PAKOM",
  "PAKOV",
  "PAKTA",
  "PAKTE",
  "PAKTI",
  "PAKTU",
  "PALAČ",
  "PALAM",
  "PALCA",
  "PALCE",
  "PALCI",
  "PALCU",
  "PALČK",
  "PALEC",
  "PALET",
  "PALEŽ",
  "PALIC",
  "PALIJ",
  "PALIK",
  "PALMA",
  "PALME",
  "PALMI",
  "PALMO",
  "PALUB",
  "PAMET",
  "PAMPA",
  "PAMPE",
  "PAMPI",
  "PAMPO",
  "PAMŽA",
  "PAMŽE",
  "PAMŽI",
  "PAMŽU",
  "PANAD",
  "PANDA",
  "PANDE",
  "PANDI",
  "PANDO",
  "PANDU",
  "PANEL",
  "PANIK",
  "PANJA",
  "PANJE",
  "PANJI",
  "PANJU",
  "PANKA",
  "PANKO",
  "PANKU",
  "PANOG",
  "PAPAJ",
  "PAPAL",
  "PAPAM",
  "PAPAN",
  "PAPAŠ",
  "PAPCA",
  "PAPEŽ",
  "PAPIG",
  "PAPIR",
  "PAPOM",
  "PAPOV",
  "PAPUL",
  "PARAA",
  "PARAD",
  "PARAF",
  "PARAJ",
  "PARAL",
  "PARAM",
  "PARAŠ",
  "PAREČ",
  "PAREN",
  "PAREO",
  "PARFE",
  "PARIC",
  "PARIJ",
  "PARKA",
  "PARKE",
  "PARKI",
  "PARKU",
  "PARNA",
  "PARNE",
  "PARNI",
  "PARNO",
  "PAROA",
  "PAROE",
  "PAROH",
  "PAROI",
  "PAROL",
  "PAROM",
  "PAROU",
  "PAROV",
  "PARTU",
  "PASAN",
  "PASAR",
  "PASAT",
  "PASEK",
  "PASEM",
  "PASEN",
  "PASHA",
  "PASHE",
  "PASHI",
  "PASHO",
  "PASIC",
  "PASJA",
  "PASJE",
  "PASJI",
  "PASKA",
  "PASKE",
  "PASKI",
  "PASKU",
  "PASMA",
  "PASME",
  "PASMI",
  "PASMO",
  "PASNA",
  "PASNE",
  "PASNI",
  "PASNO",
  "PASOČ",
  "PASOV",
  "PASTA",
  "PASTE",
  "PASTI",
  "PASTO",
  "PASUS",
  "PAŠAM",
  "PAŠEM",
  "PAŠEN",
  "PAŠEV",
  "PAŠKA",
  "PAŠKE",
  "PAŠKI",
  "PAŠKO",
  "PAŠNA",
  "PAŠNE",
  "PAŠNI",
  "PAŠNO",
  "PAŠUJ",
  "PATAS",
  "PATEN",
  "PATER",
  "PATOM",
  "PATOS",
  "PATOV",
  "PATRA",
  "PATRE",
  "PATRI",
  "PATRU",
  "PAVIC",
  "PAVJA",
  "PAVJE",
  "PAVJI",
  "PAVKA",
  "PAVKE",
  "PAVKI",
  "PAVKO",
  "PAVOM",
  "PAVOV",
  "PAZEČ",
  "PAZIL",
  "PAZIM",
  "PAZIŠ",
  "PAŽEČ",
  "PAŽEM",
  "PAŽEN",
  "PAŽEV",
  "PAŽIL",
  "PAŽIM",
  "PAŽIŠ",
  "PECIV",
  "PECKA",
  "PEČAJ",
  "PEČAK",
  "PEČAL",
  "PEČAM",
  "PEČAR",
  "PEČAŠ",
  "PEČAT",
  "PEČEM",
  "PEČEN",
  "PEČEŠ",
  "PEČIC",
  "PEČKA",
  "PEČKE",
  "PEČKI",
  "PEČKO",
  "PEČNA",
  "PEČNE",
  "PEČNI",
  "PEČNO",
  "PEDAL",
  "PEDEM",
  "PEDIC",
  "PEGAM",
  "PEGAT",
  "PEGAV",
  "PEGAZ",
  "PEGIC",
  "PEHAJ",
  "PEHAL",
  "PEHAM",
  "PEHAR",
  "PEHAŠ",
  "PEHOT",
  "PEKAČ",
  "PEKAM",
  "PEKEL",
  "PEKET",
  "PEKLA",
  "PEKLE",
  "PEKLI",
  "PEKLO",
  "PEKLU",
  "PEKOČ",
  "PEKOM",
  "PEKOV",
  "PELAT",
  "PELET",
  "PELIN",
  "PELIR",
  "PELJA",
  "PELJE",
  "PELJI",
  "PELOD",
  "PELOT",
  "PENAM",
  "PENAT",
  "PENEČ",
  "PENIC",
  "PENIL",
  "PENIN",
  "PENIS",
  "PENJA",
  "PENJE",
  "PENJI",
  "PENJO",
  "PENUŠ",
  "PEPEL",
  "PEREČ",
  "PEREM",
  "PERES",
  "PEREŠ",
  "PERIC",
  "PERJA",
  "PERJE",
  "PERJU",
  "PERMA",
  "PERMU",
  "PEROČ",
  "PERON",
  "PERUŠ",
  "PERUT",
  "PESAR",
  "PESEK",
  "PESEM",
  "PESEN",
  "PESIN",
  "PESKA",
  "PESKE",
  "PESKI",
  "PESKU",
  "PESMI",
  "PESNA",
  "PESNE",
  "PESNI",
  "PESNO",
  "PESOM",
  "PESOV",
  "PESTA",
  "PESTE",
  "PESTI",
  "PESTO",
  "PESTU",
  "PEŠAJ",
  "PEŠAK",
  "PEŠAL",
  "PEŠAM",
  "PEŠAŠ",
  "PEŠCA",
  "PEŠCE",
  "PEŠCI",
  "PEŠCU",
  "PEŠEC",
  "PEŠKA",
  "PEŠKE",
  "PEŠKI",
  "PEŠKO",
  "PETAM",
  "PETEK",
  "PETEN",
  "PETIC",
  "PETIČ",
  "PETIM",
  "PETIN",
  "PETJA",
  "PETJE",
  "PETJI",
  "PETJU",
  "PETKA",
  "PETKE",
  "PETKI",
  "PETKO",
  "PETKU",
  "PETNA",
  "PETNE",
  "PETNI",
  "PETNO",
  "PETOL",
  "PEVCA",
  "PEVCE",
  "PEVCI",
  "PEVCU",
  "PEVEC",
  "PEVEN",
  "PEVKA",
  "PEVKE",
  "PEVKI",
  "PEVKO",
  "PEVNA",
  "PEVNE",
  "PEVNI",
  "PEVNO",
  "PEZET",
  "PHAJO",
  "PHALA",
  "PHALE",
  "PHALI",
  "PHALO",
  "PHAMO",
  "PHANA",
  "PHANE",
  "PHANI",
  "PHANJ",
  "PHANO",
  "PHATA",
  "PHATE",
  "PHATI",
  "PHAVA",
  "PIANA",
  "PIANE",
  "PIANI",
  "PIANO",
  "PIANU",
  "PICAM",
  "PICET",
  "PIČEL",
  "PIČEN",
  "PIČIC",
  "PIČLA",
  "PIČLE",
  "PIČLI",
  "PIČLO",
  "PIDOM",
  "PIDOV",
  "PIETA",
  "PIHAJ",
  "PIHAL",
  "PIHAM",
  "PIHAN",
  "PIHAŠ",
  "PIHNE",
  "PIHNI",
  "PIHOM",
  "PIHOV",
  "PIJAČ",
  "PIJAN",
  "PIJEM",
  "PIJEŠ",
  "PIJEV",
  "PIJMO",
  "PIJOČ",
  "PIJTA",
  "PIJTE",
  "PIJVA",
  "PIKAJ",
  "PIKAL",
  "PIKAM",
  "PIKAŠ",
  "PIKČA",
  "PIKER",
  "PIKIC",
  "PIKNE",
  "PIKNI",
  "PIKOM",
  "PIKOV",
  "PIKRA",
  "PIKRE",
  "PIKRI",
  "PIKRO",
  "PILAM",
  "PILAR",
  "PILAV",
  "PILEČ",
  "PILIC",
  "PILOT",
  "PILUM",
  "PINCA",
  "PINCE",
  "PINCI",
  "PINCO",
  "PINČA",
  "PINČE",
  "PINČI",
  "PINČU",
  "PINIJ",
  "PINJA",
  "PINJE",
  "PINJI",
  "PINJO",
  "PINOT",
  "PINOŽ",
  "PINTA",
  "PINTE",
  "PINTI",
  "PINTO",
  "PINTU",
  "PIONA",
  "PIONE",
  "PIONI",
  "PIONU",
  "PIPAM",
  "PIPAR",
  "PIPCA",
  "PIPCE",
  "PIPCI",
  "PIPCU",
  "PIPEC",
  "PIPER",
  "PIPET",
  "PIPIC",
  "PIPSA",
  "PIPSU",
  "PIRAJ",
  "PIRAT",
  "PIRHA",
  "PIRHE",
  "PIRHI",
  "PIRHU",
  "PIRIK",
  "PIRIN",
  "PIRIT",
  "PIRKA",
  "PIRKE",
  "PIRKI",
  "PIRKO",
  "PIROG",
  "PIRUJ",
  "PISAČ",
  "PISAL",
  "PISAN",
  "PISAR",
  "PISAV",
  "PISCA",
  "PISCE",
  "PISCI",
  "PISCU",
  "PISEC",
  "PISEM",
  "PISEN",
  "PISKA",
  "PISKE",
  "PISKI",
  "PISKO",
  "PISKU",
  "PISMA",
  "PISMI",
  "PISMO",
  "PISMU",
  "PISNA",
  "PISNE",
  "PISNI",
  "PISNO",
  "PISUN",
  "PIŠČE",
  "PIŠEM",
  "PIŠEŠ",
  "PIŠEV",
  "PIŠKA",
  "PIŠKE",
  "PIŠKI",
  "PIŠKO",
  "PIŠOČ",
  "PITAJ",
  "PITAL",
  "PITAM",
  "PITAN",
  "PITAŠ",
  "PITEN",
  "PITIJ",
  "PITJA",
  "PITJE",
  "PITJI",
  "PITJU",
  "PITNA",
  "PITNE",
  "PITNI",
  "PITNO",
  "PITON",
  "PIVCA",
  "PIVCE",
  "PIVCI",
  "PIVCU",
  "PIVEC",
  "PIVEN",
  "PIVIN",
  "PIVKA",
  "PIVKE",
  "PIVKI",
  "PIVKO",
  "PIVNA",
  "PIVNE",
  "PIVNI",
  "PIVNO",
  "PIVOM",
  "PIVOT",
  "PIVOV",
  "PIŽAM",
  "PLAČA",
  "PLAČE",
  "PLAČI",
  "PLAČO",
  "PLAHA",
  "PLAHE",
  "PLAHI",
  "PLAHO",
  "PLAKA",
  "PLALA",
  "PLALE",
  "PLALI",
  "PLALO",
  "PLANA",
  "PLANJ",
  "PLANO",
  "PLAST",
  "PLAŠČ",
  "PLAŠI",
  "PLATI",
  "PLAVŽ",
  "PLAZA",
  "PLAZE",
  "PLAZI",
  "PLAZU",
  "PLAŽA",
  "PLAŽE",
  "PLAŽI",
  "PLAŽO",
  "PLEČA",
  "PLEČE",
  "PLEČK",
  "PLELA",
  "PLELE",
  "PLELI",
  "PLELO",
  "PLEME",
  "PLENA",
  "PLENE",
  "PLENI",
  "PLENU",
  "PLESA",
  "PLESE",
  "PLESI",
  "PLESU",
  "PLEŠA",
  "PLEŠE",
  "PLEŠI",
  "PLEŠK",
  "PLEŠO",
  "PLETA",
  "PLETE",
  "PLETI",
  "PLETO",
  "PLETU",
  "PLEVA",
  "PLEVE",
  "PLEVI",
  "PLEVK",
  "PLEVO",
  "PLEZA",
  "PLIKA",
  "PLIKE",
  "PLIKI",
  "PLIKO",
  "PLIMA",
  "PLIME",
  "PLIMI",
  "PLIMO",
  "PLINA",
  "PLINE",
  "PLINI",
  "PLINU",
  "PLISE",
  "PLIŠA",
  "PLIŠU",
  "PLJUČ",
  "PLJUJ",
  "PLODA",
  "PLODE",
  "PLODI",
  "PLODU",
  "PLOHA",
  "PLOHE",
  "PLOHI",
  "PLOHO",
  "PLOHU",
  "PLOMB",
  "PLOSK",
  "PLOŠČ",
  "PLOTA",
  "PLOTU",
  "PLOVA",
  "PLOVB",
  "PLOVE",
  "PLOVI",
  "PLOVK",
  "PLOVU",
  "PLUGA",
  "PLUGE",
  "PLUGI",
  "PLUGU",
  "PLUJE",
  "PLULA",
  "PLULE",
  "PLULI",
  "PLULO",
  "PLUSA",
  "PLUSE",
  "PLUSI",
  "PLUSU",
  "PLUTA",
  "PLUTE",
  "PLUTI",
  "PLUTO",
  "PLUŽI",
  "POBEG",
  "POBIČ",
  "POBIJ",
  "POBIT",
  "POBOJ",
  "POBOT",
  "POBUD",
  "POČEL",
  "POČEM",
  "POČEN",
  "POČEP",
  "POČET",
  "POČEZ",
  "POČIJ",
  "POČIL",
  "POČIM",
  "POČIŠ",
  "POČNE",
  "POČNI",
  "POČNO",
  "PODAJ",
  "PODAN",
  "PODEČ",
  "PODEL",
  "PODLA",
  "PODLE",
  "PODLI",
  "PODLO",
  "PODNA",
  "PODNE",
  "PODNO",
  "PODOB",
  "PODOJ",
  "PODOM",
  "PODOR",
  "PODOV",
  "PODRI",
  "PODRS",
  "PODRT",
  "PODUK",
  "POETA",
  "POETE",
  "POETI",
  "POETU",
  "POGAČ",
  "POGAN",
  "POGIN",
  "POGOJ",
  "POGON",
  "POGUB",
  "POGUM",
  "POHAB",
  "POHER",
  "POHOD",
  "POHOT",
  "POHRA",
  "POHRE",
  "POHRI",
  "POHRO",
  "POIDE",
  "POIDI",
  "POITI",
  "POJAV",
  "POJDE",
  "POJDI",
  "POJEČ",
  "POJEJ",
  "POJEM",
  "POJEŠ",
  "POJIL",
  "POJIM",
  "POJIŠ",
  "POJMA",
  "POJME",
  "POJMI",
  "POJMO",
  "POJMU",
  "POJOČ",
  "POJTA",
  "POJTE",
  "POJVA",
  "POKAČ",
  "POKAJ",
  "POKAL",
  "POKAM",
  "POKAŠ",
  "POKCA",
  "POKCE",
  "POKCI",
  "POKCU",
  "POKEC",
  "POKER",
  "POKNE",
  "POKNI",
  "POKOJ",
  "POKOL",
  "POKOM",
  "POKOP",
  "POKOR",
  "POKOV",
  "POKRA",
  "POKRU",
  "POLAJ",
  "POLAM",
  "POLAR",
  "POLEG",
  "POLET",
  "POLHA",
  "POLHE",
  "POLHI",
  "POLHU",
  "POLIC",
  "POLIČ",
  "POLIJ",
  "POLIP",
  "POLIT",
  "POLIV",
  "POLJA",
  "POLJE",
  "POLJI",
  "POLJU",
  "POLKA",
  "POLKE",
  "POLKI",
  "POLKO",
  "POLKU",
  "POLNA",
  "POLNE",
  "POLNI",
  "POLNO",
  "POLOG",
  "POLOM",
  "POLOS",
  "POLOV",
  "POLST",
  "POLTI",
  "POLUT",
  "POLZA",
  "POLZE",
  "POLZI",
  "POLZO",
  "POLŽA",
  "POLŽE",
  "POLŽI",
  "POLŽU",
  "POMAK",
  "POMEL",
  "POMEN",
  "POMET",
  "POMIG",
  "POMIJ",
  "POMIK",
  "POMIL",
  "POMIT",
  "POMNI",
  "POMOČ",
  "POMOL",
  "POMOR",
  "POMOT",
  "POMPA",
  "POMPE",
  "POMPI",
  "POMPU",
  "POMRE",
  "POMRI",
  "POMRL",
  "PONAM",
  "PONČA",
  "PONČE",
  "PONČI",
  "PONČO",
  "PONČU",
  "PONDA",
  "PONDE",
  "PONDI",
  "PONDU",
  "PONEV",
  "PONIK",
  "PONOR",
  "PONOS",
  "PONVE",
  "PONVI",
  "POPAJ",
  "POPAR",
  "POPEK",
  "POPER",
  "POPIJ",
  "POPIK",
  "POPIL",
  "POPIS",
  "POPIT",
  "POPJA",
  "POPJE",
  "POPJU",
  "POPKA",
  "POPKE",
  "POPKI",
  "POPKU",
  "POPOM",
  "POPON",
  "POPOV",
  "POPRA",
  "POPRE",
  "POPRH",
  "POPRI",
  "POPRU",
  "PORAB",
  "PORAM",
  "PORAZ",
  "PORDI",
  "POREK",
  "POREN",
  "PORIJ",
  "PORIL",
  "PORIV",
  "PORNA",
  "PORNE",
  "PORNI",
  "PORNO",
  "POROD",
  "POROG",
  "POROK",
  "POROM",
  "POROT",
  "POROV",
  "POROZ",
  "PORUJ",
  "POSEG",
  "POSEJ",
  "POSEK",
  "POSEL",
  "POSIJ",
  "POSIP",
  "POSLA",
  "POSLE",
  "POSLI",
  "POSLU",
  "POSOD",
  "POSPI",
  "POSTA",
  "POSTE",
  "POSTI",
  "POSTU",
  "POSUJ",
  "POSUT",
  "POŠEL",
  "POŠEV",
  "POŠIJ",
  "POŠIT",
  "POŠKA",
  "POŠKE",
  "POŠKI",
  "POŠKO",
  "POŠLA",
  "POŠLE",
  "POŠLI",
  "POŠLO",
  "POŠTA",
  "POŠTE",
  "POŠTI",
  "POŠTO",
  "POTEČ",
  "POTEG",
  "POTEK",
  "POTEM",
  "POTEN",
  "POTEP",
  "POTEZ",
  "POTIC",
  "POTKA",
  "POTKE",
  "POTKI",
  "POTKO",
  "POTLI",
  "POTNA",
  "POTNE",
  "POTNI",
  "POTNO",
  "POTOK",
  "POTOM",
  "POTOP",
  "POTOV",
  "POTRI",
  "POTRT",
  "POTUJ",
  "POUKA",
  "POUKE",
  "POUKI",
  "POUKU",
  "POVED",
  "POVEJ",
  "POVEM",
  "POVEŠ",
  "POVIJ",
  "POVIT",
  "POVOD",
  "POVOJ",
  "POVOZ",
  "POVRE",
  "POVRI",
  "POZAB",
  "POZEB",
  "POZEN",
  "POZIV",
  "POZNA",
  "POZNE",
  "POZNI",
  "POZNO",
  "POZOR",
  "POŽAR",
  "POŽET",
  "POŽGI",
  "POŽIG",
  "POŽRE",
  "POŽRI",
  "POŽRL",
  "POŽRO",
  "POŽRT",
  "PRAČA",
  "PRAČE",
  "PRAČI",
  "PRAČO",
  "PRAGA",
  "PRAGE",
  "PRAGI",
  "PRAGU",
  "PRAHA",
  "PRAHE",
  "PRAHI",
  "PRAHO",
  "PRAHU",
  "PRAKS",
  "PRALA",
  "PRALE",
  "PRALI",
  "PRALK",
  "PRALO",
  "PRAMA",
  "PRAME",
  "PRAMI",
  "PRAMO",
  "PRAMU",
  "PRANA",
  "PRANE",
  "PRANI",
  "PRANJ",
  "PRANO",
  "PRASI",
  "PRASK",
  "PRAŠI",
  "PRAVA",
  "PRAVD",
  "PRAVE",
  "PRAVI",
  "PRAVO",
  "PRAVU",
  "PRAŽE",
  "PRAŽI",
  "PRDCA",
  "PRDCE",
  "PRDCI",
  "PRDCU",
  "PRDEC",
  "PREČA",
  "PREČE",
  "PREČI",
  "PREČK",
  "PREČO",
  "PREDE",
  "PREDI",
  "PREJA",
  "PREJE",
  "PREJI",
  "PREJK",
  "PREJO",
  "PREKO",
  "PRELK",
  "PREMA",
  "PREME",
  "PREMI",
  "PREMO",
  "PREŠA",
  "PREŠC",
  "PREŠE",
  "PREŠI",
  "PREŠO",
  "PRETE",
  "PRETI",
  "PREŽA",
  "PREŽE",
  "PREŽI",
  "PREŽO",
  "PRHAJ",
  "PRHAK",
  "PRHAL",
  "PRHAM",
  "PRHAŠ",
  "PRHEK",
  "PRHEL",
  "PRHKA",
  "PRHKE",
  "PRHKI",
  "PRHKO",
  "PRHLA",
  "PRHLE",
  "PRHLI",
  "PRHLO",
  "PRHNE",
  "PRHNI",
  "PRHUT",
  "PRIBA",
  "PRIBE",
  "PRIBI",
  "PRIBO",
  "PRIČA",
  "PRIČE",
  "PRIČI",
  "PRIČO",
  "PRIDE",
  "PRIDI",
  "PRIMI",
  "PRINC",
  "PRION",
  "PRIOR",
  "PRIŠČ",
  "PRITI",
  "PRODA",
  "PRODE",
  "PRODI",
  "PRODU",
  "PROGA",
  "PROGE",
  "PROGI",
  "PROGO",
  "PROJA",
  "PROJE",
  "PROJI",
  "PROJO",
  "PROSA",
  "PROSI",
  "PROSO",
  "PROST",
  "PROSU",
  "PROŠT",
  "PROTA",
  "PROTE",
  "PROTI",
  "PROTO",
  "PROZA",
  "PROZE",
  "PROZI",
  "PROZO",
  "PROŽI",
  "PRSAT",
  "PRSCA",
  "PRSEČ",
  "PRSEN",
  "PRSIL",
  "PRSIM",
  "PRSIŠ",
  "PRSKA",
  "PRSKE",
  "PRSKI",
  "PRSKU",
  "PRSNA",
  "PRSNE",
  "PRSNI",
  "PRSNO",
  "PRSTA",
  "PRSTE",
  "PRSTI",
  "PRSTU",
  "PRŠCA",
  "PRŠCE",
  "PRŠCI",
  "PRŠCU",
  "PRŠEC",
  "PRŠEL",
  "PRŠEM",
  "PRŠEN",
  "PRŠEV",
  "PRŠIC",
  "PRŠIČ",
  "PRŠIL",
  "PRŠIM",
  "PRŠIŠ",
  "PRŠIV",
  "PRŠNA",
  "PRŠNE",
  "PRŠNI",
  "PRŠNO",
  "PRŠUT",
  "PRTIČ",
  "PRTOM",
  "PRTOV",
  "PRUČK",
  "PRVAK",
  "PRVCA",
  "PRVCE",
  "PRVCI",
  "PRVCU",
  "PRVEC",
  "PRVIČ",
  "PRVIM",
  "PRVIN",
  "PSALM",
  "PSARK",
  "PSARN",
  "PSICA",
  "PSICE",
  "PSICI",
  "PSICO",
  "PSIČK",
  "PSIHA",
  "PSIHE",
  "PSIHI",
  "PSIJA",
  "PSIJE",
  "PSIJI",
  "PSIJU",
  "PSIKA",
  "PSOMA",
  "PSOVK",
  "PŠENA",
  "PŠENO",
  "PŠENU",
  "PTICA",
  "PTICE",
  "PTICI",
  "PTICO",
  "PTIČA",
  "PTIČE",
  "PTIČI",
  "PTIČK",
  "PTIČU",
  "PTOZA",
  "PTOZE",
  "PTOZI",
  "PTOZO",
  "PUČEM",
  "PUČEV",
  "PUDER",
  "PUDLA",
  "PUDOM",
  "PUDOV",
  "PUDRA",
  "PUDRE",
  "PUDRI",
  "PUDRN",
  "PUDRU",
  "PUFER",
  "PUFRA",
  "PUFRE",
  "PUFRI",
  "PUFRU",
  "PUHAJ",
  "PUHAL",
  "PUHAM",
  "PUHAR",
  "PUHAŠ",
  "PUHCA",
  "PUHCE",
  "PUHCI",
  "PUHCU",
  "PUHEC",
  "PUHEK",
  "PUHEL",
  "PUHKA",
  "PUHKE",
  "PUHKI",
  "PUHKU",
  "PUHLA",
  "PUHLE",
  "PUHLI",
  "PUHLO",
  "PUHNE",
  "PUHNI",
  "PUHTE",
  "PUHTI",
  "PUJSA",
  "PUJSE",
  "PUJSI",
  "PUJSO",
  "PUJSU",
  "PULPA",
  "PULPE",
  "PULPI",
  "PULPO",
  "PULTA",
  "PULTE",
  "PULTI",
  "PULTU",
  "PULZA",
  "PULZE",
  "PULZI",
  "PULZU",
  "PUMAM",
  "PUMIN",
  "PUNČA",
  "PUNČE",
  "PUNČK",
  "PUNČU",
  "PUNTA",
  "PUNTE",
  "PUNTI",
  "PUNTU",
  "PUPEK",
  "PUPIL",
  "PUPKA",
  "PUPKE",
  "PUPKI",
  "PUPKU",
  "PURAM",
  "PURAN",
  "PURIC",
  "PURIN",
  "PURJA",
  "PURJE",
  "PURJI",
  "PUSTA",
  "PUSTE",
  "PUSTI",
  "PUSTO",
  "PUSTU",
  "PUŠAM",
  "PUŠČA",
  "PUŠČE",
  "PUŠČI",
  "PUŠČO",
  "PUŠIC",
  "PUŠKA",
  "PUŠKE",
  "PUŠKI",
  "PUŠKO",
  "PUTKA",
  "PUTKE",
  "PUTKI",
  "PUTKO",
  "PUTOM",
  "PUTOV",
  "RABAM",
  "RABAT",
  "RABIN",
  "RACAJ",
  "RACAK",
  "RACAL",
  "RACAM",
  "RACAŠ",
  "RACAV",
  "RACEM",
  "RACIJ",
  "RACIN",
  "RAČAK",
  "RAČEK",
  "RAČJA",
  "RAČJE",
  "RAČJI",
  "RAČKA",
  "RAČKE",
  "RAČKI",
  "RAČKO",
  "RAČKU",
  "RAČUN",
  "RADAR",
  "RADIA",
  "RADIČ",
  "RADIE",
  "RADII",
  "RADIJ",
  "RADIO",
  "RADIU",
  "RADON",
  "RADŽA",
  "RADŽE",
  "RADŽI",
  "RADŽO",
  "RADŽU",
  "RAFAL",
  "RAFIJ",
  "RAFTA",
  "RAFTE",
  "RAFTI",
  "RAFTU",
  "RAGAD",
  "RAGBI",
  "RAHEL",
  "RAHLA",
  "RAHLE",
  "RAHLI",
  "RAHLO",
  "RAJAJ",
  "RAJAL",
  "RAJAM",
  "RAJAŠ",
  "RAJHA",
  "RAJHU",
  "RAJNA",
  "RAJNE",
  "RAJNI",
  "RAJNO",
  "RAJON",
  "RAJŠI",
  "RAKAR",
  "RAKAV",
  "RAKCA",
  "RAKCE",
  "RAKCI",
  "RAKCU",
  "RAKEC",
  "RAKEL",
  "RAKET",
  "RAKEV",
  "RAKIC",
  "RAKIT",
  "RAKLA",
  "RAKLE",
  "RAKLI",
  "RAKLU",
  "RAKOM",
  "RAKOV",
  "RAKUN",
  "RAKVE",
  "RAKVI",
  "RALOM",
  "RAMAM",
  "RAMBA",
  "RAMBE",
  "RAMBI",
  "RAMBO",
  "RAMBU",
  "RAMEN",
  "RAMIJ",
  "RAMPA",
  "RAMPE",
  "RAMPI",
  "RAMPO",
  "RANAM",
  "RANAR",
  "RANČA",
  "RANČE",
  "RANČI",
  "RANČU",
  "RANDA",
  "RANDE",
  "RANDU",
  "RANIC",
  "RANIN",
  "RANUL",
  "RAPER",
  "RAPIR",
  "RAROG",
  "RASAM",
  "RASEL",
  "RASEM",
  "RASEN",
  "RASEŠ",
  "RASKA",
  "RASLA",
  "RASLE",
  "RASLI",
  "RASLO",
  "RASNA",
  "RASNE",
  "RASNI",
  "RASNO",
  "RASTE",
  "RASTI",
  "RAŠAM",
  "RAŠČA",
  "RAŠČE",
  "RAŠČI",
  "RAŠIN",
  "RAŠKA",
  "RAŠKE",
  "RAŠKI",
  "RAŠKO",
  "RAŠPA",
  "RAŠPE",
  "RAŠPI",
  "RAŠPO",
  "RATAN",
  "RAVAN",
  "RAVEN",
  "RAVNA",
  "RAVNE",
  "RAVNI",
  "RAVNO",
  "RAZAL",
  "RAZAM",
  "RAZDA",
  "RAZEN",
  "RAZIC",
  "RAZID",
  "RAZIL",
  "RAZIM",
  "RAZIŠ",
  "RAZNA",
  "RAZNE",
  "RAZNI",
  "RAZNO",
  "RAZOM",
  "RAZOR",
  "RAZOV",
  "RAZUM",
  "RAZVE",
  "RAŽAM",
  "RAŽIN",
  "RDEČA",
  "RDEČE",
  "RDEČI",
  "RDEČK",
  "RDEČO",
  "RDELA",
  "RDELE",
  "RDELI",
  "RDELO",
  "RDENJ",
  "RDETI",
  "RDIJO",
  "RDIMO",
  "RDITA",
  "RDITE",
  "RDIVA",
  "REALA",
  "REALE",
  "REALI",
  "REALK",
  "REALU",
  "REBEK",
  "REBER",
  "REBRA",
  "REBRC",
  "REBRI",
  "REBRN",
  "REBRO",
  "REBRU",
  "REBUL",
  "REBUS",
  "REČEM",
  "REČEN",
  "REČEŠ",
  "REČIC",
  "REČIJ",
  "REČJA",
  "REČJE",
  "REČJI",
  "REČJU",
  "REČNA",
  "REČNE",
  "REČNI",
  "REČNO",
  "REDAR",
  "REDČI",
  "REDEČ",
  "REDEK",
  "REDEN",
  "REDKA",
  "REDKE",
  "REDKI",
  "REDKO",
  "REDNA",
  "REDNE",
  "REDNI",
  "REDNO",
  "REDOM",
  "REDOV",
  "REDUT",
  "REGAJ",
  "REGAL",
  "REGAM",
  "REGAŠ",
  "REGAT",
  "REGIN",
  "REIKI",
  "REISA",
  "REISE",
  "REISI",
  "REIST",
  "REISU",
  "REJAM",
  "REJCA",
  "REJCE",
  "REJCI",
  "REJCU",
  "REJEC",
  "REJEN",
  "REJKA",
  "REJKE",
  "REJKI",
  "REJKO",
  "REJNA",
  "REJNE",
  "REJNI",
  "REJNO",
  "REJON",
  "REJVA",
  "REJVE",
  "REJVI",
  "REJVU",
  "REKAJ",
  "REKAL",
  "REKAM",
  "REKAŠ",
  "REKEL",
  "REKLA",
  "REKLE",
  "REKLI",
  "REKLO",
  "REKOČ",
  "REKOM",
  "REKOV",
  "RELIK",
  "REMIZ",
  "RENČE",
  "RENČI",
  "RENIJ",
  "RENIN",
  "RENTA",
  "RENTE",
  "RENTI",
  "RENTO",
  "REPAK",
  "REPAM",
  "REPAT",
  "REPEK",
  "REPEN",
  "REPIC",
  "REPIK",
  "REPKA",
  "REPKE",
  "REPKI",
  "REPKU",
  "REPNA",
  "REPNE",
  "REPNI",
  "REPNO",
  "REPOM",
  "REPOV",
  "REPUH",
  "REPUŠ",
  "RESAM",
  "RESAR",
  "RESAV",
  "RESED",
  "RESEN",
  "RESIC",
  "RESIN",
  "RESJA",
  "RESJE",
  "RESJU",
  "RESKA",
  "RESKE",
  "RESKI",
  "RESKU",
  "RESNA",
  "RESNE",
  "RESNI",
  "RESNO",
  "RESOR",
  "RESUZ",
  "REŠEN",
  "REŠET",
  "REŠIL",
  "REŠIM",
  "REŠIŠ",
  "REŠKA",
  "REŠKE",
  "REŠKI",
  "REŠKO",
  "REŠUJ",
  "RETAM",
  "RETUŠ",
  "REVEN",
  "REVEŽ",
  "REVIC",
  "REVIJ",
  "REVIR",
  "REVNA",
  "REVNE",
  "REVNI",
  "REVNO",
  "REVSK",
  "REVŠE",
  "REZAČ",
  "REZAL",
  "REZAN",
  "REZBA",
  "REZCA",
  "REZCE",
  "REZCI",
  "REZCU",
  "REZEC",
  "REZED",
  "REZEK",
  "REZEM",
  "REZEN",
  "REZEV",
  "REZGA",
  "REZGE",
  "REZGI",
  "REZGU",
  "REZIK",
  "REZIL",
  "REZIN",
  "REZKA",
  "REZKE",
  "REZKI",
  "REZKO",
  "REZNA",
  "REZNE",
  "REZNI",
  "REZNO",
  "REZOM",
  "REZOV",
  "REZUS",
  "REZVA",
  "REZVE",
  "REZVI",
  "REZVO",
  "REŽAJ",
  "REŽAM",
  "REŽEČ",
  "REŽEM",
  "REŽEN",
  "REŽEŠ",
  "REŽIC",
  "REŽIJ",
  "REŽIM",
  "REŽNA",
  "REŽNE",
  "REŽNI",
  "REŽNO",
  "RIALA",
  "RIALE",
  "RIALI",
  "RIALU",
  "RIBAJ",
  "RIBAL",
  "RIBAM",
  "RIBAN",
  "RIBAŠ",
  "RIBEZ",
  "RIBIC",
  "RIBIČ",
  "RIBIN",
  "RIBJA",
  "RIBJE",
  "RIBJI",
  "RIBON",
  "RIBOZ",
  "RICIN",
  "RIČEK",
  "RIČEM",
  "RIČEŠ",
  "RIČET",
  "RIČKA",
  "RIČKE",
  "RIČKI",
  "RIČKU",
  "RIČOT",
  "RIDAM",
  "RIGAJ",
  "RIGAL",
  "RIGAM",
  "RIGAŠ",
  "RIGCA",
  "RIGCE",
  "RIGCI",
  "RIGCU",
  "RIGEC",
  "RIGOM",
  "RIGOV",
  "RIJEM",
  "RIJEŠ",
  "RIJMO",
  "RIJOČ",
  "RIJTA",
  "RIJTE",
  "RIJVA",
  "RIKAJ",
  "RIKAL",
  "RIKAM",
  "RIKAŠ",
  "RIKOT",
  "RIKŠA",
  "RIKŠE",
  "RIKŠI",
  "RIKŠO",
  "RILCA",
  "RILCE",
  "RILCI",
  "RILCU",
  "RILEC",
  "RIMAČ",
  "RIMAJ",
  "RIMAM",
  "RIMAN",
  "RIMAR",
  "RIMES",
  "RINEČ",
  "RINEŽ",
  "RINGA",
  "RINGE",
  "RINGI",
  "RINGU",
  "RIPEČ",
  "RISAL",
  "RISAM",
  "RISAN",
  "RISAR",
  "RISBA",
  "RISBE",
  "RISBI",
  "RISBO",
  "RISIN",
  "RISJA",
  "RISJE",
  "RISJI",
  "RISOM",
  "RISOV",
  "RIŠEM",
  "RIŠEŠ",
  "RIŠKA",
  "RIŠKE",
  "RIŠKI",
  "RIŠKO",
  "RITAJ",
  "RITAL",
  "RITAM",
  "RITAŠ",
  "RITEM",
  "RITEN",
  "RITIJ",
  "RITJA",
  "RITJE",
  "RITJI",
  "RITJU",
  "RITKA",
  "RITKE",
  "RITKI",
  "RITKO",
  "RITMA",
  "RITME",
  "RITMI",
  "RITMU",
  "RITNA",
  "RITNE",
  "RITNI",
  "RITNO",
  "RIZEM",
  "RIZMA",
  "RIZME",
  "RIZMI",
  "RIZMO",
  "RIŽAM",
  "RIŽEK",
  "RIŽEV",
  "RIŽKA",
  "RIŽKE",
  "RIŽKI",
  "RIŽKU",
  "RIŽOT",
  "RJAMA",
  "RJAST",
  "RJAVA",
  "RJAVE",
  "RJAVI",
  "RJAVK",
  "RJAVO",
  "RJOVE",
  "RJOVI",
  "RJUHA",
  "RJUHE",
  "RJUHI",
  "RJUHO",
  "RJUJE",
  "RJULA",
  "RJULE",
  "RJULI",
  "RJULO",
  "RJUTA",
  "RJUTE",
  "RJUTI",
  "RJUTO",
  "RMANA",
  "RMANU",
  "ROBAT",
  "ROBCA",
  "ROBCE",
  "ROBCI",
  "ROBCU",
  "ROBEC",
  "ROBEČ",
  "ROBEK",
  "ROBEN",
  "ROBID",
  "ROBIL",
  "ROBIM",
  "ROBIŠ",
  "ROBKA",
  "ROBKE",
  "ROBKI",
  "ROBKU",
  "ROBNA",
  "ROBNE",
  "ROBNI",
  "ROBNO",
  "ROBOM",
  "ROBOT",
  "ROCKA",
  "ROCKU",
  "ROČAJ",
  "ROČEK",
  "ROČEM",
  "ROČEN",
  "ROČEV",
  "ROČIC",
  "ROČKA",
  "ROČKE",
  "ROČKI",
  "ROČKO",
  "ROČKU",
  "ROČNA",
  "ROČNE",
  "ROČNI",
  "ROČNO",
  "RODEA",
  "RODEČ",
  "RODEE",
  "RODEI",
  "RODEN",
  "RODEO",
  "RODEU",
  "RODIJ",
  "RODIL",
  "RODIM",
  "RODIŠ",
  "RODNA",
  "RODNE",
  "RODNI",
  "RODNO",
  "RODOV",
  "ROGAČ",
  "ROGAR",
  "ROGAT",
  "ROGOM",
  "ROGOV",
  "ROGOZ",
  "ROHNI",
  "ROJAK",
  "ROJAM",
  "ROJEČ",
  "ROJEM",
  "ROJEN",
  "ROJEV",
  "ROJIL",
  "ROJIM",
  "ROJIŠ",
  "ROJIV",
  "ROKAD",
  "ROKAM",
  "ROKAV",
  "ROKER",
  "ROKIC",
  "ROKOM",
  "ROKOV",
  "ROLAR",
  "ROLER",
  "ROLET",
  "ROLKA",
  "ROLKE",
  "ROLKI",
  "ROLKO",
  "ROMAJ",
  "ROMAL",
  "ROMAM",
  "ROMAN",
  "ROMAR",
  "ROMAŠ",
  "ROMBA",
  "ROMBE",
  "ROMBI",
  "ROMBU",
  "RONDO",
  "ROPAJ",
  "ROPAL",
  "ROPAM",
  "ROPAR",
  "ROPAŠ",
  "ROPOM",
  "ROPOT",
  "ROPOV",
  "ROSAM",
  "ROSAN",
  "ROSEČ",
  "ROSEN",
  "ROSIC",
  "ROSIK",
  "ROSIL",
  "ROSIM",
  "ROSIŠ",
  "ROSNA",
  "ROSNE",
  "ROSNI",
  "ROSNO",
  "ROŠKA",
  "ROŠKE",
  "ROŠKI",
  "ROŠKO",
  "ROTEČ",
  "ROTIL",
  "ROTIM",
  "ROTIŠ",
  "ROTOR",
  "ROVAR",
  "ROVAŠ",
  "ROVEN",
  "ROVER",
  "ROVKA",
  "ROVKE",
  "ROVKI",
  "ROVKO",
  "ROVNA",
  "ROVNE",
  "ROVNI",
  "ROVNO",
  "ROVOM",
  "ROVOV",
  "ROVTE",
  "ROZET",
  "ROZGA",
  "ROZGE",
  "ROZGI",
  "ROZGO",
  "ROZIN",
  "ROŽAM",
  "ROŽEN",
  "ROŽIC",
  "ROŽIČ",
  "ROŽJA",
  "ROŽJE",
  "ROŽJU",
  "ROŽKA",
  "ROŽKE",
  "ROŽKI",
  "ROŽKO",
  "ROŽNA",
  "ROŽNE",
  "ROŽNI",
  "ROŽNO",
  "RTAST",
  "RTIČA",
  "RTIČE",
  "RTIČI",
  "RTIČU",
  "RTOMA",
  "RUBEČ",
  "RUBEŽ",
  "RUBIL",
  "RUBIM",
  "RUBIN",
  "RUBIŠ",
  "RUDAM",
  "RUDAR",
  "RUDEN",
  "RUDNA",
  "RUDNE",
  "RUDNI",
  "RUDNO",
  "RUJEM",
  "RUJEV",
  "RUJMO",
  "RUJTA",
  "RUJTE",
  "RUJVA",
  "RUKAJ",
  "RUKAL",
  "RUKAM",
  "RUKAN",
  "RUKAŠ",
  "RUKOM",
  "RUKOV",
  "RULAD",
  "RULET",
  "RUMBA",
  "RUMBE",
  "RUMBI",
  "RUMBO",
  "RUMEN",
  "RUMOM",
  "RUMOV",
  "RUNAM",
  "RUNDA",
  "RUNDE",
  "RUNDI",
  "RUNDO",
  "RUNOM",
  "RUPIJ",
  "RUSEČ",
  "RUSEN",
  "RUSIL",
  "RUSIM",
  "RUSIŠ",
  "RUSKA",
  "RUSKE",
  "RUSKI",
  "RUSKO",
  "RUŠAM",
  "RUŠEČ",
  "RUŠEN",
  "RUŠJA",
  "RUŠJE",
  "RUŠJU",
  "RUŠKA",
  "RUŠKE",
  "RUŠKI",
  "RUŠKO",
  "RUŠNA",
  "RUŠNE",
  "RUŠNI",
  "RUŠNO",
  "RUTAM",
  "RUTEN",
  "RUTIC",
  "RUTIN",
  "RUTKA",
  "RUTKE",
  "RUTKI",
  "RUTKO",
  "RUTNA",
  "RUTNE",
  "RUTNI",
  "RUTNO",
  "RUVAČ",
  "RŽENA",
  "RŽENE",
  "RŽENI",
  "RŽENO",
  "RŽIŠČ",
  "RŽULJ",
  "SAABA",
  "SAABE",
  "SAABI",
  "SAABU",
  "SABAT",
  "SABIR",
  "SABOR",
  "SADEČ",
  "SADEN",
  "SADEŽ",
  "SADIK",
  "SADJA",
  "SADJE",
  "SADJU",
  "SADNA",
  "SADNE",
  "SADNI",
  "SADNO",
  "SADOV",
  "SADRA",
  "SADRE",
  "SADRI",
  "SADRO",
  "SADŽA",
  "SADŽE",
  "SADŽI",
  "SADŽO",
  "SAFIR",
  "SAGAM",
  "SAGOV",
  "SAHEL",
  "SAHIB",
  "SAJAM",
  "SAJAV",
  "SAJEČ",
  "SAJEM",
  "SAJEN",
  "SAJEV",
  "SAJGA",
  "SAJGE",
  "SAJGI",
  "SAJGO",
  "SAJIL",
  "SAJIM",
  "SAJIŠ",
  "SAKAR",
  "SAKOM",
  "SAKOV",
  "SALAK",
  "SALAŠ",
  "SALDA",
  "SALDE",
  "SALDI",
  "SALDO",
  "SALDU",
  "SALEN",
  "SALNA",
  "SALNE",
  "SALNI",
  "SALNO",
  "SALON",
  "SALPA",
  "SALPE",
  "SALPI",
  "SALPO",
  "SALSA",
  "SALSE",
  "SALSI",
  "SALSO",
  "SALTA",
  "SALTE",
  "SALTI",
  "SALTO",
  "SALTU",
  "SALUN",
  "SALVA",
  "SALVE",
  "SALVI",
  "SALVO",
  "SAMBA",
  "SAMBE",
  "SAMBI",
  "SAMBO",
  "SAMCA",
  "SAMCE",
  "SAMCI",
  "SAMCU",
  "SAMEC",
  "SAMIC",
  "SAMOL",
  "SAMOT",
  "SAMUJ",
  "SAMUM",
  "SANEM",
  "SANIC",
  "SANJA",
  "SANJE",
  "SANJI",
  "SANJO",
  "SANKA",
  "SANKE",
  "SAPAM",
  "SAPER",
  "SAPIC",
  "SARIN",
  "SARMA",
  "SARME",
  "SARMI",
  "SARMO",
  "SARSA",
  "SARSU",
  "SARUS",
  "SAŠKA",
  "SAŠKE",
  "SAŠKI",
  "SAŠKO",
  "SATAN",
  "SATEN",
  "SATIR",
  "SATJA",
  "SATJE",
  "SATJU",
  "SATNA",
  "SATNE",
  "SATNI",
  "SATNO",
  "SATOM",
  "SATOV",
  "SAVAN",
  "SAVET",
  "SAVNA",
  "SAVNE",
  "SAVNI",
  "SAVNO",
  "SCEDI",
  "SCEJA",
  "SCELA",
  "SCELI",
  "SCENA",
  "SCENE",
  "SCENI",
  "SCENO",
  "SCVRE",
  "SCVRI",
  "SCVRL",
  "SCVRT",
  "SČRNI",
  "SEANS",
  "SEBEK",
  "SEBKA",
  "SEBKE",
  "SEBKI",
  "SEBKU",
  "SEČEN",
  "SEČIL",
  "SEČNA",
  "SEČNE",
  "SEČNI",
  "SEČNO",
  "SEDAL",
  "SEDAM",
  "SEDAŠ",
  "SEDEČ",
  "SEDEL",
  "SEDEM",
  "SEDEN",
  "SEDEŠ",
  "SEDEŽ",
  "SEDIJ",
  "SEDIM",
  "SEDIŠ",
  "SEDJA",
  "SEDJE",
  "SEDJI",
  "SEDJU",
  "SEDLA",
  "SEDLE",
  "SEDLI",
  "SEDLO",
  "SEDLU",
  "SEDMA",
  "SEDME",
  "SEDMI",
  "SEDMO",
  "SEDNA",
  "SEDNE",
  "SEDNI",
  "SEDNO",
  "SEFOM",
  "SEFOV",
  "SEGAJ",
  "SEGAL",
  "SEGAM",
  "SEGAŠ",
  "SEGEL",
  "SEGLA",
  "SEGLE",
  "SEGLI",
  "SEGLO",
  "SEJAL",
  "SEJAM",
  "SEJAN",
  "SEJEM",
  "SEJEN",
  "SEJEŠ",
  "SEJMA",
  "SEJME",
  "SEJMI",
  "SEJMO",
  "SEJMU",
  "SEJNA",
  "SEJNE",
  "SEJNI",
  "SEJNO",
  "SEJTA",
  "SEJTE",
  "SEJVA",
  "SEKAČ",
  "SEKAJ",
  "SEKAL",
  "SEKAM",
  "SEKAN",
  "SEKAŠ",
  "SEKIR",
  "SEKTA",
  "SEKTE",
  "SEKTI",
  "SEKTO",
  "SELCA",
  "SELCE",
  "SELCI",
  "SELCU",
  "SELEC",
  "SELEČ",
  "SELEN",
  "SELKA",
  "SELKE",
  "SELKI",
  "SELKO",
  "SEMEN",
  "SEMIŠ",
  "SEMIT",
  "SEMLE",
  "SENAR",
  "SENAT",
  "SENCA",
  "SENCE",
  "SENCI",
  "SENCO",
  "SENCU",
  "SENČI",
  "SENČK",
  "SENEN",
  "SENIK",
  "SEPIJ",
  "SEPOJ",
  "SEPSA",
  "SEPSE",
  "SEPSI",
  "SEPSO",
  "SERAF",
  "SERAJ",
  "SERAK",
  "SERAP",
  "SERCA",
  "SERCE",
  "SERCI",
  "SERCU",
  "SEREC",
  "SERIF",
  "SERIJ",
  "SERIN",
  "SERKA",
  "SERKE",
  "SERKI",
  "SERKO",
  "SEROM",
  "SEROV",
  "SEROZ",
  "SERUM",
  "SERŽA",
  "SERŽU",
  "SESAČ",
  "SESAJ",
  "SESAL",
  "SESAM",
  "SESAN",
  "SESAŠ",
  "SESEK",
  "SESEN",
  "SESIP",
  "SESKA",
  "SESKE",
  "SESKI",
  "SESKU",
  "SESNA",
  "SESNE",
  "SESNI",
  "SESNO",
  "SESTI",
  "SESUJ",
  "SESUT",
  "SEŠIJ",
  "SEŠIL",
  "SEŠIT",
  "SETER",
  "SETEV",
  "SETOM",
  "SETOV",
  "SETRA",
  "SETRE",
  "SETRI",
  "SETRU",
  "SETVE",
  "SETVI",
  "SEVAJ",
  "SEVAL",
  "SEVER",
  "SEVKA",
  "SEVKE",
  "SEVKI",
  "SEVKO",
  "SEVOM",
  "SEVOV",
  "SEZAM",
  "SEZON",
  "SEZUJ",
  "SEZUT",
  "SEŽAJ",
  "SEŽEM",
  "SEŽEŠ",
  "SEŽGI",
  "SEŽIG",
  "SFENA",
  "SFERA",
  "SFERE",
  "SFERI",
  "SFERO",
  "SFING",
  "SFRLI",
  "SHAJA",
  "SHEMA",
  "SHEME",
  "SHEMI",
  "SHEMO",
  "SHIRA",
  "SHITI",
  "SHODA",
  "SHODE",
  "SHODI",
  "SHODU",
  "SHUDA",
  "SIALA",
  "SIALU",
  "SIČAL",
  "SIČEČ",
  "SIČEM",
  "SIČEŠ",
  "SIČIM",
  "SIČIŠ",
  "SIDER",
  "SIDOL",
  "SIDRA",
  "SIDRC",
  "SIDRI",
  "SIDRN",
  "SIDRO",
  "SIDRU",
  "SIEST",
  "SIFAK",
  "SIFON",
  "SIGAJ",
  "SIGAL",
  "SIGAM",
  "SIGAŠ",
  "SIGAV",
  "SIGEM",
  "SIGMA",
  "SIGME",
  "SIGMI",
  "SIGMO",
  "SIGOV",
  "SIJAJ",
  "SIJAL",
  "SIJEM",
  "SIJEŠ",
  "SIJEV",
  "SIJMO",
  "SIJOČ",
  "SIJTA",
  "SIJTE",
  "SIJVA",
  "SIKAJ",
  "SIKAL",
  "SIKAM",
  "SIKAŠ",
  "SIKAV",
  "SIKHA",
  "SIKHE",
  "SIKHI",
  "SIKHU",
  "SIKNE",
  "SIKNI",
  "SIKOM",
  "SIKON",
  "SIKOV",
  "SILAK",
  "SILAM",
  "SILAŽ",
  "SILEN",
  "SILFA",
  "SILFE",
  "SILFI",
  "SILFO",
  "SILFU",
  "SILIL",
  "SILIM",
  "SILIN",
  "SILIŠ",
  "SILJA",
  "SILJE",
  "SILJI",
  "SILJU",
  "SILNA",
  "SILNE",
  "SILNI",
  "SILNO",
  "SILOS",
  "SILUR",
  "SIMAM",
  "SINEK",
  "SINEM",
  "SINEŠ",
  "SINIC",
  "SINIL",
  "SINJA",
  "SINJE",
  "SINJI",
  "SINKA",
  "SINKE",
  "SINKI",
  "SINKO",
  "SINKU",
  "SINOD",
  "SINOV",
  "SINUS",
  "SIOLA",
  "SIOLU",
  "SIPAČ",
  "SIPAJ",
  "SIPAM",
  "SIPEK",
  "SIPIC",
  "SIPIN",
  "SIPJA",
  "SIPJE",
  "SIPJI",
  "SIPKA",
  "SIPKE",
  "SIPKI",
  "SIPKO",
  "SIRAR",
  "SIREČ",
  "SIREK",
  "SIREN",
  "SIRIL",
  "SIRIM",
  "SIRIŠ",
  "SIRKA",
  "SIRKE",
  "SIRKI",
  "SIRKU",
  "SIRNA",
  "SIRNE",
  "SIRNI",
  "SIRNO",
  "SIROM",
  "SIROT",
  "SIROV",
  "SIRUP",
  "SISAL",
  "SIŠKA",
  "SIŠKE",
  "SIŠKI",
  "SIŠKO",
  "SITAM",
  "SITCA",
  "SITCE",
  "SITCI",
  "SITCU",
  "SITEC",
  "SITEN",
  "SITIH",
  "SITJA",
  "SITJE",
  "SITJU",
  "SITKA",
  "SITKE",
  "SITKI",
  "SITKO",
  "SITNA",
  "SITNE",
  "SITNI",
  "SITNO",
  "SITOM",
  "SITUL",
  "SIVCA",
  "SIVCE",
  "SIVCI",
  "SIVCU",
  "SIVEC",
  "SIVEČ",
  "SIVEL",
  "SIVIC",
  "SIVIL",
  "SIVIM",
  "SIVIN",
  "SIVIŠ",
  "SIVKA",
  "SIVKE",
  "SIVKI",
  "SIVKO",
  "SKAČE",
  "SKAČI",
  "SKAJA",
  "SKAJU",
  "SKAKA",
  "SKALA",
  "SKALD",
  "SKALE",
  "SKALI",
  "SKALO",
  "SKALP",
  "SKANK",
  "SKATA",
  "SKATE",
  "SKATI",
  "SKATU",
  "SKAVT",
  "SKAZA",
  "SKAZE",
  "SKAZI",
  "SKAZO",
  "SKEČA",
  "SKEČE",
  "SKEČI",
  "SKEČU",
  "SKELI",
  "SKEPI",
  "SKEPS",
  "SKESA",
  "SKICA",
  "SKICE",
  "SKICI",
  "SKICO",
  "SKIMA",
  "SKINK",
  "SKIPI",
  "SKIRO",
  "SKLAČ",
  "SKLAD",
  "SKLAL",
  "SKLAN",
  "SKLEC",
  "SKLED",
  "SKLEP",
  "SKLIC",
  "SKLON",
  "SKLOP",
  "SKOBA",
  "SKOBE",
  "SKOBI",
  "SKOBO",
  "SKOČI",
  "SKOKA",
  "SKOKE",
  "SKOKI",
  "SKOKU",
  "SKOPA",
  "SKOPE",
  "SKOPI",
  "SKOPO",
  "SKORŠ",
  "SKOTA",
  "SKOTE",
  "SKOTI",
  "SKOTU",
  "SKOZI",
  "SKRAM",
  "SKRBE",
  "SKRBI",
  "SKRČI",
  "SKRČK",
  "SKRIJ",
  "SKRIL",
  "SKRIT",
  "SKRKA",
  "SKRKE",
  "SKRKI",
  "SKRKO",
  "SKRPA",
  "SKUAM",
  "SKUBE",
  "SKUBI",
  "SKUHA",
  "SKUIN",
  "SKUJA",
  "SKUNK",
  "SKURI",
  "SKUSI",
  "SKUŠA",
  "SKUŠE",
  "SKUŠI",
  "SKUŠO",
  "SKUTA",
  "SKUTE",
  "SKUTI",
  "SKUTO",
  "SKVOŠ",
  "SKVOT",
  "SLABA",
  "SLABE",
  "SLABI",
  "SLABO",
  "SLAČI",
  "SLADA",
  "SLADI",
  "SLADU",
  "SLAKA",
  "SLAKU",
  "SLAMA",
  "SLAME",
  "SLAMI",
  "SLAMO",
  "SLANA",
  "SLANE",
  "SLANI",
  "SLANO",
  "SLAPA",
  "SLAPE",
  "SLAPI",
  "SLAPU",
  "SLAST",
  "SLAVA",
  "SLAVE",
  "SLAVI",
  "SLAVO",
  "SLECI",
  "SLEČA",
  "SLEČE",
  "SLEČI",
  "SLEČU",
  "SLEDA",
  "SLEDE",
  "SLEDI",
  "SLEDU",
  "SLEME",
  "SLENG",
  "SLEPA",
  "SLEPE",
  "SLEPI",
  "SLEPK",
  "SLEPO",
  "SLEZA",
  "SLEZE",
  "SLEZI",
  "SLEZU",
  "SLIKA",
  "SLIKE",
  "SLIKI",
  "SLIKO",
  "SLINA",
  "SLINE",
  "SLINI",
  "SLINO",
  "SLIPA",
  "SLIPE",
  "SLIPI",
  "SLIPU",
  "SLIŠE",
  "SLIŠI",
  "SLIVA",
  "SLIVE",
  "SLIVI",
  "SLIVO",
  "SLJUD",
  "SLOČI",
  "SLOGA",
  "SLOGE",
  "SLOGI",
  "SLOGO",
  "SLOGU",
  "SLOJA",
  "SLOJE",
  "SLOJI",
  "SLOJU",
  "SLOKA",
  "SLOKE",
  "SLOKI",
  "SLOKO",
  "SLOMA",
  "SLONA",
  "SLONE",
  "SLONI",
  "SLONU",
  "SLOPA",
  "SLOPE",
  "SLOPI",
  "SLOPU",
  "SLOVE",
  "SLOVO",
  "SLUGA",
  "SLUGE",
  "SLUGI",
  "SLUGO",
  "SLUGU",
  "SLUHA",
  "SLUHU",
  "SLUPA",
  "SLUPE",
  "SLUPI",
  "SLUPU",
  "SLUTI",
  "SLUZA",
  "SLUZI",
  "SLUZU",
  "SLUŽB",
  "SLUŽI",
  "SMALT",
  "SMEHA",
  "SMEHU",
  "SMEJE",
  "SMEJO",
  "SMELA",
  "SMELE",
  "SMELI",
  "SMELO",
  "SMEMO",
  "SMERI",
  "SMEŠI",
  "SMETA",
  "SMETE",
  "SMETI",
  "SMEVA",
  "SMILJ",
  "SMODA",
  "SMODE",
  "SMODI",
  "SMOGA",
  "SMOGE",
  "SMOGI",
  "SMOGU",
  "SMOLA",
  "SMOLE",
  "SMOLI",
  "SMOLO",
  "SMRAD",
  "SMRČE",
  "SMRČI",
  "SMRDE",
  "SMRDI",
  "SMREK",
  "SMRKA",
  "SMRKE",
  "SMRKI",
  "SMRKU",
  "SMRTI",
  "SMUČI",
  "SMUČK",
  "SMUČU",
  "SMUKA",
  "SMUKE",
  "SMUKI",
  "SMUKO",
  "SMUKU",
  "SMUTI",
  "SNAGA",
  "SNAGE",
  "SNAGI",
  "SNAGO",
  "SNAHA",
  "SNAHE",
  "SNAHI",
  "SNAHO",
  "SNAST",
  "SNAŽI",
  "SNEGA",
  "SNEGU",
  "SNEMA",
  "SNEMI",
  "SNETA",
  "SNETE",
  "SNETI",
  "SNETO",
  "SNEŽI",
  "SNEŽK",
  "SNOBA",
  "SNOBE",
  "SNOBI",
  "SNOBU",
  "SNOČI",
  "SNOMA",
  "SNOPA",
  "SNOPE",
  "SNOPI",
  "SNOPU",
  "SNOVI",
  "SNUBI",
  "SOBAM",
  "SOBAN",
  "SOBAR",
  "SOBEN",
  "SOBIC",
  "SOBNA",
  "SOBNE",
  "SOBNI",
  "SOBNO",
  "SOBOT",
  "SOČEČ",
  "SOČEN",
  "SOČNA",
  "SOČNE",
  "SOČNI",
  "SOČNO",
  "SODAM",
  "SODAR",
  "SODAV",
  "SODBA",
  "SODBE",
  "SODBI",
  "SODBO",
  "SODCA",
  "SODCE",
  "SODCI",
  "SODCU",
  "SODEC",
  "SODEČ",
  "SODEL",
  "SODEN",
  "SODIL",
  "SODIM",
  "SODIN",
  "SODIŠ",
  "SODNA",
  "SODNE",
  "SODNI",
  "SODNO",
  "SODOM",
  "SODOV",
  "SODRA",
  "SODRE",
  "SODRG",
  "SODRI",
  "SODRO",
  "SOFIT",
  "SOFOR",
  "SOHAM",
  "SOHIC",
  "SOJEM",
  "SOJEN",
  "SOJEV",
  "SOJIN",
  "SOKOL",
  "SOKOT",
  "SOKOV",
  "SOLAT",
  "SOLDA",
  "SOLDE",
  "SOLDI",
  "SOLDU",
  "SOLEČ",
  "SOLEM",
  "SOLEN",
  "SOLID",
  "SOLIL",
  "SOLIM",
  "SOLIN",
  "SOLIŠ",
  "SOLNA",
  "SOLNE",
  "SOLNI",
  "SOLNO",
  "SOLOM",
  "SOLOV",
  "SOLZA",
  "SOLZE",
  "SOLZI",
  "SOLZO",
  "SOMAN",
  "SOMIC",
  "SOMIČ",
  "SOMOM",
  "SOMOV",
  "SONAR",
  "SONAT",
  "SONCA",
  "SONCE",
  "SONCI",
  "SONCU",
  "SONČI",
  "SONDA",
  "SONDE",
  "SONDI",
  "SONDO",
  "SONET",
  "SOOČI",
  "SOPAR",
  "SOPEČ",
  "SOPEL",
  "SOPEM",
  "SOPEŠ",
  "SOPIH",
  "SOPLA",
  "SOPLE",
  "SOPLI",
  "SOPLO",
  "SOPNE",
  "SOPNI",
  "SOPUH",
  "SOROD",
  "SORTA",
  "SORTE",
  "SORTI",
  "SORTO",
  "SOSED",
  "SOŠEN",
  "SOŠIC",
  "SOŠKA",
  "SOŠKE",
  "SOŠKI",
  "SOŠKO",
  "SOŠNA",
  "SOŠNE",
  "SOŠNI",
  "SOŠNO",
  "SOULA",
  "SOULU",
  "SOVAM",
  "SOVCA",
  "SOVCE",
  "SOVCI",
  "SOVCU",
  "SOVEC",
  "SOVIC",
  "SOVIN",
  "SOVIR",
  "SOVJA",
  "SOVJE",
  "SOVJI",
  "SOVKA",
  "SOVKE",
  "SOVKI",
  "SOVKO",
  "SOZDA",
  "SOZDE",
  "SOZDI",
  "SOZDU",
  "SPAČI",
  "SPADA",
  "SPAHA",
  "SPAHE",
  "SPAHI",
  "SPAHU",
  "SPAJK",
  "SPAKA",
  "SPAKE",
  "SPAKI",
  "SPAKO",
  "SPAKU",
  "SPALA",
  "SPALE",
  "SPALI",
  "SPALO",
  "SPAMA",
  "SPAMU",
  "SPARA",
  "SPATI",
  "SPECI",
  "SPEČA",
  "SPEČE",
  "SPEČI",
  "SPEHA",
  "SPENI",
  "SPERI",
  "SPERM",
  "SPETA",
  "SPETE",
  "SPETI",
  "SPETK",
  "SPETO",
  "SPEVA",
  "SPEVE",
  "SPEVI",
  "SPEVU",
  "SPHAJ",
  "SPHAL",
  "SPHAM",
  "SPHAN",
  "SPHAŠ",
  "SPIHA",
  "SPIJE",
  "SPIJO",
  "SPILA",
  "SPILE",
  "SPILI",
  "SPILO",
  "SPIMO",
  "SPINA",
  "SPINE",
  "SPINI",
  "SPINO",
  "SPINU",
  "SPISA",
  "SPISE",
  "SPISI",
  "SPISU",
  "SPIŠI",
  "SPITA",
  "SPITE",
  "SPITI",
  "SPIVA",
  "SPLAV",
  "SPLET",
  "SPODI",
  "SPOJA",
  "SPOJE",
  "SPOJI",
  "SPOJK",
  "SPOJU",
  "SPOLA",
  "SPOLE",
  "SPOLI",
  "SPOLU",
  "SPONA",
  "SPONE",
  "SPONI",
  "SPONK",
  "SPONO",
  "SPORA",
  "SPORE",
  "SPORI",
  "SPORO",
  "SPORU",
  "SPOTA",
  "SPOTE",
  "SPOTI",
  "SPOTU",
  "SPRAN",
  "SPRAV",
  "SPRTA",
  "SPRTE",
  "SPRTI",
  "SPRTO",
  "SPRVA",
  "SPŠEM",
  "SPŠEŠ",
  "SPUST",
  "SRAGA",
  "SRAGE",
  "SRAGI",
  "SRAGO",
  "SRAJC",
  "SRAKA",
  "SRAKE",
  "SRAKI",
  "SRAKO",
  "SRAMA",
  "SRAMU",
  "SRBCA",
  "SRBCE",
  "SRBCI",
  "SRBCU",
  "SRBEC",
  "SRBEČ",
  "SRBEŽ",
  "SRCEM",
  "SRČEC",
  "SRČEK",
  "SRČEM",
  "SRČEN",
  "SRČEŠ",
  "SRČEV",
  "SRČKA",
  "SRČKE",
  "SRČKI",
  "SRČKU",
  "SRČNA",
  "SRČNE",
  "SRČNI",
  "SRČNO",
  "SRDEČ",
  "SRDEN",
  "SRDIT",
  "SRDOM",
  "SRDOV",
  "SREČA",
  "SREČE",
  "SREČI",
  "SREČK",
  "SREČO",
  "SREDA",
  "SREDE",
  "SREDI",
  "SREDO",
  "SRENA",
  "SRENE",
  "SRENI",
  "SRENJ",
  "SRENU",
  "SREPA",
  "SREPE",
  "SREPI",
  "SREPO",
  "SREZA",
  "SREZE",
  "SREZI",
  "SREZU",
  "SREŽA",
  "SREŽE",
  "SREŽI",
  "SREŽU",
  "SRHEK",
  "SRHKA",
  "SRHKE",
  "SRHKI",
  "SRHKO",
  "SRHOM",
  "SRHOV",
  "SRKAJ",
  "SRKAL",
  "SRKAM",
  "SRKAŠ",
  "SRKNI",
  "SRKOM",
  "SRKOV",
  "SRNAM",
  "SRNIC",
  "SRNIN",
  "SRNJA",
  "SRNJE",
  "SRNJI",
  "SRPAN",
  "SRPAT",
  "SRPEK",
  "SRPIC",
  "SRPIČ",
  "SRPIJ",
  "SRPJA",
  "SRPJE",
  "SRPJI",
  "SRPJU",
  "SRPKA",
  "SRPKE",
  "SRPKI",
  "SRPKU",
  "SRPOM",
  "SRPOV",
  "SRSTI",
  "SRŠAJ",
  "SRŠAL",
  "SRŠAT",
  "SRŠAV",
  "SRŠEČ",
  "SRŠEL",
  "SRŠEN",
  "SRŠIC",
  "SRTEM",
  "SRŽEM",
  "STAJA",
  "STAJE",
  "STAJI",
  "STAJO",
  "STALA",
  "STALE",
  "STALI",
  "STALO",
  "STANA",
  "STANC",
  "STANE",
  "STANI",
  "STANJ",
  "STANU",
  "STARA",
  "STARE",
  "STARI",
  "STARK",
  "STARO",
  "STARŠ",
  "START",
  "STASA",
  "STASU",
  "STATI",
  "STAVA",
  "STAVB",
  "STAVE",
  "STAVI",
  "STAVK",
  "STAVO",
  "STAŽA",
  "STAŽE",
  "STAŽI",
  "STAŽU",
  "STECI",
  "STEČE",
  "STEJK",
  "STEKA",
  "STEKE",
  "STEKI",
  "STEKU",
  "STELA",
  "STELE",
  "STELI",
  "STELO",
  "STENA",
  "STENE",
  "STENI",
  "STENJ",
  "STENO",
  "STENT",
  "STEPA",
  "STEPE",
  "STEPI",
  "STEPO",
  "STEPU",
  "STEŠE",
  "STEŠI",
  "STEZA",
  "STEZE",
  "STEZI",
  "STEZO",
  "STIHA",
  "STIHE",
  "STIHI",
  "STIHU",
  "STIKA",
  "STIKE",
  "STIKI",
  "STIKU",
  "STINT",
  "STISK",
  "STIVA",
  "STIVE",
  "STIVI",
  "STIVO",
  "STKAN",
  "STOČE",
  "STOČI",
  "STOGA",
  "STOGE",
  "STOGI",
  "STOGU",
  "STOIK",
  "STOJA",
  "STOJE",
  "STOJI",
  "STOJK",
  "STOJO",
  "STOKA",
  "STOKE",
  "STOKI",
  "STOKU",
  "STOLA",
  "STOLE",
  "STOLI",
  "STOLP",
  "STOLU",
  "STOMA",
  "STOME",
  "STOMI",
  "STOMO",
  "STOOK",
  "STOPA",
  "STOPE",
  "STOPI",
  "STOPO",
  "STOPU",
  "STORI",
  "STORŽ",
  "STOTA",
  "STOTE",
  "STOTI",
  "STOTO",
  "STOTU",
  "STOŽA",
  "STOŽE",
  "STOŽI",
  "STOŽK",
  "STOŽU",
  "STRAH",
  "STRAN",
  "STRAV",
  "STRAŽ",
  "STRDI",
  "STREH",
  "STREL",
  "STRES",
  "STRIC",
  "STRIG",
  "STRIN",
  "STRIP",
  "STRKA",
  "STRMA",
  "STRME",
  "STRMI",
  "STRMO",
  "STRNI",
  "STROG",
  "STROJ",
  "STROK",
  "STROM",
  "STROP",
  "STRPE",
  "STRPI",
  "STRTA",
  "STRTE",
  "STRTI",
  "STRTO",
  "STRUG",
  "STRUM",
  "STRUN",
  "STRUP",
  "STUDA",
  "STUDU",
  "STVAR",
  "STVOR",
  "SUČEN",
  "SUČNA",
  "SUČNE",
  "SUČNI",
  "SUČNO",
  "SUFLE",
  "SUHCA",
  "SUHCE",
  "SUHCI",
  "SUHCU",
  "SUHEC",
  "SUHOT",
  "SUITA",
  "SUITE",
  "SUITI",
  "SUITO",
  "SUJEM",
  "SUJEŠ",
  "SUJMO",
  "SUJTA",
  "SUJTE",
  "SUJVA",
  "SUKAČ",
  "SUKAJ",
  "SUKAN",
  "SUKNA",
  "SUKNO",
  "SUKNU",
  "SUKUB",
  "SULCA",
  "SULCE",
  "SULCI",
  "SULCU",
  "SULEC",
  "SULIC",
  "SULKI",
  "SUMEČ",
  "SUMOM",
  "SUMOV",
  "SUNEK",
  "SUNEM",
  "SUNEŠ",
  "SUNIL",
  "SUNIS",
  "SUNIT",
  "SUNKA",
  "SUNKE",
  "SUNKI",
  "SUNKU",
  "SUPAJ",
  "SUPAL",
  "SUPAM",
  "SUPAŠ",
  "SUPOM",
  "SUPOV",
  "SURAM",
  "SURIL",
  "SUROV",
  "SUŠAM",
  "SUŠEČ",
  "SUŠEN",
  "SUŠIL",
  "SUŠIN",
  "SUŠKA",
  "SUŠKE",
  "SUŠKI",
  "SUŠKO",
  "SUŠNA",
  "SUŠNE",
  "SUŠNI",
  "SUŠNO",
  "SUTER",
  "SUTRA",
  "SUTRE",
  "SUTRI",
  "SUTRO",
  "SUVAJ",
  "SUVAL",
  "SUVAM",
  "SUVAN",
  "SUVAŠ",
  "SVAJA",
  "SVAJE",
  "SVAJI",
  "SVAJO",
  "SVAKA",
  "SVAKE",
  "SVAKI",
  "SVAKU",
  "SVARI",
  "SVAST",
  "SVATA",
  "SVATB",
  "SVATE",
  "SVATI",
  "SVATU",
  "SVEČA",
  "SVEČE",
  "SVEČI",
  "SVEČK",
  "SVEČO",
  "SVETA",
  "SVETE",
  "SVETI",
  "SVETO",
  "SVETU",
  "SVEŽA",
  "SVEŽE",
  "SVEŽI",
  "SVILA",
  "SVILE",
  "SVILI",
  "SVILO",
  "SVING",
  "SVINJ",
  "SVIŠČ",
  "SVITA",
  "SVITE",
  "SVITI",
  "SVITU",
  "SVIŽA",
  "SVIŽU",
  "SVODA",
  "SVODE",
  "SVODI",
  "SVODU",
  "SVORA",
  "SVORE",
  "SVORI",
  "SVORO",
  "ŠABAT",
  "ŠABES",
  "ŠAHOM",
  "ŠAHOV",
  "ŠAKAL",
  "ŠALAM",
  "ŠALEČ",
  "ŠALOM",
  "ŠALOV",
  "ŠALUP",
  "ŠAMAN",
  "ŠAMOT",
  "ŠAPAM",
  "ŠAPIC",
  "ŠAPNE",
  "ŠAPNI",
  "ŠARAD",
  "ŠARCA",
  "ŠARCE",
  "ŠARCI",
  "ŠARCU",
  "ŠAREC",
  "ŠAREČ",
  "ŠARGA",
  "ŠARGE",
  "ŠARGI",
  "ŠARGU",
  "ŠARMA",
  "ŠARMU",
  "ŠASIJ",
  "ŠAŠEM",
  "ŠAŠEV",
  "ŠAŠJA",
  "ŠAŠJE",
  "ŠAŠJU",
  "ŠAVER",
  "ŠAVRA",
  "ŠAVRE",
  "ŠAVRI",
  "ŠAVRO",
  "ŠČEME",
  "ŠČEMI",
  "ŠČENE",
  "ŠČEPA",
  "ŠČEPE",
  "ŠČEPI",
  "ŠČEPU",
  "ŠČETK",
  "ŠČIPA",
  "ŠČIPE",
  "ŠČIPI",
  "ŠČIPU",
  "ŠČIRA",
  "ŠČIRE",
  "ŠČIRI",
  "ŠČIRU",
  "ŠČITA",
  "ŠČITE",
  "ŠČITI",
  "ŠČITU",
  "ŠČUKA",
  "ŠČUKE",
  "ŠČUKI",
  "ŠČUKO",
  "ŠEBOJ",
  "ŠEDOM",
  "ŠEDOV",
  "ŠEFIC",
  "ŠEFOM",
  "ŠEFOV",
  "ŠEGAM",
  "ŠEGAV",
  "ŠEHOM",
  "ŠEHOV",
  "ŠEJEK",
  "ŠEJKA",
  "ŠEJKE",
  "ŠEJKI",
  "ŠEJKU",
  "ŠEKEL",
  "ŠEKLA",
  "ŠEKLE",
  "ŠEKLI",
  "ŠEKLU",
  "ŠELAK",
  "ŠEMAM",
  "ŠEMEČ",
  "ŠEMIC",
  "ŠEMIL",
  "ŠEMIM",
  "ŠEMIN",
  "ŠEMIŠ",
  "ŠENOM",
  "ŠENOV",
  "ŠEPAJ",
  "ŠEPAL",
  "ŠEPAM",
  "ŠEPAŠ",
  "ŠEPAV",
  "ŠEPET",
  "ŠEPNE",
  "ŠEPNI",
  "ŠERIF",
  "ŠERPA",
  "ŠERPE",
  "ŠERPI",
  "ŠERPO",
  "ŠERPU",
  "ŠESTA",
  "ŠESTE",
  "ŠESTI",
  "ŠESTO",
  "ŠEŠKA",
  "ŠEŠKE",
  "ŠEŠKI",
  "ŠEŠKO",
  "ŠIBAK",
  "ŠIBAM",
  "ŠIBEČ",
  "ŠIBEK",
  "ŠIBER",
  "ŠIBIC",
  "ŠIBIJ",
  "ŠIBJA",
  "ŠIBJE",
  "ŠIBJI",
  "ŠIBJU",
  "ŠIBKA",
  "ŠIBKE",
  "ŠIBKI",
  "ŠIBKO",
  "ŠIBRA",
  "ŠIBRE",
  "ŠIBRI",
  "ŠIBRO",
  "ŠIFER",
  "ŠIFRA",
  "ŠIFRE",
  "ŠIFRI",
  "ŠIFRO",
  "ŠIITA",
  "ŠIITE",
  "ŠIITI",
  "ŠIITU",
  "ŠIKAN",
  "ŠILAR",
  "ŠILCA",
  "ŠILCE",
  "ŠILCI",
  "ŠILCU",
  "ŠILEČ",
  "ŠILOM",
  "ŠINEM",
  "ŠINEŠ",
  "ŠINIL",
  "ŠINJA",
  "ŠINJE",
  "ŠINJI",
  "ŠINJU",
  "ŠIPAM",
  "ŠIPEK",
  "ŠIPKA",
  "ŠIPKE",
  "ŠIPKI",
  "ŠIPKU",
  "ŠIPON",
  "ŠIREN",
  "ŠIRIN",
  "ŠIRNA",
  "ŠIRNE",
  "ŠIRNI",
  "ŠIRNO",
  "ŠIROK",
  "ŠIRŠA",
  "ŠIRŠE",
  "ŠIRŠI",
  "ŠIŠAM",
  "ŠIŠKA",
  "ŠIŠKE",
  "ŠIŠKI",
  "ŠIŠKO",
  "ŠITAK",
  "ŠIVAJ",
  "ŠIVAL",
  "ŠIVAM",
  "ŠIVAN",
  "ŠIVAŠ",
  "ŠIVEN",
  "ŠIVNA",
  "ŠIVNE",
  "ŠIVNI",
  "ŠIVNO",
  "ŠIVOM",
  "ŠIVOV",
  "ŠKAFA",
  "ŠKAFE",
  "ŠKAFI",
  "ŠKAFU",
  "ŠKAJA",
  "ŠKAJE",
  "ŠKAJI",
  "ŠKAJO",
  "ŠKAMP",
  "ŠKARP",
  "ŠKILI",
  "ŠKISA",
  "ŠKISE",
  "ŠKISI",
  "ŠKISU",
  "ŠKLOC",
  "ŠKODA",
  "ŠKODE",
  "ŠKODI",
  "ŠKODO",
  "ŠKOFA",
  "ŠKOFE",
  "ŠKOFI",
  "ŠKOFU",
  "ŠKOLJ",
  "ŠKOPA",
  "ŠKOPE",
  "ŠKOPI",
  "ŠKOPO",
  "ŠKOTA",
  "ŠKOTE",
  "ŠKOTI",
  "ŠKOTO",
  "ŠKRAT",
  "ŠKRBE",
  "ŠKRBI",
  "ŠKRGA",
  "ŠKRGE",
  "ŠKRGI",
  "ŠKRGO",
  "ŠKRIC",
  "ŠKRLI",
  "ŠKROB",
  "ŠKURH",
  "ŠKVER",
  "ŠLEMA",
  "ŠLEME",
  "ŠLEMI",
  "ŠLEMU",
  "ŠMENT",
  "ŠMINK",
  "ŠNJUR",
  "ŠOAHA",
  "ŠOAHU",
  "ŠOBAM",
  "ŠOBEN",
  "ŠOBIC",
  "ŠOBNA",
  "ŠOBNE",
  "ŠOBNI",
  "ŠOBNO",
  "ŠOFER",
  "ŠOGUN",
  "ŠOJAM",
  "ŠOKOM",
  "ŠOKOV",
  "ŠOLAJ",
  "ŠOLAM",
  "ŠOLAN",
  "ŠOLAR",
  "ŠOPEK",
  "ŠOPKA",
  "ŠOPKE",
  "ŠOPKI",
  "ŠOPKU",
  "ŠOPOM",
  "ŠOPOV",
  "ŠOTAM",
  "ŠOTAR",
  "ŠOTEN",
  "ŠOTIŠ",
  "ŠOTNA",
  "ŠOTNE",
  "ŠOTNI",
  "ŠOTNO",
  "ŠOTOR",
  "ŠOVOM",
  "ŠOVOV",
  "ŠPAJK",
  "ŠPARA",
  "ŠPARE",
  "ŠPARI",
  "ŠPARU",
  "ŠPICA",
  "ŠPICE",
  "ŠPICI",
  "ŠPICO",
  "ŠPICU",
  "ŠPIKA",
  "ŠPIKE",
  "ŠPIKI",
  "ŠPIKU",
  "ŠPILA",
  "ŠPILE",
  "ŠPILI",
  "ŠPILJ",
  "ŠPILO",
  "ŠPORT",
  "ŠRANG",
  "ŠTABA",
  "ŠTABE",
  "ŠTABI",
  "ŠTABU",
  "ŠTANC",
  "ŠTEJE",
  "ŠTELA",
  "ŠTELE",
  "ŠTELI",
  "ŠTELO",
  "ŠTETA",
  "ŠTETE",
  "ŠTETI",
  "ŠTETO",
  "ŠTEVK",
  "ŠTIRI",
  "ŠTOLA",
  "ŠTOLE",
  "ŠTOLI",
  "ŠTOLO",
  "ŠTORA",
  "ŠTORE",
  "ŠTORI",
  "ŠTORU",
  "ŠTOSA",
  "ŠTOSE",
  "ŠTOSI",
  "ŠTOSU",
  "ŠTRAS",
  "ŠTRCA",
  "ŠTRKA",
  "ŠTRKE",
  "ŠTRKI",
  "ŠTRKU",
  "ŠTRLE",
  "ŠTRLI",
  "ŠTRUC",
  "ŠTULA",
  "ŠTULE",
  "ŠTULI",
  "ŠTULO",
  "ŠUMEČ",
  "ŠUMEL",
  "ŠUMEN",
  "ŠUMIM",
  "ŠUMIŠ",
  "ŠUMNA",
  "ŠUMNE",
  "ŠUMNI",
  "ŠUMNO",
  "ŠUMOM",
  "ŠUMOT",
  "ŠUMOV",
  "ŠUNDA",
  "ŠUNDU",
  "ŠUNKA",
  "ŠUNKE",
  "ŠUNKI",
  "ŠUNKO",
  "ŠURAM",
  "ŠUROM",
  "ŠUROV",
  "ŠUŠTE",
  "ŠUŠTI",
  "ŠVABA",
  "ŠVABE",
  "ŠVABI",
  "ŠVABU",
  "ŠVIGA",
  "ŠVIST",
  "ŠVRKA",
  "ŠVRKE",
  "ŠVRKI",
  "ŠVRKU",
  "TABEL",
  "TABLA",
  "TABLE",
  "TABLI",
  "TABLO",
  "TABOR",
  "TABOT",
  "TABUN",
  "TACAJ",
  "TACAM",
  "TAČAS",
  "TAČIC",
  "TAČKA",
  "TAČKE",
  "TAČKI",
  "TAČKO",
  "TADVA",
  "TAEBO",
  "TAFTA",
  "TAFTU",
  "TAJAJ",
  "TAJEČ",
  "TAJEN",
  "TAJGA",
  "TAJGE",
  "TAJGI",
  "TAJGO",
  "TAJNA",
  "TAJNE",
  "TAJNI",
  "TAJNO",
  "TAKAH",
  "TAKIJ",
  "TAKIN",
  "TAKLE",
  "TAKOJ",
  "TAKSI",
  "TAKTA",
  "TAKTE",
  "TAKTI",
  "TAKTU",
  "TALAR",
  "TALCA",
  "TALCE",
  "TALCI",
  "TALCU",
  "TALEC",
  "TALEČ",
  "TALEN",
  "TALIB",
  "TALIG",
  "TALIJ",
  "TALIL",
  "TALIN",
  "TALKA",
  "TALKE",
  "TALKI",
  "TALKO",
  "TALNA",
  "TALNE",
  "TALNI",
  "TALNO",
  "TALON",
  "TALOZ",
  "TAMLE",
  "TANEČ",
  "TANEK",
  "TANGA",
  "TANGE",
  "TANGI",
  "TANGO",
  "TANGU",
  "TANIL",
  "TANIM",
  "TANIŠ",
  "TANJE",
  "TANKA",
  "TANKE",
  "TANKI",
  "TANKO",
  "TANKU",
  "TAPAS",
  "TAPET",
  "TAPIR",
  "TAPKA",
  "TAPNE",
  "TAPNI",
  "TARAM",
  "TARČA",
  "TARČE",
  "TARČI",
  "TARČO",
  "TARIF",
  "TARNA",
  "TAROK",
  "TAROM",
  "TAROT",
  "TAROV",
  "TASTA",
  "TASTE",
  "TASTI",
  "TASTU",
  "TAŠČA",
  "TAŠČE",
  "TAŠČI",
  "TAŠČO",
  "TATAM",
  "TATIC",
  "TATIČ",
  "TATOM",
  "TATOV",
  "TAUOM",
  "TAUOV",
  "TAVAJ",
  "TAVAL",
  "TAVAM",
  "TAVAŠ",
  "TEČAJ",
  "TEČEM",
  "TEČEN",
  "TEČEŠ",
  "TEČKA",
  "TEČKE",
  "TEČKI",
  "TEČKO",
  "TEČNA",
  "TEČNE",
  "TEČNI",
  "TEČNO",
  "TEDAJ",
  "TEDEN",
  "TEDNA",
  "TEDNE",
  "TEDNI",
  "TEDNU",
  "TEDVE",
  "TEGOB",
  "TEGOL",
  "TEHLE",
  "TEHTA",
  "TEINA",
  "TEINU",
  "TEIST",
  "TEJLE",
  "TEKAČ",
  "TEKAJ",
  "TEKAL",
  "TEKAM",
  "TEKAŠ",
  "TEKEL",
  "TEKEM",
  "TEKIC",
  "TEKIL",
  "TEKLA",
  "TEKLE",
  "TEKLI",
  "TEKLO",
  "TEKMA",
  "TEKME",
  "TEKMI",
  "TEKMO",
  "TEKNE",
  "TEKNI",
  "TEKOČ",
  "TEKOM",
  "TEKOV",
  "TEKUT",
  "TELEG",
  "TELES",
  "TELET",
  "TELIC",
  "TELIL",
  "TELIM",
  "TELIN",
  "TELIŠ",
  "TELOH",
  "TELOP",
  "TELUR",
  "TEMAM",
  "TEMAN",
  "TEMEN",
  "TEMLE",
  "TEMNA",
  "TEMNE",
  "TEMNI",
  "TEMNO",
  "TEMOT",
  "TEMPA",
  "TEMPE",
  "TEMPI",
  "TEMPO",
  "TEMPU",
  "TENAK",
  "TENEK",
  "TENIS",
  "TENKA",
  "TENKE",
  "TENKI",
  "TENKO",
  "TENOM",
  "TENOR",
  "TENOV",
  "TEPAČ",
  "TEPCA",
  "TEPCE",
  "TEPCI",
  "TEPCU",
  "TEPEC",
  "TEPEN",
  "TEPEŽ",
  "TEPKA",
  "TEPKE",
  "TEPKI",
  "TEPKO",
  "TERAN",
  "TERAS",
  "TERCA",
  "TERCE",
  "TERCI",
  "TERCO",
  "TEREN",
  "TERIC",
  "TERIJ",
  "TERJE",
  "TERMA",
  "TERME",
  "TERMI",
  "TERMO",
  "TERNA",
  "TERNE",
  "TERNI",
  "TERNO",
  "TEROČ",
  "TEROR",
  "TESAČ",
  "TESAL",
  "TESAN",
  "TESAR",
  "TESEL",
  "TESEN",
  "TESLA",
  "TESLE",
  "TESLI",
  "TESLO",
  "TESLU",
  "TESNA",
  "TESNE",
  "TESNI",
  "TESNO",
  "TESTA",
  "TESTE",
  "TESTI",
  "TESTO",
  "TESTU",
  "TEŠČA",
  "TEŠČE",
  "TEŠČI",
  "TEŠEČ",
  "TEŠEM",
  "TEŠEN",
  "TEŠEŠ",
  "TEŠIL",
  "TEŠIM",
  "TEŠIŠ",
  "TEŠOČ",
  "TETAM",
  "TETER",
  "TETIC",
  "TETIN",
  "TETIV",
  "TETKA",
  "TETKE",
  "TETKI",
  "TETKO",
  "TETRA",
  "TETRE",
  "TETRI",
  "TETRO",
  "TEVIJ",
  "TEVJA",
  "TEVJE",
  "TEVJI",
  "TEVJU",
  "TEZAM",
  "TEŽAK",
  "TEŽAM",
  "TEŽAV",
  "TEŽCA",
  "TEŽCU",
  "TEŽEC",
  "TEŽEK",
  "TEŽEN",
  "TEŽJA",
  "TEŽJE",
  "TEŽJI",
  "TEŽKA",
  "TEŽKE",
  "TEŽKI",
  "TEŽKO",
  "TEŽNA",
  "TEŽNE",
  "TEŽNI",
  "TEŽNO",
  "THETA",
  "THETE",
  "THETI",
  "THETO",
  "TIARA",
  "TIARE",
  "TIARI",
  "TIARO",
  "TIČEK",
  "TIČIC",
  "TIČKA",
  "TIČKE",
  "TIČKI",
  "TIČKO",
  "TIČKU",
  "TIDVE",
  "TIFUS",
  "TIGER",
  "TIGON",
  "TIGRA",
  "TIGRE",
  "TIGRI",
  "TIGRU",
  "TIHOT",
  "TIKOM",
  "TIKOV",
  "TILDA",
  "TILDE",
  "TILDI",
  "TILDO",
  "TIMIN",
  "TIMOL",
  "TIMOM",
  "TIOLA",
  "TIOLE",
  "TIOLI",
  "TIOLU",
  "TIPAJ",
  "TIPAL",
  "TIPAM",
  "TIPAŠ",
  "TIPEN",
  "TIPIC",
  "TIPIK",
  "TIPKA",
  "TIPKE",
  "TIPKI",
  "TIPKO",
  "TIPNA",
  "TIPNE",
  "TIPNI",
  "TIPNO",
  "TIPOM",
  "TIPOV",
  "TIRAD",
  "TIRAJ",
  "TIRAL",
  "TIRAM",
  "TIRAN",
  "TIRAŠ",
  "TIREN",
  "TIRNA",
  "TIRNE",
  "TIRNI",
  "TIRNO",
  "TIROM",
  "TIROV",
  "TIRSA",
  "TIRSE",
  "TIRSI",
  "TIRSU",
  "TISAM",
  "TISIN",
  "TISKA",
  "TISKE",
  "TISKI",
  "TISKU",
  "TISOČ",
  "TISOV",
  "TISTA",
  "TISTE",
  "TISTI",
  "TISTO",
  "TIŠČE",
  "TIŠČI",
  "TIŠIN",
  "TIŠJA",
  "TIŠJE",
  "TIŠJI",
  "TIŠKA",
  "TIŠKE",
  "TIŠKI",
  "TIŠKO",
  "TITAN",
  "TITER",
  "TITRA",
  "TITRE",
  "TITRI",
  "TITRU",
  "TJALE",
  "TKALK",
  "TKANA",
  "TKANE",
  "TKANI",
  "TKANJ",
  "TKANO",
  "TKIMO",
  "TKITA",
  "TKITE",
  "TKIVA",
  "TKIVI",
  "TKIVO",
  "TKIVU",
  "TKOČA",
  "TKOČE",
  "TKOČI",
  "TLAČI",
  "TLAKA",
  "TLAKE",
  "TLAKI",
  "TLAKO",
  "TLAKU",
  "TLEČA",
  "TLEČE",
  "TLEČI",
  "TLELA",
  "TLELE",
  "TLELI",
  "TLELO",
  "TLENJ",
  "TLESK",
  "TLETI",
  "TLIJO",
  "TLIMO",
  "TLITA",
  "TLITE",
  "TLIVA",
  "TLIVK",
  "TNALC",
  "TNALO",
  "TNALU",
  "TOAST",
  "TOBAK",
  "TOČAJ",
  "TOČAM",
  "TOČEN",
  "TOČIL",
  "TOČIM",
  "TOČIŠ",
  "TOČKA",
  "TOČKE",
  "TOČKI",
  "TOČKO",
  "TOČNA",
  "TOČNE",
  "TOČNI",
  "TOČNO",
  "TOGOT",
  "TOKAJ",
  "TOKAT",
  "TOKAV",
  "TOKEJ",
  "TOKOJ",
  "TOKOM",
  "TOKOV",
  "TOLAR",
  "TOLCI",
  "TOLČE",
  "TOLIG",
  "TOLPA",
  "TOLPE",
  "TOLPI",
  "TOLPO",
  "TOLST",
  "TOLŠČ",
  "TONAM",
  "TONAŽ",
  "TONEČ",
  "TONEM",
  "TONER",
  "TONEŠ",
  "TONIK",
  "TONIL",
  "TONOM",
  "TONOV",
  "TONUS",
  "TOPAZ",
  "TOPEČ",
  "TOPEL",
  "TOPEN",
  "TOPIČ",
  "TOPIK",
  "TOPIL",
  "TOPLA",
  "TOPLE",
  "TOPLI",
  "TOPLO",
  "TOPNA",
  "TOPNE",
  "TOPNI",
  "TOPNO",
  "TOPOL",
  "TOPOT",
  "TOPOV",
  "TORBA",
  "TORBE",
  "TORBI",
  "TORBO",
  "TOREK",
  "TOREN",
  "TORIC",
  "TORIJ",
  "TORKA",
  "TORKE",
  "TORKI",
  "TORKU",
  "TORNA",
  "TORNE",
  "TORNI",
  "TORNO",
  "TOROM",
  "TOROV",
  "TORTA",
  "TORTE",
  "TORTI",
  "TORTO",
  "TORZA",
  "TORZE",
  "TORZI",
  "TORZO",
  "TORZU",
  "TOTAL",
  "TOTEM",
  "TOVOR",
  "TOZDA",
  "TOZDE",
  "TOZDI",
  "TOZDU",
  "TOŽBA",
  "TOŽBE",
  "TOŽBI",
  "TOŽBO",
  "TOŽEČ",
  "TOŽEN",
  "TOŽIL",
  "TOŽIM",
  "TOŽIŠ",
  "TOŽNA",
  "TOŽNE",
  "TOŽNI",
  "TOŽNO",
  "TRAČA",
  "TRAČE",
  "TRAČI",
  "TRAČU",
  "TRAJA",
  "TRAJN",
  "TRAKA",
  "TRAKE",
  "TRAKI",
  "TRAKT",
  "TRAKU",
  "TRALA",
  "TRAMA",
  "TRAME",
  "TRAMI",
  "TRAMU",
  "TRAPA",
  "TRAPE",
  "TRAPI",
  "TRAPO",
  "TRAPU",
  "TRASA",
  "TRASE",
  "TRASI",
  "TRASO",
  "TRATA",
  "TRATE",
  "TRATI",
  "TRATO",
  "TRAVA",
  "TRAVE",
  "TRAVI",
  "TRAVM",
  "TRAVO",
  "TRČEN",
  "TRČIL",
  "TRČIM",
  "TRČIŠ",
  "TRDEČ",
  "TRDEL",
  "TRDEN",
  "TRDIK",
  "TRDIL",
  "TRDNA",
  "TRDNE",
  "TRDNI",
  "TRDNO",
  "TRDOT",
  "TREBA",
  "TREBE",
  "TREMA",
  "TREME",
  "TREMI",
  "TREMO",
  "TREND",
  "TRENE",
  "TRENI",
  "TRENJ",
  "TRESI",
  "TRESK",
  "TRGAČ",
  "TRGAJ",
  "TRGAN",
  "TRGCA",
  "TRGCE",
  "TRGCI",
  "TRGCU",
  "TRGEC",
  "TRGOM",
  "TRGOV",
  "TRGUJ",
  "TRHEL",
  "TRHLA",
  "TRHLE",
  "TRHLI",
  "TRHLO",
  "TRIAD",
  "TRIAK",
  "TRIAS",
  "TRIAŽ",
  "TRIJE",
  "TRIKA",
  "TRIKE",
  "TRIKI",
  "TRIKO",
  "TRIKU",
  "TRIMA",
  "TRIMO",
  "TRIMU",
  "TRIOD",
  "TRIOK",
  "TRIOL",
  "TRIOM",
  "TRIOV",
  "TRIOZ",
  "TRIPS",
  "TRISA",
  "TRISE",
  "TRISI",
  "TRISU",
  "TRITA",
  "TRITE",
  "TRIVA",
  "TRJEN",
  "TRKAJ",
  "TRKAL",
  "TRKAM",
  "TRKAŠ",
  "TRKNE",
  "TRKNI",
  "TRKOM",
  "TRKOV",
  "TRLIC",
  "TRLJA",
  "TRLJE",
  "TRLJI",
  "TRLJO",
  "TRMAM",
  "TRMIC",
  "TRNAČ",
  "TRNAT",
  "TRNEK",
  "TRNEŽ",
  "TRNIC",
  "TRNJA",
  "TRNJE",
  "TRNJU",
  "TRNKA",
  "TRNKE",
  "TRNKI",
  "TRNKU",
  "TRNOM",
  "TRNOV",
  "TROBE",
  "TROBI",
  "TROFI",
  "TROHA",
  "TROHE",
  "TROHI",
  "TROHO",
  "TROJA",
  "TROJI",
  "TROJK",
  "TROLI",
  "TROLU",
  "TROMB",
  "TROPA",
  "TROPE",
  "TROPI",
  "TROPU",
  "TROSA",
  "TROSE",
  "TROSI",
  "TROSU",
  "TROŠI",
  "TROTA",
  "TROTE",
  "TROTI",
  "TROTU",
  "TRPAJ",
  "TRPEČ",
  "TRPEK",
  "TRPEL",
  "TRPEN",
  "TRPIM",
  "TRPIN",
  "TRPIŠ",
  "TRPKA",
  "TRPKE",
  "TRPKI",
  "TRPKO",
  "TRPNA",
  "TRPNE",
  "TRPNI",
  "TRPNO",
  "TRSAK",
  "TRSEN",
  "TRSJA",
  "TRSJE",
  "TRSJU",
  "TRSKA",
  "TRSKE",
  "TRSKI",
  "TRSKO",
  "TRSNA",
  "TRSNE",
  "TRSNI",
  "TRSNO",
  "TRSOM",
  "TRSOV",
  "TRSTA",
  "TRSTE",
  "TRSTI",
  "TRSTU",
  "TRŠAT",
  "TRŠKA",
  "TRŠKE",
  "TRŠKI",
  "TRŠKO",
  "TRTAM",
  "TRTEN",
  "TRTIC",
  "TRTNA",
  "TRTNE",
  "TRTNI",
  "TRTNO",
  "TRUDA",
  "TRUDU",
  "TRULA",
  "TRULE",
  "TRULI",
  "TRULO",
  "TRUMA",
  "TRUME",
  "TRUMI",
  "TRUMO",
  "TRUPA",
  "TRUPE",
  "TRUPI",
  "TRUPU",
  "TRUST",
  "TRUŠČ",
  "TRZAČ",
  "TRZAJ",
  "TRZAL",
  "TRZAM",
  "TRZAŠ",
  "TRZAV",
  "TRZNE",
  "TRZNI",
  "TRŽAN",
  "TRŽEČ",
  "TRŽEN",
  "TRŽNA",
  "TRŽNE",
  "TRŽNI",
  "TRŽNO",
  "TUBAM",
  "TUBIT",
  "TUBUS",
  "TUFIT",
  "TUHTA",
  "TUIJA",
  "TUIJE",
  "TUIJI",
  "TUIJU",
  "TUJAM",
  "TUJCA",
  "TUJCE",
  "TUJCI",
  "TUJCU",
  "TUJČI",
  "TUJEC",
  "TUJEK",
  "TUJIN",
  "TUJKA",
  "TUJKE",
  "TUJKI",
  "TUJKO",
  "TUJKU",
  "TUKAJ",
  "TUKAN",
  "TUKEL",
  "TUKLA",
  "TUKLE",
  "TUKLI",
  "TUKLO",
  "TULCA",
  "TULCE",
  "TULCI",
  "TULCU",
  "TULEC",
  "TULEČ",
  "TULEŽ",
  "TULIJ",
  "TULIL",
  "TULIM",
  "TULIŠ",
  "TULKA",
  "TULKE",
  "TULKI",
  "TULKO",
  "TULOM",
  "TULOV",
  "TUMOR",
  "TUMUL",
  "TUNAM",
  "TUNEL",
  "TUNIK",
  "TUNOM",
  "TUNOV",
  "TUPAJ",
  "TURAK",
  "TURAM",
  "TURAV",
  "TURBA",
  "TURBE",
  "TURBI",
  "TURBO",
  "TURBU",
  "TUREK",
  "TUREN",
  "TURIC",
  "TURKA",
  "TURKE",
  "TURKI",
  "TURKU",
  "TURNA",
  "TURNE",
  "TURNO",
  "TUROM",
  "TUROV",
  "TUŠEM",
  "TUŠEV",
  "TUTOR",
  "TVEGA",
  "TVEZE",
  "TVIDA",
  "TVIDU",
  "TVITA",
  "TVITE",
  "TVITI",
  "TVITU",
  "TVOJA",
  "TVOJE",
  "TVOJI",
  "TVORA",
  "TVORB",
  "TVORE",
  "TVORI",
  "TVORU",
  "UAKAR",
  "UBEŽE",
  "UBEŽI",
  "UBIJA",
  "UBITA",
  "UBITE",
  "UBITI",
  "UBITO",
  "UBOGA",
  "UBOGE",
  "UBOGI",
  "UBOGO",
  "UBOJA",
  "UBOJE",
  "UBOJI",
  "UBOJU",
  "UBRAN",
  "UČAKA",
  "UČEČA",
  "UČEČE",
  "UČEČI",
  "UČENJ",
  "UČENK",
  "UČIJO",
  "UČILA",
  "UČILE",
  "UČILI",
  "UČILO",
  "UČIMO",
  "UČITA",
  "UČITE",
  "UČITI",
  "UČIVA",
  "UČNIK",
  "UČNIN",
  "UDARA",
  "UDARE",
  "UDARI",
  "UDARU",
  "UDAVA",
  "UDAVE",
  "UDAVI",
  "UDAVU",
  "UDELA",
  "UDERE",
  "UDIRA",
  "UDOMA",
  "UDOMI",
  "UDORA",
  "UDORE",
  "UDORI",
  "UDORU",
  "UDREM",
  "UDREŠ",
  "UDRLA",
  "UDRLE",
  "UDRLI",
  "UDRLO",
  "UDRTA",
  "UDRTE",
  "UDRTI",
  "UDRTO",
  "UDUŠI",
  "UFSKA",
  "UFSKE",
  "UFSKI",
  "UFSKO",
  "UGANI",
  "UGANK",
  "UGASI",
  "UGAŠA",
  "UGIBA",
  "UGLED",
  "UGLEJ",
  "UGNAN",
  "UGREZ",
  "UGRIZ",
  "UHAČA",
  "UHAČE",
  "UHAČI",
  "UHAČU",
  "UHAJA",
  "UHANA",
  "UHANE",
  "UHANI",
  "UHANU",
  "UHATA",
  "UHATE",
  "UHATI",
  "UHATO",
  "UHELJ",
  "UHLJA",
  "UHLJE",
  "UHLJI",
  "UHLJU",
  "UHODI",
  "UIDEM",
  "UIDEŠ",
  "UIGRA",
  "UJAŠI",
  "UJCEM",
  "UJČEV",
  "UJEDA",
  "UJEDE",
  "UJEDI",
  "UJEDO",
  "UJEJO",
  "UJEMA",
  "UJEME",
  "UJEMI",
  "UJEMU",
  "UJETA",
  "UJETE",
  "UJETI",
  "UJETO",
  "UJEVA",
  "UJEZI",
  "UJMAM",
  "UJNAM",
  "UJNIN",
  "UKAJE",
  "UKAJO",
  "UKALA",
  "UKALE",
  "UKALI",
  "UKALO",
  "UKAMO",
  "UKANA",
  "UKANE",
  "UKANI",
  "UKANJ",
  "UKANO",
  "UKATA",
  "UKATE",
  "UKATI",
  "UKAVA",
  "UKAZA",
  "UKAZE",
  "UKAZI",
  "UKAZU",
  "UKAŽE",
  "UKAŽI",
  "UKINI",
  "UKLEL",
  "UKLET",
  "UKLON",
  "UKORA",
  "UKORE",
  "UKORI",
  "UKORU",
  "UKOSI",
  "UKREP",
  "UKUHA",
  "ULANA",
  "ULANE",
  "ULANI",
  "ULANK",
  "ULANU",
  "ULEKS",
  "ULEMA",
  "ULEME",
  "ULEMI",
  "ULEMO",
  "ULEMU",
  "ULEŽI",
  "ULICA",
  "ULICE",
  "ULICI",
  "ULICO",
  "ULITA",
  "ULITE",
  "ULITI",
  "ULITO",
  "ULJES",
  "ULKUS",
  "ULOMI",
  "ULOVA",
  "ULOVE",
  "ULOVI",
  "ULOVU",
  "ULTRA",
  "UMAMI",
  "UMAŽI",
  "UMBER",
  "UMBRA",
  "UMBRE",
  "UMBRI",
  "UMBRO",
  "UMEDI",
  "UMEJO",
  "UMELA",
  "UMELE",
  "UMELI",
  "UMELO",
  "UMEMO",
  "UMERI",
  "UMESI",
  "UMEŠA",
  "UMETA",
  "UMETE",
  "UMETI",
  "UMETO",
  "UMEVA",
  "UMIKA",
  "UMIKE",
  "UMIKI",
  "UMIKU",
  "UMIMO",
  "UMIRA",
  "UMIRI",
  "UMITA",
  "UMITE",
  "UMITI",
  "UMITO",
  "UMOMA",
  "UMORA",
  "UMORE",
  "UMORI",
  "UMORU",
  "UMREM",
  "UMREŠ",
  "UMRJE",
  "UMRLA",
  "UMRLE",
  "UMRLI",
  "UMRLO",
  "UMSKA",
  "UMSKE",
  "UMSKI",
  "UMSKO",
  "UMUJE",
  "UNČAM",
  "UNIAT",
  "UNIČI",
  "UNIJA",
  "UNIJE",
  "UNIJI",
  "UNIJO",
  "UNSKA",
  "UNSKE",
  "UNSKI",
  "UNSKO",
  "UNŠKA",
  "UNŠKE",
  "UNŠKI",
  "UNŠKO",
  "UPADA",
  "UPADE",
  "UPADI",
  "UPADU",
  "UPAJE",
  "UPAJO",
  "UPALA",
  "UPALE",
  "UPALI",
  "UPALO",
  "UPAMO",
  "UPANJ",
  "UPARI",
  "UPATA",
  "UPATE",
  "UPATI",
  "UPAVA",
  "UPEHA",
  "UPENI",
  "UPERE",
  "UPERI",
  "UPETA",
  "UPETE",
  "UPETI",
  "UPETO",
  "UPNIC",
  "UPNIK",
  "UPOMA",
  "UPORA",
  "UPORE",
  "UPORI",
  "UPORU",
  "UPOVE",
  "UPRAL",
  "UPRAV",
  "UPRTA",
  "UPRTE",
  "UPRTI",
  "UPRTO",
  "URADA",
  "URADE",
  "URADI",
  "URADU",
  "URAMA",
  "URANA",
  "URANU",
  "URARK",
  "URARN",
  "URATA",
  "URATE",
  "URATI",
  "URATU",
  "URBAN",
  "URBAR",
  "URECI",
  "UREČE",
  "UREČI",
  "UREDB",
  "UREDI",
  "UREZA",
  "UREZE",
  "UREZI",
  "UREZU",
  "UREŽI",
  "URHOM",
  "URHOV",
  "URICA",
  "URICE",
  "URICI",
  "URICO",
  "URIMO",
  "URINA",
  "URINE",
  "URINI",
  "URINO",
  "URINU",
  "URITA",
  "URITE",
  "URIVA",
  "URJEN",
  "URNIK",
  "UROČI",
  "UROKA",
  "UROKE",
  "UROKI",
  "UROKU",
  "URTIK",
  "URZON",
  "USADA",
  "USADE",
  "USADI",
  "USADU",
  "USEDA",
  "USEDE",
  "USEDI",
  "USEDU",
  "USEKA",
  "USEKE",
  "USEKI",
  "USEKU",
  "USIHA",
  "USIPA",
  "USIRI",
  "USLUG",
  "USNJA",
  "USNJE",
  "USNJU",
  "USODA",
  "USODE",
  "USODI",
  "USODO",
  "USPEH",
  "USPEL",
  "USPEM",
  "USPEŠ",
  "USTAŠ",
  "USTAV",
  "USTEC",
  "USTEČ",
  "USTEN",
  "USTEŽ",
  "USTIJ",
  "USTIL",
  "USTIŠ",
  "USTJA",
  "USTJE",
  "USTJI",
  "USTJU",
  "USTKA",
  "USTNA",
  "USTNE",
  "USTNI",
  "USTNO",
  "USTOM",
  "USUJE",
  "USULA",
  "USULE",
  "USULI",
  "USULO",
  "USUTA",
  "USUTE",
  "USUTI",
  "USUTO",
  "UŠATA",
  "UŠATE",
  "UŠATI",
  "UŠATO",
  "UŠČIP",
  "UŠEMA",
  "UŠESA",
  "UŠESC",
  "UŠESI",
  "UŠESU",
  "UŠICA",
  "UŠICE",
  "UŠICI",
  "UŠICO",
  "UŠIVA",
  "UŠIVE",
  "UŠIVI",
  "UŠIVK",
  "UŠIVO",
  "UŠKAM",
  "UŠKIN",
  "UTAJA",
  "UTAJE",
  "UTAJI",
  "UTAJO",
  "UTAMA",
  "UTECI",
  "UTEČE",
  "UTEČI",
  "UTEHA",
  "UTEHE",
  "UTEHI",
  "UTEHO",
  "UTEKA",
  "UTEKE",
  "UTEKI",
  "UTEKU",
  "UTEŠI",
  "UTEŽI",
  "UTICA",
  "UTICE",
  "UTICI",
  "UTICO",
  "UTIRI",
  "UTONE",
  "UTONI",
  "UTOPA",
  "UTOPE",
  "UTOPI",
  "UTOPU",
  "UTORA",
  "UTORE",
  "UTORI",
  "UTORU",
  "UTRDB",
  "UTRDI",
  "UTRGA",
  "UTRGE",
  "UTRGI",
  "UTRGU",
  "UTRIP",
  "UTRNE",
  "UTRNI",
  "UTRPI",
  "UTRTA",
  "UTRTE",
  "UTRTI",
  "UTRTO",
  "UTVAR",
  "UVALA",
  "UVALE",
  "UVALI",
  "UVALO",
  "UVARI",
  "UVEDB",
  "UVEDI",
  "UVELA",
  "UVELE",
  "UVELI",
  "UVELO",
  "UVENI",
  "UVEZE",
  "UVEZI",
  "UVIDA",
  "UVIDE",
  "UVIDI",
  "UVIDU",
  "UVIHA",
  "UVIJE",
  "UVILA",
  "UVILE",
  "UVILI",
  "UVILO",
  "UVITA",
  "UVITE",
  "UVITI",
  "UVITO",
  "UVODA",
  "UVODE",
  "UVODI",
  "UVODU",
  "UVOJA",
  "UVOJE",
  "UVOJI",
  "UVOJU",
  "UVOZA",
  "UVOZE",
  "UVOZI",
  "UVOZU",
  "UVRTA",
  "UZANC",
  "UZDAJ",
  "UZDAL",
  "UZDAM",
  "UZDAN",
  "UZDAŠ",
  "UZIJA",
  "UZIJE",
  "UZIJI",
  "UZIJU",
  "UZIRA",
  "UZRTA",
  "UZRTE",
  "UZRTI",
  "UZRTO",
  "UŽALI",
  "UŽARI",
  "UŽEJA",
  "UŽENI",
  "UŽIJE",
  "UŽILA",
  "UŽILE",
  "UŽILI",
  "UŽILO",
  "UŽITA",
  "UŽITE",
  "UŽITI",
  "UŽITO",
  "UŽIVA",
  "UŽUGA",
  "VABAM",
  "VABEČ",
  "VABIL",
  "VABIM",
  "VABIŠ",
  "VADBA",
  "VADBE",
  "VADBI",
  "VADBO",
  "VADEČ",
  "VADIL",
  "VADIM",
  "VADIŠ",
  "VAGON",
  "VAJAM",
  "VAJEN",
  "VAJET",
  "VAJIN",
  "VALAT",
  "VALEČ",
  "VALET",
  "VALIL",
  "VALIM",
  "VALIN",
  "VALIŠ",
  "VALJA",
  "VALJE",
  "VALJI",
  "VALJU",
  "VALOM",
  "VALOV",
  "VALUJ",
  "VALUK",
  "VAMPA",
  "VAMPE",
  "VAMPI",
  "VAMPU",
  "VANEŽ",
  "VANGA",
  "VANGE",
  "VANGI",
  "VANGO",
  "VANGU",
  "VARAJ",
  "VARAN",
  "VARAV",
  "VAREČ",
  "VAREK",
  "VAREN",
  "VARIC",
  "VARIL",
  "VARIM",
  "VARIŠ",
  "VARIV",
  "VARKA",
  "VARKE",
  "VARKI",
  "VARKU",
  "VARNA",
  "VARNE",
  "VARNI",
  "VARNO",
  "VAROA",
  "VAROE",
  "VAROI",
  "VAROJ",
  "VAROM",
  "VAROO",
  "VAROV",
  "VAROZ",
  "VARPA",
  "VARPE",
  "VARPI",
  "VARPO",
  "VARUH",
  "VARUJ",
  "VASEM",
  "VASIC",
  "VASUJ",
  "VAŠKA",
  "VAŠKE",
  "VAŠKI",
  "VAŠKO",
  "VATAM",
  "VATEL",
  "VATEN",
  "VATKA",
  "VATKE",
  "VATKI",
  "VATKO",
  "VATLA",
  "VATLE",
  "VATLI",
  "VATLU",
  "VATNA",
  "VATNE",
  "VATNI",
  "VATNO",
  "VATOM",
  "VATOV",
  "VAZAL",
  "VAZAM",
  "VAZEN",
  "VAZIC",
  "VAZNA",
  "VAZNE",
  "VAZNI",
  "VAZNO",
  "VAŽEN",
  "VAŽNA",
  "VAŽNE",
  "VAŽNI",
  "VAŽNO",
  "VBADA",
  "VBIJA",
  "VBIJE",
  "VBILA",
  "VBILE",
  "VBILI",
  "VBILO",
  "VBITA",
  "VBITE",
  "VBITI",
  "VBITO",
  "VBOČI",
  "VBODA",
  "VBODE",
  "VBODI",
  "VBODU",
  "VCEPI",
  "VČRTA",
  "VDABA",
  "VDABE",
  "VDABI",
  "VDABU",
  "VDAJA",
  "VDAJE",
  "VDAJI",
  "VDAJO",
  "VDANA",
  "VDANE",
  "VDANI",
  "VDANO",
  "VDENI",
  "VDERE",
  "VDETA",
  "VDETE",
  "VDETI",
  "VDETO",
  "VDIHA",
  "VDIHE",
  "VDIHI",
  "VDIHU",
  "VDIRA",
  "VDORA",
  "VDORE",
  "VDORI",
  "VDORU",
  "VDOVA",
  "VDOVE",
  "VDOVI",
  "VDOVO",
  "VDREM",
  "VDREŠ",
  "VDRLA",
  "VDRLE",
  "VDRLI",
  "VDRLO",
  "VDRTA",
  "VDRTE",
  "VDRTI",
  "VDRTO",
  "VEBER",
  "VEBRA",
  "VEBRE",
  "VEBRI",
  "VEBRU",
  "VEČAJ",
  "VEČAM",
  "VEČAŠ",
  "VEČEN",
  "VEČER",
  "VEČIN",
  "VEČJA",
  "VEČJE",
  "VEČJI",
  "VEČNA",
  "VEČNE",
  "VEČNI",
  "VEČNO",
  "VEDAM",
  "VEDEL",
  "VEDER",
  "VEDEŽ",
  "VEDNO",
  "VEDOČ",
  "VEDRA",
  "VEDRC",
  "VEDRE",
  "VEDRI",
  "VEDRN",
  "VEDRO",
  "VEDRU",
  "VEDUT",
  "VEDVE",
  "VEGAN",
  "VEGAV",
  "VEHAM",
  "VEJAJ",
  "VEJAL",
  "VEJAM",
  "VEJAŠ",
  "VEJEČ",
  "VEJEM",
  "VEJEN",
  "VEJEŠ",
  "VEJIC",
  "VEJIL",
  "VEJIM",
  "VEJIŠ",
  "VEJMO",
  "VEJNA",
  "VEJNE",
  "VEJNI",
  "VEJNO",
  "VEJOČ",
  "VEJPA",
  "VEJPE",
  "VEJPI",
  "VEJPU",
  "VEJTA",
  "VEJTE",
  "VEJVA",
  "VEKAJ",
  "VEKAL",
  "VEKAM",
  "VEKAŠ",
  "VEKOM",
  "VEKOV",
  "VELEČ",
  "VELEL",
  "VELES",
  "VELIK",
  "VELIM",
  "VELIŠ",
  "VELJA",
  "VELUR",
  "VENAM",
  "VENCA",
  "VENCE",
  "VENCI",
  "VENCU",
  "VENEC",
  "VENEČ",
  "VENEL",
  "VENEM",
  "VENEŠ",
  "VENGE",
  "VENIL",
  "VENIM",
  "VENIŠ",
  "VENUL",
  "VEPER",
  "VEPRA",
  "VEPRE",
  "VEPRI",
  "VEPRU",
  "VERAM",
  "VEREN",
  "VERIG",
  "VERNA",
  "VERNE",
  "VERNI",
  "VERNO",
  "VERUJ",
  "VERZA",
  "VERZE",
  "VERZI",
  "VERZU",
  "VESAM",
  "VESEL",
  "VESIN",
  "VESLA",
  "VESLI",
  "VESLO",
  "VESLU",
  "VESPA",
  "VESPE",
  "VESPI",
  "VESPO",
  "VESTA",
  "VESTE",
  "VESTI",
  "VEŠAL",
  "VEŠCA",
  "VEŠCE",
  "VEŠCI",
  "VEŠCU",
  "VEŠČA",
  "VEŠČE",
  "VEŠČI",
  "VEŠČO",
  "VEŠČU",
  "VEŠEC",
  "VEŠKA",
  "VEŠKE",
  "VEŠKI",
  "VEŠKO",
  "VETER",
  "VETIJ",
  "VETJA",
  "VETJE",
  "VETJI",
  "VETJU",
  "VETOM",
  "VETOV",
  "VETRA",
  "VETRC",
  "VETRN",
  "VETRU",
  "VEZAČ",
  "VEZAJ",
  "VEZAL",
  "VEZAN",
  "VEZAV",
  "VEZEL",
  "VEZEM",
  "VEZEN",
  "VEZEŠ",
  "VEZIC",
  "VEZIJ",
  "VEZIR",
  "VEZIV",
  "VEZJA",
  "VEZJE",
  "VEZJI",
  "VEZJU",
  "VEZLA",
  "VEZLE",
  "VEZLI",
  "VEZLO",
  "VEZNA",
  "VEZNE",
  "VEZNI",
  "VEZNO",
  "VEZOČ",
  "VEŽAM",
  "VEŽEM",
  "VEŽEN",
  "VEŽEŠ",
  "VEŽIC",
  "VEŽNA",
  "VEŽNE",
  "VEŽNI",
  "VEŽNO",
  "VEŽOČ",
  "VHODA",
  "VHODE",
  "VHODI",
  "VHODU",
  "VICAM",
  "VIDAJ",
  "VIDCA",
  "VIDCE",
  "VIDCI",
  "VIDCU",
  "VIDEA",
  "VIDEC",
  "VIDEČ",
  "VIDEE",
  "VIDEI",
  "VIDEL",
  "VIDEN",
  "VIDEO",
  "VIDER",
  "VIDEU",
  "VIDEZ",
  "VIDIK",
  "VIDIM",
  "VIDIŠ",
  "VIDNA",
  "VIDNE",
  "VIDNI",
  "VIDNO",
  "VIDON",
  "VIDRA",
  "VIDRE",
  "VIDRI",
  "VIDRO",
  "VIDVA",
  "VIDVE",
  "VIGEN",
  "VIGNA",
  "VIGNE",
  "VIGNI",
  "VIGNO",
  "VIHAJ",
  "VIHAR",
  "VIHER",
  "VIHRA",
  "VIHRE",
  "VIHRI",
  "VIHRO",
  "VIHRU",
  "VIHTI",
  "VIJAK",
  "VIJOČ",
  "VIJUG",
  "VIKAJ",
  "VIKAL",
  "VIKAM",
  "VIKAR",
  "VIKAŠ",
  "VILAM",
  "VILIC",
  "VILIN",
  "VIMEN",
  "VINAR",
  "VINCA",
  "VINCE",
  "VINCI",
  "VINCU",
  "VINIK",
  "VINIL",
  "VINOM",
  "VIOLA",
  "VIOLE",
  "VIOLI",
  "VIOLO",
  "VIROM",
  "VIROV",
  "VIROZ",
  "VIRUS",
  "VISEČ",
  "VISEL",
  "VISIM",
  "VISIŠ",
  "VISKI",
  "VISOK",
  "VISTA",
  "VISTU",
  "VIŠAJ",
  "VIŠAV",
  "VIŠEK",
  "VIŠIN",
  "VIŠJA",
  "VIŠJE",
  "VIŠJI",
  "VIŠKA",
  "VIŠKE",
  "VIŠKI",
  "VIŠKO",
  "VIŠKU",
  "VIŠOČ",
  "VITEK",
  "VITEL",
  "VITER",
  "VITEZ",
  "VITIC",
  "VITJA",
  "VITJE",
  "VITJI",
  "VITKA",
  "VITKE",
  "VITKI",
  "VITKO",
  "VITLA",
  "VITLE",
  "VITLI",
  "VITLO",
  "VITLU",
  "VITRA",
  "VITRE",
  "VITRI",
  "VITRO",
  "VIZIJ",
  "VIZIR",
  "VIZIT",
  "VIZUM",
  "VIZUR",
  "VJEDO",
  "VJEMO",
  "VJEVA",
  "VKLOP",
  "VKOPA",
  "VKOPE",
  "VKOPI",
  "VKOPU",
  "VKUHA",
  "VKUJE",
  "VLAČA",
  "VLAČE",
  "VLAČI",
  "VLAČO",
  "VLADA",
  "VLADE",
  "VLADI",
  "VLADO",
  "VLAGA",
  "VLAGE",
  "VLAGI",
  "VLAGO",
  "VLAKA",
  "VLAKE",
  "VLAKI",
  "VLAKO",
  "VLAKU",
  "VLAŽI",
  "VLECI",
  "VLEČK",
  "VLEKA",
  "VLEKE",
  "VLEKI",
  "VLEKO",
  "VLEKU",
  "VLEPI",
  "VLIKA",
  "VLITA",
  "VLITE",
  "VLITI",
  "VLITO",
  "VLIVA",
  "VLIVE",
  "VLIVI",
  "VLIVU",
  "VLOGA",
  "VLOGE",
  "VLOGI",
  "VLOGO",
  "VLOMA",
  "VLOME",
  "VLOMI",
  "VLOMU",
  "VLOŽI",
  "VMESI",
  "VNEMA",
  "VNEME",
  "VNEMI",
  "VNEMO",
  "VNESI",
  "VNETA",
  "VNETE",
  "VNETI",
  "VNETO",
  "VNOSA",
  "VNOSE",
  "VNOSI",
  "VNOSU",
  "VNUČK",
  "VNUKA",
  "VNUKE",
  "VNUKI",
  "VNUKU",
  "VODAM",
  "VODAR",
  "VODEB",
  "VODEČ",
  "VODEN",
  "VODIC",
  "VODIČ",
  "VODIJ",
  "VODIK",
  "VODIL",
  "VODIM",
  "VODIŠ",
  "VODJA",
  "VODJE",
  "VODJI",
  "VODJO",
  "VODJU",
  "VODKA",
  "VODKE",
  "VODKI",
  "VODKO",
  "VODNA",
  "VODNE",
  "VODNI",
  "VODNO",
  "VODOM",
  "VODOV",
  "VOGAL",
  "VOGEL",
  "VOGLA",
  "VOGLE",
  "VOGLI",
  "VOGLU",
  "VOHAJ",
  "VOHAL",
  "VOHAM",
  "VOHAŠ",
  "VOHKA",
  "VOHUN",
  "VOJAK",
  "VOJEN",
  "VOJNA",
  "VOJNE",
  "VOJNI",
  "VOJNO",
  "VOJSK",
  "VOKAL",
  "VOKOM",
  "VOKOV",
  "VOLAN",
  "VOLAR",
  "VOLEČ",
  "VOLEJ",
  "VOLEK",
  "VOLEM",
  "VOLHA",
  "VOLHE",
  "VOLHI",
  "VOLHU",
  "VOLIL",
  "VOLIM",
  "VOLIŠ",
  "VOLJA",
  "VOLJE",
  "VOLJI",
  "VOLJO",
  "VOLKA",
  "VOLKU",
  "VOLNA",
  "VOLNE",
  "VOLNI",
  "VOLNO",
  "VOLOM",
  "VOLOV",
  "VOLTA",
  "VOLTE",
  "VOLTI",
  "VOLTU",
  "VOLUT",
  "VOLVA",
  "VOLVE",
  "VOLVI",
  "VOLVO",
  "VOLVU",
  "VOMER",
  "VONJA",
  "VONJE",
  "VONJI",
  "VONJU",
  "VONOM",
  "VONOV",
  "VOSAT",
  "VOSEK",
  "VOSKA",
  "VOSKE",
  "VOSKI",
  "VOSKU",
  "VOSOM",
  "VOSOV",
  "VOŠČI",
  "VOŠKA",
  "VOŠKE",
  "VOŠKI",
  "VOŠKO",
  "VOTEK",
  "VOTEL",
  "VOTKA",
  "VOTKE",
  "VOTKI",
  "VOTKU",
  "VOTLA",
  "VOTLE",
  "VOTLI",
  "VOTLO",
  "VOZAČ",
  "VOZAR",
  "VOZEČ",
  "VOZEK",
  "VOZEL",
  "VOZEM",
  "VOZEN",
  "VOZIL",
  "VOZIM",
  "VOZIŠ",
  "VOZJE",
  "VOZKA",
  "VOZKE",
  "VOZKI",
  "VOZKU",
  "VOZLA",
  "VOZLE",
  "VOZLI",
  "VOZLU",
  "VOZNA",
  "VOZNE",
  "VOZNI",
  "VOZNO",
  "VOZOV",
  "VPADA",
  "VPADE",
  "VPADI",
  "VPADU",
  "VPETA",
  "VPETE",
  "VPETI",
  "VPETO",
  "VPIHA",
  "VPIHE",
  "VPIHI",
  "VPIHU",
  "VPIJA",
  "VPIJE",
  "VPILA",
  "VPILE",
  "VPILI",
  "VPILO",
  "VPISA",
  "VPISE",
  "VPISI",
  "VPISU",
  "VPIŠE",
  "VPIŠI",
  "VPITI",
  "VPLET",
  "VPLIV",
  "VPLUJ",
  "VPLUL",
  "VPOJA",
  "VPOJE",
  "VPOJI",
  "VPOJU",
  "VPONA",
  "VPONE",
  "VPONI",
  "VPONK",
  "VPONO",
  "VPREG",
  "VPREK",
  "VPUST",
  "VRAČA",
  "VRAČE",
  "VRAČI",
  "VRAČK",
  "VRAČU",
  "VRAGA",
  "VRAGE",
  "VRAGI",
  "VRAGU",
  "VRANA",
  "VRANE",
  "VRANI",
  "VRANK",
  "VRANO",
  "VRANU",
  "VRASE",
  "VRATA",
  "VRATC",
  "VRATI",
  "VRATU",
  "VRAŽA",
  "VRAŽE",
  "VRAŽI",
  "VRAŽO",
  "VRBAM",
  "VRBAR",
  "VRBEN",
  "VRBIC",
  "VRBIN",
  "VRBJA",
  "VRBJE",
  "VRBJI",
  "VRBJU",
  "VRBNA",
  "VRBNE",
  "VRBNI",
  "VRBNO",
  "VRBOV",
  "VRČEK",
  "VRČEM",
  "VRČEV",
  "VRČIC",
  "VRČKA",
  "VRČKE",
  "VRČKI",
  "VRČKU",
  "VREČA",
  "VREČE",
  "VREČI",
  "VREČK",
  "VREČO",
  "VREJO",
  "VRELA",
  "VRELE",
  "VRELI",
  "VRELK",
  "VRELO",
  "VREME",
  "VREMO",
  "VRENJ",
  "VRESA",
  "VRESE",
  "VRESI",
  "VRESO",
  "VRESU",
  "VREŠČ",
  "VRETA",
  "VRETE",
  "VRETI",
  "VREVA",
  "VREZA",
  "VREZE",
  "VREZI",
  "VREZU",
  "VREŽA",
  "VREŽE",
  "VREŽI",
  "VREŽO",
  "VRGEL",
  "VRGLA",
  "VRGLE",
  "VRGLI",
  "VRGLO",
  "VRHAČ",
  "VRHAJ",
  "VRHAL",
  "VRHAM",
  "VRHAŠ",
  "VRHAT",
  "VRHOM",
  "VRHOV",
  "VRIJA",
  "VRIJE",
  "VRIJI",
  "VRIJU",
  "VRIMO",
  "VRINI",
  "VRISA",
  "VRISE",
  "VRISI",
  "VRISK",
  "VRISU",
  "VRIŠČ",
  "VRIŠI",
  "VRITA",
  "VRITE",
  "VRIVA",
  "VRLIN",
  "VROČA",
  "VROČE",
  "VROČI",
  "VRSTA",
  "VRSTE",
  "VRSTI",
  "VRSTO",
  "VRŠAC",
  "VRŠAJ",
  "VRŠAL",
  "VRŠAM",
  "VRŠEČ",
  "VRŠEK",
  "VRŠEL",
  "VRŠEN",
  "VRŠIČ",
  "VRŠKA",
  "VRŠKE",
  "VRŠKI",
  "VRŠKO",
  "VRŠKU",
  "VRŠNA",
  "VRŠNE",
  "VRŠNI",
  "VRŠNO",
  "VRTAČ",
  "VRTAJ",
  "VRTAL",
  "VRTAM",
  "VRTAN",
  "VRTAŠ",
  "VRTAV",
  "VRTCA",
  "VRTCE",
  "VRTCI",
  "VRTCU",
  "VRTEC",
  "VRTEČ",
  "VRTEK",
  "VRTEN",
  "VRTEŽ",
  "VRTIL",
  "VRTIN",
  "VRTKA",
  "VRTKE",
  "VRTKI",
  "VRTKU",
  "VRTNA",
  "VRTNE",
  "VRTNI",
  "VRTNO",
  "VRTOM",
  "VRTOV",
  "VRVAR",
  "VRVCA",
  "VRVCE",
  "VRVCI",
  "VRVCO",
  "VRVEČ",
  "VRVEL",
  "VRVEM",
  "VRVEN",
  "VRVEŽ",
  "VRVIC",
  "VRVIM",
  "VRVIŠ",
  "VRVJA",
  "VRVJE",
  "VRVJU",
  "VRVNA",
  "VRVNE",
  "VRVNI",
  "VRVNO",
  "VRZEL",
  "VRŽEM",
  "VRŽEN",
  "VRŽEŠ",
  "VSADI",
  "VSAKA",
  "VSAKE",
  "VSAKI",
  "VSAKO",
  "VSEGA",
  "VSEJE",
  "VSEKA",
  "VSEKE",
  "VSEKI",
  "VSEKU",
  "VSEMU",
  "VSESA",
  "VSILI",
  "VSIPA",
  "VSIPE",
  "VSIPI",
  "VSIPU",
  "VSKOK",
  "VSOTA",
  "VSOTE",
  "VSOTI",
  "VSOTO",
  "VSRČE",
  "VSRKA",
  "VSTAJ",
  "VSTAL",
  "VSTAV",
  "VSTOP",
  "VSUJE",
  "VSULA",
  "VSULE",
  "VSULI",
  "VSULO",
  "VSUTA",
  "VSUTE",
  "VSUTI",
  "VSUTO",
  "VŠIJE",
  "VŠILA",
  "VŠILE",
  "VŠILI",
  "VŠILO",
  "VŠITA",
  "VŠITE",
  "VŠITI",
  "VŠITO",
  "VŠIVA",
  "VŠIVE",
  "VŠIVI",
  "VŠOLA",
  "VŠTEJ",
  "VŠTET",
  "VTALI",
  "VTECI",
  "VTEČE",
  "VTEČI",
  "VTEKA",
  "VTEPA",
  "VTEPE",
  "VTEPI",
  "VTIČA",
  "VTIČE",
  "VTIČI",
  "VTIČU",
  "VTIKA",
  "VTIKE",
  "VTIKI",
  "VTIKU",
  "VTISA",
  "VTISE",
  "VTISI",
  "VTISK",
  "VTISU",
  "VTKAN",
  "VTOČI",
  "VTOKA",
  "VTOKE",
  "VTOKI",
  "VTOKU",
  "VTRTA",
  "VTRTE",
  "VTRTI",
  "VTRTO",
  "VULVA",
  "VULVE",
  "VULVI",
  "VULVO",
  "VZAME",
  "VZBUH",
  "VZDEL",
  "VZDIG",
  "VZDIH",
  "VZELA",
  "VZELE",
  "VZELI",
  "VZELO",
  "VZEMI",
  "VZETA",
  "VZETE",
  "VZETI",
  "VZETO",
  "VZGIB",
  "VZGON",
  "VZHOD",
  "VZIDA",
  "VZIDE",
  "VZIDI",
  "VZITI",
  "VZKIP",
  "VZLET",
  "VZMAH",
  "VZMET",
  "VZMIK",
  "VZNAK",
  "VZNIK",
  "VZORA",
  "VZORE",
  "VZORI",
  "VZORU",
  "VZPET",
  "VZPON",
  "VZPOR",
  "VZREJ",
  "VZROK",
  "VZŠEL",
  "VZŠLA",
  "VZŠLE",
  "VZŠLI",
  "VZŠLO",
  "VZVOD",
  "VŽGAL",
  "VŽGAN",
  "VŽGEM",
  "VŽGEŠ",
  "VŽIGA",
  "VŽIGE",
  "VŽIGI",
  "VŽIGU",
  "ZAARA",
  "ZABAV",
  "ZABEL",
  "ZABIJ",
  "ZABIT",
  "ZABOD",
  "ZABOJ",
  "ZAČEL",
  "ZAČET",
  "ZAČNE",
  "ZAČNI",
  "ZAČNO",
  "ZAČUJ",
  "ZAČUL",
  "ZADAH",
  "ZADAJ",
  "ZADAN",
  "ZADEK",
  "ZADEL",
  "ZADET",
  "ZADEV",
  "ZADKA",
  "ZADKE",
  "ZADKI",
  "ZADKU",
  "ZADRG",
  "ZADRT",
  "ZAGAT",
  "ZAGIB",
  "ZAGLA",
  "ZAGON",
  "ZAHOD",
  "ZAIDE",
  "ZAIDI",
  "ZAITI",
  "ZAJCA",
  "ZAJCE",
  "ZAJCI",
  "ZAJCU",
  "ZAJEC",
  "ZAJED",
  "ZAJEJ",
  "ZAJEM",
  "ZAJER",
  "ZAJEŠ",
  "ZAJET",
  "ZAJKA",
  "ZAJKE",
  "ZAJKI",
  "ZAJKO",
  "ZAKAJ",
  "ZAKOL",
  "ZAKON",
  "ZAKOP",
  "ZAKOV",
  "ZAKUH",
  "ZAKUJ",
  "ZAKUP",
  "ZALAZ",
  "ZALEG",
  "ZALET",
  "ZALIJ",
  "ZALIL",
  "ZALIT",
  "ZALIV",
  "ZALOG",
  "ZALOM",
  "ZAMAH",
  "ZAMAN",
  "ZAMAZ",
  "ZAMEN",
  "ZAMER",
  "ZAMET",
  "ZAMIK",
  "ZAMRE",
  "ZAMRI",
  "ZAMRL",
  "ZAMUD",
  "ZANIČ",
  "ZANKA",
  "ZANKE",
  "ZANKI",
  "ZANKO",
  "ZANOS",
  "ZAPAH",
  "ZAPAŽ",
  "ZAPEL",
  "ZAPET",
  "ZAPIJ",
  "ZAPIK",
  "ZAPIL",
  "ZAPIS",
  "ZAPIT",
  "ZAPNI",
  "ZAPOG",
  "ZAPOJ",
  "ZAPON",
  "ZAPOR",
  "ZAPRI",
  "ZAPRT",
  "ZARDI",
  "ZARES",
  "ZAREZ",
  "ZARIJ",
  "ZARIS",
  "ZARIT",
  "ZARJA",
  "ZARJE",
  "ZARJI",
  "ZARJO",
  "ZAROD",
  "ZAROK",
  "ZAROM",
  "ZAROT",
  "ZAROV",
  "ZASED",
  "ZASEG",
  "ZASEJ",
  "ZASEK",
  "ZASIJ",
  "ZASIP",
  "ZASPI",
  "ZASUJ",
  "ZASUK",
  "ZASUN",
  "ZASUT",
  "ZAŠEL",
  "ZAŠIJ",
  "ZAŠIT",
  "ZAŠLA",
  "ZAŠLE",
  "ZAŠLI",
  "ZAŠLO",
  "ZATEG",
  "ZATEM",
  "ZATIČ",
  "ZATIK",
  "ZATOK",
  "ZATON",
  "ZATRI",
  "ZATRT",
  "ZAUKA",
  "ZAUPA",
  "ZAVEJ",
  "ZAVEL",
  "ZAVER",
  "ZAVES",
  "ZAVET",
  "ZAVEZ",
  "ZAVIH",
  "ZAVIJ",
  "ZAVIL",
  "ZAVIT",
  "ZAVOD",
  "ZAVOJ",
  "ZAVOR",
  "ZAVRA",
  "ZAVRE",
  "ZAVRI",
  "ZAVRL",
  "ZAVRT",
  "ZAVRU",
  "ZAZIB",
  "ZAZIL",
  "ZAZRT",
  "ZAŽIG",
  "ZAŽRE",
  "ZAŽRI",
  "ZAŽRL",
  "ZAŽRT",
  "ZBAŠI",
  "ZBERI",
  "ZBEZA",
  "ZBEŽE",
  "ZBEŽI",
  "ZBIČA",
  "ZBIOP",
  "ZBIRA",
  "ZBIRE",
  "ZBIRI",
  "ZBIRK",
  "ZBIRU",
  "ZBITA",
  "ZBITE",
  "ZBITI",
  "ZBITO",
  "ZBLOD",
  "ZBLOP",
  "ZBODI",
  "ZBOLI",
  "ZBORA",
  "ZBORE",
  "ZBORI",
  "ZBORU",
  "ZBOSI",
  "ZBRAN",
  "ZBRCA",
  "ZBRIS",
  "ZBUDI",
  "ZDAJE",
  "ZDAJI",
  "ZDAVP",
  "ZDELA",
  "ZDELI",
  "ZDELO",
  "ZDERE",
  "ZDERI",
  "ZDIMS",
  "ZDIRA",
  "ZDLOV",
  "ZDOMA",
  "ZDOMK",
  "ZDOSK",
  "ZDPRA",
  "ZDPRE",
  "ZDRAD",
  "ZDRAH",
  "ZDRAV",
  "ZDRČI",
  "ZDREM",
  "ZDREŠ",
  "ZDRIZ",
  "ZDRKA",
  "ZDRKE",
  "ZDRKI",
  "ZDRKU",
  "ZDRLA",
  "ZDRLE",
  "ZDRLI",
  "ZDRLO",
  "ZDROB",
  "ZDRSA",
  "ZDRSE",
  "ZDRSI",
  "ZDRSU",
  "ZDRUP",
  "ZDRVI",
  "ZDRŽE",
  "ZDRŽI",
  "ZDSTA",
  "ZDSVE",
  "ZDTON",
  "ZDUŠI",
  "ZDZDR",
  "ZEBER",
  "ZEBRA",
  "ZEBRE",
  "ZEBRI",
  "ZEBRO",
  "ZEHAJ",
  "ZEHAL",
  "ZEHAM",
  "ZEHAŠ",
  "ZEHAV",
  "ZEKOM",
  "ZELEM",
  "ZELEN",
  "ZELIK",
  "ZELJA",
  "ZELJE",
  "ZELJU",
  "ZELOT",
  "ZENIC",
  "ZENIT",
  "ZEŠKA",
  "ZEŠKE",
  "ZEŠKI",
  "ZEŠKO",
  "ZETAM",
  "ZETOM",
  "ZETOV",
  "ZEVAJ",
  "ZEVAL",
  "ZEVAM",
  "ZEVAŠ",
  "ZEVEM",
  "ZEVKA",
  "ZEVKE",
  "ZEVKI",
  "ZEVKO",
  "ZEVOM",
  "ZEVOV",
  "ZGAGA",
  "ZGAGE",
  "ZGAGI",
  "ZGAGO",
  "ZGANI",
  "ZGARA",
  "ZGAZI",
  "ZGDEJ",
  "ZGIBA",
  "ZGIBE",
  "ZGIBI",
  "ZGIBU",
  "ZGLED",
  "ZGLOB",
  "ZGNIJ",
  "ZGNIL",
  "ZGNIT",
  "ZGODA",
  "ZGODB",
  "ZGODE",
  "ZGODI",
  "ZGODO",
  "ZGONJ",
  "ZGORI",
  "ZGPRO",
  "ZGRBI",
  "ZGRDA",
  "ZGRMI",
  "ZGUBA",
  "ZGUBE",
  "ZGUBI",
  "ZGUBO",
  "ZGULI",
  "ZIBAJ",
  "ZIBAN",
  "ZIBEL",
  "ZIBKA",
  "ZIBKE",
  "ZIBKI",
  "ZIBKO",
  "ZIBOM",
  "ZIBOV",
  "ZIDAJ",
  "ZIDAK",
  "ZIDAL",
  "ZIDAM",
  "ZIDAN",
  "ZIDAR",
  "ZIDAŠ",
  "ZIDAV",
  "ZIDCA",
  "ZIDCE",
  "ZIDCI",
  "ZIDCU",
  "ZIDEC",
  "ZIDEK",
  "ZIDEN",
  "ZIDKA",
  "ZIDKE",
  "ZIDKI",
  "ZIDKU",
  "ZIDNA",
  "ZIDNE",
  "ZIDNI",
  "ZIDNO",
  "ZIDOV",
  "ZIGOT",
  "ZIGOZ",
  "ZIJAJ",
  "ZIJAL",
  "ZIJAM",
  "ZIJAŠ",
  "ZIMAM",
  "ZINEM",
  "ZINEŠ",
  "ZINFP",
  "ZINIL",
  "ZINVO",
  "ZIPRO",
  "ZJAHA",
  "ZJAŠE",
  "ZJAŠI",
  "ZJEDI",
  "ZJEZI",
  "ZJOČE",
  "ZJOČI",
  "ZJOKA",
  "ZKOLP",
  "ZKOZP",
  "ZKRMI",
  "ZLASA",
  "ZLATA",
  "ZLATE",
  "ZLATI",
  "ZLATO",
  "ZLATU",
  "ZLEPA",
  "ZLEPI",
  "ZLETA",
  "ZLETE",
  "ZLETI",
  "ZLETU",
  "ZLEZE",
  "ZLEZI",
  "ZLIKA",
  "ZLITA",
  "ZLITE",
  "ZLITI",
  "ZLITO",
  "ZLIŽE",
  "ZLIŽI",
  "ZLOBA",
  "ZLOBE",
  "ZLOBI",
  "ZLOBO",
  "ZLOGA",
  "ZLOGE",
  "ZLOGI",
  "ZLOGU",
  "ZLOMA",
  "ZLOME",
  "ZLOMI",
  "ZLOMU",
  "ZLOTA",
  "ZLOTE",
  "ZLOTI",
  "ZLOTU",
  "ZLOŽI",
  "ZMAGA",
  "ZMAGE",
  "ZMAGI",
  "ZMAGO",
  "ZMAJA",
  "ZMAJE",
  "ZMAJI",
  "ZMAJU",
  "ZMAMI",
  "ZMANE",
  "ZMANI",
  "ZMAŠI",
  "ZMATD",
  "ZMATR",
  "ZMAŽI",
  "ZMEČE",
  "ZMEČI",
  "ZMEDA",
  "ZMEDE",
  "ZMEDI",
  "ZMEDO",
  "ZMELA",
  "ZMELE",
  "ZMELI",
  "ZMELO",
  "ZMENE",
  "ZMESI",
  "ZMEŠA",
  "ZMETA",
  "ZMETD",
  "ZMETE",
  "ZMETI",
  "ZMETO",
  "ZMEZI",
  "ZMIJE",
  "ZMILA",
  "ZMILE",
  "ZMILI",
  "ZMILO",
  "ZMING",
  "ZMINP",
  "ZMITA",
  "ZMITE",
  "ZMITI",
  "ZMITO",
  "ZMLET",
  "ZMOČI",
  "ZMORE",
  "ZMOTA",
  "ZMOTE",
  "ZMOTI",
  "ZMOTO",
  "ZMRŠI",
  "ZNAČK",
  "ZNAJO",
  "ZNAKA",
  "ZNAKE",
  "ZNAKI",
  "ZNAKU",
  "ZNALA",
  "ZNALE",
  "ZNALI",
  "ZNALK",
  "ZNALO",
  "ZNAMK",
  "ZNAMO",
  "ZNANA",
  "ZNANE",
  "ZNANI",
  "ZNANJ",
  "ZNANK",
  "ZNANO",
  "ZNATA",
  "ZNATE",
  "ZNATI",
  "ZNAVA",
  "ZNOČI",
  "ZNOJA",
  "ZNOJU",
  "ZNORE",
  "ZNORG",
  "ZNORI",
  "ZNOSI",
  "ZNOVA",
  "ZOBAČ",
  "ZOBAJ",
  "ZOBAN",
  "ZOBAT",
  "ZOBCA",
  "ZOBCE",
  "ZOBCI",
  "ZOBCU",
  "ZOBČA",
  "ZOBEC",
  "ZOBEČ",
  "ZOBEK",
  "ZOBEM",
  "ZOBEN",
  "ZOBER",
  "ZOBIL",
  "ZOBIM",
  "ZOBIŠ",
  "ZOBJE",
  "ZOBKA",
  "ZOBKE",
  "ZOBKI",
  "ZOBKU",
  "ZOBNA",
  "ZOBNE",
  "ZOBNI",
  "ZOBNO",
  "ZOBRA",
  "ZOBRE",
  "ZOBRI",
  "ZOBRU",
  "ZOFAM",
  "ZOIZK",
  "ZOJED",
  "ZOLJA",
  "ZOLJE",
  "ZOLJI",
  "ZOLJU",
  "ZOMBI",
  "ZONAM",
  "ZONUL",
  "ZOPRN",
  "ZORAM",
  "ZORAN",
  "ZOREČ",
  "ZORED",
  "ZOREL",
  "ZOREN",
  "ZORIC",
  "ZORIM",
  "ZORIŠ",
  "ZORJI",
  "ZORNA",
  "ZORNE",
  "ZORNI",
  "ZORNO",
  "ZOŽEN",
  "ZOŽUJ",
  "ZPEDS",
  "ZPKOR",
  "ZPKRI",
  "ZPKRV",
  "ZPLAP",
  "ZPOLS",
  "ZPOMK",
  "ZPOTK",
  "ZPPRE",
  "ZPRAP",
  "ZPRCP",
  "ZPREN",
  "ZPSTO",
  "ZPSTR",
  "ZRACS",
  "ZRAČI",
  "ZRAKA",
  "ZRAKE",
  "ZRAKI",
  "ZRAKU",
  "ZRASE",
  "ZRAST",
  "ZRCAL",
  "ZREDI",
  "ZREJO",
  "ZRELA",
  "ZRELE",
  "ZRELI",
  "ZRELO",
  "ZREMO",
  "ZRENJ",
  "ZRETA",
  "ZRETE",
  "ZRETI",
  "ZREVA",
  "ZREŽE",
  "ZREŽI",
  "ZRIDR",
  "ZRIJE",
  "ZRILA",
  "ZRILE",
  "ZRILI",
  "ZRILO",
  "ZRIMO",
  "ZRINI",
  "ZRISA",
  "ZRIŠE",
  "ZRIŠI",
  "ZRITA",
  "ZRITE",
  "ZRITI",
  "ZRITO",
  "ZRIVA",
  "ZRKEL",
  "ZRKLA",
  "ZRKLI",
  "ZRKLO",
  "ZRKLU",
  "ZRNAT",
  "ZRNCA",
  "ZRNCE",
  "ZRNCI",
  "ZRNCU",
  "ZRNEC",
  "ZRNEČ",
  "ZRNIL",
  "ZRNIM",
  "ZRNIŠ",
  "ZRNJA",
  "ZRNJE",
  "ZRNJU",
  "ZRNOM",
  "ZROČA",
  "ZROČE",
  "ZROČI",
  "ZROJI",
  "ZROMS",
  "ZRPRI",
  "ZRSIN",
  "ZRUŠI",
  "ZRVAM",
  "ZRVIN",
  "ZSAMS",
  "ZSDRP",
  "ZSEIS",
  "ZSEMS",
  "ZSKUS",
  "ZSLAR",
  "ZSOLI",
  "ZSOLL",
  "ZSRAZ",
  "ZSREG",
  "ZSREZ",
  "ZSRIB",
  "ZSSUK",
  "ZSSVE",
  "ZSZVE",
  "ZUMOM",
  "ZUMOV",
  "ZUNAJ",
  "ZUREP",
  "ZUSTS",
  "ZUVZA",
  "ZVABI",
  "ZVADI",
  "ZVAJA",
  "ZVALI",
  "ZVARA",
  "ZVARD",
  "ZVARE",
  "ZVARI",
  "ZVARU",
  "ZVEČA",
  "ZVEDI",
  "ZVEDO",
  "ZVEJO",
  "ZVEKO",
  "ZVEMO",
  "ZVENA",
  "ZVENE",
  "ZVENI",
  "ZVENU",
  "ZVERC",
  "ZVERI",
  "ZVEST",
  "ZVEVA",
  "ZVEZA",
  "ZVEZD",
  "ZVEZE",
  "ZVEZI",
  "ZVEZO",
  "ZVEŽI",
  "ZVICA",
  "ZVINA",
  "ZVINE",
  "ZVINI",
  "ZVINU",
  "ZVITA",
  "ZVITE",
  "ZVITI",
  "ZVITO",
  "ZVODI",
  "ZVOJA",
  "ZVOJD",
  "ZVOJE",
  "ZVOJI",
  "ZVOJL",
  "ZVOJU",
  "ZVOKA",
  "ZVOKE",
  "ZVOKI",
  "ZVOKU",
  "ZVOLK",
  "ZVONA",
  "ZVONE",
  "ZVONI",
  "ZVONU",
  "ZVOZI",
  "ZVPOT",
  "ZVPOZ",
  "ZVRHA",
  "ZVRNE",
  "ZVRNI",
  "ZVRST",
  "ZVRTA",
  "ZVRTE",
  "ZVRTI",
  "ZVRTU",
  "ZVTEH",
  "ZVTEL",
  "ZVUPR",
  "ZZASV",
  "ZZDEJ",
  "ZZDRI",
  "ZZDRL",
  "ZZDRS",
  "ZZELP",
  "ZZKAT",
  "ZZOBV",
  "ZZPRI",
  "ZZRAP",
  "ZZZAT",
  "ZZZIV",
  "ŽABAM",
  "ŽABCA",
  "ŽABCE",
  "ŽABCI",
  "ŽABCU",
  "ŽABEC",
  "ŽABIC",
  "ŽABIN",
  "ŽABJA",
  "ŽABJE",
  "ŽABJI",
  "ŽABON",
  "ŽADEN",
  "ŽADNA",
  "ŽADNE",
  "ŽADNI",
  "ŽADNO",
  "ŽADOV",
  "ŽAGAJ",
  "ŽAGAL",
  "ŽAGAM",
  "ŽAGAN",
  "ŽAGAR",
  "ŽAGAŠ",
  "ŽAGEN",
  "ŽAGIC",
  "ŽAGIN",
  "ŽAGNA",
  "ŽAGNE",
  "ŽAGNI",
  "ŽAGNO",
  "ŽALAM",
  "ŽALEČ",
  "ŽALEN",
  "ŽALNA",
  "ŽALNE",
  "ŽALNI",
  "ŽALNO",
  "ŽALUJ",
  "ŽAMEM",
  "ŽAMEŠ",
  "ŽAMET",
  "ŽANJE",
  "ŽANJI",
  "ŽANRA",
  "ŽANRE",
  "ŽANRI",
  "ŽANRU",
  "ŽARAM",
  "ŽARČI",
  "ŽAREČ",
  "ŽAREK",
  "ŽAREL",
  "ŽAREN",
  "ŽARIJ",
  "ŽARIL",
  "ŽARIM",
  "ŽARIN",
  "ŽARIŠ",
  "ŽARJA",
  "ŽARJE",
  "ŽARJI",
  "ŽARJO",
  "ŽARKA",
  "ŽARKE",
  "ŽARKI",
  "ŽARKO",
  "ŽARKU",
  "ŽARNA",
  "ŽARNE",
  "ŽARNI",
  "ŽARNO",
  "ŽAROM",
  "ŽAROV",
  "ŽBICA",
  "ŽBICE",
  "ŽBICI",
  "ŽBICO",
  "ŽDEČA",
  "ŽDEČE",
  "ŽDEČI",
  "ŽDELA",
  "ŽDELE",
  "ŽDELI",
  "ŽDELO",
  "ŽDENJ",
  "ŽDETI",
  "ŽDIJO",
  "ŽDIMO",
  "ŽDITA",
  "ŽDITE",
  "ŽDIVA",
  "ŽEBRA",
  "ŽEHTE",
  "ŽEJAJ",
  "ŽEJAL",
  "ŽEJAM",
  "ŽEJAŠ",
  "ŽEJEN",
  "ŽEJNA",
  "ŽEJNE",
  "ŽEJNI",
  "ŽEJNO",
  "ŽELAT",
  "ŽELEČ",
  "ŽELEL",
  "ŽELEN",
  "ŽELEZ",
  "ŽELIM",
  "ŽELIŠ",
  "ŽELJA",
  "ŽELJE",
  "ŽELJI",
  "ŽELJO",
  "ŽELOD",
  "ŽELOM",
  "ŽELVA",
  "ŽELVE",
  "ŽELVI",
  "ŽELVO",
  "ŽENAM",
  "ŽENET",
  "ŽENIC",
  "ŽENIN",
  "ŽENKA",
  "ŽENKE",
  "ŽENKI",
  "ŽENKO",
  "ŽENSK",
  "ŽEPAR",
  "ŽEPEK",
  "ŽEPEN",
  "ŽEPIČ",
  "ŽEPKA",
  "ŽEPKE",
  "ŽEPKI",
  "ŽEPKU",
  "ŽEPNA",
  "ŽEPNE",
  "ŽEPNI",
  "ŽEPNO",
  "ŽEPOM",
  "ŽEPOV",
  "ŽERKA",
  "ŽERKE",
  "ŽERKI",
  "ŽERKO",
  "ŽERUH",
  "ŽETEV",
  "ŽETIJ",
  "ŽETJA",
  "ŽETJE",
  "ŽETJI",
  "ŽETJU",
  "ŽETON",
  "ŽETVE",
  "ŽETVI",
  "ŽEZEL",
  "ŽEZLA",
  "ŽEZLI",
  "ŽEZLO",
  "ŽEZLU",
  "ŽGALA",
  "ŽGALE",
  "ŽGALI",
  "ŽGALK",
  "ŽGALO",
  "ŽGANA",
  "ŽGANE",
  "ŽGANI",
  "ŽGANJ",
  "ŽGANO",
  "ŽGATI",
  "ŽGEJO",
  "ŽGEMO",
  "ŽGETA",
  "ŽGETE",
  "ŽGEVA",
  "ŽGIMO",
  "ŽGITA",
  "ŽGITE",
  "ŽGIVA",
  "ŽGOČA",
  "ŽGOČE",
  "ŽGOČI",
  "ŽGOLI",
  "ŽICAM",
  "ŽIČEČ",
  "ŽIČEN",
  "ŽIČIL",
  "ŽIČIM",
  "ŽIČIŠ",
  "ŽIČKA",
  "ŽIČKE",
  "ŽIČKI",
  "ŽIČNA",
  "ŽIČNE",
  "ŽIČNI",
  "ŽIČNO",
  "ŽIDEK",
  "ŽIDKA",
  "ŽIDKE",
  "ŽIDKI",
  "ŽIDKO",
  "ŽIDOM",
  "ŽIDOV",
  "ŽIGOM",
  "ŽIGOV",
  "ŽILAM",
  "ŽILAV",
  "ŽILEN",
  "ŽILIC",
  "ŽILIK",
  "ŽILJA",
  "ŽILJE",
  "ŽILJI",
  "ŽILJK",
  "ŽILJU",
  "ŽILNA",
  "ŽILNE",
  "ŽILNI",
  "ŽILNO",
  "ŽIRAF",
  "ŽIRIJ",
  "ŽIROM",
  "ŽIROV",
  "ŽIŠKA",
  "ŽIŠKE",
  "ŽIŠKI",
  "ŽIŠKO",
  "ŽITCA",
  "ŽITCE",
  "ŽITCI",
  "ŽITCU",
  "ŽITEC",
  "ŽITEN",
  "ŽITIJ",
  "ŽITJA",
  "ŽITJE",
  "ŽITJI",
  "ŽITJU",
  "ŽITNA",
  "ŽITNE",
  "ŽITNI",
  "ŽITNO",
  "ŽITOM",
  "ŽIVAL",
  "ŽIVCA",
  "ŽIVCE",
  "ŽIVCI",
  "ŽIVCU",
  "ŽIVEC",
  "ŽIVEČ",
  "ŽIVEL",
  "ŽIVET",
  "ŽIVEŽ",
  "ŽIVIC",
  "ŽIVIK",
  "ŽIVIL",
  "ŽIVIM",
  "ŽIVIN",
  "ŽIVIO",
  "ŽIVIŠ",
  "ŽIŽOL",
  "ŽIŽUL",
  "ŽLEBA",
  "ŽLEBE",
  "ŽLEBI",
  "ŽLEBU",
  "ŽLEDA",
  "ŽLEDI",
  "ŽLEDU",
  "ŽLEMP",
  "ŽLEZA",
  "ŽLEZE",
  "ŽLEZI",
  "ŽLEZO",
  "ŽLICA",
  "ŽLICE",
  "ŽLICI",
  "ŽLICO",
  "ŽLIČK",
  "ŽMEJO",
  "ŽMEMO",
  "ŽMETA",
  "ŽMETE",
  "ŽMEVA",
  "ŽMIMO",
  "ŽMITA",
  "ŽMITE",
  "ŽMIVA",
  "ŽMULA",
  "ŽMULE",
  "ŽMULI",
  "ŽMULO",
  "ŽMURK",
  "ŽOGAJ",
  "ŽOGAL",
  "ŽOGAM",
  "ŽOGAŠ",
  "ŽOGIC",
  "ŽOLCA",
  "ŽOLCE",
  "ŽOLCI",
  "ŽOLCO",
  "ŽOLČA",
  "ŽOLČE",
  "ŽOLČI",
  "ŽOLČU",
  "ŽOLNA",
  "ŽOLNE",
  "ŽOLNI",
  "ŽOLNO",
  "ŽRDAN",
  "ŽRDEM",
  "ŽREBA",
  "ŽREBE",
  "ŽREBU",
  "ŽREJO",
  "ŽRELA",
  "ŽRELC",
  "ŽRELI",
  "ŽRELO",
  "ŽRELU",
  "ŽREMO",
  "ŽRETA",
  "ŽRETE",
  "ŽRETI",
  "ŽREVA",
  "ŽRIMO",
  "ŽRITA",
  "ŽRITE",
  "ŽRIVA",
  "ŽRTEV",
  "ŽRTVE",
  "ŽRTVI",
  "ŽUGAJ",
  "ŽUGAL",
  "ŽUGAM",
  "ŽUGAŠ",
  "ŽUKAM",
  "ŽUKIN",
  "ŽULEČ",
  "ŽULIL",
  "ŽULJA",
  "ŽULJE",
  "ŽULJI",
  "ŽULJU",
  "ŽUMAR",
  "ŽUPAN",
  "ŽUPEN",
  "ŽUPNA",
  "ŽUPNE",
  "ŽUPNI",
  "ŽUPNO",
  "ŽUŽEK",
  "ŽUŽKA",
  "ŽUŽKE",
  "ŽUŽKI",
  "ŽUŽKU",
  "ŽVALA",
  "ŽVALE",
  "ŽVALI",
  "ŽVALO",
  "ŽVEČI",
  "ŽVENK",
  "ŽVERC",
  "ŽVIŽG",
  "ŽVRKA",
];